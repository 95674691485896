.tw-container {
  width: 100%;
}

@media (min-width: 640px) {
  .tw-container {
    max-width: 640px;
  }
}

@media (min-width: 768px) {
  .tw-container {
    max-width: 768px;
  }
}

@media (min-width: 1024px) {
  .tw-container {
    max-width: 1024px;
  }
}

@media (min-width: 1280px) {
  .tw-container {
    max-width: 1280px;
  }
}

.tw-space-y-0 > :not(template) ~ :not(template) {
  --space-y-reverse: 0;
  margin-top: calc(0px * calc(1 - var(--space-y-reverse)));
  margin-bottom: calc(0px * var(--space-y-reverse));
}

.tw-space-x-0 > :not(template) ~ :not(template) {
  --space-x-reverse: 0;
  margin-right: calc(0px * var(--space-x-reverse));
  margin-left: calc(0px * calc(1 - var(--space-x-reverse)));
}

.tw-space-y-1 > :not(template) ~ :not(template) {
  --space-y-reverse: 0;
  margin-top: calc(0.25rem * calc(1 - var(--space-y-reverse)));
  margin-bottom: calc(0.25rem * var(--space-y-reverse));
}

.tw-space-x-1 > :not(template) ~ :not(template) {
  --space-x-reverse: 0;
  margin-right: calc(0.25rem * var(--space-x-reverse));
  margin-left: calc(0.25rem * calc(1 - var(--space-x-reverse)));
}

.tw-space-y-2 > :not(template) ~ :not(template) {
  --space-y-reverse: 0;
  margin-top: calc(0.5rem * calc(1 - var(--space-y-reverse)));
  margin-bottom: calc(0.5rem * var(--space-y-reverse));
}

.tw-space-x-2 > :not(template) ~ :not(template) {
  --space-x-reverse: 0;
  margin-right: calc(0.5rem * var(--space-x-reverse));
  margin-left: calc(0.5rem * calc(1 - var(--space-x-reverse)));
}

.tw-space-y-3 > :not(template) ~ :not(template) {
  --space-y-reverse: 0;
  margin-top: calc(0.75rem * calc(1 - var(--space-y-reverse)));
  margin-bottom: calc(0.75rem * var(--space-y-reverse));
}

.tw-space-x-3 > :not(template) ~ :not(template) {
  --space-x-reverse: 0;
  margin-right: calc(0.75rem * var(--space-x-reverse));
  margin-left: calc(0.75rem * calc(1 - var(--space-x-reverse)));
}

.tw-space-y-4 > :not(template) ~ :not(template) {
  --space-y-reverse: 0;
  margin-top: calc(1rem * calc(1 - var(--space-y-reverse)));
  margin-bottom: calc(1rem * var(--space-y-reverse));
}

.tw-space-x-4 > :not(template) ~ :not(template) {
  --space-x-reverse: 0;
  margin-right: calc(1rem * var(--space-x-reverse));
  margin-left: calc(1rem * calc(1 - var(--space-x-reverse)));
}

.tw-space-y-5 > :not(template) ~ :not(template) {
  --space-y-reverse: 0;
  margin-top: calc(1.25rem * calc(1 - var(--space-y-reverse)));
  margin-bottom: calc(1.25rem * var(--space-y-reverse));
}

.tw-space-x-5 > :not(template) ~ :not(template) {
  --space-x-reverse: 0;
  margin-right: calc(1.25rem * var(--space-x-reverse));
  margin-left: calc(1.25rem * calc(1 - var(--space-x-reverse)));
}

.tw-space-y-6 > :not(template) ~ :not(template) {
  --space-y-reverse: 0;
  margin-top: calc(1.5rem * calc(1 - var(--space-y-reverse)));
  margin-bottom: calc(1.5rem * var(--space-y-reverse));
}

.tw-space-x-6 > :not(template) ~ :not(template) {
  --space-x-reverse: 0;
  margin-right: calc(1.5rem * var(--space-x-reverse));
  margin-left: calc(1.5rem * calc(1 - var(--space-x-reverse)));
}

.tw-space-y-8 > :not(template) ~ :not(template) {
  --space-y-reverse: 0;
  margin-top: calc(2rem * calc(1 - var(--space-y-reverse)));
  margin-bottom: calc(2rem * var(--space-y-reverse));
}

.tw-space-x-8 > :not(template) ~ :not(template) {
  --space-x-reverse: 0;
  margin-right: calc(2rem * var(--space-x-reverse));
  margin-left: calc(2rem * calc(1 - var(--space-x-reverse)));
}

.tw-space-y-10 > :not(template) ~ :not(template) {
  --space-y-reverse: 0;
  margin-top: calc(2.5rem * calc(1 - var(--space-y-reverse)));
  margin-bottom: calc(2.5rem * var(--space-y-reverse));
}

.tw-space-x-10 > :not(template) ~ :not(template) {
  --space-x-reverse: 0;
  margin-right: calc(2.5rem * var(--space-x-reverse));
  margin-left: calc(2.5rem * calc(1 - var(--space-x-reverse)));
}

.tw-space-y-12 > :not(template) ~ :not(template) {
  --space-y-reverse: 0;
  margin-top: calc(3rem * calc(1 - var(--space-y-reverse)));
  margin-bottom: calc(3rem * var(--space-y-reverse));
}

.tw-space-x-12 > :not(template) ~ :not(template) {
  --space-x-reverse: 0;
  margin-right: calc(3rem * var(--space-x-reverse));
  margin-left: calc(3rem * calc(1 - var(--space-x-reverse)));
}

.tw-space-y-16 > :not(template) ~ :not(template) {
  --space-y-reverse: 0;
  margin-top: calc(4rem * calc(1 - var(--space-y-reverse)));
  margin-bottom: calc(4rem * var(--space-y-reverse));
}

.tw-space-x-16 > :not(template) ~ :not(template) {
  --space-x-reverse: 0;
  margin-right: calc(4rem * var(--space-x-reverse));
  margin-left: calc(4rem * calc(1 - var(--space-x-reverse)));
}

.tw-space-y-20 > :not(template) ~ :not(template) {
  --space-y-reverse: 0;
  margin-top: calc(5rem * calc(1 - var(--space-y-reverse)));
  margin-bottom: calc(5rem * var(--space-y-reverse));
}

.tw-space-x-20 > :not(template) ~ :not(template) {
  --space-x-reverse: 0;
  margin-right: calc(5rem * var(--space-x-reverse));
  margin-left: calc(5rem * calc(1 - var(--space-x-reverse)));
}

.tw-space-y-24 > :not(template) ~ :not(template) {
  --space-y-reverse: 0;
  margin-top: calc(6rem * calc(1 - var(--space-y-reverse)));
  margin-bottom: calc(6rem * var(--space-y-reverse));
}

.tw-space-x-24 > :not(template) ~ :not(template) {
  --space-x-reverse: 0;
  margin-right: calc(6rem * var(--space-x-reverse));
  margin-left: calc(6rem * calc(1 - var(--space-x-reverse)));
}

.tw-space-y-32 > :not(template) ~ :not(template) {
  --space-y-reverse: 0;
  margin-top: calc(8rem * calc(1 - var(--space-y-reverse)));
  margin-bottom: calc(8rem * var(--space-y-reverse));
}

.tw-space-x-32 > :not(template) ~ :not(template) {
  --space-x-reverse: 0;
  margin-right: calc(8rem * var(--space-x-reverse));
  margin-left: calc(8rem * calc(1 - var(--space-x-reverse)));
}

.tw-space-y-40 > :not(template) ~ :not(template) {
  --space-y-reverse: 0;
  margin-top: calc(10rem * calc(1 - var(--space-y-reverse)));
  margin-bottom: calc(10rem * var(--space-y-reverse));
}

.tw-space-x-40 > :not(template) ~ :not(template) {
  --space-x-reverse: 0;
  margin-right: calc(10rem * var(--space-x-reverse));
  margin-left: calc(10rem * calc(1 - var(--space-x-reverse)));
}

.tw-space-y-48 > :not(template) ~ :not(template) {
  --space-y-reverse: 0;
  margin-top: calc(12rem * calc(1 - var(--space-y-reverse)));
  margin-bottom: calc(12rem * var(--space-y-reverse));
}

.tw-space-x-48 > :not(template) ~ :not(template) {
  --space-x-reverse: 0;
  margin-right: calc(12rem * var(--space-x-reverse));
  margin-left: calc(12rem * calc(1 - var(--space-x-reverse)));
}

.tw-space-y-56 > :not(template) ~ :not(template) {
  --space-y-reverse: 0;
  margin-top: calc(14rem * calc(1 - var(--space-y-reverse)));
  margin-bottom: calc(14rem * var(--space-y-reverse));
}

.tw-space-x-56 > :not(template) ~ :not(template) {
  --space-x-reverse: 0;
  margin-right: calc(14rem * var(--space-x-reverse));
  margin-left: calc(14rem * calc(1 - var(--space-x-reverse)));
}

.tw-space-y-64 > :not(template) ~ :not(template) {
  --space-y-reverse: 0;
  margin-top: calc(16rem * calc(1 - var(--space-y-reverse)));
  margin-bottom: calc(16rem * var(--space-y-reverse));
}

.tw-space-x-64 > :not(template) ~ :not(template) {
  --space-x-reverse: 0;
  margin-right: calc(16rem * var(--space-x-reverse));
  margin-left: calc(16rem * calc(1 - var(--space-x-reverse)));
}

.tw-space-y-px > :not(template) ~ :not(template) {
  --space-y-reverse: 0;
  margin-top: calc(1px * calc(1 - var(--space-y-reverse)));
  margin-bottom: calc(1px * var(--space-y-reverse));
}

.tw-space-x-px > :not(template) ~ :not(template) {
  --space-x-reverse: 0;
  margin-right: calc(1px * var(--space-x-reverse));
  margin-left: calc(1px * calc(1 - var(--space-x-reverse)));
}

.tw-space-y-270px > :not(template) ~ :not(template) {
  --space-y-reverse: 0;
  margin-top: calc(270px * calc(1 - var(--space-y-reverse)));
  margin-bottom: calc(270px * var(--space-y-reverse));
}

.tw-space-x-270px > :not(template) ~ :not(template) {
  --space-x-reverse: 0;
  margin-right: calc(270px * var(--space-x-reverse));
  margin-left: calc(270px * calc(1 - var(--space-x-reverse)));
}

.tw-space-y-400px > :not(template) ~ :not(template) {
  --space-y-reverse: 0;
  margin-top: calc(400px * calc(1 - var(--space-y-reverse)));
  margin-bottom: calc(400px * var(--space-y-reverse));
}

.tw-space-x-400px > :not(template) ~ :not(template) {
  --space-x-reverse: 0;
  margin-right: calc(400px * var(--space-x-reverse));
  margin-left: calc(400px * calc(1 - var(--space-x-reverse)));
}

.tw-space-y-464px > :not(template) ~ :not(template) {
  --space-y-reverse: 0;
  margin-top: calc(464px * calc(1 - var(--space-y-reverse)));
  margin-bottom: calc(464px * var(--space-y-reverse));
}

.tw-space-x-464px > :not(template) ~ :not(template) {
  --space-x-reverse: 0;
  margin-right: calc(464px * var(--space-x-reverse));
  margin-left: calc(464px * calc(1 - var(--space-x-reverse)));
}

.tw-space-y-480px > :not(template) ~ :not(template) {
  --space-y-reverse: 0;
  margin-top: calc(480px * calc(1 - var(--space-y-reverse)));
  margin-bottom: calc(480px * var(--space-y-reverse));
}

.tw-space-x-480px > :not(template) ~ :not(template) {
  --space-x-reverse: 0;
  margin-right: calc(480px * var(--space-x-reverse));
  margin-left: calc(480px * calc(1 - var(--space-x-reverse)));
}

.tw-space-y-500px > :not(template) ~ :not(template) {
  --space-y-reverse: 0;
  margin-top: calc(500px * calc(1 - var(--space-y-reverse)));
  margin-bottom: calc(500px * var(--space-y-reverse));
}

.tw-space-x-500px > :not(template) ~ :not(template) {
  --space-x-reverse: 0;
  margin-right: calc(500px * var(--space-x-reverse));
  margin-left: calc(500px * calc(1 - var(--space-x-reverse)));
}

.tw-space-y-576px > :not(template) ~ :not(template) {
  --space-y-reverse: 0;
  margin-top: calc(576px * calc(1 - var(--space-y-reverse)));
  margin-bottom: calc(576px * var(--space-y-reverse));
}

.tw-space-x-576px > :not(template) ~ :not(template) {
  --space-x-reverse: 0;
  margin-right: calc(576px * var(--space-x-reverse));
  margin-left: calc(576px * calc(1 - var(--space-x-reverse)));
}

.tw-space-y-600px > :not(template) ~ :not(template) {
  --space-y-reverse: 0;
  margin-top: calc(600px * calc(1 - var(--space-y-reverse)));
  margin-bottom: calc(600px * var(--space-y-reverse));
}

.tw-space-x-600px > :not(template) ~ :not(template) {
  --space-x-reverse: 0;
  margin-right: calc(600px * var(--space-x-reverse));
  margin-left: calc(600px * calc(1 - var(--space-x-reverse)));
}

.tw-space-y-672px > :not(template) ~ :not(template) {
  --space-y-reverse: 0;
  margin-top: calc(672px * calc(1 - var(--space-y-reverse)));
  margin-bottom: calc(672px * var(--space-y-reverse));
}

.tw-space-x-672px > :not(template) ~ :not(template) {
  --space-x-reverse: 0;
  margin-right: calc(672px * var(--space-x-reverse));
  margin-left: calc(672px * calc(1 - var(--space-x-reverse)));
}

.tw--space-y-1 > :not(template) ~ :not(template) {
  --space-y-reverse: 0;
  margin-top: calc(-0.25rem * calc(1 - var(--space-y-reverse)));
  margin-bottom: calc(-0.25rem * var(--space-y-reverse));
}

.tw--space-x-1 > :not(template) ~ :not(template) {
  --space-x-reverse: 0;
  margin-right: calc(-0.25rem * var(--space-x-reverse));
  margin-left: calc(-0.25rem * calc(1 - var(--space-x-reverse)));
}

.tw--space-y-2 > :not(template) ~ :not(template) {
  --space-y-reverse: 0;
  margin-top: calc(-0.5rem * calc(1 - var(--space-y-reverse)));
  margin-bottom: calc(-0.5rem * var(--space-y-reverse));
}

.tw--space-x-2 > :not(template) ~ :not(template) {
  --space-x-reverse: 0;
  margin-right: calc(-0.5rem * var(--space-x-reverse));
  margin-left: calc(-0.5rem * calc(1 - var(--space-x-reverse)));
}

.tw--space-y-3 > :not(template) ~ :not(template) {
  --space-y-reverse: 0;
  margin-top: calc(-0.75rem * calc(1 - var(--space-y-reverse)));
  margin-bottom: calc(-0.75rem * var(--space-y-reverse));
}

.tw--space-x-3 > :not(template) ~ :not(template) {
  --space-x-reverse: 0;
  margin-right: calc(-0.75rem * var(--space-x-reverse));
  margin-left: calc(-0.75rem * calc(1 - var(--space-x-reverse)));
}

.tw--space-y-4 > :not(template) ~ :not(template) {
  --space-y-reverse: 0;
  margin-top: calc(-1rem * calc(1 - var(--space-y-reverse)));
  margin-bottom: calc(-1rem * var(--space-y-reverse));
}

.tw--space-x-4 > :not(template) ~ :not(template) {
  --space-x-reverse: 0;
  margin-right: calc(-1rem * var(--space-x-reverse));
  margin-left: calc(-1rem * calc(1 - var(--space-x-reverse)));
}

.tw--space-y-5 > :not(template) ~ :not(template) {
  --space-y-reverse: 0;
  margin-top: calc(-1.25rem * calc(1 - var(--space-y-reverse)));
  margin-bottom: calc(-1.25rem * var(--space-y-reverse));
}

.tw--space-x-5 > :not(template) ~ :not(template) {
  --space-x-reverse: 0;
  margin-right: calc(-1.25rem * var(--space-x-reverse));
  margin-left: calc(-1.25rem * calc(1 - var(--space-x-reverse)));
}

.tw--space-y-6 > :not(template) ~ :not(template) {
  --space-y-reverse: 0;
  margin-top: calc(-1.5rem * calc(1 - var(--space-y-reverse)));
  margin-bottom: calc(-1.5rem * var(--space-y-reverse));
}

.tw--space-x-6 > :not(template) ~ :not(template) {
  --space-x-reverse: 0;
  margin-right: calc(-1.5rem * var(--space-x-reverse));
  margin-left: calc(-1.5rem * calc(1 - var(--space-x-reverse)));
}

.tw--space-y-8 > :not(template) ~ :not(template) {
  --space-y-reverse: 0;
  margin-top: calc(-2rem * calc(1 - var(--space-y-reverse)));
  margin-bottom: calc(-2rem * var(--space-y-reverse));
}

.tw--space-x-8 > :not(template) ~ :not(template) {
  --space-x-reverse: 0;
  margin-right: calc(-2rem * var(--space-x-reverse));
  margin-left: calc(-2rem * calc(1 - var(--space-x-reverse)));
}

.tw--space-y-10 > :not(template) ~ :not(template) {
  --space-y-reverse: 0;
  margin-top: calc(-2.5rem * calc(1 - var(--space-y-reverse)));
  margin-bottom: calc(-2.5rem * var(--space-y-reverse));
}

.tw--space-x-10 > :not(template) ~ :not(template) {
  --space-x-reverse: 0;
  margin-right: calc(-2.5rem * var(--space-x-reverse));
  margin-left: calc(-2.5rem * calc(1 - var(--space-x-reverse)));
}

.tw--space-y-12 > :not(template) ~ :not(template) {
  --space-y-reverse: 0;
  margin-top: calc(-3rem * calc(1 - var(--space-y-reverse)));
  margin-bottom: calc(-3rem * var(--space-y-reverse));
}

.tw--space-x-12 > :not(template) ~ :not(template) {
  --space-x-reverse: 0;
  margin-right: calc(-3rem * var(--space-x-reverse));
  margin-left: calc(-3rem * calc(1 - var(--space-x-reverse)));
}

.tw--space-y-16 > :not(template) ~ :not(template) {
  --space-y-reverse: 0;
  margin-top: calc(-4rem * calc(1 - var(--space-y-reverse)));
  margin-bottom: calc(-4rem * var(--space-y-reverse));
}

.tw--space-x-16 > :not(template) ~ :not(template) {
  --space-x-reverse: 0;
  margin-right: calc(-4rem * var(--space-x-reverse));
  margin-left: calc(-4rem * calc(1 - var(--space-x-reverse)));
}

.tw--space-y-20 > :not(template) ~ :not(template) {
  --space-y-reverse: 0;
  margin-top: calc(-5rem * calc(1 - var(--space-y-reverse)));
  margin-bottom: calc(-5rem * var(--space-y-reverse));
}

.tw--space-x-20 > :not(template) ~ :not(template) {
  --space-x-reverse: 0;
  margin-right: calc(-5rem * var(--space-x-reverse));
  margin-left: calc(-5rem * calc(1 - var(--space-x-reverse)));
}

.tw--space-y-24 > :not(template) ~ :not(template) {
  --space-y-reverse: 0;
  margin-top: calc(-6rem * calc(1 - var(--space-y-reverse)));
  margin-bottom: calc(-6rem * var(--space-y-reverse));
}

.tw--space-x-24 > :not(template) ~ :not(template) {
  --space-x-reverse: 0;
  margin-right: calc(-6rem * var(--space-x-reverse));
  margin-left: calc(-6rem * calc(1 - var(--space-x-reverse)));
}

.tw--space-y-32 > :not(template) ~ :not(template) {
  --space-y-reverse: 0;
  margin-top: calc(-8rem * calc(1 - var(--space-y-reverse)));
  margin-bottom: calc(-8rem * var(--space-y-reverse));
}

.tw--space-x-32 > :not(template) ~ :not(template) {
  --space-x-reverse: 0;
  margin-right: calc(-8rem * var(--space-x-reverse));
  margin-left: calc(-8rem * calc(1 - var(--space-x-reverse)));
}

.tw--space-y-40 > :not(template) ~ :not(template) {
  --space-y-reverse: 0;
  margin-top: calc(-10rem * calc(1 - var(--space-y-reverse)));
  margin-bottom: calc(-10rem * var(--space-y-reverse));
}

.tw--space-x-40 > :not(template) ~ :not(template) {
  --space-x-reverse: 0;
  margin-right: calc(-10rem * var(--space-x-reverse));
  margin-left: calc(-10rem * calc(1 - var(--space-x-reverse)));
}

.tw--space-y-48 > :not(template) ~ :not(template) {
  --space-y-reverse: 0;
  margin-top: calc(-12rem * calc(1 - var(--space-y-reverse)));
  margin-bottom: calc(-12rem * var(--space-y-reverse));
}

.tw--space-x-48 > :not(template) ~ :not(template) {
  --space-x-reverse: 0;
  margin-right: calc(-12rem * var(--space-x-reverse));
  margin-left: calc(-12rem * calc(1 - var(--space-x-reverse)));
}

.tw--space-y-56 > :not(template) ~ :not(template) {
  --space-y-reverse: 0;
  margin-top: calc(-14rem * calc(1 - var(--space-y-reverse)));
  margin-bottom: calc(-14rem * var(--space-y-reverse));
}

.tw--space-x-56 > :not(template) ~ :not(template) {
  --space-x-reverse: 0;
  margin-right: calc(-14rem * var(--space-x-reverse));
  margin-left: calc(-14rem * calc(1 - var(--space-x-reverse)));
}

.tw--space-y-64 > :not(template) ~ :not(template) {
  --space-y-reverse: 0;
  margin-top: calc(-16rem * calc(1 - var(--space-y-reverse)));
  margin-bottom: calc(-16rem * var(--space-y-reverse));
}

.tw--space-x-64 > :not(template) ~ :not(template) {
  --space-x-reverse: 0;
  margin-right: calc(-16rem * var(--space-x-reverse));
  margin-left: calc(-16rem * calc(1 - var(--space-x-reverse)));
}

.tw--space-y-px > :not(template) ~ :not(template) {
  --space-y-reverse: 0;
  margin-top: calc(-1px * calc(1 - var(--space-y-reverse)));
  margin-bottom: calc(-1px * var(--space-y-reverse));
}

.tw--space-x-px > :not(template) ~ :not(template) {
  --space-x-reverse: 0;
  margin-right: calc(-1px * var(--space-x-reverse));
  margin-left: calc(-1px * calc(1 - var(--space-x-reverse)));
}

.tw--space-y-270px > :not(template) ~ :not(template) {
  --space-y-reverse: 0;
  margin-top: calc(-270px * calc(1 - var(--space-y-reverse)));
  margin-bottom: calc(-270px * var(--space-y-reverse));
}

.tw--space-x-270px > :not(template) ~ :not(template) {
  --space-x-reverse: 0;
  margin-right: calc(-270px * var(--space-x-reverse));
  margin-left: calc(-270px * calc(1 - var(--space-x-reverse)));
}

.tw--space-y-400px > :not(template) ~ :not(template) {
  --space-y-reverse: 0;
  margin-top: calc(-400px * calc(1 - var(--space-y-reverse)));
  margin-bottom: calc(-400px * var(--space-y-reverse));
}

.tw--space-x-400px > :not(template) ~ :not(template) {
  --space-x-reverse: 0;
  margin-right: calc(-400px * var(--space-x-reverse));
  margin-left: calc(-400px * calc(1 - var(--space-x-reverse)));
}

.tw--space-y-464px > :not(template) ~ :not(template) {
  --space-y-reverse: 0;
  margin-top: calc(-464px * calc(1 - var(--space-y-reverse)));
  margin-bottom: calc(-464px * var(--space-y-reverse));
}

.tw--space-x-464px > :not(template) ~ :not(template) {
  --space-x-reverse: 0;
  margin-right: calc(-464px * var(--space-x-reverse));
  margin-left: calc(-464px * calc(1 - var(--space-x-reverse)));
}

.tw--space-y-480px > :not(template) ~ :not(template) {
  --space-y-reverse: 0;
  margin-top: calc(-480px * calc(1 - var(--space-y-reverse)));
  margin-bottom: calc(-480px * var(--space-y-reverse));
}

.tw--space-x-480px > :not(template) ~ :not(template) {
  --space-x-reverse: 0;
  margin-right: calc(-480px * var(--space-x-reverse));
  margin-left: calc(-480px * calc(1 - var(--space-x-reverse)));
}

.tw--space-y-500px > :not(template) ~ :not(template) {
  --space-y-reverse: 0;
  margin-top: calc(-500px * calc(1 - var(--space-y-reverse)));
  margin-bottom: calc(-500px * var(--space-y-reverse));
}

.tw--space-x-500px > :not(template) ~ :not(template) {
  --space-x-reverse: 0;
  margin-right: calc(-500px * var(--space-x-reverse));
  margin-left: calc(-500px * calc(1 - var(--space-x-reverse)));
}

.tw--space-y-576px > :not(template) ~ :not(template) {
  --space-y-reverse: 0;
  margin-top: calc(-576px * calc(1 - var(--space-y-reverse)));
  margin-bottom: calc(-576px * var(--space-y-reverse));
}

.tw--space-x-576px > :not(template) ~ :not(template) {
  --space-x-reverse: 0;
  margin-right: calc(-576px * var(--space-x-reverse));
  margin-left: calc(-576px * calc(1 - var(--space-x-reverse)));
}

.tw--space-y-600px > :not(template) ~ :not(template) {
  --space-y-reverse: 0;
  margin-top: calc(-600px * calc(1 - var(--space-y-reverse)));
  margin-bottom: calc(-600px * var(--space-y-reverse));
}

.tw--space-x-600px > :not(template) ~ :not(template) {
  --space-x-reverse: 0;
  margin-right: calc(-600px * var(--space-x-reverse));
  margin-left: calc(-600px * calc(1 - var(--space-x-reverse)));
}

.tw--space-y-672px > :not(template) ~ :not(template) {
  --space-y-reverse: 0;
  margin-top: calc(-672px * calc(1 - var(--space-y-reverse)));
  margin-bottom: calc(-672px * var(--space-y-reverse));
}

.tw--space-x-672px > :not(template) ~ :not(template) {
  --space-x-reverse: 0;
  margin-right: calc(-672px * var(--space-x-reverse));
  margin-left: calc(-672px * calc(1 - var(--space-x-reverse)));
}

.tw-space-y-reverse > :not(template) ~ :not(template) {
  --space-y-reverse: 1;
}

.tw-space-x-reverse > :not(template) ~ :not(template) {
  --space-x-reverse: 1;
}

.tw-divide-y-0 > :not(template) ~ :not(template) {
  --divide-y-reverse: 0;
  border-top-width: calc(0px * calc(1 - var(--divide-y-reverse)));
  border-bottom-width: calc(0px * var(--divide-y-reverse));
}

.tw-divide-x-0 > :not(template) ~ :not(template) {
  --divide-x-reverse: 0;
  border-right-width: calc(0px * var(--divide-x-reverse));
  border-left-width: calc(0px * calc(1 - var(--divide-x-reverse)));
}

.tw-divide-y-2 > :not(template) ~ :not(template) {
  --divide-y-reverse: 0;
  border-top-width: calc(2px * calc(1 - var(--divide-y-reverse)));
  border-bottom-width: calc(2px * var(--divide-y-reverse));
}

.tw-divide-x-2 > :not(template) ~ :not(template) {
  --divide-x-reverse: 0;
  border-right-width: calc(2px * var(--divide-x-reverse));
  border-left-width: calc(2px * calc(1 - var(--divide-x-reverse)));
}

.tw-divide-y-4 > :not(template) ~ :not(template) {
  --divide-y-reverse: 0;
  border-top-width: calc(4px * calc(1 - var(--divide-y-reverse)));
  border-bottom-width: calc(4px * var(--divide-y-reverse));
}

.tw-divide-x-4 > :not(template) ~ :not(template) {
  --divide-x-reverse: 0;
  border-right-width: calc(4px * var(--divide-x-reverse));
  border-left-width: calc(4px * calc(1 - var(--divide-x-reverse)));
}

.tw-divide-y-8 > :not(template) ~ :not(template) {
  --divide-y-reverse: 0;
  border-top-width: calc(8px * calc(1 - var(--divide-y-reverse)));
  border-bottom-width: calc(8px * var(--divide-y-reverse));
}

.tw-divide-x-8 > :not(template) ~ :not(template) {
  --divide-x-reverse: 0;
  border-right-width: calc(8px * var(--divide-x-reverse));
  border-left-width: calc(8px * calc(1 - var(--divide-x-reverse)));
}

.tw-divide-y > :not(template) ~ :not(template) {
  --divide-y-reverse: 0;
  border-top-width: calc(1px * calc(1 - var(--divide-y-reverse)));
  border-bottom-width: calc(1px * var(--divide-y-reverse));
}

.tw-divide-x > :not(template) ~ :not(template) {
  --divide-x-reverse: 0;
  border-right-width: calc(1px * var(--divide-x-reverse));
  border-left-width: calc(1px * calc(1 - var(--divide-x-reverse)));
}

.tw-divide-y-reverse > :not(template) ~ :not(template) {
  --divide-y-reverse: 1;
}

.tw-divide-x-reverse > :not(template) ~ :not(template) {
  --divide-x-reverse: 1;
}

.tw-divide-transparent > :not(template) ~ :not(template) {
  border-color: transparent;
}

.tw-divide-current > :not(template) ~ :not(template) {
  border-color: currentColor;
}

.tw-divide-black > :not(template) ~ :not(template) {
  --divide-opacity: 1;
  border-color: #000;
  border-color: rgba(0, 0, 0, var(--divide-opacity));
}

.tw-divide-white > :not(template) ~ :not(template) {
  --divide-opacity: 1;
  border-color: #fff;
  border-color: rgba(255, 255, 255, var(--divide-opacity));
}

.tw-divide-gray-100 > :not(template) ~ :not(template) {
  --divide-opacity: 1;
  border-color: #f7fafc;
  border-color: rgba(247, 250, 252, var(--divide-opacity));
}

.tw-divide-gray-200 > :not(template) ~ :not(template) {
  --divide-opacity: 1;
  border-color: #edf2f7;
  border-color: rgba(237, 242, 247, var(--divide-opacity));
}

.tw-divide-gray-300 > :not(template) ~ :not(template) {
  --divide-opacity: 1;
  border-color: #e2e8f0;
  border-color: rgba(226, 232, 240, var(--divide-opacity));
}

.tw-divide-gray-400 > :not(template) ~ :not(template) {
  --divide-opacity: 1;
  border-color: #cbd5e0;
  border-color: rgba(203, 213, 224, var(--divide-opacity));
}

.tw-divide-gray-500 > :not(template) ~ :not(template) {
  --divide-opacity: 1;
  border-color: #a0aec0;
  border-color: rgba(160, 174, 192, var(--divide-opacity));
}

.tw-divide-gray-600 > :not(template) ~ :not(template) {
  --divide-opacity: 1;
  border-color: #718096;
  border-color: rgba(113, 128, 150, var(--divide-opacity));
}

.tw-divide-gray-700 > :not(template) ~ :not(template) {
  --divide-opacity: 1;
  border-color: #4a5568;
  border-color: rgba(74, 85, 104, var(--divide-opacity));
}

.tw-divide-gray-800 > :not(template) ~ :not(template) {
  --divide-opacity: 1;
  border-color: #2d3748;
  border-color: rgba(45, 55, 72, var(--divide-opacity));
}

.tw-divide-gray-900 > :not(template) ~ :not(template) {
  --divide-opacity: 1;
  border-color: #1a202c;
  border-color: rgba(26, 32, 44, var(--divide-opacity));
}

.tw-divide-red-100 > :not(template) ~ :not(template) {
  --divide-opacity: 1;
  border-color: #fff5f5;
  border-color: rgba(255, 245, 245, var(--divide-opacity));
}

.tw-divide-red-200 > :not(template) ~ :not(template) {
  --divide-opacity: 1;
  border-color: #fed7d7;
  border-color: rgba(254, 215, 215, var(--divide-opacity));
}

.tw-divide-red-300 > :not(template) ~ :not(template) {
  --divide-opacity: 1;
  border-color: #feb2b2;
  border-color: rgba(254, 178, 178, var(--divide-opacity));
}

.tw-divide-red-400 > :not(template) ~ :not(template) {
  --divide-opacity: 1;
  border-color: #fc8181;
  border-color: rgba(252, 129, 129, var(--divide-opacity));
}

.tw-divide-red-500 > :not(template) ~ :not(template) {
  --divide-opacity: 1;
  border-color: #f56565;
  border-color: rgba(245, 101, 101, var(--divide-opacity));
}

.tw-divide-red-600 > :not(template) ~ :not(template) {
  --divide-opacity: 1;
  border-color: #e53e3e;
  border-color: rgba(229, 62, 62, var(--divide-opacity));
}

.tw-divide-red-700 > :not(template) ~ :not(template) {
  --divide-opacity: 1;
  border-color: #c53030;
  border-color: rgba(197, 48, 48, var(--divide-opacity));
}

.tw-divide-red-800 > :not(template) ~ :not(template) {
  --divide-opacity: 1;
  border-color: #9b2c2c;
  border-color: rgba(155, 44, 44, var(--divide-opacity));
}

.tw-divide-red-900 > :not(template) ~ :not(template) {
  --divide-opacity: 1;
  border-color: #742a2a;
  border-color: rgba(116, 42, 42, var(--divide-opacity));
}

.tw-divide-orange-100 > :not(template) ~ :not(template) {
  --divide-opacity: 1;
  border-color: #fffaf0;
  border-color: rgba(255, 250, 240, var(--divide-opacity));
}

.tw-divide-orange-200 > :not(template) ~ :not(template) {
  --divide-opacity: 1;
  border-color: #feebc8;
  border-color: rgba(254, 235, 200, var(--divide-opacity));
}

.tw-divide-orange-300 > :not(template) ~ :not(template) {
  --divide-opacity: 1;
  border-color: #fbd38d;
  border-color: rgba(251, 211, 141, var(--divide-opacity));
}

.tw-divide-orange-400 > :not(template) ~ :not(template) {
  --divide-opacity: 1;
  border-color: #f6ad55;
  border-color: rgba(246, 173, 85, var(--divide-opacity));
}

.tw-divide-orange-500 > :not(template) ~ :not(template) {
  --divide-opacity: 1;
  border-color: #ed8936;
  border-color: rgba(237, 137, 54, var(--divide-opacity));
}

.tw-divide-orange-600 > :not(template) ~ :not(template) {
  --divide-opacity: 1;
  border-color: #dd6b20;
  border-color: rgba(221, 107, 32, var(--divide-opacity));
}

.tw-divide-orange-700 > :not(template) ~ :not(template) {
  --divide-opacity: 1;
  border-color: #c05621;
  border-color: rgba(192, 86, 33, var(--divide-opacity));
}

.tw-divide-orange-800 > :not(template) ~ :not(template) {
  --divide-opacity: 1;
  border-color: #9c4221;
  border-color: rgba(156, 66, 33, var(--divide-opacity));
}

.tw-divide-orange-900 > :not(template) ~ :not(template) {
  --divide-opacity: 1;
  border-color: #7b341e;
  border-color: rgba(123, 52, 30, var(--divide-opacity));
}

.tw-divide-yellow-100 > :not(template) ~ :not(template) {
  --divide-opacity: 1;
  border-color: #fffff0;
  border-color: rgba(255, 255, 240, var(--divide-opacity));
}

.tw-divide-yellow-200 > :not(template) ~ :not(template) {
  --divide-opacity: 1;
  border-color: #fefcbf;
  border-color: rgba(254, 252, 191, var(--divide-opacity));
}

.tw-divide-yellow-300 > :not(template) ~ :not(template) {
  --divide-opacity: 1;
  border-color: #faf089;
  border-color: rgba(250, 240, 137, var(--divide-opacity));
}

.tw-divide-yellow-400 > :not(template) ~ :not(template) {
  --divide-opacity: 1;
  border-color: #f6e05e;
  border-color: rgba(246, 224, 94, var(--divide-opacity));
}

.tw-divide-yellow-500 > :not(template) ~ :not(template) {
  --divide-opacity: 1;
  border-color: #ecc94b;
  border-color: rgba(236, 201, 75, var(--divide-opacity));
}

.tw-divide-yellow-600 > :not(template) ~ :not(template) {
  --divide-opacity: 1;
  border-color: #d69e2e;
  border-color: rgba(214, 158, 46, var(--divide-opacity));
}

.tw-divide-yellow-700 > :not(template) ~ :not(template) {
  --divide-opacity: 1;
  border-color: #b7791f;
  border-color: rgba(183, 121, 31, var(--divide-opacity));
}

.tw-divide-yellow-800 > :not(template) ~ :not(template) {
  --divide-opacity: 1;
  border-color: #975a16;
  border-color: rgba(151, 90, 22, var(--divide-opacity));
}

.tw-divide-yellow-900 > :not(template) ~ :not(template) {
  --divide-opacity: 1;
  border-color: #744210;
  border-color: rgba(116, 66, 16, var(--divide-opacity));
}

.tw-divide-green-100 > :not(template) ~ :not(template) {
  --divide-opacity: 1;
  border-color: #f0fff4;
  border-color: rgba(240, 255, 244, var(--divide-opacity));
}

.tw-divide-green-200 > :not(template) ~ :not(template) {
  --divide-opacity: 1;
  border-color: #c6f6d5;
  border-color: rgba(198, 246, 213, var(--divide-opacity));
}

.tw-divide-green-300 > :not(template) ~ :not(template) {
  --divide-opacity: 1;
  border-color: #9ae6b4;
  border-color: rgba(154, 230, 180, var(--divide-opacity));
}

.tw-divide-green-400 > :not(template) ~ :not(template) {
  --divide-opacity: 1;
  border-color: #68d391;
  border-color: rgba(104, 211, 145, var(--divide-opacity));
}

.tw-divide-green-500 > :not(template) ~ :not(template) {
  --divide-opacity: 1;
  border-color: #48bb78;
  border-color: rgba(72, 187, 120, var(--divide-opacity));
}

.tw-divide-green-600 > :not(template) ~ :not(template) {
  --divide-opacity: 1;
  border-color: #38a169;
  border-color: rgba(56, 161, 105, var(--divide-opacity));
}

.tw-divide-green-700 > :not(template) ~ :not(template) {
  --divide-opacity: 1;
  border-color: #2f855a;
  border-color: rgba(47, 133, 90, var(--divide-opacity));
}

.tw-divide-green-800 > :not(template) ~ :not(template) {
  --divide-opacity: 1;
  border-color: #276749;
  border-color: rgba(39, 103, 73, var(--divide-opacity));
}

.tw-divide-green-900 > :not(template) ~ :not(template) {
  --divide-opacity: 1;
  border-color: #22543d;
  border-color: rgba(34, 84, 61, var(--divide-opacity));
}

.tw-divide-teal-100 > :not(template) ~ :not(template) {
  --divide-opacity: 1;
  border-color: #e6fffa;
  border-color: rgba(230, 255, 250, var(--divide-opacity));
}

.tw-divide-teal-200 > :not(template) ~ :not(template) {
  --divide-opacity: 1;
  border-color: #b2f5ea;
  border-color: rgba(178, 245, 234, var(--divide-opacity));
}

.tw-divide-teal-300 > :not(template) ~ :not(template) {
  --divide-opacity: 1;
  border-color: #81e6d9;
  border-color: rgba(129, 230, 217, var(--divide-opacity));
}

.tw-divide-teal-400 > :not(template) ~ :not(template) {
  --divide-opacity: 1;
  border-color: #4fd1c5;
  border-color: rgba(79, 209, 197, var(--divide-opacity));
}

.tw-divide-teal-500 > :not(template) ~ :not(template) {
  --divide-opacity: 1;
  border-color: #38b2ac;
  border-color: rgba(56, 178, 172, var(--divide-opacity));
}

.tw-divide-teal-600 > :not(template) ~ :not(template) {
  --divide-opacity: 1;
  border-color: #319795;
  border-color: rgba(49, 151, 149, var(--divide-opacity));
}

.tw-divide-teal-700 > :not(template) ~ :not(template) {
  --divide-opacity: 1;
  border-color: #2c7a7b;
  border-color: rgba(44, 122, 123, var(--divide-opacity));
}

.tw-divide-teal-800 > :not(template) ~ :not(template) {
  --divide-opacity: 1;
  border-color: #285e61;
  border-color: rgba(40, 94, 97, var(--divide-opacity));
}

.tw-divide-teal-900 > :not(template) ~ :not(template) {
  --divide-opacity: 1;
  border-color: #234e52;
  border-color: rgba(35, 78, 82, var(--divide-opacity));
}

.tw-divide-blue-100 > :not(template) ~ :not(template) {
  --divide-opacity: 1;
  border-color: #ebf8ff;
  border-color: rgba(235, 248, 255, var(--divide-opacity));
}

.tw-divide-blue-200 > :not(template) ~ :not(template) {
  --divide-opacity: 1;
  border-color: #bee3f8;
  border-color: rgba(190, 227, 248, var(--divide-opacity));
}

.tw-divide-blue-300 > :not(template) ~ :not(template) {
  --divide-opacity: 1;
  border-color: #90cdf4;
  border-color: rgba(144, 205, 244, var(--divide-opacity));
}

.tw-divide-blue-400 > :not(template) ~ :not(template) {
  --divide-opacity: 1;
  border-color: #63b3ed;
  border-color: rgba(99, 179, 237, var(--divide-opacity));
}

.tw-divide-blue-500 > :not(template) ~ :not(template) {
  --divide-opacity: 1;
  border-color: #4299e1;
  border-color: rgba(66, 153, 225, var(--divide-opacity));
}

.tw-divide-blue-600 > :not(template) ~ :not(template) {
  --divide-opacity: 1;
  border-color: #3182ce;
  border-color: rgba(49, 130, 206, var(--divide-opacity));
}

.tw-divide-blue-700 > :not(template) ~ :not(template) {
  --divide-opacity: 1;
  border-color: #2b6cb0;
  border-color: rgba(43, 108, 176, var(--divide-opacity));
}

.tw-divide-blue-800 > :not(template) ~ :not(template) {
  --divide-opacity: 1;
  border-color: #2c5282;
  border-color: rgba(44, 82, 130, var(--divide-opacity));
}

.tw-divide-blue-900 > :not(template) ~ :not(template) {
  --divide-opacity: 1;
  border-color: #2a4365;
  border-color: rgba(42, 67, 101, var(--divide-opacity));
}

.tw-divide-indigo-100 > :not(template) ~ :not(template) {
  --divide-opacity: 1;
  border-color: #ebf4ff;
  border-color: rgba(235, 244, 255, var(--divide-opacity));
}

.tw-divide-indigo-200 > :not(template) ~ :not(template) {
  --divide-opacity: 1;
  border-color: #c3dafe;
  border-color: rgba(195, 218, 254, var(--divide-opacity));
}

.tw-divide-indigo-300 > :not(template) ~ :not(template) {
  --divide-opacity: 1;
  border-color: #a3bffa;
  border-color: rgba(163, 191, 250, var(--divide-opacity));
}

.tw-divide-indigo-400 > :not(template) ~ :not(template) {
  --divide-opacity: 1;
  border-color: #7f9cf5;
  border-color: rgba(127, 156, 245, var(--divide-opacity));
}

.tw-divide-indigo-500 > :not(template) ~ :not(template) {
  --divide-opacity: 1;
  border-color: #667eea;
  border-color: rgba(102, 126, 234, var(--divide-opacity));
}

.tw-divide-indigo-600 > :not(template) ~ :not(template) {
  --divide-opacity: 1;
  border-color: #5a67d8;
  border-color: rgba(90, 103, 216, var(--divide-opacity));
}

.tw-divide-indigo-700 > :not(template) ~ :not(template) {
  --divide-opacity: 1;
  border-color: #4c51bf;
  border-color: rgba(76, 81, 191, var(--divide-opacity));
}

.tw-divide-indigo-800 > :not(template) ~ :not(template) {
  --divide-opacity: 1;
  border-color: #434190;
  border-color: rgba(67, 65, 144, var(--divide-opacity));
}

.tw-divide-indigo-900 > :not(template) ~ :not(template) {
  --divide-opacity: 1;
  border-color: #3c366b;
  border-color: rgba(60, 54, 107, var(--divide-opacity));
}

.tw-divide-purple-100 > :not(template) ~ :not(template) {
  --divide-opacity: 1;
  border-color: #faf5ff;
  border-color: rgba(250, 245, 255, var(--divide-opacity));
}

.tw-divide-purple-200 > :not(template) ~ :not(template) {
  --divide-opacity: 1;
  border-color: #e9d8fd;
  border-color: rgba(233, 216, 253, var(--divide-opacity));
}

.tw-divide-purple-300 > :not(template) ~ :not(template) {
  --divide-opacity: 1;
  border-color: #d6bcfa;
  border-color: rgba(214, 188, 250, var(--divide-opacity));
}

.tw-divide-purple-400 > :not(template) ~ :not(template) {
  --divide-opacity: 1;
  border-color: #b794f4;
  border-color: rgba(183, 148, 244, var(--divide-opacity));
}

.tw-divide-purple-500 > :not(template) ~ :not(template) {
  --divide-opacity: 1;
  border-color: #9f7aea;
  border-color: rgba(159, 122, 234, var(--divide-opacity));
}

.tw-divide-purple-600 > :not(template) ~ :not(template) {
  --divide-opacity: 1;
  border-color: #805ad5;
  border-color: rgba(128, 90, 213, var(--divide-opacity));
}

.tw-divide-purple-700 > :not(template) ~ :not(template) {
  --divide-opacity: 1;
  border-color: #6b46c1;
  border-color: rgba(107, 70, 193, var(--divide-opacity));
}

.tw-divide-purple-800 > :not(template) ~ :not(template) {
  --divide-opacity: 1;
  border-color: #553c9a;
  border-color: rgba(85, 60, 154, var(--divide-opacity));
}

.tw-divide-purple-900 > :not(template) ~ :not(template) {
  --divide-opacity: 1;
  border-color: #44337a;
  border-color: rgba(68, 51, 122, var(--divide-opacity));
}

.tw-divide-pink-100 > :not(template) ~ :not(template) {
  --divide-opacity: 1;
  border-color: #fff5f7;
  border-color: rgba(255, 245, 247, var(--divide-opacity));
}

.tw-divide-pink-200 > :not(template) ~ :not(template) {
  --divide-opacity: 1;
  border-color: #fed7e2;
  border-color: rgba(254, 215, 226, var(--divide-opacity));
}

.tw-divide-pink-300 > :not(template) ~ :not(template) {
  --divide-opacity: 1;
  border-color: #fbb6ce;
  border-color: rgba(251, 182, 206, var(--divide-opacity));
}

.tw-divide-pink-400 > :not(template) ~ :not(template) {
  --divide-opacity: 1;
  border-color: #f687b3;
  border-color: rgba(246, 135, 179, var(--divide-opacity));
}

.tw-divide-pink-500 > :not(template) ~ :not(template) {
  --divide-opacity: 1;
  border-color: #ed64a6;
  border-color: rgba(237, 100, 166, var(--divide-opacity));
}

.tw-divide-pink-600 > :not(template) ~ :not(template) {
  --divide-opacity: 1;
  border-color: #d53f8c;
  border-color: rgba(213, 63, 140, var(--divide-opacity));
}

.tw-divide-pink-700 > :not(template) ~ :not(template) {
  --divide-opacity: 1;
  border-color: #b83280;
  border-color: rgba(184, 50, 128, var(--divide-opacity));
}

.tw-divide-pink-800 > :not(template) ~ :not(template) {
  --divide-opacity: 1;
  border-color: #97266d;
  border-color: rgba(151, 38, 109, var(--divide-opacity));
}

.tw-divide-pink-900 > :not(template) ~ :not(template) {
  --divide-opacity: 1;
  border-color: #702459;
  border-color: rgba(112, 36, 89, var(--divide-opacity));
}

.tw-divide-solid > :not(template) ~ :not(template) {
  border-style: solid;
}

.tw-divide-dashed > :not(template) ~ :not(template) {
  border-style: dashed;
}

.tw-divide-dotted > :not(template) ~ :not(template) {
  border-style: dotted;
}

.tw-divide-double > :not(template) ~ :not(template) {
  border-style: double;
}

.tw-divide-none > :not(template) ~ :not(template) {
  border-style: none;
}

.tw-divide-opacity-0 > :not(template) ~ :not(template) {
  --divide-opacity: 0;
}

.tw-divide-opacity-25 > :not(template) ~ :not(template) {
  --divide-opacity: 0.25;
}

.tw-divide-opacity-50 > :not(template) ~ :not(template) {
  --divide-opacity: 0.5;
}

.tw-divide-opacity-75 > :not(template) ~ :not(template) {
  --divide-opacity: 0.75;
}

.tw-divide-opacity-100 > :not(template) ~ :not(template) {
  --divide-opacity: 1;
}

.tw-sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border-width: 0;
}

.tw-not-sr-only {
  position: static;
  width: auto;
  height: auto;
  padding: 0;
  margin: 0;
  overflow: visible;
  clip: auto;
  white-space: normal;
}

.focus\:tw-sr-only:focus {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border-width: 0;
}

.focus\:tw-not-sr-only:focus {
  position: static;
  width: auto;
  height: auto;
  padding: 0;
  margin: 0;
  overflow: visible;
  clip: auto;
  white-space: normal;
}

.tw-appearance-none {
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
}

.tw-bg-fixed {
  background-attachment: fixed;
}

.tw-bg-local {
  background-attachment: local;
}

.tw-bg-scroll {
  background-attachment: scroll;
}

.tw-bg-clip-border {
  background-clip: border-box;
}

.tw-bg-clip-padding {
  background-clip: padding-box;
}

.tw-bg-clip-content {
  background-clip: content-box;
}

.tw-bg-clip-text {
  -webkit-background-clip: text;
          background-clip: text;
}

.tw-bg-transparent {
  background-color: transparent;
}

.tw-bg-current {
  background-color: currentColor;
}

.tw-bg-black {
  --bg-opacity: 1;
  background-color: #000;
  background-color: rgba(0, 0, 0, var(--bg-opacity));
}

.tw-bg-white {
  --bg-opacity: 1;
  background-color: #fff;
  background-color: rgba(255, 255, 255, var(--bg-opacity));
}

.tw-bg-gray-100 {
  --bg-opacity: 1;
  background-color: #f7fafc;
  background-color: rgba(247, 250, 252, var(--bg-opacity));
}

.tw-bg-gray-200 {
  --bg-opacity: 1;
  background-color: #edf2f7;
  background-color: rgba(237, 242, 247, var(--bg-opacity));
}

.tw-bg-gray-300 {
  --bg-opacity: 1;
  background-color: #e2e8f0;
  background-color: rgba(226, 232, 240, var(--bg-opacity));
}

.tw-bg-gray-400 {
  --bg-opacity: 1;
  background-color: #cbd5e0;
  background-color: rgba(203, 213, 224, var(--bg-opacity));
}

.tw-bg-gray-500 {
  --bg-opacity: 1;
  background-color: #a0aec0;
  background-color: rgba(160, 174, 192, var(--bg-opacity));
}

.tw-bg-gray-600 {
  --bg-opacity: 1;
  background-color: #718096;
  background-color: rgba(113, 128, 150, var(--bg-opacity));
}

.tw-bg-gray-700 {
  --bg-opacity: 1;
  background-color: #4a5568;
  background-color: rgba(74, 85, 104, var(--bg-opacity));
}

.tw-bg-gray-800 {
  --bg-opacity: 1;
  background-color: #2d3748;
  background-color: rgba(45, 55, 72, var(--bg-opacity));
}

.tw-bg-gray-900 {
  --bg-opacity: 1;
  background-color: #1a202c;
  background-color: rgba(26, 32, 44, var(--bg-opacity));
}

.tw-bg-red-100 {
  --bg-opacity: 1;
  background-color: #fff5f5;
  background-color: rgba(255, 245, 245, var(--bg-opacity));
}

.tw-bg-red-200 {
  --bg-opacity: 1;
  background-color: #fed7d7;
  background-color: rgba(254, 215, 215, var(--bg-opacity));
}

.tw-bg-red-300 {
  --bg-opacity: 1;
  background-color: #feb2b2;
  background-color: rgba(254, 178, 178, var(--bg-opacity));
}

.tw-bg-red-400 {
  --bg-opacity: 1;
  background-color: #fc8181;
  background-color: rgba(252, 129, 129, var(--bg-opacity));
}

.tw-bg-red-500 {
  --bg-opacity: 1;
  background-color: #f56565;
  background-color: rgba(245, 101, 101, var(--bg-opacity));
}

.tw-bg-red-600 {
  --bg-opacity: 1;
  background-color: #e53e3e;
  background-color: rgba(229, 62, 62, var(--bg-opacity));
}

.tw-bg-red-700 {
  --bg-opacity: 1;
  background-color: #c53030;
  background-color: rgba(197, 48, 48, var(--bg-opacity));
}

.tw-bg-red-800 {
  --bg-opacity: 1;
  background-color: #9b2c2c;
  background-color: rgba(155, 44, 44, var(--bg-opacity));
}

.tw-bg-red-900 {
  --bg-opacity: 1;
  background-color: #742a2a;
  background-color: rgba(116, 42, 42, var(--bg-opacity));
}

.tw-bg-orange-100 {
  --bg-opacity: 1;
  background-color: #fffaf0;
  background-color: rgba(255, 250, 240, var(--bg-opacity));
}

.tw-bg-orange-200 {
  --bg-opacity: 1;
  background-color: #feebc8;
  background-color: rgba(254, 235, 200, var(--bg-opacity));
}

.tw-bg-orange-300 {
  --bg-opacity: 1;
  background-color: #fbd38d;
  background-color: rgba(251, 211, 141, var(--bg-opacity));
}

.tw-bg-orange-400 {
  --bg-opacity: 1;
  background-color: #f6ad55;
  background-color: rgba(246, 173, 85, var(--bg-opacity));
}

.tw-bg-orange-500 {
  --bg-opacity: 1;
  background-color: #ed8936;
  background-color: rgba(237, 137, 54, var(--bg-opacity));
}

.tw-bg-orange-600 {
  --bg-opacity: 1;
  background-color: #dd6b20;
  background-color: rgba(221, 107, 32, var(--bg-opacity));
}

.tw-bg-orange-700 {
  --bg-opacity: 1;
  background-color: #c05621;
  background-color: rgba(192, 86, 33, var(--bg-opacity));
}

.tw-bg-orange-800 {
  --bg-opacity: 1;
  background-color: #9c4221;
  background-color: rgba(156, 66, 33, var(--bg-opacity));
}

.tw-bg-orange-900 {
  --bg-opacity: 1;
  background-color: #7b341e;
  background-color: rgba(123, 52, 30, var(--bg-opacity));
}

.tw-bg-yellow-100 {
  --bg-opacity: 1;
  background-color: #fffff0;
  background-color: rgba(255, 255, 240, var(--bg-opacity));
}

.tw-bg-yellow-200 {
  --bg-opacity: 1;
  background-color: #fefcbf;
  background-color: rgba(254, 252, 191, var(--bg-opacity));
}

.tw-bg-yellow-300 {
  --bg-opacity: 1;
  background-color: #faf089;
  background-color: rgba(250, 240, 137, var(--bg-opacity));
}

.tw-bg-yellow-400 {
  --bg-opacity: 1;
  background-color: #f6e05e;
  background-color: rgba(246, 224, 94, var(--bg-opacity));
}

.tw-bg-yellow-500 {
  --bg-opacity: 1;
  background-color: #ecc94b;
  background-color: rgba(236, 201, 75, var(--bg-opacity));
}

.tw-bg-yellow-600 {
  --bg-opacity: 1;
  background-color: #d69e2e;
  background-color: rgba(214, 158, 46, var(--bg-opacity));
}

.tw-bg-yellow-700 {
  --bg-opacity: 1;
  background-color: #b7791f;
  background-color: rgba(183, 121, 31, var(--bg-opacity));
}

.tw-bg-yellow-800 {
  --bg-opacity: 1;
  background-color: #975a16;
  background-color: rgba(151, 90, 22, var(--bg-opacity));
}

.tw-bg-yellow-900 {
  --bg-opacity: 1;
  background-color: #744210;
  background-color: rgba(116, 66, 16, var(--bg-opacity));
}

.tw-bg-green-100 {
  --bg-opacity: 1;
  background-color: #f0fff4;
  background-color: rgba(240, 255, 244, var(--bg-opacity));
}

.tw-bg-green-200 {
  --bg-opacity: 1;
  background-color: #c6f6d5;
  background-color: rgba(198, 246, 213, var(--bg-opacity));
}

.tw-bg-green-300 {
  --bg-opacity: 1;
  background-color: #9ae6b4;
  background-color: rgba(154, 230, 180, var(--bg-opacity));
}

.tw-bg-green-400 {
  --bg-opacity: 1;
  background-color: #68d391;
  background-color: rgba(104, 211, 145, var(--bg-opacity));
}

.tw-bg-green-500 {
  --bg-opacity: 1;
  background-color: #48bb78;
  background-color: rgba(72, 187, 120, var(--bg-opacity));
}

.tw-bg-green-600 {
  --bg-opacity: 1;
  background-color: #38a169;
  background-color: rgba(56, 161, 105, var(--bg-opacity));
}

.tw-bg-green-700 {
  --bg-opacity: 1;
  background-color: #2f855a;
  background-color: rgba(47, 133, 90, var(--bg-opacity));
}

.tw-bg-green-800 {
  --bg-opacity: 1;
  background-color: #276749;
  background-color: rgba(39, 103, 73, var(--bg-opacity));
}

.tw-bg-green-900 {
  --bg-opacity: 1;
  background-color: #22543d;
  background-color: rgba(34, 84, 61, var(--bg-opacity));
}

.tw-bg-teal-100 {
  --bg-opacity: 1;
  background-color: #e6fffa;
  background-color: rgba(230, 255, 250, var(--bg-opacity));
}

.tw-bg-teal-200 {
  --bg-opacity: 1;
  background-color: #b2f5ea;
  background-color: rgba(178, 245, 234, var(--bg-opacity));
}

.tw-bg-teal-300 {
  --bg-opacity: 1;
  background-color: #81e6d9;
  background-color: rgba(129, 230, 217, var(--bg-opacity));
}

.tw-bg-teal-400 {
  --bg-opacity: 1;
  background-color: #4fd1c5;
  background-color: rgba(79, 209, 197, var(--bg-opacity));
}

.tw-bg-teal-500 {
  --bg-opacity: 1;
  background-color: #38b2ac;
  background-color: rgba(56, 178, 172, var(--bg-opacity));
}

.tw-bg-teal-600 {
  --bg-opacity: 1;
  background-color: #319795;
  background-color: rgba(49, 151, 149, var(--bg-opacity));
}

.tw-bg-teal-700 {
  --bg-opacity: 1;
  background-color: #2c7a7b;
  background-color: rgba(44, 122, 123, var(--bg-opacity));
}

.tw-bg-teal-800 {
  --bg-opacity: 1;
  background-color: #285e61;
  background-color: rgba(40, 94, 97, var(--bg-opacity));
}

.tw-bg-teal-900 {
  --bg-opacity: 1;
  background-color: #234e52;
  background-color: rgba(35, 78, 82, var(--bg-opacity));
}

.tw-bg-blue-100 {
  --bg-opacity: 1;
  background-color: #ebf8ff;
  background-color: rgba(235, 248, 255, var(--bg-opacity));
}

.tw-bg-blue-200 {
  --bg-opacity: 1;
  background-color: #bee3f8;
  background-color: rgba(190, 227, 248, var(--bg-opacity));
}

.tw-bg-blue-300 {
  --bg-opacity: 1;
  background-color: #90cdf4;
  background-color: rgba(144, 205, 244, var(--bg-opacity));
}

.tw-bg-blue-400 {
  --bg-opacity: 1;
  background-color: #63b3ed;
  background-color: rgba(99, 179, 237, var(--bg-opacity));
}

.tw-bg-blue-500 {
  --bg-opacity: 1;
  background-color: #4299e1;
  background-color: rgba(66, 153, 225, var(--bg-opacity));
}

.tw-bg-blue-600 {
  --bg-opacity: 1;
  background-color: #3182ce;
  background-color: rgba(49, 130, 206, var(--bg-opacity));
}

.tw-bg-blue-700 {
  --bg-opacity: 1;
  background-color: #2b6cb0;
  background-color: rgba(43, 108, 176, var(--bg-opacity));
}

.tw-bg-blue-800 {
  --bg-opacity: 1;
  background-color: #2c5282;
  background-color: rgba(44, 82, 130, var(--bg-opacity));
}

.tw-bg-blue-900 {
  --bg-opacity: 1;
  background-color: #2a4365;
  background-color: rgba(42, 67, 101, var(--bg-opacity));
}

.tw-bg-indigo-100 {
  --bg-opacity: 1;
  background-color: #ebf4ff;
  background-color: rgba(235, 244, 255, var(--bg-opacity));
}

.tw-bg-indigo-200 {
  --bg-opacity: 1;
  background-color: #c3dafe;
  background-color: rgba(195, 218, 254, var(--bg-opacity));
}

.tw-bg-indigo-300 {
  --bg-opacity: 1;
  background-color: #a3bffa;
  background-color: rgba(163, 191, 250, var(--bg-opacity));
}

.tw-bg-indigo-400 {
  --bg-opacity: 1;
  background-color: #7f9cf5;
  background-color: rgba(127, 156, 245, var(--bg-opacity));
}

.tw-bg-indigo-500 {
  --bg-opacity: 1;
  background-color: #667eea;
  background-color: rgba(102, 126, 234, var(--bg-opacity));
}

.tw-bg-indigo-600 {
  --bg-opacity: 1;
  background-color: #5a67d8;
  background-color: rgba(90, 103, 216, var(--bg-opacity));
}

.tw-bg-indigo-700 {
  --bg-opacity: 1;
  background-color: #4c51bf;
  background-color: rgba(76, 81, 191, var(--bg-opacity));
}

.tw-bg-indigo-800 {
  --bg-opacity: 1;
  background-color: #434190;
  background-color: rgba(67, 65, 144, var(--bg-opacity));
}

.tw-bg-indigo-900 {
  --bg-opacity: 1;
  background-color: #3c366b;
  background-color: rgba(60, 54, 107, var(--bg-opacity));
}

.tw-bg-purple-100 {
  --bg-opacity: 1;
  background-color: #faf5ff;
  background-color: rgba(250, 245, 255, var(--bg-opacity));
}

.tw-bg-purple-200 {
  --bg-opacity: 1;
  background-color: #e9d8fd;
  background-color: rgba(233, 216, 253, var(--bg-opacity));
}

.tw-bg-purple-300 {
  --bg-opacity: 1;
  background-color: #d6bcfa;
  background-color: rgba(214, 188, 250, var(--bg-opacity));
}

.tw-bg-purple-400 {
  --bg-opacity: 1;
  background-color: #b794f4;
  background-color: rgba(183, 148, 244, var(--bg-opacity));
}

.tw-bg-purple-500 {
  --bg-opacity: 1;
  background-color: #9f7aea;
  background-color: rgba(159, 122, 234, var(--bg-opacity));
}

.tw-bg-purple-600 {
  --bg-opacity: 1;
  background-color: #805ad5;
  background-color: rgba(128, 90, 213, var(--bg-opacity));
}

.tw-bg-purple-700 {
  --bg-opacity: 1;
  background-color: #6b46c1;
  background-color: rgba(107, 70, 193, var(--bg-opacity));
}

.tw-bg-purple-800 {
  --bg-opacity: 1;
  background-color: #553c9a;
  background-color: rgba(85, 60, 154, var(--bg-opacity));
}

.tw-bg-purple-900 {
  --bg-opacity: 1;
  background-color: #44337a;
  background-color: rgba(68, 51, 122, var(--bg-opacity));
}

.tw-bg-pink-100 {
  --bg-opacity: 1;
  background-color: #fff5f7;
  background-color: rgba(255, 245, 247, var(--bg-opacity));
}

.tw-bg-pink-200 {
  --bg-opacity: 1;
  background-color: #fed7e2;
  background-color: rgba(254, 215, 226, var(--bg-opacity));
}

.tw-bg-pink-300 {
  --bg-opacity: 1;
  background-color: #fbb6ce;
  background-color: rgba(251, 182, 206, var(--bg-opacity));
}

.tw-bg-pink-400 {
  --bg-opacity: 1;
  background-color: #f687b3;
  background-color: rgba(246, 135, 179, var(--bg-opacity));
}

.tw-bg-pink-500 {
  --bg-opacity: 1;
  background-color: #ed64a6;
  background-color: rgba(237, 100, 166, var(--bg-opacity));
}

.tw-bg-pink-600 {
  --bg-opacity: 1;
  background-color: #d53f8c;
  background-color: rgba(213, 63, 140, var(--bg-opacity));
}

.tw-bg-pink-700 {
  --bg-opacity: 1;
  background-color: #b83280;
  background-color: rgba(184, 50, 128, var(--bg-opacity));
}

.tw-bg-pink-800 {
  --bg-opacity: 1;
  background-color: #97266d;
  background-color: rgba(151, 38, 109, var(--bg-opacity));
}

.tw-bg-pink-900 {
  --bg-opacity: 1;
  background-color: #702459;
  background-color: rgba(112, 36, 89, var(--bg-opacity));
}

.hover\:tw-bg-transparent:hover {
  background-color: transparent;
}

.hover\:tw-bg-current:hover {
  background-color: currentColor;
}

.hover\:tw-bg-black:hover {
  --bg-opacity: 1;
  background-color: #000;
  background-color: rgba(0, 0, 0, var(--bg-opacity));
}

.hover\:tw-bg-white:hover {
  --bg-opacity: 1;
  background-color: #fff;
  background-color: rgba(255, 255, 255, var(--bg-opacity));
}

.hover\:tw-bg-gray-100:hover {
  --bg-opacity: 1;
  background-color: #f7fafc;
  background-color: rgba(247, 250, 252, var(--bg-opacity));
}

.hover\:tw-bg-gray-200:hover {
  --bg-opacity: 1;
  background-color: #edf2f7;
  background-color: rgba(237, 242, 247, var(--bg-opacity));
}

.hover\:tw-bg-gray-300:hover {
  --bg-opacity: 1;
  background-color: #e2e8f0;
  background-color: rgba(226, 232, 240, var(--bg-opacity));
}

.hover\:tw-bg-gray-400:hover {
  --bg-opacity: 1;
  background-color: #cbd5e0;
  background-color: rgba(203, 213, 224, var(--bg-opacity));
}

.hover\:tw-bg-gray-500:hover {
  --bg-opacity: 1;
  background-color: #a0aec0;
  background-color: rgba(160, 174, 192, var(--bg-opacity));
}

.hover\:tw-bg-gray-600:hover {
  --bg-opacity: 1;
  background-color: #718096;
  background-color: rgba(113, 128, 150, var(--bg-opacity));
}

.hover\:tw-bg-gray-700:hover {
  --bg-opacity: 1;
  background-color: #4a5568;
  background-color: rgba(74, 85, 104, var(--bg-opacity));
}

.hover\:tw-bg-gray-800:hover {
  --bg-opacity: 1;
  background-color: #2d3748;
  background-color: rgba(45, 55, 72, var(--bg-opacity));
}

.hover\:tw-bg-gray-900:hover {
  --bg-opacity: 1;
  background-color: #1a202c;
  background-color: rgba(26, 32, 44, var(--bg-opacity));
}

.hover\:tw-bg-red-100:hover {
  --bg-opacity: 1;
  background-color: #fff5f5;
  background-color: rgba(255, 245, 245, var(--bg-opacity));
}

.hover\:tw-bg-red-200:hover {
  --bg-opacity: 1;
  background-color: #fed7d7;
  background-color: rgba(254, 215, 215, var(--bg-opacity));
}

.hover\:tw-bg-red-300:hover {
  --bg-opacity: 1;
  background-color: #feb2b2;
  background-color: rgba(254, 178, 178, var(--bg-opacity));
}

.hover\:tw-bg-red-400:hover {
  --bg-opacity: 1;
  background-color: #fc8181;
  background-color: rgba(252, 129, 129, var(--bg-opacity));
}

.hover\:tw-bg-red-500:hover {
  --bg-opacity: 1;
  background-color: #f56565;
  background-color: rgba(245, 101, 101, var(--bg-opacity));
}

.hover\:tw-bg-red-600:hover {
  --bg-opacity: 1;
  background-color: #e53e3e;
  background-color: rgba(229, 62, 62, var(--bg-opacity));
}

.hover\:tw-bg-red-700:hover {
  --bg-opacity: 1;
  background-color: #c53030;
  background-color: rgba(197, 48, 48, var(--bg-opacity));
}

.hover\:tw-bg-red-800:hover {
  --bg-opacity: 1;
  background-color: #9b2c2c;
  background-color: rgba(155, 44, 44, var(--bg-opacity));
}

.hover\:tw-bg-red-900:hover {
  --bg-opacity: 1;
  background-color: #742a2a;
  background-color: rgba(116, 42, 42, var(--bg-opacity));
}

.hover\:tw-bg-orange-100:hover {
  --bg-opacity: 1;
  background-color: #fffaf0;
  background-color: rgba(255, 250, 240, var(--bg-opacity));
}

.hover\:tw-bg-orange-200:hover {
  --bg-opacity: 1;
  background-color: #feebc8;
  background-color: rgba(254, 235, 200, var(--bg-opacity));
}

.hover\:tw-bg-orange-300:hover {
  --bg-opacity: 1;
  background-color: #fbd38d;
  background-color: rgba(251, 211, 141, var(--bg-opacity));
}

.hover\:tw-bg-orange-400:hover {
  --bg-opacity: 1;
  background-color: #f6ad55;
  background-color: rgba(246, 173, 85, var(--bg-opacity));
}

.hover\:tw-bg-orange-500:hover {
  --bg-opacity: 1;
  background-color: #ed8936;
  background-color: rgba(237, 137, 54, var(--bg-opacity));
}

.hover\:tw-bg-orange-600:hover {
  --bg-opacity: 1;
  background-color: #dd6b20;
  background-color: rgba(221, 107, 32, var(--bg-opacity));
}

.hover\:tw-bg-orange-700:hover {
  --bg-opacity: 1;
  background-color: #c05621;
  background-color: rgba(192, 86, 33, var(--bg-opacity));
}

.hover\:tw-bg-orange-800:hover {
  --bg-opacity: 1;
  background-color: #9c4221;
  background-color: rgba(156, 66, 33, var(--bg-opacity));
}

.hover\:tw-bg-orange-900:hover {
  --bg-opacity: 1;
  background-color: #7b341e;
  background-color: rgba(123, 52, 30, var(--bg-opacity));
}

.hover\:tw-bg-yellow-100:hover {
  --bg-opacity: 1;
  background-color: #fffff0;
  background-color: rgba(255, 255, 240, var(--bg-opacity));
}

.hover\:tw-bg-yellow-200:hover {
  --bg-opacity: 1;
  background-color: #fefcbf;
  background-color: rgba(254, 252, 191, var(--bg-opacity));
}

.hover\:tw-bg-yellow-300:hover {
  --bg-opacity: 1;
  background-color: #faf089;
  background-color: rgba(250, 240, 137, var(--bg-opacity));
}

.hover\:tw-bg-yellow-400:hover {
  --bg-opacity: 1;
  background-color: #f6e05e;
  background-color: rgba(246, 224, 94, var(--bg-opacity));
}

.hover\:tw-bg-yellow-500:hover {
  --bg-opacity: 1;
  background-color: #ecc94b;
  background-color: rgba(236, 201, 75, var(--bg-opacity));
}

.hover\:tw-bg-yellow-600:hover {
  --bg-opacity: 1;
  background-color: #d69e2e;
  background-color: rgba(214, 158, 46, var(--bg-opacity));
}

.hover\:tw-bg-yellow-700:hover {
  --bg-opacity: 1;
  background-color: #b7791f;
  background-color: rgba(183, 121, 31, var(--bg-opacity));
}

.hover\:tw-bg-yellow-800:hover {
  --bg-opacity: 1;
  background-color: #975a16;
  background-color: rgba(151, 90, 22, var(--bg-opacity));
}

.hover\:tw-bg-yellow-900:hover {
  --bg-opacity: 1;
  background-color: #744210;
  background-color: rgba(116, 66, 16, var(--bg-opacity));
}

.hover\:tw-bg-green-100:hover {
  --bg-opacity: 1;
  background-color: #f0fff4;
  background-color: rgba(240, 255, 244, var(--bg-opacity));
}

.hover\:tw-bg-green-200:hover {
  --bg-opacity: 1;
  background-color: #c6f6d5;
  background-color: rgba(198, 246, 213, var(--bg-opacity));
}

.hover\:tw-bg-green-300:hover {
  --bg-opacity: 1;
  background-color: #9ae6b4;
  background-color: rgba(154, 230, 180, var(--bg-opacity));
}

.hover\:tw-bg-green-400:hover {
  --bg-opacity: 1;
  background-color: #68d391;
  background-color: rgba(104, 211, 145, var(--bg-opacity));
}

.hover\:tw-bg-green-500:hover {
  --bg-opacity: 1;
  background-color: #48bb78;
  background-color: rgba(72, 187, 120, var(--bg-opacity));
}

.hover\:tw-bg-green-600:hover {
  --bg-opacity: 1;
  background-color: #38a169;
  background-color: rgba(56, 161, 105, var(--bg-opacity));
}

.hover\:tw-bg-green-700:hover {
  --bg-opacity: 1;
  background-color: #2f855a;
  background-color: rgba(47, 133, 90, var(--bg-opacity));
}

.hover\:tw-bg-green-800:hover {
  --bg-opacity: 1;
  background-color: #276749;
  background-color: rgba(39, 103, 73, var(--bg-opacity));
}

.hover\:tw-bg-green-900:hover {
  --bg-opacity: 1;
  background-color: #22543d;
  background-color: rgba(34, 84, 61, var(--bg-opacity));
}

.hover\:tw-bg-teal-100:hover {
  --bg-opacity: 1;
  background-color: #e6fffa;
  background-color: rgba(230, 255, 250, var(--bg-opacity));
}

.hover\:tw-bg-teal-200:hover {
  --bg-opacity: 1;
  background-color: #b2f5ea;
  background-color: rgba(178, 245, 234, var(--bg-opacity));
}

.hover\:tw-bg-teal-300:hover {
  --bg-opacity: 1;
  background-color: #81e6d9;
  background-color: rgba(129, 230, 217, var(--bg-opacity));
}

.hover\:tw-bg-teal-400:hover {
  --bg-opacity: 1;
  background-color: #4fd1c5;
  background-color: rgba(79, 209, 197, var(--bg-opacity));
}

.hover\:tw-bg-teal-500:hover {
  --bg-opacity: 1;
  background-color: #38b2ac;
  background-color: rgba(56, 178, 172, var(--bg-opacity));
}

.hover\:tw-bg-teal-600:hover {
  --bg-opacity: 1;
  background-color: #319795;
  background-color: rgba(49, 151, 149, var(--bg-opacity));
}

.hover\:tw-bg-teal-700:hover {
  --bg-opacity: 1;
  background-color: #2c7a7b;
  background-color: rgba(44, 122, 123, var(--bg-opacity));
}

.hover\:tw-bg-teal-800:hover {
  --bg-opacity: 1;
  background-color: #285e61;
  background-color: rgba(40, 94, 97, var(--bg-opacity));
}

.hover\:tw-bg-teal-900:hover {
  --bg-opacity: 1;
  background-color: #234e52;
  background-color: rgba(35, 78, 82, var(--bg-opacity));
}

.hover\:tw-bg-blue-100:hover {
  --bg-opacity: 1;
  background-color: #ebf8ff;
  background-color: rgba(235, 248, 255, var(--bg-opacity));
}

.hover\:tw-bg-blue-200:hover {
  --bg-opacity: 1;
  background-color: #bee3f8;
  background-color: rgba(190, 227, 248, var(--bg-opacity));
}

.hover\:tw-bg-blue-300:hover {
  --bg-opacity: 1;
  background-color: #90cdf4;
  background-color: rgba(144, 205, 244, var(--bg-opacity));
}

.hover\:tw-bg-blue-400:hover {
  --bg-opacity: 1;
  background-color: #63b3ed;
  background-color: rgba(99, 179, 237, var(--bg-opacity));
}

.hover\:tw-bg-blue-500:hover {
  --bg-opacity: 1;
  background-color: #4299e1;
  background-color: rgba(66, 153, 225, var(--bg-opacity));
}

.hover\:tw-bg-blue-600:hover {
  --bg-opacity: 1;
  background-color: #3182ce;
  background-color: rgba(49, 130, 206, var(--bg-opacity));
}

.hover\:tw-bg-blue-700:hover {
  --bg-opacity: 1;
  background-color: #2b6cb0;
  background-color: rgba(43, 108, 176, var(--bg-opacity));
}

.hover\:tw-bg-blue-800:hover {
  --bg-opacity: 1;
  background-color: #2c5282;
  background-color: rgba(44, 82, 130, var(--bg-opacity));
}

.hover\:tw-bg-blue-900:hover {
  --bg-opacity: 1;
  background-color: #2a4365;
  background-color: rgba(42, 67, 101, var(--bg-opacity));
}

.hover\:tw-bg-indigo-100:hover {
  --bg-opacity: 1;
  background-color: #ebf4ff;
  background-color: rgba(235, 244, 255, var(--bg-opacity));
}

.hover\:tw-bg-indigo-200:hover {
  --bg-opacity: 1;
  background-color: #c3dafe;
  background-color: rgba(195, 218, 254, var(--bg-opacity));
}

.hover\:tw-bg-indigo-300:hover {
  --bg-opacity: 1;
  background-color: #a3bffa;
  background-color: rgba(163, 191, 250, var(--bg-opacity));
}

.hover\:tw-bg-indigo-400:hover {
  --bg-opacity: 1;
  background-color: #7f9cf5;
  background-color: rgba(127, 156, 245, var(--bg-opacity));
}

.hover\:tw-bg-indigo-500:hover {
  --bg-opacity: 1;
  background-color: #667eea;
  background-color: rgba(102, 126, 234, var(--bg-opacity));
}

.hover\:tw-bg-indigo-600:hover {
  --bg-opacity: 1;
  background-color: #5a67d8;
  background-color: rgba(90, 103, 216, var(--bg-opacity));
}

.hover\:tw-bg-indigo-700:hover {
  --bg-opacity: 1;
  background-color: #4c51bf;
  background-color: rgba(76, 81, 191, var(--bg-opacity));
}

.hover\:tw-bg-indigo-800:hover {
  --bg-opacity: 1;
  background-color: #434190;
  background-color: rgba(67, 65, 144, var(--bg-opacity));
}

.hover\:tw-bg-indigo-900:hover {
  --bg-opacity: 1;
  background-color: #3c366b;
  background-color: rgba(60, 54, 107, var(--bg-opacity));
}

.hover\:tw-bg-purple-100:hover {
  --bg-opacity: 1;
  background-color: #faf5ff;
  background-color: rgba(250, 245, 255, var(--bg-opacity));
}

.hover\:tw-bg-purple-200:hover {
  --bg-opacity: 1;
  background-color: #e9d8fd;
  background-color: rgba(233, 216, 253, var(--bg-opacity));
}

.hover\:tw-bg-purple-300:hover {
  --bg-opacity: 1;
  background-color: #d6bcfa;
  background-color: rgba(214, 188, 250, var(--bg-opacity));
}

.hover\:tw-bg-purple-400:hover {
  --bg-opacity: 1;
  background-color: #b794f4;
  background-color: rgba(183, 148, 244, var(--bg-opacity));
}

.hover\:tw-bg-purple-500:hover {
  --bg-opacity: 1;
  background-color: #9f7aea;
  background-color: rgba(159, 122, 234, var(--bg-opacity));
}

.hover\:tw-bg-purple-600:hover {
  --bg-opacity: 1;
  background-color: #805ad5;
  background-color: rgba(128, 90, 213, var(--bg-opacity));
}

.hover\:tw-bg-purple-700:hover {
  --bg-opacity: 1;
  background-color: #6b46c1;
  background-color: rgba(107, 70, 193, var(--bg-opacity));
}

.hover\:tw-bg-purple-800:hover {
  --bg-opacity: 1;
  background-color: #553c9a;
  background-color: rgba(85, 60, 154, var(--bg-opacity));
}

.hover\:tw-bg-purple-900:hover {
  --bg-opacity: 1;
  background-color: #44337a;
  background-color: rgba(68, 51, 122, var(--bg-opacity));
}

.hover\:tw-bg-pink-100:hover {
  --bg-opacity: 1;
  background-color: #fff5f7;
  background-color: rgba(255, 245, 247, var(--bg-opacity));
}

.hover\:tw-bg-pink-200:hover {
  --bg-opacity: 1;
  background-color: #fed7e2;
  background-color: rgba(254, 215, 226, var(--bg-opacity));
}

.hover\:tw-bg-pink-300:hover {
  --bg-opacity: 1;
  background-color: #fbb6ce;
  background-color: rgba(251, 182, 206, var(--bg-opacity));
}

.hover\:tw-bg-pink-400:hover {
  --bg-opacity: 1;
  background-color: #f687b3;
  background-color: rgba(246, 135, 179, var(--bg-opacity));
}

.hover\:tw-bg-pink-500:hover {
  --bg-opacity: 1;
  background-color: #ed64a6;
  background-color: rgba(237, 100, 166, var(--bg-opacity));
}

.hover\:tw-bg-pink-600:hover {
  --bg-opacity: 1;
  background-color: #d53f8c;
  background-color: rgba(213, 63, 140, var(--bg-opacity));
}

.hover\:tw-bg-pink-700:hover {
  --bg-opacity: 1;
  background-color: #b83280;
  background-color: rgba(184, 50, 128, var(--bg-opacity));
}

.hover\:tw-bg-pink-800:hover {
  --bg-opacity: 1;
  background-color: #97266d;
  background-color: rgba(151, 38, 109, var(--bg-opacity));
}

.hover\:tw-bg-pink-900:hover {
  --bg-opacity: 1;
  background-color: #702459;
  background-color: rgba(112, 36, 89, var(--bg-opacity));
}

.focus\:tw-bg-transparent:focus {
  background-color: transparent;
}

.focus\:tw-bg-current:focus {
  background-color: currentColor;
}

.focus\:tw-bg-black:focus {
  --bg-opacity: 1;
  background-color: #000;
  background-color: rgba(0, 0, 0, var(--bg-opacity));
}

.focus\:tw-bg-white:focus {
  --bg-opacity: 1;
  background-color: #fff;
  background-color: rgba(255, 255, 255, var(--bg-opacity));
}

.focus\:tw-bg-gray-100:focus {
  --bg-opacity: 1;
  background-color: #f7fafc;
  background-color: rgba(247, 250, 252, var(--bg-opacity));
}

.focus\:tw-bg-gray-200:focus {
  --bg-opacity: 1;
  background-color: #edf2f7;
  background-color: rgba(237, 242, 247, var(--bg-opacity));
}

.focus\:tw-bg-gray-300:focus {
  --bg-opacity: 1;
  background-color: #e2e8f0;
  background-color: rgba(226, 232, 240, var(--bg-opacity));
}

.focus\:tw-bg-gray-400:focus {
  --bg-opacity: 1;
  background-color: #cbd5e0;
  background-color: rgba(203, 213, 224, var(--bg-opacity));
}

.focus\:tw-bg-gray-500:focus {
  --bg-opacity: 1;
  background-color: #a0aec0;
  background-color: rgba(160, 174, 192, var(--bg-opacity));
}

.focus\:tw-bg-gray-600:focus {
  --bg-opacity: 1;
  background-color: #718096;
  background-color: rgba(113, 128, 150, var(--bg-opacity));
}

.focus\:tw-bg-gray-700:focus {
  --bg-opacity: 1;
  background-color: #4a5568;
  background-color: rgba(74, 85, 104, var(--bg-opacity));
}

.focus\:tw-bg-gray-800:focus {
  --bg-opacity: 1;
  background-color: #2d3748;
  background-color: rgba(45, 55, 72, var(--bg-opacity));
}

.focus\:tw-bg-gray-900:focus {
  --bg-opacity: 1;
  background-color: #1a202c;
  background-color: rgba(26, 32, 44, var(--bg-opacity));
}

.focus\:tw-bg-red-100:focus {
  --bg-opacity: 1;
  background-color: #fff5f5;
  background-color: rgba(255, 245, 245, var(--bg-opacity));
}

.focus\:tw-bg-red-200:focus {
  --bg-opacity: 1;
  background-color: #fed7d7;
  background-color: rgba(254, 215, 215, var(--bg-opacity));
}

.focus\:tw-bg-red-300:focus {
  --bg-opacity: 1;
  background-color: #feb2b2;
  background-color: rgba(254, 178, 178, var(--bg-opacity));
}

.focus\:tw-bg-red-400:focus {
  --bg-opacity: 1;
  background-color: #fc8181;
  background-color: rgba(252, 129, 129, var(--bg-opacity));
}

.focus\:tw-bg-red-500:focus {
  --bg-opacity: 1;
  background-color: #f56565;
  background-color: rgba(245, 101, 101, var(--bg-opacity));
}

.focus\:tw-bg-red-600:focus {
  --bg-opacity: 1;
  background-color: #e53e3e;
  background-color: rgba(229, 62, 62, var(--bg-opacity));
}

.focus\:tw-bg-red-700:focus {
  --bg-opacity: 1;
  background-color: #c53030;
  background-color: rgba(197, 48, 48, var(--bg-opacity));
}

.focus\:tw-bg-red-800:focus {
  --bg-opacity: 1;
  background-color: #9b2c2c;
  background-color: rgba(155, 44, 44, var(--bg-opacity));
}

.focus\:tw-bg-red-900:focus {
  --bg-opacity: 1;
  background-color: #742a2a;
  background-color: rgba(116, 42, 42, var(--bg-opacity));
}

.focus\:tw-bg-orange-100:focus {
  --bg-opacity: 1;
  background-color: #fffaf0;
  background-color: rgba(255, 250, 240, var(--bg-opacity));
}

.focus\:tw-bg-orange-200:focus {
  --bg-opacity: 1;
  background-color: #feebc8;
  background-color: rgba(254, 235, 200, var(--bg-opacity));
}

.focus\:tw-bg-orange-300:focus {
  --bg-opacity: 1;
  background-color: #fbd38d;
  background-color: rgba(251, 211, 141, var(--bg-opacity));
}

.focus\:tw-bg-orange-400:focus {
  --bg-opacity: 1;
  background-color: #f6ad55;
  background-color: rgba(246, 173, 85, var(--bg-opacity));
}

.focus\:tw-bg-orange-500:focus {
  --bg-opacity: 1;
  background-color: #ed8936;
  background-color: rgba(237, 137, 54, var(--bg-opacity));
}

.focus\:tw-bg-orange-600:focus {
  --bg-opacity: 1;
  background-color: #dd6b20;
  background-color: rgba(221, 107, 32, var(--bg-opacity));
}

.focus\:tw-bg-orange-700:focus {
  --bg-opacity: 1;
  background-color: #c05621;
  background-color: rgba(192, 86, 33, var(--bg-opacity));
}

.focus\:tw-bg-orange-800:focus {
  --bg-opacity: 1;
  background-color: #9c4221;
  background-color: rgba(156, 66, 33, var(--bg-opacity));
}

.focus\:tw-bg-orange-900:focus {
  --bg-opacity: 1;
  background-color: #7b341e;
  background-color: rgba(123, 52, 30, var(--bg-opacity));
}

.focus\:tw-bg-yellow-100:focus {
  --bg-opacity: 1;
  background-color: #fffff0;
  background-color: rgba(255, 255, 240, var(--bg-opacity));
}

.focus\:tw-bg-yellow-200:focus {
  --bg-opacity: 1;
  background-color: #fefcbf;
  background-color: rgba(254, 252, 191, var(--bg-opacity));
}

.focus\:tw-bg-yellow-300:focus {
  --bg-opacity: 1;
  background-color: #faf089;
  background-color: rgba(250, 240, 137, var(--bg-opacity));
}

.focus\:tw-bg-yellow-400:focus {
  --bg-opacity: 1;
  background-color: #f6e05e;
  background-color: rgba(246, 224, 94, var(--bg-opacity));
}

.focus\:tw-bg-yellow-500:focus {
  --bg-opacity: 1;
  background-color: #ecc94b;
  background-color: rgba(236, 201, 75, var(--bg-opacity));
}

.focus\:tw-bg-yellow-600:focus {
  --bg-opacity: 1;
  background-color: #d69e2e;
  background-color: rgba(214, 158, 46, var(--bg-opacity));
}

.focus\:tw-bg-yellow-700:focus {
  --bg-opacity: 1;
  background-color: #b7791f;
  background-color: rgba(183, 121, 31, var(--bg-opacity));
}

.focus\:tw-bg-yellow-800:focus {
  --bg-opacity: 1;
  background-color: #975a16;
  background-color: rgba(151, 90, 22, var(--bg-opacity));
}

.focus\:tw-bg-yellow-900:focus {
  --bg-opacity: 1;
  background-color: #744210;
  background-color: rgba(116, 66, 16, var(--bg-opacity));
}

.focus\:tw-bg-green-100:focus {
  --bg-opacity: 1;
  background-color: #f0fff4;
  background-color: rgba(240, 255, 244, var(--bg-opacity));
}

.focus\:tw-bg-green-200:focus {
  --bg-opacity: 1;
  background-color: #c6f6d5;
  background-color: rgba(198, 246, 213, var(--bg-opacity));
}

.focus\:tw-bg-green-300:focus {
  --bg-opacity: 1;
  background-color: #9ae6b4;
  background-color: rgba(154, 230, 180, var(--bg-opacity));
}

.focus\:tw-bg-green-400:focus {
  --bg-opacity: 1;
  background-color: #68d391;
  background-color: rgba(104, 211, 145, var(--bg-opacity));
}

.focus\:tw-bg-green-500:focus {
  --bg-opacity: 1;
  background-color: #48bb78;
  background-color: rgba(72, 187, 120, var(--bg-opacity));
}

.focus\:tw-bg-green-600:focus {
  --bg-opacity: 1;
  background-color: #38a169;
  background-color: rgba(56, 161, 105, var(--bg-opacity));
}

.focus\:tw-bg-green-700:focus {
  --bg-opacity: 1;
  background-color: #2f855a;
  background-color: rgba(47, 133, 90, var(--bg-opacity));
}

.focus\:tw-bg-green-800:focus {
  --bg-opacity: 1;
  background-color: #276749;
  background-color: rgba(39, 103, 73, var(--bg-opacity));
}

.focus\:tw-bg-green-900:focus {
  --bg-opacity: 1;
  background-color: #22543d;
  background-color: rgba(34, 84, 61, var(--bg-opacity));
}

.focus\:tw-bg-teal-100:focus {
  --bg-opacity: 1;
  background-color: #e6fffa;
  background-color: rgba(230, 255, 250, var(--bg-opacity));
}

.focus\:tw-bg-teal-200:focus {
  --bg-opacity: 1;
  background-color: #b2f5ea;
  background-color: rgba(178, 245, 234, var(--bg-opacity));
}

.focus\:tw-bg-teal-300:focus {
  --bg-opacity: 1;
  background-color: #81e6d9;
  background-color: rgba(129, 230, 217, var(--bg-opacity));
}

.focus\:tw-bg-teal-400:focus {
  --bg-opacity: 1;
  background-color: #4fd1c5;
  background-color: rgba(79, 209, 197, var(--bg-opacity));
}

.focus\:tw-bg-teal-500:focus {
  --bg-opacity: 1;
  background-color: #38b2ac;
  background-color: rgba(56, 178, 172, var(--bg-opacity));
}

.focus\:tw-bg-teal-600:focus {
  --bg-opacity: 1;
  background-color: #319795;
  background-color: rgba(49, 151, 149, var(--bg-opacity));
}

.focus\:tw-bg-teal-700:focus {
  --bg-opacity: 1;
  background-color: #2c7a7b;
  background-color: rgba(44, 122, 123, var(--bg-opacity));
}

.focus\:tw-bg-teal-800:focus {
  --bg-opacity: 1;
  background-color: #285e61;
  background-color: rgba(40, 94, 97, var(--bg-opacity));
}

.focus\:tw-bg-teal-900:focus {
  --bg-opacity: 1;
  background-color: #234e52;
  background-color: rgba(35, 78, 82, var(--bg-opacity));
}

.focus\:tw-bg-blue-100:focus {
  --bg-opacity: 1;
  background-color: #ebf8ff;
  background-color: rgba(235, 248, 255, var(--bg-opacity));
}

.focus\:tw-bg-blue-200:focus {
  --bg-opacity: 1;
  background-color: #bee3f8;
  background-color: rgba(190, 227, 248, var(--bg-opacity));
}

.focus\:tw-bg-blue-300:focus {
  --bg-opacity: 1;
  background-color: #90cdf4;
  background-color: rgba(144, 205, 244, var(--bg-opacity));
}

.focus\:tw-bg-blue-400:focus {
  --bg-opacity: 1;
  background-color: #63b3ed;
  background-color: rgba(99, 179, 237, var(--bg-opacity));
}

.focus\:tw-bg-blue-500:focus {
  --bg-opacity: 1;
  background-color: #4299e1;
  background-color: rgba(66, 153, 225, var(--bg-opacity));
}

.focus\:tw-bg-blue-600:focus {
  --bg-opacity: 1;
  background-color: #3182ce;
  background-color: rgba(49, 130, 206, var(--bg-opacity));
}

.focus\:tw-bg-blue-700:focus {
  --bg-opacity: 1;
  background-color: #2b6cb0;
  background-color: rgba(43, 108, 176, var(--bg-opacity));
}

.focus\:tw-bg-blue-800:focus {
  --bg-opacity: 1;
  background-color: #2c5282;
  background-color: rgba(44, 82, 130, var(--bg-opacity));
}

.focus\:tw-bg-blue-900:focus {
  --bg-opacity: 1;
  background-color: #2a4365;
  background-color: rgba(42, 67, 101, var(--bg-opacity));
}

.focus\:tw-bg-indigo-100:focus {
  --bg-opacity: 1;
  background-color: #ebf4ff;
  background-color: rgba(235, 244, 255, var(--bg-opacity));
}

.focus\:tw-bg-indigo-200:focus {
  --bg-opacity: 1;
  background-color: #c3dafe;
  background-color: rgba(195, 218, 254, var(--bg-opacity));
}

.focus\:tw-bg-indigo-300:focus {
  --bg-opacity: 1;
  background-color: #a3bffa;
  background-color: rgba(163, 191, 250, var(--bg-opacity));
}

.focus\:tw-bg-indigo-400:focus {
  --bg-opacity: 1;
  background-color: #7f9cf5;
  background-color: rgba(127, 156, 245, var(--bg-opacity));
}

.focus\:tw-bg-indigo-500:focus {
  --bg-opacity: 1;
  background-color: #667eea;
  background-color: rgba(102, 126, 234, var(--bg-opacity));
}

.focus\:tw-bg-indigo-600:focus {
  --bg-opacity: 1;
  background-color: #5a67d8;
  background-color: rgba(90, 103, 216, var(--bg-opacity));
}

.focus\:tw-bg-indigo-700:focus {
  --bg-opacity: 1;
  background-color: #4c51bf;
  background-color: rgba(76, 81, 191, var(--bg-opacity));
}

.focus\:tw-bg-indigo-800:focus {
  --bg-opacity: 1;
  background-color: #434190;
  background-color: rgba(67, 65, 144, var(--bg-opacity));
}

.focus\:tw-bg-indigo-900:focus {
  --bg-opacity: 1;
  background-color: #3c366b;
  background-color: rgba(60, 54, 107, var(--bg-opacity));
}

.focus\:tw-bg-purple-100:focus {
  --bg-opacity: 1;
  background-color: #faf5ff;
  background-color: rgba(250, 245, 255, var(--bg-opacity));
}

.focus\:tw-bg-purple-200:focus {
  --bg-opacity: 1;
  background-color: #e9d8fd;
  background-color: rgba(233, 216, 253, var(--bg-opacity));
}

.focus\:tw-bg-purple-300:focus {
  --bg-opacity: 1;
  background-color: #d6bcfa;
  background-color: rgba(214, 188, 250, var(--bg-opacity));
}

.focus\:tw-bg-purple-400:focus {
  --bg-opacity: 1;
  background-color: #b794f4;
  background-color: rgba(183, 148, 244, var(--bg-opacity));
}

.focus\:tw-bg-purple-500:focus {
  --bg-opacity: 1;
  background-color: #9f7aea;
  background-color: rgba(159, 122, 234, var(--bg-opacity));
}

.focus\:tw-bg-purple-600:focus {
  --bg-opacity: 1;
  background-color: #805ad5;
  background-color: rgba(128, 90, 213, var(--bg-opacity));
}

.focus\:tw-bg-purple-700:focus {
  --bg-opacity: 1;
  background-color: #6b46c1;
  background-color: rgba(107, 70, 193, var(--bg-opacity));
}

.focus\:tw-bg-purple-800:focus {
  --bg-opacity: 1;
  background-color: #553c9a;
  background-color: rgba(85, 60, 154, var(--bg-opacity));
}

.focus\:tw-bg-purple-900:focus {
  --bg-opacity: 1;
  background-color: #44337a;
  background-color: rgba(68, 51, 122, var(--bg-opacity));
}

.focus\:tw-bg-pink-100:focus {
  --bg-opacity: 1;
  background-color: #fff5f7;
  background-color: rgba(255, 245, 247, var(--bg-opacity));
}

.focus\:tw-bg-pink-200:focus {
  --bg-opacity: 1;
  background-color: #fed7e2;
  background-color: rgba(254, 215, 226, var(--bg-opacity));
}

.focus\:tw-bg-pink-300:focus {
  --bg-opacity: 1;
  background-color: #fbb6ce;
  background-color: rgba(251, 182, 206, var(--bg-opacity));
}

.focus\:tw-bg-pink-400:focus {
  --bg-opacity: 1;
  background-color: #f687b3;
  background-color: rgba(246, 135, 179, var(--bg-opacity));
}

.focus\:tw-bg-pink-500:focus {
  --bg-opacity: 1;
  background-color: #ed64a6;
  background-color: rgba(237, 100, 166, var(--bg-opacity));
}

.focus\:tw-bg-pink-600:focus {
  --bg-opacity: 1;
  background-color: #d53f8c;
  background-color: rgba(213, 63, 140, var(--bg-opacity));
}

.focus\:tw-bg-pink-700:focus {
  --bg-opacity: 1;
  background-color: #b83280;
  background-color: rgba(184, 50, 128, var(--bg-opacity));
}

.focus\:tw-bg-pink-800:focus {
  --bg-opacity: 1;
  background-color: #97266d;
  background-color: rgba(151, 38, 109, var(--bg-opacity));
}

.focus\:tw-bg-pink-900:focus {
  --bg-opacity: 1;
  background-color: #702459;
  background-color: rgba(112, 36, 89, var(--bg-opacity));
}

.tw-bg-none {
  background-image: none;
}

.tw-bg-gradient-to-t {
  background-image: linear-gradient(to top, var(--gradient-color-stops));
}

.tw-bg-gradient-to-tr {
  background-image: linear-gradient(to top right, var(--gradient-color-stops));
}

.tw-bg-gradient-to-r {
  background-image: linear-gradient(to right, var(--gradient-color-stops));
}

.tw-bg-gradient-to-br {
  background-image: linear-gradient(to bottom right, var(--gradient-color-stops));
}

.tw-bg-gradient-to-b {
  background-image: linear-gradient(to bottom, var(--gradient-color-stops));
}

.tw-bg-gradient-to-bl {
  background-image: linear-gradient(to bottom left, var(--gradient-color-stops));
}

.tw-bg-gradient-to-l {
  background-image: linear-gradient(to left, var(--gradient-color-stops));
}

.tw-bg-gradient-to-tl {
  background-image: linear-gradient(to top left, var(--gradient-color-stops));
}

.tw-bg-wave-pattern {
  background-image: url('../assets/images/nawbar_waves.svg');
}

.tw-from-transparent {
  --gradient-from-color: transparent;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(0, 0, 0, 0));
}

.tw-from-current {
  --gradient-from-color: currentColor;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0));
}

.tw-from-black {
  --gradient-from-color: #000;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(0, 0, 0, 0));
}

.tw-from-white {
  --gradient-from-color: #fff;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0));
}

.tw-from-gray-100 {
  --gradient-from-color: #f7fafc;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(247, 250, 252, 0));
}

.tw-from-gray-200 {
  --gradient-from-color: #edf2f7;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(237, 242, 247, 0));
}

.tw-from-gray-300 {
  --gradient-from-color: #e2e8f0;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(226, 232, 240, 0));
}

.tw-from-gray-400 {
  --gradient-from-color: #cbd5e0;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(203, 213, 224, 0));
}

.tw-from-gray-500 {
  --gradient-from-color: #a0aec0;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(160, 174, 192, 0));
}

.tw-from-gray-600 {
  --gradient-from-color: #718096;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(113, 128, 150, 0));
}

.tw-from-gray-700 {
  --gradient-from-color: #4a5568;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(74, 85, 104, 0));
}

.tw-from-gray-800 {
  --gradient-from-color: #2d3748;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(45, 55, 72, 0));
}

.tw-from-gray-900 {
  --gradient-from-color: #1a202c;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(26, 32, 44, 0));
}

.tw-from-red-100 {
  --gradient-from-color: #fff5f5;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 245, 245, 0));
}

.tw-from-red-200 {
  --gradient-from-color: #fed7d7;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(254, 215, 215, 0));
}

.tw-from-red-300 {
  --gradient-from-color: #feb2b2;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(254, 178, 178, 0));
}

.tw-from-red-400 {
  --gradient-from-color: #fc8181;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(252, 129, 129, 0));
}

.tw-from-red-500 {
  --gradient-from-color: #f56565;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(245, 101, 101, 0));
}

.tw-from-red-600 {
  --gradient-from-color: #e53e3e;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(229, 62, 62, 0));
}

.tw-from-red-700 {
  --gradient-from-color: #c53030;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(197, 48, 48, 0));
}

.tw-from-red-800 {
  --gradient-from-color: #9b2c2c;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(155, 44, 44, 0));
}

.tw-from-red-900 {
  --gradient-from-color: #742a2a;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(116, 42, 42, 0));
}

.tw-from-orange-100 {
  --gradient-from-color: #fffaf0;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 250, 240, 0));
}

.tw-from-orange-200 {
  --gradient-from-color: #feebc8;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(254, 235, 200, 0));
}

.tw-from-orange-300 {
  --gradient-from-color: #fbd38d;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(251, 211, 141, 0));
}

.tw-from-orange-400 {
  --gradient-from-color: #f6ad55;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(246, 173, 85, 0));
}

.tw-from-orange-500 {
  --gradient-from-color: #ed8936;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(237, 137, 54, 0));
}

.tw-from-orange-600 {
  --gradient-from-color: #dd6b20;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(221, 107, 32, 0));
}

.tw-from-orange-700 {
  --gradient-from-color: #c05621;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(192, 86, 33, 0));
}

.tw-from-orange-800 {
  --gradient-from-color: #9c4221;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(156, 66, 33, 0));
}

.tw-from-orange-900 {
  --gradient-from-color: #7b341e;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(123, 52, 30, 0));
}

.tw-from-yellow-100 {
  --gradient-from-color: #fffff0;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 240, 0));
}

.tw-from-yellow-200 {
  --gradient-from-color: #fefcbf;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(254, 252, 191, 0));
}

.tw-from-yellow-300 {
  --gradient-from-color: #faf089;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(250, 240, 137, 0));
}

.tw-from-yellow-400 {
  --gradient-from-color: #f6e05e;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(246, 224, 94, 0));
}

.tw-from-yellow-500 {
  --gradient-from-color: #ecc94b;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(236, 201, 75, 0));
}

.tw-from-yellow-600 {
  --gradient-from-color: #d69e2e;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(214, 158, 46, 0));
}

.tw-from-yellow-700 {
  --gradient-from-color: #b7791f;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(183, 121, 31, 0));
}

.tw-from-yellow-800 {
  --gradient-from-color: #975a16;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(151, 90, 22, 0));
}

.tw-from-yellow-900 {
  --gradient-from-color: #744210;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(116, 66, 16, 0));
}

.tw-from-green-100 {
  --gradient-from-color: #f0fff4;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(240, 255, 244, 0));
}

.tw-from-green-200 {
  --gradient-from-color: #c6f6d5;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(198, 246, 213, 0));
}

.tw-from-green-300 {
  --gradient-from-color: #9ae6b4;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(154, 230, 180, 0));
}

.tw-from-green-400 {
  --gradient-from-color: #68d391;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(104, 211, 145, 0));
}

.tw-from-green-500 {
  --gradient-from-color: #48bb78;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(72, 187, 120, 0));
}

.tw-from-green-600 {
  --gradient-from-color: #38a169;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(56, 161, 105, 0));
}

.tw-from-green-700 {
  --gradient-from-color: #2f855a;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(47, 133, 90, 0));
}

.tw-from-green-800 {
  --gradient-from-color: #276749;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(39, 103, 73, 0));
}

.tw-from-green-900 {
  --gradient-from-color: #22543d;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(34, 84, 61, 0));
}

.tw-from-teal-100 {
  --gradient-from-color: #e6fffa;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(230, 255, 250, 0));
}

.tw-from-teal-200 {
  --gradient-from-color: #b2f5ea;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(178, 245, 234, 0));
}

.tw-from-teal-300 {
  --gradient-from-color: #81e6d9;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(129, 230, 217, 0));
}

.tw-from-teal-400 {
  --gradient-from-color: #4fd1c5;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(79, 209, 197, 0));
}

.tw-from-teal-500 {
  --gradient-from-color: #38b2ac;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(56, 178, 172, 0));
}

.tw-from-teal-600 {
  --gradient-from-color: #319795;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(49, 151, 149, 0));
}

.tw-from-teal-700 {
  --gradient-from-color: #2c7a7b;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(44, 122, 123, 0));
}

.tw-from-teal-800 {
  --gradient-from-color: #285e61;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(40, 94, 97, 0));
}

.tw-from-teal-900 {
  --gradient-from-color: #234e52;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(35, 78, 82, 0));
}

.tw-from-blue-100 {
  --gradient-from-color: #ebf8ff;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(235, 248, 255, 0));
}

.tw-from-blue-200 {
  --gradient-from-color: #bee3f8;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(190, 227, 248, 0));
}

.tw-from-blue-300 {
  --gradient-from-color: #90cdf4;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(144, 205, 244, 0));
}

.tw-from-blue-400 {
  --gradient-from-color: #63b3ed;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(99, 179, 237, 0));
}

.tw-from-blue-500 {
  --gradient-from-color: #4299e1;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(66, 153, 225, 0));
}

.tw-from-blue-600 {
  --gradient-from-color: #3182ce;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(49, 130, 206, 0));
}

.tw-from-blue-700 {
  --gradient-from-color: #2b6cb0;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(43, 108, 176, 0));
}

.tw-from-blue-800 {
  --gradient-from-color: #2c5282;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(44, 82, 130, 0));
}

.tw-from-blue-900 {
  --gradient-from-color: #2a4365;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(42, 67, 101, 0));
}

.tw-from-indigo-100 {
  --gradient-from-color: #ebf4ff;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(235, 244, 255, 0));
}

.tw-from-indigo-200 {
  --gradient-from-color: #c3dafe;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(195, 218, 254, 0));
}

.tw-from-indigo-300 {
  --gradient-from-color: #a3bffa;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(163, 191, 250, 0));
}

.tw-from-indigo-400 {
  --gradient-from-color: #7f9cf5;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(127, 156, 245, 0));
}

.tw-from-indigo-500 {
  --gradient-from-color: #667eea;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(102, 126, 234, 0));
}

.tw-from-indigo-600 {
  --gradient-from-color: #5a67d8;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(90, 103, 216, 0));
}

.tw-from-indigo-700 {
  --gradient-from-color: #4c51bf;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(76, 81, 191, 0));
}

.tw-from-indigo-800 {
  --gradient-from-color: #434190;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(67, 65, 144, 0));
}

.tw-from-indigo-900 {
  --gradient-from-color: #3c366b;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(60, 54, 107, 0));
}

.tw-from-purple-100 {
  --gradient-from-color: #faf5ff;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(250, 245, 255, 0));
}

.tw-from-purple-200 {
  --gradient-from-color: #e9d8fd;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(233, 216, 253, 0));
}

.tw-from-purple-300 {
  --gradient-from-color: #d6bcfa;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(214, 188, 250, 0));
}

.tw-from-purple-400 {
  --gradient-from-color: #b794f4;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(183, 148, 244, 0));
}

.tw-from-purple-500 {
  --gradient-from-color: #9f7aea;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(159, 122, 234, 0));
}

.tw-from-purple-600 {
  --gradient-from-color: #805ad5;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(128, 90, 213, 0));
}

.tw-from-purple-700 {
  --gradient-from-color: #6b46c1;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(107, 70, 193, 0));
}

.tw-from-purple-800 {
  --gradient-from-color: #553c9a;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(85, 60, 154, 0));
}

.tw-from-purple-900 {
  --gradient-from-color: #44337a;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(68, 51, 122, 0));
}

.tw-from-pink-100 {
  --gradient-from-color: #fff5f7;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 245, 247, 0));
}

.tw-from-pink-200 {
  --gradient-from-color: #fed7e2;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(254, 215, 226, 0));
}

.tw-from-pink-300 {
  --gradient-from-color: #fbb6ce;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(251, 182, 206, 0));
}

.tw-from-pink-400 {
  --gradient-from-color: #f687b3;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(246, 135, 179, 0));
}

.tw-from-pink-500 {
  --gradient-from-color: #ed64a6;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(237, 100, 166, 0));
}

.tw-from-pink-600 {
  --gradient-from-color: #d53f8c;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(213, 63, 140, 0));
}

.tw-from-pink-700 {
  --gradient-from-color: #b83280;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(184, 50, 128, 0));
}

.tw-from-pink-800 {
  --gradient-from-color: #97266d;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(151, 38, 109, 0));
}

.tw-from-pink-900 {
  --gradient-from-color: #702459;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(112, 36, 89, 0));
}

.tw-via-transparent {
  --gradient-via-color: transparent;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(0, 0, 0, 0));
}

.tw-via-current {
  --gradient-via-color: currentColor;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0));
}

.tw-via-black {
  --gradient-via-color: #000;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(0, 0, 0, 0));
}

.tw-via-white {
  --gradient-via-color: #fff;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0));
}

.tw-via-gray-100 {
  --gradient-via-color: #f7fafc;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(247, 250, 252, 0));
}

.tw-via-gray-200 {
  --gradient-via-color: #edf2f7;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(237, 242, 247, 0));
}

.tw-via-gray-300 {
  --gradient-via-color: #e2e8f0;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(226, 232, 240, 0));
}

.tw-via-gray-400 {
  --gradient-via-color: #cbd5e0;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(203, 213, 224, 0));
}

.tw-via-gray-500 {
  --gradient-via-color: #a0aec0;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(160, 174, 192, 0));
}

.tw-via-gray-600 {
  --gradient-via-color: #718096;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(113, 128, 150, 0));
}

.tw-via-gray-700 {
  --gradient-via-color: #4a5568;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(74, 85, 104, 0));
}

.tw-via-gray-800 {
  --gradient-via-color: #2d3748;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(45, 55, 72, 0));
}

.tw-via-gray-900 {
  --gradient-via-color: #1a202c;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(26, 32, 44, 0));
}

.tw-via-red-100 {
  --gradient-via-color: #fff5f5;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 245, 245, 0));
}

.tw-via-red-200 {
  --gradient-via-color: #fed7d7;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(254, 215, 215, 0));
}

.tw-via-red-300 {
  --gradient-via-color: #feb2b2;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(254, 178, 178, 0));
}

.tw-via-red-400 {
  --gradient-via-color: #fc8181;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(252, 129, 129, 0));
}

.tw-via-red-500 {
  --gradient-via-color: #f56565;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(245, 101, 101, 0));
}

.tw-via-red-600 {
  --gradient-via-color: #e53e3e;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(229, 62, 62, 0));
}

.tw-via-red-700 {
  --gradient-via-color: #c53030;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(197, 48, 48, 0));
}

.tw-via-red-800 {
  --gradient-via-color: #9b2c2c;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(155, 44, 44, 0));
}

.tw-via-red-900 {
  --gradient-via-color: #742a2a;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(116, 42, 42, 0));
}

.tw-via-orange-100 {
  --gradient-via-color: #fffaf0;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 250, 240, 0));
}

.tw-via-orange-200 {
  --gradient-via-color: #feebc8;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(254, 235, 200, 0));
}

.tw-via-orange-300 {
  --gradient-via-color: #fbd38d;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(251, 211, 141, 0));
}

.tw-via-orange-400 {
  --gradient-via-color: #f6ad55;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(246, 173, 85, 0));
}

.tw-via-orange-500 {
  --gradient-via-color: #ed8936;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(237, 137, 54, 0));
}

.tw-via-orange-600 {
  --gradient-via-color: #dd6b20;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(221, 107, 32, 0));
}

.tw-via-orange-700 {
  --gradient-via-color: #c05621;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(192, 86, 33, 0));
}

.tw-via-orange-800 {
  --gradient-via-color: #9c4221;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(156, 66, 33, 0));
}

.tw-via-orange-900 {
  --gradient-via-color: #7b341e;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(123, 52, 30, 0));
}

.tw-via-yellow-100 {
  --gradient-via-color: #fffff0;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 240, 0));
}

.tw-via-yellow-200 {
  --gradient-via-color: #fefcbf;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(254, 252, 191, 0));
}

.tw-via-yellow-300 {
  --gradient-via-color: #faf089;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(250, 240, 137, 0));
}

.tw-via-yellow-400 {
  --gradient-via-color: #f6e05e;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(246, 224, 94, 0));
}

.tw-via-yellow-500 {
  --gradient-via-color: #ecc94b;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(236, 201, 75, 0));
}

.tw-via-yellow-600 {
  --gradient-via-color: #d69e2e;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(214, 158, 46, 0));
}

.tw-via-yellow-700 {
  --gradient-via-color: #b7791f;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(183, 121, 31, 0));
}

.tw-via-yellow-800 {
  --gradient-via-color: #975a16;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(151, 90, 22, 0));
}

.tw-via-yellow-900 {
  --gradient-via-color: #744210;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(116, 66, 16, 0));
}

.tw-via-green-100 {
  --gradient-via-color: #f0fff4;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(240, 255, 244, 0));
}

.tw-via-green-200 {
  --gradient-via-color: #c6f6d5;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(198, 246, 213, 0));
}

.tw-via-green-300 {
  --gradient-via-color: #9ae6b4;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(154, 230, 180, 0));
}

.tw-via-green-400 {
  --gradient-via-color: #68d391;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(104, 211, 145, 0));
}

.tw-via-green-500 {
  --gradient-via-color: #48bb78;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(72, 187, 120, 0));
}

.tw-via-green-600 {
  --gradient-via-color: #38a169;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(56, 161, 105, 0));
}

.tw-via-green-700 {
  --gradient-via-color: #2f855a;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(47, 133, 90, 0));
}

.tw-via-green-800 {
  --gradient-via-color: #276749;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(39, 103, 73, 0));
}

.tw-via-green-900 {
  --gradient-via-color: #22543d;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(34, 84, 61, 0));
}

.tw-via-teal-100 {
  --gradient-via-color: #e6fffa;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(230, 255, 250, 0));
}

.tw-via-teal-200 {
  --gradient-via-color: #b2f5ea;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(178, 245, 234, 0));
}

.tw-via-teal-300 {
  --gradient-via-color: #81e6d9;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(129, 230, 217, 0));
}

.tw-via-teal-400 {
  --gradient-via-color: #4fd1c5;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(79, 209, 197, 0));
}

.tw-via-teal-500 {
  --gradient-via-color: #38b2ac;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(56, 178, 172, 0));
}

.tw-via-teal-600 {
  --gradient-via-color: #319795;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(49, 151, 149, 0));
}

.tw-via-teal-700 {
  --gradient-via-color: #2c7a7b;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(44, 122, 123, 0));
}

.tw-via-teal-800 {
  --gradient-via-color: #285e61;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(40, 94, 97, 0));
}

.tw-via-teal-900 {
  --gradient-via-color: #234e52;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(35, 78, 82, 0));
}

.tw-via-blue-100 {
  --gradient-via-color: #ebf8ff;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(235, 248, 255, 0));
}

.tw-via-blue-200 {
  --gradient-via-color: #bee3f8;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(190, 227, 248, 0));
}

.tw-via-blue-300 {
  --gradient-via-color: #90cdf4;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(144, 205, 244, 0));
}

.tw-via-blue-400 {
  --gradient-via-color: #63b3ed;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(99, 179, 237, 0));
}

.tw-via-blue-500 {
  --gradient-via-color: #4299e1;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(66, 153, 225, 0));
}

.tw-via-blue-600 {
  --gradient-via-color: #3182ce;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(49, 130, 206, 0));
}

.tw-via-blue-700 {
  --gradient-via-color: #2b6cb0;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(43, 108, 176, 0));
}

.tw-via-blue-800 {
  --gradient-via-color: #2c5282;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(44, 82, 130, 0));
}

.tw-via-blue-900 {
  --gradient-via-color: #2a4365;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(42, 67, 101, 0));
}

.tw-via-indigo-100 {
  --gradient-via-color: #ebf4ff;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(235, 244, 255, 0));
}

.tw-via-indigo-200 {
  --gradient-via-color: #c3dafe;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(195, 218, 254, 0));
}

.tw-via-indigo-300 {
  --gradient-via-color: #a3bffa;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(163, 191, 250, 0));
}

.tw-via-indigo-400 {
  --gradient-via-color: #7f9cf5;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(127, 156, 245, 0));
}

.tw-via-indigo-500 {
  --gradient-via-color: #667eea;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(102, 126, 234, 0));
}

.tw-via-indigo-600 {
  --gradient-via-color: #5a67d8;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(90, 103, 216, 0));
}

.tw-via-indigo-700 {
  --gradient-via-color: #4c51bf;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(76, 81, 191, 0));
}

.tw-via-indigo-800 {
  --gradient-via-color: #434190;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(67, 65, 144, 0));
}

.tw-via-indigo-900 {
  --gradient-via-color: #3c366b;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(60, 54, 107, 0));
}

.tw-via-purple-100 {
  --gradient-via-color: #faf5ff;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(250, 245, 255, 0));
}

.tw-via-purple-200 {
  --gradient-via-color: #e9d8fd;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(233, 216, 253, 0));
}

.tw-via-purple-300 {
  --gradient-via-color: #d6bcfa;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(214, 188, 250, 0));
}

.tw-via-purple-400 {
  --gradient-via-color: #b794f4;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(183, 148, 244, 0));
}

.tw-via-purple-500 {
  --gradient-via-color: #9f7aea;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(159, 122, 234, 0));
}

.tw-via-purple-600 {
  --gradient-via-color: #805ad5;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(128, 90, 213, 0));
}

.tw-via-purple-700 {
  --gradient-via-color: #6b46c1;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(107, 70, 193, 0));
}

.tw-via-purple-800 {
  --gradient-via-color: #553c9a;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(85, 60, 154, 0));
}

.tw-via-purple-900 {
  --gradient-via-color: #44337a;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(68, 51, 122, 0));
}

.tw-via-pink-100 {
  --gradient-via-color: #fff5f7;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 245, 247, 0));
}

.tw-via-pink-200 {
  --gradient-via-color: #fed7e2;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(254, 215, 226, 0));
}

.tw-via-pink-300 {
  --gradient-via-color: #fbb6ce;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(251, 182, 206, 0));
}

.tw-via-pink-400 {
  --gradient-via-color: #f687b3;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(246, 135, 179, 0));
}

.tw-via-pink-500 {
  --gradient-via-color: #ed64a6;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(237, 100, 166, 0));
}

.tw-via-pink-600 {
  --gradient-via-color: #d53f8c;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(213, 63, 140, 0));
}

.tw-via-pink-700 {
  --gradient-via-color: #b83280;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(184, 50, 128, 0));
}

.tw-via-pink-800 {
  --gradient-via-color: #97266d;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(151, 38, 109, 0));
}

.tw-via-pink-900 {
  --gradient-via-color: #702459;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(112, 36, 89, 0));
}

.tw-to-transparent {
  --gradient-to-color: transparent;
}

.tw-to-current {
  --gradient-to-color: currentColor;
}

.tw-to-black {
  --gradient-to-color: #000;
}

.tw-to-white {
  --gradient-to-color: #fff;
}

.tw-to-gray-100 {
  --gradient-to-color: #f7fafc;
}

.tw-to-gray-200 {
  --gradient-to-color: #edf2f7;
}

.tw-to-gray-300 {
  --gradient-to-color: #e2e8f0;
}

.tw-to-gray-400 {
  --gradient-to-color: #cbd5e0;
}

.tw-to-gray-500 {
  --gradient-to-color: #a0aec0;
}

.tw-to-gray-600 {
  --gradient-to-color: #718096;
}

.tw-to-gray-700 {
  --gradient-to-color: #4a5568;
}

.tw-to-gray-800 {
  --gradient-to-color: #2d3748;
}

.tw-to-gray-900 {
  --gradient-to-color: #1a202c;
}

.tw-to-red-100 {
  --gradient-to-color: #fff5f5;
}

.tw-to-red-200 {
  --gradient-to-color: #fed7d7;
}

.tw-to-red-300 {
  --gradient-to-color: #feb2b2;
}

.tw-to-red-400 {
  --gradient-to-color: #fc8181;
}

.tw-to-red-500 {
  --gradient-to-color: #f56565;
}

.tw-to-red-600 {
  --gradient-to-color: #e53e3e;
}

.tw-to-red-700 {
  --gradient-to-color: #c53030;
}

.tw-to-red-800 {
  --gradient-to-color: #9b2c2c;
}

.tw-to-red-900 {
  --gradient-to-color: #742a2a;
}

.tw-to-orange-100 {
  --gradient-to-color: #fffaf0;
}

.tw-to-orange-200 {
  --gradient-to-color: #feebc8;
}

.tw-to-orange-300 {
  --gradient-to-color: #fbd38d;
}

.tw-to-orange-400 {
  --gradient-to-color: #f6ad55;
}

.tw-to-orange-500 {
  --gradient-to-color: #ed8936;
}

.tw-to-orange-600 {
  --gradient-to-color: #dd6b20;
}

.tw-to-orange-700 {
  --gradient-to-color: #c05621;
}

.tw-to-orange-800 {
  --gradient-to-color: #9c4221;
}

.tw-to-orange-900 {
  --gradient-to-color: #7b341e;
}

.tw-to-yellow-100 {
  --gradient-to-color: #fffff0;
}

.tw-to-yellow-200 {
  --gradient-to-color: #fefcbf;
}

.tw-to-yellow-300 {
  --gradient-to-color: #faf089;
}

.tw-to-yellow-400 {
  --gradient-to-color: #f6e05e;
}

.tw-to-yellow-500 {
  --gradient-to-color: #ecc94b;
}

.tw-to-yellow-600 {
  --gradient-to-color: #d69e2e;
}

.tw-to-yellow-700 {
  --gradient-to-color: #b7791f;
}

.tw-to-yellow-800 {
  --gradient-to-color: #975a16;
}

.tw-to-yellow-900 {
  --gradient-to-color: #744210;
}

.tw-to-green-100 {
  --gradient-to-color: #f0fff4;
}

.tw-to-green-200 {
  --gradient-to-color: #c6f6d5;
}

.tw-to-green-300 {
  --gradient-to-color: #9ae6b4;
}

.tw-to-green-400 {
  --gradient-to-color: #68d391;
}

.tw-to-green-500 {
  --gradient-to-color: #48bb78;
}

.tw-to-green-600 {
  --gradient-to-color: #38a169;
}

.tw-to-green-700 {
  --gradient-to-color: #2f855a;
}

.tw-to-green-800 {
  --gradient-to-color: #276749;
}

.tw-to-green-900 {
  --gradient-to-color: #22543d;
}

.tw-to-teal-100 {
  --gradient-to-color: #e6fffa;
}

.tw-to-teal-200 {
  --gradient-to-color: #b2f5ea;
}

.tw-to-teal-300 {
  --gradient-to-color: #81e6d9;
}

.tw-to-teal-400 {
  --gradient-to-color: #4fd1c5;
}

.tw-to-teal-500 {
  --gradient-to-color: #38b2ac;
}

.tw-to-teal-600 {
  --gradient-to-color: #319795;
}

.tw-to-teal-700 {
  --gradient-to-color: #2c7a7b;
}

.tw-to-teal-800 {
  --gradient-to-color: #285e61;
}

.tw-to-teal-900 {
  --gradient-to-color: #234e52;
}

.tw-to-blue-100 {
  --gradient-to-color: #ebf8ff;
}

.tw-to-blue-200 {
  --gradient-to-color: #bee3f8;
}

.tw-to-blue-300 {
  --gradient-to-color: #90cdf4;
}

.tw-to-blue-400 {
  --gradient-to-color: #63b3ed;
}

.tw-to-blue-500 {
  --gradient-to-color: #4299e1;
}

.tw-to-blue-600 {
  --gradient-to-color: #3182ce;
}

.tw-to-blue-700 {
  --gradient-to-color: #2b6cb0;
}

.tw-to-blue-800 {
  --gradient-to-color: #2c5282;
}

.tw-to-blue-900 {
  --gradient-to-color: #2a4365;
}

.tw-to-indigo-100 {
  --gradient-to-color: #ebf4ff;
}

.tw-to-indigo-200 {
  --gradient-to-color: #c3dafe;
}

.tw-to-indigo-300 {
  --gradient-to-color: #a3bffa;
}

.tw-to-indigo-400 {
  --gradient-to-color: #7f9cf5;
}

.tw-to-indigo-500 {
  --gradient-to-color: #667eea;
}

.tw-to-indigo-600 {
  --gradient-to-color: #5a67d8;
}

.tw-to-indigo-700 {
  --gradient-to-color: #4c51bf;
}

.tw-to-indigo-800 {
  --gradient-to-color: #434190;
}

.tw-to-indigo-900 {
  --gradient-to-color: #3c366b;
}

.tw-to-purple-100 {
  --gradient-to-color: #faf5ff;
}

.tw-to-purple-200 {
  --gradient-to-color: #e9d8fd;
}

.tw-to-purple-300 {
  --gradient-to-color: #d6bcfa;
}

.tw-to-purple-400 {
  --gradient-to-color: #b794f4;
}

.tw-to-purple-500 {
  --gradient-to-color: #9f7aea;
}

.tw-to-purple-600 {
  --gradient-to-color: #805ad5;
}

.tw-to-purple-700 {
  --gradient-to-color: #6b46c1;
}

.tw-to-purple-800 {
  --gradient-to-color: #553c9a;
}

.tw-to-purple-900 {
  --gradient-to-color: #44337a;
}

.tw-to-pink-100 {
  --gradient-to-color: #fff5f7;
}

.tw-to-pink-200 {
  --gradient-to-color: #fed7e2;
}

.tw-to-pink-300 {
  --gradient-to-color: #fbb6ce;
}

.tw-to-pink-400 {
  --gradient-to-color: #f687b3;
}

.tw-to-pink-500 {
  --gradient-to-color: #ed64a6;
}

.tw-to-pink-600 {
  --gradient-to-color: #d53f8c;
}

.tw-to-pink-700 {
  --gradient-to-color: #b83280;
}

.tw-to-pink-800 {
  --gradient-to-color: #97266d;
}

.tw-to-pink-900 {
  --gradient-to-color: #702459;
}

.hover\:tw-from-transparent:hover {
  --gradient-from-color: transparent;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(0, 0, 0, 0));
}

.hover\:tw-from-current:hover {
  --gradient-from-color: currentColor;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0));
}

.hover\:tw-from-black:hover {
  --gradient-from-color: #000;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(0, 0, 0, 0));
}

.hover\:tw-from-white:hover {
  --gradient-from-color: #fff;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0));
}

.hover\:tw-from-gray-100:hover {
  --gradient-from-color: #f7fafc;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(247, 250, 252, 0));
}

.hover\:tw-from-gray-200:hover {
  --gradient-from-color: #edf2f7;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(237, 242, 247, 0));
}

.hover\:tw-from-gray-300:hover {
  --gradient-from-color: #e2e8f0;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(226, 232, 240, 0));
}

.hover\:tw-from-gray-400:hover {
  --gradient-from-color: #cbd5e0;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(203, 213, 224, 0));
}

.hover\:tw-from-gray-500:hover {
  --gradient-from-color: #a0aec0;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(160, 174, 192, 0));
}

.hover\:tw-from-gray-600:hover {
  --gradient-from-color: #718096;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(113, 128, 150, 0));
}

.hover\:tw-from-gray-700:hover {
  --gradient-from-color: #4a5568;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(74, 85, 104, 0));
}

.hover\:tw-from-gray-800:hover {
  --gradient-from-color: #2d3748;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(45, 55, 72, 0));
}

.hover\:tw-from-gray-900:hover {
  --gradient-from-color: #1a202c;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(26, 32, 44, 0));
}

.hover\:tw-from-red-100:hover {
  --gradient-from-color: #fff5f5;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 245, 245, 0));
}

.hover\:tw-from-red-200:hover {
  --gradient-from-color: #fed7d7;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(254, 215, 215, 0));
}

.hover\:tw-from-red-300:hover {
  --gradient-from-color: #feb2b2;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(254, 178, 178, 0));
}

.hover\:tw-from-red-400:hover {
  --gradient-from-color: #fc8181;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(252, 129, 129, 0));
}

.hover\:tw-from-red-500:hover {
  --gradient-from-color: #f56565;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(245, 101, 101, 0));
}

.hover\:tw-from-red-600:hover {
  --gradient-from-color: #e53e3e;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(229, 62, 62, 0));
}

.hover\:tw-from-red-700:hover {
  --gradient-from-color: #c53030;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(197, 48, 48, 0));
}

.hover\:tw-from-red-800:hover {
  --gradient-from-color: #9b2c2c;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(155, 44, 44, 0));
}

.hover\:tw-from-red-900:hover {
  --gradient-from-color: #742a2a;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(116, 42, 42, 0));
}

.hover\:tw-from-orange-100:hover {
  --gradient-from-color: #fffaf0;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 250, 240, 0));
}

.hover\:tw-from-orange-200:hover {
  --gradient-from-color: #feebc8;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(254, 235, 200, 0));
}

.hover\:tw-from-orange-300:hover {
  --gradient-from-color: #fbd38d;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(251, 211, 141, 0));
}

.hover\:tw-from-orange-400:hover {
  --gradient-from-color: #f6ad55;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(246, 173, 85, 0));
}

.hover\:tw-from-orange-500:hover {
  --gradient-from-color: #ed8936;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(237, 137, 54, 0));
}

.hover\:tw-from-orange-600:hover {
  --gradient-from-color: #dd6b20;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(221, 107, 32, 0));
}

.hover\:tw-from-orange-700:hover {
  --gradient-from-color: #c05621;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(192, 86, 33, 0));
}

.hover\:tw-from-orange-800:hover {
  --gradient-from-color: #9c4221;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(156, 66, 33, 0));
}

.hover\:tw-from-orange-900:hover {
  --gradient-from-color: #7b341e;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(123, 52, 30, 0));
}

.hover\:tw-from-yellow-100:hover {
  --gradient-from-color: #fffff0;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 240, 0));
}

.hover\:tw-from-yellow-200:hover {
  --gradient-from-color: #fefcbf;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(254, 252, 191, 0));
}

.hover\:tw-from-yellow-300:hover {
  --gradient-from-color: #faf089;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(250, 240, 137, 0));
}

.hover\:tw-from-yellow-400:hover {
  --gradient-from-color: #f6e05e;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(246, 224, 94, 0));
}

.hover\:tw-from-yellow-500:hover {
  --gradient-from-color: #ecc94b;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(236, 201, 75, 0));
}

.hover\:tw-from-yellow-600:hover {
  --gradient-from-color: #d69e2e;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(214, 158, 46, 0));
}

.hover\:tw-from-yellow-700:hover {
  --gradient-from-color: #b7791f;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(183, 121, 31, 0));
}

.hover\:tw-from-yellow-800:hover {
  --gradient-from-color: #975a16;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(151, 90, 22, 0));
}

.hover\:tw-from-yellow-900:hover {
  --gradient-from-color: #744210;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(116, 66, 16, 0));
}

.hover\:tw-from-green-100:hover {
  --gradient-from-color: #f0fff4;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(240, 255, 244, 0));
}

.hover\:tw-from-green-200:hover {
  --gradient-from-color: #c6f6d5;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(198, 246, 213, 0));
}

.hover\:tw-from-green-300:hover {
  --gradient-from-color: #9ae6b4;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(154, 230, 180, 0));
}

.hover\:tw-from-green-400:hover {
  --gradient-from-color: #68d391;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(104, 211, 145, 0));
}

.hover\:tw-from-green-500:hover {
  --gradient-from-color: #48bb78;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(72, 187, 120, 0));
}

.hover\:tw-from-green-600:hover {
  --gradient-from-color: #38a169;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(56, 161, 105, 0));
}

.hover\:tw-from-green-700:hover {
  --gradient-from-color: #2f855a;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(47, 133, 90, 0));
}

.hover\:tw-from-green-800:hover {
  --gradient-from-color: #276749;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(39, 103, 73, 0));
}

.hover\:tw-from-green-900:hover {
  --gradient-from-color: #22543d;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(34, 84, 61, 0));
}

.hover\:tw-from-teal-100:hover {
  --gradient-from-color: #e6fffa;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(230, 255, 250, 0));
}

.hover\:tw-from-teal-200:hover {
  --gradient-from-color: #b2f5ea;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(178, 245, 234, 0));
}

.hover\:tw-from-teal-300:hover {
  --gradient-from-color: #81e6d9;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(129, 230, 217, 0));
}

.hover\:tw-from-teal-400:hover {
  --gradient-from-color: #4fd1c5;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(79, 209, 197, 0));
}

.hover\:tw-from-teal-500:hover {
  --gradient-from-color: #38b2ac;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(56, 178, 172, 0));
}

.hover\:tw-from-teal-600:hover {
  --gradient-from-color: #319795;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(49, 151, 149, 0));
}

.hover\:tw-from-teal-700:hover {
  --gradient-from-color: #2c7a7b;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(44, 122, 123, 0));
}

.hover\:tw-from-teal-800:hover {
  --gradient-from-color: #285e61;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(40, 94, 97, 0));
}

.hover\:tw-from-teal-900:hover {
  --gradient-from-color: #234e52;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(35, 78, 82, 0));
}

.hover\:tw-from-blue-100:hover {
  --gradient-from-color: #ebf8ff;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(235, 248, 255, 0));
}

.hover\:tw-from-blue-200:hover {
  --gradient-from-color: #bee3f8;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(190, 227, 248, 0));
}

.hover\:tw-from-blue-300:hover {
  --gradient-from-color: #90cdf4;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(144, 205, 244, 0));
}

.hover\:tw-from-blue-400:hover {
  --gradient-from-color: #63b3ed;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(99, 179, 237, 0));
}

.hover\:tw-from-blue-500:hover {
  --gradient-from-color: #4299e1;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(66, 153, 225, 0));
}

.hover\:tw-from-blue-600:hover {
  --gradient-from-color: #3182ce;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(49, 130, 206, 0));
}

.hover\:tw-from-blue-700:hover {
  --gradient-from-color: #2b6cb0;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(43, 108, 176, 0));
}

.hover\:tw-from-blue-800:hover {
  --gradient-from-color: #2c5282;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(44, 82, 130, 0));
}

.hover\:tw-from-blue-900:hover {
  --gradient-from-color: #2a4365;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(42, 67, 101, 0));
}

.hover\:tw-from-indigo-100:hover {
  --gradient-from-color: #ebf4ff;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(235, 244, 255, 0));
}

.hover\:tw-from-indigo-200:hover {
  --gradient-from-color: #c3dafe;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(195, 218, 254, 0));
}

.hover\:tw-from-indigo-300:hover {
  --gradient-from-color: #a3bffa;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(163, 191, 250, 0));
}

.hover\:tw-from-indigo-400:hover {
  --gradient-from-color: #7f9cf5;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(127, 156, 245, 0));
}

.hover\:tw-from-indigo-500:hover {
  --gradient-from-color: #667eea;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(102, 126, 234, 0));
}

.hover\:tw-from-indigo-600:hover {
  --gradient-from-color: #5a67d8;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(90, 103, 216, 0));
}

.hover\:tw-from-indigo-700:hover {
  --gradient-from-color: #4c51bf;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(76, 81, 191, 0));
}

.hover\:tw-from-indigo-800:hover {
  --gradient-from-color: #434190;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(67, 65, 144, 0));
}

.hover\:tw-from-indigo-900:hover {
  --gradient-from-color: #3c366b;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(60, 54, 107, 0));
}

.hover\:tw-from-purple-100:hover {
  --gradient-from-color: #faf5ff;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(250, 245, 255, 0));
}

.hover\:tw-from-purple-200:hover {
  --gradient-from-color: #e9d8fd;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(233, 216, 253, 0));
}

.hover\:tw-from-purple-300:hover {
  --gradient-from-color: #d6bcfa;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(214, 188, 250, 0));
}

.hover\:tw-from-purple-400:hover {
  --gradient-from-color: #b794f4;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(183, 148, 244, 0));
}

.hover\:tw-from-purple-500:hover {
  --gradient-from-color: #9f7aea;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(159, 122, 234, 0));
}

.hover\:tw-from-purple-600:hover {
  --gradient-from-color: #805ad5;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(128, 90, 213, 0));
}

.hover\:tw-from-purple-700:hover {
  --gradient-from-color: #6b46c1;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(107, 70, 193, 0));
}

.hover\:tw-from-purple-800:hover {
  --gradient-from-color: #553c9a;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(85, 60, 154, 0));
}

.hover\:tw-from-purple-900:hover {
  --gradient-from-color: #44337a;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(68, 51, 122, 0));
}

.hover\:tw-from-pink-100:hover {
  --gradient-from-color: #fff5f7;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 245, 247, 0));
}

.hover\:tw-from-pink-200:hover {
  --gradient-from-color: #fed7e2;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(254, 215, 226, 0));
}

.hover\:tw-from-pink-300:hover {
  --gradient-from-color: #fbb6ce;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(251, 182, 206, 0));
}

.hover\:tw-from-pink-400:hover {
  --gradient-from-color: #f687b3;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(246, 135, 179, 0));
}

.hover\:tw-from-pink-500:hover {
  --gradient-from-color: #ed64a6;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(237, 100, 166, 0));
}

.hover\:tw-from-pink-600:hover {
  --gradient-from-color: #d53f8c;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(213, 63, 140, 0));
}

.hover\:tw-from-pink-700:hover {
  --gradient-from-color: #b83280;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(184, 50, 128, 0));
}

.hover\:tw-from-pink-800:hover {
  --gradient-from-color: #97266d;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(151, 38, 109, 0));
}

.hover\:tw-from-pink-900:hover {
  --gradient-from-color: #702459;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(112, 36, 89, 0));
}

.hover\:tw-via-transparent:hover {
  --gradient-via-color: transparent;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(0, 0, 0, 0));
}

.hover\:tw-via-current:hover {
  --gradient-via-color: currentColor;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0));
}

.hover\:tw-via-black:hover {
  --gradient-via-color: #000;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(0, 0, 0, 0));
}

.hover\:tw-via-white:hover {
  --gradient-via-color: #fff;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0));
}

.hover\:tw-via-gray-100:hover {
  --gradient-via-color: #f7fafc;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(247, 250, 252, 0));
}

.hover\:tw-via-gray-200:hover {
  --gradient-via-color: #edf2f7;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(237, 242, 247, 0));
}

.hover\:tw-via-gray-300:hover {
  --gradient-via-color: #e2e8f0;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(226, 232, 240, 0));
}

.hover\:tw-via-gray-400:hover {
  --gradient-via-color: #cbd5e0;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(203, 213, 224, 0));
}

.hover\:tw-via-gray-500:hover {
  --gradient-via-color: #a0aec0;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(160, 174, 192, 0));
}

.hover\:tw-via-gray-600:hover {
  --gradient-via-color: #718096;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(113, 128, 150, 0));
}

.hover\:tw-via-gray-700:hover {
  --gradient-via-color: #4a5568;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(74, 85, 104, 0));
}

.hover\:tw-via-gray-800:hover {
  --gradient-via-color: #2d3748;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(45, 55, 72, 0));
}

.hover\:tw-via-gray-900:hover {
  --gradient-via-color: #1a202c;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(26, 32, 44, 0));
}

.hover\:tw-via-red-100:hover {
  --gradient-via-color: #fff5f5;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 245, 245, 0));
}

.hover\:tw-via-red-200:hover {
  --gradient-via-color: #fed7d7;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(254, 215, 215, 0));
}

.hover\:tw-via-red-300:hover {
  --gradient-via-color: #feb2b2;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(254, 178, 178, 0));
}

.hover\:tw-via-red-400:hover {
  --gradient-via-color: #fc8181;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(252, 129, 129, 0));
}

.hover\:tw-via-red-500:hover {
  --gradient-via-color: #f56565;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(245, 101, 101, 0));
}

.hover\:tw-via-red-600:hover {
  --gradient-via-color: #e53e3e;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(229, 62, 62, 0));
}

.hover\:tw-via-red-700:hover {
  --gradient-via-color: #c53030;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(197, 48, 48, 0));
}

.hover\:tw-via-red-800:hover {
  --gradient-via-color: #9b2c2c;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(155, 44, 44, 0));
}

.hover\:tw-via-red-900:hover {
  --gradient-via-color: #742a2a;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(116, 42, 42, 0));
}

.hover\:tw-via-orange-100:hover {
  --gradient-via-color: #fffaf0;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 250, 240, 0));
}

.hover\:tw-via-orange-200:hover {
  --gradient-via-color: #feebc8;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(254, 235, 200, 0));
}

.hover\:tw-via-orange-300:hover {
  --gradient-via-color: #fbd38d;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(251, 211, 141, 0));
}

.hover\:tw-via-orange-400:hover {
  --gradient-via-color: #f6ad55;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(246, 173, 85, 0));
}

.hover\:tw-via-orange-500:hover {
  --gradient-via-color: #ed8936;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(237, 137, 54, 0));
}

.hover\:tw-via-orange-600:hover {
  --gradient-via-color: #dd6b20;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(221, 107, 32, 0));
}

.hover\:tw-via-orange-700:hover {
  --gradient-via-color: #c05621;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(192, 86, 33, 0));
}

.hover\:tw-via-orange-800:hover {
  --gradient-via-color: #9c4221;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(156, 66, 33, 0));
}

.hover\:tw-via-orange-900:hover {
  --gradient-via-color: #7b341e;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(123, 52, 30, 0));
}

.hover\:tw-via-yellow-100:hover {
  --gradient-via-color: #fffff0;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 240, 0));
}

.hover\:tw-via-yellow-200:hover {
  --gradient-via-color: #fefcbf;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(254, 252, 191, 0));
}

.hover\:tw-via-yellow-300:hover {
  --gradient-via-color: #faf089;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(250, 240, 137, 0));
}

.hover\:tw-via-yellow-400:hover {
  --gradient-via-color: #f6e05e;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(246, 224, 94, 0));
}

.hover\:tw-via-yellow-500:hover {
  --gradient-via-color: #ecc94b;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(236, 201, 75, 0));
}

.hover\:tw-via-yellow-600:hover {
  --gradient-via-color: #d69e2e;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(214, 158, 46, 0));
}

.hover\:tw-via-yellow-700:hover {
  --gradient-via-color: #b7791f;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(183, 121, 31, 0));
}

.hover\:tw-via-yellow-800:hover {
  --gradient-via-color: #975a16;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(151, 90, 22, 0));
}

.hover\:tw-via-yellow-900:hover {
  --gradient-via-color: #744210;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(116, 66, 16, 0));
}

.hover\:tw-via-green-100:hover {
  --gradient-via-color: #f0fff4;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(240, 255, 244, 0));
}

.hover\:tw-via-green-200:hover {
  --gradient-via-color: #c6f6d5;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(198, 246, 213, 0));
}

.hover\:tw-via-green-300:hover {
  --gradient-via-color: #9ae6b4;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(154, 230, 180, 0));
}

.hover\:tw-via-green-400:hover {
  --gradient-via-color: #68d391;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(104, 211, 145, 0));
}

.hover\:tw-via-green-500:hover {
  --gradient-via-color: #48bb78;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(72, 187, 120, 0));
}

.hover\:tw-via-green-600:hover {
  --gradient-via-color: #38a169;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(56, 161, 105, 0));
}

.hover\:tw-via-green-700:hover {
  --gradient-via-color: #2f855a;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(47, 133, 90, 0));
}

.hover\:tw-via-green-800:hover {
  --gradient-via-color: #276749;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(39, 103, 73, 0));
}

.hover\:tw-via-green-900:hover {
  --gradient-via-color: #22543d;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(34, 84, 61, 0));
}

.hover\:tw-via-teal-100:hover {
  --gradient-via-color: #e6fffa;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(230, 255, 250, 0));
}

.hover\:tw-via-teal-200:hover {
  --gradient-via-color: #b2f5ea;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(178, 245, 234, 0));
}

.hover\:tw-via-teal-300:hover {
  --gradient-via-color: #81e6d9;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(129, 230, 217, 0));
}

.hover\:tw-via-teal-400:hover {
  --gradient-via-color: #4fd1c5;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(79, 209, 197, 0));
}

.hover\:tw-via-teal-500:hover {
  --gradient-via-color: #38b2ac;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(56, 178, 172, 0));
}

.hover\:tw-via-teal-600:hover {
  --gradient-via-color: #319795;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(49, 151, 149, 0));
}

.hover\:tw-via-teal-700:hover {
  --gradient-via-color: #2c7a7b;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(44, 122, 123, 0));
}

.hover\:tw-via-teal-800:hover {
  --gradient-via-color: #285e61;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(40, 94, 97, 0));
}

.hover\:tw-via-teal-900:hover {
  --gradient-via-color: #234e52;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(35, 78, 82, 0));
}

.hover\:tw-via-blue-100:hover {
  --gradient-via-color: #ebf8ff;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(235, 248, 255, 0));
}

.hover\:tw-via-blue-200:hover {
  --gradient-via-color: #bee3f8;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(190, 227, 248, 0));
}

.hover\:tw-via-blue-300:hover {
  --gradient-via-color: #90cdf4;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(144, 205, 244, 0));
}

.hover\:tw-via-blue-400:hover {
  --gradient-via-color: #63b3ed;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(99, 179, 237, 0));
}

.hover\:tw-via-blue-500:hover {
  --gradient-via-color: #4299e1;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(66, 153, 225, 0));
}

.hover\:tw-via-blue-600:hover {
  --gradient-via-color: #3182ce;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(49, 130, 206, 0));
}

.hover\:tw-via-blue-700:hover {
  --gradient-via-color: #2b6cb0;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(43, 108, 176, 0));
}

.hover\:tw-via-blue-800:hover {
  --gradient-via-color: #2c5282;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(44, 82, 130, 0));
}

.hover\:tw-via-blue-900:hover {
  --gradient-via-color: #2a4365;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(42, 67, 101, 0));
}

.hover\:tw-via-indigo-100:hover {
  --gradient-via-color: #ebf4ff;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(235, 244, 255, 0));
}

.hover\:tw-via-indigo-200:hover {
  --gradient-via-color: #c3dafe;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(195, 218, 254, 0));
}

.hover\:tw-via-indigo-300:hover {
  --gradient-via-color: #a3bffa;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(163, 191, 250, 0));
}

.hover\:tw-via-indigo-400:hover {
  --gradient-via-color: #7f9cf5;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(127, 156, 245, 0));
}

.hover\:tw-via-indigo-500:hover {
  --gradient-via-color: #667eea;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(102, 126, 234, 0));
}

.hover\:tw-via-indigo-600:hover {
  --gradient-via-color: #5a67d8;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(90, 103, 216, 0));
}

.hover\:tw-via-indigo-700:hover {
  --gradient-via-color: #4c51bf;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(76, 81, 191, 0));
}

.hover\:tw-via-indigo-800:hover {
  --gradient-via-color: #434190;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(67, 65, 144, 0));
}

.hover\:tw-via-indigo-900:hover {
  --gradient-via-color: #3c366b;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(60, 54, 107, 0));
}

.hover\:tw-via-purple-100:hover {
  --gradient-via-color: #faf5ff;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(250, 245, 255, 0));
}

.hover\:tw-via-purple-200:hover {
  --gradient-via-color: #e9d8fd;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(233, 216, 253, 0));
}

.hover\:tw-via-purple-300:hover {
  --gradient-via-color: #d6bcfa;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(214, 188, 250, 0));
}

.hover\:tw-via-purple-400:hover {
  --gradient-via-color: #b794f4;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(183, 148, 244, 0));
}

.hover\:tw-via-purple-500:hover {
  --gradient-via-color: #9f7aea;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(159, 122, 234, 0));
}

.hover\:tw-via-purple-600:hover {
  --gradient-via-color: #805ad5;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(128, 90, 213, 0));
}

.hover\:tw-via-purple-700:hover {
  --gradient-via-color: #6b46c1;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(107, 70, 193, 0));
}

.hover\:tw-via-purple-800:hover {
  --gradient-via-color: #553c9a;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(85, 60, 154, 0));
}

.hover\:tw-via-purple-900:hover {
  --gradient-via-color: #44337a;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(68, 51, 122, 0));
}

.hover\:tw-via-pink-100:hover {
  --gradient-via-color: #fff5f7;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 245, 247, 0));
}

.hover\:tw-via-pink-200:hover {
  --gradient-via-color: #fed7e2;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(254, 215, 226, 0));
}

.hover\:tw-via-pink-300:hover {
  --gradient-via-color: #fbb6ce;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(251, 182, 206, 0));
}

.hover\:tw-via-pink-400:hover {
  --gradient-via-color: #f687b3;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(246, 135, 179, 0));
}

.hover\:tw-via-pink-500:hover {
  --gradient-via-color: #ed64a6;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(237, 100, 166, 0));
}

.hover\:tw-via-pink-600:hover {
  --gradient-via-color: #d53f8c;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(213, 63, 140, 0));
}

.hover\:tw-via-pink-700:hover {
  --gradient-via-color: #b83280;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(184, 50, 128, 0));
}

.hover\:tw-via-pink-800:hover {
  --gradient-via-color: #97266d;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(151, 38, 109, 0));
}

.hover\:tw-via-pink-900:hover {
  --gradient-via-color: #702459;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(112, 36, 89, 0));
}

.hover\:tw-to-transparent:hover {
  --gradient-to-color: transparent;
}

.hover\:tw-to-current:hover {
  --gradient-to-color: currentColor;
}

.hover\:tw-to-black:hover {
  --gradient-to-color: #000;
}

.hover\:tw-to-white:hover {
  --gradient-to-color: #fff;
}

.hover\:tw-to-gray-100:hover {
  --gradient-to-color: #f7fafc;
}

.hover\:tw-to-gray-200:hover {
  --gradient-to-color: #edf2f7;
}

.hover\:tw-to-gray-300:hover {
  --gradient-to-color: #e2e8f0;
}

.hover\:tw-to-gray-400:hover {
  --gradient-to-color: #cbd5e0;
}

.hover\:tw-to-gray-500:hover {
  --gradient-to-color: #a0aec0;
}

.hover\:tw-to-gray-600:hover {
  --gradient-to-color: #718096;
}

.hover\:tw-to-gray-700:hover {
  --gradient-to-color: #4a5568;
}

.hover\:tw-to-gray-800:hover {
  --gradient-to-color: #2d3748;
}

.hover\:tw-to-gray-900:hover {
  --gradient-to-color: #1a202c;
}

.hover\:tw-to-red-100:hover {
  --gradient-to-color: #fff5f5;
}

.hover\:tw-to-red-200:hover {
  --gradient-to-color: #fed7d7;
}

.hover\:tw-to-red-300:hover {
  --gradient-to-color: #feb2b2;
}

.hover\:tw-to-red-400:hover {
  --gradient-to-color: #fc8181;
}

.hover\:tw-to-red-500:hover {
  --gradient-to-color: #f56565;
}

.hover\:tw-to-red-600:hover {
  --gradient-to-color: #e53e3e;
}

.hover\:tw-to-red-700:hover {
  --gradient-to-color: #c53030;
}

.hover\:tw-to-red-800:hover {
  --gradient-to-color: #9b2c2c;
}

.hover\:tw-to-red-900:hover {
  --gradient-to-color: #742a2a;
}

.hover\:tw-to-orange-100:hover {
  --gradient-to-color: #fffaf0;
}

.hover\:tw-to-orange-200:hover {
  --gradient-to-color: #feebc8;
}

.hover\:tw-to-orange-300:hover {
  --gradient-to-color: #fbd38d;
}

.hover\:tw-to-orange-400:hover {
  --gradient-to-color: #f6ad55;
}

.hover\:tw-to-orange-500:hover {
  --gradient-to-color: #ed8936;
}

.hover\:tw-to-orange-600:hover {
  --gradient-to-color: #dd6b20;
}

.hover\:tw-to-orange-700:hover {
  --gradient-to-color: #c05621;
}

.hover\:tw-to-orange-800:hover {
  --gradient-to-color: #9c4221;
}

.hover\:tw-to-orange-900:hover {
  --gradient-to-color: #7b341e;
}

.hover\:tw-to-yellow-100:hover {
  --gradient-to-color: #fffff0;
}

.hover\:tw-to-yellow-200:hover {
  --gradient-to-color: #fefcbf;
}

.hover\:tw-to-yellow-300:hover {
  --gradient-to-color: #faf089;
}

.hover\:tw-to-yellow-400:hover {
  --gradient-to-color: #f6e05e;
}

.hover\:tw-to-yellow-500:hover {
  --gradient-to-color: #ecc94b;
}

.hover\:tw-to-yellow-600:hover {
  --gradient-to-color: #d69e2e;
}

.hover\:tw-to-yellow-700:hover {
  --gradient-to-color: #b7791f;
}

.hover\:tw-to-yellow-800:hover {
  --gradient-to-color: #975a16;
}

.hover\:tw-to-yellow-900:hover {
  --gradient-to-color: #744210;
}

.hover\:tw-to-green-100:hover {
  --gradient-to-color: #f0fff4;
}

.hover\:tw-to-green-200:hover {
  --gradient-to-color: #c6f6d5;
}

.hover\:tw-to-green-300:hover {
  --gradient-to-color: #9ae6b4;
}

.hover\:tw-to-green-400:hover {
  --gradient-to-color: #68d391;
}

.hover\:tw-to-green-500:hover {
  --gradient-to-color: #48bb78;
}

.hover\:tw-to-green-600:hover {
  --gradient-to-color: #38a169;
}

.hover\:tw-to-green-700:hover {
  --gradient-to-color: #2f855a;
}

.hover\:tw-to-green-800:hover {
  --gradient-to-color: #276749;
}

.hover\:tw-to-green-900:hover {
  --gradient-to-color: #22543d;
}

.hover\:tw-to-teal-100:hover {
  --gradient-to-color: #e6fffa;
}

.hover\:tw-to-teal-200:hover {
  --gradient-to-color: #b2f5ea;
}

.hover\:tw-to-teal-300:hover {
  --gradient-to-color: #81e6d9;
}

.hover\:tw-to-teal-400:hover {
  --gradient-to-color: #4fd1c5;
}

.hover\:tw-to-teal-500:hover {
  --gradient-to-color: #38b2ac;
}

.hover\:tw-to-teal-600:hover {
  --gradient-to-color: #319795;
}

.hover\:tw-to-teal-700:hover {
  --gradient-to-color: #2c7a7b;
}

.hover\:tw-to-teal-800:hover {
  --gradient-to-color: #285e61;
}

.hover\:tw-to-teal-900:hover {
  --gradient-to-color: #234e52;
}

.hover\:tw-to-blue-100:hover {
  --gradient-to-color: #ebf8ff;
}

.hover\:tw-to-blue-200:hover {
  --gradient-to-color: #bee3f8;
}

.hover\:tw-to-blue-300:hover {
  --gradient-to-color: #90cdf4;
}

.hover\:tw-to-blue-400:hover {
  --gradient-to-color: #63b3ed;
}

.hover\:tw-to-blue-500:hover {
  --gradient-to-color: #4299e1;
}

.hover\:tw-to-blue-600:hover {
  --gradient-to-color: #3182ce;
}

.hover\:tw-to-blue-700:hover {
  --gradient-to-color: #2b6cb0;
}

.hover\:tw-to-blue-800:hover {
  --gradient-to-color: #2c5282;
}

.hover\:tw-to-blue-900:hover {
  --gradient-to-color: #2a4365;
}

.hover\:tw-to-indigo-100:hover {
  --gradient-to-color: #ebf4ff;
}

.hover\:tw-to-indigo-200:hover {
  --gradient-to-color: #c3dafe;
}

.hover\:tw-to-indigo-300:hover {
  --gradient-to-color: #a3bffa;
}

.hover\:tw-to-indigo-400:hover {
  --gradient-to-color: #7f9cf5;
}

.hover\:tw-to-indigo-500:hover {
  --gradient-to-color: #667eea;
}

.hover\:tw-to-indigo-600:hover {
  --gradient-to-color: #5a67d8;
}

.hover\:tw-to-indigo-700:hover {
  --gradient-to-color: #4c51bf;
}

.hover\:tw-to-indigo-800:hover {
  --gradient-to-color: #434190;
}

.hover\:tw-to-indigo-900:hover {
  --gradient-to-color: #3c366b;
}

.hover\:tw-to-purple-100:hover {
  --gradient-to-color: #faf5ff;
}

.hover\:tw-to-purple-200:hover {
  --gradient-to-color: #e9d8fd;
}

.hover\:tw-to-purple-300:hover {
  --gradient-to-color: #d6bcfa;
}

.hover\:tw-to-purple-400:hover {
  --gradient-to-color: #b794f4;
}

.hover\:tw-to-purple-500:hover {
  --gradient-to-color: #9f7aea;
}

.hover\:tw-to-purple-600:hover {
  --gradient-to-color: #805ad5;
}

.hover\:tw-to-purple-700:hover {
  --gradient-to-color: #6b46c1;
}

.hover\:tw-to-purple-800:hover {
  --gradient-to-color: #553c9a;
}

.hover\:tw-to-purple-900:hover {
  --gradient-to-color: #44337a;
}

.hover\:tw-to-pink-100:hover {
  --gradient-to-color: #fff5f7;
}

.hover\:tw-to-pink-200:hover {
  --gradient-to-color: #fed7e2;
}

.hover\:tw-to-pink-300:hover {
  --gradient-to-color: #fbb6ce;
}

.hover\:tw-to-pink-400:hover {
  --gradient-to-color: #f687b3;
}

.hover\:tw-to-pink-500:hover {
  --gradient-to-color: #ed64a6;
}

.hover\:tw-to-pink-600:hover {
  --gradient-to-color: #d53f8c;
}

.hover\:tw-to-pink-700:hover {
  --gradient-to-color: #b83280;
}

.hover\:tw-to-pink-800:hover {
  --gradient-to-color: #97266d;
}

.hover\:tw-to-pink-900:hover {
  --gradient-to-color: #702459;
}

.focus\:tw-from-transparent:focus {
  --gradient-from-color: transparent;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(0, 0, 0, 0));
}

.focus\:tw-from-current:focus {
  --gradient-from-color: currentColor;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0));
}

.focus\:tw-from-black:focus {
  --gradient-from-color: #000;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(0, 0, 0, 0));
}

.focus\:tw-from-white:focus {
  --gradient-from-color: #fff;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0));
}

.focus\:tw-from-gray-100:focus {
  --gradient-from-color: #f7fafc;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(247, 250, 252, 0));
}

.focus\:tw-from-gray-200:focus {
  --gradient-from-color: #edf2f7;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(237, 242, 247, 0));
}

.focus\:tw-from-gray-300:focus {
  --gradient-from-color: #e2e8f0;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(226, 232, 240, 0));
}

.focus\:tw-from-gray-400:focus {
  --gradient-from-color: #cbd5e0;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(203, 213, 224, 0));
}

.focus\:tw-from-gray-500:focus {
  --gradient-from-color: #a0aec0;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(160, 174, 192, 0));
}

.focus\:tw-from-gray-600:focus {
  --gradient-from-color: #718096;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(113, 128, 150, 0));
}

.focus\:tw-from-gray-700:focus {
  --gradient-from-color: #4a5568;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(74, 85, 104, 0));
}

.focus\:tw-from-gray-800:focus {
  --gradient-from-color: #2d3748;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(45, 55, 72, 0));
}

.focus\:tw-from-gray-900:focus {
  --gradient-from-color: #1a202c;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(26, 32, 44, 0));
}

.focus\:tw-from-red-100:focus {
  --gradient-from-color: #fff5f5;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 245, 245, 0));
}

.focus\:tw-from-red-200:focus {
  --gradient-from-color: #fed7d7;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(254, 215, 215, 0));
}

.focus\:tw-from-red-300:focus {
  --gradient-from-color: #feb2b2;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(254, 178, 178, 0));
}

.focus\:tw-from-red-400:focus {
  --gradient-from-color: #fc8181;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(252, 129, 129, 0));
}

.focus\:tw-from-red-500:focus {
  --gradient-from-color: #f56565;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(245, 101, 101, 0));
}

.focus\:tw-from-red-600:focus {
  --gradient-from-color: #e53e3e;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(229, 62, 62, 0));
}

.focus\:tw-from-red-700:focus {
  --gradient-from-color: #c53030;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(197, 48, 48, 0));
}

.focus\:tw-from-red-800:focus {
  --gradient-from-color: #9b2c2c;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(155, 44, 44, 0));
}

.focus\:tw-from-red-900:focus {
  --gradient-from-color: #742a2a;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(116, 42, 42, 0));
}

.focus\:tw-from-orange-100:focus {
  --gradient-from-color: #fffaf0;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 250, 240, 0));
}

.focus\:tw-from-orange-200:focus {
  --gradient-from-color: #feebc8;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(254, 235, 200, 0));
}

.focus\:tw-from-orange-300:focus {
  --gradient-from-color: #fbd38d;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(251, 211, 141, 0));
}

.focus\:tw-from-orange-400:focus {
  --gradient-from-color: #f6ad55;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(246, 173, 85, 0));
}

.focus\:tw-from-orange-500:focus {
  --gradient-from-color: #ed8936;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(237, 137, 54, 0));
}

.focus\:tw-from-orange-600:focus {
  --gradient-from-color: #dd6b20;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(221, 107, 32, 0));
}

.focus\:tw-from-orange-700:focus {
  --gradient-from-color: #c05621;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(192, 86, 33, 0));
}

.focus\:tw-from-orange-800:focus {
  --gradient-from-color: #9c4221;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(156, 66, 33, 0));
}

.focus\:tw-from-orange-900:focus {
  --gradient-from-color: #7b341e;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(123, 52, 30, 0));
}

.focus\:tw-from-yellow-100:focus {
  --gradient-from-color: #fffff0;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 240, 0));
}

.focus\:tw-from-yellow-200:focus {
  --gradient-from-color: #fefcbf;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(254, 252, 191, 0));
}

.focus\:tw-from-yellow-300:focus {
  --gradient-from-color: #faf089;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(250, 240, 137, 0));
}

.focus\:tw-from-yellow-400:focus {
  --gradient-from-color: #f6e05e;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(246, 224, 94, 0));
}

.focus\:tw-from-yellow-500:focus {
  --gradient-from-color: #ecc94b;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(236, 201, 75, 0));
}

.focus\:tw-from-yellow-600:focus {
  --gradient-from-color: #d69e2e;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(214, 158, 46, 0));
}

.focus\:tw-from-yellow-700:focus {
  --gradient-from-color: #b7791f;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(183, 121, 31, 0));
}

.focus\:tw-from-yellow-800:focus {
  --gradient-from-color: #975a16;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(151, 90, 22, 0));
}

.focus\:tw-from-yellow-900:focus {
  --gradient-from-color: #744210;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(116, 66, 16, 0));
}

.focus\:tw-from-green-100:focus {
  --gradient-from-color: #f0fff4;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(240, 255, 244, 0));
}

.focus\:tw-from-green-200:focus {
  --gradient-from-color: #c6f6d5;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(198, 246, 213, 0));
}

.focus\:tw-from-green-300:focus {
  --gradient-from-color: #9ae6b4;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(154, 230, 180, 0));
}

.focus\:tw-from-green-400:focus {
  --gradient-from-color: #68d391;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(104, 211, 145, 0));
}

.focus\:tw-from-green-500:focus {
  --gradient-from-color: #48bb78;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(72, 187, 120, 0));
}

.focus\:tw-from-green-600:focus {
  --gradient-from-color: #38a169;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(56, 161, 105, 0));
}

.focus\:tw-from-green-700:focus {
  --gradient-from-color: #2f855a;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(47, 133, 90, 0));
}

.focus\:tw-from-green-800:focus {
  --gradient-from-color: #276749;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(39, 103, 73, 0));
}

.focus\:tw-from-green-900:focus {
  --gradient-from-color: #22543d;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(34, 84, 61, 0));
}

.focus\:tw-from-teal-100:focus {
  --gradient-from-color: #e6fffa;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(230, 255, 250, 0));
}

.focus\:tw-from-teal-200:focus {
  --gradient-from-color: #b2f5ea;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(178, 245, 234, 0));
}

.focus\:tw-from-teal-300:focus {
  --gradient-from-color: #81e6d9;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(129, 230, 217, 0));
}

.focus\:tw-from-teal-400:focus {
  --gradient-from-color: #4fd1c5;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(79, 209, 197, 0));
}

.focus\:tw-from-teal-500:focus {
  --gradient-from-color: #38b2ac;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(56, 178, 172, 0));
}

.focus\:tw-from-teal-600:focus {
  --gradient-from-color: #319795;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(49, 151, 149, 0));
}

.focus\:tw-from-teal-700:focus {
  --gradient-from-color: #2c7a7b;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(44, 122, 123, 0));
}

.focus\:tw-from-teal-800:focus {
  --gradient-from-color: #285e61;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(40, 94, 97, 0));
}

.focus\:tw-from-teal-900:focus {
  --gradient-from-color: #234e52;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(35, 78, 82, 0));
}

.focus\:tw-from-blue-100:focus {
  --gradient-from-color: #ebf8ff;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(235, 248, 255, 0));
}

.focus\:tw-from-blue-200:focus {
  --gradient-from-color: #bee3f8;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(190, 227, 248, 0));
}

.focus\:tw-from-blue-300:focus {
  --gradient-from-color: #90cdf4;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(144, 205, 244, 0));
}

.focus\:tw-from-blue-400:focus {
  --gradient-from-color: #63b3ed;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(99, 179, 237, 0));
}

.focus\:tw-from-blue-500:focus {
  --gradient-from-color: #4299e1;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(66, 153, 225, 0));
}

.focus\:tw-from-blue-600:focus {
  --gradient-from-color: #3182ce;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(49, 130, 206, 0));
}

.focus\:tw-from-blue-700:focus {
  --gradient-from-color: #2b6cb0;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(43, 108, 176, 0));
}

.focus\:tw-from-blue-800:focus {
  --gradient-from-color: #2c5282;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(44, 82, 130, 0));
}

.focus\:tw-from-blue-900:focus {
  --gradient-from-color: #2a4365;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(42, 67, 101, 0));
}

.focus\:tw-from-indigo-100:focus {
  --gradient-from-color: #ebf4ff;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(235, 244, 255, 0));
}

.focus\:tw-from-indigo-200:focus {
  --gradient-from-color: #c3dafe;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(195, 218, 254, 0));
}

.focus\:tw-from-indigo-300:focus {
  --gradient-from-color: #a3bffa;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(163, 191, 250, 0));
}

.focus\:tw-from-indigo-400:focus {
  --gradient-from-color: #7f9cf5;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(127, 156, 245, 0));
}

.focus\:tw-from-indigo-500:focus {
  --gradient-from-color: #667eea;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(102, 126, 234, 0));
}

.focus\:tw-from-indigo-600:focus {
  --gradient-from-color: #5a67d8;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(90, 103, 216, 0));
}

.focus\:tw-from-indigo-700:focus {
  --gradient-from-color: #4c51bf;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(76, 81, 191, 0));
}

.focus\:tw-from-indigo-800:focus {
  --gradient-from-color: #434190;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(67, 65, 144, 0));
}

.focus\:tw-from-indigo-900:focus {
  --gradient-from-color: #3c366b;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(60, 54, 107, 0));
}

.focus\:tw-from-purple-100:focus {
  --gradient-from-color: #faf5ff;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(250, 245, 255, 0));
}

.focus\:tw-from-purple-200:focus {
  --gradient-from-color: #e9d8fd;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(233, 216, 253, 0));
}

.focus\:tw-from-purple-300:focus {
  --gradient-from-color: #d6bcfa;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(214, 188, 250, 0));
}

.focus\:tw-from-purple-400:focus {
  --gradient-from-color: #b794f4;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(183, 148, 244, 0));
}

.focus\:tw-from-purple-500:focus {
  --gradient-from-color: #9f7aea;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(159, 122, 234, 0));
}

.focus\:tw-from-purple-600:focus {
  --gradient-from-color: #805ad5;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(128, 90, 213, 0));
}

.focus\:tw-from-purple-700:focus {
  --gradient-from-color: #6b46c1;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(107, 70, 193, 0));
}

.focus\:tw-from-purple-800:focus {
  --gradient-from-color: #553c9a;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(85, 60, 154, 0));
}

.focus\:tw-from-purple-900:focus {
  --gradient-from-color: #44337a;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(68, 51, 122, 0));
}

.focus\:tw-from-pink-100:focus {
  --gradient-from-color: #fff5f7;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 245, 247, 0));
}

.focus\:tw-from-pink-200:focus {
  --gradient-from-color: #fed7e2;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(254, 215, 226, 0));
}

.focus\:tw-from-pink-300:focus {
  --gradient-from-color: #fbb6ce;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(251, 182, 206, 0));
}

.focus\:tw-from-pink-400:focus {
  --gradient-from-color: #f687b3;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(246, 135, 179, 0));
}

.focus\:tw-from-pink-500:focus {
  --gradient-from-color: #ed64a6;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(237, 100, 166, 0));
}

.focus\:tw-from-pink-600:focus {
  --gradient-from-color: #d53f8c;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(213, 63, 140, 0));
}

.focus\:tw-from-pink-700:focus {
  --gradient-from-color: #b83280;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(184, 50, 128, 0));
}

.focus\:tw-from-pink-800:focus {
  --gradient-from-color: #97266d;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(151, 38, 109, 0));
}

.focus\:tw-from-pink-900:focus {
  --gradient-from-color: #702459;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(112, 36, 89, 0));
}

.focus\:tw-via-transparent:focus {
  --gradient-via-color: transparent;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(0, 0, 0, 0));
}

.focus\:tw-via-current:focus {
  --gradient-via-color: currentColor;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0));
}

.focus\:tw-via-black:focus {
  --gradient-via-color: #000;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(0, 0, 0, 0));
}

.focus\:tw-via-white:focus {
  --gradient-via-color: #fff;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0));
}

.focus\:tw-via-gray-100:focus {
  --gradient-via-color: #f7fafc;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(247, 250, 252, 0));
}

.focus\:tw-via-gray-200:focus {
  --gradient-via-color: #edf2f7;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(237, 242, 247, 0));
}

.focus\:tw-via-gray-300:focus {
  --gradient-via-color: #e2e8f0;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(226, 232, 240, 0));
}

.focus\:tw-via-gray-400:focus {
  --gradient-via-color: #cbd5e0;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(203, 213, 224, 0));
}

.focus\:tw-via-gray-500:focus {
  --gradient-via-color: #a0aec0;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(160, 174, 192, 0));
}

.focus\:tw-via-gray-600:focus {
  --gradient-via-color: #718096;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(113, 128, 150, 0));
}

.focus\:tw-via-gray-700:focus {
  --gradient-via-color: #4a5568;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(74, 85, 104, 0));
}

.focus\:tw-via-gray-800:focus {
  --gradient-via-color: #2d3748;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(45, 55, 72, 0));
}

.focus\:tw-via-gray-900:focus {
  --gradient-via-color: #1a202c;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(26, 32, 44, 0));
}

.focus\:tw-via-red-100:focus {
  --gradient-via-color: #fff5f5;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 245, 245, 0));
}

.focus\:tw-via-red-200:focus {
  --gradient-via-color: #fed7d7;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(254, 215, 215, 0));
}

.focus\:tw-via-red-300:focus {
  --gradient-via-color: #feb2b2;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(254, 178, 178, 0));
}

.focus\:tw-via-red-400:focus {
  --gradient-via-color: #fc8181;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(252, 129, 129, 0));
}

.focus\:tw-via-red-500:focus {
  --gradient-via-color: #f56565;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(245, 101, 101, 0));
}

.focus\:tw-via-red-600:focus {
  --gradient-via-color: #e53e3e;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(229, 62, 62, 0));
}

.focus\:tw-via-red-700:focus {
  --gradient-via-color: #c53030;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(197, 48, 48, 0));
}

.focus\:tw-via-red-800:focus {
  --gradient-via-color: #9b2c2c;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(155, 44, 44, 0));
}

.focus\:tw-via-red-900:focus {
  --gradient-via-color: #742a2a;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(116, 42, 42, 0));
}

.focus\:tw-via-orange-100:focus {
  --gradient-via-color: #fffaf0;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 250, 240, 0));
}

.focus\:tw-via-orange-200:focus {
  --gradient-via-color: #feebc8;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(254, 235, 200, 0));
}

.focus\:tw-via-orange-300:focus {
  --gradient-via-color: #fbd38d;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(251, 211, 141, 0));
}

.focus\:tw-via-orange-400:focus {
  --gradient-via-color: #f6ad55;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(246, 173, 85, 0));
}

.focus\:tw-via-orange-500:focus {
  --gradient-via-color: #ed8936;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(237, 137, 54, 0));
}

.focus\:tw-via-orange-600:focus {
  --gradient-via-color: #dd6b20;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(221, 107, 32, 0));
}

.focus\:tw-via-orange-700:focus {
  --gradient-via-color: #c05621;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(192, 86, 33, 0));
}

.focus\:tw-via-orange-800:focus {
  --gradient-via-color: #9c4221;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(156, 66, 33, 0));
}

.focus\:tw-via-orange-900:focus {
  --gradient-via-color: #7b341e;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(123, 52, 30, 0));
}

.focus\:tw-via-yellow-100:focus {
  --gradient-via-color: #fffff0;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 240, 0));
}

.focus\:tw-via-yellow-200:focus {
  --gradient-via-color: #fefcbf;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(254, 252, 191, 0));
}

.focus\:tw-via-yellow-300:focus {
  --gradient-via-color: #faf089;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(250, 240, 137, 0));
}

.focus\:tw-via-yellow-400:focus {
  --gradient-via-color: #f6e05e;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(246, 224, 94, 0));
}

.focus\:tw-via-yellow-500:focus {
  --gradient-via-color: #ecc94b;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(236, 201, 75, 0));
}

.focus\:tw-via-yellow-600:focus {
  --gradient-via-color: #d69e2e;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(214, 158, 46, 0));
}

.focus\:tw-via-yellow-700:focus {
  --gradient-via-color: #b7791f;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(183, 121, 31, 0));
}

.focus\:tw-via-yellow-800:focus {
  --gradient-via-color: #975a16;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(151, 90, 22, 0));
}

.focus\:tw-via-yellow-900:focus {
  --gradient-via-color: #744210;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(116, 66, 16, 0));
}

.focus\:tw-via-green-100:focus {
  --gradient-via-color: #f0fff4;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(240, 255, 244, 0));
}

.focus\:tw-via-green-200:focus {
  --gradient-via-color: #c6f6d5;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(198, 246, 213, 0));
}

.focus\:tw-via-green-300:focus {
  --gradient-via-color: #9ae6b4;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(154, 230, 180, 0));
}

.focus\:tw-via-green-400:focus {
  --gradient-via-color: #68d391;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(104, 211, 145, 0));
}

.focus\:tw-via-green-500:focus {
  --gradient-via-color: #48bb78;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(72, 187, 120, 0));
}

.focus\:tw-via-green-600:focus {
  --gradient-via-color: #38a169;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(56, 161, 105, 0));
}

.focus\:tw-via-green-700:focus {
  --gradient-via-color: #2f855a;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(47, 133, 90, 0));
}

.focus\:tw-via-green-800:focus {
  --gradient-via-color: #276749;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(39, 103, 73, 0));
}

.focus\:tw-via-green-900:focus {
  --gradient-via-color: #22543d;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(34, 84, 61, 0));
}

.focus\:tw-via-teal-100:focus {
  --gradient-via-color: #e6fffa;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(230, 255, 250, 0));
}

.focus\:tw-via-teal-200:focus {
  --gradient-via-color: #b2f5ea;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(178, 245, 234, 0));
}

.focus\:tw-via-teal-300:focus {
  --gradient-via-color: #81e6d9;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(129, 230, 217, 0));
}

.focus\:tw-via-teal-400:focus {
  --gradient-via-color: #4fd1c5;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(79, 209, 197, 0));
}

.focus\:tw-via-teal-500:focus {
  --gradient-via-color: #38b2ac;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(56, 178, 172, 0));
}

.focus\:tw-via-teal-600:focus {
  --gradient-via-color: #319795;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(49, 151, 149, 0));
}

.focus\:tw-via-teal-700:focus {
  --gradient-via-color: #2c7a7b;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(44, 122, 123, 0));
}

.focus\:tw-via-teal-800:focus {
  --gradient-via-color: #285e61;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(40, 94, 97, 0));
}

.focus\:tw-via-teal-900:focus {
  --gradient-via-color: #234e52;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(35, 78, 82, 0));
}

.focus\:tw-via-blue-100:focus {
  --gradient-via-color: #ebf8ff;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(235, 248, 255, 0));
}

.focus\:tw-via-blue-200:focus {
  --gradient-via-color: #bee3f8;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(190, 227, 248, 0));
}

.focus\:tw-via-blue-300:focus {
  --gradient-via-color: #90cdf4;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(144, 205, 244, 0));
}

.focus\:tw-via-blue-400:focus {
  --gradient-via-color: #63b3ed;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(99, 179, 237, 0));
}

.focus\:tw-via-blue-500:focus {
  --gradient-via-color: #4299e1;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(66, 153, 225, 0));
}

.focus\:tw-via-blue-600:focus {
  --gradient-via-color: #3182ce;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(49, 130, 206, 0));
}

.focus\:tw-via-blue-700:focus {
  --gradient-via-color: #2b6cb0;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(43, 108, 176, 0));
}

.focus\:tw-via-blue-800:focus {
  --gradient-via-color: #2c5282;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(44, 82, 130, 0));
}

.focus\:tw-via-blue-900:focus {
  --gradient-via-color: #2a4365;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(42, 67, 101, 0));
}

.focus\:tw-via-indigo-100:focus {
  --gradient-via-color: #ebf4ff;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(235, 244, 255, 0));
}

.focus\:tw-via-indigo-200:focus {
  --gradient-via-color: #c3dafe;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(195, 218, 254, 0));
}

.focus\:tw-via-indigo-300:focus {
  --gradient-via-color: #a3bffa;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(163, 191, 250, 0));
}

.focus\:tw-via-indigo-400:focus {
  --gradient-via-color: #7f9cf5;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(127, 156, 245, 0));
}

.focus\:tw-via-indigo-500:focus {
  --gradient-via-color: #667eea;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(102, 126, 234, 0));
}

.focus\:tw-via-indigo-600:focus {
  --gradient-via-color: #5a67d8;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(90, 103, 216, 0));
}

.focus\:tw-via-indigo-700:focus {
  --gradient-via-color: #4c51bf;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(76, 81, 191, 0));
}

.focus\:tw-via-indigo-800:focus {
  --gradient-via-color: #434190;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(67, 65, 144, 0));
}

.focus\:tw-via-indigo-900:focus {
  --gradient-via-color: #3c366b;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(60, 54, 107, 0));
}

.focus\:tw-via-purple-100:focus {
  --gradient-via-color: #faf5ff;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(250, 245, 255, 0));
}

.focus\:tw-via-purple-200:focus {
  --gradient-via-color: #e9d8fd;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(233, 216, 253, 0));
}

.focus\:tw-via-purple-300:focus {
  --gradient-via-color: #d6bcfa;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(214, 188, 250, 0));
}

.focus\:tw-via-purple-400:focus {
  --gradient-via-color: #b794f4;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(183, 148, 244, 0));
}

.focus\:tw-via-purple-500:focus {
  --gradient-via-color: #9f7aea;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(159, 122, 234, 0));
}

.focus\:tw-via-purple-600:focus {
  --gradient-via-color: #805ad5;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(128, 90, 213, 0));
}

.focus\:tw-via-purple-700:focus {
  --gradient-via-color: #6b46c1;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(107, 70, 193, 0));
}

.focus\:tw-via-purple-800:focus {
  --gradient-via-color: #553c9a;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(85, 60, 154, 0));
}

.focus\:tw-via-purple-900:focus {
  --gradient-via-color: #44337a;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(68, 51, 122, 0));
}

.focus\:tw-via-pink-100:focus {
  --gradient-via-color: #fff5f7;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 245, 247, 0));
}

.focus\:tw-via-pink-200:focus {
  --gradient-via-color: #fed7e2;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(254, 215, 226, 0));
}

.focus\:tw-via-pink-300:focus {
  --gradient-via-color: #fbb6ce;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(251, 182, 206, 0));
}

.focus\:tw-via-pink-400:focus {
  --gradient-via-color: #f687b3;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(246, 135, 179, 0));
}

.focus\:tw-via-pink-500:focus {
  --gradient-via-color: #ed64a6;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(237, 100, 166, 0));
}

.focus\:tw-via-pink-600:focus {
  --gradient-via-color: #d53f8c;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(213, 63, 140, 0));
}

.focus\:tw-via-pink-700:focus {
  --gradient-via-color: #b83280;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(184, 50, 128, 0));
}

.focus\:tw-via-pink-800:focus {
  --gradient-via-color: #97266d;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(151, 38, 109, 0));
}

.focus\:tw-via-pink-900:focus {
  --gradient-via-color: #702459;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(112, 36, 89, 0));
}

.focus\:tw-to-transparent:focus {
  --gradient-to-color: transparent;
}

.focus\:tw-to-current:focus {
  --gradient-to-color: currentColor;
}

.focus\:tw-to-black:focus {
  --gradient-to-color: #000;
}

.focus\:tw-to-white:focus {
  --gradient-to-color: #fff;
}

.focus\:tw-to-gray-100:focus {
  --gradient-to-color: #f7fafc;
}

.focus\:tw-to-gray-200:focus {
  --gradient-to-color: #edf2f7;
}

.focus\:tw-to-gray-300:focus {
  --gradient-to-color: #e2e8f0;
}

.focus\:tw-to-gray-400:focus {
  --gradient-to-color: #cbd5e0;
}

.focus\:tw-to-gray-500:focus {
  --gradient-to-color: #a0aec0;
}

.focus\:tw-to-gray-600:focus {
  --gradient-to-color: #718096;
}

.focus\:tw-to-gray-700:focus {
  --gradient-to-color: #4a5568;
}

.focus\:tw-to-gray-800:focus {
  --gradient-to-color: #2d3748;
}

.focus\:tw-to-gray-900:focus {
  --gradient-to-color: #1a202c;
}

.focus\:tw-to-red-100:focus {
  --gradient-to-color: #fff5f5;
}

.focus\:tw-to-red-200:focus {
  --gradient-to-color: #fed7d7;
}

.focus\:tw-to-red-300:focus {
  --gradient-to-color: #feb2b2;
}

.focus\:tw-to-red-400:focus {
  --gradient-to-color: #fc8181;
}

.focus\:tw-to-red-500:focus {
  --gradient-to-color: #f56565;
}

.focus\:tw-to-red-600:focus {
  --gradient-to-color: #e53e3e;
}

.focus\:tw-to-red-700:focus {
  --gradient-to-color: #c53030;
}

.focus\:tw-to-red-800:focus {
  --gradient-to-color: #9b2c2c;
}

.focus\:tw-to-red-900:focus {
  --gradient-to-color: #742a2a;
}

.focus\:tw-to-orange-100:focus {
  --gradient-to-color: #fffaf0;
}

.focus\:tw-to-orange-200:focus {
  --gradient-to-color: #feebc8;
}

.focus\:tw-to-orange-300:focus {
  --gradient-to-color: #fbd38d;
}

.focus\:tw-to-orange-400:focus {
  --gradient-to-color: #f6ad55;
}

.focus\:tw-to-orange-500:focus {
  --gradient-to-color: #ed8936;
}

.focus\:tw-to-orange-600:focus {
  --gradient-to-color: #dd6b20;
}

.focus\:tw-to-orange-700:focus {
  --gradient-to-color: #c05621;
}

.focus\:tw-to-orange-800:focus {
  --gradient-to-color: #9c4221;
}

.focus\:tw-to-orange-900:focus {
  --gradient-to-color: #7b341e;
}

.focus\:tw-to-yellow-100:focus {
  --gradient-to-color: #fffff0;
}

.focus\:tw-to-yellow-200:focus {
  --gradient-to-color: #fefcbf;
}

.focus\:tw-to-yellow-300:focus {
  --gradient-to-color: #faf089;
}

.focus\:tw-to-yellow-400:focus {
  --gradient-to-color: #f6e05e;
}

.focus\:tw-to-yellow-500:focus {
  --gradient-to-color: #ecc94b;
}

.focus\:tw-to-yellow-600:focus {
  --gradient-to-color: #d69e2e;
}

.focus\:tw-to-yellow-700:focus {
  --gradient-to-color: #b7791f;
}

.focus\:tw-to-yellow-800:focus {
  --gradient-to-color: #975a16;
}

.focus\:tw-to-yellow-900:focus {
  --gradient-to-color: #744210;
}

.focus\:tw-to-green-100:focus {
  --gradient-to-color: #f0fff4;
}

.focus\:tw-to-green-200:focus {
  --gradient-to-color: #c6f6d5;
}

.focus\:tw-to-green-300:focus {
  --gradient-to-color: #9ae6b4;
}

.focus\:tw-to-green-400:focus {
  --gradient-to-color: #68d391;
}

.focus\:tw-to-green-500:focus {
  --gradient-to-color: #48bb78;
}

.focus\:tw-to-green-600:focus {
  --gradient-to-color: #38a169;
}

.focus\:tw-to-green-700:focus {
  --gradient-to-color: #2f855a;
}

.focus\:tw-to-green-800:focus {
  --gradient-to-color: #276749;
}

.focus\:tw-to-green-900:focus {
  --gradient-to-color: #22543d;
}

.focus\:tw-to-teal-100:focus {
  --gradient-to-color: #e6fffa;
}

.focus\:tw-to-teal-200:focus {
  --gradient-to-color: #b2f5ea;
}

.focus\:tw-to-teal-300:focus {
  --gradient-to-color: #81e6d9;
}

.focus\:tw-to-teal-400:focus {
  --gradient-to-color: #4fd1c5;
}

.focus\:tw-to-teal-500:focus {
  --gradient-to-color: #38b2ac;
}

.focus\:tw-to-teal-600:focus {
  --gradient-to-color: #319795;
}

.focus\:tw-to-teal-700:focus {
  --gradient-to-color: #2c7a7b;
}

.focus\:tw-to-teal-800:focus {
  --gradient-to-color: #285e61;
}

.focus\:tw-to-teal-900:focus {
  --gradient-to-color: #234e52;
}

.focus\:tw-to-blue-100:focus {
  --gradient-to-color: #ebf8ff;
}

.focus\:tw-to-blue-200:focus {
  --gradient-to-color: #bee3f8;
}

.focus\:tw-to-blue-300:focus {
  --gradient-to-color: #90cdf4;
}

.focus\:tw-to-blue-400:focus {
  --gradient-to-color: #63b3ed;
}

.focus\:tw-to-blue-500:focus {
  --gradient-to-color: #4299e1;
}

.focus\:tw-to-blue-600:focus {
  --gradient-to-color: #3182ce;
}

.focus\:tw-to-blue-700:focus {
  --gradient-to-color: #2b6cb0;
}

.focus\:tw-to-blue-800:focus {
  --gradient-to-color: #2c5282;
}

.focus\:tw-to-blue-900:focus {
  --gradient-to-color: #2a4365;
}

.focus\:tw-to-indigo-100:focus {
  --gradient-to-color: #ebf4ff;
}

.focus\:tw-to-indigo-200:focus {
  --gradient-to-color: #c3dafe;
}

.focus\:tw-to-indigo-300:focus {
  --gradient-to-color: #a3bffa;
}

.focus\:tw-to-indigo-400:focus {
  --gradient-to-color: #7f9cf5;
}

.focus\:tw-to-indigo-500:focus {
  --gradient-to-color: #667eea;
}

.focus\:tw-to-indigo-600:focus {
  --gradient-to-color: #5a67d8;
}

.focus\:tw-to-indigo-700:focus {
  --gradient-to-color: #4c51bf;
}

.focus\:tw-to-indigo-800:focus {
  --gradient-to-color: #434190;
}

.focus\:tw-to-indigo-900:focus {
  --gradient-to-color: #3c366b;
}

.focus\:tw-to-purple-100:focus {
  --gradient-to-color: #faf5ff;
}

.focus\:tw-to-purple-200:focus {
  --gradient-to-color: #e9d8fd;
}

.focus\:tw-to-purple-300:focus {
  --gradient-to-color: #d6bcfa;
}

.focus\:tw-to-purple-400:focus {
  --gradient-to-color: #b794f4;
}

.focus\:tw-to-purple-500:focus {
  --gradient-to-color: #9f7aea;
}

.focus\:tw-to-purple-600:focus {
  --gradient-to-color: #805ad5;
}

.focus\:tw-to-purple-700:focus {
  --gradient-to-color: #6b46c1;
}

.focus\:tw-to-purple-800:focus {
  --gradient-to-color: #553c9a;
}

.focus\:tw-to-purple-900:focus {
  --gradient-to-color: #44337a;
}

.focus\:tw-to-pink-100:focus {
  --gradient-to-color: #fff5f7;
}

.focus\:tw-to-pink-200:focus {
  --gradient-to-color: #fed7e2;
}

.focus\:tw-to-pink-300:focus {
  --gradient-to-color: #fbb6ce;
}

.focus\:tw-to-pink-400:focus {
  --gradient-to-color: #f687b3;
}

.focus\:tw-to-pink-500:focus {
  --gradient-to-color: #ed64a6;
}

.focus\:tw-to-pink-600:focus {
  --gradient-to-color: #d53f8c;
}

.focus\:tw-to-pink-700:focus {
  --gradient-to-color: #b83280;
}

.focus\:tw-to-pink-800:focus {
  --gradient-to-color: #97266d;
}

.focus\:tw-to-pink-900:focus {
  --gradient-to-color: #702459;
}

.tw-bg-opacity-0 {
  --bg-opacity: 0;
}

.tw-bg-opacity-25 {
  --bg-opacity: 0.25;
}

.tw-bg-opacity-50 {
  --bg-opacity: 0.5;
}

.tw-bg-opacity-75 {
  --bg-opacity: 0.75;
}

.tw-bg-opacity-100 {
  --bg-opacity: 1;
}

.hover\:tw-bg-opacity-0:hover {
  --bg-opacity: 0;
}

.hover\:tw-bg-opacity-25:hover {
  --bg-opacity: 0.25;
}

.hover\:tw-bg-opacity-50:hover {
  --bg-opacity: 0.5;
}

.hover\:tw-bg-opacity-75:hover {
  --bg-opacity: 0.75;
}

.hover\:tw-bg-opacity-100:hover {
  --bg-opacity: 1;
}

.focus\:tw-bg-opacity-0:focus {
  --bg-opacity: 0;
}

.focus\:tw-bg-opacity-25:focus {
  --bg-opacity: 0.25;
}

.focus\:tw-bg-opacity-50:focus {
  --bg-opacity: 0.5;
}

.focus\:tw-bg-opacity-75:focus {
  --bg-opacity: 0.75;
}

.focus\:tw-bg-opacity-100:focus {
  --bg-opacity: 1;
}

.tw-bg-bottom {
  background-position: bottom;
}

.tw-bg-center {
  background-position: center;
}

.tw-bg-left {
  background-position: left;
}

.tw-bg-left-bottom {
  background-position: left bottom;
}

.tw-bg-left-top {
  background-position: left top;
}

.tw-bg-right {
  background-position: right;
}

.tw-bg-right-bottom {
  background-position: right bottom;
}

.tw-bg-right-top {
  background-position: right top;
}

.tw-bg-top {
  background-position: top;
}

.tw-bg-repeat {
  background-repeat: repeat;
}

.tw-bg-no-repeat {
  background-repeat: no-repeat;
}

.tw-bg-repeat-x {
  background-repeat: repeat-x;
}

.tw-bg-repeat-y {
  background-repeat: repeat-y;
}

.tw-bg-repeat-round {
  background-repeat: round;
}

.tw-bg-repeat-space {
  background-repeat: space;
}

.tw-bg-auto {
  background-size: auto;
}

.tw-bg-cover {
  background-size: cover;
}

.tw-bg-contain {
  background-size: contain;
}

.tw-border-collapse {
  border-collapse: collapse;
}

.tw-border-separate {
  border-collapse: separate;
}

.tw-border-transparent {
  border-color: transparent;
}

.tw-border-current {
  border-color: currentColor;
}

.tw-border-black {
  --border-opacity: 1;
  border-color: #000;
  border-color: rgba(0, 0, 0, var(--border-opacity));
}

.tw-border-white {
  --border-opacity: 1;
  border-color: #fff;
  border-color: rgba(255, 255, 255, var(--border-opacity));
}

.tw-border-gray-100 {
  --border-opacity: 1;
  border-color: #f7fafc;
  border-color: rgba(247, 250, 252, var(--border-opacity));
}

.tw-border-gray-200 {
  --border-opacity: 1;
  border-color: #edf2f7;
  border-color: rgba(237, 242, 247, var(--border-opacity));
}

.tw-border-gray-300 {
  --border-opacity: 1;
  border-color: #e2e8f0;
  border-color: rgba(226, 232, 240, var(--border-opacity));
}

.tw-border-gray-400 {
  --border-opacity: 1;
  border-color: #cbd5e0;
  border-color: rgba(203, 213, 224, var(--border-opacity));
}

.tw-border-gray-500 {
  --border-opacity: 1;
  border-color: #a0aec0;
  border-color: rgba(160, 174, 192, var(--border-opacity));
}

.tw-border-gray-600 {
  --border-opacity: 1;
  border-color: #718096;
  border-color: rgba(113, 128, 150, var(--border-opacity));
}

.tw-border-gray-700 {
  --border-opacity: 1;
  border-color: #4a5568;
  border-color: rgba(74, 85, 104, var(--border-opacity));
}

.tw-border-gray-800 {
  --border-opacity: 1;
  border-color: #2d3748;
  border-color: rgba(45, 55, 72, var(--border-opacity));
}

.tw-border-gray-900 {
  --border-opacity: 1;
  border-color: #1a202c;
  border-color: rgba(26, 32, 44, var(--border-opacity));
}

.tw-border-red-100 {
  --border-opacity: 1;
  border-color: #fff5f5;
  border-color: rgba(255, 245, 245, var(--border-opacity));
}

.tw-border-red-200 {
  --border-opacity: 1;
  border-color: #fed7d7;
  border-color: rgba(254, 215, 215, var(--border-opacity));
}

.tw-border-red-300 {
  --border-opacity: 1;
  border-color: #feb2b2;
  border-color: rgba(254, 178, 178, var(--border-opacity));
}

.tw-border-red-400 {
  --border-opacity: 1;
  border-color: #fc8181;
  border-color: rgba(252, 129, 129, var(--border-opacity));
}

.tw-border-red-500 {
  --border-opacity: 1;
  border-color: #f56565;
  border-color: rgba(245, 101, 101, var(--border-opacity));
}

.tw-border-red-600 {
  --border-opacity: 1;
  border-color: #e53e3e;
  border-color: rgba(229, 62, 62, var(--border-opacity));
}

.tw-border-red-700 {
  --border-opacity: 1;
  border-color: #c53030;
  border-color: rgba(197, 48, 48, var(--border-opacity));
}

.tw-border-red-800 {
  --border-opacity: 1;
  border-color: #9b2c2c;
  border-color: rgba(155, 44, 44, var(--border-opacity));
}

.tw-border-red-900 {
  --border-opacity: 1;
  border-color: #742a2a;
  border-color: rgba(116, 42, 42, var(--border-opacity));
}

.tw-border-orange-100 {
  --border-opacity: 1;
  border-color: #fffaf0;
  border-color: rgba(255, 250, 240, var(--border-opacity));
}

.tw-border-orange-200 {
  --border-opacity: 1;
  border-color: #feebc8;
  border-color: rgba(254, 235, 200, var(--border-opacity));
}

.tw-border-orange-300 {
  --border-opacity: 1;
  border-color: #fbd38d;
  border-color: rgba(251, 211, 141, var(--border-opacity));
}

.tw-border-orange-400 {
  --border-opacity: 1;
  border-color: #f6ad55;
  border-color: rgba(246, 173, 85, var(--border-opacity));
}

.tw-border-orange-500 {
  --border-opacity: 1;
  border-color: #ed8936;
  border-color: rgba(237, 137, 54, var(--border-opacity));
}

.tw-border-orange-600 {
  --border-opacity: 1;
  border-color: #dd6b20;
  border-color: rgba(221, 107, 32, var(--border-opacity));
}

.tw-border-orange-700 {
  --border-opacity: 1;
  border-color: #c05621;
  border-color: rgba(192, 86, 33, var(--border-opacity));
}

.tw-border-orange-800 {
  --border-opacity: 1;
  border-color: #9c4221;
  border-color: rgba(156, 66, 33, var(--border-opacity));
}

.tw-border-orange-900 {
  --border-opacity: 1;
  border-color: #7b341e;
  border-color: rgba(123, 52, 30, var(--border-opacity));
}

.tw-border-yellow-100 {
  --border-opacity: 1;
  border-color: #fffff0;
  border-color: rgba(255, 255, 240, var(--border-opacity));
}

.tw-border-yellow-200 {
  --border-opacity: 1;
  border-color: #fefcbf;
  border-color: rgba(254, 252, 191, var(--border-opacity));
}

.tw-border-yellow-300 {
  --border-opacity: 1;
  border-color: #faf089;
  border-color: rgba(250, 240, 137, var(--border-opacity));
}

.tw-border-yellow-400 {
  --border-opacity: 1;
  border-color: #f6e05e;
  border-color: rgba(246, 224, 94, var(--border-opacity));
}

.tw-border-yellow-500 {
  --border-opacity: 1;
  border-color: #ecc94b;
  border-color: rgba(236, 201, 75, var(--border-opacity));
}

.tw-border-yellow-600 {
  --border-opacity: 1;
  border-color: #d69e2e;
  border-color: rgba(214, 158, 46, var(--border-opacity));
}

.tw-border-yellow-700 {
  --border-opacity: 1;
  border-color: #b7791f;
  border-color: rgba(183, 121, 31, var(--border-opacity));
}

.tw-border-yellow-800 {
  --border-opacity: 1;
  border-color: #975a16;
  border-color: rgba(151, 90, 22, var(--border-opacity));
}

.tw-border-yellow-900 {
  --border-opacity: 1;
  border-color: #744210;
  border-color: rgba(116, 66, 16, var(--border-opacity));
}

.tw-border-green-100 {
  --border-opacity: 1;
  border-color: #f0fff4;
  border-color: rgba(240, 255, 244, var(--border-opacity));
}

.tw-border-green-200 {
  --border-opacity: 1;
  border-color: #c6f6d5;
  border-color: rgba(198, 246, 213, var(--border-opacity));
}

.tw-border-green-300 {
  --border-opacity: 1;
  border-color: #9ae6b4;
  border-color: rgba(154, 230, 180, var(--border-opacity));
}

.tw-border-green-400 {
  --border-opacity: 1;
  border-color: #68d391;
  border-color: rgba(104, 211, 145, var(--border-opacity));
}

.tw-border-green-500 {
  --border-opacity: 1;
  border-color: #48bb78;
  border-color: rgba(72, 187, 120, var(--border-opacity));
}

.tw-border-green-600 {
  --border-opacity: 1;
  border-color: #38a169;
  border-color: rgba(56, 161, 105, var(--border-opacity));
}

.tw-border-green-700 {
  --border-opacity: 1;
  border-color: #2f855a;
  border-color: rgba(47, 133, 90, var(--border-opacity));
}

.tw-border-green-800 {
  --border-opacity: 1;
  border-color: #276749;
  border-color: rgba(39, 103, 73, var(--border-opacity));
}

.tw-border-green-900 {
  --border-opacity: 1;
  border-color: #22543d;
  border-color: rgba(34, 84, 61, var(--border-opacity));
}

.tw-border-teal-100 {
  --border-opacity: 1;
  border-color: #e6fffa;
  border-color: rgba(230, 255, 250, var(--border-opacity));
}

.tw-border-teal-200 {
  --border-opacity: 1;
  border-color: #b2f5ea;
  border-color: rgba(178, 245, 234, var(--border-opacity));
}

.tw-border-teal-300 {
  --border-opacity: 1;
  border-color: #81e6d9;
  border-color: rgba(129, 230, 217, var(--border-opacity));
}

.tw-border-teal-400 {
  --border-opacity: 1;
  border-color: #4fd1c5;
  border-color: rgba(79, 209, 197, var(--border-opacity));
}

.tw-border-teal-500 {
  --border-opacity: 1;
  border-color: #38b2ac;
  border-color: rgba(56, 178, 172, var(--border-opacity));
}

.tw-border-teal-600 {
  --border-opacity: 1;
  border-color: #319795;
  border-color: rgba(49, 151, 149, var(--border-opacity));
}

.tw-border-teal-700 {
  --border-opacity: 1;
  border-color: #2c7a7b;
  border-color: rgba(44, 122, 123, var(--border-opacity));
}

.tw-border-teal-800 {
  --border-opacity: 1;
  border-color: #285e61;
  border-color: rgba(40, 94, 97, var(--border-opacity));
}

.tw-border-teal-900 {
  --border-opacity: 1;
  border-color: #234e52;
  border-color: rgba(35, 78, 82, var(--border-opacity));
}

.tw-border-blue-100 {
  --border-opacity: 1;
  border-color: #ebf8ff;
  border-color: rgba(235, 248, 255, var(--border-opacity));
}

.tw-border-blue-200 {
  --border-opacity: 1;
  border-color: #bee3f8;
  border-color: rgba(190, 227, 248, var(--border-opacity));
}

.tw-border-blue-300 {
  --border-opacity: 1;
  border-color: #90cdf4;
  border-color: rgba(144, 205, 244, var(--border-opacity));
}

.tw-border-blue-400 {
  --border-opacity: 1;
  border-color: #63b3ed;
  border-color: rgba(99, 179, 237, var(--border-opacity));
}

.tw-border-blue-500 {
  --border-opacity: 1;
  border-color: #4299e1;
  border-color: rgba(66, 153, 225, var(--border-opacity));
}

.tw-border-blue-600 {
  --border-opacity: 1;
  border-color: #3182ce;
  border-color: rgba(49, 130, 206, var(--border-opacity));
}

.tw-border-blue-700 {
  --border-opacity: 1;
  border-color: #2b6cb0;
  border-color: rgba(43, 108, 176, var(--border-opacity));
}

.tw-border-blue-800 {
  --border-opacity: 1;
  border-color: #2c5282;
  border-color: rgba(44, 82, 130, var(--border-opacity));
}

.tw-border-blue-900 {
  --border-opacity: 1;
  border-color: #2a4365;
  border-color: rgba(42, 67, 101, var(--border-opacity));
}

.tw-border-indigo-100 {
  --border-opacity: 1;
  border-color: #ebf4ff;
  border-color: rgba(235, 244, 255, var(--border-opacity));
}

.tw-border-indigo-200 {
  --border-opacity: 1;
  border-color: #c3dafe;
  border-color: rgba(195, 218, 254, var(--border-opacity));
}

.tw-border-indigo-300 {
  --border-opacity: 1;
  border-color: #a3bffa;
  border-color: rgba(163, 191, 250, var(--border-opacity));
}

.tw-border-indigo-400 {
  --border-opacity: 1;
  border-color: #7f9cf5;
  border-color: rgba(127, 156, 245, var(--border-opacity));
}

.tw-border-indigo-500 {
  --border-opacity: 1;
  border-color: #667eea;
  border-color: rgba(102, 126, 234, var(--border-opacity));
}

.tw-border-indigo-600 {
  --border-opacity: 1;
  border-color: #5a67d8;
  border-color: rgba(90, 103, 216, var(--border-opacity));
}

.tw-border-indigo-700 {
  --border-opacity: 1;
  border-color: #4c51bf;
  border-color: rgba(76, 81, 191, var(--border-opacity));
}

.tw-border-indigo-800 {
  --border-opacity: 1;
  border-color: #434190;
  border-color: rgba(67, 65, 144, var(--border-opacity));
}

.tw-border-indigo-900 {
  --border-opacity: 1;
  border-color: #3c366b;
  border-color: rgba(60, 54, 107, var(--border-opacity));
}

.tw-border-purple-100 {
  --border-opacity: 1;
  border-color: #faf5ff;
  border-color: rgba(250, 245, 255, var(--border-opacity));
}

.tw-border-purple-200 {
  --border-opacity: 1;
  border-color: #e9d8fd;
  border-color: rgba(233, 216, 253, var(--border-opacity));
}

.tw-border-purple-300 {
  --border-opacity: 1;
  border-color: #d6bcfa;
  border-color: rgba(214, 188, 250, var(--border-opacity));
}

.tw-border-purple-400 {
  --border-opacity: 1;
  border-color: #b794f4;
  border-color: rgba(183, 148, 244, var(--border-opacity));
}

.tw-border-purple-500 {
  --border-opacity: 1;
  border-color: #9f7aea;
  border-color: rgba(159, 122, 234, var(--border-opacity));
}

.tw-border-purple-600 {
  --border-opacity: 1;
  border-color: #805ad5;
  border-color: rgba(128, 90, 213, var(--border-opacity));
}

.tw-border-purple-700 {
  --border-opacity: 1;
  border-color: #6b46c1;
  border-color: rgba(107, 70, 193, var(--border-opacity));
}

.tw-border-purple-800 {
  --border-opacity: 1;
  border-color: #553c9a;
  border-color: rgba(85, 60, 154, var(--border-opacity));
}

.tw-border-purple-900 {
  --border-opacity: 1;
  border-color: #44337a;
  border-color: rgba(68, 51, 122, var(--border-opacity));
}

.tw-border-pink-100 {
  --border-opacity: 1;
  border-color: #fff5f7;
  border-color: rgba(255, 245, 247, var(--border-opacity));
}

.tw-border-pink-200 {
  --border-opacity: 1;
  border-color: #fed7e2;
  border-color: rgba(254, 215, 226, var(--border-opacity));
}

.tw-border-pink-300 {
  --border-opacity: 1;
  border-color: #fbb6ce;
  border-color: rgba(251, 182, 206, var(--border-opacity));
}

.tw-border-pink-400 {
  --border-opacity: 1;
  border-color: #f687b3;
  border-color: rgba(246, 135, 179, var(--border-opacity));
}

.tw-border-pink-500 {
  --border-opacity: 1;
  border-color: #ed64a6;
  border-color: rgba(237, 100, 166, var(--border-opacity));
}

.tw-border-pink-600 {
  --border-opacity: 1;
  border-color: #d53f8c;
  border-color: rgba(213, 63, 140, var(--border-opacity));
}

.tw-border-pink-700 {
  --border-opacity: 1;
  border-color: #b83280;
  border-color: rgba(184, 50, 128, var(--border-opacity));
}

.tw-border-pink-800 {
  --border-opacity: 1;
  border-color: #97266d;
  border-color: rgba(151, 38, 109, var(--border-opacity));
}

.tw-border-pink-900 {
  --border-opacity: 1;
  border-color: #702459;
  border-color: rgba(112, 36, 89, var(--border-opacity));
}

.hover\:tw-border-transparent:hover {
  border-color: transparent;
}

.hover\:tw-border-current:hover {
  border-color: currentColor;
}

.hover\:tw-border-black:hover {
  --border-opacity: 1;
  border-color: #000;
  border-color: rgba(0, 0, 0, var(--border-opacity));
}

.hover\:tw-border-white:hover {
  --border-opacity: 1;
  border-color: #fff;
  border-color: rgba(255, 255, 255, var(--border-opacity));
}

.hover\:tw-border-gray-100:hover {
  --border-opacity: 1;
  border-color: #f7fafc;
  border-color: rgba(247, 250, 252, var(--border-opacity));
}

.hover\:tw-border-gray-200:hover {
  --border-opacity: 1;
  border-color: #edf2f7;
  border-color: rgba(237, 242, 247, var(--border-opacity));
}

.hover\:tw-border-gray-300:hover {
  --border-opacity: 1;
  border-color: #e2e8f0;
  border-color: rgba(226, 232, 240, var(--border-opacity));
}

.hover\:tw-border-gray-400:hover {
  --border-opacity: 1;
  border-color: #cbd5e0;
  border-color: rgba(203, 213, 224, var(--border-opacity));
}

.hover\:tw-border-gray-500:hover {
  --border-opacity: 1;
  border-color: #a0aec0;
  border-color: rgba(160, 174, 192, var(--border-opacity));
}

.hover\:tw-border-gray-600:hover {
  --border-opacity: 1;
  border-color: #718096;
  border-color: rgba(113, 128, 150, var(--border-opacity));
}

.hover\:tw-border-gray-700:hover {
  --border-opacity: 1;
  border-color: #4a5568;
  border-color: rgba(74, 85, 104, var(--border-opacity));
}

.hover\:tw-border-gray-800:hover {
  --border-opacity: 1;
  border-color: #2d3748;
  border-color: rgba(45, 55, 72, var(--border-opacity));
}

.hover\:tw-border-gray-900:hover {
  --border-opacity: 1;
  border-color: #1a202c;
  border-color: rgba(26, 32, 44, var(--border-opacity));
}

.hover\:tw-border-red-100:hover {
  --border-opacity: 1;
  border-color: #fff5f5;
  border-color: rgba(255, 245, 245, var(--border-opacity));
}

.hover\:tw-border-red-200:hover {
  --border-opacity: 1;
  border-color: #fed7d7;
  border-color: rgba(254, 215, 215, var(--border-opacity));
}

.hover\:tw-border-red-300:hover {
  --border-opacity: 1;
  border-color: #feb2b2;
  border-color: rgba(254, 178, 178, var(--border-opacity));
}

.hover\:tw-border-red-400:hover {
  --border-opacity: 1;
  border-color: #fc8181;
  border-color: rgba(252, 129, 129, var(--border-opacity));
}

.hover\:tw-border-red-500:hover {
  --border-opacity: 1;
  border-color: #f56565;
  border-color: rgba(245, 101, 101, var(--border-opacity));
}

.hover\:tw-border-red-600:hover {
  --border-opacity: 1;
  border-color: #e53e3e;
  border-color: rgba(229, 62, 62, var(--border-opacity));
}

.hover\:tw-border-red-700:hover {
  --border-opacity: 1;
  border-color: #c53030;
  border-color: rgba(197, 48, 48, var(--border-opacity));
}

.hover\:tw-border-red-800:hover {
  --border-opacity: 1;
  border-color: #9b2c2c;
  border-color: rgba(155, 44, 44, var(--border-opacity));
}

.hover\:tw-border-red-900:hover {
  --border-opacity: 1;
  border-color: #742a2a;
  border-color: rgba(116, 42, 42, var(--border-opacity));
}

.hover\:tw-border-orange-100:hover {
  --border-opacity: 1;
  border-color: #fffaf0;
  border-color: rgba(255, 250, 240, var(--border-opacity));
}

.hover\:tw-border-orange-200:hover {
  --border-opacity: 1;
  border-color: #feebc8;
  border-color: rgba(254, 235, 200, var(--border-opacity));
}

.hover\:tw-border-orange-300:hover {
  --border-opacity: 1;
  border-color: #fbd38d;
  border-color: rgba(251, 211, 141, var(--border-opacity));
}

.hover\:tw-border-orange-400:hover {
  --border-opacity: 1;
  border-color: #f6ad55;
  border-color: rgba(246, 173, 85, var(--border-opacity));
}

.hover\:tw-border-orange-500:hover {
  --border-opacity: 1;
  border-color: #ed8936;
  border-color: rgba(237, 137, 54, var(--border-opacity));
}

.hover\:tw-border-orange-600:hover {
  --border-opacity: 1;
  border-color: #dd6b20;
  border-color: rgba(221, 107, 32, var(--border-opacity));
}

.hover\:tw-border-orange-700:hover {
  --border-opacity: 1;
  border-color: #c05621;
  border-color: rgba(192, 86, 33, var(--border-opacity));
}

.hover\:tw-border-orange-800:hover {
  --border-opacity: 1;
  border-color: #9c4221;
  border-color: rgba(156, 66, 33, var(--border-opacity));
}

.hover\:tw-border-orange-900:hover {
  --border-opacity: 1;
  border-color: #7b341e;
  border-color: rgba(123, 52, 30, var(--border-opacity));
}

.hover\:tw-border-yellow-100:hover {
  --border-opacity: 1;
  border-color: #fffff0;
  border-color: rgba(255, 255, 240, var(--border-opacity));
}

.hover\:tw-border-yellow-200:hover {
  --border-opacity: 1;
  border-color: #fefcbf;
  border-color: rgba(254, 252, 191, var(--border-opacity));
}

.hover\:tw-border-yellow-300:hover {
  --border-opacity: 1;
  border-color: #faf089;
  border-color: rgba(250, 240, 137, var(--border-opacity));
}

.hover\:tw-border-yellow-400:hover {
  --border-opacity: 1;
  border-color: #f6e05e;
  border-color: rgba(246, 224, 94, var(--border-opacity));
}

.hover\:tw-border-yellow-500:hover {
  --border-opacity: 1;
  border-color: #ecc94b;
  border-color: rgba(236, 201, 75, var(--border-opacity));
}

.hover\:tw-border-yellow-600:hover {
  --border-opacity: 1;
  border-color: #d69e2e;
  border-color: rgba(214, 158, 46, var(--border-opacity));
}

.hover\:tw-border-yellow-700:hover {
  --border-opacity: 1;
  border-color: #b7791f;
  border-color: rgba(183, 121, 31, var(--border-opacity));
}

.hover\:tw-border-yellow-800:hover {
  --border-opacity: 1;
  border-color: #975a16;
  border-color: rgba(151, 90, 22, var(--border-opacity));
}

.hover\:tw-border-yellow-900:hover {
  --border-opacity: 1;
  border-color: #744210;
  border-color: rgba(116, 66, 16, var(--border-opacity));
}

.hover\:tw-border-green-100:hover {
  --border-opacity: 1;
  border-color: #f0fff4;
  border-color: rgba(240, 255, 244, var(--border-opacity));
}

.hover\:tw-border-green-200:hover {
  --border-opacity: 1;
  border-color: #c6f6d5;
  border-color: rgba(198, 246, 213, var(--border-opacity));
}

.hover\:tw-border-green-300:hover {
  --border-opacity: 1;
  border-color: #9ae6b4;
  border-color: rgba(154, 230, 180, var(--border-opacity));
}

.hover\:tw-border-green-400:hover {
  --border-opacity: 1;
  border-color: #68d391;
  border-color: rgba(104, 211, 145, var(--border-opacity));
}

.hover\:tw-border-green-500:hover {
  --border-opacity: 1;
  border-color: #48bb78;
  border-color: rgba(72, 187, 120, var(--border-opacity));
}

.hover\:tw-border-green-600:hover {
  --border-opacity: 1;
  border-color: #38a169;
  border-color: rgba(56, 161, 105, var(--border-opacity));
}

.hover\:tw-border-green-700:hover {
  --border-opacity: 1;
  border-color: #2f855a;
  border-color: rgba(47, 133, 90, var(--border-opacity));
}

.hover\:tw-border-green-800:hover {
  --border-opacity: 1;
  border-color: #276749;
  border-color: rgba(39, 103, 73, var(--border-opacity));
}

.hover\:tw-border-green-900:hover {
  --border-opacity: 1;
  border-color: #22543d;
  border-color: rgba(34, 84, 61, var(--border-opacity));
}

.hover\:tw-border-teal-100:hover {
  --border-opacity: 1;
  border-color: #e6fffa;
  border-color: rgba(230, 255, 250, var(--border-opacity));
}

.hover\:tw-border-teal-200:hover {
  --border-opacity: 1;
  border-color: #b2f5ea;
  border-color: rgba(178, 245, 234, var(--border-opacity));
}

.hover\:tw-border-teal-300:hover {
  --border-opacity: 1;
  border-color: #81e6d9;
  border-color: rgba(129, 230, 217, var(--border-opacity));
}

.hover\:tw-border-teal-400:hover {
  --border-opacity: 1;
  border-color: #4fd1c5;
  border-color: rgba(79, 209, 197, var(--border-opacity));
}

.hover\:tw-border-teal-500:hover {
  --border-opacity: 1;
  border-color: #38b2ac;
  border-color: rgba(56, 178, 172, var(--border-opacity));
}

.hover\:tw-border-teal-600:hover {
  --border-opacity: 1;
  border-color: #319795;
  border-color: rgba(49, 151, 149, var(--border-opacity));
}

.hover\:tw-border-teal-700:hover {
  --border-opacity: 1;
  border-color: #2c7a7b;
  border-color: rgba(44, 122, 123, var(--border-opacity));
}

.hover\:tw-border-teal-800:hover {
  --border-opacity: 1;
  border-color: #285e61;
  border-color: rgba(40, 94, 97, var(--border-opacity));
}

.hover\:tw-border-teal-900:hover {
  --border-opacity: 1;
  border-color: #234e52;
  border-color: rgba(35, 78, 82, var(--border-opacity));
}

.hover\:tw-border-blue-100:hover {
  --border-opacity: 1;
  border-color: #ebf8ff;
  border-color: rgba(235, 248, 255, var(--border-opacity));
}

.hover\:tw-border-blue-200:hover {
  --border-opacity: 1;
  border-color: #bee3f8;
  border-color: rgba(190, 227, 248, var(--border-opacity));
}

.hover\:tw-border-blue-300:hover {
  --border-opacity: 1;
  border-color: #90cdf4;
  border-color: rgba(144, 205, 244, var(--border-opacity));
}

.hover\:tw-border-blue-400:hover {
  --border-opacity: 1;
  border-color: #63b3ed;
  border-color: rgba(99, 179, 237, var(--border-opacity));
}

.hover\:tw-border-blue-500:hover {
  --border-opacity: 1;
  border-color: #4299e1;
  border-color: rgba(66, 153, 225, var(--border-opacity));
}

.hover\:tw-border-blue-600:hover {
  --border-opacity: 1;
  border-color: #3182ce;
  border-color: rgba(49, 130, 206, var(--border-opacity));
}

.hover\:tw-border-blue-700:hover {
  --border-opacity: 1;
  border-color: #2b6cb0;
  border-color: rgba(43, 108, 176, var(--border-opacity));
}

.hover\:tw-border-blue-800:hover {
  --border-opacity: 1;
  border-color: #2c5282;
  border-color: rgba(44, 82, 130, var(--border-opacity));
}

.hover\:tw-border-blue-900:hover {
  --border-opacity: 1;
  border-color: #2a4365;
  border-color: rgba(42, 67, 101, var(--border-opacity));
}

.hover\:tw-border-indigo-100:hover {
  --border-opacity: 1;
  border-color: #ebf4ff;
  border-color: rgba(235, 244, 255, var(--border-opacity));
}

.hover\:tw-border-indigo-200:hover {
  --border-opacity: 1;
  border-color: #c3dafe;
  border-color: rgba(195, 218, 254, var(--border-opacity));
}

.hover\:tw-border-indigo-300:hover {
  --border-opacity: 1;
  border-color: #a3bffa;
  border-color: rgba(163, 191, 250, var(--border-opacity));
}

.hover\:tw-border-indigo-400:hover {
  --border-opacity: 1;
  border-color: #7f9cf5;
  border-color: rgba(127, 156, 245, var(--border-opacity));
}

.hover\:tw-border-indigo-500:hover {
  --border-opacity: 1;
  border-color: #667eea;
  border-color: rgba(102, 126, 234, var(--border-opacity));
}

.hover\:tw-border-indigo-600:hover {
  --border-opacity: 1;
  border-color: #5a67d8;
  border-color: rgba(90, 103, 216, var(--border-opacity));
}

.hover\:tw-border-indigo-700:hover {
  --border-opacity: 1;
  border-color: #4c51bf;
  border-color: rgba(76, 81, 191, var(--border-opacity));
}

.hover\:tw-border-indigo-800:hover {
  --border-opacity: 1;
  border-color: #434190;
  border-color: rgba(67, 65, 144, var(--border-opacity));
}

.hover\:tw-border-indigo-900:hover {
  --border-opacity: 1;
  border-color: #3c366b;
  border-color: rgba(60, 54, 107, var(--border-opacity));
}

.hover\:tw-border-purple-100:hover {
  --border-opacity: 1;
  border-color: #faf5ff;
  border-color: rgba(250, 245, 255, var(--border-opacity));
}

.hover\:tw-border-purple-200:hover {
  --border-opacity: 1;
  border-color: #e9d8fd;
  border-color: rgba(233, 216, 253, var(--border-opacity));
}

.hover\:tw-border-purple-300:hover {
  --border-opacity: 1;
  border-color: #d6bcfa;
  border-color: rgba(214, 188, 250, var(--border-opacity));
}

.hover\:tw-border-purple-400:hover {
  --border-opacity: 1;
  border-color: #b794f4;
  border-color: rgba(183, 148, 244, var(--border-opacity));
}

.hover\:tw-border-purple-500:hover {
  --border-opacity: 1;
  border-color: #9f7aea;
  border-color: rgba(159, 122, 234, var(--border-opacity));
}

.hover\:tw-border-purple-600:hover {
  --border-opacity: 1;
  border-color: #805ad5;
  border-color: rgba(128, 90, 213, var(--border-opacity));
}

.hover\:tw-border-purple-700:hover {
  --border-opacity: 1;
  border-color: #6b46c1;
  border-color: rgba(107, 70, 193, var(--border-opacity));
}

.hover\:tw-border-purple-800:hover {
  --border-opacity: 1;
  border-color: #553c9a;
  border-color: rgba(85, 60, 154, var(--border-opacity));
}

.hover\:tw-border-purple-900:hover {
  --border-opacity: 1;
  border-color: #44337a;
  border-color: rgba(68, 51, 122, var(--border-opacity));
}

.hover\:tw-border-pink-100:hover {
  --border-opacity: 1;
  border-color: #fff5f7;
  border-color: rgba(255, 245, 247, var(--border-opacity));
}

.hover\:tw-border-pink-200:hover {
  --border-opacity: 1;
  border-color: #fed7e2;
  border-color: rgba(254, 215, 226, var(--border-opacity));
}

.hover\:tw-border-pink-300:hover {
  --border-opacity: 1;
  border-color: #fbb6ce;
  border-color: rgba(251, 182, 206, var(--border-opacity));
}

.hover\:tw-border-pink-400:hover {
  --border-opacity: 1;
  border-color: #f687b3;
  border-color: rgba(246, 135, 179, var(--border-opacity));
}

.hover\:tw-border-pink-500:hover {
  --border-opacity: 1;
  border-color: #ed64a6;
  border-color: rgba(237, 100, 166, var(--border-opacity));
}

.hover\:tw-border-pink-600:hover {
  --border-opacity: 1;
  border-color: #d53f8c;
  border-color: rgba(213, 63, 140, var(--border-opacity));
}

.hover\:tw-border-pink-700:hover {
  --border-opacity: 1;
  border-color: #b83280;
  border-color: rgba(184, 50, 128, var(--border-opacity));
}

.hover\:tw-border-pink-800:hover {
  --border-opacity: 1;
  border-color: #97266d;
  border-color: rgba(151, 38, 109, var(--border-opacity));
}

.hover\:tw-border-pink-900:hover {
  --border-opacity: 1;
  border-color: #702459;
  border-color: rgba(112, 36, 89, var(--border-opacity));
}

.focus\:tw-border-transparent:focus {
  border-color: transparent;
}

.focus\:tw-border-current:focus {
  border-color: currentColor;
}

.focus\:tw-border-black:focus {
  --border-opacity: 1;
  border-color: #000;
  border-color: rgba(0, 0, 0, var(--border-opacity));
}

.focus\:tw-border-white:focus {
  --border-opacity: 1;
  border-color: #fff;
  border-color: rgba(255, 255, 255, var(--border-opacity));
}

.focus\:tw-border-gray-100:focus {
  --border-opacity: 1;
  border-color: #f7fafc;
  border-color: rgba(247, 250, 252, var(--border-opacity));
}

.focus\:tw-border-gray-200:focus {
  --border-opacity: 1;
  border-color: #edf2f7;
  border-color: rgba(237, 242, 247, var(--border-opacity));
}

.focus\:tw-border-gray-300:focus {
  --border-opacity: 1;
  border-color: #e2e8f0;
  border-color: rgba(226, 232, 240, var(--border-opacity));
}

.focus\:tw-border-gray-400:focus {
  --border-opacity: 1;
  border-color: #cbd5e0;
  border-color: rgba(203, 213, 224, var(--border-opacity));
}

.focus\:tw-border-gray-500:focus {
  --border-opacity: 1;
  border-color: #a0aec0;
  border-color: rgba(160, 174, 192, var(--border-opacity));
}

.focus\:tw-border-gray-600:focus {
  --border-opacity: 1;
  border-color: #718096;
  border-color: rgba(113, 128, 150, var(--border-opacity));
}

.focus\:tw-border-gray-700:focus {
  --border-opacity: 1;
  border-color: #4a5568;
  border-color: rgba(74, 85, 104, var(--border-opacity));
}

.focus\:tw-border-gray-800:focus {
  --border-opacity: 1;
  border-color: #2d3748;
  border-color: rgba(45, 55, 72, var(--border-opacity));
}

.focus\:tw-border-gray-900:focus {
  --border-opacity: 1;
  border-color: #1a202c;
  border-color: rgba(26, 32, 44, var(--border-opacity));
}

.focus\:tw-border-red-100:focus {
  --border-opacity: 1;
  border-color: #fff5f5;
  border-color: rgba(255, 245, 245, var(--border-opacity));
}

.focus\:tw-border-red-200:focus {
  --border-opacity: 1;
  border-color: #fed7d7;
  border-color: rgba(254, 215, 215, var(--border-opacity));
}

.focus\:tw-border-red-300:focus {
  --border-opacity: 1;
  border-color: #feb2b2;
  border-color: rgba(254, 178, 178, var(--border-opacity));
}

.focus\:tw-border-red-400:focus {
  --border-opacity: 1;
  border-color: #fc8181;
  border-color: rgba(252, 129, 129, var(--border-opacity));
}

.focus\:tw-border-red-500:focus {
  --border-opacity: 1;
  border-color: #f56565;
  border-color: rgba(245, 101, 101, var(--border-opacity));
}

.focus\:tw-border-red-600:focus {
  --border-opacity: 1;
  border-color: #e53e3e;
  border-color: rgba(229, 62, 62, var(--border-opacity));
}

.focus\:tw-border-red-700:focus {
  --border-opacity: 1;
  border-color: #c53030;
  border-color: rgba(197, 48, 48, var(--border-opacity));
}

.focus\:tw-border-red-800:focus {
  --border-opacity: 1;
  border-color: #9b2c2c;
  border-color: rgba(155, 44, 44, var(--border-opacity));
}

.focus\:tw-border-red-900:focus {
  --border-opacity: 1;
  border-color: #742a2a;
  border-color: rgba(116, 42, 42, var(--border-opacity));
}

.focus\:tw-border-orange-100:focus {
  --border-opacity: 1;
  border-color: #fffaf0;
  border-color: rgba(255, 250, 240, var(--border-opacity));
}

.focus\:tw-border-orange-200:focus {
  --border-opacity: 1;
  border-color: #feebc8;
  border-color: rgba(254, 235, 200, var(--border-opacity));
}

.focus\:tw-border-orange-300:focus {
  --border-opacity: 1;
  border-color: #fbd38d;
  border-color: rgba(251, 211, 141, var(--border-opacity));
}

.focus\:tw-border-orange-400:focus {
  --border-opacity: 1;
  border-color: #f6ad55;
  border-color: rgba(246, 173, 85, var(--border-opacity));
}

.focus\:tw-border-orange-500:focus {
  --border-opacity: 1;
  border-color: #ed8936;
  border-color: rgba(237, 137, 54, var(--border-opacity));
}

.focus\:tw-border-orange-600:focus {
  --border-opacity: 1;
  border-color: #dd6b20;
  border-color: rgba(221, 107, 32, var(--border-opacity));
}

.focus\:tw-border-orange-700:focus {
  --border-opacity: 1;
  border-color: #c05621;
  border-color: rgba(192, 86, 33, var(--border-opacity));
}

.focus\:tw-border-orange-800:focus {
  --border-opacity: 1;
  border-color: #9c4221;
  border-color: rgba(156, 66, 33, var(--border-opacity));
}

.focus\:tw-border-orange-900:focus {
  --border-opacity: 1;
  border-color: #7b341e;
  border-color: rgba(123, 52, 30, var(--border-opacity));
}

.focus\:tw-border-yellow-100:focus {
  --border-opacity: 1;
  border-color: #fffff0;
  border-color: rgba(255, 255, 240, var(--border-opacity));
}

.focus\:tw-border-yellow-200:focus {
  --border-opacity: 1;
  border-color: #fefcbf;
  border-color: rgba(254, 252, 191, var(--border-opacity));
}

.focus\:tw-border-yellow-300:focus {
  --border-opacity: 1;
  border-color: #faf089;
  border-color: rgba(250, 240, 137, var(--border-opacity));
}

.focus\:tw-border-yellow-400:focus {
  --border-opacity: 1;
  border-color: #f6e05e;
  border-color: rgba(246, 224, 94, var(--border-opacity));
}

.focus\:tw-border-yellow-500:focus {
  --border-opacity: 1;
  border-color: #ecc94b;
  border-color: rgba(236, 201, 75, var(--border-opacity));
}

.focus\:tw-border-yellow-600:focus {
  --border-opacity: 1;
  border-color: #d69e2e;
  border-color: rgba(214, 158, 46, var(--border-opacity));
}

.focus\:tw-border-yellow-700:focus {
  --border-opacity: 1;
  border-color: #b7791f;
  border-color: rgba(183, 121, 31, var(--border-opacity));
}

.focus\:tw-border-yellow-800:focus {
  --border-opacity: 1;
  border-color: #975a16;
  border-color: rgba(151, 90, 22, var(--border-opacity));
}

.focus\:tw-border-yellow-900:focus {
  --border-opacity: 1;
  border-color: #744210;
  border-color: rgba(116, 66, 16, var(--border-opacity));
}

.focus\:tw-border-green-100:focus {
  --border-opacity: 1;
  border-color: #f0fff4;
  border-color: rgba(240, 255, 244, var(--border-opacity));
}

.focus\:tw-border-green-200:focus {
  --border-opacity: 1;
  border-color: #c6f6d5;
  border-color: rgba(198, 246, 213, var(--border-opacity));
}

.focus\:tw-border-green-300:focus {
  --border-opacity: 1;
  border-color: #9ae6b4;
  border-color: rgba(154, 230, 180, var(--border-opacity));
}

.focus\:tw-border-green-400:focus {
  --border-opacity: 1;
  border-color: #68d391;
  border-color: rgba(104, 211, 145, var(--border-opacity));
}

.focus\:tw-border-green-500:focus {
  --border-opacity: 1;
  border-color: #48bb78;
  border-color: rgba(72, 187, 120, var(--border-opacity));
}

.focus\:tw-border-green-600:focus {
  --border-opacity: 1;
  border-color: #38a169;
  border-color: rgba(56, 161, 105, var(--border-opacity));
}

.focus\:tw-border-green-700:focus {
  --border-opacity: 1;
  border-color: #2f855a;
  border-color: rgba(47, 133, 90, var(--border-opacity));
}

.focus\:tw-border-green-800:focus {
  --border-opacity: 1;
  border-color: #276749;
  border-color: rgba(39, 103, 73, var(--border-opacity));
}

.focus\:tw-border-green-900:focus {
  --border-opacity: 1;
  border-color: #22543d;
  border-color: rgba(34, 84, 61, var(--border-opacity));
}

.focus\:tw-border-teal-100:focus {
  --border-opacity: 1;
  border-color: #e6fffa;
  border-color: rgba(230, 255, 250, var(--border-opacity));
}

.focus\:tw-border-teal-200:focus {
  --border-opacity: 1;
  border-color: #b2f5ea;
  border-color: rgba(178, 245, 234, var(--border-opacity));
}

.focus\:tw-border-teal-300:focus {
  --border-opacity: 1;
  border-color: #81e6d9;
  border-color: rgba(129, 230, 217, var(--border-opacity));
}

.focus\:tw-border-teal-400:focus {
  --border-opacity: 1;
  border-color: #4fd1c5;
  border-color: rgba(79, 209, 197, var(--border-opacity));
}

.focus\:tw-border-teal-500:focus {
  --border-opacity: 1;
  border-color: #38b2ac;
  border-color: rgba(56, 178, 172, var(--border-opacity));
}

.focus\:tw-border-teal-600:focus {
  --border-opacity: 1;
  border-color: #319795;
  border-color: rgba(49, 151, 149, var(--border-opacity));
}

.focus\:tw-border-teal-700:focus {
  --border-opacity: 1;
  border-color: #2c7a7b;
  border-color: rgba(44, 122, 123, var(--border-opacity));
}

.focus\:tw-border-teal-800:focus {
  --border-opacity: 1;
  border-color: #285e61;
  border-color: rgba(40, 94, 97, var(--border-opacity));
}

.focus\:tw-border-teal-900:focus {
  --border-opacity: 1;
  border-color: #234e52;
  border-color: rgba(35, 78, 82, var(--border-opacity));
}

.focus\:tw-border-blue-100:focus {
  --border-opacity: 1;
  border-color: #ebf8ff;
  border-color: rgba(235, 248, 255, var(--border-opacity));
}

.focus\:tw-border-blue-200:focus {
  --border-opacity: 1;
  border-color: #bee3f8;
  border-color: rgba(190, 227, 248, var(--border-opacity));
}

.focus\:tw-border-blue-300:focus {
  --border-opacity: 1;
  border-color: #90cdf4;
  border-color: rgba(144, 205, 244, var(--border-opacity));
}

.focus\:tw-border-blue-400:focus {
  --border-opacity: 1;
  border-color: #63b3ed;
  border-color: rgba(99, 179, 237, var(--border-opacity));
}

.focus\:tw-border-blue-500:focus {
  --border-opacity: 1;
  border-color: #4299e1;
  border-color: rgba(66, 153, 225, var(--border-opacity));
}

.focus\:tw-border-blue-600:focus {
  --border-opacity: 1;
  border-color: #3182ce;
  border-color: rgba(49, 130, 206, var(--border-opacity));
}

.focus\:tw-border-blue-700:focus {
  --border-opacity: 1;
  border-color: #2b6cb0;
  border-color: rgba(43, 108, 176, var(--border-opacity));
}

.focus\:tw-border-blue-800:focus {
  --border-opacity: 1;
  border-color: #2c5282;
  border-color: rgba(44, 82, 130, var(--border-opacity));
}

.focus\:tw-border-blue-900:focus {
  --border-opacity: 1;
  border-color: #2a4365;
  border-color: rgba(42, 67, 101, var(--border-opacity));
}

.focus\:tw-border-indigo-100:focus {
  --border-opacity: 1;
  border-color: #ebf4ff;
  border-color: rgba(235, 244, 255, var(--border-opacity));
}

.focus\:tw-border-indigo-200:focus {
  --border-opacity: 1;
  border-color: #c3dafe;
  border-color: rgba(195, 218, 254, var(--border-opacity));
}

.focus\:tw-border-indigo-300:focus {
  --border-opacity: 1;
  border-color: #a3bffa;
  border-color: rgba(163, 191, 250, var(--border-opacity));
}

.focus\:tw-border-indigo-400:focus {
  --border-opacity: 1;
  border-color: #7f9cf5;
  border-color: rgba(127, 156, 245, var(--border-opacity));
}

.focus\:tw-border-indigo-500:focus {
  --border-opacity: 1;
  border-color: #667eea;
  border-color: rgba(102, 126, 234, var(--border-opacity));
}

.focus\:tw-border-indigo-600:focus {
  --border-opacity: 1;
  border-color: #5a67d8;
  border-color: rgba(90, 103, 216, var(--border-opacity));
}

.focus\:tw-border-indigo-700:focus {
  --border-opacity: 1;
  border-color: #4c51bf;
  border-color: rgba(76, 81, 191, var(--border-opacity));
}

.focus\:tw-border-indigo-800:focus {
  --border-opacity: 1;
  border-color: #434190;
  border-color: rgba(67, 65, 144, var(--border-opacity));
}

.focus\:tw-border-indigo-900:focus {
  --border-opacity: 1;
  border-color: #3c366b;
  border-color: rgba(60, 54, 107, var(--border-opacity));
}

.focus\:tw-border-purple-100:focus {
  --border-opacity: 1;
  border-color: #faf5ff;
  border-color: rgba(250, 245, 255, var(--border-opacity));
}

.focus\:tw-border-purple-200:focus {
  --border-opacity: 1;
  border-color: #e9d8fd;
  border-color: rgba(233, 216, 253, var(--border-opacity));
}

.focus\:tw-border-purple-300:focus {
  --border-opacity: 1;
  border-color: #d6bcfa;
  border-color: rgba(214, 188, 250, var(--border-opacity));
}

.focus\:tw-border-purple-400:focus {
  --border-opacity: 1;
  border-color: #b794f4;
  border-color: rgba(183, 148, 244, var(--border-opacity));
}

.focus\:tw-border-purple-500:focus {
  --border-opacity: 1;
  border-color: #9f7aea;
  border-color: rgba(159, 122, 234, var(--border-opacity));
}

.focus\:tw-border-purple-600:focus {
  --border-opacity: 1;
  border-color: #805ad5;
  border-color: rgba(128, 90, 213, var(--border-opacity));
}

.focus\:tw-border-purple-700:focus {
  --border-opacity: 1;
  border-color: #6b46c1;
  border-color: rgba(107, 70, 193, var(--border-opacity));
}

.focus\:tw-border-purple-800:focus {
  --border-opacity: 1;
  border-color: #553c9a;
  border-color: rgba(85, 60, 154, var(--border-opacity));
}

.focus\:tw-border-purple-900:focus {
  --border-opacity: 1;
  border-color: #44337a;
  border-color: rgba(68, 51, 122, var(--border-opacity));
}

.focus\:tw-border-pink-100:focus {
  --border-opacity: 1;
  border-color: #fff5f7;
  border-color: rgba(255, 245, 247, var(--border-opacity));
}

.focus\:tw-border-pink-200:focus {
  --border-opacity: 1;
  border-color: #fed7e2;
  border-color: rgba(254, 215, 226, var(--border-opacity));
}

.focus\:tw-border-pink-300:focus {
  --border-opacity: 1;
  border-color: #fbb6ce;
  border-color: rgba(251, 182, 206, var(--border-opacity));
}

.focus\:tw-border-pink-400:focus {
  --border-opacity: 1;
  border-color: #f687b3;
  border-color: rgba(246, 135, 179, var(--border-opacity));
}

.focus\:tw-border-pink-500:focus {
  --border-opacity: 1;
  border-color: #ed64a6;
  border-color: rgba(237, 100, 166, var(--border-opacity));
}

.focus\:tw-border-pink-600:focus {
  --border-opacity: 1;
  border-color: #d53f8c;
  border-color: rgba(213, 63, 140, var(--border-opacity));
}

.focus\:tw-border-pink-700:focus {
  --border-opacity: 1;
  border-color: #b83280;
  border-color: rgba(184, 50, 128, var(--border-opacity));
}

.focus\:tw-border-pink-800:focus {
  --border-opacity: 1;
  border-color: #97266d;
  border-color: rgba(151, 38, 109, var(--border-opacity));
}

.focus\:tw-border-pink-900:focus {
  --border-opacity: 1;
  border-color: #702459;
  border-color: rgba(112, 36, 89, var(--border-opacity));
}

.tw-border-opacity-0 {
  --border-opacity: 0;
}

.tw-border-opacity-25 {
  --border-opacity: 0.25;
}

.tw-border-opacity-50 {
  --border-opacity: 0.5;
}

.tw-border-opacity-75 {
  --border-opacity: 0.75;
}

.tw-border-opacity-100 {
  --border-opacity: 1;
}

.hover\:tw-border-opacity-0:hover {
  --border-opacity: 0;
}

.hover\:tw-border-opacity-25:hover {
  --border-opacity: 0.25;
}

.hover\:tw-border-opacity-50:hover {
  --border-opacity: 0.5;
}

.hover\:tw-border-opacity-75:hover {
  --border-opacity: 0.75;
}

.hover\:tw-border-opacity-100:hover {
  --border-opacity: 1;
}

.focus\:tw-border-opacity-0:focus {
  --border-opacity: 0;
}

.focus\:tw-border-opacity-25:focus {
  --border-opacity: 0.25;
}

.focus\:tw-border-opacity-50:focus {
  --border-opacity: 0.5;
}

.focus\:tw-border-opacity-75:focus {
  --border-opacity: 0.75;
}

.focus\:tw-border-opacity-100:focus {
  --border-opacity: 1;
}

.tw-rounded-none {
  border-radius: 0;
}

.tw-rounded-sm {
  border-radius: 0.125rem;
}

.tw-rounded {
  border-radius: 0.25rem;
}

.tw-rounded-md {
  border-radius: 0.375rem;
}

.tw-rounded-lg {
  border-radius: 0.5rem;
}

.tw-rounded-xl {
  border-radius: 0.75rem;
}

.tw-rounded-2xl {
  border-radius: 1rem;
}

.tw-rounded-3xl {
  border-radius: 1.5rem;
}

.tw-rounded-full {
  border-radius: 9999px;
}

.tw-rounded-t-none {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.tw-rounded-r-none {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.tw-rounded-b-none {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.tw-rounded-l-none {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.tw-rounded-t-sm {
  border-top-left-radius: 0.125rem;
  border-top-right-radius: 0.125rem;
}

.tw-rounded-r-sm {
  border-top-right-radius: 0.125rem;
  border-bottom-right-radius: 0.125rem;
}

.tw-rounded-b-sm {
  border-bottom-right-radius: 0.125rem;
  border-bottom-left-radius: 0.125rem;
}

.tw-rounded-l-sm {
  border-top-left-radius: 0.125rem;
  border-bottom-left-radius: 0.125rem;
}

.tw-rounded-t {
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}

.tw-rounded-r {
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
}

.tw-rounded-b {
  border-bottom-right-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}

.tw-rounded-l {
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}

.tw-rounded-t-md {
  border-top-left-radius: 0.375rem;
  border-top-right-radius: 0.375rem;
}

.tw-rounded-r-md {
  border-top-right-radius: 0.375rem;
  border-bottom-right-radius: 0.375rem;
}

.tw-rounded-b-md {
  border-bottom-right-radius: 0.375rem;
  border-bottom-left-radius: 0.375rem;
}

.tw-rounded-l-md {
  border-top-left-radius: 0.375rem;
  border-bottom-left-radius: 0.375rem;
}

.tw-rounded-t-lg {
  border-top-left-radius: 0.5rem;
  border-top-right-radius: 0.5rem;
}

.tw-rounded-r-lg {
  border-top-right-radius: 0.5rem;
  border-bottom-right-radius: 0.5rem;
}

.tw-rounded-b-lg {
  border-bottom-right-radius: 0.5rem;
  border-bottom-left-radius: 0.5rem;
}

.tw-rounded-l-lg {
  border-top-left-radius: 0.5rem;
  border-bottom-left-radius: 0.5rem;
}

.tw-rounded-t-xl {
  border-top-left-radius: 0.75rem;
  border-top-right-radius: 0.75rem;
}

.tw-rounded-r-xl {
  border-top-right-radius: 0.75rem;
  border-bottom-right-radius: 0.75rem;
}

.tw-rounded-b-xl {
  border-bottom-right-radius: 0.75rem;
  border-bottom-left-radius: 0.75rem;
}

.tw-rounded-l-xl {
  border-top-left-radius: 0.75rem;
  border-bottom-left-radius: 0.75rem;
}

.tw-rounded-t-2xl {
  border-top-left-radius: 1rem;
  border-top-right-radius: 1rem;
}

.tw-rounded-r-2xl {
  border-top-right-radius: 1rem;
  border-bottom-right-radius: 1rem;
}

.tw-rounded-b-2xl {
  border-bottom-right-radius: 1rem;
  border-bottom-left-radius: 1rem;
}

.tw-rounded-l-2xl {
  border-top-left-radius: 1rem;
  border-bottom-left-radius: 1rem;
}

.tw-rounded-t-3xl {
  border-top-left-radius: 1.5rem;
  border-top-right-radius: 1.5rem;
}

.tw-rounded-r-3xl {
  border-top-right-radius: 1.5rem;
  border-bottom-right-radius: 1.5rem;
}

.tw-rounded-b-3xl {
  border-bottom-right-radius: 1.5rem;
  border-bottom-left-radius: 1.5rem;
}

.tw-rounded-l-3xl {
  border-top-left-radius: 1.5rem;
  border-bottom-left-radius: 1.5rem;
}

.tw-rounded-t-full {
  border-top-left-radius: 9999px;
  border-top-right-radius: 9999px;
}

.tw-rounded-r-full {
  border-top-right-radius: 9999px;
  border-bottom-right-radius: 9999px;
}

.tw-rounded-b-full {
  border-bottom-right-radius: 9999px;
  border-bottom-left-radius: 9999px;
}

.tw-rounded-l-full {
  border-top-left-radius: 9999px;
  border-bottom-left-radius: 9999px;
}

.tw-rounded-tl-none {
  border-top-left-radius: 0;
}

.tw-rounded-tr-none {
  border-top-right-radius: 0;
}

.tw-rounded-br-none {
  border-bottom-right-radius: 0;
}

.tw-rounded-bl-none {
  border-bottom-left-radius: 0;
}

.tw-rounded-tl-sm {
  border-top-left-radius: 0.125rem;
}

.tw-rounded-tr-sm {
  border-top-right-radius: 0.125rem;
}

.tw-rounded-br-sm {
  border-bottom-right-radius: 0.125rem;
}

.tw-rounded-bl-sm {
  border-bottom-left-radius: 0.125rem;
}

.tw-rounded-tl {
  border-top-left-radius: 0.25rem;
}

.tw-rounded-tr {
  border-top-right-radius: 0.25rem;
}

.tw-rounded-br {
  border-bottom-right-radius: 0.25rem;
}

.tw-rounded-bl {
  border-bottom-left-radius: 0.25rem;
}

.tw-rounded-tl-md {
  border-top-left-radius: 0.375rem;
}

.tw-rounded-tr-md {
  border-top-right-radius: 0.375rem;
}

.tw-rounded-br-md {
  border-bottom-right-radius: 0.375rem;
}

.tw-rounded-bl-md {
  border-bottom-left-radius: 0.375rem;
}

.tw-rounded-tl-lg {
  border-top-left-radius: 0.5rem;
}

.tw-rounded-tr-lg {
  border-top-right-radius: 0.5rem;
}

.tw-rounded-br-lg {
  border-bottom-right-radius: 0.5rem;
}

.tw-rounded-bl-lg {
  border-bottom-left-radius: 0.5rem;
}

.tw-rounded-tl-xl {
  border-top-left-radius: 0.75rem;
}

.tw-rounded-tr-xl {
  border-top-right-radius: 0.75rem;
}

.tw-rounded-br-xl {
  border-bottom-right-radius: 0.75rem;
}

.tw-rounded-bl-xl {
  border-bottom-left-radius: 0.75rem;
}

.tw-rounded-tl-2xl {
  border-top-left-radius: 1rem;
}

.tw-rounded-tr-2xl {
  border-top-right-radius: 1rem;
}

.tw-rounded-br-2xl {
  border-bottom-right-radius: 1rem;
}

.tw-rounded-bl-2xl {
  border-bottom-left-radius: 1rem;
}

.tw-rounded-tl-3xl {
  border-top-left-radius: 1.5rem;
}

.tw-rounded-tr-3xl {
  border-top-right-radius: 1.5rem;
}

.tw-rounded-br-3xl {
  border-bottom-right-radius: 1.5rem;
}

.tw-rounded-bl-3xl {
  border-bottom-left-radius: 1.5rem;
}

.tw-rounded-tl-full {
  border-top-left-radius: 9999px;
}

.tw-rounded-tr-full {
  border-top-right-radius: 9999px;
}

.tw-rounded-br-full {
  border-bottom-right-radius: 9999px;
}

.tw-rounded-bl-full {
  border-bottom-left-radius: 9999px;
}

.tw-border-solid {
  border-style: solid;
}

.tw-border-dashed {
  border-style: dashed;
}

.tw-border-dotted {
  border-style: dotted;
}

.tw-border-double {
  border-style: double;
}

.tw-border-none {
  border-style: none;
}

.tw-border-0 {
  border-width: 0;
}

.tw-border-2 {
  border-width: 2px;
}

.tw-border-4 {
  border-width: 4px;
}

.tw-border-8 {
  border-width: 8px;
}

.tw-border {
  border-width: 1px;
}

.tw-border-t-0 {
  border-top-width: 0;
}

.tw-border-r-0 {
  border-right-width: 0;
}

.tw-border-b-0 {
  border-bottom-width: 0;
}

.tw-border-l-0 {
  border-left-width: 0;
}

.tw-border-t-2 {
  border-top-width: 2px;
}

.tw-border-r-2 {
  border-right-width: 2px;
}

.tw-border-b-2 {
  border-bottom-width: 2px;
}

.tw-border-l-2 {
  border-left-width: 2px;
}

.tw-border-t-4 {
  border-top-width: 4px;
}

.tw-border-r-4 {
  border-right-width: 4px;
}

.tw-border-b-4 {
  border-bottom-width: 4px;
}

.tw-border-l-4 {
  border-left-width: 4px;
}

.tw-border-t-8 {
  border-top-width: 8px;
}

.tw-border-r-8 {
  border-right-width: 8px;
}

.tw-border-b-8 {
  border-bottom-width: 8px;
}

.tw-border-l-8 {
  border-left-width: 8px;
}

.tw-border-t {
  border-top-width: 1px;
}

.tw-border-r {
  border-right-width: 1px;
}

.tw-border-b {
  border-bottom-width: 1px;
}

.tw-border-l {
  border-left-width: 1px;
}

.tw-box-border {
  box-sizing: border-box;
}

.tw-box-content {
  box-sizing: content-box;
}

.tw-cursor-auto {
  cursor: auto;
}

.tw-cursor-default {
  cursor: default;
}

.tw-cursor-pointer {
  cursor: pointer;
}

.tw-cursor-wait {
  cursor: wait;
}

.tw-cursor-text {
  cursor: text;
}

.tw-cursor-move {
  cursor: move;
}

.tw-cursor-not-allowed {
  cursor: not-allowed;
}

.tw-block {
  display: block;
}

.tw-inline-block {
  display: inline-block;
}

.tw-inline {
  display: inline;
}

.tw-flex {
  display: flex;
}

.tw-inline-flex {
  display: inline-flex;
}

.tw-table {
  display: table;
}

.tw-table-caption {
  display: table-caption;
}

.tw-table-cell {
  display: table-cell;
}

.tw-table-column {
  display: table-column;
}

.tw-table-column-group {
  display: table-column-group;
}

.tw-table-footer-group {
  display: table-footer-group;
}

.tw-table-header-group {
  display: table-header-group;
}

.tw-table-row-group {
  display: table-row-group;
}

.tw-table-row {
  display: table-row;
}

.tw-flow-root {
  display: flow-root;
}

.tw-grid {
  display: grid;
}

.tw-inline-grid {
  display: inline-grid;
}

.tw-contents {
  display: contents;
}

.tw-hidden {
  display: none;
}

.tw-flex-row {
  flex-direction: row;
}

.tw-flex-row-reverse {
  flex-direction: row-reverse;
}

.tw-flex-col {
  flex-direction: column;
}

.tw-flex-col-reverse {
  flex-direction: column-reverse;
}

.tw-flex-wrap {
  flex-wrap: wrap;
}

.tw-flex-wrap-reverse {
  flex-wrap: wrap-reverse;
}

.tw-flex-no-wrap {
  flex-wrap: nowrap;
}

.tw-place-items-auto {
  place-items: auto;
}

.tw-place-items-start {
  place-items: start;
}

.tw-place-items-end {
  place-items: end;
}

.tw-place-items-center {
  place-items: center;
}

.tw-place-items-stretch {
  place-items: stretch;
}

.tw-place-content-center {
  place-content: center;
}

.tw-place-content-start {
  place-content: start;
}

.tw-place-content-end {
  place-content: end;
}

.tw-place-content-between {
  place-content: space-between;
}

.tw-place-content-around {
  place-content: space-around;
}

.tw-place-content-evenly {
  place-content: space-evenly;
}

.tw-place-content-stretch {
  place-content: stretch;
}

.tw-place-self-auto {
  place-self: auto;
}

.tw-place-self-start {
  place-self: start;
}

.tw-place-self-end {
  place-self: end;
}

.tw-place-self-center {
  place-self: center;
}

.tw-place-self-stretch {
  place-self: stretch;
}

.tw-items-start {
  align-items: flex-start;
}

.tw-items-end {
  align-items: flex-end;
}

.tw-items-center {
  align-items: center;
}

.tw-items-baseline {
  align-items: baseline;
}

.tw-items-stretch {
  align-items: stretch;
}

.tw-content-center {
  align-content: center;
}

.tw-content-start {
  align-content: flex-start;
}

.tw-content-end {
  align-content: flex-end;
}

.tw-content-between {
  align-content: space-between;
}

.tw-content-around {
  align-content: space-around;
}

.tw-content-evenly {
  align-content: space-evenly;
}

.tw-self-auto {
  align-self: auto;
}

.tw-self-start {
  align-self: flex-start;
}

.tw-self-end {
  align-self: flex-end;
}

.tw-self-center {
  align-self: center;
}

.tw-self-stretch {
  align-self: stretch;
}

.tw-justify-items-auto {
  justify-items: auto;
}

.tw-justify-items-start {
  justify-items: start;
}

.tw-justify-items-end {
  justify-items: end;
}

.tw-justify-items-center {
  justify-items: center;
}

.tw-justify-items-stretch {
  justify-items: stretch;
}

.tw-justify-start {
  justify-content: flex-start;
}

.tw-justify-end {
  justify-content: flex-end;
}

.tw-justify-center {
  justify-content: center;
}

.tw-justify-between {
  justify-content: space-between;
}

.tw-justify-around {
  justify-content: space-around;
}

.tw-justify-evenly {
  justify-content: space-evenly;
}

.tw-justify-self-auto {
  justify-self: auto;
}

.tw-justify-self-start {
  justify-self: start;
}

.tw-justify-self-end {
  justify-self: end;
}

.tw-justify-self-center {
  justify-self: center;
}

.tw-justify-self-stretch {
  justify-self: stretch;
}

.tw-flex-1 {
  flex: 1 1 0%;
}

.tw-flex-auto {
  flex: 1 1 auto;
}

.tw-flex-initial {
  flex: 0 1 auto;
}

.tw-flex-none {
  flex: none;
}

.tw-flex-grow-0 {
  flex-grow: 0;
}

.tw-flex-grow {
  flex-grow: 1;
}

.tw-flex-shrink-0 {
  flex-shrink: 0;
}

.tw-flex-shrink {
  flex-shrink: 1;
}

.tw-order-1 {
  order: 1;
}

.tw-order-2 {
  order: 2;
}

.tw-order-3 {
  order: 3;
}

.tw-order-4 {
  order: 4;
}

.tw-order-5 {
  order: 5;
}

.tw-order-6 {
  order: 6;
}

.tw-order-7 {
  order: 7;
}

.tw-order-8 {
  order: 8;
}

.tw-order-9 {
  order: 9;
}

.tw-order-10 {
  order: 10;
}

.tw-order-11 {
  order: 11;
}

.tw-order-12 {
  order: 12;
}

.tw-order-first {
  order: -9999;
}

.tw-order-last {
  order: 9999;
}

.tw-order-none {
  order: 0;
}

.tw-float-right {
  float: right;
}

.tw-float-left {
  float: left;
}

.tw-float-none {
  float: none;
}

.tw-clearfix:after {
  content: "";
  display: table;
  clear: both;
}

.tw-clear-left {
  clear: left;
}

.tw-clear-right {
  clear: right;
}

.tw-clear-both {
  clear: both;
}

.tw-clear-none {
  clear: none;
}

.tw-font-sans {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
}

.tw-font-serif {
  font-family: Georgia, Cambria, "Times New Roman", Times, serif;
}

.tw-font-mono {
  font-family: Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
}

.tw-font-hairline {
  font-weight: 100;
}

.tw-font-thin {
  font-weight: 200;
}

.tw-font-light {
  font-weight: 300;
}

.tw-font-normal {
  font-weight: 400;
}

.tw-font-medium {
  font-weight: 500;
}

.tw-font-semibold {
  font-weight: 600;
}

.tw-font-bold {
  font-weight: 700;
}

.tw-font-extrabold {
  font-weight: 800;
}

.tw-font-black {
  font-weight: 900;
}

.hover\:tw-font-hairline:hover {
  font-weight: 100;
}

.hover\:tw-font-thin:hover {
  font-weight: 200;
}

.hover\:tw-font-light:hover {
  font-weight: 300;
}

.hover\:tw-font-normal:hover {
  font-weight: 400;
}

.hover\:tw-font-medium:hover {
  font-weight: 500;
}

.hover\:tw-font-semibold:hover {
  font-weight: 600;
}

.hover\:tw-font-bold:hover {
  font-weight: 700;
}

.hover\:tw-font-extrabold:hover {
  font-weight: 800;
}

.hover\:tw-font-black:hover {
  font-weight: 900;
}

.focus\:tw-font-hairline:focus {
  font-weight: 100;
}

.focus\:tw-font-thin:focus {
  font-weight: 200;
}

.focus\:tw-font-light:focus {
  font-weight: 300;
}

.focus\:tw-font-normal:focus {
  font-weight: 400;
}

.focus\:tw-font-medium:focus {
  font-weight: 500;
}

.focus\:tw-font-semibold:focus {
  font-weight: 600;
}

.focus\:tw-font-bold:focus {
  font-weight: 700;
}

.focus\:tw-font-extrabold:focus {
  font-weight: 800;
}

.focus\:tw-font-black:focus {
  font-weight: 900;
}

.tw-h-0 {
  height: 0;
}

.tw-h-1 {
  height: 0.25rem;
}

.tw-h-2 {
  height: 0.5rem;
}

.tw-h-3 {
  height: 0.75rem;
}

.tw-h-4 {
  height: 1rem;
}

.tw-h-5 {
  height: 1.25rem;
}

.tw-h-6 {
  height: 1.5rem;
}

.tw-h-8 {
  height: 2rem;
}

.tw-h-10 {
  height: 2.5rem;
}

.tw-h-12 {
  height: 3rem;
}

.tw-h-16 {
  height: 4rem;
}

.tw-h-20 {
  height: 5rem;
}

.tw-h-24 {
  height: 6rem;
}

.tw-h-32 {
  height: 8rem;
}

.tw-h-40 {
  height: 10rem;
}

.tw-h-48 {
  height: 12rem;
}

.tw-h-56 {
  height: 14rem;
}

.tw-h-64 {
  height: 16rem;
}

.tw-h-auto {
  height: auto;
}

.tw-h-px {
  height: 1px;
}

.tw-h-270px {
  height: 270px;
}

.tw-h-400px {
  height: 400px;
}

.tw-h-464px {
  height: 464px;
}

.tw-h-480px {
  height: 480px;
}

.tw-h-500px {
  height: 500px;
}

.tw-h-576px {
  height: 576px;
}

.tw-h-600px {
  height: 600px;
}

.tw-h-672px {
  height: 672px;
}

.tw-h-full {
  height: 100%;
}

.tw-h-screen {
  height: 100vh;
}

.tw-text-xs {
  font-size: 0.75rem;
}

.tw-text-sm {
  font-size: 0.875rem;
}

.tw-text-base {
  font-size: 1rem;
}

.tw-text-lg {
  font-size: 1.125rem;
}

.tw-text-xl {
  font-size: 1.25rem;
}

.tw-text-2xl {
  font-size: 1.5rem;
}

.tw-text-3xl {
  font-size: 1.875rem;
}

.tw-text-4xl {
  font-size: 2.25rem;
}

.tw-text-5xl {
  font-size: 3rem;
}

.tw-text-6xl {
  font-size: 4rem;
}

.tw-leading-3 {
  line-height: .75rem;
}

.tw-leading-4 {
  line-height: 1rem;
}

.tw-leading-5 {
  line-height: 1.25rem;
}

.tw-leading-6 {
  line-height: 1.5rem;
}

.tw-leading-7 {
  line-height: 1.75rem;
}

.tw-leading-8 {
  line-height: 2rem;
}

.tw-leading-9 {
  line-height: 2.25rem;
}

.tw-leading-10 {
  line-height: 2.5rem;
}

.tw-leading-none {
  line-height: 1;
}

.tw-leading-tight {
  line-height: 1.25;
}

.tw-leading-snug {
  line-height: 1.375;
}

.tw-leading-normal {
  line-height: 1.5;
}

.tw-leading-relaxed {
  line-height: 1.625;
}

.tw-leading-loose {
  line-height: 2;
}

.tw-list-inside {
  list-style-position: inside;
}

.tw-list-outside {
  list-style-position: outside;
}

.tw-list-none {
  list-style-type: none;
}

.tw-list-disc {
  list-style-type: disc;
}

.tw-list-decimal {
  list-style-type: decimal;
}

.tw-m-0 {
  margin: 0;
}

.tw-m-1 {
  margin: 0.25rem;
}

.tw-m-2 {
  margin: 0.5rem;
}

.tw-m-3 {
  margin: 0.75rem;
}

.tw-m-4 {
  margin: 1rem;
}

.tw-m-5 {
  margin: 1.25rem;
}

.tw-m-6 {
  margin: 1.5rem;
}

.tw-m-8 {
  margin: 2rem;
}

.tw-m-10 {
  margin: 2.5rem;
}

.tw-m-12 {
  margin: 3rem;
}

.tw-m-16 {
  margin: 4rem;
}

.tw-m-20 {
  margin: 5rem;
}

.tw-m-24 {
  margin: 6rem;
}

.tw-m-32 {
  margin: 8rem;
}

.tw-m-40 {
  margin: 10rem;
}

.tw-m-48 {
  margin: 12rem;
}

.tw-m-56 {
  margin: 14rem;
}

.tw-m-64 {
  margin: 16rem;
}

.tw-m-auto {
  margin: auto;
}

.tw-m-px {
  margin: 1px;
}

.tw-m-270px {
  margin: 270px;
}

.tw-m-400px {
  margin: 400px;
}

.tw-m-464px {
  margin: 464px;
}

.tw-m-480px {
  margin: 480px;
}

.tw-m-500px {
  margin: 500px;
}

.tw-m-576px {
  margin: 576px;
}

.tw-m-600px {
  margin: 600px;
}

.tw-m-672px {
  margin: 672px;
}

.tw--m-1 {
  margin: -0.25rem;
}

.tw--m-2 {
  margin: -0.5rem;
}

.tw--m-3 {
  margin: -0.75rem;
}

.tw--m-4 {
  margin: -1rem;
}

.tw--m-5 {
  margin: -1.25rem;
}

.tw--m-6 {
  margin: -1.5rem;
}

.tw--m-8 {
  margin: -2rem;
}

.tw--m-10 {
  margin: -2.5rem;
}

.tw--m-12 {
  margin: -3rem;
}

.tw--m-16 {
  margin: -4rem;
}

.tw--m-20 {
  margin: -5rem;
}

.tw--m-24 {
  margin: -6rem;
}

.tw--m-32 {
  margin: -8rem;
}

.tw--m-40 {
  margin: -10rem;
}

.tw--m-48 {
  margin: -12rem;
}

.tw--m-56 {
  margin: -14rem;
}

.tw--m-64 {
  margin: -16rem;
}

.tw--m-px {
  margin: -1px;
}

.tw--m-270px {
  margin: -270px;
}

.tw--m-400px {
  margin: -400px;
}

.tw--m-464px {
  margin: -464px;
}

.tw--m-480px {
  margin: -480px;
}

.tw--m-500px {
  margin: -500px;
}

.tw--m-576px {
  margin: -576px;
}

.tw--m-600px {
  margin: -600px;
}

.tw--m-672px {
  margin: -672px;
}

.tw-my-0 {
  margin-top: 0;
  margin-bottom: 0;
}

.tw-mx-0 {
  margin-left: 0;
  margin-right: 0;
}

.tw-my-1 {
  margin-top: 0.25rem;
  margin-bottom: 0.25rem;
}

.tw-mx-1 {
  margin-left: 0.25rem;
  margin-right: 0.25rem;
}

.tw-my-2 {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}

.tw-mx-2 {
  margin-left: 0.5rem;
  margin-right: 0.5rem;
}

.tw-my-3 {
  margin-top: 0.75rem;
  margin-bottom: 0.75rem;
}

.tw-mx-3 {
  margin-left: 0.75rem;
  margin-right: 0.75rem;
}

.tw-my-4 {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.tw-mx-4 {
  margin-left: 1rem;
  margin-right: 1rem;
}

.tw-my-5 {
  margin-top: 1.25rem;
  margin-bottom: 1.25rem;
}

.tw-mx-5 {
  margin-left: 1.25rem;
  margin-right: 1.25rem;
}

.tw-my-6 {
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
}

.tw-mx-6 {
  margin-left: 1.5rem;
  margin-right: 1.5rem;
}

.tw-my-8 {
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.tw-mx-8 {
  margin-left: 2rem;
  margin-right: 2rem;
}

.tw-my-10 {
  margin-top: 2.5rem;
  margin-bottom: 2.5rem;
}

.tw-mx-10 {
  margin-left: 2.5rem;
  margin-right: 2.5rem;
}

.tw-my-12 {
  margin-top: 3rem;
  margin-bottom: 3rem;
}

.tw-mx-12 {
  margin-left: 3rem;
  margin-right: 3rem;
}

.tw-my-16 {
  margin-top: 4rem;
  margin-bottom: 4rem;
}

.tw-mx-16 {
  margin-left: 4rem;
  margin-right: 4rem;
}

.tw-my-20 {
  margin-top: 5rem;
  margin-bottom: 5rem;
}

.tw-mx-20 {
  margin-left: 5rem;
  margin-right: 5rem;
}

.tw-my-24 {
  margin-top: 6rem;
  margin-bottom: 6rem;
}

.tw-mx-24 {
  margin-left: 6rem;
  margin-right: 6rem;
}

.tw-my-32 {
  margin-top: 8rem;
  margin-bottom: 8rem;
}

.tw-mx-32 {
  margin-left: 8rem;
  margin-right: 8rem;
}

.tw-my-40 {
  margin-top: 10rem;
  margin-bottom: 10rem;
}

.tw-mx-40 {
  margin-left: 10rem;
  margin-right: 10rem;
}

.tw-my-48 {
  margin-top: 12rem;
  margin-bottom: 12rem;
}

.tw-mx-48 {
  margin-left: 12rem;
  margin-right: 12rem;
}

.tw-my-56 {
  margin-top: 14rem;
  margin-bottom: 14rem;
}

.tw-mx-56 {
  margin-left: 14rem;
  margin-right: 14rem;
}

.tw-my-64 {
  margin-top: 16rem;
  margin-bottom: 16rem;
}

.tw-mx-64 {
  margin-left: 16rem;
  margin-right: 16rem;
}

.tw-my-auto {
  margin-top: auto;
  margin-bottom: auto;
}

.tw-mx-auto {
  margin-left: auto;
  margin-right: auto;
}

.tw-my-px {
  margin-top: 1px;
  margin-bottom: 1px;
}

.tw-mx-px {
  margin-left: 1px;
  margin-right: 1px;
}

.tw-my-270px {
  margin-top: 270px;
  margin-bottom: 270px;
}

.tw-mx-270px {
  margin-left: 270px;
  margin-right: 270px;
}

.tw-my-400px {
  margin-top: 400px;
  margin-bottom: 400px;
}

.tw-mx-400px {
  margin-left: 400px;
  margin-right: 400px;
}

.tw-my-464px {
  margin-top: 464px;
  margin-bottom: 464px;
}

.tw-mx-464px {
  margin-left: 464px;
  margin-right: 464px;
}

.tw-my-480px {
  margin-top: 480px;
  margin-bottom: 480px;
}

.tw-mx-480px {
  margin-left: 480px;
  margin-right: 480px;
}

.tw-my-500px {
  margin-top: 500px;
  margin-bottom: 500px;
}

.tw-mx-500px {
  margin-left: 500px;
  margin-right: 500px;
}

.tw-my-576px {
  margin-top: 576px;
  margin-bottom: 576px;
}

.tw-mx-576px {
  margin-left: 576px;
  margin-right: 576px;
}

.tw-my-600px {
  margin-top: 600px;
  margin-bottom: 600px;
}

.tw-mx-600px {
  margin-left: 600px;
  margin-right: 600px;
}

.tw-my-672px {
  margin-top: 672px;
  margin-bottom: 672px;
}

.tw-mx-672px {
  margin-left: 672px;
  margin-right: 672px;
}

.tw--my-1 {
  margin-top: -0.25rem;
  margin-bottom: -0.25rem;
}

.tw--mx-1 {
  margin-left: -0.25rem;
  margin-right: -0.25rem;
}

.tw--my-2 {
  margin-top: -0.5rem;
  margin-bottom: -0.5rem;
}

.tw--mx-2 {
  margin-left: -0.5rem;
  margin-right: -0.5rem;
}

.tw--my-3 {
  margin-top: -0.75rem;
  margin-bottom: -0.75rem;
}

.tw--mx-3 {
  margin-left: -0.75rem;
  margin-right: -0.75rem;
}

.tw--my-4 {
  margin-top: -1rem;
  margin-bottom: -1rem;
}

.tw--mx-4 {
  margin-left: -1rem;
  margin-right: -1rem;
}

.tw--my-5 {
  margin-top: -1.25rem;
  margin-bottom: -1.25rem;
}

.tw--mx-5 {
  margin-left: -1.25rem;
  margin-right: -1.25rem;
}

.tw--my-6 {
  margin-top: -1.5rem;
  margin-bottom: -1.5rem;
}

.tw--mx-6 {
  margin-left: -1.5rem;
  margin-right: -1.5rem;
}

.tw--my-8 {
  margin-top: -2rem;
  margin-bottom: -2rem;
}

.tw--mx-8 {
  margin-left: -2rem;
  margin-right: -2rem;
}

.tw--my-10 {
  margin-top: -2.5rem;
  margin-bottom: -2.5rem;
}

.tw--mx-10 {
  margin-left: -2.5rem;
  margin-right: -2.5rem;
}

.tw--my-12 {
  margin-top: -3rem;
  margin-bottom: -3rem;
}

.tw--mx-12 {
  margin-left: -3rem;
  margin-right: -3rem;
}

.tw--my-16 {
  margin-top: -4rem;
  margin-bottom: -4rem;
}

.tw--mx-16 {
  margin-left: -4rem;
  margin-right: -4rem;
}

.tw--my-20 {
  margin-top: -5rem;
  margin-bottom: -5rem;
}

.tw--mx-20 {
  margin-left: -5rem;
  margin-right: -5rem;
}

.tw--my-24 {
  margin-top: -6rem;
  margin-bottom: -6rem;
}

.tw--mx-24 {
  margin-left: -6rem;
  margin-right: -6rem;
}

.tw--my-32 {
  margin-top: -8rem;
  margin-bottom: -8rem;
}

.tw--mx-32 {
  margin-left: -8rem;
  margin-right: -8rem;
}

.tw--my-40 {
  margin-top: -10rem;
  margin-bottom: -10rem;
}

.tw--mx-40 {
  margin-left: -10rem;
  margin-right: -10rem;
}

.tw--my-48 {
  margin-top: -12rem;
  margin-bottom: -12rem;
}

.tw--mx-48 {
  margin-left: -12rem;
  margin-right: -12rem;
}

.tw--my-56 {
  margin-top: -14rem;
  margin-bottom: -14rem;
}

.tw--mx-56 {
  margin-left: -14rem;
  margin-right: -14rem;
}

.tw--my-64 {
  margin-top: -16rem;
  margin-bottom: -16rem;
}

.tw--mx-64 {
  margin-left: -16rem;
  margin-right: -16rem;
}

.tw--my-px {
  margin-top: -1px;
  margin-bottom: -1px;
}

.tw--mx-px {
  margin-left: -1px;
  margin-right: -1px;
}

.tw--my-270px {
  margin-top: -270px;
  margin-bottom: -270px;
}

.tw--mx-270px {
  margin-left: -270px;
  margin-right: -270px;
}

.tw--my-400px {
  margin-top: -400px;
  margin-bottom: -400px;
}

.tw--mx-400px {
  margin-left: -400px;
  margin-right: -400px;
}

.tw--my-464px {
  margin-top: -464px;
  margin-bottom: -464px;
}

.tw--mx-464px {
  margin-left: -464px;
  margin-right: -464px;
}

.tw--my-480px {
  margin-top: -480px;
  margin-bottom: -480px;
}

.tw--mx-480px {
  margin-left: -480px;
  margin-right: -480px;
}

.tw--my-500px {
  margin-top: -500px;
  margin-bottom: -500px;
}

.tw--mx-500px {
  margin-left: -500px;
  margin-right: -500px;
}

.tw--my-576px {
  margin-top: -576px;
  margin-bottom: -576px;
}

.tw--mx-576px {
  margin-left: -576px;
  margin-right: -576px;
}

.tw--my-600px {
  margin-top: -600px;
  margin-bottom: -600px;
}

.tw--mx-600px {
  margin-left: -600px;
  margin-right: -600px;
}

.tw--my-672px {
  margin-top: -672px;
  margin-bottom: -672px;
}

.tw--mx-672px {
  margin-left: -672px;
  margin-right: -672px;
}

.tw-mt-0 {
  margin-top: 0;
}

.tw-mr-0 {
  margin-right: 0;
}

.tw-mb-0 {
  margin-bottom: 0;
}

.tw-ml-0 {
  margin-left: 0;
}

.tw-mt-1 {
  margin-top: 0.25rem;
}

.tw-mr-1 {
  margin-right: 0.25rem;
}

.tw-mb-1 {
  margin-bottom: 0.25rem;
}

.tw-ml-1 {
  margin-left: 0.25rem;
}

.tw-mt-2 {
  margin-top: 0.5rem;
}

.tw-mr-2 {
  margin-right: 0.5rem;
}

.tw-mb-2 {
  margin-bottom: 0.5rem;
}

.tw-ml-2 {
  margin-left: 0.5rem;
}

.tw-mt-3 {
  margin-top: 0.75rem;
}

.tw-mr-3 {
  margin-right: 0.75rem;
}

.tw-mb-3 {
  margin-bottom: 0.75rem;
}

.tw-ml-3 {
  margin-left: 0.75rem;
}

.tw-mt-4 {
  margin-top: 1rem;
}

.tw-mr-4 {
  margin-right: 1rem;
}

.tw-mb-4 {
  margin-bottom: 1rem;
}

.tw-ml-4 {
  margin-left: 1rem;
}

.tw-mt-5 {
  margin-top: 1.25rem;
}

.tw-mr-5 {
  margin-right: 1.25rem;
}

.tw-mb-5 {
  margin-bottom: 1.25rem;
}

.tw-ml-5 {
  margin-left: 1.25rem;
}

.tw-mt-6 {
  margin-top: 1.5rem;
}

.tw-mr-6 {
  margin-right: 1.5rem;
}

.tw-mb-6 {
  margin-bottom: 1.5rem;
}

.tw-ml-6 {
  margin-left: 1.5rem;
}

.tw-mt-8 {
  margin-top: 2rem;
}

.tw-mr-8 {
  margin-right: 2rem;
}

.tw-mb-8 {
  margin-bottom: 2rem;
}

.tw-ml-8 {
  margin-left: 2rem;
}

.tw-mt-10 {
  margin-top: 2.5rem;
}

.tw-mr-10 {
  margin-right: 2.5rem;
}

.tw-mb-10 {
  margin-bottom: 2.5rem;
}

.tw-ml-10 {
  margin-left: 2.5rem;
}

.tw-mt-12 {
  margin-top: 3rem;
}

.tw-mr-12 {
  margin-right: 3rem;
}

.tw-mb-12 {
  margin-bottom: 3rem;
}

.tw-ml-12 {
  margin-left: 3rem;
}

.tw-mt-16 {
  margin-top: 4rem;
}

.tw-mr-16 {
  margin-right: 4rem;
}

.tw-mb-16 {
  margin-bottom: 4rem;
}

.tw-ml-16 {
  margin-left: 4rem;
}

.tw-mt-20 {
  margin-top: 5rem;
}

.tw-mr-20 {
  margin-right: 5rem;
}

.tw-mb-20 {
  margin-bottom: 5rem;
}

.tw-ml-20 {
  margin-left: 5rem;
}

.tw-mt-24 {
  margin-top: 6rem;
}

.tw-mr-24 {
  margin-right: 6rem;
}

.tw-mb-24 {
  margin-bottom: 6rem;
}

.tw-ml-24 {
  margin-left: 6rem;
}

.tw-mt-32 {
  margin-top: 8rem;
}

.tw-mr-32 {
  margin-right: 8rem;
}

.tw-mb-32 {
  margin-bottom: 8rem;
}

.tw-ml-32 {
  margin-left: 8rem;
}

.tw-mt-40 {
  margin-top: 10rem;
}

.tw-mr-40 {
  margin-right: 10rem;
}

.tw-mb-40 {
  margin-bottom: 10rem;
}

.tw-ml-40 {
  margin-left: 10rem;
}

.tw-mt-48 {
  margin-top: 12rem;
}

.tw-mr-48 {
  margin-right: 12rem;
}

.tw-mb-48 {
  margin-bottom: 12rem;
}

.tw-ml-48 {
  margin-left: 12rem;
}

.tw-mt-56 {
  margin-top: 14rem;
}

.tw-mr-56 {
  margin-right: 14rem;
}

.tw-mb-56 {
  margin-bottom: 14rem;
}

.tw-ml-56 {
  margin-left: 14rem;
}

.tw-mt-64 {
  margin-top: 16rem;
}

.tw-mr-64 {
  margin-right: 16rem;
}

.tw-mb-64 {
  margin-bottom: 16rem;
}

.tw-ml-64 {
  margin-left: 16rem;
}

.tw-mt-auto {
  margin-top: auto;
}

.tw-mr-auto {
  margin-right: auto;
}

.tw-mb-auto {
  margin-bottom: auto;
}

.tw-ml-auto {
  margin-left: auto;
}

.tw-mt-px {
  margin-top: 1px;
}

.tw-mr-px {
  margin-right: 1px;
}

.tw-mb-px {
  margin-bottom: 1px;
}

.tw-ml-px {
  margin-left: 1px;
}

.tw-mt-270px {
  margin-top: 270px;
}

.tw-mr-270px {
  margin-right: 270px;
}

.tw-mb-270px {
  margin-bottom: 270px;
}

.tw-ml-270px {
  margin-left: 270px;
}

.tw-mt-400px {
  margin-top: 400px;
}

.tw-mr-400px {
  margin-right: 400px;
}

.tw-mb-400px {
  margin-bottom: 400px;
}

.tw-ml-400px {
  margin-left: 400px;
}

.tw-mt-464px {
  margin-top: 464px;
}

.tw-mr-464px {
  margin-right: 464px;
}

.tw-mb-464px {
  margin-bottom: 464px;
}

.tw-ml-464px {
  margin-left: 464px;
}

.tw-mt-480px {
  margin-top: 480px;
}

.tw-mr-480px {
  margin-right: 480px;
}

.tw-mb-480px {
  margin-bottom: 480px;
}

.tw-ml-480px {
  margin-left: 480px;
}

.tw-mt-500px {
  margin-top: 500px;
}

.tw-mr-500px {
  margin-right: 500px;
}

.tw-mb-500px {
  margin-bottom: 500px;
}

.tw-ml-500px {
  margin-left: 500px;
}

.tw-mt-576px {
  margin-top: 576px;
}

.tw-mr-576px {
  margin-right: 576px;
}

.tw-mb-576px {
  margin-bottom: 576px;
}

.tw-ml-576px {
  margin-left: 576px;
}

.tw-mt-600px {
  margin-top: 600px;
}

.tw-mr-600px {
  margin-right: 600px;
}

.tw-mb-600px {
  margin-bottom: 600px;
}

.tw-ml-600px {
  margin-left: 600px;
}

.tw-mt-672px {
  margin-top: 672px;
}

.tw-mr-672px {
  margin-right: 672px;
}

.tw-mb-672px {
  margin-bottom: 672px;
}

.tw-ml-672px {
  margin-left: 672px;
}

.tw--mt-1 {
  margin-top: -0.25rem;
}

.tw--mr-1 {
  margin-right: -0.25rem;
}

.tw--mb-1 {
  margin-bottom: -0.25rem;
}

.tw--ml-1 {
  margin-left: -0.25rem;
}

.tw--mt-2 {
  margin-top: -0.5rem;
}

.tw--mr-2 {
  margin-right: -0.5rem;
}

.tw--mb-2 {
  margin-bottom: -0.5rem;
}

.tw--ml-2 {
  margin-left: -0.5rem;
}

.tw--mt-3 {
  margin-top: -0.75rem;
}

.tw--mr-3 {
  margin-right: -0.75rem;
}

.tw--mb-3 {
  margin-bottom: -0.75rem;
}

.tw--ml-3 {
  margin-left: -0.75rem;
}

.tw--mt-4 {
  margin-top: -1rem;
}

.tw--mr-4 {
  margin-right: -1rem;
}

.tw--mb-4 {
  margin-bottom: -1rem;
}

.tw--ml-4 {
  margin-left: -1rem;
}

.tw--mt-5 {
  margin-top: -1.25rem;
}

.tw--mr-5 {
  margin-right: -1.25rem;
}

.tw--mb-5 {
  margin-bottom: -1.25rem;
}

.tw--ml-5 {
  margin-left: -1.25rem;
}

.tw--mt-6 {
  margin-top: -1.5rem;
}

.tw--mr-6 {
  margin-right: -1.5rem;
}

.tw--mb-6 {
  margin-bottom: -1.5rem;
}

.tw--ml-6 {
  margin-left: -1.5rem;
}

.tw--mt-8 {
  margin-top: -2rem;
}

.tw--mr-8 {
  margin-right: -2rem;
}

.tw--mb-8 {
  margin-bottom: -2rem;
}

.tw--ml-8 {
  margin-left: -2rem;
}

.tw--mt-10 {
  margin-top: -2.5rem;
}

.tw--mr-10 {
  margin-right: -2.5rem;
}

.tw--mb-10 {
  margin-bottom: -2.5rem;
}

.tw--ml-10 {
  margin-left: -2.5rem;
}

.tw--mt-12 {
  margin-top: -3rem;
}

.tw--mr-12 {
  margin-right: -3rem;
}

.tw--mb-12 {
  margin-bottom: -3rem;
}

.tw--ml-12 {
  margin-left: -3rem;
}

.tw--mt-16 {
  margin-top: -4rem;
}

.tw--mr-16 {
  margin-right: -4rem;
}

.tw--mb-16 {
  margin-bottom: -4rem;
}

.tw--ml-16 {
  margin-left: -4rem;
}

.tw--mt-20 {
  margin-top: -5rem;
}

.tw--mr-20 {
  margin-right: -5rem;
}

.tw--mb-20 {
  margin-bottom: -5rem;
}

.tw--ml-20 {
  margin-left: -5rem;
}

.tw--mt-24 {
  margin-top: -6rem;
}

.tw--mr-24 {
  margin-right: -6rem;
}

.tw--mb-24 {
  margin-bottom: -6rem;
}

.tw--ml-24 {
  margin-left: -6rem;
}

.tw--mt-32 {
  margin-top: -8rem;
}

.tw--mr-32 {
  margin-right: -8rem;
}

.tw--mb-32 {
  margin-bottom: -8rem;
}

.tw--ml-32 {
  margin-left: -8rem;
}

.tw--mt-40 {
  margin-top: -10rem;
}

.tw--mr-40 {
  margin-right: -10rem;
}

.tw--mb-40 {
  margin-bottom: -10rem;
}

.tw--ml-40 {
  margin-left: -10rem;
}

.tw--mt-48 {
  margin-top: -12rem;
}

.tw--mr-48 {
  margin-right: -12rem;
}

.tw--mb-48 {
  margin-bottom: -12rem;
}

.tw--ml-48 {
  margin-left: -12rem;
}

.tw--mt-56 {
  margin-top: -14rem;
}

.tw--mr-56 {
  margin-right: -14rem;
}

.tw--mb-56 {
  margin-bottom: -14rem;
}

.tw--ml-56 {
  margin-left: -14rem;
}

.tw--mt-64 {
  margin-top: -16rem;
}

.tw--mr-64 {
  margin-right: -16rem;
}

.tw--mb-64 {
  margin-bottom: -16rem;
}

.tw--ml-64 {
  margin-left: -16rem;
}

.tw--mt-px {
  margin-top: -1px;
}

.tw--mr-px {
  margin-right: -1px;
}

.tw--mb-px {
  margin-bottom: -1px;
}

.tw--ml-px {
  margin-left: -1px;
}

.tw--mt-270px {
  margin-top: -270px;
}

.tw--mr-270px {
  margin-right: -270px;
}

.tw--mb-270px {
  margin-bottom: -270px;
}

.tw--ml-270px {
  margin-left: -270px;
}

.tw--mt-400px {
  margin-top: -400px;
}

.tw--mr-400px {
  margin-right: -400px;
}

.tw--mb-400px {
  margin-bottom: -400px;
}

.tw--ml-400px {
  margin-left: -400px;
}

.tw--mt-464px {
  margin-top: -464px;
}

.tw--mr-464px {
  margin-right: -464px;
}

.tw--mb-464px {
  margin-bottom: -464px;
}

.tw--ml-464px {
  margin-left: -464px;
}

.tw--mt-480px {
  margin-top: -480px;
}

.tw--mr-480px {
  margin-right: -480px;
}

.tw--mb-480px {
  margin-bottom: -480px;
}

.tw--ml-480px {
  margin-left: -480px;
}

.tw--mt-500px {
  margin-top: -500px;
}

.tw--mr-500px {
  margin-right: -500px;
}

.tw--mb-500px {
  margin-bottom: -500px;
}

.tw--ml-500px {
  margin-left: -500px;
}

.tw--mt-576px {
  margin-top: -576px;
}

.tw--mr-576px {
  margin-right: -576px;
}

.tw--mb-576px {
  margin-bottom: -576px;
}

.tw--ml-576px {
  margin-left: -576px;
}

.tw--mt-600px {
  margin-top: -600px;
}

.tw--mr-600px {
  margin-right: -600px;
}

.tw--mb-600px {
  margin-bottom: -600px;
}

.tw--ml-600px {
  margin-left: -600px;
}

.tw--mt-672px {
  margin-top: -672px;
}

.tw--mr-672px {
  margin-right: -672px;
}

.tw--mb-672px {
  margin-bottom: -672px;
}

.tw--ml-672px {
  margin-left: -672px;
}

.tw-max-h-full {
  max-height: 100%;
}

.tw-max-h-screen {
  max-height: 100vh;
}

.tw-max-w-none {
  max-width: none;
}

.tw-max-w-xs {
  max-width: 20rem;
}

.tw-max-w-sm {
  max-width: 24rem;
}

.tw-max-w-md {
  max-width: 28rem;
}

.tw-max-w-lg {
  max-width: 32rem;
}

.tw-max-w-xl {
  max-width: 36rem;
}

.tw-max-w-2xl {
  max-width: 42rem;
}

.tw-max-w-3xl {
  max-width: 48rem;
}

.tw-max-w-4xl {
  max-width: 56rem;
}

.tw-max-w-5xl {
  max-width: 64rem;
}

.tw-max-w-6xl {
  max-width: 72rem;
}

.tw-max-w-full {
  max-width: 100%;
}

.tw-max-w-screen-sm {
  max-width: 640px;
}

.tw-max-w-screen-md {
  max-width: 768px;
}

.tw-max-w-screen-lg {
  max-width: 1024px;
}

.tw-max-w-screen-xl {
  max-width: 1280px;
}

.tw-min-h-0 {
  min-height: 0;
}

.tw-min-h-full {
  min-height: 100%;
}

.tw-min-h-screen {
  min-height: 100vh;
}

.tw-min-h-576px {
  min-height: 576px;
}

.tw-min-h-600px {
  min-height: 600px;
}

.tw-min-h-672px {
  min-height: 672px;
}

.tw-min-w-0 {
  min-width: 0;
}

.tw-min-w-full {
  min-width: 100%;
}

.tw-min-w-360px {
  min-width: 360px;
}

.tw-min-w-540px {
  min-width: 540px;
}

.tw-object-contain {
  -o-object-fit: contain;
     object-fit: contain;
}

.tw-object-cover {
  -o-object-fit: cover;
     object-fit: cover;
}

.tw-object-fill {
  -o-object-fit: fill;
     object-fit: fill;
}

.tw-object-none {
  -o-object-fit: none;
     object-fit: none;
}

.tw-object-scale-down {
  -o-object-fit: scale-down;
     object-fit: scale-down;
}

.tw-object-bottom {
  -o-object-position: bottom;
     object-position: bottom;
}

.tw-object-center {
  -o-object-position: center;
     object-position: center;
}

.tw-object-left {
  -o-object-position: left;
     object-position: left;
}

.tw-object-left-bottom {
  -o-object-position: left bottom;
     object-position: left bottom;
}

.tw-object-left-top {
  -o-object-position: left top;
     object-position: left top;
}

.tw-object-right {
  -o-object-position: right;
     object-position: right;
}

.tw-object-right-bottom {
  -o-object-position: right bottom;
     object-position: right bottom;
}

.tw-object-right-top {
  -o-object-position: right top;
     object-position: right top;
}

.tw-object-top {
  -o-object-position: top;
     object-position: top;
}

.tw-opacity-0 {
  opacity: 0;
}

.tw-opacity-25 {
  opacity: 0.25;
}

.tw-opacity-50 {
  opacity: 0.5;
}

.tw-opacity-75 {
  opacity: 0.75;
}

.tw-opacity-100 {
  opacity: 1;
}

.hover\:tw-opacity-0:hover {
  opacity: 0;
}

.hover\:tw-opacity-25:hover {
  opacity: 0.25;
}

.hover\:tw-opacity-50:hover {
  opacity: 0.5;
}

.hover\:tw-opacity-75:hover {
  opacity: 0.75;
}

.hover\:tw-opacity-100:hover {
  opacity: 1;
}

.focus\:tw-opacity-0:focus {
  opacity: 0;
}

.focus\:tw-opacity-25:focus {
  opacity: 0.25;
}

.focus\:tw-opacity-50:focus {
  opacity: 0.5;
}

.focus\:tw-opacity-75:focus {
  opacity: 0.75;
}

.focus\:tw-opacity-100:focus {
  opacity: 1;
}

.tw-outline-none {
  outline: 2px solid transparent;
  outline-offset: 2px;
}

.tw-outline-white {
  outline: 2px dotted white;
  outline-offset: 2px;
}

.tw-outline-black {
  outline: 2px dotted black;
  outline-offset: 2px;
}

.focus\:tw-outline-none:focus {
  outline: 2px solid transparent;
  outline-offset: 2px;
}

.focus\:tw-outline-white:focus {
  outline: 2px dotted white;
  outline-offset: 2px;
}

.focus\:tw-outline-black:focus {
  outline: 2px dotted black;
  outline-offset: 2px;
}

.tw-overflow-auto {
  overflow: auto;
}

.tw-overflow-hidden {
  overflow: hidden;
}

.tw-overflow-visible {
  overflow: visible;
}

.tw-overflow-scroll {
  overflow: scroll;
}

.tw-overflow-x-auto {
  overflow-x: auto;
}

.tw-overflow-y-auto {
  overflow-y: auto;
}

.tw-overflow-x-hidden {
  overflow-x: hidden;
}

.tw-overflow-y-hidden {
  overflow-y: hidden;
}

.tw-overflow-x-visible {
  overflow-x: visible;
}

.tw-overflow-y-visible {
  overflow-y: visible;
}

.tw-overflow-x-scroll {
  overflow-x: scroll;
}

.tw-overflow-y-scroll {
  overflow-y: scroll;
}

.tw-scrolling-touch {
  -webkit-overflow-scrolling: touch;
}

.tw-scrolling-auto {
  -webkit-overflow-scrolling: auto;
}

.tw-overscroll-auto {
  overscroll-behavior: auto;
}

.tw-overscroll-contain {
  overscroll-behavior: contain;
}

.tw-overscroll-none {
  overscroll-behavior: none;
}

.tw-overscroll-y-auto {
  overscroll-behavior-y: auto;
}

.tw-overscroll-y-contain {
  overscroll-behavior-y: contain;
}

.tw-overscroll-y-none {
  overscroll-behavior-y: none;
}

.tw-overscroll-x-auto {
  overscroll-behavior-x: auto;
}

.tw-overscroll-x-contain {
  overscroll-behavior-x: contain;
}

.tw-overscroll-x-none {
  overscroll-behavior-x: none;
}

.tw-p-0 {
  padding: 0;
}

.tw-p-1 {
  padding: 0.25rem;
}

.tw-p-2 {
  padding: 0.5rem;
}

.tw-p-3 {
  padding: 0.75rem;
}

.tw-p-4 {
  padding: 1rem;
}

.tw-p-5 {
  padding: 1.25rem;
}

.tw-p-6 {
  padding: 1.5rem;
}

.tw-p-8 {
  padding: 2rem;
}

.tw-p-10 {
  padding: 2.5rem;
}

.tw-p-12 {
  padding: 3rem;
}

.tw-p-16 {
  padding: 4rem;
}

.tw-p-20 {
  padding: 5rem;
}

.tw-p-24 {
  padding: 6rem;
}

.tw-p-32 {
  padding: 8rem;
}

.tw-p-40 {
  padding: 10rem;
}

.tw-p-48 {
  padding: 12rem;
}

.tw-p-56 {
  padding: 14rem;
}

.tw-p-64 {
  padding: 16rem;
}

.tw-p-px {
  padding: 1px;
}

.tw-p-270px {
  padding: 270px;
}

.tw-p-400px {
  padding: 400px;
}

.tw-p-464px {
  padding: 464px;
}

.tw-p-480px {
  padding: 480px;
}

.tw-p-500px {
  padding: 500px;
}

.tw-p-576px {
  padding: 576px;
}

.tw-p-600px {
  padding: 600px;
}

.tw-p-672px {
  padding: 672px;
}

.tw-py-0 {
  padding-top: 0;
  padding-bottom: 0;
}

.tw-px-0 {
  padding-left: 0;
  padding-right: 0;
}

.tw-py-1 {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
}

.tw-px-1 {
  padding-left: 0.25rem;
  padding-right: 0.25rem;
}

.tw-py-2 {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.tw-px-2 {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}

.tw-py-3 {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
}

.tw-px-3 {
  padding-left: 0.75rem;
  padding-right: 0.75rem;
}

.tw-py-4 {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.tw-px-4 {
  padding-left: 1rem;
  padding-right: 1rem;
}

.tw-py-5 {
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
}

.tw-px-5 {
  padding-left: 1.25rem;
  padding-right: 1.25rem;
}

.tw-py-6 {
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
}

.tw-px-6 {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}

.tw-py-8 {
  padding-top: 2rem;
  padding-bottom: 2rem;
}

.tw-px-8 {
  padding-left: 2rem;
  padding-right: 2rem;
}

.tw-py-10 {
  padding-top: 2.5rem;
  padding-bottom: 2.5rem;
}

.tw-px-10 {
  padding-left: 2.5rem;
  padding-right: 2.5rem;
}

.tw-py-12 {
  padding-top: 3rem;
  padding-bottom: 3rem;
}

.tw-px-12 {
  padding-left: 3rem;
  padding-right: 3rem;
}

.tw-py-16 {
  padding-top: 4rem;
  padding-bottom: 4rem;
}

.tw-px-16 {
  padding-left: 4rem;
  padding-right: 4rem;
}

.tw-py-20 {
  padding-top: 5rem;
  padding-bottom: 5rem;
}

.tw-px-20 {
  padding-left: 5rem;
  padding-right: 5rem;
}

.tw-py-24 {
  padding-top: 6rem;
  padding-bottom: 6rem;
}

.tw-px-24 {
  padding-left: 6rem;
  padding-right: 6rem;
}

.tw-py-32 {
  padding-top: 8rem;
  padding-bottom: 8rem;
}

.tw-px-32 {
  padding-left: 8rem;
  padding-right: 8rem;
}

.tw-py-40 {
  padding-top: 10rem;
  padding-bottom: 10rem;
}

.tw-px-40 {
  padding-left: 10rem;
  padding-right: 10rem;
}

.tw-py-48 {
  padding-top: 12rem;
  padding-bottom: 12rem;
}

.tw-px-48 {
  padding-left: 12rem;
  padding-right: 12rem;
}

.tw-py-56 {
  padding-top: 14rem;
  padding-bottom: 14rem;
}

.tw-px-56 {
  padding-left: 14rem;
  padding-right: 14rem;
}

.tw-py-64 {
  padding-top: 16rem;
  padding-bottom: 16rem;
}

.tw-px-64 {
  padding-left: 16rem;
  padding-right: 16rem;
}

.tw-py-px {
  padding-top: 1px;
  padding-bottom: 1px;
}

.tw-px-px {
  padding-left: 1px;
  padding-right: 1px;
}

.tw-py-270px {
  padding-top: 270px;
  padding-bottom: 270px;
}

.tw-px-270px {
  padding-left: 270px;
  padding-right: 270px;
}

.tw-py-400px {
  padding-top: 400px;
  padding-bottom: 400px;
}

.tw-px-400px {
  padding-left: 400px;
  padding-right: 400px;
}

.tw-py-464px {
  padding-top: 464px;
  padding-bottom: 464px;
}

.tw-px-464px {
  padding-left: 464px;
  padding-right: 464px;
}

.tw-py-480px {
  padding-top: 480px;
  padding-bottom: 480px;
}

.tw-px-480px {
  padding-left: 480px;
  padding-right: 480px;
}

.tw-py-500px {
  padding-top: 500px;
  padding-bottom: 500px;
}

.tw-px-500px {
  padding-left: 500px;
  padding-right: 500px;
}

.tw-py-576px {
  padding-top: 576px;
  padding-bottom: 576px;
}

.tw-px-576px {
  padding-left: 576px;
  padding-right: 576px;
}

.tw-py-600px {
  padding-top: 600px;
  padding-bottom: 600px;
}

.tw-px-600px {
  padding-left: 600px;
  padding-right: 600px;
}

.tw-py-672px {
  padding-top: 672px;
  padding-bottom: 672px;
}

.tw-px-672px {
  padding-left: 672px;
  padding-right: 672px;
}

.tw-pt-0 {
  padding-top: 0;
}

.tw-pr-0 {
  padding-right: 0;
}

.tw-pb-0 {
  padding-bottom: 0;
}

.tw-pl-0 {
  padding-left: 0;
}

.tw-pt-1 {
  padding-top: 0.25rem;
}

.tw-pr-1 {
  padding-right: 0.25rem;
}

.tw-pb-1 {
  padding-bottom: 0.25rem;
}

.tw-pl-1 {
  padding-left: 0.25rem;
}

.tw-pt-2 {
  padding-top: 0.5rem;
}

.tw-pr-2 {
  padding-right: 0.5rem;
}

.tw-pb-2 {
  padding-bottom: 0.5rem;
}

.tw-pl-2 {
  padding-left: 0.5rem;
}

.tw-pt-3 {
  padding-top: 0.75rem;
}

.tw-pr-3 {
  padding-right: 0.75rem;
}

.tw-pb-3 {
  padding-bottom: 0.75rem;
}

.tw-pl-3 {
  padding-left: 0.75rem;
}

.tw-pt-4 {
  padding-top: 1rem;
}

.tw-pr-4 {
  padding-right: 1rem;
}

.tw-pb-4 {
  padding-bottom: 1rem;
}

.tw-pl-4 {
  padding-left: 1rem;
}

.tw-pt-5 {
  padding-top: 1.25rem;
}

.tw-pr-5 {
  padding-right: 1.25rem;
}

.tw-pb-5 {
  padding-bottom: 1.25rem;
}

.tw-pl-5 {
  padding-left: 1.25rem;
}

.tw-pt-6 {
  padding-top: 1.5rem;
}

.tw-pr-6 {
  padding-right: 1.5rem;
}

.tw-pb-6 {
  padding-bottom: 1.5rem;
}

.tw-pl-6 {
  padding-left: 1.5rem;
}

.tw-pt-8 {
  padding-top: 2rem;
}

.tw-pr-8 {
  padding-right: 2rem;
}

.tw-pb-8 {
  padding-bottom: 2rem;
}

.tw-pl-8 {
  padding-left: 2rem;
}

.tw-pt-10 {
  padding-top: 2.5rem;
}

.tw-pr-10 {
  padding-right: 2.5rem;
}

.tw-pb-10 {
  padding-bottom: 2.5rem;
}

.tw-pl-10 {
  padding-left: 2.5rem;
}

.tw-pt-12 {
  padding-top: 3rem;
}

.tw-pr-12 {
  padding-right: 3rem;
}

.tw-pb-12 {
  padding-bottom: 3rem;
}

.tw-pl-12 {
  padding-left: 3rem;
}

.tw-pt-16 {
  padding-top: 4rem;
}

.tw-pr-16 {
  padding-right: 4rem;
}

.tw-pb-16 {
  padding-bottom: 4rem;
}

.tw-pl-16 {
  padding-left: 4rem;
}

.tw-pt-20 {
  padding-top: 5rem;
}

.tw-pr-20 {
  padding-right: 5rem;
}

.tw-pb-20 {
  padding-bottom: 5rem;
}

.tw-pl-20 {
  padding-left: 5rem;
}

.tw-pt-24 {
  padding-top: 6rem;
}

.tw-pr-24 {
  padding-right: 6rem;
}

.tw-pb-24 {
  padding-bottom: 6rem;
}

.tw-pl-24 {
  padding-left: 6rem;
}

.tw-pt-32 {
  padding-top: 8rem;
}

.tw-pr-32 {
  padding-right: 8rem;
}

.tw-pb-32 {
  padding-bottom: 8rem;
}

.tw-pl-32 {
  padding-left: 8rem;
}

.tw-pt-40 {
  padding-top: 10rem;
}

.tw-pr-40 {
  padding-right: 10rem;
}

.tw-pb-40 {
  padding-bottom: 10rem;
}

.tw-pl-40 {
  padding-left: 10rem;
}

.tw-pt-48 {
  padding-top: 12rem;
}

.tw-pr-48 {
  padding-right: 12rem;
}

.tw-pb-48 {
  padding-bottom: 12rem;
}

.tw-pl-48 {
  padding-left: 12rem;
}

.tw-pt-56 {
  padding-top: 14rem;
}

.tw-pr-56 {
  padding-right: 14rem;
}

.tw-pb-56 {
  padding-bottom: 14rem;
}

.tw-pl-56 {
  padding-left: 14rem;
}

.tw-pt-64 {
  padding-top: 16rem;
}

.tw-pr-64 {
  padding-right: 16rem;
}

.tw-pb-64 {
  padding-bottom: 16rem;
}

.tw-pl-64 {
  padding-left: 16rem;
}

.tw-pt-px {
  padding-top: 1px;
}

.tw-pr-px {
  padding-right: 1px;
}

.tw-pb-px {
  padding-bottom: 1px;
}

.tw-pl-px {
  padding-left: 1px;
}

.tw-pt-270px {
  padding-top: 270px;
}

.tw-pr-270px {
  padding-right: 270px;
}

.tw-pb-270px {
  padding-bottom: 270px;
}

.tw-pl-270px {
  padding-left: 270px;
}

.tw-pt-400px {
  padding-top: 400px;
}

.tw-pr-400px {
  padding-right: 400px;
}

.tw-pb-400px {
  padding-bottom: 400px;
}

.tw-pl-400px {
  padding-left: 400px;
}

.tw-pt-464px {
  padding-top: 464px;
}

.tw-pr-464px {
  padding-right: 464px;
}

.tw-pb-464px {
  padding-bottom: 464px;
}

.tw-pl-464px {
  padding-left: 464px;
}

.tw-pt-480px {
  padding-top: 480px;
}

.tw-pr-480px {
  padding-right: 480px;
}

.tw-pb-480px {
  padding-bottom: 480px;
}

.tw-pl-480px {
  padding-left: 480px;
}

.tw-pt-500px {
  padding-top: 500px;
}

.tw-pr-500px {
  padding-right: 500px;
}

.tw-pb-500px {
  padding-bottom: 500px;
}

.tw-pl-500px {
  padding-left: 500px;
}

.tw-pt-576px {
  padding-top: 576px;
}

.tw-pr-576px {
  padding-right: 576px;
}

.tw-pb-576px {
  padding-bottom: 576px;
}

.tw-pl-576px {
  padding-left: 576px;
}

.tw-pt-600px {
  padding-top: 600px;
}

.tw-pr-600px {
  padding-right: 600px;
}

.tw-pb-600px {
  padding-bottom: 600px;
}

.tw-pl-600px {
  padding-left: 600px;
}

.tw-pt-672px {
  padding-top: 672px;
}

.tw-pr-672px {
  padding-right: 672px;
}

.tw-pb-672px {
  padding-bottom: 672px;
}

.tw-pl-672px {
  padding-left: 672px;
}

.tw-placeholder-transparent::-moz-placeholder {
  color: transparent;
}

.tw-placeholder-transparent::placeholder {
  color: transparent;
}

.tw-placeholder-current::-moz-placeholder {
  color: currentColor;
}

.tw-placeholder-current::placeholder {
  color: currentColor;
}

.tw-placeholder-black::-moz-placeholder {
  --placeholder-opacity: 1;
  color: #000;
  color: rgba(0, 0, 0, var(--placeholder-opacity));
}

.tw-placeholder-black::placeholder {
  --placeholder-opacity: 1;
  color: #000;
  color: rgba(0, 0, 0, var(--placeholder-opacity));
}

.tw-placeholder-white::-moz-placeholder {
  --placeholder-opacity: 1;
  color: #fff;
  color: rgba(255, 255, 255, var(--placeholder-opacity));
}

.tw-placeholder-white::placeholder {
  --placeholder-opacity: 1;
  color: #fff;
  color: rgba(255, 255, 255, var(--placeholder-opacity));
}

.tw-placeholder-gray-100::-moz-placeholder {
  --placeholder-opacity: 1;
  color: #f7fafc;
  color: rgba(247, 250, 252, var(--placeholder-opacity));
}

.tw-placeholder-gray-100::placeholder {
  --placeholder-opacity: 1;
  color: #f7fafc;
  color: rgba(247, 250, 252, var(--placeholder-opacity));
}

.tw-placeholder-gray-200::-moz-placeholder {
  --placeholder-opacity: 1;
  color: #edf2f7;
  color: rgba(237, 242, 247, var(--placeholder-opacity));
}

.tw-placeholder-gray-200::placeholder {
  --placeholder-opacity: 1;
  color: #edf2f7;
  color: rgba(237, 242, 247, var(--placeholder-opacity));
}

.tw-placeholder-gray-300::-moz-placeholder {
  --placeholder-opacity: 1;
  color: #e2e8f0;
  color: rgba(226, 232, 240, var(--placeholder-opacity));
}

.tw-placeholder-gray-300::placeholder {
  --placeholder-opacity: 1;
  color: #e2e8f0;
  color: rgba(226, 232, 240, var(--placeholder-opacity));
}

.tw-placeholder-gray-400::-moz-placeholder {
  --placeholder-opacity: 1;
  color: #cbd5e0;
  color: rgba(203, 213, 224, var(--placeholder-opacity));
}

.tw-placeholder-gray-400::placeholder {
  --placeholder-opacity: 1;
  color: #cbd5e0;
  color: rgba(203, 213, 224, var(--placeholder-opacity));
}

.tw-placeholder-gray-500::-moz-placeholder {
  --placeholder-opacity: 1;
  color: #a0aec0;
  color: rgba(160, 174, 192, var(--placeholder-opacity));
}

.tw-placeholder-gray-500::placeholder {
  --placeholder-opacity: 1;
  color: #a0aec0;
  color: rgba(160, 174, 192, var(--placeholder-opacity));
}

.tw-placeholder-gray-600::-moz-placeholder {
  --placeholder-opacity: 1;
  color: #718096;
  color: rgba(113, 128, 150, var(--placeholder-opacity));
}

.tw-placeholder-gray-600::placeholder {
  --placeholder-opacity: 1;
  color: #718096;
  color: rgba(113, 128, 150, var(--placeholder-opacity));
}

.tw-placeholder-gray-700::-moz-placeholder {
  --placeholder-opacity: 1;
  color: #4a5568;
  color: rgba(74, 85, 104, var(--placeholder-opacity));
}

.tw-placeholder-gray-700::placeholder {
  --placeholder-opacity: 1;
  color: #4a5568;
  color: rgba(74, 85, 104, var(--placeholder-opacity));
}

.tw-placeholder-gray-800::-moz-placeholder {
  --placeholder-opacity: 1;
  color: #2d3748;
  color: rgba(45, 55, 72, var(--placeholder-opacity));
}

.tw-placeholder-gray-800::placeholder {
  --placeholder-opacity: 1;
  color: #2d3748;
  color: rgba(45, 55, 72, var(--placeholder-opacity));
}

.tw-placeholder-gray-900::-moz-placeholder {
  --placeholder-opacity: 1;
  color: #1a202c;
  color: rgba(26, 32, 44, var(--placeholder-opacity));
}

.tw-placeholder-gray-900::placeholder {
  --placeholder-opacity: 1;
  color: #1a202c;
  color: rgba(26, 32, 44, var(--placeholder-opacity));
}

.tw-placeholder-red-100::-moz-placeholder {
  --placeholder-opacity: 1;
  color: #fff5f5;
  color: rgba(255, 245, 245, var(--placeholder-opacity));
}

.tw-placeholder-red-100::placeholder {
  --placeholder-opacity: 1;
  color: #fff5f5;
  color: rgba(255, 245, 245, var(--placeholder-opacity));
}

.tw-placeholder-red-200::-moz-placeholder {
  --placeholder-opacity: 1;
  color: #fed7d7;
  color: rgba(254, 215, 215, var(--placeholder-opacity));
}

.tw-placeholder-red-200::placeholder {
  --placeholder-opacity: 1;
  color: #fed7d7;
  color: rgba(254, 215, 215, var(--placeholder-opacity));
}

.tw-placeholder-red-300::-moz-placeholder {
  --placeholder-opacity: 1;
  color: #feb2b2;
  color: rgba(254, 178, 178, var(--placeholder-opacity));
}

.tw-placeholder-red-300::placeholder {
  --placeholder-opacity: 1;
  color: #feb2b2;
  color: rgba(254, 178, 178, var(--placeholder-opacity));
}

.tw-placeholder-red-400::-moz-placeholder {
  --placeholder-opacity: 1;
  color: #fc8181;
  color: rgba(252, 129, 129, var(--placeholder-opacity));
}

.tw-placeholder-red-400::placeholder {
  --placeholder-opacity: 1;
  color: #fc8181;
  color: rgba(252, 129, 129, var(--placeholder-opacity));
}

.tw-placeholder-red-500::-moz-placeholder {
  --placeholder-opacity: 1;
  color: #f56565;
  color: rgba(245, 101, 101, var(--placeholder-opacity));
}

.tw-placeholder-red-500::placeholder {
  --placeholder-opacity: 1;
  color: #f56565;
  color: rgba(245, 101, 101, var(--placeholder-opacity));
}

.tw-placeholder-red-600::-moz-placeholder {
  --placeholder-opacity: 1;
  color: #e53e3e;
  color: rgba(229, 62, 62, var(--placeholder-opacity));
}

.tw-placeholder-red-600::placeholder {
  --placeholder-opacity: 1;
  color: #e53e3e;
  color: rgba(229, 62, 62, var(--placeholder-opacity));
}

.tw-placeholder-red-700::-moz-placeholder {
  --placeholder-opacity: 1;
  color: #c53030;
  color: rgba(197, 48, 48, var(--placeholder-opacity));
}

.tw-placeholder-red-700::placeholder {
  --placeholder-opacity: 1;
  color: #c53030;
  color: rgba(197, 48, 48, var(--placeholder-opacity));
}

.tw-placeholder-red-800::-moz-placeholder {
  --placeholder-opacity: 1;
  color: #9b2c2c;
  color: rgba(155, 44, 44, var(--placeholder-opacity));
}

.tw-placeholder-red-800::placeholder {
  --placeholder-opacity: 1;
  color: #9b2c2c;
  color: rgba(155, 44, 44, var(--placeholder-opacity));
}

.tw-placeholder-red-900::-moz-placeholder {
  --placeholder-opacity: 1;
  color: #742a2a;
  color: rgba(116, 42, 42, var(--placeholder-opacity));
}

.tw-placeholder-red-900::placeholder {
  --placeholder-opacity: 1;
  color: #742a2a;
  color: rgba(116, 42, 42, var(--placeholder-opacity));
}

.tw-placeholder-orange-100::-moz-placeholder {
  --placeholder-opacity: 1;
  color: #fffaf0;
  color: rgba(255, 250, 240, var(--placeholder-opacity));
}

.tw-placeholder-orange-100::placeholder {
  --placeholder-opacity: 1;
  color: #fffaf0;
  color: rgba(255, 250, 240, var(--placeholder-opacity));
}

.tw-placeholder-orange-200::-moz-placeholder {
  --placeholder-opacity: 1;
  color: #feebc8;
  color: rgba(254, 235, 200, var(--placeholder-opacity));
}

.tw-placeholder-orange-200::placeholder {
  --placeholder-opacity: 1;
  color: #feebc8;
  color: rgba(254, 235, 200, var(--placeholder-opacity));
}

.tw-placeholder-orange-300::-moz-placeholder {
  --placeholder-opacity: 1;
  color: #fbd38d;
  color: rgba(251, 211, 141, var(--placeholder-opacity));
}

.tw-placeholder-orange-300::placeholder {
  --placeholder-opacity: 1;
  color: #fbd38d;
  color: rgba(251, 211, 141, var(--placeholder-opacity));
}

.tw-placeholder-orange-400::-moz-placeholder {
  --placeholder-opacity: 1;
  color: #f6ad55;
  color: rgba(246, 173, 85, var(--placeholder-opacity));
}

.tw-placeholder-orange-400::placeholder {
  --placeholder-opacity: 1;
  color: #f6ad55;
  color: rgba(246, 173, 85, var(--placeholder-opacity));
}

.tw-placeholder-orange-500::-moz-placeholder {
  --placeholder-opacity: 1;
  color: #ed8936;
  color: rgba(237, 137, 54, var(--placeholder-opacity));
}

.tw-placeholder-orange-500::placeholder {
  --placeholder-opacity: 1;
  color: #ed8936;
  color: rgba(237, 137, 54, var(--placeholder-opacity));
}

.tw-placeholder-orange-600::-moz-placeholder {
  --placeholder-opacity: 1;
  color: #dd6b20;
  color: rgba(221, 107, 32, var(--placeholder-opacity));
}

.tw-placeholder-orange-600::placeholder {
  --placeholder-opacity: 1;
  color: #dd6b20;
  color: rgba(221, 107, 32, var(--placeholder-opacity));
}

.tw-placeholder-orange-700::-moz-placeholder {
  --placeholder-opacity: 1;
  color: #c05621;
  color: rgba(192, 86, 33, var(--placeholder-opacity));
}

.tw-placeholder-orange-700::placeholder {
  --placeholder-opacity: 1;
  color: #c05621;
  color: rgba(192, 86, 33, var(--placeholder-opacity));
}

.tw-placeholder-orange-800::-moz-placeholder {
  --placeholder-opacity: 1;
  color: #9c4221;
  color: rgba(156, 66, 33, var(--placeholder-opacity));
}

.tw-placeholder-orange-800::placeholder {
  --placeholder-opacity: 1;
  color: #9c4221;
  color: rgba(156, 66, 33, var(--placeholder-opacity));
}

.tw-placeholder-orange-900::-moz-placeholder {
  --placeholder-opacity: 1;
  color: #7b341e;
  color: rgba(123, 52, 30, var(--placeholder-opacity));
}

.tw-placeholder-orange-900::placeholder {
  --placeholder-opacity: 1;
  color: #7b341e;
  color: rgba(123, 52, 30, var(--placeholder-opacity));
}

.tw-placeholder-yellow-100::-moz-placeholder {
  --placeholder-opacity: 1;
  color: #fffff0;
  color: rgba(255, 255, 240, var(--placeholder-opacity));
}

.tw-placeholder-yellow-100::placeholder {
  --placeholder-opacity: 1;
  color: #fffff0;
  color: rgba(255, 255, 240, var(--placeholder-opacity));
}

.tw-placeholder-yellow-200::-moz-placeholder {
  --placeholder-opacity: 1;
  color: #fefcbf;
  color: rgba(254, 252, 191, var(--placeholder-opacity));
}

.tw-placeholder-yellow-200::placeholder {
  --placeholder-opacity: 1;
  color: #fefcbf;
  color: rgba(254, 252, 191, var(--placeholder-opacity));
}

.tw-placeholder-yellow-300::-moz-placeholder {
  --placeholder-opacity: 1;
  color: #faf089;
  color: rgba(250, 240, 137, var(--placeholder-opacity));
}

.tw-placeholder-yellow-300::placeholder {
  --placeholder-opacity: 1;
  color: #faf089;
  color: rgba(250, 240, 137, var(--placeholder-opacity));
}

.tw-placeholder-yellow-400::-moz-placeholder {
  --placeholder-opacity: 1;
  color: #f6e05e;
  color: rgba(246, 224, 94, var(--placeholder-opacity));
}

.tw-placeholder-yellow-400::placeholder {
  --placeholder-opacity: 1;
  color: #f6e05e;
  color: rgba(246, 224, 94, var(--placeholder-opacity));
}

.tw-placeholder-yellow-500::-moz-placeholder {
  --placeholder-opacity: 1;
  color: #ecc94b;
  color: rgba(236, 201, 75, var(--placeholder-opacity));
}

.tw-placeholder-yellow-500::placeholder {
  --placeholder-opacity: 1;
  color: #ecc94b;
  color: rgba(236, 201, 75, var(--placeholder-opacity));
}

.tw-placeholder-yellow-600::-moz-placeholder {
  --placeholder-opacity: 1;
  color: #d69e2e;
  color: rgba(214, 158, 46, var(--placeholder-opacity));
}

.tw-placeholder-yellow-600::placeholder {
  --placeholder-opacity: 1;
  color: #d69e2e;
  color: rgba(214, 158, 46, var(--placeholder-opacity));
}

.tw-placeholder-yellow-700::-moz-placeholder {
  --placeholder-opacity: 1;
  color: #b7791f;
  color: rgba(183, 121, 31, var(--placeholder-opacity));
}

.tw-placeholder-yellow-700::placeholder {
  --placeholder-opacity: 1;
  color: #b7791f;
  color: rgba(183, 121, 31, var(--placeholder-opacity));
}

.tw-placeholder-yellow-800::-moz-placeholder {
  --placeholder-opacity: 1;
  color: #975a16;
  color: rgba(151, 90, 22, var(--placeholder-opacity));
}

.tw-placeholder-yellow-800::placeholder {
  --placeholder-opacity: 1;
  color: #975a16;
  color: rgba(151, 90, 22, var(--placeholder-opacity));
}

.tw-placeholder-yellow-900::-moz-placeholder {
  --placeholder-opacity: 1;
  color: #744210;
  color: rgba(116, 66, 16, var(--placeholder-opacity));
}

.tw-placeholder-yellow-900::placeholder {
  --placeholder-opacity: 1;
  color: #744210;
  color: rgba(116, 66, 16, var(--placeholder-opacity));
}

.tw-placeholder-green-100::-moz-placeholder {
  --placeholder-opacity: 1;
  color: #f0fff4;
  color: rgba(240, 255, 244, var(--placeholder-opacity));
}

.tw-placeholder-green-100::placeholder {
  --placeholder-opacity: 1;
  color: #f0fff4;
  color: rgba(240, 255, 244, var(--placeholder-opacity));
}

.tw-placeholder-green-200::-moz-placeholder {
  --placeholder-opacity: 1;
  color: #c6f6d5;
  color: rgba(198, 246, 213, var(--placeholder-opacity));
}

.tw-placeholder-green-200::placeholder {
  --placeholder-opacity: 1;
  color: #c6f6d5;
  color: rgba(198, 246, 213, var(--placeholder-opacity));
}

.tw-placeholder-green-300::-moz-placeholder {
  --placeholder-opacity: 1;
  color: #9ae6b4;
  color: rgba(154, 230, 180, var(--placeholder-opacity));
}

.tw-placeholder-green-300::placeholder {
  --placeholder-opacity: 1;
  color: #9ae6b4;
  color: rgba(154, 230, 180, var(--placeholder-opacity));
}

.tw-placeholder-green-400::-moz-placeholder {
  --placeholder-opacity: 1;
  color: #68d391;
  color: rgba(104, 211, 145, var(--placeholder-opacity));
}

.tw-placeholder-green-400::placeholder {
  --placeholder-opacity: 1;
  color: #68d391;
  color: rgba(104, 211, 145, var(--placeholder-opacity));
}

.tw-placeholder-green-500::-moz-placeholder {
  --placeholder-opacity: 1;
  color: #48bb78;
  color: rgba(72, 187, 120, var(--placeholder-opacity));
}

.tw-placeholder-green-500::placeholder {
  --placeholder-opacity: 1;
  color: #48bb78;
  color: rgba(72, 187, 120, var(--placeholder-opacity));
}

.tw-placeholder-green-600::-moz-placeholder {
  --placeholder-opacity: 1;
  color: #38a169;
  color: rgba(56, 161, 105, var(--placeholder-opacity));
}

.tw-placeholder-green-600::placeholder {
  --placeholder-opacity: 1;
  color: #38a169;
  color: rgba(56, 161, 105, var(--placeholder-opacity));
}

.tw-placeholder-green-700::-moz-placeholder {
  --placeholder-opacity: 1;
  color: #2f855a;
  color: rgba(47, 133, 90, var(--placeholder-opacity));
}

.tw-placeholder-green-700::placeholder {
  --placeholder-opacity: 1;
  color: #2f855a;
  color: rgba(47, 133, 90, var(--placeholder-opacity));
}

.tw-placeholder-green-800::-moz-placeholder {
  --placeholder-opacity: 1;
  color: #276749;
  color: rgba(39, 103, 73, var(--placeholder-opacity));
}

.tw-placeholder-green-800::placeholder {
  --placeholder-opacity: 1;
  color: #276749;
  color: rgba(39, 103, 73, var(--placeholder-opacity));
}

.tw-placeholder-green-900::-moz-placeholder {
  --placeholder-opacity: 1;
  color: #22543d;
  color: rgba(34, 84, 61, var(--placeholder-opacity));
}

.tw-placeholder-green-900::placeholder {
  --placeholder-opacity: 1;
  color: #22543d;
  color: rgba(34, 84, 61, var(--placeholder-opacity));
}

.tw-placeholder-teal-100::-moz-placeholder {
  --placeholder-opacity: 1;
  color: #e6fffa;
  color: rgba(230, 255, 250, var(--placeholder-opacity));
}

.tw-placeholder-teal-100::placeholder {
  --placeholder-opacity: 1;
  color: #e6fffa;
  color: rgba(230, 255, 250, var(--placeholder-opacity));
}

.tw-placeholder-teal-200::-moz-placeholder {
  --placeholder-opacity: 1;
  color: #b2f5ea;
  color: rgba(178, 245, 234, var(--placeholder-opacity));
}

.tw-placeholder-teal-200::placeholder {
  --placeholder-opacity: 1;
  color: #b2f5ea;
  color: rgba(178, 245, 234, var(--placeholder-opacity));
}

.tw-placeholder-teal-300::-moz-placeholder {
  --placeholder-opacity: 1;
  color: #81e6d9;
  color: rgba(129, 230, 217, var(--placeholder-opacity));
}

.tw-placeholder-teal-300::placeholder {
  --placeholder-opacity: 1;
  color: #81e6d9;
  color: rgba(129, 230, 217, var(--placeholder-opacity));
}

.tw-placeholder-teal-400::-moz-placeholder {
  --placeholder-opacity: 1;
  color: #4fd1c5;
  color: rgba(79, 209, 197, var(--placeholder-opacity));
}

.tw-placeholder-teal-400::placeholder {
  --placeholder-opacity: 1;
  color: #4fd1c5;
  color: rgba(79, 209, 197, var(--placeholder-opacity));
}

.tw-placeholder-teal-500::-moz-placeholder {
  --placeholder-opacity: 1;
  color: #38b2ac;
  color: rgba(56, 178, 172, var(--placeholder-opacity));
}

.tw-placeholder-teal-500::placeholder {
  --placeholder-opacity: 1;
  color: #38b2ac;
  color: rgba(56, 178, 172, var(--placeholder-opacity));
}

.tw-placeholder-teal-600::-moz-placeholder {
  --placeholder-opacity: 1;
  color: #319795;
  color: rgba(49, 151, 149, var(--placeholder-opacity));
}

.tw-placeholder-teal-600::placeholder {
  --placeholder-opacity: 1;
  color: #319795;
  color: rgba(49, 151, 149, var(--placeholder-opacity));
}

.tw-placeholder-teal-700::-moz-placeholder {
  --placeholder-opacity: 1;
  color: #2c7a7b;
  color: rgba(44, 122, 123, var(--placeholder-opacity));
}

.tw-placeholder-teal-700::placeholder {
  --placeholder-opacity: 1;
  color: #2c7a7b;
  color: rgba(44, 122, 123, var(--placeholder-opacity));
}

.tw-placeholder-teal-800::-moz-placeholder {
  --placeholder-opacity: 1;
  color: #285e61;
  color: rgba(40, 94, 97, var(--placeholder-opacity));
}

.tw-placeholder-teal-800::placeholder {
  --placeholder-opacity: 1;
  color: #285e61;
  color: rgba(40, 94, 97, var(--placeholder-opacity));
}

.tw-placeholder-teal-900::-moz-placeholder {
  --placeholder-opacity: 1;
  color: #234e52;
  color: rgba(35, 78, 82, var(--placeholder-opacity));
}

.tw-placeholder-teal-900::placeholder {
  --placeholder-opacity: 1;
  color: #234e52;
  color: rgba(35, 78, 82, var(--placeholder-opacity));
}

.tw-placeholder-blue-100::-moz-placeholder {
  --placeholder-opacity: 1;
  color: #ebf8ff;
  color: rgba(235, 248, 255, var(--placeholder-opacity));
}

.tw-placeholder-blue-100::placeholder {
  --placeholder-opacity: 1;
  color: #ebf8ff;
  color: rgba(235, 248, 255, var(--placeholder-opacity));
}

.tw-placeholder-blue-200::-moz-placeholder {
  --placeholder-opacity: 1;
  color: #bee3f8;
  color: rgba(190, 227, 248, var(--placeholder-opacity));
}

.tw-placeholder-blue-200::placeholder {
  --placeholder-opacity: 1;
  color: #bee3f8;
  color: rgba(190, 227, 248, var(--placeholder-opacity));
}

.tw-placeholder-blue-300::-moz-placeholder {
  --placeholder-opacity: 1;
  color: #90cdf4;
  color: rgba(144, 205, 244, var(--placeholder-opacity));
}

.tw-placeholder-blue-300::placeholder {
  --placeholder-opacity: 1;
  color: #90cdf4;
  color: rgba(144, 205, 244, var(--placeholder-opacity));
}

.tw-placeholder-blue-400::-moz-placeholder {
  --placeholder-opacity: 1;
  color: #63b3ed;
  color: rgba(99, 179, 237, var(--placeholder-opacity));
}

.tw-placeholder-blue-400::placeholder {
  --placeholder-opacity: 1;
  color: #63b3ed;
  color: rgba(99, 179, 237, var(--placeholder-opacity));
}

.tw-placeholder-blue-500::-moz-placeholder {
  --placeholder-opacity: 1;
  color: #4299e1;
  color: rgba(66, 153, 225, var(--placeholder-opacity));
}

.tw-placeholder-blue-500::placeholder {
  --placeholder-opacity: 1;
  color: #4299e1;
  color: rgba(66, 153, 225, var(--placeholder-opacity));
}

.tw-placeholder-blue-600::-moz-placeholder {
  --placeholder-opacity: 1;
  color: #3182ce;
  color: rgba(49, 130, 206, var(--placeholder-opacity));
}

.tw-placeholder-blue-600::placeholder {
  --placeholder-opacity: 1;
  color: #3182ce;
  color: rgba(49, 130, 206, var(--placeholder-opacity));
}

.tw-placeholder-blue-700::-moz-placeholder {
  --placeholder-opacity: 1;
  color: #2b6cb0;
  color: rgba(43, 108, 176, var(--placeholder-opacity));
}

.tw-placeholder-blue-700::placeholder {
  --placeholder-opacity: 1;
  color: #2b6cb0;
  color: rgba(43, 108, 176, var(--placeholder-opacity));
}

.tw-placeholder-blue-800::-moz-placeholder {
  --placeholder-opacity: 1;
  color: #2c5282;
  color: rgba(44, 82, 130, var(--placeholder-opacity));
}

.tw-placeholder-blue-800::placeholder {
  --placeholder-opacity: 1;
  color: #2c5282;
  color: rgba(44, 82, 130, var(--placeholder-opacity));
}

.tw-placeholder-blue-900::-moz-placeholder {
  --placeholder-opacity: 1;
  color: #2a4365;
  color: rgba(42, 67, 101, var(--placeholder-opacity));
}

.tw-placeholder-blue-900::placeholder {
  --placeholder-opacity: 1;
  color: #2a4365;
  color: rgba(42, 67, 101, var(--placeholder-opacity));
}

.tw-placeholder-indigo-100::-moz-placeholder {
  --placeholder-opacity: 1;
  color: #ebf4ff;
  color: rgba(235, 244, 255, var(--placeholder-opacity));
}

.tw-placeholder-indigo-100::placeholder {
  --placeholder-opacity: 1;
  color: #ebf4ff;
  color: rgba(235, 244, 255, var(--placeholder-opacity));
}

.tw-placeholder-indigo-200::-moz-placeholder {
  --placeholder-opacity: 1;
  color: #c3dafe;
  color: rgba(195, 218, 254, var(--placeholder-opacity));
}

.tw-placeholder-indigo-200::placeholder {
  --placeholder-opacity: 1;
  color: #c3dafe;
  color: rgba(195, 218, 254, var(--placeholder-opacity));
}

.tw-placeholder-indigo-300::-moz-placeholder {
  --placeholder-opacity: 1;
  color: #a3bffa;
  color: rgba(163, 191, 250, var(--placeholder-opacity));
}

.tw-placeholder-indigo-300::placeholder {
  --placeholder-opacity: 1;
  color: #a3bffa;
  color: rgba(163, 191, 250, var(--placeholder-opacity));
}

.tw-placeholder-indigo-400::-moz-placeholder {
  --placeholder-opacity: 1;
  color: #7f9cf5;
  color: rgba(127, 156, 245, var(--placeholder-opacity));
}

.tw-placeholder-indigo-400::placeholder {
  --placeholder-opacity: 1;
  color: #7f9cf5;
  color: rgba(127, 156, 245, var(--placeholder-opacity));
}

.tw-placeholder-indigo-500::-moz-placeholder {
  --placeholder-opacity: 1;
  color: #667eea;
  color: rgba(102, 126, 234, var(--placeholder-opacity));
}

.tw-placeholder-indigo-500::placeholder {
  --placeholder-opacity: 1;
  color: #667eea;
  color: rgba(102, 126, 234, var(--placeholder-opacity));
}

.tw-placeholder-indigo-600::-moz-placeholder {
  --placeholder-opacity: 1;
  color: #5a67d8;
  color: rgba(90, 103, 216, var(--placeholder-opacity));
}

.tw-placeholder-indigo-600::placeholder {
  --placeholder-opacity: 1;
  color: #5a67d8;
  color: rgba(90, 103, 216, var(--placeholder-opacity));
}

.tw-placeholder-indigo-700::-moz-placeholder {
  --placeholder-opacity: 1;
  color: #4c51bf;
  color: rgba(76, 81, 191, var(--placeholder-opacity));
}

.tw-placeholder-indigo-700::placeholder {
  --placeholder-opacity: 1;
  color: #4c51bf;
  color: rgba(76, 81, 191, var(--placeholder-opacity));
}

.tw-placeholder-indigo-800::-moz-placeholder {
  --placeholder-opacity: 1;
  color: #434190;
  color: rgba(67, 65, 144, var(--placeholder-opacity));
}

.tw-placeholder-indigo-800::placeholder {
  --placeholder-opacity: 1;
  color: #434190;
  color: rgba(67, 65, 144, var(--placeholder-opacity));
}

.tw-placeholder-indigo-900::-moz-placeholder {
  --placeholder-opacity: 1;
  color: #3c366b;
  color: rgba(60, 54, 107, var(--placeholder-opacity));
}

.tw-placeholder-indigo-900::placeholder {
  --placeholder-opacity: 1;
  color: #3c366b;
  color: rgba(60, 54, 107, var(--placeholder-opacity));
}

.tw-placeholder-purple-100::-moz-placeholder {
  --placeholder-opacity: 1;
  color: #faf5ff;
  color: rgba(250, 245, 255, var(--placeholder-opacity));
}

.tw-placeholder-purple-100::placeholder {
  --placeholder-opacity: 1;
  color: #faf5ff;
  color: rgba(250, 245, 255, var(--placeholder-opacity));
}

.tw-placeholder-purple-200::-moz-placeholder {
  --placeholder-opacity: 1;
  color: #e9d8fd;
  color: rgba(233, 216, 253, var(--placeholder-opacity));
}

.tw-placeholder-purple-200::placeholder {
  --placeholder-opacity: 1;
  color: #e9d8fd;
  color: rgba(233, 216, 253, var(--placeholder-opacity));
}

.tw-placeholder-purple-300::-moz-placeholder {
  --placeholder-opacity: 1;
  color: #d6bcfa;
  color: rgba(214, 188, 250, var(--placeholder-opacity));
}

.tw-placeholder-purple-300::placeholder {
  --placeholder-opacity: 1;
  color: #d6bcfa;
  color: rgba(214, 188, 250, var(--placeholder-opacity));
}

.tw-placeholder-purple-400::-moz-placeholder {
  --placeholder-opacity: 1;
  color: #b794f4;
  color: rgba(183, 148, 244, var(--placeholder-opacity));
}

.tw-placeholder-purple-400::placeholder {
  --placeholder-opacity: 1;
  color: #b794f4;
  color: rgba(183, 148, 244, var(--placeholder-opacity));
}

.tw-placeholder-purple-500::-moz-placeholder {
  --placeholder-opacity: 1;
  color: #9f7aea;
  color: rgba(159, 122, 234, var(--placeholder-opacity));
}

.tw-placeholder-purple-500::placeholder {
  --placeholder-opacity: 1;
  color: #9f7aea;
  color: rgba(159, 122, 234, var(--placeholder-opacity));
}

.tw-placeholder-purple-600::-moz-placeholder {
  --placeholder-opacity: 1;
  color: #805ad5;
  color: rgba(128, 90, 213, var(--placeholder-opacity));
}

.tw-placeholder-purple-600::placeholder {
  --placeholder-opacity: 1;
  color: #805ad5;
  color: rgba(128, 90, 213, var(--placeholder-opacity));
}

.tw-placeholder-purple-700::-moz-placeholder {
  --placeholder-opacity: 1;
  color: #6b46c1;
  color: rgba(107, 70, 193, var(--placeholder-opacity));
}

.tw-placeholder-purple-700::placeholder {
  --placeholder-opacity: 1;
  color: #6b46c1;
  color: rgba(107, 70, 193, var(--placeholder-opacity));
}

.tw-placeholder-purple-800::-moz-placeholder {
  --placeholder-opacity: 1;
  color: #553c9a;
  color: rgba(85, 60, 154, var(--placeholder-opacity));
}

.tw-placeholder-purple-800::placeholder {
  --placeholder-opacity: 1;
  color: #553c9a;
  color: rgba(85, 60, 154, var(--placeholder-opacity));
}

.tw-placeholder-purple-900::-moz-placeholder {
  --placeholder-opacity: 1;
  color: #44337a;
  color: rgba(68, 51, 122, var(--placeholder-opacity));
}

.tw-placeholder-purple-900::placeholder {
  --placeholder-opacity: 1;
  color: #44337a;
  color: rgba(68, 51, 122, var(--placeholder-opacity));
}

.tw-placeholder-pink-100::-moz-placeholder {
  --placeholder-opacity: 1;
  color: #fff5f7;
  color: rgba(255, 245, 247, var(--placeholder-opacity));
}

.tw-placeholder-pink-100::placeholder {
  --placeholder-opacity: 1;
  color: #fff5f7;
  color: rgba(255, 245, 247, var(--placeholder-opacity));
}

.tw-placeholder-pink-200::-moz-placeholder {
  --placeholder-opacity: 1;
  color: #fed7e2;
  color: rgba(254, 215, 226, var(--placeholder-opacity));
}

.tw-placeholder-pink-200::placeholder {
  --placeholder-opacity: 1;
  color: #fed7e2;
  color: rgba(254, 215, 226, var(--placeholder-opacity));
}

.tw-placeholder-pink-300::-moz-placeholder {
  --placeholder-opacity: 1;
  color: #fbb6ce;
  color: rgba(251, 182, 206, var(--placeholder-opacity));
}

.tw-placeholder-pink-300::placeholder {
  --placeholder-opacity: 1;
  color: #fbb6ce;
  color: rgba(251, 182, 206, var(--placeholder-opacity));
}

.tw-placeholder-pink-400::-moz-placeholder {
  --placeholder-opacity: 1;
  color: #f687b3;
  color: rgba(246, 135, 179, var(--placeholder-opacity));
}

.tw-placeholder-pink-400::placeholder {
  --placeholder-opacity: 1;
  color: #f687b3;
  color: rgba(246, 135, 179, var(--placeholder-opacity));
}

.tw-placeholder-pink-500::-moz-placeholder {
  --placeholder-opacity: 1;
  color: #ed64a6;
  color: rgba(237, 100, 166, var(--placeholder-opacity));
}

.tw-placeholder-pink-500::placeholder {
  --placeholder-opacity: 1;
  color: #ed64a6;
  color: rgba(237, 100, 166, var(--placeholder-opacity));
}

.tw-placeholder-pink-600::-moz-placeholder {
  --placeholder-opacity: 1;
  color: #d53f8c;
  color: rgba(213, 63, 140, var(--placeholder-opacity));
}

.tw-placeholder-pink-600::placeholder {
  --placeholder-opacity: 1;
  color: #d53f8c;
  color: rgba(213, 63, 140, var(--placeholder-opacity));
}

.tw-placeholder-pink-700::-moz-placeholder {
  --placeholder-opacity: 1;
  color: #b83280;
  color: rgba(184, 50, 128, var(--placeholder-opacity));
}

.tw-placeholder-pink-700::placeholder {
  --placeholder-opacity: 1;
  color: #b83280;
  color: rgba(184, 50, 128, var(--placeholder-opacity));
}

.tw-placeholder-pink-800::-moz-placeholder {
  --placeholder-opacity: 1;
  color: #97266d;
  color: rgba(151, 38, 109, var(--placeholder-opacity));
}

.tw-placeholder-pink-800::placeholder {
  --placeholder-opacity: 1;
  color: #97266d;
  color: rgba(151, 38, 109, var(--placeholder-opacity));
}

.tw-placeholder-pink-900::-moz-placeholder {
  --placeholder-opacity: 1;
  color: #702459;
  color: rgba(112, 36, 89, var(--placeholder-opacity));
}

.tw-placeholder-pink-900::placeholder {
  --placeholder-opacity: 1;
  color: #702459;
  color: rgba(112, 36, 89, var(--placeholder-opacity));
}

.focus\:tw-placeholder-transparent:focus::-moz-placeholder {
  color: transparent;
}

.focus\:tw-placeholder-transparent:focus::placeholder {
  color: transparent;
}

.focus\:tw-placeholder-current:focus::-moz-placeholder {
  color: currentColor;
}

.focus\:tw-placeholder-current:focus::placeholder {
  color: currentColor;
}

.focus\:tw-placeholder-black:focus::-moz-placeholder {
  --placeholder-opacity: 1;
  color: #000;
  color: rgba(0, 0, 0, var(--placeholder-opacity));
}

.focus\:tw-placeholder-black:focus::placeholder {
  --placeholder-opacity: 1;
  color: #000;
  color: rgba(0, 0, 0, var(--placeholder-opacity));
}

.focus\:tw-placeholder-white:focus::-moz-placeholder {
  --placeholder-opacity: 1;
  color: #fff;
  color: rgba(255, 255, 255, var(--placeholder-opacity));
}

.focus\:tw-placeholder-white:focus::placeholder {
  --placeholder-opacity: 1;
  color: #fff;
  color: rgba(255, 255, 255, var(--placeholder-opacity));
}

.focus\:tw-placeholder-gray-100:focus::-moz-placeholder {
  --placeholder-opacity: 1;
  color: #f7fafc;
  color: rgba(247, 250, 252, var(--placeholder-opacity));
}

.focus\:tw-placeholder-gray-100:focus::placeholder {
  --placeholder-opacity: 1;
  color: #f7fafc;
  color: rgba(247, 250, 252, var(--placeholder-opacity));
}

.focus\:tw-placeholder-gray-200:focus::-moz-placeholder {
  --placeholder-opacity: 1;
  color: #edf2f7;
  color: rgba(237, 242, 247, var(--placeholder-opacity));
}

.focus\:tw-placeholder-gray-200:focus::placeholder {
  --placeholder-opacity: 1;
  color: #edf2f7;
  color: rgba(237, 242, 247, var(--placeholder-opacity));
}

.focus\:tw-placeholder-gray-300:focus::-moz-placeholder {
  --placeholder-opacity: 1;
  color: #e2e8f0;
  color: rgba(226, 232, 240, var(--placeholder-opacity));
}

.focus\:tw-placeholder-gray-300:focus::placeholder {
  --placeholder-opacity: 1;
  color: #e2e8f0;
  color: rgba(226, 232, 240, var(--placeholder-opacity));
}

.focus\:tw-placeholder-gray-400:focus::-moz-placeholder {
  --placeholder-opacity: 1;
  color: #cbd5e0;
  color: rgba(203, 213, 224, var(--placeholder-opacity));
}

.focus\:tw-placeholder-gray-400:focus::placeholder {
  --placeholder-opacity: 1;
  color: #cbd5e0;
  color: rgba(203, 213, 224, var(--placeholder-opacity));
}

.focus\:tw-placeholder-gray-500:focus::-moz-placeholder {
  --placeholder-opacity: 1;
  color: #a0aec0;
  color: rgba(160, 174, 192, var(--placeholder-opacity));
}

.focus\:tw-placeholder-gray-500:focus::placeholder {
  --placeholder-opacity: 1;
  color: #a0aec0;
  color: rgba(160, 174, 192, var(--placeholder-opacity));
}

.focus\:tw-placeholder-gray-600:focus::-moz-placeholder {
  --placeholder-opacity: 1;
  color: #718096;
  color: rgba(113, 128, 150, var(--placeholder-opacity));
}

.focus\:tw-placeholder-gray-600:focus::placeholder {
  --placeholder-opacity: 1;
  color: #718096;
  color: rgba(113, 128, 150, var(--placeholder-opacity));
}

.focus\:tw-placeholder-gray-700:focus::-moz-placeholder {
  --placeholder-opacity: 1;
  color: #4a5568;
  color: rgba(74, 85, 104, var(--placeholder-opacity));
}

.focus\:tw-placeholder-gray-700:focus::placeholder {
  --placeholder-opacity: 1;
  color: #4a5568;
  color: rgba(74, 85, 104, var(--placeholder-opacity));
}

.focus\:tw-placeholder-gray-800:focus::-moz-placeholder {
  --placeholder-opacity: 1;
  color: #2d3748;
  color: rgba(45, 55, 72, var(--placeholder-opacity));
}

.focus\:tw-placeholder-gray-800:focus::placeholder {
  --placeholder-opacity: 1;
  color: #2d3748;
  color: rgba(45, 55, 72, var(--placeholder-opacity));
}

.focus\:tw-placeholder-gray-900:focus::-moz-placeholder {
  --placeholder-opacity: 1;
  color: #1a202c;
  color: rgba(26, 32, 44, var(--placeholder-opacity));
}

.focus\:tw-placeholder-gray-900:focus::placeholder {
  --placeholder-opacity: 1;
  color: #1a202c;
  color: rgba(26, 32, 44, var(--placeholder-opacity));
}

.focus\:tw-placeholder-red-100:focus::-moz-placeholder {
  --placeholder-opacity: 1;
  color: #fff5f5;
  color: rgba(255, 245, 245, var(--placeholder-opacity));
}

.focus\:tw-placeholder-red-100:focus::placeholder {
  --placeholder-opacity: 1;
  color: #fff5f5;
  color: rgba(255, 245, 245, var(--placeholder-opacity));
}

.focus\:tw-placeholder-red-200:focus::-moz-placeholder {
  --placeholder-opacity: 1;
  color: #fed7d7;
  color: rgba(254, 215, 215, var(--placeholder-opacity));
}

.focus\:tw-placeholder-red-200:focus::placeholder {
  --placeholder-opacity: 1;
  color: #fed7d7;
  color: rgba(254, 215, 215, var(--placeholder-opacity));
}

.focus\:tw-placeholder-red-300:focus::-moz-placeholder {
  --placeholder-opacity: 1;
  color: #feb2b2;
  color: rgba(254, 178, 178, var(--placeholder-opacity));
}

.focus\:tw-placeholder-red-300:focus::placeholder {
  --placeholder-opacity: 1;
  color: #feb2b2;
  color: rgba(254, 178, 178, var(--placeholder-opacity));
}

.focus\:tw-placeholder-red-400:focus::-moz-placeholder {
  --placeholder-opacity: 1;
  color: #fc8181;
  color: rgba(252, 129, 129, var(--placeholder-opacity));
}

.focus\:tw-placeholder-red-400:focus::placeholder {
  --placeholder-opacity: 1;
  color: #fc8181;
  color: rgba(252, 129, 129, var(--placeholder-opacity));
}

.focus\:tw-placeholder-red-500:focus::-moz-placeholder {
  --placeholder-opacity: 1;
  color: #f56565;
  color: rgba(245, 101, 101, var(--placeholder-opacity));
}

.focus\:tw-placeholder-red-500:focus::placeholder {
  --placeholder-opacity: 1;
  color: #f56565;
  color: rgba(245, 101, 101, var(--placeholder-opacity));
}

.focus\:tw-placeholder-red-600:focus::-moz-placeholder {
  --placeholder-opacity: 1;
  color: #e53e3e;
  color: rgba(229, 62, 62, var(--placeholder-opacity));
}

.focus\:tw-placeholder-red-600:focus::placeholder {
  --placeholder-opacity: 1;
  color: #e53e3e;
  color: rgba(229, 62, 62, var(--placeholder-opacity));
}

.focus\:tw-placeholder-red-700:focus::-moz-placeholder {
  --placeholder-opacity: 1;
  color: #c53030;
  color: rgba(197, 48, 48, var(--placeholder-opacity));
}

.focus\:tw-placeholder-red-700:focus::placeholder {
  --placeholder-opacity: 1;
  color: #c53030;
  color: rgba(197, 48, 48, var(--placeholder-opacity));
}

.focus\:tw-placeholder-red-800:focus::-moz-placeholder {
  --placeholder-opacity: 1;
  color: #9b2c2c;
  color: rgba(155, 44, 44, var(--placeholder-opacity));
}

.focus\:tw-placeholder-red-800:focus::placeholder {
  --placeholder-opacity: 1;
  color: #9b2c2c;
  color: rgba(155, 44, 44, var(--placeholder-opacity));
}

.focus\:tw-placeholder-red-900:focus::-moz-placeholder {
  --placeholder-opacity: 1;
  color: #742a2a;
  color: rgba(116, 42, 42, var(--placeholder-opacity));
}

.focus\:tw-placeholder-red-900:focus::placeholder {
  --placeholder-opacity: 1;
  color: #742a2a;
  color: rgba(116, 42, 42, var(--placeholder-opacity));
}

.focus\:tw-placeholder-orange-100:focus::-moz-placeholder {
  --placeholder-opacity: 1;
  color: #fffaf0;
  color: rgba(255, 250, 240, var(--placeholder-opacity));
}

.focus\:tw-placeholder-orange-100:focus::placeholder {
  --placeholder-opacity: 1;
  color: #fffaf0;
  color: rgba(255, 250, 240, var(--placeholder-opacity));
}

.focus\:tw-placeholder-orange-200:focus::-moz-placeholder {
  --placeholder-opacity: 1;
  color: #feebc8;
  color: rgba(254, 235, 200, var(--placeholder-opacity));
}

.focus\:tw-placeholder-orange-200:focus::placeholder {
  --placeholder-opacity: 1;
  color: #feebc8;
  color: rgba(254, 235, 200, var(--placeholder-opacity));
}

.focus\:tw-placeholder-orange-300:focus::-moz-placeholder {
  --placeholder-opacity: 1;
  color: #fbd38d;
  color: rgba(251, 211, 141, var(--placeholder-opacity));
}

.focus\:tw-placeholder-orange-300:focus::placeholder {
  --placeholder-opacity: 1;
  color: #fbd38d;
  color: rgba(251, 211, 141, var(--placeholder-opacity));
}

.focus\:tw-placeholder-orange-400:focus::-moz-placeholder {
  --placeholder-opacity: 1;
  color: #f6ad55;
  color: rgba(246, 173, 85, var(--placeholder-opacity));
}

.focus\:tw-placeholder-orange-400:focus::placeholder {
  --placeholder-opacity: 1;
  color: #f6ad55;
  color: rgba(246, 173, 85, var(--placeholder-opacity));
}

.focus\:tw-placeholder-orange-500:focus::-moz-placeholder {
  --placeholder-opacity: 1;
  color: #ed8936;
  color: rgba(237, 137, 54, var(--placeholder-opacity));
}

.focus\:tw-placeholder-orange-500:focus::placeholder {
  --placeholder-opacity: 1;
  color: #ed8936;
  color: rgba(237, 137, 54, var(--placeholder-opacity));
}

.focus\:tw-placeholder-orange-600:focus::-moz-placeholder {
  --placeholder-opacity: 1;
  color: #dd6b20;
  color: rgba(221, 107, 32, var(--placeholder-opacity));
}

.focus\:tw-placeholder-orange-600:focus::placeholder {
  --placeholder-opacity: 1;
  color: #dd6b20;
  color: rgba(221, 107, 32, var(--placeholder-opacity));
}

.focus\:tw-placeholder-orange-700:focus::-moz-placeholder {
  --placeholder-opacity: 1;
  color: #c05621;
  color: rgba(192, 86, 33, var(--placeholder-opacity));
}

.focus\:tw-placeholder-orange-700:focus::placeholder {
  --placeholder-opacity: 1;
  color: #c05621;
  color: rgba(192, 86, 33, var(--placeholder-opacity));
}

.focus\:tw-placeholder-orange-800:focus::-moz-placeholder {
  --placeholder-opacity: 1;
  color: #9c4221;
  color: rgba(156, 66, 33, var(--placeholder-opacity));
}

.focus\:tw-placeholder-orange-800:focus::placeholder {
  --placeholder-opacity: 1;
  color: #9c4221;
  color: rgba(156, 66, 33, var(--placeholder-opacity));
}

.focus\:tw-placeholder-orange-900:focus::-moz-placeholder {
  --placeholder-opacity: 1;
  color: #7b341e;
  color: rgba(123, 52, 30, var(--placeholder-opacity));
}

.focus\:tw-placeholder-orange-900:focus::placeholder {
  --placeholder-opacity: 1;
  color: #7b341e;
  color: rgba(123, 52, 30, var(--placeholder-opacity));
}

.focus\:tw-placeholder-yellow-100:focus::-moz-placeholder {
  --placeholder-opacity: 1;
  color: #fffff0;
  color: rgba(255, 255, 240, var(--placeholder-opacity));
}

.focus\:tw-placeholder-yellow-100:focus::placeholder {
  --placeholder-opacity: 1;
  color: #fffff0;
  color: rgba(255, 255, 240, var(--placeholder-opacity));
}

.focus\:tw-placeholder-yellow-200:focus::-moz-placeholder {
  --placeholder-opacity: 1;
  color: #fefcbf;
  color: rgba(254, 252, 191, var(--placeholder-opacity));
}

.focus\:tw-placeholder-yellow-200:focus::placeholder {
  --placeholder-opacity: 1;
  color: #fefcbf;
  color: rgba(254, 252, 191, var(--placeholder-opacity));
}

.focus\:tw-placeholder-yellow-300:focus::-moz-placeholder {
  --placeholder-opacity: 1;
  color: #faf089;
  color: rgba(250, 240, 137, var(--placeholder-opacity));
}

.focus\:tw-placeholder-yellow-300:focus::placeholder {
  --placeholder-opacity: 1;
  color: #faf089;
  color: rgba(250, 240, 137, var(--placeholder-opacity));
}

.focus\:tw-placeholder-yellow-400:focus::-moz-placeholder {
  --placeholder-opacity: 1;
  color: #f6e05e;
  color: rgba(246, 224, 94, var(--placeholder-opacity));
}

.focus\:tw-placeholder-yellow-400:focus::placeholder {
  --placeholder-opacity: 1;
  color: #f6e05e;
  color: rgba(246, 224, 94, var(--placeholder-opacity));
}

.focus\:tw-placeholder-yellow-500:focus::-moz-placeholder {
  --placeholder-opacity: 1;
  color: #ecc94b;
  color: rgba(236, 201, 75, var(--placeholder-opacity));
}

.focus\:tw-placeholder-yellow-500:focus::placeholder {
  --placeholder-opacity: 1;
  color: #ecc94b;
  color: rgba(236, 201, 75, var(--placeholder-opacity));
}

.focus\:tw-placeholder-yellow-600:focus::-moz-placeholder {
  --placeholder-opacity: 1;
  color: #d69e2e;
  color: rgba(214, 158, 46, var(--placeholder-opacity));
}

.focus\:tw-placeholder-yellow-600:focus::placeholder {
  --placeholder-opacity: 1;
  color: #d69e2e;
  color: rgba(214, 158, 46, var(--placeholder-opacity));
}

.focus\:tw-placeholder-yellow-700:focus::-moz-placeholder {
  --placeholder-opacity: 1;
  color: #b7791f;
  color: rgba(183, 121, 31, var(--placeholder-opacity));
}

.focus\:tw-placeholder-yellow-700:focus::placeholder {
  --placeholder-opacity: 1;
  color: #b7791f;
  color: rgba(183, 121, 31, var(--placeholder-opacity));
}

.focus\:tw-placeholder-yellow-800:focus::-moz-placeholder {
  --placeholder-opacity: 1;
  color: #975a16;
  color: rgba(151, 90, 22, var(--placeholder-opacity));
}

.focus\:tw-placeholder-yellow-800:focus::placeholder {
  --placeholder-opacity: 1;
  color: #975a16;
  color: rgba(151, 90, 22, var(--placeholder-opacity));
}

.focus\:tw-placeholder-yellow-900:focus::-moz-placeholder {
  --placeholder-opacity: 1;
  color: #744210;
  color: rgba(116, 66, 16, var(--placeholder-opacity));
}

.focus\:tw-placeholder-yellow-900:focus::placeholder {
  --placeholder-opacity: 1;
  color: #744210;
  color: rgba(116, 66, 16, var(--placeholder-opacity));
}

.focus\:tw-placeholder-green-100:focus::-moz-placeholder {
  --placeholder-opacity: 1;
  color: #f0fff4;
  color: rgba(240, 255, 244, var(--placeholder-opacity));
}

.focus\:tw-placeholder-green-100:focus::placeholder {
  --placeholder-opacity: 1;
  color: #f0fff4;
  color: rgba(240, 255, 244, var(--placeholder-opacity));
}

.focus\:tw-placeholder-green-200:focus::-moz-placeholder {
  --placeholder-opacity: 1;
  color: #c6f6d5;
  color: rgba(198, 246, 213, var(--placeholder-opacity));
}

.focus\:tw-placeholder-green-200:focus::placeholder {
  --placeholder-opacity: 1;
  color: #c6f6d5;
  color: rgba(198, 246, 213, var(--placeholder-opacity));
}

.focus\:tw-placeholder-green-300:focus::-moz-placeholder {
  --placeholder-opacity: 1;
  color: #9ae6b4;
  color: rgba(154, 230, 180, var(--placeholder-opacity));
}

.focus\:tw-placeholder-green-300:focus::placeholder {
  --placeholder-opacity: 1;
  color: #9ae6b4;
  color: rgba(154, 230, 180, var(--placeholder-opacity));
}

.focus\:tw-placeholder-green-400:focus::-moz-placeholder {
  --placeholder-opacity: 1;
  color: #68d391;
  color: rgba(104, 211, 145, var(--placeholder-opacity));
}

.focus\:tw-placeholder-green-400:focus::placeholder {
  --placeholder-opacity: 1;
  color: #68d391;
  color: rgba(104, 211, 145, var(--placeholder-opacity));
}

.focus\:tw-placeholder-green-500:focus::-moz-placeholder {
  --placeholder-opacity: 1;
  color: #48bb78;
  color: rgba(72, 187, 120, var(--placeholder-opacity));
}

.focus\:tw-placeholder-green-500:focus::placeholder {
  --placeholder-opacity: 1;
  color: #48bb78;
  color: rgba(72, 187, 120, var(--placeholder-opacity));
}

.focus\:tw-placeholder-green-600:focus::-moz-placeholder {
  --placeholder-opacity: 1;
  color: #38a169;
  color: rgba(56, 161, 105, var(--placeholder-opacity));
}

.focus\:tw-placeholder-green-600:focus::placeholder {
  --placeholder-opacity: 1;
  color: #38a169;
  color: rgba(56, 161, 105, var(--placeholder-opacity));
}

.focus\:tw-placeholder-green-700:focus::-moz-placeholder {
  --placeholder-opacity: 1;
  color: #2f855a;
  color: rgba(47, 133, 90, var(--placeholder-opacity));
}

.focus\:tw-placeholder-green-700:focus::placeholder {
  --placeholder-opacity: 1;
  color: #2f855a;
  color: rgba(47, 133, 90, var(--placeholder-opacity));
}

.focus\:tw-placeholder-green-800:focus::-moz-placeholder {
  --placeholder-opacity: 1;
  color: #276749;
  color: rgba(39, 103, 73, var(--placeholder-opacity));
}

.focus\:tw-placeholder-green-800:focus::placeholder {
  --placeholder-opacity: 1;
  color: #276749;
  color: rgba(39, 103, 73, var(--placeholder-opacity));
}

.focus\:tw-placeholder-green-900:focus::-moz-placeholder {
  --placeholder-opacity: 1;
  color: #22543d;
  color: rgba(34, 84, 61, var(--placeholder-opacity));
}

.focus\:tw-placeholder-green-900:focus::placeholder {
  --placeholder-opacity: 1;
  color: #22543d;
  color: rgba(34, 84, 61, var(--placeholder-opacity));
}

.focus\:tw-placeholder-teal-100:focus::-moz-placeholder {
  --placeholder-opacity: 1;
  color: #e6fffa;
  color: rgba(230, 255, 250, var(--placeholder-opacity));
}

.focus\:tw-placeholder-teal-100:focus::placeholder {
  --placeholder-opacity: 1;
  color: #e6fffa;
  color: rgba(230, 255, 250, var(--placeholder-opacity));
}

.focus\:tw-placeholder-teal-200:focus::-moz-placeholder {
  --placeholder-opacity: 1;
  color: #b2f5ea;
  color: rgba(178, 245, 234, var(--placeholder-opacity));
}

.focus\:tw-placeholder-teal-200:focus::placeholder {
  --placeholder-opacity: 1;
  color: #b2f5ea;
  color: rgba(178, 245, 234, var(--placeholder-opacity));
}

.focus\:tw-placeholder-teal-300:focus::-moz-placeholder {
  --placeholder-opacity: 1;
  color: #81e6d9;
  color: rgba(129, 230, 217, var(--placeholder-opacity));
}

.focus\:tw-placeholder-teal-300:focus::placeholder {
  --placeholder-opacity: 1;
  color: #81e6d9;
  color: rgba(129, 230, 217, var(--placeholder-opacity));
}

.focus\:tw-placeholder-teal-400:focus::-moz-placeholder {
  --placeholder-opacity: 1;
  color: #4fd1c5;
  color: rgba(79, 209, 197, var(--placeholder-opacity));
}

.focus\:tw-placeholder-teal-400:focus::placeholder {
  --placeholder-opacity: 1;
  color: #4fd1c5;
  color: rgba(79, 209, 197, var(--placeholder-opacity));
}

.focus\:tw-placeholder-teal-500:focus::-moz-placeholder {
  --placeholder-opacity: 1;
  color: #38b2ac;
  color: rgba(56, 178, 172, var(--placeholder-opacity));
}

.focus\:tw-placeholder-teal-500:focus::placeholder {
  --placeholder-opacity: 1;
  color: #38b2ac;
  color: rgba(56, 178, 172, var(--placeholder-opacity));
}

.focus\:tw-placeholder-teal-600:focus::-moz-placeholder {
  --placeholder-opacity: 1;
  color: #319795;
  color: rgba(49, 151, 149, var(--placeholder-opacity));
}

.focus\:tw-placeholder-teal-600:focus::placeholder {
  --placeholder-opacity: 1;
  color: #319795;
  color: rgba(49, 151, 149, var(--placeholder-opacity));
}

.focus\:tw-placeholder-teal-700:focus::-moz-placeholder {
  --placeholder-opacity: 1;
  color: #2c7a7b;
  color: rgba(44, 122, 123, var(--placeholder-opacity));
}

.focus\:tw-placeholder-teal-700:focus::placeholder {
  --placeholder-opacity: 1;
  color: #2c7a7b;
  color: rgba(44, 122, 123, var(--placeholder-opacity));
}

.focus\:tw-placeholder-teal-800:focus::-moz-placeholder {
  --placeholder-opacity: 1;
  color: #285e61;
  color: rgba(40, 94, 97, var(--placeholder-opacity));
}

.focus\:tw-placeholder-teal-800:focus::placeholder {
  --placeholder-opacity: 1;
  color: #285e61;
  color: rgba(40, 94, 97, var(--placeholder-opacity));
}

.focus\:tw-placeholder-teal-900:focus::-moz-placeholder {
  --placeholder-opacity: 1;
  color: #234e52;
  color: rgba(35, 78, 82, var(--placeholder-opacity));
}

.focus\:tw-placeholder-teal-900:focus::placeholder {
  --placeholder-opacity: 1;
  color: #234e52;
  color: rgba(35, 78, 82, var(--placeholder-opacity));
}

.focus\:tw-placeholder-blue-100:focus::-moz-placeholder {
  --placeholder-opacity: 1;
  color: #ebf8ff;
  color: rgba(235, 248, 255, var(--placeholder-opacity));
}

.focus\:tw-placeholder-blue-100:focus::placeholder {
  --placeholder-opacity: 1;
  color: #ebf8ff;
  color: rgba(235, 248, 255, var(--placeholder-opacity));
}

.focus\:tw-placeholder-blue-200:focus::-moz-placeholder {
  --placeholder-opacity: 1;
  color: #bee3f8;
  color: rgba(190, 227, 248, var(--placeholder-opacity));
}

.focus\:tw-placeholder-blue-200:focus::placeholder {
  --placeholder-opacity: 1;
  color: #bee3f8;
  color: rgba(190, 227, 248, var(--placeholder-opacity));
}

.focus\:tw-placeholder-blue-300:focus::-moz-placeholder {
  --placeholder-opacity: 1;
  color: #90cdf4;
  color: rgba(144, 205, 244, var(--placeholder-opacity));
}

.focus\:tw-placeholder-blue-300:focus::placeholder {
  --placeholder-opacity: 1;
  color: #90cdf4;
  color: rgba(144, 205, 244, var(--placeholder-opacity));
}

.focus\:tw-placeholder-blue-400:focus::-moz-placeholder {
  --placeholder-opacity: 1;
  color: #63b3ed;
  color: rgba(99, 179, 237, var(--placeholder-opacity));
}

.focus\:tw-placeholder-blue-400:focus::placeholder {
  --placeholder-opacity: 1;
  color: #63b3ed;
  color: rgba(99, 179, 237, var(--placeholder-opacity));
}

.focus\:tw-placeholder-blue-500:focus::-moz-placeholder {
  --placeholder-opacity: 1;
  color: #4299e1;
  color: rgba(66, 153, 225, var(--placeholder-opacity));
}

.focus\:tw-placeholder-blue-500:focus::placeholder {
  --placeholder-opacity: 1;
  color: #4299e1;
  color: rgba(66, 153, 225, var(--placeholder-opacity));
}

.focus\:tw-placeholder-blue-600:focus::-moz-placeholder {
  --placeholder-opacity: 1;
  color: #3182ce;
  color: rgba(49, 130, 206, var(--placeholder-opacity));
}

.focus\:tw-placeholder-blue-600:focus::placeholder {
  --placeholder-opacity: 1;
  color: #3182ce;
  color: rgba(49, 130, 206, var(--placeholder-opacity));
}

.focus\:tw-placeholder-blue-700:focus::-moz-placeholder {
  --placeholder-opacity: 1;
  color: #2b6cb0;
  color: rgba(43, 108, 176, var(--placeholder-opacity));
}

.focus\:tw-placeholder-blue-700:focus::placeholder {
  --placeholder-opacity: 1;
  color: #2b6cb0;
  color: rgba(43, 108, 176, var(--placeholder-opacity));
}

.focus\:tw-placeholder-blue-800:focus::-moz-placeholder {
  --placeholder-opacity: 1;
  color: #2c5282;
  color: rgba(44, 82, 130, var(--placeholder-opacity));
}

.focus\:tw-placeholder-blue-800:focus::placeholder {
  --placeholder-opacity: 1;
  color: #2c5282;
  color: rgba(44, 82, 130, var(--placeholder-opacity));
}

.focus\:tw-placeholder-blue-900:focus::-moz-placeholder {
  --placeholder-opacity: 1;
  color: #2a4365;
  color: rgba(42, 67, 101, var(--placeholder-opacity));
}

.focus\:tw-placeholder-blue-900:focus::placeholder {
  --placeholder-opacity: 1;
  color: #2a4365;
  color: rgba(42, 67, 101, var(--placeholder-opacity));
}

.focus\:tw-placeholder-indigo-100:focus::-moz-placeholder {
  --placeholder-opacity: 1;
  color: #ebf4ff;
  color: rgba(235, 244, 255, var(--placeholder-opacity));
}

.focus\:tw-placeholder-indigo-100:focus::placeholder {
  --placeholder-opacity: 1;
  color: #ebf4ff;
  color: rgba(235, 244, 255, var(--placeholder-opacity));
}

.focus\:tw-placeholder-indigo-200:focus::-moz-placeholder {
  --placeholder-opacity: 1;
  color: #c3dafe;
  color: rgba(195, 218, 254, var(--placeholder-opacity));
}

.focus\:tw-placeholder-indigo-200:focus::placeholder {
  --placeholder-opacity: 1;
  color: #c3dafe;
  color: rgba(195, 218, 254, var(--placeholder-opacity));
}

.focus\:tw-placeholder-indigo-300:focus::-moz-placeholder {
  --placeholder-opacity: 1;
  color: #a3bffa;
  color: rgba(163, 191, 250, var(--placeholder-opacity));
}

.focus\:tw-placeholder-indigo-300:focus::placeholder {
  --placeholder-opacity: 1;
  color: #a3bffa;
  color: rgba(163, 191, 250, var(--placeholder-opacity));
}

.focus\:tw-placeholder-indigo-400:focus::-moz-placeholder {
  --placeholder-opacity: 1;
  color: #7f9cf5;
  color: rgba(127, 156, 245, var(--placeholder-opacity));
}

.focus\:tw-placeholder-indigo-400:focus::placeholder {
  --placeholder-opacity: 1;
  color: #7f9cf5;
  color: rgba(127, 156, 245, var(--placeholder-opacity));
}

.focus\:tw-placeholder-indigo-500:focus::-moz-placeholder {
  --placeholder-opacity: 1;
  color: #667eea;
  color: rgba(102, 126, 234, var(--placeholder-opacity));
}

.focus\:tw-placeholder-indigo-500:focus::placeholder {
  --placeholder-opacity: 1;
  color: #667eea;
  color: rgba(102, 126, 234, var(--placeholder-opacity));
}

.focus\:tw-placeholder-indigo-600:focus::-moz-placeholder {
  --placeholder-opacity: 1;
  color: #5a67d8;
  color: rgba(90, 103, 216, var(--placeholder-opacity));
}

.focus\:tw-placeholder-indigo-600:focus::placeholder {
  --placeholder-opacity: 1;
  color: #5a67d8;
  color: rgba(90, 103, 216, var(--placeholder-opacity));
}

.focus\:tw-placeholder-indigo-700:focus::-moz-placeholder {
  --placeholder-opacity: 1;
  color: #4c51bf;
  color: rgba(76, 81, 191, var(--placeholder-opacity));
}

.focus\:tw-placeholder-indigo-700:focus::placeholder {
  --placeholder-opacity: 1;
  color: #4c51bf;
  color: rgba(76, 81, 191, var(--placeholder-opacity));
}

.focus\:tw-placeholder-indigo-800:focus::-moz-placeholder {
  --placeholder-opacity: 1;
  color: #434190;
  color: rgba(67, 65, 144, var(--placeholder-opacity));
}

.focus\:tw-placeholder-indigo-800:focus::placeholder {
  --placeholder-opacity: 1;
  color: #434190;
  color: rgba(67, 65, 144, var(--placeholder-opacity));
}

.focus\:tw-placeholder-indigo-900:focus::-moz-placeholder {
  --placeholder-opacity: 1;
  color: #3c366b;
  color: rgba(60, 54, 107, var(--placeholder-opacity));
}

.focus\:tw-placeholder-indigo-900:focus::placeholder {
  --placeholder-opacity: 1;
  color: #3c366b;
  color: rgba(60, 54, 107, var(--placeholder-opacity));
}

.focus\:tw-placeholder-purple-100:focus::-moz-placeholder {
  --placeholder-opacity: 1;
  color: #faf5ff;
  color: rgba(250, 245, 255, var(--placeholder-opacity));
}

.focus\:tw-placeholder-purple-100:focus::placeholder {
  --placeholder-opacity: 1;
  color: #faf5ff;
  color: rgba(250, 245, 255, var(--placeholder-opacity));
}

.focus\:tw-placeholder-purple-200:focus::-moz-placeholder {
  --placeholder-opacity: 1;
  color: #e9d8fd;
  color: rgba(233, 216, 253, var(--placeholder-opacity));
}

.focus\:tw-placeholder-purple-200:focus::placeholder {
  --placeholder-opacity: 1;
  color: #e9d8fd;
  color: rgba(233, 216, 253, var(--placeholder-opacity));
}

.focus\:tw-placeholder-purple-300:focus::-moz-placeholder {
  --placeholder-opacity: 1;
  color: #d6bcfa;
  color: rgba(214, 188, 250, var(--placeholder-opacity));
}

.focus\:tw-placeholder-purple-300:focus::placeholder {
  --placeholder-opacity: 1;
  color: #d6bcfa;
  color: rgba(214, 188, 250, var(--placeholder-opacity));
}

.focus\:tw-placeholder-purple-400:focus::-moz-placeholder {
  --placeholder-opacity: 1;
  color: #b794f4;
  color: rgba(183, 148, 244, var(--placeholder-opacity));
}

.focus\:tw-placeholder-purple-400:focus::placeholder {
  --placeholder-opacity: 1;
  color: #b794f4;
  color: rgba(183, 148, 244, var(--placeholder-opacity));
}

.focus\:tw-placeholder-purple-500:focus::-moz-placeholder {
  --placeholder-opacity: 1;
  color: #9f7aea;
  color: rgba(159, 122, 234, var(--placeholder-opacity));
}

.focus\:tw-placeholder-purple-500:focus::placeholder {
  --placeholder-opacity: 1;
  color: #9f7aea;
  color: rgba(159, 122, 234, var(--placeholder-opacity));
}

.focus\:tw-placeholder-purple-600:focus::-moz-placeholder {
  --placeholder-opacity: 1;
  color: #805ad5;
  color: rgba(128, 90, 213, var(--placeholder-opacity));
}

.focus\:tw-placeholder-purple-600:focus::placeholder {
  --placeholder-opacity: 1;
  color: #805ad5;
  color: rgba(128, 90, 213, var(--placeholder-opacity));
}

.focus\:tw-placeholder-purple-700:focus::-moz-placeholder {
  --placeholder-opacity: 1;
  color: #6b46c1;
  color: rgba(107, 70, 193, var(--placeholder-opacity));
}

.focus\:tw-placeholder-purple-700:focus::placeholder {
  --placeholder-opacity: 1;
  color: #6b46c1;
  color: rgba(107, 70, 193, var(--placeholder-opacity));
}

.focus\:tw-placeholder-purple-800:focus::-moz-placeholder {
  --placeholder-opacity: 1;
  color: #553c9a;
  color: rgba(85, 60, 154, var(--placeholder-opacity));
}

.focus\:tw-placeholder-purple-800:focus::placeholder {
  --placeholder-opacity: 1;
  color: #553c9a;
  color: rgba(85, 60, 154, var(--placeholder-opacity));
}

.focus\:tw-placeholder-purple-900:focus::-moz-placeholder {
  --placeholder-opacity: 1;
  color: #44337a;
  color: rgba(68, 51, 122, var(--placeholder-opacity));
}

.focus\:tw-placeholder-purple-900:focus::placeholder {
  --placeholder-opacity: 1;
  color: #44337a;
  color: rgba(68, 51, 122, var(--placeholder-opacity));
}

.focus\:tw-placeholder-pink-100:focus::-moz-placeholder {
  --placeholder-opacity: 1;
  color: #fff5f7;
  color: rgba(255, 245, 247, var(--placeholder-opacity));
}

.focus\:tw-placeholder-pink-100:focus::placeholder {
  --placeholder-opacity: 1;
  color: #fff5f7;
  color: rgba(255, 245, 247, var(--placeholder-opacity));
}

.focus\:tw-placeholder-pink-200:focus::-moz-placeholder {
  --placeholder-opacity: 1;
  color: #fed7e2;
  color: rgba(254, 215, 226, var(--placeholder-opacity));
}

.focus\:tw-placeholder-pink-200:focus::placeholder {
  --placeholder-opacity: 1;
  color: #fed7e2;
  color: rgba(254, 215, 226, var(--placeholder-opacity));
}

.focus\:tw-placeholder-pink-300:focus::-moz-placeholder {
  --placeholder-opacity: 1;
  color: #fbb6ce;
  color: rgba(251, 182, 206, var(--placeholder-opacity));
}

.focus\:tw-placeholder-pink-300:focus::placeholder {
  --placeholder-opacity: 1;
  color: #fbb6ce;
  color: rgba(251, 182, 206, var(--placeholder-opacity));
}

.focus\:tw-placeholder-pink-400:focus::-moz-placeholder {
  --placeholder-opacity: 1;
  color: #f687b3;
  color: rgba(246, 135, 179, var(--placeholder-opacity));
}

.focus\:tw-placeholder-pink-400:focus::placeholder {
  --placeholder-opacity: 1;
  color: #f687b3;
  color: rgba(246, 135, 179, var(--placeholder-opacity));
}

.focus\:tw-placeholder-pink-500:focus::-moz-placeholder {
  --placeholder-opacity: 1;
  color: #ed64a6;
  color: rgba(237, 100, 166, var(--placeholder-opacity));
}

.focus\:tw-placeholder-pink-500:focus::placeholder {
  --placeholder-opacity: 1;
  color: #ed64a6;
  color: rgba(237, 100, 166, var(--placeholder-opacity));
}

.focus\:tw-placeholder-pink-600:focus::-moz-placeholder {
  --placeholder-opacity: 1;
  color: #d53f8c;
  color: rgba(213, 63, 140, var(--placeholder-opacity));
}

.focus\:tw-placeholder-pink-600:focus::placeholder {
  --placeholder-opacity: 1;
  color: #d53f8c;
  color: rgba(213, 63, 140, var(--placeholder-opacity));
}

.focus\:tw-placeholder-pink-700:focus::-moz-placeholder {
  --placeholder-opacity: 1;
  color: #b83280;
  color: rgba(184, 50, 128, var(--placeholder-opacity));
}

.focus\:tw-placeholder-pink-700:focus::placeholder {
  --placeholder-opacity: 1;
  color: #b83280;
  color: rgba(184, 50, 128, var(--placeholder-opacity));
}

.focus\:tw-placeholder-pink-800:focus::-moz-placeholder {
  --placeholder-opacity: 1;
  color: #97266d;
  color: rgba(151, 38, 109, var(--placeholder-opacity));
}

.focus\:tw-placeholder-pink-800:focus::placeholder {
  --placeholder-opacity: 1;
  color: #97266d;
  color: rgba(151, 38, 109, var(--placeholder-opacity));
}

.focus\:tw-placeholder-pink-900:focus::-moz-placeholder {
  --placeholder-opacity: 1;
  color: #702459;
  color: rgba(112, 36, 89, var(--placeholder-opacity));
}

.focus\:tw-placeholder-pink-900:focus::placeholder {
  --placeholder-opacity: 1;
  color: #702459;
  color: rgba(112, 36, 89, var(--placeholder-opacity));
}

.tw-placeholder-opacity-0::-moz-placeholder {
  --placeholder-opacity: 0;
}

.tw-placeholder-opacity-0::placeholder {
  --placeholder-opacity: 0;
}

.tw-placeholder-opacity-25::-moz-placeholder {
  --placeholder-opacity: 0.25;
}

.tw-placeholder-opacity-25::placeholder {
  --placeholder-opacity: 0.25;
}

.tw-placeholder-opacity-50::-moz-placeholder {
  --placeholder-opacity: 0.5;
}

.tw-placeholder-opacity-50::placeholder {
  --placeholder-opacity: 0.5;
}

.tw-placeholder-opacity-75::-moz-placeholder {
  --placeholder-opacity: 0.75;
}

.tw-placeholder-opacity-75::placeholder {
  --placeholder-opacity: 0.75;
}

.tw-placeholder-opacity-100::-moz-placeholder {
  --placeholder-opacity: 1;
}

.tw-placeholder-opacity-100::placeholder {
  --placeholder-opacity: 1;
}

.focus\:tw-placeholder-opacity-0:focus::-moz-placeholder {
  --placeholder-opacity: 0;
}

.focus\:tw-placeholder-opacity-0:focus::placeholder {
  --placeholder-opacity: 0;
}

.focus\:tw-placeholder-opacity-25:focus::-moz-placeholder {
  --placeholder-opacity: 0.25;
}

.focus\:tw-placeholder-opacity-25:focus::placeholder {
  --placeholder-opacity: 0.25;
}

.focus\:tw-placeholder-opacity-50:focus::-moz-placeholder {
  --placeholder-opacity: 0.5;
}

.focus\:tw-placeholder-opacity-50:focus::placeholder {
  --placeholder-opacity: 0.5;
}

.focus\:tw-placeholder-opacity-75:focus::-moz-placeholder {
  --placeholder-opacity: 0.75;
}

.focus\:tw-placeholder-opacity-75:focus::placeholder {
  --placeholder-opacity: 0.75;
}

.focus\:tw-placeholder-opacity-100:focus::-moz-placeholder {
  --placeholder-opacity: 1;
}

.focus\:tw-placeholder-opacity-100:focus::placeholder {
  --placeholder-opacity: 1;
}

.tw-pointer-events-none {
  pointer-events: none;
}

.tw-pointer-events-auto {
  pointer-events: auto;
}

.tw-static {
  position: static;
}

.tw-fixed {
  position: fixed;
}

.tw-absolute {
  position: absolute;
}

.tw-relative {
  position: relative;
}

.tw-sticky {
  position: sticky;
}

.tw-inset-0 {
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.tw-inset-auto {
  top: auto;
  right: auto;
  bottom: auto;
  left: auto;
}

.tw-inset-y-0 {
  top: 0;
  bottom: 0;
}

.tw-inset-x-0 {
  right: 0;
  left: 0;
}

.tw-inset-y-auto {
  top: auto;
  bottom: auto;
}

.tw-inset-x-auto {
  right: auto;
  left: auto;
}

.tw-top-0 {
  top: 0;
}

.tw-right-0 {
  right: 0;
}

.tw-bottom-0 {
  bottom: 0;
}

.tw-left-0 {
  left: 0;
}

.tw-top-auto {
  top: auto;
}

.tw-right-auto {
  right: auto;
}

.tw-bottom-auto {
  bottom: auto;
}

.tw-left-auto {
  left: auto;
}

.tw-resize-none {
  resize: none;
}

.tw-resize-y {
  resize: vertical;
}

.tw-resize-x {
  resize: horizontal;
}

.tw-resize {
  resize: both;
}

.tw-shadow-xs {
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.05);
}

.tw-shadow-sm {
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
}

.tw-shadow {
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
}

.tw-shadow-md {
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
}

.tw-shadow-lg {
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
}

.tw-shadow-xl {
  box-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04);
}

.tw-shadow-2xl {
  box-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25);
}

.tw-shadow-inner {
  box-shadow: inset 0 2px 4px 0 rgba(0, 0, 0, 0.06);
}

.tw-shadow-outline {
  box-shadow: 0 0 0 3px rgba(66, 153, 225, 0.5);
}

.tw-shadow-none {
  box-shadow: none;
}

.hover\:tw-shadow-xs:hover {
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.05);
}

.hover\:tw-shadow-sm:hover {
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
}

.hover\:tw-shadow:hover {
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
}

.hover\:tw-shadow-md:hover {
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
}

.hover\:tw-shadow-lg:hover {
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
}

.hover\:tw-shadow-xl:hover {
  box-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04);
}

.hover\:tw-shadow-2xl:hover {
  box-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25);
}

.hover\:tw-shadow-inner:hover {
  box-shadow: inset 0 2px 4px 0 rgba(0, 0, 0, 0.06);
}

.hover\:tw-shadow-outline:hover {
  box-shadow: 0 0 0 3px rgba(66, 153, 225, 0.5);
}

.hover\:tw-shadow-none:hover {
  box-shadow: none;
}

.focus\:tw-shadow-xs:focus {
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.05);
}

.focus\:tw-shadow-sm:focus {
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
}

.focus\:tw-shadow:focus {
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
}

.focus\:tw-shadow-md:focus {
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
}

.focus\:tw-shadow-lg:focus {
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
}

.focus\:tw-shadow-xl:focus {
  box-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04);
}

.focus\:tw-shadow-2xl:focus {
  box-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25);
}

.focus\:tw-shadow-inner:focus {
  box-shadow: inset 0 2px 4px 0 rgba(0, 0, 0, 0.06);
}

.focus\:tw-shadow-outline:focus {
  box-shadow: 0 0 0 3px rgba(66, 153, 225, 0.5);
}

.focus\:tw-shadow-none:focus {
  box-shadow: none;
}

.tw-fill-current {
  fill: currentColor;
}

.tw-stroke-current {
  stroke: currentColor;
}

.tw-stroke-0 {
  stroke-width: 0;
}

.tw-stroke-1 {
  stroke-width: 1;
}

.tw-stroke-2 {
  stroke-width: 2;
}

.tw-table-auto {
  table-layout: auto;
}

.tw-table-fixed {
  table-layout: fixed;
}

.tw-text-left {
  text-align: left;
}

.tw-text-center {
  text-align: center;
}

.tw-text-right {
  text-align: right;
}

.tw-text-justify {
  text-align: justify;
}

.tw-text-transparent {
  color: transparent;
}

.tw-text-current {
  color: currentColor;
}

.tw-text-black {
  --text-opacity: 1;
  color: #000;
  color: rgba(0, 0, 0, var(--text-opacity));
}

.tw-text-white {
  --text-opacity: 1;
  color: #fff;
  color: rgba(255, 255, 255, var(--text-opacity));
}

.tw-text-gray-100 {
  --text-opacity: 1;
  color: #f7fafc;
  color: rgba(247, 250, 252, var(--text-opacity));
}

.tw-text-gray-200 {
  --text-opacity: 1;
  color: #edf2f7;
  color: rgba(237, 242, 247, var(--text-opacity));
}

.tw-text-gray-300 {
  --text-opacity: 1;
  color: #e2e8f0;
  color: rgba(226, 232, 240, var(--text-opacity));
}

.tw-text-gray-400 {
  --text-opacity: 1;
  color: #cbd5e0;
  color: rgba(203, 213, 224, var(--text-opacity));
}

.tw-text-gray-500 {
  --text-opacity: 1;
  color: #a0aec0;
  color: rgba(160, 174, 192, var(--text-opacity));
}

.tw-text-gray-600 {
  --text-opacity: 1;
  color: #718096;
  color: rgba(113, 128, 150, var(--text-opacity));
}

.tw-text-gray-700 {
  --text-opacity: 1;
  color: #4a5568;
  color: rgba(74, 85, 104, var(--text-opacity));
}

.tw-text-gray-800 {
  --text-opacity: 1;
  color: #2d3748;
  color: rgba(45, 55, 72, var(--text-opacity));
}

.tw-text-gray-900 {
  --text-opacity: 1;
  color: #1a202c;
  color: rgba(26, 32, 44, var(--text-opacity));
}

.tw-text-red-100 {
  --text-opacity: 1;
  color: #fff5f5;
  color: rgba(255, 245, 245, var(--text-opacity));
}

.tw-text-red-200 {
  --text-opacity: 1;
  color: #fed7d7;
  color: rgba(254, 215, 215, var(--text-opacity));
}

.tw-text-red-300 {
  --text-opacity: 1;
  color: #feb2b2;
  color: rgba(254, 178, 178, var(--text-opacity));
}

.tw-text-red-400 {
  --text-opacity: 1;
  color: #fc8181;
  color: rgba(252, 129, 129, var(--text-opacity));
}

.tw-text-red-500 {
  --text-opacity: 1;
  color: #f56565;
  color: rgba(245, 101, 101, var(--text-opacity));
}

.tw-text-red-600 {
  --text-opacity: 1;
  color: #e53e3e;
  color: rgba(229, 62, 62, var(--text-opacity));
}

.tw-text-red-700 {
  --text-opacity: 1;
  color: #c53030;
  color: rgba(197, 48, 48, var(--text-opacity));
}

.tw-text-red-800 {
  --text-opacity: 1;
  color: #9b2c2c;
  color: rgba(155, 44, 44, var(--text-opacity));
}

.tw-text-red-900 {
  --text-opacity: 1;
  color: #742a2a;
  color: rgba(116, 42, 42, var(--text-opacity));
}

.tw-text-orange-100 {
  --text-opacity: 1;
  color: #fffaf0;
  color: rgba(255, 250, 240, var(--text-opacity));
}

.tw-text-orange-200 {
  --text-opacity: 1;
  color: #feebc8;
  color: rgba(254, 235, 200, var(--text-opacity));
}

.tw-text-orange-300 {
  --text-opacity: 1;
  color: #fbd38d;
  color: rgba(251, 211, 141, var(--text-opacity));
}

.tw-text-orange-400 {
  --text-opacity: 1;
  color: #f6ad55;
  color: rgba(246, 173, 85, var(--text-opacity));
}

.tw-text-orange-500 {
  --text-opacity: 1;
  color: #ed8936;
  color: rgba(237, 137, 54, var(--text-opacity));
}

.tw-text-orange-600 {
  --text-opacity: 1;
  color: #dd6b20;
  color: rgba(221, 107, 32, var(--text-opacity));
}

.tw-text-orange-700 {
  --text-opacity: 1;
  color: #c05621;
  color: rgba(192, 86, 33, var(--text-opacity));
}

.tw-text-orange-800 {
  --text-opacity: 1;
  color: #9c4221;
  color: rgba(156, 66, 33, var(--text-opacity));
}

.tw-text-orange-900 {
  --text-opacity: 1;
  color: #7b341e;
  color: rgba(123, 52, 30, var(--text-opacity));
}

.tw-text-yellow-100 {
  --text-opacity: 1;
  color: #fffff0;
  color: rgba(255, 255, 240, var(--text-opacity));
}

.tw-text-yellow-200 {
  --text-opacity: 1;
  color: #fefcbf;
  color: rgba(254, 252, 191, var(--text-opacity));
}

.tw-text-yellow-300 {
  --text-opacity: 1;
  color: #faf089;
  color: rgba(250, 240, 137, var(--text-opacity));
}

.tw-text-yellow-400 {
  --text-opacity: 1;
  color: #f6e05e;
  color: rgba(246, 224, 94, var(--text-opacity));
}

.tw-text-yellow-500 {
  --text-opacity: 1;
  color: #ecc94b;
  color: rgba(236, 201, 75, var(--text-opacity));
}

.tw-text-yellow-600 {
  --text-opacity: 1;
  color: #d69e2e;
  color: rgba(214, 158, 46, var(--text-opacity));
}

.tw-text-yellow-700 {
  --text-opacity: 1;
  color: #b7791f;
  color: rgba(183, 121, 31, var(--text-opacity));
}

.tw-text-yellow-800 {
  --text-opacity: 1;
  color: #975a16;
  color: rgba(151, 90, 22, var(--text-opacity));
}

.tw-text-yellow-900 {
  --text-opacity: 1;
  color: #744210;
  color: rgba(116, 66, 16, var(--text-opacity));
}

.tw-text-green-100 {
  --text-opacity: 1;
  color: #f0fff4;
  color: rgba(240, 255, 244, var(--text-opacity));
}

.tw-text-green-200 {
  --text-opacity: 1;
  color: #c6f6d5;
  color: rgba(198, 246, 213, var(--text-opacity));
}

.tw-text-green-300 {
  --text-opacity: 1;
  color: #9ae6b4;
  color: rgba(154, 230, 180, var(--text-opacity));
}

.tw-text-green-400 {
  --text-opacity: 1;
  color: #68d391;
  color: rgba(104, 211, 145, var(--text-opacity));
}

.tw-text-green-500 {
  --text-opacity: 1;
  color: #48bb78;
  color: rgba(72, 187, 120, var(--text-opacity));
}

.tw-text-green-600 {
  --text-opacity: 1;
  color: #38a169;
  color: rgba(56, 161, 105, var(--text-opacity));
}

.tw-text-green-700 {
  --text-opacity: 1;
  color: #2f855a;
  color: rgba(47, 133, 90, var(--text-opacity));
}

.tw-text-green-800 {
  --text-opacity: 1;
  color: #276749;
  color: rgba(39, 103, 73, var(--text-opacity));
}

.tw-text-green-900 {
  --text-opacity: 1;
  color: #22543d;
  color: rgba(34, 84, 61, var(--text-opacity));
}

.tw-text-teal-100 {
  --text-opacity: 1;
  color: #e6fffa;
  color: rgba(230, 255, 250, var(--text-opacity));
}

.tw-text-teal-200 {
  --text-opacity: 1;
  color: #b2f5ea;
  color: rgba(178, 245, 234, var(--text-opacity));
}

.tw-text-teal-300 {
  --text-opacity: 1;
  color: #81e6d9;
  color: rgba(129, 230, 217, var(--text-opacity));
}

.tw-text-teal-400 {
  --text-opacity: 1;
  color: #4fd1c5;
  color: rgba(79, 209, 197, var(--text-opacity));
}

.tw-text-teal-500 {
  --text-opacity: 1;
  color: #38b2ac;
  color: rgba(56, 178, 172, var(--text-opacity));
}

.tw-text-teal-600 {
  --text-opacity: 1;
  color: #319795;
  color: rgba(49, 151, 149, var(--text-opacity));
}

.tw-text-teal-700 {
  --text-opacity: 1;
  color: #2c7a7b;
  color: rgba(44, 122, 123, var(--text-opacity));
}

.tw-text-teal-800 {
  --text-opacity: 1;
  color: #285e61;
  color: rgba(40, 94, 97, var(--text-opacity));
}

.tw-text-teal-900 {
  --text-opacity: 1;
  color: #234e52;
  color: rgba(35, 78, 82, var(--text-opacity));
}

.tw-text-blue-100 {
  --text-opacity: 1;
  color: #ebf8ff;
  color: rgba(235, 248, 255, var(--text-opacity));
}

.tw-text-blue-200 {
  --text-opacity: 1;
  color: #bee3f8;
  color: rgba(190, 227, 248, var(--text-opacity));
}

.tw-text-blue-300 {
  --text-opacity: 1;
  color: #90cdf4;
  color: rgba(144, 205, 244, var(--text-opacity));
}

.tw-text-blue-400 {
  --text-opacity: 1;
  color: #63b3ed;
  color: rgba(99, 179, 237, var(--text-opacity));
}

.tw-text-blue-500 {
  --text-opacity: 1;
  color: #4299e1;
  color: rgba(66, 153, 225, var(--text-opacity));
}

.tw-text-blue-600 {
  --text-opacity: 1;
  color: #3182ce;
  color: rgba(49, 130, 206, var(--text-opacity));
}

.tw-text-blue-700 {
  --text-opacity: 1;
  color: #2b6cb0;
  color: rgba(43, 108, 176, var(--text-opacity));
}

.tw-text-blue-800 {
  --text-opacity: 1;
  color: #2c5282;
  color: rgba(44, 82, 130, var(--text-opacity));
}

.tw-text-blue-900 {
  --text-opacity: 1;
  color: #2a4365;
  color: rgba(42, 67, 101, var(--text-opacity));
}

.tw-text-indigo-100 {
  --text-opacity: 1;
  color: #ebf4ff;
  color: rgba(235, 244, 255, var(--text-opacity));
}

.tw-text-indigo-200 {
  --text-opacity: 1;
  color: #c3dafe;
  color: rgba(195, 218, 254, var(--text-opacity));
}

.tw-text-indigo-300 {
  --text-opacity: 1;
  color: #a3bffa;
  color: rgba(163, 191, 250, var(--text-opacity));
}

.tw-text-indigo-400 {
  --text-opacity: 1;
  color: #7f9cf5;
  color: rgba(127, 156, 245, var(--text-opacity));
}

.tw-text-indigo-500 {
  --text-opacity: 1;
  color: #667eea;
  color: rgba(102, 126, 234, var(--text-opacity));
}

.tw-text-indigo-600 {
  --text-opacity: 1;
  color: #5a67d8;
  color: rgba(90, 103, 216, var(--text-opacity));
}

.tw-text-indigo-700 {
  --text-opacity: 1;
  color: #4c51bf;
  color: rgba(76, 81, 191, var(--text-opacity));
}

.tw-text-indigo-800 {
  --text-opacity: 1;
  color: #434190;
  color: rgba(67, 65, 144, var(--text-opacity));
}

.tw-text-indigo-900 {
  --text-opacity: 1;
  color: #3c366b;
  color: rgba(60, 54, 107, var(--text-opacity));
}

.tw-text-purple-100 {
  --text-opacity: 1;
  color: #faf5ff;
  color: rgba(250, 245, 255, var(--text-opacity));
}

.tw-text-purple-200 {
  --text-opacity: 1;
  color: #e9d8fd;
  color: rgba(233, 216, 253, var(--text-opacity));
}

.tw-text-purple-300 {
  --text-opacity: 1;
  color: #d6bcfa;
  color: rgba(214, 188, 250, var(--text-opacity));
}

.tw-text-purple-400 {
  --text-opacity: 1;
  color: #b794f4;
  color: rgba(183, 148, 244, var(--text-opacity));
}

.tw-text-purple-500 {
  --text-opacity: 1;
  color: #9f7aea;
  color: rgba(159, 122, 234, var(--text-opacity));
}

.tw-text-purple-600 {
  --text-opacity: 1;
  color: #805ad5;
  color: rgba(128, 90, 213, var(--text-opacity));
}

.tw-text-purple-700 {
  --text-opacity: 1;
  color: #6b46c1;
  color: rgba(107, 70, 193, var(--text-opacity));
}

.tw-text-purple-800 {
  --text-opacity: 1;
  color: #553c9a;
  color: rgba(85, 60, 154, var(--text-opacity));
}

.tw-text-purple-900 {
  --text-opacity: 1;
  color: #44337a;
  color: rgba(68, 51, 122, var(--text-opacity));
}

.tw-text-pink-100 {
  --text-opacity: 1;
  color: #fff5f7;
  color: rgba(255, 245, 247, var(--text-opacity));
}

.tw-text-pink-200 {
  --text-opacity: 1;
  color: #fed7e2;
  color: rgba(254, 215, 226, var(--text-opacity));
}

.tw-text-pink-300 {
  --text-opacity: 1;
  color: #fbb6ce;
  color: rgba(251, 182, 206, var(--text-opacity));
}

.tw-text-pink-400 {
  --text-opacity: 1;
  color: #f687b3;
  color: rgba(246, 135, 179, var(--text-opacity));
}

.tw-text-pink-500 {
  --text-opacity: 1;
  color: #ed64a6;
  color: rgba(237, 100, 166, var(--text-opacity));
}

.tw-text-pink-600 {
  --text-opacity: 1;
  color: #d53f8c;
  color: rgba(213, 63, 140, var(--text-opacity));
}

.tw-text-pink-700 {
  --text-opacity: 1;
  color: #b83280;
  color: rgba(184, 50, 128, var(--text-opacity));
}

.tw-text-pink-800 {
  --text-opacity: 1;
  color: #97266d;
  color: rgba(151, 38, 109, var(--text-opacity));
}

.tw-text-pink-900 {
  --text-opacity: 1;
  color: #702459;
  color: rgba(112, 36, 89, var(--text-opacity));
}

.hover\:tw-text-transparent:hover {
  color: transparent;
}

.hover\:tw-text-current:hover {
  color: currentColor;
}

.hover\:tw-text-black:hover {
  --text-opacity: 1;
  color: #000;
  color: rgba(0, 0, 0, var(--text-opacity));
}

.hover\:tw-text-white:hover {
  --text-opacity: 1;
  color: #fff;
  color: rgba(255, 255, 255, var(--text-opacity));
}

.hover\:tw-text-gray-100:hover {
  --text-opacity: 1;
  color: #f7fafc;
  color: rgba(247, 250, 252, var(--text-opacity));
}

.hover\:tw-text-gray-200:hover {
  --text-opacity: 1;
  color: #edf2f7;
  color: rgba(237, 242, 247, var(--text-opacity));
}

.hover\:tw-text-gray-300:hover {
  --text-opacity: 1;
  color: #e2e8f0;
  color: rgba(226, 232, 240, var(--text-opacity));
}

.hover\:tw-text-gray-400:hover {
  --text-opacity: 1;
  color: #cbd5e0;
  color: rgba(203, 213, 224, var(--text-opacity));
}

.hover\:tw-text-gray-500:hover {
  --text-opacity: 1;
  color: #a0aec0;
  color: rgba(160, 174, 192, var(--text-opacity));
}

.hover\:tw-text-gray-600:hover {
  --text-opacity: 1;
  color: #718096;
  color: rgba(113, 128, 150, var(--text-opacity));
}

.hover\:tw-text-gray-700:hover {
  --text-opacity: 1;
  color: #4a5568;
  color: rgba(74, 85, 104, var(--text-opacity));
}

.hover\:tw-text-gray-800:hover {
  --text-opacity: 1;
  color: #2d3748;
  color: rgba(45, 55, 72, var(--text-opacity));
}

.hover\:tw-text-gray-900:hover {
  --text-opacity: 1;
  color: #1a202c;
  color: rgba(26, 32, 44, var(--text-opacity));
}

.hover\:tw-text-red-100:hover {
  --text-opacity: 1;
  color: #fff5f5;
  color: rgba(255, 245, 245, var(--text-opacity));
}

.hover\:tw-text-red-200:hover {
  --text-opacity: 1;
  color: #fed7d7;
  color: rgba(254, 215, 215, var(--text-opacity));
}

.hover\:tw-text-red-300:hover {
  --text-opacity: 1;
  color: #feb2b2;
  color: rgba(254, 178, 178, var(--text-opacity));
}

.hover\:tw-text-red-400:hover {
  --text-opacity: 1;
  color: #fc8181;
  color: rgba(252, 129, 129, var(--text-opacity));
}

.hover\:tw-text-red-500:hover {
  --text-opacity: 1;
  color: #f56565;
  color: rgba(245, 101, 101, var(--text-opacity));
}

.hover\:tw-text-red-600:hover {
  --text-opacity: 1;
  color: #e53e3e;
  color: rgba(229, 62, 62, var(--text-opacity));
}

.hover\:tw-text-red-700:hover {
  --text-opacity: 1;
  color: #c53030;
  color: rgba(197, 48, 48, var(--text-opacity));
}

.hover\:tw-text-red-800:hover {
  --text-opacity: 1;
  color: #9b2c2c;
  color: rgba(155, 44, 44, var(--text-opacity));
}

.hover\:tw-text-red-900:hover {
  --text-opacity: 1;
  color: #742a2a;
  color: rgba(116, 42, 42, var(--text-opacity));
}

.hover\:tw-text-orange-100:hover {
  --text-opacity: 1;
  color: #fffaf0;
  color: rgba(255, 250, 240, var(--text-opacity));
}

.hover\:tw-text-orange-200:hover {
  --text-opacity: 1;
  color: #feebc8;
  color: rgba(254, 235, 200, var(--text-opacity));
}

.hover\:tw-text-orange-300:hover {
  --text-opacity: 1;
  color: #fbd38d;
  color: rgba(251, 211, 141, var(--text-opacity));
}

.hover\:tw-text-orange-400:hover {
  --text-opacity: 1;
  color: #f6ad55;
  color: rgba(246, 173, 85, var(--text-opacity));
}

.hover\:tw-text-orange-500:hover {
  --text-opacity: 1;
  color: #ed8936;
  color: rgba(237, 137, 54, var(--text-opacity));
}

.hover\:tw-text-orange-600:hover {
  --text-opacity: 1;
  color: #dd6b20;
  color: rgba(221, 107, 32, var(--text-opacity));
}

.hover\:tw-text-orange-700:hover {
  --text-opacity: 1;
  color: #c05621;
  color: rgba(192, 86, 33, var(--text-opacity));
}

.hover\:tw-text-orange-800:hover {
  --text-opacity: 1;
  color: #9c4221;
  color: rgba(156, 66, 33, var(--text-opacity));
}

.hover\:tw-text-orange-900:hover {
  --text-opacity: 1;
  color: #7b341e;
  color: rgba(123, 52, 30, var(--text-opacity));
}

.hover\:tw-text-yellow-100:hover {
  --text-opacity: 1;
  color: #fffff0;
  color: rgba(255, 255, 240, var(--text-opacity));
}

.hover\:tw-text-yellow-200:hover {
  --text-opacity: 1;
  color: #fefcbf;
  color: rgba(254, 252, 191, var(--text-opacity));
}

.hover\:tw-text-yellow-300:hover {
  --text-opacity: 1;
  color: #faf089;
  color: rgba(250, 240, 137, var(--text-opacity));
}

.hover\:tw-text-yellow-400:hover {
  --text-opacity: 1;
  color: #f6e05e;
  color: rgba(246, 224, 94, var(--text-opacity));
}

.hover\:tw-text-yellow-500:hover {
  --text-opacity: 1;
  color: #ecc94b;
  color: rgba(236, 201, 75, var(--text-opacity));
}

.hover\:tw-text-yellow-600:hover {
  --text-opacity: 1;
  color: #d69e2e;
  color: rgba(214, 158, 46, var(--text-opacity));
}

.hover\:tw-text-yellow-700:hover {
  --text-opacity: 1;
  color: #b7791f;
  color: rgba(183, 121, 31, var(--text-opacity));
}

.hover\:tw-text-yellow-800:hover {
  --text-opacity: 1;
  color: #975a16;
  color: rgba(151, 90, 22, var(--text-opacity));
}

.hover\:tw-text-yellow-900:hover {
  --text-opacity: 1;
  color: #744210;
  color: rgba(116, 66, 16, var(--text-opacity));
}

.hover\:tw-text-green-100:hover {
  --text-opacity: 1;
  color: #f0fff4;
  color: rgba(240, 255, 244, var(--text-opacity));
}

.hover\:tw-text-green-200:hover {
  --text-opacity: 1;
  color: #c6f6d5;
  color: rgba(198, 246, 213, var(--text-opacity));
}

.hover\:tw-text-green-300:hover {
  --text-opacity: 1;
  color: #9ae6b4;
  color: rgba(154, 230, 180, var(--text-opacity));
}

.hover\:tw-text-green-400:hover {
  --text-opacity: 1;
  color: #68d391;
  color: rgba(104, 211, 145, var(--text-opacity));
}

.hover\:tw-text-green-500:hover {
  --text-opacity: 1;
  color: #48bb78;
  color: rgba(72, 187, 120, var(--text-opacity));
}

.hover\:tw-text-green-600:hover {
  --text-opacity: 1;
  color: #38a169;
  color: rgba(56, 161, 105, var(--text-opacity));
}

.hover\:tw-text-green-700:hover {
  --text-opacity: 1;
  color: #2f855a;
  color: rgba(47, 133, 90, var(--text-opacity));
}

.hover\:tw-text-green-800:hover {
  --text-opacity: 1;
  color: #276749;
  color: rgba(39, 103, 73, var(--text-opacity));
}

.hover\:tw-text-green-900:hover {
  --text-opacity: 1;
  color: #22543d;
  color: rgba(34, 84, 61, var(--text-opacity));
}

.hover\:tw-text-teal-100:hover {
  --text-opacity: 1;
  color: #e6fffa;
  color: rgba(230, 255, 250, var(--text-opacity));
}

.hover\:tw-text-teal-200:hover {
  --text-opacity: 1;
  color: #b2f5ea;
  color: rgba(178, 245, 234, var(--text-opacity));
}

.hover\:tw-text-teal-300:hover {
  --text-opacity: 1;
  color: #81e6d9;
  color: rgba(129, 230, 217, var(--text-opacity));
}

.hover\:tw-text-teal-400:hover {
  --text-opacity: 1;
  color: #4fd1c5;
  color: rgba(79, 209, 197, var(--text-opacity));
}

.hover\:tw-text-teal-500:hover {
  --text-opacity: 1;
  color: #38b2ac;
  color: rgba(56, 178, 172, var(--text-opacity));
}

.hover\:tw-text-teal-600:hover {
  --text-opacity: 1;
  color: #319795;
  color: rgba(49, 151, 149, var(--text-opacity));
}

.hover\:tw-text-teal-700:hover {
  --text-opacity: 1;
  color: #2c7a7b;
  color: rgba(44, 122, 123, var(--text-opacity));
}

.hover\:tw-text-teal-800:hover {
  --text-opacity: 1;
  color: #285e61;
  color: rgba(40, 94, 97, var(--text-opacity));
}

.hover\:tw-text-teal-900:hover {
  --text-opacity: 1;
  color: #234e52;
  color: rgba(35, 78, 82, var(--text-opacity));
}

.hover\:tw-text-blue-100:hover {
  --text-opacity: 1;
  color: #ebf8ff;
  color: rgba(235, 248, 255, var(--text-opacity));
}

.hover\:tw-text-blue-200:hover {
  --text-opacity: 1;
  color: #bee3f8;
  color: rgba(190, 227, 248, var(--text-opacity));
}

.hover\:tw-text-blue-300:hover {
  --text-opacity: 1;
  color: #90cdf4;
  color: rgba(144, 205, 244, var(--text-opacity));
}

.hover\:tw-text-blue-400:hover {
  --text-opacity: 1;
  color: #63b3ed;
  color: rgba(99, 179, 237, var(--text-opacity));
}

.hover\:tw-text-blue-500:hover {
  --text-opacity: 1;
  color: #4299e1;
  color: rgba(66, 153, 225, var(--text-opacity));
}

.hover\:tw-text-blue-600:hover {
  --text-opacity: 1;
  color: #3182ce;
  color: rgba(49, 130, 206, var(--text-opacity));
}

.hover\:tw-text-blue-700:hover {
  --text-opacity: 1;
  color: #2b6cb0;
  color: rgba(43, 108, 176, var(--text-opacity));
}

.hover\:tw-text-blue-800:hover {
  --text-opacity: 1;
  color: #2c5282;
  color: rgba(44, 82, 130, var(--text-opacity));
}

.hover\:tw-text-blue-900:hover {
  --text-opacity: 1;
  color: #2a4365;
  color: rgba(42, 67, 101, var(--text-opacity));
}

.hover\:tw-text-indigo-100:hover {
  --text-opacity: 1;
  color: #ebf4ff;
  color: rgba(235, 244, 255, var(--text-opacity));
}

.hover\:tw-text-indigo-200:hover {
  --text-opacity: 1;
  color: #c3dafe;
  color: rgba(195, 218, 254, var(--text-opacity));
}

.hover\:tw-text-indigo-300:hover {
  --text-opacity: 1;
  color: #a3bffa;
  color: rgba(163, 191, 250, var(--text-opacity));
}

.hover\:tw-text-indigo-400:hover {
  --text-opacity: 1;
  color: #7f9cf5;
  color: rgba(127, 156, 245, var(--text-opacity));
}

.hover\:tw-text-indigo-500:hover {
  --text-opacity: 1;
  color: #667eea;
  color: rgba(102, 126, 234, var(--text-opacity));
}

.hover\:tw-text-indigo-600:hover {
  --text-opacity: 1;
  color: #5a67d8;
  color: rgba(90, 103, 216, var(--text-opacity));
}

.hover\:tw-text-indigo-700:hover {
  --text-opacity: 1;
  color: #4c51bf;
  color: rgba(76, 81, 191, var(--text-opacity));
}

.hover\:tw-text-indigo-800:hover {
  --text-opacity: 1;
  color: #434190;
  color: rgba(67, 65, 144, var(--text-opacity));
}

.hover\:tw-text-indigo-900:hover {
  --text-opacity: 1;
  color: #3c366b;
  color: rgba(60, 54, 107, var(--text-opacity));
}

.hover\:tw-text-purple-100:hover {
  --text-opacity: 1;
  color: #faf5ff;
  color: rgba(250, 245, 255, var(--text-opacity));
}

.hover\:tw-text-purple-200:hover {
  --text-opacity: 1;
  color: #e9d8fd;
  color: rgba(233, 216, 253, var(--text-opacity));
}

.hover\:tw-text-purple-300:hover {
  --text-opacity: 1;
  color: #d6bcfa;
  color: rgba(214, 188, 250, var(--text-opacity));
}

.hover\:tw-text-purple-400:hover {
  --text-opacity: 1;
  color: #b794f4;
  color: rgba(183, 148, 244, var(--text-opacity));
}

.hover\:tw-text-purple-500:hover {
  --text-opacity: 1;
  color: #9f7aea;
  color: rgba(159, 122, 234, var(--text-opacity));
}

.hover\:tw-text-purple-600:hover {
  --text-opacity: 1;
  color: #805ad5;
  color: rgba(128, 90, 213, var(--text-opacity));
}

.hover\:tw-text-purple-700:hover {
  --text-opacity: 1;
  color: #6b46c1;
  color: rgba(107, 70, 193, var(--text-opacity));
}

.hover\:tw-text-purple-800:hover {
  --text-opacity: 1;
  color: #553c9a;
  color: rgba(85, 60, 154, var(--text-opacity));
}

.hover\:tw-text-purple-900:hover {
  --text-opacity: 1;
  color: #44337a;
  color: rgba(68, 51, 122, var(--text-opacity));
}

.hover\:tw-text-pink-100:hover {
  --text-opacity: 1;
  color: #fff5f7;
  color: rgba(255, 245, 247, var(--text-opacity));
}

.hover\:tw-text-pink-200:hover {
  --text-opacity: 1;
  color: #fed7e2;
  color: rgba(254, 215, 226, var(--text-opacity));
}

.hover\:tw-text-pink-300:hover {
  --text-opacity: 1;
  color: #fbb6ce;
  color: rgba(251, 182, 206, var(--text-opacity));
}

.hover\:tw-text-pink-400:hover {
  --text-opacity: 1;
  color: #f687b3;
  color: rgba(246, 135, 179, var(--text-opacity));
}

.hover\:tw-text-pink-500:hover {
  --text-opacity: 1;
  color: #ed64a6;
  color: rgba(237, 100, 166, var(--text-opacity));
}

.hover\:tw-text-pink-600:hover {
  --text-opacity: 1;
  color: #d53f8c;
  color: rgba(213, 63, 140, var(--text-opacity));
}

.hover\:tw-text-pink-700:hover {
  --text-opacity: 1;
  color: #b83280;
  color: rgba(184, 50, 128, var(--text-opacity));
}

.hover\:tw-text-pink-800:hover {
  --text-opacity: 1;
  color: #97266d;
  color: rgba(151, 38, 109, var(--text-opacity));
}

.hover\:tw-text-pink-900:hover {
  --text-opacity: 1;
  color: #702459;
  color: rgba(112, 36, 89, var(--text-opacity));
}

.focus\:tw-text-transparent:focus {
  color: transparent;
}

.focus\:tw-text-current:focus {
  color: currentColor;
}

.focus\:tw-text-black:focus {
  --text-opacity: 1;
  color: #000;
  color: rgba(0, 0, 0, var(--text-opacity));
}

.focus\:tw-text-white:focus {
  --text-opacity: 1;
  color: #fff;
  color: rgba(255, 255, 255, var(--text-opacity));
}

.focus\:tw-text-gray-100:focus {
  --text-opacity: 1;
  color: #f7fafc;
  color: rgba(247, 250, 252, var(--text-opacity));
}

.focus\:tw-text-gray-200:focus {
  --text-opacity: 1;
  color: #edf2f7;
  color: rgba(237, 242, 247, var(--text-opacity));
}

.focus\:tw-text-gray-300:focus {
  --text-opacity: 1;
  color: #e2e8f0;
  color: rgba(226, 232, 240, var(--text-opacity));
}

.focus\:tw-text-gray-400:focus {
  --text-opacity: 1;
  color: #cbd5e0;
  color: rgba(203, 213, 224, var(--text-opacity));
}

.focus\:tw-text-gray-500:focus {
  --text-opacity: 1;
  color: #a0aec0;
  color: rgba(160, 174, 192, var(--text-opacity));
}

.focus\:tw-text-gray-600:focus {
  --text-opacity: 1;
  color: #718096;
  color: rgba(113, 128, 150, var(--text-opacity));
}

.focus\:tw-text-gray-700:focus {
  --text-opacity: 1;
  color: #4a5568;
  color: rgba(74, 85, 104, var(--text-opacity));
}

.focus\:tw-text-gray-800:focus {
  --text-opacity: 1;
  color: #2d3748;
  color: rgba(45, 55, 72, var(--text-opacity));
}

.focus\:tw-text-gray-900:focus {
  --text-opacity: 1;
  color: #1a202c;
  color: rgba(26, 32, 44, var(--text-opacity));
}

.focus\:tw-text-red-100:focus {
  --text-opacity: 1;
  color: #fff5f5;
  color: rgba(255, 245, 245, var(--text-opacity));
}

.focus\:tw-text-red-200:focus {
  --text-opacity: 1;
  color: #fed7d7;
  color: rgba(254, 215, 215, var(--text-opacity));
}

.focus\:tw-text-red-300:focus {
  --text-opacity: 1;
  color: #feb2b2;
  color: rgba(254, 178, 178, var(--text-opacity));
}

.focus\:tw-text-red-400:focus {
  --text-opacity: 1;
  color: #fc8181;
  color: rgba(252, 129, 129, var(--text-opacity));
}

.focus\:tw-text-red-500:focus {
  --text-opacity: 1;
  color: #f56565;
  color: rgba(245, 101, 101, var(--text-opacity));
}

.focus\:tw-text-red-600:focus {
  --text-opacity: 1;
  color: #e53e3e;
  color: rgba(229, 62, 62, var(--text-opacity));
}

.focus\:tw-text-red-700:focus {
  --text-opacity: 1;
  color: #c53030;
  color: rgba(197, 48, 48, var(--text-opacity));
}

.focus\:tw-text-red-800:focus {
  --text-opacity: 1;
  color: #9b2c2c;
  color: rgba(155, 44, 44, var(--text-opacity));
}

.focus\:tw-text-red-900:focus {
  --text-opacity: 1;
  color: #742a2a;
  color: rgba(116, 42, 42, var(--text-opacity));
}

.focus\:tw-text-orange-100:focus {
  --text-opacity: 1;
  color: #fffaf0;
  color: rgba(255, 250, 240, var(--text-opacity));
}

.focus\:tw-text-orange-200:focus {
  --text-opacity: 1;
  color: #feebc8;
  color: rgba(254, 235, 200, var(--text-opacity));
}

.focus\:tw-text-orange-300:focus {
  --text-opacity: 1;
  color: #fbd38d;
  color: rgba(251, 211, 141, var(--text-opacity));
}

.focus\:tw-text-orange-400:focus {
  --text-opacity: 1;
  color: #f6ad55;
  color: rgba(246, 173, 85, var(--text-opacity));
}

.focus\:tw-text-orange-500:focus {
  --text-opacity: 1;
  color: #ed8936;
  color: rgba(237, 137, 54, var(--text-opacity));
}

.focus\:tw-text-orange-600:focus {
  --text-opacity: 1;
  color: #dd6b20;
  color: rgba(221, 107, 32, var(--text-opacity));
}

.focus\:tw-text-orange-700:focus {
  --text-opacity: 1;
  color: #c05621;
  color: rgba(192, 86, 33, var(--text-opacity));
}

.focus\:tw-text-orange-800:focus {
  --text-opacity: 1;
  color: #9c4221;
  color: rgba(156, 66, 33, var(--text-opacity));
}

.focus\:tw-text-orange-900:focus {
  --text-opacity: 1;
  color: #7b341e;
  color: rgba(123, 52, 30, var(--text-opacity));
}

.focus\:tw-text-yellow-100:focus {
  --text-opacity: 1;
  color: #fffff0;
  color: rgba(255, 255, 240, var(--text-opacity));
}

.focus\:tw-text-yellow-200:focus {
  --text-opacity: 1;
  color: #fefcbf;
  color: rgba(254, 252, 191, var(--text-opacity));
}

.focus\:tw-text-yellow-300:focus {
  --text-opacity: 1;
  color: #faf089;
  color: rgba(250, 240, 137, var(--text-opacity));
}

.focus\:tw-text-yellow-400:focus {
  --text-opacity: 1;
  color: #f6e05e;
  color: rgba(246, 224, 94, var(--text-opacity));
}

.focus\:tw-text-yellow-500:focus {
  --text-opacity: 1;
  color: #ecc94b;
  color: rgba(236, 201, 75, var(--text-opacity));
}

.focus\:tw-text-yellow-600:focus {
  --text-opacity: 1;
  color: #d69e2e;
  color: rgba(214, 158, 46, var(--text-opacity));
}

.focus\:tw-text-yellow-700:focus {
  --text-opacity: 1;
  color: #b7791f;
  color: rgba(183, 121, 31, var(--text-opacity));
}

.focus\:tw-text-yellow-800:focus {
  --text-opacity: 1;
  color: #975a16;
  color: rgba(151, 90, 22, var(--text-opacity));
}

.focus\:tw-text-yellow-900:focus {
  --text-opacity: 1;
  color: #744210;
  color: rgba(116, 66, 16, var(--text-opacity));
}

.focus\:tw-text-green-100:focus {
  --text-opacity: 1;
  color: #f0fff4;
  color: rgba(240, 255, 244, var(--text-opacity));
}

.focus\:tw-text-green-200:focus {
  --text-opacity: 1;
  color: #c6f6d5;
  color: rgba(198, 246, 213, var(--text-opacity));
}

.focus\:tw-text-green-300:focus {
  --text-opacity: 1;
  color: #9ae6b4;
  color: rgba(154, 230, 180, var(--text-opacity));
}

.focus\:tw-text-green-400:focus {
  --text-opacity: 1;
  color: #68d391;
  color: rgba(104, 211, 145, var(--text-opacity));
}

.focus\:tw-text-green-500:focus {
  --text-opacity: 1;
  color: #48bb78;
  color: rgba(72, 187, 120, var(--text-opacity));
}

.focus\:tw-text-green-600:focus {
  --text-opacity: 1;
  color: #38a169;
  color: rgba(56, 161, 105, var(--text-opacity));
}

.focus\:tw-text-green-700:focus {
  --text-opacity: 1;
  color: #2f855a;
  color: rgba(47, 133, 90, var(--text-opacity));
}

.focus\:tw-text-green-800:focus {
  --text-opacity: 1;
  color: #276749;
  color: rgba(39, 103, 73, var(--text-opacity));
}

.focus\:tw-text-green-900:focus {
  --text-opacity: 1;
  color: #22543d;
  color: rgba(34, 84, 61, var(--text-opacity));
}

.focus\:tw-text-teal-100:focus {
  --text-opacity: 1;
  color: #e6fffa;
  color: rgba(230, 255, 250, var(--text-opacity));
}

.focus\:tw-text-teal-200:focus {
  --text-opacity: 1;
  color: #b2f5ea;
  color: rgba(178, 245, 234, var(--text-opacity));
}

.focus\:tw-text-teal-300:focus {
  --text-opacity: 1;
  color: #81e6d9;
  color: rgba(129, 230, 217, var(--text-opacity));
}

.focus\:tw-text-teal-400:focus {
  --text-opacity: 1;
  color: #4fd1c5;
  color: rgba(79, 209, 197, var(--text-opacity));
}

.focus\:tw-text-teal-500:focus {
  --text-opacity: 1;
  color: #38b2ac;
  color: rgba(56, 178, 172, var(--text-opacity));
}

.focus\:tw-text-teal-600:focus {
  --text-opacity: 1;
  color: #319795;
  color: rgba(49, 151, 149, var(--text-opacity));
}

.focus\:tw-text-teal-700:focus {
  --text-opacity: 1;
  color: #2c7a7b;
  color: rgba(44, 122, 123, var(--text-opacity));
}

.focus\:tw-text-teal-800:focus {
  --text-opacity: 1;
  color: #285e61;
  color: rgba(40, 94, 97, var(--text-opacity));
}

.focus\:tw-text-teal-900:focus {
  --text-opacity: 1;
  color: #234e52;
  color: rgba(35, 78, 82, var(--text-opacity));
}

.focus\:tw-text-blue-100:focus {
  --text-opacity: 1;
  color: #ebf8ff;
  color: rgba(235, 248, 255, var(--text-opacity));
}

.focus\:tw-text-blue-200:focus {
  --text-opacity: 1;
  color: #bee3f8;
  color: rgba(190, 227, 248, var(--text-opacity));
}

.focus\:tw-text-blue-300:focus {
  --text-opacity: 1;
  color: #90cdf4;
  color: rgba(144, 205, 244, var(--text-opacity));
}

.focus\:tw-text-blue-400:focus {
  --text-opacity: 1;
  color: #63b3ed;
  color: rgba(99, 179, 237, var(--text-opacity));
}

.focus\:tw-text-blue-500:focus {
  --text-opacity: 1;
  color: #4299e1;
  color: rgba(66, 153, 225, var(--text-opacity));
}

.focus\:tw-text-blue-600:focus {
  --text-opacity: 1;
  color: #3182ce;
  color: rgba(49, 130, 206, var(--text-opacity));
}

.focus\:tw-text-blue-700:focus {
  --text-opacity: 1;
  color: #2b6cb0;
  color: rgba(43, 108, 176, var(--text-opacity));
}

.focus\:tw-text-blue-800:focus {
  --text-opacity: 1;
  color: #2c5282;
  color: rgba(44, 82, 130, var(--text-opacity));
}

.focus\:tw-text-blue-900:focus {
  --text-opacity: 1;
  color: #2a4365;
  color: rgba(42, 67, 101, var(--text-opacity));
}

.focus\:tw-text-indigo-100:focus {
  --text-opacity: 1;
  color: #ebf4ff;
  color: rgba(235, 244, 255, var(--text-opacity));
}

.focus\:tw-text-indigo-200:focus {
  --text-opacity: 1;
  color: #c3dafe;
  color: rgba(195, 218, 254, var(--text-opacity));
}

.focus\:tw-text-indigo-300:focus {
  --text-opacity: 1;
  color: #a3bffa;
  color: rgba(163, 191, 250, var(--text-opacity));
}

.focus\:tw-text-indigo-400:focus {
  --text-opacity: 1;
  color: #7f9cf5;
  color: rgba(127, 156, 245, var(--text-opacity));
}

.focus\:tw-text-indigo-500:focus {
  --text-opacity: 1;
  color: #667eea;
  color: rgba(102, 126, 234, var(--text-opacity));
}

.focus\:tw-text-indigo-600:focus {
  --text-opacity: 1;
  color: #5a67d8;
  color: rgba(90, 103, 216, var(--text-opacity));
}

.focus\:tw-text-indigo-700:focus {
  --text-opacity: 1;
  color: #4c51bf;
  color: rgba(76, 81, 191, var(--text-opacity));
}

.focus\:tw-text-indigo-800:focus {
  --text-opacity: 1;
  color: #434190;
  color: rgba(67, 65, 144, var(--text-opacity));
}

.focus\:tw-text-indigo-900:focus {
  --text-opacity: 1;
  color: #3c366b;
  color: rgba(60, 54, 107, var(--text-opacity));
}

.focus\:tw-text-purple-100:focus {
  --text-opacity: 1;
  color: #faf5ff;
  color: rgba(250, 245, 255, var(--text-opacity));
}

.focus\:tw-text-purple-200:focus {
  --text-opacity: 1;
  color: #e9d8fd;
  color: rgba(233, 216, 253, var(--text-opacity));
}

.focus\:tw-text-purple-300:focus {
  --text-opacity: 1;
  color: #d6bcfa;
  color: rgba(214, 188, 250, var(--text-opacity));
}

.focus\:tw-text-purple-400:focus {
  --text-opacity: 1;
  color: #b794f4;
  color: rgba(183, 148, 244, var(--text-opacity));
}

.focus\:tw-text-purple-500:focus {
  --text-opacity: 1;
  color: #9f7aea;
  color: rgba(159, 122, 234, var(--text-opacity));
}

.focus\:tw-text-purple-600:focus {
  --text-opacity: 1;
  color: #805ad5;
  color: rgba(128, 90, 213, var(--text-opacity));
}

.focus\:tw-text-purple-700:focus {
  --text-opacity: 1;
  color: #6b46c1;
  color: rgba(107, 70, 193, var(--text-opacity));
}

.focus\:tw-text-purple-800:focus {
  --text-opacity: 1;
  color: #553c9a;
  color: rgba(85, 60, 154, var(--text-opacity));
}

.focus\:tw-text-purple-900:focus {
  --text-opacity: 1;
  color: #44337a;
  color: rgba(68, 51, 122, var(--text-opacity));
}

.focus\:tw-text-pink-100:focus {
  --text-opacity: 1;
  color: #fff5f7;
  color: rgba(255, 245, 247, var(--text-opacity));
}

.focus\:tw-text-pink-200:focus {
  --text-opacity: 1;
  color: #fed7e2;
  color: rgba(254, 215, 226, var(--text-opacity));
}

.focus\:tw-text-pink-300:focus {
  --text-opacity: 1;
  color: #fbb6ce;
  color: rgba(251, 182, 206, var(--text-opacity));
}

.focus\:tw-text-pink-400:focus {
  --text-opacity: 1;
  color: #f687b3;
  color: rgba(246, 135, 179, var(--text-opacity));
}

.focus\:tw-text-pink-500:focus {
  --text-opacity: 1;
  color: #ed64a6;
  color: rgba(237, 100, 166, var(--text-opacity));
}

.focus\:tw-text-pink-600:focus {
  --text-opacity: 1;
  color: #d53f8c;
  color: rgba(213, 63, 140, var(--text-opacity));
}

.focus\:tw-text-pink-700:focus {
  --text-opacity: 1;
  color: #b83280;
  color: rgba(184, 50, 128, var(--text-opacity));
}

.focus\:tw-text-pink-800:focus {
  --text-opacity: 1;
  color: #97266d;
  color: rgba(151, 38, 109, var(--text-opacity));
}

.focus\:tw-text-pink-900:focus {
  --text-opacity: 1;
  color: #702459;
  color: rgba(112, 36, 89, var(--text-opacity));
}

.tw-text-opacity-0 {
  --text-opacity: 0;
}

.tw-text-opacity-25 {
  --text-opacity: 0.25;
}

.tw-text-opacity-50 {
  --text-opacity: 0.5;
}

.tw-text-opacity-75 {
  --text-opacity: 0.75;
}

.tw-text-opacity-100 {
  --text-opacity: 1;
}

.hover\:tw-text-opacity-0:hover {
  --text-opacity: 0;
}

.hover\:tw-text-opacity-25:hover {
  --text-opacity: 0.25;
}

.hover\:tw-text-opacity-50:hover {
  --text-opacity: 0.5;
}

.hover\:tw-text-opacity-75:hover {
  --text-opacity: 0.75;
}

.hover\:tw-text-opacity-100:hover {
  --text-opacity: 1;
}

.focus\:tw-text-opacity-0:focus {
  --text-opacity: 0;
}

.focus\:tw-text-opacity-25:focus {
  --text-opacity: 0.25;
}

.focus\:tw-text-opacity-50:focus {
  --text-opacity: 0.5;
}

.focus\:tw-text-opacity-75:focus {
  --text-opacity: 0.75;
}

.focus\:tw-text-opacity-100:focus {
  --text-opacity: 1;
}

.tw-italic {
  font-style: italic;
}

.tw-not-italic {
  font-style: normal;
}

.tw-uppercase {
  text-transform: uppercase;
}

.tw-lowercase {
  text-transform: lowercase;
}

.tw-capitalize {
  text-transform: capitalize;
}

.tw-normal-case {
  text-transform: none;
}

.tw-underline {
  text-decoration: underline;
}

.tw-line-through {
  text-decoration: line-through;
}

.tw-no-underline {
  text-decoration: none;
}

.hover\:tw-underline:hover {
  text-decoration: underline;
}

.hover\:tw-line-through:hover {
  text-decoration: line-through;
}

.hover\:tw-no-underline:hover {
  text-decoration: none;
}

.focus\:tw-underline:focus {
  text-decoration: underline;
}

.focus\:tw-line-through:focus {
  text-decoration: line-through;
}

.focus\:tw-no-underline:focus {
  text-decoration: none;
}

.tw-antialiased {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.tw-subpixel-antialiased {
  -webkit-font-smoothing: auto;
  -moz-osx-font-smoothing: auto;
}

.tw-ordinal, .tw-slashed-zero, .tw-lining-nums, .tw-oldstyle-nums, .tw-proportional-nums, .tw-tabular-nums, .tw-diagonal-fractions, .tw-stacked-fractions {
  --font-variant-numeric-ordinal: var(--tailwind-empty,/*!*/ /*!*/);
  --font-variant-numeric-slashed-zero: var(--tailwind-empty,/*!*/ /*!*/);
  --font-variant-numeric-figure: var(--tailwind-empty,/*!*/ /*!*/);
  --font-variant-numeric-spacing: var(--tailwind-empty,/*!*/ /*!*/);
  --font-variant-numeric-fraction: var(--tailwind-empty,/*!*/ /*!*/);
  font-variant-numeric: var(--font-variant-numeric-ordinal) var(--font-variant-numeric-slashed-zero) var(--font-variant-numeric-figure) var(--font-variant-numeric-spacing) var(--font-variant-numeric-fraction);
}

.tw-normal-nums {
  font-variant-numeric: normal;
}

.tw-ordinal {
  --font-variant-numeric-ordinal: ordinal;
}

.tw-slashed-zero {
  --font-variant-numeric-slashed-zero: slashed-zero;
}

.tw-lining-nums {
  --font-variant-numeric-figure: lining-nums;
}

.tw-oldstyle-nums {
  --font-variant-numeric-figure: oldstyle-nums;
}

.tw-proportional-nums {
  --font-variant-numeric-spacing: proportional-nums;
}

.tw-tabular-nums {
  --font-variant-numeric-spacing: tabular-nums;
}

.tw-diagonal-fractions {
  --font-variant-numeric-fraction: diagonal-fractions;
}

.tw-stacked-fractions {
  --font-variant-numeric-fraction: stacked-fractions;
}

.tw-tracking-tighter {
  letter-spacing: -0.05em;
}

.tw-tracking-tight {
  letter-spacing: -0.025em;
}

.tw-tracking-normal {
  letter-spacing: 0;
}

.tw-tracking-wide {
  letter-spacing: 0.025em;
}

.tw-tracking-wider {
  letter-spacing: 0.05em;
}

.tw-tracking-widest {
  letter-spacing: 0.1em;
}

.tw-select-none {
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
}

.tw-select-text {
  -webkit-user-select: text;
     -moz-user-select: text;
          user-select: text;
}

.tw-select-all {
  -webkit-user-select: all;
     -moz-user-select: all;
          user-select: all;
}

.tw-select-auto {
  -webkit-user-select: auto;
     -moz-user-select: auto;
          user-select: auto;
}

.tw-align-baseline {
  vertical-align: baseline;
}

.tw-align-top {
  vertical-align: top;
}

.tw-align-middle {
  vertical-align: middle;
}

.tw-align-bottom {
  vertical-align: bottom;
}

.tw-align-text-top {
  vertical-align: text-top;
}

.tw-align-text-bottom {
  vertical-align: text-bottom;
}

.tw-visible {
  visibility: visible;
}

.tw-invisible {
  visibility: hidden;
}

.tw-whitespace-normal {
  white-space: normal;
}

.tw-whitespace-no-wrap {
  white-space: nowrap;
}

.tw-whitespace-pre {
  white-space: pre;
}

.tw-whitespace-pre-line {
  white-space: pre-line;
}

.tw-whitespace-pre-wrap {
  white-space: pre-wrap;
}

.tw-break-normal {
  word-wrap: normal;
  overflow-wrap: normal;
  word-break: normal;
}

.tw-break-words {
  word-wrap: break-word;
  overflow-wrap: break-word;
}

.tw-break-all {
  word-break: break-all;
}

.tw-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.tw-w-0 {
  width: 0;
}

.tw-w-1 {
  width: 0.25rem;
}

.tw-w-2 {
  width: 0.5rem;
}

.tw-w-3 {
  width: 0.75rem;
}

.tw-w-4 {
  width: 1rem;
}

.tw-w-5 {
  width: 1.25rem;
}

.tw-w-6 {
  width: 1.5rem;
}

.tw-w-8 {
  width: 2rem;
}

.tw-w-10 {
  width: 2.5rem;
}

.tw-w-12 {
  width: 3rem;
}

.tw-w-16 {
  width: 4rem;
}

.tw-w-20 {
  width: 5rem;
}

.tw-w-24 {
  width: 6rem;
}

.tw-w-32 {
  width: 8rem;
}

.tw-w-40 {
  width: 10rem;
}

.tw-w-48 {
  width: 12rem;
}

.tw-w-56 {
  width: 14rem;
}

.tw-w-64 {
  width: 16rem;
}

.tw-w-auto {
  width: auto;
}

.tw-w-px {
  width: 1px;
}

.tw-w-270px {
  width: 270px;
}

.tw-w-400px {
  width: 400px;
}

.tw-w-464px {
  width: 464px;
}

.tw-w-480px {
  width: 480px;
}

.tw-w-500px {
  width: 500px;
}

.tw-w-576px {
  width: 576px;
}

.tw-w-600px {
  width: 600px;
}

.tw-w-672px {
  width: 672px;
}

.tw-w-1\/2 {
  width: 50%;
}

.tw-w-1\/3 {
  width: 33.333333%;
}

.tw-w-2\/3 {
  width: 66.666667%;
}

.tw-w-1\/4 {
  width: 25%;
}

.tw-w-2\/4 {
  width: 50%;
}

.tw-w-3\/4 {
  width: 75%;
}

.tw-w-1\/5 {
  width: 20%;
}

.tw-w-2\/5 {
  width: 40%;
}

.tw-w-3\/5 {
  width: 60%;
}

.tw-w-4\/5 {
  width: 80%;
}

.tw-w-1\/6 {
  width: 16.666667%;
}

.tw-w-2\/6 {
  width: 33.333333%;
}

.tw-w-3\/6 {
  width: 50%;
}

.tw-w-4\/6 {
  width: 66.666667%;
}

.tw-w-5\/6 {
  width: 83.333333%;
}

.tw-w-1\/12 {
  width: 8.333333%;
}

.tw-w-2\/12 {
  width: 16.666667%;
}

.tw-w-3\/12 {
  width: 25%;
}

.tw-w-4\/12 {
  width: 33.333333%;
}

.tw-w-5\/12 {
  width: 41.666667%;
}

.tw-w-6\/12 {
  width: 50%;
}

.tw-w-7\/12 {
  width: 58.333333%;
}

.tw-w-8\/12 {
  width: 66.666667%;
}

.tw-w-9\/12 {
  width: 75%;
}

.tw-w-10\/12 {
  width: 83.333333%;
}

.tw-w-11\/12 {
  width: 91.666667%;
}

.tw-w-full {
  width: 100%;
}

.tw-w-screen {
  width: 100vw;
}

.tw-z-0 {
  z-index: 0;
}

.tw-z-10 {
  z-index: 10;
}

.tw-z-20 {
  z-index: 20;
}

.tw-z-30 {
  z-index: 30;
}

.tw-z-40 {
  z-index: 40;
}

.tw-z-50 {
  z-index: 50;
}

.tw-z-auto {
  z-index: auto;
}

.tw-gap-0 {
  grid-gap: 0;
  gap: 0;
}

.tw-gap-1 {
  grid-gap: 0.25rem;
  gap: 0.25rem;
}

.tw-gap-2 {
  grid-gap: 0.5rem;
  gap: 0.5rem;
}

.tw-gap-3 {
  grid-gap: 0.75rem;
  gap: 0.75rem;
}

.tw-gap-4 {
  grid-gap: 1rem;
  gap: 1rem;
}

.tw-gap-5 {
  grid-gap: 1.25rem;
  gap: 1.25rem;
}

.tw-gap-6 {
  grid-gap: 1.5rem;
  gap: 1.5rem;
}

.tw-gap-8 {
  grid-gap: 2rem;
  gap: 2rem;
}

.tw-gap-10 {
  grid-gap: 2.5rem;
  gap: 2.5rem;
}

.tw-gap-12 {
  grid-gap: 3rem;
  gap: 3rem;
}

.tw-gap-16 {
  grid-gap: 4rem;
  gap: 4rem;
}

.tw-gap-20 {
  grid-gap: 5rem;
  gap: 5rem;
}

.tw-gap-24 {
  grid-gap: 6rem;
  gap: 6rem;
}

.tw-gap-32 {
  grid-gap: 8rem;
  gap: 8rem;
}

.tw-gap-40 {
  grid-gap: 10rem;
  gap: 10rem;
}

.tw-gap-48 {
  grid-gap: 12rem;
  gap: 12rem;
}

.tw-gap-56 {
  grid-gap: 14rem;
  gap: 14rem;
}

.tw-gap-64 {
  grid-gap: 16rem;
  gap: 16rem;
}

.tw-gap-px {
  grid-gap: 1px;
  gap: 1px;
}

.tw-gap-270px {
  grid-gap: 270px;
  gap: 270px;
}

.tw-gap-400px {
  grid-gap: 400px;
  gap: 400px;
}

.tw-gap-464px {
  grid-gap: 464px;
  gap: 464px;
}

.tw-gap-480px {
  grid-gap: 480px;
  gap: 480px;
}

.tw-gap-500px {
  grid-gap: 500px;
  gap: 500px;
}

.tw-gap-576px {
  grid-gap: 576px;
  gap: 576px;
}

.tw-gap-600px {
  grid-gap: 600px;
  gap: 600px;
}

.tw-gap-672px {
  grid-gap: 672px;
  gap: 672px;
}

.tw-col-gap-0 {
  grid-column-gap: 0;
  -moz-column-gap: 0;
       column-gap: 0;
}

.tw-col-gap-1 {
  grid-column-gap: 0.25rem;
  -moz-column-gap: 0.25rem;
       column-gap: 0.25rem;
}

.tw-col-gap-2 {
  grid-column-gap: 0.5rem;
  -moz-column-gap: 0.5rem;
       column-gap: 0.5rem;
}

.tw-col-gap-3 {
  grid-column-gap: 0.75rem;
  -moz-column-gap: 0.75rem;
       column-gap: 0.75rem;
}

.tw-col-gap-4 {
  grid-column-gap: 1rem;
  -moz-column-gap: 1rem;
       column-gap: 1rem;
}

.tw-col-gap-5 {
  grid-column-gap: 1.25rem;
  -moz-column-gap: 1.25rem;
       column-gap: 1.25rem;
}

.tw-col-gap-6 {
  grid-column-gap: 1.5rem;
  -moz-column-gap: 1.5rem;
       column-gap: 1.5rem;
}

.tw-col-gap-8 {
  grid-column-gap: 2rem;
  -moz-column-gap: 2rem;
       column-gap: 2rem;
}

.tw-col-gap-10 {
  grid-column-gap: 2.5rem;
  -moz-column-gap: 2.5rem;
       column-gap: 2.5rem;
}

.tw-col-gap-12 {
  grid-column-gap: 3rem;
  -moz-column-gap: 3rem;
       column-gap: 3rem;
}

.tw-col-gap-16 {
  grid-column-gap: 4rem;
  -moz-column-gap: 4rem;
       column-gap: 4rem;
}

.tw-col-gap-20 {
  grid-column-gap: 5rem;
  -moz-column-gap: 5rem;
       column-gap: 5rem;
}

.tw-col-gap-24 {
  grid-column-gap: 6rem;
  -moz-column-gap: 6rem;
       column-gap: 6rem;
}

.tw-col-gap-32 {
  grid-column-gap: 8rem;
  -moz-column-gap: 8rem;
       column-gap: 8rem;
}

.tw-col-gap-40 {
  grid-column-gap: 10rem;
  -moz-column-gap: 10rem;
       column-gap: 10rem;
}

.tw-col-gap-48 {
  grid-column-gap: 12rem;
  -moz-column-gap: 12rem;
       column-gap: 12rem;
}

.tw-col-gap-56 {
  grid-column-gap: 14rem;
  -moz-column-gap: 14rem;
       column-gap: 14rem;
}

.tw-col-gap-64 {
  grid-column-gap: 16rem;
  -moz-column-gap: 16rem;
       column-gap: 16rem;
}

.tw-col-gap-px {
  grid-column-gap: 1px;
  -moz-column-gap: 1px;
       column-gap: 1px;
}

.tw-col-gap-270px {
  grid-column-gap: 270px;
  -moz-column-gap: 270px;
       column-gap: 270px;
}

.tw-col-gap-400px {
  grid-column-gap: 400px;
  -moz-column-gap: 400px;
       column-gap: 400px;
}

.tw-col-gap-464px {
  grid-column-gap: 464px;
  -moz-column-gap: 464px;
       column-gap: 464px;
}

.tw-col-gap-480px {
  grid-column-gap: 480px;
  -moz-column-gap: 480px;
       column-gap: 480px;
}

.tw-col-gap-500px {
  grid-column-gap: 500px;
  -moz-column-gap: 500px;
       column-gap: 500px;
}

.tw-col-gap-576px {
  grid-column-gap: 576px;
  -moz-column-gap: 576px;
       column-gap: 576px;
}

.tw-col-gap-600px {
  grid-column-gap: 600px;
  -moz-column-gap: 600px;
       column-gap: 600px;
}

.tw-col-gap-672px {
  grid-column-gap: 672px;
  -moz-column-gap: 672px;
       column-gap: 672px;
}

.tw-gap-x-0 {
  grid-column-gap: 0;
  -moz-column-gap: 0;
       column-gap: 0;
}

.tw-gap-x-1 {
  grid-column-gap: 0.25rem;
  -moz-column-gap: 0.25rem;
       column-gap: 0.25rem;
}

.tw-gap-x-2 {
  grid-column-gap: 0.5rem;
  -moz-column-gap: 0.5rem;
       column-gap: 0.5rem;
}

.tw-gap-x-3 {
  grid-column-gap: 0.75rem;
  -moz-column-gap: 0.75rem;
       column-gap: 0.75rem;
}

.tw-gap-x-4 {
  grid-column-gap: 1rem;
  -moz-column-gap: 1rem;
       column-gap: 1rem;
}

.tw-gap-x-5 {
  grid-column-gap: 1.25rem;
  -moz-column-gap: 1.25rem;
       column-gap: 1.25rem;
}

.tw-gap-x-6 {
  grid-column-gap: 1.5rem;
  -moz-column-gap: 1.5rem;
       column-gap: 1.5rem;
}

.tw-gap-x-8 {
  grid-column-gap: 2rem;
  -moz-column-gap: 2rem;
       column-gap: 2rem;
}

.tw-gap-x-10 {
  grid-column-gap: 2.5rem;
  -moz-column-gap: 2.5rem;
       column-gap: 2.5rem;
}

.tw-gap-x-12 {
  grid-column-gap: 3rem;
  -moz-column-gap: 3rem;
       column-gap: 3rem;
}

.tw-gap-x-16 {
  grid-column-gap: 4rem;
  -moz-column-gap: 4rem;
       column-gap: 4rem;
}

.tw-gap-x-20 {
  grid-column-gap: 5rem;
  -moz-column-gap: 5rem;
       column-gap: 5rem;
}

.tw-gap-x-24 {
  grid-column-gap: 6rem;
  -moz-column-gap: 6rem;
       column-gap: 6rem;
}

.tw-gap-x-32 {
  grid-column-gap: 8rem;
  -moz-column-gap: 8rem;
       column-gap: 8rem;
}

.tw-gap-x-40 {
  grid-column-gap: 10rem;
  -moz-column-gap: 10rem;
       column-gap: 10rem;
}

.tw-gap-x-48 {
  grid-column-gap: 12rem;
  -moz-column-gap: 12rem;
       column-gap: 12rem;
}

.tw-gap-x-56 {
  grid-column-gap: 14rem;
  -moz-column-gap: 14rem;
       column-gap: 14rem;
}

.tw-gap-x-64 {
  grid-column-gap: 16rem;
  -moz-column-gap: 16rem;
       column-gap: 16rem;
}

.tw-gap-x-px {
  grid-column-gap: 1px;
  -moz-column-gap: 1px;
       column-gap: 1px;
}

.tw-gap-x-270px {
  grid-column-gap: 270px;
  -moz-column-gap: 270px;
       column-gap: 270px;
}

.tw-gap-x-400px {
  grid-column-gap: 400px;
  -moz-column-gap: 400px;
       column-gap: 400px;
}

.tw-gap-x-464px {
  grid-column-gap: 464px;
  -moz-column-gap: 464px;
       column-gap: 464px;
}

.tw-gap-x-480px {
  grid-column-gap: 480px;
  -moz-column-gap: 480px;
       column-gap: 480px;
}

.tw-gap-x-500px {
  grid-column-gap: 500px;
  -moz-column-gap: 500px;
       column-gap: 500px;
}

.tw-gap-x-576px {
  grid-column-gap: 576px;
  -moz-column-gap: 576px;
       column-gap: 576px;
}

.tw-gap-x-600px {
  grid-column-gap: 600px;
  -moz-column-gap: 600px;
       column-gap: 600px;
}

.tw-gap-x-672px {
  grid-column-gap: 672px;
  -moz-column-gap: 672px;
       column-gap: 672px;
}

.tw-row-gap-0 {
  grid-row-gap: 0;
  row-gap: 0;
}

.tw-row-gap-1 {
  grid-row-gap: 0.25rem;
  row-gap: 0.25rem;
}

.tw-row-gap-2 {
  grid-row-gap: 0.5rem;
  row-gap: 0.5rem;
}

.tw-row-gap-3 {
  grid-row-gap: 0.75rem;
  row-gap: 0.75rem;
}

.tw-row-gap-4 {
  grid-row-gap: 1rem;
  row-gap: 1rem;
}

.tw-row-gap-5 {
  grid-row-gap: 1.25rem;
  row-gap: 1.25rem;
}

.tw-row-gap-6 {
  grid-row-gap: 1.5rem;
  row-gap: 1.5rem;
}

.tw-row-gap-8 {
  grid-row-gap: 2rem;
  row-gap: 2rem;
}

.tw-row-gap-10 {
  grid-row-gap: 2.5rem;
  row-gap: 2.5rem;
}

.tw-row-gap-12 {
  grid-row-gap: 3rem;
  row-gap: 3rem;
}

.tw-row-gap-16 {
  grid-row-gap: 4rem;
  row-gap: 4rem;
}

.tw-row-gap-20 {
  grid-row-gap: 5rem;
  row-gap: 5rem;
}

.tw-row-gap-24 {
  grid-row-gap: 6rem;
  row-gap: 6rem;
}

.tw-row-gap-32 {
  grid-row-gap: 8rem;
  row-gap: 8rem;
}

.tw-row-gap-40 {
  grid-row-gap: 10rem;
  row-gap: 10rem;
}

.tw-row-gap-48 {
  grid-row-gap: 12rem;
  row-gap: 12rem;
}

.tw-row-gap-56 {
  grid-row-gap: 14rem;
  row-gap: 14rem;
}

.tw-row-gap-64 {
  grid-row-gap: 16rem;
  row-gap: 16rem;
}

.tw-row-gap-px {
  grid-row-gap: 1px;
  row-gap: 1px;
}

.tw-row-gap-270px {
  grid-row-gap: 270px;
  row-gap: 270px;
}

.tw-row-gap-400px {
  grid-row-gap: 400px;
  row-gap: 400px;
}

.tw-row-gap-464px {
  grid-row-gap: 464px;
  row-gap: 464px;
}

.tw-row-gap-480px {
  grid-row-gap: 480px;
  row-gap: 480px;
}

.tw-row-gap-500px {
  grid-row-gap: 500px;
  row-gap: 500px;
}

.tw-row-gap-576px {
  grid-row-gap: 576px;
  row-gap: 576px;
}

.tw-row-gap-600px {
  grid-row-gap: 600px;
  row-gap: 600px;
}

.tw-row-gap-672px {
  grid-row-gap: 672px;
  row-gap: 672px;
}

.tw-gap-y-0 {
  grid-row-gap: 0;
  row-gap: 0;
}

.tw-gap-y-1 {
  grid-row-gap: 0.25rem;
  row-gap: 0.25rem;
}

.tw-gap-y-2 {
  grid-row-gap: 0.5rem;
  row-gap: 0.5rem;
}

.tw-gap-y-3 {
  grid-row-gap: 0.75rem;
  row-gap: 0.75rem;
}

.tw-gap-y-4 {
  grid-row-gap: 1rem;
  row-gap: 1rem;
}

.tw-gap-y-5 {
  grid-row-gap: 1.25rem;
  row-gap: 1.25rem;
}

.tw-gap-y-6 {
  grid-row-gap: 1.5rem;
  row-gap: 1.5rem;
}

.tw-gap-y-8 {
  grid-row-gap: 2rem;
  row-gap: 2rem;
}

.tw-gap-y-10 {
  grid-row-gap: 2.5rem;
  row-gap: 2.5rem;
}

.tw-gap-y-12 {
  grid-row-gap: 3rem;
  row-gap: 3rem;
}

.tw-gap-y-16 {
  grid-row-gap: 4rem;
  row-gap: 4rem;
}

.tw-gap-y-20 {
  grid-row-gap: 5rem;
  row-gap: 5rem;
}

.tw-gap-y-24 {
  grid-row-gap: 6rem;
  row-gap: 6rem;
}

.tw-gap-y-32 {
  grid-row-gap: 8rem;
  row-gap: 8rem;
}

.tw-gap-y-40 {
  grid-row-gap: 10rem;
  row-gap: 10rem;
}

.tw-gap-y-48 {
  grid-row-gap: 12rem;
  row-gap: 12rem;
}

.tw-gap-y-56 {
  grid-row-gap: 14rem;
  row-gap: 14rem;
}

.tw-gap-y-64 {
  grid-row-gap: 16rem;
  row-gap: 16rem;
}

.tw-gap-y-px {
  grid-row-gap: 1px;
  row-gap: 1px;
}

.tw-gap-y-270px {
  grid-row-gap: 270px;
  row-gap: 270px;
}

.tw-gap-y-400px {
  grid-row-gap: 400px;
  row-gap: 400px;
}

.tw-gap-y-464px {
  grid-row-gap: 464px;
  row-gap: 464px;
}

.tw-gap-y-480px {
  grid-row-gap: 480px;
  row-gap: 480px;
}

.tw-gap-y-500px {
  grid-row-gap: 500px;
  row-gap: 500px;
}

.tw-gap-y-576px {
  grid-row-gap: 576px;
  row-gap: 576px;
}

.tw-gap-y-600px {
  grid-row-gap: 600px;
  row-gap: 600px;
}

.tw-gap-y-672px {
  grid-row-gap: 672px;
  row-gap: 672px;
}

.tw-grid-flow-row {
  grid-auto-flow: row;
}

.tw-grid-flow-col {
  grid-auto-flow: column;
}

.tw-grid-flow-row-dense {
  grid-auto-flow: row dense;
}

.tw-grid-flow-col-dense {
  grid-auto-flow: column dense;
}

.tw-grid-cols-1 {
  grid-template-columns: repeat(1, minmax(0, 1fr));
}

.tw-grid-cols-2 {
  grid-template-columns: repeat(2, minmax(0, 1fr));
}

.tw-grid-cols-3 {
  grid-template-columns: repeat(3, minmax(0, 1fr));
}

.tw-grid-cols-4 {
  grid-template-columns: repeat(4, minmax(0, 1fr));
}

.tw-grid-cols-5 {
  grid-template-columns: repeat(5, minmax(0, 1fr));
}

.tw-grid-cols-6 {
  grid-template-columns: repeat(6, minmax(0, 1fr));
}

.tw-grid-cols-7 {
  grid-template-columns: repeat(7, minmax(0, 1fr));
}

.tw-grid-cols-8 {
  grid-template-columns: repeat(8, minmax(0, 1fr));
}

.tw-grid-cols-9 {
  grid-template-columns: repeat(9, minmax(0, 1fr));
}

.tw-grid-cols-10 {
  grid-template-columns: repeat(10, minmax(0, 1fr));
}

.tw-grid-cols-11 {
  grid-template-columns: repeat(11, minmax(0, 1fr));
}

.tw-grid-cols-12 {
  grid-template-columns: repeat(12, minmax(0, 1fr));
}

.tw-grid-cols-none {
  grid-template-columns: none;
}

.tw-auto-cols-auto {
  grid-auto-columns: auto;
}

.tw-auto-cols-min {
  grid-auto-columns: min-content;
}

.tw-auto-cols-max {
  grid-auto-columns: max-content;
}

.tw-auto-cols-fr {
  grid-auto-columns: minmax(0, 1fr);
}

.tw-col-auto {
  grid-column: auto;
}

.tw-col-span-1 {
  grid-column: span 1 / span 1;
}

.tw-col-span-2 {
  grid-column: span 2 / span 2;
}

.tw-col-span-3 {
  grid-column: span 3 / span 3;
}

.tw-col-span-4 {
  grid-column: span 4 / span 4;
}

.tw-col-span-5 {
  grid-column: span 5 / span 5;
}

.tw-col-span-6 {
  grid-column: span 6 / span 6;
}

.tw-col-span-7 {
  grid-column: span 7 / span 7;
}

.tw-col-span-8 {
  grid-column: span 8 / span 8;
}

.tw-col-span-9 {
  grid-column: span 9 / span 9;
}

.tw-col-span-10 {
  grid-column: span 10 / span 10;
}

.tw-col-span-11 {
  grid-column: span 11 / span 11;
}

.tw-col-span-12 {
  grid-column: span 12 / span 12;
}

.tw-col-span-full {
  grid-column: 1 / -1;
}

.tw-col-start-1 {
  grid-column-start: 1;
}

.tw-col-start-2 {
  grid-column-start: 2;
}

.tw-col-start-3 {
  grid-column-start: 3;
}

.tw-col-start-4 {
  grid-column-start: 4;
}

.tw-col-start-5 {
  grid-column-start: 5;
}

.tw-col-start-6 {
  grid-column-start: 6;
}

.tw-col-start-7 {
  grid-column-start: 7;
}

.tw-col-start-8 {
  grid-column-start: 8;
}

.tw-col-start-9 {
  grid-column-start: 9;
}

.tw-col-start-10 {
  grid-column-start: 10;
}

.tw-col-start-11 {
  grid-column-start: 11;
}

.tw-col-start-12 {
  grid-column-start: 12;
}

.tw-col-start-13 {
  grid-column-start: 13;
}

.tw-col-start-auto {
  grid-column-start: auto;
}

.tw-col-end-1 {
  grid-column-end: 1;
}

.tw-col-end-2 {
  grid-column-end: 2;
}

.tw-col-end-3 {
  grid-column-end: 3;
}

.tw-col-end-4 {
  grid-column-end: 4;
}

.tw-col-end-5 {
  grid-column-end: 5;
}

.tw-col-end-6 {
  grid-column-end: 6;
}

.tw-col-end-7 {
  grid-column-end: 7;
}

.tw-col-end-8 {
  grid-column-end: 8;
}

.tw-col-end-9 {
  grid-column-end: 9;
}

.tw-col-end-10 {
  grid-column-end: 10;
}

.tw-col-end-11 {
  grid-column-end: 11;
}

.tw-col-end-12 {
  grid-column-end: 12;
}

.tw-col-end-13 {
  grid-column-end: 13;
}

.tw-col-end-auto {
  grid-column-end: auto;
}

.tw-grid-rows-1 {
  grid-template-rows: repeat(1, minmax(0, 1fr));
}

.tw-grid-rows-2 {
  grid-template-rows: repeat(2, minmax(0, 1fr));
}

.tw-grid-rows-3 {
  grid-template-rows: repeat(3, minmax(0, 1fr));
}

.tw-grid-rows-4 {
  grid-template-rows: repeat(4, minmax(0, 1fr));
}

.tw-grid-rows-5 {
  grid-template-rows: repeat(5, minmax(0, 1fr));
}

.tw-grid-rows-6 {
  grid-template-rows: repeat(6, minmax(0, 1fr));
}

.tw-grid-rows-none {
  grid-template-rows: none;
}

.tw-auto-rows-auto {
  grid-auto-rows: auto;
}

.tw-auto-rows-min {
  grid-auto-rows: min-content;
}

.tw-auto-rows-max {
  grid-auto-rows: max-content;
}

.tw-auto-rows-fr {
  grid-auto-rows: minmax(0, 1fr);
}

.tw-row-auto {
  grid-row: auto;
}

.tw-row-span-1 {
  grid-row: span 1 / span 1;
}

.tw-row-span-2 {
  grid-row: span 2 / span 2;
}

.tw-row-span-3 {
  grid-row: span 3 / span 3;
}

.tw-row-span-4 {
  grid-row: span 4 / span 4;
}

.tw-row-span-5 {
  grid-row: span 5 / span 5;
}

.tw-row-span-6 {
  grid-row: span 6 / span 6;
}

.tw-row-span-full {
  grid-row: 1 / -1;
}

.tw-row-start-1 {
  grid-row-start: 1;
}

.tw-row-start-2 {
  grid-row-start: 2;
}

.tw-row-start-3 {
  grid-row-start: 3;
}

.tw-row-start-4 {
  grid-row-start: 4;
}

.tw-row-start-5 {
  grid-row-start: 5;
}

.tw-row-start-6 {
  grid-row-start: 6;
}

.tw-row-start-7 {
  grid-row-start: 7;
}

.tw-row-start-auto {
  grid-row-start: auto;
}

.tw-row-end-1 {
  grid-row-end: 1;
}

.tw-row-end-2 {
  grid-row-end: 2;
}

.tw-row-end-3 {
  grid-row-end: 3;
}

.tw-row-end-4 {
  grid-row-end: 4;
}

.tw-row-end-5 {
  grid-row-end: 5;
}

.tw-row-end-6 {
  grid-row-end: 6;
}

.tw-row-end-7 {
  grid-row-end: 7;
}

.tw-row-end-auto {
  grid-row-end: auto;
}

.tw-transform {
  --transform-translate-x: 0;
  --transform-translate-y: 0;
  --transform-rotate: 0;
  --transform-skew-x: 0;
  --transform-skew-y: 0;
  --transform-scale-x: 1;
  --transform-scale-y: 1;
  transform: translateX(var(--transform-translate-x)) translateY(var(--transform-translate-y)) rotate(var(--transform-rotate)) skewX(var(--transform-skew-x)) skewY(var(--transform-skew-y)) scaleX(var(--transform-scale-x)) scaleY(var(--transform-scale-y));
}

.tw-transform-none {
  transform: none;
}

.tw-origin-center {
  transform-origin: center;
}

.tw-origin-top {
  transform-origin: top;
}

.tw-origin-top-right {
  transform-origin: top right;
}

.tw-origin-right {
  transform-origin: right;
}

.tw-origin-bottom-right {
  transform-origin: bottom right;
}

.tw-origin-bottom {
  transform-origin: bottom;
}

.tw-origin-bottom-left {
  transform-origin: bottom left;
}

.tw-origin-left {
  transform-origin: left;
}

.tw-origin-top-left {
  transform-origin: top left;
}

.tw-scale-0 {
  --transform-scale-x: 0;
  --transform-scale-y: 0;
}

.tw-scale-50 {
  --transform-scale-x: .5;
  --transform-scale-y: .5;
}

.tw-scale-75 {
  --transform-scale-x: .75;
  --transform-scale-y: .75;
}

.tw-scale-90 {
  --transform-scale-x: .9;
  --transform-scale-y: .9;
}

.tw-scale-95 {
  --transform-scale-x: .95;
  --transform-scale-y: .95;
}

.tw-scale-100 {
  --transform-scale-x: 1;
  --transform-scale-y: 1;
}

.tw-scale-105 {
  --transform-scale-x: 1.05;
  --transform-scale-y: 1.05;
}

.tw-scale-110 {
  --transform-scale-x: 1.1;
  --transform-scale-y: 1.1;
}

.tw-scale-125 {
  --transform-scale-x: 1.25;
  --transform-scale-y: 1.25;
}

.tw-scale-150 {
  --transform-scale-x: 1.5;
  --transform-scale-y: 1.5;
}

.tw-scale-x-0 {
  --transform-scale-x: 0;
}

.tw-scale-x-50 {
  --transform-scale-x: .5;
}

.tw-scale-x-75 {
  --transform-scale-x: .75;
}

.tw-scale-x-90 {
  --transform-scale-x: .9;
}

.tw-scale-x-95 {
  --transform-scale-x: .95;
}

.tw-scale-x-100 {
  --transform-scale-x: 1;
}

.tw-scale-x-105 {
  --transform-scale-x: 1.05;
}

.tw-scale-x-110 {
  --transform-scale-x: 1.1;
}

.tw-scale-x-125 {
  --transform-scale-x: 1.25;
}

.tw-scale-x-150 {
  --transform-scale-x: 1.5;
}

.tw-scale-y-0 {
  --transform-scale-y: 0;
}

.tw-scale-y-50 {
  --transform-scale-y: .5;
}

.tw-scale-y-75 {
  --transform-scale-y: .75;
}

.tw-scale-y-90 {
  --transform-scale-y: .9;
}

.tw-scale-y-95 {
  --transform-scale-y: .95;
}

.tw-scale-y-100 {
  --transform-scale-y: 1;
}

.tw-scale-y-105 {
  --transform-scale-y: 1.05;
}

.tw-scale-y-110 {
  --transform-scale-y: 1.1;
}

.tw-scale-y-125 {
  --transform-scale-y: 1.25;
}

.tw-scale-y-150 {
  --transform-scale-y: 1.5;
}

.hover\:tw-scale-0:hover {
  --transform-scale-x: 0;
  --transform-scale-y: 0;
}

.hover\:tw-scale-50:hover {
  --transform-scale-x: .5;
  --transform-scale-y: .5;
}

.hover\:tw-scale-75:hover {
  --transform-scale-x: .75;
  --transform-scale-y: .75;
}

.hover\:tw-scale-90:hover {
  --transform-scale-x: .9;
  --transform-scale-y: .9;
}

.hover\:tw-scale-95:hover {
  --transform-scale-x: .95;
  --transform-scale-y: .95;
}

.hover\:tw-scale-100:hover {
  --transform-scale-x: 1;
  --transform-scale-y: 1;
}

.hover\:tw-scale-105:hover {
  --transform-scale-x: 1.05;
  --transform-scale-y: 1.05;
}

.hover\:tw-scale-110:hover {
  --transform-scale-x: 1.1;
  --transform-scale-y: 1.1;
}

.hover\:tw-scale-125:hover {
  --transform-scale-x: 1.25;
  --transform-scale-y: 1.25;
}

.hover\:tw-scale-150:hover {
  --transform-scale-x: 1.5;
  --transform-scale-y: 1.5;
}

.hover\:tw-scale-x-0:hover {
  --transform-scale-x: 0;
}

.hover\:tw-scale-x-50:hover {
  --transform-scale-x: .5;
}

.hover\:tw-scale-x-75:hover {
  --transform-scale-x: .75;
}

.hover\:tw-scale-x-90:hover {
  --transform-scale-x: .9;
}

.hover\:tw-scale-x-95:hover {
  --transform-scale-x: .95;
}

.hover\:tw-scale-x-100:hover {
  --transform-scale-x: 1;
}

.hover\:tw-scale-x-105:hover {
  --transform-scale-x: 1.05;
}

.hover\:tw-scale-x-110:hover {
  --transform-scale-x: 1.1;
}

.hover\:tw-scale-x-125:hover {
  --transform-scale-x: 1.25;
}

.hover\:tw-scale-x-150:hover {
  --transform-scale-x: 1.5;
}

.hover\:tw-scale-y-0:hover {
  --transform-scale-y: 0;
}

.hover\:tw-scale-y-50:hover {
  --transform-scale-y: .5;
}

.hover\:tw-scale-y-75:hover {
  --transform-scale-y: .75;
}

.hover\:tw-scale-y-90:hover {
  --transform-scale-y: .9;
}

.hover\:tw-scale-y-95:hover {
  --transform-scale-y: .95;
}

.hover\:tw-scale-y-100:hover {
  --transform-scale-y: 1;
}

.hover\:tw-scale-y-105:hover {
  --transform-scale-y: 1.05;
}

.hover\:tw-scale-y-110:hover {
  --transform-scale-y: 1.1;
}

.hover\:tw-scale-y-125:hover {
  --transform-scale-y: 1.25;
}

.hover\:tw-scale-y-150:hover {
  --transform-scale-y: 1.5;
}

.focus\:tw-scale-0:focus {
  --transform-scale-x: 0;
  --transform-scale-y: 0;
}

.focus\:tw-scale-50:focus {
  --transform-scale-x: .5;
  --transform-scale-y: .5;
}

.focus\:tw-scale-75:focus {
  --transform-scale-x: .75;
  --transform-scale-y: .75;
}

.focus\:tw-scale-90:focus {
  --transform-scale-x: .9;
  --transform-scale-y: .9;
}

.focus\:tw-scale-95:focus {
  --transform-scale-x: .95;
  --transform-scale-y: .95;
}

.focus\:tw-scale-100:focus {
  --transform-scale-x: 1;
  --transform-scale-y: 1;
}

.focus\:tw-scale-105:focus {
  --transform-scale-x: 1.05;
  --transform-scale-y: 1.05;
}

.focus\:tw-scale-110:focus {
  --transform-scale-x: 1.1;
  --transform-scale-y: 1.1;
}

.focus\:tw-scale-125:focus {
  --transform-scale-x: 1.25;
  --transform-scale-y: 1.25;
}

.focus\:tw-scale-150:focus {
  --transform-scale-x: 1.5;
  --transform-scale-y: 1.5;
}

.focus\:tw-scale-x-0:focus {
  --transform-scale-x: 0;
}

.focus\:tw-scale-x-50:focus {
  --transform-scale-x: .5;
}

.focus\:tw-scale-x-75:focus {
  --transform-scale-x: .75;
}

.focus\:tw-scale-x-90:focus {
  --transform-scale-x: .9;
}

.focus\:tw-scale-x-95:focus {
  --transform-scale-x: .95;
}

.focus\:tw-scale-x-100:focus {
  --transform-scale-x: 1;
}

.focus\:tw-scale-x-105:focus {
  --transform-scale-x: 1.05;
}

.focus\:tw-scale-x-110:focus {
  --transform-scale-x: 1.1;
}

.focus\:tw-scale-x-125:focus {
  --transform-scale-x: 1.25;
}

.focus\:tw-scale-x-150:focus {
  --transform-scale-x: 1.5;
}

.focus\:tw-scale-y-0:focus {
  --transform-scale-y: 0;
}

.focus\:tw-scale-y-50:focus {
  --transform-scale-y: .5;
}

.focus\:tw-scale-y-75:focus {
  --transform-scale-y: .75;
}

.focus\:tw-scale-y-90:focus {
  --transform-scale-y: .9;
}

.focus\:tw-scale-y-95:focus {
  --transform-scale-y: .95;
}

.focus\:tw-scale-y-100:focus {
  --transform-scale-y: 1;
}

.focus\:tw-scale-y-105:focus {
  --transform-scale-y: 1.05;
}

.focus\:tw-scale-y-110:focus {
  --transform-scale-y: 1.1;
}

.focus\:tw-scale-y-125:focus {
  --transform-scale-y: 1.25;
}

.focus\:tw-scale-y-150:focus {
  --transform-scale-y: 1.5;
}

.tw-rotate-0 {
  --transform-rotate: 0;
}

.tw-rotate-1 {
  --transform-rotate: 1deg;
}

.tw-rotate-2 {
  --transform-rotate: 2deg;
}

.tw-rotate-3 {
  --transform-rotate: 3deg;
}

.tw-rotate-6 {
  --transform-rotate: 6deg;
}

.tw-rotate-12 {
  --transform-rotate: 12deg;
}

.tw-rotate-45 {
  --transform-rotate: 45deg;
}

.tw-rotate-90 {
  --transform-rotate: 90deg;
}

.tw-rotate-180 {
  --transform-rotate: 180deg;
}

.tw--rotate-180 {
  --transform-rotate: -180deg;
}

.tw--rotate-90 {
  --transform-rotate: -90deg;
}

.tw--rotate-45 {
  --transform-rotate: -45deg;
}

.tw--rotate-12 {
  --transform-rotate: -12deg;
}

.tw--rotate-6 {
  --transform-rotate: -6deg;
}

.tw--rotate-3 {
  --transform-rotate: -3deg;
}

.tw--rotate-2 {
  --transform-rotate: -2deg;
}

.tw--rotate-1 {
  --transform-rotate: -1deg;
}

.hover\:tw-rotate-0:hover {
  --transform-rotate: 0;
}

.hover\:tw-rotate-1:hover {
  --transform-rotate: 1deg;
}

.hover\:tw-rotate-2:hover {
  --transform-rotate: 2deg;
}

.hover\:tw-rotate-3:hover {
  --transform-rotate: 3deg;
}

.hover\:tw-rotate-6:hover {
  --transform-rotate: 6deg;
}

.hover\:tw-rotate-12:hover {
  --transform-rotate: 12deg;
}

.hover\:tw-rotate-45:hover {
  --transform-rotate: 45deg;
}

.hover\:tw-rotate-90:hover {
  --transform-rotate: 90deg;
}

.hover\:tw-rotate-180:hover {
  --transform-rotate: 180deg;
}

.hover\:tw--rotate-180:hover {
  --transform-rotate: -180deg;
}

.hover\:tw--rotate-90:hover {
  --transform-rotate: -90deg;
}

.hover\:tw--rotate-45:hover {
  --transform-rotate: -45deg;
}

.hover\:tw--rotate-12:hover {
  --transform-rotate: -12deg;
}

.hover\:tw--rotate-6:hover {
  --transform-rotate: -6deg;
}

.hover\:tw--rotate-3:hover {
  --transform-rotate: -3deg;
}

.hover\:tw--rotate-2:hover {
  --transform-rotate: -2deg;
}

.hover\:tw--rotate-1:hover {
  --transform-rotate: -1deg;
}

.focus\:tw-rotate-0:focus {
  --transform-rotate: 0;
}

.focus\:tw-rotate-1:focus {
  --transform-rotate: 1deg;
}

.focus\:tw-rotate-2:focus {
  --transform-rotate: 2deg;
}

.focus\:tw-rotate-3:focus {
  --transform-rotate: 3deg;
}

.focus\:tw-rotate-6:focus {
  --transform-rotate: 6deg;
}

.focus\:tw-rotate-12:focus {
  --transform-rotate: 12deg;
}

.focus\:tw-rotate-45:focus {
  --transform-rotate: 45deg;
}

.focus\:tw-rotate-90:focus {
  --transform-rotate: 90deg;
}

.focus\:tw-rotate-180:focus {
  --transform-rotate: 180deg;
}

.focus\:tw--rotate-180:focus {
  --transform-rotate: -180deg;
}

.focus\:tw--rotate-90:focus {
  --transform-rotate: -90deg;
}

.focus\:tw--rotate-45:focus {
  --transform-rotate: -45deg;
}

.focus\:tw--rotate-12:focus {
  --transform-rotate: -12deg;
}

.focus\:tw--rotate-6:focus {
  --transform-rotate: -6deg;
}

.focus\:tw--rotate-3:focus {
  --transform-rotate: -3deg;
}

.focus\:tw--rotate-2:focus {
  --transform-rotate: -2deg;
}

.focus\:tw--rotate-1:focus {
  --transform-rotate: -1deg;
}

.tw-translate-x-0 {
  --transform-translate-x: 0;
}

.tw-translate-x-1 {
  --transform-translate-x: 0.25rem;
}

.tw-translate-x-2 {
  --transform-translate-x: 0.5rem;
}

.tw-translate-x-3 {
  --transform-translate-x: 0.75rem;
}

.tw-translate-x-4 {
  --transform-translate-x: 1rem;
}

.tw-translate-x-5 {
  --transform-translate-x: 1.25rem;
}

.tw-translate-x-6 {
  --transform-translate-x: 1.5rem;
}

.tw-translate-x-8 {
  --transform-translate-x: 2rem;
}

.tw-translate-x-10 {
  --transform-translate-x: 2.5rem;
}

.tw-translate-x-12 {
  --transform-translate-x: 3rem;
}

.tw-translate-x-16 {
  --transform-translate-x: 4rem;
}

.tw-translate-x-20 {
  --transform-translate-x: 5rem;
}

.tw-translate-x-24 {
  --transform-translate-x: 6rem;
}

.tw-translate-x-32 {
  --transform-translate-x: 8rem;
}

.tw-translate-x-40 {
  --transform-translate-x: 10rem;
}

.tw-translate-x-48 {
  --transform-translate-x: 12rem;
}

.tw-translate-x-56 {
  --transform-translate-x: 14rem;
}

.tw-translate-x-64 {
  --transform-translate-x: 16rem;
}

.tw-translate-x-px {
  --transform-translate-x: 1px;
}

.tw-translate-x-270px {
  --transform-translate-x: 270px;
}

.tw-translate-x-400px {
  --transform-translate-x: 400px;
}

.tw-translate-x-464px {
  --transform-translate-x: 464px;
}

.tw-translate-x-480px {
  --transform-translate-x: 480px;
}

.tw-translate-x-500px {
  --transform-translate-x: 500px;
}

.tw-translate-x-576px {
  --transform-translate-x: 576px;
}

.tw-translate-x-600px {
  --transform-translate-x: 600px;
}

.tw-translate-x-672px {
  --transform-translate-x: 672px;
}

.tw--translate-x-1 {
  --transform-translate-x: -0.25rem;
}

.tw--translate-x-2 {
  --transform-translate-x: -0.5rem;
}

.tw--translate-x-3 {
  --transform-translate-x: -0.75rem;
}

.tw--translate-x-4 {
  --transform-translate-x: -1rem;
}

.tw--translate-x-5 {
  --transform-translate-x: -1.25rem;
}

.tw--translate-x-6 {
  --transform-translate-x: -1.5rem;
}

.tw--translate-x-8 {
  --transform-translate-x: -2rem;
}

.tw--translate-x-10 {
  --transform-translate-x: -2.5rem;
}

.tw--translate-x-12 {
  --transform-translate-x: -3rem;
}

.tw--translate-x-16 {
  --transform-translate-x: -4rem;
}

.tw--translate-x-20 {
  --transform-translate-x: -5rem;
}

.tw--translate-x-24 {
  --transform-translate-x: -6rem;
}

.tw--translate-x-32 {
  --transform-translate-x: -8rem;
}

.tw--translate-x-40 {
  --transform-translate-x: -10rem;
}

.tw--translate-x-48 {
  --transform-translate-x: -12rem;
}

.tw--translate-x-56 {
  --transform-translate-x: -14rem;
}

.tw--translate-x-64 {
  --transform-translate-x: -16rem;
}

.tw--translate-x-px {
  --transform-translate-x: -1px;
}

.tw--translate-x-270px {
  --transform-translate-x: -270px;
}

.tw--translate-x-400px {
  --transform-translate-x: -400px;
}

.tw--translate-x-464px {
  --transform-translate-x: -464px;
}

.tw--translate-x-480px {
  --transform-translate-x: -480px;
}

.tw--translate-x-500px {
  --transform-translate-x: -500px;
}

.tw--translate-x-576px {
  --transform-translate-x: -576px;
}

.tw--translate-x-600px {
  --transform-translate-x: -600px;
}

.tw--translate-x-672px {
  --transform-translate-x: -672px;
}

.tw--translate-x-full {
  --transform-translate-x: -100%;
}

.tw--translate-x-1\/2 {
  --transform-translate-x: -50%;
}

.tw-translate-x-1\/2 {
  --transform-translate-x: 50%;
}

.tw-translate-x-full {
  --transform-translate-x: 100%;
}

.tw-translate-y-0 {
  --transform-translate-y: 0;
}

.tw-translate-y-1 {
  --transform-translate-y: 0.25rem;
}

.tw-translate-y-2 {
  --transform-translate-y: 0.5rem;
}

.tw-translate-y-3 {
  --transform-translate-y: 0.75rem;
}

.tw-translate-y-4 {
  --transform-translate-y: 1rem;
}

.tw-translate-y-5 {
  --transform-translate-y: 1.25rem;
}

.tw-translate-y-6 {
  --transform-translate-y: 1.5rem;
}

.tw-translate-y-8 {
  --transform-translate-y: 2rem;
}

.tw-translate-y-10 {
  --transform-translate-y: 2.5rem;
}

.tw-translate-y-12 {
  --transform-translate-y: 3rem;
}

.tw-translate-y-16 {
  --transform-translate-y: 4rem;
}

.tw-translate-y-20 {
  --transform-translate-y: 5rem;
}

.tw-translate-y-24 {
  --transform-translate-y: 6rem;
}

.tw-translate-y-32 {
  --transform-translate-y: 8rem;
}

.tw-translate-y-40 {
  --transform-translate-y: 10rem;
}

.tw-translate-y-48 {
  --transform-translate-y: 12rem;
}

.tw-translate-y-56 {
  --transform-translate-y: 14rem;
}

.tw-translate-y-64 {
  --transform-translate-y: 16rem;
}

.tw-translate-y-px {
  --transform-translate-y: 1px;
}

.tw-translate-y-270px {
  --transform-translate-y: 270px;
}

.tw-translate-y-400px {
  --transform-translate-y: 400px;
}

.tw-translate-y-464px {
  --transform-translate-y: 464px;
}

.tw-translate-y-480px {
  --transform-translate-y: 480px;
}

.tw-translate-y-500px {
  --transform-translate-y: 500px;
}

.tw-translate-y-576px {
  --transform-translate-y: 576px;
}

.tw-translate-y-600px {
  --transform-translate-y: 600px;
}

.tw-translate-y-672px {
  --transform-translate-y: 672px;
}

.tw--translate-y-1 {
  --transform-translate-y: -0.25rem;
}

.tw--translate-y-2 {
  --transform-translate-y: -0.5rem;
}

.tw--translate-y-3 {
  --transform-translate-y: -0.75rem;
}

.tw--translate-y-4 {
  --transform-translate-y: -1rem;
}

.tw--translate-y-5 {
  --transform-translate-y: -1.25rem;
}

.tw--translate-y-6 {
  --transform-translate-y: -1.5rem;
}

.tw--translate-y-8 {
  --transform-translate-y: -2rem;
}

.tw--translate-y-10 {
  --transform-translate-y: -2.5rem;
}

.tw--translate-y-12 {
  --transform-translate-y: -3rem;
}

.tw--translate-y-16 {
  --transform-translate-y: -4rem;
}

.tw--translate-y-20 {
  --transform-translate-y: -5rem;
}

.tw--translate-y-24 {
  --transform-translate-y: -6rem;
}

.tw--translate-y-32 {
  --transform-translate-y: -8rem;
}

.tw--translate-y-40 {
  --transform-translate-y: -10rem;
}

.tw--translate-y-48 {
  --transform-translate-y: -12rem;
}

.tw--translate-y-56 {
  --transform-translate-y: -14rem;
}

.tw--translate-y-64 {
  --transform-translate-y: -16rem;
}

.tw--translate-y-px {
  --transform-translate-y: -1px;
}

.tw--translate-y-270px {
  --transform-translate-y: -270px;
}

.tw--translate-y-400px {
  --transform-translate-y: -400px;
}

.tw--translate-y-464px {
  --transform-translate-y: -464px;
}

.tw--translate-y-480px {
  --transform-translate-y: -480px;
}

.tw--translate-y-500px {
  --transform-translate-y: -500px;
}

.tw--translate-y-576px {
  --transform-translate-y: -576px;
}

.tw--translate-y-600px {
  --transform-translate-y: -600px;
}

.tw--translate-y-672px {
  --transform-translate-y: -672px;
}

.tw--translate-y-full {
  --transform-translate-y: -100%;
}

.tw--translate-y-1\/2 {
  --transform-translate-y: -50%;
}

.tw-translate-y-1\/2 {
  --transform-translate-y: 50%;
}

.tw-translate-y-full {
  --transform-translate-y: 100%;
}

.hover\:tw-translate-x-0:hover {
  --transform-translate-x: 0;
}

.hover\:tw-translate-x-1:hover {
  --transform-translate-x: 0.25rem;
}

.hover\:tw-translate-x-2:hover {
  --transform-translate-x: 0.5rem;
}

.hover\:tw-translate-x-3:hover {
  --transform-translate-x: 0.75rem;
}

.hover\:tw-translate-x-4:hover {
  --transform-translate-x: 1rem;
}

.hover\:tw-translate-x-5:hover {
  --transform-translate-x: 1.25rem;
}

.hover\:tw-translate-x-6:hover {
  --transform-translate-x: 1.5rem;
}

.hover\:tw-translate-x-8:hover {
  --transform-translate-x: 2rem;
}

.hover\:tw-translate-x-10:hover {
  --transform-translate-x: 2.5rem;
}

.hover\:tw-translate-x-12:hover {
  --transform-translate-x: 3rem;
}

.hover\:tw-translate-x-16:hover {
  --transform-translate-x: 4rem;
}

.hover\:tw-translate-x-20:hover {
  --transform-translate-x: 5rem;
}

.hover\:tw-translate-x-24:hover {
  --transform-translate-x: 6rem;
}

.hover\:tw-translate-x-32:hover {
  --transform-translate-x: 8rem;
}

.hover\:tw-translate-x-40:hover {
  --transform-translate-x: 10rem;
}

.hover\:tw-translate-x-48:hover {
  --transform-translate-x: 12rem;
}

.hover\:tw-translate-x-56:hover {
  --transform-translate-x: 14rem;
}

.hover\:tw-translate-x-64:hover {
  --transform-translate-x: 16rem;
}

.hover\:tw-translate-x-px:hover {
  --transform-translate-x: 1px;
}

.hover\:tw-translate-x-270px:hover {
  --transform-translate-x: 270px;
}

.hover\:tw-translate-x-400px:hover {
  --transform-translate-x: 400px;
}

.hover\:tw-translate-x-464px:hover {
  --transform-translate-x: 464px;
}

.hover\:tw-translate-x-480px:hover {
  --transform-translate-x: 480px;
}

.hover\:tw-translate-x-500px:hover {
  --transform-translate-x: 500px;
}

.hover\:tw-translate-x-576px:hover {
  --transform-translate-x: 576px;
}

.hover\:tw-translate-x-600px:hover {
  --transform-translate-x: 600px;
}

.hover\:tw-translate-x-672px:hover {
  --transform-translate-x: 672px;
}

.hover\:tw--translate-x-1:hover {
  --transform-translate-x: -0.25rem;
}

.hover\:tw--translate-x-2:hover {
  --transform-translate-x: -0.5rem;
}

.hover\:tw--translate-x-3:hover {
  --transform-translate-x: -0.75rem;
}

.hover\:tw--translate-x-4:hover {
  --transform-translate-x: -1rem;
}

.hover\:tw--translate-x-5:hover {
  --transform-translate-x: -1.25rem;
}

.hover\:tw--translate-x-6:hover {
  --transform-translate-x: -1.5rem;
}

.hover\:tw--translate-x-8:hover {
  --transform-translate-x: -2rem;
}

.hover\:tw--translate-x-10:hover {
  --transform-translate-x: -2.5rem;
}

.hover\:tw--translate-x-12:hover {
  --transform-translate-x: -3rem;
}

.hover\:tw--translate-x-16:hover {
  --transform-translate-x: -4rem;
}

.hover\:tw--translate-x-20:hover {
  --transform-translate-x: -5rem;
}

.hover\:tw--translate-x-24:hover {
  --transform-translate-x: -6rem;
}

.hover\:tw--translate-x-32:hover {
  --transform-translate-x: -8rem;
}

.hover\:tw--translate-x-40:hover {
  --transform-translate-x: -10rem;
}

.hover\:tw--translate-x-48:hover {
  --transform-translate-x: -12rem;
}

.hover\:tw--translate-x-56:hover {
  --transform-translate-x: -14rem;
}

.hover\:tw--translate-x-64:hover {
  --transform-translate-x: -16rem;
}

.hover\:tw--translate-x-px:hover {
  --transform-translate-x: -1px;
}

.hover\:tw--translate-x-270px:hover {
  --transform-translate-x: -270px;
}

.hover\:tw--translate-x-400px:hover {
  --transform-translate-x: -400px;
}

.hover\:tw--translate-x-464px:hover {
  --transform-translate-x: -464px;
}

.hover\:tw--translate-x-480px:hover {
  --transform-translate-x: -480px;
}

.hover\:tw--translate-x-500px:hover {
  --transform-translate-x: -500px;
}

.hover\:tw--translate-x-576px:hover {
  --transform-translate-x: -576px;
}

.hover\:tw--translate-x-600px:hover {
  --transform-translate-x: -600px;
}

.hover\:tw--translate-x-672px:hover {
  --transform-translate-x: -672px;
}

.hover\:tw--translate-x-full:hover {
  --transform-translate-x: -100%;
}

.hover\:tw--translate-x-1\/2:hover {
  --transform-translate-x: -50%;
}

.hover\:tw-translate-x-1\/2:hover {
  --transform-translate-x: 50%;
}

.hover\:tw-translate-x-full:hover {
  --transform-translate-x: 100%;
}

.hover\:tw-translate-y-0:hover {
  --transform-translate-y: 0;
}

.hover\:tw-translate-y-1:hover {
  --transform-translate-y: 0.25rem;
}

.hover\:tw-translate-y-2:hover {
  --transform-translate-y: 0.5rem;
}

.hover\:tw-translate-y-3:hover {
  --transform-translate-y: 0.75rem;
}

.hover\:tw-translate-y-4:hover {
  --transform-translate-y: 1rem;
}

.hover\:tw-translate-y-5:hover {
  --transform-translate-y: 1.25rem;
}

.hover\:tw-translate-y-6:hover {
  --transform-translate-y: 1.5rem;
}

.hover\:tw-translate-y-8:hover {
  --transform-translate-y: 2rem;
}

.hover\:tw-translate-y-10:hover {
  --transform-translate-y: 2.5rem;
}

.hover\:tw-translate-y-12:hover {
  --transform-translate-y: 3rem;
}

.hover\:tw-translate-y-16:hover {
  --transform-translate-y: 4rem;
}

.hover\:tw-translate-y-20:hover {
  --transform-translate-y: 5rem;
}

.hover\:tw-translate-y-24:hover {
  --transform-translate-y: 6rem;
}

.hover\:tw-translate-y-32:hover {
  --transform-translate-y: 8rem;
}

.hover\:tw-translate-y-40:hover {
  --transform-translate-y: 10rem;
}

.hover\:tw-translate-y-48:hover {
  --transform-translate-y: 12rem;
}

.hover\:tw-translate-y-56:hover {
  --transform-translate-y: 14rem;
}

.hover\:tw-translate-y-64:hover {
  --transform-translate-y: 16rem;
}

.hover\:tw-translate-y-px:hover {
  --transform-translate-y: 1px;
}

.hover\:tw-translate-y-270px:hover {
  --transform-translate-y: 270px;
}

.hover\:tw-translate-y-400px:hover {
  --transform-translate-y: 400px;
}

.hover\:tw-translate-y-464px:hover {
  --transform-translate-y: 464px;
}

.hover\:tw-translate-y-480px:hover {
  --transform-translate-y: 480px;
}

.hover\:tw-translate-y-500px:hover {
  --transform-translate-y: 500px;
}

.hover\:tw-translate-y-576px:hover {
  --transform-translate-y: 576px;
}

.hover\:tw-translate-y-600px:hover {
  --transform-translate-y: 600px;
}

.hover\:tw-translate-y-672px:hover {
  --transform-translate-y: 672px;
}

.hover\:tw--translate-y-1:hover {
  --transform-translate-y: -0.25rem;
}

.hover\:tw--translate-y-2:hover {
  --transform-translate-y: -0.5rem;
}

.hover\:tw--translate-y-3:hover {
  --transform-translate-y: -0.75rem;
}

.hover\:tw--translate-y-4:hover {
  --transform-translate-y: -1rem;
}

.hover\:tw--translate-y-5:hover {
  --transform-translate-y: -1.25rem;
}

.hover\:tw--translate-y-6:hover {
  --transform-translate-y: -1.5rem;
}

.hover\:tw--translate-y-8:hover {
  --transform-translate-y: -2rem;
}

.hover\:tw--translate-y-10:hover {
  --transform-translate-y: -2.5rem;
}

.hover\:tw--translate-y-12:hover {
  --transform-translate-y: -3rem;
}

.hover\:tw--translate-y-16:hover {
  --transform-translate-y: -4rem;
}

.hover\:tw--translate-y-20:hover {
  --transform-translate-y: -5rem;
}

.hover\:tw--translate-y-24:hover {
  --transform-translate-y: -6rem;
}

.hover\:tw--translate-y-32:hover {
  --transform-translate-y: -8rem;
}

.hover\:tw--translate-y-40:hover {
  --transform-translate-y: -10rem;
}

.hover\:tw--translate-y-48:hover {
  --transform-translate-y: -12rem;
}

.hover\:tw--translate-y-56:hover {
  --transform-translate-y: -14rem;
}

.hover\:tw--translate-y-64:hover {
  --transform-translate-y: -16rem;
}

.hover\:tw--translate-y-px:hover {
  --transform-translate-y: -1px;
}

.hover\:tw--translate-y-270px:hover {
  --transform-translate-y: -270px;
}

.hover\:tw--translate-y-400px:hover {
  --transform-translate-y: -400px;
}

.hover\:tw--translate-y-464px:hover {
  --transform-translate-y: -464px;
}

.hover\:tw--translate-y-480px:hover {
  --transform-translate-y: -480px;
}

.hover\:tw--translate-y-500px:hover {
  --transform-translate-y: -500px;
}

.hover\:tw--translate-y-576px:hover {
  --transform-translate-y: -576px;
}

.hover\:tw--translate-y-600px:hover {
  --transform-translate-y: -600px;
}

.hover\:tw--translate-y-672px:hover {
  --transform-translate-y: -672px;
}

.hover\:tw--translate-y-full:hover {
  --transform-translate-y: -100%;
}

.hover\:tw--translate-y-1\/2:hover {
  --transform-translate-y: -50%;
}

.hover\:tw-translate-y-1\/2:hover {
  --transform-translate-y: 50%;
}

.hover\:tw-translate-y-full:hover {
  --transform-translate-y: 100%;
}

.focus\:tw-translate-x-0:focus {
  --transform-translate-x: 0;
}

.focus\:tw-translate-x-1:focus {
  --transform-translate-x: 0.25rem;
}

.focus\:tw-translate-x-2:focus {
  --transform-translate-x: 0.5rem;
}

.focus\:tw-translate-x-3:focus {
  --transform-translate-x: 0.75rem;
}

.focus\:tw-translate-x-4:focus {
  --transform-translate-x: 1rem;
}

.focus\:tw-translate-x-5:focus {
  --transform-translate-x: 1.25rem;
}

.focus\:tw-translate-x-6:focus {
  --transform-translate-x: 1.5rem;
}

.focus\:tw-translate-x-8:focus {
  --transform-translate-x: 2rem;
}

.focus\:tw-translate-x-10:focus {
  --transform-translate-x: 2.5rem;
}

.focus\:tw-translate-x-12:focus {
  --transform-translate-x: 3rem;
}

.focus\:tw-translate-x-16:focus {
  --transform-translate-x: 4rem;
}

.focus\:tw-translate-x-20:focus {
  --transform-translate-x: 5rem;
}

.focus\:tw-translate-x-24:focus {
  --transform-translate-x: 6rem;
}

.focus\:tw-translate-x-32:focus {
  --transform-translate-x: 8rem;
}

.focus\:tw-translate-x-40:focus {
  --transform-translate-x: 10rem;
}

.focus\:tw-translate-x-48:focus {
  --transform-translate-x: 12rem;
}

.focus\:tw-translate-x-56:focus {
  --transform-translate-x: 14rem;
}

.focus\:tw-translate-x-64:focus {
  --transform-translate-x: 16rem;
}

.focus\:tw-translate-x-px:focus {
  --transform-translate-x: 1px;
}

.focus\:tw-translate-x-270px:focus {
  --transform-translate-x: 270px;
}

.focus\:tw-translate-x-400px:focus {
  --transform-translate-x: 400px;
}

.focus\:tw-translate-x-464px:focus {
  --transform-translate-x: 464px;
}

.focus\:tw-translate-x-480px:focus {
  --transform-translate-x: 480px;
}

.focus\:tw-translate-x-500px:focus {
  --transform-translate-x: 500px;
}

.focus\:tw-translate-x-576px:focus {
  --transform-translate-x: 576px;
}

.focus\:tw-translate-x-600px:focus {
  --transform-translate-x: 600px;
}

.focus\:tw-translate-x-672px:focus {
  --transform-translate-x: 672px;
}

.focus\:tw--translate-x-1:focus {
  --transform-translate-x: -0.25rem;
}

.focus\:tw--translate-x-2:focus {
  --transform-translate-x: -0.5rem;
}

.focus\:tw--translate-x-3:focus {
  --transform-translate-x: -0.75rem;
}

.focus\:tw--translate-x-4:focus {
  --transform-translate-x: -1rem;
}

.focus\:tw--translate-x-5:focus {
  --transform-translate-x: -1.25rem;
}

.focus\:tw--translate-x-6:focus {
  --transform-translate-x: -1.5rem;
}

.focus\:tw--translate-x-8:focus {
  --transform-translate-x: -2rem;
}

.focus\:tw--translate-x-10:focus {
  --transform-translate-x: -2.5rem;
}

.focus\:tw--translate-x-12:focus {
  --transform-translate-x: -3rem;
}

.focus\:tw--translate-x-16:focus {
  --transform-translate-x: -4rem;
}

.focus\:tw--translate-x-20:focus {
  --transform-translate-x: -5rem;
}

.focus\:tw--translate-x-24:focus {
  --transform-translate-x: -6rem;
}

.focus\:tw--translate-x-32:focus {
  --transform-translate-x: -8rem;
}

.focus\:tw--translate-x-40:focus {
  --transform-translate-x: -10rem;
}

.focus\:tw--translate-x-48:focus {
  --transform-translate-x: -12rem;
}

.focus\:tw--translate-x-56:focus {
  --transform-translate-x: -14rem;
}

.focus\:tw--translate-x-64:focus {
  --transform-translate-x: -16rem;
}

.focus\:tw--translate-x-px:focus {
  --transform-translate-x: -1px;
}

.focus\:tw--translate-x-270px:focus {
  --transform-translate-x: -270px;
}

.focus\:tw--translate-x-400px:focus {
  --transform-translate-x: -400px;
}

.focus\:tw--translate-x-464px:focus {
  --transform-translate-x: -464px;
}

.focus\:tw--translate-x-480px:focus {
  --transform-translate-x: -480px;
}

.focus\:tw--translate-x-500px:focus {
  --transform-translate-x: -500px;
}

.focus\:tw--translate-x-576px:focus {
  --transform-translate-x: -576px;
}

.focus\:tw--translate-x-600px:focus {
  --transform-translate-x: -600px;
}

.focus\:tw--translate-x-672px:focus {
  --transform-translate-x: -672px;
}

.focus\:tw--translate-x-full:focus {
  --transform-translate-x: -100%;
}

.focus\:tw--translate-x-1\/2:focus {
  --transform-translate-x: -50%;
}

.focus\:tw-translate-x-1\/2:focus {
  --transform-translate-x: 50%;
}

.focus\:tw-translate-x-full:focus {
  --transform-translate-x: 100%;
}

.focus\:tw-translate-y-0:focus {
  --transform-translate-y: 0;
}

.focus\:tw-translate-y-1:focus {
  --transform-translate-y: 0.25rem;
}

.focus\:tw-translate-y-2:focus {
  --transform-translate-y: 0.5rem;
}

.focus\:tw-translate-y-3:focus {
  --transform-translate-y: 0.75rem;
}

.focus\:tw-translate-y-4:focus {
  --transform-translate-y: 1rem;
}

.focus\:tw-translate-y-5:focus {
  --transform-translate-y: 1.25rem;
}

.focus\:tw-translate-y-6:focus {
  --transform-translate-y: 1.5rem;
}

.focus\:tw-translate-y-8:focus {
  --transform-translate-y: 2rem;
}

.focus\:tw-translate-y-10:focus {
  --transform-translate-y: 2.5rem;
}

.focus\:tw-translate-y-12:focus {
  --transform-translate-y: 3rem;
}

.focus\:tw-translate-y-16:focus {
  --transform-translate-y: 4rem;
}

.focus\:tw-translate-y-20:focus {
  --transform-translate-y: 5rem;
}

.focus\:tw-translate-y-24:focus {
  --transform-translate-y: 6rem;
}

.focus\:tw-translate-y-32:focus {
  --transform-translate-y: 8rem;
}

.focus\:tw-translate-y-40:focus {
  --transform-translate-y: 10rem;
}

.focus\:tw-translate-y-48:focus {
  --transform-translate-y: 12rem;
}

.focus\:tw-translate-y-56:focus {
  --transform-translate-y: 14rem;
}

.focus\:tw-translate-y-64:focus {
  --transform-translate-y: 16rem;
}

.focus\:tw-translate-y-px:focus {
  --transform-translate-y: 1px;
}

.focus\:tw-translate-y-270px:focus {
  --transform-translate-y: 270px;
}

.focus\:tw-translate-y-400px:focus {
  --transform-translate-y: 400px;
}

.focus\:tw-translate-y-464px:focus {
  --transform-translate-y: 464px;
}

.focus\:tw-translate-y-480px:focus {
  --transform-translate-y: 480px;
}

.focus\:tw-translate-y-500px:focus {
  --transform-translate-y: 500px;
}

.focus\:tw-translate-y-576px:focus {
  --transform-translate-y: 576px;
}

.focus\:tw-translate-y-600px:focus {
  --transform-translate-y: 600px;
}

.focus\:tw-translate-y-672px:focus {
  --transform-translate-y: 672px;
}

.focus\:tw--translate-y-1:focus {
  --transform-translate-y: -0.25rem;
}

.focus\:tw--translate-y-2:focus {
  --transform-translate-y: -0.5rem;
}

.focus\:tw--translate-y-3:focus {
  --transform-translate-y: -0.75rem;
}

.focus\:tw--translate-y-4:focus {
  --transform-translate-y: -1rem;
}

.focus\:tw--translate-y-5:focus {
  --transform-translate-y: -1.25rem;
}

.focus\:tw--translate-y-6:focus {
  --transform-translate-y: -1.5rem;
}

.focus\:tw--translate-y-8:focus {
  --transform-translate-y: -2rem;
}

.focus\:tw--translate-y-10:focus {
  --transform-translate-y: -2.5rem;
}

.focus\:tw--translate-y-12:focus {
  --transform-translate-y: -3rem;
}

.focus\:tw--translate-y-16:focus {
  --transform-translate-y: -4rem;
}

.focus\:tw--translate-y-20:focus {
  --transform-translate-y: -5rem;
}

.focus\:tw--translate-y-24:focus {
  --transform-translate-y: -6rem;
}

.focus\:tw--translate-y-32:focus {
  --transform-translate-y: -8rem;
}

.focus\:tw--translate-y-40:focus {
  --transform-translate-y: -10rem;
}

.focus\:tw--translate-y-48:focus {
  --transform-translate-y: -12rem;
}

.focus\:tw--translate-y-56:focus {
  --transform-translate-y: -14rem;
}

.focus\:tw--translate-y-64:focus {
  --transform-translate-y: -16rem;
}

.focus\:tw--translate-y-px:focus {
  --transform-translate-y: -1px;
}

.focus\:tw--translate-y-270px:focus {
  --transform-translate-y: -270px;
}

.focus\:tw--translate-y-400px:focus {
  --transform-translate-y: -400px;
}

.focus\:tw--translate-y-464px:focus {
  --transform-translate-y: -464px;
}

.focus\:tw--translate-y-480px:focus {
  --transform-translate-y: -480px;
}

.focus\:tw--translate-y-500px:focus {
  --transform-translate-y: -500px;
}

.focus\:tw--translate-y-576px:focus {
  --transform-translate-y: -576px;
}

.focus\:tw--translate-y-600px:focus {
  --transform-translate-y: -600px;
}

.focus\:tw--translate-y-672px:focus {
  --transform-translate-y: -672px;
}

.focus\:tw--translate-y-full:focus {
  --transform-translate-y: -100%;
}

.focus\:tw--translate-y-1\/2:focus {
  --transform-translate-y: -50%;
}

.focus\:tw-translate-y-1\/2:focus {
  --transform-translate-y: 50%;
}

.focus\:tw-translate-y-full:focus {
  --transform-translate-y: 100%;
}

.tw-skew-x-0 {
  --transform-skew-x: 0;
}

.tw-skew-x-1 {
  --transform-skew-x: 1deg;
}

.tw-skew-x-2 {
  --transform-skew-x: 2deg;
}

.tw-skew-x-3 {
  --transform-skew-x: 3deg;
}

.tw-skew-x-6 {
  --transform-skew-x: 6deg;
}

.tw-skew-x-12 {
  --transform-skew-x: 12deg;
}

.tw--skew-x-12 {
  --transform-skew-x: -12deg;
}

.tw--skew-x-6 {
  --transform-skew-x: -6deg;
}

.tw--skew-x-3 {
  --transform-skew-x: -3deg;
}

.tw--skew-x-2 {
  --transform-skew-x: -2deg;
}

.tw--skew-x-1 {
  --transform-skew-x: -1deg;
}

.tw-skew-y-0 {
  --transform-skew-y: 0;
}

.tw-skew-y-1 {
  --transform-skew-y: 1deg;
}

.tw-skew-y-2 {
  --transform-skew-y: 2deg;
}

.tw-skew-y-3 {
  --transform-skew-y: 3deg;
}

.tw-skew-y-6 {
  --transform-skew-y: 6deg;
}

.tw-skew-y-12 {
  --transform-skew-y: 12deg;
}

.tw--skew-y-12 {
  --transform-skew-y: -12deg;
}

.tw--skew-y-6 {
  --transform-skew-y: -6deg;
}

.tw--skew-y-3 {
  --transform-skew-y: -3deg;
}

.tw--skew-y-2 {
  --transform-skew-y: -2deg;
}

.tw--skew-y-1 {
  --transform-skew-y: -1deg;
}

.hover\:tw-skew-x-0:hover {
  --transform-skew-x: 0;
}

.hover\:tw-skew-x-1:hover {
  --transform-skew-x: 1deg;
}

.hover\:tw-skew-x-2:hover {
  --transform-skew-x: 2deg;
}

.hover\:tw-skew-x-3:hover {
  --transform-skew-x: 3deg;
}

.hover\:tw-skew-x-6:hover {
  --transform-skew-x: 6deg;
}

.hover\:tw-skew-x-12:hover {
  --transform-skew-x: 12deg;
}

.hover\:tw--skew-x-12:hover {
  --transform-skew-x: -12deg;
}

.hover\:tw--skew-x-6:hover {
  --transform-skew-x: -6deg;
}

.hover\:tw--skew-x-3:hover {
  --transform-skew-x: -3deg;
}

.hover\:tw--skew-x-2:hover {
  --transform-skew-x: -2deg;
}

.hover\:tw--skew-x-1:hover {
  --transform-skew-x: -1deg;
}

.hover\:tw-skew-y-0:hover {
  --transform-skew-y: 0;
}

.hover\:tw-skew-y-1:hover {
  --transform-skew-y: 1deg;
}

.hover\:tw-skew-y-2:hover {
  --transform-skew-y: 2deg;
}

.hover\:tw-skew-y-3:hover {
  --transform-skew-y: 3deg;
}

.hover\:tw-skew-y-6:hover {
  --transform-skew-y: 6deg;
}

.hover\:tw-skew-y-12:hover {
  --transform-skew-y: 12deg;
}

.hover\:tw--skew-y-12:hover {
  --transform-skew-y: -12deg;
}

.hover\:tw--skew-y-6:hover {
  --transform-skew-y: -6deg;
}

.hover\:tw--skew-y-3:hover {
  --transform-skew-y: -3deg;
}

.hover\:tw--skew-y-2:hover {
  --transform-skew-y: -2deg;
}

.hover\:tw--skew-y-1:hover {
  --transform-skew-y: -1deg;
}

.focus\:tw-skew-x-0:focus {
  --transform-skew-x: 0;
}

.focus\:tw-skew-x-1:focus {
  --transform-skew-x: 1deg;
}

.focus\:tw-skew-x-2:focus {
  --transform-skew-x: 2deg;
}

.focus\:tw-skew-x-3:focus {
  --transform-skew-x: 3deg;
}

.focus\:tw-skew-x-6:focus {
  --transform-skew-x: 6deg;
}

.focus\:tw-skew-x-12:focus {
  --transform-skew-x: 12deg;
}

.focus\:tw--skew-x-12:focus {
  --transform-skew-x: -12deg;
}

.focus\:tw--skew-x-6:focus {
  --transform-skew-x: -6deg;
}

.focus\:tw--skew-x-3:focus {
  --transform-skew-x: -3deg;
}

.focus\:tw--skew-x-2:focus {
  --transform-skew-x: -2deg;
}

.focus\:tw--skew-x-1:focus {
  --transform-skew-x: -1deg;
}

.focus\:tw-skew-y-0:focus {
  --transform-skew-y: 0;
}

.focus\:tw-skew-y-1:focus {
  --transform-skew-y: 1deg;
}

.focus\:tw-skew-y-2:focus {
  --transform-skew-y: 2deg;
}

.focus\:tw-skew-y-3:focus {
  --transform-skew-y: 3deg;
}

.focus\:tw-skew-y-6:focus {
  --transform-skew-y: 6deg;
}

.focus\:tw-skew-y-12:focus {
  --transform-skew-y: 12deg;
}

.focus\:tw--skew-y-12:focus {
  --transform-skew-y: -12deg;
}

.focus\:tw--skew-y-6:focus {
  --transform-skew-y: -6deg;
}

.focus\:tw--skew-y-3:focus {
  --transform-skew-y: -3deg;
}

.focus\:tw--skew-y-2:focus {
  --transform-skew-y: -2deg;
}

.focus\:tw--skew-y-1:focus {
  --transform-skew-y: -1deg;
}

.tw-transition-none {
  transition-property: none;
}

.tw-transition-all {
  transition-property: all;
}

.tw-transition {
  transition-property: background-color, border-color, color, fill, stroke, opacity, box-shadow, transform;
}

.tw-transition-colors {
  transition-property: background-color, border-color, color, fill, stroke;
}

.tw-transition-opacity {
  transition-property: opacity;
}

.tw-transition-shadow {
  transition-property: box-shadow;
}

.tw-transition-transform {
  transition-property: transform;
}

.tw-ease-linear {
  transition-timing-function: linear;
}

.tw-ease-in {
  transition-timing-function: cubic-bezier(0.4, 0, 1, 1);
}

.tw-ease-out {
  transition-timing-function: cubic-bezier(0, 0, 0.2, 1);
}

.tw-ease-in-out {
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
}

.tw-duration-75 {
  transition-duration: 75ms;
}

.tw-duration-100 {
  transition-duration: 100ms;
}

.tw-duration-150 {
  transition-duration: 150ms;
}

.tw-duration-200 {
  transition-duration: 200ms;
}

.tw-duration-300 {
  transition-duration: 300ms;
}

.tw-duration-500 {
  transition-duration: 500ms;
}

.tw-duration-700 {
  transition-duration: 700ms;
}

.tw-duration-1000 {
  transition-duration: 1000ms;
}

.tw-delay-75 {
  transition-delay: 75ms;
}

.tw-delay-100 {
  transition-delay: 100ms;
}

.tw-delay-150 {
  transition-delay: 150ms;
}

.tw-delay-200 {
  transition-delay: 200ms;
}

.tw-delay-300 {
  transition-delay: 300ms;
}

.tw-delay-500 {
  transition-delay: 500ms;
}

.tw-delay-700 {
  transition-delay: 700ms;
}

.tw-delay-1000 {
  transition-delay: 1000ms;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

@keyframes ping {
  75%, 100% {
    transform: scale(2);
    opacity: 0;
  }
}

@keyframes pulse {
  50% {
    opacity: .5;
  }
}

@keyframes bounce {
  0%, 100% {
    transform: translateY(-25%);
    animation-timing-function: cubic-bezier(0.8,0,1,1);
  }

  50% {
    transform: none;
    animation-timing-function: cubic-bezier(0,0,0.2,1);
  }
}

.tw-animate-none {
  animation: none;
}

.tw-animate-spin {
  animation: spin 1s linear infinite;
}

.tw-animate-ping {
  animation: ping 1s cubic-bezier(0, 0, 0.2, 1) infinite;
}

.tw-animate-pulse {
  animation: pulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite;
}

.tw-animate-bounce {
  animation: bounce 1s infinite;
}

/* CSS */

/* Create below composite classes, after you finish, from the /frontend directory, you must run the command 'npm run build:tailwind' to build tailwind css to see the changes on the development enviroment

Any custom css, create it here and do not make any updates to the tailwind.css file, since it will be deleted every time tailwind build is executed*/

.tw-break-words-overflow {
  overflow-wrap: break-word;
  word-break: break-word;
}

.tw-rounded-02rem {
  border-radius: 0.2rem
}

@media (min-width: 640px) {
  .sm\:tw-container {
    width: 100%;
  }

  @media (min-width: 640px) {
    .sm\:tw-container {
      max-width: 640px;
    }
  }

  @media (min-width: 768px) {
    .sm\:tw-container {
      max-width: 768px;
    }
  }

  @media (min-width: 1024px) {
    .sm\:tw-container {
      max-width: 1024px;
    }
  }

  @media (min-width: 1280px) {
    .sm\:tw-container {
      max-width: 1280px;
    }
  }

  .sm\:tw-space-y-0 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(0px * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(0px * var(--space-y-reverse));
  }

  .sm\:tw-space-x-0 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(0px * var(--space-x-reverse));
    margin-left: calc(0px * calc(1 - var(--space-x-reverse)));
  }

  .sm\:tw-space-y-1 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(0.25rem * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(0.25rem * var(--space-y-reverse));
  }

  .sm\:tw-space-x-1 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(0.25rem * var(--space-x-reverse));
    margin-left: calc(0.25rem * calc(1 - var(--space-x-reverse)));
  }

  .sm\:tw-space-y-2 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(0.5rem * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(0.5rem * var(--space-y-reverse));
  }

  .sm\:tw-space-x-2 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(0.5rem * var(--space-x-reverse));
    margin-left: calc(0.5rem * calc(1 - var(--space-x-reverse)));
  }

  .sm\:tw-space-y-3 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(0.75rem * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(0.75rem * var(--space-y-reverse));
  }

  .sm\:tw-space-x-3 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(0.75rem * var(--space-x-reverse));
    margin-left: calc(0.75rem * calc(1 - var(--space-x-reverse)));
  }

  .sm\:tw-space-y-4 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(1rem * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(1rem * var(--space-y-reverse));
  }

  .sm\:tw-space-x-4 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(1rem * var(--space-x-reverse));
    margin-left: calc(1rem * calc(1 - var(--space-x-reverse)));
  }

  .sm\:tw-space-y-5 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(1.25rem * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(1.25rem * var(--space-y-reverse));
  }

  .sm\:tw-space-x-5 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(1.25rem * var(--space-x-reverse));
    margin-left: calc(1.25rem * calc(1 - var(--space-x-reverse)));
  }

  .sm\:tw-space-y-6 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(1.5rem * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(1.5rem * var(--space-y-reverse));
  }

  .sm\:tw-space-x-6 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(1.5rem * var(--space-x-reverse));
    margin-left: calc(1.5rem * calc(1 - var(--space-x-reverse)));
  }

  .sm\:tw-space-y-8 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(2rem * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(2rem * var(--space-y-reverse));
  }

  .sm\:tw-space-x-8 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(2rem * var(--space-x-reverse));
    margin-left: calc(2rem * calc(1 - var(--space-x-reverse)));
  }

  .sm\:tw-space-y-10 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(2.5rem * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(2.5rem * var(--space-y-reverse));
  }

  .sm\:tw-space-x-10 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(2.5rem * var(--space-x-reverse));
    margin-left: calc(2.5rem * calc(1 - var(--space-x-reverse)));
  }

  .sm\:tw-space-y-12 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(3rem * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(3rem * var(--space-y-reverse));
  }

  .sm\:tw-space-x-12 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(3rem * var(--space-x-reverse));
    margin-left: calc(3rem * calc(1 - var(--space-x-reverse)));
  }

  .sm\:tw-space-y-16 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(4rem * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(4rem * var(--space-y-reverse));
  }

  .sm\:tw-space-x-16 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(4rem * var(--space-x-reverse));
    margin-left: calc(4rem * calc(1 - var(--space-x-reverse)));
  }

  .sm\:tw-space-y-20 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(5rem * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(5rem * var(--space-y-reverse));
  }

  .sm\:tw-space-x-20 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(5rem * var(--space-x-reverse));
    margin-left: calc(5rem * calc(1 - var(--space-x-reverse)));
  }

  .sm\:tw-space-y-24 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(6rem * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(6rem * var(--space-y-reverse));
  }

  .sm\:tw-space-x-24 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(6rem * var(--space-x-reverse));
    margin-left: calc(6rem * calc(1 - var(--space-x-reverse)));
  }

  .sm\:tw-space-y-32 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(8rem * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(8rem * var(--space-y-reverse));
  }

  .sm\:tw-space-x-32 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(8rem * var(--space-x-reverse));
    margin-left: calc(8rem * calc(1 - var(--space-x-reverse)));
  }

  .sm\:tw-space-y-40 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(10rem * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(10rem * var(--space-y-reverse));
  }

  .sm\:tw-space-x-40 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(10rem * var(--space-x-reverse));
    margin-left: calc(10rem * calc(1 - var(--space-x-reverse)));
  }

  .sm\:tw-space-y-48 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(12rem * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(12rem * var(--space-y-reverse));
  }

  .sm\:tw-space-x-48 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(12rem * var(--space-x-reverse));
    margin-left: calc(12rem * calc(1 - var(--space-x-reverse)));
  }

  .sm\:tw-space-y-56 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(14rem * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(14rem * var(--space-y-reverse));
  }

  .sm\:tw-space-x-56 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(14rem * var(--space-x-reverse));
    margin-left: calc(14rem * calc(1 - var(--space-x-reverse)));
  }

  .sm\:tw-space-y-64 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(16rem * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(16rem * var(--space-y-reverse));
  }

  .sm\:tw-space-x-64 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(16rem * var(--space-x-reverse));
    margin-left: calc(16rem * calc(1 - var(--space-x-reverse)));
  }

  .sm\:tw-space-y-px > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(1px * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(1px * var(--space-y-reverse));
  }

  .sm\:tw-space-x-px > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(1px * var(--space-x-reverse));
    margin-left: calc(1px * calc(1 - var(--space-x-reverse)));
  }

  .sm\:tw-space-y-270px > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(270px * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(270px * var(--space-y-reverse));
  }

  .sm\:tw-space-x-270px > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(270px * var(--space-x-reverse));
    margin-left: calc(270px * calc(1 - var(--space-x-reverse)));
  }

  .sm\:tw-space-y-400px > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(400px * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(400px * var(--space-y-reverse));
  }

  .sm\:tw-space-x-400px > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(400px * var(--space-x-reverse));
    margin-left: calc(400px * calc(1 - var(--space-x-reverse)));
  }

  .sm\:tw-space-y-464px > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(464px * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(464px * var(--space-y-reverse));
  }

  .sm\:tw-space-x-464px > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(464px * var(--space-x-reverse));
    margin-left: calc(464px * calc(1 - var(--space-x-reverse)));
  }

  .sm\:tw-space-y-480px > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(480px * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(480px * var(--space-y-reverse));
  }

  .sm\:tw-space-x-480px > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(480px * var(--space-x-reverse));
    margin-left: calc(480px * calc(1 - var(--space-x-reverse)));
  }

  .sm\:tw-space-y-500px > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(500px * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(500px * var(--space-y-reverse));
  }

  .sm\:tw-space-x-500px > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(500px * var(--space-x-reverse));
    margin-left: calc(500px * calc(1 - var(--space-x-reverse)));
  }

  .sm\:tw-space-y-576px > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(576px * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(576px * var(--space-y-reverse));
  }

  .sm\:tw-space-x-576px > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(576px * var(--space-x-reverse));
    margin-left: calc(576px * calc(1 - var(--space-x-reverse)));
  }

  .sm\:tw-space-y-600px > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(600px * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(600px * var(--space-y-reverse));
  }

  .sm\:tw-space-x-600px > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(600px * var(--space-x-reverse));
    margin-left: calc(600px * calc(1 - var(--space-x-reverse)));
  }

  .sm\:tw-space-y-672px > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(672px * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(672px * var(--space-y-reverse));
  }

  .sm\:tw-space-x-672px > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(672px * var(--space-x-reverse));
    margin-left: calc(672px * calc(1 - var(--space-x-reverse)));
  }

  .sm\:tw--space-y-1 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(-0.25rem * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(-0.25rem * var(--space-y-reverse));
  }

  .sm\:tw--space-x-1 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(-0.25rem * var(--space-x-reverse));
    margin-left: calc(-0.25rem * calc(1 - var(--space-x-reverse)));
  }

  .sm\:tw--space-y-2 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(-0.5rem * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(-0.5rem * var(--space-y-reverse));
  }

  .sm\:tw--space-x-2 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(-0.5rem * var(--space-x-reverse));
    margin-left: calc(-0.5rem * calc(1 - var(--space-x-reverse)));
  }

  .sm\:tw--space-y-3 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(-0.75rem * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(-0.75rem * var(--space-y-reverse));
  }

  .sm\:tw--space-x-3 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(-0.75rem * var(--space-x-reverse));
    margin-left: calc(-0.75rem * calc(1 - var(--space-x-reverse)));
  }

  .sm\:tw--space-y-4 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(-1rem * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(-1rem * var(--space-y-reverse));
  }

  .sm\:tw--space-x-4 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(-1rem * var(--space-x-reverse));
    margin-left: calc(-1rem * calc(1 - var(--space-x-reverse)));
  }

  .sm\:tw--space-y-5 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(-1.25rem * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(-1.25rem * var(--space-y-reverse));
  }

  .sm\:tw--space-x-5 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(-1.25rem * var(--space-x-reverse));
    margin-left: calc(-1.25rem * calc(1 - var(--space-x-reverse)));
  }

  .sm\:tw--space-y-6 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(-1.5rem * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(-1.5rem * var(--space-y-reverse));
  }

  .sm\:tw--space-x-6 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(-1.5rem * var(--space-x-reverse));
    margin-left: calc(-1.5rem * calc(1 - var(--space-x-reverse)));
  }

  .sm\:tw--space-y-8 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(-2rem * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(-2rem * var(--space-y-reverse));
  }

  .sm\:tw--space-x-8 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(-2rem * var(--space-x-reverse));
    margin-left: calc(-2rem * calc(1 - var(--space-x-reverse)));
  }

  .sm\:tw--space-y-10 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(-2.5rem * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(-2.5rem * var(--space-y-reverse));
  }

  .sm\:tw--space-x-10 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(-2.5rem * var(--space-x-reverse));
    margin-left: calc(-2.5rem * calc(1 - var(--space-x-reverse)));
  }

  .sm\:tw--space-y-12 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(-3rem * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(-3rem * var(--space-y-reverse));
  }

  .sm\:tw--space-x-12 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(-3rem * var(--space-x-reverse));
    margin-left: calc(-3rem * calc(1 - var(--space-x-reverse)));
  }

  .sm\:tw--space-y-16 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(-4rem * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(-4rem * var(--space-y-reverse));
  }

  .sm\:tw--space-x-16 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(-4rem * var(--space-x-reverse));
    margin-left: calc(-4rem * calc(1 - var(--space-x-reverse)));
  }

  .sm\:tw--space-y-20 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(-5rem * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(-5rem * var(--space-y-reverse));
  }

  .sm\:tw--space-x-20 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(-5rem * var(--space-x-reverse));
    margin-left: calc(-5rem * calc(1 - var(--space-x-reverse)));
  }

  .sm\:tw--space-y-24 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(-6rem * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(-6rem * var(--space-y-reverse));
  }

  .sm\:tw--space-x-24 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(-6rem * var(--space-x-reverse));
    margin-left: calc(-6rem * calc(1 - var(--space-x-reverse)));
  }

  .sm\:tw--space-y-32 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(-8rem * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(-8rem * var(--space-y-reverse));
  }

  .sm\:tw--space-x-32 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(-8rem * var(--space-x-reverse));
    margin-left: calc(-8rem * calc(1 - var(--space-x-reverse)));
  }

  .sm\:tw--space-y-40 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(-10rem * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(-10rem * var(--space-y-reverse));
  }

  .sm\:tw--space-x-40 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(-10rem * var(--space-x-reverse));
    margin-left: calc(-10rem * calc(1 - var(--space-x-reverse)));
  }

  .sm\:tw--space-y-48 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(-12rem * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(-12rem * var(--space-y-reverse));
  }

  .sm\:tw--space-x-48 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(-12rem * var(--space-x-reverse));
    margin-left: calc(-12rem * calc(1 - var(--space-x-reverse)));
  }

  .sm\:tw--space-y-56 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(-14rem * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(-14rem * var(--space-y-reverse));
  }

  .sm\:tw--space-x-56 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(-14rem * var(--space-x-reverse));
    margin-left: calc(-14rem * calc(1 - var(--space-x-reverse)));
  }

  .sm\:tw--space-y-64 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(-16rem * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(-16rem * var(--space-y-reverse));
  }

  .sm\:tw--space-x-64 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(-16rem * var(--space-x-reverse));
    margin-left: calc(-16rem * calc(1 - var(--space-x-reverse)));
  }

  .sm\:tw--space-y-px > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(-1px * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(-1px * var(--space-y-reverse));
  }

  .sm\:tw--space-x-px > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(-1px * var(--space-x-reverse));
    margin-left: calc(-1px * calc(1 - var(--space-x-reverse)));
  }

  .sm\:tw--space-y-270px > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(-270px * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(-270px * var(--space-y-reverse));
  }

  .sm\:tw--space-x-270px > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(-270px * var(--space-x-reverse));
    margin-left: calc(-270px * calc(1 - var(--space-x-reverse)));
  }

  .sm\:tw--space-y-400px > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(-400px * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(-400px * var(--space-y-reverse));
  }

  .sm\:tw--space-x-400px > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(-400px * var(--space-x-reverse));
    margin-left: calc(-400px * calc(1 - var(--space-x-reverse)));
  }

  .sm\:tw--space-y-464px > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(-464px * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(-464px * var(--space-y-reverse));
  }

  .sm\:tw--space-x-464px > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(-464px * var(--space-x-reverse));
    margin-left: calc(-464px * calc(1 - var(--space-x-reverse)));
  }

  .sm\:tw--space-y-480px > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(-480px * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(-480px * var(--space-y-reverse));
  }

  .sm\:tw--space-x-480px > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(-480px * var(--space-x-reverse));
    margin-left: calc(-480px * calc(1 - var(--space-x-reverse)));
  }

  .sm\:tw--space-y-500px > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(-500px * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(-500px * var(--space-y-reverse));
  }

  .sm\:tw--space-x-500px > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(-500px * var(--space-x-reverse));
    margin-left: calc(-500px * calc(1 - var(--space-x-reverse)));
  }

  .sm\:tw--space-y-576px > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(-576px * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(-576px * var(--space-y-reverse));
  }

  .sm\:tw--space-x-576px > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(-576px * var(--space-x-reverse));
    margin-left: calc(-576px * calc(1 - var(--space-x-reverse)));
  }

  .sm\:tw--space-y-600px > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(-600px * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(-600px * var(--space-y-reverse));
  }

  .sm\:tw--space-x-600px > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(-600px * var(--space-x-reverse));
    margin-left: calc(-600px * calc(1 - var(--space-x-reverse)));
  }

  .sm\:tw--space-y-672px > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(-672px * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(-672px * var(--space-y-reverse));
  }

  .sm\:tw--space-x-672px > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(-672px * var(--space-x-reverse));
    margin-left: calc(-672px * calc(1 - var(--space-x-reverse)));
  }

  .sm\:tw-space-y-reverse > :not(template) ~ :not(template) {
    --space-y-reverse: 1;
  }

  .sm\:tw-space-x-reverse > :not(template) ~ :not(template) {
    --space-x-reverse: 1;
  }

  .sm\:tw-divide-y-0 > :not(template) ~ :not(template) {
    --divide-y-reverse: 0;
    border-top-width: calc(0px * calc(1 - var(--divide-y-reverse)));
    border-bottom-width: calc(0px * var(--divide-y-reverse));
  }

  .sm\:tw-divide-x-0 > :not(template) ~ :not(template) {
    --divide-x-reverse: 0;
    border-right-width: calc(0px * var(--divide-x-reverse));
    border-left-width: calc(0px * calc(1 - var(--divide-x-reverse)));
  }

  .sm\:tw-divide-y-2 > :not(template) ~ :not(template) {
    --divide-y-reverse: 0;
    border-top-width: calc(2px * calc(1 - var(--divide-y-reverse)));
    border-bottom-width: calc(2px * var(--divide-y-reverse));
  }

  .sm\:tw-divide-x-2 > :not(template) ~ :not(template) {
    --divide-x-reverse: 0;
    border-right-width: calc(2px * var(--divide-x-reverse));
    border-left-width: calc(2px * calc(1 - var(--divide-x-reverse)));
  }

  .sm\:tw-divide-y-4 > :not(template) ~ :not(template) {
    --divide-y-reverse: 0;
    border-top-width: calc(4px * calc(1 - var(--divide-y-reverse)));
    border-bottom-width: calc(4px * var(--divide-y-reverse));
  }

  .sm\:tw-divide-x-4 > :not(template) ~ :not(template) {
    --divide-x-reverse: 0;
    border-right-width: calc(4px * var(--divide-x-reverse));
    border-left-width: calc(4px * calc(1 - var(--divide-x-reverse)));
  }

  .sm\:tw-divide-y-8 > :not(template) ~ :not(template) {
    --divide-y-reverse: 0;
    border-top-width: calc(8px * calc(1 - var(--divide-y-reverse)));
    border-bottom-width: calc(8px * var(--divide-y-reverse));
  }

  .sm\:tw-divide-x-8 > :not(template) ~ :not(template) {
    --divide-x-reverse: 0;
    border-right-width: calc(8px * var(--divide-x-reverse));
    border-left-width: calc(8px * calc(1 - var(--divide-x-reverse)));
  }

  .sm\:tw-divide-y > :not(template) ~ :not(template) {
    --divide-y-reverse: 0;
    border-top-width: calc(1px * calc(1 - var(--divide-y-reverse)));
    border-bottom-width: calc(1px * var(--divide-y-reverse));
  }

  .sm\:tw-divide-x > :not(template) ~ :not(template) {
    --divide-x-reverse: 0;
    border-right-width: calc(1px * var(--divide-x-reverse));
    border-left-width: calc(1px * calc(1 - var(--divide-x-reverse)));
  }

  .sm\:tw-divide-y-reverse > :not(template) ~ :not(template) {
    --divide-y-reverse: 1;
  }

  .sm\:tw-divide-x-reverse > :not(template) ~ :not(template) {
    --divide-x-reverse: 1;
  }

  .sm\:tw-divide-transparent > :not(template) ~ :not(template) {
    border-color: transparent;
  }

  .sm\:tw-divide-current > :not(template) ~ :not(template) {
    border-color: currentColor;
  }

  .sm\:tw-divide-black > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #000;
    border-color: rgba(0, 0, 0, var(--divide-opacity));
  }

  .sm\:tw-divide-white > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #fff;
    border-color: rgba(255, 255, 255, var(--divide-opacity));
  }

  .sm\:tw-divide-gray-100 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #f7fafc;
    border-color: rgba(247, 250, 252, var(--divide-opacity));
  }

  .sm\:tw-divide-gray-200 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #edf2f7;
    border-color: rgba(237, 242, 247, var(--divide-opacity));
  }

  .sm\:tw-divide-gray-300 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #e2e8f0;
    border-color: rgba(226, 232, 240, var(--divide-opacity));
  }

  .sm\:tw-divide-gray-400 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #cbd5e0;
    border-color: rgba(203, 213, 224, var(--divide-opacity));
  }

  .sm\:tw-divide-gray-500 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #a0aec0;
    border-color: rgba(160, 174, 192, var(--divide-opacity));
  }

  .sm\:tw-divide-gray-600 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #718096;
    border-color: rgba(113, 128, 150, var(--divide-opacity));
  }

  .sm\:tw-divide-gray-700 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #4a5568;
    border-color: rgba(74, 85, 104, var(--divide-opacity));
  }

  .sm\:tw-divide-gray-800 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #2d3748;
    border-color: rgba(45, 55, 72, var(--divide-opacity));
  }

  .sm\:tw-divide-gray-900 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #1a202c;
    border-color: rgba(26, 32, 44, var(--divide-opacity));
  }

  .sm\:tw-divide-red-100 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #fff5f5;
    border-color: rgba(255, 245, 245, var(--divide-opacity));
  }

  .sm\:tw-divide-red-200 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #fed7d7;
    border-color: rgba(254, 215, 215, var(--divide-opacity));
  }

  .sm\:tw-divide-red-300 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #feb2b2;
    border-color: rgba(254, 178, 178, var(--divide-opacity));
  }

  .sm\:tw-divide-red-400 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #fc8181;
    border-color: rgba(252, 129, 129, var(--divide-opacity));
  }

  .sm\:tw-divide-red-500 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #f56565;
    border-color: rgba(245, 101, 101, var(--divide-opacity));
  }

  .sm\:tw-divide-red-600 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #e53e3e;
    border-color: rgba(229, 62, 62, var(--divide-opacity));
  }

  .sm\:tw-divide-red-700 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #c53030;
    border-color: rgba(197, 48, 48, var(--divide-opacity));
  }

  .sm\:tw-divide-red-800 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #9b2c2c;
    border-color: rgba(155, 44, 44, var(--divide-opacity));
  }

  .sm\:tw-divide-red-900 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #742a2a;
    border-color: rgba(116, 42, 42, var(--divide-opacity));
  }

  .sm\:tw-divide-orange-100 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #fffaf0;
    border-color: rgba(255, 250, 240, var(--divide-opacity));
  }

  .sm\:tw-divide-orange-200 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #feebc8;
    border-color: rgba(254, 235, 200, var(--divide-opacity));
  }

  .sm\:tw-divide-orange-300 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #fbd38d;
    border-color: rgba(251, 211, 141, var(--divide-opacity));
  }

  .sm\:tw-divide-orange-400 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #f6ad55;
    border-color: rgba(246, 173, 85, var(--divide-opacity));
  }

  .sm\:tw-divide-orange-500 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #ed8936;
    border-color: rgba(237, 137, 54, var(--divide-opacity));
  }

  .sm\:tw-divide-orange-600 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #dd6b20;
    border-color: rgba(221, 107, 32, var(--divide-opacity));
  }

  .sm\:tw-divide-orange-700 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #c05621;
    border-color: rgba(192, 86, 33, var(--divide-opacity));
  }

  .sm\:tw-divide-orange-800 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #9c4221;
    border-color: rgba(156, 66, 33, var(--divide-opacity));
  }

  .sm\:tw-divide-orange-900 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #7b341e;
    border-color: rgba(123, 52, 30, var(--divide-opacity));
  }

  .sm\:tw-divide-yellow-100 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #fffff0;
    border-color: rgba(255, 255, 240, var(--divide-opacity));
  }

  .sm\:tw-divide-yellow-200 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #fefcbf;
    border-color: rgba(254, 252, 191, var(--divide-opacity));
  }

  .sm\:tw-divide-yellow-300 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #faf089;
    border-color: rgba(250, 240, 137, var(--divide-opacity));
  }

  .sm\:tw-divide-yellow-400 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #f6e05e;
    border-color: rgba(246, 224, 94, var(--divide-opacity));
  }

  .sm\:tw-divide-yellow-500 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #ecc94b;
    border-color: rgba(236, 201, 75, var(--divide-opacity));
  }

  .sm\:tw-divide-yellow-600 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #d69e2e;
    border-color: rgba(214, 158, 46, var(--divide-opacity));
  }

  .sm\:tw-divide-yellow-700 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #b7791f;
    border-color: rgba(183, 121, 31, var(--divide-opacity));
  }

  .sm\:tw-divide-yellow-800 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #975a16;
    border-color: rgba(151, 90, 22, var(--divide-opacity));
  }

  .sm\:tw-divide-yellow-900 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #744210;
    border-color: rgba(116, 66, 16, var(--divide-opacity));
  }

  .sm\:tw-divide-green-100 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #f0fff4;
    border-color: rgba(240, 255, 244, var(--divide-opacity));
  }

  .sm\:tw-divide-green-200 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #c6f6d5;
    border-color: rgba(198, 246, 213, var(--divide-opacity));
  }

  .sm\:tw-divide-green-300 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #9ae6b4;
    border-color: rgba(154, 230, 180, var(--divide-opacity));
  }

  .sm\:tw-divide-green-400 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #68d391;
    border-color: rgba(104, 211, 145, var(--divide-opacity));
  }

  .sm\:tw-divide-green-500 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #48bb78;
    border-color: rgba(72, 187, 120, var(--divide-opacity));
  }

  .sm\:tw-divide-green-600 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #38a169;
    border-color: rgba(56, 161, 105, var(--divide-opacity));
  }

  .sm\:tw-divide-green-700 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #2f855a;
    border-color: rgba(47, 133, 90, var(--divide-opacity));
  }

  .sm\:tw-divide-green-800 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #276749;
    border-color: rgba(39, 103, 73, var(--divide-opacity));
  }

  .sm\:tw-divide-green-900 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #22543d;
    border-color: rgba(34, 84, 61, var(--divide-opacity));
  }

  .sm\:tw-divide-teal-100 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #e6fffa;
    border-color: rgba(230, 255, 250, var(--divide-opacity));
  }

  .sm\:tw-divide-teal-200 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #b2f5ea;
    border-color: rgba(178, 245, 234, var(--divide-opacity));
  }

  .sm\:tw-divide-teal-300 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #81e6d9;
    border-color: rgba(129, 230, 217, var(--divide-opacity));
  }

  .sm\:tw-divide-teal-400 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #4fd1c5;
    border-color: rgba(79, 209, 197, var(--divide-opacity));
  }

  .sm\:tw-divide-teal-500 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #38b2ac;
    border-color: rgba(56, 178, 172, var(--divide-opacity));
  }

  .sm\:tw-divide-teal-600 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #319795;
    border-color: rgba(49, 151, 149, var(--divide-opacity));
  }

  .sm\:tw-divide-teal-700 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #2c7a7b;
    border-color: rgba(44, 122, 123, var(--divide-opacity));
  }

  .sm\:tw-divide-teal-800 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #285e61;
    border-color: rgba(40, 94, 97, var(--divide-opacity));
  }

  .sm\:tw-divide-teal-900 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #234e52;
    border-color: rgba(35, 78, 82, var(--divide-opacity));
  }

  .sm\:tw-divide-blue-100 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #ebf8ff;
    border-color: rgba(235, 248, 255, var(--divide-opacity));
  }

  .sm\:tw-divide-blue-200 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #bee3f8;
    border-color: rgba(190, 227, 248, var(--divide-opacity));
  }

  .sm\:tw-divide-blue-300 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #90cdf4;
    border-color: rgba(144, 205, 244, var(--divide-opacity));
  }

  .sm\:tw-divide-blue-400 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #63b3ed;
    border-color: rgba(99, 179, 237, var(--divide-opacity));
  }

  .sm\:tw-divide-blue-500 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #4299e1;
    border-color: rgba(66, 153, 225, var(--divide-opacity));
  }

  .sm\:tw-divide-blue-600 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #3182ce;
    border-color: rgba(49, 130, 206, var(--divide-opacity));
  }

  .sm\:tw-divide-blue-700 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #2b6cb0;
    border-color: rgba(43, 108, 176, var(--divide-opacity));
  }

  .sm\:tw-divide-blue-800 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #2c5282;
    border-color: rgba(44, 82, 130, var(--divide-opacity));
  }

  .sm\:tw-divide-blue-900 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #2a4365;
    border-color: rgba(42, 67, 101, var(--divide-opacity));
  }

  .sm\:tw-divide-indigo-100 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #ebf4ff;
    border-color: rgba(235, 244, 255, var(--divide-opacity));
  }

  .sm\:tw-divide-indigo-200 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #c3dafe;
    border-color: rgba(195, 218, 254, var(--divide-opacity));
  }

  .sm\:tw-divide-indigo-300 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #a3bffa;
    border-color: rgba(163, 191, 250, var(--divide-opacity));
  }

  .sm\:tw-divide-indigo-400 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #7f9cf5;
    border-color: rgba(127, 156, 245, var(--divide-opacity));
  }

  .sm\:tw-divide-indigo-500 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #667eea;
    border-color: rgba(102, 126, 234, var(--divide-opacity));
  }

  .sm\:tw-divide-indigo-600 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #5a67d8;
    border-color: rgba(90, 103, 216, var(--divide-opacity));
  }

  .sm\:tw-divide-indigo-700 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #4c51bf;
    border-color: rgba(76, 81, 191, var(--divide-opacity));
  }

  .sm\:tw-divide-indigo-800 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #434190;
    border-color: rgba(67, 65, 144, var(--divide-opacity));
  }

  .sm\:tw-divide-indigo-900 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #3c366b;
    border-color: rgba(60, 54, 107, var(--divide-opacity));
  }

  .sm\:tw-divide-purple-100 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #faf5ff;
    border-color: rgba(250, 245, 255, var(--divide-opacity));
  }

  .sm\:tw-divide-purple-200 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #e9d8fd;
    border-color: rgba(233, 216, 253, var(--divide-opacity));
  }

  .sm\:tw-divide-purple-300 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #d6bcfa;
    border-color: rgba(214, 188, 250, var(--divide-opacity));
  }

  .sm\:tw-divide-purple-400 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #b794f4;
    border-color: rgba(183, 148, 244, var(--divide-opacity));
  }

  .sm\:tw-divide-purple-500 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #9f7aea;
    border-color: rgba(159, 122, 234, var(--divide-opacity));
  }

  .sm\:tw-divide-purple-600 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #805ad5;
    border-color: rgba(128, 90, 213, var(--divide-opacity));
  }

  .sm\:tw-divide-purple-700 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #6b46c1;
    border-color: rgba(107, 70, 193, var(--divide-opacity));
  }

  .sm\:tw-divide-purple-800 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #553c9a;
    border-color: rgba(85, 60, 154, var(--divide-opacity));
  }

  .sm\:tw-divide-purple-900 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #44337a;
    border-color: rgba(68, 51, 122, var(--divide-opacity));
  }

  .sm\:tw-divide-pink-100 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #fff5f7;
    border-color: rgba(255, 245, 247, var(--divide-opacity));
  }

  .sm\:tw-divide-pink-200 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #fed7e2;
    border-color: rgba(254, 215, 226, var(--divide-opacity));
  }

  .sm\:tw-divide-pink-300 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #fbb6ce;
    border-color: rgba(251, 182, 206, var(--divide-opacity));
  }

  .sm\:tw-divide-pink-400 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #f687b3;
    border-color: rgba(246, 135, 179, var(--divide-opacity));
  }

  .sm\:tw-divide-pink-500 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #ed64a6;
    border-color: rgba(237, 100, 166, var(--divide-opacity));
  }

  .sm\:tw-divide-pink-600 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #d53f8c;
    border-color: rgba(213, 63, 140, var(--divide-opacity));
  }

  .sm\:tw-divide-pink-700 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #b83280;
    border-color: rgba(184, 50, 128, var(--divide-opacity));
  }

  .sm\:tw-divide-pink-800 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #97266d;
    border-color: rgba(151, 38, 109, var(--divide-opacity));
  }

  .sm\:tw-divide-pink-900 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #702459;
    border-color: rgba(112, 36, 89, var(--divide-opacity));
  }

  .sm\:tw-divide-solid > :not(template) ~ :not(template) {
    border-style: solid;
  }

  .sm\:tw-divide-dashed > :not(template) ~ :not(template) {
    border-style: dashed;
  }

  .sm\:tw-divide-dotted > :not(template) ~ :not(template) {
    border-style: dotted;
  }

  .sm\:tw-divide-double > :not(template) ~ :not(template) {
    border-style: double;
  }

  .sm\:tw-divide-none > :not(template) ~ :not(template) {
    border-style: none;
  }

  .sm\:tw-divide-opacity-0 > :not(template) ~ :not(template) {
    --divide-opacity: 0;
  }

  .sm\:tw-divide-opacity-25 > :not(template) ~ :not(template) {
    --divide-opacity: 0.25;
  }

  .sm\:tw-divide-opacity-50 > :not(template) ~ :not(template) {
    --divide-opacity: 0.5;
  }

  .sm\:tw-divide-opacity-75 > :not(template) ~ :not(template) {
    --divide-opacity: 0.75;
  }

  .sm\:tw-divide-opacity-100 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
  }

  .sm\:tw-sr-only {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    border-width: 0;
  }

  .sm\:tw-not-sr-only {
    position: static;
    width: auto;
    height: auto;
    padding: 0;
    margin: 0;
    overflow: visible;
    clip: auto;
    white-space: normal;
  }

  .sm\:focus\:tw-sr-only:focus {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    border-width: 0;
  }

  .sm\:focus\:tw-not-sr-only:focus {
    position: static;
    width: auto;
    height: auto;
    padding: 0;
    margin: 0;
    overflow: visible;
    clip: auto;
    white-space: normal;
  }

  .sm\:tw-appearance-none {
    -webkit-appearance: none;
       -moz-appearance: none;
            appearance: none;
  }

  .sm\:tw-bg-fixed {
    background-attachment: fixed;
  }

  .sm\:tw-bg-local {
    background-attachment: local;
  }

  .sm\:tw-bg-scroll {
    background-attachment: scroll;
  }

  .sm\:tw-bg-clip-border {
    background-clip: border-box;
  }

  .sm\:tw-bg-clip-padding {
    background-clip: padding-box;
  }

  .sm\:tw-bg-clip-content {
    background-clip: content-box;
  }

  .sm\:tw-bg-clip-text {
    -webkit-background-clip: text;
            background-clip: text;
  }

  .sm\:tw-bg-transparent {
    background-color: transparent;
  }

  .sm\:tw-bg-current {
    background-color: currentColor;
  }

  .sm\:tw-bg-black {
    --bg-opacity: 1;
    background-color: #000;
    background-color: rgba(0, 0, 0, var(--bg-opacity));
  }

  .sm\:tw-bg-white {
    --bg-opacity: 1;
    background-color: #fff;
    background-color: rgba(255, 255, 255, var(--bg-opacity));
  }

  .sm\:tw-bg-gray-100 {
    --bg-opacity: 1;
    background-color: #f7fafc;
    background-color: rgba(247, 250, 252, var(--bg-opacity));
  }

  .sm\:tw-bg-gray-200 {
    --bg-opacity: 1;
    background-color: #edf2f7;
    background-color: rgba(237, 242, 247, var(--bg-opacity));
  }

  .sm\:tw-bg-gray-300 {
    --bg-opacity: 1;
    background-color: #e2e8f0;
    background-color: rgba(226, 232, 240, var(--bg-opacity));
  }

  .sm\:tw-bg-gray-400 {
    --bg-opacity: 1;
    background-color: #cbd5e0;
    background-color: rgba(203, 213, 224, var(--bg-opacity));
  }

  .sm\:tw-bg-gray-500 {
    --bg-opacity: 1;
    background-color: #a0aec0;
    background-color: rgba(160, 174, 192, var(--bg-opacity));
  }

  .sm\:tw-bg-gray-600 {
    --bg-opacity: 1;
    background-color: #718096;
    background-color: rgba(113, 128, 150, var(--bg-opacity));
  }

  .sm\:tw-bg-gray-700 {
    --bg-opacity: 1;
    background-color: #4a5568;
    background-color: rgba(74, 85, 104, var(--bg-opacity));
  }

  .sm\:tw-bg-gray-800 {
    --bg-opacity: 1;
    background-color: #2d3748;
    background-color: rgba(45, 55, 72, var(--bg-opacity));
  }

  .sm\:tw-bg-gray-900 {
    --bg-opacity: 1;
    background-color: #1a202c;
    background-color: rgba(26, 32, 44, var(--bg-opacity));
  }

  .sm\:tw-bg-red-100 {
    --bg-opacity: 1;
    background-color: #fff5f5;
    background-color: rgba(255, 245, 245, var(--bg-opacity));
  }

  .sm\:tw-bg-red-200 {
    --bg-opacity: 1;
    background-color: #fed7d7;
    background-color: rgba(254, 215, 215, var(--bg-opacity));
  }

  .sm\:tw-bg-red-300 {
    --bg-opacity: 1;
    background-color: #feb2b2;
    background-color: rgba(254, 178, 178, var(--bg-opacity));
  }

  .sm\:tw-bg-red-400 {
    --bg-opacity: 1;
    background-color: #fc8181;
    background-color: rgba(252, 129, 129, var(--bg-opacity));
  }

  .sm\:tw-bg-red-500 {
    --bg-opacity: 1;
    background-color: #f56565;
    background-color: rgba(245, 101, 101, var(--bg-opacity));
  }

  .sm\:tw-bg-red-600 {
    --bg-opacity: 1;
    background-color: #e53e3e;
    background-color: rgba(229, 62, 62, var(--bg-opacity));
  }

  .sm\:tw-bg-red-700 {
    --bg-opacity: 1;
    background-color: #c53030;
    background-color: rgba(197, 48, 48, var(--bg-opacity));
  }

  .sm\:tw-bg-red-800 {
    --bg-opacity: 1;
    background-color: #9b2c2c;
    background-color: rgba(155, 44, 44, var(--bg-opacity));
  }

  .sm\:tw-bg-red-900 {
    --bg-opacity: 1;
    background-color: #742a2a;
    background-color: rgba(116, 42, 42, var(--bg-opacity));
  }

  .sm\:tw-bg-orange-100 {
    --bg-opacity: 1;
    background-color: #fffaf0;
    background-color: rgba(255, 250, 240, var(--bg-opacity));
  }

  .sm\:tw-bg-orange-200 {
    --bg-opacity: 1;
    background-color: #feebc8;
    background-color: rgba(254, 235, 200, var(--bg-opacity));
  }

  .sm\:tw-bg-orange-300 {
    --bg-opacity: 1;
    background-color: #fbd38d;
    background-color: rgba(251, 211, 141, var(--bg-opacity));
  }

  .sm\:tw-bg-orange-400 {
    --bg-opacity: 1;
    background-color: #f6ad55;
    background-color: rgba(246, 173, 85, var(--bg-opacity));
  }

  .sm\:tw-bg-orange-500 {
    --bg-opacity: 1;
    background-color: #ed8936;
    background-color: rgba(237, 137, 54, var(--bg-opacity));
  }

  .sm\:tw-bg-orange-600 {
    --bg-opacity: 1;
    background-color: #dd6b20;
    background-color: rgba(221, 107, 32, var(--bg-opacity));
  }

  .sm\:tw-bg-orange-700 {
    --bg-opacity: 1;
    background-color: #c05621;
    background-color: rgba(192, 86, 33, var(--bg-opacity));
  }

  .sm\:tw-bg-orange-800 {
    --bg-opacity: 1;
    background-color: #9c4221;
    background-color: rgba(156, 66, 33, var(--bg-opacity));
  }

  .sm\:tw-bg-orange-900 {
    --bg-opacity: 1;
    background-color: #7b341e;
    background-color: rgba(123, 52, 30, var(--bg-opacity));
  }

  .sm\:tw-bg-yellow-100 {
    --bg-opacity: 1;
    background-color: #fffff0;
    background-color: rgba(255, 255, 240, var(--bg-opacity));
  }

  .sm\:tw-bg-yellow-200 {
    --bg-opacity: 1;
    background-color: #fefcbf;
    background-color: rgba(254, 252, 191, var(--bg-opacity));
  }

  .sm\:tw-bg-yellow-300 {
    --bg-opacity: 1;
    background-color: #faf089;
    background-color: rgba(250, 240, 137, var(--bg-opacity));
  }

  .sm\:tw-bg-yellow-400 {
    --bg-opacity: 1;
    background-color: #f6e05e;
    background-color: rgba(246, 224, 94, var(--bg-opacity));
  }

  .sm\:tw-bg-yellow-500 {
    --bg-opacity: 1;
    background-color: #ecc94b;
    background-color: rgba(236, 201, 75, var(--bg-opacity));
  }

  .sm\:tw-bg-yellow-600 {
    --bg-opacity: 1;
    background-color: #d69e2e;
    background-color: rgba(214, 158, 46, var(--bg-opacity));
  }

  .sm\:tw-bg-yellow-700 {
    --bg-opacity: 1;
    background-color: #b7791f;
    background-color: rgba(183, 121, 31, var(--bg-opacity));
  }

  .sm\:tw-bg-yellow-800 {
    --bg-opacity: 1;
    background-color: #975a16;
    background-color: rgba(151, 90, 22, var(--bg-opacity));
  }

  .sm\:tw-bg-yellow-900 {
    --bg-opacity: 1;
    background-color: #744210;
    background-color: rgba(116, 66, 16, var(--bg-opacity));
  }

  .sm\:tw-bg-green-100 {
    --bg-opacity: 1;
    background-color: #f0fff4;
    background-color: rgba(240, 255, 244, var(--bg-opacity));
  }

  .sm\:tw-bg-green-200 {
    --bg-opacity: 1;
    background-color: #c6f6d5;
    background-color: rgba(198, 246, 213, var(--bg-opacity));
  }

  .sm\:tw-bg-green-300 {
    --bg-opacity: 1;
    background-color: #9ae6b4;
    background-color: rgba(154, 230, 180, var(--bg-opacity));
  }

  .sm\:tw-bg-green-400 {
    --bg-opacity: 1;
    background-color: #68d391;
    background-color: rgba(104, 211, 145, var(--bg-opacity));
  }

  .sm\:tw-bg-green-500 {
    --bg-opacity: 1;
    background-color: #48bb78;
    background-color: rgba(72, 187, 120, var(--bg-opacity));
  }

  .sm\:tw-bg-green-600 {
    --bg-opacity: 1;
    background-color: #38a169;
    background-color: rgba(56, 161, 105, var(--bg-opacity));
  }

  .sm\:tw-bg-green-700 {
    --bg-opacity: 1;
    background-color: #2f855a;
    background-color: rgba(47, 133, 90, var(--bg-opacity));
  }

  .sm\:tw-bg-green-800 {
    --bg-opacity: 1;
    background-color: #276749;
    background-color: rgba(39, 103, 73, var(--bg-opacity));
  }

  .sm\:tw-bg-green-900 {
    --bg-opacity: 1;
    background-color: #22543d;
    background-color: rgba(34, 84, 61, var(--bg-opacity));
  }

  .sm\:tw-bg-teal-100 {
    --bg-opacity: 1;
    background-color: #e6fffa;
    background-color: rgba(230, 255, 250, var(--bg-opacity));
  }

  .sm\:tw-bg-teal-200 {
    --bg-opacity: 1;
    background-color: #b2f5ea;
    background-color: rgba(178, 245, 234, var(--bg-opacity));
  }

  .sm\:tw-bg-teal-300 {
    --bg-opacity: 1;
    background-color: #81e6d9;
    background-color: rgba(129, 230, 217, var(--bg-opacity));
  }

  .sm\:tw-bg-teal-400 {
    --bg-opacity: 1;
    background-color: #4fd1c5;
    background-color: rgba(79, 209, 197, var(--bg-opacity));
  }

  .sm\:tw-bg-teal-500 {
    --bg-opacity: 1;
    background-color: #38b2ac;
    background-color: rgba(56, 178, 172, var(--bg-opacity));
  }

  .sm\:tw-bg-teal-600 {
    --bg-opacity: 1;
    background-color: #319795;
    background-color: rgba(49, 151, 149, var(--bg-opacity));
  }

  .sm\:tw-bg-teal-700 {
    --bg-opacity: 1;
    background-color: #2c7a7b;
    background-color: rgba(44, 122, 123, var(--bg-opacity));
  }

  .sm\:tw-bg-teal-800 {
    --bg-opacity: 1;
    background-color: #285e61;
    background-color: rgba(40, 94, 97, var(--bg-opacity));
  }

  .sm\:tw-bg-teal-900 {
    --bg-opacity: 1;
    background-color: #234e52;
    background-color: rgba(35, 78, 82, var(--bg-opacity));
  }

  .sm\:tw-bg-blue-100 {
    --bg-opacity: 1;
    background-color: #ebf8ff;
    background-color: rgba(235, 248, 255, var(--bg-opacity));
  }

  .sm\:tw-bg-blue-200 {
    --bg-opacity: 1;
    background-color: #bee3f8;
    background-color: rgba(190, 227, 248, var(--bg-opacity));
  }

  .sm\:tw-bg-blue-300 {
    --bg-opacity: 1;
    background-color: #90cdf4;
    background-color: rgba(144, 205, 244, var(--bg-opacity));
  }

  .sm\:tw-bg-blue-400 {
    --bg-opacity: 1;
    background-color: #63b3ed;
    background-color: rgba(99, 179, 237, var(--bg-opacity));
  }

  .sm\:tw-bg-blue-500 {
    --bg-opacity: 1;
    background-color: #4299e1;
    background-color: rgba(66, 153, 225, var(--bg-opacity));
  }

  .sm\:tw-bg-blue-600 {
    --bg-opacity: 1;
    background-color: #3182ce;
    background-color: rgba(49, 130, 206, var(--bg-opacity));
  }

  .sm\:tw-bg-blue-700 {
    --bg-opacity: 1;
    background-color: #2b6cb0;
    background-color: rgba(43, 108, 176, var(--bg-opacity));
  }

  .sm\:tw-bg-blue-800 {
    --bg-opacity: 1;
    background-color: #2c5282;
    background-color: rgba(44, 82, 130, var(--bg-opacity));
  }

  .sm\:tw-bg-blue-900 {
    --bg-opacity: 1;
    background-color: #2a4365;
    background-color: rgba(42, 67, 101, var(--bg-opacity));
  }

  .sm\:tw-bg-indigo-100 {
    --bg-opacity: 1;
    background-color: #ebf4ff;
    background-color: rgba(235, 244, 255, var(--bg-opacity));
  }

  .sm\:tw-bg-indigo-200 {
    --bg-opacity: 1;
    background-color: #c3dafe;
    background-color: rgba(195, 218, 254, var(--bg-opacity));
  }

  .sm\:tw-bg-indigo-300 {
    --bg-opacity: 1;
    background-color: #a3bffa;
    background-color: rgba(163, 191, 250, var(--bg-opacity));
  }

  .sm\:tw-bg-indigo-400 {
    --bg-opacity: 1;
    background-color: #7f9cf5;
    background-color: rgba(127, 156, 245, var(--bg-opacity));
  }

  .sm\:tw-bg-indigo-500 {
    --bg-opacity: 1;
    background-color: #667eea;
    background-color: rgba(102, 126, 234, var(--bg-opacity));
  }

  .sm\:tw-bg-indigo-600 {
    --bg-opacity: 1;
    background-color: #5a67d8;
    background-color: rgba(90, 103, 216, var(--bg-opacity));
  }

  .sm\:tw-bg-indigo-700 {
    --bg-opacity: 1;
    background-color: #4c51bf;
    background-color: rgba(76, 81, 191, var(--bg-opacity));
  }

  .sm\:tw-bg-indigo-800 {
    --bg-opacity: 1;
    background-color: #434190;
    background-color: rgba(67, 65, 144, var(--bg-opacity));
  }

  .sm\:tw-bg-indigo-900 {
    --bg-opacity: 1;
    background-color: #3c366b;
    background-color: rgba(60, 54, 107, var(--bg-opacity));
  }

  .sm\:tw-bg-purple-100 {
    --bg-opacity: 1;
    background-color: #faf5ff;
    background-color: rgba(250, 245, 255, var(--bg-opacity));
  }

  .sm\:tw-bg-purple-200 {
    --bg-opacity: 1;
    background-color: #e9d8fd;
    background-color: rgba(233, 216, 253, var(--bg-opacity));
  }

  .sm\:tw-bg-purple-300 {
    --bg-opacity: 1;
    background-color: #d6bcfa;
    background-color: rgba(214, 188, 250, var(--bg-opacity));
  }

  .sm\:tw-bg-purple-400 {
    --bg-opacity: 1;
    background-color: #b794f4;
    background-color: rgba(183, 148, 244, var(--bg-opacity));
  }

  .sm\:tw-bg-purple-500 {
    --bg-opacity: 1;
    background-color: #9f7aea;
    background-color: rgba(159, 122, 234, var(--bg-opacity));
  }

  .sm\:tw-bg-purple-600 {
    --bg-opacity: 1;
    background-color: #805ad5;
    background-color: rgba(128, 90, 213, var(--bg-opacity));
  }

  .sm\:tw-bg-purple-700 {
    --bg-opacity: 1;
    background-color: #6b46c1;
    background-color: rgba(107, 70, 193, var(--bg-opacity));
  }

  .sm\:tw-bg-purple-800 {
    --bg-opacity: 1;
    background-color: #553c9a;
    background-color: rgba(85, 60, 154, var(--bg-opacity));
  }

  .sm\:tw-bg-purple-900 {
    --bg-opacity: 1;
    background-color: #44337a;
    background-color: rgba(68, 51, 122, var(--bg-opacity));
  }

  .sm\:tw-bg-pink-100 {
    --bg-opacity: 1;
    background-color: #fff5f7;
    background-color: rgba(255, 245, 247, var(--bg-opacity));
  }

  .sm\:tw-bg-pink-200 {
    --bg-opacity: 1;
    background-color: #fed7e2;
    background-color: rgba(254, 215, 226, var(--bg-opacity));
  }

  .sm\:tw-bg-pink-300 {
    --bg-opacity: 1;
    background-color: #fbb6ce;
    background-color: rgba(251, 182, 206, var(--bg-opacity));
  }

  .sm\:tw-bg-pink-400 {
    --bg-opacity: 1;
    background-color: #f687b3;
    background-color: rgba(246, 135, 179, var(--bg-opacity));
  }

  .sm\:tw-bg-pink-500 {
    --bg-opacity: 1;
    background-color: #ed64a6;
    background-color: rgba(237, 100, 166, var(--bg-opacity));
  }

  .sm\:tw-bg-pink-600 {
    --bg-opacity: 1;
    background-color: #d53f8c;
    background-color: rgba(213, 63, 140, var(--bg-opacity));
  }

  .sm\:tw-bg-pink-700 {
    --bg-opacity: 1;
    background-color: #b83280;
    background-color: rgba(184, 50, 128, var(--bg-opacity));
  }

  .sm\:tw-bg-pink-800 {
    --bg-opacity: 1;
    background-color: #97266d;
    background-color: rgba(151, 38, 109, var(--bg-opacity));
  }

  .sm\:tw-bg-pink-900 {
    --bg-opacity: 1;
    background-color: #702459;
    background-color: rgba(112, 36, 89, var(--bg-opacity));
  }

  .sm\:hover\:tw-bg-transparent:hover {
    background-color: transparent;
  }

  .sm\:hover\:tw-bg-current:hover {
    background-color: currentColor;
  }

  .sm\:hover\:tw-bg-black:hover {
    --bg-opacity: 1;
    background-color: #000;
    background-color: rgba(0, 0, 0, var(--bg-opacity));
  }

  .sm\:hover\:tw-bg-white:hover {
    --bg-opacity: 1;
    background-color: #fff;
    background-color: rgba(255, 255, 255, var(--bg-opacity));
  }

  .sm\:hover\:tw-bg-gray-100:hover {
    --bg-opacity: 1;
    background-color: #f7fafc;
    background-color: rgba(247, 250, 252, var(--bg-opacity));
  }

  .sm\:hover\:tw-bg-gray-200:hover {
    --bg-opacity: 1;
    background-color: #edf2f7;
    background-color: rgba(237, 242, 247, var(--bg-opacity));
  }

  .sm\:hover\:tw-bg-gray-300:hover {
    --bg-opacity: 1;
    background-color: #e2e8f0;
    background-color: rgba(226, 232, 240, var(--bg-opacity));
  }

  .sm\:hover\:tw-bg-gray-400:hover {
    --bg-opacity: 1;
    background-color: #cbd5e0;
    background-color: rgba(203, 213, 224, var(--bg-opacity));
  }

  .sm\:hover\:tw-bg-gray-500:hover {
    --bg-opacity: 1;
    background-color: #a0aec0;
    background-color: rgba(160, 174, 192, var(--bg-opacity));
  }

  .sm\:hover\:tw-bg-gray-600:hover {
    --bg-opacity: 1;
    background-color: #718096;
    background-color: rgba(113, 128, 150, var(--bg-opacity));
  }

  .sm\:hover\:tw-bg-gray-700:hover {
    --bg-opacity: 1;
    background-color: #4a5568;
    background-color: rgba(74, 85, 104, var(--bg-opacity));
  }

  .sm\:hover\:tw-bg-gray-800:hover {
    --bg-opacity: 1;
    background-color: #2d3748;
    background-color: rgba(45, 55, 72, var(--bg-opacity));
  }

  .sm\:hover\:tw-bg-gray-900:hover {
    --bg-opacity: 1;
    background-color: #1a202c;
    background-color: rgba(26, 32, 44, var(--bg-opacity));
  }

  .sm\:hover\:tw-bg-red-100:hover {
    --bg-opacity: 1;
    background-color: #fff5f5;
    background-color: rgba(255, 245, 245, var(--bg-opacity));
  }

  .sm\:hover\:tw-bg-red-200:hover {
    --bg-opacity: 1;
    background-color: #fed7d7;
    background-color: rgba(254, 215, 215, var(--bg-opacity));
  }

  .sm\:hover\:tw-bg-red-300:hover {
    --bg-opacity: 1;
    background-color: #feb2b2;
    background-color: rgba(254, 178, 178, var(--bg-opacity));
  }

  .sm\:hover\:tw-bg-red-400:hover {
    --bg-opacity: 1;
    background-color: #fc8181;
    background-color: rgba(252, 129, 129, var(--bg-opacity));
  }

  .sm\:hover\:tw-bg-red-500:hover {
    --bg-opacity: 1;
    background-color: #f56565;
    background-color: rgba(245, 101, 101, var(--bg-opacity));
  }

  .sm\:hover\:tw-bg-red-600:hover {
    --bg-opacity: 1;
    background-color: #e53e3e;
    background-color: rgba(229, 62, 62, var(--bg-opacity));
  }

  .sm\:hover\:tw-bg-red-700:hover {
    --bg-opacity: 1;
    background-color: #c53030;
    background-color: rgba(197, 48, 48, var(--bg-opacity));
  }

  .sm\:hover\:tw-bg-red-800:hover {
    --bg-opacity: 1;
    background-color: #9b2c2c;
    background-color: rgba(155, 44, 44, var(--bg-opacity));
  }

  .sm\:hover\:tw-bg-red-900:hover {
    --bg-opacity: 1;
    background-color: #742a2a;
    background-color: rgba(116, 42, 42, var(--bg-opacity));
  }

  .sm\:hover\:tw-bg-orange-100:hover {
    --bg-opacity: 1;
    background-color: #fffaf0;
    background-color: rgba(255, 250, 240, var(--bg-opacity));
  }

  .sm\:hover\:tw-bg-orange-200:hover {
    --bg-opacity: 1;
    background-color: #feebc8;
    background-color: rgba(254, 235, 200, var(--bg-opacity));
  }

  .sm\:hover\:tw-bg-orange-300:hover {
    --bg-opacity: 1;
    background-color: #fbd38d;
    background-color: rgba(251, 211, 141, var(--bg-opacity));
  }

  .sm\:hover\:tw-bg-orange-400:hover {
    --bg-opacity: 1;
    background-color: #f6ad55;
    background-color: rgba(246, 173, 85, var(--bg-opacity));
  }

  .sm\:hover\:tw-bg-orange-500:hover {
    --bg-opacity: 1;
    background-color: #ed8936;
    background-color: rgba(237, 137, 54, var(--bg-opacity));
  }

  .sm\:hover\:tw-bg-orange-600:hover {
    --bg-opacity: 1;
    background-color: #dd6b20;
    background-color: rgba(221, 107, 32, var(--bg-opacity));
  }

  .sm\:hover\:tw-bg-orange-700:hover {
    --bg-opacity: 1;
    background-color: #c05621;
    background-color: rgba(192, 86, 33, var(--bg-opacity));
  }

  .sm\:hover\:tw-bg-orange-800:hover {
    --bg-opacity: 1;
    background-color: #9c4221;
    background-color: rgba(156, 66, 33, var(--bg-opacity));
  }

  .sm\:hover\:tw-bg-orange-900:hover {
    --bg-opacity: 1;
    background-color: #7b341e;
    background-color: rgba(123, 52, 30, var(--bg-opacity));
  }

  .sm\:hover\:tw-bg-yellow-100:hover {
    --bg-opacity: 1;
    background-color: #fffff0;
    background-color: rgba(255, 255, 240, var(--bg-opacity));
  }

  .sm\:hover\:tw-bg-yellow-200:hover {
    --bg-opacity: 1;
    background-color: #fefcbf;
    background-color: rgba(254, 252, 191, var(--bg-opacity));
  }

  .sm\:hover\:tw-bg-yellow-300:hover {
    --bg-opacity: 1;
    background-color: #faf089;
    background-color: rgba(250, 240, 137, var(--bg-opacity));
  }

  .sm\:hover\:tw-bg-yellow-400:hover {
    --bg-opacity: 1;
    background-color: #f6e05e;
    background-color: rgba(246, 224, 94, var(--bg-opacity));
  }

  .sm\:hover\:tw-bg-yellow-500:hover {
    --bg-opacity: 1;
    background-color: #ecc94b;
    background-color: rgba(236, 201, 75, var(--bg-opacity));
  }

  .sm\:hover\:tw-bg-yellow-600:hover {
    --bg-opacity: 1;
    background-color: #d69e2e;
    background-color: rgba(214, 158, 46, var(--bg-opacity));
  }

  .sm\:hover\:tw-bg-yellow-700:hover {
    --bg-opacity: 1;
    background-color: #b7791f;
    background-color: rgba(183, 121, 31, var(--bg-opacity));
  }

  .sm\:hover\:tw-bg-yellow-800:hover {
    --bg-opacity: 1;
    background-color: #975a16;
    background-color: rgba(151, 90, 22, var(--bg-opacity));
  }

  .sm\:hover\:tw-bg-yellow-900:hover {
    --bg-opacity: 1;
    background-color: #744210;
    background-color: rgba(116, 66, 16, var(--bg-opacity));
  }

  .sm\:hover\:tw-bg-green-100:hover {
    --bg-opacity: 1;
    background-color: #f0fff4;
    background-color: rgba(240, 255, 244, var(--bg-opacity));
  }

  .sm\:hover\:tw-bg-green-200:hover {
    --bg-opacity: 1;
    background-color: #c6f6d5;
    background-color: rgba(198, 246, 213, var(--bg-opacity));
  }

  .sm\:hover\:tw-bg-green-300:hover {
    --bg-opacity: 1;
    background-color: #9ae6b4;
    background-color: rgba(154, 230, 180, var(--bg-opacity));
  }

  .sm\:hover\:tw-bg-green-400:hover {
    --bg-opacity: 1;
    background-color: #68d391;
    background-color: rgba(104, 211, 145, var(--bg-opacity));
  }

  .sm\:hover\:tw-bg-green-500:hover {
    --bg-opacity: 1;
    background-color: #48bb78;
    background-color: rgba(72, 187, 120, var(--bg-opacity));
  }

  .sm\:hover\:tw-bg-green-600:hover {
    --bg-opacity: 1;
    background-color: #38a169;
    background-color: rgba(56, 161, 105, var(--bg-opacity));
  }

  .sm\:hover\:tw-bg-green-700:hover {
    --bg-opacity: 1;
    background-color: #2f855a;
    background-color: rgba(47, 133, 90, var(--bg-opacity));
  }

  .sm\:hover\:tw-bg-green-800:hover {
    --bg-opacity: 1;
    background-color: #276749;
    background-color: rgba(39, 103, 73, var(--bg-opacity));
  }

  .sm\:hover\:tw-bg-green-900:hover {
    --bg-opacity: 1;
    background-color: #22543d;
    background-color: rgba(34, 84, 61, var(--bg-opacity));
  }

  .sm\:hover\:tw-bg-teal-100:hover {
    --bg-opacity: 1;
    background-color: #e6fffa;
    background-color: rgba(230, 255, 250, var(--bg-opacity));
  }

  .sm\:hover\:tw-bg-teal-200:hover {
    --bg-opacity: 1;
    background-color: #b2f5ea;
    background-color: rgba(178, 245, 234, var(--bg-opacity));
  }

  .sm\:hover\:tw-bg-teal-300:hover {
    --bg-opacity: 1;
    background-color: #81e6d9;
    background-color: rgba(129, 230, 217, var(--bg-opacity));
  }

  .sm\:hover\:tw-bg-teal-400:hover {
    --bg-opacity: 1;
    background-color: #4fd1c5;
    background-color: rgba(79, 209, 197, var(--bg-opacity));
  }

  .sm\:hover\:tw-bg-teal-500:hover {
    --bg-opacity: 1;
    background-color: #38b2ac;
    background-color: rgba(56, 178, 172, var(--bg-opacity));
  }

  .sm\:hover\:tw-bg-teal-600:hover {
    --bg-opacity: 1;
    background-color: #319795;
    background-color: rgba(49, 151, 149, var(--bg-opacity));
  }

  .sm\:hover\:tw-bg-teal-700:hover {
    --bg-opacity: 1;
    background-color: #2c7a7b;
    background-color: rgba(44, 122, 123, var(--bg-opacity));
  }

  .sm\:hover\:tw-bg-teal-800:hover {
    --bg-opacity: 1;
    background-color: #285e61;
    background-color: rgba(40, 94, 97, var(--bg-opacity));
  }

  .sm\:hover\:tw-bg-teal-900:hover {
    --bg-opacity: 1;
    background-color: #234e52;
    background-color: rgba(35, 78, 82, var(--bg-opacity));
  }

  .sm\:hover\:tw-bg-blue-100:hover {
    --bg-opacity: 1;
    background-color: #ebf8ff;
    background-color: rgba(235, 248, 255, var(--bg-opacity));
  }

  .sm\:hover\:tw-bg-blue-200:hover {
    --bg-opacity: 1;
    background-color: #bee3f8;
    background-color: rgba(190, 227, 248, var(--bg-opacity));
  }

  .sm\:hover\:tw-bg-blue-300:hover {
    --bg-opacity: 1;
    background-color: #90cdf4;
    background-color: rgba(144, 205, 244, var(--bg-opacity));
  }

  .sm\:hover\:tw-bg-blue-400:hover {
    --bg-opacity: 1;
    background-color: #63b3ed;
    background-color: rgba(99, 179, 237, var(--bg-opacity));
  }

  .sm\:hover\:tw-bg-blue-500:hover {
    --bg-opacity: 1;
    background-color: #4299e1;
    background-color: rgba(66, 153, 225, var(--bg-opacity));
  }

  .sm\:hover\:tw-bg-blue-600:hover {
    --bg-opacity: 1;
    background-color: #3182ce;
    background-color: rgba(49, 130, 206, var(--bg-opacity));
  }

  .sm\:hover\:tw-bg-blue-700:hover {
    --bg-opacity: 1;
    background-color: #2b6cb0;
    background-color: rgba(43, 108, 176, var(--bg-opacity));
  }

  .sm\:hover\:tw-bg-blue-800:hover {
    --bg-opacity: 1;
    background-color: #2c5282;
    background-color: rgba(44, 82, 130, var(--bg-opacity));
  }

  .sm\:hover\:tw-bg-blue-900:hover {
    --bg-opacity: 1;
    background-color: #2a4365;
    background-color: rgba(42, 67, 101, var(--bg-opacity));
  }

  .sm\:hover\:tw-bg-indigo-100:hover {
    --bg-opacity: 1;
    background-color: #ebf4ff;
    background-color: rgba(235, 244, 255, var(--bg-opacity));
  }

  .sm\:hover\:tw-bg-indigo-200:hover {
    --bg-opacity: 1;
    background-color: #c3dafe;
    background-color: rgba(195, 218, 254, var(--bg-opacity));
  }

  .sm\:hover\:tw-bg-indigo-300:hover {
    --bg-opacity: 1;
    background-color: #a3bffa;
    background-color: rgba(163, 191, 250, var(--bg-opacity));
  }

  .sm\:hover\:tw-bg-indigo-400:hover {
    --bg-opacity: 1;
    background-color: #7f9cf5;
    background-color: rgba(127, 156, 245, var(--bg-opacity));
  }

  .sm\:hover\:tw-bg-indigo-500:hover {
    --bg-opacity: 1;
    background-color: #667eea;
    background-color: rgba(102, 126, 234, var(--bg-opacity));
  }

  .sm\:hover\:tw-bg-indigo-600:hover {
    --bg-opacity: 1;
    background-color: #5a67d8;
    background-color: rgba(90, 103, 216, var(--bg-opacity));
  }

  .sm\:hover\:tw-bg-indigo-700:hover {
    --bg-opacity: 1;
    background-color: #4c51bf;
    background-color: rgba(76, 81, 191, var(--bg-opacity));
  }

  .sm\:hover\:tw-bg-indigo-800:hover {
    --bg-opacity: 1;
    background-color: #434190;
    background-color: rgba(67, 65, 144, var(--bg-opacity));
  }

  .sm\:hover\:tw-bg-indigo-900:hover {
    --bg-opacity: 1;
    background-color: #3c366b;
    background-color: rgba(60, 54, 107, var(--bg-opacity));
  }

  .sm\:hover\:tw-bg-purple-100:hover {
    --bg-opacity: 1;
    background-color: #faf5ff;
    background-color: rgba(250, 245, 255, var(--bg-opacity));
  }

  .sm\:hover\:tw-bg-purple-200:hover {
    --bg-opacity: 1;
    background-color: #e9d8fd;
    background-color: rgba(233, 216, 253, var(--bg-opacity));
  }

  .sm\:hover\:tw-bg-purple-300:hover {
    --bg-opacity: 1;
    background-color: #d6bcfa;
    background-color: rgba(214, 188, 250, var(--bg-opacity));
  }

  .sm\:hover\:tw-bg-purple-400:hover {
    --bg-opacity: 1;
    background-color: #b794f4;
    background-color: rgba(183, 148, 244, var(--bg-opacity));
  }

  .sm\:hover\:tw-bg-purple-500:hover {
    --bg-opacity: 1;
    background-color: #9f7aea;
    background-color: rgba(159, 122, 234, var(--bg-opacity));
  }

  .sm\:hover\:tw-bg-purple-600:hover {
    --bg-opacity: 1;
    background-color: #805ad5;
    background-color: rgba(128, 90, 213, var(--bg-opacity));
  }

  .sm\:hover\:tw-bg-purple-700:hover {
    --bg-opacity: 1;
    background-color: #6b46c1;
    background-color: rgba(107, 70, 193, var(--bg-opacity));
  }

  .sm\:hover\:tw-bg-purple-800:hover {
    --bg-opacity: 1;
    background-color: #553c9a;
    background-color: rgba(85, 60, 154, var(--bg-opacity));
  }

  .sm\:hover\:tw-bg-purple-900:hover {
    --bg-opacity: 1;
    background-color: #44337a;
    background-color: rgba(68, 51, 122, var(--bg-opacity));
  }

  .sm\:hover\:tw-bg-pink-100:hover {
    --bg-opacity: 1;
    background-color: #fff5f7;
    background-color: rgba(255, 245, 247, var(--bg-opacity));
  }

  .sm\:hover\:tw-bg-pink-200:hover {
    --bg-opacity: 1;
    background-color: #fed7e2;
    background-color: rgba(254, 215, 226, var(--bg-opacity));
  }

  .sm\:hover\:tw-bg-pink-300:hover {
    --bg-opacity: 1;
    background-color: #fbb6ce;
    background-color: rgba(251, 182, 206, var(--bg-opacity));
  }

  .sm\:hover\:tw-bg-pink-400:hover {
    --bg-opacity: 1;
    background-color: #f687b3;
    background-color: rgba(246, 135, 179, var(--bg-opacity));
  }

  .sm\:hover\:tw-bg-pink-500:hover {
    --bg-opacity: 1;
    background-color: #ed64a6;
    background-color: rgba(237, 100, 166, var(--bg-opacity));
  }

  .sm\:hover\:tw-bg-pink-600:hover {
    --bg-opacity: 1;
    background-color: #d53f8c;
    background-color: rgba(213, 63, 140, var(--bg-opacity));
  }

  .sm\:hover\:tw-bg-pink-700:hover {
    --bg-opacity: 1;
    background-color: #b83280;
    background-color: rgba(184, 50, 128, var(--bg-opacity));
  }

  .sm\:hover\:tw-bg-pink-800:hover {
    --bg-opacity: 1;
    background-color: #97266d;
    background-color: rgba(151, 38, 109, var(--bg-opacity));
  }

  .sm\:hover\:tw-bg-pink-900:hover {
    --bg-opacity: 1;
    background-color: #702459;
    background-color: rgba(112, 36, 89, var(--bg-opacity));
  }

  .sm\:focus\:tw-bg-transparent:focus {
    background-color: transparent;
  }

  .sm\:focus\:tw-bg-current:focus {
    background-color: currentColor;
  }

  .sm\:focus\:tw-bg-black:focus {
    --bg-opacity: 1;
    background-color: #000;
    background-color: rgba(0, 0, 0, var(--bg-opacity));
  }

  .sm\:focus\:tw-bg-white:focus {
    --bg-opacity: 1;
    background-color: #fff;
    background-color: rgba(255, 255, 255, var(--bg-opacity));
  }

  .sm\:focus\:tw-bg-gray-100:focus {
    --bg-opacity: 1;
    background-color: #f7fafc;
    background-color: rgba(247, 250, 252, var(--bg-opacity));
  }

  .sm\:focus\:tw-bg-gray-200:focus {
    --bg-opacity: 1;
    background-color: #edf2f7;
    background-color: rgba(237, 242, 247, var(--bg-opacity));
  }

  .sm\:focus\:tw-bg-gray-300:focus {
    --bg-opacity: 1;
    background-color: #e2e8f0;
    background-color: rgba(226, 232, 240, var(--bg-opacity));
  }

  .sm\:focus\:tw-bg-gray-400:focus {
    --bg-opacity: 1;
    background-color: #cbd5e0;
    background-color: rgba(203, 213, 224, var(--bg-opacity));
  }

  .sm\:focus\:tw-bg-gray-500:focus {
    --bg-opacity: 1;
    background-color: #a0aec0;
    background-color: rgba(160, 174, 192, var(--bg-opacity));
  }

  .sm\:focus\:tw-bg-gray-600:focus {
    --bg-opacity: 1;
    background-color: #718096;
    background-color: rgba(113, 128, 150, var(--bg-opacity));
  }

  .sm\:focus\:tw-bg-gray-700:focus {
    --bg-opacity: 1;
    background-color: #4a5568;
    background-color: rgba(74, 85, 104, var(--bg-opacity));
  }

  .sm\:focus\:tw-bg-gray-800:focus {
    --bg-opacity: 1;
    background-color: #2d3748;
    background-color: rgba(45, 55, 72, var(--bg-opacity));
  }

  .sm\:focus\:tw-bg-gray-900:focus {
    --bg-opacity: 1;
    background-color: #1a202c;
    background-color: rgba(26, 32, 44, var(--bg-opacity));
  }

  .sm\:focus\:tw-bg-red-100:focus {
    --bg-opacity: 1;
    background-color: #fff5f5;
    background-color: rgba(255, 245, 245, var(--bg-opacity));
  }

  .sm\:focus\:tw-bg-red-200:focus {
    --bg-opacity: 1;
    background-color: #fed7d7;
    background-color: rgba(254, 215, 215, var(--bg-opacity));
  }

  .sm\:focus\:tw-bg-red-300:focus {
    --bg-opacity: 1;
    background-color: #feb2b2;
    background-color: rgba(254, 178, 178, var(--bg-opacity));
  }

  .sm\:focus\:tw-bg-red-400:focus {
    --bg-opacity: 1;
    background-color: #fc8181;
    background-color: rgba(252, 129, 129, var(--bg-opacity));
  }

  .sm\:focus\:tw-bg-red-500:focus {
    --bg-opacity: 1;
    background-color: #f56565;
    background-color: rgba(245, 101, 101, var(--bg-opacity));
  }

  .sm\:focus\:tw-bg-red-600:focus {
    --bg-opacity: 1;
    background-color: #e53e3e;
    background-color: rgba(229, 62, 62, var(--bg-opacity));
  }

  .sm\:focus\:tw-bg-red-700:focus {
    --bg-opacity: 1;
    background-color: #c53030;
    background-color: rgba(197, 48, 48, var(--bg-opacity));
  }

  .sm\:focus\:tw-bg-red-800:focus {
    --bg-opacity: 1;
    background-color: #9b2c2c;
    background-color: rgba(155, 44, 44, var(--bg-opacity));
  }

  .sm\:focus\:tw-bg-red-900:focus {
    --bg-opacity: 1;
    background-color: #742a2a;
    background-color: rgba(116, 42, 42, var(--bg-opacity));
  }

  .sm\:focus\:tw-bg-orange-100:focus {
    --bg-opacity: 1;
    background-color: #fffaf0;
    background-color: rgba(255, 250, 240, var(--bg-opacity));
  }

  .sm\:focus\:tw-bg-orange-200:focus {
    --bg-opacity: 1;
    background-color: #feebc8;
    background-color: rgba(254, 235, 200, var(--bg-opacity));
  }

  .sm\:focus\:tw-bg-orange-300:focus {
    --bg-opacity: 1;
    background-color: #fbd38d;
    background-color: rgba(251, 211, 141, var(--bg-opacity));
  }

  .sm\:focus\:tw-bg-orange-400:focus {
    --bg-opacity: 1;
    background-color: #f6ad55;
    background-color: rgba(246, 173, 85, var(--bg-opacity));
  }

  .sm\:focus\:tw-bg-orange-500:focus {
    --bg-opacity: 1;
    background-color: #ed8936;
    background-color: rgba(237, 137, 54, var(--bg-opacity));
  }

  .sm\:focus\:tw-bg-orange-600:focus {
    --bg-opacity: 1;
    background-color: #dd6b20;
    background-color: rgba(221, 107, 32, var(--bg-opacity));
  }

  .sm\:focus\:tw-bg-orange-700:focus {
    --bg-opacity: 1;
    background-color: #c05621;
    background-color: rgba(192, 86, 33, var(--bg-opacity));
  }

  .sm\:focus\:tw-bg-orange-800:focus {
    --bg-opacity: 1;
    background-color: #9c4221;
    background-color: rgba(156, 66, 33, var(--bg-opacity));
  }

  .sm\:focus\:tw-bg-orange-900:focus {
    --bg-opacity: 1;
    background-color: #7b341e;
    background-color: rgba(123, 52, 30, var(--bg-opacity));
  }

  .sm\:focus\:tw-bg-yellow-100:focus {
    --bg-opacity: 1;
    background-color: #fffff0;
    background-color: rgba(255, 255, 240, var(--bg-opacity));
  }

  .sm\:focus\:tw-bg-yellow-200:focus {
    --bg-opacity: 1;
    background-color: #fefcbf;
    background-color: rgba(254, 252, 191, var(--bg-opacity));
  }

  .sm\:focus\:tw-bg-yellow-300:focus {
    --bg-opacity: 1;
    background-color: #faf089;
    background-color: rgba(250, 240, 137, var(--bg-opacity));
  }

  .sm\:focus\:tw-bg-yellow-400:focus {
    --bg-opacity: 1;
    background-color: #f6e05e;
    background-color: rgba(246, 224, 94, var(--bg-opacity));
  }

  .sm\:focus\:tw-bg-yellow-500:focus {
    --bg-opacity: 1;
    background-color: #ecc94b;
    background-color: rgba(236, 201, 75, var(--bg-opacity));
  }

  .sm\:focus\:tw-bg-yellow-600:focus {
    --bg-opacity: 1;
    background-color: #d69e2e;
    background-color: rgba(214, 158, 46, var(--bg-opacity));
  }

  .sm\:focus\:tw-bg-yellow-700:focus {
    --bg-opacity: 1;
    background-color: #b7791f;
    background-color: rgba(183, 121, 31, var(--bg-opacity));
  }

  .sm\:focus\:tw-bg-yellow-800:focus {
    --bg-opacity: 1;
    background-color: #975a16;
    background-color: rgba(151, 90, 22, var(--bg-opacity));
  }

  .sm\:focus\:tw-bg-yellow-900:focus {
    --bg-opacity: 1;
    background-color: #744210;
    background-color: rgba(116, 66, 16, var(--bg-opacity));
  }

  .sm\:focus\:tw-bg-green-100:focus {
    --bg-opacity: 1;
    background-color: #f0fff4;
    background-color: rgba(240, 255, 244, var(--bg-opacity));
  }

  .sm\:focus\:tw-bg-green-200:focus {
    --bg-opacity: 1;
    background-color: #c6f6d5;
    background-color: rgba(198, 246, 213, var(--bg-opacity));
  }

  .sm\:focus\:tw-bg-green-300:focus {
    --bg-opacity: 1;
    background-color: #9ae6b4;
    background-color: rgba(154, 230, 180, var(--bg-opacity));
  }

  .sm\:focus\:tw-bg-green-400:focus {
    --bg-opacity: 1;
    background-color: #68d391;
    background-color: rgba(104, 211, 145, var(--bg-opacity));
  }

  .sm\:focus\:tw-bg-green-500:focus {
    --bg-opacity: 1;
    background-color: #48bb78;
    background-color: rgba(72, 187, 120, var(--bg-opacity));
  }

  .sm\:focus\:tw-bg-green-600:focus {
    --bg-opacity: 1;
    background-color: #38a169;
    background-color: rgba(56, 161, 105, var(--bg-opacity));
  }

  .sm\:focus\:tw-bg-green-700:focus {
    --bg-opacity: 1;
    background-color: #2f855a;
    background-color: rgba(47, 133, 90, var(--bg-opacity));
  }

  .sm\:focus\:tw-bg-green-800:focus {
    --bg-opacity: 1;
    background-color: #276749;
    background-color: rgba(39, 103, 73, var(--bg-opacity));
  }

  .sm\:focus\:tw-bg-green-900:focus {
    --bg-opacity: 1;
    background-color: #22543d;
    background-color: rgba(34, 84, 61, var(--bg-opacity));
  }

  .sm\:focus\:tw-bg-teal-100:focus {
    --bg-opacity: 1;
    background-color: #e6fffa;
    background-color: rgba(230, 255, 250, var(--bg-opacity));
  }

  .sm\:focus\:tw-bg-teal-200:focus {
    --bg-opacity: 1;
    background-color: #b2f5ea;
    background-color: rgba(178, 245, 234, var(--bg-opacity));
  }

  .sm\:focus\:tw-bg-teal-300:focus {
    --bg-opacity: 1;
    background-color: #81e6d9;
    background-color: rgba(129, 230, 217, var(--bg-opacity));
  }

  .sm\:focus\:tw-bg-teal-400:focus {
    --bg-opacity: 1;
    background-color: #4fd1c5;
    background-color: rgba(79, 209, 197, var(--bg-opacity));
  }

  .sm\:focus\:tw-bg-teal-500:focus {
    --bg-opacity: 1;
    background-color: #38b2ac;
    background-color: rgba(56, 178, 172, var(--bg-opacity));
  }

  .sm\:focus\:tw-bg-teal-600:focus {
    --bg-opacity: 1;
    background-color: #319795;
    background-color: rgba(49, 151, 149, var(--bg-opacity));
  }

  .sm\:focus\:tw-bg-teal-700:focus {
    --bg-opacity: 1;
    background-color: #2c7a7b;
    background-color: rgba(44, 122, 123, var(--bg-opacity));
  }

  .sm\:focus\:tw-bg-teal-800:focus {
    --bg-opacity: 1;
    background-color: #285e61;
    background-color: rgba(40, 94, 97, var(--bg-opacity));
  }

  .sm\:focus\:tw-bg-teal-900:focus {
    --bg-opacity: 1;
    background-color: #234e52;
    background-color: rgba(35, 78, 82, var(--bg-opacity));
  }

  .sm\:focus\:tw-bg-blue-100:focus {
    --bg-opacity: 1;
    background-color: #ebf8ff;
    background-color: rgba(235, 248, 255, var(--bg-opacity));
  }

  .sm\:focus\:tw-bg-blue-200:focus {
    --bg-opacity: 1;
    background-color: #bee3f8;
    background-color: rgba(190, 227, 248, var(--bg-opacity));
  }

  .sm\:focus\:tw-bg-blue-300:focus {
    --bg-opacity: 1;
    background-color: #90cdf4;
    background-color: rgba(144, 205, 244, var(--bg-opacity));
  }

  .sm\:focus\:tw-bg-blue-400:focus {
    --bg-opacity: 1;
    background-color: #63b3ed;
    background-color: rgba(99, 179, 237, var(--bg-opacity));
  }

  .sm\:focus\:tw-bg-blue-500:focus {
    --bg-opacity: 1;
    background-color: #4299e1;
    background-color: rgba(66, 153, 225, var(--bg-opacity));
  }

  .sm\:focus\:tw-bg-blue-600:focus {
    --bg-opacity: 1;
    background-color: #3182ce;
    background-color: rgba(49, 130, 206, var(--bg-opacity));
  }

  .sm\:focus\:tw-bg-blue-700:focus {
    --bg-opacity: 1;
    background-color: #2b6cb0;
    background-color: rgba(43, 108, 176, var(--bg-opacity));
  }

  .sm\:focus\:tw-bg-blue-800:focus {
    --bg-opacity: 1;
    background-color: #2c5282;
    background-color: rgba(44, 82, 130, var(--bg-opacity));
  }

  .sm\:focus\:tw-bg-blue-900:focus {
    --bg-opacity: 1;
    background-color: #2a4365;
    background-color: rgba(42, 67, 101, var(--bg-opacity));
  }

  .sm\:focus\:tw-bg-indigo-100:focus {
    --bg-opacity: 1;
    background-color: #ebf4ff;
    background-color: rgba(235, 244, 255, var(--bg-opacity));
  }

  .sm\:focus\:tw-bg-indigo-200:focus {
    --bg-opacity: 1;
    background-color: #c3dafe;
    background-color: rgba(195, 218, 254, var(--bg-opacity));
  }

  .sm\:focus\:tw-bg-indigo-300:focus {
    --bg-opacity: 1;
    background-color: #a3bffa;
    background-color: rgba(163, 191, 250, var(--bg-opacity));
  }

  .sm\:focus\:tw-bg-indigo-400:focus {
    --bg-opacity: 1;
    background-color: #7f9cf5;
    background-color: rgba(127, 156, 245, var(--bg-opacity));
  }

  .sm\:focus\:tw-bg-indigo-500:focus {
    --bg-opacity: 1;
    background-color: #667eea;
    background-color: rgba(102, 126, 234, var(--bg-opacity));
  }

  .sm\:focus\:tw-bg-indigo-600:focus {
    --bg-opacity: 1;
    background-color: #5a67d8;
    background-color: rgba(90, 103, 216, var(--bg-opacity));
  }

  .sm\:focus\:tw-bg-indigo-700:focus {
    --bg-opacity: 1;
    background-color: #4c51bf;
    background-color: rgba(76, 81, 191, var(--bg-opacity));
  }

  .sm\:focus\:tw-bg-indigo-800:focus {
    --bg-opacity: 1;
    background-color: #434190;
    background-color: rgba(67, 65, 144, var(--bg-opacity));
  }

  .sm\:focus\:tw-bg-indigo-900:focus {
    --bg-opacity: 1;
    background-color: #3c366b;
    background-color: rgba(60, 54, 107, var(--bg-opacity));
  }

  .sm\:focus\:tw-bg-purple-100:focus {
    --bg-opacity: 1;
    background-color: #faf5ff;
    background-color: rgba(250, 245, 255, var(--bg-opacity));
  }

  .sm\:focus\:tw-bg-purple-200:focus {
    --bg-opacity: 1;
    background-color: #e9d8fd;
    background-color: rgba(233, 216, 253, var(--bg-opacity));
  }

  .sm\:focus\:tw-bg-purple-300:focus {
    --bg-opacity: 1;
    background-color: #d6bcfa;
    background-color: rgba(214, 188, 250, var(--bg-opacity));
  }

  .sm\:focus\:tw-bg-purple-400:focus {
    --bg-opacity: 1;
    background-color: #b794f4;
    background-color: rgba(183, 148, 244, var(--bg-opacity));
  }

  .sm\:focus\:tw-bg-purple-500:focus {
    --bg-opacity: 1;
    background-color: #9f7aea;
    background-color: rgba(159, 122, 234, var(--bg-opacity));
  }

  .sm\:focus\:tw-bg-purple-600:focus {
    --bg-opacity: 1;
    background-color: #805ad5;
    background-color: rgba(128, 90, 213, var(--bg-opacity));
  }

  .sm\:focus\:tw-bg-purple-700:focus {
    --bg-opacity: 1;
    background-color: #6b46c1;
    background-color: rgba(107, 70, 193, var(--bg-opacity));
  }

  .sm\:focus\:tw-bg-purple-800:focus {
    --bg-opacity: 1;
    background-color: #553c9a;
    background-color: rgba(85, 60, 154, var(--bg-opacity));
  }

  .sm\:focus\:tw-bg-purple-900:focus {
    --bg-opacity: 1;
    background-color: #44337a;
    background-color: rgba(68, 51, 122, var(--bg-opacity));
  }

  .sm\:focus\:tw-bg-pink-100:focus {
    --bg-opacity: 1;
    background-color: #fff5f7;
    background-color: rgba(255, 245, 247, var(--bg-opacity));
  }

  .sm\:focus\:tw-bg-pink-200:focus {
    --bg-opacity: 1;
    background-color: #fed7e2;
    background-color: rgba(254, 215, 226, var(--bg-opacity));
  }

  .sm\:focus\:tw-bg-pink-300:focus {
    --bg-opacity: 1;
    background-color: #fbb6ce;
    background-color: rgba(251, 182, 206, var(--bg-opacity));
  }

  .sm\:focus\:tw-bg-pink-400:focus {
    --bg-opacity: 1;
    background-color: #f687b3;
    background-color: rgba(246, 135, 179, var(--bg-opacity));
  }

  .sm\:focus\:tw-bg-pink-500:focus {
    --bg-opacity: 1;
    background-color: #ed64a6;
    background-color: rgba(237, 100, 166, var(--bg-opacity));
  }

  .sm\:focus\:tw-bg-pink-600:focus {
    --bg-opacity: 1;
    background-color: #d53f8c;
    background-color: rgba(213, 63, 140, var(--bg-opacity));
  }

  .sm\:focus\:tw-bg-pink-700:focus {
    --bg-opacity: 1;
    background-color: #b83280;
    background-color: rgba(184, 50, 128, var(--bg-opacity));
  }

  .sm\:focus\:tw-bg-pink-800:focus {
    --bg-opacity: 1;
    background-color: #97266d;
    background-color: rgba(151, 38, 109, var(--bg-opacity));
  }

  .sm\:focus\:tw-bg-pink-900:focus {
    --bg-opacity: 1;
    background-color: #702459;
    background-color: rgba(112, 36, 89, var(--bg-opacity));
  }

  .sm\:tw-bg-none {
    background-image: none;
  }

  .sm\:tw-bg-gradient-to-t {
    background-image: linear-gradient(to top, var(--gradient-color-stops));
  }

  .sm\:tw-bg-gradient-to-tr {
    background-image: linear-gradient(to top right, var(--gradient-color-stops));
  }

  .sm\:tw-bg-gradient-to-r {
    background-image: linear-gradient(to right, var(--gradient-color-stops));
  }

  .sm\:tw-bg-gradient-to-br {
    background-image: linear-gradient(to bottom right, var(--gradient-color-stops));
  }

  .sm\:tw-bg-gradient-to-b {
    background-image: linear-gradient(to bottom, var(--gradient-color-stops));
  }

  .sm\:tw-bg-gradient-to-bl {
    background-image: linear-gradient(to bottom left, var(--gradient-color-stops));
  }

  .sm\:tw-bg-gradient-to-l {
    background-image: linear-gradient(to left, var(--gradient-color-stops));
  }

  .sm\:tw-bg-gradient-to-tl {
    background-image: linear-gradient(to top left, var(--gradient-color-stops));
  }

  .sm\:tw-bg-wave-pattern {
    background-image: url('../assets/images/nawbar_waves.svg');
  }

  .sm\:tw-from-transparent {
    --gradient-from-color: transparent;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(0, 0, 0, 0));
  }

  .sm\:tw-from-current {
    --gradient-from-color: currentColor;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0));
  }

  .sm\:tw-from-black {
    --gradient-from-color: #000;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(0, 0, 0, 0));
  }

  .sm\:tw-from-white {
    --gradient-from-color: #fff;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0));
  }

  .sm\:tw-from-gray-100 {
    --gradient-from-color: #f7fafc;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(247, 250, 252, 0));
  }

  .sm\:tw-from-gray-200 {
    --gradient-from-color: #edf2f7;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(237, 242, 247, 0));
  }

  .sm\:tw-from-gray-300 {
    --gradient-from-color: #e2e8f0;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(226, 232, 240, 0));
  }

  .sm\:tw-from-gray-400 {
    --gradient-from-color: #cbd5e0;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(203, 213, 224, 0));
  }

  .sm\:tw-from-gray-500 {
    --gradient-from-color: #a0aec0;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(160, 174, 192, 0));
  }

  .sm\:tw-from-gray-600 {
    --gradient-from-color: #718096;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(113, 128, 150, 0));
  }

  .sm\:tw-from-gray-700 {
    --gradient-from-color: #4a5568;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(74, 85, 104, 0));
  }

  .sm\:tw-from-gray-800 {
    --gradient-from-color: #2d3748;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(45, 55, 72, 0));
  }

  .sm\:tw-from-gray-900 {
    --gradient-from-color: #1a202c;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(26, 32, 44, 0));
  }

  .sm\:tw-from-red-100 {
    --gradient-from-color: #fff5f5;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 245, 245, 0));
  }

  .sm\:tw-from-red-200 {
    --gradient-from-color: #fed7d7;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(254, 215, 215, 0));
  }

  .sm\:tw-from-red-300 {
    --gradient-from-color: #feb2b2;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(254, 178, 178, 0));
  }

  .sm\:tw-from-red-400 {
    --gradient-from-color: #fc8181;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(252, 129, 129, 0));
  }

  .sm\:tw-from-red-500 {
    --gradient-from-color: #f56565;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(245, 101, 101, 0));
  }

  .sm\:tw-from-red-600 {
    --gradient-from-color: #e53e3e;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(229, 62, 62, 0));
  }

  .sm\:tw-from-red-700 {
    --gradient-from-color: #c53030;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(197, 48, 48, 0));
  }

  .sm\:tw-from-red-800 {
    --gradient-from-color: #9b2c2c;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(155, 44, 44, 0));
  }

  .sm\:tw-from-red-900 {
    --gradient-from-color: #742a2a;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(116, 42, 42, 0));
  }

  .sm\:tw-from-orange-100 {
    --gradient-from-color: #fffaf0;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 250, 240, 0));
  }

  .sm\:tw-from-orange-200 {
    --gradient-from-color: #feebc8;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(254, 235, 200, 0));
  }

  .sm\:tw-from-orange-300 {
    --gradient-from-color: #fbd38d;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(251, 211, 141, 0));
  }

  .sm\:tw-from-orange-400 {
    --gradient-from-color: #f6ad55;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(246, 173, 85, 0));
  }

  .sm\:tw-from-orange-500 {
    --gradient-from-color: #ed8936;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(237, 137, 54, 0));
  }

  .sm\:tw-from-orange-600 {
    --gradient-from-color: #dd6b20;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(221, 107, 32, 0));
  }

  .sm\:tw-from-orange-700 {
    --gradient-from-color: #c05621;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(192, 86, 33, 0));
  }

  .sm\:tw-from-orange-800 {
    --gradient-from-color: #9c4221;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(156, 66, 33, 0));
  }

  .sm\:tw-from-orange-900 {
    --gradient-from-color: #7b341e;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(123, 52, 30, 0));
  }

  .sm\:tw-from-yellow-100 {
    --gradient-from-color: #fffff0;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 240, 0));
  }

  .sm\:tw-from-yellow-200 {
    --gradient-from-color: #fefcbf;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(254, 252, 191, 0));
  }

  .sm\:tw-from-yellow-300 {
    --gradient-from-color: #faf089;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(250, 240, 137, 0));
  }

  .sm\:tw-from-yellow-400 {
    --gradient-from-color: #f6e05e;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(246, 224, 94, 0));
  }

  .sm\:tw-from-yellow-500 {
    --gradient-from-color: #ecc94b;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(236, 201, 75, 0));
  }

  .sm\:tw-from-yellow-600 {
    --gradient-from-color: #d69e2e;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(214, 158, 46, 0));
  }

  .sm\:tw-from-yellow-700 {
    --gradient-from-color: #b7791f;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(183, 121, 31, 0));
  }

  .sm\:tw-from-yellow-800 {
    --gradient-from-color: #975a16;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(151, 90, 22, 0));
  }

  .sm\:tw-from-yellow-900 {
    --gradient-from-color: #744210;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(116, 66, 16, 0));
  }

  .sm\:tw-from-green-100 {
    --gradient-from-color: #f0fff4;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(240, 255, 244, 0));
  }

  .sm\:tw-from-green-200 {
    --gradient-from-color: #c6f6d5;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(198, 246, 213, 0));
  }

  .sm\:tw-from-green-300 {
    --gradient-from-color: #9ae6b4;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(154, 230, 180, 0));
  }

  .sm\:tw-from-green-400 {
    --gradient-from-color: #68d391;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(104, 211, 145, 0));
  }

  .sm\:tw-from-green-500 {
    --gradient-from-color: #48bb78;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(72, 187, 120, 0));
  }

  .sm\:tw-from-green-600 {
    --gradient-from-color: #38a169;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(56, 161, 105, 0));
  }

  .sm\:tw-from-green-700 {
    --gradient-from-color: #2f855a;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(47, 133, 90, 0));
  }

  .sm\:tw-from-green-800 {
    --gradient-from-color: #276749;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(39, 103, 73, 0));
  }

  .sm\:tw-from-green-900 {
    --gradient-from-color: #22543d;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(34, 84, 61, 0));
  }

  .sm\:tw-from-teal-100 {
    --gradient-from-color: #e6fffa;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(230, 255, 250, 0));
  }

  .sm\:tw-from-teal-200 {
    --gradient-from-color: #b2f5ea;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(178, 245, 234, 0));
  }

  .sm\:tw-from-teal-300 {
    --gradient-from-color: #81e6d9;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(129, 230, 217, 0));
  }

  .sm\:tw-from-teal-400 {
    --gradient-from-color: #4fd1c5;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(79, 209, 197, 0));
  }

  .sm\:tw-from-teal-500 {
    --gradient-from-color: #38b2ac;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(56, 178, 172, 0));
  }

  .sm\:tw-from-teal-600 {
    --gradient-from-color: #319795;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(49, 151, 149, 0));
  }

  .sm\:tw-from-teal-700 {
    --gradient-from-color: #2c7a7b;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(44, 122, 123, 0));
  }

  .sm\:tw-from-teal-800 {
    --gradient-from-color: #285e61;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(40, 94, 97, 0));
  }

  .sm\:tw-from-teal-900 {
    --gradient-from-color: #234e52;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(35, 78, 82, 0));
  }

  .sm\:tw-from-blue-100 {
    --gradient-from-color: #ebf8ff;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(235, 248, 255, 0));
  }

  .sm\:tw-from-blue-200 {
    --gradient-from-color: #bee3f8;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(190, 227, 248, 0));
  }

  .sm\:tw-from-blue-300 {
    --gradient-from-color: #90cdf4;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(144, 205, 244, 0));
  }

  .sm\:tw-from-blue-400 {
    --gradient-from-color: #63b3ed;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(99, 179, 237, 0));
  }

  .sm\:tw-from-blue-500 {
    --gradient-from-color: #4299e1;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(66, 153, 225, 0));
  }

  .sm\:tw-from-blue-600 {
    --gradient-from-color: #3182ce;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(49, 130, 206, 0));
  }

  .sm\:tw-from-blue-700 {
    --gradient-from-color: #2b6cb0;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(43, 108, 176, 0));
  }

  .sm\:tw-from-blue-800 {
    --gradient-from-color: #2c5282;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(44, 82, 130, 0));
  }

  .sm\:tw-from-blue-900 {
    --gradient-from-color: #2a4365;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(42, 67, 101, 0));
  }

  .sm\:tw-from-indigo-100 {
    --gradient-from-color: #ebf4ff;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(235, 244, 255, 0));
  }

  .sm\:tw-from-indigo-200 {
    --gradient-from-color: #c3dafe;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(195, 218, 254, 0));
  }

  .sm\:tw-from-indigo-300 {
    --gradient-from-color: #a3bffa;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(163, 191, 250, 0));
  }

  .sm\:tw-from-indigo-400 {
    --gradient-from-color: #7f9cf5;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(127, 156, 245, 0));
  }

  .sm\:tw-from-indigo-500 {
    --gradient-from-color: #667eea;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(102, 126, 234, 0));
  }

  .sm\:tw-from-indigo-600 {
    --gradient-from-color: #5a67d8;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(90, 103, 216, 0));
  }

  .sm\:tw-from-indigo-700 {
    --gradient-from-color: #4c51bf;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(76, 81, 191, 0));
  }

  .sm\:tw-from-indigo-800 {
    --gradient-from-color: #434190;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(67, 65, 144, 0));
  }

  .sm\:tw-from-indigo-900 {
    --gradient-from-color: #3c366b;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(60, 54, 107, 0));
  }

  .sm\:tw-from-purple-100 {
    --gradient-from-color: #faf5ff;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(250, 245, 255, 0));
  }

  .sm\:tw-from-purple-200 {
    --gradient-from-color: #e9d8fd;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(233, 216, 253, 0));
  }

  .sm\:tw-from-purple-300 {
    --gradient-from-color: #d6bcfa;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(214, 188, 250, 0));
  }

  .sm\:tw-from-purple-400 {
    --gradient-from-color: #b794f4;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(183, 148, 244, 0));
  }

  .sm\:tw-from-purple-500 {
    --gradient-from-color: #9f7aea;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(159, 122, 234, 0));
  }

  .sm\:tw-from-purple-600 {
    --gradient-from-color: #805ad5;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(128, 90, 213, 0));
  }

  .sm\:tw-from-purple-700 {
    --gradient-from-color: #6b46c1;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(107, 70, 193, 0));
  }

  .sm\:tw-from-purple-800 {
    --gradient-from-color: #553c9a;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(85, 60, 154, 0));
  }

  .sm\:tw-from-purple-900 {
    --gradient-from-color: #44337a;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(68, 51, 122, 0));
  }

  .sm\:tw-from-pink-100 {
    --gradient-from-color: #fff5f7;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 245, 247, 0));
  }

  .sm\:tw-from-pink-200 {
    --gradient-from-color: #fed7e2;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(254, 215, 226, 0));
  }

  .sm\:tw-from-pink-300 {
    --gradient-from-color: #fbb6ce;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(251, 182, 206, 0));
  }

  .sm\:tw-from-pink-400 {
    --gradient-from-color: #f687b3;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(246, 135, 179, 0));
  }

  .sm\:tw-from-pink-500 {
    --gradient-from-color: #ed64a6;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(237, 100, 166, 0));
  }

  .sm\:tw-from-pink-600 {
    --gradient-from-color: #d53f8c;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(213, 63, 140, 0));
  }

  .sm\:tw-from-pink-700 {
    --gradient-from-color: #b83280;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(184, 50, 128, 0));
  }

  .sm\:tw-from-pink-800 {
    --gradient-from-color: #97266d;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(151, 38, 109, 0));
  }

  .sm\:tw-from-pink-900 {
    --gradient-from-color: #702459;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(112, 36, 89, 0));
  }

  .sm\:tw-via-transparent {
    --gradient-via-color: transparent;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(0, 0, 0, 0));
  }

  .sm\:tw-via-current {
    --gradient-via-color: currentColor;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0));
  }

  .sm\:tw-via-black {
    --gradient-via-color: #000;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(0, 0, 0, 0));
  }

  .sm\:tw-via-white {
    --gradient-via-color: #fff;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0));
  }

  .sm\:tw-via-gray-100 {
    --gradient-via-color: #f7fafc;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(247, 250, 252, 0));
  }

  .sm\:tw-via-gray-200 {
    --gradient-via-color: #edf2f7;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(237, 242, 247, 0));
  }

  .sm\:tw-via-gray-300 {
    --gradient-via-color: #e2e8f0;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(226, 232, 240, 0));
  }

  .sm\:tw-via-gray-400 {
    --gradient-via-color: #cbd5e0;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(203, 213, 224, 0));
  }

  .sm\:tw-via-gray-500 {
    --gradient-via-color: #a0aec0;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(160, 174, 192, 0));
  }

  .sm\:tw-via-gray-600 {
    --gradient-via-color: #718096;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(113, 128, 150, 0));
  }

  .sm\:tw-via-gray-700 {
    --gradient-via-color: #4a5568;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(74, 85, 104, 0));
  }

  .sm\:tw-via-gray-800 {
    --gradient-via-color: #2d3748;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(45, 55, 72, 0));
  }

  .sm\:tw-via-gray-900 {
    --gradient-via-color: #1a202c;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(26, 32, 44, 0));
  }

  .sm\:tw-via-red-100 {
    --gradient-via-color: #fff5f5;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 245, 245, 0));
  }

  .sm\:tw-via-red-200 {
    --gradient-via-color: #fed7d7;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(254, 215, 215, 0));
  }

  .sm\:tw-via-red-300 {
    --gradient-via-color: #feb2b2;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(254, 178, 178, 0));
  }

  .sm\:tw-via-red-400 {
    --gradient-via-color: #fc8181;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(252, 129, 129, 0));
  }

  .sm\:tw-via-red-500 {
    --gradient-via-color: #f56565;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(245, 101, 101, 0));
  }

  .sm\:tw-via-red-600 {
    --gradient-via-color: #e53e3e;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(229, 62, 62, 0));
  }

  .sm\:tw-via-red-700 {
    --gradient-via-color: #c53030;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(197, 48, 48, 0));
  }

  .sm\:tw-via-red-800 {
    --gradient-via-color: #9b2c2c;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(155, 44, 44, 0));
  }

  .sm\:tw-via-red-900 {
    --gradient-via-color: #742a2a;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(116, 42, 42, 0));
  }

  .sm\:tw-via-orange-100 {
    --gradient-via-color: #fffaf0;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 250, 240, 0));
  }

  .sm\:tw-via-orange-200 {
    --gradient-via-color: #feebc8;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(254, 235, 200, 0));
  }

  .sm\:tw-via-orange-300 {
    --gradient-via-color: #fbd38d;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(251, 211, 141, 0));
  }

  .sm\:tw-via-orange-400 {
    --gradient-via-color: #f6ad55;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(246, 173, 85, 0));
  }

  .sm\:tw-via-orange-500 {
    --gradient-via-color: #ed8936;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(237, 137, 54, 0));
  }

  .sm\:tw-via-orange-600 {
    --gradient-via-color: #dd6b20;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(221, 107, 32, 0));
  }

  .sm\:tw-via-orange-700 {
    --gradient-via-color: #c05621;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(192, 86, 33, 0));
  }

  .sm\:tw-via-orange-800 {
    --gradient-via-color: #9c4221;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(156, 66, 33, 0));
  }

  .sm\:tw-via-orange-900 {
    --gradient-via-color: #7b341e;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(123, 52, 30, 0));
  }

  .sm\:tw-via-yellow-100 {
    --gradient-via-color: #fffff0;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 240, 0));
  }

  .sm\:tw-via-yellow-200 {
    --gradient-via-color: #fefcbf;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(254, 252, 191, 0));
  }

  .sm\:tw-via-yellow-300 {
    --gradient-via-color: #faf089;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(250, 240, 137, 0));
  }

  .sm\:tw-via-yellow-400 {
    --gradient-via-color: #f6e05e;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(246, 224, 94, 0));
  }

  .sm\:tw-via-yellow-500 {
    --gradient-via-color: #ecc94b;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(236, 201, 75, 0));
  }

  .sm\:tw-via-yellow-600 {
    --gradient-via-color: #d69e2e;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(214, 158, 46, 0));
  }

  .sm\:tw-via-yellow-700 {
    --gradient-via-color: #b7791f;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(183, 121, 31, 0));
  }

  .sm\:tw-via-yellow-800 {
    --gradient-via-color: #975a16;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(151, 90, 22, 0));
  }

  .sm\:tw-via-yellow-900 {
    --gradient-via-color: #744210;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(116, 66, 16, 0));
  }

  .sm\:tw-via-green-100 {
    --gradient-via-color: #f0fff4;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(240, 255, 244, 0));
  }

  .sm\:tw-via-green-200 {
    --gradient-via-color: #c6f6d5;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(198, 246, 213, 0));
  }

  .sm\:tw-via-green-300 {
    --gradient-via-color: #9ae6b4;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(154, 230, 180, 0));
  }

  .sm\:tw-via-green-400 {
    --gradient-via-color: #68d391;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(104, 211, 145, 0));
  }

  .sm\:tw-via-green-500 {
    --gradient-via-color: #48bb78;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(72, 187, 120, 0));
  }

  .sm\:tw-via-green-600 {
    --gradient-via-color: #38a169;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(56, 161, 105, 0));
  }

  .sm\:tw-via-green-700 {
    --gradient-via-color: #2f855a;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(47, 133, 90, 0));
  }

  .sm\:tw-via-green-800 {
    --gradient-via-color: #276749;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(39, 103, 73, 0));
  }

  .sm\:tw-via-green-900 {
    --gradient-via-color: #22543d;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(34, 84, 61, 0));
  }

  .sm\:tw-via-teal-100 {
    --gradient-via-color: #e6fffa;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(230, 255, 250, 0));
  }

  .sm\:tw-via-teal-200 {
    --gradient-via-color: #b2f5ea;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(178, 245, 234, 0));
  }

  .sm\:tw-via-teal-300 {
    --gradient-via-color: #81e6d9;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(129, 230, 217, 0));
  }

  .sm\:tw-via-teal-400 {
    --gradient-via-color: #4fd1c5;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(79, 209, 197, 0));
  }

  .sm\:tw-via-teal-500 {
    --gradient-via-color: #38b2ac;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(56, 178, 172, 0));
  }

  .sm\:tw-via-teal-600 {
    --gradient-via-color: #319795;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(49, 151, 149, 0));
  }

  .sm\:tw-via-teal-700 {
    --gradient-via-color: #2c7a7b;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(44, 122, 123, 0));
  }

  .sm\:tw-via-teal-800 {
    --gradient-via-color: #285e61;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(40, 94, 97, 0));
  }

  .sm\:tw-via-teal-900 {
    --gradient-via-color: #234e52;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(35, 78, 82, 0));
  }

  .sm\:tw-via-blue-100 {
    --gradient-via-color: #ebf8ff;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(235, 248, 255, 0));
  }

  .sm\:tw-via-blue-200 {
    --gradient-via-color: #bee3f8;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(190, 227, 248, 0));
  }

  .sm\:tw-via-blue-300 {
    --gradient-via-color: #90cdf4;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(144, 205, 244, 0));
  }

  .sm\:tw-via-blue-400 {
    --gradient-via-color: #63b3ed;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(99, 179, 237, 0));
  }

  .sm\:tw-via-blue-500 {
    --gradient-via-color: #4299e1;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(66, 153, 225, 0));
  }

  .sm\:tw-via-blue-600 {
    --gradient-via-color: #3182ce;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(49, 130, 206, 0));
  }

  .sm\:tw-via-blue-700 {
    --gradient-via-color: #2b6cb0;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(43, 108, 176, 0));
  }

  .sm\:tw-via-blue-800 {
    --gradient-via-color: #2c5282;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(44, 82, 130, 0));
  }

  .sm\:tw-via-blue-900 {
    --gradient-via-color: #2a4365;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(42, 67, 101, 0));
  }

  .sm\:tw-via-indigo-100 {
    --gradient-via-color: #ebf4ff;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(235, 244, 255, 0));
  }

  .sm\:tw-via-indigo-200 {
    --gradient-via-color: #c3dafe;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(195, 218, 254, 0));
  }

  .sm\:tw-via-indigo-300 {
    --gradient-via-color: #a3bffa;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(163, 191, 250, 0));
  }

  .sm\:tw-via-indigo-400 {
    --gradient-via-color: #7f9cf5;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(127, 156, 245, 0));
  }

  .sm\:tw-via-indigo-500 {
    --gradient-via-color: #667eea;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(102, 126, 234, 0));
  }

  .sm\:tw-via-indigo-600 {
    --gradient-via-color: #5a67d8;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(90, 103, 216, 0));
  }

  .sm\:tw-via-indigo-700 {
    --gradient-via-color: #4c51bf;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(76, 81, 191, 0));
  }

  .sm\:tw-via-indigo-800 {
    --gradient-via-color: #434190;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(67, 65, 144, 0));
  }

  .sm\:tw-via-indigo-900 {
    --gradient-via-color: #3c366b;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(60, 54, 107, 0));
  }

  .sm\:tw-via-purple-100 {
    --gradient-via-color: #faf5ff;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(250, 245, 255, 0));
  }

  .sm\:tw-via-purple-200 {
    --gradient-via-color: #e9d8fd;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(233, 216, 253, 0));
  }

  .sm\:tw-via-purple-300 {
    --gradient-via-color: #d6bcfa;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(214, 188, 250, 0));
  }

  .sm\:tw-via-purple-400 {
    --gradient-via-color: #b794f4;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(183, 148, 244, 0));
  }

  .sm\:tw-via-purple-500 {
    --gradient-via-color: #9f7aea;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(159, 122, 234, 0));
  }

  .sm\:tw-via-purple-600 {
    --gradient-via-color: #805ad5;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(128, 90, 213, 0));
  }

  .sm\:tw-via-purple-700 {
    --gradient-via-color: #6b46c1;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(107, 70, 193, 0));
  }

  .sm\:tw-via-purple-800 {
    --gradient-via-color: #553c9a;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(85, 60, 154, 0));
  }

  .sm\:tw-via-purple-900 {
    --gradient-via-color: #44337a;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(68, 51, 122, 0));
  }

  .sm\:tw-via-pink-100 {
    --gradient-via-color: #fff5f7;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 245, 247, 0));
  }

  .sm\:tw-via-pink-200 {
    --gradient-via-color: #fed7e2;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(254, 215, 226, 0));
  }

  .sm\:tw-via-pink-300 {
    --gradient-via-color: #fbb6ce;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(251, 182, 206, 0));
  }

  .sm\:tw-via-pink-400 {
    --gradient-via-color: #f687b3;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(246, 135, 179, 0));
  }

  .sm\:tw-via-pink-500 {
    --gradient-via-color: #ed64a6;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(237, 100, 166, 0));
  }

  .sm\:tw-via-pink-600 {
    --gradient-via-color: #d53f8c;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(213, 63, 140, 0));
  }

  .sm\:tw-via-pink-700 {
    --gradient-via-color: #b83280;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(184, 50, 128, 0));
  }

  .sm\:tw-via-pink-800 {
    --gradient-via-color: #97266d;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(151, 38, 109, 0));
  }

  .sm\:tw-via-pink-900 {
    --gradient-via-color: #702459;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(112, 36, 89, 0));
  }

  .sm\:tw-to-transparent {
    --gradient-to-color: transparent;
  }

  .sm\:tw-to-current {
    --gradient-to-color: currentColor;
  }

  .sm\:tw-to-black {
    --gradient-to-color: #000;
  }

  .sm\:tw-to-white {
    --gradient-to-color: #fff;
  }

  .sm\:tw-to-gray-100 {
    --gradient-to-color: #f7fafc;
  }

  .sm\:tw-to-gray-200 {
    --gradient-to-color: #edf2f7;
  }

  .sm\:tw-to-gray-300 {
    --gradient-to-color: #e2e8f0;
  }

  .sm\:tw-to-gray-400 {
    --gradient-to-color: #cbd5e0;
  }

  .sm\:tw-to-gray-500 {
    --gradient-to-color: #a0aec0;
  }

  .sm\:tw-to-gray-600 {
    --gradient-to-color: #718096;
  }

  .sm\:tw-to-gray-700 {
    --gradient-to-color: #4a5568;
  }

  .sm\:tw-to-gray-800 {
    --gradient-to-color: #2d3748;
  }

  .sm\:tw-to-gray-900 {
    --gradient-to-color: #1a202c;
  }

  .sm\:tw-to-red-100 {
    --gradient-to-color: #fff5f5;
  }

  .sm\:tw-to-red-200 {
    --gradient-to-color: #fed7d7;
  }

  .sm\:tw-to-red-300 {
    --gradient-to-color: #feb2b2;
  }

  .sm\:tw-to-red-400 {
    --gradient-to-color: #fc8181;
  }

  .sm\:tw-to-red-500 {
    --gradient-to-color: #f56565;
  }

  .sm\:tw-to-red-600 {
    --gradient-to-color: #e53e3e;
  }

  .sm\:tw-to-red-700 {
    --gradient-to-color: #c53030;
  }

  .sm\:tw-to-red-800 {
    --gradient-to-color: #9b2c2c;
  }

  .sm\:tw-to-red-900 {
    --gradient-to-color: #742a2a;
  }

  .sm\:tw-to-orange-100 {
    --gradient-to-color: #fffaf0;
  }

  .sm\:tw-to-orange-200 {
    --gradient-to-color: #feebc8;
  }

  .sm\:tw-to-orange-300 {
    --gradient-to-color: #fbd38d;
  }

  .sm\:tw-to-orange-400 {
    --gradient-to-color: #f6ad55;
  }

  .sm\:tw-to-orange-500 {
    --gradient-to-color: #ed8936;
  }

  .sm\:tw-to-orange-600 {
    --gradient-to-color: #dd6b20;
  }

  .sm\:tw-to-orange-700 {
    --gradient-to-color: #c05621;
  }

  .sm\:tw-to-orange-800 {
    --gradient-to-color: #9c4221;
  }

  .sm\:tw-to-orange-900 {
    --gradient-to-color: #7b341e;
  }

  .sm\:tw-to-yellow-100 {
    --gradient-to-color: #fffff0;
  }

  .sm\:tw-to-yellow-200 {
    --gradient-to-color: #fefcbf;
  }

  .sm\:tw-to-yellow-300 {
    --gradient-to-color: #faf089;
  }

  .sm\:tw-to-yellow-400 {
    --gradient-to-color: #f6e05e;
  }

  .sm\:tw-to-yellow-500 {
    --gradient-to-color: #ecc94b;
  }

  .sm\:tw-to-yellow-600 {
    --gradient-to-color: #d69e2e;
  }

  .sm\:tw-to-yellow-700 {
    --gradient-to-color: #b7791f;
  }

  .sm\:tw-to-yellow-800 {
    --gradient-to-color: #975a16;
  }

  .sm\:tw-to-yellow-900 {
    --gradient-to-color: #744210;
  }

  .sm\:tw-to-green-100 {
    --gradient-to-color: #f0fff4;
  }

  .sm\:tw-to-green-200 {
    --gradient-to-color: #c6f6d5;
  }

  .sm\:tw-to-green-300 {
    --gradient-to-color: #9ae6b4;
  }

  .sm\:tw-to-green-400 {
    --gradient-to-color: #68d391;
  }

  .sm\:tw-to-green-500 {
    --gradient-to-color: #48bb78;
  }

  .sm\:tw-to-green-600 {
    --gradient-to-color: #38a169;
  }

  .sm\:tw-to-green-700 {
    --gradient-to-color: #2f855a;
  }

  .sm\:tw-to-green-800 {
    --gradient-to-color: #276749;
  }

  .sm\:tw-to-green-900 {
    --gradient-to-color: #22543d;
  }

  .sm\:tw-to-teal-100 {
    --gradient-to-color: #e6fffa;
  }

  .sm\:tw-to-teal-200 {
    --gradient-to-color: #b2f5ea;
  }

  .sm\:tw-to-teal-300 {
    --gradient-to-color: #81e6d9;
  }

  .sm\:tw-to-teal-400 {
    --gradient-to-color: #4fd1c5;
  }

  .sm\:tw-to-teal-500 {
    --gradient-to-color: #38b2ac;
  }

  .sm\:tw-to-teal-600 {
    --gradient-to-color: #319795;
  }

  .sm\:tw-to-teal-700 {
    --gradient-to-color: #2c7a7b;
  }

  .sm\:tw-to-teal-800 {
    --gradient-to-color: #285e61;
  }

  .sm\:tw-to-teal-900 {
    --gradient-to-color: #234e52;
  }

  .sm\:tw-to-blue-100 {
    --gradient-to-color: #ebf8ff;
  }

  .sm\:tw-to-blue-200 {
    --gradient-to-color: #bee3f8;
  }

  .sm\:tw-to-blue-300 {
    --gradient-to-color: #90cdf4;
  }

  .sm\:tw-to-blue-400 {
    --gradient-to-color: #63b3ed;
  }

  .sm\:tw-to-blue-500 {
    --gradient-to-color: #4299e1;
  }

  .sm\:tw-to-blue-600 {
    --gradient-to-color: #3182ce;
  }

  .sm\:tw-to-blue-700 {
    --gradient-to-color: #2b6cb0;
  }

  .sm\:tw-to-blue-800 {
    --gradient-to-color: #2c5282;
  }

  .sm\:tw-to-blue-900 {
    --gradient-to-color: #2a4365;
  }

  .sm\:tw-to-indigo-100 {
    --gradient-to-color: #ebf4ff;
  }

  .sm\:tw-to-indigo-200 {
    --gradient-to-color: #c3dafe;
  }

  .sm\:tw-to-indigo-300 {
    --gradient-to-color: #a3bffa;
  }

  .sm\:tw-to-indigo-400 {
    --gradient-to-color: #7f9cf5;
  }

  .sm\:tw-to-indigo-500 {
    --gradient-to-color: #667eea;
  }

  .sm\:tw-to-indigo-600 {
    --gradient-to-color: #5a67d8;
  }

  .sm\:tw-to-indigo-700 {
    --gradient-to-color: #4c51bf;
  }

  .sm\:tw-to-indigo-800 {
    --gradient-to-color: #434190;
  }

  .sm\:tw-to-indigo-900 {
    --gradient-to-color: #3c366b;
  }

  .sm\:tw-to-purple-100 {
    --gradient-to-color: #faf5ff;
  }

  .sm\:tw-to-purple-200 {
    --gradient-to-color: #e9d8fd;
  }

  .sm\:tw-to-purple-300 {
    --gradient-to-color: #d6bcfa;
  }

  .sm\:tw-to-purple-400 {
    --gradient-to-color: #b794f4;
  }

  .sm\:tw-to-purple-500 {
    --gradient-to-color: #9f7aea;
  }

  .sm\:tw-to-purple-600 {
    --gradient-to-color: #805ad5;
  }

  .sm\:tw-to-purple-700 {
    --gradient-to-color: #6b46c1;
  }

  .sm\:tw-to-purple-800 {
    --gradient-to-color: #553c9a;
  }

  .sm\:tw-to-purple-900 {
    --gradient-to-color: #44337a;
  }

  .sm\:tw-to-pink-100 {
    --gradient-to-color: #fff5f7;
  }

  .sm\:tw-to-pink-200 {
    --gradient-to-color: #fed7e2;
  }

  .sm\:tw-to-pink-300 {
    --gradient-to-color: #fbb6ce;
  }

  .sm\:tw-to-pink-400 {
    --gradient-to-color: #f687b3;
  }

  .sm\:tw-to-pink-500 {
    --gradient-to-color: #ed64a6;
  }

  .sm\:tw-to-pink-600 {
    --gradient-to-color: #d53f8c;
  }

  .sm\:tw-to-pink-700 {
    --gradient-to-color: #b83280;
  }

  .sm\:tw-to-pink-800 {
    --gradient-to-color: #97266d;
  }

  .sm\:tw-to-pink-900 {
    --gradient-to-color: #702459;
  }

  .sm\:hover\:tw-from-transparent:hover {
    --gradient-from-color: transparent;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(0, 0, 0, 0));
  }

  .sm\:hover\:tw-from-current:hover {
    --gradient-from-color: currentColor;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0));
  }

  .sm\:hover\:tw-from-black:hover {
    --gradient-from-color: #000;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(0, 0, 0, 0));
  }

  .sm\:hover\:tw-from-white:hover {
    --gradient-from-color: #fff;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0));
  }

  .sm\:hover\:tw-from-gray-100:hover {
    --gradient-from-color: #f7fafc;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(247, 250, 252, 0));
  }

  .sm\:hover\:tw-from-gray-200:hover {
    --gradient-from-color: #edf2f7;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(237, 242, 247, 0));
  }

  .sm\:hover\:tw-from-gray-300:hover {
    --gradient-from-color: #e2e8f0;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(226, 232, 240, 0));
  }

  .sm\:hover\:tw-from-gray-400:hover {
    --gradient-from-color: #cbd5e0;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(203, 213, 224, 0));
  }

  .sm\:hover\:tw-from-gray-500:hover {
    --gradient-from-color: #a0aec0;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(160, 174, 192, 0));
  }

  .sm\:hover\:tw-from-gray-600:hover {
    --gradient-from-color: #718096;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(113, 128, 150, 0));
  }

  .sm\:hover\:tw-from-gray-700:hover {
    --gradient-from-color: #4a5568;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(74, 85, 104, 0));
  }

  .sm\:hover\:tw-from-gray-800:hover {
    --gradient-from-color: #2d3748;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(45, 55, 72, 0));
  }

  .sm\:hover\:tw-from-gray-900:hover {
    --gradient-from-color: #1a202c;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(26, 32, 44, 0));
  }

  .sm\:hover\:tw-from-red-100:hover {
    --gradient-from-color: #fff5f5;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 245, 245, 0));
  }

  .sm\:hover\:tw-from-red-200:hover {
    --gradient-from-color: #fed7d7;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(254, 215, 215, 0));
  }

  .sm\:hover\:tw-from-red-300:hover {
    --gradient-from-color: #feb2b2;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(254, 178, 178, 0));
  }

  .sm\:hover\:tw-from-red-400:hover {
    --gradient-from-color: #fc8181;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(252, 129, 129, 0));
  }

  .sm\:hover\:tw-from-red-500:hover {
    --gradient-from-color: #f56565;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(245, 101, 101, 0));
  }

  .sm\:hover\:tw-from-red-600:hover {
    --gradient-from-color: #e53e3e;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(229, 62, 62, 0));
  }

  .sm\:hover\:tw-from-red-700:hover {
    --gradient-from-color: #c53030;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(197, 48, 48, 0));
  }

  .sm\:hover\:tw-from-red-800:hover {
    --gradient-from-color: #9b2c2c;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(155, 44, 44, 0));
  }

  .sm\:hover\:tw-from-red-900:hover {
    --gradient-from-color: #742a2a;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(116, 42, 42, 0));
  }

  .sm\:hover\:tw-from-orange-100:hover {
    --gradient-from-color: #fffaf0;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 250, 240, 0));
  }

  .sm\:hover\:tw-from-orange-200:hover {
    --gradient-from-color: #feebc8;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(254, 235, 200, 0));
  }

  .sm\:hover\:tw-from-orange-300:hover {
    --gradient-from-color: #fbd38d;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(251, 211, 141, 0));
  }

  .sm\:hover\:tw-from-orange-400:hover {
    --gradient-from-color: #f6ad55;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(246, 173, 85, 0));
  }

  .sm\:hover\:tw-from-orange-500:hover {
    --gradient-from-color: #ed8936;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(237, 137, 54, 0));
  }

  .sm\:hover\:tw-from-orange-600:hover {
    --gradient-from-color: #dd6b20;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(221, 107, 32, 0));
  }

  .sm\:hover\:tw-from-orange-700:hover {
    --gradient-from-color: #c05621;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(192, 86, 33, 0));
  }

  .sm\:hover\:tw-from-orange-800:hover {
    --gradient-from-color: #9c4221;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(156, 66, 33, 0));
  }

  .sm\:hover\:tw-from-orange-900:hover {
    --gradient-from-color: #7b341e;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(123, 52, 30, 0));
  }

  .sm\:hover\:tw-from-yellow-100:hover {
    --gradient-from-color: #fffff0;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 240, 0));
  }

  .sm\:hover\:tw-from-yellow-200:hover {
    --gradient-from-color: #fefcbf;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(254, 252, 191, 0));
  }

  .sm\:hover\:tw-from-yellow-300:hover {
    --gradient-from-color: #faf089;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(250, 240, 137, 0));
  }

  .sm\:hover\:tw-from-yellow-400:hover {
    --gradient-from-color: #f6e05e;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(246, 224, 94, 0));
  }

  .sm\:hover\:tw-from-yellow-500:hover {
    --gradient-from-color: #ecc94b;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(236, 201, 75, 0));
  }

  .sm\:hover\:tw-from-yellow-600:hover {
    --gradient-from-color: #d69e2e;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(214, 158, 46, 0));
  }

  .sm\:hover\:tw-from-yellow-700:hover {
    --gradient-from-color: #b7791f;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(183, 121, 31, 0));
  }

  .sm\:hover\:tw-from-yellow-800:hover {
    --gradient-from-color: #975a16;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(151, 90, 22, 0));
  }

  .sm\:hover\:tw-from-yellow-900:hover {
    --gradient-from-color: #744210;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(116, 66, 16, 0));
  }

  .sm\:hover\:tw-from-green-100:hover {
    --gradient-from-color: #f0fff4;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(240, 255, 244, 0));
  }

  .sm\:hover\:tw-from-green-200:hover {
    --gradient-from-color: #c6f6d5;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(198, 246, 213, 0));
  }

  .sm\:hover\:tw-from-green-300:hover {
    --gradient-from-color: #9ae6b4;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(154, 230, 180, 0));
  }

  .sm\:hover\:tw-from-green-400:hover {
    --gradient-from-color: #68d391;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(104, 211, 145, 0));
  }

  .sm\:hover\:tw-from-green-500:hover {
    --gradient-from-color: #48bb78;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(72, 187, 120, 0));
  }

  .sm\:hover\:tw-from-green-600:hover {
    --gradient-from-color: #38a169;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(56, 161, 105, 0));
  }

  .sm\:hover\:tw-from-green-700:hover {
    --gradient-from-color: #2f855a;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(47, 133, 90, 0));
  }

  .sm\:hover\:tw-from-green-800:hover {
    --gradient-from-color: #276749;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(39, 103, 73, 0));
  }

  .sm\:hover\:tw-from-green-900:hover {
    --gradient-from-color: #22543d;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(34, 84, 61, 0));
  }

  .sm\:hover\:tw-from-teal-100:hover {
    --gradient-from-color: #e6fffa;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(230, 255, 250, 0));
  }

  .sm\:hover\:tw-from-teal-200:hover {
    --gradient-from-color: #b2f5ea;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(178, 245, 234, 0));
  }

  .sm\:hover\:tw-from-teal-300:hover {
    --gradient-from-color: #81e6d9;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(129, 230, 217, 0));
  }

  .sm\:hover\:tw-from-teal-400:hover {
    --gradient-from-color: #4fd1c5;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(79, 209, 197, 0));
  }

  .sm\:hover\:tw-from-teal-500:hover {
    --gradient-from-color: #38b2ac;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(56, 178, 172, 0));
  }

  .sm\:hover\:tw-from-teal-600:hover {
    --gradient-from-color: #319795;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(49, 151, 149, 0));
  }

  .sm\:hover\:tw-from-teal-700:hover {
    --gradient-from-color: #2c7a7b;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(44, 122, 123, 0));
  }

  .sm\:hover\:tw-from-teal-800:hover {
    --gradient-from-color: #285e61;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(40, 94, 97, 0));
  }

  .sm\:hover\:tw-from-teal-900:hover {
    --gradient-from-color: #234e52;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(35, 78, 82, 0));
  }

  .sm\:hover\:tw-from-blue-100:hover {
    --gradient-from-color: #ebf8ff;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(235, 248, 255, 0));
  }

  .sm\:hover\:tw-from-blue-200:hover {
    --gradient-from-color: #bee3f8;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(190, 227, 248, 0));
  }

  .sm\:hover\:tw-from-blue-300:hover {
    --gradient-from-color: #90cdf4;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(144, 205, 244, 0));
  }

  .sm\:hover\:tw-from-blue-400:hover {
    --gradient-from-color: #63b3ed;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(99, 179, 237, 0));
  }

  .sm\:hover\:tw-from-blue-500:hover {
    --gradient-from-color: #4299e1;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(66, 153, 225, 0));
  }

  .sm\:hover\:tw-from-blue-600:hover {
    --gradient-from-color: #3182ce;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(49, 130, 206, 0));
  }

  .sm\:hover\:tw-from-blue-700:hover {
    --gradient-from-color: #2b6cb0;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(43, 108, 176, 0));
  }

  .sm\:hover\:tw-from-blue-800:hover {
    --gradient-from-color: #2c5282;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(44, 82, 130, 0));
  }

  .sm\:hover\:tw-from-blue-900:hover {
    --gradient-from-color: #2a4365;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(42, 67, 101, 0));
  }

  .sm\:hover\:tw-from-indigo-100:hover {
    --gradient-from-color: #ebf4ff;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(235, 244, 255, 0));
  }

  .sm\:hover\:tw-from-indigo-200:hover {
    --gradient-from-color: #c3dafe;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(195, 218, 254, 0));
  }

  .sm\:hover\:tw-from-indigo-300:hover {
    --gradient-from-color: #a3bffa;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(163, 191, 250, 0));
  }

  .sm\:hover\:tw-from-indigo-400:hover {
    --gradient-from-color: #7f9cf5;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(127, 156, 245, 0));
  }

  .sm\:hover\:tw-from-indigo-500:hover {
    --gradient-from-color: #667eea;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(102, 126, 234, 0));
  }

  .sm\:hover\:tw-from-indigo-600:hover {
    --gradient-from-color: #5a67d8;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(90, 103, 216, 0));
  }

  .sm\:hover\:tw-from-indigo-700:hover {
    --gradient-from-color: #4c51bf;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(76, 81, 191, 0));
  }

  .sm\:hover\:tw-from-indigo-800:hover {
    --gradient-from-color: #434190;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(67, 65, 144, 0));
  }

  .sm\:hover\:tw-from-indigo-900:hover {
    --gradient-from-color: #3c366b;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(60, 54, 107, 0));
  }

  .sm\:hover\:tw-from-purple-100:hover {
    --gradient-from-color: #faf5ff;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(250, 245, 255, 0));
  }

  .sm\:hover\:tw-from-purple-200:hover {
    --gradient-from-color: #e9d8fd;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(233, 216, 253, 0));
  }

  .sm\:hover\:tw-from-purple-300:hover {
    --gradient-from-color: #d6bcfa;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(214, 188, 250, 0));
  }

  .sm\:hover\:tw-from-purple-400:hover {
    --gradient-from-color: #b794f4;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(183, 148, 244, 0));
  }

  .sm\:hover\:tw-from-purple-500:hover {
    --gradient-from-color: #9f7aea;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(159, 122, 234, 0));
  }

  .sm\:hover\:tw-from-purple-600:hover {
    --gradient-from-color: #805ad5;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(128, 90, 213, 0));
  }

  .sm\:hover\:tw-from-purple-700:hover {
    --gradient-from-color: #6b46c1;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(107, 70, 193, 0));
  }

  .sm\:hover\:tw-from-purple-800:hover {
    --gradient-from-color: #553c9a;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(85, 60, 154, 0));
  }

  .sm\:hover\:tw-from-purple-900:hover {
    --gradient-from-color: #44337a;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(68, 51, 122, 0));
  }

  .sm\:hover\:tw-from-pink-100:hover {
    --gradient-from-color: #fff5f7;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 245, 247, 0));
  }

  .sm\:hover\:tw-from-pink-200:hover {
    --gradient-from-color: #fed7e2;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(254, 215, 226, 0));
  }

  .sm\:hover\:tw-from-pink-300:hover {
    --gradient-from-color: #fbb6ce;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(251, 182, 206, 0));
  }

  .sm\:hover\:tw-from-pink-400:hover {
    --gradient-from-color: #f687b3;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(246, 135, 179, 0));
  }

  .sm\:hover\:tw-from-pink-500:hover {
    --gradient-from-color: #ed64a6;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(237, 100, 166, 0));
  }

  .sm\:hover\:tw-from-pink-600:hover {
    --gradient-from-color: #d53f8c;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(213, 63, 140, 0));
  }

  .sm\:hover\:tw-from-pink-700:hover {
    --gradient-from-color: #b83280;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(184, 50, 128, 0));
  }

  .sm\:hover\:tw-from-pink-800:hover {
    --gradient-from-color: #97266d;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(151, 38, 109, 0));
  }

  .sm\:hover\:tw-from-pink-900:hover {
    --gradient-from-color: #702459;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(112, 36, 89, 0));
  }

  .sm\:hover\:tw-via-transparent:hover {
    --gradient-via-color: transparent;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(0, 0, 0, 0));
  }

  .sm\:hover\:tw-via-current:hover {
    --gradient-via-color: currentColor;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0));
  }

  .sm\:hover\:tw-via-black:hover {
    --gradient-via-color: #000;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(0, 0, 0, 0));
  }

  .sm\:hover\:tw-via-white:hover {
    --gradient-via-color: #fff;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0));
  }

  .sm\:hover\:tw-via-gray-100:hover {
    --gradient-via-color: #f7fafc;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(247, 250, 252, 0));
  }

  .sm\:hover\:tw-via-gray-200:hover {
    --gradient-via-color: #edf2f7;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(237, 242, 247, 0));
  }

  .sm\:hover\:tw-via-gray-300:hover {
    --gradient-via-color: #e2e8f0;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(226, 232, 240, 0));
  }

  .sm\:hover\:tw-via-gray-400:hover {
    --gradient-via-color: #cbd5e0;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(203, 213, 224, 0));
  }

  .sm\:hover\:tw-via-gray-500:hover {
    --gradient-via-color: #a0aec0;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(160, 174, 192, 0));
  }

  .sm\:hover\:tw-via-gray-600:hover {
    --gradient-via-color: #718096;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(113, 128, 150, 0));
  }

  .sm\:hover\:tw-via-gray-700:hover {
    --gradient-via-color: #4a5568;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(74, 85, 104, 0));
  }

  .sm\:hover\:tw-via-gray-800:hover {
    --gradient-via-color: #2d3748;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(45, 55, 72, 0));
  }

  .sm\:hover\:tw-via-gray-900:hover {
    --gradient-via-color: #1a202c;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(26, 32, 44, 0));
  }

  .sm\:hover\:tw-via-red-100:hover {
    --gradient-via-color: #fff5f5;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 245, 245, 0));
  }

  .sm\:hover\:tw-via-red-200:hover {
    --gradient-via-color: #fed7d7;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(254, 215, 215, 0));
  }

  .sm\:hover\:tw-via-red-300:hover {
    --gradient-via-color: #feb2b2;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(254, 178, 178, 0));
  }

  .sm\:hover\:tw-via-red-400:hover {
    --gradient-via-color: #fc8181;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(252, 129, 129, 0));
  }

  .sm\:hover\:tw-via-red-500:hover {
    --gradient-via-color: #f56565;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(245, 101, 101, 0));
  }

  .sm\:hover\:tw-via-red-600:hover {
    --gradient-via-color: #e53e3e;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(229, 62, 62, 0));
  }

  .sm\:hover\:tw-via-red-700:hover {
    --gradient-via-color: #c53030;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(197, 48, 48, 0));
  }

  .sm\:hover\:tw-via-red-800:hover {
    --gradient-via-color: #9b2c2c;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(155, 44, 44, 0));
  }

  .sm\:hover\:tw-via-red-900:hover {
    --gradient-via-color: #742a2a;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(116, 42, 42, 0));
  }

  .sm\:hover\:tw-via-orange-100:hover {
    --gradient-via-color: #fffaf0;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 250, 240, 0));
  }

  .sm\:hover\:tw-via-orange-200:hover {
    --gradient-via-color: #feebc8;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(254, 235, 200, 0));
  }

  .sm\:hover\:tw-via-orange-300:hover {
    --gradient-via-color: #fbd38d;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(251, 211, 141, 0));
  }

  .sm\:hover\:tw-via-orange-400:hover {
    --gradient-via-color: #f6ad55;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(246, 173, 85, 0));
  }

  .sm\:hover\:tw-via-orange-500:hover {
    --gradient-via-color: #ed8936;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(237, 137, 54, 0));
  }

  .sm\:hover\:tw-via-orange-600:hover {
    --gradient-via-color: #dd6b20;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(221, 107, 32, 0));
  }

  .sm\:hover\:tw-via-orange-700:hover {
    --gradient-via-color: #c05621;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(192, 86, 33, 0));
  }

  .sm\:hover\:tw-via-orange-800:hover {
    --gradient-via-color: #9c4221;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(156, 66, 33, 0));
  }

  .sm\:hover\:tw-via-orange-900:hover {
    --gradient-via-color: #7b341e;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(123, 52, 30, 0));
  }

  .sm\:hover\:tw-via-yellow-100:hover {
    --gradient-via-color: #fffff0;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 240, 0));
  }

  .sm\:hover\:tw-via-yellow-200:hover {
    --gradient-via-color: #fefcbf;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(254, 252, 191, 0));
  }

  .sm\:hover\:tw-via-yellow-300:hover {
    --gradient-via-color: #faf089;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(250, 240, 137, 0));
  }

  .sm\:hover\:tw-via-yellow-400:hover {
    --gradient-via-color: #f6e05e;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(246, 224, 94, 0));
  }

  .sm\:hover\:tw-via-yellow-500:hover {
    --gradient-via-color: #ecc94b;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(236, 201, 75, 0));
  }

  .sm\:hover\:tw-via-yellow-600:hover {
    --gradient-via-color: #d69e2e;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(214, 158, 46, 0));
  }

  .sm\:hover\:tw-via-yellow-700:hover {
    --gradient-via-color: #b7791f;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(183, 121, 31, 0));
  }

  .sm\:hover\:tw-via-yellow-800:hover {
    --gradient-via-color: #975a16;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(151, 90, 22, 0));
  }

  .sm\:hover\:tw-via-yellow-900:hover {
    --gradient-via-color: #744210;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(116, 66, 16, 0));
  }

  .sm\:hover\:tw-via-green-100:hover {
    --gradient-via-color: #f0fff4;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(240, 255, 244, 0));
  }

  .sm\:hover\:tw-via-green-200:hover {
    --gradient-via-color: #c6f6d5;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(198, 246, 213, 0));
  }

  .sm\:hover\:tw-via-green-300:hover {
    --gradient-via-color: #9ae6b4;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(154, 230, 180, 0));
  }

  .sm\:hover\:tw-via-green-400:hover {
    --gradient-via-color: #68d391;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(104, 211, 145, 0));
  }

  .sm\:hover\:tw-via-green-500:hover {
    --gradient-via-color: #48bb78;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(72, 187, 120, 0));
  }

  .sm\:hover\:tw-via-green-600:hover {
    --gradient-via-color: #38a169;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(56, 161, 105, 0));
  }

  .sm\:hover\:tw-via-green-700:hover {
    --gradient-via-color: #2f855a;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(47, 133, 90, 0));
  }

  .sm\:hover\:tw-via-green-800:hover {
    --gradient-via-color: #276749;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(39, 103, 73, 0));
  }

  .sm\:hover\:tw-via-green-900:hover {
    --gradient-via-color: #22543d;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(34, 84, 61, 0));
  }

  .sm\:hover\:tw-via-teal-100:hover {
    --gradient-via-color: #e6fffa;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(230, 255, 250, 0));
  }

  .sm\:hover\:tw-via-teal-200:hover {
    --gradient-via-color: #b2f5ea;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(178, 245, 234, 0));
  }

  .sm\:hover\:tw-via-teal-300:hover {
    --gradient-via-color: #81e6d9;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(129, 230, 217, 0));
  }

  .sm\:hover\:tw-via-teal-400:hover {
    --gradient-via-color: #4fd1c5;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(79, 209, 197, 0));
  }

  .sm\:hover\:tw-via-teal-500:hover {
    --gradient-via-color: #38b2ac;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(56, 178, 172, 0));
  }

  .sm\:hover\:tw-via-teal-600:hover {
    --gradient-via-color: #319795;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(49, 151, 149, 0));
  }

  .sm\:hover\:tw-via-teal-700:hover {
    --gradient-via-color: #2c7a7b;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(44, 122, 123, 0));
  }

  .sm\:hover\:tw-via-teal-800:hover {
    --gradient-via-color: #285e61;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(40, 94, 97, 0));
  }

  .sm\:hover\:tw-via-teal-900:hover {
    --gradient-via-color: #234e52;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(35, 78, 82, 0));
  }

  .sm\:hover\:tw-via-blue-100:hover {
    --gradient-via-color: #ebf8ff;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(235, 248, 255, 0));
  }

  .sm\:hover\:tw-via-blue-200:hover {
    --gradient-via-color: #bee3f8;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(190, 227, 248, 0));
  }

  .sm\:hover\:tw-via-blue-300:hover {
    --gradient-via-color: #90cdf4;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(144, 205, 244, 0));
  }

  .sm\:hover\:tw-via-blue-400:hover {
    --gradient-via-color: #63b3ed;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(99, 179, 237, 0));
  }

  .sm\:hover\:tw-via-blue-500:hover {
    --gradient-via-color: #4299e1;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(66, 153, 225, 0));
  }

  .sm\:hover\:tw-via-blue-600:hover {
    --gradient-via-color: #3182ce;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(49, 130, 206, 0));
  }

  .sm\:hover\:tw-via-blue-700:hover {
    --gradient-via-color: #2b6cb0;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(43, 108, 176, 0));
  }

  .sm\:hover\:tw-via-blue-800:hover {
    --gradient-via-color: #2c5282;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(44, 82, 130, 0));
  }

  .sm\:hover\:tw-via-blue-900:hover {
    --gradient-via-color: #2a4365;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(42, 67, 101, 0));
  }

  .sm\:hover\:tw-via-indigo-100:hover {
    --gradient-via-color: #ebf4ff;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(235, 244, 255, 0));
  }

  .sm\:hover\:tw-via-indigo-200:hover {
    --gradient-via-color: #c3dafe;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(195, 218, 254, 0));
  }

  .sm\:hover\:tw-via-indigo-300:hover {
    --gradient-via-color: #a3bffa;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(163, 191, 250, 0));
  }

  .sm\:hover\:tw-via-indigo-400:hover {
    --gradient-via-color: #7f9cf5;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(127, 156, 245, 0));
  }

  .sm\:hover\:tw-via-indigo-500:hover {
    --gradient-via-color: #667eea;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(102, 126, 234, 0));
  }

  .sm\:hover\:tw-via-indigo-600:hover {
    --gradient-via-color: #5a67d8;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(90, 103, 216, 0));
  }

  .sm\:hover\:tw-via-indigo-700:hover {
    --gradient-via-color: #4c51bf;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(76, 81, 191, 0));
  }

  .sm\:hover\:tw-via-indigo-800:hover {
    --gradient-via-color: #434190;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(67, 65, 144, 0));
  }

  .sm\:hover\:tw-via-indigo-900:hover {
    --gradient-via-color: #3c366b;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(60, 54, 107, 0));
  }

  .sm\:hover\:tw-via-purple-100:hover {
    --gradient-via-color: #faf5ff;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(250, 245, 255, 0));
  }

  .sm\:hover\:tw-via-purple-200:hover {
    --gradient-via-color: #e9d8fd;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(233, 216, 253, 0));
  }

  .sm\:hover\:tw-via-purple-300:hover {
    --gradient-via-color: #d6bcfa;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(214, 188, 250, 0));
  }

  .sm\:hover\:tw-via-purple-400:hover {
    --gradient-via-color: #b794f4;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(183, 148, 244, 0));
  }

  .sm\:hover\:tw-via-purple-500:hover {
    --gradient-via-color: #9f7aea;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(159, 122, 234, 0));
  }

  .sm\:hover\:tw-via-purple-600:hover {
    --gradient-via-color: #805ad5;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(128, 90, 213, 0));
  }

  .sm\:hover\:tw-via-purple-700:hover {
    --gradient-via-color: #6b46c1;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(107, 70, 193, 0));
  }

  .sm\:hover\:tw-via-purple-800:hover {
    --gradient-via-color: #553c9a;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(85, 60, 154, 0));
  }

  .sm\:hover\:tw-via-purple-900:hover {
    --gradient-via-color: #44337a;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(68, 51, 122, 0));
  }

  .sm\:hover\:tw-via-pink-100:hover {
    --gradient-via-color: #fff5f7;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 245, 247, 0));
  }

  .sm\:hover\:tw-via-pink-200:hover {
    --gradient-via-color: #fed7e2;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(254, 215, 226, 0));
  }

  .sm\:hover\:tw-via-pink-300:hover {
    --gradient-via-color: #fbb6ce;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(251, 182, 206, 0));
  }

  .sm\:hover\:tw-via-pink-400:hover {
    --gradient-via-color: #f687b3;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(246, 135, 179, 0));
  }

  .sm\:hover\:tw-via-pink-500:hover {
    --gradient-via-color: #ed64a6;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(237, 100, 166, 0));
  }

  .sm\:hover\:tw-via-pink-600:hover {
    --gradient-via-color: #d53f8c;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(213, 63, 140, 0));
  }

  .sm\:hover\:tw-via-pink-700:hover {
    --gradient-via-color: #b83280;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(184, 50, 128, 0));
  }

  .sm\:hover\:tw-via-pink-800:hover {
    --gradient-via-color: #97266d;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(151, 38, 109, 0));
  }

  .sm\:hover\:tw-via-pink-900:hover {
    --gradient-via-color: #702459;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(112, 36, 89, 0));
  }

  .sm\:hover\:tw-to-transparent:hover {
    --gradient-to-color: transparent;
  }

  .sm\:hover\:tw-to-current:hover {
    --gradient-to-color: currentColor;
  }

  .sm\:hover\:tw-to-black:hover {
    --gradient-to-color: #000;
  }

  .sm\:hover\:tw-to-white:hover {
    --gradient-to-color: #fff;
  }

  .sm\:hover\:tw-to-gray-100:hover {
    --gradient-to-color: #f7fafc;
  }

  .sm\:hover\:tw-to-gray-200:hover {
    --gradient-to-color: #edf2f7;
  }

  .sm\:hover\:tw-to-gray-300:hover {
    --gradient-to-color: #e2e8f0;
  }

  .sm\:hover\:tw-to-gray-400:hover {
    --gradient-to-color: #cbd5e0;
  }

  .sm\:hover\:tw-to-gray-500:hover {
    --gradient-to-color: #a0aec0;
  }

  .sm\:hover\:tw-to-gray-600:hover {
    --gradient-to-color: #718096;
  }

  .sm\:hover\:tw-to-gray-700:hover {
    --gradient-to-color: #4a5568;
  }

  .sm\:hover\:tw-to-gray-800:hover {
    --gradient-to-color: #2d3748;
  }

  .sm\:hover\:tw-to-gray-900:hover {
    --gradient-to-color: #1a202c;
  }

  .sm\:hover\:tw-to-red-100:hover {
    --gradient-to-color: #fff5f5;
  }

  .sm\:hover\:tw-to-red-200:hover {
    --gradient-to-color: #fed7d7;
  }

  .sm\:hover\:tw-to-red-300:hover {
    --gradient-to-color: #feb2b2;
  }

  .sm\:hover\:tw-to-red-400:hover {
    --gradient-to-color: #fc8181;
  }

  .sm\:hover\:tw-to-red-500:hover {
    --gradient-to-color: #f56565;
  }

  .sm\:hover\:tw-to-red-600:hover {
    --gradient-to-color: #e53e3e;
  }

  .sm\:hover\:tw-to-red-700:hover {
    --gradient-to-color: #c53030;
  }

  .sm\:hover\:tw-to-red-800:hover {
    --gradient-to-color: #9b2c2c;
  }

  .sm\:hover\:tw-to-red-900:hover {
    --gradient-to-color: #742a2a;
  }

  .sm\:hover\:tw-to-orange-100:hover {
    --gradient-to-color: #fffaf0;
  }

  .sm\:hover\:tw-to-orange-200:hover {
    --gradient-to-color: #feebc8;
  }

  .sm\:hover\:tw-to-orange-300:hover {
    --gradient-to-color: #fbd38d;
  }

  .sm\:hover\:tw-to-orange-400:hover {
    --gradient-to-color: #f6ad55;
  }

  .sm\:hover\:tw-to-orange-500:hover {
    --gradient-to-color: #ed8936;
  }

  .sm\:hover\:tw-to-orange-600:hover {
    --gradient-to-color: #dd6b20;
  }

  .sm\:hover\:tw-to-orange-700:hover {
    --gradient-to-color: #c05621;
  }

  .sm\:hover\:tw-to-orange-800:hover {
    --gradient-to-color: #9c4221;
  }

  .sm\:hover\:tw-to-orange-900:hover {
    --gradient-to-color: #7b341e;
  }

  .sm\:hover\:tw-to-yellow-100:hover {
    --gradient-to-color: #fffff0;
  }

  .sm\:hover\:tw-to-yellow-200:hover {
    --gradient-to-color: #fefcbf;
  }

  .sm\:hover\:tw-to-yellow-300:hover {
    --gradient-to-color: #faf089;
  }

  .sm\:hover\:tw-to-yellow-400:hover {
    --gradient-to-color: #f6e05e;
  }

  .sm\:hover\:tw-to-yellow-500:hover {
    --gradient-to-color: #ecc94b;
  }

  .sm\:hover\:tw-to-yellow-600:hover {
    --gradient-to-color: #d69e2e;
  }

  .sm\:hover\:tw-to-yellow-700:hover {
    --gradient-to-color: #b7791f;
  }

  .sm\:hover\:tw-to-yellow-800:hover {
    --gradient-to-color: #975a16;
  }

  .sm\:hover\:tw-to-yellow-900:hover {
    --gradient-to-color: #744210;
  }

  .sm\:hover\:tw-to-green-100:hover {
    --gradient-to-color: #f0fff4;
  }

  .sm\:hover\:tw-to-green-200:hover {
    --gradient-to-color: #c6f6d5;
  }

  .sm\:hover\:tw-to-green-300:hover {
    --gradient-to-color: #9ae6b4;
  }

  .sm\:hover\:tw-to-green-400:hover {
    --gradient-to-color: #68d391;
  }

  .sm\:hover\:tw-to-green-500:hover {
    --gradient-to-color: #48bb78;
  }

  .sm\:hover\:tw-to-green-600:hover {
    --gradient-to-color: #38a169;
  }

  .sm\:hover\:tw-to-green-700:hover {
    --gradient-to-color: #2f855a;
  }

  .sm\:hover\:tw-to-green-800:hover {
    --gradient-to-color: #276749;
  }

  .sm\:hover\:tw-to-green-900:hover {
    --gradient-to-color: #22543d;
  }

  .sm\:hover\:tw-to-teal-100:hover {
    --gradient-to-color: #e6fffa;
  }

  .sm\:hover\:tw-to-teal-200:hover {
    --gradient-to-color: #b2f5ea;
  }

  .sm\:hover\:tw-to-teal-300:hover {
    --gradient-to-color: #81e6d9;
  }

  .sm\:hover\:tw-to-teal-400:hover {
    --gradient-to-color: #4fd1c5;
  }

  .sm\:hover\:tw-to-teal-500:hover {
    --gradient-to-color: #38b2ac;
  }

  .sm\:hover\:tw-to-teal-600:hover {
    --gradient-to-color: #319795;
  }

  .sm\:hover\:tw-to-teal-700:hover {
    --gradient-to-color: #2c7a7b;
  }

  .sm\:hover\:tw-to-teal-800:hover {
    --gradient-to-color: #285e61;
  }

  .sm\:hover\:tw-to-teal-900:hover {
    --gradient-to-color: #234e52;
  }

  .sm\:hover\:tw-to-blue-100:hover {
    --gradient-to-color: #ebf8ff;
  }

  .sm\:hover\:tw-to-blue-200:hover {
    --gradient-to-color: #bee3f8;
  }

  .sm\:hover\:tw-to-blue-300:hover {
    --gradient-to-color: #90cdf4;
  }

  .sm\:hover\:tw-to-blue-400:hover {
    --gradient-to-color: #63b3ed;
  }

  .sm\:hover\:tw-to-blue-500:hover {
    --gradient-to-color: #4299e1;
  }

  .sm\:hover\:tw-to-blue-600:hover {
    --gradient-to-color: #3182ce;
  }

  .sm\:hover\:tw-to-blue-700:hover {
    --gradient-to-color: #2b6cb0;
  }

  .sm\:hover\:tw-to-blue-800:hover {
    --gradient-to-color: #2c5282;
  }

  .sm\:hover\:tw-to-blue-900:hover {
    --gradient-to-color: #2a4365;
  }

  .sm\:hover\:tw-to-indigo-100:hover {
    --gradient-to-color: #ebf4ff;
  }

  .sm\:hover\:tw-to-indigo-200:hover {
    --gradient-to-color: #c3dafe;
  }

  .sm\:hover\:tw-to-indigo-300:hover {
    --gradient-to-color: #a3bffa;
  }

  .sm\:hover\:tw-to-indigo-400:hover {
    --gradient-to-color: #7f9cf5;
  }

  .sm\:hover\:tw-to-indigo-500:hover {
    --gradient-to-color: #667eea;
  }

  .sm\:hover\:tw-to-indigo-600:hover {
    --gradient-to-color: #5a67d8;
  }

  .sm\:hover\:tw-to-indigo-700:hover {
    --gradient-to-color: #4c51bf;
  }

  .sm\:hover\:tw-to-indigo-800:hover {
    --gradient-to-color: #434190;
  }

  .sm\:hover\:tw-to-indigo-900:hover {
    --gradient-to-color: #3c366b;
  }

  .sm\:hover\:tw-to-purple-100:hover {
    --gradient-to-color: #faf5ff;
  }

  .sm\:hover\:tw-to-purple-200:hover {
    --gradient-to-color: #e9d8fd;
  }

  .sm\:hover\:tw-to-purple-300:hover {
    --gradient-to-color: #d6bcfa;
  }

  .sm\:hover\:tw-to-purple-400:hover {
    --gradient-to-color: #b794f4;
  }

  .sm\:hover\:tw-to-purple-500:hover {
    --gradient-to-color: #9f7aea;
  }

  .sm\:hover\:tw-to-purple-600:hover {
    --gradient-to-color: #805ad5;
  }

  .sm\:hover\:tw-to-purple-700:hover {
    --gradient-to-color: #6b46c1;
  }

  .sm\:hover\:tw-to-purple-800:hover {
    --gradient-to-color: #553c9a;
  }

  .sm\:hover\:tw-to-purple-900:hover {
    --gradient-to-color: #44337a;
  }

  .sm\:hover\:tw-to-pink-100:hover {
    --gradient-to-color: #fff5f7;
  }

  .sm\:hover\:tw-to-pink-200:hover {
    --gradient-to-color: #fed7e2;
  }

  .sm\:hover\:tw-to-pink-300:hover {
    --gradient-to-color: #fbb6ce;
  }

  .sm\:hover\:tw-to-pink-400:hover {
    --gradient-to-color: #f687b3;
  }

  .sm\:hover\:tw-to-pink-500:hover {
    --gradient-to-color: #ed64a6;
  }

  .sm\:hover\:tw-to-pink-600:hover {
    --gradient-to-color: #d53f8c;
  }

  .sm\:hover\:tw-to-pink-700:hover {
    --gradient-to-color: #b83280;
  }

  .sm\:hover\:tw-to-pink-800:hover {
    --gradient-to-color: #97266d;
  }

  .sm\:hover\:tw-to-pink-900:hover {
    --gradient-to-color: #702459;
  }

  .sm\:focus\:tw-from-transparent:focus {
    --gradient-from-color: transparent;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(0, 0, 0, 0));
  }

  .sm\:focus\:tw-from-current:focus {
    --gradient-from-color: currentColor;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0));
  }

  .sm\:focus\:tw-from-black:focus {
    --gradient-from-color: #000;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(0, 0, 0, 0));
  }

  .sm\:focus\:tw-from-white:focus {
    --gradient-from-color: #fff;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0));
  }

  .sm\:focus\:tw-from-gray-100:focus {
    --gradient-from-color: #f7fafc;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(247, 250, 252, 0));
  }

  .sm\:focus\:tw-from-gray-200:focus {
    --gradient-from-color: #edf2f7;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(237, 242, 247, 0));
  }

  .sm\:focus\:tw-from-gray-300:focus {
    --gradient-from-color: #e2e8f0;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(226, 232, 240, 0));
  }

  .sm\:focus\:tw-from-gray-400:focus {
    --gradient-from-color: #cbd5e0;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(203, 213, 224, 0));
  }

  .sm\:focus\:tw-from-gray-500:focus {
    --gradient-from-color: #a0aec0;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(160, 174, 192, 0));
  }

  .sm\:focus\:tw-from-gray-600:focus {
    --gradient-from-color: #718096;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(113, 128, 150, 0));
  }

  .sm\:focus\:tw-from-gray-700:focus {
    --gradient-from-color: #4a5568;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(74, 85, 104, 0));
  }

  .sm\:focus\:tw-from-gray-800:focus {
    --gradient-from-color: #2d3748;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(45, 55, 72, 0));
  }

  .sm\:focus\:tw-from-gray-900:focus {
    --gradient-from-color: #1a202c;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(26, 32, 44, 0));
  }

  .sm\:focus\:tw-from-red-100:focus {
    --gradient-from-color: #fff5f5;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 245, 245, 0));
  }

  .sm\:focus\:tw-from-red-200:focus {
    --gradient-from-color: #fed7d7;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(254, 215, 215, 0));
  }

  .sm\:focus\:tw-from-red-300:focus {
    --gradient-from-color: #feb2b2;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(254, 178, 178, 0));
  }

  .sm\:focus\:tw-from-red-400:focus {
    --gradient-from-color: #fc8181;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(252, 129, 129, 0));
  }

  .sm\:focus\:tw-from-red-500:focus {
    --gradient-from-color: #f56565;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(245, 101, 101, 0));
  }

  .sm\:focus\:tw-from-red-600:focus {
    --gradient-from-color: #e53e3e;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(229, 62, 62, 0));
  }

  .sm\:focus\:tw-from-red-700:focus {
    --gradient-from-color: #c53030;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(197, 48, 48, 0));
  }

  .sm\:focus\:tw-from-red-800:focus {
    --gradient-from-color: #9b2c2c;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(155, 44, 44, 0));
  }

  .sm\:focus\:tw-from-red-900:focus {
    --gradient-from-color: #742a2a;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(116, 42, 42, 0));
  }

  .sm\:focus\:tw-from-orange-100:focus {
    --gradient-from-color: #fffaf0;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 250, 240, 0));
  }

  .sm\:focus\:tw-from-orange-200:focus {
    --gradient-from-color: #feebc8;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(254, 235, 200, 0));
  }

  .sm\:focus\:tw-from-orange-300:focus {
    --gradient-from-color: #fbd38d;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(251, 211, 141, 0));
  }

  .sm\:focus\:tw-from-orange-400:focus {
    --gradient-from-color: #f6ad55;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(246, 173, 85, 0));
  }

  .sm\:focus\:tw-from-orange-500:focus {
    --gradient-from-color: #ed8936;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(237, 137, 54, 0));
  }

  .sm\:focus\:tw-from-orange-600:focus {
    --gradient-from-color: #dd6b20;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(221, 107, 32, 0));
  }

  .sm\:focus\:tw-from-orange-700:focus {
    --gradient-from-color: #c05621;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(192, 86, 33, 0));
  }

  .sm\:focus\:tw-from-orange-800:focus {
    --gradient-from-color: #9c4221;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(156, 66, 33, 0));
  }

  .sm\:focus\:tw-from-orange-900:focus {
    --gradient-from-color: #7b341e;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(123, 52, 30, 0));
  }

  .sm\:focus\:tw-from-yellow-100:focus {
    --gradient-from-color: #fffff0;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 240, 0));
  }

  .sm\:focus\:tw-from-yellow-200:focus {
    --gradient-from-color: #fefcbf;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(254, 252, 191, 0));
  }

  .sm\:focus\:tw-from-yellow-300:focus {
    --gradient-from-color: #faf089;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(250, 240, 137, 0));
  }

  .sm\:focus\:tw-from-yellow-400:focus {
    --gradient-from-color: #f6e05e;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(246, 224, 94, 0));
  }

  .sm\:focus\:tw-from-yellow-500:focus {
    --gradient-from-color: #ecc94b;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(236, 201, 75, 0));
  }

  .sm\:focus\:tw-from-yellow-600:focus {
    --gradient-from-color: #d69e2e;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(214, 158, 46, 0));
  }

  .sm\:focus\:tw-from-yellow-700:focus {
    --gradient-from-color: #b7791f;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(183, 121, 31, 0));
  }

  .sm\:focus\:tw-from-yellow-800:focus {
    --gradient-from-color: #975a16;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(151, 90, 22, 0));
  }

  .sm\:focus\:tw-from-yellow-900:focus {
    --gradient-from-color: #744210;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(116, 66, 16, 0));
  }

  .sm\:focus\:tw-from-green-100:focus {
    --gradient-from-color: #f0fff4;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(240, 255, 244, 0));
  }

  .sm\:focus\:tw-from-green-200:focus {
    --gradient-from-color: #c6f6d5;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(198, 246, 213, 0));
  }

  .sm\:focus\:tw-from-green-300:focus {
    --gradient-from-color: #9ae6b4;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(154, 230, 180, 0));
  }

  .sm\:focus\:tw-from-green-400:focus {
    --gradient-from-color: #68d391;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(104, 211, 145, 0));
  }

  .sm\:focus\:tw-from-green-500:focus {
    --gradient-from-color: #48bb78;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(72, 187, 120, 0));
  }

  .sm\:focus\:tw-from-green-600:focus {
    --gradient-from-color: #38a169;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(56, 161, 105, 0));
  }

  .sm\:focus\:tw-from-green-700:focus {
    --gradient-from-color: #2f855a;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(47, 133, 90, 0));
  }

  .sm\:focus\:tw-from-green-800:focus {
    --gradient-from-color: #276749;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(39, 103, 73, 0));
  }

  .sm\:focus\:tw-from-green-900:focus {
    --gradient-from-color: #22543d;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(34, 84, 61, 0));
  }

  .sm\:focus\:tw-from-teal-100:focus {
    --gradient-from-color: #e6fffa;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(230, 255, 250, 0));
  }

  .sm\:focus\:tw-from-teal-200:focus {
    --gradient-from-color: #b2f5ea;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(178, 245, 234, 0));
  }

  .sm\:focus\:tw-from-teal-300:focus {
    --gradient-from-color: #81e6d9;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(129, 230, 217, 0));
  }

  .sm\:focus\:tw-from-teal-400:focus {
    --gradient-from-color: #4fd1c5;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(79, 209, 197, 0));
  }

  .sm\:focus\:tw-from-teal-500:focus {
    --gradient-from-color: #38b2ac;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(56, 178, 172, 0));
  }

  .sm\:focus\:tw-from-teal-600:focus {
    --gradient-from-color: #319795;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(49, 151, 149, 0));
  }

  .sm\:focus\:tw-from-teal-700:focus {
    --gradient-from-color: #2c7a7b;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(44, 122, 123, 0));
  }

  .sm\:focus\:tw-from-teal-800:focus {
    --gradient-from-color: #285e61;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(40, 94, 97, 0));
  }

  .sm\:focus\:tw-from-teal-900:focus {
    --gradient-from-color: #234e52;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(35, 78, 82, 0));
  }

  .sm\:focus\:tw-from-blue-100:focus {
    --gradient-from-color: #ebf8ff;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(235, 248, 255, 0));
  }

  .sm\:focus\:tw-from-blue-200:focus {
    --gradient-from-color: #bee3f8;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(190, 227, 248, 0));
  }

  .sm\:focus\:tw-from-blue-300:focus {
    --gradient-from-color: #90cdf4;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(144, 205, 244, 0));
  }

  .sm\:focus\:tw-from-blue-400:focus {
    --gradient-from-color: #63b3ed;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(99, 179, 237, 0));
  }

  .sm\:focus\:tw-from-blue-500:focus {
    --gradient-from-color: #4299e1;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(66, 153, 225, 0));
  }

  .sm\:focus\:tw-from-blue-600:focus {
    --gradient-from-color: #3182ce;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(49, 130, 206, 0));
  }

  .sm\:focus\:tw-from-blue-700:focus {
    --gradient-from-color: #2b6cb0;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(43, 108, 176, 0));
  }

  .sm\:focus\:tw-from-blue-800:focus {
    --gradient-from-color: #2c5282;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(44, 82, 130, 0));
  }

  .sm\:focus\:tw-from-blue-900:focus {
    --gradient-from-color: #2a4365;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(42, 67, 101, 0));
  }

  .sm\:focus\:tw-from-indigo-100:focus {
    --gradient-from-color: #ebf4ff;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(235, 244, 255, 0));
  }

  .sm\:focus\:tw-from-indigo-200:focus {
    --gradient-from-color: #c3dafe;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(195, 218, 254, 0));
  }

  .sm\:focus\:tw-from-indigo-300:focus {
    --gradient-from-color: #a3bffa;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(163, 191, 250, 0));
  }

  .sm\:focus\:tw-from-indigo-400:focus {
    --gradient-from-color: #7f9cf5;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(127, 156, 245, 0));
  }

  .sm\:focus\:tw-from-indigo-500:focus {
    --gradient-from-color: #667eea;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(102, 126, 234, 0));
  }

  .sm\:focus\:tw-from-indigo-600:focus {
    --gradient-from-color: #5a67d8;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(90, 103, 216, 0));
  }

  .sm\:focus\:tw-from-indigo-700:focus {
    --gradient-from-color: #4c51bf;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(76, 81, 191, 0));
  }

  .sm\:focus\:tw-from-indigo-800:focus {
    --gradient-from-color: #434190;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(67, 65, 144, 0));
  }

  .sm\:focus\:tw-from-indigo-900:focus {
    --gradient-from-color: #3c366b;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(60, 54, 107, 0));
  }

  .sm\:focus\:tw-from-purple-100:focus {
    --gradient-from-color: #faf5ff;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(250, 245, 255, 0));
  }

  .sm\:focus\:tw-from-purple-200:focus {
    --gradient-from-color: #e9d8fd;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(233, 216, 253, 0));
  }

  .sm\:focus\:tw-from-purple-300:focus {
    --gradient-from-color: #d6bcfa;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(214, 188, 250, 0));
  }

  .sm\:focus\:tw-from-purple-400:focus {
    --gradient-from-color: #b794f4;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(183, 148, 244, 0));
  }

  .sm\:focus\:tw-from-purple-500:focus {
    --gradient-from-color: #9f7aea;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(159, 122, 234, 0));
  }

  .sm\:focus\:tw-from-purple-600:focus {
    --gradient-from-color: #805ad5;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(128, 90, 213, 0));
  }

  .sm\:focus\:tw-from-purple-700:focus {
    --gradient-from-color: #6b46c1;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(107, 70, 193, 0));
  }

  .sm\:focus\:tw-from-purple-800:focus {
    --gradient-from-color: #553c9a;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(85, 60, 154, 0));
  }

  .sm\:focus\:tw-from-purple-900:focus {
    --gradient-from-color: #44337a;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(68, 51, 122, 0));
  }

  .sm\:focus\:tw-from-pink-100:focus {
    --gradient-from-color: #fff5f7;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 245, 247, 0));
  }

  .sm\:focus\:tw-from-pink-200:focus {
    --gradient-from-color: #fed7e2;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(254, 215, 226, 0));
  }

  .sm\:focus\:tw-from-pink-300:focus {
    --gradient-from-color: #fbb6ce;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(251, 182, 206, 0));
  }

  .sm\:focus\:tw-from-pink-400:focus {
    --gradient-from-color: #f687b3;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(246, 135, 179, 0));
  }

  .sm\:focus\:tw-from-pink-500:focus {
    --gradient-from-color: #ed64a6;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(237, 100, 166, 0));
  }

  .sm\:focus\:tw-from-pink-600:focus {
    --gradient-from-color: #d53f8c;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(213, 63, 140, 0));
  }

  .sm\:focus\:tw-from-pink-700:focus {
    --gradient-from-color: #b83280;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(184, 50, 128, 0));
  }

  .sm\:focus\:tw-from-pink-800:focus {
    --gradient-from-color: #97266d;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(151, 38, 109, 0));
  }

  .sm\:focus\:tw-from-pink-900:focus {
    --gradient-from-color: #702459;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(112, 36, 89, 0));
  }

  .sm\:focus\:tw-via-transparent:focus {
    --gradient-via-color: transparent;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(0, 0, 0, 0));
  }

  .sm\:focus\:tw-via-current:focus {
    --gradient-via-color: currentColor;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0));
  }

  .sm\:focus\:tw-via-black:focus {
    --gradient-via-color: #000;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(0, 0, 0, 0));
  }

  .sm\:focus\:tw-via-white:focus {
    --gradient-via-color: #fff;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0));
  }

  .sm\:focus\:tw-via-gray-100:focus {
    --gradient-via-color: #f7fafc;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(247, 250, 252, 0));
  }

  .sm\:focus\:tw-via-gray-200:focus {
    --gradient-via-color: #edf2f7;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(237, 242, 247, 0));
  }

  .sm\:focus\:tw-via-gray-300:focus {
    --gradient-via-color: #e2e8f0;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(226, 232, 240, 0));
  }

  .sm\:focus\:tw-via-gray-400:focus {
    --gradient-via-color: #cbd5e0;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(203, 213, 224, 0));
  }

  .sm\:focus\:tw-via-gray-500:focus {
    --gradient-via-color: #a0aec0;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(160, 174, 192, 0));
  }

  .sm\:focus\:tw-via-gray-600:focus {
    --gradient-via-color: #718096;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(113, 128, 150, 0));
  }

  .sm\:focus\:tw-via-gray-700:focus {
    --gradient-via-color: #4a5568;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(74, 85, 104, 0));
  }

  .sm\:focus\:tw-via-gray-800:focus {
    --gradient-via-color: #2d3748;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(45, 55, 72, 0));
  }

  .sm\:focus\:tw-via-gray-900:focus {
    --gradient-via-color: #1a202c;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(26, 32, 44, 0));
  }

  .sm\:focus\:tw-via-red-100:focus {
    --gradient-via-color: #fff5f5;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 245, 245, 0));
  }

  .sm\:focus\:tw-via-red-200:focus {
    --gradient-via-color: #fed7d7;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(254, 215, 215, 0));
  }

  .sm\:focus\:tw-via-red-300:focus {
    --gradient-via-color: #feb2b2;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(254, 178, 178, 0));
  }

  .sm\:focus\:tw-via-red-400:focus {
    --gradient-via-color: #fc8181;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(252, 129, 129, 0));
  }

  .sm\:focus\:tw-via-red-500:focus {
    --gradient-via-color: #f56565;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(245, 101, 101, 0));
  }

  .sm\:focus\:tw-via-red-600:focus {
    --gradient-via-color: #e53e3e;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(229, 62, 62, 0));
  }

  .sm\:focus\:tw-via-red-700:focus {
    --gradient-via-color: #c53030;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(197, 48, 48, 0));
  }

  .sm\:focus\:tw-via-red-800:focus {
    --gradient-via-color: #9b2c2c;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(155, 44, 44, 0));
  }

  .sm\:focus\:tw-via-red-900:focus {
    --gradient-via-color: #742a2a;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(116, 42, 42, 0));
  }

  .sm\:focus\:tw-via-orange-100:focus {
    --gradient-via-color: #fffaf0;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 250, 240, 0));
  }

  .sm\:focus\:tw-via-orange-200:focus {
    --gradient-via-color: #feebc8;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(254, 235, 200, 0));
  }

  .sm\:focus\:tw-via-orange-300:focus {
    --gradient-via-color: #fbd38d;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(251, 211, 141, 0));
  }

  .sm\:focus\:tw-via-orange-400:focus {
    --gradient-via-color: #f6ad55;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(246, 173, 85, 0));
  }

  .sm\:focus\:tw-via-orange-500:focus {
    --gradient-via-color: #ed8936;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(237, 137, 54, 0));
  }

  .sm\:focus\:tw-via-orange-600:focus {
    --gradient-via-color: #dd6b20;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(221, 107, 32, 0));
  }

  .sm\:focus\:tw-via-orange-700:focus {
    --gradient-via-color: #c05621;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(192, 86, 33, 0));
  }

  .sm\:focus\:tw-via-orange-800:focus {
    --gradient-via-color: #9c4221;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(156, 66, 33, 0));
  }

  .sm\:focus\:tw-via-orange-900:focus {
    --gradient-via-color: #7b341e;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(123, 52, 30, 0));
  }

  .sm\:focus\:tw-via-yellow-100:focus {
    --gradient-via-color: #fffff0;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 240, 0));
  }

  .sm\:focus\:tw-via-yellow-200:focus {
    --gradient-via-color: #fefcbf;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(254, 252, 191, 0));
  }

  .sm\:focus\:tw-via-yellow-300:focus {
    --gradient-via-color: #faf089;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(250, 240, 137, 0));
  }

  .sm\:focus\:tw-via-yellow-400:focus {
    --gradient-via-color: #f6e05e;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(246, 224, 94, 0));
  }

  .sm\:focus\:tw-via-yellow-500:focus {
    --gradient-via-color: #ecc94b;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(236, 201, 75, 0));
  }

  .sm\:focus\:tw-via-yellow-600:focus {
    --gradient-via-color: #d69e2e;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(214, 158, 46, 0));
  }

  .sm\:focus\:tw-via-yellow-700:focus {
    --gradient-via-color: #b7791f;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(183, 121, 31, 0));
  }

  .sm\:focus\:tw-via-yellow-800:focus {
    --gradient-via-color: #975a16;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(151, 90, 22, 0));
  }

  .sm\:focus\:tw-via-yellow-900:focus {
    --gradient-via-color: #744210;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(116, 66, 16, 0));
  }

  .sm\:focus\:tw-via-green-100:focus {
    --gradient-via-color: #f0fff4;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(240, 255, 244, 0));
  }

  .sm\:focus\:tw-via-green-200:focus {
    --gradient-via-color: #c6f6d5;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(198, 246, 213, 0));
  }

  .sm\:focus\:tw-via-green-300:focus {
    --gradient-via-color: #9ae6b4;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(154, 230, 180, 0));
  }

  .sm\:focus\:tw-via-green-400:focus {
    --gradient-via-color: #68d391;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(104, 211, 145, 0));
  }

  .sm\:focus\:tw-via-green-500:focus {
    --gradient-via-color: #48bb78;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(72, 187, 120, 0));
  }

  .sm\:focus\:tw-via-green-600:focus {
    --gradient-via-color: #38a169;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(56, 161, 105, 0));
  }

  .sm\:focus\:tw-via-green-700:focus {
    --gradient-via-color: #2f855a;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(47, 133, 90, 0));
  }

  .sm\:focus\:tw-via-green-800:focus {
    --gradient-via-color: #276749;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(39, 103, 73, 0));
  }

  .sm\:focus\:tw-via-green-900:focus {
    --gradient-via-color: #22543d;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(34, 84, 61, 0));
  }

  .sm\:focus\:tw-via-teal-100:focus {
    --gradient-via-color: #e6fffa;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(230, 255, 250, 0));
  }

  .sm\:focus\:tw-via-teal-200:focus {
    --gradient-via-color: #b2f5ea;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(178, 245, 234, 0));
  }

  .sm\:focus\:tw-via-teal-300:focus {
    --gradient-via-color: #81e6d9;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(129, 230, 217, 0));
  }

  .sm\:focus\:tw-via-teal-400:focus {
    --gradient-via-color: #4fd1c5;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(79, 209, 197, 0));
  }

  .sm\:focus\:tw-via-teal-500:focus {
    --gradient-via-color: #38b2ac;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(56, 178, 172, 0));
  }

  .sm\:focus\:tw-via-teal-600:focus {
    --gradient-via-color: #319795;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(49, 151, 149, 0));
  }

  .sm\:focus\:tw-via-teal-700:focus {
    --gradient-via-color: #2c7a7b;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(44, 122, 123, 0));
  }

  .sm\:focus\:tw-via-teal-800:focus {
    --gradient-via-color: #285e61;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(40, 94, 97, 0));
  }

  .sm\:focus\:tw-via-teal-900:focus {
    --gradient-via-color: #234e52;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(35, 78, 82, 0));
  }

  .sm\:focus\:tw-via-blue-100:focus {
    --gradient-via-color: #ebf8ff;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(235, 248, 255, 0));
  }

  .sm\:focus\:tw-via-blue-200:focus {
    --gradient-via-color: #bee3f8;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(190, 227, 248, 0));
  }

  .sm\:focus\:tw-via-blue-300:focus {
    --gradient-via-color: #90cdf4;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(144, 205, 244, 0));
  }

  .sm\:focus\:tw-via-blue-400:focus {
    --gradient-via-color: #63b3ed;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(99, 179, 237, 0));
  }

  .sm\:focus\:tw-via-blue-500:focus {
    --gradient-via-color: #4299e1;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(66, 153, 225, 0));
  }

  .sm\:focus\:tw-via-blue-600:focus {
    --gradient-via-color: #3182ce;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(49, 130, 206, 0));
  }

  .sm\:focus\:tw-via-blue-700:focus {
    --gradient-via-color: #2b6cb0;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(43, 108, 176, 0));
  }

  .sm\:focus\:tw-via-blue-800:focus {
    --gradient-via-color: #2c5282;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(44, 82, 130, 0));
  }

  .sm\:focus\:tw-via-blue-900:focus {
    --gradient-via-color: #2a4365;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(42, 67, 101, 0));
  }

  .sm\:focus\:tw-via-indigo-100:focus {
    --gradient-via-color: #ebf4ff;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(235, 244, 255, 0));
  }

  .sm\:focus\:tw-via-indigo-200:focus {
    --gradient-via-color: #c3dafe;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(195, 218, 254, 0));
  }

  .sm\:focus\:tw-via-indigo-300:focus {
    --gradient-via-color: #a3bffa;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(163, 191, 250, 0));
  }

  .sm\:focus\:tw-via-indigo-400:focus {
    --gradient-via-color: #7f9cf5;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(127, 156, 245, 0));
  }

  .sm\:focus\:tw-via-indigo-500:focus {
    --gradient-via-color: #667eea;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(102, 126, 234, 0));
  }

  .sm\:focus\:tw-via-indigo-600:focus {
    --gradient-via-color: #5a67d8;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(90, 103, 216, 0));
  }

  .sm\:focus\:tw-via-indigo-700:focus {
    --gradient-via-color: #4c51bf;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(76, 81, 191, 0));
  }

  .sm\:focus\:tw-via-indigo-800:focus {
    --gradient-via-color: #434190;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(67, 65, 144, 0));
  }

  .sm\:focus\:tw-via-indigo-900:focus {
    --gradient-via-color: #3c366b;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(60, 54, 107, 0));
  }

  .sm\:focus\:tw-via-purple-100:focus {
    --gradient-via-color: #faf5ff;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(250, 245, 255, 0));
  }

  .sm\:focus\:tw-via-purple-200:focus {
    --gradient-via-color: #e9d8fd;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(233, 216, 253, 0));
  }

  .sm\:focus\:tw-via-purple-300:focus {
    --gradient-via-color: #d6bcfa;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(214, 188, 250, 0));
  }

  .sm\:focus\:tw-via-purple-400:focus {
    --gradient-via-color: #b794f4;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(183, 148, 244, 0));
  }

  .sm\:focus\:tw-via-purple-500:focus {
    --gradient-via-color: #9f7aea;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(159, 122, 234, 0));
  }

  .sm\:focus\:tw-via-purple-600:focus {
    --gradient-via-color: #805ad5;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(128, 90, 213, 0));
  }

  .sm\:focus\:tw-via-purple-700:focus {
    --gradient-via-color: #6b46c1;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(107, 70, 193, 0));
  }

  .sm\:focus\:tw-via-purple-800:focus {
    --gradient-via-color: #553c9a;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(85, 60, 154, 0));
  }

  .sm\:focus\:tw-via-purple-900:focus {
    --gradient-via-color: #44337a;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(68, 51, 122, 0));
  }

  .sm\:focus\:tw-via-pink-100:focus {
    --gradient-via-color: #fff5f7;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 245, 247, 0));
  }

  .sm\:focus\:tw-via-pink-200:focus {
    --gradient-via-color: #fed7e2;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(254, 215, 226, 0));
  }

  .sm\:focus\:tw-via-pink-300:focus {
    --gradient-via-color: #fbb6ce;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(251, 182, 206, 0));
  }

  .sm\:focus\:tw-via-pink-400:focus {
    --gradient-via-color: #f687b3;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(246, 135, 179, 0));
  }

  .sm\:focus\:tw-via-pink-500:focus {
    --gradient-via-color: #ed64a6;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(237, 100, 166, 0));
  }

  .sm\:focus\:tw-via-pink-600:focus {
    --gradient-via-color: #d53f8c;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(213, 63, 140, 0));
  }

  .sm\:focus\:tw-via-pink-700:focus {
    --gradient-via-color: #b83280;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(184, 50, 128, 0));
  }

  .sm\:focus\:tw-via-pink-800:focus {
    --gradient-via-color: #97266d;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(151, 38, 109, 0));
  }

  .sm\:focus\:tw-via-pink-900:focus {
    --gradient-via-color: #702459;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(112, 36, 89, 0));
  }

  .sm\:focus\:tw-to-transparent:focus {
    --gradient-to-color: transparent;
  }

  .sm\:focus\:tw-to-current:focus {
    --gradient-to-color: currentColor;
  }

  .sm\:focus\:tw-to-black:focus {
    --gradient-to-color: #000;
  }

  .sm\:focus\:tw-to-white:focus {
    --gradient-to-color: #fff;
  }

  .sm\:focus\:tw-to-gray-100:focus {
    --gradient-to-color: #f7fafc;
  }

  .sm\:focus\:tw-to-gray-200:focus {
    --gradient-to-color: #edf2f7;
  }

  .sm\:focus\:tw-to-gray-300:focus {
    --gradient-to-color: #e2e8f0;
  }

  .sm\:focus\:tw-to-gray-400:focus {
    --gradient-to-color: #cbd5e0;
  }

  .sm\:focus\:tw-to-gray-500:focus {
    --gradient-to-color: #a0aec0;
  }

  .sm\:focus\:tw-to-gray-600:focus {
    --gradient-to-color: #718096;
  }

  .sm\:focus\:tw-to-gray-700:focus {
    --gradient-to-color: #4a5568;
  }

  .sm\:focus\:tw-to-gray-800:focus {
    --gradient-to-color: #2d3748;
  }

  .sm\:focus\:tw-to-gray-900:focus {
    --gradient-to-color: #1a202c;
  }

  .sm\:focus\:tw-to-red-100:focus {
    --gradient-to-color: #fff5f5;
  }

  .sm\:focus\:tw-to-red-200:focus {
    --gradient-to-color: #fed7d7;
  }

  .sm\:focus\:tw-to-red-300:focus {
    --gradient-to-color: #feb2b2;
  }

  .sm\:focus\:tw-to-red-400:focus {
    --gradient-to-color: #fc8181;
  }

  .sm\:focus\:tw-to-red-500:focus {
    --gradient-to-color: #f56565;
  }

  .sm\:focus\:tw-to-red-600:focus {
    --gradient-to-color: #e53e3e;
  }

  .sm\:focus\:tw-to-red-700:focus {
    --gradient-to-color: #c53030;
  }

  .sm\:focus\:tw-to-red-800:focus {
    --gradient-to-color: #9b2c2c;
  }

  .sm\:focus\:tw-to-red-900:focus {
    --gradient-to-color: #742a2a;
  }

  .sm\:focus\:tw-to-orange-100:focus {
    --gradient-to-color: #fffaf0;
  }

  .sm\:focus\:tw-to-orange-200:focus {
    --gradient-to-color: #feebc8;
  }

  .sm\:focus\:tw-to-orange-300:focus {
    --gradient-to-color: #fbd38d;
  }

  .sm\:focus\:tw-to-orange-400:focus {
    --gradient-to-color: #f6ad55;
  }

  .sm\:focus\:tw-to-orange-500:focus {
    --gradient-to-color: #ed8936;
  }

  .sm\:focus\:tw-to-orange-600:focus {
    --gradient-to-color: #dd6b20;
  }

  .sm\:focus\:tw-to-orange-700:focus {
    --gradient-to-color: #c05621;
  }

  .sm\:focus\:tw-to-orange-800:focus {
    --gradient-to-color: #9c4221;
  }

  .sm\:focus\:tw-to-orange-900:focus {
    --gradient-to-color: #7b341e;
  }

  .sm\:focus\:tw-to-yellow-100:focus {
    --gradient-to-color: #fffff0;
  }

  .sm\:focus\:tw-to-yellow-200:focus {
    --gradient-to-color: #fefcbf;
  }

  .sm\:focus\:tw-to-yellow-300:focus {
    --gradient-to-color: #faf089;
  }

  .sm\:focus\:tw-to-yellow-400:focus {
    --gradient-to-color: #f6e05e;
  }

  .sm\:focus\:tw-to-yellow-500:focus {
    --gradient-to-color: #ecc94b;
  }

  .sm\:focus\:tw-to-yellow-600:focus {
    --gradient-to-color: #d69e2e;
  }

  .sm\:focus\:tw-to-yellow-700:focus {
    --gradient-to-color: #b7791f;
  }

  .sm\:focus\:tw-to-yellow-800:focus {
    --gradient-to-color: #975a16;
  }

  .sm\:focus\:tw-to-yellow-900:focus {
    --gradient-to-color: #744210;
  }

  .sm\:focus\:tw-to-green-100:focus {
    --gradient-to-color: #f0fff4;
  }

  .sm\:focus\:tw-to-green-200:focus {
    --gradient-to-color: #c6f6d5;
  }

  .sm\:focus\:tw-to-green-300:focus {
    --gradient-to-color: #9ae6b4;
  }

  .sm\:focus\:tw-to-green-400:focus {
    --gradient-to-color: #68d391;
  }

  .sm\:focus\:tw-to-green-500:focus {
    --gradient-to-color: #48bb78;
  }

  .sm\:focus\:tw-to-green-600:focus {
    --gradient-to-color: #38a169;
  }

  .sm\:focus\:tw-to-green-700:focus {
    --gradient-to-color: #2f855a;
  }

  .sm\:focus\:tw-to-green-800:focus {
    --gradient-to-color: #276749;
  }

  .sm\:focus\:tw-to-green-900:focus {
    --gradient-to-color: #22543d;
  }

  .sm\:focus\:tw-to-teal-100:focus {
    --gradient-to-color: #e6fffa;
  }

  .sm\:focus\:tw-to-teal-200:focus {
    --gradient-to-color: #b2f5ea;
  }

  .sm\:focus\:tw-to-teal-300:focus {
    --gradient-to-color: #81e6d9;
  }

  .sm\:focus\:tw-to-teal-400:focus {
    --gradient-to-color: #4fd1c5;
  }

  .sm\:focus\:tw-to-teal-500:focus {
    --gradient-to-color: #38b2ac;
  }

  .sm\:focus\:tw-to-teal-600:focus {
    --gradient-to-color: #319795;
  }

  .sm\:focus\:tw-to-teal-700:focus {
    --gradient-to-color: #2c7a7b;
  }

  .sm\:focus\:tw-to-teal-800:focus {
    --gradient-to-color: #285e61;
  }

  .sm\:focus\:tw-to-teal-900:focus {
    --gradient-to-color: #234e52;
  }

  .sm\:focus\:tw-to-blue-100:focus {
    --gradient-to-color: #ebf8ff;
  }

  .sm\:focus\:tw-to-blue-200:focus {
    --gradient-to-color: #bee3f8;
  }

  .sm\:focus\:tw-to-blue-300:focus {
    --gradient-to-color: #90cdf4;
  }

  .sm\:focus\:tw-to-blue-400:focus {
    --gradient-to-color: #63b3ed;
  }

  .sm\:focus\:tw-to-blue-500:focus {
    --gradient-to-color: #4299e1;
  }

  .sm\:focus\:tw-to-blue-600:focus {
    --gradient-to-color: #3182ce;
  }

  .sm\:focus\:tw-to-blue-700:focus {
    --gradient-to-color: #2b6cb0;
  }

  .sm\:focus\:tw-to-blue-800:focus {
    --gradient-to-color: #2c5282;
  }

  .sm\:focus\:tw-to-blue-900:focus {
    --gradient-to-color: #2a4365;
  }

  .sm\:focus\:tw-to-indigo-100:focus {
    --gradient-to-color: #ebf4ff;
  }

  .sm\:focus\:tw-to-indigo-200:focus {
    --gradient-to-color: #c3dafe;
  }

  .sm\:focus\:tw-to-indigo-300:focus {
    --gradient-to-color: #a3bffa;
  }

  .sm\:focus\:tw-to-indigo-400:focus {
    --gradient-to-color: #7f9cf5;
  }

  .sm\:focus\:tw-to-indigo-500:focus {
    --gradient-to-color: #667eea;
  }

  .sm\:focus\:tw-to-indigo-600:focus {
    --gradient-to-color: #5a67d8;
  }

  .sm\:focus\:tw-to-indigo-700:focus {
    --gradient-to-color: #4c51bf;
  }

  .sm\:focus\:tw-to-indigo-800:focus {
    --gradient-to-color: #434190;
  }

  .sm\:focus\:tw-to-indigo-900:focus {
    --gradient-to-color: #3c366b;
  }

  .sm\:focus\:tw-to-purple-100:focus {
    --gradient-to-color: #faf5ff;
  }

  .sm\:focus\:tw-to-purple-200:focus {
    --gradient-to-color: #e9d8fd;
  }

  .sm\:focus\:tw-to-purple-300:focus {
    --gradient-to-color: #d6bcfa;
  }

  .sm\:focus\:tw-to-purple-400:focus {
    --gradient-to-color: #b794f4;
  }

  .sm\:focus\:tw-to-purple-500:focus {
    --gradient-to-color: #9f7aea;
  }

  .sm\:focus\:tw-to-purple-600:focus {
    --gradient-to-color: #805ad5;
  }

  .sm\:focus\:tw-to-purple-700:focus {
    --gradient-to-color: #6b46c1;
  }

  .sm\:focus\:tw-to-purple-800:focus {
    --gradient-to-color: #553c9a;
  }

  .sm\:focus\:tw-to-purple-900:focus {
    --gradient-to-color: #44337a;
  }

  .sm\:focus\:tw-to-pink-100:focus {
    --gradient-to-color: #fff5f7;
  }

  .sm\:focus\:tw-to-pink-200:focus {
    --gradient-to-color: #fed7e2;
  }

  .sm\:focus\:tw-to-pink-300:focus {
    --gradient-to-color: #fbb6ce;
  }

  .sm\:focus\:tw-to-pink-400:focus {
    --gradient-to-color: #f687b3;
  }

  .sm\:focus\:tw-to-pink-500:focus {
    --gradient-to-color: #ed64a6;
  }

  .sm\:focus\:tw-to-pink-600:focus {
    --gradient-to-color: #d53f8c;
  }

  .sm\:focus\:tw-to-pink-700:focus {
    --gradient-to-color: #b83280;
  }

  .sm\:focus\:tw-to-pink-800:focus {
    --gradient-to-color: #97266d;
  }

  .sm\:focus\:tw-to-pink-900:focus {
    --gradient-to-color: #702459;
  }

  .sm\:tw-bg-opacity-0 {
    --bg-opacity: 0;
  }

  .sm\:tw-bg-opacity-25 {
    --bg-opacity: 0.25;
  }

  .sm\:tw-bg-opacity-50 {
    --bg-opacity: 0.5;
  }

  .sm\:tw-bg-opacity-75 {
    --bg-opacity: 0.75;
  }

  .sm\:tw-bg-opacity-100 {
    --bg-opacity: 1;
  }

  .sm\:hover\:tw-bg-opacity-0:hover {
    --bg-opacity: 0;
  }

  .sm\:hover\:tw-bg-opacity-25:hover {
    --bg-opacity: 0.25;
  }

  .sm\:hover\:tw-bg-opacity-50:hover {
    --bg-opacity: 0.5;
  }

  .sm\:hover\:tw-bg-opacity-75:hover {
    --bg-opacity: 0.75;
  }

  .sm\:hover\:tw-bg-opacity-100:hover {
    --bg-opacity: 1;
  }

  .sm\:focus\:tw-bg-opacity-0:focus {
    --bg-opacity: 0;
  }

  .sm\:focus\:tw-bg-opacity-25:focus {
    --bg-opacity: 0.25;
  }

  .sm\:focus\:tw-bg-opacity-50:focus {
    --bg-opacity: 0.5;
  }

  .sm\:focus\:tw-bg-opacity-75:focus {
    --bg-opacity: 0.75;
  }

  .sm\:focus\:tw-bg-opacity-100:focus {
    --bg-opacity: 1;
  }

  .sm\:tw-bg-bottom {
    background-position: bottom;
  }

  .sm\:tw-bg-center {
    background-position: center;
  }

  .sm\:tw-bg-left {
    background-position: left;
  }

  .sm\:tw-bg-left-bottom {
    background-position: left bottom;
  }

  .sm\:tw-bg-left-top {
    background-position: left top;
  }

  .sm\:tw-bg-right {
    background-position: right;
  }

  .sm\:tw-bg-right-bottom {
    background-position: right bottom;
  }

  .sm\:tw-bg-right-top {
    background-position: right top;
  }

  .sm\:tw-bg-top {
    background-position: top;
  }

  .sm\:tw-bg-repeat {
    background-repeat: repeat;
  }

  .sm\:tw-bg-no-repeat {
    background-repeat: no-repeat;
  }

  .sm\:tw-bg-repeat-x {
    background-repeat: repeat-x;
  }

  .sm\:tw-bg-repeat-y {
    background-repeat: repeat-y;
  }

  .sm\:tw-bg-repeat-round {
    background-repeat: round;
  }

  .sm\:tw-bg-repeat-space {
    background-repeat: space;
  }

  .sm\:tw-bg-auto {
    background-size: auto;
  }

  .sm\:tw-bg-cover {
    background-size: cover;
  }

  .sm\:tw-bg-contain {
    background-size: contain;
  }

  .sm\:tw-border-collapse {
    border-collapse: collapse;
  }

  .sm\:tw-border-separate {
    border-collapse: separate;
  }

  .sm\:tw-border-transparent {
    border-color: transparent;
  }

  .sm\:tw-border-current {
    border-color: currentColor;
  }

  .sm\:tw-border-black {
    --border-opacity: 1;
    border-color: #000;
    border-color: rgba(0, 0, 0, var(--border-opacity));
  }

  .sm\:tw-border-white {
    --border-opacity: 1;
    border-color: #fff;
    border-color: rgba(255, 255, 255, var(--border-opacity));
  }

  .sm\:tw-border-gray-100 {
    --border-opacity: 1;
    border-color: #f7fafc;
    border-color: rgba(247, 250, 252, var(--border-opacity));
  }

  .sm\:tw-border-gray-200 {
    --border-opacity: 1;
    border-color: #edf2f7;
    border-color: rgba(237, 242, 247, var(--border-opacity));
  }

  .sm\:tw-border-gray-300 {
    --border-opacity: 1;
    border-color: #e2e8f0;
    border-color: rgba(226, 232, 240, var(--border-opacity));
  }

  .sm\:tw-border-gray-400 {
    --border-opacity: 1;
    border-color: #cbd5e0;
    border-color: rgba(203, 213, 224, var(--border-opacity));
  }

  .sm\:tw-border-gray-500 {
    --border-opacity: 1;
    border-color: #a0aec0;
    border-color: rgba(160, 174, 192, var(--border-opacity));
  }

  .sm\:tw-border-gray-600 {
    --border-opacity: 1;
    border-color: #718096;
    border-color: rgba(113, 128, 150, var(--border-opacity));
  }

  .sm\:tw-border-gray-700 {
    --border-opacity: 1;
    border-color: #4a5568;
    border-color: rgba(74, 85, 104, var(--border-opacity));
  }

  .sm\:tw-border-gray-800 {
    --border-opacity: 1;
    border-color: #2d3748;
    border-color: rgba(45, 55, 72, var(--border-opacity));
  }

  .sm\:tw-border-gray-900 {
    --border-opacity: 1;
    border-color: #1a202c;
    border-color: rgba(26, 32, 44, var(--border-opacity));
  }

  .sm\:tw-border-red-100 {
    --border-opacity: 1;
    border-color: #fff5f5;
    border-color: rgba(255, 245, 245, var(--border-opacity));
  }

  .sm\:tw-border-red-200 {
    --border-opacity: 1;
    border-color: #fed7d7;
    border-color: rgba(254, 215, 215, var(--border-opacity));
  }

  .sm\:tw-border-red-300 {
    --border-opacity: 1;
    border-color: #feb2b2;
    border-color: rgba(254, 178, 178, var(--border-opacity));
  }

  .sm\:tw-border-red-400 {
    --border-opacity: 1;
    border-color: #fc8181;
    border-color: rgba(252, 129, 129, var(--border-opacity));
  }

  .sm\:tw-border-red-500 {
    --border-opacity: 1;
    border-color: #f56565;
    border-color: rgba(245, 101, 101, var(--border-opacity));
  }

  .sm\:tw-border-red-600 {
    --border-opacity: 1;
    border-color: #e53e3e;
    border-color: rgba(229, 62, 62, var(--border-opacity));
  }

  .sm\:tw-border-red-700 {
    --border-opacity: 1;
    border-color: #c53030;
    border-color: rgba(197, 48, 48, var(--border-opacity));
  }

  .sm\:tw-border-red-800 {
    --border-opacity: 1;
    border-color: #9b2c2c;
    border-color: rgba(155, 44, 44, var(--border-opacity));
  }

  .sm\:tw-border-red-900 {
    --border-opacity: 1;
    border-color: #742a2a;
    border-color: rgba(116, 42, 42, var(--border-opacity));
  }

  .sm\:tw-border-orange-100 {
    --border-opacity: 1;
    border-color: #fffaf0;
    border-color: rgba(255, 250, 240, var(--border-opacity));
  }

  .sm\:tw-border-orange-200 {
    --border-opacity: 1;
    border-color: #feebc8;
    border-color: rgba(254, 235, 200, var(--border-opacity));
  }

  .sm\:tw-border-orange-300 {
    --border-opacity: 1;
    border-color: #fbd38d;
    border-color: rgba(251, 211, 141, var(--border-opacity));
  }

  .sm\:tw-border-orange-400 {
    --border-opacity: 1;
    border-color: #f6ad55;
    border-color: rgba(246, 173, 85, var(--border-opacity));
  }

  .sm\:tw-border-orange-500 {
    --border-opacity: 1;
    border-color: #ed8936;
    border-color: rgba(237, 137, 54, var(--border-opacity));
  }

  .sm\:tw-border-orange-600 {
    --border-opacity: 1;
    border-color: #dd6b20;
    border-color: rgba(221, 107, 32, var(--border-opacity));
  }

  .sm\:tw-border-orange-700 {
    --border-opacity: 1;
    border-color: #c05621;
    border-color: rgba(192, 86, 33, var(--border-opacity));
  }

  .sm\:tw-border-orange-800 {
    --border-opacity: 1;
    border-color: #9c4221;
    border-color: rgba(156, 66, 33, var(--border-opacity));
  }

  .sm\:tw-border-orange-900 {
    --border-opacity: 1;
    border-color: #7b341e;
    border-color: rgba(123, 52, 30, var(--border-opacity));
  }

  .sm\:tw-border-yellow-100 {
    --border-opacity: 1;
    border-color: #fffff0;
    border-color: rgba(255, 255, 240, var(--border-opacity));
  }

  .sm\:tw-border-yellow-200 {
    --border-opacity: 1;
    border-color: #fefcbf;
    border-color: rgba(254, 252, 191, var(--border-opacity));
  }

  .sm\:tw-border-yellow-300 {
    --border-opacity: 1;
    border-color: #faf089;
    border-color: rgba(250, 240, 137, var(--border-opacity));
  }

  .sm\:tw-border-yellow-400 {
    --border-opacity: 1;
    border-color: #f6e05e;
    border-color: rgba(246, 224, 94, var(--border-opacity));
  }

  .sm\:tw-border-yellow-500 {
    --border-opacity: 1;
    border-color: #ecc94b;
    border-color: rgba(236, 201, 75, var(--border-opacity));
  }

  .sm\:tw-border-yellow-600 {
    --border-opacity: 1;
    border-color: #d69e2e;
    border-color: rgba(214, 158, 46, var(--border-opacity));
  }

  .sm\:tw-border-yellow-700 {
    --border-opacity: 1;
    border-color: #b7791f;
    border-color: rgba(183, 121, 31, var(--border-opacity));
  }

  .sm\:tw-border-yellow-800 {
    --border-opacity: 1;
    border-color: #975a16;
    border-color: rgba(151, 90, 22, var(--border-opacity));
  }

  .sm\:tw-border-yellow-900 {
    --border-opacity: 1;
    border-color: #744210;
    border-color: rgba(116, 66, 16, var(--border-opacity));
  }

  .sm\:tw-border-green-100 {
    --border-opacity: 1;
    border-color: #f0fff4;
    border-color: rgba(240, 255, 244, var(--border-opacity));
  }

  .sm\:tw-border-green-200 {
    --border-opacity: 1;
    border-color: #c6f6d5;
    border-color: rgba(198, 246, 213, var(--border-opacity));
  }

  .sm\:tw-border-green-300 {
    --border-opacity: 1;
    border-color: #9ae6b4;
    border-color: rgba(154, 230, 180, var(--border-opacity));
  }

  .sm\:tw-border-green-400 {
    --border-opacity: 1;
    border-color: #68d391;
    border-color: rgba(104, 211, 145, var(--border-opacity));
  }

  .sm\:tw-border-green-500 {
    --border-opacity: 1;
    border-color: #48bb78;
    border-color: rgba(72, 187, 120, var(--border-opacity));
  }

  .sm\:tw-border-green-600 {
    --border-opacity: 1;
    border-color: #38a169;
    border-color: rgba(56, 161, 105, var(--border-opacity));
  }

  .sm\:tw-border-green-700 {
    --border-opacity: 1;
    border-color: #2f855a;
    border-color: rgba(47, 133, 90, var(--border-opacity));
  }

  .sm\:tw-border-green-800 {
    --border-opacity: 1;
    border-color: #276749;
    border-color: rgba(39, 103, 73, var(--border-opacity));
  }

  .sm\:tw-border-green-900 {
    --border-opacity: 1;
    border-color: #22543d;
    border-color: rgba(34, 84, 61, var(--border-opacity));
  }

  .sm\:tw-border-teal-100 {
    --border-opacity: 1;
    border-color: #e6fffa;
    border-color: rgba(230, 255, 250, var(--border-opacity));
  }

  .sm\:tw-border-teal-200 {
    --border-opacity: 1;
    border-color: #b2f5ea;
    border-color: rgba(178, 245, 234, var(--border-opacity));
  }

  .sm\:tw-border-teal-300 {
    --border-opacity: 1;
    border-color: #81e6d9;
    border-color: rgba(129, 230, 217, var(--border-opacity));
  }

  .sm\:tw-border-teal-400 {
    --border-opacity: 1;
    border-color: #4fd1c5;
    border-color: rgba(79, 209, 197, var(--border-opacity));
  }

  .sm\:tw-border-teal-500 {
    --border-opacity: 1;
    border-color: #38b2ac;
    border-color: rgba(56, 178, 172, var(--border-opacity));
  }

  .sm\:tw-border-teal-600 {
    --border-opacity: 1;
    border-color: #319795;
    border-color: rgba(49, 151, 149, var(--border-opacity));
  }

  .sm\:tw-border-teal-700 {
    --border-opacity: 1;
    border-color: #2c7a7b;
    border-color: rgba(44, 122, 123, var(--border-opacity));
  }

  .sm\:tw-border-teal-800 {
    --border-opacity: 1;
    border-color: #285e61;
    border-color: rgba(40, 94, 97, var(--border-opacity));
  }

  .sm\:tw-border-teal-900 {
    --border-opacity: 1;
    border-color: #234e52;
    border-color: rgba(35, 78, 82, var(--border-opacity));
  }

  .sm\:tw-border-blue-100 {
    --border-opacity: 1;
    border-color: #ebf8ff;
    border-color: rgba(235, 248, 255, var(--border-opacity));
  }

  .sm\:tw-border-blue-200 {
    --border-opacity: 1;
    border-color: #bee3f8;
    border-color: rgba(190, 227, 248, var(--border-opacity));
  }

  .sm\:tw-border-blue-300 {
    --border-opacity: 1;
    border-color: #90cdf4;
    border-color: rgba(144, 205, 244, var(--border-opacity));
  }

  .sm\:tw-border-blue-400 {
    --border-opacity: 1;
    border-color: #63b3ed;
    border-color: rgba(99, 179, 237, var(--border-opacity));
  }

  .sm\:tw-border-blue-500 {
    --border-opacity: 1;
    border-color: #4299e1;
    border-color: rgba(66, 153, 225, var(--border-opacity));
  }

  .sm\:tw-border-blue-600 {
    --border-opacity: 1;
    border-color: #3182ce;
    border-color: rgba(49, 130, 206, var(--border-opacity));
  }

  .sm\:tw-border-blue-700 {
    --border-opacity: 1;
    border-color: #2b6cb0;
    border-color: rgba(43, 108, 176, var(--border-opacity));
  }

  .sm\:tw-border-blue-800 {
    --border-opacity: 1;
    border-color: #2c5282;
    border-color: rgba(44, 82, 130, var(--border-opacity));
  }

  .sm\:tw-border-blue-900 {
    --border-opacity: 1;
    border-color: #2a4365;
    border-color: rgba(42, 67, 101, var(--border-opacity));
  }

  .sm\:tw-border-indigo-100 {
    --border-opacity: 1;
    border-color: #ebf4ff;
    border-color: rgba(235, 244, 255, var(--border-opacity));
  }

  .sm\:tw-border-indigo-200 {
    --border-opacity: 1;
    border-color: #c3dafe;
    border-color: rgba(195, 218, 254, var(--border-opacity));
  }

  .sm\:tw-border-indigo-300 {
    --border-opacity: 1;
    border-color: #a3bffa;
    border-color: rgba(163, 191, 250, var(--border-opacity));
  }

  .sm\:tw-border-indigo-400 {
    --border-opacity: 1;
    border-color: #7f9cf5;
    border-color: rgba(127, 156, 245, var(--border-opacity));
  }

  .sm\:tw-border-indigo-500 {
    --border-opacity: 1;
    border-color: #667eea;
    border-color: rgba(102, 126, 234, var(--border-opacity));
  }

  .sm\:tw-border-indigo-600 {
    --border-opacity: 1;
    border-color: #5a67d8;
    border-color: rgba(90, 103, 216, var(--border-opacity));
  }

  .sm\:tw-border-indigo-700 {
    --border-opacity: 1;
    border-color: #4c51bf;
    border-color: rgba(76, 81, 191, var(--border-opacity));
  }

  .sm\:tw-border-indigo-800 {
    --border-opacity: 1;
    border-color: #434190;
    border-color: rgba(67, 65, 144, var(--border-opacity));
  }

  .sm\:tw-border-indigo-900 {
    --border-opacity: 1;
    border-color: #3c366b;
    border-color: rgba(60, 54, 107, var(--border-opacity));
  }

  .sm\:tw-border-purple-100 {
    --border-opacity: 1;
    border-color: #faf5ff;
    border-color: rgba(250, 245, 255, var(--border-opacity));
  }

  .sm\:tw-border-purple-200 {
    --border-opacity: 1;
    border-color: #e9d8fd;
    border-color: rgba(233, 216, 253, var(--border-opacity));
  }

  .sm\:tw-border-purple-300 {
    --border-opacity: 1;
    border-color: #d6bcfa;
    border-color: rgba(214, 188, 250, var(--border-opacity));
  }

  .sm\:tw-border-purple-400 {
    --border-opacity: 1;
    border-color: #b794f4;
    border-color: rgba(183, 148, 244, var(--border-opacity));
  }

  .sm\:tw-border-purple-500 {
    --border-opacity: 1;
    border-color: #9f7aea;
    border-color: rgba(159, 122, 234, var(--border-opacity));
  }

  .sm\:tw-border-purple-600 {
    --border-opacity: 1;
    border-color: #805ad5;
    border-color: rgba(128, 90, 213, var(--border-opacity));
  }

  .sm\:tw-border-purple-700 {
    --border-opacity: 1;
    border-color: #6b46c1;
    border-color: rgba(107, 70, 193, var(--border-opacity));
  }

  .sm\:tw-border-purple-800 {
    --border-opacity: 1;
    border-color: #553c9a;
    border-color: rgba(85, 60, 154, var(--border-opacity));
  }

  .sm\:tw-border-purple-900 {
    --border-opacity: 1;
    border-color: #44337a;
    border-color: rgba(68, 51, 122, var(--border-opacity));
  }

  .sm\:tw-border-pink-100 {
    --border-opacity: 1;
    border-color: #fff5f7;
    border-color: rgba(255, 245, 247, var(--border-opacity));
  }

  .sm\:tw-border-pink-200 {
    --border-opacity: 1;
    border-color: #fed7e2;
    border-color: rgba(254, 215, 226, var(--border-opacity));
  }

  .sm\:tw-border-pink-300 {
    --border-opacity: 1;
    border-color: #fbb6ce;
    border-color: rgba(251, 182, 206, var(--border-opacity));
  }

  .sm\:tw-border-pink-400 {
    --border-opacity: 1;
    border-color: #f687b3;
    border-color: rgba(246, 135, 179, var(--border-opacity));
  }

  .sm\:tw-border-pink-500 {
    --border-opacity: 1;
    border-color: #ed64a6;
    border-color: rgba(237, 100, 166, var(--border-opacity));
  }

  .sm\:tw-border-pink-600 {
    --border-opacity: 1;
    border-color: #d53f8c;
    border-color: rgba(213, 63, 140, var(--border-opacity));
  }

  .sm\:tw-border-pink-700 {
    --border-opacity: 1;
    border-color: #b83280;
    border-color: rgba(184, 50, 128, var(--border-opacity));
  }

  .sm\:tw-border-pink-800 {
    --border-opacity: 1;
    border-color: #97266d;
    border-color: rgba(151, 38, 109, var(--border-opacity));
  }

  .sm\:tw-border-pink-900 {
    --border-opacity: 1;
    border-color: #702459;
    border-color: rgba(112, 36, 89, var(--border-opacity));
  }

  .sm\:hover\:tw-border-transparent:hover {
    border-color: transparent;
  }

  .sm\:hover\:tw-border-current:hover {
    border-color: currentColor;
  }

  .sm\:hover\:tw-border-black:hover {
    --border-opacity: 1;
    border-color: #000;
    border-color: rgba(0, 0, 0, var(--border-opacity));
  }

  .sm\:hover\:tw-border-white:hover {
    --border-opacity: 1;
    border-color: #fff;
    border-color: rgba(255, 255, 255, var(--border-opacity));
  }

  .sm\:hover\:tw-border-gray-100:hover {
    --border-opacity: 1;
    border-color: #f7fafc;
    border-color: rgba(247, 250, 252, var(--border-opacity));
  }

  .sm\:hover\:tw-border-gray-200:hover {
    --border-opacity: 1;
    border-color: #edf2f7;
    border-color: rgba(237, 242, 247, var(--border-opacity));
  }

  .sm\:hover\:tw-border-gray-300:hover {
    --border-opacity: 1;
    border-color: #e2e8f0;
    border-color: rgba(226, 232, 240, var(--border-opacity));
  }

  .sm\:hover\:tw-border-gray-400:hover {
    --border-opacity: 1;
    border-color: #cbd5e0;
    border-color: rgba(203, 213, 224, var(--border-opacity));
  }

  .sm\:hover\:tw-border-gray-500:hover {
    --border-opacity: 1;
    border-color: #a0aec0;
    border-color: rgba(160, 174, 192, var(--border-opacity));
  }

  .sm\:hover\:tw-border-gray-600:hover {
    --border-opacity: 1;
    border-color: #718096;
    border-color: rgba(113, 128, 150, var(--border-opacity));
  }

  .sm\:hover\:tw-border-gray-700:hover {
    --border-opacity: 1;
    border-color: #4a5568;
    border-color: rgba(74, 85, 104, var(--border-opacity));
  }

  .sm\:hover\:tw-border-gray-800:hover {
    --border-opacity: 1;
    border-color: #2d3748;
    border-color: rgba(45, 55, 72, var(--border-opacity));
  }

  .sm\:hover\:tw-border-gray-900:hover {
    --border-opacity: 1;
    border-color: #1a202c;
    border-color: rgba(26, 32, 44, var(--border-opacity));
  }

  .sm\:hover\:tw-border-red-100:hover {
    --border-opacity: 1;
    border-color: #fff5f5;
    border-color: rgba(255, 245, 245, var(--border-opacity));
  }

  .sm\:hover\:tw-border-red-200:hover {
    --border-opacity: 1;
    border-color: #fed7d7;
    border-color: rgba(254, 215, 215, var(--border-opacity));
  }

  .sm\:hover\:tw-border-red-300:hover {
    --border-opacity: 1;
    border-color: #feb2b2;
    border-color: rgba(254, 178, 178, var(--border-opacity));
  }

  .sm\:hover\:tw-border-red-400:hover {
    --border-opacity: 1;
    border-color: #fc8181;
    border-color: rgba(252, 129, 129, var(--border-opacity));
  }

  .sm\:hover\:tw-border-red-500:hover {
    --border-opacity: 1;
    border-color: #f56565;
    border-color: rgba(245, 101, 101, var(--border-opacity));
  }

  .sm\:hover\:tw-border-red-600:hover {
    --border-opacity: 1;
    border-color: #e53e3e;
    border-color: rgba(229, 62, 62, var(--border-opacity));
  }

  .sm\:hover\:tw-border-red-700:hover {
    --border-opacity: 1;
    border-color: #c53030;
    border-color: rgba(197, 48, 48, var(--border-opacity));
  }

  .sm\:hover\:tw-border-red-800:hover {
    --border-opacity: 1;
    border-color: #9b2c2c;
    border-color: rgba(155, 44, 44, var(--border-opacity));
  }

  .sm\:hover\:tw-border-red-900:hover {
    --border-opacity: 1;
    border-color: #742a2a;
    border-color: rgba(116, 42, 42, var(--border-opacity));
  }

  .sm\:hover\:tw-border-orange-100:hover {
    --border-opacity: 1;
    border-color: #fffaf0;
    border-color: rgba(255, 250, 240, var(--border-opacity));
  }

  .sm\:hover\:tw-border-orange-200:hover {
    --border-opacity: 1;
    border-color: #feebc8;
    border-color: rgba(254, 235, 200, var(--border-opacity));
  }

  .sm\:hover\:tw-border-orange-300:hover {
    --border-opacity: 1;
    border-color: #fbd38d;
    border-color: rgba(251, 211, 141, var(--border-opacity));
  }

  .sm\:hover\:tw-border-orange-400:hover {
    --border-opacity: 1;
    border-color: #f6ad55;
    border-color: rgba(246, 173, 85, var(--border-opacity));
  }

  .sm\:hover\:tw-border-orange-500:hover {
    --border-opacity: 1;
    border-color: #ed8936;
    border-color: rgba(237, 137, 54, var(--border-opacity));
  }

  .sm\:hover\:tw-border-orange-600:hover {
    --border-opacity: 1;
    border-color: #dd6b20;
    border-color: rgba(221, 107, 32, var(--border-opacity));
  }

  .sm\:hover\:tw-border-orange-700:hover {
    --border-opacity: 1;
    border-color: #c05621;
    border-color: rgba(192, 86, 33, var(--border-opacity));
  }

  .sm\:hover\:tw-border-orange-800:hover {
    --border-opacity: 1;
    border-color: #9c4221;
    border-color: rgba(156, 66, 33, var(--border-opacity));
  }

  .sm\:hover\:tw-border-orange-900:hover {
    --border-opacity: 1;
    border-color: #7b341e;
    border-color: rgba(123, 52, 30, var(--border-opacity));
  }

  .sm\:hover\:tw-border-yellow-100:hover {
    --border-opacity: 1;
    border-color: #fffff0;
    border-color: rgba(255, 255, 240, var(--border-opacity));
  }

  .sm\:hover\:tw-border-yellow-200:hover {
    --border-opacity: 1;
    border-color: #fefcbf;
    border-color: rgba(254, 252, 191, var(--border-opacity));
  }

  .sm\:hover\:tw-border-yellow-300:hover {
    --border-opacity: 1;
    border-color: #faf089;
    border-color: rgba(250, 240, 137, var(--border-opacity));
  }

  .sm\:hover\:tw-border-yellow-400:hover {
    --border-opacity: 1;
    border-color: #f6e05e;
    border-color: rgba(246, 224, 94, var(--border-opacity));
  }

  .sm\:hover\:tw-border-yellow-500:hover {
    --border-opacity: 1;
    border-color: #ecc94b;
    border-color: rgba(236, 201, 75, var(--border-opacity));
  }

  .sm\:hover\:tw-border-yellow-600:hover {
    --border-opacity: 1;
    border-color: #d69e2e;
    border-color: rgba(214, 158, 46, var(--border-opacity));
  }

  .sm\:hover\:tw-border-yellow-700:hover {
    --border-opacity: 1;
    border-color: #b7791f;
    border-color: rgba(183, 121, 31, var(--border-opacity));
  }

  .sm\:hover\:tw-border-yellow-800:hover {
    --border-opacity: 1;
    border-color: #975a16;
    border-color: rgba(151, 90, 22, var(--border-opacity));
  }

  .sm\:hover\:tw-border-yellow-900:hover {
    --border-opacity: 1;
    border-color: #744210;
    border-color: rgba(116, 66, 16, var(--border-opacity));
  }

  .sm\:hover\:tw-border-green-100:hover {
    --border-opacity: 1;
    border-color: #f0fff4;
    border-color: rgba(240, 255, 244, var(--border-opacity));
  }

  .sm\:hover\:tw-border-green-200:hover {
    --border-opacity: 1;
    border-color: #c6f6d5;
    border-color: rgba(198, 246, 213, var(--border-opacity));
  }

  .sm\:hover\:tw-border-green-300:hover {
    --border-opacity: 1;
    border-color: #9ae6b4;
    border-color: rgba(154, 230, 180, var(--border-opacity));
  }

  .sm\:hover\:tw-border-green-400:hover {
    --border-opacity: 1;
    border-color: #68d391;
    border-color: rgba(104, 211, 145, var(--border-opacity));
  }

  .sm\:hover\:tw-border-green-500:hover {
    --border-opacity: 1;
    border-color: #48bb78;
    border-color: rgba(72, 187, 120, var(--border-opacity));
  }

  .sm\:hover\:tw-border-green-600:hover {
    --border-opacity: 1;
    border-color: #38a169;
    border-color: rgba(56, 161, 105, var(--border-opacity));
  }

  .sm\:hover\:tw-border-green-700:hover {
    --border-opacity: 1;
    border-color: #2f855a;
    border-color: rgba(47, 133, 90, var(--border-opacity));
  }

  .sm\:hover\:tw-border-green-800:hover {
    --border-opacity: 1;
    border-color: #276749;
    border-color: rgba(39, 103, 73, var(--border-opacity));
  }

  .sm\:hover\:tw-border-green-900:hover {
    --border-opacity: 1;
    border-color: #22543d;
    border-color: rgba(34, 84, 61, var(--border-opacity));
  }

  .sm\:hover\:tw-border-teal-100:hover {
    --border-opacity: 1;
    border-color: #e6fffa;
    border-color: rgba(230, 255, 250, var(--border-opacity));
  }

  .sm\:hover\:tw-border-teal-200:hover {
    --border-opacity: 1;
    border-color: #b2f5ea;
    border-color: rgba(178, 245, 234, var(--border-opacity));
  }

  .sm\:hover\:tw-border-teal-300:hover {
    --border-opacity: 1;
    border-color: #81e6d9;
    border-color: rgba(129, 230, 217, var(--border-opacity));
  }

  .sm\:hover\:tw-border-teal-400:hover {
    --border-opacity: 1;
    border-color: #4fd1c5;
    border-color: rgba(79, 209, 197, var(--border-opacity));
  }

  .sm\:hover\:tw-border-teal-500:hover {
    --border-opacity: 1;
    border-color: #38b2ac;
    border-color: rgba(56, 178, 172, var(--border-opacity));
  }

  .sm\:hover\:tw-border-teal-600:hover {
    --border-opacity: 1;
    border-color: #319795;
    border-color: rgba(49, 151, 149, var(--border-opacity));
  }

  .sm\:hover\:tw-border-teal-700:hover {
    --border-opacity: 1;
    border-color: #2c7a7b;
    border-color: rgba(44, 122, 123, var(--border-opacity));
  }

  .sm\:hover\:tw-border-teal-800:hover {
    --border-opacity: 1;
    border-color: #285e61;
    border-color: rgba(40, 94, 97, var(--border-opacity));
  }

  .sm\:hover\:tw-border-teal-900:hover {
    --border-opacity: 1;
    border-color: #234e52;
    border-color: rgba(35, 78, 82, var(--border-opacity));
  }

  .sm\:hover\:tw-border-blue-100:hover {
    --border-opacity: 1;
    border-color: #ebf8ff;
    border-color: rgba(235, 248, 255, var(--border-opacity));
  }

  .sm\:hover\:tw-border-blue-200:hover {
    --border-opacity: 1;
    border-color: #bee3f8;
    border-color: rgba(190, 227, 248, var(--border-opacity));
  }

  .sm\:hover\:tw-border-blue-300:hover {
    --border-opacity: 1;
    border-color: #90cdf4;
    border-color: rgba(144, 205, 244, var(--border-opacity));
  }

  .sm\:hover\:tw-border-blue-400:hover {
    --border-opacity: 1;
    border-color: #63b3ed;
    border-color: rgba(99, 179, 237, var(--border-opacity));
  }

  .sm\:hover\:tw-border-blue-500:hover {
    --border-opacity: 1;
    border-color: #4299e1;
    border-color: rgba(66, 153, 225, var(--border-opacity));
  }

  .sm\:hover\:tw-border-blue-600:hover {
    --border-opacity: 1;
    border-color: #3182ce;
    border-color: rgba(49, 130, 206, var(--border-opacity));
  }

  .sm\:hover\:tw-border-blue-700:hover {
    --border-opacity: 1;
    border-color: #2b6cb0;
    border-color: rgba(43, 108, 176, var(--border-opacity));
  }

  .sm\:hover\:tw-border-blue-800:hover {
    --border-opacity: 1;
    border-color: #2c5282;
    border-color: rgba(44, 82, 130, var(--border-opacity));
  }

  .sm\:hover\:tw-border-blue-900:hover {
    --border-opacity: 1;
    border-color: #2a4365;
    border-color: rgba(42, 67, 101, var(--border-opacity));
  }

  .sm\:hover\:tw-border-indigo-100:hover {
    --border-opacity: 1;
    border-color: #ebf4ff;
    border-color: rgba(235, 244, 255, var(--border-opacity));
  }

  .sm\:hover\:tw-border-indigo-200:hover {
    --border-opacity: 1;
    border-color: #c3dafe;
    border-color: rgba(195, 218, 254, var(--border-opacity));
  }

  .sm\:hover\:tw-border-indigo-300:hover {
    --border-opacity: 1;
    border-color: #a3bffa;
    border-color: rgba(163, 191, 250, var(--border-opacity));
  }

  .sm\:hover\:tw-border-indigo-400:hover {
    --border-opacity: 1;
    border-color: #7f9cf5;
    border-color: rgba(127, 156, 245, var(--border-opacity));
  }

  .sm\:hover\:tw-border-indigo-500:hover {
    --border-opacity: 1;
    border-color: #667eea;
    border-color: rgba(102, 126, 234, var(--border-opacity));
  }

  .sm\:hover\:tw-border-indigo-600:hover {
    --border-opacity: 1;
    border-color: #5a67d8;
    border-color: rgba(90, 103, 216, var(--border-opacity));
  }

  .sm\:hover\:tw-border-indigo-700:hover {
    --border-opacity: 1;
    border-color: #4c51bf;
    border-color: rgba(76, 81, 191, var(--border-opacity));
  }

  .sm\:hover\:tw-border-indigo-800:hover {
    --border-opacity: 1;
    border-color: #434190;
    border-color: rgba(67, 65, 144, var(--border-opacity));
  }

  .sm\:hover\:tw-border-indigo-900:hover {
    --border-opacity: 1;
    border-color: #3c366b;
    border-color: rgba(60, 54, 107, var(--border-opacity));
  }

  .sm\:hover\:tw-border-purple-100:hover {
    --border-opacity: 1;
    border-color: #faf5ff;
    border-color: rgba(250, 245, 255, var(--border-opacity));
  }

  .sm\:hover\:tw-border-purple-200:hover {
    --border-opacity: 1;
    border-color: #e9d8fd;
    border-color: rgba(233, 216, 253, var(--border-opacity));
  }

  .sm\:hover\:tw-border-purple-300:hover {
    --border-opacity: 1;
    border-color: #d6bcfa;
    border-color: rgba(214, 188, 250, var(--border-opacity));
  }

  .sm\:hover\:tw-border-purple-400:hover {
    --border-opacity: 1;
    border-color: #b794f4;
    border-color: rgba(183, 148, 244, var(--border-opacity));
  }

  .sm\:hover\:tw-border-purple-500:hover {
    --border-opacity: 1;
    border-color: #9f7aea;
    border-color: rgba(159, 122, 234, var(--border-opacity));
  }

  .sm\:hover\:tw-border-purple-600:hover {
    --border-opacity: 1;
    border-color: #805ad5;
    border-color: rgba(128, 90, 213, var(--border-opacity));
  }

  .sm\:hover\:tw-border-purple-700:hover {
    --border-opacity: 1;
    border-color: #6b46c1;
    border-color: rgba(107, 70, 193, var(--border-opacity));
  }

  .sm\:hover\:tw-border-purple-800:hover {
    --border-opacity: 1;
    border-color: #553c9a;
    border-color: rgba(85, 60, 154, var(--border-opacity));
  }

  .sm\:hover\:tw-border-purple-900:hover {
    --border-opacity: 1;
    border-color: #44337a;
    border-color: rgba(68, 51, 122, var(--border-opacity));
  }

  .sm\:hover\:tw-border-pink-100:hover {
    --border-opacity: 1;
    border-color: #fff5f7;
    border-color: rgba(255, 245, 247, var(--border-opacity));
  }

  .sm\:hover\:tw-border-pink-200:hover {
    --border-opacity: 1;
    border-color: #fed7e2;
    border-color: rgba(254, 215, 226, var(--border-opacity));
  }

  .sm\:hover\:tw-border-pink-300:hover {
    --border-opacity: 1;
    border-color: #fbb6ce;
    border-color: rgba(251, 182, 206, var(--border-opacity));
  }

  .sm\:hover\:tw-border-pink-400:hover {
    --border-opacity: 1;
    border-color: #f687b3;
    border-color: rgba(246, 135, 179, var(--border-opacity));
  }

  .sm\:hover\:tw-border-pink-500:hover {
    --border-opacity: 1;
    border-color: #ed64a6;
    border-color: rgba(237, 100, 166, var(--border-opacity));
  }

  .sm\:hover\:tw-border-pink-600:hover {
    --border-opacity: 1;
    border-color: #d53f8c;
    border-color: rgba(213, 63, 140, var(--border-opacity));
  }

  .sm\:hover\:tw-border-pink-700:hover {
    --border-opacity: 1;
    border-color: #b83280;
    border-color: rgba(184, 50, 128, var(--border-opacity));
  }

  .sm\:hover\:tw-border-pink-800:hover {
    --border-opacity: 1;
    border-color: #97266d;
    border-color: rgba(151, 38, 109, var(--border-opacity));
  }

  .sm\:hover\:tw-border-pink-900:hover {
    --border-opacity: 1;
    border-color: #702459;
    border-color: rgba(112, 36, 89, var(--border-opacity));
  }

  .sm\:focus\:tw-border-transparent:focus {
    border-color: transparent;
  }

  .sm\:focus\:tw-border-current:focus {
    border-color: currentColor;
  }

  .sm\:focus\:tw-border-black:focus {
    --border-opacity: 1;
    border-color: #000;
    border-color: rgba(0, 0, 0, var(--border-opacity));
  }

  .sm\:focus\:tw-border-white:focus {
    --border-opacity: 1;
    border-color: #fff;
    border-color: rgba(255, 255, 255, var(--border-opacity));
  }

  .sm\:focus\:tw-border-gray-100:focus {
    --border-opacity: 1;
    border-color: #f7fafc;
    border-color: rgba(247, 250, 252, var(--border-opacity));
  }

  .sm\:focus\:tw-border-gray-200:focus {
    --border-opacity: 1;
    border-color: #edf2f7;
    border-color: rgba(237, 242, 247, var(--border-opacity));
  }

  .sm\:focus\:tw-border-gray-300:focus {
    --border-opacity: 1;
    border-color: #e2e8f0;
    border-color: rgba(226, 232, 240, var(--border-opacity));
  }

  .sm\:focus\:tw-border-gray-400:focus {
    --border-opacity: 1;
    border-color: #cbd5e0;
    border-color: rgba(203, 213, 224, var(--border-opacity));
  }

  .sm\:focus\:tw-border-gray-500:focus {
    --border-opacity: 1;
    border-color: #a0aec0;
    border-color: rgba(160, 174, 192, var(--border-opacity));
  }

  .sm\:focus\:tw-border-gray-600:focus {
    --border-opacity: 1;
    border-color: #718096;
    border-color: rgba(113, 128, 150, var(--border-opacity));
  }

  .sm\:focus\:tw-border-gray-700:focus {
    --border-opacity: 1;
    border-color: #4a5568;
    border-color: rgba(74, 85, 104, var(--border-opacity));
  }

  .sm\:focus\:tw-border-gray-800:focus {
    --border-opacity: 1;
    border-color: #2d3748;
    border-color: rgba(45, 55, 72, var(--border-opacity));
  }

  .sm\:focus\:tw-border-gray-900:focus {
    --border-opacity: 1;
    border-color: #1a202c;
    border-color: rgba(26, 32, 44, var(--border-opacity));
  }

  .sm\:focus\:tw-border-red-100:focus {
    --border-opacity: 1;
    border-color: #fff5f5;
    border-color: rgba(255, 245, 245, var(--border-opacity));
  }

  .sm\:focus\:tw-border-red-200:focus {
    --border-opacity: 1;
    border-color: #fed7d7;
    border-color: rgba(254, 215, 215, var(--border-opacity));
  }

  .sm\:focus\:tw-border-red-300:focus {
    --border-opacity: 1;
    border-color: #feb2b2;
    border-color: rgba(254, 178, 178, var(--border-opacity));
  }

  .sm\:focus\:tw-border-red-400:focus {
    --border-opacity: 1;
    border-color: #fc8181;
    border-color: rgba(252, 129, 129, var(--border-opacity));
  }

  .sm\:focus\:tw-border-red-500:focus {
    --border-opacity: 1;
    border-color: #f56565;
    border-color: rgba(245, 101, 101, var(--border-opacity));
  }

  .sm\:focus\:tw-border-red-600:focus {
    --border-opacity: 1;
    border-color: #e53e3e;
    border-color: rgba(229, 62, 62, var(--border-opacity));
  }

  .sm\:focus\:tw-border-red-700:focus {
    --border-opacity: 1;
    border-color: #c53030;
    border-color: rgba(197, 48, 48, var(--border-opacity));
  }

  .sm\:focus\:tw-border-red-800:focus {
    --border-opacity: 1;
    border-color: #9b2c2c;
    border-color: rgba(155, 44, 44, var(--border-opacity));
  }

  .sm\:focus\:tw-border-red-900:focus {
    --border-opacity: 1;
    border-color: #742a2a;
    border-color: rgba(116, 42, 42, var(--border-opacity));
  }

  .sm\:focus\:tw-border-orange-100:focus {
    --border-opacity: 1;
    border-color: #fffaf0;
    border-color: rgba(255, 250, 240, var(--border-opacity));
  }

  .sm\:focus\:tw-border-orange-200:focus {
    --border-opacity: 1;
    border-color: #feebc8;
    border-color: rgba(254, 235, 200, var(--border-opacity));
  }

  .sm\:focus\:tw-border-orange-300:focus {
    --border-opacity: 1;
    border-color: #fbd38d;
    border-color: rgba(251, 211, 141, var(--border-opacity));
  }

  .sm\:focus\:tw-border-orange-400:focus {
    --border-opacity: 1;
    border-color: #f6ad55;
    border-color: rgba(246, 173, 85, var(--border-opacity));
  }

  .sm\:focus\:tw-border-orange-500:focus {
    --border-opacity: 1;
    border-color: #ed8936;
    border-color: rgba(237, 137, 54, var(--border-opacity));
  }

  .sm\:focus\:tw-border-orange-600:focus {
    --border-opacity: 1;
    border-color: #dd6b20;
    border-color: rgba(221, 107, 32, var(--border-opacity));
  }

  .sm\:focus\:tw-border-orange-700:focus {
    --border-opacity: 1;
    border-color: #c05621;
    border-color: rgba(192, 86, 33, var(--border-opacity));
  }

  .sm\:focus\:tw-border-orange-800:focus {
    --border-opacity: 1;
    border-color: #9c4221;
    border-color: rgba(156, 66, 33, var(--border-opacity));
  }

  .sm\:focus\:tw-border-orange-900:focus {
    --border-opacity: 1;
    border-color: #7b341e;
    border-color: rgba(123, 52, 30, var(--border-opacity));
  }

  .sm\:focus\:tw-border-yellow-100:focus {
    --border-opacity: 1;
    border-color: #fffff0;
    border-color: rgba(255, 255, 240, var(--border-opacity));
  }

  .sm\:focus\:tw-border-yellow-200:focus {
    --border-opacity: 1;
    border-color: #fefcbf;
    border-color: rgba(254, 252, 191, var(--border-opacity));
  }

  .sm\:focus\:tw-border-yellow-300:focus {
    --border-opacity: 1;
    border-color: #faf089;
    border-color: rgba(250, 240, 137, var(--border-opacity));
  }

  .sm\:focus\:tw-border-yellow-400:focus {
    --border-opacity: 1;
    border-color: #f6e05e;
    border-color: rgba(246, 224, 94, var(--border-opacity));
  }

  .sm\:focus\:tw-border-yellow-500:focus {
    --border-opacity: 1;
    border-color: #ecc94b;
    border-color: rgba(236, 201, 75, var(--border-opacity));
  }

  .sm\:focus\:tw-border-yellow-600:focus {
    --border-opacity: 1;
    border-color: #d69e2e;
    border-color: rgba(214, 158, 46, var(--border-opacity));
  }

  .sm\:focus\:tw-border-yellow-700:focus {
    --border-opacity: 1;
    border-color: #b7791f;
    border-color: rgba(183, 121, 31, var(--border-opacity));
  }

  .sm\:focus\:tw-border-yellow-800:focus {
    --border-opacity: 1;
    border-color: #975a16;
    border-color: rgba(151, 90, 22, var(--border-opacity));
  }

  .sm\:focus\:tw-border-yellow-900:focus {
    --border-opacity: 1;
    border-color: #744210;
    border-color: rgba(116, 66, 16, var(--border-opacity));
  }

  .sm\:focus\:tw-border-green-100:focus {
    --border-opacity: 1;
    border-color: #f0fff4;
    border-color: rgba(240, 255, 244, var(--border-opacity));
  }

  .sm\:focus\:tw-border-green-200:focus {
    --border-opacity: 1;
    border-color: #c6f6d5;
    border-color: rgba(198, 246, 213, var(--border-opacity));
  }

  .sm\:focus\:tw-border-green-300:focus {
    --border-opacity: 1;
    border-color: #9ae6b4;
    border-color: rgba(154, 230, 180, var(--border-opacity));
  }

  .sm\:focus\:tw-border-green-400:focus {
    --border-opacity: 1;
    border-color: #68d391;
    border-color: rgba(104, 211, 145, var(--border-opacity));
  }

  .sm\:focus\:tw-border-green-500:focus {
    --border-opacity: 1;
    border-color: #48bb78;
    border-color: rgba(72, 187, 120, var(--border-opacity));
  }

  .sm\:focus\:tw-border-green-600:focus {
    --border-opacity: 1;
    border-color: #38a169;
    border-color: rgba(56, 161, 105, var(--border-opacity));
  }

  .sm\:focus\:tw-border-green-700:focus {
    --border-opacity: 1;
    border-color: #2f855a;
    border-color: rgba(47, 133, 90, var(--border-opacity));
  }

  .sm\:focus\:tw-border-green-800:focus {
    --border-opacity: 1;
    border-color: #276749;
    border-color: rgba(39, 103, 73, var(--border-opacity));
  }

  .sm\:focus\:tw-border-green-900:focus {
    --border-opacity: 1;
    border-color: #22543d;
    border-color: rgba(34, 84, 61, var(--border-opacity));
  }

  .sm\:focus\:tw-border-teal-100:focus {
    --border-opacity: 1;
    border-color: #e6fffa;
    border-color: rgba(230, 255, 250, var(--border-opacity));
  }

  .sm\:focus\:tw-border-teal-200:focus {
    --border-opacity: 1;
    border-color: #b2f5ea;
    border-color: rgba(178, 245, 234, var(--border-opacity));
  }

  .sm\:focus\:tw-border-teal-300:focus {
    --border-opacity: 1;
    border-color: #81e6d9;
    border-color: rgba(129, 230, 217, var(--border-opacity));
  }

  .sm\:focus\:tw-border-teal-400:focus {
    --border-opacity: 1;
    border-color: #4fd1c5;
    border-color: rgba(79, 209, 197, var(--border-opacity));
  }

  .sm\:focus\:tw-border-teal-500:focus {
    --border-opacity: 1;
    border-color: #38b2ac;
    border-color: rgba(56, 178, 172, var(--border-opacity));
  }

  .sm\:focus\:tw-border-teal-600:focus {
    --border-opacity: 1;
    border-color: #319795;
    border-color: rgba(49, 151, 149, var(--border-opacity));
  }

  .sm\:focus\:tw-border-teal-700:focus {
    --border-opacity: 1;
    border-color: #2c7a7b;
    border-color: rgba(44, 122, 123, var(--border-opacity));
  }

  .sm\:focus\:tw-border-teal-800:focus {
    --border-opacity: 1;
    border-color: #285e61;
    border-color: rgba(40, 94, 97, var(--border-opacity));
  }

  .sm\:focus\:tw-border-teal-900:focus {
    --border-opacity: 1;
    border-color: #234e52;
    border-color: rgba(35, 78, 82, var(--border-opacity));
  }

  .sm\:focus\:tw-border-blue-100:focus {
    --border-opacity: 1;
    border-color: #ebf8ff;
    border-color: rgba(235, 248, 255, var(--border-opacity));
  }

  .sm\:focus\:tw-border-blue-200:focus {
    --border-opacity: 1;
    border-color: #bee3f8;
    border-color: rgba(190, 227, 248, var(--border-opacity));
  }

  .sm\:focus\:tw-border-blue-300:focus {
    --border-opacity: 1;
    border-color: #90cdf4;
    border-color: rgba(144, 205, 244, var(--border-opacity));
  }

  .sm\:focus\:tw-border-blue-400:focus {
    --border-opacity: 1;
    border-color: #63b3ed;
    border-color: rgba(99, 179, 237, var(--border-opacity));
  }

  .sm\:focus\:tw-border-blue-500:focus {
    --border-opacity: 1;
    border-color: #4299e1;
    border-color: rgba(66, 153, 225, var(--border-opacity));
  }

  .sm\:focus\:tw-border-blue-600:focus {
    --border-opacity: 1;
    border-color: #3182ce;
    border-color: rgba(49, 130, 206, var(--border-opacity));
  }

  .sm\:focus\:tw-border-blue-700:focus {
    --border-opacity: 1;
    border-color: #2b6cb0;
    border-color: rgba(43, 108, 176, var(--border-opacity));
  }

  .sm\:focus\:tw-border-blue-800:focus {
    --border-opacity: 1;
    border-color: #2c5282;
    border-color: rgba(44, 82, 130, var(--border-opacity));
  }

  .sm\:focus\:tw-border-blue-900:focus {
    --border-opacity: 1;
    border-color: #2a4365;
    border-color: rgba(42, 67, 101, var(--border-opacity));
  }

  .sm\:focus\:tw-border-indigo-100:focus {
    --border-opacity: 1;
    border-color: #ebf4ff;
    border-color: rgba(235, 244, 255, var(--border-opacity));
  }

  .sm\:focus\:tw-border-indigo-200:focus {
    --border-opacity: 1;
    border-color: #c3dafe;
    border-color: rgba(195, 218, 254, var(--border-opacity));
  }

  .sm\:focus\:tw-border-indigo-300:focus {
    --border-opacity: 1;
    border-color: #a3bffa;
    border-color: rgba(163, 191, 250, var(--border-opacity));
  }

  .sm\:focus\:tw-border-indigo-400:focus {
    --border-opacity: 1;
    border-color: #7f9cf5;
    border-color: rgba(127, 156, 245, var(--border-opacity));
  }

  .sm\:focus\:tw-border-indigo-500:focus {
    --border-opacity: 1;
    border-color: #667eea;
    border-color: rgba(102, 126, 234, var(--border-opacity));
  }

  .sm\:focus\:tw-border-indigo-600:focus {
    --border-opacity: 1;
    border-color: #5a67d8;
    border-color: rgba(90, 103, 216, var(--border-opacity));
  }

  .sm\:focus\:tw-border-indigo-700:focus {
    --border-opacity: 1;
    border-color: #4c51bf;
    border-color: rgba(76, 81, 191, var(--border-opacity));
  }

  .sm\:focus\:tw-border-indigo-800:focus {
    --border-opacity: 1;
    border-color: #434190;
    border-color: rgba(67, 65, 144, var(--border-opacity));
  }

  .sm\:focus\:tw-border-indigo-900:focus {
    --border-opacity: 1;
    border-color: #3c366b;
    border-color: rgba(60, 54, 107, var(--border-opacity));
  }

  .sm\:focus\:tw-border-purple-100:focus {
    --border-opacity: 1;
    border-color: #faf5ff;
    border-color: rgba(250, 245, 255, var(--border-opacity));
  }

  .sm\:focus\:tw-border-purple-200:focus {
    --border-opacity: 1;
    border-color: #e9d8fd;
    border-color: rgba(233, 216, 253, var(--border-opacity));
  }

  .sm\:focus\:tw-border-purple-300:focus {
    --border-opacity: 1;
    border-color: #d6bcfa;
    border-color: rgba(214, 188, 250, var(--border-opacity));
  }

  .sm\:focus\:tw-border-purple-400:focus {
    --border-opacity: 1;
    border-color: #b794f4;
    border-color: rgba(183, 148, 244, var(--border-opacity));
  }

  .sm\:focus\:tw-border-purple-500:focus {
    --border-opacity: 1;
    border-color: #9f7aea;
    border-color: rgba(159, 122, 234, var(--border-opacity));
  }

  .sm\:focus\:tw-border-purple-600:focus {
    --border-opacity: 1;
    border-color: #805ad5;
    border-color: rgba(128, 90, 213, var(--border-opacity));
  }

  .sm\:focus\:tw-border-purple-700:focus {
    --border-opacity: 1;
    border-color: #6b46c1;
    border-color: rgba(107, 70, 193, var(--border-opacity));
  }

  .sm\:focus\:tw-border-purple-800:focus {
    --border-opacity: 1;
    border-color: #553c9a;
    border-color: rgba(85, 60, 154, var(--border-opacity));
  }

  .sm\:focus\:tw-border-purple-900:focus {
    --border-opacity: 1;
    border-color: #44337a;
    border-color: rgba(68, 51, 122, var(--border-opacity));
  }

  .sm\:focus\:tw-border-pink-100:focus {
    --border-opacity: 1;
    border-color: #fff5f7;
    border-color: rgba(255, 245, 247, var(--border-opacity));
  }

  .sm\:focus\:tw-border-pink-200:focus {
    --border-opacity: 1;
    border-color: #fed7e2;
    border-color: rgba(254, 215, 226, var(--border-opacity));
  }

  .sm\:focus\:tw-border-pink-300:focus {
    --border-opacity: 1;
    border-color: #fbb6ce;
    border-color: rgba(251, 182, 206, var(--border-opacity));
  }

  .sm\:focus\:tw-border-pink-400:focus {
    --border-opacity: 1;
    border-color: #f687b3;
    border-color: rgba(246, 135, 179, var(--border-opacity));
  }

  .sm\:focus\:tw-border-pink-500:focus {
    --border-opacity: 1;
    border-color: #ed64a6;
    border-color: rgba(237, 100, 166, var(--border-opacity));
  }

  .sm\:focus\:tw-border-pink-600:focus {
    --border-opacity: 1;
    border-color: #d53f8c;
    border-color: rgba(213, 63, 140, var(--border-opacity));
  }

  .sm\:focus\:tw-border-pink-700:focus {
    --border-opacity: 1;
    border-color: #b83280;
    border-color: rgba(184, 50, 128, var(--border-opacity));
  }

  .sm\:focus\:tw-border-pink-800:focus {
    --border-opacity: 1;
    border-color: #97266d;
    border-color: rgba(151, 38, 109, var(--border-opacity));
  }

  .sm\:focus\:tw-border-pink-900:focus {
    --border-opacity: 1;
    border-color: #702459;
    border-color: rgba(112, 36, 89, var(--border-opacity));
  }

  .sm\:tw-border-opacity-0 {
    --border-opacity: 0;
  }

  .sm\:tw-border-opacity-25 {
    --border-opacity: 0.25;
  }

  .sm\:tw-border-opacity-50 {
    --border-opacity: 0.5;
  }

  .sm\:tw-border-opacity-75 {
    --border-opacity: 0.75;
  }

  .sm\:tw-border-opacity-100 {
    --border-opacity: 1;
  }

  .sm\:hover\:tw-border-opacity-0:hover {
    --border-opacity: 0;
  }

  .sm\:hover\:tw-border-opacity-25:hover {
    --border-opacity: 0.25;
  }

  .sm\:hover\:tw-border-opacity-50:hover {
    --border-opacity: 0.5;
  }

  .sm\:hover\:tw-border-opacity-75:hover {
    --border-opacity: 0.75;
  }

  .sm\:hover\:tw-border-opacity-100:hover {
    --border-opacity: 1;
  }

  .sm\:focus\:tw-border-opacity-0:focus {
    --border-opacity: 0;
  }

  .sm\:focus\:tw-border-opacity-25:focus {
    --border-opacity: 0.25;
  }

  .sm\:focus\:tw-border-opacity-50:focus {
    --border-opacity: 0.5;
  }

  .sm\:focus\:tw-border-opacity-75:focus {
    --border-opacity: 0.75;
  }

  .sm\:focus\:tw-border-opacity-100:focus {
    --border-opacity: 1;
  }

  .sm\:tw-rounded-none {
    border-radius: 0;
  }

  .sm\:tw-rounded-sm {
    border-radius: 0.125rem;
  }

  .sm\:tw-rounded {
    border-radius: 0.25rem;
  }

  .sm\:tw-rounded-md {
    border-radius: 0.375rem;
  }

  .sm\:tw-rounded-lg {
    border-radius: 0.5rem;
  }

  .sm\:tw-rounded-xl {
    border-radius: 0.75rem;
  }

  .sm\:tw-rounded-2xl {
    border-radius: 1rem;
  }

  .sm\:tw-rounded-3xl {
    border-radius: 1.5rem;
  }

  .sm\:tw-rounded-full {
    border-radius: 9999px;
  }

  .sm\:tw-rounded-t-none {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }

  .sm\:tw-rounded-r-none {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  .sm\:tw-rounded-b-none {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
  }

  .sm\:tw-rounded-l-none {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }

  .sm\:tw-rounded-t-sm {
    border-top-left-radius: 0.125rem;
    border-top-right-radius: 0.125rem;
  }

  .sm\:tw-rounded-r-sm {
    border-top-right-radius: 0.125rem;
    border-bottom-right-radius: 0.125rem;
  }

  .sm\:tw-rounded-b-sm {
    border-bottom-right-radius: 0.125rem;
    border-bottom-left-radius: 0.125rem;
  }

  .sm\:tw-rounded-l-sm {
    border-top-left-radius: 0.125rem;
    border-bottom-left-radius: 0.125rem;
  }

  .sm\:tw-rounded-t {
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem;
  }

  .sm\:tw-rounded-r {
    border-top-right-radius: 0.25rem;
    border-bottom-right-radius: 0.25rem;
  }

  .sm\:tw-rounded-b {
    border-bottom-right-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem;
  }

  .sm\:tw-rounded-l {
    border-top-left-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem;
  }

  .sm\:tw-rounded-t-md {
    border-top-left-radius: 0.375rem;
    border-top-right-radius: 0.375rem;
  }

  .sm\:tw-rounded-r-md {
    border-top-right-radius: 0.375rem;
    border-bottom-right-radius: 0.375rem;
  }

  .sm\:tw-rounded-b-md {
    border-bottom-right-radius: 0.375rem;
    border-bottom-left-radius: 0.375rem;
  }

  .sm\:tw-rounded-l-md {
    border-top-left-radius: 0.375rem;
    border-bottom-left-radius: 0.375rem;
  }

  .sm\:tw-rounded-t-lg {
    border-top-left-radius: 0.5rem;
    border-top-right-radius: 0.5rem;
  }

  .sm\:tw-rounded-r-lg {
    border-top-right-radius: 0.5rem;
    border-bottom-right-radius: 0.5rem;
  }

  .sm\:tw-rounded-b-lg {
    border-bottom-right-radius: 0.5rem;
    border-bottom-left-radius: 0.5rem;
  }

  .sm\:tw-rounded-l-lg {
    border-top-left-radius: 0.5rem;
    border-bottom-left-radius: 0.5rem;
  }

  .sm\:tw-rounded-t-xl {
    border-top-left-radius: 0.75rem;
    border-top-right-radius: 0.75rem;
  }

  .sm\:tw-rounded-r-xl {
    border-top-right-radius: 0.75rem;
    border-bottom-right-radius: 0.75rem;
  }

  .sm\:tw-rounded-b-xl {
    border-bottom-right-radius: 0.75rem;
    border-bottom-left-radius: 0.75rem;
  }

  .sm\:tw-rounded-l-xl {
    border-top-left-radius: 0.75rem;
    border-bottom-left-radius: 0.75rem;
  }

  .sm\:tw-rounded-t-2xl {
    border-top-left-radius: 1rem;
    border-top-right-radius: 1rem;
  }

  .sm\:tw-rounded-r-2xl {
    border-top-right-radius: 1rem;
    border-bottom-right-radius: 1rem;
  }

  .sm\:tw-rounded-b-2xl {
    border-bottom-right-radius: 1rem;
    border-bottom-left-radius: 1rem;
  }

  .sm\:tw-rounded-l-2xl {
    border-top-left-radius: 1rem;
    border-bottom-left-radius: 1rem;
  }

  .sm\:tw-rounded-t-3xl {
    border-top-left-radius: 1.5rem;
    border-top-right-radius: 1.5rem;
  }

  .sm\:tw-rounded-r-3xl {
    border-top-right-radius: 1.5rem;
    border-bottom-right-radius: 1.5rem;
  }

  .sm\:tw-rounded-b-3xl {
    border-bottom-right-radius: 1.5rem;
    border-bottom-left-radius: 1.5rem;
  }

  .sm\:tw-rounded-l-3xl {
    border-top-left-radius: 1.5rem;
    border-bottom-left-radius: 1.5rem;
  }

  .sm\:tw-rounded-t-full {
    border-top-left-radius: 9999px;
    border-top-right-radius: 9999px;
  }

  .sm\:tw-rounded-r-full {
    border-top-right-radius: 9999px;
    border-bottom-right-radius: 9999px;
  }

  .sm\:tw-rounded-b-full {
    border-bottom-right-radius: 9999px;
    border-bottom-left-radius: 9999px;
  }

  .sm\:tw-rounded-l-full {
    border-top-left-radius: 9999px;
    border-bottom-left-radius: 9999px;
  }

  .sm\:tw-rounded-tl-none {
    border-top-left-radius: 0;
  }

  .sm\:tw-rounded-tr-none {
    border-top-right-radius: 0;
  }

  .sm\:tw-rounded-br-none {
    border-bottom-right-radius: 0;
  }

  .sm\:tw-rounded-bl-none {
    border-bottom-left-radius: 0;
  }

  .sm\:tw-rounded-tl-sm {
    border-top-left-radius: 0.125rem;
  }

  .sm\:tw-rounded-tr-sm {
    border-top-right-radius: 0.125rem;
  }

  .sm\:tw-rounded-br-sm {
    border-bottom-right-radius: 0.125rem;
  }

  .sm\:tw-rounded-bl-sm {
    border-bottom-left-radius: 0.125rem;
  }

  .sm\:tw-rounded-tl {
    border-top-left-radius: 0.25rem;
  }

  .sm\:tw-rounded-tr {
    border-top-right-radius: 0.25rem;
  }

  .sm\:tw-rounded-br {
    border-bottom-right-radius: 0.25rem;
  }

  .sm\:tw-rounded-bl {
    border-bottom-left-radius: 0.25rem;
  }

  .sm\:tw-rounded-tl-md {
    border-top-left-radius: 0.375rem;
  }

  .sm\:tw-rounded-tr-md {
    border-top-right-radius: 0.375rem;
  }

  .sm\:tw-rounded-br-md {
    border-bottom-right-radius: 0.375rem;
  }

  .sm\:tw-rounded-bl-md {
    border-bottom-left-radius: 0.375rem;
  }

  .sm\:tw-rounded-tl-lg {
    border-top-left-radius: 0.5rem;
  }

  .sm\:tw-rounded-tr-lg {
    border-top-right-radius: 0.5rem;
  }

  .sm\:tw-rounded-br-lg {
    border-bottom-right-radius: 0.5rem;
  }

  .sm\:tw-rounded-bl-lg {
    border-bottom-left-radius: 0.5rem;
  }

  .sm\:tw-rounded-tl-xl {
    border-top-left-radius: 0.75rem;
  }

  .sm\:tw-rounded-tr-xl {
    border-top-right-radius: 0.75rem;
  }

  .sm\:tw-rounded-br-xl {
    border-bottom-right-radius: 0.75rem;
  }

  .sm\:tw-rounded-bl-xl {
    border-bottom-left-radius: 0.75rem;
  }

  .sm\:tw-rounded-tl-2xl {
    border-top-left-radius: 1rem;
  }

  .sm\:tw-rounded-tr-2xl {
    border-top-right-radius: 1rem;
  }

  .sm\:tw-rounded-br-2xl {
    border-bottom-right-radius: 1rem;
  }

  .sm\:tw-rounded-bl-2xl {
    border-bottom-left-radius: 1rem;
  }

  .sm\:tw-rounded-tl-3xl {
    border-top-left-radius: 1.5rem;
  }

  .sm\:tw-rounded-tr-3xl {
    border-top-right-radius: 1.5rem;
  }

  .sm\:tw-rounded-br-3xl {
    border-bottom-right-radius: 1.5rem;
  }

  .sm\:tw-rounded-bl-3xl {
    border-bottom-left-radius: 1.5rem;
  }

  .sm\:tw-rounded-tl-full {
    border-top-left-radius: 9999px;
  }

  .sm\:tw-rounded-tr-full {
    border-top-right-radius: 9999px;
  }

  .sm\:tw-rounded-br-full {
    border-bottom-right-radius: 9999px;
  }

  .sm\:tw-rounded-bl-full {
    border-bottom-left-radius: 9999px;
  }

  .sm\:tw-border-solid {
    border-style: solid;
  }

  .sm\:tw-border-dashed {
    border-style: dashed;
  }

  .sm\:tw-border-dotted {
    border-style: dotted;
  }

  .sm\:tw-border-double {
    border-style: double;
  }

  .sm\:tw-border-none {
    border-style: none;
  }

  .sm\:tw-border-0 {
    border-width: 0;
  }

  .sm\:tw-border-2 {
    border-width: 2px;
  }

  .sm\:tw-border-4 {
    border-width: 4px;
  }

  .sm\:tw-border-8 {
    border-width: 8px;
  }

  .sm\:tw-border {
    border-width: 1px;
  }

  .sm\:tw-border-t-0 {
    border-top-width: 0;
  }

  .sm\:tw-border-r-0 {
    border-right-width: 0;
  }

  .sm\:tw-border-b-0 {
    border-bottom-width: 0;
  }

  .sm\:tw-border-l-0 {
    border-left-width: 0;
  }

  .sm\:tw-border-t-2 {
    border-top-width: 2px;
  }

  .sm\:tw-border-r-2 {
    border-right-width: 2px;
  }

  .sm\:tw-border-b-2 {
    border-bottom-width: 2px;
  }

  .sm\:tw-border-l-2 {
    border-left-width: 2px;
  }

  .sm\:tw-border-t-4 {
    border-top-width: 4px;
  }

  .sm\:tw-border-r-4 {
    border-right-width: 4px;
  }

  .sm\:tw-border-b-4 {
    border-bottom-width: 4px;
  }

  .sm\:tw-border-l-4 {
    border-left-width: 4px;
  }

  .sm\:tw-border-t-8 {
    border-top-width: 8px;
  }

  .sm\:tw-border-r-8 {
    border-right-width: 8px;
  }

  .sm\:tw-border-b-8 {
    border-bottom-width: 8px;
  }

  .sm\:tw-border-l-8 {
    border-left-width: 8px;
  }

  .sm\:tw-border-t {
    border-top-width: 1px;
  }

  .sm\:tw-border-r {
    border-right-width: 1px;
  }

  .sm\:tw-border-b {
    border-bottom-width: 1px;
  }

  .sm\:tw-border-l {
    border-left-width: 1px;
  }

  .sm\:tw-box-border {
    box-sizing: border-box;
  }

  .sm\:tw-box-content {
    box-sizing: content-box;
  }

  .sm\:tw-cursor-auto {
    cursor: auto;
  }

  .sm\:tw-cursor-default {
    cursor: default;
  }

  .sm\:tw-cursor-pointer {
    cursor: pointer;
  }

  .sm\:tw-cursor-wait {
    cursor: wait;
  }

  .sm\:tw-cursor-text {
    cursor: text;
  }

  .sm\:tw-cursor-move {
    cursor: move;
  }

  .sm\:tw-cursor-not-allowed {
    cursor: not-allowed;
  }

  .sm\:tw-block {
    display: block;
  }

  .sm\:tw-inline-block {
    display: inline-block;
  }

  .sm\:tw-inline {
    display: inline;
  }

  .sm\:tw-flex {
    display: flex;
  }

  .sm\:tw-inline-flex {
    display: inline-flex;
  }

  .sm\:tw-table {
    display: table;
  }

  .sm\:tw-table-caption {
    display: table-caption;
  }

  .sm\:tw-table-cell {
    display: table-cell;
  }

  .sm\:tw-table-column {
    display: table-column;
  }

  .sm\:tw-table-column-group {
    display: table-column-group;
  }

  .sm\:tw-table-footer-group {
    display: table-footer-group;
  }

  .sm\:tw-table-header-group {
    display: table-header-group;
  }

  .sm\:tw-table-row-group {
    display: table-row-group;
  }

  .sm\:tw-table-row {
    display: table-row;
  }

  .sm\:tw-flow-root {
    display: flow-root;
  }

  .sm\:tw-grid {
    display: grid;
  }

  .sm\:tw-inline-grid {
    display: inline-grid;
  }

  .sm\:tw-contents {
    display: contents;
  }

  .sm\:tw-hidden {
    display: none;
  }

  .sm\:tw-flex-row {
    flex-direction: row;
  }

  .sm\:tw-flex-row-reverse {
    flex-direction: row-reverse;
  }

  .sm\:tw-flex-col {
    flex-direction: column;
  }

  .sm\:tw-flex-col-reverse {
    flex-direction: column-reverse;
  }

  .sm\:tw-flex-wrap {
    flex-wrap: wrap;
  }

  .sm\:tw-flex-wrap-reverse {
    flex-wrap: wrap-reverse;
  }

  .sm\:tw-flex-no-wrap {
    flex-wrap: nowrap;
  }

  .sm\:tw-place-items-auto {
    place-items: auto;
  }

  .sm\:tw-place-items-start {
    place-items: start;
  }

  .sm\:tw-place-items-end {
    place-items: end;
  }

  .sm\:tw-place-items-center {
    place-items: center;
  }

  .sm\:tw-place-items-stretch {
    place-items: stretch;
  }

  .sm\:tw-place-content-center {
    place-content: center;
  }

  .sm\:tw-place-content-start {
    place-content: start;
  }

  .sm\:tw-place-content-end {
    place-content: end;
  }

  .sm\:tw-place-content-between {
    place-content: space-between;
  }

  .sm\:tw-place-content-around {
    place-content: space-around;
  }

  .sm\:tw-place-content-evenly {
    place-content: space-evenly;
  }

  .sm\:tw-place-content-stretch {
    place-content: stretch;
  }

  .sm\:tw-place-self-auto {
    place-self: auto;
  }

  .sm\:tw-place-self-start {
    place-self: start;
  }

  .sm\:tw-place-self-end {
    place-self: end;
  }

  .sm\:tw-place-self-center {
    place-self: center;
  }

  .sm\:tw-place-self-stretch {
    place-self: stretch;
  }

  .sm\:tw-items-start {
    align-items: flex-start;
  }

  .sm\:tw-items-end {
    align-items: flex-end;
  }

  .sm\:tw-items-center {
    align-items: center;
  }

  .sm\:tw-items-baseline {
    align-items: baseline;
  }

  .sm\:tw-items-stretch {
    align-items: stretch;
  }

  .sm\:tw-content-center {
    align-content: center;
  }

  .sm\:tw-content-start {
    align-content: flex-start;
  }

  .sm\:tw-content-end {
    align-content: flex-end;
  }

  .sm\:tw-content-between {
    align-content: space-between;
  }

  .sm\:tw-content-around {
    align-content: space-around;
  }

  .sm\:tw-content-evenly {
    align-content: space-evenly;
  }

  .sm\:tw-self-auto {
    align-self: auto;
  }

  .sm\:tw-self-start {
    align-self: flex-start;
  }

  .sm\:tw-self-end {
    align-self: flex-end;
  }

  .sm\:tw-self-center {
    align-self: center;
  }

  .sm\:tw-self-stretch {
    align-self: stretch;
  }

  .sm\:tw-justify-items-auto {
    justify-items: auto;
  }

  .sm\:tw-justify-items-start {
    justify-items: start;
  }

  .sm\:tw-justify-items-end {
    justify-items: end;
  }

  .sm\:tw-justify-items-center {
    justify-items: center;
  }

  .sm\:tw-justify-items-stretch {
    justify-items: stretch;
  }

  .sm\:tw-justify-start {
    justify-content: flex-start;
  }

  .sm\:tw-justify-end {
    justify-content: flex-end;
  }

  .sm\:tw-justify-center {
    justify-content: center;
  }

  .sm\:tw-justify-between {
    justify-content: space-between;
  }

  .sm\:tw-justify-around {
    justify-content: space-around;
  }

  .sm\:tw-justify-evenly {
    justify-content: space-evenly;
  }

  .sm\:tw-justify-self-auto {
    justify-self: auto;
  }

  .sm\:tw-justify-self-start {
    justify-self: start;
  }

  .sm\:tw-justify-self-end {
    justify-self: end;
  }

  .sm\:tw-justify-self-center {
    justify-self: center;
  }

  .sm\:tw-justify-self-stretch {
    justify-self: stretch;
  }

  .sm\:tw-flex-1 {
    flex: 1 1 0%;
  }

  .sm\:tw-flex-auto {
    flex: 1 1 auto;
  }

  .sm\:tw-flex-initial {
    flex: 0 1 auto;
  }

  .sm\:tw-flex-none {
    flex: none;
  }

  .sm\:tw-flex-grow-0 {
    flex-grow: 0;
  }

  .sm\:tw-flex-grow {
    flex-grow: 1;
  }

  .sm\:tw-flex-shrink-0 {
    flex-shrink: 0;
  }

  .sm\:tw-flex-shrink {
    flex-shrink: 1;
  }

  .sm\:tw-order-1 {
    order: 1;
  }

  .sm\:tw-order-2 {
    order: 2;
  }

  .sm\:tw-order-3 {
    order: 3;
  }

  .sm\:tw-order-4 {
    order: 4;
  }

  .sm\:tw-order-5 {
    order: 5;
  }

  .sm\:tw-order-6 {
    order: 6;
  }

  .sm\:tw-order-7 {
    order: 7;
  }

  .sm\:tw-order-8 {
    order: 8;
  }

  .sm\:tw-order-9 {
    order: 9;
  }

  .sm\:tw-order-10 {
    order: 10;
  }

  .sm\:tw-order-11 {
    order: 11;
  }

  .sm\:tw-order-12 {
    order: 12;
  }

  .sm\:tw-order-first {
    order: -9999;
  }

  .sm\:tw-order-last {
    order: 9999;
  }

  .sm\:tw-order-none {
    order: 0;
  }

  .sm\:tw-float-right {
    float: right;
  }

  .sm\:tw-float-left {
    float: left;
  }

  .sm\:tw-float-none {
    float: none;
  }

  .sm\:tw-clearfix:after {
    content: "";
    display: table;
    clear: both;
  }

  .sm\:tw-clear-left {
    clear: left;
  }

  .sm\:tw-clear-right {
    clear: right;
  }

  .sm\:tw-clear-both {
    clear: both;
  }

  .sm\:tw-clear-none {
    clear: none;
  }

  .sm\:tw-font-sans {
    font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  }

  .sm\:tw-font-serif {
    font-family: Georgia, Cambria, "Times New Roman", Times, serif;
  }

  .sm\:tw-font-mono {
    font-family: Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  }

  .sm\:tw-font-hairline {
    font-weight: 100;
  }

  .sm\:tw-font-thin {
    font-weight: 200;
  }

  .sm\:tw-font-light {
    font-weight: 300;
  }

  .sm\:tw-font-normal {
    font-weight: 400;
  }

  .sm\:tw-font-medium {
    font-weight: 500;
  }

  .sm\:tw-font-semibold {
    font-weight: 600;
  }

  .sm\:tw-font-bold {
    font-weight: 700;
  }

  .sm\:tw-font-extrabold {
    font-weight: 800;
  }

  .sm\:tw-font-black {
    font-weight: 900;
  }

  .sm\:hover\:tw-font-hairline:hover {
    font-weight: 100;
  }

  .sm\:hover\:tw-font-thin:hover {
    font-weight: 200;
  }

  .sm\:hover\:tw-font-light:hover {
    font-weight: 300;
  }

  .sm\:hover\:tw-font-normal:hover {
    font-weight: 400;
  }

  .sm\:hover\:tw-font-medium:hover {
    font-weight: 500;
  }

  .sm\:hover\:tw-font-semibold:hover {
    font-weight: 600;
  }

  .sm\:hover\:tw-font-bold:hover {
    font-weight: 700;
  }

  .sm\:hover\:tw-font-extrabold:hover {
    font-weight: 800;
  }

  .sm\:hover\:tw-font-black:hover {
    font-weight: 900;
  }

  .sm\:focus\:tw-font-hairline:focus {
    font-weight: 100;
  }

  .sm\:focus\:tw-font-thin:focus {
    font-weight: 200;
  }

  .sm\:focus\:tw-font-light:focus {
    font-weight: 300;
  }

  .sm\:focus\:tw-font-normal:focus {
    font-weight: 400;
  }

  .sm\:focus\:tw-font-medium:focus {
    font-weight: 500;
  }

  .sm\:focus\:tw-font-semibold:focus {
    font-weight: 600;
  }

  .sm\:focus\:tw-font-bold:focus {
    font-weight: 700;
  }

  .sm\:focus\:tw-font-extrabold:focus {
    font-weight: 800;
  }

  .sm\:focus\:tw-font-black:focus {
    font-weight: 900;
  }

  .sm\:tw-h-0 {
    height: 0;
  }

  .sm\:tw-h-1 {
    height: 0.25rem;
  }

  .sm\:tw-h-2 {
    height: 0.5rem;
  }

  .sm\:tw-h-3 {
    height: 0.75rem;
  }

  .sm\:tw-h-4 {
    height: 1rem;
  }

  .sm\:tw-h-5 {
    height: 1.25rem;
  }

  .sm\:tw-h-6 {
    height: 1.5rem;
  }

  .sm\:tw-h-8 {
    height: 2rem;
  }

  .sm\:tw-h-10 {
    height: 2.5rem;
  }

  .sm\:tw-h-12 {
    height: 3rem;
  }

  .sm\:tw-h-16 {
    height: 4rem;
  }

  .sm\:tw-h-20 {
    height: 5rem;
  }

  .sm\:tw-h-24 {
    height: 6rem;
  }

  .sm\:tw-h-32 {
    height: 8rem;
  }

  .sm\:tw-h-40 {
    height: 10rem;
  }

  .sm\:tw-h-48 {
    height: 12rem;
  }

  .sm\:tw-h-56 {
    height: 14rem;
  }

  .sm\:tw-h-64 {
    height: 16rem;
  }

  .sm\:tw-h-auto {
    height: auto;
  }

  .sm\:tw-h-px {
    height: 1px;
  }

  .sm\:tw-h-270px {
    height: 270px;
  }

  .sm\:tw-h-400px {
    height: 400px;
  }

  .sm\:tw-h-464px {
    height: 464px;
  }

  .sm\:tw-h-480px {
    height: 480px;
  }

  .sm\:tw-h-500px {
    height: 500px;
  }

  .sm\:tw-h-576px {
    height: 576px;
  }

  .sm\:tw-h-600px {
    height: 600px;
  }

  .sm\:tw-h-672px {
    height: 672px;
  }

  .sm\:tw-h-full {
    height: 100%;
  }

  .sm\:tw-h-screen {
    height: 100vh;
  }

  .sm\:tw-text-xs {
    font-size: 0.75rem;
  }

  .sm\:tw-text-sm {
    font-size: 0.875rem;
  }

  .sm\:tw-text-base {
    font-size: 1rem;
  }

  .sm\:tw-text-lg {
    font-size: 1.125rem;
  }

  .sm\:tw-text-xl {
    font-size: 1.25rem;
  }

  .sm\:tw-text-2xl {
    font-size: 1.5rem;
  }

  .sm\:tw-text-3xl {
    font-size: 1.875rem;
  }

  .sm\:tw-text-4xl {
    font-size: 2.25rem;
  }

  .sm\:tw-text-5xl {
    font-size: 3rem;
  }

  .sm\:tw-text-6xl {
    font-size: 4rem;
  }

  .sm\:tw-leading-3 {
    line-height: .75rem;
  }

  .sm\:tw-leading-4 {
    line-height: 1rem;
  }

  .sm\:tw-leading-5 {
    line-height: 1.25rem;
  }

  .sm\:tw-leading-6 {
    line-height: 1.5rem;
  }

  .sm\:tw-leading-7 {
    line-height: 1.75rem;
  }

  .sm\:tw-leading-8 {
    line-height: 2rem;
  }

  .sm\:tw-leading-9 {
    line-height: 2.25rem;
  }

  .sm\:tw-leading-10 {
    line-height: 2.5rem;
  }

  .sm\:tw-leading-none {
    line-height: 1;
  }

  .sm\:tw-leading-tight {
    line-height: 1.25;
  }

  .sm\:tw-leading-snug {
    line-height: 1.375;
  }

  .sm\:tw-leading-normal {
    line-height: 1.5;
  }

  .sm\:tw-leading-relaxed {
    line-height: 1.625;
  }

  .sm\:tw-leading-loose {
    line-height: 2;
  }

  .sm\:tw-list-inside {
    list-style-position: inside;
  }

  .sm\:tw-list-outside {
    list-style-position: outside;
  }

  .sm\:tw-list-none {
    list-style-type: none;
  }

  .sm\:tw-list-disc {
    list-style-type: disc;
  }

  .sm\:tw-list-decimal {
    list-style-type: decimal;
  }

  .sm\:tw-m-0 {
    margin: 0;
  }

  .sm\:tw-m-1 {
    margin: 0.25rem;
  }

  .sm\:tw-m-2 {
    margin: 0.5rem;
  }

  .sm\:tw-m-3 {
    margin: 0.75rem;
  }

  .sm\:tw-m-4 {
    margin: 1rem;
  }

  .sm\:tw-m-5 {
    margin: 1.25rem;
  }

  .sm\:tw-m-6 {
    margin: 1.5rem;
  }

  .sm\:tw-m-8 {
    margin: 2rem;
  }

  .sm\:tw-m-10 {
    margin: 2.5rem;
  }

  .sm\:tw-m-12 {
    margin: 3rem;
  }

  .sm\:tw-m-16 {
    margin: 4rem;
  }

  .sm\:tw-m-20 {
    margin: 5rem;
  }

  .sm\:tw-m-24 {
    margin: 6rem;
  }

  .sm\:tw-m-32 {
    margin: 8rem;
  }

  .sm\:tw-m-40 {
    margin: 10rem;
  }

  .sm\:tw-m-48 {
    margin: 12rem;
  }

  .sm\:tw-m-56 {
    margin: 14rem;
  }

  .sm\:tw-m-64 {
    margin: 16rem;
  }

  .sm\:tw-m-auto {
    margin: auto;
  }

  .sm\:tw-m-px {
    margin: 1px;
  }

  .sm\:tw-m-270px {
    margin: 270px;
  }

  .sm\:tw-m-400px {
    margin: 400px;
  }

  .sm\:tw-m-464px {
    margin: 464px;
  }

  .sm\:tw-m-480px {
    margin: 480px;
  }

  .sm\:tw-m-500px {
    margin: 500px;
  }

  .sm\:tw-m-576px {
    margin: 576px;
  }

  .sm\:tw-m-600px {
    margin: 600px;
  }

  .sm\:tw-m-672px {
    margin: 672px;
  }

  .sm\:tw--m-1 {
    margin: -0.25rem;
  }

  .sm\:tw--m-2 {
    margin: -0.5rem;
  }

  .sm\:tw--m-3 {
    margin: -0.75rem;
  }

  .sm\:tw--m-4 {
    margin: -1rem;
  }

  .sm\:tw--m-5 {
    margin: -1.25rem;
  }

  .sm\:tw--m-6 {
    margin: -1.5rem;
  }

  .sm\:tw--m-8 {
    margin: -2rem;
  }

  .sm\:tw--m-10 {
    margin: -2.5rem;
  }

  .sm\:tw--m-12 {
    margin: -3rem;
  }

  .sm\:tw--m-16 {
    margin: -4rem;
  }

  .sm\:tw--m-20 {
    margin: -5rem;
  }

  .sm\:tw--m-24 {
    margin: -6rem;
  }

  .sm\:tw--m-32 {
    margin: -8rem;
  }

  .sm\:tw--m-40 {
    margin: -10rem;
  }

  .sm\:tw--m-48 {
    margin: -12rem;
  }

  .sm\:tw--m-56 {
    margin: -14rem;
  }

  .sm\:tw--m-64 {
    margin: -16rem;
  }

  .sm\:tw--m-px {
    margin: -1px;
  }

  .sm\:tw--m-270px {
    margin: -270px;
  }

  .sm\:tw--m-400px {
    margin: -400px;
  }

  .sm\:tw--m-464px {
    margin: -464px;
  }

  .sm\:tw--m-480px {
    margin: -480px;
  }

  .sm\:tw--m-500px {
    margin: -500px;
  }

  .sm\:tw--m-576px {
    margin: -576px;
  }

  .sm\:tw--m-600px {
    margin: -600px;
  }

  .sm\:tw--m-672px {
    margin: -672px;
  }

  .sm\:tw-my-0 {
    margin-top: 0;
    margin-bottom: 0;
  }

  .sm\:tw-mx-0 {
    margin-left: 0;
    margin-right: 0;
  }

  .sm\:tw-my-1 {
    margin-top: 0.25rem;
    margin-bottom: 0.25rem;
  }

  .sm\:tw-mx-1 {
    margin-left: 0.25rem;
    margin-right: 0.25rem;
  }

  .sm\:tw-my-2 {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
  }

  .sm\:tw-mx-2 {
    margin-left: 0.5rem;
    margin-right: 0.5rem;
  }

  .sm\:tw-my-3 {
    margin-top: 0.75rem;
    margin-bottom: 0.75rem;
  }

  .sm\:tw-mx-3 {
    margin-left: 0.75rem;
    margin-right: 0.75rem;
  }

  .sm\:tw-my-4 {
    margin-top: 1rem;
    margin-bottom: 1rem;
  }

  .sm\:tw-mx-4 {
    margin-left: 1rem;
    margin-right: 1rem;
  }

  .sm\:tw-my-5 {
    margin-top: 1.25rem;
    margin-bottom: 1.25rem;
  }

  .sm\:tw-mx-5 {
    margin-left: 1.25rem;
    margin-right: 1.25rem;
  }

  .sm\:tw-my-6 {
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
  }

  .sm\:tw-mx-6 {
    margin-left: 1.5rem;
    margin-right: 1.5rem;
  }

  .sm\:tw-my-8 {
    margin-top: 2rem;
    margin-bottom: 2rem;
  }

  .sm\:tw-mx-8 {
    margin-left: 2rem;
    margin-right: 2rem;
  }

  .sm\:tw-my-10 {
    margin-top: 2.5rem;
    margin-bottom: 2.5rem;
  }

  .sm\:tw-mx-10 {
    margin-left: 2.5rem;
    margin-right: 2.5rem;
  }

  .sm\:tw-my-12 {
    margin-top: 3rem;
    margin-bottom: 3rem;
  }

  .sm\:tw-mx-12 {
    margin-left: 3rem;
    margin-right: 3rem;
  }

  .sm\:tw-my-16 {
    margin-top: 4rem;
    margin-bottom: 4rem;
  }

  .sm\:tw-mx-16 {
    margin-left: 4rem;
    margin-right: 4rem;
  }

  .sm\:tw-my-20 {
    margin-top: 5rem;
    margin-bottom: 5rem;
  }

  .sm\:tw-mx-20 {
    margin-left: 5rem;
    margin-right: 5rem;
  }

  .sm\:tw-my-24 {
    margin-top: 6rem;
    margin-bottom: 6rem;
  }

  .sm\:tw-mx-24 {
    margin-left: 6rem;
    margin-right: 6rem;
  }

  .sm\:tw-my-32 {
    margin-top: 8rem;
    margin-bottom: 8rem;
  }

  .sm\:tw-mx-32 {
    margin-left: 8rem;
    margin-right: 8rem;
  }

  .sm\:tw-my-40 {
    margin-top: 10rem;
    margin-bottom: 10rem;
  }

  .sm\:tw-mx-40 {
    margin-left: 10rem;
    margin-right: 10rem;
  }

  .sm\:tw-my-48 {
    margin-top: 12rem;
    margin-bottom: 12rem;
  }

  .sm\:tw-mx-48 {
    margin-left: 12rem;
    margin-right: 12rem;
  }

  .sm\:tw-my-56 {
    margin-top: 14rem;
    margin-bottom: 14rem;
  }

  .sm\:tw-mx-56 {
    margin-left: 14rem;
    margin-right: 14rem;
  }

  .sm\:tw-my-64 {
    margin-top: 16rem;
    margin-bottom: 16rem;
  }

  .sm\:tw-mx-64 {
    margin-left: 16rem;
    margin-right: 16rem;
  }

  .sm\:tw-my-auto {
    margin-top: auto;
    margin-bottom: auto;
  }

  .sm\:tw-mx-auto {
    margin-left: auto;
    margin-right: auto;
  }

  .sm\:tw-my-px {
    margin-top: 1px;
    margin-bottom: 1px;
  }

  .sm\:tw-mx-px {
    margin-left: 1px;
    margin-right: 1px;
  }

  .sm\:tw-my-270px {
    margin-top: 270px;
    margin-bottom: 270px;
  }

  .sm\:tw-mx-270px {
    margin-left: 270px;
    margin-right: 270px;
  }

  .sm\:tw-my-400px {
    margin-top: 400px;
    margin-bottom: 400px;
  }

  .sm\:tw-mx-400px {
    margin-left: 400px;
    margin-right: 400px;
  }

  .sm\:tw-my-464px {
    margin-top: 464px;
    margin-bottom: 464px;
  }

  .sm\:tw-mx-464px {
    margin-left: 464px;
    margin-right: 464px;
  }

  .sm\:tw-my-480px {
    margin-top: 480px;
    margin-bottom: 480px;
  }

  .sm\:tw-mx-480px {
    margin-left: 480px;
    margin-right: 480px;
  }

  .sm\:tw-my-500px {
    margin-top: 500px;
    margin-bottom: 500px;
  }

  .sm\:tw-mx-500px {
    margin-left: 500px;
    margin-right: 500px;
  }

  .sm\:tw-my-576px {
    margin-top: 576px;
    margin-bottom: 576px;
  }

  .sm\:tw-mx-576px {
    margin-left: 576px;
    margin-right: 576px;
  }

  .sm\:tw-my-600px {
    margin-top: 600px;
    margin-bottom: 600px;
  }

  .sm\:tw-mx-600px {
    margin-left: 600px;
    margin-right: 600px;
  }

  .sm\:tw-my-672px {
    margin-top: 672px;
    margin-bottom: 672px;
  }

  .sm\:tw-mx-672px {
    margin-left: 672px;
    margin-right: 672px;
  }

  .sm\:tw--my-1 {
    margin-top: -0.25rem;
    margin-bottom: -0.25rem;
  }

  .sm\:tw--mx-1 {
    margin-left: -0.25rem;
    margin-right: -0.25rem;
  }

  .sm\:tw--my-2 {
    margin-top: -0.5rem;
    margin-bottom: -0.5rem;
  }

  .sm\:tw--mx-2 {
    margin-left: -0.5rem;
    margin-right: -0.5rem;
  }

  .sm\:tw--my-3 {
    margin-top: -0.75rem;
    margin-bottom: -0.75rem;
  }

  .sm\:tw--mx-3 {
    margin-left: -0.75rem;
    margin-right: -0.75rem;
  }

  .sm\:tw--my-4 {
    margin-top: -1rem;
    margin-bottom: -1rem;
  }

  .sm\:tw--mx-4 {
    margin-left: -1rem;
    margin-right: -1rem;
  }

  .sm\:tw--my-5 {
    margin-top: -1.25rem;
    margin-bottom: -1.25rem;
  }

  .sm\:tw--mx-5 {
    margin-left: -1.25rem;
    margin-right: -1.25rem;
  }

  .sm\:tw--my-6 {
    margin-top: -1.5rem;
    margin-bottom: -1.5rem;
  }

  .sm\:tw--mx-6 {
    margin-left: -1.5rem;
    margin-right: -1.5rem;
  }

  .sm\:tw--my-8 {
    margin-top: -2rem;
    margin-bottom: -2rem;
  }

  .sm\:tw--mx-8 {
    margin-left: -2rem;
    margin-right: -2rem;
  }

  .sm\:tw--my-10 {
    margin-top: -2.5rem;
    margin-bottom: -2.5rem;
  }

  .sm\:tw--mx-10 {
    margin-left: -2.5rem;
    margin-right: -2.5rem;
  }

  .sm\:tw--my-12 {
    margin-top: -3rem;
    margin-bottom: -3rem;
  }

  .sm\:tw--mx-12 {
    margin-left: -3rem;
    margin-right: -3rem;
  }

  .sm\:tw--my-16 {
    margin-top: -4rem;
    margin-bottom: -4rem;
  }

  .sm\:tw--mx-16 {
    margin-left: -4rem;
    margin-right: -4rem;
  }

  .sm\:tw--my-20 {
    margin-top: -5rem;
    margin-bottom: -5rem;
  }

  .sm\:tw--mx-20 {
    margin-left: -5rem;
    margin-right: -5rem;
  }

  .sm\:tw--my-24 {
    margin-top: -6rem;
    margin-bottom: -6rem;
  }

  .sm\:tw--mx-24 {
    margin-left: -6rem;
    margin-right: -6rem;
  }

  .sm\:tw--my-32 {
    margin-top: -8rem;
    margin-bottom: -8rem;
  }

  .sm\:tw--mx-32 {
    margin-left: -8rem;
    margin-right: -8rem;
  }

  .sm\:tw--my-40 {
    margin-top: -10rem;
    margin-bottom: -10rem;
  }

  .sm\:tw--mx-40 {
    margin-left: -10rem;
    margin-right: -10rem;
  }

  .sm\:tw--my-48 {
    margin-top: -12rem;
    margin-bottom: -12rem;
  }

  .sm\:tw--mx-48 {
    margin-left: -12rem;
    margin-right: -12rem;
  }

  .sm\:tw--my-56 {
    margin-top: -14rem;
    margin-bottom: -14rem;
  }

  .sm\:tw--mx-56 {
    margin-left: -14rem;
    margin-right: -14rem;
  }

  .sm\:tw--my-64 {
    margin-top: -16rem;
    margin-bottom: -16rem;
  }

  .sm\:tw--mx-64 {
    margin-left: -16rem;
    margin-right: -16rem;
  }

  .sm\:tw--my-px {
    margin-top: -1px;
    margin-bottom: -1px;
  }

  .sm\:tw--mx-px {
    margin-left: -1px;
    margin-right: -1px;
  }

  .sm\:tw--my-270px {
    margin-top: -270px;
    margin-bottom: -270px;
  }

  .sm\:tw--mx-270px {
    margin-left: -270px;
    margin-right: -270px;
  }

  .sm\:tw--my-400px {
    margin-top: -400px;
    margin-bottom: -400px;
  }

  .sm\:tw--mx-400px {
    margin-left: -400px;
    margin-right: -400px;
  }

  .sm\:tw--my-464px {
    margin-top: -464px;
    margin-bottom: -464px;
  }

  .sm\:tw--mx-464px {
    margin-left: -464px;
    margin-right: -464px;
  }

  .sm\:tw--my-480px {
    margin-top: -480px;
    margin-bottom: -480px;
  }

  .sm\:tw--mx-480px {
    margin-left: -480px;
    margin-right: -480px;
  }

  .sm\:tw--my-500px {
    margin-top: -500px;
    margin-bottom: -500px;
  }

  .sm\:tw--mx-500px {
    margin-left: -500px;
    margin-right: -500px;
  }

  .sm\:tw--my-576px {
    margin-top: -576px;
    margin-bottom: -576px;
  }

  .sm\:tw--mx-576px {
    margin-left: -576px;
    margin-right: -576px;
  }

  .sm\:tw--my-600px {
    margin-top: -600px;
    margin-bottom: -600px;
  }

  .sm\:tw--mx-600px {
    margin-left: -600px;
    margin-right: -600px;
  }

  .sm\:tw--my-672px {
    margin-top: -672px;
    margin-bottom: -672px;
  }

  .sm\:tw--mx-672px {
    margin-left: -672px;
    margin-right: -672px;
  }

  .sm\:tw-mt-0 {
    margin-top: 0;
  }

  .sm\:tw-mr-0 {
    margin-right: 0;
  }

  .sm\:tw-mb-0 {
    margin-bottom: 0;
  }

  .sm\:tw-ml-0 {
    margin-left: 0;
  }

  .sm\:tw-mt-1 {
    margin-top: 0.25rem;
  }

  .sm\:tw-mr-1 {
    margin-right: 0.25rem;
  }

  .sm\:tw-mb-1 {
    margin-bottom: 0.25rem;
  }

  .sm\:tw-ml-1 {
    margin-left: 0.25rem;
  }

  .sm\:tw-mt-2 {
    margin-top: 0.5rem;
  }

  .sm\:tw-mr-2 {
    margin-right: 0.5rem;
  }

  .sm\:tw-mb-2 {
    margin-bottom: 0.5rem;
  }

  .sm\:tw-ml-2 {
    margin-left: 0.5rem;
  }

  .sm\:tw-mt-3 {
    margin-top: 0.75rem;
  }

  .sm\:tw-mr-3 {
    margin-right: 0.75rem;
  }

  .sm\:tw-mb-3 {
    margin-bottom: 0.75rem;
  }

  .sm\:tw-ml-3 {
    margin-left: 0.75rem;
  }

  .sm\:tw-mt-4 {
    margin-top: 1rem;
  }

  .sm\:tw-mr-4 {
    margin-right: 1rem;
  }

  .sm\:tw-mb-4 {
    margin-bottom: 1rem;
  }

  .sm\:tw-ml-4 {
    margin-left: 1rem;
  }

  .sm\:tw-mt-5 {
    margin-top: 1.25rem;
  }

  .sm\:tw-mr-5 {
    margin-right: 1.25rem;
  }

  .sm\:tw-mb-5 {
    margin-bottom: 1.25rem;
  }

  .sm\:tw-ml-5 {
    margin-left: 1.25rem;
  }

  .sm\:tw-mt-6 {
    margin-top: 1.5rem;
  }

  .sm\:tw-mr-6 {
    margin-right: 1.5rem;
  }

  .sm\:tw-mb-6 {
    margin-bottom: 1.5rem;
  }

  .sm\:tw-ml-6 {
    margin-left: 1.5rem;
  }

  .sm\:tw-mt-8 {
    margin-top: 2rem;
  }

  .sm\:tw-mr-8 {
    margin-right: 2rem;
  }

  .sm\:tw-mb-8 {
    margin-bottom: 2rem;
  }

  .sm\:tw-ml-8 {
    margin-left: 2rem;
  }

  .sm\:tw-mt-10 {
    margin-top: 2.5rem;
  }

  .sm\:tw-mr-10 {
    margin-right: 2.5rem;
  }

  .sm\:tw-mb-10 {
    margin-bottom: 2.5rem;
  }

  .sm\:tw-ml-10 {
    margin-left: 2.5rem;
  }

  .sm\:tw-mt-12 {
    margin-top: 3rem;
  }

  .sm\:tw-mr-12 {
    margin-right: 3rem;
  }

  .sm\:tw-mb-12 {
    margin-bottom: 3rem;
  }

  .sm\:tw-ml-12 {
    margin-left: 3rem;
  }

  .sm\:tw-mt-16 {
    margin-top: 4rem;
  }

  .sm\:tw-mr-16 {
    margin-right: 4rem;
  }

  .sm\:tw-mb-16 {
    margin-bottom: 4rem;
  }

  .sm\:tw-ml-16 {
    margin-left: 4rem;
  }

  .sm\:tw-mt-20 {
    margin-top: 5rem;
  }

  .sm\:tw-mr-20 {
    margin-right: 5rem;
  }

  .sm\:tw-mb-20 {
    margin-bottom: 5rem;
  }

  .sm\:tw-ml-20 {
    margin-left: 5rem;
  }

  .sm\:tw-mt-24 {
    margin-top: 6rem;
  }

  .sm\:tw-mr-24 {
    margin-right: 6rem;
  }

  .sm\:tw-mb-24 {
    margin-bottom: 6rem;
  }

  .sm\:tw-ml-24 {
    margin-left: 6rem;
  }

  .sm\:tw-mt-32 {
    margin-top: 8rem;
  }

  .sm\:tw-mr-32 {
    margin-right: 8rem;
  }

  .sm\:tw-mb-32 {
    margin-bottom: 8rem;
  }

  .sm\:tw-ml-32 {
    margin-left: 8rem;
  }

  .sm\:tw-mt-40 {
    margin-top: 10rem;
  }

  .sm\:tw-mr-40 {
    margin-right: 10rem;
  }

  .sm\:tw-mb-40 {
    margin-bottom: 10rem;
  }

  .sm\:tw-ml-40 {
    margin-left: 10rem;
  }

  .sm\:tw-mt-48 {
    margin-top: 12rem;
  }

  .sm\:tw-mr-48 {
    margin-right: 12rem;
  }

  .sm\:tw-mb-48 {
    margin-bottom: 12rem;
  }

  .sm\:tw-ml-48 {
    margin-left: 12rem;
  }

  .sm\:tw-mt-56 {
    margin-top: 14rem;
  }

  .sm\:tw-mr-56 {
    margin-right: 14rem;
  }

  .sm\:tw-mb-56 {
    margin-bottom: 14rem;
  }

  .sm\:tw-ml-56 {
    margin-left: 14rem;
  }

  .sm\:tw-mt-64 {
    margin-top: 16rem;
  }

  .sm\:tw-mr-64 {
    margin-right: 16rem;
  }

  .sm\:tw-mb-64 {
    margin-bottom: 16rem;
  }

  .sm\:tw-ml-64 {
    margin-left: 16rem;
  }

  .sm\:tw-mt-auto {
    margin-top: auto;
  }

  .sm\:tw-mr-auto {
    margin-right: auto;
  }

  .sm\:tw-mb-auto {
    margin-bottom: auto;
  }

  .sm\:tw-ml-auto {
    margin-left: auto;
  }

  .sm\:tw-mt-px {
    margin-top: 1px;
  }

  .sm\:tw-mr-px {
    margin-right: 1px;
  }

  .sm\:tw-mb-px {
    margin-bottom: 1px;
  }

  .sm\:tw-ml-px {
    margin-left: 1px;
  }

  .sm\:tw-mt-270px {
    margin-top: 270px;
  }

  .sm\:tw-mr-270px {
    margin-right: 270px;
  }

  .sm\:tw-mb-270px {
    margin-bottom: 270px;
  }

  .sm\:tw-ml-270px {
    margin-left: 270px;
  }

  .sm\:tw-mt-400px {
    margin-top: 400px;
  }

  .sm\:tw-mr-400px {
    margin-right: 400px;
  }

  .sm\:tw-mb-400px {
    margin-bottom: 400px;
  }

  .sm\:tw-ml-400px {
    margin-left: 400px;
  }

  .sm\:tw-mt-464px {
    margin-top: 464px;
  }

  .sm\:tw-mr-464px {
    margin-right: 464px;
  }

  .sm\:tw-mb-464px {
    margin-bottom: 464px;
  }

  .sm\:tw-ml-464px {
    margin-left: 464px;
  }

  .sm\:tw-mt-480px {
    margin-top: 480px;
  }

  .sm\:tw-mr-480px {
    margin-right: 480px;
  }

  .sm\:tw-mb-480px {
    margin-bottom: 480px;
  }

  .sm\:tw-ml-480px {
    margin-left: 480px;
  }

  .sm\:tw-mt-500px {
    margin-top: 500px;
  }

  .sm\:tw-mr-500px {
    margin-right: 500px;
  }

  .sm\:tw-mb-500px {
    margin-bottom: 500px;
  }

  .sm\:tw-ml-500px {
    margin-left: 500px;
  }

  .sm\:tw-mt-576px {
    margin-top: 576px;
  }

  .sm\:tw-mr-576px {
    margin-right: 576px;
  }

  .sm\:tw-mb-576px {
    margin-bottom: 576px;
  }

  .sm\:tw-ml-576px {
    margin-left: 576px;
  }

  .sm\:tw-mt-600px {
    margin-top: 600px;
  }

  .sm\:tw-mr-600px {
    margin-right: 600px;
  }

  .sm\:tw-mb-600px {
    margin-bottom: 600px;
  }

  .sm\:tw-ml-600px {
    margin-left: 600px;
  }

  .sm\:tw-mt-672px {
    margin-top: 672px;
  }

  .sm\:tw-mr-672px {
    margin-right: 672px;
  }

  .sm\:tw-mb-672px {
    margin-bottom: 672px;
  }

  .sm\:tw-ml-672px {
    margin-left: 672px;
  }

  .sm\:tw--mt-1 {
    margin-top: -0.25rem;
  }

  .sm\:tw--mr-1 {
    margin-right: -0.25rem;
  }

  .sm\:tw--mb-1 {
    margin-bottom: -0.25rem;
  }

  .sm\:tw--ml-1 {
    margin-left: -0.25rem;
  }

  .sm\:tw--mt-2 {
    margin-top: -0.5rem;
  }

  .sm\:tw--mr-2 {
    margin-right: -0.5rem;
  }

  .sm\:tw--mb-2 {
    margin-bottom: -0.5rem;
  }

  .sm\:tw--ml-2 {
    margin-left: -0.5rem;
  }

  .sm\:tw--mt-3 {
    margin-top: -0.75rem;
  }

  .sm\:tw--mr-3 {
    margin-right: -0.75rem;
  }

  .sm\:tw--mb-3 {
    margin-bottom: -0.75rem;
  }

  .sm\:tw--ml-3 {
    margin-left: -0.75rem;
  }

  .sm\:tw--mt-4 {
    margin-top: -1rem;
  }

  .sm\:tw--mr-4 {
    margin-right: -1rem;
  }

  .sm\:tw--mb-4 {
    margin-bottom: -1rem;
  }

  .sm\:tw--ml-4 {
    margin-left: -1rem;
  }

  .sm\:tw--mt-5 {
    margin-top: -1.25rem;
  }

  .sm\:tw--mr-5 {
    margin-right: -1.25rem;
  }

  .sm\:tw--mb-5 {
    margin-bottom: -1.25rem;
  }

  .sm\:tw--ml-5 {
    margin-left: -1.25rem;
  }

  .sm\:tw--mt-6 {
    margin-top: -1.5rem;
  }

  .sm\:tw--mr-6 {
    margin-right: -1.5rem;
  }

  .sm\:tw--mb-6 {
    margin-bottom: -1.5rem;
  }

  .sm\:tw--ml-6 {
    margin-left: -1.5rem;
  }

  .sm\:tw--mt-8 {
    margin-top: -2rem;
  }

  .sm\:tw--mr-8 {
    margin-right: -2rem;
  }

  .sm\:tw--mb-8 {
    margin-bottom: -2rem;
  }

  .sm\:tw--ml-8 {
    margin-left: -2rem;
  }

  .sm\:tw--mt-10 {
    margin-top: -2.5rem;
  }

  .sm\:tw--mr-10 {
    margin-right: -2.5rem;
  }

  .sm\:tw--mb-10 {
    margin-bottom: -2.5rem;
  }

  .sm\:tw--ml-10 {
    margin-left: -2.5rem;
  }

  .sm\:tw--mt-12 {
    margin-top: -3rem;
  }

  .sm\:tw--mr-12 {
    margin-right: -3rem;
  }

  .sm\:tw--mb-12 {
    margin-bottom: -3rem;
  }

  .sm\:tw--ml-12 {
    margin-left: -3rem;
  }

  .sm\:tw--mt-16 {
    margin-top: -4rem;
  }

  .sm\:tw--mr-16 {
    margin-right: -4rem;
  }

  .sm\:tw--mb-16 {
    margin-bottom: -4rem;
  }

  .sm\:tw--ml-16 {
    margin-left: -4rem;
  }

  .sm\:tw--mt-20 {
    margin-top: -5rem;
  }

  .sm\:tw--mr-20 {
    margin-right: -5rem;
  }

  .sm\:tw--mb-20 {
    margin-bottom: -5rem;
  }

  .sm\:tw--ml-20 {
    margin-left: -5rem;
  }

  .sm\:tw--mt-24 {
    margin-top: -6rem;
  }

  .sm\:tw--mr-24 {
    margin-right: -6rem;
  }

  .sm\:tw--mb-24 {
    margin-bottom: -6rem;
  }

  .sm\:tw--ml-24 {
    margin-left: -6rem;
  }

  .sm\:tw--mt-32 {
    margin-top: -8rem;
  }

  .sm\:tw--mr-32 {
    margin-right: -8rem;
  }

  .sm\:tw--mb-32 {
    margin-bottom: -8rem;
  }

  .sm\:tw--ml-32 {
    margin-left: -8rem;
  }

  .sm\:tw--mt-40 {
    margin-top: -10rem;
  }

  .sm\:tw--mr-40 {
    margin-right: -10rem;
  }

  .sm\:tw--mb-40 {
    margin-bottom: -10rem;
  }

  .sm\:tw--ml-40 {
    margin-left: -10rem;
  }

  .sm\:tw--mt-48 {
    margin-top: -12rem;
  }

  .sm\:tw--mr-48 {
    margin-right: -12rem;
  }

  .sm\:tw--mb-48 {
    margin-bottom: -12rem;
  }

  .sm\:tw--ml-48 {
    margin-left: -12rem;
  }

  .sm\:tw--mt-56 {
    margin-top: -14rem;
  }

  .sm\:tw--mr-56 {
    margin-right: -14rem;
  }

  .sm\:tw--mb-56 {
    margin-bottom: -14rem;
  }

  .sm\:tw--ml-56 {
    margin-left: -14rem;
  }

  .sm\:tw--mt-64 {
    margin-top: -16rem;
  }

  .sm\:tw--mr-64 {
    margin-right: -16rem;
  }

  .sm\:tw--mb-64 {
    margin-bottom: -16rem;
  }

  .sm\:tw--ml-64 {
    margin-left: -16rem;
  }

  .sm\:tw--mt-px {
    margin-top: -1px;
  }

  .sm\:tw--mr-px {
    margin-right: -1px;
  }

  .sm\:tw--mb-px {
    margin-bottom: -1px;
  }

  .sm\:tw--ml-px {
    margin-left: -1px;
  }

  .sm\:tw--mt-270px {
    margin-top: -270px;
  }

  .sm\:tw--mr-270px {
    margin-right: -270px;
  }

  .sm\:tw--mb-270px {
    margin-bottom: -270px;
  }

  .sm\:tw--ml-270px {
    margin-left: -270px;
  }

  .sm\:tw--mt-400px {
    margin-top: -400px;
  }

  .sm\:tw--mr-400px {
    margin-right: -400px;
  }

  .sm\:tw--mb-400px {
    margin-bottom: -400px;
  }

  .sm\:tw--ml-400px {
    margin-left: -400px;
  }

  .sm\:tw--mt-464px {
    margin-top: -464px;
  }

  .sm\:tw--mr-464px {
    margin-right: -464px;
  }

  .sm\:tw--mb-464px {
    margin-bottom: -464px;
  }

  .sm\:tw--ml-464px {
    margin-left: -464px;
  }

  .sm\:tw--mt-480px {
    margin-top: -480px;
  }

  .sm\:tw--mr-480px {
    margin-right: -480px;
  }

  .sm\:tw--mb-480px {
    margin-bottom: -480px;
  }

  .sm\:tw--ml-480px {
    margin-left: -480px;
  }

  .sm\:tw--mt-500px {
    margin-top: -500px;
  }

  .sm\:tw--mr-500px {
    margin-right: -500px;
  }

  .sm\:tw--mb-500px {
    margin-bottom: -500px;
  }

  .sm\:tw--ml-500px {
    margin-left: -500px;
  }

  .sm\:tw--mt-576px {
    margin-top: -576px;
  }

  .sm\:tw--mr-576px {
    margin-right: -576px;
  }

  .sm\:tw--mb-576px {
    margin-bottom: -576px;
  }

  .sm\:tw--ml-576px {
    margin-left: -576px;
  }

  .sm\:tw--mt-600px {
    margin-top: -600px;
  }

  .sm\:tw--mr-600px {
    margin-right: -600px;
  }

  .sm\:tw--mb-600px {
    margin-bottom: -600px;
  }

  .sm\:tw--ml-600px {
    margin-left: -600px;
  }

  .sm\:tw--mt-672px {
    margin-top: -672px;
  }

  .sm\:tw--mr-672px {
    margin-right: -672px;
  }

  .sm\:tw--mb-672px {
    margin-bottom: -672px;
  }

  .sm\:tw--ml-672px {
    margin-left: -672px;
  }

  .sm\:tw-max-h-full {
    max-height: 100%;
  }

  .sm\:tw-max-h-screen {
    max-height: 100vh;
  }

  .sm\:tw-max-w-none {
    max-width: none;
  }

  .sm\:tw-max-w-xs {
    max-width: 20rem;
  }

  .sm\:tw-max-w-sm {
    max-width: 24rem;
  }

  .sm\:tw-max-w-md {
    max-width: 28rem;
  }

  .sm\:tw-max-w-lg {
    max-width: 32rem;
  }

  .sm\:tw-max-w-xl {
    max-width: 36rem;
  }

  .sm\:tw-max-w-2xl {
    max-width: 42rem;
  }

  .sm\:tw-max-w-3xl {
    max-width: 48rem;
  }

  .sm\:tw-max-w-4xl {
    max-width: 56rem;
  }

  .sm\:tw-max-w-5xl {
    max-width: 64rem;
  }

  .sm\:tw-max-w-6xl {
    max-width: 72rem;
  }

  .sm\:tw-max-w-full {
    max-width: 100%;
  }

  .sm\:tw-max-w-screen-sm {
    max-width: 640px;
  }

  .sm\:tw-max-w-screen-md {
    max-width: 768px;
  }

  .sm\:tw-max-w-screen-lg {
    max-width: 1024px;
  }

  .sm\:tw-max-w-screen-xl {
    max-width: 1280px;
  }

  .sm\:tw-min-h-0 {
    min-height: 0;
  }

  .sm\:tw-min-h-full {
    min-height: 100%;
  }

  .sm\:tw-min-h-screen {
    min-height: 100vh;
  }

  .sm\:tw-min-h-576px {
    min-height: 576px;
  }

  .sm\:tw-min-h-600px {
    min-height: 600px;
  }

  .sm\:tw-min-h-672px {
    min-height: 672px;
  }

  .sm\:tw-min-w-0 {
    min-width: 0;
  }

  .sm\:tw-min-w-full {
    min-width: 100%;
  }

  .sm\:tw-min-w-360px {
    min-width: 360px;
  }

  .sm\:tw-min-w-540px {
    min-width: 540px;
  }

  .sm\:tw-object-contain {
    -o-object-fit: contain;
       object-fit: contain;
  }

  .sm\:tw-object-cover {
    -o-object-fit: cover;
       object-fit: cover;
  }

  .sm\:tw-object-fill {
    -o-object-fit: fill;
       object-fit: fill;
  }

  .sm\:tw-object-none {
    -o-object-fit: none;
       object-fit: none;
  }

  .sm\:tw-object-scale-down {
    -o-object-fit: scale-down;
       object-fit: scale-down;
  }

  .sm\:tw-object-bottom {
    -o-object-position: bottom;
       object-position: bottom;
  }

  .sm\:tw-object-center {
    -o-object-position: center;
       object-position: center;
  }

  .sm\:tw-object-left {
    -o-object-position: left;
       object-position: left;
  }

  .sm\:tw-object-left-bottom {
    -o-object-position: left bottom;
       object-position: left bottom;
  }

  .sm\:tw-object-left-top {
    -o-object-position: left top;
       object-position: left top;
  }

  .sm\:tw-object-right {
    -o-object-position: right;
       object-position: right;
  }

  .sm\:tw-object-right-bottom {
    -o-object-position: right bottom;
       object-position: right bottom;
  }

  .sm\:tw-object-right-top {
    -o-object-position: right top;
       object-position: right top;
  }

  .sm\:tw-object-top {
    -o-object-position: top;
       object-position: top;
  }

  .sm\:tw-opacity-0 {
    opacity: 0;
  }

  .sm\:tw-opacity-25 {
    opacity: 0.25;
  }

  .sm\:tw-opacity-50 {
    opacity: 0.5;
  }

  .sm\:tw-opacity-75 {
    opacity: 0.75;
  }

  .sm\:tw-opacity-100 {
    opacity: 1;
  }

  .sm\:hover\:tw-opacity-0:hover {
    opacity: 0;
  }

  .sm\:hover\:tw-opacity-25:hover {
    opacity: 0.25;
  }

  .sm\:hover\:tw-opacity-50:hover {
    opacity: 0.5;
  }

  .sm\:hover\:tw-opacity-75:hover {
    opacity: 0.75;
  }

  .sm\:hover\:tw-opacity-100:hover {
    opacity: 1;
  }

  .sm\:focus\:tw-opacity-0:focus {
    opacity: 0;
  }

  .sm\:focus\:tw-opacity-25:focus {
    opacity: 0.25;
  }

  .sm\:focus\:tw-opacity-50:focus {
    opacity: 0.5;
  }

  .sm\:focus\:tw-opacity-75:focus {
    opacity: 0.75;
  }

  .sm\:focus\:tw-opacity-100:focus {
    opacity: 1;
  }

  .sm\:tw-outline-none {
    outline: 2px solid transparent;
    outline-offset: 2px;
  }

  .sm\:tw-outline-white {
    outline: 2px dotted white;
    outline-offset: 2px;
  }

  .sm\:tw-outline-black {
    outline: 2px dotted black;
    outline-offset: 2px;
  }

  .sm\:focus\:tw-outline-none:focus {
    outline: 2px solid transparent;
    outline-offset: 2px;
  }

  .sm\:focus\:tw-outline-white:focus {
    outline: 2px dotted white;
    outline-offset: 2px;
  }

  .sm\:focus\:tw-outline-black:focus {
    outline: 2px dotted black;
    outline-offset: 2px;
  }

  .sm\:tw-overflow-auto {
    overflow: auto;
  }

  .sm\:tw-overflow-hidden {
    overflow: hidden;
  }

  .sm\:tw-overflow-visible {
    overflow: visible;
  }

  .sm\:tw-overflow-scroll {
    overflow: scroll;
  }

  .sm\:tw-overflow-x-auto {
    overflow-x: auto;
  }

  .sm\:tw-overflow-y-auto {
    overflow-y: auto;
  }

  .sm\:tw-overflow-x-hidden {
    overflow-x: hidden;
  }

  .sm\:tw-overflow-y-hidden {
    overflow-y: hidden;
  }

  .sm\:tw-overflow-x-visible {
    overflow-x: visible;
  }

  .sm\:tw-overflow-y-visible {
    overflow-y: visible;
  }

  .sm\:tw-overflow-x-scroll {
    overflow-x: scroll;
  }

  .sm\:tw-overflow-y-scroll {
    overflow-y: scroll;
  }

  .sm\:tw-scrolling-touch {
    -webkit-overflow-scrolling: touch;
  }

  .sm\:tw-scrolling-auto {
    -webkit-overflow-scrolling: auto;
  }

  .sm\:tw-overscroll-auto {
    overscroll-behavior: auto;
  }

  .sm\:tw-overscroll-contain {
    overscroll-behavior: contain;
  }

  .sm\:tw-overscroll-none {
    overscroll-behavior: none;
  }

  .sm\:tw-overscroll-y-auto {
    overscroll-behavior-y: auto;
  }

  .sm\:tw-overscroll-y-contain {
    overscroll-behavior-y: contain;
  }

  .sm\:tw-overscroll-y-none {
    overscroll-behavior-y: none;
  }

  .sm\:tw-overscroll-x-auto {
    overscroll-behavior-x: auto;
  }

  .sm\:tw-overscroll-x-contain {
    overscroll-behavior-x: contain;
  }

  .sm\:tw-overscroll-x-none {
    overscroll-behavior-x: none;
  }

  .sm\:tw-p-0 {
    padding: 0;
  }

  .sm\:tw-p-1 {
    padding: 0.25rem;
  }

  .sm\:tw-p-2 {
    padding: 0.5rem;
  }

  .sm\:tw-p-3 {
    padding: 0.75rem;
  }

  .sm\:tw-p-4 {
    padding: 1rem;
  }

  .sm\:tw-p-5 {
    padding: 1.25rem;
  }

  .sm\:tw-p-6 {
    padding: 1.5rem;
  }

  .sm\:tw-p-8 {
    padding: 2rem;
  }

  .sm\:tw-p-10 {
    padding: 2.5rem;
  }

  .sm\:tw-p-12 {
    padding: 3rem;
  }

  .sm\:tw-p-16 {
    padding: 4rem;
  }

  .sm\:tw-p-20 {
    padding: 5rem;
  }

  .sm\:tw-p-24 {
    padding: 6rem;
  }

  .sm\:tw-p-32 {
    padding: 8rem;
  }

  .sm\:tw-p-40 {
    padding: 10rem;
  }

  .sm\:tw-p-48 {
    padding: 12rem;
  }

  .sm\:tw-p-56 {
    padding: 14rem;
  }

  .sm\:tw-p-64 {
    padding: 16rem;
  }

  .sm\:tw-p-px {
    padding: 1px;
  }

  .sm\:tw-p-270px {
    padding: 270px;
  }

  .sm\:tw-p-400px {
    padding: 400px;
  }

  .sm\:tw-p-464px {
    padding: 464px;
  }

  .sm\:tw-p-480px {
    padding: 480px;
  }

  .sm\:tw-p-500px {
    padding: 500px;
  }

  .sm\:tw-p-576px {
    padding: 576px;
  }

  .sm\:tw-p-600px {
    padding: 600px;
  }

  .sm\:tw-p-672px {
    padding: 672px;
  }

  .sm\:tw-py-0 {
    padding-top: 0;
    padding-bottom: 0;
  }

  .sm\:tw-px-0 {
    padding-left: 0;
    padding-right: 0;
  }

  .sm\:tw-py-1 {
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;
  }

  .sm\:tw-px-1 {
    padding-left: 0.25rem;
    padding-right: 0.25rem;
  }

  .sm\:tw-py-2 {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
  }

  .sm\:tw-px-2 {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }

  .sm\:tw-py-3 {
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
  }

  .sm\:tw-px-3 {
    padding-left: 0.75rem;
    padding-right: 0.75rem;
  }

  .sm\:tw-py-4 {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }

  .sm\:tw-px-4 {
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .sm\:tw-py-5 {
    padding-top: 1.25rem;
    padding-bottom: 1.25rem;
  }

  .sm\:tw-px-5 {
    padding-left: 1.25rem;
    padding-right: 1.25rem;
  }

  .sm\:tw-py-6 {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
  }

  .sm\:tw-px-6 {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }

  .sm\:tw-py-8 {
    padding-top: 2rem;
    padding-bottom: 2rem;
  }

  .sm\:tw-px-8 {
    padding-left: 2rem;
    padding-right: 2rem;
  }

  .sm\:tw-py-10 {
    padding-top: 2.5rem;
    padding-bottom: 2.5rem;
  }

  .sm\:tw-px-10 {
    padding-left: 2.5rem;
    padding-right: 2.5rem;
  }

  .sm\:tw-py-12 {
    padding-top: 3rem;
    padding-bottom: 3rem;
  }

  .sm\:tw-px-12 {
    padding-left: 3rem;
    padding-right: 3rem;
  }

  .sm\:tw-py-16 {
    padding-top: 4rem;
    padding-bottom: 4rem;
  }

  .sm\:tw-px-16 {
    padding-left: 4rem;
    padding-right: 4rem;
  }

  .sm\:tw-py-20 {
    padding-top: 5rem;
    padding-bottom: 5rem;
  }

  .sm\:tw-px-20 {
    padding-left: 5rem;
    padding-right: 5rem;
  }

  .sm\:tw-py-24 {
    padding-top: 6rem;
    padding-bottom: 6rem;
  }

  .sm\:tw-px-24 {
    padding-left: 6rem;
    padding-right: 6rem;
  }

  .sm\:tw-py-32 {
    padding-top: 8rem;
    padding-bottom: 8rem;
  }

  .sm\:tw-px-32 {
    padding-left: 8rem;
    padding-right: 8rem;
  }

  .sm\:tw-py-40 {
    padding-top: 10rem;
    padding-bottom: 10rem;
  }

  .sm\:tw-px-40 {
    padding-left: 10rem;
    padding-right: 10rem;
  }

  .sm\:tw-py-48 {
    padding-top: 12rem;
    padding-bottom: 12rem;
  }

  .sm\:tw-px-48 {
    padding-left: 12rem;
    padding-right: 12rem;
  }

  .sm\:tw-py-56 {
    padding-top: 14rem;
    padding-bottom: 14rem;
  }

  .sm\:tw-px-56 {
    padding-left: 14rem;
    padding-right: 14rem;
  }

  .sm\:tw-py-64 {
    padding-top: 16rem;
    padding-bottom: 16rem;
  }

  .sm\:tw-px-64 {
    padding-left: 16rem;
    padding-right: 16rem;
  }

  .sm\:tw-py-px {
    padding-top: 1px;
    padding-bottom: 1px;
  }

  .sm\:tw-px-px {
    padding-left: 1px;
    padding-right: 1px;
  }

  .sm\:tw-py-270px {
    padding-top: 270px;
    padding-bottom: 270px;
  }

  .sm\:tw-px-270px {
    padding-left: 270px;
    padding-right: 270px;
  }

  .sm\:tw-py-400px {
    padding-top: 400px;
    padding-bottom: 400px;
  }

  .sm\:tw-px-400px {
    padding-left: 400px;
    padding-right: 400px;
  }

  .sm\:tw-py-464px {
    padding-top: 464px;
    padding-bottom: 464px;
  }

  .sm\:tw-px-464px {
    padding-left: 464px;
    padding-right: 464px;
  }

  .sm\:tw-py-480px {
    padding-top: 480px;
    padding-bottom: 480px;
  }

  .sm\:tw-px-480px {
    padding-left: 480px;
    padding-right: 480px;
  }

  .sm\:tw-py-500px {
    padding-top: 500px;
    padding-bottom: 500px;
  }

  .sm\:tw-px-500px {
    padding-left: 500px;
    padding-right: 500px;
  }

  .sm\:tw-py-576px {
    padding-top: 576px;
    padding-bottom: 576px;
  }

  .sm\:tw-px-576px {
    padding-left: 576px;
    padding-right: 576px;
  }

  .sm\:tw-py-600px {
    padding-top: 600px;
    padding-bottom: 600px;
  }

  .sm\:tw-px-600px {
    padding-left: 600px;
    padding-right: 600px;
  }

  .sm\:tw-py-672px {
    padding-top: 672px;
    padding-bottom: 672px;
  }

  .sm\:tw-px-672px {
    padding-left: 672px;
    padding-right: 672px;
  }

  .sm\:tw-pt-0 {
    padding-top: 0;
  }

  .sm\:tw-pr-0 {
    padding-right: 0;
  }

  .sm\:tw-pb-0 {
    padding-bottom: 0;
  }

  .sm\:tw-pl-0 {
    padding-left: 0;
  }

  .sm\:tw-pt-1 {
    padding-top: 0.25rem;
  }

  .sm\:tw-pr-1 {
    padding-right: 0.25rem;
  }

  .sm\:tw-pb-1 {
    padding-bottom: 0.25rem;
  }

  .sm\:tw-pl-1 {
    padding-left: 0.25rem;
  }

  .sm\:tw-pt-2 {
    padding-top: 0.5rem;
  }

  .sm\:tw-pr-2 {
    padding-right: 0.5rem;
  }

  .sm\:tw-pb-2 {
    padding-bottom: 0.5rem;
  }

  .sm\:tw-pl-2 {
    padding-left: 0.5rem;
  }

  .sm\:tw-pt-3 {
    padding-top: 0.75rem;
  }

  .sm\:tw-pr-3 {
    padding-right: 0.75rem;
  }

  .sm\:tw-pb-3 {
    padding-bottom: 0.75rem;
  }

  .sm\:tw-pl-3 {
    padding-left: 0.75rem;
  }

  .sm\:tw-pt-4 {
    padding-top: 1rem;
  }

  .sm\:tw-pr-4 {
    padding-right: 1rem;
  }

  .sm\:tw-pb-4 {
    padding-bottom: 1rem;
  }

  .sm\:tw-pl-4 {
    padding-left: 1rem;
  }

  .sm\:tw-pt-5 {
    padding-top: 1.25rem;
  }

  .sm\:tw-pr-5 {
    padding-right: 1.25rem;
  }

  .sm\:tw-pb-5 {
    padding-bottom: 1.25rem;
  }

  .sm\:tw-pl-5 {
    padding-left: 1.25rem;
  }

  .sm\:tw-pt-6 {
    padding-top: 1.5rem;
  }

  .sm\:tw-pr-6 {
    padding-right: 1.5rem;
  }

  .sm\:tw-pb-6 {
    padding-bottom: 1.5rem;
  }

  .sm\:tw-pl-6 {
    padding-left: 1.5rem;
  }

  .sm\:tw-pt-8 {
    padding-top: 2rem;
  }

  .sm\:tw-pr-8 {
    padding-right: 2rem;
  }

  .sm\:tw-pb-8 {
    padding-bottom: 2rem;
  }

  .sm\:tw-pl-8 {
    padding-left: 2rem;
  }

  .sm\:tw-pt-10 {
    padding-top: 2.5rem;
  }

  .sm\:tw-pr-10 {
    padding-right: 2.5rem;
  }

  .sm\:tw-pb-10 {
    padding-bottom: 2.5rem;
  }

  .sm\:tw-pl-10 {
    padding-left: 2.5rem;
  }

  .sm\:tw-pt-12 {
    padding-top: 3rem;
  }

  .sm\:tw-pr-12 {
    padding-right: 3rem;
  }

  .sm\:tw-pb-12 {
    padding-bottom: 3rem;
  }

  .sm\:tw-pl-12 {
    padding-left: 3rem;
  }

  .sm\:tw-pt-16 {
    padding-top: 4rem;
  }

  .sm\:tw-pr-16 {
    padding-right: 4rem;
  }

  .sm\:tw-pb-16 {
    padding-bottom: 4rem;
  }

  .sm\:tw-pl-16 {
    padding-left: 4rem;
  }

  .sm\:tw-pt-20 {
    padding-top: 5rem;
  }

  .sm\:tw-pr-20 {
    padding-right: 5rem;
  }

  .sm\:tw-pb-20 {
    padding-bottom: 5rem;
  }

  .sm\:tw-pl-20 {
    padding-left: 5rem;
  }

  .sm\:tw-pt-24 {
    padding-top: 6rem;
  }

  .sm\:tw-pr-24 {
    padding-right: 6rem;
  }

  .sm\:tw-pb-24 {
    padding-bottom: 6rem;
  }

  .sm\:tw-pl-24 {
    padding-left: 6rem;
  }

  .sm\:tw-pt-32 {
    padding-top: 8rem;
  }

  .sm\:tw-pr-32 {
    padding-right: 8rem;
  }

  .sm\:tw-pb-32 {
    padding-bottom: 8rem;
  }

  .sm\:tw-pl-32 {
    padding-left: 8rem;
  }

  .sm\:tw-pt-40 {
    padding-top: 10rem;
  }

  .sm\:tw-pr-40 {
    padding-right: 10rem;
  }

  .sm\:tw-pb-40 {
    padding-bottom: 10rem;
  }

  .sm\:tw-pl-40 {
    padding-left: 10rem;
  }

  .sm\:tw-pt-48 {
    padding-top: 12rem;
  }

  .sm\:tw-pr-48 {
    padding-right: 12rem;
  }

  .sm\:tw-pb-48 {
    padding-bottom: 12rem;
  }

  .sm\:tw-pl-48 {
    padding-left: 12rem;
  }

  .sm\:tw-pt-56 {
    padding-top: 14rem;
  }

  .sm\:tw-pr-56 {
    padding-right: 14rem;
  }

  .sm\:tw-pb-56 {
    padding-bottom: 14rem;
  }

  .sm\:tw-pl-56 {
    padding-left: 14rem;
  }

  .sm\:tw-pt-64 {
    padding-top: 16rem;
  }

  .sm\:tw-pr-64 {
    padding-right: 16rem;
  }

  .sm\:tw-pb-64 {
    padding-bottom: 16rem;
  }

  .sm\:tw-pl-64 {
    padding-left: 16rem;
  }

  .sm\:tw-pt-px {
    padding-top: 1px;
  }

  .sm\:tw-pr-px {
    padding-right: 1px;
  }

  .sm\:tw-pb-px {
    padding-bottom: 1px;
  }

  .sm\:tw-pl-px {
    padding-left: 1px;
  }

  .sm\:tw-pt-270px {
    padding-top: 270px;
  }

  .sm\:tw-pr-270px {
    padding-right: 270px;
  }

  .sm\:tw-pb-270px {
    padding-bottom: 270px;
  }

  .sm\:tw-pl-270px {
    padding-left: 270px;
  }

  .sm\:tw-pt-400px {
    padding-top: 400px;
  }

  .sm\:tw-pr-400px {
    padding-right: 400px;
  }

  .sm\:tw-pb-400px {
    padding-bottom: 400px;
  }

  .sm\:tw-pl-400px {
    padding-left: 400px;
  }

  .sm\:tw-pt-464px {
    padding-top: 464px;
  }

  .sm\:tw-pr-464px {
    padding-right: 464px;
  }

  .sm\:tw-pb-464px {
    padding-bottom: 464px;
  }

  .sm\:tw-pl-464px {
    padding-left: 464px;
  }

  .sm\:tw-pt-480px {
    padding-top: 480px;
  }

  .sm\:tw-pr-480px {
    padding-right: 480px;
  }

  .sm\:tw-pb-480px {
    padding-bottom: 480px;
  }

  .sm\:tw-pl-480px {
    padding-left: 480px;
  }

  .sm\:tw-pt-500px {
    padding-top: 500px;
  }

  .sm\:tw-pr-500px {
    padding-right: 500px;
  }

  .sm\:tw-pb-500px {
    padding-bottom: 500px;
  }

  .sm\:tw-pl-500px {
    padding-left: 500px;
  }

  .sm\:tw-pt-576px {
    padding-top: 576px;
  }

  .sm\:tw-pr-576px {
    padding-right: 576px;
  }

  .sm\:tw-pb-576px {
    padding-bottom: 576px;
  }

  .sm\:tw-pl-576px {
    padding-left: 576px;
  }

  .sm\:tw-pt-600px {
    padding-top: 600px;
  }

  .sm\:tw-pr-600px {
    padding-right: 600px;
  }

  .sm\:tw-pb-600px {
    padding-bottom: 600px;
  }

  .sm\:tw-pl-600px {
    padding-left: 600px;
  }

  .sm\:tw-pt-672px {
    padding-top: 672px;
  }

  .sm\:tw-pr-672px {
    padding-right: 672px;
  }

  .sm\:tw-pb-672px {
    padding-bottom: 672px;
  }

  .sm\:tw-pl-672px {
    padding-left: 672px;
  }

  .sm\:tw-placeholder-transparent::-moz-placeholder {
    color: transparent;
  }

  .sm\:tw-placeholder-transparent::placeholder {
    color: transparent;
  }

  .sm\:tw-placeholder-current::-moz-placeholder {
    color: currentColor;
  }

  .sm\:tw-placeholder-current::placeholder {
    color: currentColor;
  }

  .sm\:tw-placeholder-black::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #000;
    color: rgba(0, 0, 0, var(--placeholder-opacity));
  }

  .sm\:tw-placeholder-black::placeholder {
    --placeholder-opacity: 1;
    color: #000;
    color: rgba(0, 0, 0, var(--placeholder-opacity));
  }

  .sm\:tw-placeholder-white::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #fff;
    color: rgba(255, 255, 255, var(--placeholder-opacity));
  }

  .sm\:tw-placeholder-white::placeholder {
    --placeholder-opacity: 1;
    color: #fff;
    color: rgba(255, 255, 255, var(--placeholder-opacity));
  }

  .sm\:tw-placeholder-gray-100::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #f7fafc;
    color: rgba(247, 250, 252, var(--placeholder-opacity));
  }

  .sm\:tw-placeholder-gray-100::placeholder {
    --placeholder-opacity: 1;
    color: #f7fafc;
    color: rgba(247, 250, 252, var(--placeholder-opacity));
  }

  .sm\:tw-placeholder-gray-200::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #edf2f7;
    color: rgba(237, 242, 247, var(--placeholder-opacity));
  }

  .sm\:tw-placeholder-gray-200::placeholder {
    --placeholder-opacity: 1;
    color: #edf2f7;
    color: rgba(237, 242, 247, var(--placeholder-opacity));
  }

  .sm\:tw-placeholder-gray-300::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #e2e8f0;
    color: rgba(226, 232, 240, var(--placeholder-opacity));
  }

  .sm\:tw-placeholder-gray-300::placeholder {
    --placeholder-opacity: 1;
    color: #e2e8f0;
    color: rgba(226, 232, 240, var(--placeholder-opacity));
  }

  .sm\:tw-placeholder-gray-400::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #cbd5e0;
    color: rgba(203, 213, 224, var(--placeholder-opacity));
  }

  .sm\:tw-placeholder-gray-400::placeholder {
    --placeholder-opacity: 1;
    color: #cbd5e0;
    color: rgba(203, 213, 224, var(--placeholder-opacity));
  }

  .sm\:tw-placeholder-gray-500::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #a0aec0;
    color: rgba(160, 174, 192, var(--placeholder-opacity));
  }

  .sm\:tw-placeholder-gray-500::placeholder {
    --placeholder-opacity: 1;
    color: #a0aec0;
    color: rgba(160, 174, 192, var(--placeholder-opacity));
  }

  .sm\:tw-placeholder-gray-600::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #718096;
    color: rgba(113, 128, 150, var(--placeholder-opacity));
  }

  .sm\:tw-placeholder-gray-600::placeholder {
    --placeholder-opacity: 1;
    color: #718096;
    color: rgba(113, 128, 150, var(--placeholder-opacity));
  }

  .sm\:tw-placeholder-gray-700::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #4a5568;
    color: rgba(74, 85, 104, var(--placeholder-opacity));
  }

  .sm\:tw-placeholder-gray-700::placeholder {
    --placeholder-opacity: 1;
    color: #4a5568;
    color: rgba(74, 85, 104, var(--placeholder-opacity));
  }

  .sm\:tw-placeholder-gray-800::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #2d3748;
    color: rgba(45, 55, 72, var(--placeholder-opacity));
  }

  .sm\:tw-placeholder-gray-800::placeholder {
    --placeholder-opacity: 1;
    color: #2d3748;
    color: rgba(45, 55, 72, var(--placeholder-opacity));
  }

  .sm\:tw-placeholder-gray-900::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #1a202c;
    color: rgba(26, 32, 44, var(--placeholder-opacity));
  }

  .sm\:tw-placeholder-gray-900::placeholder {
    --placeholder-opacity: 1;
    color: #1a202c;
    color: rgba(26, 32, 44, var(--placeholder-opacity));
  }

  .sm\:tw-placeholder-red-100::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #fff5f5;
    color: rgba(255, 245, 245, var(--placeholder-opacity));
  }

  .sm\:tw-placeholder-red-100::placeholder {
    --placeholder-opacity: 1;
    color: #fff5f5;
    color: rgba(255, 245, 245, var(--placeholder-opacity));
  }

  .sm\:tw-placeholder-red-200::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #fed7d7;
    color: rgba(254, 215, 215, var(--placeholder-opacity));
  }

  .sm\:tw-placeholder-red-200::placeholder {
    --placeholder-opacity: 1;
    color: #fed7d7;
    color: rgba(254, 215, 215, var(--placeholder-opacity));
  }

  .sm\:tw-placeholder-red-300::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #feb2b2;
    color: rgba(254, 178, 178, var(--placeholder-opacity));
  }

  .sm\:tw-placeholder-red-300::placeholder {
    --placeholder-opacity: 1;
    color: #feb2b2;
    color: rgba(254, 178, 178, var(--placeholder-opacity));
  }

  .sm\:tw-placeholder-red-400::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #fc8181;
    color: rgba(252, 129, 129, var(--placeholder-opacity));
  }

  .sm\:tw-placeholder-red-400::placeholder {
    --placeholder-opacity: 1;
    color: #fc8181;
    color: rgba(252, 129, 129, var(--placeholder-opacity));
  }

  .sm\:tw-placeholder-red-500::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #f56565;
    color: rgba(245, 101, 101, var(--placeholder-opacity));
  }

  .sm\:tw-placeholder-red-500::placeholder {
    --placeholder-opacity: 1;
    color: #f56565;
    color: rgba(245, 101, 101, var(--placeholder-opacity));
  }

  .sm\:tw-placeholder-red-600::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #e53e3e;
    color: rgba(229, 62, 62, var(--placeholder-opacity));
  }

  .sm\:tw-placeholder-red-600::placeholder {
    --placeholder-opacity: 1;
    color: #e53e3e;
    color: rgba(229, 62, 62, var(--placeholder-opacity));
  }

  .sm\:tw-placeholder-red-700::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #c53030;
    color: rgba(197, 48, 48, var(--placeholder-opacity));
  }

  .sm\:tw-placeholder-red-700::placeholder {
    --placeholder-opacity: 1;
    color: #c53030;
    color: rgba(197, 48, 48, var(--placeholder-opacity));
  }

  .sm\:tw-placeholder-red-800::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #9b2c2c;
    color: rgba(155, 44, 44, var(--placeholder-opacity));
  }

  .sm\:tw-placeholder-red-800::placeholder {
    --placeholder-opacity: 1;
    color: #9b2c2c;
    color: rgba(155, 44, 44, var(--placeholder-opacity));
  }

  .sm\:tw-placeholder-red-900::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #742a2a;
    color: rgba(116, 42, 42, var(--placeholder-opacity));
  }

  .sm\:tw-placeholder-red-900::placeholder {
    --placeholder-opacity: 1;
    color: #742a2a;
    color: rgba(116, 42, 42, var(--placeholder-opacity));
  }

  .sm\:tw-placeholder-orange-100::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #fffaf0;
    color: rgba(255, 250, 240, var(--placeholder-opacity));
  }

  .sm\:tw-placeholder-orange-100::placeholder {
    --placeholder-opacity: 1;
    color: #fffaf0;
    color: rgba(255, 250, 240, var(--placeholder-opacity));
  }

  .sm\:tw-placeholder-orange-200::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #feebc8;
    color: rgba(254, 235, 200, var(--placeholder-opacity));
  }

  .sm\:tw-placeholder-orange-200::placeholder {
    --placeholder-opacity: 1;
    color: #feebc8;
    color: rgba(254, 235, 200, var(--placeholder-opacity));
  }

  .sm\:tw-placeholder-orange-300::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #fbd38d;
    color: rgba(251, 211, 141, var(--placeholder-opacity));
  }

  .sm\:tw-placeholder-orange-300::placeholder {
    --placeholder-opacity: 1;
    color: #fbd38d;
    color: rgba(251, 211, 141, var(--placeholder-opacity));
  }

  .sm\:tw-placeholder-orange-400::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #f6ad55;
    color: rgba(246, 173, 85, var(--placeholder-opacity));
  }

  .sm\:tw-placeholder-orange-400::placeholder {
    --placeholder-opacity: 1;
    color: #f6ad55;
    color: rgba(246, 173, 85, var(--placeholder-opacity));
  }

  .sm\:tw-placeholder-orange-500::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #ed8936;
    color: rgba(237, 137, 54, var(--placeholder-opacity));
  }

  .sm\:tw-placeholder-orange-500::placeholder {
    --placeholder-opacity: 1;
    color: #ed8936;
    color: rgba(237, 137, 54, var(--placeholder-opacity));
  }

  .sm\:tw-placeholder-orange-600::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #dd6b20;
    color: rgba(221, 107, 32, var(--placeholder-opacity));
  }

  .sm\:tw-placeholder-orange-600::placeholder {
    --placeholder-opacity: 1;
    color: #dd6b20;
    color: rgba(221, 107, 32, var(--placeholder-opacity));
  }

  .sm\:tw-placeholder-orange-700::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #c05621;
    color: rgba(192, 86, 33, var(--placeholder-opacity));
  }

  .sm\:tw-placeholder-orange-700::placeholder {
    --placeholder-opacity: 1;
    color: #c05621;
    color: rgba(192, 86, 33, var(--placeholder-opacity));
  }

  .sm\:tw-placeholder-orange-800::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #9c4221;
    color: rgba(156, 66, 33, var(--placeholder-opacity));
  }

  .sm\:tw-placeholder-orange-800::placeholder {
    --placeholder-opacity: 1;
    color: #9c4221;
    color: rgba(156, 66, 33, var(--placeholder-opacity));
  }

  .sm\:tw-placeholder-orange-900::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #7b341e;
    color: rgba(123, 52, 30, var(--placeholder-opacity));
  }

  .sm\:tw-placeholder-orange-900::placeholder {
    --placeholder-opacity: 1;
    color: #7b341e;
    color: rgba(123, 52, 30, var(--placeholder-opacity));
  }

  .sm\:tw-placeholder-yellow-100::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #fffff0;
    color: rgba(255, 255, 240, var(--placeholder-opacity));
  }

  .sm\:tw-placeholder-yellow-100::placeholder {
    --placeholder-opacity: 1;
    color: #fffff0;
    color: rgba(255, 255, 240, var(--placeholder-opacity));
  }

  .sm\:tw-placeholder-yellow-200::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #fefcbf;
    color: rgba(254, 252, 191, var(--placeholder-opacity));
  }

  .sm\:tw-placeholder-yellow-200::placeholder {
    --placeholder-opacity: 1;
    color: #fefcbf;
    color: rgba(254, 252, 191, var(--placeholder-opacity));
  }

  .sm\:tw-placeholder-yellow-300::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #faf089;
    color: rgba(250, 240, 137, var(--placeholder-opacity));
  }

  .sm\:tw-placeholder-yellow-300::placeholder {
    --placeholder-opacity: 1;
    color: #faf089;
    color: rgba(250, 240, 137, var(--placeholder-opacity));
  }

  .sm\:tw-placeholder-yellow-400::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #f6e05e;
    color: rgba(246, 224, 94, var(--placeholder-opacity));
  }

  .sm\:tw-placeholder-yellow-400::placeholder {
    --placeholder-opacity: 1;
    color: #f6e05e;
    color: rgba(246, 224, 94, var(--placeholder-opacity));
  }

  .sm\:tw-placeholder-yellow-500::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #ecc94b;
    color: rgba(236, 201, 75, var(--placeholder-opacity));
  }

  .sm\:tw-placeholder-yellow-500::placeholder {
    --placeholder-opacity: 1;
    color: #ecc94b;
    color: rgba(236, 201, 75, var(--placeholder-opacity));
  }

  .sm\:tw-placeholder-yellow-600::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #d69e2e;
    color: rgba(214, 158, 46, var(--placeholder-opacity));
  }

  .sm\:tw-placeholder-yellow-600::placeholder {
    --placeholder-opacity: 1;
    color: #d69e2e;
    color: rgba(214, 158, 46, var(--placeholder-opacity));
  }

  .sm\:tw-placeholder-yellow-700::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #b7791f;
    color: rgba(183, 121, 31, var(--placeholder-opacity));
  }

  .sm\:tw-placeholder-yellow-700::placeholder {
    --placeholder-opacity: 1;
    color: #b7791f;
    color: rgba(183, 121, 31, var(--placeholder-opacity));
  }

  .sm\:tw-placeholder-yellow-800::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #975a16;
    color: rgba(151, 90, 22, var(--placeholder-opacity));
  }

  .sm\:tw-placeholder-yellow-800::placeholder {
    --placeholder-opacity: 1;
    color: #975a16;
    color: rgba(151, 90, 22, var(--placeholder-opacity));
  }

  .sm\:tw-placeholder-yellow-900::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #744210;
    color: rgba(116, 66, 16, var(--placeholder-opacity));
  }

  .sm\:tw-placeholder-yellow-900::placeholder {
    --placeholder-opacity: 1;
    color: #744210;
    color: rgba(116, 66, 16, var(--placeholder-opacity));
  }

  .sm\:tw-placeholder-green-100::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #f0fff4;
    color: rgba(240, 255, 244, var(--placeholder-opacity));
  }

  .sm\:tw-placeholder-green-100::placeholder {
    --placeholder-opacity: 1;
    color: #f0fff4;
    color: rgba(240, 255, 244, var(--placeholder-opacity));
  }

  .sm\:tw-placeholder-green-200::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #c6f6d5;
    color: rgba(198, 246, 213, var(--placeholder-opacity));
  }

  .sm\:tw-placeholder-green-200::placeholder {
    --placeholder-opacity: 1;
    color: #c6f6d5;
    color: rgba(198, 246, 213, var(--placeholder-opacity));
  }

  .sm\:tw-placeholder-green-300::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #9ae6b4;
    color: rgba(154, 230, 180, var(--placeholder-opacity));
  }

  .sm\:tw-placeholder-green-300::placeholder {
    --placeholder-opacity: 1;
    color: #9ae6b4;
    color: rgba(154, 230, 180, var(--placeholder-opacity));
  }

  .sm\:tw-placeholder-green-400::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #68d391;
    color: rgba(104, 211, 145, var(--placeholder-opacity));
  }

  .sm\:tw-placeholder-green-400::placeholder {
    --placeholder-opacity: 1;
    color: #68d391;
    color: rgba(104, 211, 145, var(--placeholder-opacity));
  }

  .sm\:tw-placeholder-green-500::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #48bb78;
    color: rgba(72, 187, 120, var(--placeholder-opacity));
  }

  .sm\:tw-placeholder-green-500::placeholder {
    --placeholder-opacity: 1;
    color: #48bb78;
    color: rgba(72, 187, 120, var(--placeholder-opacity));
  }

  .sm\:tw-placeholder-green-600::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #38a169;
    color: rgba(56, 161, 105, var(--placeholder-opacity));
  }

  .sm\:tw-placeholder-green-600::placeholder {
    --placeholder-opacity: 1;
    color: #38a169;
    color: rgba(56, 161, 105, var(--placeholder-opacity));
  }

  .sm\:tw-placeholder-green-700::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #2f855a;
    color: rgba(47, 133, 90, var(--placeholder-opacity));
  }

  .sm\:tw-placeholder-green-700::placeholder {
    --placeholder-opacity: 1;
    color: #2f855a;
    color: rgba(47, 133, 90, var(--placeholder-opacity));
  }

  .sm\:tw-placeholder-green-800::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #276749;
    color: rgba(39, 103, 73, var(--placeholder-opacity));
  }

  .sm\:tw-placeholder-green-800::placeholder {
    --placeholder-opacity: 1;
    color: #276749;
    color: rgba(39, 103, 73, var(--placeholder-opacity));
  }

  .sm\:tw-placeholder-green-900::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #22543d;
    color: rgba(34, 84, 61, var(--placeholder-opacity));
  }

  .sm\:tw-placeholder-green-900::placeholder {
    --placeholder-opacity: 1;
    color: #22543d;
    color: rgba(34, 84, 61, var(--placeholder-opacity));
  }

  .sm\:tw-placeholder-teal-100::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #e6fffa;
    color: rgba(230, 255, 250, var(--placeholder-opacity));
  }

  .sm\:tw-placeholder-teal-100::placeholder {
    --placeholder-opacity: 1;
    color: #e6fffa;
    color: rgba(230, 255, 250, var(--placeholder-opacity));
  }

  .sm\:tw-placeholder-teal-200::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #b2f5ea;
    color: rgba(178, 245, 234, var(--placeholder-opacity));
  }

  .sm\:tw-placeholder-teal-200::placeholder {
    --placeholder-opacity: 1;
    color: #b2f5ea;
    color: rgba(178, 245, 234, var(--placeholder-opacity));
  }

  .sm\:tw-placeholder-teal-300::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #81e6d9;
    color: rgba(129, 230, 217, var(--placeholder-opacity));
  }

  .sm\:tw-placeholder-teal-300::placeholder {
    --placeholder-opacity: 1;
    color: #81e6d9;
    color: rgba(129, 230, 217, var(--placeholder-opacity));
  }

  .sm\:tw-placeholder-teal-400::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #4fd1c5;
    color: rgba(79, 209, 197, var(--placeholder-opacity));
  }

  .sm\:tw-placeholder-teal-400::placeholder {
    --placeholder-opacity: 1;
    color: #4fd1c5;
    color: rgba(79, 209, 197, var(--placeholder-opacity));
  }

  .sm\:tw-placeholder-teal-500::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #38b2ac;
    color: rgba(56, 178, 172, var(--placeholder-opacity));
  }

  .sm\:tw-placeholder-teal-500::placeholder {
    --placeholder-opacity: 1;
    color: #38b2ac;
    color: rgba(56, 178, 172, var(--placeholder-opacity));
  }

  .sm\:tw-placeholder-teal-600::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #319795;
    color: rgba(49, 151, 149, var(--placeholder-opacity));
  }

  .sm\:tw-placeholder-teal-600::placeholder {
    --placeholder-opacity: 1;
    color: #319795;
    color: rgba(49, 151, 149, var(--placeholder-opacity));
  }

  .sm\:tw-placeholder-teal-700::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #2c7a7b;
    color: rgba(44, 122, 123, var(--placeholder-opacity));
  }

  .sm\:tw-placeholder-teal-700::placeholder {
    --placeholder-opacity: 1;
    color: #2c7a7b;
    color: rgba(44, 122, 123, var(--placeholder-opacity));
  }

  .sm\:tw-placeholder-teal-800::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #285e61;
    color: rgba(40, 94, 97, var(--placeholder-opacity));
  }

  .sm\:tw-placeholder-teal-800::placeholder {
    --placeholder-opacity: 1;
    color: #285e61;
    color: rgba(40, 94, 97, var(--placeholder-opacity));
  }

  .sm\:tw-placeholder-teal-900::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #234e52;
    color: rgba(35, 78, 82, var(--placeholder-opacity));
  }

  .sm\:tw-placeholder-teal-900::placeholder {
    --placeholder-opacity: 1;
    color: #234e52;
    color: rgba(35, 78, 82, var(--placeholder-opacity));
  }

  .sm\:tw-placeholder-blue-100::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #ebf8ff;
    color: rgba(235, 248, 255, var(--placeholder-opacity));
  }

  .sm\:tw-placeholder-blue-100::placeholder {
    --placeholder-opacity: 1;
    color: #ebf8ff;
    color: rgba(235, 248, 255, var(--placeholder-opacity));
  }

  .sm\:tw-placeholder-blue-200::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #bee3f8;
    color: rgba(190, 227, 248, var(--placeholder-opacity));
  }

  .sm\:tw-placeholder-blue-200::placeholder {
    --placeholder-opacity: 1;
    color: #bee3f8;
    color: rgba(190, 227, 248, var(--placeholder-opacity));
  }

  .sm\:tw-placeholder-blue-300::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #90cdf4;
    color: rgba(144, 205, 244, var(--placeholder-opacity));
  }

  .sm\:tw-placeholder-blue-300::placeholder {
    --placeholder-opacity: 1;
    color: #90cdf4;
    color: rgba(144, 205, 244, var(--placeholder-opacity));
  }

  .sm\:tw-placeholder-blue-400::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #63b3ed;
    color: rgba(99, 179, 237, var(--placeholder-opacity));
  }

  .sm\:tw-placeholder-blue-400::placeholder {
    --placeholder-opacity: 1;
    color: #63b3ed;
    color: rgba(99, 179, 237, var(--placeholder-opacity));
  }

  .sm\:tw-placeholder-blue-500::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #4299e1;
    color: rgba(66, 153, 225, var(--placeholder-opacity));
  }

  .sm\:tw-placeholder-blue-500::placeholder {
    --placeholder-opacity: 1;
    color: #4299e1;
    color: rgba(66, 153, 225, var(--placeholder-opacity));
  }

  .sm\:tw-placeholder-blue-600::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #3182ce;
    color: rgba(49, 130, 206, var(--placeholder-opacity));
  }

  .sm\:tw-placeholder-blue-600::placeholder {
    --placeholder-opacity: 1;
    color: #3182ce;
    color: rgba(49, 130, 206, var(--placeholder-opacity));
  }

  .sm\:tw-placeholder-blue-700::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #2b6cb0;
    color: rgba(43, 108, 176, var(--placeholder-opacity));
  }

  .sm\:tw-placeholder-blue-700::placeholder {
    --placeholder-opacity: 1;
    color: #2b6cb0;
    color: rgba(43, 108, 176, var(--placeholder-opacity));
  }

  .sm\:tw-placeholder-blue-800::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #2c5282;
    color: rgba(44, 82, 130, var(--placeholder-opacity));
  }

  .sm\:tw-placeholder-blue-800::placeholder {
    --placeholder-opacity: 1;
    color: #2c5282;
    color: rgba(44, 82, 130, var(--placeholder-opacity));
  }

  .sm\:tw-placeholder-blue-900::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #2a4365;
    color: rgba(42, 67, 101, var(--placeholder-opacity));
  }

  .sm\:tw-placeholder-blue-900::placeholder {
    --placeholder-opacity: 1;
    color: #2a4365;
    color: rgba(42, 67, 101, var(--placeholder-opacity));
  }

  .sm\:tw-placeholder-indigo-100::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #ebf4ff;
    color: rgba(235, 244, 255, var(--placeholder-opacity));
  }

  .sm\:tw-placeholder-indigo-100::placeholder {
    --placeholder-opacity: 1;
    color: #ebf4ff;
    color: rgba(235, 244, 255, var(--placeholder-opacity));
  }

  .sm\:tw-placeholder-indigo-200::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #c3dafe;
    color: rgba(195, 218, 254, var(--placeholder-opacity));
  }

  .sm\:tw-placeholder-indigo-200::placeholder {
    --placeholder-opacity: 1;
    color: #c3dafe;
    color: rgba(195, 218, 254, var(--placeholder-opacity));
  }

  .sm\:tw-placeholder-indigo-300::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #a3bffa;
    color: rgba(163, 191, 250, var(--placeholder-opacity));
  }

  .sm\:tw-placeholder-indigo-300::placeholder {
    --placeholder-opacity: 1;
    color: #a3bffa;
    color: rgba(163, 191, 250, var(--placeholder-opacity));
  }

  .sm\:tw-placeholder-indigo-400::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #7f9cf5;
    color: rgba(127, 156, 245, var(--placeholder-opacity));
  }

  .sm\:tw-placeholder-indigo-400::placeholder {
    --placeholder-opacity: 1;
    color: #7f9cf5;
    color: rgba(127, 156, 245, var(--placeholder-opacity));
  }

  .sm\:tw-placeholder-indigo-500::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #667eea;
    color: rgba(102, 126, 234, var(--placeholder-opacity));
  }

  .sm\:tw-placeholder-indigo-500::placeholder {
    --placeholder-opacity: 1;
    color: #667eea;
    color: rgba(102, 126, 234, var(--placeholder-opacity));
  }

  .sm\:tw-placeholder-indigo-600::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #5a67d8;
    color: rgba(90, 103, 216, var(--placeholder-opacity));
  }

  .sm\:tw-placeholder-indigo-600::placeholder {
    --placeholder-opacity: 1;
    color: #5a67d8;
    color: rgba(90, 103, 216, var(--placeholder-opacity));
  }

  .sm\:tw-placeholder-indigo-700::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #4c51bf;
    color: rgba(76, 81, 191, var(--placeholder-opacity));
  }

  .sm\:tw-placeholder-indigo-700::placeholder {
    --placeholder-opacity: 1;
    color: #4c51bf;
    color: rgba(76, 81, 191, var(--placeholder-opacity));
  }

  .sm\:tw-placeholder-indigo-800::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #434190;
    color: rgba(67, 65, 144, var(--placeholder-opacity));
  }

  .sm\:tw-placeholder-indigo-800::placeholder {
    --placeholder-opacity: 1;
    color: #434190;
    color: rgba(67, 65, 144, var(--placeholder-opacity));
  }

  .sm\:tw-placeholder-indigo-900::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #3c366b;
    color: rgba(60, 54, 107, var(--placeholder-opacity));
  }

  .sm\:tw-placeholder-indigo-900::placeholder {
    --placeholder-opacity: 1;
    color: #3c366b;
    color: rgba(60, 54, 107, var(--placeholder-opacity));
  }

  .sm\:tw-placeholder-purple-100::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #faf5ff;
    color: rgba(250, 245, 255, var(--placeholder-opacity));
  }

  .sm\:tw-placeholder-purple-100::placeholder {
    --placeholder-opacity: 1;
    color: #faf5ff;
    color: rgba(250, 245, 255, var(--placeholder-opacity));
  }

  .sm\:tw-placeholder-purple-200::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #e9d8fd;
    color: rgba(233, 216, 253, var(--placeholder-opacity));
  }

  .sm\:tw-placeholder-purple-200::placeholder {
    --placeholder-opacity: 1;
    color: #e9d8fd;
    color: rgba(233, 216, 253, var(--placeholder-opacity));
  }

  .sm\:tw-placeholder-purple-300::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #d6bcfa;
    color: rgba(214, 188, 250, var(--placeholder-opacity));
  }

  .sm\:tw-placeholder-purple-300::placeholder {
    --placeholder-opacity: 1;
    color: #d6bcfa;
    color: rgba(214, 188, 250, var(--placeholder-opacity));
  }

  .sm\:tw-placeholder-purple-400::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #b794f4;
    color: rgba(183, 148, 244, var(--placeholder-opacity));
  }

  .sm\:tw-placeholder-purple-400::placeholder {
    --placeholder-opacity: 1;
    color: #b794f4;
    color: rgba(183, 148, 244, var(--placeholder-opacity));
  }

  .sm\:tw-placeholder-purple-500::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #9f7aea;
    color: rgba(159, 122, 234, var(--placeholder-opacity));
  }

  .sm\:tw-placeholder-purple-500::placeholder {
    --placeholder-opacity: 1;
    color: #9f7aea;
    color: rgba(159, 122, 234, var(--placeholder-opacity));
  }

  .sm\:tw-placeholder-purple-600::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #805ad5;
    color: rgba(128, 90, 213, var(--placeholder-opacity));
  }

  .sm\:tw-placeholder-purple-600::placeholder {
    --placeholder-opacity: 1;
    color: #805ad5;
    color: rgba(128, 90, 213, var(--placeholder-opacity));
  }

  .sm\:tw-placeholder-purple-700::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #6b46c1;
    color: rgba(107, 70, 193, var(--placeholder-opacity));
  }

  .sm\:tw-placeholder-purple-700::placeholder {
    --placeholder-opacity: 1;
    color: #6b46c1;
    color: rgba(107, 70, 193, var(--placeholder-opacity));
  }

  .sm\:tw-placeholder-purple-800::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #553c9a;
    color: rgba(85, 60, 154, var(--placeholder-opacity));
  }

  .sm\:tw-placeholder-purple-800::placeholder {
    --placeholder-opacity: 1;
    color: #553c9a;
    color: rgba(85, 60, 154, var(--placeholder-opacity));
  }

  .sm\:tw-placeholder-purple-900::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #44337a;
    color: rgba(68, 51, 122, var(--placeholder-opacity));
  }

  .sm\:tw-placeholder-purple-900::placeholder {
    --placeholder-opacity: 1;
    color: #44337a;
    color: rgba(68, 51, 122, var(--placeholder-opacity));
  }

  .sm\:tw-placeholder-pink-100::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #fff5f7;
    color: rgba(255, 245, 247, var(--placeholder-opacity));
  }

  .sm\:tw-placeholder-pink-100::placeholder {
    --placeholder-opacity: 1;
    color: #fff5f7;
    color: rgba(255, 245, 247, var(--placeholder-opacity));
  }

  .sm\:tw-placeholder-pink-200::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #fed7e2;
    color: rgba(254, 215, 226, var(--placeholder-opacity));
  }

  .sm\:tw-placeholder-pink-200::placeholder {
    --placeholder-opacity: 1;
    color: #fed7e2;
    color: rgba(254, 215, 226, var(--placeholder-opacity));
  }

  .sm\:tw-placeholder-pink-300::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #fbb6ce;
    color: rgba(251, 182, 206, var(--placeholder-opacity));
  }

  .sm\:tw-placeholder-pink-300::placeholder {
    --placeholder-opacity: 1;
    color: #fbb6ce;
    color: rgba(251, 182, 206, var(--placeholder-opacity));
  }

  .sm\:tw-placeholder-pink-400::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #f687b3;
    color: rgba(246, 135, 179, var(--placeholder-opacity));
  }

  .sm\:tw-placeholder-pink-400::placeholder {
    --placeholder-opacity: 1;
    color: #f687b3;
    color: rgba(246, 135, 179, var(--placeholder-opacity));
  }

  .sm\:tw-placeholder-pink-500::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #ed64a6;
    color: rgba(237, 100, 166, var(--placeholder-opacity));
  }

  .sm\:tw-placeholder-pink-500::placeholder {
    --placeholder-opacity: 1;
    color: #ed64a6;
    color: rgba(237, 100, 166, var(--placeholder-opacity));
  }

  .sm\:tw-placeholder-pink-600::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #d53f8c;
    color: rgba(213, 63, 140, var(--placeholder-opacity));
  }

  .sm\:tw-placeholder-pink-600::placeholder {
    --placeholder-opacity: 1;
    color: #d53f8c;
    color: rgba(213, 63, 140, var(--placeholder-opacity));
  }

  .sm\:tw-placeholder-pink-700::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #b83280;
    color: rgba(184, 50, 128, var(--placeholder-opacity));
  }

  .sm\:tw-placeholder-pink-700::placeholder {
    --placeholder-opacity: 1;
    color: #b83280;
    color: rgba(184, 50, 128, var(--placeholder-opacity));
  }

  .sm\:tw-placeholder-pink-800::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #97266d;
    color: rgba(151, 38, 109, var(--placeholder-opacity));
  }

  .sm\:tw-placeholder-pink-800::placeholder {
    --placeholder-opacity: 1;
    color: #97266d;
    color: rgba(151, 38, 109, var(--placeholder-opacity));
  }

  .sm\:tw-placeholder-pink-900::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #702459;
    color: rgba(112, 36, 89, var(--placeholder-opacity));
  }

  .sm\:tw-placeholder-pink-900::placeholder {
    --placeholder-opacity: 1;
    color: #702459;
    color: rgba(112, 36, 89, var(--placeholder-opacity));
  }

  .sm\:focus\:tw-placeholder-transparent:focus::-moz-placeholder {
    color: transparent;
  }

  .sm\:focus\:tw-placeholder-transparent:focus::placeholder {
    color: transparent;
  }

  .sm\:focus\:tw-placeholder-current:focus::-moz-placeholder {
    color: currentColor;
  }

  .sm\:focus\:tw-placeholder-current:focus::placeholder {
    color: currentColor;
  }

  .sm\:focus\:tw-placeholder-black:focus::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #000;
    color: rgba(0, 0, 0, var(--placeholder-opacity));
  }

  .sm\:focus\:tw-placeholder-black:focus::placeholder {
    --placeholder-opacity: 1;
    color: #000;
    color: rgba(0, 0, 0, var(--placeholder-opacity));
  }

  .sm\:focus\:tw-placeholder-white:focus::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #fff;
    color: rgba(255, 255, 255, var(--placeholder-opacity));
  }

  .sm\:focus\:tw-placeholder-white:focus::placeholder {
    --placeholder-opacity: 1;
    color: #fff;
    color: rgba(255, 255, 255, var(--placeholder-opacity));
  }

  .sm\:focus\:tw-placeholder-gray-100:focus::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #f7fafc;
    color: rgba(247, 250, 252, var(--placeholder-opacity));
  }

  .sm\:focus\:tw-placeholder-gray-100:focus::placeholder {
    --placeholder-opacity: 1;
    color: #f7fafc;
    color: rgba(247, 250, 252, var(--placeholder-opacity));
  }

  .sm\:focus\:tw-placeholder-gray-200:focus::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #edf2f7;
    color: rgba(237, 242, 247, var(--placeholder-opacity));
  }

  .sm\:focus\:tw-placeholder-gray-200:focus::placeholder {
    --placeholder-opacity: 1;
    color: #edf2f7;
    color: rgba(237, 242, 247, var(--placeholder-opacity));
  }

  .sm\:focus\:tw-placeholder-gray-300:focus::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #e2e8f0;
    color: rgba(226, 232, 240, var(--placeholder-opacity));
  }

  .sm\:focus\:tw-placeholder-gray-300:focus::placeholder {
    --placeholder-opacity: 1;
    color: #e2e8f0;
    color: rgba(226, 232, 240, var(--placeholder-opacity));
  }

  .sm\:focus\:tw-placeholder-gray-400:focus::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #cbd5e0;
    color: rgba(203, 213, 224, var(--placeholder-opacity));
  }

  .sm\:focus\:tw-placeholder-gray-400:focus::placeholder {
    --placeholder-opacity: 1;
    color: #cbd5e0;
    color: rgba(203, 213, 224, var(--placeholder-opacity));
  }

  .sm\:focus\:tw-placeholder-gray-500:focus::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #a0aec0;
    color: rgba(160, 174, 192, var(--placeholder-opacity));
  }

  .sm\:focus\:tw-placeholder-gray-500:focus::placeholder {
    --placeholder-opacity: 1;
    color: #a0aec0;
    color: rgba(160, 174, 192, var(--placeholder-opacity));
  }

  .sm\:focus\:tw-placeholder-gray-600:focus::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #718096;
    color: rgba(113, 128, 150, var(--placeholder-opacity));
  }

  .sm\:focus\:tw-placeholder-gray-600:focus::placeholder {
    --placeholder-opacity: 1;
    color: #718096;
    color: rgba(113, 128, 150, var(--placeholder-opacity));
  }

  .sm\:focus\:tw-placeholder-gray-700:focus::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #4a5568;
    color: rgba(74, 85, 104, var(--placeholder-opacity));
  }

  .sm\:focus\:tw-placeholder-gray-700:focus::placeholder {
    --placeholder-opacity: 1;
    color: #4a5568;
    color: rgba(74, 85, 104, var(--placeholder-opacity));
  }

  .sm\:focus\:tw-placeholder-gray-800:focus::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #2d3748;
    color: rgba(45, 55, 72, var(--placeholder-opacity));
  }

  .sm\:focus\:tw-placeholder-gray-800:focus::placeholder {
    --placeholder-opacity: 1;
    color: #2d3748;
    color: rgba(45, 55, 72, var(--placeholder-opacity));
  }

  .sm\:focus\:tw-placeholder-gray-900:focus::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #1a202c;
    color: rgba(26, 32, 44, var(--placeholder-opacity));
  }

  .sm\:focus\:tw-placeholder-gray-900:focus::placeholder {
    --placeholder-opacity: 1;
    color: #1a202c;
    color: rgba(26, 32, 44, var(--placeholder-opacity));
  }

  .sm\:focus\:tw-placeholder-red-100:focus::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #fff5f5;
    color: rgba(255, 245, 245, var(--placeholder-opacity));
  }

  .sm\:focus\:tw-placeholder-red-100:focus::placeholder {
    --placeholder-opacity: 1;
    color: #fff5f5;
    color: rgba(255, 245, 245, var(--placeholder-opacity));
  }

  .sm\:focus\:tw-placeholder-red-200:focus::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #fed7d7;
    color: rgba(254, 215, 215, var(--placeholder-opacity));
  }

  .sm\:focus\:tw-placeholder-red-200:focus::placeholder {
    --placeholder-opacity: 1;
    color: #fed7d7;
    color: rgba(254, 215, 215, var(--placeholder-opacity));
  }

  .sm\:focus\:tw-placeholder-red-300:focus::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #feb2b2;
    color: rgba(254, 178, 178, var(--placeholder-opacity));
  }

  .sm\:focus\:tw-placeholder-red-300:focus::placeholder {
    --placeholder-opacity: 1;
    color: #feb2b2;
    color: rgba(254, 178, 178, var(--placeholder-opacity));
  }

  .sm\:focus\:tw-placeholder-red-400:focus::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #fc8181;
    color: rgba(252, 129, 129, var(--placeholder-opacity));
  }

  .sm\:focus\:tw-placeholder-red-400:focus::placeholder {
    --placeholder-opacity: 1;
    color: #fc8181;
    color: rgba(252, 129, 129, var(--placeholder-opacity));
  }

  .sm\:focus\:tw-placeholder-red-500:focus::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #f56565;
    color: rgba(245, 101, 101, var(--placeholder-opacity));
  }

  .sm\:focus\:tw-placeholder-red-500:focus::placeholder {
    --placeholder-opacity: 1;
    color: #f56565;
    color: rgba(245, 101, 101, var(--placeholder-opacity));
  }

  .sm\:focus\:tw-placeholder-red-600:focus::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #e53e3e;
    color: rgba(229, 62, 62, var(--placeholder-opacity));
  }

  .sm\:focus\:tw-placeholder-red-600:focus::placeholder {
    --placeholder-opacity: 1;
    color: #e53e3e;
    color: rgba(229, 62, 62, var(--placeholder-opacity));
  }

  .sm\:focus\:tw-placeholder-red-700:focus::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #c53030;
    color: rgba(197, 48, 48, var(--placeholder-opacity));
  }

  .sm\:focus\:tw-placeholder-red-700:focus::placeholder {
    --placeholder-opacity: 1;
    color: #c53030;
    color: rgba(197, 48, 48, var(--placeholder-opacity));
  }

  .sm\:focus\:tw-placeholder-red-800:focus::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #9b2c2c;
    color: rgba(155, 44, 44, var(--placeholder-opacity));
  }

  .sm\:focus\:tw-placeholder-red-800:focus::placeholder {
    --placeholder-opacity: 1;
    color: #9b2c2c;
    color: rgba(155, 44, 44, var(--placeholder-opacity));
  }

  .sm\:focus\:tw-placeholder-red-900:focus::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #742a2a;
    color: rgba(116, 42, 42, var(--placeholder-opacity));
  }

  .sm\:focus\:tw-placeholder-red-900:focus::placeholder {
    --placeholder-opacity: 1;
    color: #742a2a;
    color: rgba(116, 42, 42, var(--placeholder-opacity));
  }

  .sm\:focus\:tw-placeholder-orange-100:focus::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #fffaf0;
    color: rgba(255, 250, 240, var(--placeholder-opacity));
  }

  .sm\:focus\:tw-placeholder-orange-100:focus::placeholder {
    --placeholder-opacity: 1;
    color: #fffaf0;
    color: rgba(255, 250, 240, var(--placeholder-opacity));
  }

  .sm\:focus\:tw-placeholder-orange-200:focus::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #feebc8;
    color: rgba(254, 235, 200, var(--placeholder-opacity));
  }

  .sm\:focus\:tw-placeholder-orange-200:focus::placeholder {
    --placeholder-opacity: 1;
    color: #feebc8;
    color: rgba(254, 235, 200, var(--placeholder-opacity));
  }

  .sm\:focus\:tw-placeholder-orange-300:focus::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #fbd38d;
    color: rgba(251, 211, 141, var(--placeholder-opacity));
  }

  .sm\:focus\:tw-placeholder-orange-300:focus::placeholder {
    --placeholder-opacity: 1;
    color: #fbd38d;
    color: rgba(251, 211, 141, var(--placeholder-opacity));
  }

  .sm\:focus\:tw-placeholder-orange-400:focus::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #f6ad55;
    color: rgba(246, 173, 85, var(--placeholder-opacity));
  }

  .sm\:focus\:tw-placeholder-orange-400:focus::placeholder {
    --placeholder-opacity: 1;
    color: #f6ad55;
    color: rgba(246, 173, 85, var(--placeholder-opacity));
  }

  .sm\:focus\:tw-placeholder-orange-500:focus::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #ed8936;
    color: rgba(237, 137, 54, var(--placeholder-opacity));
  }

  .sm\:focus\:tw-placeholder-orange-500:focus::placeholder {
    --placeholder-opacity: 1;
    color: #ed8936;
    color: rgba(237, 137, 54, var(--placeholder-opacity));
  }

  .sm\:focus\:tw-placeholder-orange-600:focus::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #dd6b20;
    color: rgba(221, 107, 32, var(--placeholder-opacity));
  }

  .sm\:focus\:tw-placeholder-orange-600:focus::placeholder {
    --placeholder-opacity: 1;
    color: #dd6b20;
    color: rgba(221, 107, 32, var(--placeholder-opacity));
  }

  .sm\:focus\:tw-placeholder-orange-700:focus::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #c05621;
    color: rgba(192, 86, 33, var(--placeholder-opacity));
  }

  .sm\:focus\:tw-placeholder-orange-700:focus::placeholder {
    --placeholder-opacity: 1;
    color: #c05621;
    color: rgba(192, 86, 33, var(--placeholder-opacity));
  }

  .sm\:focus\:tw-placeholder-orange-800:focus::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #9c4221;
    color: rgba(156, 66, 33, var(--placeholder-opacity));
  }

  .sm\:focus\:tw-placeholder-orange-800:focus::placeholder {
    --placeholder-opacity: 1;
    color: #9c4221;
    color: rgba(156, 66, 33, var(--placeholder-opacity));
  }

  .sm\:focus\:tw-placeholder-orange-900:focus::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #7b341e;
    color: rgba(123, 52, 30, var(--placeholder-opacity));
  }

  .sm\:focus\:tw-placeholder-orange-900:focus::placeholder {
    --placeholder-opacity: 1;
    color: #7b341e;
    color: rgba(123, 52, 30, var(--placeholder-opacity));
  }

  .sm\:focus\:tw-placeholder-yellow-100:focus::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #fffff0;
    color: rgba(255, 255, 240, var(--placeholder-opacity));
  }

  .sm\:focus\:tw-placeholder-yellow-100:focus::placeholder {
    --placeholder-opacity: 1;
    color: #fffff0;
    color: rgba(255, 255, 240, var(--placeholder-opacity));
  }

  .sm\:focus\:tw-placeholder-yellow-200:focus::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #fefcbf;
    color: rgba(254, 252, 191, var(--placeholder-opacity));
  }

  .sm\:focus\:tw-placeholder-yellow-200:focus::placeholder {
    --placeholder-opacity: 1;
    color: #fefcbf;
    color: rgba(254, 252, 191, var(--placeholder-opacity));
  }

  .sm\:focus\:tw-placeholder-yellow-300:focus::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #faf089;
    color: rgba(250, 240, 137, var(--placeholder-opacity));
  }

  .sm\:focus\:tw-placeholder-yellow-300:focus::placeholder {
    --placeholder-opacity: 1;
    color: #faf089;
    color: rgba(250, 240, 137, var(--placeholder-opacity));
  }

  .sm\:focus\:tw-placeholder-yellow-400:focus::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #f6e05e;
    color: rgba(246, 224, 94, var(--placeholder-opacity));
  }

  .sm\:focus\:tw-placeholder-yellow-400:focus::placeholder {
    --placeholder-opacity: 1;
    color: #f6e05e;
    color: rgba(246, 224, 94, var(--placeholder-opacity));
  }

  .sm\:focus\:tw-placeholder-yellow-500:focus::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #ecc94b;
    color: rgba(236, 201, 75, var(--placeholder-opacity));
  }

  .sm\:focus\:tw-placeholder-yellow-500:focus::placeholder {
    --placeholder-opacity: 1;
    color: #ecc94b;
    color: rgba(236, 201, 75, var(--placeholder-opacity));
  }

  .sm\:focus\:tw-placeholder-yellow-600:focus::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #d69e2e;
    color: rgba(214, 158, 46, var(--placeholder-opacity));
  }

  .sm\:focus\:tw-placeholder-yellow-600:focus::placeholder {
    --placeholder-opacity: 1;
    color: #d69e2e;
    color: rgba(214, 158, 46, var(--placeholder-opacity));
  }

  .sm\:focus\:tw-placeholder-yellow-700:focus::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #b7791f;
    color: rgba(183, 121, 31, var(--placeholder-opacity));
  }

  .sm\:focus\:tw-placeholder-yellow-700:focus::placeholder {
    --placeholder-opacity: 1;
    color: #b7791f;
    color: rgba(183, 121, 31, var(--placeholder-opacity));
  }

  .sm\:focus\:tw-placeholder-yellow-800:focus::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #975a16;
    color: rgba(151, 90, 22, var(--placeholder-opacity));
  }

  .sm\:focus\:tw-placeholder-yellow-800:focus::placeholder {
    --placeholder-opacity: 1;
    color: #975a16;
    color: rgba(151, 90, 22, var(--placeholder-opacity));
  }

  .sm\:focus\:tw-placeholder-yellow-900:focus::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #744210;
    color: rgba(116, 66, 16, var(--placeholder-opacity));
  }

  .sm\:focus\:tw-placeholder-yellow-900:focus::placeholder {
    --placeholder-opacity: 1;
    color: #744210;
    color: rgba(116, 66, 16, var(--placeholder-opacity));
  }

  .sm\:focus\:tw-placeholder-green-100:focus::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #f0fff4;
    color: rgba(240, 255, 244, var(--placeholder-opacity));
  }

  .sm\:focus\:tw-placeholder-green-100:focus::placeholder {
    --placeholder-opacity: 1;
    color: #f0fff4;
    color: rgba(240, 255, 244, var(--placeholder-opacity));
  }

  .sm\:focus\:tw-placeholder-green-200:focus::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #c6f6d5;
    color: rgba(198, 246, 213, var(--placeholder-opacity));
  }

  .sm\:focus\:tw-placeholder-green-200:focus::placeholder {
    --placeholder-opacity: 1;
    color: #c6f6d5;
    color: rgba(198, 246, 213, var(--placeholder-opacity));
  }

  .sm\:focus\:tw-placeholder-green-300:focus::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #9ae6b4;
    color: rgba(154, 230, 180, var(--placeholder-opacity));
  }

  .sm\:focus\:tw-placeholder-green-300:focus::placeholder {
    --placeholder-opacity: 1;
    color: #9ae6b4;
    color: rgba(154, 230, 180, var(--placeholder-opacity));
  }

  .sm\:focus\:tw-placeholder-green-400:focus::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #68d391;
    color: rgba(104, 211, 145, var(--placeholder-opacity));
  }

  .sm\:focus\:tw-placeholder-green-400:focus::placeholder {
    --placeholder-opacity: 1;
    color: #68d391;
    color: rgba(104, 211, 145, var(--placeholder-opacity));
  }

  .sm\:focus\:tw-placeholder-green-500:focus::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #48bb78;
    color: rgba(72, 187, 120, var(--placeholder-opacity));
  }

  .sm\:focus\:tw-placeholder-green-500:focus::placeholder {
    --placeholder-opacity: 1;
    color: #48bb78;
    color: rgba(72, 187, 120, var(--placeholder-opacity));
  }

  .sm\:focus\:tw-placeholder-green-600:focus::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #38a169;
    color: rgba(56, 161, 105, var(--placeholder-opacity));
  }

  .sm\:focus\:tw-placeholder-green-600:focus::placeholder {
    --placeholder-opacity: 1;
    color: #38a169;
    color: rgba(56, 161, 105, var(--placeholder-opacity));
  }

  .sm\:focus\:tw-placeholder-green-700:focus::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #2f855a;
    color: rgba(47, 133, 90, var(--placeholder-opacity));
  }

  .sm\:focus\:tw-placeholder-green-700:focus::placeholder {
    --placeholder-opacity: 1;
    color: #2f855a;
    color: rgba(47, 133, 90, var(--placeholder-opacity));
  }

  .sm\:focus\:tw-placeholder-green-800:focus::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #276749;
    color: rgba(39, 103, 73, var(--placeholder-opacity));
  }

  .sm\:focus\:tw-placeholder-green-800:focus::placeholder {
    --placeholder-opacity: 1;
    color: #276749;
    color: rgba(39, 103, 73, var(--placeholder-opacity));
  }

  .sm\:focus\:tw-placeholder-green-900:focus::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #22543d;
    color: rgba(34, 84, 61, var(--placeholder-opacity));
  }

  .sm\:focus\:tw-placeholder-green-900:focus::placeholder {
    --placeholder-opacity: 1;
    color: #22543d;
    color: rgba(34, 84, 61, var(--placeholder-opacity));
  }

  .sm\:focus\:tw-placeholder-teal-100:focus::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #e6fffa;
    color: rgba(230, 255, 250, var(--placeholder-opacity));
  }

  .sm\:focus\:tw-placeholder-teal-100:focus::placeholder {
    --placeholder-opacity: 1;
    color: #e6fffa;
    color: rgba(230, 255, 250, var(--placeholder-opacity));
  }

  .sm\:focus\:tw-placeholder-teal-200:focus::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #b2f5ea;
    color: rgba(178, 245, 234, var(--placeholder-opacity));
  }

  .sm\:focus\:tw-placeholder-teal-200:focus::placeholder {
    --placeholder-opacity: 1;
    color: #b2f5ea;
    color: rgba(178, 245, 234, var(--placeholder-opacity));
  }

  .sm\:focus\:tw-placeholder-teal-300:focus::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #81e6d9;
    color: rgba(129, 230, 217, var(--placeholder-opacity));
  }

  .sm\:focus\:tw-placeholder-teal-300:focus::placeholder {
    --placeholder-opacity: 1;
    color: #81e6d9;
    color: rgba(129, 230, 217, var(--placeholder-opacity));
  }

  .sm\:focus\:tw-placeholder-teal-400:focus::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #4fd1c5;
    color: rgba(79, 209, 197, var(--placeholder-opacity));
  }

  .sm\:focus\:tw-placeholder-teal-400:focus::placeholder {
    --placeholder-opacity: 1;
    color: #4fd1c5;
    color: rgba(79, 209, 197, var(--placeholder-opacity));
  }

  .sm\:focus\:tw-placeholder-teal-500:focus::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #38b2ac;
    color: rgba(56, 178, 172, var(--placeholder-opacity));
  }

  .sm\:focus\:tw-placeholder-teal-500:focus::placeholder {
    --placeholder-opacity: 1;
    color: #38b2ac;
    color: rgba(56, 178, 172, var(--placeholder-opacity));
  }

  .sm\:focus\:tw-placeholder-teal-600:focus::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #319795;
    color: rgba(49, 151, 149, var(--placeholder-opacity));
  }

  .sm\:focus\:tw-placeholder-teal-600:focus::placeholder {
    --placeholder-opacity: 1;
    color: #319795;
    color: rgba(49, 151, 149, var(--placeholder-opacity));
  }

  .sm\:focus\:tw-placeholder-teal-700:focus::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #2c7a7b;
    color: rgba(44, 122, 123, var(--placeholder-opacity));
  }

  .sm\:focus\:tw-placeholder-teal-700:focus::placeholder {
    --placeholder-opacity: 1;
    color: #2c7a7b;
    color: rgba(44, 122, 123, var(--placeholder-opacity));
  }

  .sm\:focus\:tw-placeholder-teal-800:focus::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #285e61;
    color: rgba(40, 94, 97, var(--placeholder-opacity));
  }

  .sm\:focus\:tw-placeholder-teal-800:focus::placeholder {
    --placeholder-opacity: 1;
    color: #285e61;
    color: rgba(40, 94, 97, var(--placeholder-opacity));
  }

  .sm\:focus\:tw-placeholder-teal-900:focus::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #234e52;
    color: rgba(35, 78, 82, var(--placeholder-opacity));
  }

  .sm\:focus\:tw-placeholder-teal-900:focus::placeholder {
    --placeholder-opacity: 1;
    color: #234e52;
    color: rgba(35, 78, 82, var(--placeholder-opacity));
  }

  .sm\:focus\:tw-placeholder-blue-100:focus::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #ebf8ff;
    color: rgba(235, 248, 255, var(--placeholder-opacity));
  }

  .sm\:focus\:tw-placeholder-blue-100:focus::placeholder {
    --placeholder-opacity: 1;
    color: #ebf8ff;
    color: rgba(235, 248, 255, var(--placeholder-opacity));
  }

  .sm\:focus\:tw-placeholder-blue-200:focus::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #bee3f8;
    color: rgba(190, 227, 248, var(--placeholder-opacity));
  }

  .sm\:focus\:tw-placeholder-blue-200:focus::placeholder {
    --placeholder-opacity: 1;
    color: #bee3f8;
    color: rgba(190, 227, 248, var(--placeholder-opacity));
  }

  .sm\:focus\:tw-placeholder-blue-300:focus::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #90cdf4;
    color: rgba(144, 205, 244, var(--placeholder-opacity));
  }

  .sm\:focus\:tw-placeholder-blue-300:focus::placeholder {
    --placeholder-opacity: 1;
    color: #90cdf4;
    color: rgba(144, 205, 244, var(--placeholder-opacity));
  }

  .sm\:focus\:tw-placeholder-blue-400:focus::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #63b3ed;
    color: rgba(99, 179, 237, var(--placeholder-opacity));
  }

  .sm\:focus\:tw-placeholder-blue-400:focus::placeholder {
    --placeholder-opacity: 1;
    color: #63b3ed;
    color: rgba(99, 179, 237, var(--placeholder-opacity));
  }

  .sm\:focus\:tw-placeholder-blue-500:focus::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #4299e1;
    color: rgba(66, 153, 225, var(--placeholder-opacity));
  }

  .sm\:focus\:tw-placeholder-blue-500:focus::placeholder {
    --placeholder-opacity: 1;
    color: #4299e1;
    color: rgba(66, 153, 225, var(--placeholder-opacity));
  }

  .sm\:focus\:tw-placeholder-blue-600:focus::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #3182ce;
    color: rgba(49, 130, 206, var(--placeholder-opacity));
  }

  .sm\:focus\:tw-placeholder-blue-600:focus::placeholder {
    --placeholder-opacity: 1;
    color: #3182ce;
    color: rgba(49, 130, 206, var(--placeholder-opacity));
  }

  .sm\:focus\:tw-placeholder-blue-700:focus::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #2b6cb0;
    color: rgba(43, 108, 176, var(--placeholder-opacity));
  }

  .sm\:focus\:tw-placeholder-blue-700:focus::placeholder {
    --placeholder-opacity: 1;
    color: #2b6cb0;
    color: rgba(43, 108, 176, var(--placeholder-opacity));
  }

  .sm\:focus\:tw-placeholder-blue-800:focus::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #2c5282;
    color: rgba(44, 82, 130, var(--placeholder-opacity));
  }

  .sm\:focus\:tw-placeholder-blue-800:focus::placeholder {
    --placeholder-opacity: 1;
    color: #2c5282;
    color: rgba(44, 82, 130, var(--placeholder-opacity));
  }

  .sm\:focus\:tw-placeholder-blue-900:focus::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #2a4365;
    color: rgba(42, 67, 101, var(--placeholder-opacity));
  }

  .sm\:focus\:tw-placeholder-blue-900:focus::placeholder {
    --placeholder-opacity: 1;
    color: #2a4365;
    color: rgba(42, 67, 101, var(--placeholder-opacity));
  }

  .sm\:focus\:tw-placeholder-indigo-100:focus::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #ebf4ff;
    color: rgba(235, 244, 255, var(--placeholder-opacity));
  }

  .sm\:focus\:tw-placeholder-indigo-100:focus::placeholder {
    --placeholder-opacity: 1;
    color: #ebf4ff;
    color: rgba(235, 244, 255, var(--placeholder-opacity));
  }

  .sm\:focus\:tw-placeholder-indigo-200:focus::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #c3dafe;
    color: rgba(195, 218, 254, var(--placeholder-opacity));
  }

  .sm\:focus\:tw-placeholder-indigo-200:focus::placeholder {
    --placeholder-opacity: 1;
    color: #c3dafe;
    color: rgba(195, 218, 254, var(--placeholder-opacity));
  }

  .sm\:focus\:tw-placeholder-indigo-300:focus::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #a3bffa;
    color: rgba(163, 191, 250, var(--placeholder-opacity));
  }

  .sm\:focus\:tw-placeholder-indigo-300:focus::placeholder {
    --placeholder-opacity: 1;
    color: #a3bffa;
    color: rgba(163, 191, 250, var(--placeholder-opacity));
  }

  .sm\:focus\:tw-placeholder-indigo-400:focus::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #7f9cf5;
    color: rgba(127, 156, 245, var(--placeholder-opacity));
  }

  .sm\:focus\:tw-placeholder-indigo-400:focus::placeholder {
    --placeholder-opacity: 1;
    color: #7f9cf5;
    color: rgba(127, 156, 245, var(--placeholder-opacity));
  }

  .sm\:focus\:tw-placeholder-indigo-500:focus::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #667eea;
    color: rgba(102, 126, 234, var(--placeholder-opacity));
  }

  .sm\:focus\:tw-placeholder-indigo-500:focus::placeholder {
    --placeholder-opacity: 1;
    color: #667eea;
    color: rgba(102, 126, 234, var(--placeholder-opacity));
  }

  .sm\:focus\:tw-placeholder-indigo-600:focus::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #5a67d8;
    color: rgba(90, 103, 216, var(--placeholder-opacity));
  }

  .sm\:focus\:tw-placeholder-indigo-600:focus::placeholder {
    --placeholder-opacity: 1;
    color: #5a67d8;
    color: rgba(90, 103, 216, var(--placeholder-opacity));
  }

  .sm\:focus\:tw-placeholder-indigo-700:focus::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #4c51bf;
    color: rgba(76, 81, 191, var(--placeholder-opacity));
  }

  .sm\:focus\:tw-placeholder-indigo-700:focus::placeholder {
    --placeholder-opacity: 1;
    color: #4c51bf;
    color: rgba(76, 81, 191, var(--placeholder-opacity));
  }

  .sm\:focus\:tw-placeholder-indigo-800:focus::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #434190;
    color: rgba(67, 65, 144, var(--placeholder-opacity));
  }

  .sm\:focus\:tw-placeholder-indigo-800:focus::placeholder {
    --placeholder-opacity: 1;
    color: #434190;
    color: rgba(67, 65, 144, var(--placeholder-opacity));
  }

  .sm\:focus\:tw-placeholder-indigo-900:focus::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #3c366b;
    color: rgba(60, 54, 107, var(--placeholder-opacity));
  }

  .sm\:focus\:tw-placeholder-indigo-900:focus::placeholder {
    --placeholder-opacity: 1;
    color: #3c366b;
    color: rgba(60, 54, 107, var(--placeholder-opacity));
  }

  .sm\:focus\:tw-placeholder-purple-100:focus::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #faf5ff;
    color: rgba(250, 245, 255, var(--placeholder-opacity));
  }

  .sm\:focus\:tw-placeholder-purple-100:focus::placeholder {
    --placeholder-opacity: 1;
    color: #faf5ff;
    color: rgba(250, 245, 255, var(--placeholder-opacity));
  }

  .sm\:focus\:tw-placeholder-purple-200:focus::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #e9d8fd;
    color: rgba(233, 216, 253, var(--placeholder-opacity));
  }

  .sm\:focus\:tw-placeholder-purple-200:focus::placeholder {
    --placeholder-opacity: 1;
    color: #e9d8fd;
    color: rgba(233, 216, 253, var(--placeholder-opacity));
  }

  .sm\:focus\:tw-placeholder-purple-300:focus::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #d6bcfa;
    color: rgba(214, 188, 250, var(--placeholder-opacity));
  }

  .sm\:focus\:tw-placeholder-purple-300:focus::placeholder {
    --placeholder-opacity: 1;
    color: #d6bcfa;
    color: rgba(214, 188, 250, var(--placeholder-opacity));
  }

  .sm\:focus\:tw-placeholder-purple-400:focus::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #b794f4;
    color: rgba(183, 148, 244, var(--placeholder-opacity));
  }

  .sm\:focus\:tw-placeholder-purple-400:focus::placeholder {
    --placeholder-opacity: 1;
    color: #b794f4;
    color: rgba(183, 148, 244, var(--placeholder-opacity));
  }

  .sm\:focus\:tw-placeholder-purple-500:focus::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #9f7aea;
    color: rgba(159, 122, 234, var(--placeholder-opacity));
  }

  .sm\:focus\:tw-placeholder-purple-500:focus::placeholder {
    --placeholder-opacity: 1;
    color: #9f7aea;
    color: rgba(159, 122, 234, var(--placeholder-opacity));
  }

  .sm\:focus\:tw-placeholder-purple-600:focus::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #805ad5;
    color: rgba(128, 90, 213, var(--placeholder-opacity));
  }

  .sm\:focus\:tw-placeholder-purple-600:focus::placeholder {
    --placeholder-opacity: 1;
    color: #805ad5;
    color: rgba(128, 90, 213, var(--placeholder-opacity));
  }

  .sm\:focus\:tw-placeholder-purple-700:focus::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #6b46c1;
    color: rgba(107, 70, 193, var(--placeholder-opacity));
  }

  .sm\:focus\:tw-placeholder-purple-700:focus::placeholder {
    --placeholder-opacity: 1;
    color: #6b46c1;
    color: rgba(107, 70, 193, var(--placeholder-opacity));
  }

  .sm\:focus\:tw-placeholder-purple-800:focus::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #553c9a;
    color: rgba(85, 60, 154, var(--placeholder-opacity));
  }

  .sm\:focus\:tw-placeholder-purple-800:focus::placeholder {
    --placeholder-opacity: 1;
    color: #553c9a;
    color: rgba(85, 60, 154, var(--placeholder-opacity));
  }

  .sm\:focus\:tw-placeholder-purple-900:focus::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #44337a;
    color: rgba(68, 51, 122, var(--placeholder-opacity));
  }

  .sm\:focus\:tw-placeholder-purple-900:focus::placeholder {
    --placeholder-opacity: 1;
    color: #44337a;
    color: rgba(68, 51, 122, var(--placeholder-opacity));
  }

  .sm\:focus\:tw-placeholder-pink-100:focus::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #fff5f7;
    color: rgba(255, 245, 247, var(--placeholder-opacity));
  }

  .sm\:focus\:tw-placeholder-pink-100:focus::placeholder {
    --placeholder-opacity: 1;
    color: #fff5f7;
    color: rgba(255, 245, 247, var(--placeholder-opacity));
  }

  .sm\:focus\:tw-placeholder-pink-200:focus::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #fed7e2;
    color: rgba(254, 215, 226, var(--placeholder-opacity));
  }

  .sm\:focus\:tw-placeholder-pink-200:focus::placeholder {
    --placeholder-opacity: 1;
    color: #fed7e2;
    color: rgba(254, 215, 226, var(--placeholder-opacity));
  }

  .sm\:focus\:tw-placeholder-pink-300:focus::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #fbb6ce;
    color: rgba(251, 182, 206, var(--placeholder-opacity));
  }

  .sm\:focus\:tw-placeholder-pink-300:focus::placeholder {
    --placeholder-opacity: 1;
    color: #fbb6ce;
    color: rgba(251, 182, 206, var(--placeholder-opacity));
  }

  .sm\:focus\:tw-placeholder-pink-400:focus::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #f687b3;
    color: rgba(246, 135, 179, var(--placeholder-opacity));
  }

  .sm\:focus\:tw-placeholder-pink-400:focus::placeholder {
    --placeholder-opacity: 1;
    color: #f687b3;
    color: rgba(246, 135, 179, var(--placeholder-opacity));
  }

  .sm\:focus\:tw-placeholder-pink-500:focus::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #ed64a6;
    color: rgba(237, 100, 166, var(--placeholder-opacity));
  }

  .sm\:focus\:tw-placeholder-pink-500:focus::placeholder {
    --placeholder-opacity: 1;
    color: #ed64a6;
    color: rgba(237, 100, 166, var(--placeholder-opacity));
  }

  .sm\:focus\:tw-placeholder-pink-600:focus::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #d53f8c;
    color: rgba(213, 63, 140, var(--placeholder-opacity));
  }

  .sm\:focus\:tw-placeholder-pink-600:focus::placeholder {
    --placeholder-opacity: 1;
    color: #d53f8c;
    color: rgba(213, 63, 140, var(--placeholder-opacity));
  }

  .sm\:focus\:tw-placeholder-pink-700:focus::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #b83280;
    color: rgba(184, 50, 128, var(--placeholder-opacity));
  }

  .sm\:focus\:tw-placeholder-pink-700:focus::placeholder {
    --placeholder-opacity: 1;
    color: #b83280;
    color: rgba(184, 50, 128, var(--placeholder-opacity));
  }

  .sm\:focus\:tw-placeholder-pink-800:focus::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #97266d;
    color: rgba(151, 38, 109, var(--placeholder-opacity));
  }

  .sm\:focus\:tw-placeholder-pink-800:focus::placeholder {
    --placeholder-opacity: 1;
    color: #97266d;
    color: rgba(151, 38, 109, var(--placeholder-opacity));
  }

  .sm\:focus\:tw-placeholder-pink-900:focus::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #702459;
    color: rgba(112, 36, 89, var(--placeholder-opacity));
  }

  .sm\:focus\:tw-placeholder-pink-900:focus::placeholder {
    --placeholder-opacity: 1;
    color: #702459;
    color: rgba(112, 36, 89, var(--placeholder-opacity));
  }

  .sm\:tw-placeholder-opacity-0::-moz-placeholder {
    --placeholder-opacity: 0;
  }

  .sm\:tw-placeholder-opacity-0::placeholder {
    --placeholder-opacity: 0;
  }

  .sm\:tw-placeholder-opacity-25::-moz-placeholder {
    --placeholder-opacity: 0.25;
  }

  .sm\:tw-placeholder-opacity-25::placeholder {
    --placeholder-opacity: 0.25;
  }

  .sm\:tw-placeholder-opacity-50::-moz-placeholder {
    --placeholder-opacity: 0.5;
  }

  .sm\:tw-placeholder-opacity-50::placeholder {
    --placeholder-opacity: 0.5;
  }

  .sm\:tw-placeholder-opacity-75::-moz-placeholder {
    --placeholder-opacity: 0.75;
  }

  .sm\:tw-placeholder-opacity-75::placeholder {
    --placeholder-opacity: 0.75;
  }

  .sm\:tw-placeholder-opacity-100::-moz-placeholder {
    --placeholder-opacity: 1;
  }

  .sm\:tw-placeholder-opacity-100::placeholder {
    --placeholder-opacity: 1;
  }

  .sm\:focus\:tw-placeholder-opacity-0:focus::-moz-placeholder {
    --placeholder-opacity: 0;
  }

  .sm\:focus\:tw-placeholder-opacity-0:focus::placeholder {
    --placeholder-opacity: 0;
  }

  .sm\:focus\:tw-placeholder-opacity-25:focus::-moz-placeholder {
    --placeholder-opacity: 0.25;
  }

  .sm\:focus\:tw-placeholder-opacity-25:focus::placeholder {
    --placeholder-opacity: 0.25;
  }

  .sm\:focus\:tw-placeholder-opacity-50:focus::-moz-placeholder {
    --placeholder-opacity: 0.5;
  }

  .sm\:focus\:tw-placeholder-opacity-50:focus::placeholder {
    --placeholder-opacity: 0.5;
  }

  .sm\:focus\:tw-placeholder-opacity-75:focus::-moz-placeholder {
    --placeholder-opacity: 0.75;
  }

  .sm\:focus\:tw-placeholder-opacity-75:focus::placeholder {
    --placeholder-opacity: 0.75;
  }

  .sm\:focus\:tw-placeholder-opacity-100:focus::-moz-placeholder {
    --placeholder-opacity: 1;
  }

  .sm\:focus\:tw-placeholder-opacity-100:focus::placeholder {
    --placeholder-opacity: 1;
  }

  .sm\:tw-pointer-events-none {
    pointer-events: none;
  }

  .sm\:tw-pointer-events-auto {
    pointer-events: auto;
  }

  .sm\:tw-static {
    position: static;
  }

  .sm\:tw-fixed {
    position: fixed;
  }

  .sm\:tw-absolute {
    position: absolute;
  }

  .sm\:tw-relative {
    position: relative;
  }

  .sm\:tw-sticky {
    position: sticky;
  }

  .sm\:tw-inset-0 {
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }

  .sm\:tw-inset-auto {
    top: auto;
    right: auto;
    bottom: auto;
    left: auto;
  }

  .sm\:tw-inset-y-0 {
    top: 0;
    bottom: 0;
  }

  .sm\:tw-inset-x-0 {
    right: 0;
    left: 0;
  }

  .sm\:tw-inset-y-auto {
    top: auto;
    bottom: auto;
  }

  .sm\:tw-inset-x-auto {
    right: auto;
    left: auto;
  }

  .sm\:tw-top-0 {
    top: 0;
  }

  .sm\:tw-right-0 {
    right: 0;
  }

  .sm\:tw-bottom-0 {
    bottom: 0;
  }

  .sm\:tw-left-0 {
    left: 0;
  }

  .sm\:tw-top-auto {
    top: auto;
  }

  .sm\:tw-right-auto {
    right: auto;
  }

  .sm\:tw-bottom-auto {
    bottom: auto;
  }

  .sm\:tw-left-auto {
    left: auto;
  }

  .sm\:tw-resize-none {
    resize: none;
  }

  .sm\:tw-resize-y {
    resize: vertical;
  }

  .sm\:tw-resize-x {
    resize: horizontal;
  }

  .sm\:tw-resize {
    resize: both;
  }

  .sm\:tw-shadow-xs {
    box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.05);
  }

  .sm\:tw-shadow-sm {
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
  }

  .sm\:tw-shadow {
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
  }

  .sm\:tw-shadow-md {
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
  }

  .sm\:tw-shadow-lg {
    box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
  }

  .sm\:tw-shadow-xl {
    box-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04);
  }

  .sm\:tw-shadow-2xl {
    box-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25);
  }

  .sm\:tw-shadow-inner {
    box-shadow: inset 0 2px 4px 0 rgba(0, 0, 0, 0.06);
  }

  .sm\:tw-shadow-outline {
    box-shadow: 0 0 0 3px rgba(66, 153, 225, 0.5);
  }

  .sm\:tw-shadow-none {
    box-shadow: none;
  }

  .sm\:hover\:tw-shadow-xs:hover {
    box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.05);
  }

  .sm\:hover\:tw-shadow-sm:hover {
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
  }

  .sm\:hover\:tw-shadow:hover {
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
  }

  .sm\:hover\:tw-shadow-md:hover {
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
  }

  .sm\:hover\:tw-shadow-lg:hover {
    box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
  }

  .sm\:hover\:tw-shadow-xl:hover {
    box-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04);
  }

  .sm\:hover\:tw-shadow-2xl:hover {
    box-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25);
  }

  .sm\:hover\:tw-shadow-inner:hover {
    box-shadow: inset 0 2px 4px 0 rgba(0, 0, 0, 0.06);
  }

  .sm\:hover\:tw-shadow-outline:hover {
    box-shadow: 0 0 0 3px rgba(66, 153, 225, 0.5);
  }

  .sm\:hover\:tw-shadow-none:hover {
    box-shadow: none;
  }

  .sm\:focus\:tw-shadow-xs:focus {
    box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.05);
  }

  .sm\:focus\:tw-shadow-sm:focus {
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
  }

  .sm\:focus\:tw-shadow:focus {
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
  }

  .sm\:focus\:tw-shadow-md:focus {
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
  }

  .sm\:focus\:tw-shadow-lg:focus {
    box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
  }

  .sm\:focus\:tw-shadow-xl:focus {
    box-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04);
  }

  .sm\:focus\:tw-shadow-2xl:focus {
    box-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25);
  }

  .sm\:focus\:tw-shadow-inner:focus {
    box-shadow: inset 0 2px 4px 0 rgba(0, 0, 0, 0.06);
  }

  .sm\:focus\:tw-shadow-outline:focus {
    box-shadow: 0 0 0 3px rgba(66, 153, 225, 0.5);
  }

  .sm\:focus\:tw-shadow-none:focus {
    box-shadow: none;
  }

  .sm\:tw-fill-current {
    fill: currentColor;
  }

  .sm\:tw-stroke-current {
    stroke: currentColor;
  }

  .sm\:tw-stroke-0 {
    stroke-width: 0;
  }

  .sm\:tw-stroke-1 {
    stroke-width: 1;
  }

  .sm\:tw-stroke-2 {
    stroke-width: 2;
  }

  .sm\:tw-table-auto {
    table-layout: auto;
  }

  .sm\:tw-table-fixed {
    table-layout: fixed;
  }

  .sm\:tw-text-left {
    text-align: left;
  }

  .sm\:tw-text-center {
    text-align: center;
  }

  .sm\:tw-text-right {
    text-align: right;
  }

  .sm\:tw-text-justify {
    text-align: justify;
  }

  .sm\:tw-text-transparent {
    color: transparent;
  }

  .sm\:tw-text-current {
    color: currentColor;
  }

  .sm\:tw-text-black {
    --text-opacity: 1;
    color: #000;
    color: rgba(0, 0, 0, var(--text-opacity));
  }

  .sm\:tw-text-white {
    --text-opacity: 1;
    color: #fff;
    color: rgba(255, 255, 255, var(--text-opacity));
  }

  .sm\:tw-text-gray-100 {
    --text-opacity: 1;
    color: #f7fafc;
    color: rgba(247, 250, 252, var(--text-opacity));
  }

  .sm\:tw-text-gray-200 {
    --text-opacity: 1;
    color: #edf2f7;
    color: rgba(237, 242, 247, var(--text-opacity));
  }

  .sm\:tw-text-gray-300 {
    --text-opacity: 1;
    color: #e2e8f0;
    color: rgba(226, 232, 240, var(--text-opacity));
  }

  .sm\:tw-text-gray-400 {
    --text-opacity: 1;
    color: #cbd5e0;
    color: rgba(203, 213, 224, var(--text-opacity));
  }

  .sm\:tw-text-gray-500 {
    --text-opacity: 1;
    color: #a0aec0;
    color: rgba(160, 174, 192, var(--text-opacity));
  }

  .sm\:tw-text-gray-600 {
    --text-opacity: 1;
    color: #718096;
    color: rgba(113, 128, 150, var(--text-opacity));
  }

  .sm\:tw-text-gray-700 {
    --text-opacity: 1;
    color: #4a5568;
    color: rgba(74, 85, 104, var(--text-opacity));
  }

  .sm\:tw-text-gray-800 {
    --text-opacity: 1;
    color: #2d3748;
    color: rgba(45, 55, 72, var(--text-opacity));
  }

  .sm\:tw-text-gray-900 {
    --text-opacity: 1;
    color: #1a202c;
    color: rgba(26, 32, 44, var(--text-opacity));
  }

  .sm\:tw-text-red-100 {
    --text-opacity: 1;
    color: #fff5f5;
    color: rgba(255, 245, 245, var(--text-opacity));
  }

  .sm\:tw-text-red-200 {
    --text-opacity: 1;
    color: #fed7d7;
    color: rgba(254, 215, 215, var(--text-opacity));
  }

  .sm\:tw-text-red-300 {
    --text-opacity: 1;
    color: #feb2b2;
    color: rgba(254, 178, 178, var(--text-opacity));
  }

  .sm\:tw-text-red-400 {
    --text-opacity: 1;
    color: #fc8181;
    color: rgba(252, 129, 129, var(--text-opacity));
  }

  .sm\:tw-text-red-500 {
    --text-opacity: 1;
    color: #f56565;
    color: rgba(245, 101, 101, var(--text-opacity));
  }

  .sm\:tw-text-red-600 {
    --text-opacity: 1;
    color: #e53e3e;
    color: rgba(229, 62, 62, var(--text-opacity));
  }

  .sm\:tw-text-red-700 {
    --text-opacity: 1;
    color: #c53030;
    color: rgba(197, 48, 48, var(--text-opacity));
  }

  .sm\:tw-text-red-800 {
    --text-opacity: 1;
    color: #9b2c2c;
    color: rgba(155, 44, 44, var(--text-opacity));
  }

  .sm\:tw-text-red-900 {
    --text-opacity: 1;
    color: #742a2a;
    color: rgba(116, 42, 42, var(--text-opacity));
  }

  .sm\:tw-text-orange-100 {
    --text-opacity: 1;
    color: #fffaf0;
    color: rgba(255, 250, 240, var(--text-opacity));
  }

  .sm\:tw-text-orange-200 {
    --text-opacity: 1;
    color: #feebc8;
    color: rgba(254, 235, 200, var(--text-opacity));
  }

  .sm\:tw-text-orange-300 {
    --text-opacity: 1;
    color: #fbd38d;
    color: rgba(251, 211, 141, var(--text-opacity));
  }

  .sm\:tw-text-orange-400 {
    --text-opacity: 1;
    color: #f6ad55;
    color: rgba(246, 173, 85, var(--text-opacity));
  }

  .sm\:tw-text-orange-500 {
    --text-opacity: 1;
    color: #ed8936;
    color: rgba(237, 137, 54, var(--text-opacity));
  }

  .sm\:tw-text-orange-600 {
    --text-opacity: 1;
    color: #dd6b20;
    color: rgba(221, 107, 32, var(--text-opacity));
  }

  .sm\:tw-text-orange-700 {
    --text-opacity: 1;
    color: #c05621;
    color: rgba(192, 86, 33, var(--text-opacity));
  }

  .sm\:tw-text-orange-800 {
    --text-opacity: 1;
    color: #9c4221;
    color: rgba(156, 66, 33, var(--text-opacity));
  }

  .sm\:tw-text-orange-900 {
    --text-opacity: 1;
    color: #7b341e;
    color: rgba(123, 52, 30, var(--text-opacity));
  }

  .sm\:tw-text-yellow-100 {
    --text-opacity: 1;
    color: #fffff0;
    color: rgba(255, 255, 240, var(--text-opacity));
  }

  .sm\:tw-text-yellow-200 {
    --text-opacity: 1;
    color: #fefcbf;
    color: rgba(254, 252, 191, var(--text-opacity));
  }

  .sm\:tw-text-yellow-300 {
    --text-opacity: 1;
    color: #faf089;
    color: rgba(250, 240, 137, var(--text-opacity));
  }

  .sm\:tw-text-yellow-400 {
    --text-opacity: 1;
    color: #f6e05e;
    color: rgba(246, 224, 94, var(--text-opacity));
  }

  .sm\:tw-text-yellow-500 {
    --text-opacity: 1;
    color: #ecc94b;
    color: rgba(236, 201, 75, var(--text-opacity));
  }

  .sm\:tw-text-yellow-600 {
    --text-opacity: 1;
    color: #d69e2e;
    color: rgba(214, 158, 46, var(--text-opacity));
  }

  .sm\:tw-text-yellow-700 {
    --text-opacity: 1;
    color: #b7791f;
    color: rgba(183, 121, 31, var(--text-opacity));
  }

  .sm\:tw-text-yellow-800 {
    --text-opacity: 1;
    color: #975a16;
    color: rgba(151, 90, 22, var(--text-opacity));
  }

  .sm\:tw-text-yellow-900 {
    --text-opacity: 1;
    color: #744210;
    color: rgba(116, 66, 16, var(--text-opacity));
  }

  .sm\:tw-text-green-100 {
    --text-opacity: 1;
    color: #f0fff4;
    color: rgba(240, 255, 244, var(--text-opacity));
  }

  .sm\:tw-text-green-200 {
    --text-opacity: 1;
    color: #c6f6d5;
    color: rgba(198, 246, 213, var(--text-opacity));
  }

  .sm\:tw-text-green-300 {
    --text-opacity: 1;
    color: #9ae6b4;
    color: rgba(154, 230, 180, var(--text-opacity));
  }

  .sm\:tw-text-green-400 {
    --text-opacity: 1;
    color: #68d391;
    color: rgba(104, 211, 145, var(--text-opacity));
  }

  .sm\:tw-text-green-500 {
    --text-opacity: 1;
    color: #48bb78;
    color: rgba(72, 187, 120, var(--text-opacity));
  }

  .sm\:tw-text-green-600 {
    --text-opacity: 1;
    color: #38a169;
    color: rgba(56, 161, 105, var(--text-opacity));
  }

  .sm\:tw-text-green-700 {
    --text-opacity: 1;
    color: #2f855a;
    color: rgba(47, 133, 90, var(--text-opacity));
  }

  .sm\:tw-text-green-800 {
    --text-opacity: 1;
    color: #276749;
    color: rgba(39, 103, 73, var(--text-opacity));
  }

  .sm\:tw-text-green-900 {
    --text-opacity: 1;
    color: #22543d;
    color: rgba(34, 84, 61, var(--text-opacity));
  }

  .sm\:tw-text-teal-100 {
    --text-opacity: 1;
    color: #e6fffa;
    color: rgba(230, 255, 250, var(--text-opacity));
  }

  .sm\:tw-text-teal-200 {
    --text-opacity: 1;
    color: #b2f5ea;
    color: rgba(178, 245, 234, var(--text-opacity));
  }

  .sm\:tw-text-teal-300 {
    --text-opacity: 1;
    color: #81e6d9;
    color: rgba(129, 230, 217, var(--text-opacity));
  }

  .sm\:tw-text-teal-400 {
    --text-opacity: 1;
    color: #4fd1c5;
    color: rgba(79, 209, 197, var(--text-opacity));
  }

  .sm\:tw-text-teal-500 {
    --text-opacity: 1;
    color: #38b2ac;
    color: rgba(56, 178, 172, var(--text-opacity));
  }

  .sm\:tw-text-teal-600 {
    --text-opacity: 1;
    color: #319795;
    color: rgba(49, 151, 149, var(--text-opacity));
  }

  .sm\:tw-text-teal-700 {
    --text-opacity: 1;
    color: #2c7a7b;
    color: rgba(44, 122, 123, var(--text-opacity));
  }

  .sm\:tw-text-teal-800 {
    --text-opacity: 1;
    color: #285e61;
    color: rgba(40, 94, 97, var(--text-opacity));
  }

  .sm\:tw-text-teal-900 {
    --text-opacity: 1;
    color: #234e52;
    color: rgba(35, 78, 82, var(--text-opacity));
  }

  .sm\:tw-text-blue-100 {
    --text-opacity: 1;
    color: #ebf8ff;
    color: rgba(235, 248, 255, var(--text-opacity));
  }

  .sm\:tw-text-blue-200 {
    --text-opacity: 1;
    color: #bee3f8;
    color: rgba(190, 227, 248, var(--text-opacity));
  }

  .sm\:tw-text-blue-300 {
    --text-opacity: 1;
    color: #90cdf4;
    color: rgba(144, 205, 244, var(--text-opacity));
  }

  .sm\:tw-text-blue-400 {
    --text-opacity: 1;
    color: #63b3ed;
    color: rgba(99, 179, 237, var(--text-opacity));
  }

  .sm\:tw-text-blue-500 {
    --text-opacity: 1;
    color: #4299e1;
    color: rgba(66, 153, 225, var(--text-opacity));
  }

  .sm\:tw-text-blue-600 {
    --text-opacity: 1;
    color: #3182ce;
    color: rgba(49, 130, 206, var(--text-opacity));
  }

  .sm\:tw-text-blue-700 {
    --text-opacity: 1;
    color: #2b6cb0;
    color: rgba(43, 108, 176, var(--text-opacity));
  }

  .sm\:tw-text-blue-800 {
    --text-opacity: 1;
    color: #2c5282;
    color: rgba(44, 82, 130, var(--text-opacity));
  }

  .sm\:tw-text-blue-900 {
    --text-opacity: 1;
    color: #2a4365;
    color: rgba(42, 67, 101, var(--text-opacity));
  }

  .sm\:tw-text-indigo-100 {
    --text-opacity: 1;
    color: #ebf4ff;
    color: rgba(235, 244, 255, var(--text-opacity));
  }

  .sm\:tw-text-indigo-200 {
    --text-opacity: 1;
    color: #c3dafe;
    color: rgba(195, 218, 254, var(--text-opacity));
  }

  .sm\:tw-text-indigo-300 {
    --text-opacity: 1;
    color: #a3bffa;
    color: rgba(163, 191, 250, var(--text-opacity));
  }

  .sm\:tw-text-indigo-400 {
    --text-opacity: 1;
    color: #7f9cf5;
    color: rgba(127, 156, 245, var(--text-opacity));
  }

  .sm\:tw-text-indigo-500 {
    --text-opacity: 1;
    color: #667eea;
    color: rgba(102, 126, 234, var(--text-opacity));
  }

  .sm\:tw-text-indigo-600 {
    --text-opacity: 1;
    color: #5a67d8;
    color: rgba(90, 103, 216, var(--text-opacity));
  }

  .sm\:tw-text-indigo-700 {
    --text-opacity: 1;
    color: #4c51bf;
    color: rgba(76, 81, 191, var(--text-opacity));
  }

  .sm\:tw-text-indigo-800 {
    --text-opacity: 1;
    color: #434190;
    color: rgba(67, 65, 144, var(--text-opacity));
  }

  .sm\:tw-text-indigo-900 {
    --text-opacity: 1;
    color: #3c366b;
    color: rgba(60, 54, 107, var(--text-opacity));
  }

  .sm\:tw-text-purple-100 {
    --text-opacity: 1;
    color: #faf5ff;
    color: rgba(250, 245, 255, var(--text-opacity));
  }

  .sm\:tw-text-purple-200 {
    --text-opacity: 1;
    color: #e9d8fd;
    color: rgba(233, 216, 253, var(--text-opacity));
  }

  .sm\:tw-text-purple-300 {
    --text-opacity: 1;
    color: #d6bcfa;
    color: rgba(214, 188, 250, var(--text-opacity));
  }

  .sm\:tw-text-purple-400 {
    --text-opacity: 1;
    color: #b794f4;
    color: rgba(183, 148, 244, var(--text-opacity));
  }

  .sm\:tw-text-purple-500 {
    --text-opacity: 1;
    color: #9f7aea;
    color: rgba(159, 122, 234, var(--text-opacity));
  }

  .sm\:tw-text-purple-600 {
    --text-opacity: 1;
    color: #805ad5;
    color: rgba(128, 90, 213, var(--text-opacity));
  }

  .sm\:tw-text-purple-700 {
    --text-opacity: 1;
    color: #6b46c1;
    color: rgba(107, 70, 193, var(--text-opacity));
  }

  .sm\:tw-text-purple-800 {
    --text-opacity: 1;
    color: #553c9a;
    color: rgba(85, 60, 154, var(--text-opacity));
  }

  .sm\:tw-text-purple-900 {
    --text-opacity: 1;
    color: #44337a;
    color: rgba(68, 51, 122, var(--text-opacity));
  }

  .sm\:tw-text-pink-100 {
    --text-opacity: 1;
    color: #fff5f7;
    color: rgba(255, 245, 247, var(--text-opacity));
  }

  .sm\:tw-text-pink-200 {
    --text-opacity: 1;
    color: #fed7e2;
    color: rgba(254, 215, 226, var(--text-opacity));
  }

  .sm\:tw-text-pink-300 {
    --text-opacity: 1;
    color: #fbb6ce;
    color: rgba(251, 182, 206, var(--text-opacity));
  }

  .sm\:tw-text-pink-400 {
    --text-opacity: 1;
    color: #f687b3;
    color: rgba(246, 135, 179, var(--text-opacity));
  }

  .sm\:tw-text-pink-500 {
    --text-opacity: 1;
    color: #ed64a6;
    color: rgba(237, 100, 166, var(--text-opacity));
  }

  .sm\:tw-text-pink-600 {
    --text-opacity: 1;
    color: #d53f8c;
    color: rgba(213, 63, 140, var(--text-opacity));
  }

  .sm\:tw-text-pink-700 {
    --text-opacity: 1;
    color: #b83280;
    color: rgba(184, 50, 128, var(--text-opacity));
  }

  .sm\:tw-text-pink-800 {
    --text-opacity: 1;
    color: #97266d;
    color: rgba(151, 38, 109, var(--text-opacity));
  }

  .sm\:tw-text-pink-900 {
    --text-opacity: 1;
    color: #702459;
    color: rgba(112, 36, 89, var(--text-opacity));
  }

  .sm\:hover\:tw-text-transparent:hover {
    color: transparent;
  }

  .sm\:hover\:tw-text-current:hover {
    color: currentColor;
  }

  .sm\:hover\:tw-text-black:hover {
    --text-opacity: 1;
    color: #000;
    color: rgba(0, 0, 0, var(--text-opacity));
  }

  .sm\:hover\:tw-text-white:hover {
    --text-opacity: 1;
    color: #fff;
    color: rgba(255, 255, 255, var(--text-opacity));
  }

  .sm\:hover\:tw-text-gray-100:hover {
    --text-opacity: 1;
    color: #f7fafc;
    color: rgba(247, 250, 252, var(--text-opacity));
  }

  .sm\:hover\:tw-text-gray-200:hover {
    --text-opacity: 1;
    color: #edf2f7;
    color: rgba(237, 242, 247, var(--text-opacity));
  }

  .sm\:hover\:tw-text-gray-300:hover {
    --text-opacity: 1;
    color: #e2e8f0;
    color: rgba(226, 232, 240, var(--text-opacity));
  }

  .sm\:hover\:tw-text-gray-400:hover {
    --text-opacity: 1;
    color: #cbd5e0;
    color: rgba(203, 213, 224, var(--text-opacity));
  }

  .sm\:hover\:tw-text-gray-500:hover {
    --text-opacity: 1;
    color: #a0aec0;
    color: rgba(160, 174, 192, var(--text-opacity));
  }

  .sm\:hover\:tw-text-gray-600:hover {
    --text-opacity: 1;
    color: #718096;
    color: rgba(113, 128, 150, var(--text-opacity));
  }

  .sm\:hover\:tw-text-gray-700:hover {
    --text-opacity: 1;
    color: #4a5568;
    color: rgba(74, 85, 104, var(--text-opacity));
  }

  .sm\:hover\:tw-text-gray-800:hover {
    --text-opacity: 1;
    color: #2d3748;
    color: rgba(45, 55, 72, var(--text-opacity));
  }

  .sm\:hover\:tw-text-gray-900:hover {
    --text-opacity: 1;
    color: #1a202c;
    color: rgba(26, 32, 44, var(--text-opacity));
  }

  .sm\:hover\:tw-text-red-100:hover {
    --text-opacity: 1;
    color: #fff5f5;
    color: rgba(255, 245, 245, var(--text-opacity));
  }

  .sm\:hover\:tw-text-red-200:hover {
    --text-opacity: 1;
    color: #fed7d7;
    color: rgba(254, 215, 215, var(--text-opacity));
  }

  .sm\:hover\:tw-text-red-300:hover {
    --text-opacity: 1;
    color: #feb2b2;
    color: rgba(254, 178, 178, var(--text-opacity));
  }

  .sm\:hover\:tw-text-red-400:hover {
    --text-opacity: 1;
    color: #fc8181;
    color: rgba(252, 129, 129, var(--text-opacity));
  }

  .sm\:hover\:tw-text-red-500:hover {
    --text-opacity: 1;
    color: #f56565;
    color: rgba(245, 101, 101, var(--text-opacity));
  }

  .sm\:hover\:tw-text-red-600:hover {
    --text-opacity: 1;
    color: #e53e3e;
    color: rgba(229, 62, 62, var(--text-opacity));
  }

  .sm\:hover\:tw-text-red-700:hover {
    --text-opacity: 1;
    color: #c53030;
    color: rgba(197, 48, 48, var(--text-opacity));
  }

  .sm\:hover\:tw-text-red-800:hover {
    --text-opacity: 1;
    color: #9b2c2c;
    color: rgba(155, 44, 44, var(--text-opacity));
  }

  .sm\:hover\:tw-text-red-900:hover {
    --text-opacity: 1;
    color: #742a2a;
    color: rgba(116, 42, 42, var(--text-opacity));
  }

  .sm\:hover\:tw-text-orange-100:hover {
    --text-opacity: 1;
    color: #fffaf0;
    color: rgba(255, 250, 240, var(--text-opacity));
  }

  .sm\:hover\:tw-text-orange-200:hover {
    --text-opacity: 1;
    color: #feebc8;
    color: rgba(254, 235, 200, var(--text-opacity));
  }

  .sm\:hover\:tw-text-orange-300:hover {
    --text-opacity: 1;
    color: #fbd38d;
    color: rgba(251, 211, 141, var(--text-opacity));
  }

  .sm\:hover\:tw-text-orange-400:hover {
    --text-opacity: 1;
    color: #f6ad55;
    color: rgba(246, 173, 85, var(--text-opacity));
  }

  .sm\:hover\:tw-text-orange-500:hover {
    --text-opacity: 1;
    color: #ed8936;
    color: rgba(237, 137, 54, var(--text-opacity));
  }

  .sm\:hover\:tw-text-orange-600:hover {
    --text-opacity: 1;
    color: #dd6b20;
    color: rgba(221, 107, 32, var(--text-opacity));
  }

  .sm\:hover\:tw-text-orange-700:hover {
    --text-opacity: 1;
    color: #c05621;
    color: rgba(192, 86, 33, var(--text-opacity));
  }

  .sm\:hover\:tw-text-orange-800:hover {
    --text-opacity: 1;
    color: #9c4221;
    color: rgba(156, 66, 33, var(--text-opacity));
  }

  .sm\:hover\:tw-text-orange-900:hover {
    --text-opacity: 1;
    color: #7b341e;
    color: rgba(123, 52, 30, var(--text-opacity));
  }

  .sm\:hover\:tw-text-yellow-100:hover {
    --text-opacity: 1;
    color: #fffff0;
    color: rgba(255, 255, 240, var(--text-opacity));
  }

  .sm\:hover\:tw-text-yellow-200:hover {
    --text-opacity: 1;
    color: #fefcbf;
    color: rgba(254, 252, 191, var(--text-opacity));
  }

  .sm\:hover\:tw-text-yellow-300:hover {
    --text-opacity: 1;
    color: #faf089;
    color: rgba(250, 240, 137, var(--text-opacity));
  }

  .sm\:hover\:tw-text-yellow-400:hover {
    --text-opacity: 1;
    color: #f6e05e;
    color: rgba(246, 224, 94, var(--text-opacity));
  }

  .sm\:hover\:tw-text-yellow-500:hover {
    --text-opacity: 1;
    color: #ecc94b;
    color: rgba(236, 201, 75, var(--text-opacity));
  }

  .sm\:hover\:tw-text-yellow-600:hover {
    --text-opacity: 1;
    color: #d69e2e;
    color: rgba(214, 158, 46, var(--text-opacity));
  }

  .sm\:hover\:tw-text-yellow-700:hover {
    --text-opacity: 1;
    color: #b7791f;
    color: rgba(183, 121, 31, var(--text-opacity));
  }

  .sm\:hover\:tw-text-yellow-800:hover {
    --text-opacity: 1;
    color: #975a16;
    color: rgba(151, 90, 22, var(--text-opacity));
  }

  .sm\:hover\:tw-text-yellow-900:hover {
    --text-opacity: 1;
    color: #744210;
    color: rgba(116, 66, 16, var(--text-opacity));
  }

  .sm\:hover\:tw-text-green-100:hover {
    --text-opacity: 1;
    color: #f0fff4;
    color: rgba(240, 255, 244, var(--text-opacity));
  }

  .sm\:hover\:tw-text-green-200:hover {
    --text-opacity: 1;
    color: #c6f6d5;
    color: rgba(198, 246, 213, var(--text-opacity));
  }

  .sm\:hover\:tw-text-green-300:hover {
    --text-opacity: 1;
    color: #9ae6b4;
    color: rgba(154, 230, 180, var(--text-opacity));
  }

  .sm\:hover\:tw-text-green-400:hover {
    --text-opacity: 1;
    color: #68d391;
    color: rgba(104, 211, 145, var(--text-opacity));
  }

  .sm\:hover\:tw-text-green-500:hover {
    --text-opacity: 1;
    color: #48bb78;
    color: rgba(72, 187, 120, var(--text-opacity));
  }

  .sm\:hover\:tw-text-green-600:hover {
    --text-opacity: 1;
    color: #38a169;
    color: rgba(56, 161, 105, var(--text-opacity));
  }

  .sm\:hover\:tw-text-green-700:hover {
    --text-opacity: 1;
    color: #2f855a;
    color: rgba(47, 133, 90, var(--text-opacity));
  }

  .sm\:hover\:tw-text-green-800:hover {
    --text-opacity: 1;
    color: #276749;
    color: rgba(39, 103, 73, var(--text-opacity));
  }

  .sm\:hover\:tw-text-green-900:hover {
    --text-opacity: 1;
    color: #22543d;
    color: rgba(34, 84, 61, var(--text-opacity));
  }

  .sm\:hover\:tw-text-teal-100:hover {
    --text-opacity: 1;
    color: #e6fffa;
    color: rgba(230, 255, 250, var(--text-opacity));
  }

  .sm\:hover\:tw-text-teal-200:hover {
    --text-opacity: 1;
    color: #b2f5ea;
    color: rgba(178, 245, 234, var(--text-opacity));
  }

  .sm\:hover\:tw-text-teal-300:hover {
    --text-opacity: 1;
    color: #81e6d9;
    color: rgba(129, 230, 217, var(--text-opacity));
  }

  .sm\:hover\:tw-text-teal-400:hover {
    --text-opacity: 1;
    color: #4fd1c5;
    color: rgba(79, 209, 197, var(--text-opacity));
  }

  .sm\:hover\:tw-text-teal-500:hover {
    --text-opacity: 1;
    color: #38b2ac;
    color: rgba(56, 178, 172, var(--text-opacity));
  }

  .sm\:hover\:tw-text-teal-600:hover {
    --text-opacity: 1;
    color: #319795;
    color: rgba(49, 151, 149, var(--text-opacity));
  }

  .sm\:hover\:tw-text-teal-700:hover {
    --text-opacity: 1;
    color: #2c7a7b;
    color: rgba(44, 122, 123, var(--text-opacity));
  }

  .sm\:hover\:tw-text-teal-800:hover {
    --text-opacity: 1;
    color: #285e61;
    color: rgba(40, 94, 97, var(--text-opacity));
  }

  .sm\:hover\:tw-text-teal-900:hover {
    --text-opacity: 1;
    color: #234e52;
    color: rgba(35, 78, 82, var(--text-opacity));
  }

  .sm\:hover\:tw-text-blue-100:hover {
    --text-opacity: 1;
    color: #ebf8ff;
    color: rgba(235, 248, 255, var(--text-opacity));
  }

  .sm\:hover\:tw-text-blue-200:hover {
    --text-opacity: 1;
    color: #bee3f8;
    color: rgba(190, 227, 248, var(--text-opacity));
  }

  .sm\:hover\:tw-text-blue-300:hover {
    --text-opacity: 1;
    color: #90cdf4;
    color: rgba(144, 205, 244, var(--text-opacity));
  }

  .sm\:hover\:tw-text-blue-400:hover {
    --text-opacity: 1;
    color: #63b3ed;
    color: rgba(99, 179, 237, var(--text-opacity));
  }

  .sm\:hover\:tw-text-blue-500:hover {
    --text-opacity: 1;
    color: #4299e1;
    color: rgba(66, 153, 225, var(--text-opacity));
  }

  .sm\:hover\:tw-text-blue-600:hover {
    --text-opacity: 1;
    color: #3182ce;
    color: rgba(49, 130, 206, var(--text-opacity));
  }

  .sm\:hover\:tw-text-blue-700:hover {
    --text-opacity: 1;
    color: #2b6cb0;
    color: rgba(43, 108, 176, var(--text-opacity));
  }

  .sm\:hover\:tw-text-blue-800:hover {
    --text-opacity: 1;
    color: #2c5282;
    color: rgba(44, 82, 130, var(--text-opacity));
  }

  .sm\:hover\:tw-text-blue-900:hover {
    --text-opacity: 1;
    color: #2a4365;
    color: rgba(42, 67, 101, var(--text-opacity));
  }

  .sm\:hover\:tw-text-indigo-100:hover {
    --text-opacity: 1;
    color: #ebf4ff;
    color: rgba(235, 244, 255, var(--text-opacity));
  }

  .sm\:hover\:tw-text-indigo-200:hover {
    --text-opacity: 1;
    color: #c3dafe;
    color: rgba(195, 218, 254, var(--text-opacity));
  }

  .sm\:hover\:tw-text-indigo-300:hover {
    --text-opacity: 1;
    color: #a3bffa;
    color: rgba(163, 191, 250, var(--text-opacity));
  }

  .sm\:hover\:tw-text-indigo-400:hover {
    --text-opacity: 1;
    color: #7f9cf5;
    color: rgba(127, 156, 245, var(--text-opacity));
  }

  .sm\:hover\:tw-text-indigo-500:hover {
    --text-opacity: 1;
    color: #667eea;
    color: rgba(102, 126, 234, var(--text-opacity));
  }

  .sm\:hover\:tw-text-indigo-600:hover {
    --text-opacity: 1;
    color: #5a67d8;
    color: rgba(90, 103, 216, var(--text-opacity));
  }

  .sm\:hover\:tw-text-indigo-700:hover {
    --text-opacity: 1;
    color: #4c51bf;
    color: rgba(76, 81, 191, var(--text-opacity));
  }

  .sm\:hover\:tw-text-indigo-800:hover {
    --text-opacity: 1;
    color: #434190;
    color: rgba(67, 65, 144, var(--text-opacity));
  }

  .sm\:hover\:tw-text-indigo-900:hover {
    --text-opacity: 1;
    color: #3c366b;
    color: rgba(60, 54, 107, var(--text-opacity));
  }

  .sm\:hover\:tw-text-purple-100:hover {
    --text-opacity: 1;
    color: #faf5ff;
    color: rgba(250, 245, 255, var(--text-opacity));
  }

  .sm\:hover\:tw-text-purple-200:hover {
    --text-opacity: 1;
    color: #e9d8fd;
    color: rgba(233, 216, 253, var(--text-opacity));
  }

  .sm\:hover\:tw-text-purple-300:hover {
    --text-opacity: 1;
    color: #d6bcfa;
    color: rgba(214, 188, 250, var(--text-opacity));
  }

  .sm\:hover\:tw-text-purple-400:hover {
    --text-opacity: 1;
    color: #b794f4;
    color: rgba(183, 148, 244, var(--text-opacity));
  }

  .sm\:hover\:tw-text-purple-500:hover {
    --text-opacity: 1;
    color: #9f7aea;
    color: rgba(159, 122, 234, var(--text-opacity));
  }

  .sm\:hover\:tw-text-purple-600:hover {
    --text-opacity: 1;
    color: #805ad5;
    color: rgba(128, 90, 213, var(--text-opacity));
  }

  .sm\:hover\:tw-text-purple-700:hover {
    --text-opacity: 1;
    color: #6b46c1;
    color: rgba(107, 70, 193, var(--text-opacity));
  }

  .sm\:hover\:tw-text-purple-800:hover {
    --text-opacity: 1;
    color: #553c9a;
    color: rgba(85, 60, 154, var(--text-opacity));
  }

  .sm\:hover\:tw-text-purple-900:hover {
    --text-opacity: 1;
    color: #44337a;
    color: rgba(68, 51, 122, var(--text-opacity));
  }

  .sm\:hover\:tw-text-pink-100:hover {
    --text-opacity: 1;
    color: #fff5f7;
    color: rgba(255, 245, 247, var(--text-opacity));
  }

  .sm\:hover\:tw-text-pink-200:hover {
    --text-opacity: 1;
    color: #fed7e2;
    color: rgba(254, 215, 226, var(--text-opacity));
  }

  .sm\:hover\:tw-text-pink-300:hover {
    --text-opacity: 1;
    color: #fbb6ce;
    color: rgba(251, 182, 206, var(--text-opacity));
  }

  .sm\:hover\:tw-text-pink-400:hover {
    --text-opacity: 1;
    color: #f687b3;
    color: rgba(246, 135, 179, var(--text-opacity));
  }

  .sm\:hover\:tw-text-pink-500:hover {
    --text-opacity: 1;
    color: #ed64a6;
    color: rgba(237, 100, 166, var(--text-opacity));
  }

  .sm\:hover\:tw-text-pink-600:hover {
    --text-opacity: 1;
    color: #d53f8c;
    color: rgba(213, 63, 140, var(--text-opacity));
  }

  .sm\:hover\:tw-text-pink-700:hover {
    --text-opacity: 1;
    color: #b83280;
    color: rgba(184, 50, 128, var(--text-opacity));
  }

  .sm\:hover\:tw-text-pink-800:hover {
    --text-opacity: 1;
    color: #97266d;
    color: rgba(151, 38, 109, var(--text-opacity));
  }

  .sm\:hover\:tw-text-pink-900:hover {
    --text-opacity: 1;
    color: #702459;
    color: rgba(112, 36, 89, var(--text-opacity));
  }

  .sm\:focus\:tw-text-transparent:focus {
    color: transparent;
  }

  .sm\:focus\:tw-text-current:focus {
    color: currentColor;
  }

  .sm\:focus\:tw-text-black:focus {
    --text-opacity: 1;
    color: #000;
    color: rgba(0, 0, 0, var(--text-opacity));
  }

  .sm\:focus\:tw-text-white:focus {
    --text-opacity: 1;
    color: #fff;
    color: rgba(255, 255, 255, var(--text-opacity));
  }

  .sm\:focus\:tw-text-gray-100:focus {
    --text-opacity: 1;
    color: #f7fafc;
    color: rgba(247, 250, 252, var(--text-opacity));
  }

  .sm\:focus\:tw-text-gray-200:focus {
    --text-opacity: 1;
    color: #edf2f7;
    color: rgba(237, 242, 247, var(--text-opacity));
  }

  .sm\:focus\:tw-text-gray-300:focus {
    --text-opacity: 1;
    color: #e2e8f0;
    color: rgba(226, 232, 240, var(--text-opacity));
  }

  .sm\:focus\:tw-text-gray-400:focus {
    --text-opacity: 1;
    color: #cbd5e0;
    color: rgba(203, 213, 224, var(--text-opacity));
  }

  .sm\:focus\:tw-text-gray-500:focus {
    --text-opacity: 1;
    color: #a0aec0;
    color: rgba(160, 174, 192, var(--text-opacity));
  }

  .sm\:focus\:tw-text-gray-600:focus {
    --text-opacity: 1;
    color: #718096;
    color: rgba(113, 128, 150, var(--text-opacity));
  }

  .sm\:focus\:tw-text-gray-700:focus {
    --text-opacity: 1;
    color: #4a5568;
    color: rgba(74, 85, 104, var(--text-opacity));
  }

  .sm\:focus\:tw-text-gray-800:focus {
    --text-opacity: 1;
    color: #2d3748;
    color: rgba(45, 55, 72, var(--text-opacity));
  }

  .sm\:focus\:tw-text-gray-900:focus {
    --text-opacity: 1;
    color: #1a202c;
    color: rgba(26, 32, 44, var(--text-opacity));
  }

  .sm\:focus\:tw-text-red-100:focus {
    --text-opacity: 1;
    color: #fff5f5;
    color: rgba(255, 245, 245, var(--text-opacity));
  }

  .sm\:focus\:tw-text-red-200:focus {
    --text-opacity: 1;
    color: #fed7d7;
    color: rgba(254, 215, 215, var(--text-opacity));
  }

  .sm\:focus\:tw-text-red-300:focus {
    --text-opacity: 1;
    color: #feb2b2;
    color: rgba(254, 178, 178, var(--text-opacity));
  }

  .sm\:focus\:tw-text-red-400:focus {
    --text-opacity: 1;
    color: #fc8181;
    color: rgba(252, 129, 129, var(--text-opacity));
  }

  .sm\:focus\:tw-text-red-500:focus {
    --text-opacity: 1;
    color: #f56565;
    color: rgba(245, 101, 101, var(--text-opacity));
  }

  .sm\:focus\:tw-text-red-600:focus {
    --text-opacity: 1;
    color: #e53e3e;
    color: rgba(229, 62, 62, var(--text-opacity));
  }

  .sm\:focus\:tw-text-red-700:focus {
    --text-opacity: 1;
    color: #c53030;
    color: rgba(197, 48, 48, var(--text-opacity));
  }

  .sm\:focus\:tw-text-red-800:focus {
    --text-opacity: 1;
    color: #9b2c2c;
    color: rgba(155, 44, 44, var(--text-opacity));
  }

  .sm\:focus\:tw-text-red-900:focus {
    --text-opacity: 1;
    color: #742a2a;
    color: rgba(116, 42, 42, var(--text-opacity));
  }

  .sm\:focus\:tw-text-orange-100:focus {
    --text-opacity: 1;
    color: #fffaf0;
    color: rgba(255, 250, 240, var(--text-opacity));
  }

  .sm\:focus\:tw-text-orange-200:focus {
    --text-opacity: 1;
    color: #feebc8;
    color: rgba(254, 235, 200, var(--text-opacity));
  }

  .sm\:focus\:tw-text-orange-300:focus {
    --text-opacity: 1;
    color: #fbd38d;
    color: rgba(251, 211, 141, var(--text-opacity));
  }

  .sm\:focus\:tw-text-orange-400:focus {
    --text-opacity: 1;
    color: #f6ad55;
    color: rgba(246, 173, 85, var(--text-opacity));
  }

  .sm\:focus\:tw-text-orange-500:focus {
    --text-opacity: 1;
    color: #ed8936;
    color: rgba(237, 137, 54, var(--text-opacity));
  }

  .sm\:focus\:tw-text-orange-600:focus {
    --text-opacity: 1;
    color: #dd6b20;
    color: rgba(221, 107, 32, var(--text-opacity));
  }

  .sm\:focus\:tw-text-orange-700:focus {
    --text-opacity: 1;
    color: #c05621;
    color: rgba(192, 86, 33, var(--text-opacity));
  }

  .sm\:focus\:tw-text-orange-800:focus {
    --text-opacity: 1;
    color: #9c4221;
    color: rgba(156, 66, 33, var(--text-opacity));
  }

  .sm\:focus\:tw-text-orange-900:focus {
    --text-opacity: 1;
    color: #7b341e;
    color: rgba(123, 52, 30, var(--text-opacity));
  }

  .sm\:focus\:tw-text-yellow-100:focus {
    --text-opacity: 1;
    color: #fffff0;
    color: rgba(255, 255, 240, var(--text-opacity));
  }

  .sm\:focus\:tw-text-yellow-200:focus {
    --text-opacity: 1;
    color: #fefcbf;
    color: rgba(254, 252, 191, var(--text-opacity));
  }

  .sm\:focus\:tw-text-yellow-300:focus {
    --text-opacity: 1;
    color: #faf089;
    color: rgba(250, 240, 137, var(--text-opacity));
  }

  .sm\:focus\:tw-text-yellow-400:focus {
    --text-opacity: 1;
    color: #f6e05e;
    color: rgba(246, 224, 94, var(--text-opacity));
  }

  .sm\:focus\:tw-text-yellow-500:focus {
    --text-opacity: 1;
    color: #ecc94b;
    color: rgba(236, 201, 75, var(--text-opacity));
  }

  .sm\:focus\:tw-text-yellow-600:focus {
    --text-opacity: 1;
    color: #d69e2e;
    color: rgba(214, 158, 46, var(--text-opacity));
  }

  .sm\:focus\:tw-text-yellow-700:focus {
    --text-opacity: 1;
    color: #b7791f;
    color: rgba(183, 121, 31, var(--text-opacity));
  }

  .sm\:focus\:tw-text-yellow-800:focus {
    --text-opacity: 1;
    color: #975a16;
    color: rgba(151, 90, 22, var(--text-opacity));
  }

  .sm\:focus\:tw-text-yellow-900:focus {
    --text-opacity: 1;
    color: #744210;
    color: rgba(116, 66, 16, var(--text-opacity));
  }

  .sm\:focus\:tw-text-green-100:focus {
    --text-opacity: 1;
    color: #f0fff4;
    color: rgba(240, 255, 244, var(--text-opacity));
  }

  .sm\:focus\:tw-text-green-200:focus {
    --text-opacity: 1;
    color: #c6f6d5;
    color: rgba(198, 246, 213, var(--text-opacity));
  }

  .sm\:focus\:tw-text-green-300:focus {
    --text-opacity: 1;
    color: #9ae6b4;
    color: rgba(154, 230, 180, var(--text-opacity));
  }

  .sm\:focus\:tw-text-green-400:focus {
    --text-opacity: 1;
    color: #68d391;
    color: rgba(104, 211, 145, var(--text-opacity));
  }

  .sm\:focus\:tw-text-green-500:focus {
    --text-opacity: 1;
    color: #48bb78;
    color: rgba(72, 187, 120, var(--text-opacity));
  }

  .sm\:focus\:tw-text-green-600:focus {
    --text-opacity: 1;
    color: #38a169;
    color: rgba(56, 161, 105, var(--text-opacity));
  }

  .sm\:focus\:tw-text-green-700:focus {
    --text-opacity: 1;
    color: #2f855a;
    color: rgba(47, 133, 90, var(--text-opacity));
  }

  .sm\:focus\:tw-text-green-800:focus {
    --text-opacity: 1;
    color: #276749;
    color: rgba(39, 103, 73, var(--text-opacity));
  }

  .sm\:focus\:tw-text-green-900:focus {
    --text-opacity: 1;
    color: #22543d;
    color: rgba(34, 84, 61, var(--text-opacity));
  }

  .sm\:focus\:tw-text-teal-100:focus {
    --text-opacity: 1;
    color: #e6fffa;
    color: rgba(230, 255, 250, var(--text-opacity));
  }

  .sm\:focus\:tw-text-teal-200:focus {
    --text-opacity: 1;
    color: #b2f5ea;
    color: rgba(178, 245, 234, var(--text-opacity));
  }

  .sm\:focus\:tw-text-teal-300:focus {
    --text-opacity: 1;
    color: #81e6d9;
    color: rgba(129, 230, 217, var(--text-opacity));
  }

  .sm\:focus\:tw-text-teal-400:focus {
    --text-opacity: 1;
    color: #4fd1c5;
    color: rgba(79, 209, 197, var(--text-opacity));
  }

  .sm\:focus\:tw-text-teal-500:focus {
    --text-opacity: 1;
    color: #38b2ac;
    color: rgba(56, 178, 172, var(--text-opacity));
  }

  .sm\:focus\:tw-text-teal-600:focus {
    --text-opacity: 1;
    color: #319795;
    color: rgba(49, 151, 149, var(--text-opacity));
  }

  .sm\:focus\:tw-text-teal-700:focus {
    --text-opacity: 1;
    color: #2c7a7b;
    color: rgba(44, 122, 123, var(--text-opacity));
  }

  .sm\:focus\:tw-text-teal-800:focus {
    --text-opacity: 1;
    color: #285e61;
    color: rgba(40, 94, 97, var(--text-opacity));
  }

  .sm\:focus\:tw-text-teal-900:focus {
    --text-opacity: 1;
    color: #234e52;
    color: rgba(35, 78, 82, var(--text-opacity));
  }

  .sm\:focus\:tw-text-blue-100:focus {
    --text-opacity: 1;
    color: #ebf8ff;
    color: rgba(235, 248, 255, var(--text-opacity));
  }

  .sm\:focus\:tw-text-blue-200:focus {
    --text-opacity: 1;
    color: #bee3f8;
    color: rgba(190, 227, 248, var(--text-opacity));
  }

  .sm\:focus\:tw-text-blue-300:focus {
    --text-opacity: 1;
    color: #90cdf4;
    color: rgba(144, 205, 244, var(--text-opacity));
  }

  .sm\:focus\:tw-text-blue-400:focus {
    --text-opacity: 1;
    color: #63b3ed;
    color: rgba(99, 179, 237, var(--text-opacity));
  }

  .sm\:focus\:tw-text-blue-500:focus {
    --text-opacity: 1;
    color: #4299e1;
    color: rgba(66, 153, 225, var(--text-opacity));
  }

  .sm\:focus\:tw-text-blue-600:focus {
    --text-opacity: 1;
    color: #3182ce;
    color: rgba(49, 130, 206, var(--text-opacity));
  }

  .sm\:focus\:tw-text-blue-700:focus {
    --text-opacity: 1;
    color: #2b6cb0;
    color: rgba(43, 108, 176, var(--text-opacity));
  }

  .sm\:focus\:tw-text-blue-800:focus {
    --text-opacity: 1;
    color: #2c5282;
    color: rgba(44, 82, 130, var(--text-opacity));
  }

  .sm\:focus\:tw-text-blue-900:focus {
    --text-opacity: 1;
    color: #2a4365;
    color: rgba(42, 67, 101, var(--text-opacity));
  }

  .sm\:focus\:tw-text-indigo-100:focus {
    --text-opacity: 1;
    color: #ebf4ff;
    color: rgba(235, 244, 255, var(--text-opacity));
  }

  .sm\:focus\:tw-text-indigo-200:focus {
    --text-opacity: 1;
    color: #c3dafe;
    color: rgba(195, 218, 254, var(--text-opacity));
  }

  .sm\:focus\:tw-text-indigo-300:focus {
    --text-opacity: 1;
    color: #a3bffa;
    color: rgba(163, 191, 250, var(--text-opacity));
  }

  .sm\:focus\:tw-text-indigo-400:focus {
    --text-opacity: 1;
    color: #7f9cf5;
    color: rgba(127, 156, 245, var(--text-opacity));
  }

  .sm\:focus\:tw-text-indigo-500:focus {
    --text-opacity: 1;
    color: #667eea;
    color: rgba(102, 126, 234, var(--text-opacity));
  }

  .sm\:focus\:tw-text-indigo-600:focus {
    --text-opacity: 1;
    color: #5a67d8;
    color: rgba(90, 103, 216, var(--text-opacity));
  }

  .sm\:focus\:tw-text-indigo-700:focus {
    --text-opacity: 1;
    color: #4c51bf;
    color: rgba(76, 81, 191, var(--text-opacity));
  }

  .sm\:focus\:tw-text-indigo-800:focus {
    --text-opacity: 1;
    color: #434190;
    color: rgba(67, 65, 144, var(--text-opacity));
  }

  .sm\:focus\:tw-text-indigo-900:focus {
    --text-opacity: 1;
    color: #3c366b;
    color: rgba(60, 54, 107, var(--text-opacity));
  }

  .sm\:focus\:tw-text-purple-100:focus {
    --text-opacity: 1;
    color: #faf5ff;
    color: rgba(250, 245, 255, var(--text-opacity));
  }

  .sm\:focus\:tw-text-purple-200:focus {
    --text-opacity: 1;
    color: #e9d8fd;
    color: rgba(233, 216, 253, var(--text-opacity));
  }

  .sm\:focus\:tw-text-purple-300:focus {
    --text-opacity: 1;
    color: #d6bcfa;
    color: rgba(214, 188, 250, var(--text-opacity));
  }

  .sm\:focus\:tw-text-purple-400:focus {
    --text-opacity: 1;
    color: #b794f4;
    color: rgba(183, 148, 244, var(--text-opacity));
  }

  .sm\:focus\:tw-text-purple-500:focus {
    --text-opacity: 1;
    color: #9f7aea;
    color: rgba(159, 122, 234, var(--text-opacity));
  }

  .sm\:focus\:tw-text-purple-600:focus {
    --text-opacity: 1;
    color: #805ad5;
    color: rgba(128, 90, 213, var(--text-opacity));
  }

  .sm\:focus\:tw-text-purple-700:focus {
    --text-opacity: 1;
    color: #6b46c1;
    color: rgba(107, 70, 193, var(--text-opacity));
  }

  .sm\:focus\:tw-text-purple-800:focus {
    --text-opacity: 1;
    color: #553c9a;
    color: rgba(85, 60, 154, var(--text-opacity));
  }

  .sm\:focus\:tw-text-purple-900:focus {
    --text-opacity: 1;
    color: #44337a;
    color: rgba(68, 51, 122, var(--text-opacity));
  }

  .sm\:focus\:tw-text-pink-100:focus {
    --text-opacity: 1;
    color: #fff5f7;
    color: rgba(255, 245, 247, var(--text-opacity));
  }

  .sm\:focus\:tw-text-pink-200:focus {
    --text-opacity: 1;
    color: #fed7e2;
    color: rgba(254, 215, 226, var(--text-opacity));
  }

  .sm\:focus\:tw-text-pink-300:focus {
    --text-opacity: 1;
    color: #fbb6ce;
    color: rgba(251, 182, 206, var(--text-opacity));
  }

  .sm\:focus\:tw-text-pink-400:focus {
    --text-opacity: 1;
    color: #f687b3;
    color: rgba(246, 135, 179, var(--text-opacity));
  }

  .sm\:focus\:tw-text-pink-500:focus {
    --text-opacity: 1;
    color: #ed64a6;
    color: rgba(237, 100, 166, var(--text-opacity));
  }

  .sm\:focus\:tw-text-pink-600:focus {
    --text-opacity: 1;
    color: #d53f8c;
    color: rgba(213, 63, 140, var(--text-opacity));
  }

  .sm\:focus\:tw-text-pink-700:focus {
    --text-opacity: 1;
    color: #b83280;
    color: rgba(184, 50, 128, var(--text-opacity));
  }

  .sm\:focus\:tw-text-pink-800:focus {
    --text-opacity: 1;
    color: #97266d;
    color: rgba(151, 38, 109, var(--text-opacity));
  }

  .sm\:focus\:tw-text-pink-900:focus {
    --text-opacity: 1;
    color: #702459;
    color: rgba(112, 36, 89, var(--text-opacity));
  }

  .sm\:tw-text-opacity-0 {
    --text-opacity: 0;
  }

  .sm\:tw-text-opacity-25 {
    --text-opacity: 0.25;
  }

  .sm\:tw-text-opacity-50 {
    --text-opacity: 0.5;
  }

  .sm\:tw-text-opacity-75 {
    --text-opacity: 0.75;
  }

  .sm\:tw-text-opacity-100 {
    --text-opacity: 1;
  }

  .sm\:hover\:tw-text-opacity-0:hover {
    --text-opacity: 0;
  }

  .sm\:hover\:tw-text-opacity-25:hover {
    --text-opacity: 0.25;
  }

  .sm\:hover\:tw-text-opacity-50:hover {
    --text-opacity: 0.5;
  }

  .sm\:hover\:tw-text-opacity-75:hover {
    --text-opacity: 0.75;
  }

  .sm\:hover\:tw-text-opacity-100:hover {
    --text-opacity: 1;
  }

  .sm\:focus\:tw-text-opacity-0:focus {
    --text-opacity: 0;
  }

  .sm\:focus\:tw-text-opacity-25:focus {
    --text-opacity: 0.25;
  }

  .sm\:focus\:tw-text-opacity-50:focus {
    --text-opacity: 0.5;
  }

  .sm\:focus\:tw-text-opacity-75:focus {
    --text-opacity: 0.75;
  }

  .sm\:focus\:tw-text-opacity-100:focus {
    --text-opacity: 1;
  }

  .sm\:tw-italic {
    font-style: italic;
  }

  .sm\:tw-not-italic {
    font-style: normal;
  }

  .sm\:tw-uppercase {
    text-transform: uppercase;
  }

  .sm\:tw-lowercase {
    text-transform: lowercase;
  }

  .sm\:tw-capitalize {
    text-transform: capitalize;
  }

  .sm\:tw-normal-case {
    text-transform: none;
  }

  .sm\:tw-underline {
    text-decoration: underline;
  }

  .sm\:tw-line-through {
    text-decoration: line-through;
  }

  .sm\:tw-no-underline {
    text-decoration: none;
  }

  .sm\:hover\:tw-underline:hover {
    text-decoration: underline;
  }

  .sm\:hover\:tw-line-through:hover {
    text-decoration: line-through;
  }

  .sm\:hover\:tw-no-underline:hover {
    text-decoration: none;
  }

  .sm\:focus\:tw-underline:focus {
    text-decoration: underline;
  }

  .sm\:focus\:tw-line-through:focus {
    text-decoration: line-through;
  }

  .sm\:focus\:tw-no-underline:focus {
    text-decoration: none;
  }

  .sm\:tw-antialiased {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  .sm\:tw-subpixel-antialiased {
    -webkit-font-smoothing: auto;
    -moz-osx-font-smoothing: auto;
  }

  .sm\:tw-ordinal, .sm\:tw-slashed-zero, .sm\:tw-lining-nums, .sm\:tw-oldstyle-nums, .sm\:tw-proportional-nums, .sm\:tw-tabular-nums, .sm\:tw-diagonal-fractions, .sm\:tw-stacked-fractions {
    --font-variant-numeric-ordinal: var(--tailwind-empty,/*!*/ /*!*/);
    --font-variant-numeric-slashed-zero: var(--tailwind-empty,/*!*/ /*!*/);
    --font-variant-numeric-figure: var(--tailwind-empty,/*!*/ /*!*/);
    --font-variant-numeric-spacing: var(--tailwind-empty,/*!*/ /*!*/);
    --font-variant-numeric-fraction: var(--tailwind-empty,/*!*/ /*!*/);
    font-variant-numeric: var(--font-variant-numeric-ordinal) var(--font-variant-numeric-slashed-zero) var(--font-variant-numeric-figure) var(--font-variant-numeric-spacing) var(--font-variant-numeric-fraction);
  }

  .sm\:tw-normal-nums {
    font-variant-numeric: normal;
  }

  .sm\:tw-ordinal {
    --font-variant-numeric-ordinal: ordinal;
  }

  .sm\:tw-slashed-zero {
    --font-variant-numeric-slashed-zero: slashed-zero;
  }

  .sm\:tw-lining-nums {
    --font-variant-numeric-figure: lining-nums;
  }

  .sm\:tw-oldstyle-nums {
    --font-variant-numeric-figure: oldstyle-nums;
  }

  .sm\:tw-proportional-nums {
    --font-variant-numeric-spacing: proportional-nums;
  }

  .sm\:tw-tabular-nums {
    --font-variant-numeric-spacing: tabular-nums;
  }

  .sm\:tw-diagonal-fractions {
    --font-variant-numeric-fraction: diagonal-fractions;
  }

  .sm\:tw-stacked-fractions {
    --font-variant-numeric-fraction: stacked-fractions;
  }

  .sm\:tw-tracking-tighter {
    letter-spacing: -0.05em;
  }

  .sm\:tw-tracking-tight {
    letter-spacing: -0.025em;
  }

  .sm\:tw-tracking-normal {
    letter-spacing: 0;
  }

  .sm\:tw-tracking-wide {
    letter-spacing: 0.025em;
  }

  .sm\:tw-tracking-wider {
    letter-spacing: 0.05em;
  }

  .sm\:tw-tracking-widest {
    letter-spacing: 0.1em;
  }

  .sm\:tw-select-none {
    -webkit-user-select: none;
       -moz-user-select: none;
            user-select: none;
  }

  .sm\:tw-select-text {
    -webkit-user-select: text;
       -moz-user-select: text;
            user-select: text;
  }

  .sm\:tw-select-all {
    -webkit-user-select: all;
       -moz-user-select: all;
            user-select: all;
  }

  .sm\:tw-select-auto {
    -webkit-user-select: auto;
       -moz-user-select: auto;
            user-select: auto;
  }

  .sm\:tw-align-baseline {
    vertical-align: baseline;
  }

  .sm\:tw-align-top {
    vertical-align: top;
  }

  .sm\:tw-align-middle {
    vertical-align: middle;
  }

  .sm\:tw-align-bottom {
    vertical-align: bottom;
  }

  .sm\:tw-align-text-top {
    vertical-align: text-top;
  }

  .sm\:tw-align-text-bottom {
    vertical-align: text-bottom;
  }

  .sm\:tw-visible {
    visibility: visible;
  }

  .sm\:tw-invisible {
    visibility: hidden;
  }

  .sm\:tw-whitespace-normal {
    white-space: normal;
  }

  .sm\:tw-whitespace-no-wrap {
    white-space: nowrap;
  }

  .sm\:tw-whitespace-pre {
    white-space: pre;
  }

  .sm\:tw-whitespace-pre-line {
    white-space: pre-line;
  }

  .sm\:tw-whitespace-pre-wrap {
    white-space: pre-wrap;
  }

  .sm\:tw-break-normal {
    word-wrap: normal;
    overflow-wrap: normal;
    word-break: normal;
  }

  .sm\:tw-break-words {
    word-wrap: break-word;
    overflow-wrap: break-word;
  }

  .sm\:tw-break-all {
    word-break: break-all;
  }

  .sm\:tw-truncate {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .sm\:tw-w-0 {
    width: 0;
  }

  .sm\:tw-w-1 {
    width: 0.25rem;
  }

  .sm\:tw-w-2 {
    width: 0.5rem;
  }

  .sm\:tw-w-3 {
    width: 0.75rem;
  }

  .sm\:tw-w-4 {
    width: 1rem;
  }

  .sm\:tw-w-5 {
    width: 1.25rem;
  }

  .sm\:tw-w-6 {
    width: 1.5rem;
  }

  .sm\:tw-w-8 {
    width: 2rem;
  }

  .sm\:tw-w-10 {
    width: 2.5rem;
  }

  .sm\:tw-w-12 {
    width: 3rem;
  }

  .sm\:tw-w-16 {
    width: 4rem;
  }

  .sm\:tw-w-20 {
    width: 5rem;
  }

  .sm\:tw-w-24 {
    width: 6rem;
  }

  .sm\:tw-w-32 {
    width: 8rem;
  }

  .sm\:tw-w-40 {
    width: 10rem;
  }

  .sm\:tw-w-48 {
    width: 12rem;
  }

  .sm\:tw-w-56 {
    width: 14rem;
  }

  .sm\:tw-w-64 {
    width: 16rem;
  }

  .sm\:tw-w-auto {
    width: auto;
  }

  .sm\:tw-w-px {
    width: 1px;
  }

  .sm\:tw-w-270px {
    width: 270px;
  }

  .sm\:tw-w-400px {
    width: 400px;
  }

  .sm\:tw-w-464px {
    width: 464px;
  }

  .sm\:tw-w-480px {
    width: 480px;
  }

  .sm\:tw-w-500px {
    width: 500px;
  }

  .sm\:tw-w-576px {
    width: 576px;
  }

  .sm\:tw-w-600px {
    width: 600px;
  }

  .sm\:tw-w-672px {
    width: 672px;
  }

  .sm\:tw-w-1\/2 {
    width: 50%;
  }

  .sm\:tw-w-1\/3 {
    width: 33.333333%;
  }

  .sm\:tw-w-2\/3 {
    width: 66.666667%;
  }

  .sm\:tw-w-1\/4 {
    width: 25%;
  }

  .sm\:tw-w-2\/4 {
    width: 50%;
  }

  .sm\:tw-w-3\/4 {
    width: 75%;
  }

  .sm\:tw-w-1\/5 {
    width: 20%;
  }

  .sm\:tw-w-2\/5 {
    width: 40%;
  }

  .sm\:tw-w-3\/5 {
    width: 60%;
  }

  .sm\:tw-w-4\/5 {
    width: 80%;
  }

  .sm\:tw-w-1\/6 {
    width: 16.666667%;
  }

  .sm\:tw-w-2\/6 {
    width: 33.333333%;
  }

  .sm\:tw-w-3\/6 {
    width: 50%;
  }

  .sm\:tw-w-4\/6 {
    width: 66.666667%;
  }

  .sm\:tw-w-5\/6 {
    width: 83.333333%;
  }

  .sm\:tw-w-1\/12 {
    width: 8.333333%;
  }

  .sm\:tw-w-2\/12 {
    width: 16.666667%;
  }

  .sm\:tw-w-3\/12 {
    width: 25%;
  }

  .sm\:tw-w-4\/12 {
    width: 33.333333%;
  }

  .sm\:tw-w-5\/12 {
    width: 41.666667%;
  }

  .sm\:tw-w-6\/12 {
    width: 50%;
  }

  .sm\:tw-w-7\/12 {
    width: 58.333333%;
  }

  .sm\:tw-w-8\/12 {
    width: 66.666667%;
  }

  .sm\:tw-w-9\/12 {
    width: 75%;
  }

  .sm\:tw-w-10\/12 {
    width: 83.333333%;
  }

  .sm\:tw-w-11\/12 {
    width: 91.666667%;
  }

  .sm\:tw-w-full {
    width: 100%;
  }

  .sm\:tw-w-screen {
    width: 100vw;
  }

  .sm\:tw-z-0 {
    z-index: 0;
  }

  .sm\:tw-z-10 {
    z-index: 10;
  }

  .sm\:tw-z-20 {
    z-index: 20;
  }

  .sm\:tw-z-30 {
    z-index: 30;
  }

  .sm\:tw-z-40 {
    z-index: 40;
  }

  .sm\:tw-z-50 {
    z-index: 50;
  }

  .sm\:tw-z-auto {
    z-index: auto;
  }

  .sm\:tw-gap-0 {
    grid-gap: 0;
    gap: 0;
  }

  .sm\:tw-gap-1 {
    grid-gap: 0.25rem;
    gap: 0.25rem;
  }

  .sm\:tw-gap-2 {
    grid-gap: 0.5rem;
    gap: 0.5rem;
  }

  .sm\:tw-gap-3 {
    grid-gap: 0.75rem;
    gap: 0.75rem;
  }

  .sm\:tw-gap-4 {
    grid-gap: 1rem;
    gap: 1rem;
  }

  .sm\:tw-gap-5 {
    grid-gap: 1.25rem;
    gap: 1.25rem;
  }

  .sm\:tw-gap-6 {
    grid-gap: 1.5rem;
    gap: 1.5rem;
  }

  .sm\:tw-gap-8 {
    grid-gap: 2rem;
    gap: 2rem;
  }

  .sm\:tw-gap-10 {
    grid-gap: 2.5rem;
    gap: 2.5rem;
  }

  .sm\:tw-gap-12 {
    grid-gap: 3rem;
    gap: 3rem;
  }

  .sm\:tw-gap-16 {
    grid-gap: 4rem;
    gap: 4rem;
  }

  .sm\:tw-gap-20 {
    grid-gap: 5rem;
    gap: 5rem;
  }

  .sm\:tw-gap-24 {
    grid-gap: 6rem;
    gap: 6rem;
  }

  .sm\:tw-gap-32 {
    grid-gap: 8rem;
    gap: 8rem;
  }

  .sm\:tw-gap-40 {
    grid-gap: 10rem;
    gap: 10rem;
  }

  .sm\:tw-gap-48 {
    grid-gap: 12rem;
    gap: 12rem;
  }

  .sm\:tw-gap-56 {
    grid-gap: 14rem;
    gap: 14rem;
  }

  .sm\:tw-gap-64 {
    grid-gap: 16rem;
    gap: 16rem;
  }

  .sm\:tw-gap-px {
    grid-gap: 1px;
    gap: 1px;
  }

  .sm\:tw-gap-270px {
    grid-gap: 270px;
    gap: 270px;
  }

  .sm\:tw-gap-400px {
    grid-gap: 400px;
    gap: 400px;
  }

  .sm\:tw-gap-464px {
    grid-gap: 464px;
    gap: 464px;
  }

  .sm\:tw-gap-480px {
    grid-gap: 480px;
    gap: 480px;
  }

  .sm\:tw-gap-500px {
    grid-gap: 500px;
    gap: 500px;
  }

  .sm\:tw-gap-576px {
    grid-gap: 576px;
    gap: 576px;
  }

  .sm\:tw-gap-600px {
    grid-gap: 600px;
    gap: 600px;
  }

  .sm\:tw-gap-672px {
    grid-gap: 672px;
    gap: 672px;
  }

  .sm\:tw-col-gap-0 {
    grid-column-gap: 0;
    -moz-column-gap: 0;
         column-gap: 0;
  }

  .sm\:tw-col-gap-1 {
    grid-column-gap: 0.25rem;
    -moz-column-gap: 0.25rem;
         column-gap: 0.25rem;
  }

  .sm\:tw-col-gap-2 {
    grid-column-gap: 0.5rem;
    -moz-column-gap: 0.5rem;
         column-gap: 0.5rem;
  }

  .sm\:tw-col-gap-3 {
    grid-column-gap: 0.75rem;
    -moz-column-gap: 0.75rem;
         column-gap: 0.75rem;
  }

  .sm\:tw-col-gap-4 {
    grid-column-gap: 1rem;
    -moz-column-gap: 1rem;
         column-gap: 1rem;
  }

  .sm\:tw-col-gap-5 {
    grid-column-gap: 1.25rem;
    -moz-column-gap: 1.25rem;
         column-gap: 1.25rem;
  }

  .sm\:tw-col-gap-6 {
    grid-column-gap: 1.5rem;
    -moz-column-gap: 1.5rem;
         column-gap: 1.5rem;
  }

  .sm\:tw-col-gap-8 {
    grid-column-gap: 2rem;
    -moz-column-gap: 2rem;
         column-gap: 2rem;
  }

  .sm\:tw-col-gap-10 {
    grid-column-gap: 2.5rem;
    -moz-column-gap: 2.5rem;
         column-gap: 2.5rem;
  }

  .sm\:tw-col-gap-12 {
    grid-column-gap: 3rem;
    -moz-column-gap: 3rem;
         column-gap: 3rem;
  }

  .sm\:tw-col-gap-16 {
    grid-column-gap: 4rem;
    -moz-column-gap: 4rem;
         column-gap: 4rem;
  }

  .sm\:tw-col-gap-20 {
    grid-column-gap: 5rem;
    -moz-column-gap: 5rem;
         column-gap: 5rem;
  }

  .sm\:tw-col-gap-24 {
    grid-column-gap: 6rem;
    -moz-column-gap: 6rem;
         column-gap: 6rem;
  }

  .sm\:tw-col-gap-32 {
    grid-column-gap: 8rem;
    -moz-column-gap: 8rem;
         column-gap: 8rem;
  }

  .sm\:tw-col-gap-40 {
    grid-column-gap: 10rem;
    -moz-column-gap: 10rem;
         column-gap: 10rem;
  }

  .sm\:tw-col-gap-48 {
    grid-column-gap: 12rem;
    -moz-column-gap: 12rem;
         column-gap: 12rem;
  }

  .sm\:tw-col-gap-56 {
    grid-column-gap: 14rem;
    -moz-column-gap: 14rem;
         column-gap: 14rem;
  }

  .sm\:tw-col-gap-64 {
    grid-column-gap: 16rem;
    -moz-column-gap: 16rem;
         column-gap: 16rem;
  }

  .sm\:tw-col-gap-px {
    grid-column-gap: 1px;
    -moz-column-gap: 1px;
         column-gap: 1px;
  }

  .sm\:tw-col-gap-270px {
    grid-column-gap: 270px;
    -moz-column-gap: 270px;
         column-gap: 270px;
  }

  .sm\:tw-col-gap-400px {
    grid-column-gap: 400px;
    -moz-column-gap: 400px;
         column-gap: 400px;
  }

  .sm\:tw-col-gap-464px {
    grid-column-gap: 464px;
    -moz-column-gap: 464px;
         column-gap: 464px;
  }

  .sm\:tw-col-gap-480px {
    grid-column-gap: 480px;
    -moz-column-gap: 480px;
         column-gap: 480px;
  }

  .sm\:tw-col-gap-500px {
    grid-column-gap: 500px;
    -moz-column-gap: 500px;
         column-gap: 500px;
  }

  .sm\:tw-col-gap-576px {
    grid-column-gap: 576px;
    -moz-column-gap: 576px;
         column-gap: 576px;
  }

  .sm\:tw-col-gap-600px {
    grid-column-gap: 600px;
    -moz-column-gap: 600px;
         column-gap: 600px;
  }

  .sm\:tw-col-gap-672px {
    grid-column-gap: 672px;
    -moz-column-gap: 672px;
         column-gap: 672px;
  }

  .sm\:tw-gap-x-0 {
    grid-column-gap: 0;
    -moz-column-gap: 0;
         column-gap: 0;
  }

  .sm\:tw-gap-x-1 {
    grid-column-gap: 0.25rem;
    -moz-column-gap: 0.25rem;
         column-gap: 0.25rem;
  }

  .sm\:tw-gap-x-2 {
    grid-column-gap: 0.5rem;
    -moz-column-gap: 0.5rem;
         column-gap: 0.5rem;
  }

  .sm\:tw-gap-x-3 {
    grid-column-gap: 0.75rem;
    -moz-column-gap: 0.75rem;
         column-gap: 0.75rem;
  }

  .sm\:tw-gap-x-4 {
    grid-column-gap: 1rem;
    -moz-column-gap: 1rem;
         column-gap: 1rem;
  }

  .sm\:tw-gap-x-5 {
    grid-column-gap: 1.25rem;
    -moz-column-gap: 1.25rem;
         column-gap: 1.25rem;
  }

  .sm\:tw-gap-x-6 {
    grid-column-gap: 1.5rem;
    -moz-column-gap: 1.5rem;
         column-gap: 1.5rem;
  }

  .sm\:tw-gap-x-8 {
    grid-column-gap: 2rem;
    -moz-column-gap: 2rem;
         column-gap: 2rem;
  }

  .sm\:tw-gap-x-10 {
    grid-column-gap: 2.5rem;
    -moz-column-gap: 2.5rem;
         column-gap: 2.5rem;
  }

  .sm\:tw-gap-x-12 {
    grid-column-gap: 3rem;
    -moz-column-gap: 3rem;
         column-gap: 3rem;
  }

  .sm\:tw-gap-x-16 {
    grid-column-gap: 4rem;
    -moz-column-gap: 4rem;
         column-gap: 4rem;
  }

  .sm\:tw-gap-x-20 {
    grid-column-gap: 5rem;
    -moz-column-gap: 5rem;
         column-gap: 5rem;
  }

  .sm\:tw-gap-x-24 {
    grid-column-gap: 6rem;
    -moz-column-gap: 6rem;
         column-gap: 6rem;
  }

  .sm\:tw-gap-x-32 {
    grid-column-gap: 8rem;
    -moz-column-gap: 8rem;
         column-gap: 8rem;
  }

  .sm\:tw-gap-x-40 {
    grid-column-gap: 10rem;
    -moz-column-gap: 10rem;
         column-gap: 10rem;
  }

  .sm\:tw-gap-x-48 {
    grid-column-gap: 12rem;
    -moz-column-gap: 12rem;
         column-gap: 12rem;
  }

  .sm\:tw-gap-x-56 {
    grid-column-gap: 14rem;
    -moz-column-gap: 14rem;
         column-gap: 14rem;
  }

  .sm\:tw-gap-x-64 {
    grid-column-gap: 16rem;
    -moz-column-gap: 16rem;
         column-gap: 16rem;
  }

  .sm\:tw-gap-x-px {
    grid-column-gap: 1px;
    -moz-column-gap: 1px;
         column-gap: 1px;
  }

  .sm\:tw-gap-x-270px {
    grid-column-gap: 270px;
    -moz-column-gap: 270px;
         column-gap: 270px;
  }

  .sm\:tw-gap-x-400px {
    grid-column-gap: 400px;
    -moz-column-gap: 400px;
         column-gap: 400px;
  }

  .sm\:tw-gap-x-464px {
    grid-column-gap: 464px;
    -moz-column-gap: 464px;
         column-gap: 464px;
  }

  .sm\:tw-gap-x-480px {
    grid-column-gap: 480px;
    -moz-column-gap: 480px;
         column-gap: 480px;
  }

  .sm\:tw-gap-x-500px {
    grid-column-gap: 500px;
    -moz-column-gap: 500px;
         column-gap: 500px;
  }

  .sm\:tw-gap-x-576px {
    grid-column-gap: 576px;
    -moz-column-gap: 576px;
         column-gap: 576px;
  }

  .sm\:tw-gap-x-600px {
    grid-column-gap: 600px;
    -moz-column-gap: 600px;
         column-gap: 600px;
  }

  .sm\:tw-gap-x-672px {
    grid-column-gap: 672px;
    -moz-column-gap: 672px;
         column-gap: 672px;
  }

  .sm\:tw-row-gap-0 {
    grid-row-gap: 0;
    row-gap: 0;
  }

  .sm\:tw-row-gap-1 {
    grid-row-gap: 0.25rem;
    row-gap: 0.25rem;
  }

  .sm\:tw-row-gap-2 {
    grid-row-gap: 0.5rem;
    row-gap: 0.5rem;
  }

  .sm\:tw-row-gap-3 {
    grid-row-gap: 0.75rem;
    row-gap: 0.75rem;
  }

  .sm\:tw-row-gap-4 {
    grid-row-gap: 1rem;
    row-gap: 1rem;
  }

  .sm\:tw-row-gap-5 {
    grid-row-gap: 1.25rem;
    row-gap: 1.25rem;
  }

  .sm\:tw-row-gap-6 {
    grid-row-gap: 1.5rem;
    row-gap: 1.5rem;
  }

  .sm\:tw-row-gap-8 {
    grid-row-gap: 2rem;
    row-gap: 2rem;
  }

  .sm\:tw-row-gap-10 {
    grid-row-gap: 2.5rem;
    row-gap: 2.5rem;
  }

  .sm\:tw-row-gap-12 {
    grid-row-gap: 3rem;
    row-gap: 3rem;
  }

  .sm\:tw-row-gap-16 {
    grid-row-gap: 4rem;
    row-gap: 4rem;
  }

  .sm\:tw-row-gap-20 {
    grid-row-gap: 5rem;
    row-gap: 5rem;
  }

  .sm\:tw-row-gap-24 {
    grid-row-gap: 6rem;
    row-gap: 6rem;
  }

  .sm\:tw-row-gap-32 {
    grid-row-gap: 8rem;
    row-gap: 8rem;
  }

  .sm\:tw-row-gap-40 {
    grid-row-gap: 10rem;
    row-gap: 10rem;
  }

  .sm\:tw-row-gap-48 {
    grid-row-gap: 12rem;
    row-gap: 12rem;
  }

  .sm\:tw-row-gap-56 {
    grid-row-gap: 14rem;
    row-gap: 14rem;
  }

  .sm\:tw-row-gap-64 {
    grid-row-gap: 16rem;
    row-gap: 16rem;
  }

  .sm\:tw-row-gap-px {
    grid-row-gap: 1px;
    row-gap: 1px;
  }

  .sm\:tw-row-gap-270px {
    grid-row-gap: 270px;
    row-gap: 270px;
  }

  .sm\:tw-row-gap-400px {
    grid-row-gap: 400px;
    row-gap: 400px;
  }

  .sm\:tw-row-gap-464px {
    grid-row-gap: 464px;
    row-gap: 464px;
  }

  .sm\:tw-row-gap-480px {
    grid-row-gap: 480px;
    row-gap: 480px;
  }

  .sm\:tw-row-gap-500px {
    grid-row-gap: 500px;
    row-gap: 500px;
  }

  .sm\:tw-row-gap-576px {
    grid-row-gap: 576px;
    row-gap: 576px;
  }

  .sm\:tw-row-gap-600px {
    grid-row-gap: 600px;
    row-gap: 600px;
  }

  .sm\:tw-row-gap-672px {
    grid-row-gap: 672px;
    row-gap: 672px;
  }

  .sm\:tw-gap-y-0 {
    grid-row-gap: 0;
    row-gap: 0;
  }

  .sm\:tw-gap-y-1 {
    grid-row-gap: 0.25rem;
    row-gap: 0.25rem;
  }

  .sm\:tw-gap-y-2 {
    grid-row-gap: 0.5rem;
    row-gap: 0.5rem;
  }

  .sm\:tw-gap-y-3 {
    grid-row-gap: 0.75rem;
    row-gap: 0.75rem;
  }

  .sm\:tw-gap-y-4 {
    grid-row-gap: 1rem;
    row-gap: 1rem;
  }

  .sm\:tw-gap-y-5 {
    grid-row-gap: 1.25rem;
    row-gap: 1.25rem;
  }

  .sm\:tw-gap-y-6 {
    grid-row-gap: 1.5rem;
    row-gap: 1.5rem;
  }

  .sm\:tw-gap-y-8 {
    grid-row-gap: 2rem;
    row-gap: 2rem;
  }

  .sm\:tw-gap-y-10 {
    grid-row-gap: 2.5rem;
    row-gap: 2.5rem;
  }

  .sm\:tw-gap-y-12 {
    grid-row-gap: 3rem;
    row-gap: 3rem;
  }

  .sm\:tw-gap-y-16 {
    grid-row-gap: 4rem;
    row-gap: 4rem;
  }

  .sm\:tw-gap-y-20 {
    grid-row-gap: 5rem;
    row-gap: 5rem;
  }

  .sm\:tw-gap-y-24 {
    grid-row-gap: 6rem;
    row-gap: 6rem;
  }

  .sm\:tw-gap-y-32 {
    grid-row-gap: 8rem;
    row-gap: 8rem;
  }

  .sm\:tw-gap-y-40 {
    grid-row-gap: 10rem;
    row-gap: 10rem;
  }

  .sm\:tw-gap-y-48 {
    grid-row-gap: 12rem;
    row-gap: 12rem;
  }

  .sm\:tw-gap-y-56 {
    grid-row-gap: 14rem;
    row-gap: 14rem;
  }

  .sm\:tw-gap-y-64 {
    grid-row-gap: 16rem;
    row-gap: 16rem;
  }

  .sm\:tw-gap-y-px {
    grid-row-gap: 1px;
    row-gap: 1px;
  }

  .sm\:tw-gap-y-270px {
    grid-row-gap: 270px;
    row-gap: 270px;
  }

  .sm\:tw-gap-y-400px {
    grid-row-gap: 400px;
    row-gap: 400px;
  }

  .sm\:tw-gap-y-464px {
    grid-row-gap: 464px;
    row-gap: 464px;
  }

  .sm\:tw-gap-y-480px {
    grid-row-gap: 480px;
    row-gap: 480px;
  }

  .sm\:tw-gap-y-500px {
    grid-row-gap: 500px;
    row-gap: 500px;
  }

  .sm\:tw-gap-y-576px {
    grid-row-gap: 576px;
    row-gap: 576px;
  }

  .sm\:tw-gap-y-600px {
    grid-row-gap: 600px;
    row-gap: 600px;
  }

  .sm\:tw-gap-y-672px {
    grid-row-gap: 672px;
    row-gap: 672px;
  }

  .sm\:tw-grid-flow-row {
    grid-auto-flow: row;
  }

  .sm\:tw-grid-flow-col {
    grid-auto-flow: column;
  }

  .sm\:tw-grid-flow-row-dense {
    grid-auto-flow: row dense;
  }

  .sm\:tw-grid-flow-col-dense {
    grid-auto-flow: column dense;
  }

  .sm\:tw-grid-cols-1 {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }

  .sm\:tw-grid-cols-2 {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  .sm\:tw-grid-cols-3 {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }

  .sm\:tw-grid-cols-4 {
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }

  .sm\:tw-grid-cols-5 {
    grid-template-columns: repeat(5, minmax(0, 1fr));
  }

  .sm\:tw-grid-cols-6 {
    grid-template-columns: repeat(6, minmax(0, 1fr));
  }

  .sm\:tw-grid-cols-7 {
    grid-template-columns: repeat(7, minmax(0, 1fr));
  }

  .sm\:tw-grid-cols-8 {
    grid-template-columns: repeat(8, minmax(0, 1fr));
  }

  .sm\:tw-grid-cols-9 {
    grid-template-columns: repeat(9, minmax(0, 1fr));
  }

  .sm\:tw-grid-cols-10 {
    grid-template-columns: repeat(10, minmax(0, 1fr));
  }

  .sm\:tw-grid-cols-11 {
    grid-template-columns: repeat(11, minmax(0, 1fr));
  }

  .sm\:tw-grid-cols-12 {
    grid-template-columns: repeat(12, minmax(0, 1fr));
  }

  .sm\:tw-grid-cols-none {
    grid-template-columns: none;
  }

  .sm\:tw-auto-cols-auto {
    grid-auto-columns: auto;
  }

  .sm\:tw-auto-cols-min {
    grid-auto-columns: min-content;
  }

  .sm\:tw-auto-cols-max {
    grid-auto-columns: max-content;
  }

  .sm\:tw-auto-cols-fr {
    grid-auto-columns: minmax(0, 1fr);
  }

  .sm\:tw-col-auto {
    grid-column: auto;
  }

  .sm\:tw-col-span-1 {
    grid-column: span 1 / span 1;
  }

  .sm\:tw-col-span-2 {
    grid-column: span 2 / span 2;
  }

  .sm\:tw-col-span-3 {
    grid-column: span 3 / span 3;
  }

  .sm\:tw-col-span-4 {
    grid-column: span 4 / span 4;
  }

  .sm\:tw-col-span-5 {
    grid-column: span 5 / span 5;
  }

  .sm\:tw-col-span-6 {
    grid-column: span 6 / span 6;
  }

  .sm\:tw-col-span-7 {
    grid-column: span 7 / span 7;
  }

  .sm\:tw-col-span-8 {
    grid-column: span 8 / span 8;
  }

  .sm\:tw-col-span-9 {
    grid-column: span 9 / span 9;
  }

  .sm\:tw-col-span-10 {
    grid-column: span 10 / span 10;
  }

  .sm\:tw-col-span-11 {
    grid-column: span 11 / span 11;
  }

  .sm\:tw-col-span-12 {
    grid-column: span 12 / span 12;
  }

  .sm\:tw-col-span-full {
    grid-column: 1 / -1;
  }

  .sm\:tw-col-start-1 {
    grid-column-start: 1;
  }

  .sm\:tw-col-start-2 {
    grid-column-start: 2;
  }

  .sm\:tw-col-start-3 {
    grid-column-start: 3;
  }

  .sm\:tw-col-start-4 {
    grid-column-start: 4;
  }

  .sm\:tw-col-start-5 {
    grid-column-start: 5;
  }

  .sm\:tw-col-start-6 {
    grid-column-start: 6;
  }

  .sm\:tw-col-start-7 {
    grid-column-start: 7;
  }

  .sm\:tw-col-start-8 {
    grid-column-start: 8;
  }

  .sm\:tw-col-start-9 {
    grid-column-start: 9;
  }

  .sm\:tw-col-start-10 {
    grid-column-start: 10;
  }

  .sm\:tw-col-start-11 {
    grid-column-start: 11;
  }

  .sm\:tw-col-start-12 {
    grid-column-start: 12;
  }

  .sm\:tw-col-start-13 {
    grid-column-start: 13;
  }

  .sm\:tw-col-start-auto {
    grid-column-start: auto;
  }

  .sm\:tw-col-end-1 {
    grid-column-end: 1;
  }

  .sm\:tw-col-end-2 {
    grid-column-end: 2;
  }

  .sm\:tw-col-end-3 {
    grid-column-end: 3;
  }

  .sm\:tw-col-end-4 {
    grid-column-end: 4;
  }

  .sm\:tw-col-end-5 {
    grid-column-end: 5;
  }

  .sm\:tw-col-end-6 {
    grid-column-end: 6;
  }

  .sm\:tw-col-end-7 {
    grid-column-end: 7;
  }

  .sm\:tw-col-end-8 {
    grid-column-end: 8;
  }

  .sm\:tw-col-end-9 {
    grid-column-end: 9;
  }

  .sm\:tw-col-end-10 {
    grid-column-end: 10;
  }

  .sm\:tw-col-end-11 {
    grid-column-end: 11;
  }

  .sm\:tw-col-end-12 {
    grid-column-end: 12;
  }

  .sm\:tw-col-end-13 {
    grid-column-end: 13;
  }

  .sm\:tw-col-end-auto {
    grid-column-end: auto;
  }

  .sm\:tw-grid-rows-1 {
    grid-template-rows: repeat(1, minmax(0, 1fr));
  }

  .sm\:tw-grid-rows-2 {
    grid-template-rows: repeat(2, minmax(0, 1fr));
  }

  .sm\:tw-grid-rows-3 {
    grid-template-rows: repeat(3, minmax(0, 1fr));
  }

  .sm\:tw-grid-rows-4 {
    grid-template-rows: repeat(4, minmax(0, 1fr));
  }

  .sm\:tw-grid-rows-5 {
    grid-template-rows: repeat(5, minmax(0, 1fr));
  }

  .sm\:tw-grid-rows-6 {
    grid-template-rows: repeat(6, minmax(0, 1fr));
  }

  .sm\:tw-grid-rows-none {
    grid-template-rows: none;
  }

  .sm\:tw-auto-rows-auto {
    grid-auto-rows: auto;
  }

  .sm\:tw-auto-rows-min {
    grid-auto-rows: min-content;
  }

  .sm\:tw-auto-rows-max {
    grid-auto-rows: max-content;
  }

  .sm\:tw-auto-rows-fr {
    grid-auto-rows: minmax(0, 1fr);
  }

  .sm\:tw-row-auto {
    grid-row: auto;
  }

  .sm\:tw-row-span-1 {
    grid-row: span 1 / span 1;
  }

  .sm\:tw-row-span-2 {
    grid-row: span 2 / span 2;
  }

  .sm\:tw-row-span-3 {
    grid-row: span 3 / span 3;
  }

  .sm\:tw-row-span-4 {
    grid-row: span 4 / span 4;
  }

  .sm\:tw-row-span-5 {
    grid-row: span 5 / span 5;
  }

  .sm\:tw-row-span-6 {
    grid-row: span 6 / span 6;
  }

  .sm\:tw-row-span-full {
    grid-row: 1 / -1;
  }

  .sm\:tw-row-start-1 {
    grid-row-start: 1;
  }

  .sm\:tw-row-start-2 {
    grid-row-start: 2;
  }

  .sm\:tw-row-start-3 {
    grid-row-start: 3;
  }

  .sm\:tw-row-start-4 {
    grid-row-start: 4;
  }

  .sm\:tw-row-start-5 {
    grid-row-start: 5;
  }

  .sm\:tw-row-start-6 {
    grid-row-start: 6;
  }

  .sm\:tw-row-start-7 {
    grid-row-start: 7;
  }

  .sm\:tw-row-start-auto {
    grid-row-start: auto;
  }

  .sm\:tw-row-end-1 {
    grid-row-end: 1;
  }

  .sm\:tw-row-end-2 {
    grid-row-end: 2;
  }

  .sm\:tw-row-end-3 {
    grid-row-end: 3;
  }

  .sm\:tw-row-end-4 {
    grid-row-end: 4;
  }

  .sm\:tw-row-end-5 {
    grid-row-end: 5;
  }

  .sm\:tw-row-end-6 {
    grid-row-end: 6;
  }

  .sm\:tw-row-end-7 {
    grid-row-end: 7;
  }

  .sm\:tw-row-end-auto {
    grid-row-end: auto;
  }

  .sm\:tw-transform {
    --transform-translate-x: 0;
    --transform-translate-y: 0;
    --transform-rotate: 0;
    --transform-skew-x: 0;
    --transform-skew-y: 0;
    --transform-scale-x: 1;
    --transform-scale-y: 1;
    transform: translateX(var(--transform-translate-x)) translateY(var(--transform-translate-y)) rotate(var(--transform-rotate)) skewX(var(--transform-skew-x)) skewY(var(--transform-skew-y)) scaleX(var(--transform-scale-x)) scaleY(var(--transform-scale-y));
  }

  .sm\:tw-transform-none {
    transform: none;
  }

  .sm\:tw-origin-center {
    transform-origin: center;
  }

  .sm\:tw-origin-top {
    transform-origin: top;
  }

  .sm\:tw-origin-top-right {
    transform-origin: top right;
  }

  .sm\:tw-origin-right {
    transform-origin: right;
  }

  .sm\:tw-origin-bottom-right {
    transform-origin: bottom right;
  }

  .sm\:tw-origin-bottom {
    transform-origin: bottom;
  }

  .sm\:tw-origin-bottom-left {
    transform-origin: bottom left;
  }

  .sm\:tw-origin-left {
    transform-origin: left;
  }

  .sm\:tw-origin-top-left {
    transform-origin: top left;
  }

  .sm\:tw-scale-0 {
    --transform-scale-x: 0;
    --transform-scale-y: 0;
  }

  .sm\:tw-scale-50 {
    --transform-scale-x: .5;
    --transform-scale-y: .5;
  }

  .sm\:tw-scale-75 {
    --transform-scale-x: .75;
    --transform-scale-y: .75;
  }

  .sm\:tw-scale-90 {
    --transform-scale-x: .9;
    --transform-scale-y: .9;
  }

  .sm\:tw-scale-95 {
    --transform-scale-x: .95;
    --transform-scale-y: .95;
  }

  .sm\:tw-scale-100 {
    --transform-scale-x: 1;
    --transform-scale-y: 1;
  }

  .sm\:tw-scale-105 {
    --transform-scale-x: 1.05;
    --transform-scale-y: 1.05;
  }

  .sm\:tw-scale-110 {
    --transform-scale-x: 1.1;
    --transform-scale-y: 1.1;
  }

  .sm\:tw-scale-125 {
    --transform-scale-x: 1.25;
    --transform-scale-y: 1.25;
  }

  .sm\:tw-scale-150 {
    --transform-scale-x: 1.5;
    --transform-scale-y: 1.5;
  }

  .sm\:tw-scale-x-0 {
    --transform-scale-x: 0;
  }

  .sm\:tw-scale-x-50 {
    --transform-scale-x: .5;
  }

  .sm\:tw-scale-x-75 {
    --transform-scale-x: .75;
  }

  .sm\:tw-scale-x-90 {
    --transform-scale-x: .9;
  }

  .sm\:tw-scale-x-95 {
    --transform-scale-x: .95;
  }

  .sm\:tw-scale-x-100 {
    --transform-scale-x: 1;
  }

  .sm\:tw-scale-x-105 {
    --transform-scale-x: 1.05;
  }

  .sm\:tw-scale-x-110 {
    --transform-scale-x: 1.1;
  }

  .sm\:tw-scale-x-125 {
    --transform-scale-x: 1.25;
  }

  .sm\:tw-scale-x-150 {
    --transform-scale-x: 1.5;
  }

  .sm\:tw-scale-y-0 {
    --transform-scale-y: 0;
  }

  .sm\:tw-scale-y-50 {
    --transform-scale-y: .5;
  }

  .sm\:tw-scale-y-75 {
    --transform-scale-y: .75;
  }

  .sm\:tw-scale-y-90 {
    --transform-scale-y: .9;
  }

  .sm\:tw-scale-y-95 {
    --transform-scale-y: .95;
  }

  .sm\:tw-scale-y-100 {
    --transform-scale-y: 1;
  }

  .sm\:tw-scale-y-105 {
    --transform-scale-y: 1.05;
  }

  .sm\:tw-scale-y-110 {
    --transform-scale-y: 1.1;
  }

  .sm\:tw-scale-y-125 {
    --transform-scale-y: 1.25;
  }

  .sm\:tw-scale-y-150 {
    --transform-scale-y: 1.5;
  }

  .sm\:hover\:tw-scale-0:hover {
    --transform-scale-x: 0;
    --transform-scale-y: 0;
  }

  .sm\:hover\:tw-scale-50:hover {
    --transform-scale-x: .5;
    --transform-scale-y: .5;
  }

  .sm\:hover\:tw-scale-75:hover {
    --transform-scale-x: .75;
    --transform-scale-y: .75;
  }

  .sm\:hover\:tw-scale-90:hover {
    --transform-scale-x: .9;
    --transform-scale-y: .9;
  }

  .sm\:hover\:tw-scale-95:hover {
    --transform-scale-x: .95;
    --transform-scale-y: .95;
  }

  .sm\:hover\:tw-scale-100:hover {
    --transform-scale-x: 1;
    --transform-scale-y: 1;
  }

  .sm\:hover\:tw-scale-105:hover {
    --transform-scale-x: 1.05;
    --transform-scale-y: 1.05;
  }

  .sm\:hover\:tw-scale-110:hover {
    --transform-scale-x: 1.1;
    --transform-scale-y: 1.1;
  }

  .sm\:hover\:tw-scale-125:hover {
    --transform-scale-x: 1.25;
    --transform-scale-y: 1.25;
  }

  .sm\:hover\:tw-scale-150:hover {
    --transform-scale-x: 1.5;
    --transform-scale-y: 1.5;
  }

  .sm\:hover\:tw-scale-x-0:hover {
    --transform-scale-x: 0;
  }

  .sm\:hover\:tw-scale-x-50:hover {
    --transform-scale-x: .5;
  }

  .sm\:hover\:tw-scale-x-75:hover {
    --transform-scale-x: .75;
  }

  .sm\:hover\:tw-scale-x-90:hover {
    --transform-scale-x: .9;
  }

  .sm\:hover\:tw-scale-x-95:hover {
    --transform-scale-x: .95;
  }

  .sm\:hover\:tw-scale-x-100:hover {
    --transform-scale-x: 1;
  }

  .sm\:hover\:tw-scale-x-105:hover {
    --transform-scale-x: 1.05;
  }

  .sm\:hover\:tw-scale-x-110:hover {
    --transform-scale-x: 1.1;
  }

  .sm\:hover\:tw-scale-x-125:hover {
    --transform-scale-x: 1.25;
  }

  .sm\:hover\:tw-scale-x-150:hover {
    --transform-scale-x: 1.5;
  }

  .sm\:hover\:tw-scale-y-0:hover {
    --transform-scale-y: 0;
  }

  .sm\:hover\:tw-scale-y-50:hover {
    --transform-scale-y: .5;
  }

  .sm\:hover\:tw-scale-y-75:hover {
    --transform-scale-y: .75;
  }

  .sm\:hover\:tw-scale-y-90:hover {
    --transform-scale-y: .9;
  }

  .sm\:hover\:tw-scale-y-95:hover {
    --transform-scale-y: .95;
  }

  .sm\:hover\:tw-scale-y-100:hover {
    --transform-scale-y: 1;
  }

  .sm\:hover\:tw-scale-y-105:hover {
    --transform-scale-y: 1.05;
  }

  .sm\:hover\:tw-scale-y-110:hover {
    --transform-scale-y: 1.1;
  }

  .sm\:hover\:tw-scale-y-125:hover {
    --transform-scale-y: 1.25;
  }

  .sm\:hover\:tw-scale-y-150:hover {
    --transform-scale-y: 1.5;
  }

  .sm\:focus\:tw-scale-0:focus {
    --transform-scale-x: 0;
    --transform-scale-y: 0;
  }

  .sm\:focus\:tw-scale-50:focus {
    --transform-scale-x: .5;
    --transform-scale-y: .5;
  }

  .sm\:focus\:tw-scale-75:focus {
    --transform-scale-x: .75;
    --transform-scale-y: .75;
  }

  .sm\:focus\:tw-scale-90:focus {
    --transform-scale-x: .9;
    --transform-scale-y: .9;
  }

  .sm\:focus\:tw-scale-95:focus {
    --transform-scale-x: .95;
    --transform-scale-y: .95;
  }

  .sm\:focus\:tw-scale-100:focus {
    --transform-scale-x: 1;
    --transform-scale-y: 1;
  }

  .sm\:focus\:tw-scale-105:focus {
    --transform-scale-x: 1.05;
    --transform-scale-y: 1.05;
  }

  .sm\:focus\:tw-scale-110:focus {
    --transform-scale-x: 1.1;
    --transform-scale-y: 1.1;
  }

  .sm\:focus\:tw-scale-125:focus {
    --transform-scale-x: 1.25;
    --transform-scale-y: 1.25;
  }

  .sm\:focus\:tw-scale-150:focus {
    --transform-scale-x: 1.5;
    --transform-scale-y: 1.5;
  }

  .sm\:focus\:tw-scale-x-0:focus {
    --transform-scale-x: 0;
  }

  .sm\:focus\:tw-scale-x-50:focus {
    --transform-scale-x: .5;
  }

  .sm\:focus\:tw-scale-x-75:focus {
    --transform-scale-x: .75;
  }

  .sm\:focus\:tw-scale-x-90:focus {
    --transform-scale-x: .9;
  }

  .sm\:focus\:tw-scale-x-95:focus {
    --transform-scale-x: .95;
  }

  .sm\:focus\:tw-scale-x-100:focus {
    --transform-scale-x: 1;
  }

  .sm\:focus\:tw-scale-x-105:focus {
    --transform-scale-x: 1.05;
  }

  .sm\:focus\:tw-scale-x-110:focus {
    --transform-scale-x: 1.1;
  }

  .sm\:focus\:tw-scale-x-125:focus {
    --transform-scale-x: 1.25;
  }

  .sm\:focus\:tw-scale-x-150:focus {
    --transform-scale-x: 1.5;
  }

  .sm\:focus\:tw-scale-y-0:focus {
    --transform-scale-y: 0;
  }

  .sm\:focus\:tw-scale-y-50:focus {
    --transform-scale-y: .5;
  }

  .sm\:focus\:tw-scale-y-75:focus {
    --transform-scale-y: .75;
  }

  .sm\:focus\:tw-scale-y-90:focus {
    --transform-scale-y: .9;
  }

  .sm\:focus\:tw-scale-y-95:focus {
    --transform-scale-y: .95;
  }

  .sm\:focus\:tw-scale-y-100:focus {
    --transform-scale-y: 1;
  }

  .sm\:focus\:tw-scale-y-105:focus {
    --transform-scale-y: 1.05;
  }

  .sm\:focus\:tw-scale-y-110:focus {
    --transform-scale-y: 1.1;
  }

  .sm\:focus\:tw-scale-y-125:focus {
    --transform-scale-y: 1.25;
  }

  .sm\:focus\:tw-scale-y-150:focus {
    --transform-scale-y: 1.5;
  }

  .sm\:tw-rotate-0 {
    --transform-rotate: 0;
  }

  .sm\:tw-rotate-1 {
    --transform-rotate: 1deg;
  }

  .sm\:tw-rotate-2 {
    --transform-rotate: 2deg;
  }

  .sm\:tw-rotate-3 {
    --transform-rotate: 3deg;
  }

  .sm\:tw-rotate-6 {
    --transform-rotate: 6deg;
  }

  .sm\:tw-rotate-12 {
    --transform-rotate: 12deg;
  }

  .sm\:tw-rotate-45 {
    --transform-rotate: 45deg;
  }

  .sm\:tw-rotate-90 {
    --transform-rotate: 90deg;
  }

  .sm\:tw-rotate-180 {
    --transform-rotate: 180deg;
  }

  .sm\:tw--rotate-180 {
    --transform-rotate: -180deg;
  }

  .sm\:tw--rotate-90 {
    --transform-rotate: -90deg;
  }

  .sm\:tw--rotate-45 {
    --transform-rotate: -45deg;
  }

  .sm\:tw--rotate-12 {
    --transform-rotate: -12deg;
  }

  .sm\:tw--rotate-6 {
    --transform-rotate: -6deg;
  }

  .sm\:tw--rotate-3 {
    --transform-rotate: -3deg;
  }

  .sm\:tw--rotate-2 {
    --transform-rotate: -2deg;
  }

  .sm\:tw--rotate-1 {
    --transform-rotate: -1deg;
  }

  .sm\:hover\:tw-rotate-0:hover {
    --transform-rotate: 0;
  }

  .sm\:hover\:tw-rotate-1:hover {
    --transform-rotate: 1deg;
  }

  .sm\:hover\:tw-rotate-2:hover {
    --transform-rotate: 2deg;
  }

  .sm\:hover\:tw-rotate-3:hover {
    --transform-rotate: 3deg;
  }

  .sm\:hover\:tw-rotate-6:hover {
    --transform-rotate: 6deg;
  }

  .sm\:hover\:tw-rotate-12:hover {
    --transform-rotate: 12deg;
  }

  .sm\:hover\:tw-rotate-45:hover {
    --transform-rotate: 45deg;
  }

  .sm\:hover\:tw-rotate-90:hover {
    --transform-rotate: 90deg;
  }

  .sm\:hover\:tw-rotate-180:hover {
    --transform-rotate: 180deg;
  }

  .sm\:hover\:tw--rotate-180:hover {
    --transform-rotate: -180deg;
  }

  .sm\:hover\:tw--rotate-90:hover {
    --transform-rotate: -90deg;
  }

  .sm\:hover\:tw--rotate-45:hover {
    --transform-rotate: -45deg;
  }

  .sm\:hover\:tw--rotate-12:hover {
    --transform-rotate: -12deg;
  }

  .sm\:hover\:tw--rotate-6:hover {
    --transform-rotate: -6deg;
  }

  .sm\:hover\:tw--rotate-3:hover {
    --transform-rotate: -3deg;
  }

  .sm\:hover\:tw--rotate-2:hover {
    --transform-rotate: -2deg;
  }

  .sm\:hover\:tw--rotate-1:hover {
    --transform-rotate: -1deg;
  }

  .sm\:focus\:tw-rotate-0:focus {
    --transform-rotate: 0;
  }

  .sm\:focus\:tw-rotate-1:focus {
    --transform-rotate: 1deg;
  }

  .sm\:focus\:tw-rotate-2:focus {
    --transform-rotate: 2deg;
  }

  .sm\:focus\:tw-rotate-3:focus {
    --transform-rotate: 3deg;
  }

  .sm\:focus\:tw-rotate-6:focus {
    --transform-rotate: 6deg;
  }

  .sm\:focus\:tw-rotate-12:focus {
    --transform-rotate: 12deg;
  }

  .sm\:focus\:tw-rotate-45:focus {
    --transform-rotate: 45deg;
  }

  .sm\:focus\:tw-rotate-90:focus {
    --transform-rotate: 90deg;
  }

  .sm\:focus\:tw-rotate-180:focus {
    --transform-rotate: 180deg;
  }

  .sm\:focus\:tw--rotate-180:focus {
    --transform-rotate: -180deg;
  }

  .sm\:focus\:tw--rotate-90:focus {
    --transform-rotate: -90deg;
  }

  .sm\:focus\:tw--rotate-45:focus {
    --transform-rotate: -45deg;
  }

  .sm\:focus\:tw--rotate-12:focus {
    --transform-rotate: -12deg;
  }

  .sm\:focus\:tw--rotate-6:focus {
    --transform-rotate: -6deg;
  }

  .sm\:focus\:tw--rotate-3:focus {
    --transform-rotate: -3deg;
  }

  .sm\:focus\:tw--rotate-2:focus {
    --transform-rotate: -2deg;
  }

  .sm\:focus\:tw--rotate-1:focus {
    --transform-rotate: -1deg;
  }

  .sm\:tw-translate-x-0 {
    --transform-translate-x: 0;
  }

  .sm\:tw-translate-x-1 {
    --transform-translate-x: 0.25rem;
  }

  .sm\:tw-translate-x-2 {
    --transform-translate-x: 0.5rem;
  }

  .sm\:tw-translate-x-3 {
    --transform-translate-x: 0.75rem;
  }

  .sm\:tw-translate-x-4 {
    --transform-translate-x: 1rem;
  }

  .sm\:tw-translate-x-5 {
    --transform-translate-x: 1.25rem;
  }

  .sm\:tw-translate-x-6 {
    --transform-translate-x: 1.5rem;
  }

  .sm\:tw-translate-x-8 {
    --transform-translate-x: 2rem;
  }

  .sm\:tw-translate-x-10 {
    --transform-translate-x: 2.5rem;
  }

  .sm\:tw-translate-x-12 {
    --transform-translate-x: 3rem;
  }

  .sm\:tw-translate-x-16 {
    --transform-translate-x: 4rem;
  }

  .sm\:tw-translate-x-20 {
    --transform-translate-x: 5rem;
  }

  .sm\:tw-translate-x-24 {
    --transform-translate-x: 6rem;
  }

  .sm\:tw-translate-x-32 {
    --transform-translate-x: 8rem;
  }

  .sm\:tw-translate-x-40 {
    --transform-translate-x: 10rem;
  }

  .sm\:tw-translate-x-48 {
    --transform-translate-x: 12rem;
  }

  .sm\:tw-translate-x-56 {
    --transform-translate-x: 14rem;
  }

  .sm\:tw-translate-x-64 {
    --transform-translate-x: 16rem;
  }

  .sm\:tw-translate-x-px {
    --transform-translate-x: 1px;
  }

  .sm\:tw-translate-x-270px {
    --transform-translate-x: 270px;
  }

  .sm\:tw-translate-x-400px {
    --transform-translate-x: 400px;
  }

  .sm\:tw-translate-x-464px {
    --transform-translate-x: 464px;
  }

  .sm\:tw-translate-x-480px {
    --transform-translate-x: 480px;
  }

  .sm\:tw-translate-x-500px {
    --transform-translate-x: 500px;
  }

  .sm\:tw-translate-x-576px {
    --transform-translate-x: 576px;
  }

  .sm\:tw-translate-x-600px {
    --transform-translate-x: 600px;
  }

  .sm\:tw-translate-x-672px {
    --transform-translate-x: 672px;
  }

  .sm\:tw--translate-x-1 {
    --transform-translate-x: -0.25rem;
  }

  .sm\:tw--translate-x-2 {
    --transform-translate-x: -0.5rem;
  }

  .sm\:tw--translate-x-3 {
    --transform-translate-x: -0.75rem;
  }

  .sm\:tw--translate-x-4 {
    --transform-translate-x: -1rem;
  }

  .sm\:tw--translate-x-5 {
    --transform-translate-x: -1.25rem;
  }

  .sm\:tw--translate-x-6 {
    --transform-translate-x: -1.5rem;
  }

  .sm\:tw--translate-x-8 {
    --transform-translate-x: -2rem;
  }

  .sm\:tw--translate-x-10 {
    --transform-translate-x: -2.5rem;
  }

  .sm\:tw--translate-x-12 {
    --transform-translate-x: -3rem;
  }

  .sm\:tw--translate-x-16 {
    --transform-translate-x: -4rem;
  }

  .sm\:tw--translate-x-20 {
    --transform-translate-x: -5rem;
  }

  .sm\:tw--translate-x-24 {
    --transform-translate-x: -6rem;
  }

  .sm\:tw--translate-x-32 {
    --transform-translate-x: -8rem;
  }

  .sm\:tw--translate-x-40 {
    --transform-translate-x: -10rem;
  }

  .sm\:tw--translate-x-48 {
    --transform-translate-x: -12rem;
  }

  .sm\:tw--translate-x-56 {
    --transform-translate-x: -14rem;
  }

  .sm\:tw--translate-x-64 {
    --transform-translate-x: -16rem;
  }

  .sm\:tw--translate-x-px {
    --transform-translate-x: -1px;
  }

  .sm\:tw--translate-x-270px {
    --transform-translate-x: -270px;
  }

  .sm\:tw--translate-x-400px {
    --transform-translate-x: -400px;
  }

  .sm\:tw--translate-x-464px {
    --transform-translate-x: -464px;
  }

  .sm\:tw--translate-x-480px {
    --transform-translate-x: -480px;
  }

  .sm\:tw--translate-x-500px {
    --transform-translate-x: -500px;
  }

  .sm\:tw--translate-x-576px {
    --transform-translate-x: -576px;
  }

  .sm\:tw--translate-x-600px {
    --transform-translate-x: -600px;
  }

  .sm\:tw--translate-x-672px {
    --transform-translate-x: -672px;
  }

  .sm\:tw--translate-x-full {
    --transform-translate-x: -100%;
  }

  .sm\:tw--translate-x-1\/2 {
    --transform-translate-x: -50%;
  }

  .sm\:tw-translate-x-1\/2 {
    --transform-translate-x: 50%;
  }

  .sm\:tw-translate-x-full {
    --transform-translate-x: 100%;
  }

  .sm\:tw-translate-y-0 {
    --transform-translate-y: 0;
  }

  .sm\:tw-translate-y-1 {
    --transform-translate-y: 0.25rem;
  }

  .sm\:tw-translate-y-2 {
    --transform-translate-y: 0.5rem;
  }

  .sm\:tw-translate-y-3 {
    --transform-translate-y: 0.75rem;
  }

  .sm\:tw-translate-y-4 {
    --transform-translate-y: 1rem;
  }

  .sm\:tw-translate-y-5 {
    --transform-translate-y: 1.25rem;
  }

  .sm\:tw-translate-y-6 {
    --transform-translate-y: 1.5rem;
  }

  .sm\:tw-translate-y-8 {
    --transform-translate-y: 2rem;
  }

  .sm\:tw-translate-y-10 {
    --transform-translate-y: 2.5rem;
  }

  .sm\:tw-translate-y-12 {
    --transform-translate-y: 3rem;
  }

  .sm\:tw-translate-y-16 {
    --transform-translate-y: 4rem;
  }

  .sm\:tw-translate-y-20 {
    --transform-translate-y: 5rem;
  }

  .sm\:tw-translate-y-24 {
    --transform-translate-y: 6rem;
  }

  .sm\:tw-translate-y-32 {
    --transform-translate-y: 8rem;
  }

  .sm\:tw-translate-y-40 {
    --transform-translate-y: 10rem;
  }

  .sm\:tw-translate-y-48 {
    --transform-translate-y: 12rem;
  }

  .sm\:tw-translate-y-56 {
    --transform-translate-y: 14rem;
  }

  .sm\:tw-translate-y-64 {
    --transform-translate-y: 16rem;
  }

  .sm\:tw-translate-y-px {
    --transform-translate-y: 1px;
  }

  .sm\:tw-translate-y-270px {
    --transform-translate-y: 270px;
  }

  .sm\:tw-translate-y-400px {
    --transform-translate-y: 400px;
  }

  .sm\:tw-translate-y-464px {
    --transform-translate-y: 464px;
  }

  .sm\:tw-translate-y-480px {
    --transform-translate-y: 480px;
  }

  .sm\:tw-translate-y-500px {
    --transform-translate-y: 500px;
  }

  .sm\:tw-translate-y-576px {
    --transform-translate-y: 576px;
  }

  .sm\:tw-translate-y-600px {
    --transform-translate-y: 600px;
  }

  .sm\:tw-translate-y-672px {
    --transform-translate-y: 672px;
  }

  .sm\:tw--translate-y-1 {
    --transform-translate-y: -0.25rem;
  }

  .sm\:tw--translate-y-2 {
    --transform-translate-y: -0.5rem;
  }

  .sm\:tw--translate-y-3 {
    --transform-translate-y: -0.75rem;
  }

  .sm\:tw--translate-y-4 {
    --transform-translate-y: -1rem;
  }

  .sm\:tw--translate-y-5 {
    --transform-translate-y: -1.25rem;
  }

  .sm\:tw--translate-y-6 {
    --transform-translate-y: -1.5rem;
  }

  .sm\:tw--translate-y-8 {
    --transform-translate-y: -2rem;
  }

  .sm\:tw--translate-y-10 {
    --transform-translate-y: -2.5rem;
  }

  .sm\:tw--translate-y-12 {
    --transform-translate-y: -3rem;
  }

  .sm\:tw--translate-y-16 {
    --transform-translate-y: -4rem;
  }

  .sm\:tw--translate-y-20 {
    --transform-translate-y: -5rem;
  }

  .sm\:tw--translate-y-24 {
    --transform-translate-y: -6rem;
  }

  .sm\:tw--translate-y-32 {
    --transform-translate-y: -8rem;
  }

  .sm\:tw--translate-y-40 {
    --transform-translate-y: -10rem;
  }

  .sm\:tw--translate-y-48 {
    --transform-translate-y: -12rem;
  }

  .sm\:tw--translate-y-56 {
    --transform-translate-y: -14rem;
  }

  .sm\:tw--translate-y-64 {
    --transform-translate-y: -16rem;
  }

  .sm\:tw--translate-y-px {
    --transform-translate-y: -1px;
  }

  .sm\:tw--translate-y-270px {
    --transform-translate-y: -270px;
  }

  .sm\:tw--translate-y-400px {
    --transform-translate-y: -400px;
  }

  .sm\:tw--translate-y-464px {
    --transform-translate-y: -464px;
  }

  .sm\:tw--translate-y-480px {
    --transform-translate-y: -480px;
  }

  .sm\:tw--translate-y-500px {
    --transform-translate-y: -500px;
  }

  .sm\:tw--translate-y-576px {
    --transform-translate-y: -576px;
  }

  .sm\:tw--translate-y-600px {
    --transform-translate-y: -600px;
  }

  .sm\:tw--translate-y-672px {
    --transform-translate-y: -672px;
  }

  .sm\:tw--translate-y-full {
    --transform-translate-y: -100%;
  }

  .sm\:tw--translate-y-1\/2 {
    --transform-translate-y: -50%;
  }

  .sm\:tw-translate-y-1\/2 {
    --transform-translate-y: 50%;
  }

  .sm\:tw-translate-y-full {
    --transform-translate-y: 100%;
  }

  .sm\:hover\:tw-translate-x-0:hover {
    --transform-translate-x: 0;
  }

  .sm\:hover\:tw-translate-x-1:hover {
    --transform-translate-x: 0.25rem;
  }

  .sm\:hover\:tw-translate-x-2:hover {
    --transform-translate-x: 0.5rem;
  }

  .sm\:hover\:tw-translate-x-3:hover {
    --transform-translate-x: 0.75rem;
  }

  .sm\:hover\:tw-translate-x-4:hover {
    --transform-translate-x: 1rem;
  }

  .sm\:hover\:tw-translate-x-5:hover {
    --transform-translate-x: 1.25rem;
  }

  .sm\:hover\:tw-translate-x-6:hover {
    --transform-translate-x: 1.5rem;
  }

  .sm\:hover\:tw-translate-x-8:hover {
    --transform-translate-x: 2rem;
  }

  .sm\:hover\:tw-translate-x-10:hover {
    --transform-translate-x: 2.5rem;
  }

  .sm\:hover\:tw-translate-x-12:hover {
    --transform-translate-x: 3rem;
  }

  .sm\:hover\:tw-translate-x-16:hover {
    --transform-translate-x: 4rem;
  }

  .sm\:hover\:tw-translate-x-20:hover {
    --transform-translate-x: 5rem;
  }

  .sm\:hover\:tw-translate-x-24:hover {
    --transform-translate-x: 6rem;
  }

  .sm\:hover\:tw-translate-x-32:hover {
    --transform-translate-x: 8rem;
  }

  .sm\:hover\:tw-translate-x-40:hover {
    --transform-translate-x: 10rem;
  }

  .sm\:hover\:tw-translate-x-48:hover {
    --transform-translate-x: 12rem;
  }

  .sm\:hover\:tw-translate-x-56:hover {
    --transform-translate-x: 14rem;
  }

  .sm\:hover\:tw-translate-x-64:hover {
    --transform-translate-x: 16rem;
  }

  .sm\:hover\:tw-translate-x-px:hover {
    --transform-translate-x: 1px;
  }

  .sm\:hover\:tw-translate-x-270px:hover {
    --transform-translate-x: 270px;
  }

  .sm\:hover\:tw-translate-x-400px:hover {
    --transform-translate-x: 400px;
  }

  .sm\:hover\:tw-translate-x-464px:hover {
    --transform-translate-x: 464px;
  }

  .sm\:hover\:tw-translate-x-480px:hover {
    --transform-translate-x: 480px;
  }

  .sm\:hover\:tw-translate-x-500px:hover {
    --transform-translate-x: 500px;
  }

  .sm\:hover\:tw-translate-x-576px:hover {
    --transform-translate-x: 576px;
  }

  .sm\:hover\:tw-translate-x-600px:hover {
    --transform-translate-x: 600px;
  }

  .sm\:hover\:tw-translate-x-672px:hover {
    --transform-translate-x: 672px;
  }

  .sm\:hover\:tw--translate-x-1:hover {
    --transform-translate-x: -0.25rem;
  }

  .sm\:hover\:tw--translate-x-2:hover {
    --transform-translate-x: -0.5rem;
  }

  .sm\:hover\:tw--translate-x-3:hover {
    --transform-translate-x: -0.75rem;
  }

  .sm\:hover\:tw--translate-x-4:hover {
    --transform-translate-x: -1rem;
  }

  .sm\:hover\:tw--translate-x-5:hover {
    --transform-translate-x: -1.25rem;
  }

  .sm\:hover\:tw--translate-x-6:hover {
    --transform-translate-x: -1.5rem;
  }

  .sm\:hover\:tw--translate-x-8:hover {
    --transform-translate-x: -2rem;
  }

  .sm\:hover\:tw--translate-x-10:hover {
    --transform-translate-x: -2.5rem;
  }

  .sm\:hover\:tw--translate-x-12:hover {
    --transform-translate-x: -3rem;
  }

  .sm\:hover\:tw--translate-x-16:hover {
    --transform-translate-x: -4rem;
  }

  .sm\:hover\:tw--translate-x-20:hover {
    --transform-translate-x: -5rem;
  }

  .sm\:hover\:tw--translate-x-24:hover {
    --transform-translate-x: -6rem;
  }

  .sm\:hover\:tw--translate-x-32:hover {
    --transform-translate-x: -8rem;
  }

  .sm\:hover\:tw--translate-x-40:hover {
    --transform-translate-x: -10rem;
  }

  .sm\:hover\:tw--translate-x-48:hover {
    --transform-translate-x: -12rem;
  }

  .sm\:hover\:tw--translate-x-56:hover {
    --transform-translate-x: -14rem;
  }

  .sm\:hover\:tw--translate-x-64:hover {
    --transform-translate-x: -16rem;
  }

  .sm\:hover\:tw--translate-x-px:hover {
    --transform-translate-x: -1px;
  }

  .sm\:hover\:tw--translate-x-270px:hover {
    --transform-translate-x: -270px;
  }

  .sm\:hover\:tw--translate-x-400px:hover {
    --transform-translate-x: -400px;
  }

  .sm\:hover\:tw--translate-x-464px:hover {
    --transform-translate-x: -464px;
  }

  .sm\:hover\:tw--translate-x-480px:hover {
    --transform-translate-x: -480px;
  }

  .sm\:hover\:tw--translate-x-500px:hover {
    --transform-translate-x: -500px;
  }

  .sm\:hover\:tw--translate-x-576px:hover {
    --transform-translate-x: -576px;
  }

  .sm\:hover\:tw--translate-x-600px:hover {
    --transform-translate-x: -600px;
  }

  .sm\:hover\:tw--translate-x-672px:hover {
    --transform-translate-x: -672px;
  }

  .sm\:hover\:tw--translate-x-full:hover {
    --transform-translate-x: -100%;
  }

  .sm\:hover\:tw--translate-x-1\/2:hover {
    --transform-translate-x: -50%;
  }

  .sm\:hover\:tw-translate-x-1\/2:hover {
    --transform-translate-x: 50%;
  }

  .sm\:hover\:tw-translate-x-full:hover {
    --transform-translate-x: 100%;
  }

  .sm\:hover\:tw-translate-y-0:hover {
    --transform-translate-y: 0;
  }

  .sm\:hover\:tw-translate-y-1:hover {
    --transform-translate-y: 0.25rem;
  }

  .sm\:hover\:tw-translate-y-2:hover {
    --transform-translate-y: 0.5rem;
  }

  .sm\:hover\:tw-translate-y-3:hover {
    --transform-translate-y: 0.75rem;
  }

  .sm\:hover\:tw-translate-y-4:hover {
    --transform-translate-y: 1rem;
  }

  .sm\:hover\:tw-translate-y-5:hover {
    --transform-translate-y: 1.25rem;
  }

  .sm\:hover\:tw-translate-y-6:hover {
    --transform-translate-y: 1.5rem;
  }

  .sm\:hover\:tw-translate-y-8:hover {
    --transform-translate-y: 2rem;
  }

  .sm\:hover\:tw-translate-y-10:hover {
    --transform-translate-y: 2.5rem;
  }

  .sm\:hover\:tw-translate-y-12:hover {
    --transform-translate-y: 3rem;
  }

  .sm\:hover\:tw-translate-y-16:hover {
    --transform-translate-y: 4rem;
  }

  .sm\:hover\:tw-translate-y-20:hover {
    --transform-translate-y: 5rem;
  }

  .sm\:hover\:tw-translate-y-24:hover {
    --transform-translate-y: 6rem;
  }

  .sm\:hover\:tw-translate-y-32:hover {
    --transform-translate-y: 8rem;
  }

  .sm\:hover\:tw-translate-y-40:hover {
    --transform-translate-y: 10rem;
  }

  .sm\:hover\:tw-translate-y-48:hover {
    --transform-translate-y: 12rem;
  }

  .sm\:hover\:tw-translate-y-56:hover {
    --transform-translate-y: 14rem;
  }

  .sm\:hover\:tw-translate-y-64:hover {
    --transform-translate-y: 16rem;
  }

  .sm\:hover\:tw-translate-y-px:hover {
    --transform-translate-y: 1px;
  }

  .sm\:hover\:tw-translate-y-270px:hover {
    --transform-translate-y: 270px;
  }

  .sm\:hover\:tw-translate-y-400px:hover {
    --transform-translate-y: 400px;
  }

  .sm\:hover\:tw-translate-y-464px:hover {
    --transform-translate-y: 464px;
  }

  .sm\:hover\:tw-translate-y-480px:hover {
    --transform-translate-y: 480px;
  }

  .sm\:hover\:tw-translate-y-500px:hover {
    --transform-translate-y: 500px;
  }

  .sm\:hover\:tw-translate-y-576px:hover {
    --transform-translate-y: 576px;
  }

  .sm\:hover\:tw-translate-y-600px:hover {
    --transform-translate-y: 600px;
  }

  .sm\:hover\:tw-translate-y-672px:hover {
    --transform-translate-y: 672px;
  }

  .sm\:hover\:tw--translate-y-1:hover {
    --transform-translate-y: -0.25rem;
  }

  .sm\:hover\:tw--translate-y-2:hover {
    --transform-translate-y: -0.5rem;
  }

  .sm\:hover\:tw--translate-y-3:hover {
    --transform-translate-y: -0.75rem;
  }

  .sm\:hover\:tw--translate-y-4:hover {
    --transform-translate-y: -1rem;
  }

  .sm\:hover\:tw--translate-y-5:hover {
    --transform-translate-y: -1.25rem;
  }

  .sm\:hover\:tw--translate-y-6:hover {
    --transform-translate-y: -1.5rem;
  }

  .sm\:hover\:tw--translate-y-8:hover {
    --transform-translate-y: -2rem;
  }

  .sm\:hover\:tw--translate-y-10:hover {
    --transform-translate-y: -2.5rem;
  }

  .sm\:hover\:tw--translate-y-12:hover {
    --transform-translate-y: -3rem;
  }

  .sm\:hover\:tw--translate-y-16:hover {
    --transform-translate-y: -4rem;
  }

  .sm\:hover\:tw--translate-y-20:hover {
    --transform-translate-y: -5rem;
  }

  .sm\:hover\:tw--translate-y-24:hover {
    --transform-translate-y: -6rem;
  }

  .sm\:hover\:tw--translate-y-32:hover {
    --transform-translate-y: -8rem;
  }

  .sm\:hover\:tw--translate-y-40:hover {
    --transform-translate-y: -10rem;
  }

  .sm\:hover\:tw--translate-y-48:hover {
    --transform-translate-y: -12rem;
  }

  .sm\:hover\:tw--translate-y-56:hover {
    --transform-translate-y: -14rem;
  }

  .sm\:hover\:tw--translate-y-64:hover {
    --transform-translate-y: -16rem;
  }

  .sm\:hover\:tw--translate-y-px:hover {
    --transform-translate-y: -1px;
  }

  .sm\:hover\:tw--translate-y-270px:hover {
    --transform-translate-y: -270px;
  }

  .sm\:hover\:tw--translate-y-400px:hover {
    --transform-translate-y: -400px;
  }

  .sm\:hover\:tw--translate-y-464px:hover {
    --transform-translate-y: -464px;
  }

  .sm\:hover\:tw--translate-y-480px:hover {
    --transform-translate-y: -480px;
  }

  .sm\:hover\:tw--translate-y-500px:hover {
    --transform-translate-y: -500px;
  }

  .sm\:hover\:tw--translate-y-576px:hover {
    --transform-translate-y: -576px;
  }

  .sm\:hover\:tw--translate-y-600px:hover {
    --transform-translate-y: -600px;
  }

  .sm\:hover\:tw--translate-y-672px:hover {
    --transform-translate-y: -672px;
  }

  .sm\:hover\:tw--translate-y-full:hover {
    --transform-translate-y: -100%;
  }

  .sm\:hover\:tw--translate-y-1\/2:hover {
    --transform-translate-y: -50%;
  }

  .sm\:hover\:tw-translate-y-1\/2:hover {
    --transform-translate-y: 50%;
  }

  .sm\:hover\:tw-translate-y-full:hover {
    --transform-translate-y: 100%;
  }

  .sm\:focus\:tw-translate-x-0:focus {
    --transform-translate-x: 0;
  }

  .sm\:focus\:tw-translate-x-1:focus {
    --transform-translate-x: 0.25rem;
  }

  .sm\:focus\:tw-translate-x-2:focus {
    --transform-translate-x: 0.5rem;
  }

  .sm\:focus\:tw-translate-x-3:focus {
    --transform-translate-x: 0.75rem;
  }

  .sm\:focus\:tw-translate-x-4:focus {
    --transform-translate-x: 1rem;
  }

  .sm\:focus\:tw-translate-x-5:focus {
    --transform-translate-x: 1.25rem;
  }

  .sm\:focus\:tw-translate-x-6:focus {
    --transform-translate-x: 1.5rem;
  }

  .sm\:focus\:tw-translate-x-8:focus {
    --transform-translate-x: 2rem;
  }

  .sm\:focus\:tw-translate-x-10:focus {
    --transform-translate-x: 2.5rem;
  }

  .sm\:focus\:tw-translate-x-12:focus {
    --transform-translate-x: 3rem;
  }

  .sm\:focus\:tw-translate-x-16:focus {
    --transform-translate-x: 4rem;
  }

  .sm\:focus\:tw-translate-x-20:focus {
    --transform-translate-x: 5rem;
  }

  .sm\:focus\:tw-translate-x-24:focus {
    --transform-translate-x: 6rem;
  }

  .sm\:focus\:tw-translate-x-32:focus {
    --transform-translate-x: 8rem;
  }

  .sm\:focus\:tw-translate-x-40:focus {
    --transform-translate-x: 10rem;
  }

  .sm\:focus\:tw-translate-x-48:focus {
    --transform-translate-x: 12rem;
  }

  .sm\:focus\:tw-translate-x-56:focus {
    --transform-translate-x: 14rem;
  }

  .sm\:focus\:tw-translate-x-64:focus {
    --transform-translate-x: 16rem;
  }

  .sm\:focus\:tw-translate-x-px:focus {
    --transform-translate-x: 1px;
  }

  .sm\:focus\:tw-translate-x-270px:focus {
    --transform-translate-x: 270px;
  }

  .sm\:focus\:tw-translate-x-400px:focus {
    --transform-translate-x: 400px;
  }

  .sm\:focus\:tw-translate-x-464px:focus {
    --transform-translate-x: 464px;
  }

  .sm\:focus\:tw-translate-x-480px:focus {
    --transform-translate-x: 480px;
  }

  .sm\:focus\:tw-translate-x-500px:focus {
    --transform-translate-x: 500px;
  }

  .sm\:focus\:tw-translate-x-576px:focus {
    --transform-translate-x: 576px;
  }

  .sm\:focus\:tw-translate-x-600px:focus {
    --transform-translate-x: 600px;
  }

  .sm\:focus\:tw-translate-x-672px:focus {
    --transform-translate-x: 672px;
  }

  .sm\:focus\:tw--translate-x-1:focus {
    --transform-translate-x: -0.25rem;
  }

  .sm\:focus\:tw--translate-x-2:focus {
    --transform-translate-x: -0.5rem;
  }

  .sm\:focus\:tw--translate-x-3:focus {
    --transform-translate-x: -0.75rem;
  }

  .sm\:focus\:tw--translate-x-4:focus {
    --transform-translate-x: -1rem;
  }

  .sm\:focus\:tw--translate-x-5:focus {
    --transform-translate-x: -1.25rem;
  }

  .sm\:focus\:tw--translate-x-6:focus {
    --transform-translate-x: -1.5rem;
  }

  .sm\:focus\:tw--translate-x-8:focus {
    --transform-translate-x: -2rem;
  }

  .sm\:focus\:tw--translate-x-10:focus {
    --transform-translate-x: -2.5rem;
  }

  .sm\:focus\:tw--translate-x-12:focus {
    --transform-translate-x: -3rem;
  }

  .sm\:focus\:tw--translate-x-16:focus {
    --transform-translate-x: -4rem;
  }

  .sm\:focus\:tw--translate-x-20:focus {
    --transform-translate-x: -5rem;
  }

  .sm\:focus\:tw--translate-x-24:focus {
    --transform-translate-x: -6rem;
  }

  .sm\:focus\:tw--translate-x-32:focus {
    --transform-translate-x: -8rem;
  }

  .sm\:focus\:tw--translate-x-40:focus {
    --transform-translate-x: -10rem;
  }

  .sm\:focus\:tw--translate-x-48:focus {
    --transform-translate-x: -12rem;
  }

  .sm\:focus\:tw--translate-x-56:focus {
    --transform-translate-x: -14rem;
  }

  .sm\:focus\:tw--translate-x-64:focus {
    --transform-translate-x: -16rem;
  }

  .sm\:focus\:tw--translate-x-px:focus {
    --transform-translate-x: -1px;
  }

  .sm\:focus\:tw--translate-x-270px:focus {
    --transform-translate-x: -270px;
  }

  .sm\:focus\:tw--translate-x-400px:focus {
    --transform-translate-x: -400px;
  }

  .sm\:focus\:tw--translate-x-464px:focus {
    --transform-translate-x: -464px;
  }

  .sm\:focus\:tw--translate-x-480px:focus {
    --transform-translate-x: -480px;
  }

  .sm\:focus\:tw--translate-x-500px:focus {
    --transform-translate-x: -500px;
  }

  .sm\:focus\:tw--translate-x-576px:focus {
    --transform-translate-x: -576px;
  }

  .sm\:focus\:tw--translate-x-600px:focus {
    --transform-translate-x: -600px;
  }

  .sm\:focus\:tw--translate-x-672px:focus {
    --transform-translate-x: -672px;
  }

  .sm\:focus\:tw--translate-x-full:focus {
    --transform-translate-x: -100%;
  }

  .sm\:focus\:tw--translate-x-1\/2:focus {
    --transform-translate-x: -50%;
  }

  .sm\:focus\:tw-translate-x-1\/2:focus {
    --transform-translate-x: 50%;
  }

  .sm\:focus\:tw-translate-x-full:focus {
    --transform-translate-x: 100%;
  }

  .sm\:focus\:tw-translate-y-0:focus {
    --transform-translate-y: 0;
  }

  .sm\:focus\:tw-translate-y-1:focus {
    --transform-translate-y: 0.25rem;
  }

  .sm\:focus\:tw-translate-y-2:focus {
    --transform-translate-y: 0.5rem;
  }

  .sm\:focus\:tw-translate-y-3:focus {
    --transform-translate-y: 0.75rem;
  }

  .sm\:focus\:tw-translate-y-4:focus {
    --transform-translate-y: 1rem;
  }

  .sm\:focus\:tw-translate-y-5:focus {
    --transform-translate-y: 1.25rem;
  }

  .sm\:focus\:tw-translate-y-6:focus {
    --transform-translate-y: 1.5rem;
  }

  .sm\:focus\:tw-translate-y-8:focus {
    --transform-translate-y: 2rem;
  }

  .sm\:focus\:tw-translate-y-10:focus {
    --transform-translate-y: 2.5rem;
  }

  .sm\:focus\:tw-translate-y-12:focus {
    --transform-translate-y: 3rem;
  }

  .sm\:focus\:tw-translate-y-16:focus {
    --transform-translate-y: 4rem;
  }

  .sm\:focus\:tw-translate-y-20:focus {
    --transform-translate-y: 5rem;
  }

  .sm\:focus\:tw-translate-y-24:focus {
    --transform-translate-y: 6rem;
  }

  .sm\:focus\:tw-translate-y-32:focus {
    --transform-translate-y: 8rem;
  }

  .sm\:focus\:tw-translate-y-40:focus {
    --transform-translate-y: 10rem;
  }

  .sm\:focus\:tw-translate-y-48:focus {
    --transform-translate-y: 12rem;
  }

  .sm\:focus\:tw-translate-y-56:focus {
    --transform-translate-y: 14rem;
  }

  .sm\:focus\:tw-translate-y-64:focus {
    --transform-translate-y: 16rem;
  }

  .sm\:focus\:tw-translate-y-px:focus {
    --transform-translate-y: 1px;
  }

  .sm\:focus\:tw-translate-y-270px:focus {
    --transform-translate-y: 270px;
  }

  .sm\:focus\:tw-translate-y-400px:focus {
    --transform-translate-y: 400px;
  }

  .sm\:focus\:tw-translate-y-464px:focus {
    --transform-translate-y: 464px;
  }

  .sm\:focus\:tw-translate-y-480px:focus {
    --transform-translate-y: 480px;
  }

  .sm\:focus\:tw-translate-y-500px:focus {
    --transform-translate-y: 500px;
  }

  .sm\:focus\:tw-translate-y-576px:focus {
    --transform-translate-y: 576px;
  }

  .sm\:focus\:tw-translate-y-600px:focus {
    --transform-translate-y: 600px;
  }

  .sm\:focus\:tw-translate-y-672px:focus {
    --transform-translate-y: 672px;
  }

  .sm\:focus\:tw--translate-y-1:focus {
    --transform-translate-y: -0.25rem;
  }

  .sm\:focus\:tw--translate-y-2:focus {
    --transform-translate-y: -0.5rem;
  }

  .sm\:focus\:tw--translate-y-3:focus {
    --transform-translate-y: -0.75rem;
  }

  .sm\:focus\:tw--translate-y-4:focus {
    --transform-translate-y: -1rem;
  }

  .sm\:focus\:tw--translate-y-5:focus {
    --transform-translate-y: -1.25rem;
  }

  .sm\:focus\:tw--translate-y-6:focus {
    --transform-translate-y: -1.5rem;
  }

  .sm\:focus\:tw--translate-y-8:focus {
    --transform-translate-y: -2rem;
  }

  .sm\:focus\:tw--translate-y-10:focus {
    --transform-translate-y: -2.5rem;
  }

  .sm\:focus\:tw--translate-y-12:focus {
    --transform-translate-y: -3rem;
  }

  .sm\:focus\:tw--translate-y-16:focus {
    --transform-translate-y: -4rem;
  }

  .sm\:focus\:tw--translate-y-20:focus {
    --transform-translate-y: -5rem;
  }

  .sm\:focus\:tw--translate-y-24:focus {
    --transform-translate-y: -6rem;
  }

  .sm\:focus\:tw--translate-y-32:focus {
    --transform-translate-y: -8rem;
  }

  .sm\:focus\:tw--translate-y-40:focus {
    --transform-translate-y: -10rem;
  }

  .sm\:focus\:tw--translate-y-48:focus {
    --transform-translate-y: -12rem;
  }

  .sm\:focus\:tw--translate-y-56:focus {
    --transform-translate-y: -14rem;
  }

  .sm\:focus\:tw--translate-y-64:focus {
    --transform-translate-y: -16rem;
  }

  .sm\:focus\:tw--translate-y-px:focus {
    --transform-translate-y: -1px;
  }

  .sm\:focus\:tw--translate-y-270px:focus {
    --transform-translate-y: -270px;
  }

  .sm\:focus\:tw--translate-y-400px:focus {
    --transform-translate-y: -400px;
  }

  .sm\:focus\:tw--translate-y-464px:focus {
    --transform-translate-y: -464px;
  }

  .sm\:focus\:tw--translate-y-480px:focus {
    --transform-translate-y: -480px;
  }

  .sm\:focus\:tw--translate-y-500px:focus {
    --transform-translate-y: -500px;
  }

  .sm\:focus\:tw--translate-y-576px:focus {
    --transform-translate-y: -576px;
  }

  .sm\:focus\:tw--translate-y-600px:focus {
    --transform-translate-y: -600px;
  }

  .sm\:focus\:tw--translate-y-672px:focus {
    --transform-translate-y: -672px;
  }

  .sm\:focus\:tw--translate-y-full:focus {
    --transform-translate-y: -100%;
  }

  .sm\:focus\:tw--translate-y-1\/2:focus {
    --transform-translate-y: -50%;
  }

  .sm\:focus\:tw-translate-y-1\/2:focus {
    --transform-translate-y: 50%;
  }

  .sm\:focus\:tw-translate-y-full:focus {
    --transform-translate-y: 100%;
  }

  .sm\:tw-skew-x-0 {
    --transform-skew-x: 0;
  }

  .sm\:tw-skew-x-1 {
    --transform-skew-x: 1deg;
  }

  .sm\:tw-skew-x-2 {
    --transform-skew-x: 2deg;
  }

  .sm\:tw-skew-x-3 {
    --transform-skew-x: 3deg;
  }

  .sm\:tw-skew-x-6 {
    --transform-skew-x: 6deg;
  }

  .sm\:tw-skew-x-12 {
    --transform-skew-x: 12deg;
  }

  .sm\:tw--skew-x-12 {
    --transform-skew-x: -12deg;
  }

  .sm\:tw--skew-x-6 {
    --transform-skew-x: -6deg;
  }

  .sm\:tw--skew-x-3 {
    --transform-skew-x: -3deg;
  }

  .sm\:tw--skew-x-2 {
    --transform-skew-x: -2deg;
  }

  .sm\:tw--skew-x-1 {
    --transform-skew-x: -1deg;
  }

  .sm\:tw-skew-y-0 {
    --transform-skew-y: 0;
  }

  .sm\:tw-skew-y-1 {
    --transform-skew-y: 1deg;
  }

  .sm\:tw-skew-y-2 {
    --transform-skew-y: 2deg;
  }

  .sm\:tw-skew-y-3 {
    --transform-skew-y: 3deg;
  }

  .sm\:tw-skew-y-6 {
    --transform-skew-y: 6deg;
  }

  .sm\:tw-skew-y-12 {
    --transform-skew-y: 12deg;
  }

  .sm\:tw--skew-y-12 {
    --transform-skew-y: -12deg;
  }

  .sm\:tw--skew-y-6 {
    --transform-skew-y: -6deg;
  }

  .sm\:tw--skew-y-3 {
    --transform-skew-y: -3deg;
  }

  .sm\:tw--skew-y-2 {
    --transform-skew-y: -2deg;
  }

  .sm\:tw--skew-y-1 {
    --transform-skew-y: -1deg;
  }

  .sm\:hover\:tw-skew-x-0:hover {
    --transform-skew-x: 0;
  }

  .sm\:hover\:tw-skew-x-1:hover {
    --transform-skew-x: 1deg;
  }

  .sm\:hover\:tw-skew-x-2:hover {
    --transform-skew-x: 2deg;
  }

  .sm\:hover\:tw-skew-x-3:hover {
    --transform-skew-x: 3deg;
  }

  .sm\:hover\:tw-skew-x-6:hover {
    --transform-skew-x: 6deg;
  }

  .sm\:hover\:tw-skew-x-12:hover {
    --transform-skew-x: 12deg;
  }

  .sm\:hover\:tw--skew-x-12:hover {
    --transform-skew-x: -12deg;
  }

  .sm\:hover\:tw--skew-x-6:hover {
    --transform-skew-x: -6deg;
  }

  .sm\:hover\:tw--skew-x-3:hover {
    --transform-skew-x: -3deg;
  }

  .sm\:hover\:tw--skew-x-2:hover {
    --transform-skew-x: -2deg;
  }

  .sm\:hover\:tw--skew-x-1:hover {
    --transform-skew-x: -1deg;
  }

  .sm\:hover\:tw-skew-y-0:hover {
    --transform-skew-y: 0;
  }

  .sm\:hover\:tw-skew-y-1:hover {
    --transform-skew-y: 1deg;
  }

  .sm\:hover\:tw-skew-y-2:hover {
    --transform-skew-y: 2deg;
  }

  .sm\:hover\:tw-skew-y-3:hover {
    --transform-skew-y: 3deg;
  }

  .sm\:hover\:tw-skew-y-6:hover {
    --transform-skew-y: 6deg;
  }

  .sm\:hover\:tw-skew-y-12:hover {
    --transform-skew-y: 12deg;
  }

  .sm\:hover\:tw--skew-y-12:hover {
    --transform-skew-y: -12deg;
  }

  .sm\:hover\:tw--skew-y-6:hover {
    --transform-skew-y: -6deg;
  }

  .sm\:hover\:tw--skew-y-3:hover {
    --transform-skew-y: -3deg;
  }

  .sm\:hover\:tw--skew-y-2:hover {
    --transform-skew-y: -2deg;
  }

  .sm\:hover\:tw--skew-y-1:hover {
    --transform-skew-y: -1deg;
  }

  .sm\:focus\:tw-skew-x-0:focus {
    --transform-skew-x: 0;
  }

  .sm\:focus\:tw-skew-x-1:focus {
    --transform-skew-x: 1deg;
  }

  .sm\:focus\:tw-skew-x-2:focus {
    --transform-skew-x: 2deg;
  }

  .sm\:focus\:tw-skew-x-3:focus {
    --transform-skew-x: 3deg;
  }

  .sm\:focus\:tw-skew-x-6:focus {
    --transform-skew-x: 6deg;
  }

  .sm\:focus\:tw-skew-x-12:focus {
    --transform-skew-x: 12deg;
  }

  .sm\:focus\:tw--skew-x-12:focus {
    --transform-skew-x: -12deg;
  }

  .sm\:focus\:tw--skew-x-6:focus {
    --transform-skew-x: -6deg;
  }

  .sm\:focus\:tw--skew-x-3:focus {
    --transform-skew-x: -3deg;
  }

  .sm\:focus\:tw--skew-x-2:focus {
    --transform-skew-x: -2deg;
  }

  .sm\:focus\:tw--skew-x-1:focus {
    --transform-skew-x: -1deg;
  }

  .sm\:focus\:tw-skew-y-0:focus {
    --transform-skew-y: 0;
  }

  .sm\:focus\:tw-skew-y-1:focus {
    --transform-skew-y: 1deg;
  }

  .sm\:focus\:tw-skew-y-2:focus {
    --transform-skew-y: 2deg;
  }

  .sm\:focus\:tw-skew-y-3:focus {
    --transform-skew-y: 3deg;
  }

  .sm\:focus\:tw-skew-y-6:focus {
    --transform-skew-y: 6deg;
  }

  .sm\:focus\:tw-skew-y-12:focus {
    --transform-skew-y: 12deg;
  }

  .sm\:focus\:tw--skew-y-12:focus {
    --transform-skew-y: -12deg;
  }

  .sm\:focus\:tw--skew-y-6:focus {
    --transform-skew-y: -6deg;
  }

  .sm\:focus\:tw--skew-y-3:focus {
    --transform-skew-y: -3deg;
  }

  .sm\:focus\:tw--skew-y-2:focus {
    --transform-skew-y: -2deg;
  }

  .sm\:focus\:tw--skew-y-1:focus {
    --transform-skew-y: -1deg;
  }

  .sm\:tw-transition-none {
    transition-property: none;
  }

  .sm\:tw-transition-all {
    transition-property: all;
  }

  .sm\:tw-transition {
    transition-property: background-color, border-color, color, fill, stroke, opacity, box-shadow, transform;
  }

  .sm\:tw-transition-colors {
    transition-property: background-color, border-color, color, fill, stroke;
  }

  .sm\:tw-transition-opacity {
    transition-property: opacity;
  }

  .sm\:tw-transition-shadow {
    transition-property: box-shadow;
  }

  .sm\:tw-transition-transform {
    transition-property: transform;
  }

  .sm\:tw-ease-linear {
    transition-timing-function: linear;
  }

  .sm\:tw-ease-in {
    transition-timing-function: cubic-bezier(0.4, 0, 1, 1);
  }

  .sm\:tw-ease-out {
    transition-timing-function: cubic-bezier(0, 0, 0.2, 1);
  }

  .sm\:tw-ease-in-out {
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  }

  .sm\:tw-duration-75 {
    transition-duration: 75ms;
  }

  .sm\:tw-duration-100 {
    transition-duration: 100ms;
  }

  .sm\:tw-duration-150 {
    transition-duration: 150ms;
  }

  .sm\:tw-duration-200 {
    transition-duration: 200ms;
  }

  .sm\:tw-duration-300 {
    transition-duration: 300ms;
  }

  .sm\:tw-duration-500 {
    transition-duration: 500ms;
  }

  .sm\:tw-duration-700 {
    transition-duration: 700ms;
  }

  .sm\:tw-duration-1000 {
    transition-duration: 1000ms;
  }

  .sm\:tw-delay-75 {
    transition-delay: 75ms;
  }

  .sm\:tw-delay-100 {
    transition-delay: 100ms;
  }

  .sm\:tw-delay-150 {
    transition-delay: 150ms;
  }

  .sm\:tw-delay-200 {
    transition-delay: 200ms;
  }

  .sm\:tw-delay-300 {
    transition-delay: 300ms;
  }

  .sm\:tw-delay-500 {
    transition-delay: 500ms;
  }

  .sm\:tw-delay-700 {
    transition-delay: 700ms;
  }

  .sm\:tw-delay-1000 {
    transition-delay: 1000ms;
  }

  .sm\:tw-animate-none {
    animation: none;
  }

  .sm\:tw-animate-spin {
    animation: spin 1s linear infinite;
  }

  .sm\:tw-animate-ping {
    animation: ping 1s cubic-bezier(0, 0, 0.2, 1) infinite;
  }

  .sm\:tw-animate-pulse {
    animation: pulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite;
  }

  .sm\:tw-animate-bounce {
    animation: bounce 1s infinite;
  }
}

@media (min-width: 768px) {
  .md\:tw-container {
    width: 100%;
  }

  @media (min-width: 640px) {
    .md\:tw-container {
      max-width: 640px;
    }
  }

  @media (min-width: 768px) {
    .md\:tw-container {
      max-width: 768px;
    }
  }

  @media (min-width: 1024px) {
    .md\:tw-container {
      max-width: 1024px;
    }
  }

  @media (min-width: 1280px) {
    .md\:tw-container {
      max-width: 1280px;
    }
  }

  .md\:tw-space-y-0 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(0px * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(0px * var(--space-y-reverse));
  }

  .md\:tw-space-x-0 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(0px * var(--space-x-reverse));
    margin-left: calc(0px * calc(1 - var(--space-x-reverse)));
  }

  .md\:tw-space-y-1 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(0.25rem * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(0.25rem * var(--space-y-reverse));
  }

  .md\:tw-space-x-1 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(0.25rem * var(--space-x-reverse));
    margin-left: calc(0.25rem * calc(1 - var(--space-x-reverse)));
  }

  .md\:tw-space-y-2 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(0.5rem * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(0.5rem * var(--space-y-reverse));
  }

  .md\:tw-space-x-2 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(0.5rem * var(--space-x-reverse));
    margin-left: calc(0.5rem * calc(1 - var(--space-x-reverse)));
  }

  .md\:tw-space-y-3 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(0.75rem * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(0.75rem * var(--space-y-reverse));
  }

  .md\:tw-space-x-3 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(0.75rem * var(--space-x-reverse));
    margin-left: calc(0.75rem * calc(1 - var(--space-x-reverse)));
  }

  .md\:tw-space-y-4 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(1rem * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(1rem * var(--space-y-reverse));
  }

  .md\:tw-space-x-4 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(1rem * var(--space-x-reverse));
    margin-left: calc(1rem * calc(1 - var(--space-x-reverse)));
  }

  .md\:tw-space-y-5 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(1.25rem * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(1.25rem * var(--space-y-reverse));
  }

  .md\:tw-space-x-5 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(1.25rem * var(--space-x-reverse));
    margin-left: calc(1.25rem * calc(1 - var(--space-x-reverse)));
  }

  .md\:tw-space-y-6 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(1.5rem * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(1.5rem * var(--space-y-reverse));
  }

  .md\:tw-space-x-6 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(1.5rem * var(--space-x-reverse));
    margin-left: calc(1.5rem * calc(1 - var(--space-x-reverse)));
  }

  .md\:tw-space-y-8 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(2rem * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(2rem * var(--space-y-reverse));
  }

  .md\:tw-space-x-8 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(2rem * var(--space-x-reverse));
    margin-left: calc(2rem * calc(1 - var(--space-x-reverse)));
  }

  .md\:tw-space-y-10 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(2.5rem * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(2.5rem * var(--space-y-reverse));
  }

  .md\:tw-space-x-10 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(2.5rem * var(--space-x-reverse));
    margin-left: calc(2.5rem * calc(1 - var(--space-x-reverse)));
  }

  .md\:tw-space-y-12 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(3rem * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(3rem * var(--space-y-reverse));
  }

  .md\:tw-space-x-12 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(3rem * var(--space-x-reverse));
    margin-left: calc(3rem * calc(1 - var(--space-x-reverse)));
  }

  .md\:tw-space-y-16 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(4rem * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(4rem * var(--space-y-reverse));
  }

  .md\:tw-space-x-16 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(4rem * var(--space-x-reverse));
    margin-left: calc(4rem * calc(1 - var(--space-x-reverse)));
  }

  .md\:tw-space-y-20 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(5rem * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(5rem * var(--space-y-reverse));
  }

  .md\:tw-space-x-20 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(5rem * var(--space-x-reverse));
    margin-left: calc(5rem * calc(1 - var(--space-x-reverse)));
  }

  .md\:tw-space-y-24 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(6rem * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(6rem * var(--space-y-reverse));
  }

  .md\:tw-space-x-24 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(6rem * var(--space-x-reverse));
    margin-left: calc(6rem * calc(1 - var(--space-x-reverse)));
  }

  .md\:tw-space-y-32 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(8rem * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(8rem * var(--space-y-reverse));
  }

  .md\:tw-space-x-32 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(8rem * var(--space-x-reverse));
    margin-left: calc(8rem * calc(1 - var(--space-x-reverse)));
  }

  .md\:tw-space-y-40 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(10rem * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(10rem * var(--space-y-reverse));
  }

  .md\:tw-space-x-40 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(10rem * var(--space-x-reverse));
    margin-left: calc(10rem * calc(1 - var(--space-x-reverse)));
  }

  .md\:tw-space-y-48 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(12rem * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(12rem * var(--space-y-reverse));
  }

  .md\:tw-space-x-48 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(12rem * var(--space-x-reverse));
    margin-left: calc(12rem * calc(1 - var(--space-x-reverse)));
  }

  .md\:tw-space-y-56 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(14rem * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(14rem * var(--space-y-reverse));
  }

  .md\:tw-space-x-56 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(14rem * var(--space-x-reverse));
    margin-left: calc(14rem * calc(1 - var(--space-x-reverse)));
  }

  .md\:tw-space-y-64 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(16rem * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(16rem * var(--space-y-reverse));
  }

  .md\:tw-space-x-64 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(16rem * var(--space-x-reverse));
    margin-left: calc(16rem * calc(1 - var(--space-x-reverse)));
  }

  .md\:tw-space-y-px > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(1px * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(1px * var(--space-y-reverse));
  }

  .md\:tw-space-x-px > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(1px * var(--space-x-reverse));
    margin-left: calc(1px * calc(1 - var(--space-x-reverse)));
  }

  .md\:tw-space-y-270px > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(270px * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(270px * var(--space-y-reverse));
  }

  .md\:tw-space-x-270px > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(270px * var(--space-x-reverse));
    margin-left: calc(270px * calc(1 - var(--space-x-reverse)));
  }

  .md\:tw-space-y-400px > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(400px * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(400px * var(--space-y-reverse));
  }

  .md\:tw-space-x-400px > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(400px * var(--space-x-reverse));
    margin-left: calc(400px * calc(1 - var(--space-x-reverse)));
  }

  .md\:tw-space-y-464px > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(464px * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(464px * var(--space-y-reverse));
  }

  .md\:tw-space-x-464px > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(464px * var(--space-x-reverse));
    margin-left: calc(464px * calc(1 - var(--space-x-reverse)));
  }

  .md\:tw-space-y-480px > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(480px * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(480px * var(--space-y-reverse));
  }

  .md\:tw-space-x-480px > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(480px * var(--space-x-reverse));
    margin-left: calc(480px * calc(1 - var(--space-x-reverse)));
  }

  .md\:tw-space-y-500px > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(500px * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(500px * var(--space-y-reverse));
  }

  .md\:tw-space-x-500px > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(500px * var(--space-x-reverse));
    margin-left: calc(500px * calc(1 - var(--space-x-reverse)));
  }

  .md\:tw-space-y-576px > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(576px * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(576px * var(--space-y-reverse));
  }

  .md\:tw-space-x-576px > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(576px * var(--space-x-reverse));
    margin-left: calc(576px * calc(1 - var(--space-x-reverse)));
  }

  .md\:tw-space-y-600px > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(600px * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(600px * var(--space-y-reverse));
  }

  .md\:tw-space-x-600px > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(600px * var(--space-x-reverse));
    margin-left: calc(600px * calc(1 - var(--space-x-reverse)));
  }

  .md\:tw-space-y-672px > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(672px * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(672px * var(--space-y-reverse));
  }

  .md\:tw-space-x-672px > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(672px * var(--space-x-reverse));
    margin-left: calc(672px * calc(1 - var(--space-x-reverse)));
  }

  .md\:tw--space-y-1 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(-0.25rem * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(-0.25rem * var(--space-y-reverse));
  }

  .md\:tw--space-x-1 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(-0.25rem * var(--space-x-reverse));
    margin-left: calc(-0.25rem * calc(1 - var(--space-x-reverse)));
  }

  .md\:tw--space-y-2 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(-0.5rem * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(-0.5rem * var(--space-y-reverse));
  }

  .md\:tw--space-x-2 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(-0.5rem * var(--space-x-reverse));
    margin-left: calc(-0.5rem * calc(1 - var(--space-x-reverse)));
  }

  .md\:tw--space-y-3 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(-0.75rem * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(-0.75rem * var(--space-y-reverse));
  }

  .md\:tw--space-x-3 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(-0.75rem * var(--space-x-reverse));
    margin-left: calc(-0.75rem * calc(1 - var(--space-x-reverse)));
  }

  .md\:tw--space-y-4 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(-1rem * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(-1rem * var(--space-y-reverse));
  }

  .md\:tw--space-x-4 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(-1rem * var(--space-x-reverse));
    margin-left: calc(-1rem * calc(1 - var(--space-x-reverse)));
  }

  .md\:tw--space-y-5 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(-1.25rem * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(-1.25rem * var(--space-y-reverse));
  }

  .md\:tw--space-x-5 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(-1.25rem * var(--space-x-reverse));
    margin-left: calc(-1.25rem * calc(1 - var(--space-x-reverse)));
  }

  .md\:tw--space-y-6 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(-1.5rem * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(-1.5rem * var(--space-y-reverse));
  }

  .md\:tw--space-x-6 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(-1.5rem * var(--space-x-reverse));
    margin-left: calc(-1.5rem * calc(1 - var(--space-x-reverse)));
  }

  .md\:tw--space-y-8 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(-2rem * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(-2rem * var(--space-y-reverse));
  }

  .md\:tw--space-x-8 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(-2rem * var(--space-x-reverse));
    margin-left: calc(-2rem * calc(1 - var(--space-x-reverse)));
  }

  .md\:tw--space-y-10 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(-2.5rem * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(-2.5rem * var(--space-y-reverse));
  }

  .md\:tw--space-x-10 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(-2.5rem * var(--space-x-reverse));
    margin-left: calc(-2.5rem * calc(1 - var(--space-x-reverse)));
  }

  .md\:tw--space-y-12 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(-3rem * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(-3rem * var(--space-y-reverse));
  }

  .md\:tw--space-x-12 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(-3rem * var(--space-x-reverse));
    margin-left: calc(-3rem * calc(1 - var(--space-x-reverse)));
  }

  .md\:tw--space-y-16 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(-4rem * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(-4rem * var(--space-y-reverse));
  }

  .md\:tw--space-x-16 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(-4rem * var(--space-x-reverse));
    margin-left: calc(-4rem * calc(1 - var(--space-x-reverse)));
  }

  .md\:tw--space-y-20 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(-5rem * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(-5rem * var(--space-y-reverse));
  }

  .md\:tw--space-x-20 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(-5rem * var(--space-x-reverse));
    margin-left: calc(-5rem * calc(1 - var(--space-x-reverse)));
  }

  .md\:tw--space-y-24 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(-6rem * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(-6rem * var(--space-y-reverse));
  }

  .md\:tw--space-x-24 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(-6rem * var(--space-x-reverse));
    margin-left: calc(-6rem * calc(1 - var(--space-x-reverse)));
  }

  .md\:tw--space-y-32 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(-8rem * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(-8rem * var(--space-y-reverse));
  }

  .md\:tw--space-x-32 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(-8rem * var(--space-x-reverse));
    margin-left: calc(-8rem * calc(1 - var(--space-x-reverse)));
  }

  .md\:tw--space-y-40 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(-10rem * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(-10rem * var(--space-y-reverse));
  }

  .md\:tw--space-x-40 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(-10rem * var(--space-x-reverse));
    margin-left: calc(-10rem * calc(1 - var(--space-x-reverse)));
  }

  .md\:tw--space-y-48 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(-12rem * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(-12rem * var(--space-y-reverse));
  }

  .md\:tw--space-x-48 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(-12rem * var(--space-x-reverse));
    margin-left: calc(-12rem * calc(1 - var(--space-x-reverse)));
  }

  .md\:tw--space-y-56 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(-14rem * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(-14rem * var(--space-y-reverse));
  }

  .md\:tw--space-x-56 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(-14rem * var(--space-x-reverse));
    margin-left: calc(-14rem * calc(1 - var(--space-x-reverse)));
  }

  .md\:tw--space-y-64 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(-16rem * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(-16rem * var(--space-y-reverse));
  }

  .md\:tw--space-x-64 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(-16rem * var(--space-x-reverse));
    margin-left: calc(-16rem * calc(1 - var(--space-x-reverse)));
  }

  .md\:tw--space-y-px > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(-1px * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(-1px * var(--space-y-reverse));
  }

  .md\:tw--space-x-px > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(-1px * var(--space-x-reverse));
    margin-left: calc(-1px * calc(1 - var(--space-x-reverse)));
  }

  .md\:tw--space-y-270px > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(-270px * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(-270px * var(--space-y-reverse));
  }

  .md\:tw--space-x-270px > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(-270px * var(--space-x-reverse));
    margin-left: calc(-270px * calc(1 - var(--space-x-reverse)));
  }

  .md\:tw--space-y-400px > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(-400px * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(-400px * var(--space-y-reverse));
  }

  .md\:tw--space-x-400px > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(-400px * var(--space-x-reverse));
    margin-left: calc(-400px * calc(1 - var(--space-x-reverse)));
  }

  .md\:tw--space-y-464px > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(-464px * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(-464px * var(--space-y-reverse));
  }

  .md\:tw--space-x-464px > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(-464px * var(--space-x-reverse));
    margin-left: calc(-464px * calc(1 - var(--space-x-reverse)));
  }

  .md\:tw--space-y-480px > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(-480px * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(-480px * var(--space-y-reverse));
  }

  .md\:tw--space-x-480px > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(-480px * var(--space-x-reverse));
    margin-left: calc(-480px * calc(1 - var(--space-x-reverse)));
  }

  .md\:tw--space-y-500px > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(-500px * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(-500px * var(--space-y-reverse));
  }

  .md\:tw--space-x-500px > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(-500px * var(--space-x-reverse));
    margin-left: calc(-500px * calc(1 - var(--space-x-reverse)));
  }

  .md\:tw--space-y-576px > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(-576px * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(-576px * var(--space-y-reverse));
  }

  .md\:tw--space-x-576px > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(-576px * var(--space-x-reverse));
    margin-left: calc(-576px * calc(1 - var(--space-x-reverse)));
  }

  .md\:tw--space-y-600px > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(-600px * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(-600px * var(--space-y-reverse));
  }

  .md\:tw--space-x-600px > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(-600px * var(--space-x-reverse));
    margin-left: calc(-600px * calc(1 - var(--space-x-reverse)));
  }

  .md\:tw--space-y-672px > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(-672px * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(-672px * var(--space-y-reverse));
  }

  .md\:tw--space-x-672px > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(-672px * var(--space-x-reverse));
    margin-left: calc(-672px * calc(1 - var(--space-x-reverse)));
  }

  .md\:tw-space-y-reverse > :not(template) ~ :not(template) {
    --space-y-reverse: 1;
  }

  .md\:tw-space-x-reverse > :not(template) ~ :not(template) {
    --space-x-reverse: 1;
  }

  .md\:tw-divide-y-0 > :not(template) ~ :not(template) {
    --divide-y-reverse: 0;
    border-top-width: calc(0px * calc(1 - var(--divide-y-reverse)));
    border-bottom-width: calc(0px * var(--divide-y-reverse));
  }

  .md\:tw-divide-x-0 > :not(template) ~ :not(template) {
    --divide-x-reverse: 0;
    border-right-width: calc(0px * var(--divide-x-reverse));
    border-left-width: calc(0px * calc(1 - var(--divide-x-reverse)));
  }

  .md\:tw-divide-y-2 > :not(template) ~ :not(template) {
    --divide-y-reverse: 0;
    border-top-width: calc(2px * calc(1 - var(--divide-y-reverse)));
    border-bottom-width: calc(2px * var(--divide-y-reverse));
  }

  .md\:tw-divide-x-2 > :not(template) ~ :not(template) {
    --divide-x-reverse: 0;
    border-right-width: calc(2px * var(--divide-x-reverse));
    border-left-width: calc(2px * calc(1 - var(--divide-x-reverse)));
  }

  .md\:tw-divide-y-4 > :not(template) ~ :not(template) {
    --divide-y-reverse: 0;
    border-top-width: calc(4px * calc(1 - var(--divide-y-reverse)));
    border-bottom-width: calc(4px * var(--divide-y-reverse));
  }

  .md\:tw-divide-x-4 > :not(template) ~ :not(template) {
    --divide-x-reverse: 0;
    border-right-width: calc(4px * var(--divide-x-reverse));
    border-left-width: calc(4px * calc(1 - var(--divide-x-reverse)));
  }

  .md\:tw-divide-y-8 > :not(template) ~ :not(template) {
    --divide-y-reverse: 0;
    border-top-width: calc(8px * calc(1 - var(--divide-y-reverse)));
    border-bottom-width: calc(8px * var(--divide-y-reverse));
  }

  .md\:tw-divide-x-8 > :not(template) ~ :not(template) {
    --divide-x-reverse: 0;
    border-right-width: calc(8px * var(--divide-x-reverse));
    border-left-width: calc(8px * calc(1 - var(--divide-x-reverse)));
  }

  .md\:tw-divide-y > :not(template) ~ :not(template) {
    --divide-y-reverse: 0;
    border-top-width: calc(1px * calc(1 - var(--divide-y-reverse)));
    border-bottom-width: calc(1px * var(--divide-y-reverse));
  }

  .md\:tw-divide-x > :not(template) ~ :not(template) {
    --divide-x-reverse: 0;
    border-right-width: calc(1px * var(--divide-x-reverse));
    border-left-width: calc(1px * calc(1 - var(--divide-x-reverse)));
  }

  .md\:tw-divide-y-reverse > :not(template) ~ :not(template) {
    --divide-y-reverse: 1;
  }

  .md\:tw-divide-x-reverse > :not(template) ~ :not(template) {
    --divide-x-reverse: 1;
  }

  .md\:tw-divide-transparent > :not(template) ~ :not(template) {
    border-color: transparent;
  }

  .md\:tw-divide-current > :not(template) ~ :not(template) {
    border-color: currentColor;
  }

  .md\:tw-divide-black > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #000;
    border-color: rgba(0, 0, 0, var(--divide-opacity));
  }

  .md\:tw-divide-white > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #fff;
    border-color: rgba(255, 255, 255, var(--divide-opacity));
  }

  .md\:tw-divide-gray-100 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #f7fafc;
    border-color: rgba(247, 250, 252, var(--divide-opacity));
  }

  .md\:tw-divide-gray-200 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #edf2f7;
    border-color: rgba(237, 242, 247, var(--divide-opacity));
  }

  .md\:tw-divide-gray-300 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #e2e8f0;
    border-color: rgba(226, 232, 240, var(--divide-opacity));
  }

  .md\:tw-divide-gray-400 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #cbd5e0;
    border-color: rgba(203, 213, 224, var(--divide-opacity));
  }

  .md\:tw-divide-gray-500 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #a0aec0;
    border-color: rgba(160, 174, 192, var(--divide-opacity));
  }

  .md\:tw-divide-gray-600 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #718096;
    border-color: rgba(113, 128, 150, var(--divide-opacity));
  }

  .md\:tw-divide-gray-700 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #4a5568;
    border-color: rgba(74, 85, 104, var(--divide-opacity));
  }

  .md\:tw-divide-gray-800 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #2d3748;
    border-color: rgba(45, 55, 72, var(--divide-opacity));
  }

  .md\:tw-divide-gray-900 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #1a202c;
    border-color: rgba(26, 32, 44, var(--divide-opacity));
  }

  .md\:tw-divide-red-100 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #fff5f5;
    border-color: rgba(255, 245, 245, var(--divide-opacity));
  }

  .md\:tw-divide-red-200 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #fed7d7;
    border-color: rgba(254, 215, 215, var(--divide-opacity));
  }

  .md\:tw-divide-red-300 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #feb2b2;
    border-color: rgba(254, 178, 178, var(--divide-opacity));
  }

  .md\:tw-divide-red-400 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #fc8181;
    border-color: rgba(252, 129, 129, var(--divide-opacity));
  }

  .md\:tw-divide-red-500 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #f56565;
    border-color: rgba(245, 101, 101, var(--divide-opacity));
  }

  .md\:tw-divide-red-600 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #e53e3e;
    border-color: rgba(229, 62, 62, var(--divide-opacity));
  }

  .md\:tw-divide-red-700 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #c53030;
    border-color: rgba(197, 48, 48, var(--divide-opacity));
  }

  .md\:tw-divide-red-800 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #9b2c2c;
    border-color: rgba(155, 44, 44, var(--divide-opacity));
  }

  .md\:tw-divide-red-900 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #742a2a;
    border-color: rgba(116, 42, 42, var(--divide-opacity));
  }

  .md\:tw-divide-orange-100 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #fffaf0;
    border-color: rgba(255, 250, 240, var(--divide-opacity));
  }

  .md\:tw-divide-orange-200 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #feebc8;
    border-color: rgba(254, 235, 200, var(--divide-opacity));
  }

  .md\:tw-divide-orange-300 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #fbd38d;
    border-color: rgba(251, 211, 141, var(--divide-opacity));
  }

  .md\:tw-divide-orange-400 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #f6ad55;
    border-color: rgba(246, 173, 85, var(--divide-opacity));
  }

  .md\:tw-divide-orange-500 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #ed8936;
    border-color: rgba(237, 137, 54, var(--divide-opacity));
  }

  .md\:tw-divide-orange-600 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #dd6b20;
    border-color: rgba(221, 107, 32, var(--divide-opacity));
  }

  .md\:tw-divide-orange-700 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #c05621;
    border-color: rgba(192, 86, 33, var(--divide-opacity));
  }

  .md\:tw-divide-orange-800 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #9c4221;
    border-color: rgba(156, 66, 33, var(--divide-opacity));
  }

  .md\:tw-divide-orange-900 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #7b341e;
    border-color: rgba(123, 52, 30, var(--divide-opacity));
  }

  .md\:tw-divide-yellow-100 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #fffff0;
    border-color: rgba(255, 255, 240, var(--divide-opacity));
  }

  .md\:tw-divide-yellow-200 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #fefcbf;
    border-color: rgba(254, 252, 191, var(--divide-opacity));
  }

  .md\:tw-divide-yellow-300 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #faf089;
    border-color: rgba(250, 240, 137, var(--divide-opacity));
  }

  .md\:tw-divide-yellow-400 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #f6e05e;
    border-color: rgba(246, 224, 94, var(--divide-opacity));
  }

  .md\:tw-divide-yellow-500 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #ecc94b;
    border-color: rgba(236, 201, 75, var(--divide-opacity));
  }

  .md\:tw-divide-yellow-600 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #d69e2e;
    border-color: rgba(214, 158, 46, var(--divide-opacity));
  }

  .md\:tw-divide-yellow-700 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #b7791f;
    border-color: rgba(183, 121, 31, var(--divide-opacity));
  }

  .md\:tw-divide-yellow-800 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #975a16;
    border-color: rgba(151, 90, 22, var(--divide-opacity));
  }

  .md\:tw-divide-yellow-900 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #744210;
    border-color: rgba(116, 66, 16, var(--divide-opacity));
  }

  .md\:tw-divide-green-100 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #f0fff4;
    border-color: rgba(240, 255, 244, var(--divide-opacity));
  }

  .md\:tw-divide-green-200 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #c6f6d5;
    border-color: rgba(198, 246, 213, var(--divide-opacity));
  }

  .md\:tw-divide-green-300 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #9ae6b4;
    border-color: rgba(154, 230, 180, var(--divide-opacity));
  }

  .md\:tw-divide-green-400 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #68d391;
    border-color: rgba(104, 211, 145, var(--divide-opacity));
  }

  .md\:tw-divide-green-500 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #48bb78;
    border-color: rgba(72, 187, 120, var(--divide-opacity));
  }

  .md\:tw-divide-green-600 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #38a169;
    border-color: rgba(56, 161, 105, var(--divide-opacity));
  }

  .md\:tw-divide-green-700 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #2f855a;
    border-color: rgba(47, 133, 90, var(--divide-opacity));
  }

  .md\:tw-divide-green-800 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #276749;
    border-color: rgba(39, 103, 73, var(--divide-opacity));
  }

  .md\:tw-divide-green-900 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #22543d;
    border-color: rgba(34, 84, 61, var(--divide-opacity));
  }

  .md\:tw-divide-teal-100 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #e6fffa;
    border-color: rgba(230, 255, 250, var(--divide-opacity));
  }

  .md\:tw-divide-teal-200 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #b2f5ea;
    border-color: rgba(178, 245, 234, var(--divide-opacity));
  }

  .md\:tw-divide-teal-300 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #81e6d9;
    border-color: rgba(129, 230, 217, var(--divide-opacity));
  }

  .md\:tw-divide-teal-400 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #4fd1c5;
    border-color: rgba(79, 209, 197, var(--divide-opacity));
  }

  .md\:tw-divide-teal-500 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #38b2ac;
    border-color: rgba(56, 178, 172, var(--divide-opacity));
  }

  .md\:tw-divide-teal-600 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #319795;
    border-color: rgba(49, 151, 149, var(--divide-opacity));
  }

  .md\:tw-divide-teal-700 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #2c7a7b;
    border-color: rgba(44, 122, 123, var(--divide-opacity));
  }

  .md\:tw-divide-teal-800 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #285e61;
    border-color: rgba(40, 94, 97, var(--divide-opacity));
  }

  .md\:tw-divide-teal-900 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #234e52;
    border-color: rgba(35, 78, 82, var(--divide-opacity));
  }

  .md\:tw-divide-blue-100 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #ebf8ff;
    border-color: rgba(235, 248, 255, var(--divide-opacity));
  }

  .md\:tw-divide-blue-200 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #bee3f8;
    border-color: rgba(190, 227, 248, var(--divide-opacity));
  }

  .md\:tw-divide-blue-300 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #90cdf4;
    border-color: rgba(144, 205, 244, var(--divide-opacity));
  }

  .md\:tw-divide-blue-400 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #63b3ed;
    border-color: rgba(99, 179, 237, var(--divide-opacity));
  }

  .md\:tw-divide-blue-500 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #4299e1;
    border-color: rgba(66, 153, 225, var(--divide-opacity));
  }

  .md\:tw-divide-blue-600 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #3182ce;
    border-color: rgba(49, 130, 206, var(--divide-opacity));
  }

  .md\:tw-divide-blue-700 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #2b6cb0;
    border-color: rgba(43, 108, 176, var(--divide-opacity));
  }

  .md\:tw-divide-blue-800 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #2c5282;
    border-color: rgba(44, 82, 130, var(--divide-opacity));
  }

  .md\:tw-divide-blue-900 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #2a4365;
    border-color: rgba(42, 67, 101, var(--divide-opacity));
  }

  .md\:tw-divide-indigo-100 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #ebf4ff;
    border-color: rgba(235, 244, 255, var(--divide-opacity));
  }

  .md\:tw-divide-indigo-200 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #c3dafe;
    border-color: rgba(195, 218, 254, var(--divide-opacity));
  }

  .md\:tw-divide-indigo-300 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #a3bffa;
    border-color: rgba(163, 191, 250, var(--divide-opacity));
  }

  .md\:tw-divide-indigo-400 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #7f9cf5;
    border-color: rgba(127, 156, 245, var(--divide-opacity));
  }

  .md\:tw-divide-indigo-500 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #667eea;
    border-color: rgba(102, 126, 234, var(--divide-opacity));
  }

  .md\:tw-divide-indigo-600 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #5a67d8;
    border-color: rgba(90, 103, 216, var(--divide-opacity));
  }

  .md\:tw-divide-indigo-700 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #4c51bf;
    border-color: rgba(76, 81, 191, var(--divide-opacity));
  }

  .md\:tw-divide-indigo-800 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #434190;
    border-color: rgba(67, 65, 144, var(--divide-opacity));
  }

  .md\:tw-divide-indigo-900 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #3c366b;
    border-color: rgba(60, 54, 107, var(--divide-opacity));
  }

  .md\:tw-divide-purple-100 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #faf5ff;
    border-color: rgba(250, 245, 255, var(--divide-opacity));
  }

  .md\:tw-divide-purple-200 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #e9d8fd;
    border-color: rgba(233, 216, 253, var(--divide-opacity));
  }

  .md\:tw-divide-purple-300 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #d6bcfa;
    border-color: rgba(214, 188, 250, var(--divide-opacity));
  }

  .md\:tw-divide-purple-400 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #b794f4;
    border-color: rgba(183, 148, 244, var(--divide-opacity));
  }

  .md\:tw-divide-purple-500 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #9f7aea;
    border-color: rgba(159, 122, 234, var(--divide-opacity));
  }

  .md\:tw-divide-purple-600 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #805ad5;
    border-color: rgba(128, 90, 213, var(--divide-opacity));
  }

  .md\:tw-divide-purple-700 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #6b46c1;
    border-color: rgba(107, 70, 193, var(--divide-opacity));
  }

  .md\:tw-divide-purple-800 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #553c9a;
    border-color: rgba(85, 60, 154, var(--divide-opacity));
  }

  .md\:tw-divide-purple-900 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #44337a;
    border-color: rgba(68, 51, 122, var(--divide-opacity));
  }

  .md\:tw-divide-pink-100 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #fff5f7;
    border-color: rgba(255, 245, 247, var(--divide-opacity));
  }

  .md\:tw-divide-pink-200 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #fed7e2;
    border-color: rgba(254, 215, 226, var(--divide-opacity));
  }

  .md\:tw-divide-pink-300 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #fbb6ce;
    border-color: rgba(251, 182, 206, var(--divide-opacity));
  }

  .md\:tw-divide-pink-400 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #f687b3;
    border-color: rgba(246, 135, 179, var(--divide-opacity));
  }

  .md\:tw-divide-pink-500 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #ed64a6;
    border-color: rgba(237, 100, 166, var(--divide-opacity));
  }

  .md\:tw-divide-pink-600 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #d53f8c;
    border-color: rgba(213, 63, 140, var(--divide-opacity));
  }

  .md\:tw-divide-pink-700 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #b83280;
    border-color: rgba(184, 50, 128, var(--divide-opacity));
  }

  .md\:tw-divide-pink-800 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #97266d;
    border-color: rgba(151, 38, 109, var(--divide-opacity));
  }

  .md\:tw-divide-pink-900 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #702459;
    border-color: rgba(112, 36, 89, var(--divide-opacity));
  }

  .md\:tw-divide-solid > :not(template) ~ :not(template) {
    border-style: solid;
  }

  .md\:tw-divide-dashed > :not(template) ~ :not(template) {
    border-style: dashed;
  }

  .md\:tw-divide-dotted > :not(template) ~ :not(template) {
    border-style: dotted;
  }

  .md\:tw-divide-double > :not(template) ~ :not(template) {
    border-style: double;
  }

  .md\:tw-divide-none > :not(template) ~ :not(template) {
    border-style: none;
  }

  .md\:tw-divide-opacity-0 > :not(template) ~ :not(template) {
    --divide-opacity: 0;
  }

  .md\:tw-divide-opacity-25 > :not(template) ~ :not(template) {
    --divide-opacity: 0.25;
  }

  .md\:tw-divide-opacity-50 > :not(template) ~ :not(template) {
    --divide-opacity: 0.5;
  }

  .md\:tw-divide-opacity-75 > :not(template) ~ :not(template) {
    --divide-opacity: 0.75;
  }

  .md\:tw-divide-opacity-100 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
  }

  .md\:tw-sr-only {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    border-width: 0;
  }

  .md\:tw-not-sr-only {
    position: static;
    width: auto;
    height: auto;
    padding: 0;
    margin: 0;
    overflow: visible;
    clip: auto;
    white-space: normal;
  }

  .md\:focus\:tw-sr-only:focus {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    border-width: 0;
  }

  .md\:focus\:tw-not-sr-only:focus {
    position: static;
    width: auto;
    height: auto;
    padding: 0;
    margin: 0;
    overflow: visible;
    clip: auto;
    white-space: normal;
  }

  .md\:tw-appearance-none {
    -webkit-appearance: none;
       -moz-appearance: none;
            appearance: none;
  }

  .md\:tw-bg-fixed {
    background-attachment: fixed;
  }

  .md\:tw-bg-local {
    background-attachment: local;
  }

  .md\:tw-bg-scroll {
    background-attachment: scroll;
  }

  .md\:tw-bg-clip-border {
    background-clip: border-box;
  }

  .md\:tw-bg-clip-padding {
    background-clip: padding-box;
  }

  .md\:tw-bg-clip-content {
    background-clip: content-box;
  }

  .md\:tw-bg-clip-text {
    -webkit-background-clip: text;
            background-clip: text;
  }

  .md\:tw-bg-transparent {
    background-color: transparent;
  }

  .md\:tw-bg-current {
    background-color: currentColor;
  }

  .md\:tw-bg-black {
    --bg-opacity: 1;
    background-color: #000;
    background-color: rgba(0, 0, 0, var(--bg-opacity));
  }

  .md\:tw-bg-white {
    --bg-opacity: 1;
    background-color: #fff;
    background-color: rgba(255, 255, 255, var(--bg-opacity));
  }

  .md\:tw-bg-gray-100 {
    --bg-opacity: 1;
    background-color: #f7fafc;
    background-color: rgba(247, 250, 252, var(--bg-opacity));
  }

  .md\:tw-bg-gray-200 {
    --bg-opacity: 1;
    background-color: #edf2f7;
    background-color: rgba(237, 242, 247, var(--bg-opacity));
  }

  .md\:tw-bg-gray-300 {
    --bg-opacity: 1;
    background-color: #e2e8f0;
    background-color: rgba(226, 232, 240, var(--bg-opacity));
  }

  .md\:tw-bg-gray-400 {
    --bg-opacity: 1;
    background-color: #cbd5e0;
    background-color: rgba(203, 213, 224, var(--bg-opacity));
  }

  .md\:tw-bg-gray-500 {
    --bg-opacity: 1;
    background-color: #a0aec0;
    background-color: rgba(160, 174, 192, var(--bg-opacity));
  }

  .md\:tw-bg-gray-600 {
    --bg-opacity: 1;
    background-color: #718096;
    background-color: rgba(113, 128, 150, var(--bg-opacity));
  }

  .md\:tw-bg-gray-700 {
    --bg-opacity: 1;
    background-color: #4a5568;
    background-color: rgba(74, 85, 104, var(--bg-opacity));
  }

  .md\:tw-bg-gray-800 {
    --bg-opacity: 1;
    background-color: #2d3748;
    background-color: rgba(45, 55, 72, var(--bg-opacity));
  }

  .md\:tw-bg-gray-900 {
    --bg-opacity: 1;
    background-color: #1a202c;
    background-color: rgba(26, 32, 44, var(--bg-opacity));
  }

  .md\:tw-bg-red-100 {
    --bg-opacity: 1;
    background-color: #fff5f5;
    background-color: rgba(255, 245, 245, var(--bg-opacity));
  }

  .md\:tw-bg-red-200 {
    --bg-opacity: 1;
    background-color: #fed7d7;
    background-color: rgba(254, 215, 215, var(--bg-opacity));
  }

  .md\:tw-bg-red-300 {
    --bg-opacity: 1;
    background-color: #feb2b2;
    background-color: rgba(254, 178, 178, var(--bg-opacity));
  }

  .md\:tw-bg-red-400 {
    --bg-opacity: 1;
    background-color: #fc8181;
    background-color: rgba(252, 129, 129, var(--bg-opacity));
  }

  .md\:tw-bg-red-500 {
    --bg-opacity: 1;
    background-color: #f56565;
    background-color: rgba(245, 101, 101, var(--bg-opacity));
  }

  .md\:tw-bg-red-600 {
    --bg-opacity: 1;
    background-color: #e53e3e;
    background-color: rgba(229, 62, 62, var(--bg-opacity));
  }

  .md\:tw-bg-red-700 {
    --bg-opacity: 1;
    background-color: #c53030;
    background-color: rgba(197, 48, 48, var(--bg-opacity));
  }

  .md\:tw-bg-red-800 {
    --bg-opacity: 1;
    background-color: #9b2c2c;
    background-color: rgba(155, 44, 44, var(--bg-opacity));
  }

  .md\:tw-bg-red-900 {
    --bg-opacity: 1;
    background-color: #742a2a;
    background-color: rgba(116, 42, 42, var(--bg-opacity));
  }

  .md\:tw-bg-orange-100 {
    --bg-opacity: 1;
    background-color: #fffaf0;
    background-color: rgba(255, 250, 240, var(--bg-opacity));
  }

  .md\:tw-bg-orange-200 {
    --bg-opacity: 1;
    background-color: #feebc8;
    background-color: rgba(254, 235, 200, var(--bg-opacity));
  }

  .md\:tw-bg-orange-300 {
    --bg-opacity: 1;
    background-color: #fbd38d;
    background-color: rgba(251, 211, 141, var(--bg-opacity));
  }

  .md\:tw-bg-orange-400 {
    --bg-opacity: 1;
    background-color: #f6ad55;
    background-color: rgba(246, 173, 85, var(--bg-opacity));
  }

  .md\:tw-bg-orange-500 {
    --bg-opacity: 1;
    background-color: #ed8936;
    background-color: rgba(237, 137, 54, var(--bg-opacity));
  }

  .md\:tw-bg-orange-600 {
    --bg-opacity: 1;
    background-color: #dd6b20;
    background-color: rgba(221, 107, 32, var(--bg-opacity));
  }

  .md\:tw-bg-orange-700 {
    --bg-opacity: 1;
    background-color: #c05621;
    background-color: rgba(192, 86, 33, var(--bg-opacity));
  }

  .md\:tw-bg-orange-800 {
    --bg-opacity: 1;
    background-color: #9c4221;
    background-color: rgba(156, 66, 33, var(--bg-opacity));
  }

  .md\:tw-bg-orange-900 {
    --bg-opacity: 1;
    background-color: #7b341e;
    background-color: rgba(123, 52, 30, var(--bg-opacity));
  }

  .md\:tw-bg-yellow-100 {
    --bg-opacity: 1;
    background-color: #fffff0;
    background-color: rgba(255, 255, 240, var(--bg-opacity));
  }

  .md\:tw-bg-yellow-200 {
    --bg-opacity: 1;
    background-color: #fefcbf;
    background-color: rgba(254, 252, 191, var(--bg-opacity));
  }

  .md\:tw-bg-yellow-300 {
    --bg-opacity: 1;
    background-color: #faf089;
    background-color: rgba(250, 240, 137, var(--bg-opacity));
  }

  .md\:tw-bg-yellow-400 {
    --bg-opacity: 1;
    background-color: #f6e05e;
    background-color: rgba(246, 224, 94, var(--bg-opacity));
  }

  .md\:tw-bg-yellow-500 {
    --bg-opacity: 1;
    background-color: #ecc94b;
    background-color: rgba(236, 201, 75, var(--bg-opacity));
  }

  .md\:tw-bg-yellow-600 {
    --bg-opacity: 1;
    background-color: #d69e2e;
    background-color: rgba(214, 158, 46, var(--bg-opacity));
  }

  .md\:tw-bg-yellow-700 {
    --bg-opacity: 1;
    background-color: #b7791f;
    background-color: rgba(183, 121, 31, var(--bg-opacity));
  }

  .md\:tw-bg-yellow-800 {
    --bg-opacity: 1;
    background-color: #975a16;
    background-color: rgba(151, 90, 22, var(--bg-opacity));
  }

  .md\:tw-bg-yellow-900 {
    --bg-opacity: 1;
    background-color: #744210;
    background-color: rgba(116, 66, 16, var(--bg-opacity));
  }

  .md\:tw-bg-green-100 {
    --bg-opacity: 1;
    background-color: #f0fff4;
    background-color: rgba(240, 255, 244, var(--bg-opacity));
  }

  .md\:tw-bg-green-200 {
    --bg-opacity: 1;
    background-color: #c6f6d5;
    background-color: rgba(198, 246, 213, var(--bg-opacity));
  }

  .md\:tw-bg-green-300 {
    --bg-opacity: 1;
    background-color: #9ae6b4;
    background-color: rgba(154, 230, 180, var(--bg-opacity));
  }

  .md\:tw-bg-green-400 {
    --bg-opacity: 1;
    background-color: #68d391;
    background-color: rgba(104, 211, 145, var(--bg-opacity));
  }

  .md\:tw-bg-green-500 {
    --bg-opacity: 1;
    background-color: #48bb78;
    background-color: rgba(72, 187, 120, var(--bg-opacity));
  }

  .md\:tw-bg-green-600 {
    --bg-opacity: 1;
    background-color: #38a169;
    background-color: rgba(56, 161, 105, var(--bg-opacity));
  }

  .md\:tw-bg-green-700 {
    --bg-opacity: 1;
    background-color: #2f855a;
    background-color: rgba(47, 133, 90, var(--bg-opacity));
  }

  .md\:tw-bg-green-800 {
    --bg-opacity: 1;
    background-color: #276749;
    background-color: rgba(39, 103, 73, var(--bg-opacity));
  }

  .md\:tw-bg-green-900 {
    --bg-opacity: 1;
    background-color: #22543d;
    background-color: rgba(34, 84, 61, var(--bg-opacity));
  }

  .md\:tw-bg-teal-100 {
    --bg-opacity: 1;
    background-color: #e6fffa;
    background-color: rgba(230, 255, 250, var(--bg-opacity));
  }

  .md\:tw-bg-teal-200 {
    --bg-opacity: 1;
    background-color: #b2f5ea;
    background-color: rgba(178, 245, 234, var(--bg-opacity));
  }

  .md\:tw-bg-teal-300 {
    --bg-opacity: 1;
    background-color: #81e6d9;
    background-color: rgba(129, 230, 217, var(--bg-opacity));
  }

  .md\:tw-bg-teal-400 {
    --bg-opacity: 1;
    background-color: #4fd1c5;
    background-color: rgba(79, 209, 197, var(--bg-opacity));
  }

  .md\:tw-bg-teal-500 {
    --bg-opacity: 1;
    background-color: #38b2ac;
    background-color: rgba(56, 178, 172, var(--bg-opacity));
  }

  .md\:tw-bg-teal-600 {
    --bg-opacity: 1;
    background-color: #319795;
    background-color: rgba(49, 151, 149, var(--bg-opacity));
  }

  .md\:tw-bg-teal-700 {
    --bg-opacity: 1;
    background-color: #2c7a7b;
    background-color: rgba(44, 122, 123, var(--bg-opacity));
  }

  .md\:tw-bg-teal-800 {
    --bg-opacity: 1;
    background-color: #285e61;
    background-color: rgba(40, 94, 97, var(--bg-opacity));
  }

  .md\:tw-bg-teal-900 {
    --bg-opacity: 1;
    background-color: #234e52;
    background-color: rgba(35, 78, 82, var(--bg-opacity));
  }

  .md\:tw-bg-blue-100 {
    --bg-opacity: 1;
    background-color: #ebf8ff;
    background-color: rgba(235, 248, 255, var(--bg-opacity));
  }

  .md\:tw-bg-blue-200 {
    --bg-opacity: 1;
    background-color: #bee3f8;
    background-color: rgba(190, 227, 248, var(--bg-opacity));
  }

  .md\:tw-bg-blue-300 {
    --bg-opacity: 1;
    background-color: #90cdf4;
    background-color: rgba(144, 205, 244, var(--bg-opacity));
  }

  .md\:tw-bg-blue-400 {
    --bg-opacity: 1;
    background-color: #63b3ed;
    background-color: rgba(99, 179, 237, var(--bg-opacity));
  }

  .md\:tw-bg-blue-500 {
    --bg-opacity: 1;
    background-color: #4299e1;
    background-color: rgba(66, 153, 225, var(--bg-opacity));
  }

  .md\:tw-bg-blue-600 {
    --bg-opacity: 1;
    background-color: #3182ce;
    background-color: rgba(49, 130, 206, var(--bg-opacity));
  }

  .md\:tw-bg-blue-700 {
    --bg-opacity: 1;
    background-color: #2b6cb0;
    background-color: rgba(43, 108, 176, var(--bg-opacity));
  }

  .md\:tw-bg-blue-800 {
    --bg-opacity: 1;
    background-color: #2c5282;
    background-color: rgba(44, 82, 130, var(--bg-opacity));
  }

  .md\:tw-bg-blue-900 {
    --bg-opacity: 1;
    background-color: #2a4365;
    background-color: rgba(42, 67, 101, var(--bg-opacity));
  }

  .md\:tw-bg-indigo-100 {
    --bg-opacity: 1;
    background-color: #ebf4ff;
    background-color: rgba(235, 244, 255, var(--bg-opacity));
  }

  .md\:tw-bg-indigo-200 {
    --bg-opacity: 1;
    background-color: #c3dafe;
    background-color: rgba(195, 218, 254, var(--bg-opacity));
  }

  .md\:tw-bg-indigo-300 {
    --bg-opacity: 1;
    background-color: #a3bffa;
    background-color: rgba(163, 191, 250, var(--bg-opacity));
  }

  .md\:tw-bg-indigo-400 {
    --bg-opacity: 1;
    background-color: #7f9cf5;
    background-color: rgba(127, 156, 245, var(--bg-opacity));
  }

  .md\:tw-bg-indigo-500 {
    --bg-opacity: 1;
    background-color: #667eea;
    background-color: rgba(102, 126, 234, var(--bg-opacity));
  }

  .md\:tw-bg-indigo-600 {
    --bg-opacity: 1;
    background-color: #5a67d8;
    background-color: rgba(90, 103, 216, var(--bg-opacity));
  }

  .md\:tw-bg-indigo-700 {
    --bg-opacity: 1;
    background-color: #4c51bf;
    background-color: rgba(76, 81, 191, var(--bg-opacity));
  }

  .md\:tw-bg-indigo-800 {
    --bg-opacity: 1;
    background-color: #434190;
    background-color: rgba(67, 65, 144, var(--bg-opacity));
  }

  .md\:tw-bg-indigo-900 {
    --bg-opacity: 1;
    background-color: #3c366b;
    background-color: rgba(60, 54, 107, var(--bg-opacity));
  }

  .md\:tw-bg-purple-100 {
    --bg-opacity: 1;
    background-color: #faf5ff;
    background-color: rgba(250, 245, 255, var(--bg-opacity));
  }

  .md\:tw-bg-purple-200 {
    --bg-opacity: 1;
    background-color: #e9d8fd;
    background-color: rgba(233, 216, 253, var(--bg-opacity));
  }

  .md\:tw-bg-purple-300 {
    --bg-opacity: 1;
    background-color: #d6bcfa;
    background-color: rgba(214, 188, 250, var(--bg-opacity));
  }

  .md\:tw-bg-purple-400 {
    --bg-opacity: 1;
    background-color: #b794f4;
    background-color: rgba(183, 148, 244, var(--bg-opacity));
  }

  .md\:tw-bg-purple-500 {
    --bg-opacity: 1;
    background-color: #9f7aea;
    background-color: rgba(159, 122, 234, var(--bg-opacity));
  }

  .md\:tw-bg-purple-600 {
    --bg-opacity: 1;
    background-color: #805ad5;
    background-color: rgba(128, 90, 213, var(--bg-opacity));
  }

  .md\:tw-bg-purple-700 {
    --bg-opacity: 1;
    background-color: #6b46c1;
    background-color: rgba(107, 70, 193, var(--bg-opacity));
  }

  .md\:tw-bg-purple-800 {
    --bg-opacity: 1;
    background-color: #553c9a;
    background-color: rgba(85, 60, 154, var(--bg-opacity));
  }

  .md\:tw-bg-purple-900 {
    --bg-opacity: 1;
    background-color: #44337a;
    background-color: rgba(68, 51, 122, var(--bg-opacity));
  }

  .md\:tw-bg-pink-100 {
    --bg-opacity: 1;
    background-color: #fff5f7;
    background-color: rgba(255, 245, 247, var(--bg-opacity));
  }

  .md\:tw-bg-pink-200 {
    --bg-opacity: 1;
    background-color: #fed7e2;
    background-color: rgba(254, 215, 226, var(--bg-opacity));
  }

  .md\:tw-bg-pink-300 {
    --bg-opacity: 1;
    background-color: #fbb6ce;
    background-color: rgba(251, 182, 206, var(--bg-opacity));
  }

  .md\:tw-bg-pink-400 {
    --bg-opacity: 1;
    background-color: #f687b3;
    background-color: rgba(246, 135, 179, var(--bg-opacity));
  }

  .md\:tw-bg-pink-500 {
    --bg-opacity: 1;
    background-color: #ed64a6;
    background-color: rgba(237, 100, 166, var(--bg-opacity));
  }

  .md\:tw-bg-pink-600 {
    --bg-opacity: 1;
    background-color: #d53f8c;
    background-color: rgba(213, 63, 140, var(--bg-opacity));
  }

  .md\:tw-bg-pink-700 {
    --bg-opacity: 1;
    background-color: #b83280;
    background-color: rgba(184, 50, 128, var(--bg-opacity));
  }

  .md\:tw-bg-pink-800 {
    --bg-opacity: 1;
    background-color: #97266d;
    background-color: rgba(151, 38, 109, var(--bg-opacity));
  }

  .md\:tw-bg-pink-900 {
    --bg-opacity: 1;
    background-color: #702459;
    background-color: rgba(112, 36, 89, var(--bg-opacity));
  }

  .md\:hover\:tw-bg-transparent:hover {
    background-color: transparent;
  }

  .md\:hover\:tw-bg-current:hover {
    background-color: currentColor;
  }

  .md\:hover\:tw-bg-black:hover {
    --bg-opacity: 1;
    background-color: #000;
    background-color: rgba(0, 0, 0, var(--bg-opacity));
  }

  .md\:hover\:tw-bg-white:hover {
    --bg-opacity: 1;
    background-color: #fff;
    background-color: rgba(255, 255, 255, var(--bg-opacity));
  }

  .md\:hover\:tw-bg-gray-100:hover {
    --bg-opacity: 1;
    background-color: #f7fafc;
    background-color: rgba(247, 250, 252, var(--bg-opacity));
  }

  .md\:hover\:tw-bg-gray-200:hover {
    --bg-opacity: 1;
    background-color: #edf2f7;
    background-color: rgba(237, 242, 247, var(--bg-opacity));
  }

  .md\:hover\:tw-bg-gray-300:hover {
    --bg-opacity: 1;
    background-color: #e2e8f0;
    background-color: rgba(226, 232, 240, var(--bg-opacity));
  }

  .md\:hover\:tw-bg-gray-400:hover {
    --bg-opacity: 1;
    background-color: #cbd5e0;
    background-color: rgba(203, 213, 224, var(--bg-opacity));
  }

  .md\:hover\:tw-bg-gray-500:hover {
    --bg-opacity: 1;
    background-color: #a0aec0;
    background-color: rgba(160, 174, 192, var(--bg-opacity));
  }

  .md\:hover\:tw-bg-gray-600:hover {
    --bg-opacity: 1;
    background-color: #718096;
    background-color: rgba(113, 128, 150, var(--bg-opacity));
  }

  .md\:hover\:tw-bg-gray-700:hover {
    --bg-opacity: 1;
    background-color: #4a5568;
    background-color: rgba(74, 85, 104, var(--bg-opacity));
  }

  .md\:hover\:tw-bg-gray-800:hover {
    --bg-opacity: 1;
    background-color: #2d3748;
    background-color: rgba(45, 55, 72, var(--bg-opacity));
  }

  .md\:hover\:tw-bg-gray-900:hover {
    --bg-opacity: 1;
    background-color: #1a202c;
    background-color: rgba(26, 32, 44, var(--bg-opacity));
  }

  .md\:hover\:tw-bg-red-100:hover {
    --bg-opacity: 1;
    background-color: #fff5f5;
    background-color: rgba(255, 245, 245, var(--bg-opacity));
  }

  .md\:hover\:tw-bg-red-200:hover {
    --bg-opacity: 1;
    background-color: #fed7d7;
    background-color: rgba(254, 215, 215, var(--bg-opacity));
  }

  .md\:hover\:tw-bg-red-300:hover {
    --bg-opacity: 1;
    background-color: #feb2b2;
    background-color: rgba(254, 178, 178, var(--bg-opacity));
  }

  .md\:hover\:tw-bg-red-400:hover {
    --bg-opacity: 1;
    background-color: #fc8181;
    background-color: rgba(252, 129, 129, var(--bg-opacity));
  }

  .md\:hover\:tw-bg-red-500:hover {
    --bg-opacity: 1;
    background-color: #f56565;
    background-color: rgba(245, 101, 101, var(--bg-opacity));
  }

  .md\:hover\:tw-bg-red-600:hover {
    --bg-opacity: 1;
    background-color: #e53e3e;
    background-color: rgba(229, 62, 62, var(--bg-opacity));
  }

  .md\:hover\:tw-bg-red-700:hover {
    --bg-opacity: 1;
    background-color: #c53030;
    background-color: rgba(197, 48, 48, var(--bg-opacity));
  }

  .md\:hover\:tw-bg-red-800:hover {
    --bg-opacity: 1;
    background-color: #9b2c2c;
    background-color: rgba(155, 44, 44, var(--bg-opacity));
  }

  .md\:hover\:tw-bg-red-900:hover {
    --bg-opacity: 1;
    background-color: #742a2a;
    background-color: rgba(116, 42, 42, var(--bg-opacity));
  }

  .md\:hover\:tw-bg-orange-100:hover {
    --bg-opacity: 1;
    background-color: #fffaf0;
    background-color: rgba(255, 250, 240, var(--bg-opacity));
  }

  .md\:hover\:tw-bg-orange-200:hover {
    --bg-opacity: 1;
    background-color: #feebc8;
    background-color: rgba(254, 235, 200, var(--bg-opacity));
  }

  .md\:hover\:tw-bg-orange-300:hover {
    --bg-opacity: 1;
    background-color: #fbd38d;
    background-color: rgba(251, 211, 141, var(--bg-opacity));
  }

  .md\:hover\:tw-bg-orange-400:hover {
    --bg-opacity: 1;
    background-color: #f6ad55;
    background-color: rgba(246, 173, 85, var(--bg-opacity));
  }

  .md\:hover\:tw-bg-orange-500:hover {
    --bg-opacity: 1;
    background-color: #ed8936;
    background-color: rgba(237, 137, 54, var(--bg-opacity));
  }

  .md\:hover\:tw-bg-orange-600:hover {
    --bg-opacity: 1;
    background-color: #dd6b20;
    background-color: rgba(221, 107, 32, var(--bg-opacity));
  }

  .md\:hover\:tw-bg-orange-700:hover {
    --bg-opacity: 1;
    background-color: #c05621;
    background-color: rgba(192, 86, 33, var(--bg-opacity));
  }

  .md\:hover\:tw-bg-orange-800:hover {
    --bg-opacity: 1;
    background-color: #9c4221;
    background-color: rgba(156, 66, 33, var(--bg-opacity));
  }

  .md\:hover\:tw-bg-orange-900:hover {
    --bg-opacity: 1;
    background-color: #7b341e;
    background-color: rgba(123, 52, 30, var(--bg-opacity));
  }

  .md\:hover\:tw-bg-yellow-100:hover {
    --bg-opacity: 1;
    background-color: #fffff0;
    background-color: rgba(255, 255, 240, var(--bg-opacity));
  }

  .md\:hover\:tw-bg-yellow-200:hover {
    --bg-opacity: 1;
    background-color: #fefcbf;
    background-color: rgba(254, 252, 191, var(--bg-opacity));
  }

  .md\:hover\:tw-bg-yellow-300:hover {
    --bg-opacity: 1;
    background-color: #faf089;
    background-color: rgba(250, 240, 137, var(--bg-opacity));
  }

  .md\:hover\:tw-bg-yellow-400:hover {
    --bg-opacity: 1;
    background-color: #f6e05e;
    background-color: rgba(246, 224, 94, var(--bg-opacity));
  }

  .md\:hover\:tw-bg-yellow-500:hover {
    --bg-opacity: 1;
    background-color: #ecc94b;
    background-color: rgba(236, 201, 75, var(--bg-opacity));
  }

  .md\:hover\:tw-bg-yellow-600:hover {
    --bg-opacity: 1;
    background-color: #d69e2e;
    background-color: rgba(214, 158, 46, var(--bg-opacity));
  }

  .md\:hover\:tw-bg-yellow-700:hover {
    --bg-opacity: 1;
    background-color: #b7791f;
    background-color: rgba(183, 121, 31, var(--bg-opacity));
  }

  .md\:hover\:tw-bg-yellow-800:hover {
    --bg-opacity: 1;
    background-color: #975a16;
    background-color: rgba(151, 90, 22, var(--bg-opacity));
  }

  .md\:hover\:tw-bg-yellow-900:hover {
    --bg-opacity: 1;
    background-color: #744210;
    background-color: rgba(116, 66, 16, var(--bg-opacity));
  }

  .md\:hover\:tw-bg-green-100:hover {
    --bg-opacity: 1;
    background-color: #f0fff4;
    background-color: rgba(240, 255, 244, var(--bg-opacity));
  }

  .md\:hover\:tw-bg-green-200:hover {
    --bg-opacity: 1;
    background-color: #c6f6d5;
    background-color: rgba(198, 246, 213, var(--bg-opacity));
  }

  .md\:hover\:tw-bg-green-300:hover {
    --bg-opacity: 1;
    background-color: #9ae6b4;
    background-color: rgba(154, 230, 180, var(--bg-opacity));
  }

  .md\:hover\:tw-bg-green-400:hover {
    --bg-opacity: 1;
    background-color: #68d391;
    background-color: rgba(104, 211, 145, var(--bg-opacity));
  }

  .md\:hover\:tw-bg-green-500:hover {
    --bg-opacity: 1;
    background-color: #48bb78;
    background-color: rgba(72, 187, 120, var(--bg-opacity));
  }

  .md\:hover\:tw-bg-green-600:hover {
    --bg-opacity: 1;
    background-color: #38a169;
    background-color: rgba(56, 161, 105, var(--bg-opacity));
  }

  .md\:hover\:tw-bg-green-700:hover {
    --bg-opacity: 1;
    background-color: #2f855a;
    background-color: rgba(47, 133, 90, var(--bg-opacity));
  }

  .md\:hover\:tw-bg-green-800:hover {
    --bg-opacity: 1;
    background-color: #276749;
    background-color: rgba(39, 103, 73, var(--bg-opacity));
  }

  .md\:hover\:tw-bg-green-900:hover {
    --bg-opacity: 1;
    background-color: #22543d;
    background-color: rgba(34, 84, 61, var(--bg-opacity));
  }

  .md\:hover\:tw-bg-teal-100:hover {
    --bg-opacity: 1;
    background-color: #e6fffa;
    background-color: rgba(230, 255, 250, var(--bg-opacity));
  }

  .md\:hover\:tw-bg-teal-200:hover {
    --bg-opacity: 1;
    background-color: #b2f5ea;
    background-color: rgba(178, 245, 234, var(--bg-opacity));
  }

  .md\:hover\:tw-bg-teal-300:hover {
    --bg-opacity: 1;
    background-color: #81e6d9;
    background-color: rgba(129, 230, 217, var(--bg-opacity));
  }

  .md\:hover\:tw-bg-teal-400:hover {
    --bg-opacity: 1;
    background-color: #4fd1c5;
    background-color: rgba(79, 209, 197, var(--bg-opacity));
  }

  .md\:hover\:tw-bg-teal-500:hover {
    --bg-opacity: 1;
    background-color: #38b2ac;
    background-color: rgba(56, 178, 172, var(--bg-opacity));
  }

  .md\:hover\:tw-bg-teal-600:hover {
    --bg-opacity: 1;
    background-color: #319795;
    background-color: rgba(49, 151, 149, var(--bg-opacity));
  }

  .md\:hover\:tw-bg-teal-700:hover {
    --bg-opacity: 1;
    background-color: #2c7a7b;
    background-color: rgba(44, 122, 123, var(--bg-opacity));
  }

  .md\:hover\:tw-bg-teal-800:hover {
    --bg-opacity: 1;
    background-color: #285e61;
    background-color: rgba(40, 94, 97, var(--bg-opacity));
  }

  .md\:hover\:tw-bg-teal-900:hover {
    --bg-opacity: 1;
    background-color: #234e52;
    background-color: rgba(35, 78, 82, var(--bg-opacity));
  }

  .md\:hover\:tw-bg-blue-100:hover {
    --bg-opacity: 1;
    background-color: #ebf8ff;
    background-color: rgba(235, 248, 255, var(--bg-opacity));
  }

  .md\:hover\:tw-bg-blue-200:hover {
    --bg-opacity: 1;
    background-color: #bee3f8;
    background-color: rgba(190, 227, 248, var(--bg-opacity));
  }

  .md\:hover\:tw-bg-blue-300:hover {
    --bg-opacity: 1;
    background-color: #90cdf4;
    background-color: rgba(144, 205, 244, var(--bg-opacity));
  }

  .md\:hover\:tw-bg-blue-400:hover {
    --bg-opacity: 1;
    background-color: #63b3ed;
    background-color: rgba(99, 179, 237, var(--bg-opacity));
  }

  .md\:hover\:tw-bg-blue-500:hover {
    --bg-opacity: 1;
    background-color: #4299e1;
    background-color: rgba(66, 153, 225, var(--bg-opacity));
  }

  .md\:hover\:tw-bg-blue-600:hover {
    --bg-opacity: 1;
    background-color: #3182ce;
    background-color: rgba(49, 130, 206, var(--bg-opacity));
  }

  .md\:hover\:tw-bg-blue-700:hover {
    --bg-opacity: 1;
    background-color: #2b6cb0;
    background-color: rgba(43, 108, 176, var(--bg-opacity));
  }

  .md\:hover\:tw-bg-blue-800:hover {
    --bg-opacity: 1;
    background-color: #2c5282;
    background-color: rgba(44, 82, 130, var(--bg-opacity));
  }

  .md\:hover\:tw-bg-blue-900:hover {
    --bg-opacity: 1;
    background-color: #2a4365;
    background-color: rgba(42, 67, 101, var(--bg-opacity));
  }

  .md\:hover\:tw-bg-indigo-100:hover {
    --bg-opacity: 1;
    background-color: #ebf4ff;
    background-color: rgba(235, 244, 255, var(--bg-opacity));
  }

  .md\:hover\:tw-bg-indigo-200:hover {
    --bg-opacity: 1;
    background-color: #c3dafe;
    background-color: rgba(195, 218, 254, var(--bg-opacity));
  }

  .md\:hover\:tw-bg-indigo-300:hover {
    --bg-opacity: 1;
    background-color: #a3bffa;
    background-color: rgba(163, 191, 250, var(--bg-opacity));
  }

  .md\:hover\:tw-bg-indigo-400:hover {
    --bg-opacity: 1;
    background-color: #7f9cf5;
    background-color: rgba(127, 156, 245, var(--bg-opacity));
  }

  .md\:hover\:tw-bg-indigo-500:hover {
    --bg-opacity: 1;
    background-color: #667eea;
    background-color: rgba(102, 126, 234, var(--bg-opacity));
  }

  .md\:hover\:tw-bg-indigo-600:hover {
    --bg-opacity: 1;
    background-color: #5a67d8;
    background-color: rgba(90, 103, 216, var(--bg-opacity));
  }

  .md\:hover\:tw-bg-indigo-700:hover {
    --bg-opacity: 1;
    background-color: #4c51bf;
    background-color: rgba(76, 81, 191, var(--bg-opacity));
  }

  .md\:hover\:tw-bg-indigo-800:hover {
    --bg-opacity: 1;
    background-color: #434190;
    background-color: rgba(67, 65, 144, var(--bg-opacity));
  }

  .md\:hover\:tw-bg-indigo-900:hover {
    --bg-opacity: 1;
    background-color: #3c366b;
    background-color: rgba(60, 54, 107, var(--bg-opacity));
  }

  .md\:hover\:tw-bg-purple-100:hover {
    --bg-opacity: 1;
    background-color: #faf5ff;
    background-color: rgba(250, 245, 255, var(--bg-opacity));
  }

  .md\:hover\:tw-bg-purple-200:hover {
    --bg-opacity: 1;
    background-color: #e9d8fd;
    background-color: rgba(233, 216, 253, var(--bg-opacity));
  }

  .md\:hover\:tw-bg-purple-300:hover {
    --bg-opacity: 1;
    background-color: #d6bcfa;
    background-color: rgba(214, 188, 250, var(--bg-opacity));
  }

  .md\:hover\:tw-bg-purple-400:hover {
    --bg-opacity: 1;
    background-color: #b794f4;
    background-color: rgba(183, 148, 244, var(--bg-opacity));
  }

  .md\:hover\:tw-bg-purple-500:hover {
    --bg-opacity: 1;
    background-color: #9f7aea;
    background-color: rgba(159, 122, 234, var(--bg-opacity));
  }

  .md\:hover\:tw-bg-purple-600:hover {
    --bg-opacity: 1;
    background-color: #805ad5;
    background-color: rgba(128, 90, 213, var(--bg-opacity));
  }

  .md\:hover\:tw-bg-purple-700:hover {
    --bg-opacity: 1;
    background-color: #6b46c1;
    background-color: rgba(107, 70, 193, var(--bg-opacity));
  }

  .md\:hover\:tw-bg-purple-800:hover {
    --bg-opacity: 1;
    background-color: #553c9a;
    background-color: rgba(85, 60, 154, var(--bg-opacity));
  }

  .md\:hover\:tw-bg-purple-900:hover {
    --bg-opacity: 1;
    background-color: #44337a;
    background-color: rgba(68, 51, 122, var(--bg-opacity));
  }

  .md\:hover\:tw-bg-pink-100:hover {
    --bg-opacity: 1;
    background-color: #fff5f7;
    background-color: rgba(255, 245, 247, var(--bg-opacity));
  }

  .md\:hover\:tw-bg-pink-200:hover {
    --bg-opacity: 1;
    background-color: #fed7e2;
    background-color: rgba(254, 215, 226, var(--bg-opacity));
  }

  .md\:hover\:tw-bg-pink-300:hover {
    --bg-opacity: 1;
    background-color: #fbb6ce;
    background-color: rgba(251, 182, 206, var(--bg-opacity));
  }

  .md\:hover\:tw-bg-pink-400:hover {
    --bg-opacity: 1;
    background-color: #f687b3;
    background-color: rgba(246, 135, 179, var(--bg-opacity));
  }

  .md\:hover\:tw-bg-pink-500:hover {
    --bg-opacity: 1;
    background-color: #ed64a6;
    background-color: rgba(237, 100, 166, var(--bg-opacity));
  }

  .md\:hover\:tw-bg-pink-600:hover {
    --bg-opacity: 1;
    background-color: #d53f8c;
    background-color: rgba(213, 63, 140, var(--bg-opacity));
  }

  .md\:hover\:tw-bg-pink-700:hover {
    --bg-opacity: 1;
    background-color: #b83280;
    background-color: rgba(184, 50, 128, var(--bg-opacity));
  }

  .md\:hover\:tw-bg-pink-800:hover {
    --bg-opacity: 1;
    background-color: #97266d;
    background-color: rgba(151, 38, 109, var(--bg-opacity));
  }

  .md\:hover\:tw-bg-pink-900:hover {
    --bg-opacity: 1;
    background-color: #702459;
    background-color: rgba(112, 36, 89, var(--bg-opacity));
  }

  .md\:focus\:tw-bg-transparent:focus {
    background-color: transparent;
  }

  .md\:focus\:tw-bg-current:focus {
    background-color: currentColor;
  }

  .md\:focus\:tw-bg-black:focus {
    --bg-opacity: 1;
    background-color: #000;
    background-color: rgba(0, 0, 0, var(--bg-opacity));
  }

  .md\:focus\:tw-bg-white:focus {
    --bg-opacity: 1;
    background-color: #fff;
    background-color: rgba(255, 255, 255, var(--bg-opacity));
  }

  .md\:focus\:tw-bg-gray-100:focus {
    --bg-opacity: 1;
    background-color: #f7fafc;
    background-color: rgba(247, 250, 252, var(--bg-opacity));
  }

  .md\:focus\:tw-bg-gray-200:focus {
    --bg-opacity: 1;
    background-color: #edf2f7;
    background-color: rgba(237, 242, 247, var(--bg-opacity));
  }

  .md\:focus\:tw-bg-gray-300:focus {
    --bg-opacity: 1;
    background-color: #e2e8f0;
    background-color: rgba(226, 232, 240, var(--bg-opacity));
  }

  .md\:focus\:tw-bg-gray-400:focus {
    --bg-opacity: 1;
    background-color: #cbd5e0;
    background-color: rgba(203, 213, 224, var(--bg-opacity));
  }

  .md\:focus\:tw-bg-gray-500:focus {
    --bg-opacity: 1;
    background-color: #a0aec0;
    background-color: rgba(160, 174, 192, var(--bg-opacity));
  }

  .md\:focus\:tw-bg-gray-600:focus {
    --bg-opacity: 1;
    background-color: #718096;
    background-color: rgba(113, 128, 150, var(--bg-opacity));
  }

  .md\:focus\:tw-bg-gray-700:focus {
    --bg-opacity: 1;
    background-color: #4a5568;
    background-color: rgba(74, 85, 104, var(--bg-opacity));
  }

  .md\:focus\:tw-bg-gray-800:focus {
    --bg-opacity: 1;
    background-color: #2d3748;
    background-color: rgba(45, 55, 72, var(--bg-opacity));
  }

  .md\:focus\:tw-bg-gray-900:focus {
    --bg-opacity: 1;
    background-color: #1a202c;
    background-color: rgba(26, 32, 44, var(--bg-opacity));
  }

  .md\:focus\:tw-bg-red-100:focus {
    --bg-opacity: 1;
    background-color: #fff5f5;
    background-color: rgba(255, 245, 245, var(--bg-opacity));
  }

  .md\:focus\:tw-bg-red-200:focus {
    --bg-opacity: 1;
    background-color: #fed7d7;
    background-color: rgba(254, 215, 215, var(--bg-opacity));
  }

  .md\:focus\:tw-bg-red-300:focus {
    --bg-opacity: 1;
    background-color: #feb2b2;
    background-color: rgba(254, 178, 178, var(--bg-opacity));
  }

  .md\:focus\:tw-bg-red-400:focus {
    --bg-opacity: 1;
    background-color: #fc8181;
    background-color: rgba(252, 129, 129, var(--bg-opacity));
  }

  .md\:focus\:tw-bg-red-500:focus {
    --bg-opacity: 1;
    background-color: #f56565;
    background-color: rgba(245, 101, 101, var(--bg-opacity));
  }

  .md\:focus\:tw-bg-red-600:focus {
    --bg-opacity: 1;
    background-color: #e53e3e;
    background-color: rgba(229, 62, 62, var(--bg-opacity));
  }

  .md\:focus\:tw-bg-red-700:focus {
    --bg-opacity: 1;
    background-color: #c53030;
    background-color: rgba(197, 48, 48, var(--bg-opacity));
  }

  .md\:focus\:tw-bg-red-800:focus {
    --bg-opacity: 1;
    background-color: #9b2c2c;
    background-color: rgba(155, 44, 44, var(--bg-opacity));
  }

  .md\:focus\:tw-bg-red-900:focus {
    --bg-opacity: 1;
    background-color: #742a2a;
    background-color: rgba(116, 42, 42, var(--bg-opacity));
  }

  .md\:focus\:tw-bg-orange-100:focus {
    --bg-opacity: 1;
    background-color: #fffaf0;
    background-color: rgba(255, 250, 240, var(--bg-opacity));
  }

  .md\:focus\:tw-bg-orange-200:focus {
    --bg-opacity: 1;
    background-color: #feebc8;
    background-color: rgba(254, 235, 200, var(--bg-opacity));
  }

  .md\:focus\:tw-bg-orange-300:focus {
    --bg-opacity: 1;
    background-color: #fbd38d;
    background-color: rgba(251, 211, 141, var(--bg-opacity));
  }

  .md\:focus\:tw-bg-orange-400:focus {
    --bg-opacity: 1;
    background-color: #f6ad55;
    background-color: rgba(246, 173, 85, var(--bg-opacity));
  }

  .md\:focus\:tw-bg-orange-500:focus {
    --bg-opacity: 1;
    background-color: #ed8936;
    background-color: rgba(237, 137, 54, var(--bg-opacity));
  }

  .md\:focus\:tw-bg-orange-600:focus {
    --bg-opacity: 1;
    background-color: #dd6b20;
    background-color: rgba(221, 107, 32, var(--bg-opacity));
  }

  .md\:focus\:tw-bg-orange-700:focus {
    --bg-opacity: 1;
    background-color: #c05621;
    background-color: rgba(192, 86, 33, var(--bg-opacity));
  }

  .md\:focus\:tw-bg-orange-800:focus {
    --bg-opacity: 1;
    background-color: #9c4221;
    background-color: rgba(156, 66, 33, var(--bg-opacity));
  }

  .md\:focus\:tw-bg-orange-900:focus {
    --bg-opacity: 1;
    background-color: #7b341e;
    background-color: rgba(123, 52, 30, var(--bg-opacity));
  }

  .md\:focus\:tw-bg-yellow-100:focus {
    --bg-opacity: 1;
    background-color: #fffff0;
    background-color: rgba(255, 255, 240, var(--bg-opacity));
  }

  .md\:focus\:tw-bg-yellow-200:focus {
    --bg-opacity: 1;
    background-color: #fefcbf;
    background-color: rgba(254, 252, 191, var(--bg-opacity));
  }

  .md\:focus\:tw-bg-yellow-300:focus {
    --bg-opacity: 1;
    background-color: #faf089;
    background-color: rgba(250, 240, 137, var(--bg-opacity));
  }

  .md\:focus\:tw-bg-yellow-400:focus {
    --bg-opacity: 1;
    background-color: #f6e05e;
    background-color: rgba(246, 224, 94, var(--bg-opacity));
  }

  .md\:focus\:tw-bg-yellow-500:focus {
    --bg-opacity: 1;
    background-color: #ecc94b;
    background-color: rgba(236, 201, 75, var(--bg-opacity));
  }

  .md\:focus\:tw-bg-yellow-600:focus {
    --bg-opacity: 1;
    background-color: #d69e2e;
    background-color: rgba(214, 158, 46, var(--bg-opacity));
  }

  .md\:focus\:tw-bg-yellow-700:focus {
    --bg-opacity: 1;
    background-color: #b7791f;
    background-color: rgba(183, 121, 31, var(--bg-opacity));
  }

  .md\:focus\:tw-bg-yellow-800:focus {
    --bg-opacity: 1;
    background-color: #975a16;
    background-color: rgba(151, 90, 22, var(--bg-opacity));
  }

  .md\:focus\:tw-bg-yellow-900:focus {
    --bg-opacity: 1;
    background-color: #744210;
    background-color: rgba(116, 66, 16, var(--bg-opacity));
  }

  .md\:focus\:tw-bg-green-100:focus {
    --bg-opacity: 1;
    background-color: #f0fff4;
    background-color: rgba(240, 255, 244, var(--bg-opacity));
  }

  .md\:focus\:tw-bg-green-200:focus {
    --bg-opacity: 1;
    background-color: #c6f6d5;
    background-color: rgba(198, 246, 213, var(--bg-opacity));
  }

  .md\:focus\:tw-bg-green-300:focus {
    --bg-opacity: 1;
    background-color: #9ae6b4;
    background-color: rgba(154, 230, 180, var(--bg-opacity));
  }

  .md\:focus\:tw-bg-green-400:focus {
    --bg-opacity: 1;
    background-color: #68d391;
    background-color: rgba(104, 211, 145, var(--bg-opacity));
  }

  .md\:focus\:tw-bg-green-500:focus {
    --bg-opacity: 1;
    background-color: #48bb78;
    background-color: rgba(72, 187, 120, var(--bg-opacity));
  }

  .md\:focus\:tw-bg-green-600:focus {
    --bg-opacity: 1;
    background-color: #38a169;
    background-color: rgba(56, 161, 105, var(--bg-opacity));
  }

  .md\:focus\:tw-bg-green-700:focus {
    --bg-opacity: 1;
    background-color: #2f855a;
    background-color: rgba(47, 133, 90, var(--bg-opacity));
  }

  .md\:focus\:tw-bg-green-800:focus {
    --bg-opacity: 1;
    background-color: #276749;
    background-color: rgba(39, 103, 73, var(--bg-opacity));
  }

  .md\:focus\:tw-bg-green-900:focus {
    --bg-opacity: 1;
    background-color: #22543d;
    background-color: rgba(34, 84, 61, var(--bg-opacity));
  }

  .md\:focus\:tw-bg-teal-100:focus {
    --bg-opacity: 1;
    background-color: #e6fffa;
    background-color: rgba(230, 255, 250, var(--bg-opacity));
  }

  .md\:focus\:tw-bg-teal-200:focus {
    --bg-opacity: 1;
    background-color: #b2f5ea;
    background-color: rgba(178, 245, 234, var(--bg-opacity));
  }

  .md\:focus\:tw-bg-teal-300:focus {
    --bg-opacity: 1;
    background-color: #81e6d9;
    background-color: rgba(129, 230, 217, var(--bg-opacity));
  }

  .md\:focus\:tw-bg-teal-400:focus {
    --bg-opacity: 1;
    background-color: #4fd1c5;
    background-color: rgba(79, 209, 197, var(--bg-opacity));
  }

  .md\:focus\:tw-bg-teal-500:focus {
    --bg-opacity: 1;
    background-color: #38b2ac;
    background-color: rgba(56, 178, 172, var(--bg-opacity));
  }

  .md\:focus\:tw-bg-teal-600:focus {
    --bg-opacity: 1;
    background-color: #319795;
    background-color: rgba(49, 151, 149, var(--bg-opacity));
  }

  .md\:focus\:tw-bg-teal-700:focus {
    --bg-opacity: 1;
    background-color: #2c7a7b;
    background-color: rgba(44, 122, 123, var(--bg-opacity));
  }

  .md\:focus\:tw-bg-teal-800:focus {
    --bg-opacity: 1;
    background-color: #285e61;
    background-color: rgba(40, 94, 97, var(--bg-opacity));
  }

  .md\:focus\:tw-bg-teal-900:focus {
    --bg-opacity: 1;
    background-color: #234e52;
    background-color: rgba(35, 78, 82, var(--bg-opacity));
  }

  .md\:focus\:tw-bg-blue-100:focus {
    --bg-opacity: 1;
    background-color: #ebf8ff;
    background-color: rgba(235, 248, 255, var(--bg-opacity));
  }

  .md\:focus\:tw-bg-blue-200:focus {
    --bg-opacity: 1;
    background-color: #bee3f8;
    background-color: rgba(190, 227, 248, var(--bg-opacity));
  }

  .md\:focus\:tw-bg-blue-300:focus {
    --bg-opacity: 1;
    background-color: #90cdf4;
    background-color: rgba(144, 205, 244, var(--bg-opacity));
  }

  .md\:focus\:tw-bg-blue-400:focus {
    --bg-opacity: 1;
    background-color: #63b3ed;
    background-color: rgba(99, 179, 237, var(--bg-opacity));
  }

  .md\:focus\:tw-bg-blue-500:focus {
    --bg-opacity: 1;
    background-color: #4299e1;
    background-color: rgba(66, 153, 225, var(--bg-opacity));
  }

  .md\:focus\:tw-bg-blue-600:focus {
    --bg-opacity: 1;
    background-color: #3182ce;
    background-color: rgba(49, 130, 206, var(--bg-opacity));
  }

  .md\:focus\:tw-bg-blue-700:focus {
    --bg-opacity: 1;
    background-color: #2b6cb0;
    background-color: rgba(43, 108, 176, var(--bg-opacity));
  }

  .md\:focus\:tw-bg-blue-800:focus {
    --bg-opacity: 1;
    background-color: #2c5282;
    background-color: rgba(44, 82, 130, var(--bg-opacity));
  }

  .md\:focus\:tw-bg-blue-900:focus {
    --bg-opacity: 1;
    background-color: #2a4365;
    background-color: rgba(42, 67, 101, var(--bg-opacity));
  }

  .md\:focus\:tw-bg-indigo-100:focus {
    --bg-opacity: 1;
    background-color: #ebf4ff;
    background-color: rgba(235, 244, 255, var(--bg-opacity));
  }

  .md\:focus\:tw-bg-indigo-200:focus {
    --bg-opacity: 1;
    background-color: #c3dafe;
    background-color: rgba(195, 218, 254, var(--bg-opacity));
  }

  .md\:focus\:tw-bg-indigo-300:focus {
    --bg-opacity: 1;
    background-color: #a3bffa;
    background-color: rgba(163, 191, 250, var(--bg-opacity));
  }

  .md\:focus\:tw-bg-indigo-400:focus {
    --bg-opacity: 1;
    background-color: #7f9cf5;
    background-color: rgba(127, 156, 245, var(--bg-opacity));
  }

  .md\:focus\:tw-bg-indigo-500:focus {
    --bg-opacity: 1;
    background-color: #667eea;
    background-color: rgba(102, 126, 234, var(--bg-opacity));
  }

  .md\:focus\:tw-bg-indigo-600:focus {
    --bg-opacity: 1;
    background-color: #5a67d8;
    background-color: rgba(90, 103, 216, var(--bg-opacity));
  }

  .md\:focus\:tw-bg-indigo-700:focus {
    --bg-opacity: 1;
    background-color: #4c51bf;
    background-color: rgba(76, 81, 191, var(--bg-opacity));
  }

  .md\:focus\:tw-bg-indigo-800:focus {
    --bg-opacity: 1;
    background-color: #434190;
    background-color: rgba(67, 65, 144, var(--bg-opacity));
  }

  .md\:focus\:tw-bg-indigo-900:focus {
    --bg-opacity: 1;
    background-color: #3c366b;
    background-color: rgba(60, 54, 107, var(--bg-opacity));
  }

  .md\:focus\:tw-bg-purple-100:focus {
    --bg-opacity: 1;
    background-color: #faf5ff;
    background-color: rgba(250, 245, 255, var(--bg-opacity));
  }

  .md\:focus\:tw-bg-purple-200:focus {
    --bg-opacity: 1;
    background-color: #e9d8fd;
    background-color: rgba(233, 216, 253, var(--bg-opacity));
  }

  .md\:focus\:tw-bg-purple-300:focus {
    --bg-opacity: 1;
    background-color: #d6bcfa;
    background-color: rgba(214, 188, 250, var(--bg-opacity));
  }

  .md\:focus\:tw-bg-purple-400:focus {
    --bg-opacity: 1;
    background-color: #b794f4;
    background-color: rgba(183, 148, 244, var(--bg-opacity));
  }

  .md\:focus\:tw-bg-purple-500:focus {
    --bg-opacity: 1;
    background-color: #9f7aea;
    background-color: rgba(159, 122, 234, var(--bg-opacity));
  }

  .md\:focus\:tw-bg-purple-600:focus {
    --bg-opacity: 1;
    background-color: #805ad5;
    background-color: rgba(128, 90, 213, var(--bg-opacity));
  }

  .md\:focus\:tw-bg-purple-700:focus {
    --bg-opacity: 1;
    background-color: #6b46c1;
    background-color: rgba(107, 70, 193, var(--bg-opacity));
  }

  .md\:focus\:tw-bg-purple-800:focus {
    --bg-opacity: 1;
    background-color: #553c9a;
    background-color: rgba(85, 60, 154, var(--bg-opacity));
  }

  .md\:focus\:tw-bg-purple-900:focus {
    --bg-opacity: 1;
    background-color: #44337a;
    background-color: rgba(68, 51, 122, var(--bg-opacity));
  }

  .md\:focus\:tw-bg-pink-100:focus {
    --bg-opacity: 1;
    background-color: #fff5f7;
    background-color: rgba(255, 245, 247, var(--bg-opacity));
  }

  .md\:focus\:tw-bg-pink-200:focus {
    --bg-opacity: 1;
    background-color: #fed7e2;
    background-color: rgba(254, 215, 226, var(--bg-opacity));
  }

  .md\:focus\:tw-bg-pink-300:focus {
    --bg-opacity: 1;
    background-color: #fbb6ce;
    background-color: rgba(251, 182, 206, var(--bg-opacity));
  }

  .md\:focus\:tw-bg-pink-400:focus {
    --bg-opacity: 1;
    background-color: #f687b3;
    background-color: rgba(246, 135, 179, var(--bg-opacity));
  }

  .md\:focus\:tw-bg-pink-500:focus {
    --bg-opacity: 1;
    background-color: #ed64a6;
    background-color: rgba(237, 100, 166, var(--bg-opacity));
  }

  .md\:focus\:tw-bg-pink-600:focus {
    --bg-opacity: 1;
    background-color: #d53f8c;
    background-color: rgba(213, 63, 140, var(--bg-opacity));
  }

  .md\:focus\:tw-bg-pink-700:focus {
    --bg-opacity: 1;
    background-color: #b83280;
    background-color: rgba(184, 50, 128, var(--bg-opacity));
  }

  .md\:focus\:tw-bg-pink-800:focus {
    --bg-opacity: 1;
    background-color: #97266d;
    background-color: rgba(151, 38, 109, var(--bg-opacity));
  }

  .md\:focus\:tw-bg-pink-900:focus {
    --bg-opacity: 1;
    background-color: #702459;
    background-color: rgba(112, 36, 89, var(--bg-opacity));
  }

  .md\:tw-bg-none {
    background-image: none;
  }

  .md\:tw-bg-gradient-to-t {
    background-image: linear-gradient(to top, var(--gradient-color-stops));
  }

  .md\:tw-bg-gradient-to-tr {
    background-image: linear-gradient(to top right, var(--gradient-color-stops));
  }

  .md\:tw-bg-gradient-to-r {
    background-image: linear-gradient(to right, var(--gradient-color-stops));
  }

  .md\:tw-bg-gradient-to-br {
    background-image: linear-gradient(to bottom right, var(--gradient-color-stops));
  }

  .md\:tw-bg-gradient-to-b {
    background-image: linear-gradient(to bottom, var(--gradient-color-stops));
  }

  .md\:tw-bg-gradient-to-bl {
    background-image: linear-gradient(to bottom left, var(--gradient-color-stops));
  }

  .md\:tw-bg-gradient-to-l {
    background-image: linear-gradient(to left, var(--gradient-color-stops));
  }

  .md\:tw-bg-gradient-to-tl {
    background-image: linear-gradient(to top left, var(--gradient-color-stops));
  }

  .md\:tw-bg-wave-pattern {
    background-image: url('../assets/images/nawbar_waves.svg');
  }

  .md\:tw-from-transparent {
    --gradient-from-color: transparent;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(0, 0, 0, 0));
  }

  .md\:tw-from-current {
    --gradient-from-color: currentColor;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0));
  }

  .md\:tw-from-black {
    --gradient-from-color: #000;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(0, 0, 0, 0));
  }

  .md\:tw-from-white {
    --gradient-from-color: #fff;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0));
  }

  .md\:tw-from-gray-100 {
    --gradient-from-color: #f7fafc;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(247, 250, 252, 0));
  }

  .md\:tw-from-gray-200 {
    --gradient-from-color: #edf2f7;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(237, 242, 247, 0));
  }

  .md\:tw-from-gray-300 {
    --gradient-from-color: #e2e8f0;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(226, 232, 240, 0));
  }

  .md\:tw-from-gray-400 {
    --gradient-from-color: #cbd5e0;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(203, 213, 224, 0));
  }

  .md\:tw-from-gray-500 {
    --gradient-from-color: #a0aec0;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(160, 174, 192, 0));
  }

  .md\:tw-from-gray-600 {
    --gradient-from-color: #718096;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(113, 128, 150, 0));
  }

  .md\:tw-from-gray-700 {
    --gradient-from-color: #4a5568;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(74, 85, 104, 0));
  }

  .md\:tw-from-gray-800 {
    --gradient-from-color: #2d3748;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(45, 55, 72, 0));
  }

  .md\:tw-from-gray-900 {
    --gradient-from-color: #1a202c;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(26, 32, 44, 0));
  }

  .md\:tw-from-red-100 {
    --gradient-from-color: #fff5f5;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 245, 245, 0));
  }

  .md\:tw-from-red-200 {
    --gradient-from-color: #fed7d7;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(254, 215, 215, 0));
  }

  .md\:tw-from-red-300 {
    --gradient-from-color: #feb2b2;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(254, 178, 178, 0));
  }

  .md\:tw-from-red-400 {
    --gradient-from-color: #fc8181;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(252, 129, 129, 0));
  }

  .md\:tw-from-red-500 {
    --gradient-from-color: #f56565;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(245, 101, 101, 0));
  }

  .md\:tw-from-red-600 {
    --gradient-from-color: #e53e3e;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(229, 62, 62, 0));
  }

  .md\:tw-from-red-700 {
    --gradient-from-color: #c53030;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(197, 48, 48, 0));
  }

  .md\:tw-from-red-800 {
    --gradient-from-color: #9b2c2c;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(155, 44, 44, 0));
  }

  .md\:tw-from-red-900 {
    --gradient-from-color: #742a2a;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(116, 42, 42, 0));
  }

  .md\:tw-from-orange-100 {
    --gradient-from-color: #fffaf0;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 250, 240, 0));
  }

  .md\:tw-from-orange-200 {
    --gradient-from-color: #feebc8;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(254, 235, 200, 0));
  }

  .md\:tw-from-orange-300 {
    --gradient-from-color: #fbd38d;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(251, 211, 141, 0));
  }

  .md\:tw-from-orange-400 {
    --gradient-from-color: #f6ad55;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(246, 173, 85, 0));
  }

  .md\:tw-from-orange-500 {
    --gradient-from-color: #ed8936;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(237, 137, 54, 0));
  }

  .md\:tw-from-orange-600 {
    --gradient-from-color: #dd6b20;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(221, 107, 32, 0));
  }

  .md\:tw-from-orange-700 {
    --gradient-from-color: #c05621;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(192, 86, 33, 0));
  }

  .md\:tw-from-orange-800 {
    --gradient-from-color: #9c4221;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(156, 66, 33, 0));
  }

  .md\:tw-from-orange-900 {
    --gradient-from-color: #7b341e;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(123, 52, 30, 0));
  }

  .md\:tw-from-yellow-100 {
    --gradient-from-color: #fffff0;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 240, 0));
  }

  .md\:tw-from-yellow-200 {
    --gradient-from-color: #fefcbf;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(254, 252, 191, 0));
  }

  .md\:tw-from-yellow-300 {
    --gradient-from-color: #faf089;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(250, 240, 137, 0));
  }

  .md\:tw-from-yellow-400 {
    --gradient-from-color: #f6e05e;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(246, 224, 94, 0));
  }

  .md\:tw-from-yellow-500 {
    --gradient-from-color: #ecc94b;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(236, 201, 75, 0));
  }

  .md\:tw-from-yellow-600 {
    --gradient-from-color: #d69e2e;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(214, 158, 46, 0));
  }

  .md\:tw-from-yellow-700 {
    --gradient-from-color: #b7791f;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(183, 121, 31, 0));
  }

  .md\:tw-from-yellow-800 {
    --gradient-from-color: #975a16;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(151, 90, 22, 0));
  }

  .md\:tw-from-yellow-900 {
    --gradient-from-color: #744210;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(116, 66, 16, 0));
  }

  .md\:tw-from-green-100 {
    --gradient-from-color: #f0fff4;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(240, 255, 244, 0));
  }

  .md\:tw-from-green-200 {
    --gradient-from-color: #c6f6d5;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(198, 246, 213, 0));
  }

  .md\:tw-from-green-300 {
    --gradient-from-color: #9ae6b4;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(154, 230, 180, 0));
  }

  .md\:tw-from-green-400 {
    --gradient-from-color: #68d391;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(104, 211, 145, 0));
  }

  .md\:tw-from-green-500 {
    --gradient-from-color: #48bb78;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(72, 187, 120, 0));
  }

  .md\:tw-from-green-600 {
    --gradient-from-color: #38a169;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(56, 161, 105, 0));
  }

  .md\:tw-from-green-700 {
    --gradient-from-color: #2f855a;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(47, 133, 90, 0));
  }

  .md\:tw-from-green-800 {
    --gradient-from-color: #276749;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(39, 103, 73, 0));
  }

  .md\:tw-from-green-900 {
    --gradient-from-color: #22543d;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(34, 84, 61, 0));
  }

  .md\:tw-from-teal-100 {
    --gradient-from-color: #e6fffa;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(230, 255, 250, 0));
  }

  .md\:tw-from-teal-200 {
    --gradient-from-color: #b2f5ea;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(178, 245, 234, 0));
  }

  .md\:tw-from-teal-300 {
    --gradient-from-color: #81e6d9;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(129, 230, 217, 0));
  }

  .md\:tw-from-teal-400 {
    --gradient-from-color: #4fd1c5;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(79, 209, 197, 0));
  }

  .md\:tw-from-teal-500 {
    --gradient-from-color: #38b2ac;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(56, 178, 172, 0));
  }

  .md\:tw-from-teal-600 {
    --gradient-from-color: #319795;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(49, 151, 149, 0));
  }

  .md\:tw-from-teal-700 {
    --gradient-from-color: #2c7a7b;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(44, 122, 123, 0));
  }

  .md\:tw-from-teal-800 {
    --gradient-from-color: #285e61;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(40, 94, 97, 0));
  }

  .md\:tw-from-teal-900 {
    --gradient-from-color: #234e52;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(35, 78, 82, 0));
  }

  .md\:tw-from-blue-100 {
    --gradient-from-color: #ebf8ff;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(235, 248, 255, 0));
  }

  .md\:tw-from-blue-200 {
    --gradient-from-color: #bee3f8;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(190, 227, 248, 0));
  }

  .md\:tw-from-blue-300 {
    --gradient-from-color: #90cdf4;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(144, 205, 244, 0));
  }

  .md\:tw-from-blue-400 {
    --gradient-from-color: #63b3ed;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(99, 179, 237, 0));
  }

  .md\:tw-from-blue-500 {
    --gradient-from-color: #4299e1;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(66, 153, 225, 0));
  }

  .md\:tw-from-blue-600 {
    --gradient-from-color: #3182ce;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(49, 130, 206, 0));
  }

  .md\:tw-from-blue-700 {
    --gradient-from-color: #2b6cb0;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(43, 108, 176, 0));
  }

  .md\:tw-from-blue-800 {
    --gradient-from-color: #2c5282;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(44, 82, 130, 0));
  }

  .md\:tw-from-blue-900 {
    --gradient-from-color: #2a4365;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(42, 67, 101, 0));
  }

  .md\:tw-from-indigo-100 {
    --gradient-from-color: #ebf4ff;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(235, 244, 255, 0));
  }

  .md\:tw-from-indigo-200 {
    --gradient-from-color: #c3dafe;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(195, 218, 254, 0));
  }

  .md\:tw-from-indigo-300 {
    --gradient-from-color: #a3bffa;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(163, 191, 250, 0));
  }

  .md\:tw-from-indigo-400 {
    --gradient-from-color: #7f9cf5;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(127, 156, 245, 0));
  }

  .md\:tw-from-indigo-500 {
    --gradient-from-color: #667eea;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(102, 126, 234, 0));
  }

  .md\:tw-from-indigo-600 {
    --gradient-from-color: #5a67d8;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(90, 103, 216, 0));
  }

  .md\:tw-from-indigo-700 {
    --gradient-from-color: #4c51bf;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(76, 81, 191, 0));
  }

  .md\:tw-from-indigo-800 {
    --gradient-from-color: #434190;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(67, 65, 144, 0));
  }

  .md\:tw-from-indigo-900 {
    --gradient-from-color: #3c366b;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(60, 54, 107, 0));
  }

  .md\:tw-from-purple-100 {
    --gradient-from-color: #faf5ff;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(250, 245, 255, 0));
  }

  .md\:tw-from-purple-200 {
    --gradient-from-color: #e9d8fd;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(233, 216, 253, 0));
  }

  .md\:tw-from-purple-300 {
    --gradient-from-color: #d6bcfa;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(214, 188, 250, 0));
  }

  .md\:tw-from-purple-400 {
    --gradient-from-color: #b794f4;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(183, 148, 244, 0));
  }

  .md\:tw-from-purple-500 {
    --gradient-from-color: #9f7aea;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(159, 122, 234, 0));
  }

  .md\:tw-from-purple-600 {
    --gradient-from-color: #805ad5;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(128, 90, 213, 0));
  }

  .md\:tw-from-purple-700 {
    --gradient-from-color: #6b46c1;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(107, 70, 193, 0));
  }

  .md\:tw-from-purple-800 {
    --gradient-from-color: #553c9a;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(85, 60, 154, 0));
  }

  .md\:tw-from-purple-900 {
    --gradient-from-color: #44337a;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(68, 51, 122, 0));
  }

  .md\:tw-from-pink-100 {
    --gradient-from-color: #fff5f7;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 245, 247, 0));
  }

  .md\:tw-from-pink-200 {
    --gradient-from-color: #fed7e2;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(254, 215, 226, 0));
  }

  .md\:tw-from-pink-300 {
    --gradient-from-color: #fbb6ce;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(251, 182, 206, 0));
  }

  .md\:tw-from-pink-400 {
    --gradient-from-color: #f687b3;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(246, 135, 179, 0));
  }

  .md\:tw-from-pink-500 {
    --gradient-from-color: #ed64a6;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(237, 100, 166, 0));
  }

  .md\:tw-from-pink-600 {
    --gradient-from-color: #d53f8c;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(213, 63, 140, 0));
  }

  .md\:tw-from-pink-700 {
    --gradient-from-color: #b83280;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(184, 50, 128, 0));
  }

  .md\:tw-from-pink-800 {
    --gradient-from-color: #97266d;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(151, 38, 109, 0));
  }

  .md\:tw-from-pink-900 {
    --gradient-from-color: #702459;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(112, 36, 89, 0));
  }

  .md\:tw-via-transparent {
    --gradient-via-color: transparent;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(0, 0, 0, 0));
  }

  .md\:tw-via-current {
    --gradient-via-color: currentColor;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0));
  }

  .md\:tw-via-black {
    --gradient-via-color: #000;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(0, 0, 0, 0));
  }

  .md\:tw-via-white {
    --gradient-via-color: #fff;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0));
  }

  .md\:tw-via-gray-100 {
    --gradient-via-color: #f7fafc;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(247, 250, 252, 0));
  }

  .md\:tw-via-gray-200 {
    --gradient-via-color: #edf2f7;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(237, 242, 247, 0));
  }

  .md\:tw-via-gray-300 {
    --gradient-via-color: #e2e8f0;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(226, 232, 240, 0));
  }

  .md\:tw-via-gray-400 {
    --gradient-via-color: #cbd5e0;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(203, 213, 224, 0));
  }

  .md\:tw-via-gray-500 {
    --gradient-via-color: #a0aec0;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(160, 174, 192, 0));
  }

  .md\:tw-via-gray-600 {
    --gradient-via-color: #718096;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(113, 128, 150, 0));
  }

  .md\:tw-via-gray-700 {
    --gradient-via-color: #4a5568;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(74, 85, 104, 0));
  }

  .md\:tw-via-gray-800 {
    --gradient-via-color: #2d3748;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(45, 55, 72, 0));
  }

  .md\:tw-via-gray-900 {
    --gradient-via-color: #1a202c;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(26, 32, 44, 0));
  }

  .md\:tw-via-red-100 {
    --gradient-via-color: #fff5f5;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 245, 245, 0));
  }

  .md\:tw-via-red-200 {
    --gradient-via-color: #fed7d7;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(254, 215, 215, 0));
  }

  .md\:tw-via-red-300 {
    --gradient-via-color: #feb2b2;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(254, 178, 178, 0));
  }

  .md\:tw-via-red-400 {
    --gradient-via-color: #fc8181;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(252, 129, 129, 0));
  }

  .md\:tw-via-red-500 {
    --gradient-via-color: #f56565;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(245, 101, 101, 0));
  }

  .md\:tw-via-red-600 {
    --gradient-via-color: #e53e3e;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(229, 62, 62, 0));
  }

  .md\:tw-via-red-700 {
    --gradient-via-color: #c53030;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(197, 48, 48, 0));
  }

  .md\:tw-via-red-800 {
    --gradient-via-color: #9b2c2c;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(155, 44, 44, 0));
  }

  .md\:tw-via-red-900 {
    --gradient-via-color: #742a2a;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(116, 42, 42, 0));
  }

  .md\:tw-via-orange-100 {
    --gradient-via-color: #fffaf0;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 250, 240, 0));
  }

  .md\:tw-via-orange-200 {
    --gradient-via-color: #feebc8;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(254, 235, 200, 0));
  }

  .md\:tw-via-orange-300 {
    --gradient-via-color: #fbd38d;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(251, 211, 141, 0));
  }

  .md\:tw-via-orange-400 {
    --gradient-via-color: #f6ad55;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(246, 173, 85, 0));
  }

  .md\:tw-via-orange-500 {
    --gradient-via-color: #ed8936;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(237, 137, 54, 0));
  }

  .md\:tw-via-orange-600 {
    --gradient-via-color: #dd6b20;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(221, 107, 32, 0));
  }

  .md\:tw-via-orange-700 {
    --gradient-via-color: #c05621;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(192, 86, 33, 0));
  }

  .md\:tw-via-orange-800 {
    --gradient-via-color: #9c4221;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(156, 66, 33, 0));
  }

  .md\:tw-via-orange-900 {
    --gradient-via-color: #7b341e;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(123, 52, 30, 0));
  }

  .md\:tw-via-yellow-100 {
    --gradient-via-color: #fffff0;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 240, 0));
  }

  .md\:tw-via-yellow-200 {
    --gradient-via-color: #fefcbf;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(254, 252, 191, 0));
  }

  .md\:tw-via-yellow-300 {
    --gradient-via-color: #faf089;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(250, 240, 137, 0));
  }

  .md\:tw-via-yellow-400 {
    --gradient-via-color: #f6e05e;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(246, 224, 94, 0));
  }

  .md\:tw-via-yellow-500 {
    --gradient-via-color: #ecc94b;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(236, 201, 75, 0));
  }

  .md\:tw-via-yellow-600 {
    --gradient-via-color: #d69e2e;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(214, 158, 46, 0));
  }

  .md\:tw-via-yellow-700 {
    --gradient-via-color: #b7791f;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(183, 121, 31, 0));
  }

  .md\:tw-via-yellow-800 {
    --gradient-via-color: #975a16;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(151, 90, 22, 0));
  }

  .md\:tw-via-yellow-900 {
    --gradient-via-color: #744210;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(116, 66, 16, 0));
  }

  .md\:tw-via-green-100 {
    --gradient-via-color: #f0fff4;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(240, 255, 244, 0));
  }

  .md\:tw-via-green-200 {
    --gradient-via-color: #c6f6d5;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(198, 246, 213, 0));
  }

  .md\:tw-via-green-300 {
    --gradient-via-color: #9ae6b4;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(154, 230, 180, 0));
  }

  .md\:tw-via-green-400 {
    --gradient-via-color: #68d391;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(104, 211, 145, 0));
  }

  .md\:tw-via-green-500 {
    --gradient-via-color: #48bb78;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(72, 187, 120, 0));
  }

  .md\:tw-via-green-600 {
    --gradient-via-color: #38a169;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(56, 161, 105, 0));
  }

  .md\:tw-via-green-700 {
    --gradient-via-color: #2f855a;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(47, 133, 90, 0));
  }

  .md\:tw-via-green-800 {
    --gradient-via-color: #276749;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(39, 103, 73, 0));
  }

  .md\:tw-via-green-900 {
    --gradient-via-color: #22543d;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(34, 84, 61, 0));
  }

  .md\:tw-via-teal-100 {
    --gradient-via-color: #e6fffa;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(230, 255, 250, 0));
  }

  .md\:tw-via-teal-200 {
    --gradient-via-color: #b2f5ea;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(178, 245, 234, 0));
  }

  .md\:tw-via-teal-300 {
    --gradient-via-color: #81e6d9;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(129, 230, 217, 0));
  }

  .md\:tw-via-teal-400 {
    --gradient-via-color: #4fd1c5;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(79, 209, 197, 0));
  }

  .md\:tw-via-teal-500 {
    --gradient-via-color: #38b2ac;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(56, 178, 172, 0));
  }

  .md\:tw-via-teal-600 {
    --gradient-via-color: #319795;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(49, 151, 149, 0));
  }

  .md\:tw-via-teal-700 {
    --gradient-via-color: #2c7a7b;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(44, 122, 123, 0));
  }

  .md\:tw-via-teal-800 {
    --gradient-via-color: #285e61;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(40, 94, 97, 0));
  }

  .md\:tw-via-teal-900 {
    --gradient-via-color: #234e52;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(35, 78, 82, 0));
  }

  .md\:tw-via-blue-100 {
    --gradient-via-color: #ebf8ff;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(235, 248, 255, 0));
  }

  .md\:tw-via-blue-200 {
    --gradient-via-color: #bee3f8;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(190, 227, 248, 0));
  }

  .md\:tw-via-blue-300 {
    --gradient-via-color: #90cdf4;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(144, 205, 244, 0));
  }

  .md\:tw-via-blue-400 {
    --gradient-via-color: #63b3ed;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(99, 179, 237, 0));
  }

  .md\:tw-via-blue-500 {
    --gradient-via-color: #4299e1;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(66, 153, 225, 0));
  }

  .md\:tw-via-blue-600 {
    --gradient-via-color: #3182ce;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(49, 130, 206, 0));
  }

  .md\:tw-via-blue-700 {
    --gradient-via-color: #2b6cb0;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(43, 108, 176, 0));
  }

  .md\:tw-via-blue-800 {
    --gradient-via-color: #2c5282;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(44, 82, 130, 0));
  }

  .md\:tw-via-blue-900 {
    --gradient-via-color: #2a4365;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(42, 67, 101, 0));
  }

  .md\:tw-via-indigo-100 {
    --gradient-via-color: #ebf4ff;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(235, 244, 255, 0));
  }

  .md\:tw-via-indigo-200 {
    --gradient-via-color: #c3dafe;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(195, 218, 254, 0));
  }

  .md\:tw-via-indigo-300 {
    --gradient-via-color: #a3bffa;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(163, 191, 250, 0));
  }

  .md\:tw-via-indigo-400 {
    --gradient-via-color: #7f9cf5;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(127, 156, 245, 0));
  }

  .md\:tw-via-indigo-500 {
    --gradient-via-color: #667eea;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(102, 126, 234, 0));
  }

  .md\:tw-via-indigo-600 {
    --gradient-via-color: #5a67d8;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(90, 103, 216, 0));
  }

  .md\:tw-via-indigo-700 {
    --gradient-via-color: #4c51bf;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(76, 81, 191, 0));
  }

  .md\:tw-via-indigo-800 {
    --gradient-via-color: #434190;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(67, 65, 144, 0));
  }

  .md\:tw-via-indigo-900 {
    --gradient-via-color: #3c366b;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(60, 54, 107, 0));
  }

  .md\:tw-via-purple-100 {
    --gradient-via-color: #faf5ff;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(250, 245, 255, 0));
  }

  .md\:tw-via-purple-200 {
    --gradient-via-color: #e9d8fd;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(233, 216, 253, 0));
  }

  .md\:tw-via-purple-300 {
    --gradient-via-color: #d6bcfa;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(214, 188, 250, 0));
  }

  .md\:tw-via-purple-400 {
    --gradient-via-color: #b794f4;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(183, 148, 244, 0));
  }

  .md\:tw-via-purple-500 {
    --gradient-via-color: #9f7aea;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(159, 122, 234, 0));
  }

  .md\:tw-via-purple-600 {
    --gradient-via-color: #805ad5;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(128, 90, 213, 0));
  }

  .md\:tw-via-purple-700 {
    --gradient-via-color: #6b46c1;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(107, 70, 193, 0));
  }

  .md\:tw-via-purple-800 {
    --gradient-via-color: #553c9a;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(85, 60, 154, 0));
  }

  .md\:tw-via-purple-900 {
    --gradient-via-color: #44337a;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(68, 51, 122, 0));
  }

  .md\:tw-via-pink-100 {
    --gradient-via-color: #fff5f7;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 245, 247, 0));
  }

  .md\:tw-via-pink-200 {
    --gradient-via-color: #fed7e2;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(254, 215, 226, 0));
  }

  .md\:tw-via-pink-300 {
    --gradient-via-color: #fbb6ce;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(251, 182, 206, 0));
  }

  .md\:tw-via-pink-400 {
    --gradient-via-color: #f687b3;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(246, 135, 179, 0));
  }

  .md\:tw-via-pink-500 {
    --gradient-via-color: #ed64a6;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(237, 100, 166, 0));
  }

  .md\:tw-via-pink-600 {
    --gradient-via-color: #d53f8c;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(213, 63, 140, 0));
  }

  .md\:tw-via-pink-700 {
    --gradient-via-color: #b83280;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(184, 50, 128, 0));
  }

  .md\:tw-via-pink-800 {
    --gradient-via-color: #97266d;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(151, 38, 109, 0));
  }

  .md\:tw-via-pink-900 {
    --gradient-via-color: #702459;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(112, 36, 89, 0));
  }

  .md\:tw-to-transparent {
    --gradient-to-color: transparent;
  }

  .md\:tw-to-current {
    --gradient-to-color: currentColor;
  }

  .md\:tw-to-black {
    --gradient-to-color: #000;
  }

  .md\:tw-to-white {
    --gradient-to-color: #fff;
  }

  .md\:tw-to-gray-100 {
    --gradient-to-color: #f7fafc;
  }

  .md\:tw-to-gray-200 {
    --gradient-to-color: #edf2f7;
  }

  .md\:tw-to-gray-300 {
    --gradient-to-color: #e2e8f0;
  }

  .md\:tw-to-gray-400 {
    --gradient-to-color: #cbd5e0;
  }

  .md\:tw-to-gray-500 {
    --gradient-to-color: #a0aec0;
  }

  .md\:tw-to-gray-600 {
    --gradient-to-color: #718096;
  }

  .md\:tw-to-gray-700 {
    --gradient-to-color: #4a5568;
  }

  .md\:tw-to-gray-800 {
    --gradient-to-color: #2d3748;
  }

  .md\:tw-to-gray-900 {
    --gradient-to-color: #1a202c;
  }

  .md\:tw-to-red-100 {
    --gradient-to-color: #fff5f5;
  }

  .md\:tw-to-red-200 {
    --gradient-to-color: #fed7d7;
  }

  .md\:tw-to-red-300 {
    --gradient-to-color: #feb2b2;
  }

  .md\:tw-to-red-400 {
    --gradient-to-color: #fc8181;
  }

  .md\:tw-to-red-500 {
    --gradient-to-color: #f56565;
  }

  .md\:tw-to-red-600 {
    --gradient-to-color: #e53e3e;
  }

  .md\:tw-to-red-700 {
    --gradient-to-color: #c53030;
  }

  .md\:tw-to-red-800 {
    --gradient-to-color: #9b2c2c;
  }

  .md\:tw-to-red-900 {
    --gradient-to-color: #742a2a;
  }

  .md\:tw-to-orange-100 {
    --gradient-to-color: #fffaf0;
  }

  .md\:tw-to-orange-200 {
    --gradient-to-color: #feebc8;
  }

  .md\:tw-to-orange-300 {
    --gradient-to-color: #fbd38d;
  }

  .md\:tw-to-orange-400 {
    --gradient-to-color: #f6ad55;
  }

  .md\:tw-to-orange-500 {
    --gradient-to-color: #ed8936;
  }

  .md\:tw-to-orange-600 {
    --gradient-to-color: #dd6b20;
  }

  .md\:tw-to-orange-700 {
    --gradient-to-color: #c05621;
  }

  .md\:tw-to-orange-800 {
    --gradient-to-color: #9c4221;
  }

  .md\:tw-to-orange-900 {
    --gradient-to-color: #7b341e;
  }

  .md\:tw-to-yellow-100 {
    --gradient-to-color: #fffff0;
  }

  .md\:tw-to-yellow-200 {
    --gradient-to-color: #fefcbf;
  }

  .md\:tw-to-yellow-300 {
    --gradient-to-color: #faf089;
  }

  .md\:tw-to-yellow-400 {
    --gradient-to-color: #f6e05e;
  }

  .md\:tw-to-yellow-500 {
    --gradient-to-color: #ecc94b;
  }

  .md\:tw-to-yellow-600 {
    --gradient-to-color: #d69e2e;
  }

  .md\:tw-to-yellow-700 {
    --gradient-to-color: #b7791f;
  }

  .md\:tw-to-yellow-800 {
    --gradient-to-color: #975a16;
  }

  .md\:tw-to-yellow-900 {
    --gradient-to-color: #744210;
  }

  .md\:tw-to-green-100 {
    --gradient-to-color: #f0fff4;
  }

  .md\:tw-to-green-200 {
    --gradient-to-color: #c6f6d5;
  }

  .md\:tw-to-green-300 {
    --gradient-to-color: #9ae6b4;
  }

  .md\:tw-to-green-400 {
    --gradient-to-color: #68d391;
  }

  .md\:tw-to-green-500 {
    --gradient-to-color: #48bb78;
  }

  .md\:tw-to-green-600 {
    --gradient-to-color: #38a169;
  }

  .md\:tw-to-green-700 {
    --gradient-to-color: #2f855a;
  }

  .md\:tw-to-green-800 {
    --gradient-to-color: #276749;
  }

  .md\:tw-to-green-900 {
    --gradient-to-color: #22543d;
  }

  .md\:tw-to-teal-100 {
    --gradient-to-color: #e6fffa;
  }

  .md\:tw-to-teal-200 {
    --gradient-to-color: #b2f5ea;
  }

  .md\:tw-to-teal-300 {
    --gradient-to-color: #81e6d9;
  }

  .md\:tw-to-teal-400 {
    --gradient-to-color: #4fd1c5;
  }

  .md\:tw-to-teal-500 {
    --gradient-to-color: #38b2ac;
  }

  .md\:tw-to-teal-600 {
    --gradient-to-color: #319795;
  }

  .md\:tw-to-teal-700 {
    --gradient-to-color: #2c7a7b;
  }

  .md\:tw-to-teal-800 {
    --gradient-to-color: #285e61;
  }

  .md\:tw-to-teal-900 {
    --gradient-to-color: #234e52;
  }

  .md\:tw-to-blue-100 {
    --gradient-to-color: #ebf8ff;
  }

  .md\:tw-to-blue-200 {
    --gradient-to-color: #bee3f8;
  }

  .md\:tw-to-blue-300 {
    --gradient-to-color: #90cdf4;
  }

  .md\:tw-to-blue-400 {
    --gradient-to-color: #63b3ed;
  }

  .md\:tw-to-blue-500 {
    --gradient-to-color: #4299e1;
  }

  .md\:tw-to-blue-600 {
    --gradient-to-color: #3182ce;
  }

  .md\:tw-to-blue-700 {
    --gradient-to-color: #2b6cb0;
  }

  .md\:tw-to-blue-800 {
    --gradient-to-color: #2c5282;
  }

  .md\:tw-to-blue-900 {
    --gradient-to-color: #2a4365;
  }

  .md\:tw-to-indigo-100 {
    --gradient-to-color: #ebf4ff;
  }

  .md\:tw-to-indigo-200 {
    --gradient-to-color: #c3dafe;
  }

  .md\:tw-to-indigo-300 {
    --gradient-to-color: #a3bffa;
  }

  .md\:tw-to-indigo-400 {
    --gradient-to-color: #7f9cf5;
  }

  .md\:tw-to-indigo-500 {
    --gradient-to-color: #667eea;
  }

  .md\:tw-to-indigo-600 {
    --gradient-to-color: #5a67d8;
  }

  .md\:tw-to-indigo-700 {
    --gradient-to-color: #4c51bf;
  }

  .md\:tw-to-indigo-800 {
    --gradient-to-color: #434190;
  }

  .md\:tw-to-indigo-900 {
    --gradient-to-color: #3c366b;
  }

  .md\:tw-to-purple-100 {
    --gradient-to-color: #faf5ff;
  }

  .md\:tw-to-purple-200 {
    --gradient-to-color: #e9d8fd;
  }

  .md\:tw-to-purple-300 {
    --gradient-to-color: #d6bcfa;
  }

  .md\:tw-to-purple-400 {
    --gradient-to-color: #b794f4;
  }

  .md\:tw-to-purple-500 {
    --gradient-to-color: #9f7aea;
  }

  .md\:tw-to-purple-600 {
    --gradient-to-color: #805ad5;
  }

  .md\:tw-to-purple-700 {
    --gradient-to-color: #6b46c1;
  }

  .md\:tw-to-purple-800 {
    --gradient-to-color: #553c9a;
  }

  .md\:tw-to-purple-900 {
    --gradient-to-color: #44337a;
  }

  .md\:tw-to-pink-100 {
    --gradient-to-color: #fff5f7;
  }

  .md\:tw-to-pink-200 {
    --gradient-to-color: #fed7e2;
  }

  .md\:tw-to-pink-300 {
    --gradient-to-color: #fbb6ce;
  }

  .md\:tw-to-pink-400 {
    --gradient-to-color: #f687b3;
  }

  .md\:tw-to-pink-500 {
    --gradient-to-color: #ed64a6;
  }

  .md\:tw-to-pink-600 {
    --gradient-to-color: #d53f8c;
  }

  .md\:tw-to-pink-700 {
    --gradient-to-color: #b83280;
  }

  .md\:tw-to-pink-800 {
    --gradient-to-color: #97266d;
  }

  .md\:tw-to-pink-900 {
    --gradient-to-color: #702459;
  }

  .md\:hover\:tw-from-transparent:hover {
    --gradient-from-color: transparent;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(0, 0, 0, 0));
  }

  .md\:hover\:tw-from-current:hover {
    --gradient-from-color: currentColor;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0));
  }

  .md\:hover\:tw-from-black:hover {
    --gradient-from-color: #000;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(0, 0, 0, 0));
  }

  .md\:hover\:tw-from-white:hover {
    --gradient-from-color: #fff;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0));
  }

  .md\:hover\:tw-from-gray-100:hover {
    --gradient-from-color: #f7fafc;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(247, 250, 252, 0));
  }

  .md\:hover\:tw-from-gray-200:hover {
    --gradient-from-color: #edf2f7;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(237, 242, 247, 0));
  }

  .md\:hover\:tw-from-gray-300:hover {
    --gradient-from-color: #e2e8f0;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(226, 232, 240, 0));
  }

  .md\:hover\:tw-from-gray-400:hover {
    --gradient-from-color: #cbd5e0;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(203, 213, 224, 0));
  }

  .md\:hover\:tw-from-gray-500:hover {
    --gradient-from-color: #a0aec0;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(160, 174, 192, 0));
  }

  .md\:hover\:tw-from-gray-600:hover {
    --gradient-from-color: #718096;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(113, 128, 150, 0));
  }

  .md\:hover\:tw-from-gray-700:hover {
    --gradient-from-color: #4a5568;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(74, 85, 104, 0));
  }

  .md\:hover\:tw-from-gray-800:hover {
    --gradient-from-color: #2d3748;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(45, 55, 72, 0));
  }

  .md\:hover\:tw-from-gray-900:hover {
    --gradient-from-color: #1a202c;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(26, 32, 44, 0));
  }

  .md\:hover\:tw-from-red-100:hover {
    --gradient-from-color: #fff5f5;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 245, 245, 0));
  }

  .md\:hover\:tw-from-red-200:hover {
    --gradient-from-color: #fed7d7;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(254, 215, 215, 0));
  }

  .md\:hover\:tw-from-red-300:hover {
    --gradient-from-color: #feb2b2;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(254, 178, 178, 0));
  }

  .md\:hover\:tw-from-red-400:hover {
    --gradient-from-color: #fc8181;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(252, 129, 129, 0));
  }

  .md\:hover\:tw-from-red-500:hover {
    --gradient-from-color: #f56565;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(245, 101, 101, 0));
  }

  .md\:hover\:tw-from-red-600:hover {
    --gradient-from-color: #e53e3e;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(229, 62, 62, 0));
  }

  .md\:hover\:tw-from-red-700:hover {
    --gradient-from-color: #c53030;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(197, 48, 48, 0));
  }

  .md\:hover\:tw-from-red-800:hover {
    --gradient-from-color: #9b2c2c;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(155, 44, 44, 0));
  }

  .md\:hover\:tw-from-red-900:hover {
    --gradient-from-color: #742a2a;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(116, 42, 42, 0));
  }

  .md\:hover\:tw-from-orange-100:hover {
    --gradient-from-color: #fffaf0;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 250, 240, 0));
  }

  .md\:hover\:tw-from-orange-200:hover {
    --gradient-from-color: #feebc8;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(254, 235, 200, 0));
  }

  .md\:hover\:tw-from-orange-300:hover {
    --gradient-from-color: #fbd38d;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(251, 211, 141, 0));
  }

  .md\:hover\:tw-from-orange-400:hover {
    --gradient-from-color: #f6ad55;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(246, 173, 85, 0));
  }

  .md\:hover\:tw-from-orange-500:hover {
    --gradient-from-color: #ed8936;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(237, 137, 54, 0));
  }

  .md\:hover\:tw-from-orange-600:hover {
    --gradient-from-color: #dd6b20;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(221, 107, 32, 0));
  }

  .md\:hover\:tw-from-orange-700:hover {
    --gradient-from-color: #c05621;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(192, 86, 33, 0));
  }

  .md\:hover\:tw-from-orange-800:hover {
    --gradient-from-color: #9c4221;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(156, 66, 33, 0));
  }

  .md\:hover\:tw-from-orange-900:hover {
    --gradient-from-color: #7b341e;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(123, 52, 30, 0));
  }

  .md\:hover\:tw-from-yellow-100:hover {
    --gradient-from-color: #fffff0;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 240, 0));
  }

  .md\:hover\:tw-from-yellow-200:hover {
    --gradient-from-color: #fefcbf;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(254, 252, 191, 0));
  }

  .md\:hover\:tw-from-yellow-300:hover {
    --gradient-from-color: #faf089;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(250, 240, 137, 0));
  }

  .md\:hover\:tw-from-yellow-400:hover {
    --gradient-from-color: #f6e05e;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(246, 224, 94, 0));
  }

  .md\:hover\:tw-from-yellow-500:hover {
    --gradient-from-color: #ecc94b;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(236, 201, 75, 0));
  }

  .md\:hover\:tw-from-yellow-600:hover {
    --gradient-from-color: #d69e2e;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(214, 158, 46, 0));
  }

  .md\:hover\:tw-from-yellow-700:hover {
    --gradient-from-color: #b7791f;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(183, 121, 31, 0));
  }

  .md\:hover\:tw-from-yellow-800:hover {
    --gradient-from-color: #975a16;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(151, 90, 22, 0));
  }

  .md\:hover\:tw-from-yellow-900:hover {
    --gradient-from-color: #744210;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(116, 66, 16, 0));
  }

  .md\:hover\:tw-from-green-100:hover {
    --gradient-from-color: #f0fff4;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(240, 255, 244, 0));
  }

  .md\:hover\:tw-from-green-200:hover {
    --gradient-from-color: #c6f6d5;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(198, 246, 213, 0));
  }

  .md\:hover\:tw-from-green-300:hover {
    --gradient-from-color: #9ae6b4;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(154, 230, 180, 0));
  }

  .md\:hover\:tw-from-green-400:hover {
    --gradient-from-color: #68d391;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(104, 211, 145, 0));
  }

  .md\:hover\:tw-from-green-500:hover {
    --gradient-from-color: #48bb78;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(72, 187, 120, 0));
  }

  .md\:hover\:tw-from-green-600:hover {
    --gradient-from-color: #38a169;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(56, 161, 105, 0));
  }

  .md\:hover\:tw-from-green-700:hover {
    --gradient-from-color: #2f855a;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(47, 133, 90, 0));
  }

  .md\:hover\:tw-from-green-800:hover {
    --gradient-from-color: #276749;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(39, 103, 73, 0));
  }

  .md\:hover\:tw-from-green-900:hover {
    --gradient-from-color: #22543d;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(34, 84, 61, 0));
  }

  .md\:hover\:tw-from-teal-100:hover {
    --gradient-from-color: #e6fffa;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(230, 255, 250, 0));
  }

  .md\:hover\:tw-from-teal-200:hover {
    --gradient-from-color: #b2f5ea;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(178, 245, 234, 0));
  }

  .md\:hover\:tw-from-teal-300:hover {
    --gradient-from-color: #81e6d9;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(129, 230, 217, 0));
  }

  .md\:hover\:tw-from-teal-400:hover {
    --gradient-from-color: #4fd1c5;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(79, 209, 197, 0));
  }

  .md\:hover\:tw-from-teal-500:hover {
    --gradient-from-color: #38b2ac;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(56, 178, 172, 0));
  }

  .md\:hover\:tw-from-teal-600:hover {
    --gradient-from-color: #319795;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(49, 151, 149, 0));
  }

  .md\:hover\:tw-from-teal-700:hover {
    --gradient-from-color: #2c7a7b;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(44, 122, 123, 0));
  }

  .md\:hover\:tw-from-teal-800:hover {
    --gradient-from-color: #285e61;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(40, 94, 97, 0));
  }

  .md\:hover\:tw-from-teal-900:hover {
    --gradient-from-color: #234e52;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(35, 78, 82, 0));
  }

  .md\:hover\:tw-from-blue-100:hover {
    --gradient-from-color: #ebf8ff;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(235, 248, 255, 0));
  }

  .md\:hover\:tw-from-blue-200:hover {
    --gradient-from-color: #bee3f8;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(190, 227, 248, 0));
  }

  .md\:hover\:tw-from-blue-300:hover {
    --gradient-from-color: #90cdf4;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(144, 205, 244, 0));
  }

  .md\:hover\:tw-from-blue-400:hover {
    --gradient-from-color: #63b3ed;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(99, 179, 237, 0));
  }

  .md\:hover\:tw-from-blue-500:hover {
    --gradient-from-color: #4299e1;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(66, 153, 225, 0));
  }

  .md\:hover\:tw-from-blue-600:hover {
    --gradient-from-color: #3182ce;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(49, 130, 206, 0));
  }

  .md\:hover\:tw-from-blue-700:hover {
    --gradient-from-color: #2b6cb0;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(43, 108, 176, 0));
  }

  .md\:hover\:tw-from-blue-800:hover {
    --gradient-from-color: #2c5282;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(44, 82, 130, 0));
  }

  .md\:hover\:tw-from-blue-900:hover {
    --gradient-from-color: #2a4365;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(42, 67, 101, 0));
  }

  .md\:hover\:tw-from-indigo-100:hover {
    --gradient-from-color: #ebf4ff;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(235, 244, 255, 0));
  }

  .md\:hover\:tw-from-indigo-200:hover {
    --gradient-from-color: #c3dafe;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(195, 218, 254, 0));
  }

  .md\:hover\:tw-from-indigo-300:hover {
    --gradient-from-color: #a3bffa;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(163, 191, 250, 0));
  }

  .md\:hover\:tw-from-indigo-400:hover {
    --gradient-from-color: #7f9cf5;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(127, 156, 245, 0));
  }

  .md\:hover\:tw-from-indigo-500:hover {
    --gradient-from-color: #667eea;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(102, 126, 234, 0));
  }

  .md\:hover\:tw-from-indigo-600:hover {
    --gradient-from-color: #5a67d8;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(90, 103, 216, 0));
  }

  .md\:hover\:tw-from-indigo-700:hover {
    --gradient-from-color: #4c51bf;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(76, 81, 191, 0));
  }

  .md\:hover\:tw-from-indigo-800:hover {
    --gradient-from-color: #434190;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(67, 65, 144, 0));
  }

  .md\:hover\:tw-from-indigo-900:hover {
    --gradient-from-color: #3c366b;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(60, 54, 107, 0));
  }

  .md\:hover\:tw-from-purple-100:hover {
    --gradient-from-color: #faf5ff;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(250, 245, 255, 0));
  }

  .md\:hover\:tw-from-purple-200:hover {
    --gradient-from-color: #e9d8fd;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(233, 216, 253, 0));
  }

  .md\:hover\:tw-from-purple-300:hover {
    --gradient-from-color: #d6bcfa;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(214, 188, 250, 0));
  }

  .md\:hover\:tw-from-purple-400:hover {
    --gradient-from-color: #b794f4;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(183, 148, 244, 0));
  }

  .md\:hover\:tw-from-purple-500:hover {
    --gradient-from-color: #9f7aea;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(159, 122, 234, 0));
  }

  .md\:hover\:tw-from-purple-600:hover {
    --gradient-from-color: #805ad5;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(128, 90, 213, 0));
  }

  .md\:hover\:tw-from-purple-700:hover {
    --gradient-from-color: #6b46c1;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(107, 70, 193, 0));
  }

  .md\:hover\:tw-from-purple-800:hover {
    --gradient-from-color: #553c9a;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(85, 60, 154, 0));
  }

  .md\:hover\:tw-from-purple-900:hover {
    --gradient-from-color: #44337a;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(68, 51, 122, 0));
  }

  .md\:hover\:tw-from-pink-100:hover {
    --gradient-from-color: #fff5f7;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 245, 247, 0));
  }

  .md\:hover\:tw-from-pink-200:hover {
    --gradient-from-color: #fed7e2;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(254, 215, 226, 0));
  }

  .md\:hover\:tw-from-pink-300:hover {
    --gradient-from-color: #fbb6ce;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(251, 182, 206, 0));
  }

  .md\:hover\:tw-from-pink-400:hover {
    --gradient-from-color: #f687b3;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(246, 135, 179, 0));
  }

  .md\:hover\:tw-from-pink-500:hover {
    --gradient-from-color: #ed64a6;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(237, 100, 166, 0));
  }

  .md\:hover\:tw-from-pink-600:hover {
    --gradient-from-color: #d53f8c;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(213, 63, 140, 0));
  }

  .md\:hover\:tw-from-pink-700:hover {
    --gradient-from-color: #b83280;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(184, 50, 128, 0));
  }

  .md\:hover\:tw-from-pink-800:hover {
    --gradient-from-color: #97266d;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(151, 38, 109, 0));
  }

  .md\:hover\:tw-from-pink-900:hover {
    --gradient-from-color: #702459;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(112, 36, 89, 0));
  }

  .md\:hover\:tw-via-transparent:hover {
    --gradient-via-color: transparent;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(0, 0, 0, 0));
  }

  .md\:hover\:tw-via-current:hover {
    --gradient-via-color: currentColor;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0));
  }

  .md\:hover\:tw-via-black:hover {
    --gradient-via-color: #000;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(0, 0, 0, 0));
  }

  .md\:hover\:tw-via-white:hover {
    --gradient-via-color: #fff;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0));
  }

  .md\:hover\:tw-via-gray-100:hover {
    --gradient-via-color: #f7fafc;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(247, 250, 252, 0));
  }

  .md\:hover\:tw-via-gray-200:hover {
    --gradient-via-color: #edf2f7;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(237, 242, 247, 0));
  }

  .md\:hover\:tw-via-gray-300:hover {
    --gradient-via-color: #e2e8f0;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(226, 232, 240, 0));
  }

  .md\:hover\:tw-via-gray-400:hover {
    --gradient-via-color: #cbd5e0;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(203, 213, 224, 0));
  }

  .md\:hover\:tw-via-gray-500:hover {
    --gradient-via-color: #a0aec0;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(160, 174, 192, 0));
  }

  .md\:hover\:tw-via-gray-600:hover {
    --gradient-via-color: #718096;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(113, 128, 150, 0));
  }

  .md\:hover\:tw-via-gray-700:hover {
    --gradient-via-color: #4a5568;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(74, 85, 104, 0));
  }

  .md\:hover\:tw-via-gray-800:hover {
    --gradient-via-color: #2d3748;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(45, 55, 72, 0));
  }

  .md\:hover\:tw-via-gray-900:hover {
    --gradient-via-color: #1a202c;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(26, 32, 44, 0));
  }

  .md\:hover\:tw-via-red-100:hover {
    --gradient-via-color: #fff5f5;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 245, 245, 0));
  }

  .md\:hover\:tw-via-red-200:hover {
    --gradient-via-color: #fed7d7;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(254, 215, 215, 0));
  }

  .md\:hover\:tw-via-red-300:hover {
    --gradient-via-color: #feb2b2;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(254, 178, 178, 0));
  }

  .md\:hover\:tw-via-red-400:hover {
    --gradient-via-color: #fc8181;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(252, 129, 129, 0));
  }

  .md\:hover\:tw-via-red-500:hover {
    --gradient-via-color: #f56565;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(245, 101, 101, 0));
  }

  .md\:hover\:tw-via-red-600:hover {
    --gradient-via-color: #e53e3e;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(229, 62, 62, 0));
  }

  .md\:hover\:tw-via-red-700:hover {
    --gradient-via-color: #c53030;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(197, 48, 48, 0));
  }

  .md\:hover\:tw-via-red-800:hover {
    --gradient-via-color: #9b2c2c;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(155, 44, 44, 0));
  }

  .md\:hover\:tw-via-red-900:hover {
    --gradient-via-color: #742a2a;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(116, 42, 42, 0));
  }

  .md\:hover\:tw-via-orange-100:hover {
    --gradient-via-color: #fffaf0;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 250, 240, 0));
  }

  .md\:hover\:tw-via-orange-200:hover {
    --gradient-via-color: #feebc8;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(254, 235, 200, 0));
  }

  .md\:hover\:tw-via-orange-300:hover {
    --gradient-via-color: #fbd38d;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(251, 211, 141, 0));
  }

  .md\:hover\:tw-via-orange-400:hover {
    --gradient-via-color: #f6ad55;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(246, 173, 85, 0));
  }

  .md\:hover\:tw-via-orange-500:hover {
    --gradient-via-color: #ed8936;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(237, 137, 54, 0));
  }

  .md\:hover\:tw-via-orange-600:hover {
    --gradient-via-color: #dd6b20;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(221, 107, 32, 0));
  }

  .md\:hover\:tw-via-orange-700:hover {
    --gradient-via-color: #c05621;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(192, 86, 33, 0));
  }

  .md\:hover\:tw-via-orange-800:hover {
    --gradient-via-color: #9c4221;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(156, 66, 33, 0));
  }

  .md\:hover\:tw-via-orange-900:hover {
    --gradient-via-color: #7b341e;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(123, 52, 30, 0));
  }

  .md\:hover\:tw-via-yellow-100:hover {
    --gradient-via-color: #fffff0;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 240, 0));
  }

  .md\:hover\:tw-via-yellow-200:hover {
    --gradient-via-color: #fefcbf;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(254, 252, 191, 0));
  }

  .md\:hover\:tw-via-yellow-300:hover {
    --gradient-via-color: #faf089;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(250, 240, 137, 0));
  }

  .md\:hover\:tw-via-yellow-400:hover {
    --gradient-via-color: #f6e05e;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(246, 224, 94, 0));
  }

  .md\:hover\:tw-via-yellow-500:hover {
    --gradient-via-color: #ecc94b;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(236, 201, 75, 0));
  }

  .md\:hover\:tw-via-yellow-600:hover {
    --gradient-via-color: #d69e2e;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(214, 158, 46, 0));
  }

  .md\:hover\:tw-via-yellow-700:hover {
    --gradient-via-color: #b7791f;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(183, 121, 31, 0));
  }

  .md\:hover\:tw-via-yellow-800:hover {
    --gradient-via-color: #975a16;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(151, 90, 22, 0));
  }

  .md\:hover\:tw-via-yellow-900:hover {
    --gradient-via-color: #744210;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(116, 66, 16, 0));
  }

  .md\:hover\:tw-via-green-100:hover {
    --gradient-via-color: #f0fff4;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(240, 255, 244, 0));
  }

  .md\:hover\:tw-via-green-200:hover {
    --gradient-via-color: #c6f6d5;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(198, 246, 213, 0));
  }

  .md\:hover\:tw-via-green-300:hover {
    --gradient-via-color: #9ae6b4;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(154, 230, 180, 0));
  }

  .md\:hover\:tw-via-green-400:hover {
    --gradient-via-color: #68d391;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(104, 211, 145, 0));
  }

  .md\:hover\:tw-via-green-500:hover {
    --gradient-via-color: #48bb78;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(72, 187, 120, 0));
  }

  .md\:hover\:tw-via-green-600:hover {
    --gradient-via-color: #38a169;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(56, 161, 105, 0));
  }

  .md\:hover\:tw-via-green-700:hover {
    --gradient-via-color: #2f855a;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(47, 133, 90, 0));
  }

  .md\:hover\:tw-via-green-800:hover {
    --gradient-via-color: #276749;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(39, 103, 73, 0));
  }

  .md\:hover\:tw-via-green-900:hover {
    --gradient-via-color: #22543d;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(34, 84, 61, 0));
  }

  .md\:hover\:tw-via-teal-100:hover {
    --gradient-via-color: #e6fffa;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(230, 255, 250, 0));
  }

  .md\:hover\:tw-via-teal-200:hover {
    --gradient-via-color: #b2f5ea;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(178, 245, 234, 0));
  }

  .md\:hover\:tw-via-teal-300:hover {
    --gradient-via-color: #81e6d9;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(129, 230, 217, 0));
  }

  .md\:hover\:tw-via-teal-400:hover {
    --gradient-via-color: #4fd1c5;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(79, 209, 197, 0));
  }

  .md\:hover\:tw-via-teal-500:hover {
    --gradient-via-color: #38b2ac;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(56, 178, 172, 0));
  }

  .md\:hover\:tw-via-teal-600:hover {
    --gradient-via-color: #319795;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(49, 151, 149, 0));
  }

  .md\:hover\:tw-via-teal-700:hover {
    --gradient-via-color: #2c7a7b;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(44, 122, 123, 0));
  }

  .md\:hover\:tw-via-teal-800:hover {
    --gradient-via-color: #285e61;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(40, 94, 97, 0));
  }

  .md\:hover\:tw-via-teal-900:hover {
    --gradient-via-color: #234e52;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(35, 78, 82, 0));
  }

  .md\:hover\:tw-via-blue-100:hover {
    --gradient-via-color: #ebf8ff;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(235, 248, 255, 0));
  }

  .md\:hover\:tw-via-blue-200:hover {
    --gradient-via-color: #bee3f8;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(190, 227, 248, 0));
  }

  .md\:hover\:tw-via-blue-300:hover {
    --gradient-via-color: #90cdf4;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(144, 205, 244, 0));
  }

  .md\:hover\:tw-via-blue-400:hover {
    --gradient-via-color: #63b3ed;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(99, 179, 237, 0));
  }

  .md\:hover\:tw-via-blue-500:hover {
    --gradient-via-color: #4299e1;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(66, 153, 225, 0));
  }

  .md\:hover\:tw-via-blue-600:hover {
    --gradient-via-color: #3182ce;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(49, 130, 206, 0));
  }

  .md\:hover\:tw-via-blue-700:hover {
    --gradient-via-color: #2b6cb0;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(43, 108, 176, 0));
  }

  .md\:hover\:tw-via-blue-800:hover {
    --gradient-via-color: #2c5282;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(44, 82, 130, 0));
  }

  .md\:hover\:tw-via-blue-900:hover {
    --gradient-via-color: #2a4365;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(42, 67, 101, 0));
  }

  .md\:hover\:tw-via-indigo-100:hover {
    --gradient-via-color: #ebf4ff;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(235, 244, 255, 0));
  }

  .md\:hover\:tw-via-indigo-200:hover {
    --gradient-via-color: #c3dafe;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(195, 218, 254, 0));
  }

  .md\:hover\:tw-via-indigo-300:hover {
    --gradient-via-color: #a3bffa;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(163, 191, 250, 0));
  }

  .md\:hover\:tw-via-indigo-400:hover {
    --gradient-via-color: #7f9cf5;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(127, 156, 245, 0));
  }

  .md\:hover\:tw-via-indigo-500:hover {
    --gradient-via-color: #667eea;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(102, 126, 234, 0));
  }

  .md\:hover\:tw-via-indigo-600:hover {
    --gradient-via-color: #5a67d8;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(90, 103, 216, 0));
  }

  .md\:hover\:tw-via-indigo-700:hover {
    --gradient-via-color: #4c51bf;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(76, 81, 191, 0));
  }

  .md\:hover\:tw-via-indigo-800:hover {
    --gradient-via-color: #434190;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(67, 65, 144, 0));
  }

  .md\:hover\:tw-via-indigo-900:hover {
    --gradient-via-color: #3c366b;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(60, 54, 107, 0));
  }

  .md\:hover\:tw-via-purple-100:hover {
    --gradient-via-color: #faf5ff;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(250, 245, 255, 0));
  }

  .md\:hover\:tw-via-purple-200:hover {
    --gradient-via-color: #e9d8fd;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(233, 216, 253, 0));
  }

  .md\:hover\:tw-via-purple-300:hover {
    --gradient-via-color: #d6bcfa;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(214, 188, 250, 0));
  }

  .md\:hover\:tw-via-purple-400:hover {
    --gradient-via-color: #b794f4;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(183, 148, 244, 0));
  }

  .md\:hover\:tw-via-purple-500:hover {
    --gradient-via-color: #9f7aea;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(159, 122, 234, 0));
  }

  .md\:hover\:tw-via-purple-600:hover {
    --gradient-via-color: #805ad5;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(128, 90, 213, 0));
  }

  .md\:hover\:tw-via-purple-700:hover {
    --gradient-via-color: #6b46c1;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(107, 70, 193, 0));
  }

  .md\:hover\:tw-via-purple-800:hover {
    --gradient-via-color: #553c9a;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(85, 60, 154, 0));
  }

  .md\:hover\:tw-via-purple-900:hover {
    --gradient-via-color: #44337a;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(68, 51, 122, 0));
  }

  .md\:hover\:tw-via-pink-100:hover {
    --gradient-via-color: #fff5f7;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 245, 247, 0));
  }

  .md\:hover\:tw-via-pink-200:hover {
    --gradient-via-color: #fed7e2;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(254, 215, 226, 0));
  }

  .md\:hover\:tw-via-pink-300:hover {
    --gradient-via-color: #fbb6ce;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(251, 182, 206, 0));
  }

  .md\:hover\:tw-via-pink-400:hover {
    --gradient-via-color: #f687b3;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(246, 135, 179, 0));
  }

  .md\:hover\:tw-via-pink-500:hover {
    --gradient-via-color: #ed64a6;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(237, 100, 166, 0));
  }

  .md\:hover\:tw-via-pink-600:hover {
    --gradient-via-color: #d53f8c;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(213, 63, 140, 0));
  }

  .md\:hover\:tw-via-pink-700:hover {
    --gradient-via-color: #b83280;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(184, 50, 128, 0));
  }

  .md\:hover\:tw-via-pink-800:hover {
    --gradient-via-color: #97266d;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(151, 38, 109, 0));
  }

  .md\:hover\:tw-via-pink-900:hover {
    --gradient-via-color: #702459;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(112, 36, 89, 0));
  }

  .md\:hover\:tw-to-transparent:hover {
    --gradient-to-color: transparent;
  }

  .md\:hover\:tw-to-current:hover {
    --gradient-to-color: currentColor;
  }

  .md\:hover\:tw-to-black:hover {
    --gradient-to-color: #000;
  }

  .md\:hover\:tw-to-white:hover {
    --gradient-to-color: #fff;
  }

  .md\:hover\:tw-to-gray-100:hover {
    --gradient-to-color: #f7fafc;
  }

  .md\:hover\:tw-to-gray-200:hover {
    --gradient-to-color: #edf2f7;
  }

  .md\:hover\:tw-to-gray-300:hover {
    --gradient-to-color: #e2e8f0;
  }

  .md\:hover\:tw-to-gray-400:hover {
    --gradient-to-color: #cbd5e0;
  }

  .md\:hover\:tw-to-gray-500:hover {
    --gradient-to-color: #a0aec0;
  }

  .md\:hover\:tw-to-gray-600:hover {
    --gradient-to-color: #718096;
  }

  .md\:hover\:tw-to-gray-700:hover {
    --gradient-to-color: #4a5568;
  }

  .md\:hover\:tw-to-gray-800:hover {
    --gradient-to-color: #2d3748;
  }

  .md\:hover\:tw-to-gray-900:hover {
    --gradient-to-color: #1a202c;
  }

  .md\:hover\:tw-to-red-100:hover {
    --gradient-to-color: #fff5f5;
  }

  .md\:hover\:tw-to-red-200:hover {
    --gradient-to-color: #fed7d7;
  }

  .md\:hover\:tw-to-red-300:hover {
    --gradient-to-color: #feb2b2;
  }

  .md\:hover\:tw-to-red-400:hover {
    --gradient-to-color: #fc8181;
  }

  .md\:hover\:tw-to-red-500:hover {
    --gradient-to-color: #f56565;
  }

  .md\:hover\:tw-to-red-600:hover {
    --gradient-to-color: #e53e3e;
  }

  .md\:hover\:tw-to-red-700:hover {
    --gradient-to-color: #c53030;
  }

  .md\:hover\:tw-to-red-800:hover {
    --gradient-to-color: #9b2c2c;
  }

  .md\:hover\:tw-to-red-900:hover {
    --gradient-to-color: #742a2a;
  }

  .md\:hover\:tw-to-orange-100:hover {
    --gradient-to-color: #fffaf0;
  }

  .md\:hover\:tw-to-orange-200:hover {
    --gradient-to-color: #feebc8;
  }

  .md\:hover\:tw-to-orange-300:hover {
    --gradient-to-color: #fbd38d;
  }

  .md\:hover\:tw-to-orange-400:hover {
    --gradient-to-color: #f6ad55;
  }

  .md\:hover\:tw-to-orange-500:hover {
    --gradient-to-color: #ed8936;
  }

  .md\:hover\:tw-to-orange-600:hover {
    --gradient-to-color: #dd6b20;
  }

  .md\:hover\:tw-to-orange-700:hover {
    --gradient-to-color: #c05621;
  }

  .md\:hover\:tw-to-orange-800:hover {
    --gradient-to-color: #9c4221;
  }

  .md\:hover\:tw-to-orange-900:hover {
    --gradient-to-color: #7b341e;
  }

  .md\:hover\:tw-to-yellow-100:hover {
    --gradient-to-color: #fffff0;
  }

  .md\:hover\:tw-to-yellow-200:hover {
    --gradient-to-color: #fefcbf;
  }

  .md\:hover\:tw-to-yellow-300:hover {
    --gradient-to-color: #faf089;
  }

  .md\:hover\:tw-to-yellow-400:hover {
    --gradient-to-color: #f6e05e;
  }

  .md\:hover\:tw-to-yellow-500:hover {
    --gradient-to-color: #ecc94b;
  }

  .md\:hover\:tw-to-yellow-600:hover {
    --gradient-to-color: #d69e2e;
  }

  .md\:hover\:tw-to-yellow-700:hover {
    --gradient-to-color: #b7791f;
  }

  .md\:hover\:tw-to-yellow-800:hover {
    --gradient-to-color: #975a16;
  }

  .md\:hover\:tw-to-yellow-900:hover {
    --gradient-to-color: #744210;
  }

  .md\:hover\:tw-to-green-100:hover {
    --gradient-to-color: #f0fff4;
  }

  .md\:hover\:tw-to-green-200:hover {
    --gradient-to-color: #c6f6d5;
  }

  .md\:hover\:tw-to-green-300:hover {
    --gradient-to-color: #9ae6b4;
  }

  .md\:hover\:tw-to-green-400:hover {
    --gradient-to-color: #68d391;
  }

  .md\:hover\:tw-to-green-500:hover {
    --gradient-to-color: #48bb78;
  }

  .md\:hover\:tw-to-green-600:hover {
    --gradient-to-color: #38a169;
  }

  .md\:hover\:tw-to-green-700:hover {
    --gradient-to-color: #2f855a;
  }

  .md\:hover\:tw-to-green-800:hover {
    --gradient-to-color: #276749;
  }

  .md\:hover\:tw-to-green-900:hover {
    --gradient-to-color: #22543d;
  }

  .md\:hover\:tw-to-teal-100:hover {
    --gradient-to-color: #e6fffa;
  }

  .md\:hover\:tw-to-teal-200:hover {
    --gradient-to-color: #b2f5ea;
  }

  .md\:hover\:tw-to-teal-300:hover {
    --gradient-to-color: #81e6d9;
  }

  .md\:hover\:tw-to-teal-400:hover {
    --gradient-to-color: #4fd1c5;
  }

  .md\:hover\:tw-to-teal-500:hover {
    --gradient-to-color: #38b2ac;
  }

  .md\:hover\:tw-to-teal-600:hover {
    --gradient-to-color: #319795;
  }

  .md\:hover\:tw-to-teal-700:hover {
    --gradient-to-color: #2c7a7b;
  }

  .md\:hover\:tw-to-teal-800:hover {
    --gradient-to-color: #285e61;
  }

  .md\:hover\:tw-to-teal-900:hover {
    --gradient-to-color: #234e52;
  }

  .md\:hover\:tw-to-blue-100:hover {
    --gradient-to-color: #ebf8ff;
  }

  .md\:hover\:tw-to-blue-200:hover {
    --gradient-to-color: #bee3f8;
  }

  .md\:hover\:tw-to-blue-300:hover {
    --gradient-to-color: #90cdf4;
  }

  .md\:hover\:tw-to-blue-400:hover {
    --gradient-to-color: #63b3ed;
  }

  .md\:hover\:tw-to-blue-500:hover {
    --gradient-to-color: #4299e1;
  }

  .md\:hover\:tw-to-blue-600:hover {
    --gradient-to-color: #3182ce;
  }

  .md\:hover\:tw-to-blue-700:hover {
    --gradient-to-color: #2b6cb0;
  }

  .md\:hover\:tw-to-blue-800:hover {
    --gradient-to-color: #2c5282;
  }

  .md\:hover\:tw-to-blue-900:hover {
    --gradient-to-color: #2a4365;
  }

  .md\:hover\:tw-to-indigo-100:hover {
    --gradient-to-color: #ebf4ff;
  }

  .md\:hover\:tw-to-indigo-200:hover {
    --gradient-to-color: #c3dafe;
  }

  .md\:hover\:tw-to-indigo-300:hover {
    --gradient-to-color: #a3bffa;
  }

  .md\:hover\:tw-to-indigo-400:hover {
    --gradient-to-color: #7f9cf5;
  }

  .md\:hover\:tw-to-indigo-500:hover {
    --gradient-to-color: #667eea;
  }

  .md\:hover\:tw-to-indigo-600:hover {
    --gradient-to-color: #5a67d8;
  }

  .md\:hover\:tw-to-indigo-700:hover {
    --gradient-to-color: #4c51bf;
  }

  .md\:hover\:tw-to-indigo-800:hover {
    --gradient-to-color: #434190;
  }

  .md\:hover\:tw-to-indigo-900:hover {
    --gradient-to-color: #3c366b;
  }

  .md\:hover\:tw-to-purple-100:hover {
    --gradient-to-color: #faf5ff;
  }

  .md\:hover\:tw-to-purple-200:hover {
    --gradient-to-color: #e9d8fd;
  }

  .md\:hover\:tw-to-purple-300:hover {
    --gradient-to-color: #d6bcfa;
  }

  .md\:hover\:tw-to-purple-400:hover {
    --gradient-to-color: #b794f4;
  }

  .md\:hover\:tw-to-purple-500:hover {
    --gradient-to-color: #9f7aea;
  }

  .md\:hover\:tw-to-purple-600:hover {
    --gradient-to-color: #805ad5;
  }

  .md\:hover\:tw-to-purple-700:hover {
    --gradient-to-color: #6b46c1;
  }

  .md\:hover\:tw-to-purple-800:hover {
    --gradient-to-color: #553c9a;
  }

  .md\:hover\:tw-to-purple-900:hover {
    --gradient-to-color: #44337a;
  }

  .md\:hover\:tw-to-pink-100:hover {
    --gradient-to-color: #fff5f7;
  }

  .md\:hover\:tw-to-pink-200:hover {
    --gradient-to-color: #fed7e2;
  }

  .md\:hover\:tw-to-pink-300:hover {
    --gradient-to-color: #fbb6ce;
  }

  .md\:hover\:tw-to-pink-400:hover {
    --gradient-to-color: #f687b3;
  }

  .md\:hover\:tw-to-pink-500:hover {
    --gradient-to-color: #ed64a6;
  }

  .md\:hover\:tw-to-pink-600:hover {
    --gradient-to-color: #d53f8c;
  }

  .md\:hover\:tw-to-pink-700:hover {
    --gradient-to-color: #b83280;
  }

  .md\:hover\:tw-to-pink-800:hover {
    --gradient-to-color: #97266d;
  }

  .md\:hover\:tw-to-pink-900:hover {
    --gradient-to-color: #702459;
  }

  .md\:focus\:tw-from-transparent:focus {
    --gradient-from-color: transparent;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(0, 0, 0, 0));
  }

  .md\:focus\:tw-from-current:focus {
    --gradient-from-color: currentColor;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0));
  }

  .md\:focus\:tw-from-black:focus {
    --gradient-from-color: #000;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(0, 0, 0, 0));
  }

  .md\:focus\:tw-from-white:focus {
    --gradient-from-color: #fff;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0));
  }

  .md\:focus\:tw-from-gray-100:focus {
    --gradient-from-color: #f7fafc;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(247, 250, 252, 0));
  }

  .md\:focus\:tw-from-gray-200:focus {
    --gradient-from-color: #edf2f7;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(237, 242, 247, 0));
  }

  .md\:focus\:tw-from-gray-300:focus {
    --gradient-from-color: #e2e8f0;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(226, 232, 240, 0));
  }

  .md\:focus\:tw-from-gray-400:focus {
    --gradient-from-color: #cbd5e0;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(203, 213, 224, 0));
  }

  .md\:focus\:tw-from-gray-500:focus {
    --gradient-from-color: #a0aec0;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(160, 174, 192, 0));
  }

  .md\:focus\:tw-from-gray-600:focus {
    --gradient-from-color: #718096;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(113, 128, 150, 0));
  }

  .md\:focus\:tw-from-gray-700:focus {
    --gradient-from-color: #4a5568;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(74, 85, 104, 0));
  }

  .md\:focus\:tw-from-gray-800:focus {
    --gradient-from-color: #2d3748;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(45, 55, 72, 0));
  }

  .md\:focus\:tw-from-gray-900:focus {
    --gradient-from-color: #1a202c;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(26, 32, 44, 0));
  }

  .md\:focus\:tw-from-red-100:focus {
    --gradient-from-color: #fff5f5;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 245, 245, 0));
  }

  .md\:focus\:tw-from-red-200:focus {
    --gradient-from-color: #fed7d7;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(254, 215, 215, 0));
  }

  .md\:focus\:tw-from-red-300:focus {
    --gradient-from-color: #feb2b2;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(254, 178, 178, 0));
  }

  .md\:focus\:tw-from-red-400:focus {
    --gradient-from-color: #fc8181;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(252, 129, 129, 0));
  }

  .md\:focus\:tw-from-red-500:focus {
    --gradient-from-color: #f56565;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(245, 101, 101, 0));
  }

  .md\:focus\:tw-from-red-600:focus {
    --gradient-from-color: #e53e3e;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(229, 62, 62, 0));
  }

  .md\:focus\:tw-from-red-700:focus {
    --gradient-from-color: #c53030;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(197, 48, 48, 0));
  }

  .md\:focus\:tw-from-red-800:focus {
    --gradient-from-color: #9b2c2c;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(155, 44, 44, 0));
  }

  .md\:focus\:tw-from-red-900:focus {
    --gradient-from-color: #742a2a;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(116, 42, 42, 0));
  }

  .md\:focus\:tw-from-orange-100:focus {
    --gradient-from-color: #fffaf0;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 250, 240, 0));
  }

  .md\:focus\:tw-from-orange-200:focus {
    --gradient-from-color: #feebc8;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(254, 235, 200, 0));
  }

  .md\:focus\:tw-from-orange-300:focus {
    --gradient-from-color: #fbd38d;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(251, 211, 141, 0));
  }

  .md\:focus\:tw-from-orange-400:focus {
    --gradient-from-color: #f6ad55;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(246, 173, 85, 0));
  }

  .md\:focus\:tw-from-orange-500:focus {
    --gradient-from-color: #ed8936;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(237, 137, 54, 0));
  }

  .md\:focus\:tw-from-orange-600:focus {
    --gradient-from-color: #dd6b20;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(221, 107, 32, 0));
  }

  .md\:focus\:tw-from-orange-700:focus {
    --gradient-from-color: #c05621;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(192, 86, 33, 0));
  }

  .md\:focus\:tw-from-orange-800:focus {
    --gradient-from-color: #9c4221;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(156, 66, 33, 0));
  }

  .md\:focus\:tw-from-orange-900:focus {
    --gradient-from-color: #7b341e;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(123, 52, 30, 0));
  }

  .md\:focus\:tw-from-yellow-100:focus {
    --gradient-from-color: #fffff0;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 240, 0));
  }

  .md\:focus\:tw-from-yellow-200:focus {
    --gradient-from-color: #fefcbf;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(254, 252, 191, 0));
  }

  .md\:focus\:tw-from-yellow-300:focus {
    --gradient-from-color: #faf089;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(250, 240, 137, 0));
  }

  .md\:focus\:tw-from-yellow-400:focus {
    --gradient-from-color: #f6e05e;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(246, 224, 94, 0));
  }

  .md\:focus\:tw-from-yellow-500:focus {
    --gradient-from-color: #ecc94b;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(236, 201, 75, 0));
  }

  .md\:focus\:tw-from-yellow-600:focus {
    --gradient-from-color: #d69e2e;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(214, 158, 46, 0));
  }

  .md\:focus\:tw-from-yellow-700:focus {
    --gradient-from-color: #b7791f;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(183, 121, 31, 0));
  }

  .md\:focus\:tw-from-yellow-800:focus {
    --gradient-from-color: #975a16;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(151, 90, 22, 0));
  }

  .md\:focus\:tw-from-yellow-900:focus {
    --gradient-from-color: #744210;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(116, 66, 16, 0));
  }

  .md\:focus\:tw-from-green-100:focus {
    --gradient-from-color: #f0fff4;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(240, 255, 244, 0));
  }

  .md\:focus\:tw-from-green-200:focus {
    --gradient-from-color: #c6f6d5;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(198, 246, 213, 0));
  }

  .md\:focus\:tw-from-green-300:focus {
    --gradient-from-color: #9ae6b4;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(154, 230, 180, 0));
  }

  .md\:focus\:tw-from-green-400:focus {
    --gradient-from-color: #68d391;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(104, 211, 145, 0));
  }

  .md\:focus\:tw-from-green-500:focus {
    --gradient-from-color: #48bb78;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(72, 187, 120, 0));
  }

  .md\:focus\:tw-from-green-600:focus {
    --gradient-from-color: #38a169;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(56, 161, 105, 0));
  }

  .md\:focus\:tw-from-green-700:focus {
    --gradient-from-color: #2f855a;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(47, 133, 90, 0));
  }

  .md\:focus\:tw-from-green-800:focus {
    --gradient-from-color: #276749;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(39, 103, 73, 0));
  }

  .md\:focus\:tw-from-green-900:focus {
    --gradient-from-color: #22543d;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(34, 84, 61, 0));
  }

  .md\:focus\:tw-from-teal-100:focus {
    --gradient-from-color: #e6fffa;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(230, 255, 250, 0));
  }

  .md\:focus\:tw-from-teal-200:focus {
    --gradient-from-color: #b2f5ea;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(178, 245, 234, 0));
  }

  .md\:focus\:tw-from-teal-300:focus {
    --gradient-from-color: #81e6d9;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(129, 230, 217, 0));
  }

  .md\:focus\:tw-from-teal-400:focus {
    --gradient-from-color: #4fd1c5;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(79, 209, 197, 0));
  }

  .md\:focus\:tw-from-teal-500:focus {
    --gradient-from-color: #38b2ac;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(56, 178, 172, 0));
  }

  .md\:focus\:tw-from-teal-600:focus {
    --gradient-from-color: #319795;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(49, 151, 149, 0));
  }

  .md\:focus\:tw-from-teal-700:focus {
    --gradient-from-color: #2c7a7b;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(44, 122, 123, 0));
  }

  .md\:focus\:tw-from-teal-800:focus {
    --gradient-from-color: #285e61;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(40, 94, 97, 0));
  }

  .md\:focus\:tw-from-teal-900:focus {
    --gradient-from-color: #234e52;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(35, 78, 82, 0));
  }

  .md\:focus\:tw-from-blue-100:focus {
    --gradient-from-color: #ebf8ff;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(235, 248, 255, 0));
  }

  .md\:focus\:tw-from-blue-200:focus {
    --gradient-from-color: #bee3f8;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(190, 227, 248, 0));
  }

  .md\:focus\:tw-from-blue-300:focus {
    --gradient-from-color: #90cdf4;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(144, 205, 244, 0));
  }

  .md\:focus\:tw-from-blue-400:focus {
    --gradient-from-color: #63b3ed;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(99, 179, 237, 0));
  }

  .md\:focus\:tw-from-blue-500:focus {
    --gradient-from-color: #4299e1;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(66, 153, 225, 0));
  }

  .md\:focus\:tw-from-blue-600:focus {
    --gradient-from-color: #3182ce;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(49, 130, 206, 0));
  }

  .md\:focus\:tw-from-blue-700:focus {
    --gradient-from-color: #2b6cb0;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(43, 108, 176, 0));
  }

  .md\:focus\:tw-from-blue-800:focus {
    --gradient-from-color: #2c5282;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(44, 82, 130, 0));
  }

  .md\:focus\:tw-from-blue-900:focus {
    --gradient-from-color: #2a4365;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(42, 67, 101, 0));
  }

  .md\:focus\:tw-from-indigo-100:focus {
    --gradient-from-color: #ebf4ff;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(235, 244, 255, 0));
  }

  .md\:focus\:tw-from-indigo-200:focus {
    --gradient-from-color: #c3dafe;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(195, 218, 254, 0));
  }

  .md\:focus\:tw-from-indigo-300:focus {
    --gradient-from-color: #a3bffa;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(163, 191, 250, 0));
  }

  .md\:focus\:tw-from-indigo-400:focus {
    --gradient-from-color: #7f9cf5;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(127, 156, 245, 0));
  }

  .md\:focus\:tw-from-indigo-500:focus {
    --gradient-from-color: #667eea;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(102, 126, 234, 0));
  }

  .md\:focus\:tw-from-indigo-600:focus {
    --gradient-from-color: #5a67d8;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(90, 103, 216, 0));
  }

  .md\:focus\:tw-from-indigo-700:focus {
    --gradient-from-color: #4c51bf;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(76, 81, 191, 0));
  }

  .md\:focus\:tw-from-indigo-800:focus {
    --gradient-from-color: #434190;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(67, 65, 144, 0));
  }

  .md\:focus\:tw-from-indigo-900:focus {
    --gradient-from-color: #3c366b;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(60, 54, 107, 0));
  }

  .md\:focus\:tw-from-purple-100:focus {
    --gradient-from-color: #faf5ff;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(250, 245, 255, 0));
  }

  .md\:focus\:tw-from-purple-200:focus {
    --gradient-from-color: #e9d8fd;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(233, 216, 253, 0));
  }

  .md\:focus\:tw-from-purple-300:focus {
    --gradient-from-color: #d6bcfa;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(214, 188, 250, 0));
  }

  .md\:focus\:tw-from-purple-400:focus {
    --gradient-from-color: #b794f4;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(183, 148, 244, 0));
  }

  .md\:focus\:tw-from-purple-500:focus {
    --gradient-from-color: #9f7aea;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(159, 122, 234, 0));
  }

  .md\:focus\:tw-from-purple-600:focus {
    --gradient-from-color: #805ad5;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(128, 90, 213, 0));
  }

  .md\:focus\:tw-from-purple-700:focus {
    --gradient-from-color: #6b46c1;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(107, 70, 193, 0));
  }

  .md\:focus\:tw-from-purple-800:focus {
    --gradient-from-color: #553c9a;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(85, 60, 154, 0));
  }

  .md\:focus\:tw-from-purple-900:focus {
    --gradient-from-color: #44337a;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(68, 51, 122, 0));
  }

  .md\:focus\:tw-from-pink-100:focus {
    --gradient-from-color: #fff5f7;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 245, 247, 0));
  }

  .md\:focus\:tw-from-pink-200:focus {
    --gradient-from-color: #fed7e2;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(254, 215, 226, 0));
  }

  .md\:focus\:tw-from-pink-300:focus {
    --gradient-from-color: #fbb6ce;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(251, 182, 206, 0));
  }

  .md\:focus\:tw-from-pink-400:focus {
    --gradient-from-color: #f687b3;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(246, 135, 179, 0));
  }

  .md\:focus\:tw-from-pink-500:focus {
    --gradient-from-color: #ed64a6;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(237, 100, 166, 0));
  }

  .md\:focus\:tw-from-pink-600:focus {
    --gradient-from-color: #d53f8c;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(213, 63, 140, 0));
  }

  .md\:focus\:tw-from-pink-700:focus {
    --gradient-from-color: #b83280;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(184, 50, 128, 0));
  }

  .md\:focus\:tw-from-pink-800:focus {
    --gradient-from-color: #97266d;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(151, 38, 109, 0));
  }

  .md\:focus\:tw-from-pink-900:focus {
    --gradient-from-color: #702459;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(112, 36, 89, 0));
  }

  .md\:focus\:tw-via-transparent:focus {
    --gradient-via-color: transparent;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(0, 0, 0, 0));
  }

  .md\:focus\:tw-via-current:focus {
    --gradient-via-color: currentColor;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0));
  }

  .md\:focus\:tw-via-black:focus {
    --gradient-via-color: #000;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(0, 0, 0, 0));
  }

  .md\:focus\:tw-via-white:focus {
    --gradient-via-color: #fff;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0));
  }

  .md\:focus\:tw-via-gray-100:focus {
    --gradient-via-color: #f7fafc;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(247, 250, 252, 0));
  }

  .md\:focus\:tw-via-gray-200:focus {
    --gradient-via-color: #edf2f7;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(237, 242, 247, 0));
  }

  .md\:focus\:tw-via-gray-300:focus {
    --gradient-via-color: #e2e8f0;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(226, 232, 240, 0));
  }

  .md\:focus\:tw-via-gray-400:focus {
    --gradient-via-color: #cbd5e0;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(203, 213, 224, 0));
  }

  .md\:focus\:tw-via-gray-500:focus {
    --gradient-via-color: #a0aec0;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(160, 174, 192, 0));
  }

  .md\:focus\:tw-via-gray-600:focus {
    --gradient-via-color: #718096;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(113, 128, 150, 0));
  }

  .md\:focus\:tw-via-gray-700:focus {
    --gradient-via-color: #4a5568;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(74, 85, 104, 0));
  }

  .md\:focus\:tw-via-gray-800:focus {
    --gradient-via-color: #2d3748;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(45, 55, 72, 0));
  }

  .md\:focus\:tw-via-gray-900:focus {
    --gradient-via-color: #1a202c;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(26, 32, 44, 0));
  }

  .md\:focus\:tw-via-red-100:focus {
    --gradient-via-color: #fff5f5;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 245, 245, 0));
  }

  .md\:focus\:tw-via-red-200:focus {
    --gradient-via-color: #fed7d7;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(254, 215, 215, 0));
  }

  .md\:focus\:tw-via-red-300:focus {
    --gradient-via-color: #feb2b2;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(254, 178, 178, 0));
  }

  .md\:focus\:tw-via-red-400:focus {
    --gradient-via-color: #fc8181;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(252, 129, 129, 0));
  }

  .md\:focus\:tw-via-red-500:focus {
    --gradient-via-color: #f56565;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(245, 101, 101, 0));
  }

  .md\:focus\:tw-via-red-600:focus {
    --gradient-via-color: #e53e3e;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(229, 62, 62, 0));
  }

  .md\:focus\:tw-via-red-700:focus {
    --gradient-via-color: #c53030;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(197, 48, 48, 0));
  }

  .md\:focus\:tw-via-red-800:focus {
    --gradient-via-color: #9b2c2c;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(155, 44, 44, 0));
  }

  .md\:focus\:tw-via-red-900:focus {
    --gradient-via-color: #742a2a;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(116, 42, 42, 0));
  }

  .md\:focus\:tw-via-orange-100:focus {
    --gradient-via-color: #fffaf0;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 250, 240, 0));
  }

  .md\:focus\:tw-via-orange-200:focus {
    --gradient-via-color: #feebc8;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(254, 235, 200, 0));
  }

  .md\:focus\:tw-via-orange-300:focus {
    --gradient-via-color: #fbd38d;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(251, 211, 141, 0));
  }

  .md\:focus\:tw-via-orange-400:focus {
    --gradient-via-color: #f6ad55;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(246, 173, 85, 0));
  }

  .md\:focus\:tw-via-orange-500:focus {
    --gradient-via-color: #ed8936;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(237, 137, 54, 0));
  }

  .md\:focus\:tw-via-orange-600:focus {
    --gradient-via-color: #dd6b20;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(221, 107, 32, 0));
  }

  .md\:focus\:tw-via-orange-700:focus {
    --gradient-via-color: #c05621;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(192, 86, 33, 0));
  }

  .md\:focus\:tw-via-orange-800:focus {
    --gradient-via-color: #9c4221;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(156, 66, 33, 0));
  }

  .md\:focus\:tw-via-orange-900:focus {
    --gradient-via-color: #7b341e;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(123, 52, 30, 0));
  }

  .md\:focus\:tw-via-yellow-100:focus {
    --gradient-via-color: #fffff0;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 240, 0));
  }

  .md\:focus\:tw-via-yellow-200:focus {
    --gradient-via-color: #fefcbf;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(254, 252, 191, 0));
  }

  .md\:focus\:tw-via-yellow-300:focus {
    --gradient-via-color: #faf089;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(250, 240, 137, 0));
  }

  .md\:focus\:tw-via-yellow-400:focus {
    --gradient-via-color: #f6e05e;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(246, 224, 94, 0));
  }

  .md\:focus\:tw-via-yellow-500:focus {
    --gradient-via-color: #ecc94b;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(236, 201, 75, 0));
  }

  .md\:focus\:tw-via-yellow-600:focus {
    --gradient-via-color: #d69e2e;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(214, 158, 46, 0));
  }

  .md\:focus\:tw-via-yellow-700:focus {
    --gradient-via-color: #b7791f;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(183, 121, 31, 0));
  }

  .md\:focus\:tw-via-yellow-800:focus {
    --gradient-via-color: #975a16;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(151, 90, 22, 0));
  }

  .md\:focus\:tw-via-yellow-900:focus {
    --gradient-via-color: #744210;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(116, 66, 16, 0));
  }

  .md\:focus\:tw-via-green-100:focus {
    --gradient-via-color: #f0fff4;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(240, 255, 244, 0));
  }

  .md\:focus\:tw-via-green-200:focus {
    --gradient-via-color: #c6f6d5;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(198, 246, 213, 0));
  }

  .md\:focus\:tw-via-green-300:focus {
    --gradient-via-color: #9ae6b4;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(154, 230, 180, 0));
  }

  .md\:focus\:tw-via-green-400:focus {
    --gradient-via-color: #68d391;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(104, 211, 145, 0));
  }

  .md\:focus\:tw-via-green-500:focus {
    --gradient-via-color: #48bb78;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(72, 187, 120, 0));
  }

  .md\:focus\:tw-via-green-600:focus {
    --gradient-via-color: #38a169;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(56, 161, 105, 0));
  }

  .md\:focus\:tw-via-green-700:focus {
    --gradient-via-color: #2f855a;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(47, 133, 90, 0));
  }

  .md\:focus\:tw-via-green-800:focus {
    --gradient-via-color: #276749;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(39, 103, 73, 0));
  }

  .md\:focus\:tw-via-green-900:focus {
    --gradient-via-color: #22543d;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(34, 84, 61, 0));
  }

  .md\:focus\:tw-via-teal-100:focus {
    --gradient-via-color: #e6fffa;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(230, 255, 250, 0));
  }

  .md\:focus\:tw-via-teal-200:focus {
    --gradient-via-color: #b2f5ea;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(178, 245, 234, 0));
  }

  .md\:focus\:tw-via-teal-300:focus {
    --gradient-via-color: #81e6d9;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(129, 230, 217, 0));
  }

  .md\:focus\:tw-via-teal-400:focus {
    --gradient-via-color: #4fd1c5;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(79, 209, 197, 0));
  }

  .md\:focus\:tw-via-teal-500:focus {
    --gradient-via-color: #38b2ac;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(56, 178, 172, 0));
  }

  .md\:focus\:tw-via-teal-600:focus {
    --gradient-via-color: #319795;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(49, 151, 149, 0));
  }

  .md\:focus\:tw-via-teal-700:focus {
    --gradient-via-color: #2c7a7b;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(44, 122, 123, 0));
  }

  .md\:focus\:tw-via-teal-800:focus {
    --gradient-via-color: #285e61;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(40, 94, 97, 0));
  }

  .md\:focus\:tw-via-teal-900:focus {
    --gradient-via-color: #234e52;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(35, 78, 82, 0));
  }

  .md\:focus\:tw-via-blue-100:focus {
    --gradient-via-color: #ebf8ff;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(235, 248, 255, 0));
  }

  .md\:focus\:tw-via-blue-200:focus {
    --gradient-via-color: #bee3f8;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(190, 227, 248, 0));
  }

  .md\:focus\:tw-via-blue-300:focus {
    --gradient-via-color: #90cdf4;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(144, 205, 244, 0));
  }

  .md\:focus\:tw-via-blue-400:focus {
    --gradient-via-color: #63b3ed;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(99, 179, 237, 0));
  }

  .md\:focus\:tw-via-blue-500:focus {
    --gradient-via-color: #4299e1;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(66, 153, 225, 0));
  }

  .md\:focus\:tw-via-blue-600:focus {
    --gradient-via-color: #3182ce;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(49, 130, 206, 0));
  }

  .md\:focus\:tw-via-blue-700:focus {
    --gradient-via-color: #2b6cb0;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(43, 108, 176, 0));
  }

  .md\:focus\:tw-via-blue-800:focus {
    --gradient-via-color: #2c5282;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(44, 82, 130, 0));
  }

  .md\:focus\:tw-via-blue-900:focus {
    --gradient-via-color: #2a4365;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(42, 67, 101, 0));
  }

  .md\:focus\:tw-via-indigo-100:focus {
    --gradient-via-color: #ebf4ff;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(235, 244, 255, 0));
  }

  .md\:focus\:tw-via-indigo-200:focus {
    --gradient-via-color: #c3dafe;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(195, 218, 254, 0));
  }

  .md\:focus\:tw-via-indigo-300:focus {
    --gradient-via-color: #a3bffa;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(163, 191, 250, 0));
  }

  .md\:focus\:tw-via-indigo-400:focus {
    --gradient-via-color: #7f9cf5;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(127, 156, 245, 0));
  }

  .md\:focus\:tw-via-indigo-500:focus {
    --gradient-via-color: #667eea;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(102, 126, 234, 0));
  }

  .md\:focus\:tw-via-indigo-600:focus {
    --gradient-via-color: #5a67d8;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(90, 103, 216, 0));
  }

  .md\:focus\:tw-via-indigo-700:focus {
    --gradient-via-color: #4c51bf;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(76, 81, 191, 0));
  }

  .md\:focus\:tw-via-indigo-800:focus {
    --gradient-via-color: #434190;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(67, 65, 144, 0));
  }

  .md\:focus\:tw-via-indigo-900:focus {
    --gradient-via-color: #3c366b;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(60, 54, 107, 0));
  }

  .md\:focus\:tw-via-purple-100:focus {
    --gradient-via-color: #faf5ff;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(250, 245, 255, 0));
  }

  .md\:focus\:tw-via-purple-200:focus {
    --gradient-via-color: #e9d8fd;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(233, 216, 253, 0));
  }

  .md\:focus\:tw-via-purple-300:focus {
    --gradient-via-color: #d6bcfa;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(214, 188, 250, 0));
  }

  .md\:focus\:tw-via-purple-400:focus {
    --gradient-via-color: #b794f4;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(183, 148, 244, 0));
  }

  .md\:focus\:tw-via-purple-500:focus {
    --gradient-via-color: #9f7aea;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(159, 122, 234, 0));
  }

  .md\:focus\:tw-via-purple-600:focus {
    --gradient-via-color: #805ad5;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(128, 90, 213, 0));
  }

  .md\:focus\:tw-via-purple-700:focus {
    --gradient-via-color: #6b46c1;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(107, 70, 193, 0));
  }

  .md\:focus\:tw-via-purple-800:focus {
    --gradient-via-color: #553c9a;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(85, 60, 154, 0));
  }

  .md\:focus\:tw-via-purple-900:focus {
    --gradient-via-color: #44337a;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(68, 51, 122, 0));
  }

  .md\:focus\:tw-via-pink-100:focus {
    --gradient-via-color: #fff5f7;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 245, 247, 0));
  }

  .md\:focus\:tw-via-pink-200:focus {
    --gradient-via-color: #fed7e2;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(254, 215, 226, 0));
  }

  .md\:focus\:tw-via-pink-300:focus {
    --gradient-via-color: #fbb6ce;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(251, 182, 206, 0));
  }

  .md\:focus\:tw-via-pink-400:focus {
    --gradient-via-color: #f687b3;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(246, 135, 179, 0));
  }

  .md\:focus\:tw-via-pink-500:focus {
    --gradient-via-color: #ed64a6;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(237, 100, 166, 0));
  }

  .md\:focus\:tw-via-pink-600:focus {
    --gradient-via-color: #d53f8c;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(213, 63, 140, 0));
  }

  .md\:focus\:tw-via-pink-700:focus {
    --gradient-via-color: #b83280;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(184, 50, 128, 0));
  }

  .md\:focus\:tw-via-pink-800:focus {
    --gradient-via-color: #97266d;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(151, 38, 109, 0));
  }

  .md\:focus\:tw-via-pink-900:focus {
    --gradient-via-color: #702459;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(112, 36, 89, 0));
  }

  .md\:focus\:tw-to-transparent:focus {
    --gradient-to-color: transparent;
  }

  .md\:focus\:tw-to-current:focus {
    --gradient-to-color: currentColor;
  }

  .md\:focus\:tw-to-black:focus {
    --gradient-to-color: #000;
  }

  .md\:focus\:tw-to-white:focus {
    --gradient-to-color: #fff;
  }

  .md\:focus\:tw-to-gray-100:focus {
    --gradient-to-color: #f7fafc;
  }

  .md\:focus\:tw-to-gray-200:focus {
    --gradient-to-color: #edf2f7;
  }

  .md\:focus\:tw-to-gray-300:focus {
    --gradient-to-color: #e2e8f0;
  }

  .md\:focus\:tw-to-gray-400:focus {
    --gradient-to-color: #cbd5e0;
  }

  .md\:focus\:tw-to-gray-500:focus {
    --gradient-to-color: #a0aec0;
  }

  .md\:focus\:tw-to-gray-600:focus {
    --gradient-to-color: #718096;
  }

  .md\:focus\:tw-to-gray-700:focus {
    --gradient-to-color: #4a5568;
  }

  .md\:focus\:tw-to-gray-800:focus {
    --gradient-to-color: #2d3748;
  }

  .md\:focus\:tw-to-gray-900:focus {
    --gradient-to-color: #1a202c;
  }

  .md\:focus\:tw-to-red-100:focus {
    --gradient-to-color: #fff5f5;
  }

  .md\:focus\:tw-to-red-200:focus {
    --gradient-to-color: #fed7d7;
  }

  .md\:focus\:tw-to-red-300:focus {
    --gradient-to-color: #feb2b2;
  }

  .md\:focus\:tw-to-red-400:focus {
    --gradient-to-color: #fc8181;
  }

  .md\:focus\:tw-to-red-500:focus {
    --gradient-to-color: #f56565;
  }

  .md\:focus\:tw-to-red-600:focus {
    --gradient-to-color: #e53e3e;
  }

  .md\:focus\:tw-to-red-700:focus {
    --gradient-to-color: #c53030;
  }

  .md\:focus\:tw-to-red-800:focus {
    --gradient-to-color: #9b2c2c;
  }

  .md\:focus\:tw-to-red-900:focus {
    --gradient-to-color: #742a2a;
  }

  .md\:focus\:tw-to-orange-100:focus {
    --gradient-to-color: #fffaf0;
  }

  .md\:focus\:tw-to-orange-200:focus {
    --gradient-to-color: #feebc8;
  }

  .md\:focus\:tw-to-orange-300:focus {
    --gradient-to-color: #fbd38d;
  }

  .md\:focus\:tw-to-orange-400:focus {
    --gradient-to-color: #f6ad55;
  }

  .md\:focus\:tw-to-orange-500:focus {
    --gradient-to-color: #ed8936;
  }

  .md\:focus\:tw-to-orange-600:focus {
    --gradient-to-color: #dd6b20;
  }

  .md\:focus\:tw-to-orange-700:focus {
    --gradient-to-color: #c05621;
  }

  .md\:focus\:tw-to-orange-800:focus {
    --gradient-to-color: #9c4221;
  }

  .md\:focus\:tw-to-orange-900:focus {
    --gradient-to-color: #7b341e;
  }

  .md\:focus\:tw-to-yellow-100:focus {
    --gradient-to-color: #fffff0;
  }

  .md\:focus\:tw-to-yellow-200:focus {
    --gradient-to-color: #fefcbf;
  }

  .md\:focus\:tw-to-yellow-300:focus {
    --gradient-to-color: #faf089;
  }

  .md\:focus\:tw-to-yellow-400:focus {
    --gradient-to-color: #f6e05e;
  }

  .md\:focus\:tw-to-yellow-500:focus {
    --gradient-to-color: #ecc94b;
  }

  .md\:focus\:tw-to-yellow-600:focus {
    --gradient-to-color: #d69e2e;
  }

  .md\:focus\:tw-to-yellow-700:focus {
    --gradient-to-color: #b7791f;
  }

  .md\:focus\:tw-to-yellow-800:focus {
    --gradient-to-color: #975a16;
  }

  .md\:focus\:tw-to-yellow-900:focus {
    --gradient-to-color: #744210;
  }

  .md\:focus\:tw-to-green-100:focus {
    --gradient-to-color: #f0fff4;
  }

  .md\:focus\:tw-to-green-200:focus {
    --gradient-to-color: #c6f6d5;
  }

  .md\:focus\:tw-to-green-300:focus {
    --gradient-to-color: #9ae6b4;
  }

  .md\:focus\:tw-to-green-400:focus {
    --gradient-to-color: #68d391;
  }

  .md\:focus\:tw-to-green-500:focus {
    --gradient-to-color: #48bb78;
  }

  .md\:focus\:tw-to-green-600:focus {
    --gradient-to-color: #38a169;
  }

  .md\:focus\:tw-to-green-700:focus {
    --gradient-to-color: #2f855a;
  }

  .md\:focus\:tw-to-green-800:focus {
    --gradient-to-color: #276749;
  }

  .md\:focus\:tw-to-green-900:focus {
    --gradient-to-color: #22543d;
  }

  .md\:focus\:tw-to-teal-100:focus {
    --gradient-to-color: #e6fffa;
  }

  .md\:focus\:tw-to-teal-200:focus {
    --gradient-to-color: #b2f5ea;
  }

  .md\:focus\:tw-to-teal-300:focus {
    --gradient-to-color: #81e6d9;
  }

  .md\:focus\:tw-to-teal-400:focus {
    --gradient-to-color: #4fd1c5;
  }

  .md\:focus\:tw-to-teal-500:focus {
    --gradient-to-color: #38b2ac;
  }

  .md\:focus\:tw-to-teal-600:focus {
    --gradient-to-color: #319795;
  }

  .md\:focus\:tw-to-teal-700:focus {
    --gradient-to-color: #2c7a7b;
  }

  .md\:focus\:tw-to-teal-800:focus {
    --gradient-to-color: #285e61;
  }

  .md\:focus\:tw-to-teal-900:focus {
    --gradient-to-color: #234e52;
  }

  .md\:focus\:tw-to-blue-100:focus {
    --gradient-to-color: #ebf8ff;
  }

  .md\:focus\:tw-to-blue-200:focus {
    --gradient-to-color: #bee3f8;
  }

  .md\:focus\:tw-to-blue-300:focus {
    --gradient-to-color: #90cdf4;
  }

  .md\:focus\:tw-to-blue-400:focus {
    --gradient-to-color: #63b3ed;
  }

  .md\:focus\:tw-to-blue-500:focus {
    --gradient-to-color: #4299e1;
  }

  .md\:focus\:tw-to-blue-600:focus {
    --gradient-to-color: #3182ce;
  }

  .md\:focus\:tw-to-blue-700:focus {
    --gradient-to-color: #2b6cb0;
  }

  .md\:focus\:tw-to-blue-800:focus {
    --gradient-to-color: #2c5282;
  }

  .md\:focus\:tw-to-blue-900:focus {
    --gradient-to-color: #2a4365;
  }

  .md\:focus\:tw-to-indigo-100:focus {
    --gradient-to-color: #ebf4ff;
  }

  .md\:focus\:tw-to-indigo-200:focus {
    --gradient-to-color: #c3dafe;
  }

  .md\:focus\:tw-to-indigo-300:focus {
    --gradient-to-color: #a3bffa;
  }

  .md\:focus\:tw-to-indigo-400:focus {
    --gradient-to-color: #7f9cf5;
  }

  .md\:focus\:tw-to-indigo-500:focus {
    --gradient-to-color: #667eea;
  }

  .md\:focus\:tw-to-indigo-600:focus {
    --gradient-to-color: #5a67d8;
  }

  .md\:focus\:tw-to-indigo-700:focus {
    --gradient-to-color: #4c51bf;
  }

  .md\:focus\:tw-to-indigo-800:focus {
    --gradient-to-color: #434190;
  }

  .md\:focus\:tw-to-indigo-900:focus {
    --gradient-to-color: #3c366b;
  }

  .md\:focus\:tw-to-purple-100:focus {
    --gradient-to-color: #faf5ff;
  }

  .md\:focus\:tw-to-purple-200:focus {
    --gradient-to-color: #e9d8fd;
  }

  .md\:focus\:tw-to-purple-300:focus {
    --gradient-to-color: #d6bcfa;
  }

  .md\:focus\:tw-to-purple-400:focus {
    --gradient-to-color: #b794f4;
  }

  .md\:focus\:tw-to-purple-500:focus {
    --gradient-to-color: #9f7aea;
  }

  .md\:focus\:tw-to-purple-600:focus {
    --gradient-to-color: #805ad5;
  }

  .md\:focus\:tw-to-purple-700:focus {
    --gradient-to-color: #6b46c1;
  }

  .md\:focus\:tw-to-purple-800:focus {
    --gradient-to-color: #553c9a;
  }

  .md\:focus\:tw-to-purple-900:focus {
    --gradient-to-color: #44337a;
  }

  .md\:focus\:tw-to-pink-100:focus {
    --gradient-to-color: #fff5f7;
  }

  .md\:focus\:tw-to-pink-200:focus {
    --gradient-to-color: #fed7e2;
  }

  .md\:focus\:tw-to-pink-300:focus {
    --gradient-to-color: #fbb6ce;
  }

  .md\:focus\:tw-to-pink-400:focus {
    --gradient-to-color: #f687b3;
  }

  .md\:focus\:tw-to-pink-500:focus {
    --gradient-to-color: #ed64a6;
  }

  .md\:focus\:tw-to-pink-600:focus {
    --gradient-to-color: #d53f8c;
  }

  .md\:focus\:tw-to-pink-700:focus {
    --gradient-to-color: #b83280;
  }

  .md\:focus\:tw-to-pink-800:focus {
    --gradient-to-color: #97266d;
  }

  .md\:focus\:tw-to-pink-900:focus {
    --gradient-to-color: #702459;
  }

  .md\:tw-bg-opacity-0 {
    --bg-opacity: 0;
  }

  .md\:tw-bg-opacity-25 {
    --bg-opacity: 0.25;
  }

  .md\:tw-bg-opacity-50 {
    --bg-opacity: 0.5;
  }

  .md\:tw-bg-opacity-75 {
    --bg-opacity: 0.75;
  }

  .md\:tw-bg-opacity-100 {
    --bg-opacity: 1;
  }

  .md\:hover\:tw-bg-opacity-0:hover {
    --bg-opacity: 0;
  }

  .md\:hover\:tw-bg-opacity-25:hover {
    --bg-opacity: 0.25;
  }

  .md\:hover\:tw-bg-opacity-50:hover {
    --bg-opacity: 0.5;
  }

  .md\:hover\:tw-bg-opacity-75:hover {
    --bg-opacity: 0.75;
  }

  .md\:hover\:tw-bg-opacity-100:hover {
    --bg-opacity: 1;
  }

  .md\:focus\:tw-bg-opacity-0:focus {
    --bg-opacity: 0;
  }

  .md\:focus\:tw-bg-opacity-25:focus {
    --bg-opacity: 0.25;
  }

  .md\:focus\:tw-bg-opacity-50:focus {
    --bg-opacity: 0.5;
  }

  .md\:focus\:tw-bg-opacity-75:focus {
    --bg-opacity: 0.75;
  }

  .md\:focus\:tw-bg-opacity-100:focus {
    --bg-opacity: 1;
  }

  .md\:tw-bg-bottom {
    background-position: bottom;
  }

  .md\:tw-bg-center {
    background-position: center;
  }

  .md\:tw-bg-left {
    background-position: left;
  }

  .md\:tw-bg-left-bottom {
    background-position: left bottom;
  }

  .md\:tw-bg-left-top {
    background-position: left top;
  }

  .md\:tw-bg-right {
    background-position: right;
  }

  .md\:tw-bg-right-bottom {
    background-position: right bottom;
  }

  .md\:tw-bg-right-top {
    background-position: right top;
  }

  .md\:tw-bg-top {
    background-position: top;
  }

  .md\:tw-bg-repeat {
    background-repeat: repeat;
  }

  .md\:tw-bg-no-repeat {
    background-repeat: no-repeat;
  }

  .md\:tw-bg-repeat-x {
    background-repeat: repeat-x;
  }

  .md\:tw-bg-repeat-y {
    background-repeat: repeat-y;
  }

  .md\:tw-bg-repeat-round {
    background-repeat: round;
  }

  .md\:tw-bg-repeat-space {
    background-repeat: space;
  }

  .md\:tw-bg-auto {
    background-size: auto;
  }

  .md\:tw-bg-cover {
    background-size: cover;
  }

  .md\:tw-bg-contain {
    background-size: contain;
  }

  .md\:tw-border-collapse {
    border-collapse: collapse;
  }

  .md\:tw-border-separate {
    border-collapse: separate;
  }

  .md\:tw-border-transparent {
    border-color: transparent;
  }

  .md\:tw-border-current {
    border-color: currentColor;
  }

  .md\:tw-border-black {
    --border-opacity: 1;
    border-color: #000;
    border-color: rgba(0, 0, 0, var(--border-opacity));
  }

  .md\:tw-border-white {
    --border-opacity: 1;
    border-color: #fff;
    border-color: rgba(255, 255, 255, var(--border-opacity));
  }

  .md\:tw-border-gray-100 {
    --border-opacity: 1;
    border-color: #f7fafc;
    border-color: rgba(247, 250, 252, var(--border-opacity));
  }

  .md\:tw-border-gray-200 {
    --border-opacity: 1;
    border-color: #edf2f7;
    border-color: rgba(237, 242, 247, var(--border-opacity));
  }

  .md\:tw-border-gray-300 {
    --border-opacity: 1;
    border-color: #e2e8f0;
    border-color: rgba(226, 232, 240, var(--border-opacity));
  }

  .md\:tw-border-gray-400 {
    --border-opacity: 1;
    border-color: #cbd5e0;
    border-color: rgba(203, 213, 224, var(--border-opacity));
  }

  .md\:tw-border-gray-500 {
    --border-opacity: 1;
    border-color: #a0aec0;
    border-color: rgba(160, 174, 192, var(--border-opacity));
  }

  .md\:tw-border-gray-600 {
    --border-opacity: 1;
    border-color: #718096;
    border-color: rgba(113, 128, 150, var(--border-opacity));
  }

  .md\:tw-border-gray-700 {
    --border-opacity: 1;
    border-color: #4a5568;
    border-color: rgba(74, 85, 104, var(--border-opacity));
  }

  .md\:tw-border-gray-800 {
    --border-opacity: 1;
    border-color: #2d3748;
    border-color: rgba(45, 55, 72, var(--border-opacity));
  }

  .md\:tw-border-gray-900 {
    --border-opacity: 1;
    border-color: #1a202c;
    border-color: rgba(26, 32, 44, var(--border-opacity));
  }

  .md\:tw-border-red-100 {
    --border-opacity: 1;
    border-color: #fff5f5;
    border-color: rgba(255, 245, 245, var(--border-opacity));
  }

  .md\:tw-border-red-200 {
    --border-opacity: 1;
    border-color: #fed7d7;
    border-color: rgba(254, 215, 215, var(--border-opacity));
  }

  .md\:tw-border-red-300 {
    --border-opacity: 1;
    border-color: #feb2b2;
    border-color: rgba(254, 178, 178, var(--border-opacity));
  }

  .md\:tw-border-red-400 {
    --border-opacity: 1;
    border-color: #fc8181;
    border-color: rgba(252, 129, 129, var(--border-opacity));
  }

  .md\:tw-border-red-500 {
    --border-opacity: 1;
    border-color: #f56565;
    border-color: rgba(245, 101, 101, var(--border-opacity));
  }

  .md\:tw-border-red-600 {
    --border-opacity: 1;
    border-color: #e53e3e;
    border-color: rgba(229, 62, 62, var(--border-opacity));
  }

  .md\:tw-border-red-700 {
    --border-opacity: 1;
    border-color: #c53030;
    border-color: rgba(197, 48, 48, var(--border-opacity));
  }

  .md\:tw-border-red-800 {
    --border-opacity: 1;
    border-color: #9b2c2c;
    border-color: rgba(155, 44, 44, var(--border-opacity));
  }

  .md\:tw-border-red-900 {
    --border-opacity: 1;
    border-color: #742a2a;
    border-color: rgba(116, 42, 42, var(--border-opacity));
  }

  .md\:tw-border-orange-100 {
    --border-opacity: 1;
    border-color: #fffaf0;
    border-color: rgba(255, 250, 240, var(--border-opacity));
  }

  .md\:tw-border-orange-200 {
    --border-opacity: 1;
    border-color: #feebc8;
    border-color: rgba(254, 235, 200, var(--border-opacity));
  }

  .md\:tw-border-orange-300 {
    --border-opacity: 1;
    border-color: #fbd38d;
    border-color: rgba(251, 211, 141, var(--border-opacity));
  }

  .md\:tw-border-orange-400 {
    --border-opacity: 1;
    border-color: #f6ad55;
    border-color: rgba(246, 173, 85, var(--border-opacity));
  }

  .md\:tw-border-orange-500 {
    --border-opacity: 1;
    border-color: #ed8936;
    border-color: rgba(237, 137, 54, var(--border-opacity));
  }

  .md\:tw-border-orange-600 {
    --border-opacity: 1;
    border-color: #dd6b20;
    border-color: rgba(221, 107, 32, var(--border-opacity));
  }

  .md\:tw-border-orange-700 {
    --border-opacity: 1;
    border-color: #c05621;
    border-color: rgba(192, 86, 33, var(--border-opacity));
  }

  .md\:tw-border-orange-800 {
    --border-opacity: 1;
    border-color: #9c4221;
    border-color: rgba(156, 66, 33, var(--border-opacity));
  }

  .md\:tw-border-orange-900 {
    --border-opacity: 1;
    border-color: #7b341e;
    border-color: rgba(123, 52, 30, var(--border-opacity));
  }

  .md\:tw-border-yellow-100 {
    --border-opacity: 1;
    border-color: #fffff0;
    border-color: rgba(255, 255, 240, var(--border-opacity));
  }

  .md\:tw-border-yellow-200 {
    --border-opacity: 1;
    border-color: #fefcbf;
    border-color: rgba(254, 252, 191, var(--border-opacity));
  }

  .md\:tw-border-yellow-300 {
    --border-opacity: 1;
    border-color: #faf089;
    border-color: rgba(250, 240, 137, var(--border-opacity));
  }

  .md\:tw-border-yellow-400 {
    --border-opacity: 1;
    border-color: #f6e05e;
    border-color: rgba(246, 224, 94, var(--border-opacity));
  }

  .md\:tw-border-yellow-500 {
    --border-opacity: 1;
    border-color: #ecc94b;
    border-color: rgba(236, 201, 75, var(--border-opacity));
  }

  .md\:tw-border-yellow-600 {
    --border-opacity: 1;
    border-color: #d69e2e;
    border-color: rgba(214, 158, 46, var(--border-opacity));
  }

  .md\:tw-border-yellow-700 {
    --border-opacity: 1;
    border-color: #b7791f;
    border-color: rgba(183, 121, 31, var(--border-opacity));
  }

  .md\:tw-border-yellow-800 {
    --border-opacity: 1;
    border-color: #975a16;
    border-color: rgba(151, 90, 22, var(--border-opacity));
  }

  .md\:tw-border-yellow-900 {
    --border-opacity: 1;
    border-color: #744210;
    border-color: rgba(116, 66, 16, var(--border-opacity));
  }

  .md\:tw-border-green-100 {
    --border-opacity: 1;
    border-color: #f0fff4;
    border-color: rgba(240, 255, 244, var(--border-opacity));
  }

  .md\:tw-border-green-200 {
    --border-opacity: 1;
    border-color: #c6f6d5;
    border-color: rgba(198, 246, 213, var(--border-opacity));
  }

  .md\:tw-border-green-300 {
    --border-opacity: 1;
    border-color: #9ae6b4;
    border-color: rgba(154, 230, 180, var(--border-opacity));
  }

  .md\:tw-border-green-400 {
    --border-opacity: 1;
    border-color: #68d391;
    border-color: rgba(104, 211, 145, var(--border-opacity));
  }

  .md\:tw-border-green-500 {
    --border-opacity: 1;
    border-color: #48bb78;
    border-color: rgba(72, 187, 120, var(--border-opacity));
  }

  .md\:tw-border-green-600 {
    --border-opacity: 1;
    border-color: #38a169;
    border-color: rgba(56, 161, 105, var(--border-opacity));
  }

  .md\:tw-border-green-700 {
    --border-opacity: 1;
    border-color: #2f855a;
    border-color: rgba(47, 133, 90, var(--border-opacity));
  }

  .md\:tw-border-green-800 {
    --border-opacity: 1;
    border-color: #276749;
    border-color: rgba(39, 103, 73, var(--border-opacity));
  }

  .md\:tw-border-green-900 {
    --border-opacity: 1;
    border-color: #22543d;
    border-color: rgba(34, 84, 61, var(--border-opacity));
  }

  .md\:tw-border-teal-100 {
    --border-opacity: 1;
    border-color: #e6fffa;
    border-color: rgba(230, 255, 250, var(--border-opacity));
  }

  .md\:tw-border-teal-200 {
    --border-opacity: 1;
    border-color: #b2f5ea;
    border-color: rgba(178, 245, 234, var(--border-opacity));
  }

  .md\:tw-border-teal-300 {
    --border-opacity: 1;
    border-color: #81e6d9;
    border-color: rgba(129, 230, 217, var(--border-opacity));
  }

  .md\:tw-border-teal-400 {
    --border-opacity: 1;
    border-color: #4fd1c5;
    border-color: rgba(79, 209, 197, var(--border-opacity));
  }

  .md\:tw-border-teal-500 {
    --border-opacity: 1;
    border-color: #38b2ac;
    border-color: rgba(56, 178, 172, var(--border-opacity));
  }

  .md\:tw-border-teal-600 {
    --border-opacity: 1;
    border-color: #319795;
    border-color: rgba(49, 151, 149, var(--border-opacity));
  }

  .md\:tw-border-teal-700 {
    --border-opacity: 1;
    border-color: #2c7a7b;
    border-color: rgba(44, 122, 123, var(--border-opacity));
  }

  .md\:tw-border-teal-800 {
    --border-opacity: 1;
    border-color: #285e61;
    border-color: rgba(40, 94, 97, var(--border-opacity));
  }

  .md\:tw-border-teal-900 {
    --border-opacity: 1;
    border-color: #234e52;
    border-color: rgba(35, 78, 82, var(--border-opacity));
  }

  .md\:tw-border-blue-100 {
    --border-opacity: 1;
    border-color: #ebf8ff;
    border-color: rgba(235, 248, 255, var(--border-opacity));
  }

  .md\:tw-border-blue-200 {
    --border-opacity: 1;
    border-color: #bee3f8;
    border-color: rgba(190, 227, 248, var(--border-opacity));
  }

  .md\:tw-border-blue-300 {
    --border-opacity: 1;
    border-color: #90cdf4;
    border-color: rgba(144, 205, 244, var(--border-opacity));
  }

  .md\:tw-border-blue-400 {
    --border-opacity: 1;
    border-color: #63b3ed;
    border-color: rgba(99, 179, 237, var(--border-opacity));
  }

  .md\:tw-border-blue-500 {
    --border-opacity: 1;
    border-color: #4299e1;
    border-color: rgba(66, 153, 225, var(--border-opacity));
  }

  .md\:tw-border-blue-600 {
    --border-opacity: 1;
    border-color: #3182ce;
    border-color: rgba(49, 130, 206, var(--border-opacity));
  }

  .md\:tw-border-blue-700 {
    --border-opacity: 1;
    border-color: #2b6cb0;
    border-color: rgba(43, 108, 176, var(--border-opacity));
  }

  .md\:tw-border-blue-800 {
    --border-opacity: 1;
    border-color: #2c5282;
    border-color: rgba(44, 82, 130, var(--border-opacity));
  }

  .md\:tw-border-blue-900 {
    --border-opacity: 1;
    border-color: #2a4365;
    border-color: rgba(42, 67, 101, var(--border-opacity));
  }

  .md\:tw-border-indigo-100 {
    --border-opacity: 1;
    border-color: #ebf4ff;
    border-color: rgba(235, 244, 255, var(--border-opacity));
  }

  .md\:tw-border-indigo-200 {
    --border-opacity: 1;
    border-color: #c3dafe;
    border-color: rgba(195, 218, 254, var(--border-opacity));
  }

  .md\:tw-border-indigo-300 {
    --border-opacity: 1;
    border-color: #a3bffa;
    border-color: rgba(163, 191, 250, var(--border-opacity));
  }

  .md\:tw-border-indigo-400 {
    --border-opacity: 1;
    border-color: #7f9cf5;
    border-color: rgba(127, 156, 245, var(--border-opacity));
  }

  .md\:tw-border-indigo-500 {
    --border-opacity: 1;
    border-color: #667eea;
    border-color: rgba(102, 126, 234, var(--border-opacity));
  }

  .md\:tw-border-indigo-600 {
    --border-opacity: 1;
    border-color: #5a67d8;
    border-color: rgba(90, 103, 216, var(--border-opacity));
  }

  .md\:tw-border-indigo-700 {
    --border-opacity: 1;
    border-color: #4c51bf;
    border-color: rgba(76, 81, 191, var(--border-opacity));
  }

  .md\:tw-border-indigo-800 {
    --border-opacity: 1;
    border-color: #434190;
    border-color: rgba(67, 65, 144, var(--border-opacity));
  }

  .md\:tw-border-indigo-900 {
    --border-opacity: 1;
    border-color: #3c366b;
    border-color: rgba(60, 54, 107, var(--border-opacity));
  }

  .md\:tw-border-purple-100 {
    --border-opacity: 1;
    border-color: #faf5ff;
    border-color: rgba(250, 245, 255, var(--border-opacity));
  }

  .md\:tw-border-purple-200 {
    --border-opacity: 1;
    border-color: #e9d8fd;
    border-color: rgba(233, 216, 253, var(--border-opacity));
  }

  .md\:tw-border-purple-300 {
    --border-opacity: 1;
    border-color: #d6bcfa;
    border-color: rgba(214, 188, 250, var(--border-opacity));
  }

  .md\:tw-border-purple-400 {
    --border-opacity: 1;
    border-color: #b794f4;
    border-color: rgba(183, 148, 244, var(--border-opacity));
  }

  .md\:tw-border-purple-500 {
    --border-opacity: 1;
    border-color: #9f7aea;
    border-color: rgba(159, 122, 234, var(--border-opacity));
  }

  .md\:tw-border-purple-600 {
    --border-opacity: 1;
    border-color: #805ad5;
    border-color: rgba(128, 90, 213, var(--border-opacity));
  }

  .md\:tw-border-purple-700 {
    --border-opacity: 1;
    border-color: #6b46c1;
    border-color: rgba(107, 70, 193, var(--border-opacity));
  }

  .md\:tw-border-purple-800 {
    --border-opacity: 1;
    border-color: #553c9a;
    border-color: rgba(85, 60, 154, var(--border-opacity));
  }

  .md\:tw-border-purple-900 {
    --border-opacity: 1;
    border-color: #44337a;
    border-color: rgba(68, 51, 122, var(--border-opacity));
  }

  .md\:tw-border-pink-100 {
    --border-opacity: 1;
    border-color: #fff5f7;
    border-color: rgba(255, 245, 247, var(--border-opacity));
  }

  .md\:tw-border-pink-200 {
    --border-opacity: 1;
    border-color: #fed7e2;
    border-color: rgba(254, 215, 226, var(--border-opacity));
  }

  .md\:tw-border-pink-300 {
    --border-opacity: 1;
    border-color: #fbb6ce;
    border-color: rgba(251, 182, 206, var(--border-opacity));
  }

  .md\:tw-border-pink-400 {
    --border-opacity: 1;
    border-color: #f687b3;
    border-color: rgba(246, 135, 179, var(--border-opacity));
  }

  .md\:tw-border-pink-500 {
    --border-opacity: 1;
    border-color: #ed64a6;
    border-color: rgba(237, 100, 166, var(--border-opacity));
  }

  .md\:tw-border-pink-600 {
    --border-opacity: 1;
    border-color: #d53f8c;
    border-color: rgba(213, 63, 140, var(--border-opacity));
  }

  .md\:tw-border-pink-700 {
    --border-opacity: 1;
    border-color: #b83280;
    border-color: rgba(184, 50, 128, var(--border-opacity));
  }

  .md\:tw-border-pink-800 {
    --border-opacity: 1;
    border-color: #97266d;
    border-color: rgba(151, 38, 109, var(--border-opacity));
  }

  .md\:tw-border-pink-900 {
    --border-opacity: 1;
    border-color: #702459;
    border-color: rgba(112, 36, 89, var(--border-opacity));
  }

  .md\:hover\:tw-border-transparent:hover {
    border-color: transparent;
  }

  .md\:hover\:tw-border-current:hover {
    border-color: currentColor;
  }

  .md\:hover\:tw-border-black:hover {
    --border-opacity: 1;
    border-color: #000;
    border-color: rgba(0, 0, 0, var(--border-opacity));
  }

  .md\:hover\:tw-border-white:hover {
    --border-opacity: 1;
    border-color: #fff;
    border-color: rgba(255, 255, 255, var(--border-opacity));
  }

  .md\:hover\:tw-border-gray-100:hover {
    --border-opacity: 1;
    border-color: #f7fafc;
    border-color: rgba(247, 250, 252, var(--border-opacity));
  }

  .md\:hover\:tw-border-gray-200:hover {
    --border-opacity: 1;
    border-color: #edf2f7;
    border-color: rgba(237, 242, 247, var(--border-opacity));
  }

  .md\:hover\:tw-border-gray-300:hover {
    --border-opacity: 1;
    border-color: #e2e8f0;
    border-color: rgba(226, 232, 240, var(--border-opacity));
  }

  .md\:hover\:tw-border-gray-400:hover {
    --border-opacity: 1;
    border-color: #cbd5e0;
    border-color: rgba(203, 213, 224, var(--border-opacity));
  }

  .md\:hover\:tw-border-gray-500:hover {
    --border-opacity: 1;
    border-color: #a0aec0;
    border-color: rgba(160, 174, 192, var(--border-opacity));
  }

  .md\:hover\:tw-border-gray-600:hover {
    --border-opacity: 1;
    border-color: #718096;
    border-color: rgba(113, 128, 150, var(--border-opacity));
  }

  .md\:hover\:tw-border-gray-700:hover {
    --border-opacity: 1;
    border-color: #4a5568;
    border-color: rgba(74, 85, 104, var(--border-opacity));
  }

  .md\:hover\:tw-border-gray-800:hover {
    --border-opacity: 1;
    border-color: #2d3748;
    border-color: rgba(45, 55, 72, var(--border-opacity));
  }

  .md\:hover\:tw-border-gray-900:hover {
    --border-opacity: 1;
    border-color: #1a202c;
    border-color: rgba(26, 32, 44, var(--border-opacity));
  }

  .md\:hover\:tw-border-red-100:hover {
    --border-opacity: 1;
    border-color: #fff5f5;
    border-color: rgba(255, 245, 245, var(--border-opacity));
  }

  .md\:hover\:tw-border-red-200:hover {
    --border-opacity: 1;
    border-color: #fed7d7;
    border-color: rgba(254, 215, 215, var(--border-opacity));
  }

  .md\:hover\:tw-border-red-300:hover {
    --border-opacity: 1;
    border-color: #feb2b2;
    border-color: rgba(254, 178, 178, var(--border-opacity));
  }

  .md\:hover\:tw-border-red-400:hover {
    --border-opacity: 1;
    border-color: #fc8181;
    border-color: rgba(252, 129, 129, var(--border-opacity));
  }

  .md\:hover\:tw-border-red-500:hover {
    --border-opacity: 1;
    border-color: #f56565;
    border-color: rgba(245, 101, 101, var(--border-opacity));
  }

  .md\:hover\:tw-border-red-600:hover {
    --border-opacity: 1;
    border-color: #e53e3e;
    border-color: rgba(229, 62, 62, var(--border-opacity));
  }

  .md\:hover\:tw-border-red-700:hover {
    --border-opacity: 1;
    border-color: #c53030;
    border-color: rgba(197, 48, 48, var(--border-opacity));
  }

  .md\:hover\:tw-border-red-800:hover {
    --border-opacity: 1;
    border-color: #9b2c2c;
    border-color: rgba(155, 44, 44, var(--border-opacity));
  }

  .md\:hover\:tw-border-red-900:hover {
    --border-opacity: 1;
    border-color: #742a2a;
    border-color: rgba(116, 42, 42, var(--border-opacity));
  }

  .md\:hover\:tw-border-orange-100:hover {
    --border-opacity: 1;
    border-color: #fffaf0;
    border-color: rgba(255, 250, 240, var(--border-opacity));
  }

  .md\:hover\:tw-border-orange-200:hover {
    --border-opacity: 1;
    border-color: #feebc8;
    border-color: rgba(254, 235, 200, var(--border-opacity));
  }

  .md\:hover\:tw-border-orange-300:hover {
    --border-opacity: 1;
    border-color: #fbd38d;
    border-color: rgba(251, 211, 141, var(--border-opacity));
  }

  .md\:hover\:tw-border-orange-400:hover {
    --border-opacity: 1;
    border-color: #f6ad55;
    border-color: rgba(246, 173, 85, var(--border-opacity));
  }

  .md\:hover\:tw-border-orange-500:hover {
    --border-opacity: 1;
    border-color: #ed8936;
    border-color: rgba(237, 137, 54, var(--border-opacity));
  }

  .md\:hover\:tw-border-orange-600:hover {
    --border-opacity: 1;
    border-color: #dd6b20;
    border-color: rgba(221, 107, 32, var(--border-opacity));
  }

  .md\:hover\:tw-border-orange-700:hover {
    --border-opacity: 1;
    border-color: #c05621;
    border-color: rgba(192, 86, 33, var(--border-opacity));
  }

  .md\:hover\:tw-border-orange-800:hover {
    --border-opacity: 1;
    border-color: #9c4221;
    border-color: rgba(156, 66, 33, var(--border-opacity));
  }

  .md\:hover\:tw-border-orange-900:hover {
    --border-opacity: 1;
    border-color: #7b341e;
    border-color: rgba(123, 52, 30, var(--border-opacity));
  }

  .md\:hover\:tw-border-yellow-100:hover {
    --border-opacity: 1;
    border-color: #fffff0;
    border-color: rgba(255, 255, 240, var(--border-opacity));
  }

  .md\:hover\:tw-border-yellow-200:hover {
    --border-opacity: 1;
    border-color: #fefcbf;
    border-color: rgba(254, 252, 191, var(--border-opacity));
  }

  .md\:hover\:tw-border-yellow-300:hover {
    --border-opacity: 1;
    border-color: #faf089;
    border-color: rgba(250, 240, 137, var(--border-opacity));
  }

  .md\:hover\:tw-border-yellow-400:hover {
    --border-opacity: 1;
    border-color: #f6e05e;
    border-color: rgba(246, 224, 94, var(--border-opacity));
  }

  .md\:hover\:tw-border-yellow-500:hover {
    --border-opacity: 1;
    border-color: #ecc94b;
    border-color: rgba(236, 201, 75, var(--border-opacity));
  }

  .md\:hover\:tw-border-yellow-600:hover {
    --border-opacity: 1;
    border-color: #d69e2e;
    border-color: rgba(214, 158, 46, var(--border-opacity));
  }

  .md\:hover\:tw-border-yellow-700:hover {
    --border-opacity: 1;
    border-color: #b7791f;
    border-color: rgba(183, 121, 31, var(--border-opacity));
  }

  .md\:hover\:tw-border-yellow-800:hover {
    --border-opacity: 1;
    border-color: #975a16;
    border-color: rgba(151, 90, 22, var(--border-opacity));
  }

  .md\:hover\:tw-border-yellow-900:hover {
    --border-opacity: 1;
    border-color: #744210;
    border-color: rgba(116, 66, 16, var(--border-opacity));
  }

  .md\:hover\:tw-border-green-100:hover {
    --border-opacity: 1;
    border-color: #f0fff4;
    border-color: rgba(240, 255, 244, var(--border-opacity));
  }

  .md\:hover\:tw-border-green-200:hover {
    --border-opacity: 1;
    border-color: #c6f6d5;
    border-color: rgba(198, 246, 213, var(--border-opacity));
  }

  .md\:hover\:tw-border-green-300:hover {
    --border-opacity: 1;
    border-color: #9ae6b4;
    border-color: rgba(154, 230, 180, var(--border-opacity));
  }

  .md\:hover\:tw-border-green-400:hover {
    --border-opacity: 1;
    border-color: #68d391;
    border-color: rgba(104, 211, 145, var(--border-opacity));
  }

  .md\:hover\:tw-border-green-500:hover {
    --border-opacity: 1;
    border-color: #48bb78;
    border-color: rgba(72, 187, 120, var(--border-opacity));
  }

  .md\:hover\:tw-border-green-600:hover {
    --border-opacity: 1;
    border-color: #38a169;
    border-color: rgba(56, 161, 105, var(--border-opacity));
  }

  .md\:hover\:tw-border-green-700:hover {
    --border-opacity: 1;
    border-color: #2f855a;
    border-color: rgba(47, 133, 90, var(--border-opacity));
  }

  .md\:hover\:tw-border-green-800:hover {
    --border-opacity: 1;
    border-color: #276749;
    border-color: rgba(39, 103, 73, var(--border-opacity));
  }

  .md\:hover\:tw-border-green-900:hover {
    --border-opacity: 1;
    border-color: #22543d;
    border-color: rgba(34, 84, 61, var(--border-opacity));
  }

  .md\:hover\:tw-border-teal-100:hover {
    --border-opacity: 1;
    border-color: #e6fffa;
    border-color: rgba(230, 255, 250, var(--border-opacity));
  }

  .md\:hover\:tw-border-teal-200:hover {
    --border-opacity: 1;
    border-color: #b2f5ea;
    border-color: rgba(178, 245, 234, var(--border-opacity));
  }

  .md\:hover\:tw-border-teal-300:hover {
    --border-opacity: 1;
    border-color: #81e6d9;
    border-color: rgba(129, 230, 217, var(--border-opacity));
  }

  .md\:hover\:tw-border-teal-400:hover {
    --border-opacity: 1;
    border-color: #4fd1c5;
    border-color: rgba(79, 209, 197, var(--border-opacity));
  }

  .md\:hover\:tw-border-teal-500:hover {
    --border-opacity: 1;
    border-color: #38b2ac;
    border-color: rgba(56, 178, 172, var(--border-opacity));
  }

  .md\:hover\:tw-border-teal-600:hover {
    --border-opacity: 1;
    border-color: #319795;
    border-color: rgba(49, 151, 149, var(--border-opacity));
  }

  .md\:hover\:tw-border-teal-700:hover {
    --border-opacity: 1;
    border-color: #2c7a7b;
    border-color: rgba(44, 122, 123, var(--border-opacity));
  }

  .md\:hover\:tw-border-teal-800:hover {
    --border-opacity: 1;
    border-color: #285e61;
    border-color: rgba(40, 94, 97, var(--border-opacity));
  }

  .md\:hover\:tw-border-teal-900:hover {
    --border-opacity: 1;
    border-color: #234e52;
    border-color: rgba(35, 78, 82, var(--border-opacity));
  }

  .md\:hover\:tw-border-blue-100:hover {
    --border-opacity: 1;
    border-color: #ebf8ff;
    border-color: rgba(235, 248, 255, var(--border-opacity));
  }

  .md\:hover\:tw-border-blue-200:hover {
    --border-opacity: 1;
    border-color: #bee3f8;
    border-color: rgba(190, 227, 248, var(--border-opacity));
  }

  .md\:hover\:tw-border-blue-300:hover {
    --border-opacity: 1;
    border-color: #90cdf4;
    border-color: rgba(144, 205, 244, var(--border-opacity));
  }

  .md\:hover\:tw-border-blue-400:hover {
    --border-opacity: 1;
    border-color: #63b3ed;
    border-color: rgba(99, 179, 237, var(--border-opacity));
  }

  .md\:hover\:tw-border-blue-500:hover {
    --border-opacity: 1;
    border-color: #4299e1;
    border-color: rgba(66, 153, 225, var(--border-opacity));
  }

  .md\:hover\:tw-border-blue-600:hover {
    --border-opacity: 1;
    border-color: #3182ce;
    border-color: rgba(49, 130, 206, var(--border-opacity));
  }

  .md\:hover\:tw-border-blue-700:hover {
    --border-opacity: 1;
    border-color: #2b6cb0;
    border-color: rgba(43, 108, 176, var(--border-opacity));
  }

  .md\:hover\:tw-border-blue-800:hover {
    --border-opacity: 1;
    border-color: #2c5282;
    border-color: rgba(44, 82, 130, var(--border-opacity));
  }

  .md\:hover\:tw-border-blue-900:hover {
    --border-opacity: 1;
    border-color: #2a4365;
    border-color: rgba(42, 67, 101, var(--border-opacity));
  }

  .md\:hover\:tw-border-indigo-100:hover {
    --border-opacity: 1;
    border-color: #ebf4ff;
    border-color: rgba(235, 244, 255, var(--border-opacity));
  }

  .md\:hover\:tw-border-indigo-200:hover {
    --border-opacity: 1;
    border-color: #c3dafe;
    border-color: rgba(195, 218, 254, var(--border-opacity));
  }

  .md\:hover\:tw-border-indigo-300:hover {
    --border-opacity: 1;
    border-color: #a3bffa;
    border-color: rgba(163, 191, 250, var(--border-opacity));
  }

  .md\:hover\:tw-border-indigo-400:hover {
    --border-opacity: 1;
    border-color: #7f9cf5;
    border-color: rgba(127, 156, 245, var(--border-opacity));
  }

  .md\:hover\:tw-border-indigo-500:hover {
    --border-opacity: 1;
    border-color: #667eea;
    border-color: rgba(102, 126, 234, var(--border-opacity));
  }

  .md\:hover\:tw-border-indigo-600:hover {
    --border-opacity: 1;
    border-color: #5a67d8;
    border-color: rgba(90, 103, 216, var(--border-opacity));
  }

  .md\:hover\:tw-border-indigo-700:hover {
    --border-opacity: 1;
    border-color: #4c51bf;
    border-color: rgba(76, 81, 191, var(--border-opacity));
  }

  .md\:hover\:tw-border-indigo-800:hover {
    --border-opacity: 1;
    border-color: #434190;
    border-color: rgba(67, 65, 144, var(--border-opacity));
  }

  .md\:hover\:tw-border-indigo-900:hover {
    --border-opacity: 1;
    border-color: #3c366b;
    border-color: rgba(60, 54, 107, var(--border-opacity));
  }

  .md\:hover\:tw-border-purple-100:hover {
    --border-opacity: 1;
    border-color: #faf5ff;
    border-color: rgba(250, 245, 255, var(--border-opacity));
  }

  .md\:hover\:tw-border-purple-200:hover {
    --border-opacity: 1;
    border-color: #e9d8fd;
    border-color: rgba(233, 216, 253, var(--border-opacity));
  }

  .md\:hover\:tw-border-purple-300:hover {
    --border-opacity: 1;
    border-color: #d6bcfa;
    border-color: rgba(214, 188, 250, var(--border-opacity));
  }

  .md\:hover\:tw-border-purple-400:hover {
    --border-opacity: 1;
    border-color: #b794f4;
    border-color: rgba(183, 148, 244, var(--border-opacity));
  }

  .md\:hover\:tw-border-purple-500:hover {
    --border-opacity: 1;
    border-color: #9f7aea;
    border-color: rgba(159, 122, 234, var(--border-opacity));
  }

  .md\:hover\:tw-border-purple-600:hover {
    --border-opacity: 1;
    border-color: #805ad5;
    border-color: rgba(128, 90, 213, var(--border-opacity));
  }

  .md\:hover\:tw-border-purple-700:hover {
    --border-opacity: 1;
    border-color: #6b46c1;
    border-color: rgba(107, 70, 193, var(--border-opacity));
  }

  .md\:hover\:tw-border-purple-800:hover {
    --border-opacity: 1;
    border-color: #553c9a;
    border-color: rgba(85, 60, 154, var(--border-opacity));
  }

  .md\:hover\:tw-border-purple-900:hover {
    --border-opacity: 1;
    border-color: #44337a;
    border-color: rgba(68, 51, 122, var(--border-opacity));
  }

  .md\:hover\:tw-border-pink-100:hover {
    --border-opacity: 1;
    border-color: #fff5f7;
    border-color: rgba(255, 245, 247, var(--border-opacity));
  }

  .md\:hover\:tw-border-pink-200:hover {
    --border-opacity: 1;
    border-color: #fed7e2;
    border-color: rgba(254, 215, 226, var(--border-opacity));
  }

  .md\:hover\:tw-border-pink-300:hover {
    --border-opacity: 1;
    border-color: #fbb6ce;
    border-color: rgba(251, 182, 206, var(--border-opacity));
  }

  .md\:hover\:tw-border-pink-400:hover {
    --border-opacity: 1;
    border-color: #f687b3;
    border-color: rgba(246, 135, 179, var(--border-opacity));
  }

  .md\:hover\:tw-border-pink-500:hover {
    --border-opacity: 1;
    border-color: #ed64a6;
    border-color: rgba(237, 100, 166, var(--border-opacity));
  }

  .md\:hover\:tw-border-pink-600:hover {
    --border-opacity: 1;
    border-color: #d53f8c;
    border-color: rgba(213, 63, 140, var(--border-opacity));
  }

  .md\:hover\:tw-border-pink-700:hover {
    --border-opacity: 1;
    border-color: #b83280;
    border-color: rgba(184, 50, 128, var(--border-opacity));
  }

  .md\:hover\:tw-border-pink-800:hover {
    --border-opacity: 1;
    border-color: #97266d;
    border-color: rgba(151, 38, 109, var(--border-opacity));
  }

  .md\:hover\:tw-border-pink-900:hover {
    --border-opacity: 1;
    border-color: #702459;
    border-color: rgba(112, 36, 89, var(--border-opacity));
  }

  .md\:focus\:tw-border-transparent:focus {
    border-color: transparent;
  }

  .md\:focus\:tw-border-current:focus {
    border-color: currentColor;
  }

  .md\:focus\:tw-border-black:focus {
    --border-opacity: 1;
    border-color: #000;
    border-color: rgba(0, 0, 0, var(--border-opacity));
  }

  .md\:focus\:tw-border-white:focus {
    --border-opacity: 1;
    border-color: #fff;
    border-color: rgba(255, 255, 255, var(--border-opacity));
  }

  .md\:focus\:tw-border-gray-100:focus {
    --border-opacity: 1;
    border-color: #f7fafc;
    border-color: rgba(247, 250, 252, var(--border-opacity));
  }

  .md\:focus\:tw-border-gray-200:focus {
    --border-opacity: 1;
    border-color: #edf2f7;
    border-color: rgba(237, 242, 247, var(--border-opacity));
  }

  .md\:focus\:tw-border-gray-300:focus {
    --border-opacity: 1;
    border-color: #e2e8f0;
    border-color: rgba(226, 232, 240, var(--border-opacity));
  }

  .md\:focus\:tw-border-gray-400:focus {
    --border-opacity: 1;
    border-color: #cbd5e0;
    border-color: rgba(203, 213, 224, var(--border-opacity));
  }

  .md\:focus\:tw-border-gray-500:focus {
    --border-opacity: 1;
    border-color: #a0aec0;
    border-color: rgba(160, 174, 192, var(--border-opacity));
  }

  .md\:focus\:tw-border-gray-600:focus {
    --border-opacity: 1;
    border-color: #718096;
    border-color: rgba(113, 128, 150, var(--border-opacity));
  }

  .md\:focus\:tw-border-gray-700:focus {
    --border-opacity: 1;
    border-color: #4a5568;
    border-color: rgba(74, 85, 104, var(--border-opacity));
  }

  .md\:focus\:tw-border-gray-800:focus {
    --border-opacity: 1;
    border-color: #2d3748;
    border-color: rgba(45, 55, 72, var(--border-opacity));
  }

  .md\:focus\:tw-border-gray-900:focus {
    --border-opacity: 1;
    border-color: #1a202c;
    border-color: rgba(26, 32, 44, var(--border-opacity));
  }

  .md\:focus\:tw-border-red-100:focus {
    --border-opacity: 1;
    border-color: #fff5f5;
    border-color: rgba(255, 245, 245, var(--border-opacity));
  }

  .md\:focus\:tw-border-red-200:focus {
    --border-opacity: 1;
    border-color: #fed7d7;
    border-color: rgba(254, 215, 215, var(--border-opacity));
  }

  .md\:focus\:tw-border-red-300:focus {
    --border-opacity: 1;
    border-color: #feb2b2;
    border-color: rgba(254, 178, 178, var(--border-opacity));
  }

  .md\:focus\:tw-border-red-400:focus {
    --border-opacity: 1;
    border-color: #fc8181;
    border-color: rgba(252, 129, 129, var(--border-opacity));
  }

  .md\:focus\:tw-border-red-500:focus {
    --border-opacity: 1;
    border-color: #f56565;
    border-color: rgba(245, 101, 101, var(--border-opacity));
  }

  .md\:focus\:tw-border-red-600:focus {
    --border-opacity: 1;
    border-color: #e53e3e;
    border-color: rgba(229, 62, 62, var(--border-opacity));
  }

  .md\:focus\:tw-border-red-700:focus {
    --border-opacity: 1;
    border-color: #c53030;
    border-color: rgba(197, 48, 48, var(--border-opacity));
  }

  .md\:focus\:tw-border-red-800:focus {
    --border-opacity: 1;
    border-color: #9b2c2c;
    border-color: rgba(155, 44, 44, var(--border-opacity));
  }

  .md\:focus\:tw-border-red-900:focus {
    --border-opacity: 1;
    border-color: #742a2a;
    border-color: rgba(116, 42, 42, var(--border-opacity));
  }

  .md\:focus\:tw-border-orange-100:focus {
    --border-opacity: 1;
    border-color: #fffaf0;
    border-color: rgba(255, 250, 240, var(--border-opacity));
  }

  .md\:focus\:tw-border-orange-200:focus {
    --border-opacity: 1;
    border-color: #feebc8;
    border-color: rgba(254, 235, 200, var(--border-opacity));
  }

  .md\:focus\:tw-border-orange-300:focus {
    --border-opacity: 1;
    border-color: #fbd38d;
    border-color: rgba(251, 211, 141, var(--border-opacity));
  }

  .md\:focus\:tw-border-orange-400:focus {
    --border-opacity: 1;
    border-color: #f6ad55;
    border-color: rgba(246, 173, 85, var(--border-opacity));
  }

  .md\:focus\:tw-border-orange-500:focus {
    --border-opacity: 1;
    border-color: #ed8936;
    border-color: rgba(237, 137, 54, var(--border-opacity));
  }

  .md\:focus\:tw-border-orange-600:focus {
    --border-opacity: 1;
    border-color: #dd6b20;
    border-color: rgba(221, 107, 32, var(--border-opacity));
  }

  .md\:focus\:tw-border-orange-700:focus {
    --border-opacity: 1;
    border-color: #c05621;
    border-color: rgba(192, 86, 33, var(--border-opacity));
  }

  .md\:focus\:tw-border-orange-800:focus {
    --border-opacity: 1;
    border-color: #9c4221;
    border-color: rgba(156, 66, 33, var(--border-opacity));
  }

  .md\:focus\:tw-border-orange-900:focus {
    --border-opacity: 1;
    border-color: #7b341e;
    border-color: rgba(123, 52, 30, var(--border-opacity));
  }

  .md\:focus\:tw-border-yellow-100:focus {
    --border-opacity: 1;
    border-color: #fffff0;
    border-color: rgba(255, 255, 240, var(--border-opacity));
  }

  .md\:focus\:tw-border-yellow-200:focus {
    --border-opacity: 1;
    border-color: #fefcbf;
    border-color: rgba(254, 252, 191, var(--border-opacity));
  }

  .md\:focus\:tw-border-yellow-300:focus {
    --border-opacity: 1;
    border-color: #faf089;
    border-color: rgba(250, 240, 137, var(--border-opacity));
  }

  .md\:focus\:tw-border-yellow-400:focus {
    --border-opacity: 1;
    border-color: #f6e05e;
    border-color: rgba(246, 224, 94, var(--border-opacity));
  }

  .md\:focus\:tw-border-yellow-500:focus {
    --border-opacity: 1;
    border-color: #ecc94b;
    border-color: rgba(236, 201, 75, var(--border-opacity));
  }

  .md\:focus\:tw-border-yellow-600:focus {
    --border-opacity: 1;
    border-color: #d69e2e;
    border-color: rgba(214, 158, 46, var(--border-opacity));
  }

  .md\:focus\:tw-border-yellow-700:focus {
    --border-opacity: 1;
    border-color: #b7791f;
    border-color: rgba(183, 121, 31, var(--border-opacity));
  }

  .md\:focus\:tw-border-yellow-800:focus {
    --border-opacity: 1;
    border-color: #975a16;
    border-color: rgba(151, 90, 22, var(--border-opacity));
  }

  .md\:focus\:tw-border-yellow-900:focus {
    --border-opacity: 1;
    border-color: #744210;
    border-color: rgba(116, 66, 16, var(--border-opacity));
  }

  .md\:focus\:tw-border-green-100:focus {
    --border-opacity: 1;
    border-color: #f0fff4;
    border-color: rgba(240, 255, 244, var(--border-opacity));
  }

  .md\:focus\:tw-border-green-200:focus {
    --border-opacity: 1;
    border-color: #c6f6d5;
    border-color: rgba(198, 246, 213, var(--border-opacity));
  }

  .md\:focus\:tw-border-green-300:focus {
    --border-opacity: 1;
    border-color: #9ae6b4;
    border-color: rgba(154, 230, 180, var(--border-opacity));
  }

  .md\:focus\:tw-border-green-400:focus {
    --border-opacity: 1;
    border-color: #68d391;
    border-color: rgba(104, 211, 145, var(--border-opacity));
  }

  .md\:focus\:tw-border-green-500:focus {
    --border-opacity: 1;
    border-color: #48bb78;
    border-color: rgba(72, 187, 120, var(--border-opacity));
  }

  .md\:focus\:tw-border-green-600:focus {
    --border-opacity: 1;
    border-color: #38a169;
    border-color: rgba(56, 161, 105, var(--border-opacity));
  }

  .md\:focus\:tw-border-green-700:focus {
    --border-opacity: 1;
    border-color: #2f855a;
    border-color: rgba(47, 133, 90, var(--border-opacity));
  }

  .md\:focus\:tw-border-green-800:focus {
    --border-opacity: 1;
    border-color: #276749;
    border-color: rgba(39, 103, 73, var(--border-opacity));
  }

  .md\:focus\:tw-border-green-900:focus {
    --border-opacity: 1;
    border-color: #22543d;
    border-color: rgba(34, 84, 61, var(--border-opacity));
  }

  .md\:focus\:tw-border-teal-100:focus {
    --border-opacity: 1;
    border-color: #e6fffa;
    border-color: rgba(230, 255, 250, var(--border-opacity));
  }

  .md\:focus\:tw-border-teal-200:focus {
    --border-opacity: 1;
    border-color: #b2f5ea;
    border-color: rgba(178, 245, 234, var(--border-opacity));
  }

  .md\:focus\:tw-border-teal-300:focus {
    --border-opacity: 1;
    border-color: #81e6d9;
    border-color: rgba(129, 230, 217, var(--border-opacity));
  }

  .md\:focus\:tw-border-teal-400:focus {
    --border-opacity: 1;
    border-color: #4fd1c5;
    border-color: rgba(79, 209, 197, var(--border-opacity));
  }

  .md\:focus\:tw-border-teal-500:focus {
    --border-opacity: 1;
    border-color: #38b2ac;
    border-color: rgba(56, 178, 172, var(--border-opacity));
  }

  .md\:focus\:tw-border-teal-600:focus {
    --border-opacity: 1;
    border-color: #319795;
    border-color: rgba(49, 151, 149, var(--border-opacity));
  }

  .md\:focus\:tw-border-teal-700:focus {
    --border-opacity: 1;
    border-color: #2c7a7b;
    border-color: rgba(44, 122, 123, var(--border-opacity));
  }

  .md\:focus\:tw-border-teal-800:focus {
    --border-opacity: 1;
    border-color: #285e61;
    border-color: rgba(40, 94, 97, var(--border-opacity));
  }

  .md\:focus\:tw-border-teal-900:focus {
    --border-opacity: 1;
    border-color: #234e52;
    border-color: rgba(35, 78, 82, var(--border-opacity));
  }

  .md\:focus\:tw-border-blue-100:focus {
    --border-opacity: 1;
    border-color: #ebf8ff;
    border-color: rgba(235, 248, 255, var(--border-opacity));
  }

  .md\:focus\:tw-border-blue-200:focus {
    --border-opacity: 1;
    border-color: #bee3f8;
    border-color: rgba(190, 227, 248, var(--border-opacity));
  }

  .md\:focus\:tw-border-blue-300:focus {
    --border-opacity: 1;
    border-color: #90cdf4;
    border-color: rgba(144, 205, 244, var(--border-opacity));
  }

  .md\:focus\:tw-border-blue-400:focus {
    --border-opacity: 1;
    border-color: #63b3ed;
    border-color: rgba(99, 179, 237, var(--border-opacity));
  }

  .md\:focus\:tw-border-blue-500:focus {
    --border-opacity: 1;
    border-color: #4299e1;
    border-color: rgba(66, 153, 225, var(--border-opacity));
  }

  .md\:focus\:tw-border-blue-600:focus {
    --border-opacity: 1;
    border-color: #3182ce;
    border-color: rgba(49, 130, 206, var(--border-opacity));
  }

  .md\:focus\:tw-border-blue-700:focus {
    --border-opacity: 1;
    border-color: #2b6cb0;
    border-color: rgba(43, 108, 176, var(--border-opacity));
  }

  .md\:focus\:tw-border-blue-800:focus {
    --border-opacity: 1;
    border-color: #2c5282;
    border-color: rgba(44, 82, 130, var(--border-opacity));
  }

  .md\:focus\:tw-border-blue-900:focus {
    --border-opacity: 1;
    border-color: #2a4365;
    border-color: rgba(42, 67, 101, var(--border-opacity));
  }

  .md\:focus\:tw-border-indigo-100:focus {
    --border-opacity: 1;
    border-color: #ebf4ff;
    border-color: rgba(235, 244, 255, var(--border-opacity));
  }

  .md\:focus\:tw-border-indigo-200:focus {
    --border-opacity: 1;
    border-color: #c3dafe;
    border-color: rgba(195, 218, 254, var(--border-opacity));
  }

  .md\:focus\:tw-border-indigo-300:focus {
    --border-opacity: 1;
    border-color: #a3bffa;
    border-color: rgba(163, 191, 250, var(--border-opacity));
  }

  .md\:focus\:tw-border-indigo-400:focus {
    --border-opacity: 1;
    border-color: #7f9cf5;
    border-color: rgba(127, 156, 245, var(--border-opacity));
  }

  .md\:focus\:tw-border-indigo-500:focus {
    --border-opacity: 1;
    border-color: #667eea;
    border-color: rgba(102, 126, 234, var(--border-opacity));
  }

  .md\:focus\:tw-border-indigo-600:focus {
    --border-opacity: 1;
    border-color: #5a67d8;
    border-color: rgba(90, 103, 216, var(--border-opacity));
  }

  .md\:focus\:tw-border-indigo-700:focus {
    --border-opacity: 1;
    border-color: #4c51bf;
    border-color: rgba(76, 81, 191, var(--border-opacity));
  }

  .md\:focus\:tw-border-indigo-800:focus {
    --border-opacity: 1;
    border-color: #434190;
    border-color: rgba(67, 65, 144, var(--border-opacity));
  }

  .md\:focus\:tw-border-indigo-900:focus {
    --border-opacity: 1;
    border-color: #3c366b;
    border-color: rgba(60, 54, 107, var(--border-opacity));
  }

  .md\:focus\:tw-border-purple-100:focus {
    --border-opacity: 1;
    border-color: #faf5ff;
    border-color: rgba(250, 245, 255, var(--border-opacity));
  }

  .md\:focus\:tw-border-purple-200:focus {
    --border-opacity: 1;
    border-color: #e9d8fd;
    border-color: rgba(233, 216, 253, var(--border-opacity));
  }

  .md\:focus\:tw-border-purple-300:focus {
    --border-opacity: 1;
    border-color: #d6bcfa;
    border-color: rgba(214, 188, 250, var(--border-opacity));
  }

  .md\:focus\:tw-border-purple-400:focus {
    --border-opacity: 1;
    border-color: #b794f4;
    border-color: rgba(183, 148, 244, var(--border-opacity));
  }

  .md\:focus\:tw-border-purple-500:focus {
    --border-opacity: 1;
    border-color: #9f7aea;
    border-color: rgba(159, 122, 234, var(--border-opacity));
  }

  .md\:focus\:tw-border-purple-600:focus {
    --border-opacity: 1;
    border-color: #805ad5;
    border-color: rgba(128, 90, 213, var(--border-opacity));
  }

  .md\:focus\:tw-border-purple-700:focus {
    --border-opacity: 1;
    border-color: #6b46c1;
    border-color: rgba(107, 70, 193, var(--border-opacity));
  }

  .md\:focus\:tw-border-purple-800:focus {
    --border-opacity: 1;
    border-color: #553c9a;
    border-color: rgba(85, 60, 154, var(--border-opacity));
  }

  .md\:focus\:tw-border-purple-900:focus {
    --border-opacity: 1;
    border-color: #44337a;
    border-color: rgba(68, 51, 122, var(--border-opacity));
  }

  .md\:focus\:tw-border-pink-100:focus {
    --border-opacity: 1;
    border-color: #fff5f7;
    border-color: rgba(255, 245, 247, var(--border-opacity));
  }

  .md\:focus\:tw-border-pink-200:focus {
    --border-opacity: 1;
    border-color: #fed7e2;
    border-color: rgba(254, 215, 226, var(--border-opacity));
  }

  .md\:focus\:tw-border-pink-300:focus {
    --border-opacity: 1;
    border-color: #fbb6ce;
    border-color: rgba(251, 182, 206, var(--border-opacity));
  }

  .md\:focus\:tw-border-pink-400:focus {
    --border-opacity: 1;
    border-color: #f687b3;
    border-color: rgba(246, 135, 179, var(--border-opacity));
  }

  .md\:focus\:tw-border-pink-500:focus {
    --border-opacity: 1;
    border-color: #ed64a6;
    border-color: rgba(237, 100, 166, var(--border-opacity));
  }

  .md\:focus\:tw-border-pink-600:focus {
    --border-opacity: 1;
    border-color: #d53f8c;
    border-color: rgba(213, 63, 140, var(--border-opacity));
  }

  .md\:focus\:tw-border-pink-700:focus {
    --border-opacity: 1;
    border-color: #b83280;
    border-color: rgba(184, 50, 128, var(--border-opacity));
  }

  .md\:focus\:tw-border-pink-800:focus {
    --border-opacity: 1;
    border-color: #97266d;
    border-color: rgba(151, 38, 109, var(--border-opacity));
  }

  .md\:focus\:tw-border-pink-900:focus {
    --border-opacity: 1;
    border-color: #702459;
    border-color: rgba(112, 36, 89, var(--border-opacity));
  }

  .md\:tw-border-opacity-0 {
    --border-opacity: 0;
  }

  .md\:tw-border-opacity-25 {
    --border-opacity: 0.25;
  }

  .md\:tw-border-opacity-50 {
    --border-opacity: 0.5;
  }

  .md\:tw-border-opacity-75 {
    --border-opacity: 0.75;
  }

  .md\:tw-border-opacity-100 {
    --border-opacity: 1;
  }

  .md\:hover\:tw-border-opacity-0:hover {
    --border-opacity: 0;
  }

  .md\:hover\:tw-border-opacity-25:hover {
    --border-opacity: 0.25;
  }

  .md\:hover\:tw-border-opacity-50:hover {
    --border-opacity: 0.5;
  }

  .md\:hover\:tw-border-opacity-75:hover {
    --border-opacity: 0.75;
  }

  .md\:hover\:tw-border-opacity-100:hover {
    --border-opacity: 1;
  }

  .md\:focus\:tw-border-opacity-0:focus {
    --border-opacity: 0;
  }

  .md\:focus\:tw-border-opacity-25:focus {
    --border-opacity: 0.25;
  }

  .md\:focus\:tw-border-opacity-50:focus {
    --border-opacity: 0.5;
  }

  .md\:focus\:tw-border-opacity-75:focus {
    --border-opacity: 0.75;
  }

  .md\:focus\:tw-border-opacity-100:focus {
    --border-opacity: 1;
  }

  .md\:tw-rounded-none {
    border-radius: 0;
  }

  .md\:tw-rounded-sm {
    border-radius: 0.125rem;
  }

  .md\:tw-rounded {
    border-radius: 0.25rem;
  }

  .md\:tw-rounded-md {
    border-radius: 0.375rem;
  }

  .md\:tw-rounded-lg {
    border-radius: 0.5rem;
  }

  .md\:tw-rounded-xl {
    border-radius: 0.75rem;
  }

  .md\:tw-rounded-2xl {
    border-radius: 1rem;
  }

  .md\:tw-rounded-3xl {
    border-radius: 1.5rem;
  }

  .md\:tw-rounded-full {
    border-radius: 9999px;
  }

  .md\:tw-rounded-t-none {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }

  .md\:tw-rounded-r-none {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  .md\:tw-rounded-b-none {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
  }

  .md\:tw-rounded-l-none {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }

  .md\:tw-rounded-t-sm {
    border-top-left-radius: 0.125rem;
    border-top-right-radius: 0.125rem;
  }

  .md\:tw-rounded-r-sm {
    border-top-right-radius: 0.125rem;
    border-bottom-right-radius: 0.125rem;
  }

  .md\:tw-rounded-b-sm {
    border-bottom-right-radius: 0.125rem;
    border-bottom-left-radius: 0.125rem;
  }

  .md\:tw-rounded-l-sm {
    border-top-left-radius: 0.125rem;
    border-bottom-left-radius: 0.125rem;
  }

  .md\:tw-rounded-t {
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem;
  }

  .md\:tw-rounded-r {
    border-top-right-radius: 0.25rem;
    border-bottom-right-radius: 0.25rem;
  }

  .md\:tw-rounded-b {
    border-bottom-right-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem;
  }

  .md\:tw-rounded-l {
    border-top-left-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem;
  }

  .md\:tw-rounded-t-md {
    border-top-left-radius: 0.375rem;
    border-top-right-radius: 0.375rem;
  }

  .md\:tw-rounded-r-md {
    border-top-right-radius: 0.375rem;
    border-bottom-right-radius: 0.375rem;
  }

  .md\:tw-rounded-b-md {
    border-bottom-right-radius: 0.375rem;
    border-bottom-left-radius: 0.375rem;
  }

  .md\:tw-rounded-l-md {
    border-top-left-radius: 0.375rem;
    border-bottom-left-radius: 0.375rem;
  }

  .md\:tw-rounded-t-lg {
    border-top-left-radius: 0.5rem;
    border-top-right-radius: 0.5rem;
  }

  .md\:tw-rounded-r-lg {
    border-top-right-radius: 0.5rem;
    border-bottom-right-radius: 0.5rem;
  }

  .md\:tw-rounded-b-lg {
    border-bottom-right-radius: 0.5rem;
    border-bottom-left-radius: 0.5rem;
  }

  .md\:tw-rounded-l-lg {
    border-top-left-radius: 0.5rem;
    border-bottom-left-radius: 0.5rem;
  }

  .md\:tw-rounded-t-xl {
    border-top-left-radius: 0.75rem;
    border-top-right-radius: 0.75rem;
  }

  .md\:tw-rounded-r-xl {
    border-top-right-radius: 0.75rem;
    border-bottom-right-radius: 0.75rem;
  }

  .md\:tw-rounded-b-xl {
    border-bottom-right-radius: 0.75rem;
    border-bottom-left-radius: 0.75rem;
  }

  .md\:tw-rounded-l-xl {
    border-top-left-radius: 0.75rem;
    border-bottom-left-radius: 0.75rem;
  }

  .md\:tw-rounded-t-2xl {
    border-top-left-radius: 1rem;
    border-top-right-radius: 1rem;
  }

  .md\:tw-rounded-r-2xl {
    border-top-right-radius: 1rem;
    border-bottom-right-radius: 1rem;
  }

  .md\:tw-rounded-b-2xl {
    border-bottom-right-radius: 1rem;
    border-bottom-left-radius: 1rem;
  }

  .md\:tw-rounded-l-2xl {
    border-top-left-radius: 1rem;
    border-bottom-left-radius: 1rem;
  }

  .md\:tw-rounded-t-3xl {
    border-top-left-radius: 1.5rem;
    border-top-right-radius: 1.5rem;
  }

  .md\:tw-rounded-r-3xl {
    border-top-right-radius: 1.5rem;
    border-bottom-right-radius: 1.5rem;
  }

  .md\:tw-rounded-b-3xl {
    border-bottom-right-radius: 1.5rem;
    border-bottom-left-radius: 1.5rem;
  }

  .md\:tw-rounded-l-3xl {
    border-top-left-radius: 1.5rem;
    border-bottom-left-radius: 1.5rem;
  }

  .md\:tw-rounded-t-full {
    border-top-left-radius: 9999px;
    border-top-right-radius: 9999px;
  }

  .md\:tw-rounded-r-full {
    border-top-right-radius: 9999px;
    border-bottom-right-radius: 9999px;
  }

  .md\:tw-rounded-b-full {
    border-bottom-right-radius: 9999px;
    border-bottom-left-radius: 9999px;
  }

  .md\:tw-rounded-l-full {
    border-top-left-radius: 9999px;
    border-bottom-left-radius: 9999px;
  }

  .md\:tw-rounded-tl-none {
    border-top-left-radius: 0;
  }

  .md\:tw-rounded-tr-none {
    border-top-right-radius: 0;
  }

  .md\:tw-rounded-br-none {
    border-bottom-right-radius: 0;
  }

  .md\:tw-rounded-bl-none {
    border-bottom-left-radius: 0;
  }

  .md\:tw-rounded-tl-sm {
    border-top-left-radius: 0.125rem;
  }

  .md\:tw-rounded-tr-sm {
    border-top-right-radius: 0.125rem;
  }

  .md\:tw-rounded-br-sm {
    border-bottom-right-radius: 0.125rem;
  }

  .md\:tw-rounded-bl-sm {
    border-bottom-left-radius: 0.125rem;
  }

  .md\:tw-rounded-tl {
    border-top-left-radius: 0.25rem;
  }

  .md\:tw-rounded-tr {
    border-top-right-radius: 0.25rem;
  }

  .md\:tw-rounded-br {
    border-bottom-right-radius: 0.25rem;
  }

  .md\:tw-rounded-bl {
    border-bottom-left-radius: 0.25rem;
  }

  .md\:tw-rounded-tl-md {
    border-top-left-radius: 0.375rem;
  }

  .md\:tw-rounded-tr-md {
    border-top-right-radius: 0.375rem;
  }

  .md\:tw-rounded-br-md {
    border-bottom-right-radius: 0.375rem;
  }

  .md\:tw-rounded-bl-md {
    border-bottom-left-radius: 0.375rem;
  }

  .md\:tw-rounded-tl-lg {
    border-top-left-radius: 0.5rem;
  }

  .md\:tw-rounded-tr-lg {
    border-top-right-radius: 0.5rem;
  }

  .md\:tw-rounded-br-lg {
    border-bottom-right-radius: 0.5rem;
  }

  .md\:tw-rounded-bl-lg {
    border-bottom-left-radius: 0.5rem;
  }

  .md\:tw-rounded-tl-xl {
    border-top-left-radius: 0.75rem;
  }

  .md\:tw-rounded-tr-xl {
    border-top-right-radius: 0.75rem;
  }

  .md\:tw-rounded-br-xl {
    border-bottom-right-radius: 0.75rem;
  }

  .md\:tw-rounded-bl-xl {
    border-bottom-left-radius: 0.75rem;
  }

  .md\:tw-rounded-tl-2xl {
    border-top-left-radius: 1rem;
  }

  .md\:tw-rounded-tr-2xl {
    border-top-right-radius: 1rem;
  }

  .md\:tw-rounded-br-2xl {
    border-bottom-right-radius: 1rem;
  }

  .md\:tw-rounded-bl-2xl {
    border-bottom-left-radius: 1rem;
  }

  .md\:tw-rounded-tl-3xl {
    border-top-left-radius: 1.5rem;
  }

  .md\:tw-rounded-tr-3xl {
    border-top-right-radius: 1.5rem;
  }

  .md\:tw-rounded-br-3xl {
    border-bottom-right-radius: 1.5rem;
  }

  .md\:tw-rounded-bl-3xl {
    border-bottom-left-radius: 1.5rem;
  }

  .md\:tw-rounded-tl-full {
    border-top-left-radius: 9999px;
  }

  .md\:tw-rounded-tr-full {
    border-top-right-radius: 9999px;
  }

  .md\:tw-rounded-br-full {
    border-bottom-right-radius: 9999px;
  }

  .md\:tw-rounded-bl-full {
    border-bottom-left-radius: 9999px;
  }

  .md\:tw-border-solid {
    border-style: solid;
  }

  .md\:tw-border-dashed {
    border-style: dashed;
  }

  .md\:tw-border-dotted {
    border-style: dotted;
  }

  .md\:tw-border-double {
    border-style: double;
  }

  .md\:tw-border-none {
    border-style: none;
  }

  .md\:tw-border-0 {
    border-width: 0;
  }

  .md\:tw-border-2 {
    border-width: 2px;
  }

  .md\:tw-border-4 {
    border-width: 4px;
  }

  .md\:tw-border-8 {
    border-width: 8px;
  }

  .md\:tw-border {
    border-width: 1px;
  }

  .md\:tw-border-t-0 {
    border-top-width: 0;
  }

  .md\:tw-border-r-0 {
    border-right-width: 0;
  }

  .md\:tw-border-b-0 {
    border-bottom-width: 0;
  }

  .md\:tw-border-l-0 {
    border-left-width: 0;
  }

  .md\:tw-border-t-2 {
    border-top-width: 2px;
  }

  .md\:tw-border-r-2 {
    border-right-width: 2px;
  }

  .md\:tw-border-b-2 {
    border-bottom-width: 2px;
  }

  .md\:tw-border-l-2 {
    border-left-width: 2px;
  }

  .md\:tw-border-t-4 {
    border-top-width: 4px;
  }

  .md\:tw-border-r-4 {
    border-right-width: 4px;
  }

  .md\:tw-border-b-4 {
    border-bottom-width: 4px;
  }

  .md\:tw-border-l-4 {
    border-left-width: 4px;
  }

  .md\:tw-border-t-8 {
    border-top-width: 8px;
  }

  .md\:tw-border-r-8 {
    border-right-width: 8px;
  }

  .md\:tw-border-b-8 {
    border-bottom-width: 8px;
  }

  .md\:tw-border-l-8 {
    border-left-width: 8px;
  }

  .md\:tw-border-t {
    border-top-width: 1px;
  }

  .md\:tw-border-r {
    border-right-width: 1px;
  }

  .md\:tw-border-b {
    border-bottom-width: 1px;
  }

  .md\:tw-border-l {
    border-left-width: 1px;
  }

  .md\:tw-box-border {
    box-sizing: border-box;
  }

  .md\:tw-box-content {
    box-sizing: content-box;
  }

  .md\:tw-cursor-auto {
    cursor: auto;
  }

  .md\:tw-cursor-default {
    cursor: default;
  }

  .md\:tw-cursor-pointer {
    cursor: pointer;
  }

  .md\:tw-cursor-wait {
    cursor: wait;
  }

  .md\:tw-cursor-text {
    cursor: text;
  }

  .md\:tw-cursor-move {
    cursor: move;
  }

  .md\:tw-cursor-not-allowed {
    cursor: not-allowed;
  }

  .md\:tw-block {
    display: block;
  }

  .md\:tw-inline-block {
    display: inline-block;
  }

  .md\:tw-inline {
    display: inline;
  }

  .md\:tw-flex {
    display: flex;
  }

  .md\:tw-inline-flex {
    display: inline-flex;
  }

  .md\:tw-table {
    display: table;
  }

  .md\:tw-table-caption {
    display: table-caption;
  }

  .md\:tw-table-cell {
    display: table-cell;
  }

  .md\:tw-table-column {
    display: table-column;
  }

  .md\:tw-table-column-group {
    display: table-column-group;
  }

  .md\:tw-table-footer-group {
    display: table-footer-group;
  }

  .md\:tw-table-header-group {
    display: table-header-group;
  }

  .md\:tw-table-row-group {
    display: table-row-group;
  }

  .md\:tw-table-row {
    display: table-row;
  }

  .md\:tw-flow-root {
    display: flow-root;
  }

  .md\:tw-grid {
    display: grid;
  }

  .md\:tw-inline-grid {
    display: inline-grid;
  }

  .md\:tw-contents {
    display: contents;
  }

  .md\:tw-hidden {
    display: none;
  }

  .md\:tw-flex-row {
    flex-direction: row;
  }

  .md\:tw-flex-row-reverse {
    flex-direction: row-reverse;
  }

  .md\:tw-flex-col {
    flex-direction: column;
  }

  .md\:tw-flex-col-reverse {
    flex-direction: column-reverse;
  }

  .md\:tw-flex-wrap {
    flex-wrap: wrap;
  }

  .md\:tw-flex-wrap-reverse {
    flex-wrap: wrap-reverse;
  }

  .md\:tw-flex-no-wrap {
    flex-wrap: nowrap;
  }

  .md\:tw-place-items-auto {
    place-items: auto;
  }

  .md\:tw-place-items-start {
    place-items: start;
  }

  .md\:tw-place-items-end {
    place-items: end;
  }

  .md\:tw-place-items-center {
    place-items: center;
  }

  .md\:tw-place-items-stretch {
    place-items: stretch;
  }

  .md\:tw-place-content-center {
    place-content: center;
  }

  .md\:tw-place-content-start {
    place-content: start;
  }

  .md\:tw-place-content-end {
    place-content: end;
  }

  .md\:tw-place-content-between {
    place-content: space-between;
  }

  .md\:tw-place-content-around {
    place-content: space-around;
  }

  .md\:tw-place-content-evenly {
    place-content: space-evenly;
  }

  .md\:tw-place-content-stretch {
    place-content: stretch;
  }

  .md\:tw-place-self-auto {
    place-self: auto;
  }

  .md\:tw-place-self-start {
    place-self: start;
  }

  .md\:tw-place-self-end {
    place-self: end;
  }

  .md\:tw-place-self-center {
    place-self: center;
  }

  .md\:tw-place-self-stretch {
    place-self: stretch;
  }

  .md\:tw-items-start {
    align-items: flex-start;
  }

  .md\:tw-items-end {
    align-items: flex-end;
  }

  .md\:tw-items-center {
    align-items: center;
  }

  .md\:tw-items-baseline {
    align-items: baseline;
  }

  .md\:tw-items-stretch {
    align-items: stretch;
  }

  .md\:tw-content-center {
    align-content: center;
  }

  .md\:tw-content-start {
    align-content: flex-start;
  }

  .md\:tw-content-end {
    align-content: flex-end;
  }

  .md\:tw-content-between {
    align-content: space-between;
  }

  .md\:tw-content-around {
    align-content: space-around;
  }

  .md\:tw-content-evenly {
    align-content: space-evenly;
  }

  .md\:tw-self-auto {
    align-self: auto;
  }

  .md\:tw-self-start {
    align-self: flex-start;
  }

  .md\:tw-self-end {
    align-self: flex-end;
  }

  .md\:tw-self-center {
    align-self: center;
  }

  .md\:tw-self-stretch {
    align-self: stretch;
  }

  .md\:tw-justify-items-auto {
    justify-items: auto;
  }

  .md\:tw-justify-items-start {
    justify-items: start;
  }

  .md\:tw-justify-items-end {
    justify-items: end;
  }

  .md\:tw-justify-items-center {
    justify-items: center;
  }

  .md\:tw-justify-items-stretch {
    justify-items: stretch;
  }

  .md\:tw-justify-start {
    justify-content: flex-start;
  }

  .md\:tw-justify-end {
    justify-content: flex-end;
  }

  .md\:tw-justify-center {
    justify-content: center;
  }

  .md\:tw-justify-between {
    justify-content: space-between;
  }

  .md\:tw-justify-around {
    justify-content: space-around;
  }

  .md\:tw-justify-evenly {
    justify-content: space-evenly;
  }

  .md\:tw-justify-self-auto {
    justify-self: auto;
  }

  .md\:tw-justify-self-start {
    justify-self: start;
  }

  .md\:tw-justify-self-end {
    justify-self: end;
  }

  .md\:tw-justify-self-center {
    justify-self: center;
  }

  .md\:tw-justify-self-stretch {
    justify-self: stretch;
  }

  .md\:tw-flex-1 {
    flex: 1 1 0%;
  }

  .md\:tw-flex-auto {
    flex: 1 1 auto;
  }

  .md\:tw-flex-initial {
    flex: 0 1 auto;
  }

  .md\:tw-flex-none {
    flex: none;
  }

  .md\:tw-flex-grow-0 {
    flex-grow: 0;
  }

  .md\:tw-flex-grow {
    flex-grow: 1;
  }

  .md\:tw-flex-shrink-0 {
    flex-shrink: 0;
  }

  .md\:tw-flex-shrink {
    flex-shrink: 1;
  }

  .md\:tw-order-1 {
    order: 1;
  }

  .md\:tw-order-2 {
    order: 2;
  }

  .md\:tw-order-3 {
    order: 3;
  }

  .md\:tw-order-4 {
    order: 4;
  }

  .md\:tw-order-5 {
    order: 5;
  }

  .md\:tw-order-6 {
    order: 6;
  }

  .md\:tw-order-7 {
    order: 7;
  }

  .md\:tw-order-8 {
    order: 8;
  }

  .md\:tw-order-9 {
    order: 9;
  }

  .md\:tw-order-10 {
    order: 10;
  }

  .md\:tw-order-11 {
    order: 11;
  }

  .md\:tw-order-12 {
    order: 12;
  }

  .md\:tw-order-first {
    order: -9999;
  }

  .md\:tw-order-last {
    order: 9999;
  }

  .md\:tw-order-none {
    order: 0;
  }

  .md\:tw-float-right {
    float: right;
  }

  .md\:tw-float-left {
    float: left;
  }

  .md\:tw-float-none {
    float: none;
  }

  .md\:tw-clearfix:after {
    content: "";
    display: table;
    clear: both;
  }

  .md\:tw-clear-left {
    clear: left;
  }

  .md\:tw-clear-right {
    clear: right;
  }

  .md\:tw-clear-both {
    clear: both;
  }

  .md\:tw-clear-none {
    clear: none;
  }

  .md\:tw-font-sans {
    font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  }

  .md\:tw-font-serif {
    font-family: Georgia, Cambria, "Times New Roman", Times, serif;
  }

  .md\:tw-font-mono {
    font-family: Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  }

  .md\:tw-font-hairline {
    font-weight: 100;
  }

  .md\:tw-font-thin {
    font-weight: 200;
  }

  .md\:tw-font-light {
    font-weight: 300;
  }

  .md\:tw-font-normal {
    font-weight: 400;
  }

  .md\:tw-font-medium {
    font-weight: 500;
  }

  .md\:tw-font-semibold {
    font-weight: 600;
  }

  .md\:tw-font-bold {
    font-weight: 700;
  }

  .md\:tw-font-extrabold {
    font-weight: 800;
  }

  .md\:tw-font-black {
    font-weight: 900;
  }

  .md\:hover\:tw-font-hairline:hover {
    font-weight: 100;
  }

  .md\:hover\:tw-font-thin:hover {
    font-weight: 200;
  }

  .md\:hover\:tw-font-light:hover {
    font-weight: 300;
  }

  .md\:hover\:tw-font-normal:hover {
    font-weight: 400;
  }

  .md\:hover\:tw-font-medium:hover {
    font-weight: 500;
  }

  .md\:hover\:tw-font-semibold:hover {
    font-weight: 600;
  }

  .md\:hover\:tw-font-bold:hover {
    font-weight: 700;
  }

  .md\:hover\:tw-font-extrabold:hover {
    font-weight: 800;
  }

  .md\:hover\:tw-font-black:hover {
    font-weight: 900;
  }

  .md\:focus\:tw-font-hairline:focus {
    font-weight: 100;
  }

  .md\:focus\:tw-font-thin:focus {
    font-weight: 200;
  }

  .md\:focus\:tw-font-light:focus {
    font-weight: 300;
  }

  .md\:focus\:tw-font-normal:focus {
    font-weight: 400;
  }

  .md\:focus\:tw-font-medium:focus {
    font-weight: 500;
  }

  .md\:focus\:tw-font-semibold:focus {
    font-weight: 600;
  }

  .md\:focus\:tw-font-bold:focus {
    font-weight: 700;
  }

  .md\:focus\:tw-font-extrabold:focus {
    font-weight: 800;
  }

  .md\:focus\:tw-font-black:focus {
    font-weight: 900;
  }

  .md\:tw-h-0 {
    height: 0;
  }

  .md\:tw-h-1 {
    height: 0.25rem;
  }

  .md\:tw-h-2 {
    height: 0.5rem;
  }

  .md\:tw-h-3 {
    height: 0.75rem;
  }

  .md\:tw-h-4 {
    height: 1rem;
  }

  .md\:tw-h-5 {
    height: 1.25rem;
  }

  .md\:tw-h-6 {
    height: 1.5rem;
  }

  .md\:tw-h-8 {
    height: 2rem;
  }

  .md\:tw-h-10 {
    height: 2.5rem;
  }

  .md\:tw-h-12 {
    height: 3rem;
  }

  .md\:tw-h-16 {
    height: 4rem;
  }

  .md\:tw-h-20 {
    height: 5rem;
  }

  .md\:tw-h-24 {
    height: 6rem;
  }

  .md\:tw-h-32 {
    height: 8rem;
  }

  .md\:tw-h-40 {
    height: 10rem;
  }

  .md\:tw-h-48 {
    height: 12rem;
  }

  .md\:tw-h-56 {
    height: 14rem;
  }

  .md\:tw-h-64 {
    height: 16rem;
  }

  .md\:tw-h-auto {
    height: auto;
  }

  .md\:tw-h-px {
    height: 1px;
  }

  .md\:tw-h-270px {
    height: 270px;
  }

  .md\:tw-h-400px {
    height: 400px;
  }

  .md\:tw-h-464px {
    height: 464px;
  }

  .md\:tw-h-480px {
    height: 480px;
  }

  .md\:tw-h-500px {
    height: 500px;
  }

  .md\:tw-h-576px {
    height: 576px;
  }

  .md\:tw-h-600px {
    height: 600px;
  }

  .md\:tw-h-672px {
    height: 672px;
  }

  .md\:tw-h-full {
    height: 100%;
  }

  .md\:tw-h-screen {
    height: 100vh;
  }

  .md\:tw-text-xs {
    font-size: 0.75rem;
  }

  .md\:tw-text-sm {
    font-size: 0.875rem;
  }

  .md\:tw-text-base {
    font-size: 1rem;
  }

  .md\:tw-text-lg {
    font-size: 1.125rem;
  }

  .md\:tw-text-xl {
    font-size: 1.25rem;
  }

  .md\:tw-text-2xl {
    font-size: 1.5rem;
  }

  .md\:tw-text-3xl {
    font-size: 1.875rem;
  }

  .md\:tw-text-4xl {
    font-size: 2.25rem;
  }

  .md\:tw-text-5xl {
    font-size: 3rem;
  }

  .md\:tw-text-6xl {
    font-size: 4rem;
  }

  .md\:tw-leading-3 {
    line-height: .75rem;
  }

  .md\:tw-leading-4 {
    line-height: 1rem;
  }

  .md\:tw-leading-5 {
    line-height: 1.25rem;
  }

  .md\:tw-leading-6 {
    line-height: 1.5rem;
  }

  .md\:tw-leading-7 {
    line-height: 1.75rem;
  }

  .md\:tw-leading-8 {
    line-height: 2rem;
  }

  .md\:tw-leading-9 {
    line-height: 2.25rem;
  }

  .md\:tw-leading-10 {
    line-height: 2.5rem;
  }

  .md\:tw-leading-none {
    line-height: 1;
  }

  .md\:tw-leading-tight {
    line-height: 1.25;
  }

  .md\:tw-leading-snug {
    line-height: 1.375;
  }

  .md\:tw-leading-normal {
    line-height: 1.5;
  }

  .md\:tw-leading-relaxed {
    line-height: 1.625;
  }

  .md\:tw-leading-loose {
    line-height: 2;
  }

  .md\:tw-list-inside {
    list-style-position: inside;
  }

  .md\:tw-list-outside {
    list-style-position: outside;
  }

  .md\:tw-list-none {
    list-style-type: none;
  }

  .md\:tw-list-disc {
    list-style-type: disc;
  }

  .md\:tw-list-decimal {
    list-style-type: decimal;
  }

  .md\:tw-m-0 {
    margin: 0;
  }

  .md\:tw-m-1 {
    margin: 0.25rem;
  }

  .md\:tw-m-2 {
    margin: 0.5rem;
  }

  .md\:tw-m-3 {
    margin: 0.75rem;
  }

  .md\:tw-m-4 {
    margin: 1rem;
  }

  .md\:tw-m-5 {
    margin: 1.25rem;
  }

  .md\:tw-m-6 {
    margin: 1.5rem;
  }

  .md\:tw-m-8 {
    margin: 2rem;
  }

  .md\:tw-m-10 {
    margin: 2.5rem;
  }

  .md\:tw-m-12 {
    margin: 3rem;
  }

  .md\:tw-m-16 {
    margin: 4rem;
  }

  .md\:tw-m-20 {
    margin: 5rem;
  }

  .md\:tw-m-24 {
    margin: 6rem;
  }

  .md\:tw-m-32 {
    margin: 8rem;
  }

  .md\:tw-m-40 {
    margin: 10rem;
  }

  .md\:tw-m-48 {
    margin: 12rem;
  }

  .md\:tw-m-56 {
    margin: 14rem;
  }

  .md\:tw-m-64 {
    margin: 16rem;
  }

  .md\:tw-m-auto {
    margin: auto;
  }

  .md\:tw-m-px {
    margin: 1px;
  }

  .md\:tw-m-270px {
    margin: 270px;
  }

  .md\:tw-m-400px {
    margin: 400px;
  }

  .md\:tw-m-464px {
    margin: 464px;
  }

  .md\:tw-m-480px {
    margin: 480px;
  }

  .md\:tw-m-500px {
    margin: 500px;
  }

  .md\:tw-m-576px {
    margin: 576px;
  }

  .md\:tw-m-600px {
    margin: 600px;
  }

  .md\:tw-m-672px {
    margin: 672px;
  }

  .md\:tw--m-1 {
    margin: -0.25rem;
  }

  .md\:tw--m-2 {
    margin: -0.5rem;
  }

  .md\:tw--m-3 {
    margin: -0.75rem;
  }

  .md\:tw--m-4 {
    margin: -1rem;
  }

  .md\:tw--m-5 {
    margin: -1.25rem;
  }

  .md\:tw--m-6 {
    margin: -1.5rem;
  }

  .md\:tw--m-8 {
    margin: -2rem;
  }

  .md\:tw--m-10 {
    margin: -2.5rem;
  }

  .md\:tw--m-12 {
    margin: -3rem;
  }

  .md\:tw--m-16 {
    margin: -4rem;
  }

  .md\:tw--m-20 {
    margin: -5rem;
  }

  .md\:tw--m-24 {
    margin: -6rem;
  }

  .md\:tw--m-32 {
    margin: -8rem;
  }

  .md\:tw--m-40 {
    margin: -10rem;
  }

  .md\:tw--m-48 {
    margin: -12rem;
  }

  .md\:tw--m-56 {
    margin: -14rem;
  }

  .md\:tw--m-64 {
    margin: -16rem;
  }

  .md\:tw--m-px {
    margin: -1px;
  }

  .md\:tw--m-270px {
    margin: -270px;
  }

  .md\:tw--m-400px {
    margin: -400px;
  }

  .md\:tw--m-464px {
    margin: -464px;
  }

  .md\:tw--m-480px {
    margin: -480px;
  }

  .md\:tw--m-500px {
    margin: -500px;
  }

  .md\:tw--m-576px {
    margin: -576px;
  }

  .md\:tw--m-600px {
    margin: -600px;
  }

  .md\:tw--m-672px {
    margin: -672px;
  }

  .md\:tw-my-0 {
    margin-top: 0;
    margin-bottom: 0;
  }

  .md\:tw-mx-0 {
    margin-left: 0;
    margin-right: 0;
  }

  .md\:tw-my-1 {
    margin-top: 0.25rem;
    margin-bottom: 0.25rem;
  }

  .md\:tw-mx-1 {
    margin-left: 0.25rem;
    margin-right: 0.25rem;
  }

  .md\:tw-my-2 {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
  }

  .md\:tw-mx-2 {
    margin-left: 0.5rem;
    margin-right: 0.5rem;
  }

  .md\:tw-my-3 {
    margin-top: 0.75rem;
    margin-bottom: 0.75rem;
  }

  .md\:tw-mx-3 {
    margin-left: 0.75rem;
    margin-right: 0.75rem;
  }

  .md\:tw-my-4 {
    margin-top: 1rem;
    margin-bottom: 1rem;
  }

  .md\:tw-mx-4 {
    margin-left: 1rem;
    margin-right: 1rem;
  }

  .md\:tw-my-5 {
    margin-top: 1.25rem;
    margin-bottom: 1.25rem;
  }

  .md\:tw-mx-5 {
    margin-left: 1.25rem;
    margin-right: 1.25rem;
  }

  .md\:tw-my-6 {
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
  }

  .md\:tw-mx-6 {
    margin-left: 1.5rem;
    margin-right: 1.5rem;
  }

  .md\:tw-my-8 {
    margin-top: 2rem;
    margin-bottom: 2rem;
  }

  .md\:tw-mx-8 {
    margin-left: 2rem;
    margin-right: 2rem;
  }

  .md\:tw-my-10 {
    margin-top: 2.5rem;
    margin-bottom: 2.5rem;
  }

  .md\:tw-mx-10 {
    margin-left: 2.5rem;
    margin-right: 2.5rem;
  }

  .md\:tw-my-12 {
    margin-top: 3rem;
    margin-bottom: 3rem;
  }

  .md\:tw-mx-12 {
    margin-left: 3rem;
    margin-right: 3rem;
  }

  .md\:tw-my-16 {
    margin-top: 4rem;
    margin-bottom: 4rem;
  }

  .md\:tw-mx-16 {
    margin-left: 4rem;
    margin-right: 4rem;
  }

  .md\:tw-my-20 {
    margin-top: 5rem;
    margin-bottom: 5rem;
  }

  .md\:tw-mx-20 {
    margin-left: 5rem;
    margin-right: 5rem;
  }

  .md\:tw-my-24 {
    margin-top: 6rem;
    margin-bottom: 6rem;
  }

  .md\:tw-mx-24 {
    margin-left: 6rem;
    margin-right: 6rem;
  }

  .md\:tw-my-32 {
    margin-top: 8rem;
    margin-bottom: 8rem;
  }

  .md\:tw-mx-32 {
    margin-left: 8rem;
    margin-right: 8rem;
  }

  .md\:tw-my-40 {
    margin-top: 10rem;
    margin-bottom: 10rem;
  }

  .md\:tw-mx-40 {
    margin-left: 10rem;
    margin-right: 10rem;
  }

  .md\:tw-my-48 {
    margin-top: 12rem;
    margin-bottom: 12rem;
  }

  .md\:tw-mx-48 {
    margin-left: 12rem;
    margin-right: 12rem;
  }

  .md\:tw-my-56 {
    margin-top: 14rem;
    margin-bottom: 14rem;
  }

  .md\:tw-mx-56 {
    margin-left: 14rem;
    margin-right: 14rem;
  }

  .md\:tw-my-64 {
    margin-top: 16rem;
    margin-bottom: 16rem;
  }

  .md\:tw-mx-64 {
    margin-left: 16rem;
    margin-right: 16rem;
  }

  .md\:tw-my-auto {
    margin-top: auto;
    margin-bottom: auto;
  }

  .md\:tw-mx-auto {
    margin-left: auto;
    margin-right: auto;
  }

  .md\:tw-my-px {
    margin-top: 1px;
    margin-bottom: 1px;
  }

  .md\:tw-mx-px {
    margin-left: 1px;
    margin-right: 1px;
  }

  .md\:tw-my-270px {
    margin-top: 270px;
    margin-bottom: 270px;
  }

  .md\:tw-mx-270px {
    margin-left: 270px;
    margin-right: 270px;
  }

  .md\:tw-my-400px {
    margin-top: 400px;
    margin-bottom: 400px;
  }

  .md\:tw-mx-400px {
    margin-left: 400px;
    margin-right: 400px;
  }

  .md\:tw-my-464px {
    margin-top: 464px;
    margin-bottom: 464px;
  }

  .md\:tw-mx-464px {
    margin-left: 464px;
    margin-right: 464px;
  }

  .md\:tw-my-480px {
    margin-top: 480px;
    margin-bottom: 480px;
  }

  .md\:tw-mx-480px {
    margin-left: 480px;
    margin-right: 480px;
  }

  .md\:tw-my-500px {
    margin-top: 500px;
    margin-bottom: 500px;
  }

  .md\:tw-mx-500px {
    margin-left: 500px;
    margin-right: 500px;
  }

  .md\:tw-my-576px {
    margin-top: 576px;
    margin-bottom: 576px;
  }

  .md\:tw-mx-576px {
    margin-left: 576px;
    margin-right: 576px;
  }

  .md\:tw-my-600px {
    margin-top: 600px;
    margin-bottom: 600px;
  }

  .md\:tw-mx-600px {
    margin-left: 600px;
    margin-right: 600px;
  }

  .md\:tw-my-672px {
    margin-top: 672px;
    margin-bottom: 672px;
  }

  .md\:tw-mx-672px {
    margin-left: 672px;
    margin-right: 672px;
  }

  .md\:tw--my-1 {
    margin-top: -0.25rem;
    margin-bottom: -0.25rem;
  }

  .md\:tw--mx-1 {
    margin-left: -0.25rem;
    margin-right: -0.25rem;
  }

  .md\:tw--my-2 {
    margin-top: -0.5rem;
    margin-bottom: -0.5rem;
  }

  .md\:tw--mx-2 {
    margin-left: -0.5rem;
    margin-right: -0.5rem;
  }

  .md\:tw--my-3 {
    margin-top: -0.75rem;
    margin-bottom: -0.75rem;
  }

  .md\:tw--mx-3 {
    margin-left: -0.75rem;
    margin-right: -0.75rem;
  }

  .md\:tw--my-4 {
    margin-top: -1rem;
    margin-bottom: -1rem;
  }

  .md\:tw--mx-4 {
    margin-left: -1rem;
    margin-right: -1rem;
  }

  .md\:tw--my-5 {
    margin-top: -1.25rem;
    margin-bottom: -1.25rem;
  }

  .md\:tw--mx-5 {
    margin-left: -1.25rem;
    margin-right: -1.25rem;
  }

  .md\:tw--my-6 {
    margin-top: -1.5rem;
    margin-bottom: -1.5rem;
  }

  .md\:tw--mx-6 {
    margin-left: -1.5rem;
    margin-right: -1.5rem;
  }

  .md\:tw--my-8 {
    margin-top: -2rem;
    margin-bottom: -2rem;
  }

  .md\:tw--mx-8 {
    margin-left: -2rem;
    margin-right: -2rem;
  }

  .md\:tw--my-10 {
    margin-top: -2.5rem;
    margin-bottom: -2.5rem;
  }

  .md\:tw--mx-10 {
    margin-left: -2.5rem;
    margin-right: -2.5rem;
  }

  .md\:tw--my-12 {
    margin-top: -3rem;
    margin-bottom: -3rem;
  }

  .md\:tw--mx-12 {
    margin-left: -3rem;
    margin-right: -3rem;
  }

  .md\:tw--my-16 {
    margin-top: -4rem;
    margin-bottom: -4rem;
  }

  .md\:tw--mx-16 {
    margin-left: -4rem;
    margin-right: -4rem;
  }

  .md\:tw--my-20 {
    margin-top: -5rem;
    margin-bottom: -5rem;
  }

  .md\:tw--mx-20 {
    margin-left: -5rem;
    margin-right: -5rem;
  }

  .md\:tw--my-24 {
    margin-top: -6rem;
    margin-bottom: -6rem;
  }

  .md\:tw--mx-24 {
    margin-left: -6rem;
    margin-right: -6rem;
  }

  .md\:tw--my-32 {
    margin-top: -8rem;
    margin-bottom: -8rem;
  }

  .md\:tw--mx-32 {
    margin-left: -8rem;
    margin-right: -8rem;
  }

  .md\:tw--my-40 {
    margin-top: -10rem;
    margin-bottom: -10rem;
  }

  .md\:tw--mx-40 {
    margin-left: -10rem;
    margin-right: -10rem;
  }

  .md\:tw--my-48 {
    margin-top: -12rem;
    margin-bottom: -12rem;
  }

  .md\:tw--mx-48 {
    margin-left: -12rem;
    margin-right: -12rem;
  }

  .md\:tw--my-56 {
    margin-top: -14rem;
    margin-bottom: -14rem;
  }

  .md\:tw--mx-56 {
    margin-left: -14rem;
    margin-right: -14rem;
  }

  .md\:tw--my-64 {
    margin-top: -16rem;
    margin-bottom: -16rem;
  }

  .md\:tw--mx-64 {
    margin-left: -16rem;
    margin-right: -16rem;
  }

  .md\:tw--my-px {
    margin-top: -1px;
    margin-bottom: -1px;
  }

  .md\:tw--mx-px {
    margin-left: -1px;
    margin-right: -1px;
  }

  .md\:tw--my-270px {
    margin-top: -270px;
    margin-bottom: -270px;
  }

  .md\:tw--mx-270px {
    margin-left: -270px;
    margin-right: -270px;
  }

  .md\:tw--my-400px {
    margin-top: -400px;
    margin-bottom: -400px;
  }

  .md\:tw--mx-400px {
    margin-left: -400px;
    margin-right: -400px;
  }

  .md\:tw--my-464px {
    margin-top: -464px;
    margin-bottom: -464px;
  }

  .md\:tw--mx-464px {
    margin-left: -464px;
    margin-right: -464px;
  }

  .md\:tw--my-480px {
    margin-top: -480px;
    margin-bottom: -480px;
  }

  .md\:tw--mx-480px {
    margin-left: -480px;
    margin-right: -480px;
  }

  .md\:tw--my-500px {
    margin-top: -500px;
    margin-bottom: -500px;
  }

  .md\:tw--mx-500px {
    margin-left: -500px;
    margin-right: -500px;
  }

  .md\:tw--my-576px {
    margin-top: -576px;
    margin-bottom: -576px;
  }

  .md\:tw--mx-576px {
    margin-left: -576px;
    margin-right: -576px;
  }

  .md\:tw--my-600px {
    margin-top: -600px;
    margin-bottom: -600px;
  }

  .md\:tw--mx-600px {
    margin-left: -600px;
    margin-right: -600px;
  }

  .md\:tw--my-672px {
    margin-top: -672px;
    margin-bottom: -672px;
  }

  .md\:tw--mx-672px {
    margin-left: -672px;
    margin-right: -672px;
  }

  .md\:tw-mt-0 {
    margin-top: 0;
  }

  .md\:tw-mr-0 {
    margin-right: 0;
  }

  .md\:tw-mb-0 {
    margin-bottom: 0;
  }

  .md\:tw-ml-0 {
    margin-left: 0;
  }

  .md\:tw-mt-1 {
    margin-top: 0.25rem;
  }

  .md\:tw-mr-1 {
    margin-right: 0.25rem;
  }

  .md\:tw-mb-1 {
    margin-bottom: 0.25rem;
  }

  .md\:tw-ml-1 {
    margin-left: 0.25rem;
  }

  .md\:tw-mt-2 {
    margin-top: 0.5rem;
  }

  .md\:tw-mr-2 {
    margin-right: 0.5rem;
  }

  .md\:tw-mb-2 {
    margin-bottom: 0.5rem;
  }

  .md\:tw-ml-2 {
    margin-left: 0.5rem;
  }

  .md\:tw-mt-3 {
    margin-top: 0.75rem;
  }

  .md\:tw-mr-3 {
    margin-right: 0.75rem;
  }

  .md\:tw-mb-3 {
    margin-bottom: 0.75rem;
  }

  .md\:tw-ml-3 {
    margin-left: 0.75rem;
  }

  .md\:tw-mt-4 {
    margin-top: 1rem;
  }

  .md\:tw-mr-4 {
    margin-right: 1rem;
  }

  .md\:tw-mb-4 {
    margin-bottom: 1rem;
  }

  .md\:tw-ml-4 {
    margin-left: 1rem;
  }

  .md\:tw-mt-5 {
    margin-top: 1.25rem;
  }

  .md\:tw-mr-5 {
    margin-right: 1.25rem;
  }

  .md\:tw-mb-5 {
    margin-bottom: 1.25rem;
  }

  .md\:tw-ml-5 {
    margin-left: 1.25rem;
  }

  .md\:tw-mt-6 {
    margin-top: 1.5rem;
  }

  .md\:tw-mr-6 {
    margin-right: 1.5rem;
  }

  .md\:tw-mb-6 {
    margin-bottom: 1.5rem;
  }

  .md\:tw-ml-6 {
    margin-left: 1.5rem;
  }

  .md\:tw-mt-8 {
    margin-top: 2rem;
  }

  .md\:tw-mr-8 {
    margin-right: 2rem;
  }

  .md\:tw-mb-8 {
    margin-bottom: 2rem;
  }

  .md\:tw-ml-8 {
    margin-left: 2rem;
  }

  .md\:tw-mt-10 {
    margin-top: 2.5rem;
  }

  .md\:tw-mr-10 {
    margin-right: 2.5rem;
  }

  .md\:tw-mb-10 {
    margin-bottom: 2.5rem;
  }

  .md\:tw-ml-10 {
    margin-left: 2.5rem;
  }

  .md\:tw-mt-12 {
    margin-top: 3rem;
  }

  .md\:tw-mr-12 {
    margin-right: 3rem;
  }

  .md\:tw-mb-12 {
    margin-bottom: 3rem;
  }

  .md\:tw-ml-12 {
    margin-left: 3rem;
  }

  .md\:tw-mt-16 {
    margin-top: 4rem;
  }

  .md\:tw-mr-16 {
    margin-right: 4rem;
  }

  .md\:tw-mb-16 {
    margin-bottom: 4rem;
  }

  .md\:tw-ml-16 {
    margin-left: 4rem;
  }

  .md\:tw-mt-20 {
    margin-top: 5rem;
  }

  .md\:tw-mr-20 {
    margin-right: 5rem;
  }

  .md\:tw-mb-20 {
    margin-bottom: 5rem;
  }

  .md\:tw-ml-20 {
    margin-left: 5rem;
  }

  .md\:tw-mt-24 {
    margin-top: 6rem;
  }

  .md\:tw-mr-24 {
    margin-right: 6rem;
  }

  .md\:tw-mb-24 {
    margin-bottom: 6rem;
  }

  .md\:tw-ml-24 {
    margin-left: 6rem;
  }

  .md\:tw-mt-32 {
    margin-top: 8rem;
  }

  .md\:tw-mr-32 {
    margin-right: 8rem;
  }

  .md\:tw-mb-32 {
    margin-bottom: 8rem;
  }

  .md\:tw-ml-32 {
    margin-left: 8rem;
  }

  .md\:tw-mt-40 {
    margin-top: 10rem;
  }

  .md\:tw-mr-40 {
    margin-right: 10rem;
  }

  .md\:tw-mb-40 {
    margin-bottom: 10rem;
  }

  .md\:tw-ml-40 {
    margin-left: 10rem;
  }

  .md\:tw-mt-48 {
    margin-top: 12rem;
  }

  .md\:tw-mr-48 {
    margin-right: 12rem;
  }

  .md\:tw-mb-48 {
    margin-bottom: 12rem;
  }

  .md\:tw-ml-48 {
    margin-left: 12rem;
  }

  .md\:tw-mt-56 {
    margin-top: 14rem;
  }

  .md\:tw-mr-56 {
    margin-right: 14rem;
  }

  .md\:tw-mb-56 {
    margin-bottom: 14rem;
  }

  .md\:tw-ml-56 {
    margin-left: 14rem;
  }

  .md\:tw-mt-64 {
    margin-top: 16rem;
  }

  .md\:tw-mr-64 {
    margin-right: 16rem;
  }

  .md\:tw-mb-64 {
    margin-bottom: 16rem;
  }

  .md\:tw-ml-64 {
    margin-left: 16rem;
  }

  .md\:tw-mt-auto {
    margin-top: auto;
  }

  .md\:tw-mr-auto {
    margin-right: auto;
  }

  .md\:tw-mb-auto {
    margin-bottom: auto;
  }

  .md\:tw-ml-auto {
    margin-left: auto;
  }

  .md\:tw-mt-px {
    margin-top: 1px;
  }

  .md\:tw-mr-px {
    margin-right: 1px;
  }

  .md\:tw-mb-px {
    margin-bottom: 1px;
  }

  .md\:tw-ml-px {
    margin-left: 1px;
  }

  .md\:tw-mt-270px {
    margin-top: 270px;
  }

  .md\:tw-mr-270px {
    margin-right: 270px;
  }

  .md\:tw-mb-270px {
    margin-bottom: 270px;
  }

  .md\:tw-ml-270px {
    margin-left: 270px;
  }

  .md\:tw-mt-400px {
    margin-top: 400px;
  }

  .md\:tw-mr-400px {
    margin-right: 400px;
  }

  .md\:tw-mb-400px {
    margin-bottom: 400px;
  }

  .md\:tw-ml-400px {
    margin-left: 400px;
  }

  .md\:tw-mt-464px {
    margin-top: 464px;
  }

  .md\:tw-mr-464px {
    margin-right: 464px;
  }

  .md\:tw-mb-464px {
    margin-bottom: 464px;
  }

  .md\:tw-ml-464px {
    margin-left: 464px;
  }

  .md\:tw-mt-480px {
    margin-top: 480px;
  }

  .md\:tw-mr-480px {
    margin-right: 480px;
  }

  .md\:tw-mb-480px {
    margin-bottom: 480px;
  }

  .md\:tw-ml-480px {
    margin-left: 480px;
  }

  .md\:tw-mt-500px {
    margin-top: 500px;
  }

  .md\:tw-mr-500px {
    margin-right: 500px;
  }

  .md\:tw-mb-500px {
    margin-bottom: 500px;
  }

  .md\:tw-ml-500px {
    margin-left: 500px;
  }

  .md\:tw-mt-576px {
    margin-top: 576px;
  }

  .md\:tw-mr-576px {
    margin-right: 576px;
  }

  .md\:tw-mb-576px {
    margin-bottom: 576px;
  }

  .md\:tw-ml-576px {
    margin-left: 576px;
  }

  .md\:tw-mt-600px {
    margin-top: 600px;
  }

  .md\:tw-mr-600px {
    margin-right: 600px;
  }

  .md\:tw-mb-600px {
    margin-bottom: 600px;
  }

  .md\:tw-ml-600px {
    margin-left: 600px;
  }

  .md\:tw-mt-672px {
    margin-top: 672px;
  }

  .md\:tw-mr-672px {
    margin-right: 672px;
  }

  .md\:tw-mb-672px {
    margin-bottom: 672px;
  }

  .md\:tw-ml-672px {
    margin-left: 672px;
  }

  .md\:tw--mt-1 {
    margin-top: -0.25rem;
  }

  .md\:tw--mr-1 {
    margin-right: -0.25rem;
  }

  .md\:tw--mb-1 {
    margin-bottom: -0.25rem;
  }

  .md\:tw--ml-1 {
    margin-left: -0.25rem;
  }

  .md\:tw--mt-2 {
    margin-top: -0.5rem;
  }

  .md\:tw--mr-2 {
    margin-right: -0.5rem;
  }

  .md\:tw--mb-2 {
    margin-bottom: -0.5rem;
  }

  .md\:tw--ml-2 {
    margin-left: -0.5rem;
  }

  .md\:tw--mt-3 {
    margin-top: -0.75rem;
  }

  .md\:tw--mr-3 {
    margin-right: -0.75rem;
  }

  .md\:tw--mb-3 {
    margin-bottom: -0.75rem;
  }

  .md\:tw--ml-3 {
    margin-left: -0.75rem;
  }

  .md\:tw--mt-4 {
    margin-top: -1rem;
  }

  .md\:tw--mr-4 {
    margin-right: -1rem;
  }

  .md\:tw--mb-4 {
    margin-bottom: -1rem;
  }

  .md\:tw--ml-4 {
    margin-left: -1rem;
  }

  .md\:tw--mt-5 {
    margin-top: -1.25rem;
  }

  .md\:tw--mr-5 {
    margin-right: -1.25rem;
  }

  .md\:tw--mb-5 {
    margin-bottom: -1.25rem;
  }

  .md\:tw--ml-5 {
    margin-left: -1.25rem;
  }

  .md\:tw--mt-6 {
    margin-top: -1.5rem;
  }

  .md\:tw--mr-6 {
    margin-right: -1.5rem;
  }

  .md\:tw--mb-6 {
    margin-bottom: -1.5rem;
  }

  .md\:tw--ml-6 {
    margin-left: -1.5rem;
  }

  .md\:tw--mt-8 {
    margin-top: -2rem;
  }

  .md\:tw--mr-8 {
    margin-right: -2rem;
  }

  .md\:tw--mb-8 {
    margin-bottom: -2rem;
  }

  .md\:tw--ml-8 {
    margin-left: -2rem;
  }

  .md\:tw--mt-10 {
    margin-top: -2.5rem;
  }

  .md\:tw--mr-10 {
    margin-right: -2.5rem;
  }

  .md\:tw--mb-10 {
    margin-bottom: -2.5rem;
  }

  .md\:tw--ml-10 {
    margin-left: -2.5rem;
  }

  .md\:tw--mt-12 {
    margin-top: -3rem;
  }

  .md\:tw--mr-12 {
    margin-right: -3rem;
  }

  .md\:tw--mb-12 {
    margin-bottom: -3rem;
  }

  .md\:tw--ml-12 {
    margin-left: -3rem;
  }

  .md\:tw--mt-16 {
    margin-top: -4rem;
  }

  .md\:tw--mr-16 {
    margin-right: -4rem;
  }

  .md\:tw--mb-16 {
    margin-bottom: -4rem;
  }

  .md\:tw--ml-16 {
    margin-left: -4rem;
  }

  .md\:tw--mt-20 {
    margin-top: -5rem;
  }

  .md\:tw--mr-20 {
    margin-right: -5rem;
  }

  .md\:tw--mb-20 {
    margin-bottom: -5rem;
  }

  .md\:tw--ml-20 {
    margin-left: -5rem;
  }

  .md\:tw--mt-24 {
    margin-top: -6rem;
  }

  .md\:tw--mr-24 {
    margin-right: -6rem;
  }

  .md\:tw--mb-24 {
    margin-bottom: -6rem;
  }

  .md\:tw--ml-24 {
    margin-left: -6rem;
  }

  .md\:tw--mt-32 {
    margin-top: -8rem;
  }

  .md\:tw--mr-32 {
    margin-right: -8rem;
  }

  .md\:tw--mb-32 {
    margin-bottom: -8rem;
  }

  .md\:tw--ml-32 {
    margin-left: -8rem;
  }

  .md\:tw--mt-40 {
    margin-top: -10rem;
  }

  .md\:tw--mr-40 {
    margin-right: -10rem;
  }

  .md\:tw--mb-40 {
    margin-bottom: -10rem;
  }

  .md\:tw--ml-40 {
    margin-left: -10rem;
  }

  .md\:tw--mt-48 {
    margin-top: -12rem;
  }

  .md\:tw--mr-48 {
    margin-right: -12rem;
  }

  .md\:tw--mb-48 {
    margin-bottom: -12rem;
  }

  .md\:tw--ml-48 {
    margin-left: -12rem;
  }

  .md\:tw--mt-56 {
    margin-top: -14rem;
  }

  .md\:tw--mr-56 {
    margin-right: -14rem;
  }

  .md\:tw--mb-56 {
    margin-bottom: -14rem;
  }

  .md\:tw--ml-56 {
    margin-left: -14rem;
  }

  .md\:tw--mt-64 {
    margin-top: -16rem;
  }

  .md\:tw--mr-64 {
    margin-right: -16rem;
  }

  .md\:tw--mb-64 {
    margin-bottom: -16rem;
  }

  .md\:tw--ml-64 {
    margin-left: -16rem;
  }

  .md\:tw--mt-px {
    margin-top: -1px;
  }

  .md\:tw--mr-px {
    margin-right: -1px;
  }

  .md\:tw--mb-px {
    margin-bottom: -1px;
  }

  .md\:tw--ml-px {
    margin-left: -1px;
  }

  .md\:tw--mt-270px {
    margin-top: -270px;
  }

  .md\:tw--mr-270px {
    margin-right: -270px;
  }

  .md\:tw--mb-270px {
    margin-bottom: -270px;
  }

  .md\:tw--ml-270px {
    margin-left: -270px;
  }

  .md\:tw--mt-400px {
    margin-top: -400px;
  }

  .md\:tw--mr-400px {
    margin-right: -400px;
  }

  .md\:tw--mb-400px {
    margin-bottom: -400px;
  }

  .md\:tw--ml-400px {
    margin-left: -400px;
  }

  .md\:tw--mt-464px {
    margin-top: -464px;
  }

  .md\:tw--mr-464px {
    margin-right: -464px;
  }

  .md\:tw--mb-464px {
    margin-bottom: -464px;
  }

  .md\:tw--ml-464px {
    margin-left: -464px;
  }

  .md\:tw--mt-480px {
    margin-top: -480px;
  }

  .md\:tw--mr-480px {
    margin-right: -480px;
  }

  .md\:tw--mb-480px {
    margin-bottom: -480px;
  }

  .md\:tw--ml-480px {
    margin-left: -480px;
  }

  .md\:tw--mt-500px {
    margin-top: -500px;
  }

  .md\:tw--mr-500px {
    margin-right: -500px;
  }

  .md\:tw--mb-500px {
    margin-bottom: -500px;
  }

  .md\:tw--ml-500px {
    margin-left: -500px;
  }

  .md\:tw--mt-576px {
    margin-top: -576px;
  }

  .md\:tw--mr-576px {
    margin-right: -576px;
  }

  .md\:tw--mb-576px {
    margin-bottom: -576px;
  }

  .md\:tw--ml-576px {
    margin-left: -576px;
  }

  .md\:tw--mt-600px {
    margin-top: -600px;
  }

  .md\:tw--mr-600px {
    margin-right: -600px;
  }

  .md\:tw--mb-600px {
    margin-bottom: -600px;
  }

  .md\:tw--ml-600px {
    margin-left: -600px;
  }

  .md\:tw--mt-672px {
    margin-top: -672px;
  }

  .md\:tw--mr-672px {
    margin-right: -672px;
  }

  .md\:tw--mb-672px {
    margin-bottom: -672px;
  }

  .md\:tw--ml-672px {
    margin-left: -672px;
  }

  .md\:tw-max-h-full {
    max-height: 100%;
  }

  .md\:tw-max-h-screen {
    max-height: 100vh;
  }

  .md\:tw-max-w-none {
    max-width: none;
  }

  .md\:tw-max-w-xs {
    max-width: 20rem;
  }

  .md\:tw-max-w-sm {
    max-width: 24rem;
  }

  .md\:tw-max-w-md {
    max-width: 28rem;
  }

  .md\:tw-max-w-lg {
    max-width: 32rem;
  }

  .md\:tw-max-w-xl {
    max-width: 36rem;
  }

  .md\:tw-max-w-2xl {
    max-width: 42rem;
  }

  .md\:tw-max-w-3xl {
    max-width: 48rem;
  }

  .md\:tw-max-w-4xl {
    max-width: 56rem;
  }

  .md\:tw-max-w-5xl {
    max-width: 64rem;
  }

  .md\:tw-max-w-6xl {
    max-width: 72rem;
  }

  .md\:tw-max-w-full {
    max-width: 100%;
  }

  .md\:tw-max-w-screen-sm {
    max-width: 640px;
  }

  .md\:tw-max-w-screen-md {
    max-width: 768px;
  }

  .md\:tw-max-w-screen-lg {
    max-width: 1024px;
  }

  .md\:tw-max-w-screen-xl {
    max-width: 1280px;
  }

  .md\:tw-min-h-0 {
    min-height: 0;
  }

  .md\:tw-min-h-full {
    min-height: 100%;
  }

  .md\:tw-min-h-screen {
    min-height: 100vh;
  }

  .md\:tw-min-h-576px {
    min-height: 576px;
  }

  .md\:tw-min-h-600px {
    min-height: 600px;
  }

  .md\:tw-min-h-672px {
    min-height: 672px;
  }

  .md\:tw-min-w-0 {
    min-width: 0;
  }

  .md\:tw-min-w-full {
    min-width: 100%;
  }

  .md\:tw-min-w-360px {
    min-width: 360px;
  }

  .md\:tw-min-w-540px {
    min-width: 540px;
  }

  .md\:tw-object-contain {
    -o-object-fit: contain;
       object-fit: contain;
  }

  .md\:tw-object-cover {
    -o-object-fit: cover;
       object-fit: cover;
  }

  .md\:tw-object-fill {
    -o-object-fit: fill;
       object-fit: fill;
  }

  .md\:tw-object-none {
    -o-object-fit: none;
       object-fit: none;
  }

  .md\:tw-object-scale-down {
    -o-object-fit: scale-down;
       object-fit: scale-down;
  }

  .md\:tw-object-bottom {
    -o-object-position: bottom;
       object-position: bottom;
  }

  .md\:tw-object-center {
    -o-object-position: center;
       object-position: center;
  }

  .md\:tw-object-left {
    -o-object-position: left;
       object-position: left;
  }

  .md\:tw-object-left-bottom {
    -o-object-position: left bottom;
       object-position: left bottom;
  }

  .md\:tw-object-left-top {
    -o-object-position: left top;
       object-position: left top;
  }

  .md\:tw-object-right {
    -o-object-position: right;
       object-position: right;
  }

  .md\:tw-object-right-bottom {
    -o-object-position: right bottom;
       object-position: right bottom;
  }

  .md\:tw-object-right-top {
    -o-object-position: right top;
       object-position: right top;
  }

  .md\:tw-object-top {
    -o-object-position: top;
       object-position: top;
  }

  .md\:tw-opacity-0 {
    opacity: 0;
  }

  .md\:tw-opacity-25 {
    opacity: 0.25;
  }

  .md\:tw-opacity-50 {
    opacity: 0.5;
  }

  .md\:tw-opacity-75 {
    opacity: 0.75;
  }

  .md\:tw-opacity-100 {
    opacity: 1;
  }

  .md\:hover\:tw-opacity-0:hover {
    opacity: 0;
  }

  .md\:hover\:tw-opacity-25:hover {
    opacity: 0.25;
  }

  .md\:hover\:tw-opacity-50:hover {
    opacity: 0.5;
  }

  .md\:hover\:tw-opacity-75:hover {
    opacity: 0.75;
  }

  .md\:hover\:tw-opacity-100:hover {
    opacity: 1;
  }

  .md\:focus\:tw-opacity-0:focus {
    opacity: 0;
  }

  .md\:focus\:tw-opacity-25:focus {
    opacity: 0.25;
  }

  .md\:focus\:tw-opacity-50:focus {
    opacity: 0.5;
  }

  .md\:focus\:tw-opacity-75:focus {
    opacity: 0.75;
  }

  .md\:focus\:tw-opacity-100:focus {
    opacity: 1;
  }

  .md\:tw-outline-none {
    outline: 2px solid transparent;
    outline-offset: 2px;
  }

  .md\:tw-outline-white {
    outline: 2px dotted white;
    outline-offset: 2px;
  }

  .md\:tw-outline-black {
    outline: 2px dotted black;
    outline-offset: 2px;
  }

  .md\:focus\:tw-outline-none:focus {
    outline: 2px solid transparent;
    outline-offset: 2px;
  }

  .md\:focus\:tw-outline-white:focus {
    outline: 2px dotted white;
    outline-offset: 2px;
  }

  .md\:focus\:tw-outline-black:focus {
    outline: 2px dotted black;
    outline-offset: 2px;
  }

  .md\:tw-overflow-auto {
    overflow: auto;
  }

  .md\:tw-overflow-hidden {
    overflow: hidden;
  }

  .md\:tw-overflow-visible {
    overflow: visible;
  }

  .md\:tw-overflow-scroll {
    overflow: scroll;
  }

  .md\:tw-overflow-x-auto {
    overflow-x: auto;
  }

  .md\:tw-overflow-y-auto {
    overflow-y: auto;
  }

  .md\:tw-overflow-x-hidden {
    overflow-x: hidden;
  }

  .md\:tw-overflow-y-hidden {
    overflow-y: hidden;
  }

  .md\:tw-overflow-x-visible {
    overflow-x: visible;
  }

  .md\:tw-overflow-y-visible {
    overflow-y: visible;
  }

  .md\:tw-overflow-x-scroll {
    overflow-x: scroll;
  }

  .md\:tw-overflow-y-scroll {
    overflow-y: scroll;
  }

  .md\:tw-scrolling-touch {
    -webkit-overflow-scrolling: touch;
  }

  .md\:tw-scrolling-auto {
    -webkit-overflow-scrolling: auto;
  }

  .md\:tw-overscroll-auto {
    overscroll-behavior: auto;
  }

  .md\:tw-overscroll-contain {
    overscroll-behavior: contain;
  }

  .md\:tw-overscroll-none {
    overscroll-behavior: none;
  }

  .md\:tw-overscroll-y-auto {
    overscroll-behavior-y: auto;
  }

  .md\:tw-overscroll-y-contain {
    overscroll-behavior-y: contain;
  }

  .md\:tw-overscroll-y-none {
    overscroll-behavior-y: none;
  }

  .md\:tw-overscroll-x-auto {
    overscroll-behavior-x: auto;
  }

  .md\:tw-overscroll-x-contain {
    overscroll-behavior-x: contain;
  }

  .md\:tw-overscroll-x-none {
    overscroll-behavior-x: none;
  }

  .md\:tw-p-0 {
    padding: 0;
  }

  .md\:tw-p-1 {
    padding: 0.25rem;
  }

  .md\:tw-p-2 {
    padding: 0.5rem;
  }

  .md\:tw-p-3 {
    padding: 0.75rem;
  }

  .md\:tw-p-4 {
    padding: 1rem;
  }

  .md\:tw-p-5 {
    padding: 1.25rem;
  }

  .md\:tw-p-6 {
    padding: 1.5rem;
  }

  .md\:tw-p-8 {
    padding: 2rem;
  }

  .md\:tw-p-10 {
    padding: 2.5rem;
  }

  .md\:tw-p-12 {
    padding: 3rem;
  }

  .md\:tw-p-16 {
    padding: 4rem;
  }

  .md\:tw-p-20 {
    padding: 5rem;
  }

  .md\:tw-p-24 {
    padding: 6rem;
  }

  .md\:tw-p-32 {
    padding: 8rem;
  }

  .md\:tw-p-40 {
    padding: 10rem;
  }

  .md\:tw-p-48 {
    padding: 12rem;
  }

  .md\:tw-p-56 {
    padding: 14rem;
  }

  .md\:tw-p-64 {
    padding: 16rem;
  }

  .md\:tw-p-px {
    padding: 1px;
  }

  .md\:tw-p-270px {
    padding: 270px;
  }

  .md\:tw-p-400px {
    padding: 400px;
  }

  .md\:tw-p-464px {
    padding: 464px;
  }

  .md\:tw-p-480px {
    padding: 480px;
  }

  .md\:tw-p-500px {
    padding: 500px;
  }

  .md\:tw-p-576px {
    padding: 576px;
  }

  .md\:tw-p-600px {
    padding: 600px;
  }

  .md\:tw-p-672px {
    padding: 672px;
  }

  .md\:tw-py-0 {
    padding-top: 0;
    padding-bottom: 0;
  }

  .md\:tw-px-0 {
    padding-left: 0;
    padding-right: 0;
  }

  .md\:tw-py-1 {
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;
  }

  .md\:tw-px-1 {
    padding-left: 0.25rem;
    padding-right: 0.25rem;
  }

  .md\:tw-py-2 {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
  }

  .md\:tw-px-2 {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }

  .md\:tw-py-3 {
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
  }

  .md\:tw-px-3 {
    padding-left: 0.75rem;
    padding-right: 0.75rem;
  }

  .md\:tw-py-4 {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }

  .md\:tw-px-4 {
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .md\:tw-py-5 {
    padding-top: 1.25rem;
    padding-bottom: 1.25rem;
  }

  .md\:tw-px-5 {
    padding-left: 1.25rem;
    padding-right: 1.25rem;
  }

  .md\:tw-py-6 {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
  }

  .md\:tw-px-6 {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }

  .md\:tw-py-8 {
    padding-top: 2rem;
    padding-bottom: 2rem;
  }

  .md\:tw-px-8 {
    padding-left: 2rem;
    padding-right: 2rem;
  }

  .md\:tw-py-10 {
    padding-top: 2.5rem;
    padding-bottom: 2.5rem;
  }

  .md\:tw-px-10 {
    padding-left: 2.5rem;
    padding-right: 2.5rem;
  }

  .md\:tw-py-12 {
    padding-top: 3rem;
    padding-bottom: 3rem;
  }

  .md\:tw-px-12 {
    padding-left: 3rem;
    padding-right: 3rem;
  }

  .md\:tw-py-16 {
    padding-top: 4rem;
    padding-bottom: 4rem;
  }

  .md\:tw-px-16 {
    padding-left: 4rem;
    padding-right: 4rem;
  }

  .md\:tw-py-20 {
    padding-top: 5rem;
    padding-bottom: 5rem;
  }

  .md\:tw-px-20 {
    padding-left: 5rem;
    padding-right: 5rem;
  }

  .md\:tw-py-24 {
    padding-top: 6rem;
    padding-bottom: 6rem;
  }

  .md\:tw-px-24 {
    padding-left: 6rem;
    padding-right: 6rem;
  }

  .md\:tw-py-32 {
    padding-top: 8rem;
    padding-bottom: 8rem;
  }

  .md\:tw-px-32 {
    padding-left: 8rem;
    padding-right: 8rem;
  }

  .md\:tw-py-40 {
    padding-top: 10rem;
    padding-bottom: 10rem;
  }

  .md\:tw-px-40 {
    padding-left: 10rem;
    padding-right: 10rem;
  }

  .md\:tw-py-48 {
    padding-top: 12rem;
    padding-bottom: 12rem;
  }

  .md\:tw-px-48 {
    padding-left: 12rem;
    padding-right: 12rem;
  }

  .md\:tw-py-56 {
    padding-top: 14rem;
    padding-bottom: 14rem;
  }

  .md\:tw-px-56 {
    padding-left: 14rem;
    padding-right: 14rem;
  }

  .md\:tw-py-64 {
    padding-top: 16rem;
    padding-bottom: 16rem;
  }

  .md\:tw-px-64 {
    padding-left: 16rem;
    padding-right: 16rem;
  }

  .md\:tw-py-px {
    padding-top: 1px;
    padding-bottom: 1px;
  }

  .md\:tw-px-px {
    padding-left: 1px;
    padding-right: 1px;
  }

  .md\:tw-py-270px {
    padding-top: 270px;
    padding-bottom: 270px;
  }

  .md\:tw-px-270px {
    padding-left: 270px;
    padding-right: 270px;
  }

  .md\:tw-py-400px {
    padding-top: 400px;
    padding-bottom: 400px;
  }

  .md\:tw-px-400px {
    padding-left: 400px;
    padding-right: 400px;
  }

  .md\:tw-py-464px {
    padding-top: 464px;
    padding-bottom: 464px;
  }

  .md\:tw-px-464px {
    padding-left: 464px;
    padding-right: 464px;
  }

  .md\:tw-py-480px {
    padding-top: 480px;
    padding-bottom: 480px;
  }

  .md\:tw-px-480px {
    padding-left: 480px;
    padding-right: 480px;
  }

  .md\:tw-py-500px {
    padding-top: 500px;
    padding-bottom: 500px;
  }

  .md\:tw-px-500px {
    padding-left: 500px;
    padding-right: 500px;
  }

  .md\:tw-py-576px {
    padding-top: 576px;
    padding-bottom: 576px;
  }

  .md\:tw-px-576px {
    padding-left: 576px;
    padding-right: 576px;
  }

  .md\:tw-py-600px {
    padding-top: 600px;
    padding-bottom: 600px;
  }

  .md\:tw-px-600px {
    padding-left: 600px;
    padding-right: 600px;
  }

  .md\:tw-py-672px {
    padding-top: 672px;
    padding-bottom: 672px;
  }

  .md\:tw-px-672px {
    padding-left: 672px;
    padding-right: 672px;
  }

  .md\:tw-pt-0 {
    padding-top: 0;
  }

  .md\:tw-pr-0 {
    padding-right: 0;
  }

  .md\:tw-pb-0 {
    padding-bottom: 0;
  }

  .md\:tw-pl-0 {
    padding-left: 0;
  }

  .md\:tw-pt-1 {
    padding-top: 0.25rem;
  }

  .md\:tw-pr-1 {
    padding-right: 0.25rem;
  }

  .md\:tw-pb-1 {
    padding-bottom: 0.25rem;
  }

  .md\:tw-pl-1 {
    padding-left: 0.25rem;
  }

  .md\:tw-pt-2 {
    padding-top: 0.5rem;
  }

  .md\:tw-pr-2 {
    padding-right: 0.5rem;
  }

  .md\:tw-pb-2 {
    padding-bottom: 0.5rem;
  }

  .md\:tw-pl-2 {
    padding-left: 0.5rem;
  }

  .md\:tw-pt-3 {
    padding-top: 0.75rem;
  }

  .md\:tw-pr-3 {
    padding-right: 0.75rem;
  }

  .md\:tw-pb-3 {
    padding-bottom: 0.75rem;
  }

  .md\:tw-pl-3 {
    padding-left: 0.75rem;
  }

  .md\:tw-pt-4 {
    padding-top: 1rem;
  }

  .md\:tw-pr-4 {
    padding-right: 1rem;
  }

  .md\:tw-pb-4 {
    padding-bottom: 1rem;
  }

  .md\:tw-pl-4 {
    padding-left: 1rem;
  }

  .md\:tw-pt-5 {
    padding-top: 1.25rem;
  }

  .md\:tw-pr-5 {
    padding-right: 1.25rem;
  }

  .md\:tw-pb-5 {
    padding-bottom: 1.25rem;
  }

  .md\:tw-pl-5 {
    padding-left: 1.25rem;
  }

  .md\:tw-pt-6 {
    padding-top: 1.5rem;
  }

  .md\:tw-pr-6 {
    padding-right: 1.5rem;
  }

  .md\:tw-pb-6 {
    padding-bottom: 1.5rem;
  }

  .md\:tw-pl-6 {
    padding-left: 1.5rem;
  }

  .md\:tw-pt-8 {
    padding-top: 2rem;
  }

  .md\:tw-pr-8 {
    padding-right: 2rem;
  }

  .md\:tw-pb-8 {
    padding-bottom: 2rem;
  }

  .md\:tw-pl-8 {
    padding-left: 2rem;
  }

  .md\:tw-pt-10 {
    padding-top: 2.5rem;
  }

  .md\:tw-pr-10 {
    padding-right: 2.5rem;
  }

  .md\:tw-pb-10 {
    padding-bottom: 2.5rem;
  }

  .md\:tw-pl-10 {
    padding-left: 2.5rem;
  }

  .md\:tw-pt-12 {
    padding-top: 3rem;
  }

  .md\:tw-pr-12 {
    padding-right: 3rem;
  }

  .md\:tw-pb-12 {
    padding-bottom: 3rem;
  }

  .md\:tw-pl-12 {
    padding-left: 3rem;
  }

  .md\:tw-pt-16 {
    padding-top: 4rem;
  }

  .md\:tw-pr-16 {
    padding-right: 4rem;
  }

  .md\:tw-pb-16 {
    padding-bottom: 4rem;
  }

  .md\:tw-pl-16 {
    padding-left: 4rem;
  }

  .md\:tw-pt-20 {
    padding-top: 5rem;
  }

  .md\:tw-pr-20 {
    padding-right: 5rem;
  }

  .md\:tw-pb-20 {
    padding-bottom: 5rem;
  }

  .md\:tw-pl-20 {
    padding-left: 5rem;
  }

  .md\:tw-pt-24 {
    padding-top: 6rem;
  }

  .md\:tw-pr-24 {
    padding-right: 6rem;
  }

  .md\:tw-pb-24 {
    padding-bottom: 6rem;
  }

  .md\:tw-pl-24 {
    padding-left: 6rem;
  }

  .md\:tw-pt-32 {
    padding-top: 8rem;
  }

  .md\:tw-pr-32 {
    padding-right: 8rem;
  }

  .md\:tw-pb-32 {
    padding-bottom: 8rem;
  }

  .md\:tw-pl-32 {
    padding-left: 8rem;
  }

  .md\:tw-pt-40 {
    padding-top: 10rem;
  }

  .md\:tw-pr-40 {
    padding-right: 10rem;
  }

  .md\:tw-pb-40 {
    padding-bottom: 10rem;
  }

  .md\:tw-pl-40 {
    padding-left: 10rem;
  }

  .md\:tw-pt-48 {
    padding-top: 12rem;
  }

  .md\:tw-pr-48 {
    padding-right: 12rem;
  }

  .md\:tw-pb-48 {
    padding-bottom: 12rem;
  }

  .md\:tw-pl-48 {
    padding-left: 12rem;
  }

  .md\:tw-pt-56 {
    padding-top: 14rem;
  }

  .md\:tw-pr-56 {
    padding-right: 14rem;
  }

  .md\:tw-pb-56 {
    padding-bottom: 14rem;
  }

  .md\:tw-pl-56 {
    padding-left: 14rem;
  }

  .md\:tw-pt-64 {
    padding-top: 16rem;
  }

  .md\:tw-pr-64 {
    padding-right: 16rem;
  }

  .md\:tw-pb-64 {
    padding-bottom: 16rem;
  }

  .md\:tw-pl-64 {
    padding-left: 16rem;
  }

  .md\:tw-pt-px {
    padding-top: 1px;
  }

  .md\:tw-pr-px {
    padding-right: 1px;
  }

  .md\:tw-pb-px {
    padding-bottom: 1px;
  }

  .md\:tw-pl-px {
    padding-left: 1px;
  }

  .md\:tw-pt-270px {
    padding-top: 270px;
  }

  .md\:tw-pr-270px {
    padding-right: 270px;
  }

  .md\:tw-pb-270px {
    padding-bottom: 270px;
  }

  .md\:tw-pl-270px {
    padding-left: 270px;
  }

  .md\:tw-pt-400px {
    padding-top: 400px;
  }

  .md\:tw-pr-400px {
    padding-right: 400px;
  }

  .md\:tw-pb-400px {
    padding-bottom: 400px;
  }

  .md\:tw-pl-400px {
    padding-left: 400px;
  }

  .md\:tw-pt-464px {
    padding-top: 464px;
  }

  .md\:tw-pr-464px {
    padding-right: 464px;
  }

  .md\:tw-pb-464px {
    padding-bottom: 464px;
  }

  .md\:tw-pl-464px {
    padding-left: 464px;
  }

  .md\:tw-pt-480px {
    padding-top: 480px;
  }

  .md\:tw-pr-480px {
    padding-right: 480px;
  }

  .md\:tw-pb-480px {
    padding-bottom: 480px;
  }

  .md\:tw-pl-480px {
    padding-left: 480px;
  }

  .md\:tw-pt-500px {
    padding-top: 500px;
  }

  .md\:tw-pr-500px {
    padding-right: 500px;
  }

  .md\:tw-pb-500px {
    padding-bottom: 500px;
  }

  .md\:tw-pl-500px {
    padding-left: 500px;
  }

  .md\:tw-pt-576px {
    padding-top: 576px;
  }

  .md\:tw-pr-576px {
    padding-right: 576px;
  }

  .md\:tw-pb-576px {
    padding-bottom: 576px;
  }

  .md\:tw-pl-576px {
    padding-left: 576px;
  }

  .md\:tw-pt-600px {
    padding-top: 600px;
  }

  .md\:tw-pr-600px {
    padding-right: 600px;
  }

  .md\:tw-pb-600px {
    padding-bottom: 600px;
  }

  .md\:tw-pl-600px {
    padding-left: 600px;
  }

  .md\:tw-pt-672px {
    padding-top: 672px;
  }

  .md\:tw-pr-672px {
    padding-right: 672px;
  }

  .md\:tw-pb-672px {
    padding-bottom: 672px;
  }

  .md\:tw-pl-672px {
    padding-left: 672px;
  }

  .md\:tw-placeholder-transparent::-moz-placeholder {
    color: transparent;
  }

  .md\:tw-placeholder-transparent::placeholder {
    color: transparent;
  }

  .md\:tw-placeholder-current::-moz-placeholder {
    color: currentColor;
  }

  .md\:tw-placeholder-current::placeholder {
    color: currentColor;
  }

  .md\:tw-placeholder-black::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #000;
    color: rgba(0, 0, 0, var(--placeholder-opacity));
  }

  .md\:tw-placeholder-black::placeholder {
    --placeholder-opacity: 1;
    color: #000;
    color: rgba(0, 0, 0, var(--placeholder-opacity));
  }

  .md\:tw-placeholder-white::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #fff;
    color: rgba(255, 255, 255, var(--placeholder-opacity));
  }

  .md\:tw-placeholder-white::placeholder {
    --placeholder-opacity: 1;
    color: #fff;
    color: rgba(255, 255, 255, var(--placeholder-opacity));
  }

  .md\:tw-placeholder-gray-100::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #f7fafc;
    color: rgba(247, 250, 252, var(--placeholder-opacity));
  }

  .md\:tw-placeholder-gray-100::placeholder {
    --placeholder-opacity: 1;
    color: #f7fafc;
    color: rgba(247, 250, 252, var(--placeholder-opacity));
  }

  .md\:tw-placeholder-gray-200::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #edf2f7;
    color: rgba(237, 242, 247, var(--placeholder-opacity));
  }

  .md\:tw-placeholder-gray-200::placeholder {
    --placeholder-opacity: 1;
    color: #edf2f7;
    color: rgba(237, 242, 247, var(--placeholder-opacity));
  }

  .md\:tw-placeholder-gray-300::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #e2e8f0;
    color: rgba(226, 232, 240, var(--placeholder-opacity));
  }

  .md\:tw-placeholder-gray-300::placeholder {
    --placeholder-opacity: 1;
    color: #e2e8f0;
    color: rgba(226, 232, 240, var(--placeholder-opacity));
  }

  .md\:tw-placeholder-gray-400::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #cbd5e0;
    color: rgba(203, 213, 224, var(--placeholder-opacity));
  }

  .md\:tw-placeholder-gray-400::placeholder {
    --placeholder-opacity: 1;
    color: #cbd5e0;
    color: rgba(203, 213, 224, var(--placeholder-opacity));
  }

  .md\:tw-placeholder-gray-500::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #a0aec0;
    color: rgba(160, 174, 192, var(--placeholder-opacity));
  }

  .md\:tw-placeholder-gray-500::placeholder {
    --placeholder-opacity: 1;
    color: #a0aec0;
    color: rgba(160, 174, 192, var(--placeholder-opacity));
  }

  .md\:tw-placeholder-gray-600::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #718096;
    color: rgba(113, 128, 150, var(--placeholder-opacity));
  }

  .md\:tw-placeholder-gray-600::placeholder {
    --placeholder-opacity: 1;
    color: #718096;
    color: rgba(113, 128, 150, var(--placeholder-opacity));
  }

  .md\:tw-placeholder-gray-700::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #4a5568;
    color: rgba(74, 85, 104, var(--placeholder-opacity));
  }

  .md\:tw-placeholder-gray-700::placeholder {
    --placeholder-opacity: 1;
    color: #4a5568;
    color: rgba(74, 85, 104, var(--placeholder-opacity));
  }

  .md\:tw-placeholder-gray-800::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #2d3748;
    color: rgba(45, 55, 72, var(--placeholder-opacity));
  }

  .md\:tw-placeholder-gray-800::placeholder {
    --placeholder-opacity: 1;
    color: #2d3748;
    color: rgba(45, 55, 72, var(--placeholder-opacity));
  }

  .md\:tw-placeholder-gray-900::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #1a202c;
    color: rgba(26, 32, 44, var(--placeholder-opacity));
  }

  .md\:tw-placeholder-gray-900::placeholder {
    --placeholder-opacity: 1;
    color: #1a202c;
    color: rgba(26, 32, 44, var(--placeholder-opacity));
  }

  .md\:tw-placeholder-red-100::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #fff5f5;
    color: rgba(255, 245, 245, var(--placeholder-opacity));
  }

  .md\:tw-placeholder-red-100::placeholder {
    --placeholder-opacity: 1;
    color: #fff5f5;
    color: rgba(255, 245, 245, var(--placeholder-opacity));
  }

  .md\:tw-placeholder-red-200::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #fed7d7;
    color: rgba(254, 215, 215, var(--placeholder-opacity));
  }

  .md\:tw-placeholder-red-200::placeholder {
    --placeholder-opacity: 1;
    color: #fed7d7;
    color: rgba(254, 215, 215, var(--placeholder-opacity));
  }

  .md\:tw-placeholder-red-300::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #feb2b2;
    color: rgba(254, 178, 178, var(--placeholder-opacity));
  }

  .md\:tw-placeholder-red-300::placeholder {
    --placeholder-opacity: 1;
    color: #feb2b2;
    color: rgba(254, 178, 178, var(--placeholder-opacity));
  }

  .md\:tw-placeholder-red-400::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #fc8181;
    color: rgba(252, 129, 129, var(--placeholder-opacity));
  }

  .md\:tw-placeholder-red-400::placeholder {
    --placeholder-opacity: 1;
    color: #fc8181;
    color: rgba(252, 129, 129, var(--placeholder-opacity));
  }

  .md\:tw-placeholder-red-500::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #f56565;
    color: rgba(245, 101, 101, var(--placeholder-opacity));
  }

  .md\:tw-placeholder-red-500::placeholder {
    --placeholder-opacity: 1;
    color: #f56565;
    color: rgba(245, 101, 101, var(--placeholder-opacity));
  }

  .md\:tw-placeholder-red-600::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #e53e3e;
    color: rgba(229, 62, 62, var(--placeholder-opacity));
  }

  .md\:tw-placeholder-red-600::placeholder {
    --placeholder-opacity: 1;
    color: #e53e3e;
    color: rgba(229, 62, 62, var(--placeholder-opacity));
  }

  .md\:tw-placeholder-red-700::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #c53030;
    color: rgba(197, 48, 48, var(--placeholder-opacity));
  }

  .md\:tw-placeholder-red-700::placeholder {
    --placeholder-opacity: 1;
    color: #c53030;
    color: rgba(197, 48, 48, var(--placeholder-opacity));
  }

  .md\:tw-placeholder-red-800::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #9b2c2c;
    color: rgba(155, 44, 44, var(--placeholder-opacity));
  }

  .md\:tw-placeholder-red-800::placeholder {
    --placeholder-opacity: 1;
    color: #9b2c2c;
    color: rgba(155, 44, 44, var(--placeholder-opacity));
  }

  .md\:tw-placeholder-red-900::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #742a2a;
    color: rgba(116, 42, 42, var(--placeholder-opacity));
  }

  .md\:tw-placeholder-red-900::placeholder {
    --placeholder-opacity: 1;
    color: #742a2a;
    color: rgba(116, 42, 42, var(--placeholder-opacity));
  }

  .md\:tw-placeholder-orange-100::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #fffaf0;
    color: rgba(255, 250, 240, var(--placeholder-opacity));
  }

  .md\:tw-placeholder-orange-100::placeholder {
    --placeholder-opacity: 1;
    color: #fffaf0;
    color: rgba(255, 250, 240, var(--placeholder-opacity));
  }

  .md\:tw-placeholder-orange-200::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #feebc8;
    color: rgba(254, 235, 200, var(--placeholder-opacity));
  }

  .md\:tw-placeholder-orange-200::placeholder {
    --placeholder-opacity: 1;
    color: #feebc8;
    color: rgba(254, 235, 200, var(--placeholder-opacity));
  }

  .md\:tw-placeholder-orange-300::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #fbd38d;
    color: rgba(251, 211, 141, var(--placeholder-opacity));
  }

  .md\:tw-placeholder-orange-300::placeholder {
    --placeholder-opacity: 1;
    color: #fbd38d;
    color: rgba(251, 211, 141, var(--placeholder-opacity));
  }

  .md\:tw-placeholder-orange-400::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #f6ad55;
    color: rgba(246, 173, 85, var(--placeholder-opacity));
  }

  .md\:tw-placeholder-orange-400::placeholder {
    --placeholder-opacity: 1;
    color: #f6ad55;
    color: rgba(246, 173, 85, var(--placeholder-opacity));
  }

  .md\:tw-placeholder-orange-500::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #ed8936;
    color: rgba(237, 137, 54, var(--placeholder-opacity));
  }

  .md\:tw-placeholder-orange-500::placeholder {
    --placeholder-opacity: 1;
    color: #ed8936;
    color: rgba(237, 137, 54, var(--placeholder-opacity));
  }

  .md\:tw-placeholder-orange-600::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #dd6b20;
    color: rgba(221, 107, 32, var(--placeholder-opacity));
  }

  .md\:tw-placeholder-orange-600::placeholder {
    --placeholder-opacity: 1;
    color: #dd6b20;
    color: rgba(221, 107, 32, var(--placeholder-opacity));
  }

  .md\:tw-placeholder-orange-700::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #c05621;
    color: rgba(192, 86, 33, var(--placeholder-opacity));
  }

  .md\:tw-placeholder-orange-700::placeholder {
    --placeholder-opacity: 1;
    color: #c05621;
    color: rgba(192, 86, 33, var(--placeholder-opacity));
  }

  .md\:tw-placeholder-orange-800::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #9c4221;
    color: rgba(156, 66, 33, var(--placeholder-opacity));
  }

  .md\:tw-placeholder-orange-800::placeholder {
    --placeholder-opacity: 1;
    color: #9c4221;
    color: rgba(156, 66, 33, var(--placeholder-opacity));
  }

  .md\:tw-placeholder-orange-900::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #7b341e;
    color: rgba(123, 52, 30, var(--placeholder-opacity));
  }

  .md\:tw-placeholder-orange-900::placeholder {
    --placeholder-opacity: 1;
    color: #7b341e;
    color: rgba(123, 52, 30, var(--placeholder-opacity));
  }

  .md\:tw-placeholder-yellow-100::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #fffff0;
    color: rgba(255, 255, 240, var(--placeholder-opacity));
  }

  .md\:tw-placeholder-yellow-100::placeholder {
    --placeholder-opacity: 1;
    color: #fffff0;
    color: rgba(255, 255, 240, var(--placeholder-opacity));
  }

  .md\:tw-placeholder-yellow-200::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #fefcbf;
    color: rgba(254, 252, 191, var(--placeholder-opacity));
  }

  .md\:tw-placeholder-yellow-200::placeholder {
    --placeholder-opacity: 1;
    color: #fefcbf;
    color: rgba(254, 252, 191, var(--placeholder-opacity));
  }

  .md\:tw-placeholder-yellow-300::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #faf089;
    color: rgba(250, 240, 137, var(--placeholder-opacity));
  }

  .md\:tw-placeholder-yellow-300::placeholder {
    --placeholder-opacity: 1;
    color: #faf089;
    color: rgba(250, 240, 137, var(--placeholder-opacity));
  }

  .md\:tw-placeholder-yellow-400::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #f6e05e;
    color: rgba(246, 224, 94, var(--placeholder-opacity));
  }

  .md\:tw-placeholder-yellow-400::placeholder {
    --placeholder-opacity: 1;
    color: #f6e05e;
    color: rgba(246, 224, 94, var(--placeholder-opacity));
  }

  .md\:tw-placeholder-yellow-500::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #ecc94b;
    color: rgba(236, 201, 75, var(--placeholder-opacity));
  }

  .md\:tw-placeholder-yellow-500::placeholder {
    --placeholder-opacity: 1;
    color: #ecc94b;
    color: rgba(236, 201, 75, var(--placeholder-opacity));
  }

  .md\:tw-placeholder-yellow-600::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #d69e2e;
    color: rgba(214, 158, 46, var(--placeholder-opacity));
  }

  .md\:tw-placeholder-yellow-600::placeholder {
    --placeholder-opacity: 1;
    color: #d69e2e;
    color: rgba(214, 158, 46, var(--placeholder-opacity));
  }

  .md\:tw-placeholder-yellow-700::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #b7791f;
    color: rgba(183, 121, 31, var(--placeholder-opacity));
  }

  .md\:tw-placeholder-yellow-700::placeholder {
    --placeholder-opacity: 1;
    color: #b7791f;
    color: rgba(183, 121, 31, var(--placeholder-opacity));
  }

  .md\:tw-placeholder-yellow-800::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #975a16;
    color: rgba(151, 90, 22, var(--placeholder-opacity));
  }

  .md\:tw-placeholder-yellow-800::placeholder {
    --placeholder-opacity: 1;
    color: #975a16;
    color: rgba(151, 90, 22, var(--placeholder-opacity));
  }

  .md\:tw-placeholder-yellow-900::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #744210;
    color: rgba(116, 66, 16, var(--placeholder-opacity));
  }

  .md\:tw-placeholder-yellow-900::placeholder {
    --placeholder-opacity: 1;
    color: #744210;
    color: rgba(116, 66, 16, var(--placeholder-opacity));
  }

  .md\:tw-placeholder-green-100::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #f0fff4;
    color: rgba(240, 255, 244, var(--placeholder-opacity));
  }

  .md\:tw-placeholder-green-100::placeholder {
    --placeholder-opacity: 1;
    color: #f0fff4;
    color: rgba(240, 255, 244, var(--placeholder-opacity));
  }

  .md\:tw-placeholder-green-200::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #c6f6d5;
    color: rgba(198, 246, 213, var(--placeholder-opacity));
  }

  .md\:tw-placeholder-green-200::placeholder {
    --placeholder-opacity: 1;
    color: #c6f6d5;
    color: rgba(198, 246, 213, var(--placeholder-opacity));
  }

  .md\:tw-placeholder-green-300::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #9ae6b4;
    color: rgba(154, 230, 180, var(--placeholder-opacity));
  }

  .md\:tw-placeholder-green-300::placeholder {
    --placeholder-opacity: 1;
    color: #9ae6b4;
    color: rgba(154, 230, 180, var(--placeholder-opacity));
  }

  .md\:tw-placeholder-green-400::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #68d391;
    color: rgba(104, 211, 145, var(--placeholder-opacity));
  }

  .md\:tw-placeholder-green-400::placeholder {
    --placeholder-opacity: 1;
    color: #68d391;
    color: rgba(104, 211, 145, var(--placeholder-opacity));
  }

  .md\:tw-placeholder-green-500::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #48bb78;
    color: rgba(72, 187, 120, var(--placeholder-opacity));
  }

  .md\:tw-placeholder-green-500::placeholder {
    --placeholder-opacity: 1;
    color: #48bb78;
    color: rgba(72, 187, 120, var(--placeholder-opacity));
  }

  .md\:tw-placeholder-green-600::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #38a169;
    color: rgba(56, 161, 105, var(--placeholder-opacity));
  }

  .md\:tw-placeholder-green-600::placeholder {
    --placeholder-opacity: 1;
    color: #38a169;
    color: rgba(56, 161, 105, var(--placeholder-opacity));
  }

  .md\:tw-placeholder-green-700::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #2f855a;
    color: rgba(47, 133, 90, var(--placeholder-opacity));
  }

  .md\:tw-placeholder-green-700::placeholder {
    --placeholder-opacity: 1;
    color: #2f855a;
    color: rgba(47, 133, 90, var(--placeholder-opacity));
  }

  .md\:tw-placeholder-green-800::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #276749;
    color: rgba(39, 103, 73, var(--placeholder-opacity));
  }

  .md\:tw-placeholder-green-800::placeholder {
    --placeholder-opacity: 1;
    color: #276749;
    color: rgba(39, 103, 73, var(--placeholder-opacity));
  }

  .md\:tw-placeholder-green-900::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #22543d;
    color: rgba(34, 84, 61, var(--placeholder-opacity));
  }

  .md\:tw-placeholder-green-900::placeholder {
    --placeholder-opacity: 1;
    color: #22543d;
    color: rgba(34, 84, 61, var(--placeholder-opacity));
  }

  .md\:tw-placeholder-teal-100::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #e6fffa;
    color: rgba(230, 255, 250, var(--placeholder-opacity));
  }

  .md\:tw-placeholder-teal-100::placeholder {
    --placeholder-opacity: 1;
    color: #e6fffa;
    color: rgba(230, 255, 250, var(--placeholder-opacity));
  }

  .md\:tw-placeholder-teal-200::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #b2f5ea;
    color: rgba(178, 245, 234, var(--placeholder-opacity));
  }

  .md\:tw-placeholder-teal-200::placeholder {
    --placeholder-opacity: 1;
    color: #b2f5ea;
    color: rgba(178, 245, 234, var(--placeholder-opacity));
  }

  .md\:tw-placeholder-teal-300::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #81e6d9;
    color: rgba(129, 230, 217, var(--placeholder-opacity));
  }

  .md\:tw-placeholder-teal-300::placeholder {
    --placeholder-opacity: 1;
    color: #81e6d9;
    color: rgba(129, 230, 217, var(--placeholder-opacity));
  }

  .md\:tw-placeholder-teal-400::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #4fd1c5;
    color: rgba(79, 209, 197, var(--placeholder-opacity));
  }

  .md\:tw-placeholder-teal-400::placeholder {
    --placeholder-opacity: 1;
    color: #4fd1c5;
    color: rgba(79, 209, 197, var(--placeholder-opacity));
  }

  .md\:tw-placeholder-teal-500::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #38b2ac;
    color: rgba(56, 178, 172, var(--placeholder-opacity));
  }

  .md\:tw-placeholder-teal-500::placeholder {
    --placeholder-opacity: 1;
    color: #38b2ac;
    color: rgba(56, 178, 172, var(--placeholder-opacity));
  }

  .md\:tw-placeholder-teal-600::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #319795;
    color: rgba(49, 151, 149, var(--placeholder-opacity));
  }

  .md\:tw-placeholder-teal-600::placeholder {
    --placeholder-opacity: 1;
    color: #319795;
    color: rgba(49, 151, 149, var(--placeholder-opacity));
  }

  .md\:tw-placeholder-teal-700::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #2c7a7b;
    color: rgba(44, 122, 123, var(--placeholder-opacity));
  }

  .md\:tw-placeholder-teal-700::placeholder {
    --placeholder-opacity: 1;
    color: #2c7a7b;
    color: rgba(44, 122, 123, var(--placeholder-opacity));
  }

  .md\:tw-placeholder-teal-800::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #285e61;
    color: rgba(40, 94, 97, var(--placeholder-opacity));
  }

  .md\:tw-placeholder-teal-800::placeholder {
    --placeholder-opacity: 1;
    color: #285e61;
    color: rgba(40, 94, 97, var(--placeholder-opacity));
  }

  .md\:tw-placeholder-teal-900::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #234e52;
    color: rgba(35, 78, 82, var(--placeholder-opacity));
  }

  .md\:tw-placeholder-teal-900::placeholder {
    --placeholder-opacity: 1;
    color: #234e52;
    color: rgba(35, 78, 82, var(--placeholder-opacity));
  }

  .md\:tw-placeholder-blue-100::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #ebf8ff;
    color: rgba(235, 248, 255, var(--placeholder-opacity));
  }

  .md\:tw-placeholder-blue-100::placeholder {
    --placeholder-opacity: 1;
    color: #ebf8ff;
    color: rgba(235, 248, 255, var(--placeholder-opacity));
  }

  .md\:tw-placeholder-blue-200::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #bee3f8;
    color: rgba(190, 227, 248, var(--placeholder-opacity));
  }

  .md\:tw-placeholder-blue-200::placeholder {
    --placeholder-opacity: 1;
    color: #bee3f8;
    color: rgba(190, 227, 248, var(--placeholder-opacity));
  }

  .md\:tw-placeholder-blue-300::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #90cdf4;
    color: rgba(144, 205, 244, var(--placeholder-opacity));
  }

  .md\:tw-placeholder-blue-300::placeholder {
    --placeholder-opacity: 1;
    color: #90cdf4;
    color: rgba(144, 205, 244, var(--placeholder-opacity));
  }

  .md\:tw-placeholder-blue-400::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #63b3ed;
    color: rgba(99, 179, 237, var(--placeholder-opacity));
  }

  .md\:tw-placeholder-blue-400::placeholder {
    --placeholder-opacity: 1;
    color: #63b3ed;
    color: rgba(99, 179, 237, var(--placeholder-opacity));
  }

  .md\:tw-placeholder-blue-500::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #4299e1;
    color: rgba(66, 153, 225, var(--placeholder-opacity));
  }

  .md\:tw-placeholder-blue-500::placeholder {
    --placeholder-opacity: 1;
    color: #4299e1;
    color: rgba(66, 153, 225, var(--placeholder-opacity));
  }

  .md\:tw-placeholder-blue-600::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #3182ce;
    color: rgba(49, 130, 206, var(--placeholder-opacity));
  }

  .md\:tw-placeholder-blue-600::placeholder {
    --placeholder-opacity: 1;
    color: #3182ce;
    color: rgba(49, 130, 206, var(--placeholder-opacity));
  }

  .md\:tw-placeholder-blue-700::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #2b6cb0;
    color: rgba(43, 108, 176, var(--placeholder-opacity));
  }

  .md\:tw-placeholder-blue-700::placeholder {
    --placeholder-opacity: 1;
    color: #2b6cb0;
    color: rgba(43, 108, 176, var(--placeholder-opacity));
  }

  .md\:tw-placeholder-blue-800::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #2c5282;
    color: rgba(44, 82, 130, var(--placeholder-opacity));
  }

  .md\:tw-placeholder-blue-800::placeholder {
    --placeholder-opacity: 1;
    color: #2c5282;
    color: rgba(44, 82, 130, var(--placeholder-opacity));
  }

  .md\:tw-placeholder-blue-900::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #2a4365;
    color: rgba(42, 67, 101, var(--placeholder-opacity));
  }

  .md\:tw-placeholder-blue-900::placeholder {
    --placeholder-opacity: 1;
    color: #2a4365;
    color: rgba(42, 67, 101, var(--placeholder-opacity));
  }

  .md\:tw-placeholder-indigo-100::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #ebf4ff;
    color: rgba(235, 244, 255, var(--placeholder-opacity));
  }

  .md\:tw-placeholder-indigo-100::placeholder {
    --placeholder-opacity: 1;
    color: #ebf4ff;
    color: rgba(235, 244, 255, var(--placeholder-opacity));
  }

  .md\:tw-placeholder-indigo-200::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #c3dafe;
    color: rgba(195, 218, 254, var(--placeholder-opacity));
  }

  .md\:tw-placeholder-indigo-200::placeholder {
    --placeholder-opacity: 1;
    color: #c3dafe;
    color: rgba(195, 218, 254, var(--placeholder-opacity));
  }

  .md\:tw-placeholder-indigo-300::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #a3bffa;
    color: rgba(163, 191, 250, var(--placeholder-opacity));
  }

  .md\:tw-placeholder-indigo-300::placeholder {
    --placeholder-opacity: 1;
    color: #a3bffa;
    color: rgba(163, 191, 250, var(--placeholder-opacity));
  }

  .md\:tw-placeholder-indigo-400::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #7f9cf5;
    color: rgba(127, 156, 245, var(--placeholder-opacity));
  }

  .md\:tw-placeholder-indigo-400::placeholder {
    --placeholder-opacity: 1;
    color: #7f9cf5;
    color: rgba(127, 156, 245, var(--placeholder-opacity));
  }

  .md\:tw-placeholder-indigo-500::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #667eea;
    color: rgba(102, 126, 234, var(--placeholder-opacity));
  }

  .md\:tw-placeholder-indigo-500::placeholder {
    --placeholder-opacity: 1;
    color: #667eea;
    color: rgba(102, 126, 234, var(--placeholder-opacity));
  }

  .md\:tw-placeholder-indigo-600::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #5a67d8;
    color: rgba(90, 103, 216, var(--placeholder-opacity));
  }

  .md\:tw-placeholder-indigo-600::placeholder {
    --placeholder-opacity: 1;
    color: #5a67d8;
    color: rgba(90, 103, 216, var(--placeholder-opacity));
  }

  .md\:tw-placeholder-indigo-700::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #4c51bf;
    color: rgba(76, 81, 191, var(--placeholder-opacity));
  }

  .md\:tw-placeholder-indigo-700::placeholder {
    --placeholder-opacity: 1;
    color: #4c51bf;
    color: rgba(76, 81, 191, var(--placeholder-opacity));
  }

  .md\:tw-placeholder-indigo-800::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #434190;
    color: rgba(67, 65, 144, var(--placeholder-opacity));
  }

  .md\:tw-placeholder-indigo-800::placeholder {
    --placeholder-opacity: 1;
    color: #434190;
    color: rgba(67, 65, 144, var(--placeholder-opacity));
  }

  .md\:tw-placeholder-indigo-900::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #3c366b;
    color: rgba(60, 54, 107, var(--placeholder-opacity));
  }

  .md\:tw-placeholder-indigo-900::placeholder {
    --placeholder-opacity: 1;
    color: #3c366b;
    color: rgba(60, 54, 107, var(--placeholder-opacity));
  }

  .md\:tw-placeholder-purple-100::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #faf5ff;
    color: rgba(250, 245, 255, var(--placeholder-opacity));
  }

  .md\:tw-placeholder-purple-100::placeholder {
    --placeholder-opacity: 1;
    color: #faf5ff;
    color: rgba(250, 245, 255, var(--placeholder-opacity));
  }

  .md\:tw-placeholder-purple-200::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #e9d8fd;
    color: rgba(233, 216, 253, var(--placeholder-opacity));
  }

  .md\:tw-placeholder-purple-200::placeholder {
    --placeholder-opacity: 1;
    color: #e9d8fd;
    color: rgba(233, 216, 253, var(--placeholder-opacity));
  }

  .md\:tw-placeholder-purple-300::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #d6bcfa;
    color: rgba(214, 188, 250, var(--placeholder-opacity));
  }

  .md\:tw-placeholder-purple-300::placeholder {
    --placeholder-opacity: 1;
    color: #d6bcfa;
    color: rgba(214, 188, 250, var(--placeholder-opacity));
  }

  .md\:tw-placeholder-purple-400::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #b794f4;
    color: rgba(183, 148, 244, var(--placeholder-opacity));
  }

  .md\:tw-placeholder-purple-400::placeholder {
    --placeholder-opacity: 1;
    color: #b794f4;
    color: rgba(183, 148, 244, var(--placeholder-opacity));
  }

  .md\:tw-placeholder-purple-500::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #9f7aea;
    color: rgba(159, 122, 234, var(--placeholder-opacity));
  }

  .md\:tw-placeholder-purple-500::placeholder {
    --placeholder-opacity: 1;
    color: #9f7aea;
    color: rgba(159, 122, 234, var(--placeholder-opacity));
  }

  .md\:tw-placeholder-purple-600::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #805ad5;
    color: rgba(128, 90, 213, var(--placeholder-opacity));
  }

  .md\:tw-placeholder-purple-600::placeholder {
    --placeholder-opacity: 1;
    color: #805ad5;
    color: rgba(128, 90, 213, var(--placeholder-opacity));
  }

  .md\:tw-placeholder-purple-700::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #6b46c1;
    color: rgba(107, 70, 193, var(--placeholder-opacity));
  }

  .md\:tw-placeholder-purple-700::placeholder {
    --placeholder-opacity: 1;
    color: #6b46c1;
    color: rgba(107, 70, 193, var(--placeholder-opacity));
  }

  .md\:tw-placeholder-purple-800::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #553c9a;
    color: rgba(85, 60, 154, var(--placeholder-opacity));
  }

  .md\:tw-placeholder-purple-800::placeholder {
    --placeholder-opacity: 1;
    color: #553c9a;
    color: rgba(85, 60, 154, var(--placeholder-opacity));
  }

  .md\:tw-placeholder-purple-900::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #44337a;
    color: rgba(68, 51, 122, var(--placeholder-opacity));
  }

  .md\:tw-placeholder-purple-900::placeholder {
    --placeholder-opacity: 1;
    color: #44337a;
    color: rgba(68, 51, 122, var(--placeholder-opacity));
  }

  .md\:tw-placeholder-pink-100::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #fff5f7;
    color: rgba(255, 245, 247, var(--placeholder-opacity));
  }

  .md\:tw-placeholder-pink-100::placeholder {
    --placeholder-opacity: 1;
    color: #fff5f7;
    color: rgba(255, 245, 247, var(--placeholder-opacity));
  }

  .md\:tw-placeholder-pink-200::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #fed7e2;
    color: rgba(254, 215, 226, var(--placeholder-opacity));
  }

  .md\:tw-placeholder-pink-200::placeholder {
    --placeholder-opacity: 1;
    color: #fed7e2;
    color: rgba(254, 215, 226, var(--placeholder-opacity));
  }

  .md\:tw-placeholder-pink-300::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #fbb6ce;
    color: rgba(251, 182, 206, var(--placeholder-opacity));
  }

  .md\:tw-placeholder-pink-300::placeholder {
    --placeholder-opacity: 1;
    color: #fbb6ce;
    color: rgba(251, 182, 206, var(--placeholder-opacity));
  }

  .md\:tw-placeholder-pink-400::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #f687b3;
    color: rgba(246, 135, 179, var(--placeholder-opacity));
  }

  .md\:tw-placeholder-pink-400::placeholder {
    --placeholder-opacity: 1;
    color: #f687b3;
    color: rgba(246, 135, 179, var(--placeholder-opacity));
  }

  .md\:tw-placeholder-pink-500::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #ed64a6;
    color: rgba(237, 100, 166, var(--placeholder-opacity));
  }

  .md\:tw-placeholder-pink-500::placeholder {
    --placeholder-opacity: 1;
    color: #ed64a6;
    color: rgba(237, 100, 166, var(--placeholder-opacity));
  }

  .md\:tw-placeholder-pink-600::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #d53f8c;
    color: rgba(213, 63, 140, var(--placeholder-opacity));
  }

  .md\:tw-placeholder-pink-600::placeholder {
    --placeholder-opacity: 1;
    color: #d53f8c;
    color: rgba(213, 63, 140, var(--placeholder-opacity));
  }

  .md\:tw-placeholder-pink-700::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #b83280;
    color: rgba(184, 50, 128, var(--placeholder-opacity));
  }

  .md\:tw-placeholder-pink-700::placeholder {
    --placeholder-opacity: 1;
    color: #b83280;
    color: rgba(184, 50, 128, var(--placeholder-opacity));
  }

  .md\:tw-placeholder-pink-800::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #97266d;
    color: rgba(151, 38, 109, var(--placeholder-opacity));
  }

  .md\:tw-placeholder-pink-800::placeholder {
    --placeholder-opacity: 1;
    color: #97266d;
    color: rgba(151, 38, 109, var(--placeholder-opacity));
  }

  .md\:tw-placeholder-pink-900::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #702459;
    color: rgba(112, 36, 89, var(--placeholder-opacity));
  }

  .md\:tw-placeholder-pink-900::placeholder {
    --placeholder-opacity: 1;
    color: #702459;
    color: rgba(112, 36, 89, var(--placeholder-opacity));
  }

  .md\:focus\:tw-placeholder-transparent:focus::-moz-placeholder {
    color: transparent;
  }

  .md\:focus\:tw-placeholder-transparent:focus::placeholder {
    color: transparent;
  }

  .md\:focus\:tw-placeholder-current:focus::-moz-placeholder {
    color: currentColor;
  }

  .md\:focus\:tw-placeholder-current:focus::placeholder {
    color: currentColor;
  }

  .md\:focus\:tw-placeholder-black:focus::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #000;
    color: rgba(0, 0, 0, var(--placeholder-opacity));
  }

  .md\:focus\:tw-placeholder-black:focus::placeholder {
    --placeholder-opacity: 1;
    color: #000;
    color: rgba(0, 0, 0, var(--placeholder-opacity));
  }

  .md\:focus\:tw-placeholder-white:focus::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #fff;
    color: rgba(255, 255, 255, var(--placeholder-opacity));
  }

  .md\:focus\:tw-placeholder-white:focus::placeholder {
    --placeholder-opacity: 1;
    color: #fff;
    color: rgba(255, 255, 255, var(--placeholder-opacity));
  }

  .md\:focus\:tw-placeholder-gray-100:focus::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #f7fafc;
    color: rgba(247, 250, 252, var(--placeholder-opacity));
  }

  .md\:focus\:tw-placeholder-gray-100:focus::placeholder {
    --placeholder-opacity: 1;
    color: #f7fafc;
    color: rgba(247, 250, 252, var(--placeholder-opacity));
  }

  .md\:focus\:tw-placeholder-gray-200:focus::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #edf2f7;
    color: rgba(237, 242, 247, var(--placeholder-opacity));
  }

  .md\:focus\:tw-placeholder-gray-200:focus::placeholder {
    --placeholder-opacity: 1;
    color: #edf2f7;
    color: rgba(237, 242, 247, var(--placeholder-opacity));
  }

  .md\:focus\:tw-placeholder-gray-300:focus::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #e2e8f0;
    color: rgba(226, 232, 240, var(--placeholder-opacity));
  }

  .md\:focus\:tw-placeholder-gray-300:focus::placeholder {
    --placeholder-opacity: 1;
    color: #e2e8f0;
    color: rgba(226, 232, 240, var(--placeholder-opacity));
  }

  .md\:focus\:tw-placeholder-gray-400:focus::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #cbd5e0;
    color: rgba(203, 213, 224, var(--placeholder-opacity));
  }

  .md\:focus\:tw-placeholder-gray-400:focus::placeholder {
    --placeholder-opacity: 1;
    color: #cbd5e0;
    color: rgba(203, 213, 224, var(--placeholder-opacity));
  }

  .md\:focus\:tw-placeholder-gray-500:focus::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #a0aec0;
    color: rgba(160, 174, 192, var(--placeholder-opacity));
  }

  .md\:focus\:tw-placeholder-gray-500:focus::placeholder {
    --placeholder-opacity: 1;
    color: #a0aec0;
    color: rgba(160, 174, 192, var(--placeholder-opacity));
  }

  .md\:focus\:tw-placeholder-gray-600:focus::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #718096;
    color: rgba(113, 128, 150, var(--placeholder-opacity));
  }

  .md\:focus\:tw-placeholder-gray-600:focus::placeholder {
    --placeholder-opacity: 1;
    color: #718096;
    color: rgba(113, 128, 150, var(--placeholder-opacity));
  }

  .md\:focus\:tw-placeholder-gray-700:focus::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #4a5568;
    color: rgba(74, 85, 104, var(--placeholder-opacity));
  }

  .md\:focus\:tw-placeholder-gray-700:focus::placeholder {
    --placeholder-opacity: 1;
    color: #4a5568;
    color: rgba(74, 85, 104, var(--placeholder-opacity));
  }

  .md\:focus\:tw-placeholder-gray-800:focus::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #2d3748;
    color: rgba(45, 55, 72, var(--placeholder-opacity));
  }

  .md\:focus\:tw-placeholder-gray-800:focus::placeholder {
    --placeholder-opacity: 1;
    color: #2d3748;
    color: rgba(45, 55, 72, var(--placeholder-opacity));
  }

  .md\:focus\:tw-placeholder-gray-900:focus::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #1a202c;
    color: rgba(26, 32, 44, var(--placeholder-opacity));
  }

  .md\:focus\:tw-placeholder-gray-900:focus::placeholder {
    --placeholder-opacity: 1;
    color: #1a202c;
    color: rgba(26, 32, 44, var(--placeholder-opacity));
  }

  .md\:focus\:tw-placeholder-red-100:focus::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #fff5f5;
    color: rgba(255, 245, 245, var(--placeholder-opacity));
  }

  .md\:focus\:tw-placeholder-red-100:focus::placeholder {
    --placeholder-opacity: 1;
    color: #fff5f5;
    color: rgba(255, 245, 245, var(--placeholder-opacity));
  }

  .md\:focus\:tw-placeholder-red-200:focus::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #fed7d7;
    color: rgba(254, 215, 215, var(--placeholder-opacity));
  }

  .md\:focus\:tw-placeholder-red-200:focus::placeholder {
    --placeholder-opacity: 1;
    color: #fed7d7;
    color: rgba(254, 215, 215, var(--placeholder-opacity));
  }

  .md\:focus\:tw-placeholder-red-300:focus::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #feb2b2;
    color: rgba(254, 178, 178, var(--placeholder-opacity));
  }

  .md\:focus\:tw-placeholder-red-300:focus::placeholder {
    --placeholder-opacity: 1;
    color: #feb2b2;
    color: rgba(254, 178, 178, var(--placeholder-opacity));
  }

  .md\:focus\:tw-placeholder-red-400:focus::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #fc8181;
    color: rgba(252, 129, 129, var(--placeholder-opacity));
  }

  .md\:focus\:tw-placeholder-red-400:focus::placeholder {
    --placeholder-opacity: 1;
    color: #fc8181;
    color: rgba(252, 129, 129, var(--placeholder-opacity));
  }

  .md\:focus\:tw-placeholder-red-500:focus::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #f56565;
    color: rgba(245, 101, 101, var(--placeholder-opacity));
  }

  .md\:focus\:tw-placeholder-red-500:focus::placeholder {
    --placeholder-opacity: 1;
    color: #f56565;
    color: rgba(245, 101, 101, var(--placeholder-opacity));
  }

  .md\:focus\:tw-placeholder-red-600:focus::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #e53e3e;
    color: rgba(229, 62, 62, var(--placeholder-opacity));
  }

  .md\:focus\:tw-placeholder-red-600:focus::placeholder {
    --placeholder-opacity: 1;
    color: #e53e3e;
    color: rgba(229, 62, 62, var(--placeholder-opacity));
  }

  .md\:focus\:tw-placeholder-red-700:focus::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #c53030;
    color: rgba(197, 48, 48, var(--placeholder-opacity));
  }

  .md\:focus\:tw-placeholder-red-700:focus::placeholder {
    --placeholder-opacity: 1;
    color: #c53030;
    color: rgba(197, 48, 48, var(--placeholder-opacity));
  }

  .md\:focus\:tw-placeholder-red-800:focus::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #9b2c2c;
    color: rgba(155, 44, 44, var(--placeholder-opacity));
  }

  .md\:focus\:tw-placeholder-red-800:focus::placeholder {
    --placeholder-opacity: 1;
    color: #9b2c2c;
    color: rgba(155, 44, 44, var(--placeholder-opacity));
  }

  .md\:focus\:tw-placeholder-red-900:focus::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #742a2a;
    color: rgba(116, 42, 42, var(--placeholder-opacity));
  }

  .md\:focus\:tw-placeholder-red-900:focus::placeholder {
    --placeholder-opacity: 1;
    color: #742a2a;
    color: rgba(116, 42, 42, var(--placeholder-opacity));
  }

  .md\:focus\:tw-placeholder-orange-100:focus::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #fffaf0;
    color: rgba(255, 250, 240, var(--placeholder-opacity));
  }

  .md\:focus\:tw-placeholder-orange-100:focus::placeholder {
    --placeholder-opacity: 1;
    color: #fffaf0;
    color: rgba(255, 250, 240, var(--placeholder-opacity));
  }

  .md\:focus\:tw-placeholder-orange-200:focus::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #feebc8;
    color: rgba(254, 235, 200, var(--placeholder-opacity));
  }

  .md\:focus\:tw-placeholder-orange-200:focus::placeholder {
    --placeholder-opacity: 1;
    color: #feebc8;
    color: rgba(254, 235, 200, var(--placeholder-opacity));
  }

  .md\:focus\:tw-placeholder-orange-300:focus::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #fbd38d;
    color: rgba(251, 211, 141, var(--placeholder-opacity));
  }

  .md\:focus\:tw-placeholder-orange-300:focus::placeholder {
    --placeholder-opacity: 1;
    color: #fbd38d;
    color: rgba(251, 211, 141, var(--placeholder-opacity));
  }

  .md\:focus\:tw-placeholder-orange-400:focus::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #f6ad55;
    color: rgba(246, 173, 85, var(--placeholder-opacity));
  }

  .md\:focus\:tw-placeholder-orange-400:focus::placeholder {
    --placeholder-opacity: 1;
    color: #f6ad55;
    color: rgba(246, 173, 85, var(--placeholder-opacity));
  }

  .md\:focus\:tw-placeholder-orange-500:focus::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #ed8936;
    color: rgba(237, 137, 54, var(--placeholder-opacity));
  }

  .md\:focus\:tw-placeholder-orange-500:focus::placeholder {
    --placeholder-opacity: 1;
    color: #ed8936;
    color: rgba(237, 137, 54, var(--placeholder-opacity));
  }

  .md\:focus\:tw-placeholder-orange-600:focus::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #dd6b20;
    color: rgba(221, 107, 32, var(--placeholder-opacity));
  }

  .md\:focus\:tw-placeholder-orange-600:focus::placeholder {
    --placeholder-opacity: 1;
    color: #dd6b20;
    color: rgba(221, 107, 32, var(--placeholder-opacity));
  }

  .md\:focus\:tw-placeholder-orange-700:focus::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #c05621;
    color: rgba(192, 86, 33, var(--placeholder-opacity));
  }

  .md\:focus\:tw-placeholder-orange-700:focus::placeholder {
    --placeholder-opacity: 1;
    color: #c05621;
    color: rgba(192, 86, 33, var(--placeholder-opacity));
  }

  .md\:focus\:tw-placeholder-orange-800:focus::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #9c4221;
    color: rgba(156, 66, 33, var(--placeholder-opacity));
  }

  .md\:focus\:tw-placeholder-orange-800:focus::placeholder {
    --placeholder-opacity: 1;
    color: #9c4221;
    color: rgba(156, 66, 33, var(--placeholder-opacity));
  }

  .md\:focus\:tw-placeholder-orange-900:focus::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #7b341e;
    color: rgba(123, 52, 30, var(--placeholder-opacity));
  }

  .md\:focus\:tw-placeholder-orange-900:focus::placeholder {
    --placeholder-opacity: 1;
    color: #7b341e;
    color: rgba(123, 52, 30, var(--placeholder-opacity));
  }

  .md\:focus\:tw-placeholder-yellow-100:focus::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #fffff0;
    color: rgba(255, 255, 240, var(--placeholder-opacity));
  }

  .md\:focus\:tw-placeholder-yellow-100:focus::placeholder {
    --placeholder-opacity: 1;
    color: #fffff0;
    color: rgba(255, 255, 240, var(--placeholder-opacity));
  }

  .md\:focus\:tw-placeholder-yellow-200:focus::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #fefcbf;
    color: rgba(254, 252, 191, var(--placeholder-opacity));
  }

  .md\:focus\:tw-placeholder-yellow-200:focus::placeholder {
    --placeholder-opacity: 1;
    color: #fefcbf;
    color: rgba(254, 252, 191, var(--placeholder-opacity));
  }

  .md\:focus\:tw-placeholder-yellow-300:focus::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #faf089;
    color: rgba(250, 240, 137, var(--placeholder-opacity));
  }

  .md\:focus\:tw-placeholder-yellow-300:focus::placeholder {
    --placeholder-opacity: 1;
    color: #faf089;
    color: rgba(250, 240, 137, var(--placeholder-opacity));
  }

  .md\:focus\:tw-placeholder-yellow-400:focus::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #f6e05e;
    color: rgba(246, 224, 94, var(--placeholder-opacity));
  }

  .md\:focus\:tw-placeholder-yellow-400:focus::placeholder {
    --placeholder-opacity: 1;
    color: #f6e05e;
    color: rgba(246, 224, 94, var(--placeholder-opacity));
  }

  .md\:focus\:tw-placeholder-yellow-500:focus::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #ecc94b;
    color: rgba(236, 201, 75, var(--placeholder-opacity));
  }

  .md\:focus\:tw-placeholder-yellow-500:focus::placeholder {
    --placeholder-opacity: 1;
    color: #ecc94b;
    color: rgba(236, 201, 75, var(--placeholder-opacity));
  }

  .md\:focus\:tw-placeholder-yellow-600:focus::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #d69e2e;
    color: rgba(214, 158, 46, var(--placeholder-opacity));
  }

  .md\:focus\:tw-placeholder-yellow-600:focus::placeholder {
    --placeholder-opacity: 1;
    color: #d69e2e;
    color: rgba(214, 158, 46, var(--placeholder-opacity));
  }

  .md\:focus\:tw-placeholder-yellow-700:focus::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #b7791f;
    color: rgba(183, 121, 31, var(--placeholder-opacity));
  }

  .md\:focus\:tw-placeholder-yellow-700:focus::placeholder {
    --placeholder-opacity: 1;
    color: #b7791f;
    color: rgba(183, 121, 31, var(--placeholder-opacity));
  }

  .md\:focus\:tw-placeholder-yellow-800:focus::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #975a16;
    color: rgba(151, 90, 22, var(--placeholder-opacity));
  }

  .md\:focus\:tw-placeholder-yellow-800:focus::placeholder {
    --placeholder-opacity: 1;
    color: #975a16;
    color: rgba(151, 90, 22, var(--placeholder-opacity));
  }

  .md\:focus\:tw-placeholder-yellow-900:focus::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #744210;
    color: rgba(116, 66, 16, var(--placeholder-opacity));
  }

  .md\:focus\:tw-placeholder-yellow-900:focus::placeholder {
    --placeholder-opacity: 1;
    color: #744210;
    color: rgba(116, 66, 16, var(--placeholder-opacity));
  }

  .md\:focus\:tw-placeholder-green-100:focus::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #f0fff4;
    color: rgba(240, 255, 244, var(--placeholder-opacity));
  }

  .md\:focus\:tw-placeholder-green-100:focus::placeholder {
    --placeholder-opacity: 1;
    color: #f0fff4;
    color: rgba(240, 255, 244, var(--placeholder-opacity));
  }

  .md\:focus\:tw-placeholder-green-200:focus::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #c6f6d5;
    color: rgba(198, 246, 213, var(--placeholder-opacity));
  }

  .md\:focus\:tw-placeholder-green-200:focus::placeholder {
    --placeholder-opacity: 1;
    color: #c6f6d5;
    color: rgba(198, 246, 213, var(--placeholder-opacity));
  }

  .md\:focus\:tw-placeholder-green-300:focus::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #9ae6b4;
    color: rgba(154, 230, 180, var(--placeholder-opacity));
  }

  .md\:focus\:tw-placeholder-green-300:focus::placeholder {
    --placeholder-opacity: 1;
    color: #9ae6b4;
    color: rgba(154, 230, 180, var(--placeholder-opacity));
  }

  .md\:focus\:tw-placeholder-green-400:focus::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #68d391;
    color: rgba(104, 211, 145, var(--placeholder-opacity));
  }

  .md\:focus\:tw-placeholder-green-400:focus::placeholder {
    --placeholder-opacity: 1;
    color: #68d391;
    color: rgba(104, 211, 145, var(--placeholder-opacity));
  }

  .md\:focus\:tw-placeholder-green-500:focus::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #48bb78;
    color: rgba(72, 187, 120, var(--placeholder-opacity));
  }

  .md\:focus\:tw-placeholder-green-500:focus::placeholder {
    --placeholder-opacity: 1;
    color: #48bb78;
    color: rgba(72, 187, 120, var(--placeholder-opacity));
  }

  .md\:focus\:tw-placeholder-green-600:focus::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #38a169;
    color: rgba(56, 161, 105, var(--placeholder-opacity));
  }

  .md\:focus\:tw-placeholder-green-600:focus::placeholder {
    --placeholder-opacity: 1;
    color: #38a169;
    color: rgba(56, 161, 105, var(--placeholder-opacity));
  }

  .md\:focus\:tw-placeholder-green-700:focus::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #2f855a;
    color: rgba(47, 133, 90, var(--placeholder-opacity));
  }

  .md\:focus\:tw-placeholder-green-700:focus::placeholder {
    --placeholder-opacity: 1;
    color: #2f855a;
    color: rgba(47, 133, 90, var(--placeholder-opacity));
  }

  .md\:focus\:tw-placeholder-green-800:focus::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #276749;
    color: rgba(39, 103, 73, var(--placeholder-opacity));
  }

  .md\:focus\:tw-placeholder-green-800:focus::placeholder {
    --placeholder-opacity: 1;
    color: #276749;
    color: rgba(39, 103, 73, var(--placeholder-opacity));
  }

  .md\:focus\:tw-placeholder-green-900:focus::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #22543d;
    color: rgba(34, 84, 61, var(--placeholder-opacity));
  }

  .md\:focus\:tw-placeholder-green-900:focus::placeholder {
    --placeholder-opacity: 1;
    color: #22543d;
    color: rgba(34, 84, 61, var(--placeholder-opacity));
  }

  .md\:focus\:tw-placeholder-teal-100:focus::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #e6fffa;
    color: rgba(230, 255, 250, var(--placeholder-opacity));
  }

  .md\:focus\:tw-placeholder-teal-100:focus::placeholder {
    --placeholder-opacity: 1;
    color: #e6fffa;
    color: rgba(230, 255, 250, var(--placeholder-opacity));
  }

  .md\:focus\:tw-placeholder-teal-200:focus::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #b2f5ea;
    color: rgba(178, 245, 234, var(--placeholder-opacity));
  }

  .md\:focus\:tw-placeholder-teal-200:focus::placeholder {
    --placeholder-opacity: 1;
    color: #b2f5ea;
    color: rgba(178, 245, 234, var(--placeholder-opacity));
  }

  .md\:focus\:tw-placeholder-teal-300:focus::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #81e6d9;
    color: rgba(129, 230, 217, var(--placeholder-opacity));
  }

  .md\:focus\:tw-placeholder-teal-300:focus::placeholder {
    --placeholder-opacity: 1;
    color: #81e6d9;
    color: rgba(129, 230, 217, var(--placeholder-opacity));
  }

  .md\:focus\:tw-placeholder-teal-400:focus::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #4fd1c5;
    color: rgba(79, 209, 197, var(--placeholder-opacity));
  }

  .md\:focus\:tw-placeholder-teal-400:focus::placeholder {
    --placeholder-opacity: 1;
    color: #4fd1c5;
    color: rgba(79, 209, 197, var(--placeholder-opacity));
  }

  .md\:focus\:tw-placeholder-teal-500:focus::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #38b2ac;
    color: rgba(56, 178, 172, var(--placeholder-opacity));
  }

  .md\:focus\:tw-placeholder-teal-500:focus::placeholder {
    --placeholder-opacity: 1;
    color: #38b2ac;
    color: rgba(56, 178, 172, var(--placeholder-opacity));
  }

  .md\:focus\:tw-placeholder-teal-600:focus::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #319795;
    color: rgba(49, 151, 149, var(--placeholder-opacity));
  }

  .md\:focus\:tw-placeholder-teal-600:focus::placeholder {
    --placeholder-opacity: 1;
    color: #319795;
    color: rgba(49, 151, 149, var(--placeholder-opacity));
  }

  .md\:focus\:tw-placeholder-teal-700:focus::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #2c7a7b;
    color: rgba(44, 122, 123, var(--placeholder-opacity));
  }

  .md\:focus\:tw-placeholder-teal-700:focus::placeholder {
    --placeholder-opacity: 1;
    color: #2c7a7b;
    color: rgba(44, 122, 123, var(--placeholder-opacity));
  }

  .md\:focus\:tw-placeholder-teal-800:focus::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #285e61;
    color: rgba(40, 94, 97, var(--placeholder-opacity));
  }

  .md\:focus\:tw-placeholder-teal-800:focus::placeholder {
    --placeholder-opacity: 1;
    color: #285e61;
    color: rgba(40, 94, 97, var(--placeholder-opacity));
  }

  .md\:focus\:tw-placeholder-teal-900:focus::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #234e52;
    color: rgba(35, 78, 82, var(--placeholder-opacity));
  }

  .md\:focus\:tw-placeholder-teal-900:focus::placeholder {
    --placeholder-opacity: 1;
    color: #234e52;
    color: rgba(35, 78, 82, var(--placeholder-opacity));
  }

  .md\:focus\:tw-placeholder-blue-100:focus::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #ebf8ff;
    color: rgba(235, 248, 255, var(--placeholder-opacity));
  }

  .md\:focus\:tw-placeholder-blue-100:focus::placeholder {
    --placeholder-opacity: 1;
    color: #ebf8ff;
    color: rgba(235, 248, 255, var(--placeholder-opacity));
  }

  .md\:focus\:tw-placeholder-blue-200:focus::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #bee3f8;
    color: rgba(190, 227, 248, var(--placeholder-opacity));
  }

  .md\:focus\:tw-placeholder-blue-200:focus::placeholder {
    --placeholder-opacity: 1;
    color: #bee3f8;
    color: rgba(190, 227, 248, var(--placeholder-opacity));
  }

  .md\:focus\:tw-placeholder-blue-300:focus::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #90cdf4;
    color: rgba(144, 205, 244, var(--placeholder-opacity));
  }

  .md\:focus\:tw-placeholder-blue-300:focus::placeholder {
    --placeholder-opacity: 1;
    color: #90cdf4;
    color: rgba(144, 205, 244, var(--placeholder-opacity));
  }

  .md\:focus\:tw-placeholder-blue-400:focus::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #63b3ed;
    color: rgba(99, 179, 237, var(--placeholder-opacity));
  }

  .md\:focus\:tw-placeholder-blue-400:focus::placeholder {
    --placeholder-opacity: 1;
    color: #63b3ed;
    color: rgba(99, 179, 237, var(--placeholder-opacity));
  }

  .md\:focus\:tw-placeholder-blue-500:focus::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #4299e1;
    color: rgba(66, 153, 225, var(--placeholder-opacity));
  }

  .md\:focus\:tw-placeholder-blue-500:focus::placeholder {
    --placeholder-opacity: 1;
    color: #4299e1;
    color: rgba(66, 153, 225, var(--placeholder-opacity));
  }

  .md\:focus\:tw-placeholder-blue-600:focus::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #3182ce;
    color: rgba(49, 130, 206, var(--placeholder-opacity));
  }

  .md\:focus\:tw-placeholder-blue-600:focus::placeholder {
    --placeholder-opacity: 1;
    color: #3182ce;
    color: rgba(49, 130, 206, var(--placeholder-opacity));
  }

  .md\:focus\:tw-placeholder-blue-700:focus::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #2b6cb0;
    color: rgba(43, 108, 176, var(--placeholder-opacity));
  }

  .md\:focus\:tw-placeholder-blue-700:focus::placeholder {
    --placeholder-opacity: 1;
    color: #2b6cb0;
    color: rgba(43, 108, 176, var(--placeholder-opacity));
  }

  .md\:focus\:tw-placeholder-blue-800:focus::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #2c5282;
    color: rgba(44, 82, 130, var(--placeholder-opacity));
  }

  .md\:focus\:tw-placeholder-blue-800:focus::placeholder {
    --placeholder-opacity: 1;
    color: #2c5282;
    color: rgba(44, 82, 130, var(--placeholder-opacity));
  }

  .md\:focus\:tw-placeholder-blue-900:focus::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #2a4365;
    color: rgba(42, 67, 101, var(--placeholder-opacity));
  }

  .md\:focus\:tw-placeholder-blue-900:focus::placeholder {
    --placeholder-opacity: 1;
    color: #2a4365;
    color: rgba(42, 67, 101, var(--placeholder-opacity));
  }

  .md\:focus\:tw-placeholder-indigo-100:focus::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #ebf4ff;
    color: rgba(235, 244, 255, var(--placeholder-opacity));
  }

  .md\:focus\:tw-placeholder-indigo-100:focus::placeholder {
    --placeholder-opacity: 1;
    color: #ebf4ff;
    color: rgba(235, 244, 255, var(--placeholder-opacity));
  }

  .md\:focus\:tw-placeholder-indigo-200:focus::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #c3dafe;
    color: rgba(195, 218, 254, var(--placeholder-opacity));
  }

  .md\:focus\:tw-placeholder-indigo-200:focus::placeholder {
    --placeholder-opacity: 1;
    color: #c3dafe;
    color: rgba(195, 218, 254, var(--placeholder-opacity));
  }

  .md\:focus\:tw-placeholder-indigo-300:focus::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #a3bffa;
    color: rgba(163, 191, 250, var(--placeholder-opacity));
  }

  .md\:focus\:tw-placeholder-indigo-300:focus::placeholder {
    --placeholder-opacity: 1;
    color: #a3bffa;
    color: rgba(163, 191, 250, var(--placeholder-opacity));
  }

  .md\:focus\:tw-placeholder-indigo-400:focus::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #7f9cf5;
    color: rgba(127, 156, 245, var(--placeholder-opacity));
  }

  .md\:focus\:tw-placeholder-indigo-400:focus::placeholder {
    --placeholder-opacity: 1;
    color: #7f9cf5;
    color: rgba(127, 156, 245, var(--placeholder-opacity));
  }

  .md\:focus\:tw-placeholder-indigo-500:focus::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #667eea;
    color: rgba(102, 126, 234, var(--placeholder-opacity));
  }

  .md\:focus\:tw-placeholder-indigo-500:focus::placeholder {
    --placeholder-opacity: 1;
    color: #667eea;
    color: rgba(102, 126, 234, var(--placeholder-opacity));
  }

  .md\:focus\:tw-placeholder-indigo-600:focus::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #5a67d8;
    color: rgba(90, 103, 216, var(--placeholder-opacity));
  }

  .md\:focus\:tw-placeholder-indigo-600:focus::placeholder {
    --placeholder-opacity: 1;
    color: #5a67d8;
    color: rgba(90, 103, 216, var(--placeholder-opacity));
  }

  .md\:focus\:tw-placeholder-indigo-700:focus::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #4c51bf;
    color: rgba(76, 81, 191, var(--placeholder-opacity));
  }

  .md\:focus\:tw-placeholder-indigo-700:focus::placeholder {
    --placeholder-opacity: 1;
    color: #4c51bf;
    color: rgba(76, 81, 191, var(--placeholder-opacity));
  }

  .md\:focus\:tw-placeholder-indigo-800:focus::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #434190;
    color: rgba(67, 65, 144, var(--placeholder-opacity));
  }

  .md\:focus\:tw-placeholder-indigo-800:focus::placeholder {
    --placeholder-opacity: 1;
    color: #434190;
    color: rgba(67, 65, 144, var(--placeholder-opacity));
  }

  .md\:focus\:tw-placeholder-indigo-900:focus::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #3c366b;
    color: rgba(60, 54, 107, var(--placeholder-opacity));
  }

  .md\:focus\:tw-placeholder-indigo-900:focus::placeholder {
    --placeholder-opacity: 1;
    color: #3c366b;
    color: rgba(60, 54, 107, var(--placeholder-opacity));
  }

  .md\:focus\:tw-placeholder-purple-100:focus::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #faf5ff;
    color: rgba(250, 245, 255, var(--placeholder-opacity));
  }

  .md\:focus\:tw-placeholder-purple-100:focus::placeholder {
    --placeholder-opacity: 1;
    color: #faf5ff;
    color: rgba(250, 245, 255, var(--placeholder-opacity));
  }

  .md\:focus\:tw-placeholder-purple-200:focus::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #e9d8fd;
    color: rgba(233, 216, 253, var(--placeholder-opacity));
  }

  .md\:focus\:tw-placeholder-purple-200:focus::placeholder {
    --placeholder-opacity: 1;
    color: #e9d8fd;
    color: rgba(233, 216, 253, var(--placeholder-opacity));
  }

  .md\:focus\:tw-placeholder-purple-300:focus::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #d6bcfa;
    color: rgba(214, 188, 250, var(--placeholder-opacity));
  }

  .md\:focus\:tw-placeholder-purple-300:focus::placeholder {
    --placeholder-opacity: 1;
    color: #d6bcfa;
    color: rgba(214, 188, 250, var(--placeholder-opacity));
  }

  .md\:focus\:tw-placeholder-purple-400:focus::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #b794f4;
    color: rgba(183, 148, 244, var(--placeholder-opacity));
  }

  .md\:focus\:tw-placeholder-purple-400:focus::placeholder {
    --placeholder-opacity: 1;
    color: #b794f4;
    color: rgba(183, 148, 244, var(--placeholder-opacity));
  }

  .md\:focus\:tw-placeholder-purple-500:focus::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #9f7aea;
    color: rgba(159, 122, 234, var(--placeholder-opacity));
  }

  .md\:focus\:tw-placeholder-purple-500:focus::placeholder {
    --placeholder-opacity: 1;
    color: #9f7aea;
    color: rgba(159, 122, 234, var(--placeholder-opacity));
  }

  .md\:focus\:tw-placeholder-purple-600:focus::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #805ad5;
    color: rgba(128, 90, 213, var(--placeholder-opacity));
  }

  .md\:focus\:tw-placeholder-purple-600:focus::placeholder {
    --placeholder-opacity: 1;
    color: #805ad5;
    color: rgba(128, 90, 213, var(--placeholder-opacity));
  }

  .md\:focus\:tw-placeholder-purple-700:focus::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #6b46c1;
    color: rgba(107, 70, 193, var(--placeholder-opacity));
  }

  .md\:focus\:tw-placeholder-purple-700:focus::placeholder {
    --placeholder-opacity: 1;
    color: #6b46c1;
    color: rgba(107, 70, 193, var(--placeholder-opacity));
  }

  .md\:focus\:tw-placeholder-purple-800:focus::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #553c9a;
    color: rgba(85, 60, 154, var(--placeholder-opacity));
  }

  .md\:focus\:tw-placeholder-purple-800:focus::placeholder {
    --placeholder-opacity: 1;
    color: #553c9a;
    color: rgba(85, 60, 154, var(--placeholder-opacity));
  }

  .md\:focus\:tw-placeholder-purple-900:focus::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #44337a;
    color: rgba(68, 51, 122, var(--placeholder-opacity));
  }

  .md\:focus\:tw-placeholder-purple-900:focus::placeholder {
    --placeholder-opacity: 1;
    color: #44337a;
    color: rgba(68, 51, 122, var(--placeholder-opacity));
  }

  .md\:focus\:tw-placeholder-pink-100:focus::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #fff5f7;
    color: rgba(255, 245, 247, var(--placeholder-opacity));
  }

  .md\:focus\:tw-placeholder-pink-100:focus::placeholder {
    --placeholder-opacity: 1;
    color: #fff5f7;
    color: rgba(255, 245, 247, var(--placeholder-opacity));
  }

  .md\:focus\:tw-placeholder-pink-200:focus::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #fed7e2;
    color: rgba(254, 215, 226, var(--placeholder-opacity));
  }

  .md\:focus\:tw-placeholder-pink-200:focus::placeholder {
    --placeholder-opacity: 1;
    color: #fed7e2;
    color: rgba(254, 215, 226, var(--placeholder-opacity));
  }

  .md\:focus\:tw-placeholder-pink-300:focus::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #fbb6ce;
    color: rgba(251, 182, 206, var(--placeholder-opacity));
  }

  .md\:focus\:tw-placeholder-pink-300:focus::placeholder {
    --placeholder-opacity: 1;
    color: #fbb6ce;
    color: rgba(251, 182, 206, var(--placeholder-opacity));
  }

  .md\:focus\:tw-placeholder-pink-400:focus::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #f687b3;
    color: rgba(246, 135, 179, var(--placeholder-opacity));
  }

  .md\:focus\:tw-placeholder-pink-400:focus::placeholder {
    --placeholder-opacity: 1;
    color: #f687b3;
    color: rgba(246, 135, 179, var(--placeholder-opacity));
  }

  .md\:focus\:tw-placeholder-pink-500:focus::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #ed64a6;
    color: rgba(237, 100, 166, var(--placeholder-opacity));
  }

  .md\:focus\:tw-placeholder-pink-500:focus::placeholder {
    --placeholder-opacity: 1;
    color: #ed64a6;
    color: rgba(237, 100, 166, var(--placeholder-opacity));
  }

  .md\:focus\:tw-placeholder-pink-600:focus::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #d53f8c;
    color: rgba(213, 63, 140, var(--placeholder-opacity));
  }

  .md\:focus\:tw-placeholder-pink-600:focus::placeholder {
    --placeholder-opacity: 1;
    color: #d53f8c;
    color: rgba(213, 63, 140, var(--placeholder-opacity));
  }

  .md\:focus\:tw-placeholder-pink-700:focus::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #b83280;
    color: rgba(184, 50, 128, var(--placeholder-opacity));
  }

  .md\:focus\:tw-placeholder-pink-700:focus::placeholder {
    --placeholder-opacity: 1;
    color: #b83280;
    color: rgba(184, 50, 128, var(--placeholder-opacity));
  }

  .md\:focus\:tw-placeholder-pink-800:focus::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #97266d;
    color: rgba(151, 38, 109, var(--placeholder-opacity));
  }

  .md\:focus\:tw-placeholder-pink-800:focus::placeholder {
    --placeholder-opacity: 1;
    color: #97266d;
    color: rgba(151, 38, 109, var(--placeholder-opacity));
  }

  .md\:focus\:tw-placeholder-pink-900:focus::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #702459;
    color: rgba(112, 36, 89, var(--placeholder-opacity));
  }

  .md\:focus\:tw-placeholder-pink-900:focus::placeholder {
    --placeholder-opacity: 1;
    color: #702459;
    color: rgba(112, 36, 89, var(--placeholder-opacity));
  }

  .md\:tw-placeholder-opacity-0::-moz-placeholder {
    --placeholder-opacity: 0;
  }

  .md\:tw-placeholder-opacity-0::placeholder {
    --placeholder-opacity: 0;
  }

  .md\:tw-placeholder-opacity-25::-moz-placeholder {
    --placeholder-opacity: 0.25;
  }

  .md\:tw-placeholder-opacity-25::placeholder {
    --placeholder-opacity: 0.25;
  }

  .md\:tw-placeholder-opacity-50::-moz-placeholder {
    --placeholder-opacity: 0.5;
  }

  .md\:tw-placeholder-opacity-50::placeholder {
    --placeholder-opacity: 0.5;
  }

  .md\:tw-placeholder-opacity-75::-moz-placeholder {
    --placeholder-opacity: 0.75;
  }

  .md\:tw-placeholder-opacity-75::placeholder {
    --placeholder-opacity: 0.75;
  }

  .md\:tw-placeholder-opacity-100::-moz-placeholder {
    --placeholder-opacity: 1;
  }

  .md\:tw-placeholder-opacity-100::placeholder {
    --placeholder-opacity: 1;
  }

  .md\:focus\:tw-placeholder-opacity-0:focus::-moz-placeholder {
    --placeholder-opacity: 0;
  }

  .md\:focus\:tw-placeholder-opacity-0:focus::placeholder {
    --placeholder-opacity: 0;
  }

  .md\:focus\:tw-placeholder-opacity-25:focus::-moz-placeholder {
    --placeholder-opacity: 0.25;
  }

  .md\:focus\:tw-placeholder-opacity-25:focus::placeholder {
    --placeholder-opacity: 0.25;
  }

  .md\:focus\:tw-placeholder-opacity-50:focus::-moz-placeholder {
    --placeholder-opacity: 0.5;
  }

  .md\:focus\:tw-placeholder-opacity-50:focus::placeholder {
    --placeholder-opacity: 0.5;
  }

  .md\:focus\:tw-placeholder-opacity-75:focus::-moz-placeholder {
    --placeholder-opacity: 0.75;
  }

  .md\:focus\:tw-placeholder-opacity-75:focus::placeholder {
    --placeholder-opacity: 0.75;
  }

  .md\:focus\:tw-placeholder-opacity-100:focus::-moz-placeholder {
    --placeholder-opacity: 1;
  }

  .md\:focus\:tw-placeholder-opacity-100:focus::placeholder {
    --placeholder-opacity: 1;
  }

  .md\:tw-pointer-events-none {
    pointer-events: none;
  }

  .md\:tw-pointer-events-auto {
    pointer-events: auto;
  }

  .md\:tw-static {
    position: static;
  }

  .md\:tw-fixed {
    position: fixed;
  }

  .md\:tw-absolute {
    position: absolute;
  }

  .md\:tw-relative {
    position: relative;
  }

  .md\:tw-sticky {
    position: sticky;
  }

  .md\:tw-inset-0 {
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }

  .md\:tw-inset-auto {
    top: auto;
    right: auto;
    bottom: auto;
    left: auto;
  }

  .md\:tw-inset-y-0 {
    top: 0;
    bottom: 0;
  }

  .md\:tw-inset-x-0 {
    right: 0;
    left: 0;
  }

  .md\:tw-inset-y-auto {
    top: auto;
    bottom: auto;
  }

  .md\:tw-inset-x-auto {
    right: auto;
    left: auto;
  }

  .md\:tw-top-0 {
    top: 0;
  }

  .md\:tw-right-0 {
    right: 0;
  }

  .md\:tw-bottom-0 {
    bottom: 0;
  }

  .md\:tw-left-0 {
    left: 0;
  }

  .md\:tw-top-auto {
    top: auto;
  }

  .md\:tw-right-auto {
    right: auto;
  }

  .md\:tw-bottom-auto {
    bottom: auto;
  }

  .md\:tw-left-auto {
    left: auto;
  }

  .md\:tw-resize-none {
    resize: none;
  }

  .md\:tw-resize-y {
    resize: vertical;
  }

  .md\:tw-resize-x {
    resize: horizontal;
  }

  .md\:tw-resize {
    resize: both;
  }

  .md\:tw-shadow-xs {
    box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.05);
  }

  .md\:tw-shadow-sm {
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
  }

  .md\:tw-shadow {
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
  }

  .md\:tw-shadow-md {
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
  }

  .md\:tw-shadow-lg {
    box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
  }

  .md\:tw-shadow-xl {
    box-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04);
  }

  .md\:tw-shadow-2xl {
    box-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25);
  }

  .md\:tw-shadow-inner {
    box-shadow: inset 0 2px 4px 0 rgba(0, 0, 0, 0.06);
  }

  .md\:tw-shadow-outline {
    box-shadow: 0 0 0 3px rgba(66, 153, 225, 0.5);
  }

  .md\:tw-shadow-none {
    box-shadow: none;
  }

  .md\:hover\:tw-shadow-xs:hover {
    box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.05);
  }

  .md\:hover\:tw-shadow-sm:hover {
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
  }

  .md\:hover\:tw-shadow:hover {
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
  }

  .md\:hover\:tw-shadow-md:hover {
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
  }

  .md\:hover\:tw-shadow-lg:hover {
    box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
  }

  .md\:hover\:tw-shadow-xl:hover {
    box-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04);
  }

  .md\:hover\:tw-shadow-2xl:hover {
    box-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25);
  }

  .md\:hover\:tw-shadow-inner:hover {
    box-shadow: inset 0 2px 4px 0 rgba(0, 0, 0, 0.06);
  }

  .md\:hover\:tw-shadow-outline:hover {
    box-shadow: 0 0 0 3px rgba(66, 153, 225, 0.5);
  }

  .md\:hover\:tw-shadow-none:hover {
    box-shadow: none;
  }

  .md\:focus\:tw-shadow-xs:focus {
    box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.05);
  }

  .md\:focus\:tw-shadow-sm:focus {
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
  }

  .md\:focus\:tw-shadow:focus {
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
  }

  .md\:focus\:tw-shadow-md:focus {
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
  }

  .md\:focus\:tw-shadow-lg:focus {
    box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
  }

  .md\:focus\:tw-shadow-xl:focus {
    box-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04);
  }

  .md\:focus\:tw-shadow-2xl:focus {
    box-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25);
  }

  .md\:focus\:tw-shadow-inner:focus {
    box-shadow: inset 0 2px 4px 0 rgba(0, 0, 0, 0.06);
  }

  .md\:focus\:tw-shadow-outline:focus {
    box-shadow: 0 0 0 3px rgba(66, 153, 225, 0.5);
  }

  .md\:focus\:tw-shadow-none:focus {
    box-shadow: none;
  }

  .md\:tw-fill-current {
    fill: currentColor;
  }

  .md\:tw-stroke-current {
    stroke: currentColor;
  }

  .md\:tw-stroke-0 {
    stroke-width: 0;
  }

  .md\:tw-stroke-1 {
    stroke-width: 1;
  }

  .md\:tw-stroke-2 {
    stroke-width: 2;
  }

  .md\:tw-table-auto {
    table-layout: auto;
  }

  .md\:tw-table-fixed {
    table-layout: fixed;
  }

  .md\:tw-text-left {
    text-align: left;
  }

  .md\:tw-text-center {
    text-align: center;
  }

  .md\:tw-text-right {
    text-align: right;
  }

  .md\:tw-text-justify {
    text-align: justify;
  }

  .md\:tw-text-transparent {
    color: transparent;
  }

  .md\:tw-text-current {
    color: currentColor;
  }

  .md\:tw-text-black {
    --text-opacity: 1;
    color: #000;
    color: rgba(0, 0, 0, var(--text-opacity));
  }

  .md\:tw-text-white {
    --text-opacity: 1;
    color: #fff;
    color: rgba(255, 255, 255, var(--text-opacity));
  }

  .md\:tw-text-gray-100 {
    --text-opacity: 1;
    color: #f7fafc;
    color: rgba(247, 250, 252, var(--text-opacity));
  }

  .md\:tw-text-gray-200 {
    --text-opacity: 1;
    color: #edf2f7;
    color: rgba(237, 242, 247, var(--text-opacity));
  }

  .md\:tw-text-gray-300 {
    --text-opacity: 1;
    color: #e2e8f0;
    color: rgba(226, 232, 240, var(--text-opacity));
  }

  .md\:tw-text-gray-400 {
    --text-opacity: 1;
    color: #cbd5e0;
    color: rgba(203, 213, 224, var(--text-opacity));
  }

  .md\:tw-text-gray-500 {
    --text-opacity: 1;
    color: #a0aec0;
    color: rgba(160, 174, 192, var(--text-opacity));
  }

  .md\:tw-text-gray-600 {
    --text-opacity: 1;
    color: #718096;
    color: rgba(113, 128, 150, var(--text-opacity));
  }

  .md\:tw-text-gray-700 {
    --text-opacity: 1;
    color: #4a5568;
    color: rgba(74, 85, 104, var(--text-opacity));
  }

  .md\:tw-text-gray-800 {
    --text-opacity: 1;
    color: #2d3748;
    color: rgba(45, 55, 72, var(--text-opacity));
  }

  .md\:tw-text-gray-900 {
    --text-opacity: 1;
    color: #1a202c;
    color: rgba(26, 32, 44, var(--text-opacity));
  }

  .md\:tw-text-red-100 {
    --text-opacity: 1;
    color: #fff5f5;
    color: rgba(255, 245, 245, var(--text-opacity));
  }

  .md\:tw-text-red-200 {
    --text-opacity: 1;
    color: #fed7d7;
    color: rgba(254, 215, 215, var(--text-opacity));
  }

  .md\:tw-text-red-300 {
    --text-opacity: 1;
    color: #feb2b2;
    color: rgba(254, 178, 178, var(--text-opacity));
  }

  .md\:tw-text-red-400 {
    --text-opacity: 1;
    color: #fc8181;
    color: rgba(252, 129, 129, var(--text-opacity));
  }

  .md\:tw-text-red-500 {
    --text-opacity: 1;
    color: #f56565;
    color: rgba(245, 101, 101, var(--text-opacity));
  }

  .md\:tw-text-red-600 {
    --text-opacity: 1;
    color: #e53e3e;
    color: rgba(229, 62, 62, var(--text-opacity));
  }

  .md\:tw-text-red-700 {
    --text-opacity: 1;
    color: #c53030;
    color: rgba(197, 48, 48, var(--text-opacity));
  }

  .md\:tw-text-red-800 {
    --text-opacity: 1;
    color: #9b2c2c;
    color: rgba(155, 44, 44, var(--text-opacity));
  }

  .md\:tw-text-red-900 {
    --text-opacity: 1;
    color: #742a2a;
    color: rgba(116, 42, 42, var(--text-opacity));
  }

  .md\:tw-text-orange-100 {
    --text-opacity: 1;
    color: #fffaf0;
    color: rgba(255, 250, 240, var(--text-opacity));
  }

  .md\:tw-text-orange-200 {
    --text-opacity: 1;
    color: #feebc8;
    color: rgba(254, 235, 200, var(--text-opacity));
  }

  .md\:tw-text-orange-300 {
    --text-opacity: 1;
    color: #fbd38d;
    color: rgba(251, 211, 141, var(--text-opacity));
  }

  .md\:tw-text-orange-400 {
    --text-opacity: 1;
    color: #f6ad55;
    color: rgba(246, 173, 85, var(--text-opacity));
  }

  .md\:tw-text-orange-500 {
    --text-opacity: 1;
    color: #ed8936;
    color: rgba(237, 137, 54, var(--text-opacity));
  }

  .md\:tw-text-orange-600 {
    --text-opacity: 1;
    color: #dd6b20;
    color: rgba(221, 107, 32, var(--text-opacity));
  }

  .md\:tw-text-orange-700 {
    --text-opacity: 1;
    color: #c05621;
    color: rgba(192, 86, 33, var(--text-opacity));
  }

  .md\:tw-text-orange-800 {
    --text-opacity: 1;
    color: #9c4221;
    color: rgba(156, 66, 33, var(--text-opacity));
  }

  .md\:tw-text-orange-900 {
    --text-opacity: 1;
    color: #7b341e;
    color: rgba(123, 52, 30, var(--text-opacity));
  }

  .md\:tw-text-yellow-100 {
    --text-opacity: 1;
    color: #fffff0;
    color: rgba(255, 255, 240, var(--text-opacity));
  }

  .md\:tw-text-yellow-200 {
    --text-opacity: 1;
    color: #fefcbf;
    color: rgba(254, 252, 191, var(--text-opacity));
  }

  .md\:tw-text-yellow-300 {
    --text-opacity: 1;
    color: #faf089;
    color: rgba(250, 240, 137, var(--text-opacity));
  }

  .md\:tw-text-yellow-400 {
    --text-opacity: 1;
    color: #f6e05e;
    color: rgba(246, 224, 94, var(--text-opacity));
  }

  .md\:tw-text-yellow-500 {
    --text-opacity: 1;
    color: #ecc94b;
    color: rgba(236, 201, 75, var(--text-opacity));
  }

  .md\:tw-text-yellow-600 {
    --text-opacity: 1;
    color: #d69e2e;
    color: rgba(214, 158, 46, var(--text-opacity));
  }

  .md\:tw-text-yellow-700 {
    --text-opacity: 1;
    color: #b7791f;
    color: rgba(183, 121, 31, var(--text-opacity));
  }

  .md\:tw-text-yellow-800 {
    --text-opacity: 1;
    color: #975a16;
    color: rgba(151, 90, 22, var(--text-opacity));
  }

  .md\:tw-text-yellow-900 {
    --text-opacity: 1;
    color: #744210;
    color: rgba(116, 66, 16, var(--text-opacity));
  }

  .md\:tw-text-green-100 {
    --text-opacity: 1;
    color: #f0fff4;
    color: rgba(240, 255, 244, var(--text-opacity));
  }

  .md\:tw-text-green-200 {
    --text-opacity: 1;
    color: #c6f6d5;
    color: rgba(198, 246, 213, var(--text-opacity));
  }

  .md\:tw-text-green-300 {
    --text-opacity: 1;
    color: #9ae6b4;
    color: rgba(154, 230, 180, var(--text-opacity));
  }

  .md\:tw-text-green-400 {
    --text-opacity: 1;
    color: #68d391;
    color: rgba(104, 211, 145, var(--text-opacity));
  }

  .md\:tw-text-green-500 {
    --text-opacity: 1;
    color: #48bb78;
    color: rgba(72, 187, 120, var(--text-opacity));
  }

  .md\:tw-text-green-600 {
    --text-opacity: 1;
    color: #38a169;
    color: rgba(56, 161, 105, var(--text-opacity));
  }

  .md\:tw-text-green-700 {
    --text-opacity: 1;
    color: #2f855a;
    color: rgba(47, 133, 90, var(--text-opacity));
  }

  .md\:tw-text-green-800 {
    --text-opacity: 1;
    color: #276749;
    color: rgba(39, 103, 73, var(--text-opacity));
  }

  .md\:tw-text-green-900 {
    --text-opacity: 1;
    color: #22543d;
    color: rgba(34, 84, 61, var(--text-opacity));
  }

  .md\:tw-text-teal-100 {
    --text-opacity: 1;
    color: #e6fffa;
    color: rgba(230, 255, 250, var(--text-opacity));
  }

  .md\:tw-text-teal-200 {
    --text-opacity: 1;
    color: #b2f5ea;
    color: rgba(178, 245, 234, var(--text-opacity));
  }

  .md\:tw-text-teal-300 {
    --text-opacity: 1;
    color: #81e6d9;
    color: rgba(129, 230, 217, var(--text-opacity));
  }

  .md\:tw-text-teal-400 {
    --text-opacity: 1;
    color: #4fd1c5;
    color: rgba(79, 209, 197, var(--text-opacity));
  }

  .md\:tw-text-teal-500 {
    --text-opacity: 1;
    color: #38b2ac;
    color: rgba(56, 178, 172, var(--text-opacity));
  }

  .md\:tw-text-teal-600 {
    --text-opacity: 1;
    color: #319795;
    color: rgba(49, 151, 149, var(--text-opacity));
  }

  .md\:tw-text-teal-700 {
    --text-opacity: 1;
    color: #2c7a7b;
    color: rgba(44, 122, 123, var(--text-opacity));
  }

  .md\:tw-text-teal-800 {
    --text-opacity: 1;
    color: #285e61;
    color: rgba(40, 94, 97, var(--text-opacity));
  }

  .md\:tw-text-teal-900 {
    --text-opacity: 1;
    color: #234e52;
    color: rgba(35, 78, 82, var(--text-opacity));
  }

  .md\:tw-text-blue-100 {
    --text-opacity: 1;
    color: #ebf8ff;
    color: rgba(235, 248, 255, var(--text-opacity));
  }

  .md\:tw-text-blue-200 {
    --text-opacity: 1;
    color: #bee3f8;
    color: rgba(190, 227, 248, var(--text-opacity));
  }

  .md\:tw-text-blue-300 {
    --text-opacity: 1;
    color: #90cdf4;
    color: rgba(144, 205, 244, var(--text-opacity));
  }

  .md\:tw-text-blue-400 {
    --text-opacity: 1;
    color: #63b3ed;
    color: rgba(99, 179, 237, var(--text-opacity));
  }

  .md\:tw-text-blue-500 {
    --text-opacity: 1;
    color: #4299e1;
    color: rgba(66, 153, 225, var(--text-opacity));
  }

  .md\:tw-text-blue-600 {
    --text-opacity: 1;
    color: #3182ce;
    color: rgba(49, 130, 206, var(--text-opacity));
  }

  .md\:tw-text-blue-700 {
    --text-opacity: 1;
    color: #2b6cb0;
    color: rgba(43, 108, 176, var(--text-opacity));
  }

  .md\:tw-text-blue-800 {
    --text-opacity: 1;
    color: #2c5282;
    color: rgba(44, 82, 130, var(--text-opacity));
  }

  .md\:tw-text-blue-900 {
    --text-opacity: 1;
    color: #2a4365;
    color: rgba(42, 67, 101, var(--text-opacity));
  }

  .md\:tw-text-indigo-100 {
    --text-opacity: 1;
    color: #ebf4ff;
    color: rgba(235, 244, 255, var(--text-opacity));
  }

  .md\:tw-text-indigo-200 {
    --text-opacity: 1;
    color: #c3dafe;
    color: rgba(195, 218, 254, var(--text-opacity));
  }

  .md\:tw-text-indigo-300 {
    --text-opacity: 1;
    color: #a3bffa;
    color: rgba(163, 191, 250, var(--text-opacity));
  }

  .md\:tw-text-indigo-400 {
    --text-opacity: 1;
    color: #7f9cf5;
    color: rgba(127, 156, 245, var(--text-opacity));
  }

  .md\:tw-text-indigo-500 {
    --text-opacity: 1;
    color: #667eea;
    color: rgba(102, 126, 234, var(--text-opacity));
  }

  .md\:tw-text-indigo-600 {
    --text-opacity: 1;
    color: #5a67d8;
    color: rgba(90, 103, 216, var(--text-opacity));
  }

  .md\:tw-text-indigo-700 {
    --text-opacity: 1;
    color: #4c51bf;
    color: rgba(76, 81, 191, var(--text-opacity));
  }

  .md\:tw-text-indigo-800 {
    --text-opacity: 1;
    color: #434190;
    color: rgba(67, 65, 144, var(--text-opacity));
  }

  .md\:tw-text-indigo-900 {
    --text-opacity: 1;
    color: #3c366b;
    color: rgba(60, 54, 107, var(--text-opacity));
  }

  .md\:tw-text-purple-100 {
    --text-opacity: 1;
    color: #faf5ff;
    color: rgba(250, 245, 255, var(--text-opacity));
  }

  .md\:tw-text-purple-200 {
    --text-opacity: 1;
    color: #e9d8fd;
    color: rgba(233, 216, 253, var(--text-opacity));
  }

  .md\:tw-text-purple-300 {
    --text-opacity: 1;
    color: #d6bcfa;
    color: rgba(214, 188, 250, var(--text-opacity));
  }

  .md\:tw-text-purple-400 {
    --text-opacity: 1;
    color: #b794f4;
    color: rgba(183, 148, 244, var(--text-opacity));
  }

  .md\:tw-text-purple-500 {
    --text-opacity: 1;
    color: #9f7aea;
    color: rgba(159, 122, 234, var(--text-opacity));
  }

  .md\:tw-text-purple-600 {
    --text-opacity: 1;
    color: #805ad5;
    color: rgba(128, 90, 213, var(--text-opacity));
  }

  .md\:tw-text-purple-700 {
    --text-opacity: 1;
    color: #6b46c1;
    color: rgba(107, 70, 193, var(--text-opacity));
  }

  .md\:tw-text-purple-800 {
    --text-opacity: 1;
    color: #553c9a;
    color: rgba(85, 60, 154, var(--text-opacity));
  }

  .md\:tw-text-purple-900 {
    --text-opacity: 1;
    color: #44337a;
    color: rgba(68, 51, 122, var(--text-opacity));
  }

  .md\:tw-text-pink-100 {
    --text-opacity: 1;
    color: #fff5f7;
    color: rgba(255, 245, 247, var(--text-opacity));
  }

  .md\:tw-text-pink-200 {
    --text-opacity: 1;
    color: #fed7e2;
    color: rgba(254, 215, 226, var(--text-opacity));
  }

  .md\:tw-text-pink-300 {
    --text-opacity: 1;
    color: #fbb6ce;
    color: rgba(251, 182, 206, var(--text-opacity));
  }

  .md\:tw-text-pink-400 {
    --text-opacity: 1;
    color: #f687b3;
    color: rgba(246, 135, 179, var(--text-opacity));
  }

  .md\:tw-text-pink-500 {
    --text-opacity: 1;
    color: #ed64a6;
    color: rgba(237, 100, 166, var(--text-opacity));
  }

  .md\:tw-text-pink-600 {
    --text-opacity: 1;
    color: #d53f8c;
    color: rgba(213, 63, 140, var(--text-opacity));
  }

  .md\:tw-text-pink-700 {
    --text-opacity: 1;
    color: #b83280;
    color: rgba(184, 50, 128, var(--text-opacity));
  }

  .md\:tw-text-pink-800 {
    --text-opacity: 1;
    color: #97266d;
    color: rgba(151, 38, 109, var(--text-opacity));
  }

  .md\:tw-text-pink-900 {
    --text-opacity: 1;
    color: #702459;
    color: rgba(112, 36, 89, var(--text-opacity));
  }

  .md\:hover\:tw-text-transparent:hover {
    color: transparent;
  }

  .md\:hover\:tw-text-current:hover {
    color: currentColor;
  }

  .md\:hover\:tw-text-black:hover {
    --text-opacity: 1;
    color: #000;
    color: rgba(0, 0, 0, var(--text-opacity));
  }

  .md\:hover\:tw-text-white:hover {
    --text-opacity: 1;
    color: #fff;
    color: rgba(255, 255, 255, var(--text-opacity));
  }

  .md\:hover\:tw-text-gray-100:hover {
    --text-opacity: 1;
    color: #f7fafc;
    color: rgba(247, 250, 252, var(--text-opacity));
  }

  .md\:hover\:tw-text-gray-200:hover {
    --text-opacity: 1;
    color: #edf2f7;
    color: rgba(237, 242, 247, var(--text-opacity));
  }

  .md\:hover\:tw-text-gray-300:hover {
    --text-opacity: 1;
    color: #e2e8f0;
    color: rgba(226, 232, 240, var(--text-opacity));
  }

  .md\:hover\:tw-text-gray-400:hover {
    --text-opacity: 1;
    color: #cbd5e0;
    color: rgba(203, 213, 224, var(--text-opacity));
  }

  .md\:hover\:tw-text-gray-500:hover {
    --text-opacity: 1;
    color: #a0aec0;
    color: rgba(160, 174, 192, var(--text-opacity));
  }

  .md\:hover\:tw-text-gray-600:hover {
    --text-opacity: 1;
    color: #718096;
    color: rgba(113, 128, 150, var(--text-opacity));
  }

  .md\:hover\:tw-text-gray-700:hover {
    --text-opacity: 1;
    color: #4a5568;
    color: rgba(74, 85, 104, var(--text-opacity));
  }

  .md\:hover\:tw-text-gray-800:hover {
    --text-opacity: 1;
    color: #2d3748;
    color: rgba(45, 55, 72, var(--text-opacity));
  }

  .md\:hover\:tw-text-gray-900:hover {
    --text-opacity: 1;
    color: #1a202c;
    color: rgba(26, 32, 44, var(--text-opacity));
  }

  .md\:hover\:tw-text-red-100:hover {
    --text-opacity: 1;
    color: #fff5f5;
    color: rgba(255, 245, 245, var(--text-opacity));
  }

  .md\:hover\:tw-text-red-200:hover {
    --text-opacity: 1;
    color: #fed7d7;
    color: rgba(254, 215, 215, var(--text-opacity));
  }

  .md\:hover\:tw-text-red-300:hover {
    --text-opacity: 1;
    color: #feb2b2;
    color: rgba(254, 178, 178, var(--text-opacity));
  }

  .md\:hover\:tw-text-red-400:hover {
    --text-opacity: 1;
    color: #fc8181;
    color: rgba(252, 129, 129, var(--text-opacity));
  }

  .md\:hover\:tw-text-red-500:hover {
    --text-opacity: 1;
    color: #f56565;
    color: rgba(245, 101, 101, var(--text-opacity));
  }

  .md\:hover\:tw-text-red-600:hover {
    --text-opacity: 1;
    color: #e53e3e;
    color: rgba(229, 62, 62, var(--text-opacity));
  }

  .md\:hover\:tw-text-red-700:hover {
    --text-opacity: 1;
    color: #c53030;
    color: rgba(197, 48, 48, var(--text-opacity));
  }

  .md\:hover\:tw-text-red-800:hover {
    --text-opacity: 1;
    color: #9b2c2c;
    color: rgba(155, 44, 44, var(--text-opacity));
  }

  .md\:hover\:tw-text-red-900:hover {
    --text-opacity: 1;
    color: #742a2a;
    color: rgba(116, 42, 42, var(--text-opacity));
  }

  .md\:hover\:tw-text-orange-100:hover {
    --text-opacity: 1;
    color: #fffaf0;
    color: rgba(255, 250, 240, var(--text-opacity));
  }

  .md\:hover\:tw-text-orange-200:hover {
    --text-opacity: 1;
    color: #feebc8;
    color: rgba(254, 235, 200, var(--text-opacity));
  }

  .md\:hover\:tw-text-orange-300:hover {
    --text-opacity: 1;
    color: #fbd38d;
    color: rgba(251, 211, 141, var(--text-opacity));
  }

  .md\:hover\:tw-text-orange-400:hover {
    --text-opacity: 1;
    color: #f6ad55;
    color: rgba(246, 173, 85, var(--text-opacity));
  }

  .md\:hover\:tw-text-orange-500:hover {
    --text-opacity: 1;
    color: #ed8936;
    color: rgba(237, 137, 54, var(--text-opacity));
  }

  .md\:hover\:tw-text-orange-600:hover {
    --text-opacity: 1;
    color: #dd6b20;
    color: rgba(221, 107, 32, var(--text-opacity));
  }

  .md\:hover\:tw-text-orange-700:hover {
    --text-opacity: 1;
    color: #c05621;
    color: rgba(192, 86, 33, var(--text-opacity));
  }

  .md\:hover\:tw-text-orange-800:hover {
    --text-opacity: 1;
    color: #9c4221;
    color: rgba(156, 66, 33, var(--text-opacity));
  }

  .md\:hover\:tw-text-orange-900:hover {
    --text-opacity: 1;
    color: #7b341e;
    color: rgba(123, 52, 30, var(--text-opacity));
  }

  .md\:hover\:tw-text-yellow-100:hover {
    --text-opacity: 1;
    color: #fffff0;
    color: rgba(255, 255, 240, var(--text-opacity));
  }

  .md\:hover\:tw-text-yellow-200:hover {
    --text-opacity: 1;
    color: #fefcbf;
    color: rgba(254, 252, 191, var(--text-opacity));
  }

  .md\:hover\:tw-text-yellow-300:hover {
    --text-opacity: 1;
    color: #faf089;
    color: rgba(250, 240, 137, var(--text-opacity));
  }

  .md\:hover\:tw-text-yellow-400:hover {
    --text-opacity: 1;
    color: #f6e05e;
    color: rgba(246, 224, 94, var(--text-opacity));
  }

  .md\:hover\:tw-text-yellow-500:hover {
    --text-opacity: 1;
    color: #ecc94b;
    color: rgba(236, 201, 75, var(--text-opacity));
  }

  .md\:hover\:tw-text-yellow-600:hover {
    --text-opacity: 1;
    color: #d69e2e;
    color: rgba(214, 158, 46, var(--text-opacity));
  }

  .md\:hover\:tw-text-yellow-700:hover {
    --text-opacity: 1;
    color: #b7791f;
    color: rgba(183, 121, 31, var(--text-opacity));
  }

  .md\:hover\:tw-text-yellow-800:hover {
    --text-opacity: 1;
    color: #975a16;
    color: rgba(151, 90, 22, var(--text-opacity));
  }

  .md\:hover\:tw-text-yellow-900:hover {
    --text-opacity: 1;
    color: #744210;
    color: rgba(116, 66, 16, var(--text-opacity));
  }

  .md\:hover\:tw-text-green-100:hover {
    --text-opacity: 1;
    color: #f0fff4;
    color: rgba(240, 255, 244, var(--text-opacity));
  }

  .md\:hover\:tw-text-green-200:hover {
    --text-opacity: 1;
    color: #c6f6d5;
    color: rgba(198, 246, 213, var(--text-opacity));
  }

  .md\:hover\:tw-text-green-300:hover {
    --text-opacity: 1;
    color: #9ae6b4;
    color: rgba(154, 230, 180, var(--text-opacity));
  }

  .md\:hover\:tw-text-green-400:hover {
    --text-opacity: 1;
    color: #68d391;
    color: rgba(104, 211, 145, var(--text-opacity));
  }

  .md\:hover\:tw-text-green-500:hover {
    --text-opacity: 1;
    color: #48bb78;
    color: rgba(72, 187, 120, var(--text-opacity));
  }

  .md\:hover\:tw-text-green-600:hover {
    --text-opacity: 1;
    color: #38a169;
    color: rgba(56, 161, 105, var(--text-opacity));
  }

  .md\:hover\:tw-text-green-700:hover {
    --text-opacity: 1;
    color: #2f855a;
    color: rgba(47, 133, 90, var(--text-opacity));
  }

  .md\:hover\:tw-text-green-800:hover {
    --text-opacity: 1;
    color: #276749;
    color: rgba(39, 103, 73, var(--text-opacity));
  }

  .md\:hover\:tw-text-green-900:hover {
    --text-opacity: 1;
    color: #22543d;
    color: rgba(34, 84, 61, var(--text-opacity));
  }

  .md\:hover\:tw-text-teal-100:hover {
    --text-opacity: 1;
    color: #e6fffa;
    color: rgba(230, 255, 250, var(--text-opacity));
  }

  .md\:hover\:tw-text-teal-200:hover {
    --text-opacity: 1;
    color: #b2f5ea;
    color: rgba(178, 245, 234, var(--text-opacity));
  }

  .md\:hover\:tw-text-teal-300:hover {
    --text-opacity: 1;
    color: #81e6d9;
    color: rgba(129, 230, 217, var(--text-opacity));
  }

  .md\:hover\:tw-text-teal-400:hover {
    --text-opacity: 1;
    color: #4fd1c5;
    color: rgba(79, 209, 197, var(--text-opacity));
  }

  .md\:hover\:tw-text-teal-500:hover {
    --text-opacity: 1;
    color: #38b2ac;
    color: rgba(56, 178, 172, var(--text-opacity));
  }

  .md\:hover\:tw-text-teal-600:hover {
    --text-opacity: 1;
    color: #319795;
    color: rgba(49, 151, 149, var(--text-opacity));
  }

  .md\:hover\:tw-text-teal-700:hover {
    --text-opacity: 1;
    color: #2c7a7b;
    color: rgba(44, 122, 123, var(--text-opacity));
  }

  .md\:hover\:tw-text-teal-800:hover {
    --text-opacity: 1;
    color: #285e61;
    color: rgba(40, 94, 97, var(--text-opacity));
  }

  .md\:hover\:tw-text-teal-900:hover {
    --text-opacity: 1;
    color: #234e52;
    color: rgba(35, 78, 82, var(--text-opacity));
  }

  .md\:hover\:tw-text-blue-100:hover {
    --text-opacity: 1;
    color: #ebf8ff;
    color: rgba(235, 248, 255, var(--text-opacity));
  }

  .md\:hover\:tw-text-blue-200:hover {
    --text-opacity: 1;
    color: #bee3f8;
    color: rgba(190, 227, 248, var(--text-opacity));
  }

  .md\:hover\:tw-text-blue-300:hover {
    --text-opacity: 1;
    color: #90cdf4;
    color: rgba(144, 205, 244, var(--text-opacity));
  }

  .md\:hover\:tw-text-blue-400:hover {
    --text-opacity: 1;
    color: #63b3ed;
    color: rgba(99, 179, 237, var(--text-opacity));
  }

  .md\:hover\:tw-text-blue-500:hover {
    --text-opacity: 1;
    color: #4299e1;
    color: rgba(66, 153, 225, var(--text-opacity));
  }

  .md\:hover\:tw-text-blue-600:hover {
    --text-opacity: 1;
    color: #3182ce;
    color: rgba(49, 130, 206, var(--text-opacity));
  }

  .md\:hover\:tw-text-blue-700:hover {
    --text-opacity: 1;
    color: #2b6cb0;
    color: rgba(43, 108, 176, var(--text-opacity));
  }

  .md\:hover\:tw-text-blue-800:hover {
    --text-opacity: 1;
    color: #2c5282;
    color: rgba(44, 82, 130, var(--text-opacity));
  }

  .md\:hover\:tw-text-blue-900:hover {
    --text-opacity: 1;
    color: #2a4365;
    color: rgba(42, 67, 101, var(--text-opacity));
  }

  .md\:hover\:tw-text-indigo-100:hover {
    --text-opacity: 1;
    color: #ebf4ff;
    color: rgba(235, 244, 255, var(--text-opacity));
  }

  .md\:hover\:tw-text-indigo-200:hover {
    --text-opacity: 1;
    color: #c3dafe;
    color: rgba(195, 218, 254, var(--text-opacity));
  }

  .md\:hover\:tw-text-indigo-300:hover {
    --text-opacity: 1;
    color: #a3bffa;
    color: rgba(163, 191, 250, var(--text-opacity));
  }

  .md\:hover\:tw-text-indigo-400:hover {
    --text-opacity: 1;
    color: #7f9cf5;
    color: rgba(127, 156, 245, var(--text-opacity));
  }

  .md\:hover\:tw-text-indigo-500:hover {
    --text-opacity: 1;
    color: #667eea;
    color: rgba(102, 126, 234, var(--text-opacity));
  }

  .md\:hover\:tw-text-indigo-600:hover {
    --text-opacity: 1;
    color: #5a67d8;
    color: rgba(90, 103, 216, var(--text-opacity));
  }

  .md\:hover\:tw-text-indigo-700:hover {
    --text-opacity: 1;
    color: #4c51bf;
    color: rgba(76, 81, 191, var(--text-opacity));
  }

  .md\:hover\:tw-text-indigo-800:hover {
    --text-opacity: 1;
    color: #434190;
    color: rgba(67, 65, 144, var(--text-opacity));
  }

  .md\:hover\:tw-text-indigo-900:hover {
    --text-opacity: 1;
    color: #3c366b;
    color: rgba(60, 54, 107, var(--text-opacity));
  }

  .md\:hover\:tw-text-purple-100:hover {
    --text-opacity: 1;
    color: #faf5ff;
    color: rgba(250, 245, 255, var(--text-opacity));
  }

  .md\:hover\:tw-text-purple-200:hover {
    --text-opacity: 1;
    color: #e9d8fd;
    color: rgba(233, 216, 253, var(--text-opacity));
  }

  .md\:hover\:tw-text-purple-300:hover {
    --text-opacity: 1;
    color: #d6bcfa;
    color: rgba(214, 188, 250, var(--text-opacity));
  }

  .md\:hover\:tw-text-purple-400:hover {
    --text-opacity: 1;
    color: #b794f4;
    color: rgba(183, 148, 244, var(--text-opacity));
  }

  .md\:hover\:tw-text-purple-500:hover {
    --text-opacity: 1;
    color: #9f7aea;
    color: rgba(159, 122, 234, var(--text-opacity));
  }

  .md\:hover\:tw-text-purple-600:hover {
    --text-opacity: 1;
    color: #805ad5;
    color: rgba(128, 90, 213, var(--text-opacity));
  }

  .md\:hover\:tw-text-purple-700:hover {
    --text-opacity: 1;
    color: #6b46c1;
    color: rgba(107, 70, 193, var(--text-opacity));
  }

  .md\:hover\:tw-text-purple-800:hover {
    --text-opacity: 1;
    color: #553c9a;
    color: rgba(85, 60, 154, var(--text-opacity));
  }

  .md\:hover\:tw-text-purple-900:hover {
    --text-opacity: 1;
    color: #44337a;
    color: rgba(68, 51, 122, var(--text-opacity));
  }

  .md\:hover\:tw-text-pink-100:hover {
    --text-opacity: 1;
    color: #fff5f7;
    color: rgba(255, 245, 247, var(--text-opacity));
  }

  .md\:hover\:tw-text-pink-200:hover {
    --text-opacity: 1;
    color: #fed7e2;
    color: rgba(254, 215, 226, var(--text-opacity));
  }

  .md\:hover\:tw-text-pink-300:hover {
    --text-opacity: 1;
    color: #fbb6ce;
    color: rgba(251, 182, 206, var(--text-opacity));
  }

  .md\:hover\:tw-text-pink-400:hover {
    --text-opacity: 1;
    color: #f687b3;
    color: rgba(246, 135, 179, var(--text-opacity));
  }

  .md\:hover\:tw-text-pink-500:hover {
    --text-opacity: 1;
    color: #ed64a6;
    color: rgba(237, 100, 166, var(--text-opacity));
  }

  .md\:hover\:tw-text-pink-600:hover {
    --text-opacity: 1;
    color: #d53f8c;
    color: rgba(213, 63, 140, var(--text-opacity));
  }

  .md\:hover\:tw-text-pink-700:hover {
    --text-opacity: 1;
    color: #b83280;
    color: rgba(184, 50, 128, var(--text-opacity));
  }

  .md\:hover\:tw-text-pink-800:hover {
    --text-opacity: 1;
    color: #97266d;
    color: rgba(151, 38, 109, var(--text-opacity));
  }

  .md\:hover\:tw-text-pink-900:hover {
    --text-opacity: 1;
    color: #702459;
    color: rgba(112, 36, 89, var(--text-opacity));
  }

  .md\:focus\:tw-text-transparent:focus {
    color: transparent;
  }

  .md\:focus\:tw-text-current:focus {
    color: currentColor;
  }

  .md\:focus\:tw-text-black:focus {
    --text-opacity: 1;
    color: #000;
    color: rgba(0, 0, 0, var(--text-opacity));
  }

  .md\:focus\:tw-text-white:focus {
    --text-opacity: 1;
    color: #fff;
    color: rgba(255, 255, 255, var(--text-opacity));
  }

  .md\:focus\:tw-text-gray-100:focus {
    --text-opacity: 1;
    color: #f7fafc;
    color: rgba(247, 250, 252, var(--text-opacity));
  }

  .md\:focus\:tw-text-gray-200:focus {
    --text-opacity: 1;
    color: #edf2f7;
    color: rgba(237, 242, 247, var(--text-opacity));
  }

  .md\:focus\:tw-text-gray-300:focus {
    --text-opacity: 1;
    color: #e2e8f0;
    color: rgba(226, 232, 240, var(--text-opacity));
  }

  .md\:focus\:tw-text-gray-400:focus {
    --text-opacity: 1;
    color: #cbd5e0;
    color: rgba(203, 213, 224, var(--text-opacity));
  }

  .md\:focus\:tw-text-gray-500:focus {
    --text-opacity: 1;
    color: #a0aec0;
    color: rgba(160, 174, 192, var(--text-opacity));
  }

  .md\:focus\:tw-text-gray-600:focus {
    --text-opacity: 1;
    color: #718096;
    color: rgba(113, 128, 150, var(--text-opacity));
  }

  .md\:focus\:tw-text-gray-700:focus {
    --text-opacity: 1;
    color: #4a5568;
    color: rgba(74, 85, 104, var(--text-opacity));
  }

  .md\:focus\:tw-text-gray-800:focus {
    --text-opacity: 1;
    color: #2d3748;
    color: rgba(45, 55, 72, var(--text-opacity));
  }

  .md\:focus\:tw-text-gray-900:focus {
    --text-opacity: 1;
    color: #1a202c;
    color: rgba(26, 32, 44, var(--text-opacity));
  }

  .md\:focus\:tw-text-red-100:focus {
    --text-opacity: 1;
    color: #fff5f5;
    color: rgba(255, 245, 245, var(--text-opacity));
  }

  .md\:focus\:tw-text-red-200:focus {
    --text-opacity: 1;
    color: #fed7d7;
    color: rgba(254, 215, 215, var(--text-opacity));
  }

  .md\:focus\:tw-text-red-300:focus {
    --text-opacity: 1;
    color: #feb2b2;
    color: rgba(254, 178, 178, var(--text-opacity));
  }

  .md\:focus\:tw-text-red-400:focus {
    --text-opacity: 1;
    color: #fc8181;
    color: rgba(252, 129, 129, var(--text-opacity));
  }

  .md\:focus\:tw-text-red-500:focus {
    --text-opacity: 1;
    color: #f56565;
    color: rgba(245, 101, 101, var(--text-opacity));
  }

  .md\:focus\:tw-text-red-600:focus {
    --text-opacity: 1;
    color: #e53e3e;
    color: rgba(229, 62, 62, var(--text-opacity));
  }

  .md\:focus\:tw-text-red-700:focus {
    --text-opacity: 1;
    color: #c53030;
    color: rgba(197, 48, 48, var(--text-opacity));
  }

  .md\:focus\:tw-text-red-800:focus {
    --text-opacity: 1;
    color: #9b2c2c;
    color: rgba(155, 44, 44, var(--text-opacity));
  }

  .md\:focus\:tw-text-red-900:focus {
    --text-opacity: 1;
    color: #742a2a;
    color: rgba(116, 42, 42, var(--text-opacity));
  }

  .md\:focus\:tw-text-orange-100:focus {
    --text-opacity: 1;
    color: #fffaf0;
    color: rgba(255, 250, 240, var(--text-opacity));
  }

  .md\:focus\:tw-text-orange-200:focus {
    --text-opacity: 1;
    color: #feebc8;
    color: rgba(254, 235, 200, var(--text-opacity));
  }

  .md\:focus\:tw-text-orange-300:focus {
    --text-opacity: 1;
    color: #fbd38d;
    color: rgba(251, 211, 141, var(--text-opacity));
  }

  .md\:focus\:tw-text-orange-400:focus {
    --text-opacity: 1;
    color: #f6ad55;
    color: rgba(246, 173, 85, var(--text-opacity));
  }

  .md\:focus\:tw-text-orange-500:focus {
    --text-opacity: 1;
    color: #ed8936;
    color: rgba(237, 137, 54, var(--text-opacity));
  }

  .md\:focus\:tw-text-orange-600:focus {
    --text-opacity: 1;
    color: #dd6b20;
    color: rgba(221, 107, 32, var(--text-opacity));
  }

  .md\:focus\:tw-text-orange-700:focus {
    --text-opacity: 1;
    color: #c05621;
    color: rgba(192, 86, 33, var(--text-opacity));
  }

  .md\:focus\:tw-text-orange-800:focus {
    --text-opacity: 1;
    color: #9c4221;
    color: rgba(156, 66, 33, var(--text-opacity));
  }

  .md\:focus\:tw-text-orange-900:focus {
    --text-opacity: 1;
    color: #7b341e;
    color: rgba(123, 52, 30, var(--text-opacity));
  }

  .md\:focus\:tw-text-yellow-100:focus {
    --text-opacity: 1;
    color: #fffff0;
    color: rgba(255, 255, 240, var(--text-opacity));
  }

  .md\:focus\:tw-text-yellow-200:focus {
    --text-opacity: 1;
    color: #fefcbf;
    color: rgba(254, 252, 191, var(--text-opacity));
  }

  .md\:focus\:tw-text-yellow-300:focus {
    --text-opacity: 1;
    color: #faf089;
    color: rgba(250, 240, 137, var(--text-opacity));
  }

  .md\:focus\:tw-text-yellow-400:focus {
    --text-opacity: 1;
    color: #f6e05e;
    color: rgba(246, 224, 94, var(--text-opacity));
  }

  .md\:focus\:tw-text-yellow-500:focus {
    --text-opacity: 1;
    color: #ecc94b;
    color: rgba(236, 201, 75, var(--text-opacity));
  }

  .md\:focus\:tw-text-yellow-600:focus {
    --text-opacity: 1;
    color: #d69e2e;
    color: rgba(214, 158, 46, var(--text-opacity));
  }

  .md\:focus\:tw-text-yellow-700:focus {
    --text-opacity: 1;
    color: #b7791f;
    color: rgba(183, 121, 31, var(--text-opacity));
  }

  .md\:focus\:tw-text-yellow-800:focus {
    --text-opacity: 1;
    color: #975a16;
    color: rgba(151, 90, 22, var(--text-opacity));
  }

  .md\:focus\:tw-text-yellow-900:focus {
    --text-opacity: 1;
    color: #744210;
    color: rgba(116, 66, 16, var(--text-opacity));
  }

  .md\:focus\:tw-text-green-100:focus {
    --text-opacity: 1;
    color: #f0fff4;
    color: rgba(240, 255, 244, var(--text-opacity));
  }

  .md\:focus\:tw-text-green-200:focus {
    --text-opacity: 1;
    color: #c6f6d5;
    color: rgba(198, 246, 213, var(--text-opacity));
  }

  .md\:focus\:tw-text-green-300:focus {
    --text-opacity: 1;
    color: #9ae6b4;
    color: rgba(154, 230, 180, var(--text-opacity));
  }

  .md\:focus\:tw-text-green-400:focus {
    --text-opacity: 1;
    color: #68d391;
    color: rgba(104, 211, 145, var(--text-opacity));
  }

  .md\:focus\:tw-text-green-500:focus {
    --text-opacity: 1;
    color: #48bb78;
    color: rgba(72, 187, 120, var(--text-opacity));
  }

  .md\:focus\:tw-text-green-600:focus {
    --text-opacity: 1;
    color: #38a169;
    color: rgba(56, 161, 105, var(--text-opacity));
  }

  .md\:focus\:tw-text-green-700:focus {
    --text-opacity: 1;
    color: #2f855a;
    color: rgba(47, 133, 90, var(--text-opacity));
  }

  .md\:focus\:tw-text-green-800:focus {
    --text-opacity: 1;
    color: #276749;
    color: rgba(39, 103, 73, var(--text-opacity));
  }

  .md\:focus\:tw-text-green-900:focus {
    --text-opacity: 1;
    color: #22543d;
    color: rgba(34, 84, 61, var(--text-opacity));
  }

  .md\:focus\:tw-text-teal-100:focus {
    --text-opacity: 1;
    color: #e6fffa;
    color: rgba(230, 255, 250, var(--text-opacity));
  }

  .md\:focus\:tw-text-teal-200:focus {
    --text-opacity: 1;
    color: #b2f5ea;
    color: rgba(178, 245, 234, var(--text-opacity));
  }

  .md\:focus\:tw-text-teal-300:focus {
    --text-opacity: 1;
    color: #81e6d9;
    color: rgba(129, 230, 217, var(--text-opacity));
  }

  .md\:focus\:tw-text-teal-400:focus {
    --text-opacity: 1;
    color: #4fd1c5;
    color: rgba(79, 209, 197, var(--text-opacity));
  }

  .md\:focus\:tw-text-teal-500:focus {
    --text-opacity: 1;
    color: #38b2ac;
    color: rgba(56, 178, 172, var(--text-opacity));
  }

  .md\:focus\:tw-text-teal-600:focus {
    --text-opacity: 1;
    color: #319795;
    color: rgba(49, 151, 149, var(--text-opacity));
  }

  .md\:focus\:tw-text-teal-700:focus {
    --text-opacity: 1;
    color: #2c7a7b;
    color: rgba(44, 122, 123, var(--text-opacity));
  }

  .md\:focus\:tw-text-teal-800:focus {
    --text-opacity: 1;
    color: #285e61;
    color: rgba(40, 94, 97, var(--text-opacity));
  }

  .md\:focus\:tw-text-teal-900:focus {
    --text-opacity: 1;
    color: #234e52;
    color: rgba(35, 78, 82, var(--text-opacity));
  }

  .md\:focus\:tw-text-blue-100:focus {
    --text-opacity: 1;
    color: #ebf8ff;
    color: rgba(235, 248, 255, var(--text-opacity));
  }

  .md\:focus\:tw-text-blue-200:focus {
    --text-opacity: 1;
    color: #bee3f8;
    color: rgba(190, 227, 248, var(--text-opacity));
  }

  .md\:focus\:tw-text-blue-300:focus {
    --text-opacity: 1;
    color: #90cdf4;
    color: rgba(144, 205, 244, var(--text-opacity));
  }

  .md\:focus\:tw-text-blue-400:focus {
    --text-opacity: 1;
    color: #63b3ed;
    color: rgba(99, 179, 237, var(--text-opacity));
  }

  .md\:focus\:tw-text-blue-500:focus {
    --text-opacity: 1;
    color: #4299e1;
    color: rgba(66, 153, 225, var(--text-opacity));
  }

  .md\:focus\:tw-text-blue-600:focus {
    --text-opacity: 1;
    color: #3182ce;
    color: rgba(49, 130, 206, var(--text-opacity));
  }

  .md\:focus\:tw-text-blue-700:focus {
    --text-opacity: 1;
    color: #2b6cb0;
    color: rgba(43, 108, 176, var(--text-opacity));
  }

  .md\:focus\:tw-text-blue-800:focus {
    --text-opacity: 1;
    color: #2c5282;
    color: rgba(44, 82, 130, var(--text-opacity));
  }

  .md\:focus\:tw-text-blue-900:focus {
    --text-opacity: 1;
    color: #2a4365;
    color: rgba(42, 67, 101, var(--text-opacity));
  }

  .md\:focus\:tw-text-indigo-100:focus {
    --text-opacity: 1;
    color: #ebf4ff;
    color: rgba(235, 244, 255, var(--text-opacity));
  }

  .md\:focus\:tw-text-indigo-200:focus {
    --text-opacity: 1;
    color: #c3dafe;
    color: rgba(195, 218, 254, var(--text-opacity));
  }

  .md\:focus\:tw-text-indigo-300:focus {
    --text-opacity: 1;
    color: #a3bffa;
    color: rgba(163, 191, 250, var(--text-opacity));
  }

  .md\:focus\:tw-text-indigo-400:focus {
    --text-opacity: 1;
    color: #7f9cf5;
    color: rgba(127, 156, 245, var(--text-opacity));
  }

  .md\:focus\:tw-text-indigo-500:focus {
    --text-opacity: 1;
    color: #667eea;
    color: rgba(102, 126, 234, var(--text-opacity));
  }

  .md\:focus\:tw-text-indigo-600:focus {
    --text-opacity: 1;
    color: #5a67d8;
    color: rgba(90, 103, 216, var(--text-opacity));
  }

  .md\:focus\:tw-text-indigo-700:focus {
    --text-opacity: 1;
    color: #4c51bf;
    color: rgba(76, 81, 191, var(--text-opacity));
  }

  .md\:focus\:tw-text-indigo-800:focus {
    --text-opacity: 1;
    color: #434190;
    color: rgba(67, 65, 144, var(--text-opacity));
  }

  .md\:focus\:tw-text-indigo-900:focus {
    --text-opacity: 1;
    color: #3c366b;
    color: rgba(60, 54, 107, var(--text-opacity));
  }

  .md\:focus\:tw-text-purple-100:focus {
    --text-opacity: 1;
    color: #faf5ff;
    color: rgba(250, 245, 255, var(--text-opacity));
  }

  .md\:focus\:tw-text-purple-200:focus {
    --text-opacity: 1;
    color: #e9d8fd;
    color: rgba(233, 216, 253, var(--text-opacity));
  }

  .md\:focus\:tw-text-purple-300:focus {
    --text-opacity: 1;
    color: #d6bcfa;
    color: rgba(214, 188, 250, var(--text-opacity));
  }

  .md\:focus\:tw-text-purple-400:focus {
    --text-opacity: 1;
    color: #b794f4;
    color: rgba(183, 148, 244, var(--text-opacity));
  }

  .md\:focus\:tw-text-purple-500:focus {
    --text-opacity: 1;
    color: #9f7aea;
    color: rgba(159, 122, 234, var(--text-opacity));
  }

  .md\:focus\:tw-text-purple-600:focus {
    --text-opacity: 1;
    color: #805ad5;
    color: rgba(128, 90, 213, var(--text-opacity));
  }

  .md\:focus\:tw-text-purple-700:focus {
    --text-opacity: 1;
    color: #6b46c1;
    color: rgba(107, 70, 193, var(--text-opacity));
  }

  .md\:focus\:tw-text-purple-800:focus {
    --text-opacity: 1;
    color: #553c9a;
    color: rgba(85, 60, 154, var(--text-opacity));
  }

  .md\:focus\:tw-text-purple-900:focus {
    --text-opacity: 1;
    color: #44337a;
    color: rgba(68, 51, 122, var(--text-opacity));
  }

  .md\:focus\:tw-text-pink-100:focus {
    --text-opacity: 1;
    color: #fff5f7;
    color: rgba(255, 245, 247, var(--text-opacity));
  }

  .md\:focus\:tw-text-pink-200:focus {
    --text-opacity: 1;
    color: #fed7e2;
    color: rgba(254, 215, 226, var(--text-opacity));
  }

  .md\:focus\:tw-text-pink-300:focus {
    --text-opacity: 1;
    color: #fbb6ce;
    color: rgba(251, 182, 206, var(--text-opacity));
  }

  .md\:focus\:tw-text-pink-400:focus {
    --text-opacity: 1;
    color: #f687b3;
    color: rgba(246, 135, 179, var(--text-opacity));
  }

  .md\:focus\:tw-text-pink-500:focus {
    --text-opacity: 1;
    color: #ed64a6;
    color: rgba(237, 100, 166, var(--text-opacity));
  }

  .md\:focus\:tw-text-pink-600:focus {
    --text-opacity: 1;
    color: #d53f8c;
    color: rgba(213, 63, 140, var(--text-opacity));
  }

  .md\:focus\:tw-text-pink-700:focus {
    --text-opacity: 1;
    color: #b83280;
    color: rgba(184, 50, 128, var(--text-opacity));
  }

  .md\:focus\:tw-text-pink-800:focus {
    --text-opacity: 1;
    color: #97266d;
    color: rgba(151, 38, 109, var(--text-opacity));
  }

  .md\:focus\:tw-text-pink-900:focus {
    --text-opacity: 1;
    color: #702459;
    color: rgba(112, 36, 89, var(--text-opacity));
  }

  .md\:tw-text-opacity-0 {
    --text-opacity: 0;
  }

  .md\:tw-text-opacity-25 {
    --text-opacity: 0.25;
  }

  .md\:tw-text-opacity-50 {
    --text-opacity: 0.5;
  }

  .md\:tw-text-opacity-75 {
    --text-opacity: 0.75;
  }

  .md\:tw-text-opacity-100 {
    --text-opacity: 1;
  }

  .md\:hover\:tw-text-opacity-0:hover {
    --text-opacity: 0;
  }

  .md\:hover\:tw-text-opacity-25:hover {
    --text-opacity: 0.25;
  }

  .md\:hover\:tw-text-opacity-50:hover {
    --text-opacity: 0.5;
  }

  .md\:hover\:tw-text-opacity-75:hover {
    --text-opacity: 0.75;
  }

  .md\:hover\:tw-text-opacity-100:hover {
    --text-opacity: 1;
  }

  .md\:focus\:tw-text-opacity-0:focus {
    --text-opacity: 0;
  }

  .md\:focus\:tw-text-opacity-25:focus {
    --text-opacity: 0.25;
  }

  .md\:focus\:tw-text-opacity-50:focus {
    --text-opacity: 0.5;
  }

  .md\:focus\:tw-text-opacity-75:focus {
    --text-opacity: 0.75;
  }

  .md\:focus\:tw-text-opacity-100:focus {
    --text-opacity: 1;
  }

  .md\:tw-italic {
    font-style: italic;
  }

  .md\:tw-not-italic {
    font-style: normal;
  }

  .md\:tw-uppercase {
    text-transform: uppercase;
  }

  .md\:tw-lowercase {
    text-transform: lowercase;
  }

  .md\:tw-capitalize {
    text-transform: capitalize;
  }

  .md\:tw-normal-case {
    text-transform: none;
  }

  .md\:tw-underline {
    text-decoration: underline;
  }

  .md\:tw-line-through {
    text-decoration: line-through;
  }

  .md\:tw-no-underline {
    text-decoration: none;
  }

  .md\:hover\:tw-underline:hover {
    text-decoration: underline;
  }

  .md\:hover\:tw-line-through:hover {
    text-decoration: line-through;
  }

  .md\:hover\:tw-no-underline:hover {
    text-decoration: none;
  }

  .md\:focus\:tw-underline:focus {
    text-decoration: underline;
  }

  .md\:focus\:tw-line-through:focus {
    text-decoration: line-through;
  }

  .md\:focus\:tw-no-underline:focus {
    text-decoration: none;
  }

  .md\:tw-antialiased {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  .md\:tw-subpixel-antialiased {
    -webkit-font-smoothing: auto;
    -moz-osx-font-smoothing: auto;
  }

  .md\:tw-ordinal, .md\:tw-slashed-zero, .md\:tw-lining-nums, .md\:tw-oldstyle-nums, .md\:tw-proportional-nums, .md\:tw-tabular-nums, .md\:tw-diagonal-fractions, .md\:tw-stacked-fractions {
    --font-variant-numeric-ordinal: var(--tailwind-empty,/*!*/ /*!*/);
    --font-variant-numeric-slashed-zero: var(--tailwind-empty,/*!*/ /*!*/);
    --font-variant-numeric-figure: var(--tailwind-empty,/*!*/ /*!*/);
    --font-variant-numeric-spacing: var(--tailwind-empty,/*!*/ /*!*/);
    --font-variant-numeric-fraction: var(--tailwind-empty,/*!*/ /*!*/);
    font-variant-numeric: var(--font-variant-numeric-ordinal) var(--font-variant-numeric-slashed-zero) var(--font-variant-numeric-figure) var(--font-variant-numeric-spacing) var(--font-variant-numeric-fraction);
  }

  .md\:tw-normal-nums {
    font-variant-numeric: normal;
  }

  .md\:tw-ordinal {
    --font-variant-numeric-ordinal: ordinal;
  }

  .md\:tw-slashed-zero {
    --font-variant-numeric-slashed-zero: slashed-zero;
  }

  .md\:tw-lining-nums {
    --font-variant-numeric-figure: lining-nums;
  }

  .md\:tw-oldstyle-nums {
    --font-variant-numeric-figure: oldstyle-nums;
  }

  .md\:tw-proportional-nums {
    --font-variant-numeric-spacing: proportional-nums;
  }

  .md\:tw-tabular-nums {
    --font-variant-numeric-spacing: tabular-nums;
  }

  .md\:tw-diagonal-fractions {
    --font-variant-numeric-fraction: diagonal-fractions;
  }

  .md\:tw-stacked-fractions {
    --font-variant-numeric-fraction: stacked-fractions;
  }

  .md\:tw-tracking-tighter {
    letter-spacing: -0.05em;
  }

  .md\:tw-tracking-tight {
    letter-spacing: -0.025em;
  }

  .md\:tw-tracking-normal {
    letter-spacing: 0;
  }

  .md\:tw-tracking-wide {
    letter-spacing: 0.025em;
  }

  .md\:tw-tracking-wider {
    letter-spacing: 0.05em;
  }

  .md\:tw-tracking-widest {
    letter-spacing: 0.1em;
  }

  .md\:tw-select-none {
    -webkit-user-select: none;
       -moz-user-select: none;
            user-select: none;
  }

  .md\:tw-select-text {
    -webkit-user-select: text;
       -moz-user-select: text;
            user-select: text;
  }

  .md\:tw-select-all {
    -webkit-user-select: all;
       -moz-user-select: all;
            user-select: all;
  }

  .md\:tw-select-auto {
    -webkit-user-select: auto;
       -moz-user-select: auto;
            user-select: auto;
  }

  .md\:tw-align-baseline {
    vertical-align: baseline;
  }

  .md\:tw-align-top {
    vertical-align: top;
  }

  .md\:tw-align-middle {
    vertical-align: middle;
  }

  .md\:tw-align-bottom {
    vertical-align: bottom;
  }

  .md\:tw-align-text-top {
    vertical-align: text-top;
  }

  .md\:tw-align-text-bottom {
    vertical-align: text-bottom;
  }

  .md\:tw-visible {
    visibility: visible;
  }

  .md\:tw-invisible {
    visibility: hidden;
  }

  .md\:tw-whitespace-normal {
    white-space: normal;
  }

  .md\:tw-whitespace-no-wrap {
    white-space: nowrap;
  }

  .md\:tw-whitespace-pre {
    white-space: pre;
  }

  .md\:tw-whitespace-pre-line {
    white-space: pre-line;
  }

  .md\:tw-whitespace-pre-wrap {
    white-space: pre-wrap;
  }

  .md\:tw-break-normal {
    word-wrap: normal;
    overflow-wrap: normal;
    word-break: normal;
  }

  .md\:tw-break-words {
    word-wrap: break-word;
    overflow-wrap: break-word;
  }

  .md\:tw-break-all {
    word-break: break-all;
  }

  .md\:tw-truncate {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .md\:tw-w-0 {
    width: 0;
  }

  .md\:tw-w-1 {
    width: 0.25rem;
  }

  .md\:tw-w-2 {
    width: 0.5rem;
  }

  .md\:tw-w-3 {
    width: 0.75rem;
  }

  .md\:tw-w-4 {
    width: 1rem;
  }

  .md\:tw-w-5 {
    width: 1.25rem;
  }

  .md\:tw-w-6 {
    width: 1.5rem;
  }

  .md\:tw-w-8 {
    width: 2rem;
  }

  .md\:tw-w-10 {
    width: 2.5rem;
  }

  .md\:tw-w-12 {
    width: 3rem;
  }

  .md\:tw-w-16 {
    width: 4rem;
  }

  .md\:tw-w-20 {
    width: 5rem;
  }

  .md\:tw-w-24 {
    width: 6rem;
  }

  .md\:tw-w-32 {
    width: 8rem;
  }

  .md\:tw-w-40 {
    width: 10rem;
  }

  .md\:tw-w-48 {
    width: 12rem;
  }

  .md\:tw-w-56 {
    width: 14rem;
  }

  .md\:tw-w-64 {
    width: 16rem;
  }

  .md\:tw-w-auto {
    width: auto;
  }

  .md\:tw-w-px {
    width: 1px;
  }

  .md\:tw-w-270px {
    width: 270px;
  }

  .md\:tw-w-400px {
    width: 400px;
  }

  .md\:tw-w-464px {
    width: 464px;
  }

  .md\:tw-w-480px {
    width: 480px;
  }

  .md\:tw-w-500px {
    width: 500px;
  }

  .md\:tw-w-576px {
    width: 576px;
  }

  .md\:tw-w-600px {
    width: 600px;
  }

  .md\:tw-w-672px {
    width: 672px;
  }

  .md\:tw-w-1\/2 {
    width: 50%;
  }

  .md\:tw-w-1\/3 {
    width: 33.333333%;
  }

  .md\:tw-w-2\/3 {
    width: 66.666667%;
  }

  .md\:tw-w-1\/4 {
    width: 25%;
  }

  .md\:tw-w-2\/4 {
    width: 50%;
  }

  .md\:tw-w-3\/4 {
    width: 75%;
  }

  .md\:tw-w-1\/5 {
    width: 20%;
  }

  .md\:tw-w-2\/5 {
    width: 40%;
  }

  .md\:tw-w-3\/5 {
    width: 60%;
  }

  .md\:tw-w-4\/5 {
    width: 80%;
  }

  .md\:tw-w-1\/6 {
    width: 16.666667%;
  }

  .md\:tw-w-2\/6 {
    width: 33.333333%;
  }

  .md\:tw-w-3\/6 {
    width: 50%;
  }

  .md\:tw-w-4\/6 {
    width: 66.666667%;
  }

  .md\:tw-w-5\/6 {
    width: 83.333333%;
  }

  .md\:tw-w-1\/12 {
    width: 8.333333%;
  }

  .md\:tw-w-2\/12 {
    width: 16.666667%;
  }

  .md\:tw-w-3\/12 {
    width: 25%;
  }

  .md\:tw-w-4\/12 {
    width: 33.333333%;
  }

  .md\:tw-w-5\/12 {
    width: 41.666667%;
  }

  .md\:tw-w-6\/12 {
    width: 50%;
  }

  .md\:tw-w-7\/12 {
    width: 58.333333%;
  }

  .md\:tw-w-8\/12 {
    width: 66.666667%;
  }

  .md\:tw-w-9\/12 {
    width: 75%;
  }

  .md\:tw-w-10\/12 {
    width: 83.333333%;
  }

  .md\:tw-w-11\/12 {
    width: 91.666667%;
  }

  .md\:tw-w-full {
    width: 100%;
  }

  .md\:tw-w-screen {
    width: 100vw;
  }

  .md\:tw-z-0 {
    z-index: 0;
  }

  .md\:tw-z-10 {
    z-index: 10;
  }

  .md\:tw-z-20 {
    z-index: 20;
  }

  .md\:tw-z-30 {
    z-index: 30;
  }

  .md\:tw-z-40 {
    z-index: 40;
  }

  .md\:tw-z-50 {
    z-index: 50;
  }

  .md\:tw-z-auto {
    z-index: auto;
  }

  .md\:tw-gap-0 {
    grid-gap: 0;
    gap: 0;
  }

  .md\:tw-gap-1 {
    grid-gap: 0.25rem;
    gap: 0.25rem;
  }

  .md\:tw-gap-2 {
    grid-gap: 0.5rem;
    gap: 0.5rem;
  }

  .md\:tw-gap-3 {
    grid-gap: 0.75rem;
    gap: 0.75rem;
  }

  .md\:tw-gap-4 {
    grid-gap: 1rem;
    gap: 1rem;
  }

  .md\:tw-gap-5 {
    grid-gap: 1.25rem;
    gap: 1.25rem;
  }

  .md\:tw-gap-6 {
    grid-gap: 1.5rem;
    gap: 1.5rem;
  }

  .md\:tw-gap-8 {
    grid-gap: 2rem;
    gap: 2rem;
  }

  .md\:tw-gap-10 {
    grid-gap: 2.5rem;
    gap: 2.5rem;
  }

  .md\:tw-gap-12 {
    grid-gap: 3rem;
    gap: 3rem;
  }

  .md\:tw-gap-16 {
    grid-gap: 4rem;
    gap: 4rem;
  }

  .md\:tw-gap-20 {
    grid-gap: 5rem;
    gap: 5rem;
  }

  .md\:tw-gap-24 {
    grid-gap: 6rem;
    gap: 6rem;
  }

  .md\:tw-gap-32 {
    grid-gap: 8rem;
    gap: 8rem;
  }

  .md\:tw-gap-40 {
    grid-gap: 10rem;
    gap: 10rem;
  }

  .md\:tw-gap-48 {
    grid-gap: 12rem;
    gap: 12rem;
  }

  .md\:tw-gap-56 {
    grid-gap: 14rem;
    gap: 14rem;
  }

  .md\:tw-gap-64 {
    grid-gap: 16rem;
    gap: 16rem;
  }

  .md\:tw-gap-px {
    grid-gap: 1px;
    gap: 1px;
  }

  .md\:tw-gap-270px {
    grid-gap: 270px;
    gap: 270px;
  }

  .md\:tw-gap-400px {
    grid-gap: 400px;
    gap: 400px;
  }

  .md\:tw-gap-464px {
    grid-gap: 464px;
    gap: 464px;
  }

  .md\:tw-gap-480px {
    grid-gap: 480px;
    gap: 480px;
  }

  .md\:tw-gap-500px {
    grid-gap: 500px;
    gap: 500px;
  }

  .md\:tw-gap-576px {
    grid-gap: 576px;
    gap: 576px;
  }

  .md\:tw-gap-600px {
    grid-gap: 600px;
    gap: 600px;
  }

  .md\:tw-gap-672px {
    grid-gap: 672px;
    gap: 672px;
  }

  .md\:tw-col-gap-0 {
    grid-column-gap: 0;
    -moz-column-gap: 0;
         column-gap: 0;
  }

  .md\:tw-col-gap-1 {
    grid-column-gap: 0.25rem;
    -moz-column-gap: 0.25rem;
         column-gap: 0.25rem;
  }

  .md\:tw-col-gap-2 {
    grid-column-gap: 0.5rem;
    -moz-column-gap: 0.5rem;
         column-gap: 0.5rem;
  }

  .md\:tw-col-gap-3 {
    grid-column-gap: 0.75rem;
    -moz-column-gap: 0.75rem;
         column-gap: 0.75rem;
  }

  .md\:tw-col-gap-4 {
    grid-column-gap: 1rem;
    -moz-column-gap: 1rem;
         column-gap: 1rem;
  }

  .md\:tw-col-gap-5 {
    grid-column-gap: 1.25rem;
    -moz-column-gap: 1.25rem;
         column-gap: 1.25rem;
  }

  .md\:tw-col-gap-6 {
    grid-column-gap: 1.5rem;
    -moz-column-gap: 1.5rem;
         column-gap: 1.5rem;
  }

  .md\:tw-col-gap-8 {
    grid-column-gap: 2rem;
    -moz-column-gap: 2rem;
         column-gap: 2rem;
  }

  .md\:tw-col-gap-10 {
    grid-column-gap: 2.5rem;
    -moz-column-gap: 2.5rem;
         column-gap: 2.5rem;
  }

  .md\:tw-col-gap-12 {
    grid-column-gap: 3rem;
    -moz-column-gap: 3rem;
         column-gap: 3rem;
  }

  .md\:tw-col-gap-16 {
    grid-column-gap: 4rem;
    -moz-column-gap: 4rem;
         column-gap: 4rem;
  }

  .md\:tw-col-gap-20 {
    grid-column-gap: 5rem;
    -moz-column-gap: 5rem;
         column-gap: 5rem;
  }

  .md\:tw-col-gap-24 {
    grid-column-gap: 6rem;
    -moz-column-gap: 6rem;
         column-gap: 6rem;
  }

  .md\:tw-col-gap-32 {
    grid-column-gap: 8rem;
    -moz-column-gap: 8rem;
         column-gap: 8rem;
  }

  .md\:tw-col-gap-40 {
    grid-column-gap: 10rem;
    -moz-column-gap: 10rem;
         column-gap: 10rem;
  }

  .md\:tw-col-gap-48 {
    grid-column-gap: 12rem;
    -moz-column-gap: 12rem;
         column-gap: 12rem;
  }

  .md\:tw-col-gap-56 {
    grid-column-gap: 14rem;
    -moz-column-gap: 14rem;
         column-gap: 14rem;
  }

  .md\:tw-col-gap-64 {
    grid-column-gap: 16rem;
    -moz-column-gap: 16rem;
         column-gap: 16rem;
  }

  .md\:tw-col-gap-px {
    grid-column-gap: 1px;
    -moz-column-gap: 1px;
         column-gap: 1px;
  }

  .md\:tw-col-gap-270px {
    grid-column-gap: 270px;
    -moz-column-gap: 270px;
         column-gap: 270px;
  }

  .md\:tw-col-gap-400px {
    grid-column-gap: 400px;
    -moz-column-gap: 400px;
         column-gap: 400px;
  }

  .md\:tw-col-gap-464px {
    grid-column-gap: 464px;
    -moz-column-gap: 464px;
         column-gap: 464px;
  }

  .md\:tw-col-gap-480px {
    grid-column-gap: 480px;
    -moz-column-gap: 480px;
         column-gap: 480px;
  }

  .md\:tw-col-gap-500px {
    grid-column-gap: 500px;
    -moz-column-gap: 500px;
         column-gap: 500px;
  }

  .md\:tw-col-gap-576px {
    grid-column-gap: 576px;
    -moz-column-gap: 576px;
         column-gap: 576px;
  }

  .md\:tw-col-gap-600px {
    grid-column-gap: 600px;
    -moz-column-gap: 600px;
         column-gap: 600px;
  }

  .md\:tw-col-gap-672px {
    grid-column-gap: 672px;
    -moz-column-gap: 672px;
         column-gap: 672px;
  }

  .md\:tw-gap-x-0 {
    grid-column-gap: 0;
    -moz-column-gap: 0;
         column-gap: 0;
  }

  .md\:tw-gap-x-1 {
    grid-column-gap: 0.25rem;
    -moz-column-gap: 0.25rem;
         column-gap: 0.25rem;
  }

  .md\:tw-gap-x-2 {
    grid-column-gap: 0.5rem;
    -moz-column-gap: 0.5rem;
         column-gap: 0.5rem;
  }

  .md\:tw-gap-x-3 {
    grid-column-gap: 0.75rem;
    -moz-column-gap: 0.75rem;
         column-gap: 0.75rem;
  }

  .md\:tw-gap-x-4 {
    grid-column-gap: 1rem;
    -moz-column-gap: 1rem;
         column-gap: 1rem;
  }

  .md\:tw-gap-x-5 {
    grid-column-gap: 1.25rem;
    -moz-column-gap: 1.25rem;
         column-gap: 1.25rem;
  }

  .md\:tw-gap-x-6 {
    grid-column-gap: 1.5rem;
    -moz-column-gap: 1.5rem;
         column-gap: 1.5rem;
  }

  .md\:tw-gap-x-8 {
    grid-column-gap: 2rem;
    -moz-column-gap: 2rem;
         column-gap: 2rem;
  }

  .md\:tw-gap-x-10 {
    grid-column-gap: 2.5rem;
    -moz-column-gap: 2.5rem;
         column-gap: 2.5rem;
  }

  .md\:tw-gap-x-12 {
    grid-column-gap: 3rem;
    -moz-column-gap: 3rem;
         column-gap: 3rem;
  }

  .md\:tw-gap-x-16 {
    grid-column-gap: 4rem;
    -moz-column-gap: 4rem;
         column-gap: 4rem;
  }

  .md\:tw-gap-x-20 {
    grid-column-gap: 5rem;
    -moz-column-gap: 5rem;
         column-gap: 5rem;
  }

  .md\:tw-gap-x-24 {
    grid-column-gap: 6rem;
    -moz-column-gap: 6rem;
         column-gap: 6rem;
  }

  .md\:tw-gap-x-32 {
    grid-column-gap: 8rem;
    -moz-column-gap: 8rem;
         column-gap: 8rem;
  }

  .md\:tw-gap-x-40 {
    grid-column-gap: 10rem;
    -moz-column-gap: 10rem;
         column-gap: 10rem;
  }

  .md\:tw-gap-x-48 {
    grid-column-gap: 12rem;
    -moz-column-gap: 12rem;
         column-gap: 12rem;
  }

  .md\:tw-gap-x-56 {
    grid-column-gap: 14rem;
    -moz-column-gap: 14rem;
         column-gap: 14rem;
  }

  .md\:tw-gap-x-64 {
    grid-column-gap: 16rem;
    -moz-column-gap: 16rem;
         column-gap: 16rem;
  }

  .md\:tw-gap-x-px {
    grid-column-gap: 1px;
    -moz-column-gap: 1px;
         column-gap: 1px;
  }

  .md\:tw-gap-x-270px {
    grid-column-gap: 270px;
    -moz-column-gap: 270px;
         column-gap: 270px;
  }

  .md\:tw-gap-x-400px {
    grid-column-gap: 400px;
    -moz-column-gap: 400px;
         column-gap: 400px;
  }

  .md\:tw-gap-x-464px {
    grid-column-gap: 464px;
    -moz-column-gap: 464px;
         column-gap: 464px;
  }

  .md\:tw-gap-x-480px {
    grid-column-gap: 480px;
    -moz-column-gap: 480px;
         column-gap: 480px;
  }

  .md\:tw-gap-x-500px {
    grid-column-gap: 500px;
    -moz-column-gap: 500px;
         column-gap: 500px;
  }

  .md\:tw-gap-x-576px {
    grid-column-gap: 576px;
    -moz-column-gap: 576px;
         column-gap: 576px;
  }

  .md\:tw-gap-x-600px {
    grid-column-gap: 600px;
    -moz-column-gap: 600px;
         column-gap: 600px;
  }

  .md\:tw-gap-x-672px {
    grid-column-gap: 672px;
    -moz-column-gap: 672px;
         column-gap: 672px;
  }

  .md\:tw-row-gap-0 {
    grid-row-gap: 0;
    row-gap: 0;
  }

  .md\:tw-row-gap-1 {
    grid-row-gap: 0.25rem;
    row-gap: 0.25rem;
  }

  .md\:tw-row-gap-2 {
    grid-row-gap: 0.5rem;
    row-gap: 0.5rem;
  }

  .md\:tw-row-gap-3 {
    grid-row-gap: 0.75rem;
    row-gap: 0.75rem;
  }

  .md\:tw-row-gap-4 {
    grid-row-gap: 1rem;
    row-gap: 1rem;
  }

  .md\:tw-row-gap-5 {
    grid-row-gap: 1.25rem;
    row-gap: 1.25rem;
  }

  .md\:tw-row-gap-6 {
    grid-row-gap: 1.5rem;
    row-gap: 1.5rem;
  }

  .md\:tw-row-gap-8 {
    grid-row-gap: 2rem;
    row-gap: 2rem;
  }

  .md\:tw-row-gap-10 {
    grid-row-gap: 2.5rem;
    row-gap: 2.5rem;
  }

  .md\:tw-row-gap-12 {
    grid-row-gap: 3rem;
    row-gap: 3rem;
  }

  .md\:tw-row-gap-16 {
    grid-row-gap: 4rem;
    row-gap: 4rem;
  }

  .md\:tw-row-gap-20 {
    grid-row-gap: 5rem;
    row-gap: 5rem;
  }

  .md\:tw-row-gap-24 {
    grid-row-gap: 6rem;
    row-gap: 6rem;
  }

  .md\:tw-row-gap-32 {
    grid-row-gap: 8rem;
    row-gap: 8rem;
  }

  .md\:tw-row-gap-40 {
    grid-row-gap: 10rem;
    row-gap: 10rem;
  }

  .md\:tw-row-gap-48 {
    grid-row-gap: 12rem;
    row-gap: 12rem;
  }

  .md\:tw-row-gap-56 {
    grid-row-gap: 14rem;
    row-gap: 14rem;
  }

  .md\:tw-row-gap-64 {
    grid-row-gap: 16rem;
    row-gap: 16rem;
  }

  .md\:tw-row-gap-px {
    grid-row-gap: 1px;
    row-gap: 1px;
  }

  .md\:tw-row-gap-270px {
    grid-row-gap: 270px;
    row-gap: 270px;
  }

  .md\:tw-row-gap-400px {
    grid-row-gap: 400px;
    row-gap: 400px;
  }

  .md\:tw-row-gap-464px {
    grid-row-gap: 464px;
    row-gap: 464px;
  }

  .md\:tw-row-gap-480px {
    grid-row-gap: 480px;
    row-gap: 480px;
  }

  .md\:tw-row-gap-500px {
    grid-row-gap: 500px;
    row-gap: 500px;
  }

  .md\:tw-row-gap-576px {
    grid-row-gap: 576px;
    row-gap: 576px;
  }

  .md\:tw-row-gap-600px {
    grid-row-gap: 600px;
    row-gap: 600px;
  }

  .md\:tw-row-gap-672px {
    grid-row-gap: 672px;
    row-gap: 672px;
  }

  .md\:tw-gap-y-0 {
    grid-row-gap: 0;
    row-gap: 0;
  }

  .md\:tw-gap-y-1 {
    grid-row-gap: 0.25rem;
    row-gap: 0.25rem;
  }

  .md\:tw-gap-y-2 {
    grid-row-gap: 0.5rem;
    row-gap: 0.5rem;
  }

  .md\:tw-gap-y-3 {
    grid-row-gap: 0.75rem;
    row-gap: 0.75rem;
  }

  .md\:tw-gap-y-4 {
    grid-row-gap: 1rem;
    row-gap: 1rem;
  }

  .md\:tw-gap-y-5 {
    grid-row-gap: 1.25rem;
    row-gap: 1.25rem;
  }

  .md\:tw-gap-y-6 {
    grid-row-gap: 1.5rem;
    row-gap: 1.5rem;
  }

  .md\:tw-gap-y-8 {
    grid-row-gap: 2rem;
    row-gap: 2rem;
  }

  .md\:tw-gap-y-10 {
    grid-row-gap: 2.5rem;
    row-gap: 2.5rem;
  }

  .md\:tw-gap-y-12 {
    grid-row-gap: 3rem;
    row-gap: 3rem;
  }

  .md\:tw-gap-y-16 {
    grid-row-gap: 4rem;
    row-gap: 4rem;
  }

  .md\:tw-gap-y-20 {
    grid-row-gap: 5rem;
    row-gap: 5rem;
  }

  .md\:tw-gap-y-24 {
    grid-row-gap: 6rem;
    row-gap: 6rem;
  }

  .md\:tw-gap-y-32 {
    grid-row-gap: 8rem;
    row-gap: 8rem;
  }

  .md\:tw-gap-y-40 {
    grid-row-gap: 10rem;
    row-gap: 10rem;
  }

  .md\:tw-gap-y-48 {
    grid-row-gap: 12rem;
    row-gap: 12rem;
  }

  .md\:tw-gap-y-56 {
    grid-row-gap: 14rem;
    row-gap: 14rem;
  }

  .md\:tw-gap-y-64 {
    grid-row-gap: 16rem;
    row-gap: 16rem;
  }

  .md\:tw-gap-y-px {
    grid-row-gap: 1px;
    row-gap: 1px;
  }

  .md\:tw-gap-y-270px {
    grid-row-gap: 270px;
    row-gap: 270px;
  }

  .md\:tw-gap-y-400px {
    grid-row-gap: 400px;
    row-gap: 400px;
  }

  .md\:tw-gap-y-464px {
    grid-row-gap: 464px;
    row-gap: 464px;
  }

  .md\:tw-gap-y-480px {
    grid-row-gap: 480px;
    row-gap: 480px;
  }

  .md\:tw-gap-y-500px {
    grid-row-gap: 500px;
    row-gap: 500px;
  }

  .md\:tw-gap-y-576px {
    grid-row-gap: 576px;
    row-gap: 576px;
  }

  .md\:tw-gap-y-600px {
    grid-row-gap: 600px;
    row-gap: 600px;
  }

  .md\:tw-gap-y-672px {
    grid-row-gap: 672px;
    row-gap: 672px;
  }

  .md\:tw-grid-flow-row {
    grid-auto-flow: row;
  }

  .md\:tw-grid-flow-col {
    grid-auto-flow: column;
  }

  .md\:tw-grid-flow-row-dense {
    grid-auto-flow: row dense;
  }

  .md\:tw-grid-flow-col-dense {
    grid-auto-flow: column dense;
  }

  .md\:tw-grid-cols-1 {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }

  .md\:tw-grid-cols-2 {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  .md\:tw-grid-cols-3 {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }

  .md\:tw-grid-cols-4 {
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }

  .md\:tw-grid-cols-5 {
    grid-template-columns: repeat(5, minmax(0, 1fr));
  }

  .md\:tw-grid-cols-6 {
    grid-template-columns: repeat(6, minmax(0, 1fr));
  }

  .md\:tw-grid-cols-7 {
    grid-template-columns: repeat(7, minmax(0, 1fr));
  }

  .md\:tw-grid-cols-8 {
    grid-template-columns: repeat(8, minmax(0, 1fr));
  }

  .md\:tw-grid-cols-9 {
    grid-template-columns: repeat(9, minmax(0, 1fr));
  }

  .md\:tw-grid-cols-10 {
    grid-template-columns: repeat(10, minmax(0, 1fr));
  }

  .md\:tw-grid-cols-11 {
    grid-template-columns: repeat(11, minmax(0, 1fr));
  }

  .md\:tw-grid-cols-12 {
    grid-template-columns: repeat(12, minmax(0, 1fr));
  }

  .md\:tw-grid-cols-none {
    grid-template-columns: none;
  }

  .md\:tw-auto-cols-auto {
    grid-auto-columns: auto;
  }

  .md\:tw-auto-cols-min {
    grid-auto-columns: min-content;
  }

  .md\:tw-auto-cols-max {
    grid-auto-columns: max-content;
  }

  .md\:tw-auto-cols-fr {
    grid-auto-columns: minmax(0, 1fr);
  }

  .md\:tw-col-auto {
    grid-column: auto;
  }

  .md\:tw-col-span-1 {
    grid-column: span 1 / span 1;
  }

  .md\:tw-col-span-2 {
    grid-column: span 2 / span 2;
  }

  .md\:tw-col-span-3 {
    grid-column: span 3 / span 3;
  }

  .md\:tw-col-span-4 {
    grid-column: span 4 / span 4;
  }

  .md\:tw-col-span-5 {
    grid-column: span 5 / span 5;
  }

  .md\:tw-col-span-6 {
    grid-column: span 6 / span 6;
  }

  .md\:tw-col-span-7 {
    grid-column: span 7 / span 7;
  }

  .md\:tw-col-span-8 {
    grid-column: span 8 / span 8;
  }

  .md\:tw-col-span-9 {
    grid-column: span 9 / span 9;
  }

  .md\:tw-col-span-10 {
    grid-column: span 10 / span 10;
  }

  .md\:tw-col-span-11 {
    grid-column: span 11 / span 11;
  }

  .md\:tw-col-span-12 {
    grid-column: span 12 / span 12;
  }

  .md\:tw-col-span-full {
    grid-column: 1 / -1;
  }

  .md\:tw-col-start-1 {
    grid-column-start: 1;
  }

  .md\:tw-col-start-2 {
    grid-column-start: 2;
  }

  .md\:tw-col-start-3 {
    grid-column-start: 3;
  }

  .md\:tw-col-start-4 {
    grid-column-start: 4;
  }

  .md\:tw-col-start-5 {
    grid-column-start: 5;
  }

  .md\:tw-col-start-6 {
    grid-column-start: 6;
  }

  .md\:tw-col-start-7 {
    grid-column-start: 7;
  }

  .md\:tw-col-start-8 {
    grid-column-start: 8;
  }

  .md\:tw-col-start-9 {
    grid-column-start: 9;
  }

  .md\:tw-col-start-10 {
    grid-column-start: 10;
  }

  .md\:tw-col-start-11 {
    grid-column-start: 11;
  }

  .md\:tw-col-start-12 {
    grid-column-start: 12;
  }

  .md\:tw-col-start-13 {
    grid-column-start: 13;
  }

  .md\:tw-col-start-auto {
    grid-column-start: auto;
  }

  .md\:tw-col-end-1 {
    grid-column-end: 1;
  }

  .md\:tw-col-end-2 {
    grid-column-end: 2;
  }

  .md\:tw-col-end-3 {
    grid-column-end: 3;
  }

  .md\:tw-col-end-4 {
    grid-column-end: 4;
  }

  .md\:tw-col-end-5 {
    grid-column-end: 5;
  }

  .md\:tw-col-end-6 {
    grid-column-end: 6;
  }

  .md\:tw-col-end-7 {
    grid-column-end: 7;
  }

  .md\:tw-col-end-8 {
    grid-column-end: 8;
  }

  .md\:tw-col-end-9 {
    grid-column-end: 9;
  }

  .md\:tw-col-end-10 {
    grid-column-end: 10;
  }

  .md\:tw-col-end-11 {
    grid-column-end: 11;
  }

  .md\:tw-col-end-12 {
    grid-column-end: 12;
  }

  .md\:tw-col-end-13 {
    grid-column-end: 13;
  }

  .md\:tw-col-end-auto {
    grid-column-end: auto;
  }

  .md\:tw-grid-rows-1 {
    grid-template-rows: repeat(1, minmax(0, 1fr));
  }

  .md\:tw-grid-rows-2 {
    grid-template-rows: repeat(2, minmax(0, 1fr));
  }

  .md\:tw-grid-rows-3 {
    grid-template-rows: repeat(3, minmax(0, 1fr));
  }

  .md\:tw-grid-rows-4 {
    grid-template-rows: repeat(4, minmax(0, 1fr));
  }

  .md\:tw-grid-rows-5 {
    grid-template-rows: repeat(5, minmax(0, 1fr));
  }

  .md\:tw-grid-rows-6 {
    grid-template-rows: repeat(6, minmax(0, 1fr));
  }

  .md\:tw-grid-rows-none {
    grid-template-rows: none;
  }

  .md\:tw-auto-rows-auto {
    grid-auto-rows: auto;
  }

  .md\:tw-auto-rows-min {
    grid-auto-rows: min-content;
  }

  .md\:tw-auto-rows-max {
    grid-auto-rows: max-content;
  }

  .md\:tw-auto-rows-fr {
    grid-auto-rows: minmax(0, 1fr);
  }

  .md\:tw-row-auto {
    grid-row: auto;
  }

  .md\:tw-row-span-1 {
    grid-row: span 1 / span 1;
  }

  .md\:tw-row-span-2 {
    grid-row: span 2 / span 2;
  }

  .md\:tw-row-span-3 {
    grid-row: span 3 / span 3;
  }

  .md\:tw-row-span-4 {
    grid-row: span 4 / span 4;
  }

  .md\:tw-row-span-5 {
    grid-row: span 5 / span 5;
  }

  .md\:tw-row-span-6 {
    grid-row: span 6 / span 6;
  }

  .md\:tw-row-span-full {
    grid-row: 1 / -1;
  }

  .md\:tw-row-start-1 {
    grid-row-start: 1;
  }

  .md\:tw-row-start-2 {
    grid-row-start: 2;
  }

  .md\:tw-row-start-3 {
    grid-row-start: 3;
  }

  .md\:tw-row-start-4 {
    grid-row-start: 4;
  }

  .md\:tw-row-start-5 {
    grid-row-start: 5;
  }

  .md\:tw-row-start-6 {
    grid-row-start: 6;
  }

  .md\:tw-row-start-7 {
    grid-row-start: 7;
  }

  .md\:tw-row-start-auto {
    grid-row-start: auto;
  }

  .md\:tw-row-end-1 {
    grid-row-end: 1;
  }

  .md\:tw-row-end-2 {
    grid-row-end: 2;
  }

  .md\:tw-row-end-3 {
    grid-row-end: 3;
  }

  .md\:tw-row-end-4 {
    grid-row-end: 4;
  }

  .md\:tw-row-end-5 {
    grid-row-end: 5;
  }

  .md\:tw-row-end-6 {
    grid-row-end: 6;
  }

  .md\:tw-row-end-7 {
    grid-row-end: 7;
  }

  .md\:tw-row-end-auto {
    grid-row-end: auto;
  }

  .md\:tw-transform {
    --transform-translate-x: 0;
    --transform-translate-y: 0;
    --transform-rotate: 0;
    --transform-skew-x: 0;
    --transform-skew-y: 0;
    --transform-scale-x: 1;
    --transform-scale-y: 1;
    transform: translateX(var(--transform-translate-x)) translateY(var(--transform-translate-y)) rotate(var(--transform-rotate)) skewX(var(--transform-skew-x)) skewY(var(--transform-skew-y)) scaleX(var(--transform-scale-x)) scaleY(var(--transform-scale-y));
  }

  .md\:tw-transform-none {
    transform: none;
  }

  .md\:tw-origin-center {
    transform-origin: center;
  }

  .md\:tw-origin-top {
    transform-origin: top;
  }

  .md\:tw-origin-top-right {
    transform-origin: top right;
  }

  .md\:tw-origin-right {
    transform-origin: right;
  }

  .md\:tw-origin-bottom-right {
    transform-origin: bottom right;
  }

  .md\:tw-origin-bottom {
    transform-origin: bottom;
  }

  .md\:tw-origin-bottom-left {
    transform-origin: bottom left;
  }

  .md\:tw-origin-left {
    transform-origin: left;
  }

  .md\:tw-origin-top-left {
    transform-origin: top left;
  }

  .md\:tw-scale-0 {
    --transform-scale-x: 0;
    --transform-scale-y: 0;
  }

  .md\:tw-scale-50 {
    --transform-scale-x: .5;
    --transform-scale-y: .5;
  }

  .md\:tw-scale-75 {
    --transform-scale-x: .75;
    --transform-scale-y: .75;
  }

  .md\:tw-scale-90 {
    --transform-scale-x: .9;
    --transform-scale-y: .9;
  }

  .md\:tw-scale-95 {
    --transform-scale-x: .95;
    --transform-scale-y: .95;
  }

  .md\:tw-scale-100 {
    --transform-scale-x: 1;
    --transform-scale-y: 1;
  }

  .md\:tw-scale-105 {
    --transform-scale-x: 1.05;
    --transform-scale-y: 1.05;
  }

  .md\:tw-scale-110 {
    --transform-scale-x: 1.1;
    --transform-scale-y: 1.1;
  }

  .md\:tw-scale-125 {
    --transform-scale-x: 1.25;
    --transform-scale-y: 1.25;
  }

  .md\:tw-scale-150 {
    --transform-scale-x: 1.5;
    --transform-scale-y: 1.5;
  }

  .md\:tw-scale-x-0 {
    --transform-scale-x: 0;
  }

  .md\:tw-scale-x-50 {
    --transform-scale-x: .5;
  }

  .md\:tw-scale-x-75 {
    --transform-scale-x: .75;
  }

  .md\:tw-scale-x-90 {
    --transform-scale-x: .9;
  }

  .md\:tw-scale-x-95 {
    --transform-scale-x: .95;
  }

  .md\:tw-scale-x-100 {
    --transform-scale-x: 1;
  }

  .md\:tw-scale-x-105 {
    --transform-scale-x: 1.05;
  }

  .md\:tw-scale-x-110 {
    --transform-scale-x: 1.1;
  }

  .md\:tw-scale-x-125 {
    --transform-scale-x: 1.25;
  }

  .md\:tw-scale-x-150 {
    --transform-scale-x: 1.5;
  }

  .md\:tw-scale-y-0 {
    --transform-scale-y: 0;
  }

  .md\:tw-scale-y-50 {
    --transform-scale-y: .5;
  }

  .md\:tw-scale-y-75 {
    --transform-scale-y: .75;
  }

  .md\:tw-scale-y-90 {
    --transform-scale-y: .9;
  }

  .md\:tw-scale-y-95 {
    --transform-scale-y: .95;
  }

  .md\:tw-scale-y-100 {
    --transform-scale-y: 1;
  }

  .md\:tw-scale-y-105 {
    --transform-scale-y: 1.05;
  }

  .md\:tw-scale-y-110 {
    --transform-scale-y: 1.1;
  }

  .md\:tw-scale-y-125 {
    --transform-scale-y: 1.25;
  }

  .md\:tw-scale-y-150 {
    --transform-scale-y: 1.5;
  }

  .md\:hover\:tw-scale-0:hover {
    --transform-scale-x: 0;
    --transform-scale-y: 0;
  }

  .md\:hover\:tw-scale-50:hover {
    --transform-scale-x: .5;
    --transform-scale-y: .5;
  }

  .md\:hover\:tw-scale-75:hover {
    --transform-scale-x: .75;
    --transform-scale-y: .75;
  }

  .md\:hover\:tw-scale-90:hover {
    --transform-scale-x: .9;
    --transform-scale-y: .9;
  }

  .md\:hover\:tw-scale-95:hover {
    --transform-scale-x: .95;
    --transform-scale-y: .95;
  }

  .md\:hover\:tw-scale-100:hover {
    --transform-scale-x: 1;
    --transform-scale-y: 1;
  }

  .md\:hover\:tw-scale-105:hover {
    --transform-scale-x: 1.05;
    --transform-scale-y: 1.05;
  }

  .md\:hover\:tw-scale-110:hover {
    --transform-scale-x: 1.1;
    --transform-scale-y: 1.1;
  }

  .md\:hover\:tw-scale-125:hover {
    --transform-scale-x: 1.25;
    --transform-scale-y: 1.25;
  }

  .md\:hover\:tw-scale-150:hover {
    --transform-scale-x: 1.5;
    --transform-scale-y: 1.5;
  }

  .md\:hover\:tw-scale-x-0:hover {
    --transform-scale-x: 0;
  }

  .md\:hover\:tw-scale-x-50:hover {
    --transform-scale-x: .5;
  }

  .md\:hover\:tw-scale-x-75:hover {
    --transform-scale-x: .75;
  }

  .md\:hover\:tw-scale-x-90:hover {
    --transform-scale-x: .9;
  }

  .md\:hover\:tw-scale-x-95:hover {
    --transform-scale-x: .95;
  }

  .md\:hover\:tw-scale-x-100:hover {
    --transform-scale-x: 1;
  }

  .md\:hover\:tw-scale-x-105:hover {
    --transform-scale-x: 1.05;
  }

  .md\:hover\:tw-scale-x-110:hover {
    --transform-scale-x: 1.1;
  }

  .md\:hover\:tw-scale-x-125:hover {
    --transform-scale-x: 1.25;
  }

  .md\:hover\:tw-scale-x-150:hover {
    --transform-scale-x: 1.5;
  }

  .md\:hover\:tw-scale-y-0:hover {
    --transform-scale-y: 0;
  }

  .md\:hover\:tw-scale-y-50:hover {
    --transform-scale-y: .5;
  }

  .md\:hover\:tw-scale-y-75:hover {
    --transform-scale-y: .75;
  }

  .md\:hover\:tw-scale-y-90:hover {
    --transform-scale-y: .9;
  }

  .md\:hover\:tw-scale-y-95:hover {
    --transform-scale-y: .95;
  }

  .md\:hover\:tw-scale-y-100:hover {
    --transform-scale-y: 1;
  }

  .md\:hover\:tw-scale-y-105:hover {
    --transform-scale-y: 1.05;
  }

  .md\:hover\:tw-scale-y-110:hover {
    --transform-scale-y: 1.1;
  }

  .md\:hover\:tw-scale-y-125:hover {
    --transform-scale-y: 1.25;
  }

  .md\:hover\:tw-scale-y-150:hover {
    --transform-scale-y: 1.5;
  }

  .md\:focus\:tw-scale-0:focus {
    --transform-scale-x: 0;
    --transform-scale-y: 0;
  }

  .md\:focus\:tw-scale-50:focus {
    --transform-scale-x: .5;
    --transform-scale-y: .5;
  }

  .md\:focus\:tw-scale-75:focus {
    --transform-scale-x: .75;
    --transform-scale-y: .75;
  }

  .md\:focus\:tw-scale-90:focus {
    --transform-scale-x: .9;
    --transform-scale-y: .9;
  }

  .md\:focus\:tw-scale-95:focus {
    --transform-scale-x: .95;
    --transform-scale-y: .95;
  }

  .md\:focus\:tw-scale-100:focus {
    --transform-scale-x: 1;
    --transform-scale-y: 1;
  }

  .md\:focus\:tw-scale-105:focus {
    --transform-scale-x: 1.05;
    --transform-scale-y: 1.05;
  }

  .md\:focus\:tw-scale-110:focus {
    --transform-scale-x: 1.1;
    --transform-scale-y: 1.1;
  }

  .md\:focus\:tw-scale-125:focus {
    --transform-scale-x: 1.25;
    --transform-scale-y: 1.25;
  }

  .md\:focus\:tw-scale-150:focus {
    --transform-scale-x: 1.5;
    --transform-scale-y: 1.5;
  }

  .md\:focus\:tw-scale-x-0:focus {
    --transform-scale-x: 0;
  }

  .md\:focus\:tw-scale-x-50:focus {
    --transform-scale-x: .5;
  }

  .md\:focus\:tw-scale-x-75:focus {
    --transform-scale-x: .75;
  }

  .md\:focus\:tw-scale-x-90:focus {
    --transform-scale-x: .9;
  }

  .md\:focus\:tw-scale-x-95:focus {
    --transform-scale-x: .95;
  }

  .md\:focus\:tw-scale-x-100:focus {
    --transform-scale-x: 1;
  }

  .md\:focus\:tw-scale-x-105:focus {
    --transform-scale-x: 1.05;
  }

  .md\:focus\:tw-scale-x-110:focus {
    --transform-scale-x: 1.1;
  }

  .md\:focus\:tw-scale-x-125:focus {
    --transform-scale-x: 1.25;
  }

  .md\:focus\:tw-scale-x-150:focus {
    --transform-scale-x: 1.5;
  }

  .md\:focus\:tw-scale-y-0:focus {
    --transform-scale-y: 0;
  }

  .md\:focus\:tw-scale-y-50:focus {
    --transform-scale-y: .5;
  }

  .md\:focus\:tw-scale-y-75:focus {
    --transform-scale-y: .75;
  }

  .md\:focus\:tw-scale-y-90:focus {
    --transform-scale-y: .9;
  }

  .md\:focus\:tw-scale-y-95:focus {
    --transform-scale-y: .95;
  }

  .md\:focus\:tw-scale-y-100:focus {
    --transform-scale-y: 1;
  }

  .md\:focus\:tw-scale-y-105:focus {
    --transform-scale-y: 1.05;
  }

  .md\:focus\:tw-scale-y-110:focus {
    --transform-scale-y: 1.1;
  }

  .md\:focus\:tw-scale-y-125:focus {
    --transform-scale-y: 1.25;
  }

  .md\:focus\:tw-scale-y-150:focus {
    --transform-scale-y: 1.5;
  }

  .md\:tw-rotate-0 {
    --transform-rotate: 0;
  }

  .md\:tw-rotate-1 {
    --transform-rotate: 1deg;
  }

  .md\:tw-rotate-2 {
    --transform-rotate: 2deg;
  }

  .md\:tw-rotate-3 {
    --transform-rotate: 3deg;
  }

  .md\:tw-rotate-6 {
    --transform-rotate: 6deg;
  }

  .md\:tw-rotate-12 {
    --transform-rotate: 12deg;
  }

  .md\:tw-rotate-45 {
    --transform-rotate: 45deg;
  }

  .md\:tw-rotate-90 {
    --transform-rotate: 90deg;
  }

  .md\:tw-rotate-180 {
    --transform-rotate: 180deg;
  }

  .md\:tw--rotate-180 {
    --transform-rotate: -180deg;
  }

  .md\:tw--rotate-90 {
    --transform-rotate: -90deg;
  }

  .md\:tw--rotate-45 {
    --transform-rotate: -45deg;
  }

  .md\:tw--rotate-12 {
    --transform-rotate: -12deg;
  }

  .md\:tw--rotate-6 {
    --transform-rotate: -6deg;
  }

  .md\:tw--rotate-3 {
    --transform-rotate: -3deg;
  }

  .md\:tw--rotate-2 {
    --transform-rotate: -2deg;
  }

  .md\:tw--rotate-1 {
    --transform-rotate: -1deg;
  }

  .md\:hover\:tw-rotate-0:hover {
    --transform-rotate: 0;
  }

  .md\:hover\:tw-rotate-1:hover {
    --transform-rotate: 1deg;
  }

  .md\:hover\:tw-rotate-2:hover {
    --transform-rotate: 2deg;
  }

  .md\:hover\:tw-rotate-3:hover {
    --transform-rotate: 3deg;
  }

  .md\:hover\:tw-rotate-6:hover {
    --transform-rotate: 6deg;
  }

  .md\:hover\:tw-rotate-12:hover {
    --transform-rotate: 12deg;
  }

  .md\:hover\:tw-rotate-45:hover {
    --transform-rotate: 45deg;
  }

  .md\:hover\:tw-rotate-90:hover {
    --transform-rotate: 90deg;
  }

  .md\:hover\:tw-rotate-180:hover {
    --transform-rotate: 180deg;
  }

  .md\:hover\:tw--rotate-180:hover {
    --transform-rotate: -180deg;
  }

  .md\:hover\:tw--rotate-90:hover {
    --transform-rotate: -90deg;
  }

  .md\:hover\:tw--rotate-45:hover {
    --transform-rotate: -45deg;
  }

  .md\:hover\:tw--rotate-12:hover {
    --transform-rotate: -12deg;
  }

  .md\:hover\:tw--rotate-6:hover {
    --transform-rotate: -6deg;
  }

  .md\:hover\:tw--rotate-3:hover {
    --transform-rotate: -3deg;
  }

  .md\:hover\:tw--rotate-2:hover {
    --transform-rotate: -2deg;
  }

  .md\:hover\:tw--rotate-1:hover {
    --transform-rotate: -1deg;
  }

  .md\:focus\:tw-rotate-0:focus {
    --transform-rotate: 0;
  }

  .md\:focus\:tw-rotate-1:focus {
    --transform-rotate: 1deg;
  }

  .md\:focus\:tw-rotate-2:focus {
    --transform-rotate: 2deg;
  }

  .md\:focus\:tw-rotate-3:focus {
    --transform-rotate: 3deg;
  }

  .md\:focus\:tw-rotate-6:focus {
    --transform-rotate: 6deg;
  }

  .md\:focus\:tw-rotate-12:focus {
    --transform-rotate: 12deg;
  }

  .md\:focus\:tw-rotate-45:focus {
    --transform-rotate: 45deg;
  }

  .md\:focus\:tw-rotate-90:focus {
    --transform-rotate: 90deg;
  }

  .md\:focus\:tw-rotate-180:focus {
    --transform-rotate: 180deg;
  }

  .md\:focus\:tw--rotate-180:focus {
    --transform-rotate: -180deg;
  }

  .md\:focus\:tw--rotate-90:focus {
    --transform-rotate: -90deg;
  }

  .md\:focus\:tw--rotate-45:focus {
    --transform-rotate: -45deg;
  }

  .md\:focus\:tw--rotate-12:focus {
    --transform-rotate: -12deg;
  }

  .md\:focus\:tw--rotate-6:focus {
    --transform-rotate: -6deg;
  }

  .md\:focus\:tw--rotate-3:focus {
    --transform-rotate: -3deg;
  }

  .md\:focus\:tw--rotate-2:focus {
    --transform-rotate: -2deg;
  }

  .md\:focus\:tw--rotate-1:focus {
    --transform-rotate: -1deg;
  }

  .md\:tw-translate-x-0 {
    --transform-translate-x: 0;
  }

  .md\:tw-translate-x-1 {
    --transform-translate-x: 0.25rem;
  }

  .md\:tw-translate-x-2 {
    --transform-translate-x: 0.5rem;
  }

  .md\:tw-translate-x-3 {
    --transform-translate-x: 0.75rem;
  }

  .md\:tw-translate-x-4 {
    --transform-translate-x: 1rem;
  }

  .md\:tw-translate-x-5 {
    --transform-translate-x: 1.25rem;
  }

  .md\:tw-translate-x-6 {
    --transform-translate-x: 1.5rem;
  }

  .md\:tw-translate-x-8 {
    --transform-translate-x: 2rem;
  }

  .md\:tw-translate-x-10 {
    --transform-translate-x: 2.5rem;
  }

  .md\:tw-translate-x-12 {
    --transform-translate-x: 3rem;
  }

  .md\:tw-translate-x-16 {
    --transform-translate-x: 4rem;
  }

  .md\:tw-translate-x-20 {
    --transform-translate-x: 5rem;
  }

  .md\:tw-translate-x-24 {
    --transform-translate-x: 6rem;
  }

  .md\:tw-translate-x-32 {
    --transform-translate-x: 8rem;
  }

  .md\:tw-translate-x-40 {
    --transform-translate-x: 10rem;
  }

  .md\:tw-translate-x-48 {
    --transform-translate-x: 12rem;
  }

  .md\:tw-translate-x-56 {
    --transform-translate-x: 14rem;
  }

  .md\:tw-translate-x-64 {
    --transform-translate-x: 16rem;
  }

  .md\:tw-translate-x-px {
    --transform-translate-x: 1px;
  }

  .md\:tw-translate-x-270px {
    --transform-translate-x: 270px;
  }

  .md\:tw-translate-x-400px {
    --transform-translate-x: 400px;
  }

  .md\:tw-translate-x-464px {
    --transform-translate-x: 464px;
  }

  .md\:tw-translate-x-480px {
    --transform-translate-x: 480px;
  }

  .md\:tw-translate-x-500px {
    --transform-translate-x: 500px;
  }

  .md\:tw-translate-x-576px {
    --transform-translate-x: 576px;
  }

  .md\:tw-translate-x-600px {
    --transform-translate-x: 600px;
  }

  .md\:tw-translate-x-672px {
    --transform-translate-x: 672px;
  }

  .md\:tw--translate-x-1 {
    --transform-translate-x: -0.25rem;
  }

  .md\:tw--translate-x-2 {
    --transform-translate-x: -0.5rem;
  }

  .md\:tw--translate-x-3 {
    --transform-translate-x: -0.75rem;
  }

  .md\:tw--translate-x-4 {
    --transform-translate-x: -1rem;
  }

  .md\:tw--translate-x-5 {
    --transform-translate-x: -1.25rem;
  }

  .md\:tw--translate-x-6 {
    --transform-translate-x: -1.5rem;
  }

  .md\:tw--translate-x-8 {
    --transform-translate-x: -2rem;
  }

  .md\:tw--translate-x-10 {
    --transform-translate-x: -2.5rem;
  }

  .md\:tw--translate-x-12 {
    --transform-translate-x: -3rem;
  }

  .md\:tw--translate-x-16 {
    --transform-translate-x: -4rem;
  }

  .md\:tw--translate-x-20 {
    --transform-translate-x: -5rem;
  }

  .md\:tw--translate-x-24 {
    --transform-translate-x: -6rem;
  }

  .md\:tw--translate-x-32 {
    --transform-translate-x: -8rem;
  }

  .md\:tw--translate-x-40 {
    --transform-translate-x: -10rem;
  }

  .md\:tw--translate-x-48 {
    --transform-translate-x: -12rem;
  }

  .md\:tw--translate-x-56 {
    --transform-translate-x: -14rem;
  }

  .md\:tw--translate-x-64 {
    --transform-translate-x: -16rem;
  }

  .md\:tw--translate-x-px {
    --transform-translate-x: -1px;
  }

  .md\:tw--translate-x-270px {
    --transform-translate-x: -270px;
  }

  .md\:tw--translate-x-400px {
    --transform-translate-x: -400px;
  }

  .md\:tw--translate-x-464px {
    --transform-translate-x: -464px;
  }

  .md\:tw--translate-x-480px {
    --transform-translate-x: -480px;
  }

  .md\:tw--translate-x-500px {
    --transform-translate-x: -500px;
  }

  .md\:tw--translate-x-576px {
    --transform-translate-x: -576px;
  }

  .md\:tw--translate-x-600px {
    --transform-translate-x: -600px;
  }

  .md\:tw--translate-x-672px {
    --transform-translate-x: -672px;
  }

  .md\:tw--translate-x-full {
    --transform-translate-x: -100%;
  }

  .md\:tw--translate-x-1\/2 {
    --transform-translate-x: -50%;
  }

  .md\:tw-translate-x-1\/2 {
    --transform-translate-x: 50%;
  }

  .md\:tw-translate-x-full {
    --transform-translate-x: 100%;
  }

  .md\:tw-translate-y-0 {
    --transform-translate-y: 0;
  }

  .md\:tw-translate-y-1 {
    --transform-translate-y: 0.25rem;
  }

  .md\:tw-translate-y-2 {
    --transform-translate-y: 0.5rem;
  }

  .md\:tw-translate-y-3 {
    --transform-translate-y: 0.75rem;
  }

  .md\:tw-translate-y-4 {
    --transform-translate-y: 1rem;
  }

  .md\:tw-translate-y-5 {
    --transform-translate-y: 1.25rem;
  }

  .md\:tw-translate-y-6 {
    --transform-translate-y: 1.5rem;
  }

  .md\:tw-translate-y-8 {
    --transform-translate-y: 2rem;
  }

  .md\:tw-translate-y-10 {
    --transform-translate-y: 2.5rem;
  }

  .md\:tw-translate-y-12 {
    --transform-translate-y: 3rem;
  }

  .md\:tw-translate-y-16 {
    --transform-translate-y: 4rem;
  }

  .md\:tw-translate-y-20 {
    --transform-translate-y: 5rem;
  }

  .md\:tw-translate-y-24 {
    --transform-translate-y: 6rem;
  }

  .md\:tw-translate-y-32 {
    --transform-translate-y: 8rem;
  }

  .md\:tw-translate-y-40 {
    --transform-translate-y: 10rem;
  }

  .md\:tw-translate-y-48 {
    --transform-translate-y: 12rem;
  }

  .md\:tw-translate-y-56 {
    --transform-translate-y: 14rem;
  }

  .md\:tw-translate-y-64 {
    --transform-translate-y: 16rem;
  }

  .md\:tw-translate-y-px {
    --transform-translate-y: 1px;
  }

  .md\:tw-translate-y-270px {
    --transform-translate-y: 270px;
  }

  .md\:tw-translate-y-400px {
    --transform-translate-y: 400px;
  }

  .md\:tw-translate-y-464px {
    --transform-translate-y: 464px;
  }

  .md\:tw-translate-y-480px {
    --transform-translate-y: 480px;
  }

  .md\:tw-translate-y-500px {
    --transform-translate-y: 500px;
  }

  .md\:tw-translate-y-576px {
    --transform-translate-y: 576px;
  }

  .md\:tw-translate-y-600px {
    --transform-translate-y: 600px;
  }

  .md\:tw-translate-y-672px {
    --transform-translate-y: 672px;
  }

  .md\:tw--translate-y-1 {
    --transform-translate-y: -0.25rem;
  }

  .md\:tw--translate-y-2 {
    --transform-translate-y: -0.5rem;
  }

  .md\:tw--translate-y-3 {
    --transform-translate-y: -0.75rem;
  }

  .md\:tw--translate-y-4 {
    --transform-translate-y: -1rem;
  }

  .md\:tw--translate-y-5 {
    --transform-translate-y: -1.25rem;
  }

  .md\:tw--translate-y-6 {
    --transform-translate-y: -1.5rem;
  }

  .md\:tw--translate-y-8 {
    --transform-translate-y: -2rem;
  }

  .md\:tw--translate-y-10 {
    --transform-translate-y: -2.5rem;
  }

  .md\:tw--translate-y-12 {
    --transform-translate-y: -3rem;
  }

  .md\:tw--translate-y-16 {
    --transform-translate-y: -4rem;
  }

  .md\:tw--translate-y-20 {
    --transform-translate-y: -5rem;
  }

  .md\:tw--translate-y-24 {
    --transform-translate-y: -6rem;
  }

  .md\:tw--translate-y-32 {
    --transform-translate-y: -8rem;
  }

  .md\:tw--translate-y-40 {
    --transform-translate-y: -10rem;
  }

  .md\:tw--translate-y-48 {
    --transform-translate-y: -12rem;
  }

  .md\:tw--translate-y-56 {
    --transform-translate-y: -14rem;
  }

  .md\:tw--translate-y-64 {
    --transform-translate-y: -16rem;
  }

  .md\:tw--translate-y-px {
    --transform-translate-y: -1px;
  }

  .md\:tw--translate-y-270px {
    --transform-translate-y: -270px;
  }

  .md\:tw--translate-y-400px {
    --transform-translate-y: -400px;
  }

  .md\:tw--translate-y-464px {
    --transform-translate-y: -464px;
  }

  .md\:tw--translate-y-480px {
    --transform-translate-y: -480px;
  }

  .md\:tw--translate-y-500px {
    --transform-translate-y: -500px;
  }

  .md\:tw--translate-y-576px {
    --transform-translate-y: -576px;
  }

  .md\:tw--translate-y-600px {
    --transform-translate-y: -600px;
  }

  .md\:tw--translate-y-672px {
    --transform-translate-y: -672px;
  }

  .md\:tw--translate-y-full {
    --transform-translate-y: -100%;
  }

  .md\:tw--translate-y-1\/2 {
    --transform-translate-y: -50%;
  }

  .md\:tw-translate-y-1\/2 {
    --transform-translate-y: 50%;
  }

  .md\:tw-translate-y-full {
    --transform-translate-y: 100%;
  }

  .md\:hover\:tw-translate-x-0:hover {
    --transform-translate-x: 0;
  }

  .md\:hover\:tw-translate-x-1:hover {
    --transform-translate-x: 0.25rem;
  }

  .md\:hover\:tw-translate-x-2:hover {
    --transform-translate-x: 0.5rem;
  }

  .md\:hover\:tw-translate-x-3:hover {
    --transform-translate-x: 0.75rem;
  }

  .md\:hover\:tw-translate-x-4:hover {
    --transform-translate-x: 1rem;
  }

  .md\:hover\:tw-translate-x-5:hover {
    --transform-translate-x: 1.25rem;
  }

  .md\:hover\:tw-translate-x-6:hover {
    --transform-translate-x: 1.5rem;
  }

  .md\:hover\:tw-translate-x-8:hover {
    --transform-translate-x: 2rem;
  }

  .md\:hover\:tw-translate-x-10:hover {
    --transform-translate-x: 2.5rem;
  }

  .md\:hover\:tw-translate-x-12:hover {
    --transform-translate-x: 3rem;
  }

  .md\:hover\:tw-translate-x-16:hover {
    --transform-translate-x: 4rem;
  }

  .md\:hover\:tw-translate-x-20:hover {
    --transform-translate-x: 5rem;
  }

  .md\:hover\:tw-translate-x-24:hover {
    --transform-translate-x: 6rem;
  }

  .md\:hover\:tw-translate-x-32:hover {
    --transform-translate-x: 8rem;
  }

  .md\:hover\:tw-translate-x-40:hover {
    --transform-translate-x: 10rem;
  }

  .md\:hover\:tw-translate-x-48:hover {
    --transform-translate-x: 12rem;
  }

  .md\:hover\:tw-translate-x-56:hover {
    --transform-translate-x: 14rem;
  }

  .md\:hover\:tw-translate-x-64:hover {
    --transform-translate-x: 16rem;
  }

  .md\:hover\:tw-translate-x-px:hover {
    --transform-translate-x: 1px;
  }

  .md\:hover\:tw-translate-x-270px:hover {
    --transform-translate-x: 270px;
  }

  .md\:hover\:tw-translate-x-400px:hover {
    --transform-translate-x: 400px;
  }

  .md\:hover\:tw-translate-x-464px:hover {
    --transform-translate-x: 464px;
  }

  .md\:hover\:tw-translate-x-480px:hover {
    --transform-translate-x: 480px;
  }

  .md\:hover\:tw-translate-x-500px:hover {
    --transform-translate-x: 500px;
  }

  .md\:hover\:tw-translate-x-576px:hover {
    --transform-translate-x: 576px;
  }

  .md\:hover\:tw-translate-x-600px:hover {
    --transform-translate-x: 600px;
  }

  .md\:hover\:tw-translate-x-672px:hover {
    --transform-translate-x: 672px;
  }

  .md\:hover\:tw--translate-x-1:hover {
    --transform-translate-x: -0.25rem;
  }

  .md\:hover\:tw--translate-x-2:hover {
    --transform-translate-x: -0.5rem;
  }

  .md\:hover\:tw--translate-x-3:hover {
    --transform-translate-x: -0.75rem;
  }

  .md\:hover\:tw--translate-x-4:hover {
    --transform-translate-x: -1rem;
  }

  .md\:hover\:tw--translate-x-5:hover {
    --transform-translate-x: -1.25rem;
  }

  .md\:hover\:tw--translate-x-6:hover {
    --transform-translate-x: -1.5rem;
  }

  .md\:hover\:tw--translate-x-8:hover {
    --transform-translate-x: -2rem;
  }

  .md\:hover\:tw--translate-x-10:hover {
    --transform-translate-x: -2.5rem;
  }

  .md\:hover\:tw--translate-x-12:hover {
    --transform-translate-x: -3rem;
  }

  .md\:hover\:tw--translate-x-16:hover {
    --transform-translate-x: -4rem;
  }

  .md\:hover\:tw--translate-x-20:hover {
    --transform-translate-x: -5rem;
  }

  .md\:hover\:tw--translate-x-24:hover {
    --transform-translate-x: -6rem;
  }

  .md\:hover\:tw--translate-x-32:hover {
    --transform-translate-x: -8rem;
  }

  .md\:hover\:tw--translate-x-40:hover {
    --transform-translate-x: -10rem;
  }

  .md\:hover\:tw--translate-x-48:hover {
    --transform-translate-x: -12rem;
  }

  .md\:hover\:tw--translate-x-56:hover {
    --transform-translate-x: -14rem;
  }

  .md\:hover\:tw--translate-x-64:hover {
    --transform-translate-x: -16rem;
  }

  .md\:hover\:tw--translate-x-px:hover {
    --transform-translate-x: -1px;
  }

  .md\:hover\:tw--translate-x-270px:hover {
    --transform-translate-x: -270px;
  }

  .md\:hover\:tw--translate-x-400px:hover {
    --transform-translate-x: -400px;
  }

  .md\:hover\:tw--translate-x-464px:hover {
    --transform-translate-x: -464px;
  }

  .md\:hover\:tw--translate-x-480px:hover {
    --transform-translate-x: -480px;
  }

  .md\:hover\:tw--translate-x-500px:hover {
    --transform-translate-x: -500px;
  }

  .md\:hover\:tw--translate-x-576px:hover {
    --transform-translate-x: -576px;
  }

  .md\:hover\:tw--translate-x-600px:hover {
    --transform-translate-x: -600px;
  }

  .md\:hover\:tw--translate-x-672px:hover {
    --transform-translate-x: -672px;
  }

  .md\:hover\:tw--translate-x-full:hover {
    --transform-translate-x: -100%;
  }

  .md\:hover\:tw--translate-x-1\/2:hover {
    --transform-translate-x: -50%;
  }

  .md\:hover\:tw-translate-x-1\/2:hover {
    --transform-translate-x: 50%;
  }

  .md\:hover\:tw-translate-x-full:hover {
    --transform-translate-x: 100%;
  }

  .md\:hover\:tw-translate-y-0:hover {
    --transform-translate-y: 0;
  }

  .md\:hover\:tw-translate-y-1:hover {
    --transform-translate-y: 0.25rem;
  }

  .md\:hover\:tw-translate-y-2:hover {
    --transform-translate-y: 0.5rem;
  }

  .md\:hover\:tw-translate-y-3:hover {
    --transform-translate-y: 0.75rem;
  }

  .md\:hover\:tw-translate-y-4:hover {
    --transform-translate-y: 1rem;
  }

  .md\:hover\:tw-translate-y-5:hover {
    --transform-translate-y: 1.25rem;
  }

  .md\:hover\:tw-translate-y-6:hover {
    --transform-translate-y: 1.5rem;
  }

  .md\:hover\:tw-translate-y-8:hover {
    --transform-translate-y: 2rem;
  }

  .md\:hover\:tw-translate-y-10:hover {
    --transform-translate-y: 2.5rem;
  }

  .md\:hover\:tw-translate-y-12:hover {
    --transform-translate-y: 3rem;
  }

  .md\:hover\:tw-translate-y-16:hover {
    --transform-translate-y: 4rem;
  }

  .md\:hover\:tw-translate-y-20:hover {
    --transform-translate-y: 5rem;
  }

  .md\:hover\:tw-translate-y-24:hover {
    --transform-translate-y: 6rem;
  }

  .md\:hover\:tw-translate-y-32:hover {
    --transform-translate-y: 8rem;
  }

  .md\:hover\:tw-translate-y-40:hover {
    --transform-translate-y: 10rem;
  }

  .md\:hover\:tw-translate-y-48:hover {
    --transform-translate-y: 12rem;
  }

  .md\:hover\:tw-translate-y-56:hover {
    --transform-translate-y: 14rem;
  }

  .md\:hover\:tw-translate-y-64:hover {
    --transform-translate-y: 16rem;
  }

  .md\:hover\:tw-translate-y-px:hover {
    --transform-translate-y: 1px;
  }

  .md\:hover\:tw-translate-y-270px:hover {
    --transform-translate-y: 270px;
  }

  .md\:hover\:tw-translate-y-400px:hover {
    --transform-translate-y: 400px;
  }

  .md\:hover\:tw-translate-y-464px:hover {
    --transform-translate-y: 464px;
  }

  .md\:hover\:tw-translate-y-480px:hover {
    --transform-translate-y: 480px;
  }

  .md\:hover\:tw-translate-y-500px:hover {
    --transform-translate-y: 500px;
  }

  .md\:hover\:tw-translate-y-576px:hover {
    --transform-translate-y: 576px;
  }

  .md\:hover\:tw-translate-y-600px:hover {
    --transform-translate-y: 600px;
  }

  .md\:hover\:tw-translate-y-672px:hover {
    --transform-translate-y: 672px;
  }

  .md\:hover\:tw--translate-y-1:hover {
    --transform-translate-y: -0.25rem;
  }

  .md\:hover\:tw--translate-y-2:hover {
    --transform-translate-y: -0.5rem;
  }

  .md\:hover\:tw--translate-y-3:hover {
    --transform-translate-y: -0.75rem;
  }

  .md\:hover\:tw--translate-y-4:hover {
    --transform-translate-y: -1rem;
  }

  .md\:hover\:tw--translate-y-5:hover {
    --transform-translate-y: -1.25rem;
  }

  .md\:hover\:tw--translate-y-6:hover {
    --transform-translate-y: -1.5rem;
  }

  .md\:hover\:tw--translate-y-8:hover {
    --transform-translate-y: -2rem;
  }

  .md\:hover\:tw--translate-y-10:hover {
    --transform-translate-y: -2.5rem;
  }

  .md\:hover\:tw--translate-y-12:hover {
    --transform-translate-y: -3rem;
  }

  .md\:hover\:tw--translate-y-16:hover {
    --transform-translate-y: -4rem;
  }

  .md\:hover\:tw--translate-y-20:hover {
    --transform-translate-y: -5rem;
  }

  .md\:hover\:tw--translate-y-24:hover {
    --transform-translate-y: -6rem;
  }

  .md\:hover\:tw--translate-y-32:hover {
    --transform-translate-y: -8rem;
  }

  .md\:hover\:tw--translate-y-40:hover {
    --transform-translate-y: -10rem;
  }

  .md\:hover\:tw--translate-y-48:hover {
    --transform-translate-y: -12rem;
  }

  .md\:hover\:tw--translate-y-56:hover {
    --transform-translate-y: -14rem;
  }

  .md\:hover\:tw--translate-y-64:hover {
    --transform-translate-y: -16rem;
  }

  .md\:hover\:tw--translate-y-px:hover {
    --transform-translate-y: -1px;
  }

  .md\:hover\:tw--translate-y-270px:hover {
    --transform-translate-y: -270px;
  }

  .md\:hover\:tw--translate-y-400px:hover {
    --transform-translate-y: -400px;
  }

  .md\:hover\:tw--translate-y-464px:hover {
    --transform-translate-y: -464px;
  }

  .md\:hover\:tw--translate-y-480px:hover {
    --transform-translate-y: -480px;
  }

  .md\:hover\:tw--translate-y-500px:hover {
    --transform-translate-y: -500px;
  }

  .md\:hover\:tw--translate-y-576px:hover {
    --transform-translate-y: -576px;
  }

  .md\:hover\:tw--translate-y-600px:hover {
    --transform-translate-y: -600px;
  }

  .md\:hover\:tw--translate-y-672px:hover {
    --transform-translate-y: -672px;
  }

  .md\:hover\:tw--translate-y-full:hover {
    --transform-translate-y: -100%;
  }

  .md\:hover\:tw--translate-y-1\/2:hover {
    --transform-translate-y: -50%;
  }

  .md\:hover\:tw-translate-y-1\/2:hover {
    --transform-translate-y: 50%;
  }

  .md\:hover\:tw-translate-y-full:hover {
    --transform-translate-y: 100%;
  }

  .md\:focus\:tw-translate-x-0:focus {
    --transform-translate-x: 0;
  }

  .md\:focus\:tw-translate-x-1:focus {
    --transform-translate-x: 0.25rem;
  }

  .md\:focus\:tw-translate-x-2:focus {
    --transform-translate-x: 0.5rem;
  }

  .md\:focus\:tw-translate-x-3:focus {
    --transform-translate-x: 0.75rem;
  }

  .md\:focus\:tw-translate-x-4:focus {
    --transform-translate-x: 1rem;
  }

  .md\:focus\:tw-translate-x-5:focus {
    --transform-translate-x: 1.25rem;
  }

  .md\:focus\:tw-translate-x-6:focus {
    --transform-translate-x: 1.5rem;
  }

  .md\:focus\:tw-translate-x-8:focus {
    --transform-translate-x: 2rem;
  }

  .md\:focus\:tw-translate-x-10:focus {
    --transform-translate-x: 2.5rem;
  }

  .md\:focus\:tw-translate-x-12:focus {
    --transform-translate-x: 3rem;
  }

  .md\:focus\:tw-translate-x-16:focus {
    --transform-translate-x: 4rem;
  }

  .md\:focus\:tw-translate-x-20:focus {
    --transform-translate-x: 5rem;
  }

  .md\:focus\:tw-translate-x-24:focus {
    --transform-translate-x: 6rem;
  }

  .md\:focus\:tw-translate-x-32:focus {
    --transform-translate-x: 8rem;
  }

  .md\:focus\:tw-translate-x-40:focus {
    --transform-translate-x: 10rem;
  }

  .md\:focus\:tw-translate-x-48:focus {
    --transform-translate-x: 12rem;
  }

  .md\:focus\:tw-translate-x-56:focus {
    --transform-translate-x: 14rem;
  }

  .md\:focus\:tw-translate-x-64:focus {
    --transform-translate-x: 16rem;
  }

  .md\:focus\:tw-translate-x-px:focus {
    --transform-translate-x: 1px;
  }

  .md\:focus\:tw-translate-x-270px:focus {
    --transform-translate-x: 270px;
  }

  .md\:focus\:tw-translate-x-400px:focus {
    --transform-translate-x: 400px;
  }

  .md\:focus\:tw-translate-x-464px:focus {
    --transform-translate-x: 464px;
  }

  .md\:focus\:tw-translate-x-480px:focus {
    --transform-translate-x: 480px;
  }

  .md\:focus\:tw-translate-x-500px:focus {
    --transform-translate-x: 500px;
  }

  .md\:focus\:tw-translate-x-576px:focus {
    --transform-translate-x: 576px;
  }

  .md\:focus\:tw-translate-x-600px:focus {
    --transform-translate-x: 600px;
  }

  .md\:focus\:tw-translate-x-672px:focus {
    --transform-translate-x: 672px;
  }

  .md\:focus\:tw--translate-x-1:focus {
    --transform-translate-x: -0.25rem;
  }

  .md\:focus\:tw--translate-x-2:focus {
    --transform-translate-x: -0.5rem;
  }

  .md\:focus\:tw--translate-x-3:focus {
    --transform-translate-x: -0.75rem;
  }

  .md\:focus\:tw--translate-x-4:focus {
    --transform-translate-x: -1rem;
  }

  .md\:focus\:tw--translate-x-5:focus {
    --transform-translate-x: -1.25rem;
  }

  .md\:focus\:tw--translate-x-6:focus {
    --transform-translate-x: -1.5rem;
  }

  .md\:focus\:tw--translate-x-8:focus {
    --transform-translate-x: -2rem;
  }

  .md\:focus\:tw--translate-x-10:focus {
    --transform-translate-x: -2.5rem;
  }

  .md\:focus\:tw--translate-x-12:focus {
    --transform-translate-x: -3rem;
  }

  .md\:focus\:tw--translate-x-16:focus {
    --transform-translate-x: -4rem;
  }

  .md\:focus\:tw--translate-x-20:focus {
    --transform-translate-x: -5rem;
  }

  .md\:focus\:tw--translate-x-24:focus {
    --transform-translate-x: -6rem;
  }

  .md\:focus\:tw--translate-x-32:focus {
    --transform-translate-x: -8rem;
  }

  .md\:focus\:tw--translate-x-40:focus {
    --transform-translate-x: -10rem;
  }

  .md\:focus\:tw--translate-x-48:focus {
    --transform-translate-x: -12rem;
  }

  .md\:focus\:tw--translate-x-56:focus {
    --transform-translate-x: -14rem;
  }

  .md\:focus\:tw--translate-x-64:focus {
    --transform-translate-x: -16rem;
  }

  .md\:focus\:tw--translate-x-px:focus {
    --transform-translate-x: -1px;
  }

  .md\:focus\:tw--translate-x-270px:focus {
    --transform-translate-x: -270px;
  }

  .md\:focus\:tw--translate-x-400px:focus {
    --transform-translate-x: -400px;
  }

  .md\:focus\:tw--translate-x-464px:focus {
    --transform-translate-x: -464px;
  }

  .md\:focus\:tw--translate-x-480px:focus {
    --transform-translate-x: -480px;
  }

  .md\:focus\:tw--translate-x-500px:focus {
    --transform-translate-x: -500px;
  }

  .md\:focus\:tw--translate-x-576px:focus {
    --transform-translate-x: -576px;
  }

  .md\:focus\:tw--translate-x-600px:focus {
    --transform-translate-x: -600px;
  }

  .md\:focus\:tw--translate-x-672px:focus {
    --transform-translate-x: -672px;
  }

  .md\:focus\:tw--translate-x-full:focus {
    --transform-translate-x: -100%;
  }

  .md\:focus\:tw--translate-x-1\/2:focus {
    --transform-translate-x: -50%;
  }

  .md\:focus\:tw-translate-x-1\/2:focus {
    --transform-translate-x: 50%;
  }

  .md\:focus\:tw-translate-x-full:focus {
    --transform-translate-x: 100%;
  }

  .md\:focus\:tw-translate-y-0:focus {
    --transform-translate-y: 0;
  }

  .md\:focus\:tw-translate-y-1:focus {
    --transform-translate-y: 0.25rem;
  }

  .md\:focus\:tw-translate-y-2:focus {
    --transform-translate-y: 0.5rem;
  }

  .md\:focus\:tw-translate-y-3:focus {
    --transform-translate-y: 0.75rem;
  }

  .md\:focus\:tw-translate-y-4:focus {
    --transform-translate-y: 1rem;
  }

  .md\:focus\:tw-translate-y-5:focus {
    --transform-translate-y: 1.25rem;
  }

  .md\:focus\:tw-translate-y-6:focus {
    --transform-translate-y: 1.5rem;
  }

  .md\:focus\:tw-translate-y-8:focus {
    --transform-translate-y: 2rem;
  }

  .md\:focus\:tw-translate-y-10:focus {
    --transform-translate-y: 2.5rem;
  }

  .md\:focus\:tw-translate-y-12:focus {
    --transform-translate-y: 3rem;
  }

  .md\:focus\:tw-translate-y-16:focus {
    --transform-translate-y: 4rem;
  }

  .md\:focus\:tw-translate-y-20:focus {
    --transform-translate-y: 5rem;
  }

  .md\:focus\:tw-translate-y-24:focus {
    --transform-translate-y: 6rem;
  }

  .md\:focus\:tw-translate-y-32:focus {
    --transform-translate-y: 8rem;
  }

  .md\:focus\:tw-translate-y-40:focus {
    --transform-translate-y: 10rem;
  }

  .md\:focus\:tw-translate-y-48:focus {
    --transform-translate-y: 12rem;
  }

  .md\:focus\:tw-translate-y-56:focus {
    --transform-translate-y: 14rem;
  }

  .md\:focus\:tw-translate-y-64:focus {
    --transform-translate-y: 16rem;
  }

  .md\:focus\:tw-translate-y-px:focus {
    --transform-translate-y: 1px;
  }

  .md\:focus\:tw-translate-y-270px:focus {
    --transform-translate-y: 270px;
  }

  .md\:focus\:tw-translate-y-400px:focus {
    --transform-translate-y: 400px;
  }

  .md\:focus\:tw-translate-y-464px:focus {
    --transform-translate-y: 464px;
  }

  .md\:focus\:tw-translate-y-480px:focus {
    --transform-translate-y: 480px;
  }

  .md\:focus\:tw-translate-y-500px:focus {
    --transform-translate-y: 500px;
  }

  .md\:focus\:tw-translate-y-576px:focus {
    --transform-translate-y: 576px;
  }

  .md\:focus\:tw-translate-y-600px:focus {
    --transform-translate-y: 600px;
  }

  .md\:focus\:tw-translate-y-672px:focus {
    --transform-translate-y: 672px;
  }

  .md\:focus\:tw--translate-y-1:focus {
    --transform-translate-y: -0.25rem;
  }

  .md\:focus\:tw--translate-y-2:focus {
    --transform-translate-y: -0.5rem;
  }

  .md\:focus\:tw--translate-y-3:focus {
    --transform-translate-y: -0.75rem;
  }

  .md\:focus\:tw--translate-y-4:focus {
    --transform-translate-y: -1rem;
  }

  .md\:focus\:tw--translate-y-5:focus {
    --transform-translate-y: -1.25rem;
  }

  .md\:focus\:tw--translate-y-6:focus {
    --transform-translate-y: -1.5rem;
  }

  .md\:focus\:tw--translate-y-8:focus {
    --transform-translate-y: -2rem;
  }

  .md\:focus\:tw--translate-y-10:focus {
    --transform-translate-y: -2.5rem;
  }

  .md\:focus\:tw--translate-y-12:focus {
    --transform-translate-y: -3rem;
  }

  .md\:focus\:tw--translate-y-16:focus {
    --transform-translate-y: -4rem;
  }

  .md\:focus\:tw--translate-y-20:focus {
    --transform-translate-y: -5rem;
  }

  .md\:focus\:tw--translate-y-24:focus {
    --transform-translate-y: -6rem;
  }

  .md\:focus\:tw--translate-y-32:focus {
    --transform-translate-y: -8rem;
  }

  .md\:focus\:tw--translate-y-40:focus {
    --transform-translate-y: -10rem;
  }

  .md\:focus\:tw--translate-y-48:focus {
    --transform-translate-y: -12rem;
  }

  .md\:focus\:tw--translate-y-56:focus {
    --transform-translate-y: -14rem;
  }

  .md\:focus\:tw--translate-y-64:focus {
    --transform-translate-y: -16rem;
  }

  .md\:focus\:tw--translate-y-px:focus {
    --transform-translate-y: -1px;
  }

  .md\:focus\:tw--translate-y-270px:focus {
    --transform-translate-y: -270px;
  }

  .md\:focus\:tw--translate-y-400px:focus {
    --transform-translate-y: -400px;
  }

  .md\:focus\:tw--translate-y-464px:focus {
    --transform-translate-y: -464px;
  }

  .md\:focus\:tw--translate-y-480px:focus {
    --transform-translate-y: -480px;
  }

  .md\:focus\:tw--translate-y-500px:focus {
    --transform-translate-y: -500px;
  }

  .md\:focus\:tw--translate-y-576px:focus {
    --transform-translate-y: -576px;
  }

  .md\:focus\:tw--translate-y-600px:focus {
    --transform-translate-y: -600px;
  }

  .md\:focus\:tw--translate-y-672px:focus {
    --transform-translate-y: -672px;
  }

  .md\:focus\:tw--translate-y-full:focus {
    --transform-translate-y: -100%;
  }

  .md\:focus\:tw--translate-y-1\/2:focus {
    --transform-translate-y: -50%;
  }

  .md\:focus\:tw-translate-y-1\/2:focus {
    --transform-translate-y: 50%;
  }

  .md\:focus\:tw-translate-y-full:focus {
    --transform-translate-y: 100%;
  }

  .md\:tw-skew-x-0 {
    --transform-skew-x: 0;
  }

  .md\:tw-skew-x-1 {
    --transform-skew-x: 1deg;
  }

  .md\:tw-skew-x-2 {
    --transform-skew-x: 2deg;
  }

  .md\:tw-skew-x-3 {
    --transform-skew-x: 3deg;
  }

  .md\:tw-skew-x-6 {
    --transform-skew-x: 6deg;
  }

  .md\:tw-skew-x-12 {
    --transform-skew-x: 12deg;
  }

  .md\:tw--skew-x-12 {
    --transform-skew-x: -12deg;
  }

  .md\:tw--skew-x-6 {
    --transform-skew-x: -6deg;
  }

  .md\:tw--skew-x-3 {
    --transform-skew-x: -3deg;
  }

  .md\:tw--skew-x-2 {
    --transform-skew-x: -2deg;
  }

  .md\:tw--skew-x-1 {
    --transform-skew-x: -1deg;
  }

  .md\:tw-skew-y-0 {
    --transform-skew-y: 0;
  }

  .md\:tw-skew-y-1 {
    --transform-skew-y: 1deg;
  }

  .md\:tw-skew-y-2 {
    --transform-skew-y: 2deg;
  }

  .md\:tw-skew-y-3 {
    --transform-skew-y: 3deg;
  }

  .md\:tw-skew-y-6 {
    --transform-skew-y: 6deg;
  }

  .md\:tw-skew-y-12 {
    --transform-skew-y: 12deg;
  }

  .md\:tw--skew-y-12 {
    --transform-skew-y: -12deg;
  }

  .md\:tw--skew-y-6 {
    --transform-skew-y: -6deg;
  }

  .md\:tw--skew-y-3 {
    --transform-skew-y: -3deg;
  }

  .md\:tw--skew-y-2 {
    --transform-skew-y: -2deg;
  }

  .md\:tw--skew-y-1 {
    --transform-skew-y: -1deg;
  }

  .md\:hover\:tw-skew-x-0:hover {
    --transform-skew-x: 0;
  }

  .md\:hover\:tw-skew-x-1:hover {
    --transform-skew-x: 1deg;
  }

  .md\:hover\:tw-skew-x-2:hover {
    --transform-skew-x: 2deg;
  }

  .md\:hover\:tw-skew-x-3:hover {
    --transform-skew-x: 3deg;
  }

  .md\:hover\:tw-skew-x-6:hover {
    --transform-skew-x: 6deg;
  }

  .md\:hover\:tw-skew-x-12:hover {
    --transform-skew-x: 12deg;
  }

  .md\:hover\:tw--skew-x-12:hover {
    --transform-skew-x: -12deg;
  }

  .md\:hover\:tw--skew-x-6:hover {
    --transform-skew-x: -6deg;
  }

  .md\:hover\:tw--skew-x-3:hover {
    --transform-skew-x: -3deg;
  }

  .md\:hover\:tw--skew-x-2:hover {
    --transform-skew-x: -2deg;
  }

  .md\:hover\:tw--skew-x-1:hover {
    --transform-skew-x: -1deg;
  }

  .md\:hover\:tw-skew-y-0:hover {
    --transform-skew-y: 0;
  }

  .md\:hover\:tw-skew-y-1:hover {
    --transform-skew-y: 1deg;
  }

  .md\:hover\:tw-skew-y-2:hover {
    --transform-skew-y: 2deg;
  }

  .md\:hover\:tw-skew-y-3:hover {
    --transform-skew-y: 3deg;
  }

  .md\:hover\:tw-skew-y-6:hover {
    --transform-skew-y: 6deg;
  }

  .md\:hover\:tw-skew-y-12:hover {
    --transform-skew-y: 12deg;
  }

  .md\:hover\:tw--skew-y-12:hover {
    --transform-skew-y: -12deg;
  }

  .md\:hover\:tw--skew-y-6:hover {
    --transform-skew-y: -6deg;
  }

  .md\:hover\:tw--skew-y-3:hover {
    --transform-skew-y: -3deg;
  }

  .md\:hover\:tw--skew-y-2:hover {
    --transform-skew-y: -2deg;
  }

  .md\:hover\:tw--skew-y-1:hover {
    --transform-skew-y: -1deg;
  }

  .md\:focus\:tw-skew-x-0:focus {
    --transform-skew-x: 0;
  }

  .md\:focus\:tw-skew-x-1:focus {
    --transform-skew-x: 1deg;
  }

  .md\:focus\:tw-skew-x-2:focus {
    --transform-skew-x: 2deg;
  }

  .md\:focus\:tw-skew-x-3:focus {
    --transform-skew-x: 3deg;
  }

  .md\:focus\:tw-skew-x-6:focus {
    --transform-skew-x: 6deg;
  }

  .md\:focus\:tw-skew-x-12:focus {
    --transform-skew-x: 12deg;
  }

  .md\:focus\:tw--skew-x-12:focus {
    --transform-skew-x: -12deg;
  }

  .md\:focus\:tw--skew-x-6:focus {
    --transform-skew-x: -6deg;
  }

  .md\:focus\:tw--skew-x-3:focus {
    --transform-skew-x: -3deg;
  }

  .md\:focus\:tw--skew-x-2:focus {
    --transform-skew-x: -2deg;
  }

  .md\:focus\:tw--skew-x-1:focus {
    --transform-skew-x: -1deg;
  }

  .md\:focus\:tw-skew-y-0:focus {
    --transform-skew-y: 0;
  }

  .md\:focus\:tw-skew-y-1:focus {
    --transform-skew-y: 1deg;
  }

  .md\:focus\:tw-skew-y-2:focus {
    --transform-skew-y: 2deg;
  }

  .md\:focus\:tw-skew-y-3:focus {
    --transform-skew-y: 3deg;
  }

  .md\:focus\:tw-skew-y-6:focus {
    --transform-skew-y: 6deg;
  }

  .md\:focus\:tw-skew-y-12:focus {
    --transform-skew-y: 12deg;
  }

  .md\:focus\:tw--skew-y-12:focus {
    --transform-skew-y: -12deg;
  }

  .md\:focus\:tw--skew-y-6:focus {
    --transform-skew-y: -6deg;
  }

  .md\:focus\:tw--skew-y-3:focus {
    --transform-skew-y: -3deg;
  }

  .md\:focus\:tw--skew-y-2:focus {
    --transform-skew-y: -2deg;
  }

  .md\:focus\:tw--skew-y-1:focus {
    --transform-skew-y: -1deg;
  }

  .md\:tw-transition-none {
    transition-property: none;
  }

  .md\:tw-transition-all {
    transition-property: all;
  }

  .md\:tw-transition {
    transition-property: background-color, border-color, color, fill, stroke, opacity, box-shadow, transform;
  }

  .md\:tw-transition-colors {
    transition-property: background-color, border-color, color, fill, stroke;
  }

  .md\:tw-transition-opacity {
    transition-property: opacity;
  }

  .md\:tw-transition-shadow {
    transition-property: box-shadow;
  }

  .md\:tw-transition-transform {
    transition-property: transform;
  }

  .md\:tw-ease-linear {
    transition-timing-function: linear;
  }

  .md\:tw-ease-in {
    transition-timing-function: cubic-bezier(0.4, 0, 1, 1);
  }

  .md\:tw-ease-out {
    transition-timing-function: cubic-bezier(0, 0, 0.2, 1);
  }

  .md\:tw-ease-in-out {
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  }

  .md\:tw-duration-75 {
    transition-duration: 75ms;
  }

  .md\:tw-duration-100 {
    transition-duration: 100ms;
  }

  .md\:tw-duration-150 {
    transition-duration: 150ms;
  }

  .md\:tw-duration-200 {
    transition-duration: 200ms;
  }

  .md\:tw-duration-300 {
    transition-duration: 300ms;
  }

  .md\:tw-duration-500 {
    transition-duration: 500ms;
  }

  .md\:tw-duration-700 {
    transition-duration: 700ms;
  }

  .md\:tw-duration-1000 {
    transition-duration: 1000ms;
  }

  .md\:tw-delay-75 {
    transition-delay: 75ms;
  }

  .md\:tw-delay-100 {
    transition-delay: 100ms;
  }

  .md\:tw-delay-150 {
    transition-delay: 150ms;
  }

  .md\:tw-delay-200 {
    transition-delay: 200ms;
  }

  .md\:tw-delay-300 {
    transition-delay: 300ms;
  }

  .md\:tw-delay-500 {
    transition-delay: 500ms;
  }

  .md\:tw-delay-700 {
    transition-delay: 700ms;
  }

  .md\:tw-delay-1000 {
    transition-delay: 1000ms;
  }

  .md\:tw-animate-none {
    animation: none;
  }

  .md\:tw-animate-spin {
    animation: spin 1s linear infinite;
  }

  .md\:tw-animate-ping {
    animation: ping 1s cubic-bezier(0, 0, 0.2, 1) infinite;
  }

  .md\:tw-animate-pulse {
    animation: pulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite;
  }

  .md\:tw-animate-bounce {
    animation: bounce 1s infinite;
  }
}

@media (min-width: 1024px) {
  .lg\:tw-container {
    width: 100%;
  }

  @media (min-width: 640px) {
    .lg\:tw-container {
      max-width: 640px;
    }
  }

  @media (min-width: 768px) {
    .lg\:tw-container {
      max-width: 768px;
    }
  }

  @media (min-width: 1024px) {
    .lg\:tw-container {
      max-width: 1024px;
    }
  }

  @media (min-width: 1280px) {
    .lg\:tw-container {
      max-width: 1280px;
    }
  }

  .lg\:tw-space-y-0 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(0px * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(0px * var(--space-y-reverse));
  }

  .lg\:tw-space-x-0 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(0px * var(--space-x-reverse));
    margin-left: calc(0px * calc(1 - var(--space-x-reverse)));
  }

  .lg\:tw-space-y-1 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(0.25rem * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(0.25rem * var(--space-y-reverse));
  }

  .lg\:tw-space-x-1 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(0.25rem * var(--space-x-reverse));
    margin-left: calc(0.25rem * calc(1 - var(--space-x-reverse)));
  }

  .lg\:tw-space-y-2 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(0.5rem * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(0.5rem * var(--space-y-reverse));
  }

  .lg\:tw-space-x-2 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(0.5rem * var(--space-x-reverse));
    margin-left: calc(0.5rem * calc(1 - var(--space-x-reverse)));
  }

  .lg\:tw-space-y-3 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(0.75rem * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(0.75rem * var(--space-y-reverse));
  }

  .lg\:tw-space-x-3 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(0.75rem * var(--space-x-reverse));
    margin-left: calc(0.75rem * calc(1 - var(--space-x-reverse)));
  }

  .lg\:tw-space-y-4 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(1rem * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(1rem * var(--space-y-reverse));
  }

  .lg\:tw-space-x-4 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(1rem * var(--space-x-reverse));
    margin-left: calc(1rem * calc(1 - var(--space-x-reverse)));
  }

  .lg\:tw-space-y-5 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(1.25rem * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(1.25rem * var(--space-y-reverse));
  }

  .lg\:tw-space-x-5 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(1.25rem * var(--space-x-reverse));
    margin-left: calc(1.25rem * calc(1 - var(--space-x-reverse)));
  }

  .lg\:tw-space-y-6 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(1.5rem * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(1.5rem * var(--space-y-reverse));
  }

  .lg\:tw-space-x-6 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(1.5rem * var(--space-x-reverse));
    margin-left: calc(1.5rem * calc(1 - var(--space-x-reverse)));
  }

  .lg\:tw-space-y-8 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(2rem * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(2rem * var(--space-y-reverse));
  }

  .lg\:tw-space-x-8 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(2rem * var(--space-x-reverse));
    margin-left: calc(2rem * calc(1 - var(--space-x-reverse)));
  }

  .lg\:tw-space-y-10 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(2.5rem * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(2.5rem * var(--space-y-reverse));
  }

  .lg\:tw-space-x-10 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(2.5rem * var(--space-x-reverse));
    margin-left: calc(2.5rem * calc(1 - var(--space-x-reverse)));
  }

  .lg\:tw-space-y-12 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(3rem * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(3rem * var(--space-y-reverse));
  }

  .lg\:tw-space-x-12 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(3rem * var(--space-x-reverse));
    margin-left: calc(3rem * calc(1 - var(--space-x-reverse)));
  }

  .lg\:tw-space-y-16 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(4rem * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(4rem * var(--space-y-reverse));
  }

  .lg\:tw-space-x-16 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(4rem * var(--space-x-reverse));
    margin-left: calc(4rem * calc(1 - var(--space-x-reverse)));
  }

  .lg\:tw-space-y-20 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(5rem * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(5rem * var(--space-y-reverse));
  }

  .lg\:tw-space-x-20 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(5rem * var(--space-x-reverse));
    margin-left: calc(5rem * calc(1 - var(--space-x-reverse)));
  }

  .lg\:tw-space-y-24 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(6rem * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(6rem * var(--space-y-reverse));
  }

  .lg\:tw-space-x-24 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(6rem * var(--space-x-reverse));
    margin-left: calc(6rem * calc(1 - var(--space-x-reverse)));
  }

  .lg\:tw-space-y-32 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(8rem * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(8rem * var(--space-y-reverse));
  }

  .lg\:tw-space-x-32 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(8rem * var(--space-x-reverse));
    margin-left: calc(8rem * calc(1 - var(--space-x-reverse)));
  }

  .lg\:tw-space-y-40 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(10rem * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(10rem * var(--space-y-reverse));
  }

  .lg\:tw-space-x-40 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(10rem * var(--space-x-reverse));
    margin-left: calc(10rem * calc(1 - var(--space-x-reverse)));
  }

  .lg\:tw-space-y-48 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(12rem * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(12rem * var(--space-y-reverse));
  }

  .lg\:tw-space-x-48 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(12rem * var(--space-x-reverse));
    margin-left: calc(12rem * calc(1 - var(--space-x-reverse)));
  }

  .lg\:tw-space-y-56 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(14rem * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(14rem * var(--space-y-reverse));
  }

  .lg\:tw-space-x-56 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(14rem * var(--space-x-reverse));
    margin-left: calc(14rem * calc(1 - var(--space-x-reverse)));
  }

  .lg\:tw-space-y-64 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(16rem * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(16rem * var(--space-y-reverse));
  }

  .lg\:tw-space-x-64 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(16rem * var(--space-x-reverse));
    margin-left: calc(16rem * calc(1 - var(--space-x-reverse)));
  }

  .lg\:tw-space-y-px > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(1px * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(1px * var(--space-y-reverse));
  }

  .lg\:tw-space-x-px > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(1px * var(--space-x-reverse));
    margin-left: calc(1px * calc(1 - var(--space-x-reverse)));
  }

  .lg\:tw-space-y-270px > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(270px * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(270px * var(--space-y-reverse));
  }

  .lg\:tw-space-x-270px > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(270px * var(--space-x-reverse));
    margin-left: calc(270px * calc(1 - var(--space-x-reverse)));
  }

  .lg\:tw-space-y-400px > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(400px * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(400px * var(--space-y-reverse));
  }

  .lg\:tw-space-x-400px > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(400px * var(--space-x-reverse));
    margin-left: calc(400px * calc(1 - var(--space-x-reverse)));
  }

  .lg\:tw-space-y-464px > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(464px * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(464px * var(--space-y-reverse));
  }

  .lg\:tw-space-x-464px > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(464px * var(--space-x-reverse));
    margin-left: calc(464px * calc(1 - var(--space-x-reverse)));
  }

  .lg\:tw-space-y-480px > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(480px * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(480px * var(--space-y-reverse));
  }

  .lg\:tw-space-x-480px > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(480px * var(--space-x-reverse));
    margin-left: calc(480px * calc(1 - var(--space-x-reverse)));
  }

  .lg\:tw-space-y-500px > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(500px * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(500px * var(--space-y-reverse));
  }

  .lg\:tw-space-x-500px > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(500px * var(--space-x-reverse));
    margin-left: calc(500px * calc(1 - var(--space-x-reverse)));
  }

  .lg\:tw-space-y-576px > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(576px * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(576px * var(--space-y-reverse));
  }

  .lg\:tw-space-x-576px > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(576px * var(--space-x-reverse));
    margin-left: calc(576px * calc(1 - var(--space-x-reverse)));
  }

  .lg\:tw-space-y-600px > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(600px * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(600px * var(--space-y-reverse));
  }

  .lg\:tw-space-x-600px > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(600px * var(--space-x-reverse));
    margin-left: calc(600px * calc(1 - var(--space-x-reverse)));
  }

  .lg\:tw-space-y-672px > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(672px * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(672px * var(--space-y-reverse));
  }

  .lg\:tw-space-x-672px > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(672px * var(--space-x-reverse));
    margin-left: calc(672px * calc(1 - var(--space-x-reverse)));
  }

  .lg\:tw--space-y-1 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(-0.25rem * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(-0.25rem * var(--space-y-reverse));
  }

  .lg\:tw--space-x-1 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(-0.25rem * var(--space-x-reverse));
    margin-left: calc(-0.25rem * calc(1 - var(--space-x-reverse)));
  }

  .lg\:tw--space-y-2 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(-0.5rem * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(-0.5rem * var(--space-y-reverse));
  }

  .lg\:tw--space-x-2 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(-0.5rem * var(--space-x-reverse));
    margin-left: calc(-0.5rem * calc(1 - var(--space-x-reverse)));
  }

  .lg\:tw--space-y-3 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(-0.75rem * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(-0.75rem * var(--space-y-reverse));
  }

  .lg\:tw--space-x-3 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(-0.75rem * var(--space-x-reverse));
    margin-left: calc(-0.75rem * calc(1 - var(--space-x-reverse)));
  }

  .lg\:tw--space-y-4 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(-1rem * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(-1rem * var(--space-y-reverse));
  }

  .lg\:tw--space-x-4 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(-1rem * var(--space-x-reverse));
    margin-left: calc(-1rem * calc(1 - var(--space-x-reverse)));
  }

  .lg\:tw--space-y-5 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(-1.25rem * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(-1.25rem * var(--space-y-reverse));
  }

  .lg\:tw--space-x-5 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(-1.25rem * var(--space-x-reverse));
    margin-left: calc(-1.25rem * calc(1 - var(--space-x-reverse)));
  }

  .lg\:tw--space-y-6 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(-1.5rem * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(-1.5rem * var(--space-y-reverse));
  }

  .lg\:tw--space-x-6 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(-1.5rem * var(--space-x-reverse));
    margin-left: calc(-1.5rem * calc(1 - var(--space-x-reverse)));
  }

  .lg\:tw--space-y-8 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(-2rem * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(-2rem * var(--space-y-reverse));
  }

  .lg\:tw--space-x-8 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(-2rem * var(--space-x-reverse));
    margin-left: calc(-2rem * calc(1 - var(--space-x-reverse)));
  }

  .lg\:tw--space-y-10 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(-2.5rem * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(-2.5rem * var(--space-y-reverse));
  }

  .lg\:tw--space-x-10 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(-2.5rem * var(--space-x-reverse));
    margin-left: calc(-2.5rem * calc(1 - var(--space-x-reverse)));
  }

  .lg\:tw--space-y-12 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(-3rem * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(-3rem * var(--space-y-reverse));
  }

  .lg\:tw--space-x-12 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(-3rem * var(--space-x-reverse));
    margin-left: calc(-3rem * calc(1 - var(--space-x-reverse)));
  }

  .lg\:tw--space-y-16 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(-4rem * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(-4rem * var(--space-y-reverse));
  }

  .lg\:tw--space-x-16 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(-4rem * var(--space-x-reverse));
    margin-left: calc(-4rem * calc(1 - var(--space-x-reverse)));
  }

  .lg\:tw--space-y-20 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(-5rem * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(-5rem * var(--space-y-reverse));
  }

  .lg\:tw--space-x-20 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(-5rem * var(--space-x-reverse));
    margin-left: calc(-5rem * calc(1 - var(--space-x-reverse)));
  }

  .lg\:tw--space-y-24 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(-6rem * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(-6rem * var(--space-y-reverse));
  }

  .lg\:tw--space-x-24 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(-6rem * var(--space-x-reverse));
    margin-left: calc(-6rem * calc(1 - var(--space-x-reverse)));
  }

  .lg\:tw--space-y-32 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(-8rem * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(-8rem * var(--space-y-reverse));
  }

  .lg\:tw--space-x-32 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(-8rem * var(--space-x-reverse));
    margin-left: calc(-8rem * calc(1 - var(--space-x-reverse)));
  }

  .lg\:tw--space-y-40 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(-10rem * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(-10rem * var(--space-y-reverse));
  }

  .lg\:tw--space-x-40 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(-10rem * var(--space-x-reverse));
    margin-left: calc(-10rem * calc(1 - var(--space-x-reverse)));
  }

  .lg\:tw--space-y-48 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(-12rem * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(-12rem * var(--space-y-reverse));
  }

  .lg\:tw--space-x-48 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(-12rem * var(--space-x-reverse));
    margin-left: calc(-12rem * calc(1 - var(--space-x-reverse)));
  }

  .lg\:tw--space-y-56 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(-14rem * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(-14rem * var(--space-y-reverse));
  }

  .lg\:tw--space-x-56 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(-14rem * var(--space-x-reverse));
    margin-left: calc(-14rem * calc(1 - var(--space-x-reverse)));
  }

  .lg\:tw--space-y-64 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(-16rem * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(-16rem * var(--space-y-reverse));
  }

  .lg\:tw--space-x-64 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(-16rem * var(--space-x-reverse));
    margin-left: calc(-16rem * calc(1 - var(--space-x-reverse)));
  }

  .lg\:tw--space-y-px > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(-1px * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(-1px * var(--space-y-reverse));
  }

  .lg\:tw--space-x-px > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(-1px * var(--space-x-reverse));
    margin-left: calc(-1px * calc(1 - var(--space-x-reverse)));
  }

  .lg\:tw--space-y-270px > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(-270px * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(-270px * var(--space-y-reverse));
  }

  .lg\:tw--space-x-270px > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(-270px * var(--space-x-reverse));
    margin-left: calc(-270px * calc(1 - var(--space-x-reverse)));
  }

  .lg\:tw--space-y-400px > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(-400px * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(-400px * var(--space-y-reverse));
  }

  .lg\:tw--space-x-400px > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(-400px * var(--space-x-reverse));
    margin-left: calc(-400px * calc(1 - var(--space-x-reverse)));
  }

  .lg\:tw--space-y-464px > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(-464px * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(-464px * var(--space-y-reverse));
  }

  .lg\:tw--space-x-464px > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(-464px * var(--space-x-reverse));
    margin-left: calc(-464px * calc(1 - var(--space-x-reverse)));
  }

  .lg\:tw--space-y-480px > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(-480px * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(-480px * var(--space-y-reverse));
  }

  .lg\:tw--space-x-480px > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(-480px * var(--space-x-reverse));
    margin-left: calc(-480px * calc(1 - var(--space-x-reverse)));
  }

  .lg\:tw--space-y-500px > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(-500px * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(-500px * var(--space-y-reverse));
  }

  .lg\:tw--space-x-500px > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(-500px * var(--space-x-reverse));
    margin-left: calc(-500px * calc(1 - var(--space-x-reverse)));
  }

  .lg\:tw--space-y-576px > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(-576px * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(-576px * var(--space-y-reverse));
  }

  .lg\:tw--space-x-576px > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(-576px * var(--space-x-reverse));
    margin-left: calc(-576px * calc(1 - var(--space-x-reverse)));
  }

  .lg\:tw--space-y-600px > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(-600px * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(-600px * var(--space-y-reverse));
  }

  .lg\:tw--space-x-600px > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(-600px * var(--space-x-reverse));
    margin-left: calc(-600px * calc(1 - var(--space-x-reverse)));
  }

  .lg\:tw--space-y-672px > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(-672px * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(-672px * var(--space-y-reverse));
  }

  .lg\:tw--space-x-672px > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(-672px * var(--space-x-reverse));
    margin-left: calc(-672px * calc(1 - var(--space-x-reverse)));
  }

  .lg\:tw-space-y-reverse > :not(template) ~ :not(template) {
    --space-y-reverse: 1;
  }

  .lg\:tw-space-x-reverse > :not(template) ~ :not(template) {
    --space-x-reverse: 1;
  }

  .lg\:tw-divide-y-0 > :not(template) ~ :not(template) {
    --divide-y-reverse: 0;
    border-top-width: calc(0px * calc(1 - var(--divide-y-reverse)));
    border-bottom-width: calc(0px * var(--divide-y-reverse));
  }

  .lg\:tw-divide-x-0 > :not(template) ~ :not(template) {
    --divide-x-reverse: 0;
    border-right-width: calc(0px * var(--divide-x-reverse));
    border-left-width: calc(0px * calc(1 - var(--divide-x-reverse)));
  }

  .lg\:tw-divide-y-2 > :not(template) ~ :not(template) {
    --divide-y-reverse: 0;
    border-top-width: calc(2px * calc(1 - var(--divide-y-reverse)));
    border-bottom-width: calc(2px * var(--divide-y-reverse));
  }

  .lg\:tw-divide-x-2 > :not(template) ~ :not(template) {
    --divide-x-reverse: 0;
    border-right-width: calc(2px * var(--divide-x-reverse));
    border-left-width: calc(2px * calc(1 - var(--divide-x-reverse)));
  }

  .lg\:tw-divide-y-4 > :not(template) ~ :not(template) {
    --divide-y-reverse: 0;
    border-top-width: calc(4px * calc(1 - var(--divide-y-reverse)));
    border-bottom-width: calc(4px * var(--divide-y-reverse));
  }

  .lg\:tw-divide-x-4 > :not(template) ~ :not(template) {
    --divide-x-reverse: 0;
    border-right-width: calc(4px * var(--divide-x-reverse));
    border-left-width: calc(4px * calc(1 - var(--divide-x-reverse)));
  }

  .lg\:tw-divide-y-8 > :not(template) ~ :not(template) {
    --divide-y-reverse: 0;
    border-top-width: calc(8px * calc(1 - var(--divide-y-reverse)));
    border-bottom-width: calc(8px * var(--divide-y-reverse));
  }

  .lg\:tw-divide-x-8 > :not(template) ~ :not(template) {
    --divide-x-reverse: 0;
    border-right-width: calc(8px * var(--divide-x-reverse));
    border-left-width: calc(8px * calc(1 - var(--divide-x-reverse)));
  }

  .lg\:tw-divide-y > :not(template) ~ :not(template) {
    --divide-y-reverse: 0;
    border-top-width: calc(1px * calc(1 - var(--divide-y-reverse)));
    border-bottom-width: calc(1px * var(--divide-y-reverse));
  }

  .lg\:tw-divide-x > :not(template) ~ :not(template) {
    --divide-x-reverse: 0;
    border-right-width: calc(1px * var(--divide-x-reverse));
    border-left-width: calc(1px * calc(1 - var(--divide-x-reverse)));
  }

  .lg\:tw-divide-y-reverse > :not(template) ~ :not(template) {
    --divide-y-reverse: 1;
  }

  .lg\:tw-divide-x-reverse > :not(template) ~ :not(template) {
    --divide-x-reverse: 1;
  }

  .lg\:tw-divide-transparent > :not(template) ~ :not(template) {
    border-color: transparent;
  }

  .lg\:tw-divide-current > :not(template) ~ :not(template) {
    border-color: currentColor;
  }

  .lg\:tw-divide-black > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #000;
    border-color: rgba(0, 0, 0, var(--divide-opacity));
  }

  .lg\:tw-divide-white > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #fff;
    border-color: rgba(255, 255, 255, var(--divide-opacity));
  }

  .lg\:tw-divide-gray-100 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #f7fafc;
    border-color: rgba(247, 250, 252, var(--divide-opacity));
  }

  .lg\:tw-divide-gray-200 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #edf2f7;
    border-color: rgba(237, 242, 247, var(--divide-opacity));
  }

  .lg\:tw-divide-gray-300 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #e2e8f0;
    border-color: rgba(226, 232, 240, var(--divide-opacity));
  }

  .lg\:tw-divide-gray-400 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #cbd5e0;
    border-color: rgba(203, 213, 224, var(--divide-opacity));
  }

  .lg\:tw-divide-gray-500 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #a0aec0;
    border-color: rgba(160, 174, 192, var(--divide-opacity));
  }

  .lg\:tw-divide-gray-600 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #718096;
    border-color: rgba(113, 128, 150, var(--divide-opacity));
  }

  .lg\:tw-divide-gray-700 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #4a5568;
    border-color: rgba(74, 85, 104, var(--divide-opacity));
  }

  .lg\:tw-divide-gray-800 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #2d3748;
    border-color: rgba(45, 55, 72, var(--divide-opacity));
  }

  .lg\:tw-divide-gray-900 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #1a202c;
    border-color: rgba(26, 32, 44, var(--divide-opacity));
  }

  .lg\:tw-divide-red-100 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #fff5f5;
    border-color: rgba(255, 245, 245, var(--divide-opacity));
  }

  .lg\:tw-divide-red-200 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #fed7d7;
    border-color: rgba(254, 215, 215, var(--divide-opacity));
  }

  .lg\:tw-divide-red-300 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #feb2b2;
    border-color: rgba(254, 178, 178, var(--divide-opacity));
  }

  .lg\:tw-divide-red-400 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #fc8181;
    border-color: rgba(252, 129, 129, var(--divide-opacity));
  }

  .lg\:tw-divide-red-500 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #f56565;
    border-color: rgba(245, 101, 101, var(--divide-opacity));
  }

  .lg\:tw-divide-red-600 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #e53e3e;
    border-color: rgba(229, 62, 62, var(--divide-opacity));
  }

  .lg\:tw-divide-red-700 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #c53030;
    border-color: rgba(197, 48, 48, var(--divide-opacity));
  }

  .lg\:tw-divide-red-800 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #9b2c2c;
    border-color: rgba(155, 44, 44, var(--divide-opacity));
  }

  .lg\:tw-divide-red-900 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #742a2a;
    border-color: rgba(116, 42, 42, var(--divide-opacity));
  }

  .lg\:tw-divide-orange-100 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #fffaf0;
    border-color: rgba(255, 250, 240, var(--divide-opacity));
  }

  .lg\:tw-divide-orange-200 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #feebc8;
    border-color: rgba(254, 235, 200, var(--divide-opacity));
  }

  .lg\:tw-divide-orange-300 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #fbd38d;
    border-color: rgba(251, 211, 141, var(--divide-opacity));
  }

  .lg\:tw-divide-orange-400 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #f6ad55;
    border-color: rgba(246, 173, 85, var(--divide-opacity));
  }

  .lg\:tw-divide-orange-500 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #ed8936;
    border-color: rgba(237, 137, 54, var(--divide-opacity));
  }

  .lg\:tw-divide-orange-600 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #dd6b20;
    border-color: rgba(221, 107, 32, var(--divide-opacity));
  }

  .lg\:tw-divide-orange-700 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #c05621;
    border-color: rgba(192, 86, 33, var(--divide-opacity));
  }

  .lg\:tw-divide-orange-800 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #9c4221;
    border-color: rgba(156, 66, 33, var(--divide-opacity));
  }

  .lg\:tw-divide-orange-900 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #7b341e;
    border-color: rgba(123, 52, 30, var(--divide-opacity));
  }

  .lg\:tw-divide-yellow-100 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #fffff0;
    border-color: rgba(255, 255, 240, var(--divide-opacity));
  }

  .lg\:tw-divide-yellow-200 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #fefcbf;
    border-color: rgba(254, 252, 191, var(--divide-opacity));
  }

  .lg\:tw-divide-yellow-300 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #faf089;
    border-color: rgba(250, 240, 137, var(--divide-opacity));
  }

  .lg\:tw-divide-yellow-400 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #f6e05e;
    border-color: rgba(246, 224, 94, var(--divide-opacity));
  }

  .lg\:tw-divide-yellow-500 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #ecc94b;
    border-color: rgba(236, 201, 75, var(--divide-opacity));
  }

  .lg\:tw-divide-yellow-600 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #d69e2e;
    border-color: rgba(214, 158, 46, var(--divide-opacity));
  }

  .lg\:tw-divide-yellow-700 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #b7791f;
    border-color: rgba(183, 121, 31, var(--divide-opacity));
  }

  .lg\:tw-divide-yellow-800 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #975a16;
    border-color: rgba(151, 90, 22, var(--divide-opacity));
  }

  .lg\:tw-divide-yellow-900 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #744210;
    border-color: rgba(116, 66, 16, var(--divide-opacity));
  }

  .lg\:tw-divide-green-100 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #f0fff4;
    border-color: rgba(240, 255, 244, var(--divide-opacity));
  }

  .lg\:tw-divide-green-200 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #c6f6d5;
    border-color: rgba(198, 246, 213, var(--divide-opacity));
  }

  .lg\:tw-divide-green-300 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #9ae6b4;
    border-color: rgba(154, 230, 180, var(--divide-opacity));
  }

  .lg\:tw-divide-green-400 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #68d391;
    border-color: rgba(104, 211, 145, var(--divide-opacity));
  }

  .lg\:tw-divide-green-500 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #48bb78;
    border-color: rgba(72, 187, 120, var(--divide-opacity));
  }

  .lg\:tw-divide-green-600 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #38a169;
    border-color: rgba(56, 161, 105, var(--divide-opacity));
  }

  .lg\:tw-divide-green-700 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #2f855a;
    border-color: rgba(47, 133, 90, var(--divide-opacity));
  }

  .lg\:tw-divide-green-800 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #276749;
    border-color: rgba(39, 103, 73, var(--divide-opacity));
  }

  .lg\:tw-divide-green-900 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #22543d;
    border-color: rgba(34, 84, 61, var(--divide-opacity));
  }

  .lg\:tw-divide-teal-100 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #e6fffa;
    border-color: rgba(230, 255, 250, var(--divide-opacity));
  }

  .lg\:tw-divide-teal-200 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #b2f5ea;
    border-color: rgba(178, 245, 234, var(--divide-opacity));
  }

  .lg\:tw-divide-teal-300 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #81e6d9;
    border-color: rgba(129, 230, 217, var(--divide-opacity));
  }

  .lg\:tw-divide-teal-400 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #4fd1c5;
    border-color: rgba(79, 209, 197, var(--divide-opacity));
  }

  .lg\:tw-divide-teal-500 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #38b2ac;
    border-color: rgba(56, 178, 172, var(--divide-opacity));
  }

  .lg\:tw-divide-teal-600 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #319795;
    border-color: rgba(49, 151, 149, var(--divide-opacity));
  }

  .lg\:tw-divide-teal-700 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #2c7a7b;
    border-color: rgba(44, 122, 123, var(--divide-opacity));
  }

  .lg\:tw-divide-teal-800 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #285e61;
    border-color: rgba(40, 94, 97, var(--divide-opacity));
  }

  .lg\:tw-divide-teal-900 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #234e52;
    border-color: rgba(35, 78, 82, var(--divide-opacity));
  }

  .lg\:tw-divide-blue-100 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #ebf8ff;
    border-color: rgba(235, 248, 255, var(--divide-opacity));
  }

  .lg\:tw-divide-blue-200 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #bee3f8;
    border-color: rgba(190, 227, 248, var(--divide-opacity));
  }

  .lg\:tw-divide-blue-300 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #90cdf4;
    border-color: rgba(144, 205, 244, var(--divide-opacity));
  }

  .lg\:tw-divide-blue-400 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #63b3ed;
    border-color: rgba(99, 179, 237, var(--divide-opacity));
  }

  .lg\:tw-divide-blue-500 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #4299e1;
    border-color: rgba(66, 153, 225, var(--divide-opacity));
  }

  .lg\:tw-divide-blue-600 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #3182ce;
    border-color: rgba(49, 130, 206, var(--divide-opacity));
  }

  .lg\:tw-divide-blue-700 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #2b6cb0;
    border-color: rgba(43, 108, 176, var(--divide-opacity));
  }

  .lg\:tw-divide-blue-800 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #2c5282;
    border-color: rgba(44, 82, 130, var(--divide-opacity));
  }

  .lg\:tw-divide-blue-900 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #2a4365;
    border-color: rgba(42, 67, 101, var(--divide-opacity));
  }

  .lg\:tw-divide-indigo-100 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #ebf4ff;
    border-color: rgba(235, 244, 255, var(--divide-opacity));
  }

  .lg\:tw-divide-indigo-200 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #c3dafe;
    border-color: rgba(195, 218, 254, var(--divide-opacity));
  }

  .lg\:tw-divide-indigo-300 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #a3bffa;
    border-color: rgba(163, 191, 250, var(--divide-opacity));
  }

  .lg\:tw-divide-indigo-400 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #7f9cf5;
    border-color: rgba(127, 156, 245, var(--divide-opacity));
  }

  .lg\:tw-divide-indigo-500 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #667eea;
    border-color: rgba(102, 126, 234, var(--divide-opacity));
  }

  .lg\:tw-divide-indigo-600 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #5a67d8;
    border-color: rgba(90, 103, 216, var(--divide-opacity));
  }

  .lg\:tw-divide-indigo-700 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #4c51bf;
    border-color: rgba(76, 81, 191, var(--divide-opacity));
  }

  .lg\:tw-divide-indigo-800 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #434190;
    border-color: rgba(67, 65, 144, var(--divide-opacity));
  }

  .lg\:tw-divide-indigo-900 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #3c366b;
    border-color: rgba(60, 54, 107, var(--divide-opacity));
  }

  .lg\:tw-divide-purple-100 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #faf5ff;
    border-color: rgba(250, 245, 255, var(--divide-opacity));
  }

  .lg\:tw-divide-purple-200 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #e9d8fd;
    border-color: rgba(233, 216, 253, var(--divide-opacity));
  }

  .lg\:tw-divide-purple-300 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #d6bcfa;
    border-color: rgba(214, 188, 250, var(--divide-opacity));
  }

  .lg\:tw-divide-purple-400 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #b794f4;
    border-color: rgba(183, 148, 244, var(--divide-opacity));
  }

  .lg\:tw-divide-purple-500 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #9f7aea;
    border-color: rgba(159, 122, 234, var(--divide-opacity));
  }

  .lg\:tw-divide-purple-600 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #805ad5;
    border-color: rgba(128, 90, 213, var(--divide-opacity));
  }

  .lg\:tw-divide-purple-700 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #6b46c1;
    border-color: rgba(107, 70, 193, var(--divide-opacity));
  }

  .lg\:tw-divide-purple-800 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #553c9a;
    border-color: rgba(85, 60, 154, var(--divide-opacity));
  }

  .lg\:tw-divide-purple-900 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #44337a;
    border-color: rgba(68, 51, 122, var(--divide-opacity));
  }

  .lg\:tw-divide-pink-100 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #fff5f7;
    border-color: rgba(255, 245, 247, var(--divide-opacity));
  }

  .lg\:tw-divide-pink-200 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #fed7e2;
    border-color: rgba(254, 215, 226, var(--divide-opacity));
  }

  .lg\:tw-divide-pink-300 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #fbb6ce;
    border-color: rgba(251, 182, 206, var(--divide-opacity));
  }

  .lg\:tw-divide-pink-400 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #f687b3;
    border-color: rgba(246, 135, 179, var(--divide-opacity));
  }

  .lg\:tw-divide-pink-500 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #ed64a6;
    border-color: rgba(237, 100, 166, var(--divide-opacity));
  }

  .lg\:tw-divide-pink-600 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #d53f8c;
    border-color: rgba(213, 63, 140, var(--divide-opacity));
  }

  .lg\:tw-divide-pink-700 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #b83280;
    border-color: rgba(184, 50, 128, var(--divide-opacity));
  }

  .lg\:tw-divide-pink-800 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #97266d;
    border-color: rgba(151, 38, 109, var(--divide-opacity));
  }

  .lg\:tw-divide-pink-900 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #702459;
    border-color: rgba(112, 36, 89, var(--divide-opacity));
  }

  .lg\:tw-divide-solid > :not(template) ~ :not(template) {
    border-style: solid;
  }

  .lg\:tw-divide-dashed > :not(template) ~ :not(template) {
    border-style: dashed;
  }

  .lg\:tw-divide-dotted > :not(template) ~ :not(template) {
    border-style: dotted;
  }

  .lg\:tw-divide-double > :not(template) ~ :not(template) {
    border-style: double;
  }

  .lg\:tw-divide-none > :not(template) ~ :not(template) {
    border-style: none;
  }

  .lg\:tw-divide-opacity-0 > :not(template) ~ :not(template) {
    --divide-opacity: 0;
  }

  .lg\:tw-divide-opacity-25 > :not(template) ~ :not(template) {
    --divide-opacity: 0.25;
  }

  .lg\:tw-divide-opacity-50 > :not(template) ~ :not(template) {
    --divide-opacity: 0.5;
  }

  .lg\:tw-divide-opacity-75 > :not(template) ~ :not(template) {
    --divide-opacity: 0.75;
  }

  .lg\:tw-divide-opacity-100 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
  }

  .lg\:tw-sr-only {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    border-width: 0;
  }

  .lg\:tw-not-sr-only {
    position: static;
    width: auto;
    height: auto;
    padding: 0;
    margin: 0;
    overflow: visible;
    clip: auto;
    white-space: normal;
  }

  .lg\:focus\:tw-sr-only:focus {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    border-width: 0;
  }

  .lg\:focus\:tw-not-sr-only:focus {
    position: static;
    width: auto;
    height: auto;
    padding: 0;
    margin: 0;
    overflow: visible;
    clip: auto;
    white-space: normal;
  }

  .lg\:tw-appearance-none {
    -webkit-appearance: none;
       -moz-appearance: none;
            appearance: none;
  }

  .lg\:tw-bg-fixed {
    background-attachment: fixed;
  }

  .lg\:tw-bg-local {
    background-attachment: local;
  }

  .lg\:tw-bg-scroll {
    background-attachment: scroll;
  }

  .lg\:tw-bg-clip-border {
    background-clip: border-box;
  }

  .lg\:tw-bg-clip-padding {
    background-clip: padding-box;
  }

  .lg\:tw-bg-clip-content {
    background-clip: content-box;
  }

  .lg\:tw-bg-clip-text {
    -webkit-background-clip: text;
            background-clip: text;
  }

  .lg\:tw-bg-transparent {
    background-color: transparent;
  }

  .lg\:tw-bg-current {
    background-color: currentColor;
  }

  .lg\:tw-bg-black {
    --bg-opacity: 1;
    background-color: #000;
    background-color: rgba(0, 0, 0, var(--bg-opacity));
  }

  .lg\:tw-bg-white {
    --bg-opacity: 1;
    background-color: #fff;
    background-color: rgba(255, 255, 255, var(--bg-opacity));
  }

  .lg\:tw-bg-gray-100 {
    --bg-opacity: 1;
    background-color: #f7fafc;
    background-color: rgba(247, 250, 252, var(--bg-opacity));
  }

  .lg\:tw-bg-gray-200 {
    --bg-opacity: 1;
    background-color: #edf2f7;
    background-color: rgba(237, 242, 247, var(--bg-opacity));
  }

  .lg\:tw-bg-gray-300 {
    --bg-opacity: 1;
    background-color: #e2e8f0;
    background-color: rgba(226, 232, 240, var(--bg-opacity));
  }

  .lg\:tw-bg-gray-400 {
    --bg-opacity: 1;
    background-color: #cbd5e0;
    background-color: rgba(203, 213, 224, var(--bg-opacity));
  }

  .lg\:tw-bg-gray-500 {
    --bg-opacity: 1;
    background-color: #a0aec0;
    background-color: rgba(160, 174, 192, var(--bg-opacity));
  }

  .lg\:tw-bg-gray-600 {
    --bg-opacity: 1;
    background-color: #718096;
    background-color: rgba(113, 128, 150, var(--bg-opacity));
  }

  .lg\:tw-bg-gray-700 {
    --bg-opacity: 1;
    background-color: #4a5568;
    background-color: rgba(74, 85, 104, var(--bg-opacity));
  }

  .lg\:tw-bg-gray-800 {
    --bg-opacity: 1;
    background-color: #2d3748;
    background-color: rgba(45, 55, 72, var(--bg-opacity));
  }

  .lg\:tw-bg-gray-900 {
    --bg-opacity: 1;
    background-color: #1a202c;
    background-color: rgba(26, 32, 44, var(--bg-opacity));
  }

  .lg\:tw-bg-red-100 {
    --bg-opacity: 1;
    background-color: #fff5f5;
    background-color: rgba(255, 245, 245, var(--bg-opacity));
  }

  .lg\:tw-bg-red-200 {
    --bg-opacity: 1;
    background-color: #fed7d7;
    background-color: rgba(254, 215, 215, var(--bg-opacity));
  }

  .lg\:tw-bg-red-300 {
    --bg-opacity: 1;
    background-color: #feb2b2;
    background-color: rgba(254, 178, 178, var(--bg-opacity));
  }

  .lg\:tw-bg-red-400 {
    --bg-opacity: 1;
    background-color: #fc8181;
    background-color: rgba(252, 129, 129, var(--bg-opacity));
  }

  .lg\:tw-bg-red-500 {
    --bg-opacity: 1;
    background-color: #f56565;
    background-color: rgba(245, 101, 101, var(--bg-opacity));
  }

  .lg\:tw-bg-red-600 {
    --bg-opacity: 1;
    background-color: #e53e3e;
    background-color: rgba(229, 62, 62, var(--bg-opacity));
  }

  .lg\:tw-bg-red-700 {
    --bg-opacity: 1;
    background-color: #c53030;
    background-color: rgba(197, 48, 48, var(--bg-opacity));
  }

  .lg\:tw-bg-red-800 {
    --bg-opacity: 1;
    background-color: #9b2c2c;
    background-color: rgba(155, 44, 44, var(--bg-opacity));
  }

  .lg\:tw-bg-red-900 {
    --bg-opacity: 1;
    background-color: #742a2a;
    background-color: rgba(116, 42, 42, var(--bg-opacity));
  }

  .lg\:tw-bg-orange-100 {
    --bg-opacity: 1;
    background-color: #fffaf0;
    background-color: rgba(255, 250, 240, var(--bg-opacity));
  }

  .lg\:tw-bg-orange-200 {
    --bg-opacity: 1;
    background-color: #feebc8;
    background-color: rgba(254, 235, 200, var(--bg-opacity));
  }

  .lg\:tw-bg-orange-300 {
    --bg-opacity: 1;
    background-color: #fbd38d;
    background-color: rgba(251, 211, 141, var(--bg-opacity));
  }

  .lg\:tw-bg-orange-400 {
    --bg-opacity: 1;
    background-color: #f6ad55;
    background-color: rgba(246, 173, 85, var(--bg-opacity));
  }

  .lg\:tw-bg-orange-500 {
    --bg-opacity: 1;
    background-color: #ed8936;
    background-color: rgba(237, 137, 54, var(--bg-opacity));
  }

  .lg\:tw-bg-orange-600 {
    --bg-opacity: 1;
    background-color: #dd6b20;
    background-color: rgba(221, 107, 32, var(--bg-opacity));
  }

  .lg\:tw-bg-orange-700 {
    --bg-opacity: 1;
    background-color: #c05621;
    background-color: rgba(192, 86, 33, var(--bg-opacity));
  }

  .lg\:tw-bg-orange-800 {
    --bg-opacity: 1;
    background-color: #9c4221;
    background-color: rgba(156, 66, 33, var(--bg-opacity));
  }

  .lg\:tw-bg-orange-900 {
    --bg-opacity: 1;
    background-color: #7b341e;
    background-color: rgba(123, 52, 30, var(--bg-opacity));
  }

  .lg\:tw-bg-yellow-100 {
    --bg-opacity: 1;
    background-color: #fffff0;
    background-color: rgba(255, 255, 240, var(--bg-opacity));
  }

  .lg\:tw-bg-yellow-200 {
    --bg-opacity: 1;
    background-color: #fefcbf;
    background-color: rgba(254, 252, 191, var(--bg-opacity));
  }

  .lg\:tw-bg-yellow-300 {
    --bg-opacity: 1;
    background-color: #faf089;
    background-color: rgba(250, 240, 137, var(--bg-opacity));
  }

  .lg\:tw-bg-yellow-400 {
    --bg-opacity: 1;
    background-color: #f6e05e;
    background-color: rgba(246, 224, 94, var(--bg-opacity));
  }

  .lg\:tw-bg-yellow-500 {
    --bg-opacity: 1;
    background-color: #ecc94b;
    background-color: rgba(236, 201, 75, var(--bg-opacity));
  }

  .lg\:tw-bg-yellow-600 {
    --bg-opacity: 1;
    background-color: #d69e2e;
    background-color: rgba(214, 158, 46, var(--bg-opacity));
  }

  .lg\:tw-bg-yellow-700 {
    --bg-opacity: 1;
    background-color: #b7791f;
    background-color: rgba(183, 121, 31, var(--bg-opacity));
  }

  .lg\:tw-bg-yellow-800 {
    --bg-opacity: 1;
    background-color: #975a16;
    background-color: rgba(151, 90, 22, var(--bg-opacity));
  }

  .lg\:tw-bg-yellow-900 {
    --bg-opacity: 1;
    background-color: #744210;
    background-color: rgba(116, 66, 16, var(--bg-opacity));
  }

  .lg\:tw-bg-green-100 {
    --bg-opacity: 1;
    background-color: #f0fff4;
    background-color: rgba(240, 255, 244, var(--bg-opacity));
  }

  .lg\:tw-bg-green-200 {
    --bg-opacity: 1;
    background-color: #c6f6d5;
    background-color: rgba(198, 246, 213, var(--bg-opacity));
  }

  .lg\:tw-bg-green-300 {
    --bg-opacity: 1;
    background-color: #9ae6b4;
    background-color: rgba(154, 230, 180, var(--bg-opacity));
  }

  .lg\:tw-bg-green-400 {
    --bg-opacity: 1;
    background-color: #68d391;
    background-color: rgba(104, 211, 145, var(--bg-opacity));
  }

  .lg\:tw-bg-green-500 {
    --bg-opacity: 1;
    background-color: #48bb78;
    background-color: rgba(72, 187, 120, var(--bg-opacity));
  }

  .lg\:tw-bg-green-600 {
    --bg-opacity: 1;
    background-color: #38a169;
    background-color: rgba(56, 161, 105, var(--bg-opacity));
  }

  .lg\:tw-bg-green-700 {
    --bg-opacity: 1;
    background-color: #2f855a;
    background-color: rgba(47, 133, 90, var(--bg-opacity));
  }

  .lg\:tw-bg-green-800 {
    --bg-opacity: 1;
    background-color: #276749;
    background-color: rgba(39, 103, 73, var(--bg-opacity));
  }

  .lg\:tw-bg-green-900 {
    --bg-opacity: 1;
    background-color: #22543d;
    background-color: rgba(34, 84, 61, var(--bg-opacity));
  }

  .lg\:tw-bg-teal-100 {
    --bg-opacity: 1;
    background-color: #e6fffa;
    background-color: rgba(230, 255, 250, var(--bg-opacity));
  }

  .lg\:tw-bg-teal-200 {
    --bg-opacity: 1;
    background-color: #b2f5ea;
    background-color: rgba(178, 245, 234, var(--bg-opacity));
  }

  .lg\:tw-bg-teal-300 {
    --bg-opacity: 1;
    background-color: #81e6d9;
    background-color: rgba(129, 230, 217, var(--bg-opacity));
  }

  .lg\:tw-bg-teal-400 {
    --bg-opacity: 1;
    background-color: #4fd1c5;
    background-color: rgba(79, 209, 197, var(--bg-opacity));
  }

  .lg\:tw-bg-teal-500 {
    --bg-opacity: 1;
    background-color: #38b2ac;
    background-color: rgba(56, 178, 172, var(--bg-opacity));
  }

  .lg\:tw-bg-teal-600 {
    --bg-opacity: 1;
    background-color: #319795;
    background-color: rgba(49, 151, 149, var(--bg-opacity));
  }

  .lg\:tw-bg-teal-700 {
    --bg-opacity: 1;
    background-color: #2c7a7b;
    background-color: rgba(44, 122, 123, var(--bg-opacity));
  }

  .lg\:tw-bg-teal-800 {
    --bg-opacity: 1;
    background-color: #285e61;
    background-color: rgba(40, 94, 97, var(--bg-opacity));
  }

  .lg\:tw-bg-teal-900 {
    --bg-opacity: 1;
    background-color: #234e52;
    background-color: rgba(35, 78, 82, var(--bg-opacity));
  }

  .lg\:tw-bg-blue-100 {
    --bg-opacity: 1;
    background-color: #ebf8ff;
    background-color: rgba(235, 248, 255, var(--bg-opacity));
  }

  .lg\:tw-bg-blue-200 {
    --bg-opacity: 1;
    background-color: #bee3f8;
    background-color: rgba(190, 227, 248, var(--bg-opacity));
  }

  .lg\:tw-bg-blue-300 {
    --bg-opacity: 1;
    background-color: #90cdf4;
    background-color: rgba(144, 205, 244, var(--bg-opacity));
  }

  .lg\:tw-bg-blue-400 {
    --bg-opacity: 1;
    background-color: #63b3ed;
    background-color: rgba(99, 179, 237, var(--bg-opacity));
  }

  .lg\:tw-bg-blue-500 {
    --bg-opacity: 1;
    background-color: #4299e1;
    background-color: rgba(66, 153, 225, var(--bg-opacity));
  }

  .lg\:tw-bg-blue-600 {
    --bg-opacity: 1;
    background-color: #3182ce;
    background-color: rgba(49, 130, 206, var(--bg-opacity));
  }

  .lg\:tw-bg-blue-700 {
    --bg-opacity: 1;
    background-color: #2b6cb0;
    background-color: rgba(43, 108, 176, var(--bg-opacity));
  }

  .lg\:tw-bg-blue-800 {
    --bg-opacity: 1;
    background-color: #2c5282;
    background-color: rgba(44, 82, 130, var(--bg-opacity));
  }

  .lg\:tw-bg-blue-900 {
    --bg-opacity: 1;
    background-color: #2a4365;
    background-color: rgba(42, 67, 101, var(--bg-opacity));
  }

  .lg\:tw-bg-indigo-100 {
    --bg-opacity: 1;
    background-color: #ebf4ff;
    background-color: rgba(235, 244, 255, var(--bg-opacity));
  }

  .lg\:tw-bg-indigo-200 {
    --bg-opacity: 1;
    background-color: #c3dafe;
    background-color: rgba(195, 218, 254, var(--bg-opacity));
  }

  .lg\:tw-bg-indigo-300 {
    --bg-opacity: 1;
    background-color: #a3bffa;
    background-color: rgba(163, 191, 250, var(--bg-opacity));
  }

  .lg\:tw-bg-indigo-400 {
    --bg-opacity: 1;
    background-color: #7f9cf5;
    background-color: rgba(127, 156, 245, var(--bg-opacity));
  }

  .lg\:tw-bg-indigo-500 {
    --bg-opacity: 1;
    background-color: #667eea;
    background-color: rgba(102, 126, 234, var(--bg-opacity));
  }

  .lg\:tw-bg-indigo-600 {
    --bg-opacity: 1;
    background-color: #5a67d8;
    background-color: rgba(90, 103, 216, var(--bg-opacity));
  }

  .lg\:tw-bg-indigo-700 {
    --bg-opacity: 1;
    background-color: #4c51bf;
    background-color: rgba(76, 81, 191, var(--bg-opacity));
  }

  .lg\:tw-bg-indigo-800 {
    --bg-opacity: 1;
    background-color: #434190;
    background-color: rgba(67, 65, 144, var(--bg-opacity));
  }

  .lg\:tw-bg-indigo-900 {
    --bg-opacity: 1;
    background-color: #3c366b;
    background-color: rgba(60, 54, 107, var(--bg-opacity));
  }

  .lg\:tw-bg-purple-100 {
    --bg-opacity: 1;
    background-color: #faf5ff;
    background-color: rgba(250, 245, 255, var(--bg-opacity));
  }

  .lg\:tw-bg-purple-200 {
    --bg-opacity: 1;
    background-color: #e9d8fd;
    background-color: rgba(233, 216, 253, var(--bg-opacity));
  }

  .lg\:tw-bg-purple-300 {
    --bg-opacity: 1;
    background-color: #d6bcfa;
    background-color: rgba(214, 188, 250, var(--bg-opacity));
  }

  .lg\:tw-bg-purple-400 {
    --bg-opacity: 1;
    background-color: #b794f4;
    background-color: rgba(183, 148, 244, var(--bg-opacity));
  }

  .lg\:tw-bg-purple-500 {
    --bg-opacity: 1;
    background-color: #9f7aea;
    background-color: rgba(159, 122, 234, var(--bg-opacity));
  }

  .lg\:tw-bg-purple-600 {
    --bg-opacity: 1;
    background-color: #805ad5;
    background-color: rgba(128, 90, 213, var(--bg-opacity));
  }

  .lg\:tw-bg-purple-700 {
    --bg-opacity: 1;
    background-color: #6b46c1;
    background-color: rgba(107, 70, 193, var(--bg-opacity));
  }

  .lg\:tw-bg-purple-800 {
    --bg-opacity: 1;
    background-color: #553c9a;
    background-color: rgba(85, 60, 154, var(--bg-opacity));
  }

  .lg\:tw-bg-purple-900 {
    --bg-opacity: 1;
    background-color: #44337a;
    background-color: rgba(68, 51, 122, var(--bg-opacity));
  }

  .lg\:tw-bg-pink-100 {
    --bg-opacity: 1;
    background-color: #fff5f7;
    background-color: rgba(255, 245, 247, var(--bg-opacity));
  }

  .lg\:tw-bg-pink-200 {
    --bg-opacity: 1;
    background-color: #fed7e2;
    background-color: rgba(254, 215, 226, var(--bg-opacity));
  }

  .lg\:tw-bg-pink-300 {
    --bg-opacity: 1;
    background-color: #fbb6ce;
    background-color: rgba(251, 182, 206, var(--bg-opacity));
  }

  .lg\:tw-bg-pink-400 {
    --bg-opacity: 1;
    background-color: #f687b3;
    background-color: rgba(246, 135, 179, var(--bg-opacity));
  }

  .lg\:tw-bg-pink-500 {
    --bg-opacity: 1;
    background-color: #ed64a6;
    background-color: rgba(237, 100, 166, var(--bg-opacity));
  }

  .lg\:tw-bg-pink-600 {
    --bg-opacity: 1;
    background-color: #d53f8c;
    background-color: rgba(213, 63, 140, var(--bg-opacity));
  }

  .lg\:tw-bg-pink-700 {
    --bg-opacity: 1;
    background-color: #b83280;
    background-color: rgba(184, 50, 128, var(--bg-opacity));
  }

  .lg\:tw-bg-pink-800 {
    --bg-opacity: 1;
    background-color: #97266d;
    background-color: rgba(151, 38, 109, var(--bg-opacity));
  }

  .lg\:tw-bg-pink-900 {
    --bg-opacity: 1;
    background-color: #702459;
    background-color: rgba(112, 36, 89, var(--bg-opacity));
  }

  .lg\:hover\:tw-bg-transparent:hover {
    background-color: transparent;
  }

  .lg\:hover\:tw-bg-current:hover {
    background-color: currentColor;
  }

  .lg\:hover\:tw-bg-black:hover {
    --bg-opacity: 1;
    background-color: #000;
    background-color: rgba(0, 0, 0, var(--bg-opacity));
  }

  .lg\:hover\:tw-bg-white:hover {
    --bg-opacity: 1;
    background-color: #fff;
    background-color: rgba(255, 255, 255, var(--bg-opacity));
  }

  .lg\:hover\:tw-bg-gray-100:hover {
    --bg-opacity: 1;
    background-color: #f7fafc;
    background-color: rgba(247, 250, 252, var(--bg-opacity));
  }

  .lg\:hover\:tw-bg-gray-200:hover {
    --bg-opacity: 1;
    background-color: #edf2f7;
    background-color: rgba(237, 242, 247, var(--bg-opacity));
  }

  .lg\:hover\:tw-bg-gray-300:hover {
    --bg-opacity: 1;
    background-color: #e2e8f0;
    background-color: rgba(226, 232, 240, var(--bg-opacity));
  }

  .lg\:hover\:tw-bg-gray-400:hover {
    --bg-opacity: 1;
    background-color: #cbd5e0;
    background-color: rgba(203, 213, 224, var(--bg-opacity));
  }

  .lg\:hover\:tw-bg-gray-500:hover {
    --bg-opacity: 1;
    background-color: #a0aec0;
    background-color: rgba(160, 174, 192, var(--bg-opacity));
  }

  .lg\:hover\:tw-bg-gray-600:hover {
    --bg-opacity: 1;
    background-color: #718096;
    background-color: rgba(113, 128, 150, var(--bg-opacity));
  }

  .lg\:hover\:tw-bg-gray-700:hover {
    --bg-opacity: 1;
    background-color: #4a5568;
    background-color: rgba(74, 85, 104, var(--bg-opacity));
  }

  .lg\:hover\:tw-bg-gray-800:hover {
    --bg-opacity: 1;
    background-color: #2d3748;
    background-color: rgba(45, 55, 72, var(--bg-opacity));
  }

  .lg\:hover\:tw-bg-gray-900:hover {
    --bg-opacity: 1;
    background-color: #1a202c;
    background-color: rgba(26, 32, 44, var(--bg-opacity));
  }

  .lg\:hover\:tw-bg-red-100:hover {
    --bg-opacity: 1;
    background-color: #fff5f5;
    background-color: rgba(255, 245, 245, var(--bg-opacity));
  }

  .lg\:hover\:tw-bg-red-200:hover {
    --bg-opacity: 1;
    background-color: #fed7d7;
    background-color: rgba(254, 215, 215, var(--bg-opacity));
  }

  .lg\:hover\:tw-bg-red-300:hover {
    --bg-opacity: 1;
    background-color: #feb2b2;
    background-color: rgba(254, 178, 178, var(--bg-opacity));
  }

  .lg\:hover\:tw-bg-red-400:hover {
    --bg-opacity: 1;
    background-color: #fc8181;
    background-color: rgba(252, 129, 129, var(--bg-opacity));
  }

  .lg\:hover\:tw-bg-red-500:hover {
    --bg-opacity: 1;
    background-color: #f56565;
    background-color: rgba(245, 101, 101, var(--bg-opacity));
  }

  .lg\:hover\:tw-bg-red-600:hover {
    --bg-opacity: 1;
    background-color: #e53e3e;
    background-color: rgba(229, 62, 62, var(--bg-opacity));
  }

  .lg\:hover\:tw-bg-red-700:hover {
    --bg-opacity: 1;
    background-color: #c53030;
    background-color: rgba(197, 48, 48, var(--bg-opacity));
  }

  .lg\:hover\:tw-bg-red-800:hover {
    --bg-opacity: 1;
    background-color: #9b2c2c;
    background-color: rgba(155, 44, 44, var(--bg-opacity));
  }

  .lg\:hover\:tw-bg-red-900:hover {
    --bg-opacity: 1;
    background-color: #742a2a;
    background-color: rgba(116, 42, 42, var(--bg-opacity));
  }

  .lg\:hover\:tw-bg-orange-100:hover {
    --bg-opacity: 1;
    background-color: #fffaf0;
    background-color: rgba(255, 250, 240, var(--bg-opacity));
  }

  .lg\:hover\:tw-bg-orange-200:hover {
    --bg-opacity: 1;
    background-color: #feebc8;
    background-color: rgba(254, 235, 200, var(--bg-opacity));
  }

  .lg\:hover\:tw-bg-orange-300:hover {
    --bg-opacity: 1;
    background-color: #fbd38d;
    background-color: rgba(251, 211, 141, var(--bg-opacity));
  }

  .lg\:hover\:tw-bg-orange-400:hover {
    --bg-opacity: 1;
    background-color: #f6ad55;
    background-color: rgba(246, 173, 85, var(--bg-opacity));
  }

  .lg\:hover\:tw-bg-orange-500:hover {
    --bg-opacity: 1;
    background-color: #ed8936;
    background-color: rgba(237, 137, 54, var(--bg-opacity));
  }

  .lg\:hover\:tw-bg-orange-600:hover {
    --bg-opacity: 1;
    background-color: #dd6b20;
    background-color: rgba(221, 107, 32, var(--bg-opacity));
  }

  .lg\:hover\:tw-bg-orange-700:hover {
    --bg-opacity: 1;
    background-color: #c05621;
    background-color: rgba(192, 86, 33, var(--bg-opacity));
  }

  .lg\:hover\:tw-bg-orange-800:hover {
    --bg-opacity: 1;
    background-color: #9c4221;
    background-color: rgba(156, 66, 33, var(--bg-opacity));
  }

  .lg\:hover\:tw-bg-orange-900:hover {
    --bg-opacity: 1;
    background-color: #7b341e;
    background-color: rgba(123, 52, 30, var(--bg-opacity));
  }

  .lg\:hover\:tw-bg-yellow-100:hover {
    --bg-opacity: 1;
    background-color: #fffff0;
    background-color: rgba(255, 255, 240, var(--bg-opacity));
  }

  .lg\:hover\:tw-bg-yellow-200:hover {
    --bg-opacity: 1;
    background-color: #fefcbf;
    background-color: rgba(254, 252, 191, var(--bg-opacity));
  }

  .lg\:hover\:tw-bg-yellow-300:hover {
    --bg-opacity: 1;
    background-color: #faf089;
    background-color: rgba(250, 240, 137, var(--bg-opacity));
  }

  .lg\:hover\:tw-bg-yellow-400:hover {
    --bg-opacity: 1;
    background-color: #f6e05e;
    background-color: rgba(246, 224, 94, var(--bg-opacity));
  }

  .lg\:hover\:tw-bg-yellow-500:hover {
    --bg-opacity: 1;
    background-color: #ecc94b;
    background-color: rgba(236, 201, 75, var(--bg-opacity));
  }

  .lg\:hover\:tw-bg-yellow-600:hover {
    --bg-opacity: 1;
    background-color: #d69e2e;
    background-color: rgba(214, 158, 46, var(--bg-opacity));
  }

  .lg\:hover\:tw-bg-yellow-700:hover {
    --bg-opacity: 1;
    background-color: #b7791f;
    background-color: rgba(183, 121, 31, var(--bg-opacity));
  }

  .lg\:hover\:tw-bg-yellow-800:hover {
    --bg-opacity: 1;
    background-color: #975a16;
    background-color: rgba(151, 90, 22, var(--bg-opacity));
  }

  .lg\:hover\:tw-bg-yellow-900:hover {
    --bg-opacity: 1;
    background-color: #744210;
    background-color: rgba(116, 66, 16, var(--bg-opacity));
  }

  .lg\:hover\:tw-bg-green-100:hover {
    --bg-opacity: 1;
    background-color: #f0fff4;
    background-color: rgba(240, 255, 244, var(--bg-opacity));
  }

  .lg\:hover\:tw-bg-green-200:hover {
    --bg-opacity: 1;
    background-color: #c6f6d5;
    background-color: rgba(198, 246, 213, var(--bg-opacity));
  }

  .lg\:hover\:tw-bg-green-300:hover {
    --bg-opacity: 1;
    background-color: #9ae6b4;
    background-color: rgba(154, 230, 180, var(--bg-opacity));
  }

  .lg\:hover\:tw-bg-green-400:hover {
    --bg-opacity: 1;
    background-color: #68d391;
    background-color: rgba(104, 211, 145, var(--bg-opacity));
  }

  .lg\:hover\:tw-bg-green-500:hover {
    --bg-opacity: 1;
    background-color: #48bb78;
    background-color: rgba(72, 187, 120, var(--bg-opacity));
  }

  .lg\:hover\:tw-bg-green-600:hover {
    --bg-opacity: 1;
    background-color: #38a169;
    background-color: rgba(56, 161, 105, var(--bg-opacity));
  }

  .lg\:hover\:tw-bg-green-700:hover {
    --bg-opacity: 1;
    background-color: #2f855a;
    background-color: rgba(47, 133, 90, var(--bg-opacity));
  }

  .lg\:hover\:tw-bg-green-800:hover {
    --bg-opacity: 1;
    background-color: #276749;
    background-color: rgba(39, 103, 73, var(--bg-opacity));
  }

  .lg\:hover\:tw-bg-green-900:hover {
    --bg-opacity: 1;
    background-color: #22543d;
    background-color: rgba(34, 84, 61, var(--bg-opacity));
  }

  .lg\:hover\:tw-bg-teal-100:hover {
    --bg-opacity: 1;
    background-color: #e6fffa;
    background-color: rgba(230, 255, 250, var(--bg-opacity));
  }

  .lg\:hover\:tw-bg-teal-200:hover {
    --bg-opacity: 1;
    background-color: #b2f5ea;
    background-color: rgba(178, 245, 234, var(--bg-opacity));
  }

  .lg\:hover\:tw-bg-teal-300:hover {
    --bg-opacity: 1;
    background-color: #81e6d9;
    background-color: rgba(129, 230, 217, var(--bg-opacity));
  }

  .lg\:hover\:tw-bg-teal-400:hover {
    --bg-opacity: 1;
    background-color: #4fd1c5;
    background-color: rgba(79, 209, 197, var(--bg-opacity));
  }

  .lg\:hover\:tw-bg-teal-500:hover {
    --bg-opacity: 1;
    background-color: #38b2ac;
    background-color: rgba(56, 178, 172, var(--bg-opacity));
  }

  .lg\:hover\:tw-bg-teal-600:hover {
    --bg-opacity: 1;
    background-color: #319795;
    background-color: rgba(49, 151, 149, var(--bg-opacity));
  }

  .lg\:hover\:tw-bg-teal-700:hover {
    --bg-opacity: 1;
    background-color: #2c7a7b;
    background-color: rgba(44, 122, 123, var(--bg-opacity));
  }

  .lg\:hover\:tw-bg-teal-800:hover {
    --bg-opacity: 1;
    background-color: #285e61;
    background-color: rgba(40, 94, 97, var(--bg-opacity));
  }

  .lg\:hover\:tw-bg-teal-900:hover {
    --bg-opacity: 1;
    background-color: #234e52;
    background-color: rgba(35, 78, 82, var(--bg-opacity));
  }

  .lg\:hover\:tw-bg-blue-100:hover {
    --bg-opacity: 1;
    background-color: #ebf8ff;
    background-color: rgba(235, 248, 255, var(--bg-opacity));
  }

  .lg\:hover\:tw-bg-blue-200:hover {
    --bg-opacity: 1;
    background-color: #bee3f8;
    background-color: rgba(190, 227, 248, var(--bg-opacity));
  }

  .lg\:hover\:tw-bg-blue-300:hover {
    --bg-opacity: 1;
    background-color: #90cdf4;
    background-color: rgba(144, 205, 244, var(--bg-opacity));
  }

  .lg\:hover\:tw-bg-blue-400:hover {
    --bg-opacity: 1;
    background-color: #63b3ed;
    background-color: rgba(99, 179, 237, var(--bg-opacity));
  }

  .lg\:hover\:tw-bg-blue-500:hover {
    --bg-opacity: 1;
    background-color: #4299e1;
    background-color: rgba(66, 153, 225, var(--bg-opacity));
  }

  .lg\:hover\:tw-bg-blue-600:hover {
    --bg-opacity: 1;
    background-color: #3182ce;
    background-color: rgba(49, 130, 206, var(--bg-opacity));
  }

  .lg\:hover\:tw-bg-blue-700:hover {
    --bg-opacity: 1;
    background-color: #2b6cb0;
    background-color: rgba(43, 108, 176, var(--bg-opacity));
  }

  .lg\:hover\:tw-bg-blue-800:hover {
    --bg-opacity: 1;
    background-color: #2c5282;
    background-color: rgba(44, 82, 130, var(--bg-opacity));
  }

  .lg\:hover\:tw-bg-blue-900:hover {
    --bg-opacity: 1;
    background-color: #2a4365;
    background-color: rgba(42, 67, 101, var(--bg-opacity));
  }

  .lg\:hover\:tw-bg-indigo-100:hover {
    --bg-opacity: 1;
    background-color: #ebf4ff;
    background-color: rgba(235, 244, 255, var(--bg-opacity));
  }

  .lg\:hover\:tw-bg-indigo-200:hover {
    --bg-opacity: 1;
    background-color: #c3dafe;
    background-color: rgba(195, 218, 254, var(--bg-opacity));
  }

  .lg\:hover\:tw-bg-indigo-300:hover {
    --bg-opacity: 1;
    background-color: #a3bffa;
    background-color: rgba(163, 191, 250, var(--bg-opacity));
  }

  .lg\:hover\:tw-bg-indigo-400:hover {
    --bg-opacity: 1;
    background-color: #7f9cf5;
    background-color: rgba(127, 156, 245, var(--bg-opacity));
  }

  .lg\:hover\:tw-bg-indigo-500:hover {
    --bg-opacity: 1;
    background-color: #667eea;
    background-color: rgba(102, 126, 234, var(--bg-opacity));
  }

  .lg\:hover\:tw-bg-indigo-600:hover {
    --bg-opacity: 1;
    background-color: #5a67d8;
    background-color: rgba(90, 103, 216, var(--bg-opacity));
  }

  .lg\:hover\:tw-bg-indigo-700:hover {
    --bg-opacity: 1;
    background-color: #4c51bf;
    background-color: rgba(76, 81, 191, var(--bg-opacity));
  }

  .lg\:hover\:tw-bg-indigo-800:hover {
    --bg-opacity: 1;
    background-color: #434190;
    background-color: rgba(67, 65, 144, var(--bg-opacity));
  }

  .lg\:hover\:tw-bg-indigo-900:hover {
    --bg-opacity: 1;
    background-color: #3c366b;
    background-color: rgba(60, 54, 107, var(--bg-opacity));
  }

  .lg\:hover\:tw-bg-purple-100:hover {
    --bg-opacity: 1;
    background-color: #faf5ff;
    background-color: rgba(250, 245, 255, var(--bg-opacity));
  }

  .lg\:hover\:tw-bg-purple-200:hover {
    --bg-opacity: 1;
    background-color: #e9d8fd;
    background-color: rgba(233, 216, 253, var(--bg-opacity));
  }

  .lg\:hover\:tw-bg-purple-300:hover {
    --bg-opacity: 1;
    background-color: #d6bcfa;
    background-color: rgba(214, 188, 250, var(--bg-opacity));
  }

  .lg\:hover\:tw-bg-purple-400:hover {
    --bg-opacity: 1;
    background-color: #b794f4;
    background-color: rgba(183, 148, 244, var(--bg-opacity));
  }

  .lg\:hover\:tw-bg-purple-500:hover {
    --bg-opacity: 1;
    background-color: #9f7aea;
    background-color: rgba(159, 122, 234, var(--bg-opacity));
  }

  .lg\:hover\:tw-bg-purple-600:hover {
    --bg-opacity: 1;
    background-color: #805ad5;
    background-color: rgba(128, 90, 213, var(--bg-opacity));
  }

  .lg\:hover\:tw-bg-purple-700:hover {
    --bg-opacity: 1;
    background-color: #6b46c1;
    background-color: rgba(107, 70, 193, var(--bg-opacity));
  }

  .lg\:hover\:tw-bg-purple-800:hover {
    --bg-opacity: 1;
    background-color: #553c9a;
    background-color: rgba(85, 60, 154, var(--bg-opacity));
  }

  .lg\:hover\:tw-bg-purple-900:hover {
    --bg-opacity: 1;
    background-color: #44337a;
    background-color: rgba(68, 51, 122, var(--bg-opacity));
  }

  .lg\:hover\:tw-bg-pink-100:hover {
    --bg-opacity: 1;
    background-color: #fff5f7;
    background-color: rgba(255, 245, 247, var(--bg-opacity));
  }

  .lg\:hover\:tw-bg-pink-200:hover {
    --bg-opacity: 1;
    background-color: #fed7e2;
    background-color: rgba(254, 215, 226, var(--bg-opacity));
  }

  .lg\:hover\:tw-bg-pink-300:hover {
    --bg-opacity: 1;
    background-color: #fbb6ce;
    background-color: rgba(251, 182, 206, var(--bg-opacity));
  }

  .lg\:hover\:tw-bg-pink-400:hover {
    --bg-opacity: 1;
    background-color: #f687b3;
    background-color: rgba(246, 135, 179, var(--bg-opacity));
  }

  .lg\:hover\:tw-bg-pink-500:hover {
    --bg-opacity: 1;
    background-color: #ed64a6;
    background-color: rgba(237, 100, 166, var(--bg-opacity));
  }

  .lg\:hover\:tw-bg-pink-600:hover {
    --bg-opacity: 1;
    background-color: #d53f8c;
    background-color: rgba(213, 63, 140, var(--bg-opacity));
  }

  .lg\:hover\:tw-bg-pink-700:hover {
    --bg-opacity: 1;
    background-color: #b83280;
    background-color: rgba(184, 50, 128, var(--bg-opacity));
  }

  .lg\:hover\:tw-bg-pink-800:hover {
    --bg-opacity: 1;
    background-color: #97266d;
    background-color: rgba(151, 38, 109, var(--bg-opacity));
  }

  .lg\:hover\:tw-bg-pink-900:hover {
    --bg-opacity: 1;
    background-color: #702459;
    background-color: rgba(112, 36, 89, var(--bg-opacity));
  }

  .lg\:focus\:tw-bg-transparent:focus {
    background-color: transparent;
  }

  .lg\:focus\:tw-bg-current:focus {
    background-color: currentColor;
  }

  .lg\:focus\:tw-bg-black:focus {
    --bg-opacity: 1;
    background-color: #000;
    background-color: rgba(0, 0, 0, var(--bg-opacity));
  }

  .lg\:focus\:tw-bg-white:focus {
    --bg-opacity: 1;
    background-color: #fff;
    background-color: rgba(255, 255, 255, var(--bg-opacity));
  }

  .lg\:focus\:tw-bg-gray-100:focus {
    --bg-opacity: 1;
    background-color: #f7fafc;
    background-color: rgba(247, 250, 252, var(--bg-opacity));
  }

  .lg\:focus\:tw-bg-gray-200:focus {
    --bg-opacity: 1;
    background-color: #edf2f7;
    background-color: rgba(237, 242, 247, var(--bg-opacity));
  }

  .lg\:focus\:tw-bg-gray-300:focus {
    --bg-opacity: 1;
    background-color: #e2e8f0;
    background-color: rgba(226, 232, 240, var(--bg-opacity));
  }

  .lg\:focus\:tw-bg-gray-400:focus {
    --bg-opacity: 1;
    background-color: #cbd5e0;
    background-color: rgba(203, 213, 224, var(--bg-opacity));
  }

  .lg\:focus\:tw-bg-gray-500:focus {
    --bg-opacity: 1;
    background-color: #a0aec0;
    background-color: rgba(160, 174, 192, var(--bg-opacity));
  }

  .lg\:focus\:tw-bg-gray-600:focus {
    --bg-opacity: 1;
    background-color: #718096;
    background-color: rgba(113, 128, 150, var(--bg-opacity));
  }

  .lg\:focus\:tw-bg-gray-700:focus {
    --bg-opacity: 1;
    background-color: #4a5568;
    background-color: rgba(74, 85, 104, var(--bg-opacity));
  }

  .lg\:focus\:tw-bg-gray-800:focus {
    --bg-opacity: 1;
    background-color: #2d3748;
    background-color: rgba(45, 55, 72, var(--bg-opacity));
  }

  .lg\:focus\:tw-bg-gray-900:focus {
    --bg-opacity: 1;
    background-color: #1a202c;
    background-color: rgba(26, 32, 44, var(--bg-opacity));
  }

  .lg\:focus\:tw-bg-red-100:focus {
    --bg-opacity: 1;
    background-color: #fff5f5;
    background-color: rgba(255, 245, 245, var(--bg-opacity));
  }

  .lg\:focus\:tw-bg-red-200:focus {
    --bg-opacity: 1;
    background-color: #fed7d7;
    background-color: rgba(254, 215, 215, var(--bg-opacity));
  }

  .lg\:focus\:tw-bg-red-300:focus {
    --bg-opacity: 1;
    background-color: #feb2b2;
    background-color: rgba(254, 178, 178, var(--bg-opacity));
  }

  .lg\:focus\:tw-bg-red-400:focus {
    --bg-opacity: 1;
    background-color: #fc8181;
    background-color: rgba(252, 129, 129, var(--bg-opacity));
  }

  .lg\:focus\:tw-bg-red-500:focus {
    --bg-opacity: 1;
    background-color: #f56565;
    background-color: rgba(245, 101, 101, var(--bg-opacity));
  }

  .lg\:focus\:tw-bg-red-600:focus {
    --bg-opacity: 1;
    background-color: #e53e3e;
    background-color: rgba(229, 62, 62, var(--bg-opacity));
  }

  .lg\:focus\:tw-bg-red-700:focus {
    --bg-opacity: 1;
    background-color: #c53030;
    background-color: rgba(197, 48, 48, var(--bg-opacity));
  }

  .lg\:focus\:tw-bg-red-800:focus {
    --bg-opacity: 1;
    background-color: #9b2c2c;
    background-color: rgba(155, 44, 44, var(--bg-opacity));
  }

  .lg\:focus\:tw-bg-red-900:focus {
    --bg-opacity: 1;
    background-color: #742a2a;
    background-color: rgba(116, 42, 42, var(--bg-opacity));
  }

  .lg\:focus\:tw-bg-orange-100:focus {
    --bg-opacity: 1;
    background-color: #fffaf0;
    background-color: rgba(255, 250, 240, var(--bg-opacity));
  }

  .lg\:focus\:tw-bg-orange-200:focus {
    --bg-opacity: 1;
    background-color: #feebc8;
    background-color: rgba(254, 235, 200, var(--bg-opacity));
  }

  .lg\:focus\:tw-bg-orange-300:focus {
    --bg-opacity: 1;
    background-color: #fbd38d;
    background-color: rgba(251, 211, 141, var(--bg-opacity));
  }

  .lg\:focus\:tw-bg-orange-400:focus {
    --bg-opacity: 1;
    background-color: #f6ad55;
    background-color: rgba(246, 173, 85, var(--bg-opacity));
  }

  .lg\:focus\:tw-bg-orange-500:focus {
    --bg-opacity: 1;
    background-color: #ed8936;
    background-color: rgba(237, 137, 54, var(--bg-opacity));
  }

  .lg\:focus\:tw-bg-orange-600:focus {
    --bg-opacity: 1;
    background-color: #dd6b20;
    background-color: rgba(221, 107, 32, var(--bg-opacity));
  }

  .lg\:focus\:tw-bg-orange-700:focus {
    --bg-opacity: 1;
    background-color: #c05621;
    background-color: rgba(192, 86, 33, var(--bg-opacity));
  }

  .lg\:focus\:tw-bg-orange-800:focus {
    --bg-opacity: 1;
    background-color: #9c4221;
    background-color: rgba(156, 66, 33, var(--bg-opacity));
  }

  .lg\:focus\:tw-bg-orange-900:focus {
    --bg-opacity: 1;
    background-color: #7b341e;
    background-color: rgba(123, 52, 30, var(--bg-opacity));
  }

  .lg\:focus\:tw-bg-yellow-100:focus {
    --bg-opacity: 1;
    background-color: #fffff0;
    background-color: rgba(255, 255, 240, var(--bg-opacity));
  }

  .lg\:focus\:tw-bg-yellow-200:focus {
    --bg-opacity: 1;
    background-color: #fefcbf;
    background-color: rgba(254, 252, 191, var(--bg-opacity));
  }

  .lg\:focus\:tw-bg-yellow-300:focus {
    --bg-opacity: 1;
    background-color: #faf089;
    background-color: rgba(250, 240, 137, var(--bg-opacity));
  }

  .lg\:focus\:tw-bg-yellow-400:focus {
    --bg-opacity: 1;
    background-color: #f6e05e;
    background-color: rgba(246, 224, 94, var(--bg-opacity));
  }

  .lg\:focus\:tw-bg-yellow-500:focus {
    --bg-opacity: 1;
    background-color: #ecc94b;
    background-color: rgba(236, 201, 75, var(--bg-opacity));
  }

  .lg\:focus\:tw-bg-yellow-600:focus {
    --bg-opacity: 1;
    background-color: #d69e2e;
    background-color: rgba(214, 158, 46, var(--bg-opacity));
  }

  .lg\:focus\:tw-bg-yellow-700:focus {
    --bg-opacity: 1;
    background-color: #b7791f;
    background-color: rgba(183, 121, 31, var(--bg-opacity));
  }

  .lg\:focus\:tw-bg-yellow-800:focus {
    --bg-opacity: 1;
    background-color: #975a16;
    background-color: rgba(151, 90, 22, var(--bg-opacity));
  }

  .lg\:focus\:tw-bg-yellow-900:focus {
    --bg-opacity: 1;
    background-color: #744210;
    background-color: rgba(116, 66, 16, var(--bg-opacity));
  }

  .lg\:focus\:tw-bg-green-100:focus {
    --bg-opacity: 1;
    background-color: #f0fff4;
    background-color: rgba(240, 255, 244, var(--bg-opacity));
  }

  .lg\:focus\:tw-bg-green-200:focus {
    --bg-opacity: 1;
    background-color: #c6f6d5;
    background-color: rgba(198, 246, 213, var(--bg-opacity));
  }

  .lg\:focus\:tw-bg-green-300:focus {
    --bg-opacity: 1;
    background-color: #9ae6b4;
    background-color: rgba(154, 230, 180, var(--bg-opacity));
  }

  .lg\:focus\:tw-bg-green-400:focus {
    --bg-opacity: 1;
    background-color: #68d391;
    background-color: rgba(104, 211, 145, var(--bg-opacity));
  }

  .lg\:focus\:tw-bg-green-500:focus {
    --bg-opacity: 1;
    background-color: #48bb78;
    background-color: rgba(72, 187, 120, var(--bg-opacity));
  }

  .lg\:focus\:tw-bg-green-600:focus {
    --bg-opacity: 1;
    background-color: #38a169;
    background-color: rgba(56, 161, 105, var(--bg-opacity));
  }

  .lg\:focus\:tw-bg-green-700:focus {
    --bg-opacity: 1;
    background-color: #2f855a;
    background-color: rgba(47, 133, 90, var(--bg-opacity));
  }

  .lg\:focus\:tw-bg-green-800:focus {
    --bg-opacity: 1;
    background-color: #276749;
    background-color: rgba(39, 103, 73, var(--bg-opacity));
  }

  .lg\:focus\:tw-bg-green-900:focus {
    --bg-opacity: 1;
    background-color: #22543d;
    background-color: rgba(34, 84, 61, var(--bg-opacity));
  }

  .lg\:focus\:tw-bg-teal-100:focus {
    --bg-opacity: 1;
    background-color: #e6fffa;
    background-color: rgba(230, 255, 250, var(--bg-opacity));
  }

  .lg\:focus\:tw-bg-teal-200:focus {
    --bg-opacity: 1;
    background-color: #b2f5ea;
    background-color: rgba(178, 245, 234, var(--bg-opacity));
  }

  .lg\:focus\:tw-bg-teal-300:focus {
    --bg-opacity: 1;
    background-color: #81e6d9;
    background-color: rgba(129, 230, 217, var(--bg-opacity));
  }

  .lg\:focus\:tw-bg-teal-400:focus {
    --bg-opacity: 1;
    background-color: #4fd1c5;
    background-color: rgba(79, 209, 197, var(--bg-opacity));
  }

  .lg\:focus\:tw-bg-teal-500:focus {
    --bg-opacity: 1;
    background-color: #38b2ac;
    background-color: rgba(56, 178, 172, var(--bg-opacity));
  }

  .lg\:focus\:tw-bg-teal-600:focus {
    --bg-opacity: 1;
    background-color: #319795;
    background-color: rgba(49, 151, 149, var(--bg-opacity));
  }

  .lg\:focus\:tw-bg-teal-700:focus {
    --bg-opacity: 1;
    background-color: #2c7a7b;
    background-color: rgba(44, 122, 123, var(--bg-opacity));
  }

  .lg\:focus\:tw-bg-teal-800:focus {
    --bg-opacity: 1;
    background-color: #285e61;
    background-color: rgba(40, 94, 97, var(--bg-opacity));
  }

  .lg\:focus\:tw-bg-teal-900:focus {
    --bg-opacity: 1;
    background-color: #234e52;
    background-color: rgba(35, 78, 82, var(--bg-opacity));
  }

  .lg\:focus\:tw-bg-blue-100:focus {
    --bg-opacity: 1;
    background-color: #ebf8ff;
    background-color: rgba(235, 248, 255, var(--bg-opacity));
  }

  .lg\:focus\:tw-bg-blue-200:focus {
    --bg-opacity: 1;
    background-color: #bee3f8;
    background-color: rgba(190, 227, 248, var(--bg-opacity));
  }

  .lg\:focus\:tw-bg-blue-300:focus {
    --bg-opacity: 1;
    background-color: #90cdf4;
    background-color: rgba(144, 205, 244, var(--bg-opacity));
  }

  .lg\:focus\:tw-bg-blue-400:focus {
    --bg-opacity: 1;
    background-color: #63b3ed;
    background-color: rgba(99, 179, 237, var(--bg-opacity));
  }

  .lg\:focus\:tw-bg-blue-500:focus {
    --bg-opacity: 1;
    background-color: #4299e1;
    background-color: rgba(66, 153, 225, var(--bg-opacity));
  }

  .lg\:focus\:tw-bg-blue-600:focus {
    --bg-opacity: 1;
    background-color: #3182ce;
    background-color: rgba(49, 130, 206, var(--bg-opacity));
  }

  .lg\:focus\:tw-bg-blue-700:focus {
    --bg-opacity: 1;
    background-color: #2b6cb0;
    background-color: rgba(43, 108, 176, var(--bg-opacity));
  }

  .lg\:focus\:tw-bg-blue-800:focus {
    --bg-opacity: 1;
    background-color: #2c5282;
    background-color: rgba(44, 82, 130, var(--bg-opacity));
  }

  .lg\:focus\:tw-bg-blue-900:focus {
    --bg-opacity: 1;
    background-color: #2a4365;
    background-color: rgba(42, 67, 101, var(--bg-opacity));
  }

  .lg\:focus\:tw-bg-indigo-100:focus {
    --bg-opacity: 1;
    background-color: #ebf4ff;
    background-color: rgba(235, 244, 255, var(--bg-opacity));
  }

  .lg\:focus\:tw-bg-indigo-200:focus {
    --bg-opacity: 1;
    background-color: #c3dafe;
    background-color: rgba(195, 218, 254, var(--bg-opacity));
  }

  .lg\:focus\:tw-bg-indigo-300:focus {
    --bg-opacity: 1;
    background-color: #a3bffa;
    background-color: rgba(163, 191, 250, var(--bg-opacity));
  }

  .lg\:focus\:tw-bg-indigo-400:focus {
    --bg-opacity: 1;
    background-color: #7f9cf5;
    background-color: rgba(127, 156, 245, var(--bg-opacity));
  }

  .lg\:focus\:tw-bg-indigo-500:focus {
    --bg-opacity: 1;
    background-color: #667eea;
    background-color: rgba(102, 126, 234, var(--bg-opacity));
  }

  .lg\:focus\:tw-bg-indigo-600:focus {
    --bg-opacity: 1;
    background-color: #5a67d8;
    background-color: rgba(90, 103, 216, var(--bg-opacity));
  }

  .lg\:focus\:tw-bg-indigo-700:focus {
    --bg-opacity: 1;
    background-color: #4c51bf;
    background-color: rgba(76, 81, 191, var(--bg-opacity));
  }

  .lg\:focus\:tw-bg-indigo-800:focus {
    --bg-opacity: 1;
    background-color: #434190;
    background-color: rgba(67, 65, 144, var(--bg-opacity));
  }

  .lg\:focus\:tw-bg-indigo-900:focus {
    --bg-opacity: 1;
    background-color: #3c366b;
    background-color: rgba(60, 54, 107, var(--bg-opacity));
  }

  .lg\:focus\:tw-bg-purple-100:focus {
    --bg-opacity: 1;
    background-color: #faf5ff;
    background-color: rgba(250, 245, 255, var(--bg-opacity));
  }

  .lg\:focus\:tw-bg-purple-200:focus {
    --bg-opacity: 1;
    background-color: #e9d8fd;
    background-color: rgba(233, 216, 253, var(--bg-opacity));
  }

  .lg\:focus\:tw-bg-purple-300:focus {
    --bg-opacity: 1;
    background-color: #d6bcfa;
    background-color: rgba(214, 188, 250, var(--bg-opacity));
  }

  .lg\:focus\:tw-bg-purple-400:focus {
    --bg-opacity: 1;
    background-color: #b794f4;
    background-color: rgba(183, 148, 244, var(--bg-opacity));
  }

  .lg\:focus\:tw-bg-purple-500:focus {
    --bg-opacity: 1;
    background-color: #9f7aea;
    background-color: rgba(159, 122, 234, var(--bg-opacity));
  }

  .lg\:focus\:tw-bg-purple-600:focus {
    --bg-opacity: 1;
    background-color: #805ad5;
    background-color: rgba(128, 90, 213, var(--bg-opacity));
  }

  .lg\:focus\:tw-bg-purple-700:focus {
    --bg-opacity: 1;
    background-color: #6b46c1;
    background-color: rgba(107, 70, 193, var(--bg-opacity));
  }

  .lg\:focus\:tw-bg-purple-800:focus {
    --bg-opacity: 1;
    background-color: #553c9a;
    background-color: rgba(85, 60, 154, var(--bg-opacity));
  }

  .lg\:focus\:tw-bg-purple-900:focus {
    --bg-opacity: 1;
    background-color: #44337a;
    background-color: rgba(68, 51, 122, var(--bg-opacity));
  }

  .lg\:focus\:tw-bg-pink-100:focus {
    --bg-opacity: 1;
    background-color: #fff5f7;
    background-color: rgba(255, 245, 247, var(--bg-opacity));
  }

  .lg\:focus\:tw-bg-pink-200:focus {
    --bg-opacity: 1;
    background-color: #fed7e2;
    background-color: rgba(254, 215, 226, var(--bg-opacity));
  }

  .lg\:focus\:tw-bg-pink-300:focus {
    --bg-opacity: 1;
    background-color: #fbb6ce;
    background-color: rgba(251, 182, 206, var(--bg-opacity));
  }

  .lg\:focus\:tw-bg-pink-400:focus {
    --bg-opacity: 1;
    background-color: #f687b3;
    background-color: rgba(246, 135, 179, var(--bg-opacity));
  }

  .lg\:focus\:tw-bg-pink-500:focus {
    --bg-opacity: 1;
    background-color: #ed64a6;
    background-color: rgba(237, 100, 166, var(--bg-opacity));
  }

  .lg\:focus\:tw-bg-pink-600:focus {
    --bg-opacity: 1;
    background-color: #d53f8c;
    background-color: rgba(213, 63, 140, var(--bg-opacity));
  }

  .lg\:focus\:tw-bg-pink-700:focus {
    --bg-opacity: 1;
    background-color: #b83280;
    background-color: rgba(184, 50, 128, var(--bg-opacity));
  }

  .lg\:focus\:tw-bg-pink-800:focus {
    --bg-opacity: 1;
    background-color: #97266d;
    background-color: rgba(151, 38, 109, var(--bg-opacity));
  }

  .lg\:focus\:tw-bg-pink-900:focus {
    --bg-opacity: 1;
    background-color: #702459;
    background-color: rgba(112, 36, 89, var(--bg-opacity));
  }

  .lg\:tw-bg-none {
    background-image: none;
  }

  .lg\:tw-bg-gradient-to-t {
    background-image: linear-gradient(to top, var(--gradient-color-stops));
  }

  .lg\:tw-bg-gradient-to-tr {
    background-image: linear-gradient(to top right, var(--gradient-color-stops));
  }

  .lg\:tw-bg-gradient-to-r {
    background-image: linear-gradient(to right, var(--gradient-color-stops));
  }

  .lg\:tw-bg-gradient-to-br {
    background-image: linear-gradient(to bottom right, var(--gradient-color-stops));
  }

  .lg\:tw-bg-gradient-to-b {
    background-image: linear-gradient(to bottom, var(--gradient-color-stops));
  }

  .lg\:tw-bg-gradient-to-bl {
    background-image: linear-gradient(to bottom left, var(--gradient-color-stops));
  }

  .lg\:tw-bg-gradient-to-l {
    background-image: linear-gradient(to left, var(--gradient-color-stops));
  }

  .lg\:tw-bg-gradient-to-tl {
    background-image: linear-gradient(to top left, var(--gradient-color-stops));
  }

  .lg\:tw-bg-wave-pattern {
    background-image: url('../assets/images/nawbar_waves.svg');
  }

  .lg\:tw-from-transparent {
    --gradient-from-color: transparent;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(0, 0, 0, 0));
  }

  .lg\:tw-from-current {
    --gradient-from-color: currentColor;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0));
  }

  .lg\:tw-from-black {
    --gradient-from-color: #000;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(0, 0, 0, 0));
  }

  .lg\:tw-from-white {
    --gradient-from-color: #fff;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0));
  }

  .lg\:tw-from-gray-100 {
    --gradient-from-color: #f7fafc;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(247, 250, 252, 0));
  }

  .lg\:tw-from-gray-200 {
    --gradient-from-color: #edf2f7;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(237, 242, 247, 0));
  }

  .lg\:tw-from-gray-300 {
    --gradient-from-color: #e2e8f0;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(226, 232, 240, 0));
  }

  .lg\:tw-from-gray-400 {
    --gradient-from-color: #cbd5e0;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(203, 213, 224, 0));
  }

  .lg\:tw-from-gray-500 {
    --gradient-from-color: #a0aec0;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(160, 174, 192, 0));
  }

  .lg\:tw-from-gray-600 {
    --gradient-from-color: #718096;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(113, 128, 150, 0));
  }

  .lg\:tw-from-gray-700 {
    --gradient-from-color: #4a5568;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(74, 85, 104, 0));
  }

  .lg\:tw-from-gray-800 {
    --gradient-from-color: #2d3748;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(45, 55, 72, 0));
  }

  .lg\:tw-from-gray-900 {
    --gradient-from-color: #1a202c;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(26, 32, 44, 0));
  }

  .lg\:tw-from-red-100 {
    --gradient-from-color: #fff5f5;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 245, 245, 0));
  }

  .lg\:tw-from-red-200 {
    --gradient-from-color: #fed7d7;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(254, 215, 215, 0));
  }

  .lg\:tw-from-red-300 {
    --gradient-from-color: #feb2b2;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(254, 178, 178, 0));
  }

  .lg\:tw-from-red-400 {
    --gradient-from-color: #fc8181;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(252, 129, 129, 0));
  }

  .lg\:tw-from-red-500 {
    --gradient-from-color: #f56565;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(245, 101, 101, 0));
  }

  .lg\:tw-from-red-600 {
    --gradient-from-color: #e53e3e;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(229, 62, 62, 0));
  }

  .lg\:tw-from-red-700 {
    --gradient-from-color: #c53030;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(197, 48, 48, 0));
  }

  .lg\:tw-from-red-800 {
    --gradient-from-color: #9b2c2c;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(155, 44, 44, 0));
  }

  .lg\:tw-from-red-900 {
    --gradient-from-color: #742a2a;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(116, 42, 42, 0));
  }

  .lg\:tw-from-orange-100 {
    --gradient-from-color: #fffaf0;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 250, 240, 0));
  }

  .lg\:tw-from-orange-200 {
    --gradient-from-color: #feebc8;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(254, 235, 200, 0));
  }

  .lg\:tw-from-orange-300 {
    --gradient-from-color: #fbd38d;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(251, 211, 141, 0));
  }

  .lg\:tw-from-orange-400 {
    --gradient-from-color: #f6ad55;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(246, 173, 85, 0));
  }

  .lg\:tw-from-orange-500 {
    --gradient-from-color: #ed8936;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(237, 137, 54, 0));
  }

  .lg\:tw-from-orange-600 {
    --gradient-from-color: #dd6b20;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(221, 107, 32, 0));
  }

  .lg\:tw-from-orange-700 {
    --gradient-from-color: #c05621;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(192, 86, 33, 0));
  }

  .lg\:tw-from-orange-800 {
    --gradient-from-color: #9c4221;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(156, 66, 33, 0));
  }

  .lg\:tw-from-orange-900 {
    --gradient-from-color: #7b341e;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(123, 52, 30, 0));
  }

  .lg\:tw-from-yellow-100 {
    --gradient-from-color: #fffff0;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 240, 0));
  }

  .lg\:tw-from-yellow-200 {
    --gradient-from-color: #fefcbf;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(254, 252, 191, 0));
  }

  .lg\:tw-from-yellow-300 {
    --gradient-from-color: #faf089;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(250, 240, 137, 0));
  }

  .lg\:tw-from-yellow-400 {
    --gradient-from-color: #f6e05e;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(246, 224, 94, 0));
  }

  .lg\:tw-from-yellow-500 {
    --gradient-from-color: #ecc94b;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(236, 201, 75, 0));
  }

  .lg\:tw-from-yellow-600 {
    --gradient-from-color: #d69e2e;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(214, 158, 46, 0));
  }

  .lg\:tw-from-yellow-700 {
    --gradient-from-color: #b7791f;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(183, 121, 31, 0));
  }

  .lg\:tw-from-yellow-800 {
    --gradient-from-color: #975a16;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(151, 90, 22, 0));
  }

  .lg\:tw-from-yellow-900 {
    --gradient-from-color: #744210;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(116, 66, 16, 0));
  }

  .lg\:tw-from-green-100 {
    --gradient-from-color: #f0fff4;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(240, 255, 244, 0));
  }

  .lg\:tw-from-green-200 {
    --gradient-from-color: #c6f6d5;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(198, 246, 213, 0));
  }

  .lg\:tw-from-green-300 {
    --gradient-from-color: #9ae6b4;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(154, 230, 180, 0));
  }

  .lg\:tw-from-green-400 {
    --gradient-from-color: #68d391;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(104, 211, 145, 0));
  }

  .lg\:tw-from-green-500 {
    --gradient-from-color: #48bb78;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(72, 187, 120, 0));
  }

  .lg\:tw-from-green-600 {
    --gradient-from-color: #38a169;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(56, 161, 105, 0));
  }

  .lg\:tw-from-green-700 {
    --gradient-from-color: #2f855a;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(47, 133, 90, 0));
  }

  .lg\:tw-from-green-800 {
    --gradient-from-color: #276749;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(39, 103, 73, 0));
  }

  .lg\:tw-from-green-900 {
    --gradient-from-color: #22543d;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(34, 84, 61, 0));
  }

  .lg\:tw-from-teal-100 {
    --gradient-from-color: #e6fffa;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(230, 255, 250, 0));
  }

  .lg\:tw-from-teal-200 {
    --gradient-from-color: #b2f5ea;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(178, 245, 234, 0));
  }

  .lg\:tw-from-teal-300 {
    --gradient-from-color: #81e6d9;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(129, 230, 217, 0));
  }

  .lg\:tw-from-teal-400 {
    --gradient-from-color: #4fd1c5;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(79, 209, 197, 0));
  }

  .lg\:tw-from-teal-500 {
    --gradient-from-color: #38b2ac;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(56, 178, 172, 0));
  }

  .lg\:tw-from-teal-600 {
    --gradient-from-color: #319795;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(49, 151, 149, 0));
  }

  .lg\:tw-from-teal-700 {
    --gradient-from-color: #2c7a7b;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(44, 122, 123, 0));
  }

  .lg\:tw-from-teal-800 {
    --gradient-from-color: #285e61;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(40, 94, 97, 0));
  }

  .lg\:tw-from-teal-900 {
    --gradient-from-color: #234e52;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(35, 78, 82, 0));
  }

  .lg\:tw-from-blue-100 {
    --gradient-from-color: #ebf8ff;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(235, 248, 255, 0));
  }

  .lg\:tw-from-blue-200 {
    --gradient-from-color: #bee3f8;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(190, 227, 248, 0));
  }

  .lg\:tw-from-blue-300 {
    --gradient-from-color: #90cdf4;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(144, 205, 244, 0));
  }

  .lg\:tw-from-blue-400 {
    --gradient-from-color: #63b3ed;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(99, 179, 237, 0));
  }

  .lg\:tw-from-blue-500 {
    --gradient-from-color: #4299e1;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(66, 153, 225, 0));
  }

  .lg\:tw-from-blue-600 {
    --gradient-from-color: #3182ce;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(49, 130, 206, 0));
  }

  .lg\:tw-from-blue-700 {
    --gradient-from-color: #2b6cb0;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(43, 108, 176, 0));
  }

  .lg\:tw-from-blue-800 {
    --gradient-from-color: #2c5282;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(44, 82, 130, 0));
  }

  .lg\:tw-from-blue-900 {
    --gradient-from-color: #2a4365;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(42, 67, 101, 0));
  }

  .lg\:tw-from-indigo-100 {
    --gradient-from-color: #ebf4ff;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(235, 244, 255, 0));
  }

  .lg\:tw-from-indigo-200 {
    --gradient-from-color: #c3dafe;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(195, 218, 254, 0));
  }

  .lg\:tw-from-indigo-300 {
    --gradient-from-color: #a3bffa;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(163, 191, 250, 0));
  }

  .lg\:tw-from-indigo-400 {
    --gradient-from-color: #7f9cf5;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(127, 156, 245, 0));
  }

  .lg\:tw-from-indigo-500 {
    --gradient-from-color: #667eea;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(102, 126, 234, 0));
  }

  .lg\:tw-from-indigo-600 {
    --gradient-from-color: #5a67d8;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(90, 103, 216, 0));
  }

  .lg\:tw-from-indigo-700 {
    --gradient-from-color: #4c51bf;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(76, 81, 191, 0));
  }

  .lg\:tw-from-indigo-800 {
    --gradient-from-color: #434190;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(67, 65, 144, 0));
  }

  .lg\:tw-from-indigo-900 {
    --gradient-from-color: #3c366b;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(60, 54, 107, 0));
  }

  .lg\:tw-from-purple-100 {
    --gradient-from-color: #faf5ff;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(250, 245, 255, 0));
  }

  .lg\:tw-from-purple-200 {
    --gradient-from-color: #e9d8fd;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(233, 216, 253, 0));
  }

  .lg\:tw-from-purple-300 {
    --gradient-from-color: #d6bcfa;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(214, 188, 250, 0));
  }

  .lg\:tw-from-purple-400 {
    --gradient-from-color: #b794f4;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(183, 148, 244, 0));
  }

  .lg\:tw-from-purple-500 {
    --gradient-from-color: #9f7aea;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(159, 122, 234, 0));
  }

  .lg\:tw-from-purple-600 {
    --gradient-from-color: #805ad5;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(128, 90, 213, 0));
  }

  .lg\:tw-from-purple-700 {
    --gradient-from-color: #6b46c1;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(107, 70, 193, 0));
  }

  .lg\:tw-from-purple-800 {
    --gradient-from-color: #553c9a;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(85, 60, 154, 0));
  }

  .lg\:tw-from-purple-900 {
    --gradient-from-color: #44337a;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(68, 51, 122, 0));
  }

  .lg\:tw-from-pink-100 {
    --gradient-from-color: #fff5f7;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 245, 247, 0));
  }

  .lg\:tw-from-pink-200 {
    --gradient-from-color: #fed7e2;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(254, 215, 226, 0));
  }

  .lg\:tw-from-pink-300 {
    --gradient-from-color: #fbb6ce;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(251, 182, 206, 0));
  }

  .lg\:tw-from-pink-400 {
    --gradient-from-color: #f687b3;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(246, 135, 179, 0));
  }

  .lg\:tw-from-pink-500 {
    --gradient-from-color: #ed64a6;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(237, 100, 166, 0));
  }

  .lg\:tw-from-pink-600 {
    --gradient-from-color: #d53f8c;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(213, 63, 140, 0));
  }

  .lg\:tw-from-pink-700 {
    --gradient-from-color: #b83280;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(184, 50, 128, 0));
  }

  .lg\:tw-from-pink-800 {
    --gradient-from-color: #97266d;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(151, 38, 109, 0));
  }

  .lg\:tw-from-pink-900 {
    --gradient-from-color: #702459;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(112, 36, 89, 0));
  }

  .lg\:tw-via-transparent {
    --gradient-via-color: transparent;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(0, 0, 0, 0));
  }

  .lg\:tw-via-current {
    --gradient-via-color: currentColor;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0));
  }

  .lg\:tw-via-black {
    --gradient-via-color: #000;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(0, 0, 0, 0));
  }

  .lg\:tw-via-white {
    --gradient-via-color: #fff;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0));
  }

  .lg\:tw-via-gray-100 {
    --gradient-via-color: #f7fafc;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(247, 250, 252, 0));
  }

  .lg\:tw-via-gray-200 {
    --gradient-via-color: #edf2f7;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(237, 242, 247, 0));
  }

  .lg\:tw-via-gray-300 {
    --gradient-via-color: #e2e8f0;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(226, 232, 240, 0));
  }

  .lg\:tw-via-gray-400 {
    --gradient-via-color: #cbd5e0;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(203, 213, 224, 0));
  }

  .lg\:tw-via-gray-500 {
    --gradient-via-color: #a0aec0;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(160, 174, 192, 0));
  }

  .lg\:tw-via-gray-600 {
    --gradient-via-color: #718096;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(113, 128, 150, 0));
  }

  .lg\:tw-via-gray-700 {
    --gradient-via-color: #4a5568;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(74, 85, 104, 0));
  }

  .lg\:tw-via-gray-800 {
    --gradient-via-color: #2d3748;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(45, 55, 72, 0));
  }

  .lg\:tw-via-gray-900 {
    --gradient-via-color: #1a202c;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(26, 32, 44, 0));
  }

  .lg\:tw-via-red-100 {
    --gradient-via-color: #fff5f5;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 245, 245, 0));
  }

  .lg\:tw-via-red-200 {
    --gradient-via-color: #fed7d7;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(254, 215, 215, 0));
  }

  .lg\:tw-via-red-300 {
    --gradient-via-color: #feb2b2;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(254, 178, 178, 0));
  }

  .lg\:tw-via-red-400 {
    --gradient-via-color: #fc8181;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(252, 129, 129, 0));
  }

  .lg\:tw-via-red-500 {
    --gradient-via-color: #f56565;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(245, 101, 101, 0));
  }

  .lg\:tw-via-red-600 {
    --gradient-via-color: #e53e3e;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(229, 62, 62, 0));
  }

  .lg\:tw-via-red-700 {
    --gradient-via-color: #c53030;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(197, 48, 48, 0));
  }

  .lg\:tw-via-red-800 {
    --gradient-via-color: #9b2c2c;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(155, 44, 44, 0));
  }

  .lg\:tw-via-red-900 {
    --gradient-via-color: #742a2a;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(116, 42, 42, 0));
  }

  .lg\:tw-via-orange-100 {
    --gradient-via-color: #fffaf0;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 250, 240, 0));
  }

  .lg\:tw-via-orange-200 {
    --gradient-via-color: #feebc8;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(254, 235, 200, 0));
  }

  .lg\:tw-via-orange-300 {
    --gradient-via-color: #fbd38d;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(251, 211, 141, 0));
  }

  .lg\:tw-via-orange-400 {
    --gradient-via-color: #f6ad55;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(246, 173, 85, 0));
  }

  .lg\:tw-via-orange-500 {
    --gradient-via-color: #ed8936;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(237, 137, 54, 0));
  }

  .lg\:tw-via-orange-600 {
    --gradient-via-color: #dd6b20;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(221, 107, 32, 0));
  }

  .lg\:tw-via-orange-700 {
    --gradient-via-color: #c05621;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(192, 86, 33, 0));
  }

  .lg\:tw-via-orange-800 {
    --gradient-via-color: #9c4221;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(156, 66, 33, 0));
  }

  .lg\:tw-via-orange-900 {
    --gradient-via-color: #7b341e;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(123, 52, 30, 0));
  }

  .lg\:tw-via-yellow-100 {
    --gradient-via-color: #fffff0;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 240, 0));
  }

  .lg\:tw-via-yellow-200 {
    --gradient-via-color: #fefcbf;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(254, 252, 191, 0));
  }

  .lg\:tw-via-yellow-300 {
    --gradient-via-color: #faf089;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(250, 240, 137, 0));
  }

  .lg\:tw-via-yellow-400 {
    --gradient-via-color: #f6e05e;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(246, 224, 94, 0));
  }

  .lg\:tw-via-yellow-500 {
    --gradient-via-color: #ecc94b;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(236, 201, 75, 0));
  }

  .lg\:tw-via-yellow-600 {
    --gradient-via-color: #d69e2e;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(214, 158, 46, 0));
  }

  .lg\:tw-via-yellow-700 {
    --gradient-via-color: #b7791f;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(183, 121, 31, 0));
  }

  .lg\:tw-via-yellow-800 {
    --gradient-via-color: #975a16;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(151, 90, 22, 0));
  }

  .lg\:tw-via-yellow-900 {
    --gradient-via-color: #744210;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(116, 66, 16, 0));
  }

  .lg\:tw-via-green-100 {
    --gradient-via-color: #f0fff4;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(240, 255, 244, 0));
  }

  .lg\:tw-via-green-200 {
    --gradient-via-color: #c6f6d5;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(198, 246, 213, 0));
  }

  .lg\:tw-via-green-300 {
    --gradient-via-color: #9ae6b4;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(154, 230, 180, 0));
  }

  .lg\:tw-via-green-400 {
    --gradient-via-color: #68d391;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(104, 211, 145, 0));
  }

  .lg\:tw-via-green-500 {
    --gradient-via-color: #48bb78;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(72, 187, 120, 0));
  }

  .lg\:tw-via-green-600 {
    --gradient-via-color: #38a169;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(56, 161, 105, 0));
  }

  .lg\:tw-via-green-700 {
    --gradient-via-color: #2f855a;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(47, 133, 90, 0));
  }

  .lg\:tw-via-green-800 {
    --gradient-via-color: #276749;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(39, 103, 73, 0));
  }

  .lg\:tw-via-green-900 {
    --gradient-via-color: #22543d;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(34, 84, 61, 0));
  }

  .lg\:tw-via-teal-100 {
    --gradient-via-color: #e6fffa;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(230, 255, 250, 0));
  }

  .lg\:tw-via-teal-200 {
    --gradient-via-color: #b2f5ea;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(178, 245, 234, 0));
  }

  .lg\:tw-via-teal-300 {
    --gradient-via-color: #81e6d9;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(129, 230, 217, 0));
  }

  .lg\:tw-via-teal-400 {
    --gradient-via-color: #4fd1c5;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(79, 209, 197, 0));
  }

  .lg\:tw-via-teal-500 {
    --gradient-via-color: #38b2ac;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(56, 178, 172, 0));
  }

  .lg\:tw-via-teal-600 {
    --gradient-via-color: #319795;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(49, 151, 149, 0));
  }

  .lg\:tw-via-teal-700 {
    --gradient-via-color: #2c7a7b;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(44, 122, 123, 0));
  }

  .lg\:tw-via-teal-800 {
    --gradient-via-color: #285e61;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(40, 94, 97, 0));
  }

  .lg\:tw-via-teal-900 {
    --gradient-via-color: #234e52;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(35, 78, 82, 0));
  }

  .lg\:tw-via-blue-100 {
    --gradient-via-color: #ebf8ff;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(235, 248, 255, 0));
  }

  .lg\:tw-via-blue-200 {
    --gradient-via-color: #bee3f8;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(190, 227, 248, 0));
  }

  .lg\:tw-via-blue-300 {
    --gradient-via-color: #90cdf4;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(144, 205, 244, 0));
  }

  .lg\:tw-via-blue-400 {
    --gradient-via-color: #63b3ed;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(99, 179, 237, 0));
  }

  .lg\:tw-via-blue-500 {
    --gradient-via-color: #4299e1;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(66, 153, 225, 0));
  }

  .lg\:tw-via-blue-600 {
    --gradient-via-color: #3182ce;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(49, 130, 206, 0));
  }

  .lg\:tw-via-blue-700 {
    --gradient-via-color: #2b6cb0;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(43, 108, 176, 0));
  }

  .lg\:tw-via-blue-800 {
    --gradient-via-color: #2c5282;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(44, 82, 130, 0));
  }

  .lg\:tw-via-blue-900 {
    --gradient-via-color: #2a4365;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(42, 67, 101, 0));
  }

  .lg\:tw-via-indigo-100 {
    --gradient-via-color: #ebf4ff;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(235, 244, 255, 0));
  }

  .lg\:tw-via-indigo-200 {
    --gradient-via-color: #c3dafe;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(195, 218, 254, 0));
  }

  .lg\:tw-via-indigo-300 {
    --gradient-via-color: #a3bffa;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(163, 191, 250, 0));
  }

  .lg\:tw-via-indigo-400 {
    --gradient-via-color: #7f9cf5;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(127, 156, 245, 0));
  }

  .lg\:tw-via-indigo-500 {
    --gradient-via-color: #667eea;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(102, 126, 234, 0));
  }

  .lg\:tw-via-indigo-600 {
    --gradient-via-color: #5a67d8;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(90, 103, 216, 0));
  }

  .lg\:tw-via-indigo-700 {
    --gradient-via-color: #4c51bf;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(76, 81, 191, 0));
  }

  .lg\:tw-via-indigo-800 {
    --gradient-via-color: #434190;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(67, 65, 144, 0));
  }

  .lg\:tw-via-indigo-900 {
    --gradient-via-color: #3c366b;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(60, 54, 107, 0));
  }

  .lg\:tw-via-purple-100 {
    --gradient-via-color: #faf5ff;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(250, 245, 255, 0));
  }

  .lg\:tw-via-purple-200 {
    --gradient-via-color: #e9d8fd;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(233, 216, 253, 0));
  }

  .lg\:tw-via-purple-300 {
    --gradient-via-color: #d6bcfa;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(214, 188, 250, 0));
  }

  .lg\:tw-via-purple-400 {
    --gradient-via-color: #b794f4;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(183, 148, 244, 0));
  }

  .lg\:tw-via-purple-500 {
    --gradient-via-color: #9f7aea;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(159, 122, 234, 0));
  }

  .lg\:tw-via-purple-600 {
    --gradient-via-color: #805ad5;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(128, 90, 213, 0));
  }

  .lg\:tw-via-purple-700 {
    --gradient-via-color: #6b46c1;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(107, 70, 193, 0));
  }

  .lg\:tw-via-purple-800 {
    --gradient-via-color: #553c9a;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(85, 60, 154, 0));
  }

  .lg\:tw-via-purple-900 {
    --gradient-via-color: #44337a;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(68, 51, 122, 0));
  }

  .lg\:tw-via-pink-100 {
    --gradient-via-color: #fff5f7;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 245, 247, 0));
  }

  .lg\:tw-via-pink-200 {
    --gradient-via-color: #fed7e2;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(254, 215, 226, 0));
  }

  .lg\:tw-via-pink-300 {
    --gradient-via-color: #fbb6ce;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(251, 182, 206, 0));
  }

  .lg\:tw-via-pink-400 {
    --gradient-via-color: #f687b3;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(246, 135, 179, 0));
  }

  .lg\:tw-via-pink-500 {
    --gradient-via-color: #ed64a6;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(237, 100, 166, 0));
  }

  .lg\:tw-via-pink-600 {
    --gradient-via-color: #d53f8c;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(213, 63, 140, 0));
  }

  .lg\:tw-via-pink-700 {
    --gradient-via-color: #b83280;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(184, 50, 128, 0));
  }

  .lg\:tw-via-pink-800 {
    --gradient-via-color: #97266d;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(151, 38, 109, 0));
  }

  .lg\:tw-via-pink-900 {
    --gradient-via-color: #702459;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(112, 36, 89, 0));
  }

  .lg\:tw-to-transparent {
    --gradient-to-color: transparent;
  }

  .lg\:tw-to-current {
    --gradient-to-color: currentColor;
  }

  .lg\:tw-to-black {
    --gradient-to-color: #000;
  }

  .lg\:tw-to-white {
    --gradient-to-color: #fff;
  }

  .lg\:tw-to-gray-100 {
    --gradient-to-color: #f7fafc;
  }

  .lg\:tw-to-gray-200 {
    --gradient-to-color: #edf2f7;
  }

  .lg\:tw-to-gray-300 {
    --gradient-to-color: #e2e8f0;
  }

  .lg\:tw-to-gray-400 {
    --gradient-to-color: #cbd5e0;
  }

  .lg\:tw-to-gray-500 {
    --gradient-to-color: #a0aec0;
  }

  .lg\:tw-to-gray-600 {
    --gradient-to-color: #718096;
  }

  .lg\:tw-to-gray-700 {
    --gradient-to-color: #4a5568;
  }

  .lg\:tw-to-gray-800 {
    --gradient-to-color: #2d3748;
  }

  .lg\:tw-to-gray-900 {
    --gradient-to-color: #1a202c;
  }

  .lg\:tw-to-red-100 {
    --gradient-to-color: #fff5f5;
  }

  .lg\:tw-to-red-200 {
    --gradient-to-color: #fed7d7;
  }

  .lg\:tw-to-red-300 {
    --gradient-to-color: #feb2b2;
  }

  .lg\:tw-to-red-400 {
    --gradient-to-color: #fc8181;
  }

  .lg\:tw-to-red-500 {
    --gradient-to-color: #f56565;
  }

  .lg\:tw-to-red-600 {
    --gradient-to-color: #e53e3e;
  }

  .lg\:tw-to-red-700 {
    --gradient-to-color: #c53030;
  }

  .lg\:tw-to-red-800 {
    --gradient-to-color: #9b2c2c;
  }

  .lg\:tw-to-red-900 {
    --gradient-to-color: #742a2a;
  }

  .lg\:tw-to-orange-100 {
    --gradient-to-color: #fffaf0;
  }

  .lg\:tw-to-orange-200 {
    --gradient-to-color: #feebc8;
  }

  .lg\:tw-to-orange-300 {
    --gradient-to-color: #fbd38d;
  }

  .lg\:tw-to-orange-400 {
    --gradient-to-color: #f6ad55;
  }

  .lg\:tw-to-orange-500 {
    --gradient-to-color: #ed8936;
  }

  .lg\:tw-to-orange-600 {
    --gradient-to-color: #dd6b20;
  }

  .lg\:tw-to-orange-700 {
    --gradient-to-color: #c05621;
  }

  .lg\:tw-to-orange-800 {
    --gradient-to-color: #9c4221;
  }

  .lg\:tw-to-orange-900 {
    --gradient-to-color: #7b341e;
  }

  .lg\:tw-to-yellow-100 {
    --gradient-to-color: #fffff0;
  }

  .lg\:tw-to-yellow-200 {
    --gradient-to-color: #fefcbf;
  }

  .lg\:tw-to-yellow-300 {
    --gradient-to-color: #faf089;
  }

  .lg\:tw-to-yellow-400 {
    --gradient-to-color: #f6e05e;
  }

  .lg\:tw-to-yellow-500 {
    --gradient-to-color: #ecc94b;
  }

  .lg\:tw-to-yellow-600 {
    --gradient-to-color: #d69e2e;
  }

  .lg\:tw-to-yellow-700 {
    --gradient-to-color: #b7791f;
  }

  .lg\:tw-to-yellow-800 {
    --gradient-to-color: #975a16;
  }

  .lg\:tw-to-yellow-900 {
    --gradient-to-color: #744210;
  }

  .lg\:tw-to-green-100 {
    --gradient-to-color: #f0fff4;
  }

  .lg\:tw-to-green-200 {
    --gradient-to-color: #c6f6d5;
  }

  .lg\:tw-to-green-300 {
    --gradient-to-color: #9ae6b4;
  }

  .lg\:tw-to-green-400 {
    --gradient-to-color: #68d391;
  }

  .lg\:tw-to-green-500 {
    --gradient-to-color: #48bb78;
  }

  .lg\:tw-to-green-600 {
    --gradient-to-color: #38a169;
  }

  .lg\:tw-to-green-700 {
    --gradient-to-color: #2f855a;
  }

  .lg\:tw-to-green-800 {
    --gradient-to-color: #276749;
  }

  .lg\:tw-to-green-900 {
    --gradient-to-color: #22543d;
  }

  .lg\:tw-to-teal-100 {
    --gradient-to-color: #e6fffa;
  }

  .lg\:tw-to-teal-200 {
    --gradient-to-color: #b2f5ea;
  }

  .lg\:tw-to-teal-300 {
    --gradient-to-color: #81e6d9;
  }

  .lg\:tw-to-teal-400 {
    --gradient-to-color: #4fd1c5;
  }

  .lg\:tw-to-teal-500 {
    --gradient-to-color: #38b2ac;
  }

  .lg\:tw-to-teal-600 {
    --gradient-to-color: #319795;
  }

  .lg\:tw-to-teal-700 {
    --gradient-to-color: #2c7a7b;
  }

  .lg\:tw-to-teal-800 {
    --gradient-to-color: #285e61;
  }

  .lg\:tw-to-teal-900 {
    --gradient-to-color: #234e52;
  }

  .lg\:tw-to-blue-100 {
    --gradient-to-color: #ebf8ff;
  }

  .lg\:tw-to-blue-200 {
    --gradient-to-color: #bee3f8;
  }

  .lg\:tw-to-blue-300 {
    --gradient-to-color: #90cdf4;
  }

  .lg\:tw-to-blue-400 {
    --gradient-to-color: #63b3ed;
  }

  .lg\:tw-to-blue-500 {
    --gradient-to-color: #4299e1;
  }

  .lg\:tw-to-blue-600 {
    --gradient-to-color: #3182ce;
  }

  .lg\:tw-to-blue-700 {
    --gradient-to-color: #2b6cb0;
  }

  .lg\:tw-to-blue-800 {
    --gradient-to-color: #2c5282;
  }

  .lg\:tw-to-blue-900 {
    --gradient-to-color: #2a4365;
  }

  .lg\:tw-to-indigo-100 {
    --gradient-to-color: #ebf4ff;
  }

  .lg\:tw-to-indigo-200 {
    --gradient-to-color: #c3dafe;
  }

  .lg\:tw-to-indigo-300 {
    --gradient-to-color: #a3bffa;
  }

  .lg\:tw-to-indigo-400 {
    --gradient-to-color: #7f9cf5;
  }

  .lg\:tw-to-indigo-500 {
    --gradient-to-color: #667eea;
  }

  .lg\:tw-to-indigo-600 {
    --gradient-to-color: #5a67d8;
  }

  .lg\:tw-to-indigo-700 {
    --gradient-to-color: #4c51bf;
  }

  .lg\:tw-to-indigo-800 {
    --gradient-to-color: #434190;
  }

  .lg\:tw-to-indigo-900 {
    --gradient-to-color: #3c366b;
  }

  .lg\:tw-to-purple-100 {
    --gradient-to-color: #faf5ff;
  }

  .lg\:tw-to-purple-200 {
    --gradient-to-color: #e9d8fd;
  }

  .lg\:tw-to-purple-300 {
    --gradient-to-color: #d6bcfa;
  }

  .lg\:tw-to-purple-400 {
    --gradient-to-color: #b794f4;
  }

  .lg\:tw-to-purple-500 {
    --gradient-to-color: #9f7aea;
  }

  .lg\:tw-to-purple-600 {
    --gradient-to-color: #805ad5;
  }

  .lg\:tw-to-purple-700 {
    --gradient-to-color: #6b46c1;
  }

  .lg\:tw-to-purple-800 {
    --gradient-to-color: #553c9a;
  }

  .lg\:tw-to-purple-900 {
    --gradient-to-color: #44337a;
  }

  .lg\:tw-to-pink-100 {
    --gradient-to-color: #fff5f7;
  }

  .lg\:tw-to-pink-200 {
    --gradient-to-color: #fed7e2;
  }

  .lg\:tw-to-pink-300 {
    --gradient-to-color: #fbb6ce;
  }

  .lg\:tw-to-pink-400 {
    --gradient-to-color: #f687b3;
  }

  .lg\:tw-to-pink-500 {
    --gradient-to-color: #ed64a6;
  }

  .lg\:tw-to-pink-600 {
    --gradient-to-color: #d53f8c;
  }

  .lg\:tw-to-pink-700 {
    --gradient-to-color: #b83280;
  }

  .lg\:tw-to-pink-800 {
    --gradient-to-color: #97266d;
  }

  .lg\:tw-to-pink-900 {
    --gradient-to-color: #702459;
  }

  .lg\:hover\:tw-from-transparent:hover {
    --gradient-from-color: transparent;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(0, 0, 0, 0));
  }

  .lg\:hover\:tw-from-current:hover {
    --gradient-from-color: currentColor;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0));
  }

  .lg\:hover\:tw-from-black:hover {
    --gradient-from-color: #000;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(0, 0, 0, 0));
  }

  .lg\:hover\:tw-from-white:hover {
    --gradient-from-color: #fff;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0));
  }

  .lg\:hover\:tw-from-gray-100:hover {
    --gradient-from-color: #f7fafc;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(247, 250, 252, 0));
  }

  .lg\:hover\:tw-from-gray-200:hover {
    --gradient-from-color: #edf2f7;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(237, 242, 247, 0));
  }

  .lg\:hover\:tw-from-gray-300:hover {
    --gradient-from-color: #e2e8f0;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(226, 232, 240, 0));
  }

  .lg\:hover\:tw-from-gray-400:hover {
    --gradient-from-color: #cbd5e0;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(203, 213, 224, 0));
  }

  .lg\:hover\:tw-from-gray-500:hover {
    --gradient-from-color: #a0aec0;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(160, 174, 192, 0));
  }

  .lg\:hover\:tw-from-gray-600:hover {
    --gradient-from-color: #718096;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(113, 128, 150, 0));
  }

  .lg\:hover\:tw-from-gray-700:hover {
    --gradient-from-color: #4a5568;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(74, 85, 104, 0));
  }

  .lg\:hover\:tw-from-gray-800:hover {
    --gradient-from-color: #2d3748;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(45, 55, 72, 0));
  }

  .lg\:hover\:tw-from-gray-900:hover {
    --gradient-from-color: #1a202c;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(26, 32, 44, 0));
  }

  .lg\:hover\:tw-from-red-100:hover {
    --gradient-from-color: #fff5f5;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 245, 245, 0));
  }

  .lg\:hover\:tw-from-red-200:hover {
    --gradient-from-color: #fed7d7;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(254, 215, 215, 0));
  }

  .lg\:hover\:tw-from-red-300:hover {
    --gradient-from-color: #feb2b2;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(254, 178, 178, 0));
  }

  .lg\:hover\:tw-from-red-400:hover {
    --gradient-from-color: #fc8181;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(252, 129, 129, 0));
  }

  .lg\:hover\:tw-from-red-500:hover {
    --gradient-from-color: #f56565;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(245, 101, 101, 0));
  }

  .lg\:hover\:tw-from-red-600:hover {
    --gradient-from-color: #e53e3e;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(229, 62, 62, 0));
  }

  .lg\:hover\:tw-from-red-700:hover {
    --gradient-from-color: #c53030;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(197, 48, 48, 0));
  }

  .lg\:hover\:tw-from-red-800:hover {
    --gradient-from-color: #9b2c2c;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(155, 44, 44, 0));
  }

  .lg\:hover\:tw-from-red-900:hover {
    --gradient-from-color: #742a2a;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(116, 42, 42, 0));
  }

  .lg\:hover\:tw-from-orange-100:hover {
    --gradient-from-color: #fffaf0;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 250, 240, 0));
  }

  .lg\:hover\:tw-from-orange-200:hover {
    --gradient-from-color: #feebc8;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(254, 235, 200, 0));
  }

  .lg\:hover\:tw-from-orange-300:hover {
    --gradient-from-color: #fbd38d;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(251, 211, 141, 0));
  }

  .lg\:hover\:tw-from-orange-400:hover {
    --gradient-from-color: #f6ad55;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(246, 173, 85, 0));
  }

  .lg\:hover\:tw-from-orange-500:hover {
    --gradient-from-color: #ed8936;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(237, 137, 54, 0));
  }

  .lg\:hover\:tw-from-orange-600:hover {
    --gradient-from-color: #dd6b20;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(221, 107, 32, 0));
  }

  .lg\:hover\:tw-from-orange-700:hover {
    --gradient-from-color: #c05621;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(192, 86, 33, 0));
  }

  .lg\:hover\:tw-from-orange-800:hover {
    --gradient-from-color: #9c4221;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(156, 66, 33, 0));
  }

  .lg\:hover\:tw-from-orange-900:hover {
    --gradient-from-color: #7b341e;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(123, 52, 30, 0));
  }

  .lg\:hover\:tw-from-yellow-100:hover {
    --gradient-from-color: #fffff0;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 240, 0));
  }

  .lg\:hover\:tw-from-yellow-200:hover {
    --gradient-from-color: #fefcbf;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(254, 252, 191, 0));
  }

  .lg\:hover\:tw-from-yellow-300:hover {
    --gradient-from-color: #faf089;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(250, 240, 137, 0));
  }

  .lg\:hover\:tw-from-yellow-400:hover {
    --gradient-from-color: #f6e05e;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(246, 224, 94, 0));
  }

  .lg\:hover\:tw-from-yellow-500:hover {
    --gradient-from-color: #ecc94b;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(236, 201, 75, 0));
  }

  .lg\:hover\:tw-from-yellow-600:hover {
    --gradient-from-color: #d69e2e;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(214, 158, 46, 0));
  }

  .lg\:hover\:tw-from-yellow-700:hover {
    --gradient-from-color: #b7791f;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(183, 121, 31, 0));
  }

  .lg\:hover\:tw-from-yellow-800:hover {
    --gradient-from-color: #975a16;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(151, 90, 22, 0));
  }

  .lg\:hover\:tw-from-yellow-900:hover {
    --gradient-from-color: #744210;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(116, 66, 16, 0));
  }

  .lg\:hover\:tw-from-green-100:hover {
    --gradient-from-color: #f0fff4;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(240, 255, 244, 0));
  }

  .lg\:hover\:tw-from-green-200:hover {
    --gradient-from-color: #c6f6d5;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(198, 246, 213, 0));
  }

  .lg\:hover\:tw-from-green-300:hover {
    --gradient-from-color: #9ae6b4;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(154, 230, 180, 0));
  }

  .lg\:hover\:tw-from-green-400:hover {
    --gradient-from-color: #68d391;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(104, 211, 145, 0));
  }

  .lg\:hover\:tw-from-green-500:hover {
    --gradient-from-color: #48bb78;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(72, 187, 120, 0));
  }

  .lg\:hover\:tw-from-green-600:hover {
    --gradient-from-color: #38a169;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(56, 161, 105, 0));
  }

  .lg\:hover\:tw-from-green-700:hover {
    --gradient-from-color: #2f855a;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(47, 133, 90, 0));
  }

  .lg\:hover\:tw-from-green-800:hover {
    --gradient-from-color: #276749;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(39, 103, 73, 0));
  }

  .lg\:hover\:tw-from-green-900:hover {
    --gradient-from-color: #22543d;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(34, 84, 61, 0));
  }

  .lg\:hover\:tw-from-teal-100:hover {
    --gradient-from-color: #e6fffa;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(230, 255, 250, 0));
  }

  .lg\:hover\:tw-from-teal-200:hover {
    --gradient-from-color: #b2f5ea;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(178, 245, 234, 0));
  }

  .lg\:hover\:tw-from-teal-300:hover {
    --gradient-from-color: #81e6d9;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(129, 230, 217, 0));
  }

  .lg\:hover\:tw-from-teal-400:hover {
    --gradient-from-color: #4fd1c5;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(79, 209, 197, 0));
  }

  .lg\:hover\:tw-from-teal-500:hover {
    --gradient-from-color: #38b2ac;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(56, 178, 172, 0));
  }

  .lg\:hover\:tw-from-teal-600:hover {
    --gradient-from-color: #319795;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(49, 151, 149, 0));
  }

  .lg\:hover\:tw-from-teal-700:hover {
    --gradient-from-color: #2c7a7b;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(44, 122, 123, 0));
  }

  .lg\:hover\:tw-from-teal-800:hover {
    --gradient-from-color: #285e61;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(40, 94, 97, 0));
  }

  .lg\:hover\:tw-from-teal-900:hover {
    --gradient-from-color: #234e52;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(35, 78, 82, 0));
  }

  .lg\:hover\:tw-from-blue-100:hover {
    --gradient-from-color: #ebf8ff;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(235, 248, 255, 0));
  }

  .lg\:hover\:tw-from-blue-200:hover {
    --gradient-from-color: #bee3f8;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(190, 227, 248, 0));
  }

  .lg\:hover\:tw-from-blue-300:hover {
    --gradient-from-color: #90cdf4;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(144, 205, 244, 0));
  }

  .lg\:hover\:tw-from-blue-400:hover {
    --gradient-from-color: #63b3ed;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(99, 179, 237, 0));
  }

  .lg\:hover\:tw-from-blue-500:hover {
    --gradient-from-color: #4299e1;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(66, 153, 225, 0));
  }

  .lg\:hover\:tw-from-blue-600:hover {
    --gradient-from-color: #3182ce;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(49, 130, 206, 0));
  }

  .lg\:hover\:tw-from-blue-700:hover {
    --gradient-from-color: #2b6cb0;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(43, 108, 176, 0));
  }

  .lg\:hover\:tw-from-blue-800:hover {
    --gradient-from-color: #2c5282;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(44, 82, 130, 0));
  }

  .lg\:hover\:tw-from-blue-900:hover {
    --gradient-from-color: #2a4365;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(42, 67, 101, 0));
  }

  .lg\:hover\:tw-from-indigo-100:hover {
    --gradient-from-color: #ebf4ff;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(235, 244, 255, 0));
  }

  .lg\:hover\:tw-from-indigo-200:hover {
    --gradient-from-color: #c3dafe;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(195, 218, 254, 0));
  }

  .lg\:hover\:tw-from-indigo-300:hover {
    --gradient-from-color: #a3bffa;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(163, 191, 250, 0));
  }

  .lg\:hover\:tw-from-indigo-400:hover {
    --gradient-from-color: #7f9cf5;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(127, 156, 245, 0));
  }

  .lg\:hover\:tw-from-indigo-500:hover {
    --gradient-from-color: #667eea;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(102, 126, 234, 0));
  }

  .lg\:hover\:tw-from-indigo-600:hover {
    --gradient-from-color: #5a67d8;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(90, 103, 216, 0));
  }

  .lg\:hover\:tw-from-indigo-700:hover {
    --gradient-from-color: #4c51bf;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(76, 81, 191, 0));
  }

  .lg\:hover\:tw-from-indigo-800:hover {
    --gradient-from-color: #434190;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(67, 65, 144, 0));
  }

  .lg\:hover\:tw-from-indigo-900:hover {
    --gradient-from-color: #3c366b;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(60, 54, 107, 0));
  }

  .lg\:hover\:tw-from-purple-100:hover {
    --gradient-from-color: #faf5ff;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(250, 245, 255, 0));
  }

  .lg\:hover\:tw-from-purple-200:hover {
    --gradient-from-color: #e9d8fd;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(233, 216, 253, 0));
  }

  .lg\:hover\:tw-from-purple-300:hover {
    --gradient-from-color: #d6bcfa;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(214, 188, 250, 0));
  }

  .lg\:hover\:tw-from-purple-400:hover {
    --gradient-from-color: #b794f4;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(183, 148, 244, 0));
  }

  .lg\:hover\:tw-from-purple-500:hover {
    --gradient-from-color: #9f7aea;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(159, 122, 234, 0));
  }

  .lg\:hover\:tw-from-purple-600:hover {
    --gradient-from-color: #805ad5;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(128, 90, 213, 0));
  }

  .lg\:hover\:tw-from-purple-700:hover {
    --gradient-from-color: #6b46c1;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(107, 70, 193, 0));
  }

  .lg\:hover\:tw-from-purple-800:hover {
    --gradient-from-color: #553c9a;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(85, 60, 154, 0));
  }

  .lg\:hover\:tw-from-purple-900:hover {
    --gradient-from-color: #44337a;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(68, 51, 122, 0));
  }

  .lg\:hover\:tw-from-pink-100:hover {
    --gradient-from-color: #fff5f7;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 245, 247, 0));
  }

  .lg\:hover\:tw-from-pink-200:hover {
    --gradient-from-color: #fed7e2;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(254, 215, 226, 0));
  }

  .lg\:hover\:tw-from-pink-300:hover {
    --gradient-from-color: #fbb6ce;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(251, 182, 206, 0));
  }

  .lg\:hover\:tw-from-pink-400:hover {
    --gradient-from-color: #f687b3;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(246, 135, 179, 0));
  }

  .lg\:hover\:tw-from-pink-500:hover {
    --gradient-from-color: #ed64a6;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(237, 100, 166, 0));
  }

  .lg\:hover\:tw-from-pink-600:hover {
    --gradient-from-color: #d53f8c;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(213, 63, 140, 0));
  }

  .lg\:hover\:tw-from-pink-700:hover {
    --gradient-from-color: #b83280;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(184, 50, 128, 0));
  }

  .lg\:hover\:tw-from-pink-800:hover {
    --gradient-from-color: #97266d;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(151, 38, 109, 0));
  }

  .lg\:hover\:tw-from-pink-900:hover {
    --gradient-from-color: #702459;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(112, 36, 89, 0));
  }

  .lg\:hover\:tw-via-transparent:hover {
    --gradient-via-color: transparent;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(0, 0, 0, 0));
  }

  .lg\:hover\:tw-via-current:hover {
    --gradient-via-color: currentColor;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0));
  }

  .lg\:hover\:tw-via-black:hover {
    --gradient-via-color: #000;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(0, 0, 0, 0));
  }

  .lg\:hover\:tw-via-white:hover {
    --gradient-via-color: #fff;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0));
  }

  .lg\:hover\:tw-via-gray-100:hover {
    --gradient-via-color: #f7fafc;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(247, 250, 252, 0));
  }

  .lg\:hover\:tw-via-gray-200:hover {
    --gradient-via-color: #edf2f7;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(237, 242, 247, 0));
  }

  .lg\:hover\:tw-via-gray-300:hover {
    --gradient-via-color: #e2e8f0;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(226, 232, 240, 0));
  }

  .lg\:hover\:tw-via-gray-400:hover {
    --gradient-via-color: #cbd5e0;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(203, 213, 224, 0));
  }

  .lg\:hover\:tw-via-gray-500:hover {
    --gradient-via-color: #a0aec0;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(160, 174, 192, 0));
  }

  .lg\:hover\:tw-via-gray-600:hover {
    --gradient-via-color: #718096;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(113, 128, 150, 0));
  }

  .lg\:hover\:tw-via-gray-700:hover {
    --gradient-via-color: #4a5568;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(74, 85, 104, 0));
  }

  .lg\:hover\:tw-via-gray-800:hover {
    --gradient-via-color: #2d3748;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(45, 55, 72, 0));
  }

  .lg\:hover\:tw-via-gray-900:hover {
    --gradient-via-color: #1a202c;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(26, 32, 44, 0));
  }

  .lg\:hover\:tw-via-red-100:hover {
    --gradient-via-color: #fff5f5;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 245, 245, 0));
  }

  .lg\:hover\:tw-via-red-200:hover {
    --gradient-via-color: #fed7d7;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(254, 215, 215, 0));
  }

  .lg\:hover\:tw-via-red-300:hover {
    --gradient-via-color: #feb2b2;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(254, 178, 178, 0));
  }

  .lg\:hover\:tw-via-red-400:hover {
    --gradient-via-color: #fc8181;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(252, 129, 129, 0));
  }

  .lg\:hover\:tw-via-red-500:hover {
    --gradient-via-color: #f56565;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(245, 101, 101, 0));
  }

  .lg\:hover\:tw-via-red-600:hover {
    --gradient-via-color: #e53e3e;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(229, 62, 62, 0));
  }

  .lg\:hover\:tw-via-red-700:hover {
    --gradient-via-color: #c53030;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(197, 48, 48, 0));
  }

  .lg\:hover\:tw-via-red-800:hover {
    --gradient-via-color: #9b2c2c;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(155, 44, 44, 0));
  }

  .lg\:hover\:tw-via-red-900:hover {
    --gradient-via-color: #742a2a;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(116, 42, 42, 0));
  }

  .lg\:hover\:tw-via-orange-100:hover {
    --gradient-via-color: #fffaf0;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 250, 240, 0));
  }

  .lg\:hover\:tw-via-orange-200:hover {
    --gradient-via-color: #feebc8;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(254, 235, 200, 0));
  }

  .lg\:hover\:tw-via-orange-300:hover {
    --gradient-via-color: #fbd38d;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(251, 211, 141, 0));
  }

  .lg\:hover\:tw-via-orange-400:hover {
    --gradient-via-color: #f6ad55;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(246, 173, 85, 0));
  }

  .lg\:hover\:tw-via-orange-500:hover {
    --gradient-via-color: #ed8936;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(237, 137, 54, 0));
  }

  .lg\:hover\:tw-via-orange-600:hover {
    --gradient-via-color: #dd6b20;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(221, 107, 32, 0));
  }

  .lg\:hover\:tw-via-orange-700:hover {
    --gradient-via-color: #c05621;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(192, 86, 33, 0));
  }

  .lg\:hover\:tw-via-orange-800:hover {
    --gradient-via-color: #9c4221;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(156, 66, 33, 0));
  }

  .lg\:hover\:tw-via-orange-900:hover {
    --gradient-via-color: #7b341e;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(123, 52, 30, 0));
  }

  .lg\:hover\:tw-via-yellow-100:hover {
    --gradient-via-color: #fffff0;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 240, 0));
  }

  .lg\:hover\:tw-via-yellow-200:hover {
    --gradient-via-color: #fefcbf;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(254, 252, 191, 0));
  }

  .lg\:hover\:tw-via-yellow-300:hover {
    --gradient-via-color: #faf089;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(250, 240, 137, 0));
  }

  .lg\:hover\:tw-via-yellow-400:hover {
    --gradient-via-color: #f6e05e;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(246, 224, 94, 0));
  }

  .lg\:hover\:tw-via-yellow-500:hover {
    --gradient-via-color: #ecc94b;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(236, 201, 75, 0));
  }

  .lg\:hover\:tw-via-yellow-600:hover {
    --gradient-via-color: #d69e2e;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(214, 158, 46, 0));
  }

  .lg\:hover\:tw-via-yellow-700:hover {
    --gradient-via-color: #b7791f;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(183, 121, 31, 0));
  }

  .lg\:hover\:tw-via-yellow-800:hover {
    --gradient-via-color: #975a16;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(151, 90, 22, 0));
  }

  .lg\:hover\:tw-via-yellow-900:hover {
    --gradient-via-color: #744210;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(116, 66, 16, 0));
  }

  .lg\:hover\:tw-via-green-100:hover {
    --gradient-via-color: #f0fff4;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(240, 255, 244, 0));
  }

  .lg\:hover\:tw-via-green-200:hover {
    --gradient-via-color: #c6f6d5;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(198, 246, 213, 0));
  }

  .lg\:hover\:tw-via-green-300:hover {
    --gradient-via-color: #9ae6b4;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(154, 230, 180, 0));
  }

  .lg\:hover\:tw-via-green-400:hover {
    --gradient-via-color: #68d391;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(104, 211, 145, 0));
  }

  .lg\:hover\:tw-via-green-500:hover {
    --gradient-via-color: #48bb78;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(72, 187, 120, 0));
  }

  .lg\:hover\:tw-via-green-600:hover {
    --gradient-via-color: #38a169;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(56, 161, 105, 0));
  }

  .lg\:hover\:tw-via-green-700:hover {
    --gradient-via-color: #2f855a;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(47, 133, 90, 0));
  }

  .lg\:hover\:tw-via-green-800:hover {
    --gradient-via-color: #276749;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(39, 103, 73, 0));
  }

  .lg\:hover\:tw-via-green-900:hover {
    --gradient-via-color: #22543d;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(34, 84, 61, 0));
  }

  .lg\:hover\:tw-via-teal-100:hover {
    --gradient-via-color: #e6fffa;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(230, 255, 250, 0));
  }

  .lg\:hover\:tw-via-teal-200:hover {
    --gradient-via-color: #b2f5ea;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(178, 245, 234, 0));
  }

  .lg\:hover\:tw-via-teal-300:hover {
    --gradient-via-color: #81e6d9;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(129, 230, 217, 0));
  }

  .lg\:hover\:tw-via-teal-400:hover {
    --gradient-via-color: #4fd1c5;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(79, 209, 197, 0));
  }

  .lg\:hover\:tw-via-teal-500:hover {
    --gradient-via-color: #38b2ac;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(56, 178, 172, 0));
  }

  .lg\:hover\:tw-via-teal-600:hover {
    --gradient-via-color: #319795;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(49, 151, 149, 0));
  }

  .lg\:hover\:tw-via-teal-700:hover {
    --gradient-via-color: #2c7a7b;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(44, 122, 123, 0));
  }

  .lg\:hover\:tw-via-teal-800:hover {
    --gradient-via-color: #285e61;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(40, 94, 97, 0));
  }

  .lg\:hover\:tw-via-teal-900:hover {
    --gradient-via-color: #234e52;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(35, 78, 82, 0));
  }

  .lg\:hover\:tw-via-blue-100:hover {
    --gradient-via-color: #ebf8ff;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(235, 248, 255, 0));
  }

  .lg\:hover\:tw-via-blue-200:hover {
    --gradient-via-color: #bee3f8;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(190, 227, 248, 0));
  }

  .lg\:hover\:tw-via-blue-300:hover {
    --gradient-via-color: #90cdf4;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(144, 205, 244, 0));
  }

  .lg\:hover\:tw-via-blue-400:hover {
    --gradient-via-color: #63b3ed;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(99, 179, 237, 0));
  }

  .lg\:hover\:tw-via-blue-500:hover {
    --gradient-via-color: #4299e1;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(66, 153, 225, 0));
  }

  .lg\:hover\:tw-via-blue-600:hover {
    --gradient-via-color: #3182ce;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(49, 130, 206, 0));
  }

  .lg\:hover\:tw-via-blue-700:hover {
    --gradient-via-color: #2b6cb0;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(43, 108, 176, 0));
  }

  .lg\:hover\:tw-via-blue-800:hover {
    --gradient-via-color: #2c5282;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(44, 82, 130, 0));
  }

  .lg\:hover\:tw-via-blue-900:hover {
    --gradient-via-color: #2a4365;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(42, 67, 101, 0));
  }

  .lg\:hover\:tw-via-indigo-100:hover {
    --gradient-via-color: #ebf4ff;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(235, 244, 255, 0));
  }

  .lg\:hover\:tw-via-indigo-200:hover {
    --gradient-via-color: #c3dafe;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(195, 218, 254, 0));
  }

  .lg\:hover\:tw-via-indigo-300:hover {
    --gradient-via-color: #a3bffa;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(163, 191, 250, 0));
  }

  .lg\:hover\:tw-via-indigo-400:hover {
    --gradient-via-color: #7f9cf5;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(127, 156, 245, 0));
  }

  .lg\:hover\:tw-via-indigo-500:hover {
    --gradient-via-color: #667eea;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(102, 126, 234, 0));
  }

  .lg\:hover\:tw-via-indigo-600:hover {
    --gradient-via-color: #5a67d8;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(90, 103, 216, 0));
  }

  .lg\:hover\:tw-via-indigo-700:hover {
    --gradient-via-color: #4c51bf;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(76, 81, 191, 0));
  }

  .lg\:hover\:tw-via-indigo-800:hover {
    --gradient-via-color: #434190;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(67, 65, 144, 0));
  }

  .lg\:hover\:tw-via-indigo-900:hover {
    --gradient-via-color: #3c366b;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(60, 54, 107, 0));
  }

  .lg\:hover\:tw-via-purple-100:hover {
    --gradient-via-color: #faf5ff;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(250, 245, 255, 0));
  }

  .lg\:hover\:tw-via-purple-200:hover {
    --gradient-via-color: #e9d8fd;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(233, 216, 253, 0));
  }

  .lg\:hover\:tw-via-purple-300:hover {
    --gradient-via-color: #d6bcfa;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(214, 188, 250, 0));
  }

  .lg\:hover\:tw-via-purple-400:hover {
    --gradient-via-color: #b794f4;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(183, 148, 244, 0));
  }

  .lg\:hover\:tw-via-purple-500:hover {
    --gradient-via-color: #9f7aea;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(159, 122, 234, 0));
  }

  .lg\:hover\:tw-via-purple-600:hover {
    --gradient-via-color: #805ad5;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(128, 90, 213, 0));
  }

  .lg\:hover\:tw-via-purple-700:hover {
    --gradient-via-color: #6b46c1;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(107, 70, 193, 0));
  }

  .lg\:hover\:tw-via-purple-800:hover {
    --gradient-via-color: #553c9a;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(85, 60, 154, 0));
  }

  .lg\:hover\:tw-via-purple-900:hover {
    --gradient-via-color: #44337a;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(68, 51, 122, 0));
  }

  .lg\:hover\:tw-via-pink-100:hover {
    --gradient-via-color: #fff5f7;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 245, 247, 0));
  }

  .lg\:hover\:tw-via-pink-200:hover {
    --gradient-via-color: #fed7e2;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(254, 215, 226, 0));
  }

  .lg\:hover\:tw-via-pink-300:hover {
    --gradient-via-color: #fbb6ce;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(251, 182, 206, 0));
  }

  .lg\:hover\:tw-via-pink-400:hover {
    --gradient-via-color: #f687b3;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(246, 135, 179, 0));
  }

  .lg\:hover\:tw-via-pink-500:hover {
    --gradient-via-color: #ed64a6;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(237, 100, 166, 0));
  }

  .lg\:hover\:tw-via-pink-600:hover {
    --gradient-via-color: #d53f8c;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(213, 63, 140, 0));
  }

  .lg\:hover\:tw-via-pink-700:hover {
    --gradient-via-color: #b83280;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(184, 50, 128, 0));
  }

  .lg\:hover\:tw-via-pink-800:hover {
    --gradient-via-color: #97266d;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(151, 38, 109, 0));
  }

  .lg\:hover\:tw-via-pink-900:hover {
    --gradient-via-color: #702459;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(112, 36, 89, 0));
  }

  .lg\:hover\:tw-to-transparent:hover {
    --gradient-to-color: transparent;
  }

  .lg\:hover\:tw-to-current:hover {
    --gradient-to-color: currentColor;
  }

  .lg\:hover\:tw-to-black:hover {
    --gradient-to-color: #000;
  }

  .lg\:hover\:tw-to-white:hover {
    --gradient-to-color: #fff;
  }

  .lg\:hover\:tw-to-gray-100:hover {
    --gradient-to-color: #f7fafc;
  }

  .lg\:hover\:tw-to-gray-200:hover {
    --gradient-to-color: #edf2f7;
  }

  .lg\:hover\:tw-to-gray-300:hover {
    --gradient-to-color: #e2e8f0;
  }

  .lg\:hover\:tw-to-gray-400:hover {
    --gradient-to-color: #cbd5e0;
  }

  .lg\:hover\:tw-to-gray-500:hover {
    --gradient-to-color: #a0aec0;
  }

  .lg\:hover\:tw-to-gray-600:hover {
    --gradient-to-color: #718096;
  }

  .lg\:hover\:tw-to-gray-700:hover {
    --gradient-to-color: #4a5568;
  }

  .lg\:hover\:tw-to-gray-800:hover {
    --gradient-to-color: #2d3748;
  }

  .lg\:hover\:tw-to-gray-900:hover {
    --gradient-to-color: #1a202c;
  }

  .lg\:hover\:tw-to-red-100:hover {
    --gradient-to-color: #fff5f5;
  }

  .lg\:hover\:tw-to-red-200:hover {
    --gradient-to-color: #fed7d7;
  }

  .lg\:hover\:tw-to-red-300:hover {
    --gradient-to-color: #feb2b2;
  }

  .lg\:hover\:tw-to-red-400:hover {
    --gradient-to-color: #fc8181;
  }

  .lg\:hover\:tw-to-red-500:hover {
    --gradient-to-color: #f56565;
  }

  .lg\:hover\:tw-to-red-600:hover {
    --gradient-to-color: #e53e3e;
  }

  .lg\:hover\:tw-to-red-700:hover {
    --gradient-to-color: #c53030;
  }

  .lg\:hover\:tw-to-red-800:hover {
    --gradient-to-color: #9b2c2c;
  }

  .lg\:hover\:tw-to-red-900:hover {
    --gradient-to-color: #742a2a;
  }

  .lg\:hover\:tw-to-orange-100:hover {
    --gradient-to-color: #fffaf0;
  }

  .lg\:hover\:tw-to-orange-200:hover {
    --gradient-to-color: #feebc8;
  }

  .lg\:hover\:tw-to-orange-300:hover {
    --gradient-to-color: #fbd38d;
  }

  .lg\:hover\:tw-to-orange-400:hover {
    --gradient-to-color: #f6ad55;
  }

  .lg\:hover\:tw-to-orange-500:hover {
    --gradient-to-color: #ed8936;
  }

  .lg\:hover\:tw-to-orange-600:hover {
    --gradient-to-color: #dd6b20;
  }

  .lg\:hover\:tw-to-orange-700:hover {
    --gradient-to-color: #c05621;
  }

  .lg\:hover\:tw-to-orange-800:hover {
    --gradient-to-color: #9c4221;
  }

  .lg\:hover\:tw-to-orange-900:hover {
    --gradient-to-color: #7b341e;
  }

  .lg\:hover\:tw-to-yellow-100:hover {
    --gradient-to-color: #fffff0;
  }

  .lg\:hover\:tw-to-yellow-200:hover {
    --gradient-to-color: #fefcbf;
  }

  .lg\:hover\:tw-to-yellow-300:hover {
    --gradient-to-color: #faf089;
  }

  .lg\:hover\:tw-to-yellow-400:hover {
    --gradient-to-color: #f6e05e;
  }

  .lg\:hover\:tw-to-yellow-500:hover {
    --gradient-to-color: #ecc94b;
  }

  .lg\:hover\:tw-to-yellow-600:hover {
    --gradient-to-color: #d69e2e;
  }

  .lg\:hover\:tw-to-yellow-700:hover {
    --gradient-to-color: #b7791f;
  }

  .lg\:hover\:tw-to-yellow-800:hover {
    --gradient-to-color: #975a16;
  }

  .lg\:hover\:tw-to-yellow-900:hover {
    --gradient-to-color: #744210;
  }

  .lg\:hover\:tw-to-green-100:hover {
    --gradient-to-color: #f0fff4;
  }

  .lg\:hover\:tw-to-green-200:hover {
    --gradient-to-color: #c6f6d5;
  }

  .lg\:hover\:tw-to-green-300:hover {
    --gradient-to-color: #9ae6b4;
  }

  .lg\:hover\:tw-to-green-400:hover {
    --gradient-to-color: #68d391;
  }

  .lg\:hover\:tw-to-green-500:hover {
    --gradient-to-color: #48bb78;
  }

  .lg\:hover\:tw-to-green-600:hover {
    --gradient-to-color: #38a169;
  }

  .lg\:hover\:tw-to-green-700:hover {
    --gradient-to-color: #2f855a;
  }

  .lg\:hover\:tw-to-green-800:hover {
    --gradient-to-color: #276749;
  }

  .lg\:hover\:tw-to-green-900:hover {
    --gradient-to-color: #22543d;
  }

  .lg\:hover\:tw-to-teal-100:hover {
    --gradient-to-color: #e6fffa;
  }

  .lg\:hover\:tw-to-teal-200:hover {
    --gradient-to-color: #b2f5ea;
  }

  .lg\:hover\:tw-to-teal-300:hover {
    --gradient-to-color: #81e6d9;
  }

  .lg\:hover\:tw-to-teal-400:hover {
    --gradient-to-color: #4fd1c5;
  }

  .lg\:hover\:tw-to-teal-500:hover {
    --gradient-to-color: #38b2ac;
  }

  .lg\:hover\:tw-to-teal-600:hover {
    --gradient-to-color: #319795;
  }

  .lg\:hover\:tw-to-teal-700:hover {
    --gradient-to-color: #2c7a7b;
  }

  .lg\:hover\:tw-to-teal-800:hover {
    --gradient-to-color: #285e61;
  }

  .lg\:hover\:tw-to-teal-900:hover {
    --gradient-to-color: #234e52;
  }

  .lg\:hover\:tw-to-blue-100:hover {
    --gradient-to-color: #ebf8ff;
  }

  .lg\:hover\:tw-to-blue-200:hover {
    --gradient-to-color: #bee3f8;
  }

  .lg\:hover\:tw-to-blue-300:hover {
    --gradient-to-color: #90cdf4;
  }

  .lg\:hover\:tw-to-blue-400:hover {
    --gradient-to-color: #63b3ed;
  }

  .lg\:hover\:tw-to-blue-500:hover {
    --gradient-to-color: #4299e1;
  }

  .lg\:hover\:tw-to-blue-600:hover {
    --gradient-to-color: #3182ce;
  }

  .lg\:hover\:tw-to-blue-700:hover {
    --gradient-to-color: #2b6cb0;
  }

  .lg\:hover\:tw-to-blue-800:hover {
    --gradient-to-color: #2c5282;
  }

  .lg\:hover\:tw-to-blue-900:hover {
    --gradient-to-color: #2a4365;
  }

  .lg\:hover\:tw-to-indigo-100:hover {
    --gradient-to-color: #ebf4ff;
  }

  .lg\:hover\:tw-to-indigo-200:hover {
    --gradient-to-color: #c3dafe;
  }

  .lg\:hover\:tw-to-indigo-300:hover {
    --gradient-to-color: #a3bffa;
  }

  .lg\:hover\:tw-to-indigo-400:hover {
    --gradient-to-color: #7f9cf5;
  }

  .lg\:hover\:tw-to-indigo-500:hover {
    --gradient-to-color: #667eea;
  }

  .lg\:hover\:tw-to-indigo-600:hover {
    --gradient-to-color: #5a67d8;
  }

  .lg\:hover\:tw-to-indigo-700:hover {
    --gradient-to-color: #4c51bf;
  }

  .lg\:hover\:tw-to-indigo-800:hover {
    --gradient-to-color: #434190;
  }

  .lg\:hover\:tw-to-indigo-900:hover {
    --gradient-to-color: #3c366b;
  }

  .lg\:hover\:tw-to-purple-100:hover {
    --gradient-to-color: #faf5ff;
  }

  .lg\:hover\:tw-to-purple-200:hover {
    --gradient-to-color: #e9d8fd;
  }

  .lg\:hover\:tw-to-purple-300:hover {
    --gradient-to-color: #d6bcfa;
  }

  .lg\:hover\:tw-to-purple-400:hover {
    --gradient-to-color: #b794f4;
  }

  .lg\:hover\:tw-to-purple-500:hover {
    --gradient-to-color: #9f7aea;
  }

  .lg\:hover\:tw-to-purple-600:hover {
    --gradient-to-color: #805ad5;
  }

  .lg\:hover\:tw-to-purple-700:hover {
    --gradient-to-color: #6b46c1;
  }

  .lg\:hover\:tw-to-purple-800:hover {
    --gradient-to-color: #553c9a;
  }

  .lg\:hover\:tw-to-purple-900:hover {
    --gradient-to-color: #44337a;
  }

  .lg\:hover\:tw-to-pink-100:hover {
    --gradient-to-color: #fff5f7;
  }

  .lg\:hover\:tw-to-pink-200:hover {
    --gradient-to-color: #fed7e2;
  }

  .lg\:hover\:tw-to-pink-300:hover {
    --gradient-to-color: #fbb6ce;
  }

  .lg\:hover\:tw-to-pink-400:hover {
    --gradient-to-color: #f687b3;
  }

  .lg\:hover\:tw-to-pink-500:hover {
    --gradient-to-color: #ed64a6;
  }

  .lg\:hover\:tw-to-pink-600:hover {
    --gradient-to-color: #d53f8c;
  }

  .lg\:hover\:tw-to-pink-700:hover {
    --gradient-to-color: #b83280;
  }

  .lg\:hover\:tw-to-pink-800:hover {
    --gradient-to-color: #97266d;
  }

  .lg\:hover\:tw-to-pink-900:hover {
    --gradient-to-color: #702459;
  }

  .lg\:focus\:tw-from-transparent:focus {
    --gradient-from-color: transparent;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(0, 0, 0, 0));
  }

  .lg\:focus\:tw-from-current:focus {
    --gradient-from-color: currentColor;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0));
  }

  .lg\:focus\:tw-from-black:focus {
    --gradient-from-color: #000;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(0, 0, 0, 0));
  }

  .lg\:focus\:tw-from-white:focus {
    --gradient-from-color: #fff;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0));
  }

  .lg\:focus\:tw-from-gray-100:focus {
    --gradient-from-color: #f7fafc;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(247, 250, 252, 0));
  }

  .lg\:focus\:tw-from-gray-200:focus {
    --gradient-from-color: #edf2f7;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(237, 242, 247, 0));
  }

  .lg\:focus\:tw-from-gray-300:focus {
    --gradient-from-color: #e2e8f0;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(226, 232, 240, 0));
  }

  .lg\:focus\:tw-from-gray-400:focus {
    --gradient-from-color: #cbd5e0;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(203, 213, 224, 0));
  }

  .lg\:focus\:tw-from-gray-500:focus {
    --gradient-from-color: #a0aec0;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(160, 174, 192, 0));
  }

  .lg\:focus\:tw-from-gray-600:focus {
    --gradient-from-color: #718096;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(113, 128, 150, 0));
  }

  .lg\:focus\:tw-from-gray-700:focus {
    --gradient-from-color: #4a5568;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(74, 85, 104, 0));
  }

  .lg\:focus\:tw-from-gray-800:focus {
    --gradient-from-color: #2d3748;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(45, 55, 72, 0));
  }

  .lg\:focus\:tw-from-gray-900:focus {
    --gradient-from-color: #1a202c;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(26, 32, 44, 0));
  }

  .lg\:focus\:tw-from-red-100:focus {
    --gradient-from-color: #fff5f5;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 245, 245, 0));
  }

  .lg\:focus\:tw-from-red-200:focus {
    --gradient-from-color: #fed7d7;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(254, 215, 215, 0));
  }

  .lg\:focus\:tw-from-red-300:focus {
    --gradient-from-color: #feb2b2;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(254, 178, 178, 0));
  }

  .lg\:focus\:tw-from-red-400:focus {
    --gradient-from-color: #fc8181;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(252, 129, 129, 0));
  }

  .lg\:focus\:tw-from-red-500:focus {
    --gradient-from-color: #f56565;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(245, 101, 101, 0));
  }

  .lg\:focus\:tw-from-red-600:focus {
    --gradient-from-color: #e53e3e;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(229, 62, 62, 0));
  }

  .lg\:focus\:tw-from-red-700:focus {
    --gradient-from-color: #c53030;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(197, 48, 48, 0));
  }

  .lg\:focus\:tw-from-red-800:focus {
    --gradient-from-color: #9b2c2c;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(155, 44, 44, 0));
  }

  .lg\:focus\:tw-from-red-900:focus {
    --gradient-from-color: #742a2a;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(116, 42, 42, 0));
  }

  .lg\:focus\:tw-from-orange-100:focus {
    --gradient-from-color: #fffaf0;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 250, 240, 0));
  }

  .lg\:focus\:tw-from-orange-200:focus {
    --gradient-from-color: #feebc8;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(254, 235, 200, 0));
  }

  .lg\:focus\:tw-from-orange-300:focus {
    --gradient-from-color: #fbd38d;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(251, 211, 141, 0));
  }

  .lg\:focus\:tw-from-orange-400:focus {
    --gradient-from-color: #f6ad55;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(246, 173, 85, 0));
  }

  .lg\:focus\:tw-from-orange-500:focus {
    --gradient-from-color: #ed8936;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(237, 137, 54, 0));
  }

  .lg\:focus\:tw-from-orange-600:focus {
    --gradient-from-color: #dd6b20;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(221, 107, 32, 0));
  }

  .lg\:focus\:tw-from-orange-700:focus {
    --gradient-from-color: #c05621;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(192, 86, 33, 0));
  }

  .lg\:focus\:tw-from-orange-800:focus {
    --gradient-from-color: #9c4221;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(156, 66, 33, 0));
  }

  .lg\:focus\:tw-from-orange-900:focus {
    --gradient-from-color: #7b341e;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(123, 52, 30, 0));
  }

  .lg\:focus\:tw-from-yellow-100:focus {
    --gradient-from-color: #fffff0;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 240, 0));
  }

  .lg\:focus\:tw-from-yellow-200:focus {
    --gradient-from-color: #fefcbf;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(254, 252, 191, 0));
  }

  .lg\:focus\:tw-from-yellow-300:focus {
    --gradient-from-color: #faf089;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(250, 240, 137, 0));
  }

  .lg\:focus\:tw-from-yellow-400:focus {
    --gradient-from-color: #f6e05e;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(246, 224, 94, 0));
  }

  .lg\:focus\:tw-from-yellow-500:focus {
    --gradient-from-color: #ecc94b;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(236, 201, 75, 0));
  }

  .lg\:focus\:tw-from-yellow-600:focus {
    --gradient-from-color: #d69e2e;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(214, 158, 46, 0));
  }

  .lg\:focus\:tw-from-yellow-700:focus {
    --gradient-from-color: #b7791f;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(183, 121, 31, 0));
  }

  .lg\:focus\:tw-from-yellow-800:focus {
    --gradient-from-color: #975a16;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(151, 90, 22, 0));
  }

  .lg\:focus\:tw-from-yellow-900:focus {
    --gradient-from-color: #744210;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(116, 66, 16, 0));
  }

  .lg\:focus\:tw-from-green-100:focus {
    --gradient-from-color: #f0fff4;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(240, 255, 244, 0));
  }

  .lg\:focus\:tw-from-green-200:focus {
    --gradient-from-color: #c6f6d5;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(198, 246, 213, 0));
  }

  .lg\:focus\:tw-from-green-300:focus {
    --gradient-from-color: #9ae6b4;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(154, 230, 180, 0));
  }

  .lg\:focus\:tw-from-green-400:focus {
    --gradient-from-color: #68d391;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(104, 211, 145, 0));
  }

  .lg\:focus\:tw-from-green-500:focus {
    --gradient-from-color: #48bb78;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(72, 187, 120, 0));
  }

  .lg\:focus\:tw-from-green-600:focus {
    --gradient-from-color: #38a169;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(56, 161, 105, 0));
  }

  .lg\:focus\:tw-from-green-700:focus {
    --gradient-from-color: #2f855a;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(47, 133, 90, 0));
  }

  .lg\:focus\:tw-from-green-800:focus {
    --gradient-from-color: #276749;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(39, 103, 73, 0));
  }

  .lg\:focus\:tw-from-green-900:focus {
    --gradient-from-color: #22543d;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(34, 84, 61, 0));
  }

  .lg\:focus\:tw-from-teal-100:focus {
    --gradient-from-color: #e6fffa;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(230, 255, 250, 0));
  }

  .lg\:focus\:tw-from-teal-200:focus {
    --gradient-from-color: #b2f5ea;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(178, 245, 234, 0));
  }

  .lg\:focus\:tw-from-teal-300:focus {
    --gradient-from-color: #81e6d9;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(129, 230, 217, 0));
  }

  .lg\:focus\:tw-from-teal-400:focus {
    --gradient-from-color: #4fd1c5;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(79, 209, 197, 0));
  }

  .lg\:focus\:tw-from-teal-500:focus {
    --gradient-from-color: #38b2ac;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(56, 178, 172, 0));
  }

  .lg\:focus\:tw-from-teal-600:focus {
    --gradient-from-color: #319795;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(49, 151, 149, 0));
  }

  .lg\:focus\:tw-from-teal-700:focus {
    --gradient-from-color: #2c7a7b;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(44, 122, 123, 0));
  }

  .lg\:focus\:tw-from-teal-800:focus {
    --gradient-from-color: #285e61;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(40, 94, 97, 0));
  }

  .lg\:focus\:tw-from-teal-900:focus {
    --gradient-from-color: #234e52;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(35, 78, 82, 0));
  }

  .lg\:focus\:tw-from-blue-100:focus {
    --gradient-from-color: #ebf8ff;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(235, 248, 255, 0));
  }

  .lg\:focus\:tw-from-blue-200:focus {
    --gradient-from-color: #bee3f8;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(190, 227, 248, 0));
  }

  .lg\:focus\:tw-from-blue-300:focus {
    --gradient-from-color: #90cdf4;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(144, 205, 244, 0));
  }

  .lg\:focus\:tw-from-blue-400:focus {
    --gradient-from-color: #63b3ed;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(99, 179, 237, 0));
  }

  .lg\:focus\:tw-from-blue-500:focus {
    --gradient-from-color: #4299e1;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(66, 153, 225, 0));
  }

  .lg\:focus\:tw-from-blue-600:focus {
    --gradient-from-color: #3182ce;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(49, 130, 206, 0));
  }

  .lg\:focus\:tw-from-blue-700:focus {
    --gradient-from-color: #2b6cb0;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(43, 108, 176, 0));
  }

  .lg\:focus\:tw-from-blue-800:focus {
    --gradient-from-color: #2c5282;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(44, 82, 130, 0));
  }

  .lg\:focus\:tw-from-blue-900:focus {
    --gradient-from-color: #2a4365;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(42, 67, 101, 0));
  }

  .lg\:focus\:tw-from-indigo-100:focus {
    --gradient-from-color: #ebf4ff;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(235, 244, 255, 0));
  }

  .lg\:focus\:tw-from-indigo-200:focus {
    --gradient-from-color: #c3dafe;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(195, 218, 254, 0));
  }

  .lg\:focus\:tw-from-indigo-300:focus {
    --gradient-from-color: #a3bffa;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(163, 191, 250, 0));
  }

  .lg\:focus\:tw-from-indigo-400:focus {
    --gradient-from-color: #7f9cf5;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(127, 156, 245, 0));
  }

  .lg\:focus\:tw-from-indigo-500:focus {
    --gradient-from-color: #667eea;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(102, 126, 234, 0));
  }

  .lg\:focus\:tw-from-indigo-600:focus {
    --gradient-from-color: #5a67d8;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(90, 103, 216, 0));
  }

  .lg\:focus\:tw-from-indigo-700:focus {
    --gradient-from-color: #4c51bf;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(76, 81, 191, 0));
  }

  .lg\:focus\:tw-from-indigo-800:focus {
    --gradient-from-color: #434190;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(67, 65, 144, 0));
  }

  .lg\:focus\:tw-from-indigo-900:focus {
    --gradient-from-color: #3c366b;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(60, 54, 107, 0));
  }

  .lg\:focus\:tw-from-purple-100:focus {
    --gradient-from-color: #faf5ff;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(250, 245, 255, 0));
  }

  .lg\:focus\:tw-from-purple-200:focus {
    --gradient-from-color: #e9d8fd;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(233, 216, 253, 0));
  }

  .lg\:focus\:tw-from-purple-300:focus {
    --gradient-from-color: #d6bcfa;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(214, 188, 250, 0));
  }

  .lg\:focus\:tw-from-purple-400:focus {
    --gradient-from-color: #b794f4;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(183, 148, 244, 0));
  }

  .lg\:focus\:tw-from-purple-500:focus {
    --gradient-from-color: #9f7aea;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(159, 122, 234, 0));
  }

  .lg\:focus\:tw-from-purple-600:focus {
    --gradient-from-color: #805ad5;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(128, 90, 213, 0));
  }

  .lg\:focus\:tw-from-purple-700:focus {
    --gradient-from-color: #6b46c1;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(107, 70, 193, 0));
  }

  .lg\:focus\:tw-from-purple-800:focus {
    --gradient-from-color: #553c9a;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(85, 60, 154, 0));
  }

  .lg\:focus\:tw-from-purple-900:focus {
    --gradient-from-color: #44337a;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(68, 51, 122, 0));
  }

  .lg\:focus\:tw-from-pink-100:focus {
    --gradient-from-color: #fff5f7;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 245, 247, 0));
  }

  .lg\:focus\:tw-from-pink-200:focus {
    --gradient-from-color: #fed7e2;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(254, 215, 226, 0));
  }

  .lg\:focus\:tw-from-pink-300:focus {
    --gradient-from-color: #fbb6ce;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(251, 182, 206, 0));
  }

  .lg\:focus\:tw-from-pink-400:focus {
    --gradient-from-color: #f687b3;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(246, 135, 179, 0));
  }

  .lg\:focus\:tw-from-pink-500:focus {
    --gradient-from-color: #ed64a6;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(237, 100, 166, 0));
  }

  .lg\:focus\:tw-from-pink-600:focus {
    --gradient-from-color: #d53f8c;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(213, 63, 140, 0));
  }

  .lg\:focus\:tw-from-pink-700:focus {
    --gradient-from-color: #b83280;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(184, 50, 128, 0));
  }

  .lg\:focus\:tw-from-pink-800:focus {
    --gradient-from-color: #97266d;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(151, 38, 109, 0));
  }

  .lg\:focus\:tw-from-pink-900:focus {
    --gradient-from-color: #702459;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(112, 36, 89, 0));
  }

  .lg\:focus\:tw-via-transparent:focus {
    --gradient-via-color: transparent;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(0, 0, 0, 0));
  }

  .lg\:focus\:tw-via-current:focus {
    --gradient-via-color: currentColor;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0));
  }

  .lg\:focus\:tw-via-black:focus {
    --gradient-via-color: #000;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(0, 0, 0, 0));
  }

  .lg\:focus\:tw-via-white:focus {
    --gradient-via-color: #fff;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0));
  }

  .lg\:focus\:tw-via-gray-100:focus {
    --gradient-via-color: #f7fafc;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(247, 250, 252, 0));
  }

  .lg\:focus\:tw-via-gray-200:focus {
    --gradient-via-color: #edf2f7;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(237, 242, 247, 0));
  }

  .lg\:focus\:tw-via-gray-300:focus {
    --gradient-via-color: #e2e8f0;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(226, 232, 240, 0));
  }

  .lg\:focus\:tw-via-gray-400:focus {
    --gradient-via-color: #cbd5e0;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(203, 213, 224, 0));
  }

  .lg\:focus\:tw-via-gray-500:focus {
    --gradient-via-color: #a0aec0;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(160, 174, 192, 0));
  }

  .lg\:focus\:tw-via-gray-600:focus {
    --gradient-via-color: #718096;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(113, 128, 150, 0));
  }

  .lg\:focus\:tw-via-gray-700:focus {
    --gradient-via-color: #4a5568;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(74, 85, 104, 0));
  }

  .lg\:focus\:tw-via-gray-800:focus {
    --gradient-via-color: #2d3748;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(45, 55, 72, 0));
  }

  .lg\:focus\:tw-via-gray-900:focus {
    --gradient-via-color: #1a202c;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(26, 32, 44, 0));
  }

  .lg\:focus\:tw-via-red-100:focus {
    --gradient-via-color: #fff5f5;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 245, 245, 0));
  }

  .lg\:focus\:tw-via-red-200:focus {
    --gradient-via-color: #fed7d7;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(254, 215, 215, 0));
  }

  .lg\:focus\:tw-via-red-300:focus {
    --gradient-via-color: #feb2b2;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(254, 178, 178, 0));
  }

  .lg\:focus\:tw-via-red-400:focus {
    --gradient-via-color: #fc8181;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(252, 129, 129, 0));
  }

  .lg\:focus\:tw-via-red-500:focus {
    --gradient-via-color: #f56565;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(245, 101, 101, 0));
  }

  .lg\:focus\:tw-via-red-600:focus {
    --gradient-via-color: #e53e3e;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(229, 62, 62, 0));
  }

  .lg\:focus\:tw-via-red-700:focus {
    --gradient-via-color: #c53030;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(197, 48, 48, 0));
  }

  .lg\:focus\:tw-via-red-800:focus {
    --gradient-via-color: #9b2c2c;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(155, 44, 44, 0));
  }

  .lg\:focus\:tw-via-red-900:focus {
    --gradient-via-color: #742a2a;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(116, 42, 42, 0));
  }

  .lg\:focus\:tw-via-orange-100:focus {
    --gradient-via-color: #fffaf0;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 250, 240, 0));
  }

  .lg\:focus\:tw-via-orange-200:focus {
    --gradient-via-color: #feebc8;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(254, 235, 200, 0));
  }

  .lg\:focus\:tw-via-orange-300:focus {
    --gradient-via-color: #fbd38d;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(251, 211, 141, 0));
  }

  .lg\:focus\:tw-via-orange-400:focus {
    --gradient-via-color: #f6ad55;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(246, 173, 85, 0));
  }

  .lg\:focus\:tw-via-orange-500:focus {
    --gradient-via-color: #ed8936;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(237, 137, 54, 0));
  }

  .lg\:focus\:tw-via-orange-600:focus {
    --gradient-via-color: #dd6b20;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(221, 107, 32, 0));
  }

  .lg\:focus\:tw-via-orange-700:focus {
    --gradient-via-color: #c05621;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(192, 86, 33, 0));
  }

  .lg\:focus\:tw-via-orange-800:focus {
    --gradient-via-color: #9c4221;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(156, 66, 33, 0));
  }

  .lg\:focus\:tw-via-orange-900:focus {
    --gradient-via-color: #7b341e;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(123, 52, 30, 0));
  }

  .lg\:focus\:tw-via-yellow-100:focus {
    --gradient-via-color: #fffff0;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 240, 0));
  }

  .lg\:focus\:tw-via-yellow-200:focus {
    --gradient-via-color: #fefcbf;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(254, 252, 191, 0));
  }

  .lg\:focus\:tw-via-yellow-300:focus {
    --gradient-via-color: #faf089;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(250, 240, 137, 0));
  }

  .lg\:focus\:tw-via-yellow-400:focus {
    --gradient-via-color: #f6e05e;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(246, 224, 94, 0));
  }

  .lg\:focus\:tw-via-yellow-500:focus {
    --gradient-via-color: #ecc94b;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(236, 201, 75, 0));
  }

  .lg\:focus\:tw-via-yellow-600:focus {
    --gradient-via-color: #d69e2e;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(214, 158, 46, 0));
  }

  .lg\:focus\:tw-via-yellow-700:focus {
    --gradient-via-color: #b7791f;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(183, 121, 31, 0));
  }

  .lg\:focus\:tw-via-yellow-800:focus {
    --gradient-via-color: #975a16;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(151, 90, 22, 0));
  }

  .lg\:focus\:tw-via-yellow-900:focus {
    --gradient-via-color: #744210;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(116, 66, 16, 0));
  }

  .lg\:focus\:tw-via-green-100:focus {
    --gradient-via-color: #f0fff4;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(240, 255, 244, 0));
  }

  .lg\:focus\:tw-via-green-200:focus {
    --gradient-via-color: #c6f6d5;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(198, 246, 213, 0));
  }

  .lg\:focus\:tw-via-green-300:focus {
    --gradient-via-color: #9ae6b4;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(154, 230, 180, 0));
  }

  .lg\:focus\:tw-via-green-400:focus {
    --gradient-via-color: #68d391;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(104, 211, 145, 0));
  }

  .lg\:focus\:tw-via-green-500:focus {
    --gradient-via-color: #48bb78;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(72, 187, 120, 0));
  }

  .lg\:focus\:tw-via-green-600:focus {
    --gradient-via-color: #38a169;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(56, 161, 105, 0));
  }

  .lg\:focus\:tw-via-green-700:focus {
    --gradient-via-color: #2f855a;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(47, 133, 90, 0));
  }

  .lg\:focus\:tw-via-green-800:focus {
    --gradient-via-color: #276749;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(39, 103, 73, 0));
  }

  .lg\:focus\:tw-via-green-900:focus {
    --gradient-via-color: #22543d;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(34, 84, 61, 0));
  }

  .lg\:focus\:tw-via-teal-100:focus {
    --gradient-via-color: #e6fffa;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(230, 255, 250, 0));
  }

  .lg\:focus\:tw-via-teal-200:focus {
    --gradient-via-color: #b2f5ea;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(178, 245, 234, 0));
  }

  .lg\:focus\:tw-via-teal-300:focus {
    --gradient-via-color: #81e6d9;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(129, 230, 217, 0));
  }

  .lg\:focus\:tw-via-teal-400:focus {
    --gradient-via-color: #4fd1c5;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(79, 209, 197, 0));
  }

  .lg\:focus\:tw-via-teal-500:focus {
    --gradient-via-color: #38b2ac;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(56, 178, 172, 0));
  }

  .lg\:focus\:tw-via-teal-600:focus {
    --gradient-via-color: #319795;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(49, 151, 149, 0));
  }

  .lg\:focus\:tw-via-teal-700:focus {
    --gradient-via-color: #2c7a7b;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(44, 122, 123, 0));
  }

  .lg\:focus\:tw-via-teal-800:focus {
    --gradient-via-color: #285e61;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(40, 94, 97, 0));
  }

  .lg\:focus\:tw-via-teal-900:focus {
    --gradient-via-color: #234e52;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(35, 78, 82, 0));
  }

  .lg\:focus\:tw-via-blue-100:focus {
    --gradient-via-color: #ebf8ff;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(235, 248, 255, 0));
  }

  .lg\:focus\:tw-via-blue-200:focus {
    --gradient-via-color: #bee3f8;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(190, 227, 248, 0));
  }

  .lg\:focus\:tw-via-blue-300:focus {
    --gradient-via-color: #90cdf4;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(144, 205, 244, 0));
  }

  .lg\:focus\:tw-via-blue-400:focus {
    --gradient-via-color: #63b3ed;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(99, 179, 237, 0));
  }

  .lg\:focus\:tw-via-blue-500:focus {
    --gradient-via-color: #4299e1;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(66, 153, 225, 0));
  }

  .lg\:focus\:tw-via-blue-600:focus {
    --gradient-via-color: #3182ce;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(49, 130, 206, 0));
  }

  .lg\:focus\:tw-via-blue-700:focus {
    --gradient-via-color: #2b6cb0;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(43, 108, 176, 0));
  }

  .lg\:focus\:tw-via-blue-800:focus {
    --gradient-via-color: #2c5282;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(44, 82, 130, 0));
  }

  .lg\:focus\:tw-via-blue-900:focus {
    --gradient-via-color: #2a4365;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(42, 67, 101, 0));
  }

  .lg\:focus\:tw-via-indigo-100:focus {
    --gradient-via-color: #ebf4ff;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(235, 244, 255, 0));
  }

  .lg\:focus\:tw-via-indigo-200:focus {
    --gradient-via-color: #c3dafe;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(195, 218, 254, 0));
  }

  .lg\:focus\:tw-via-indigo-300:focus {
    --gradient-via-color: #a3bffa;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(163, 191, 250, 0));
  }

  .lg\:focus\:tw-via-indigo-400:focus {
    --gradient-via-color: #7f9cf5;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(127, 156, 245, 0));
  }

  .lg\:focus\:tw-via-indigo-500:focus {
    --gradient-via-color: #667eea;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(102, 126, 234, 0));
  }

  .lg\:focus\:tw-via-indigo-600:focus {
    --gradient-via-color: #5a67d8;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(90, 103, 216, 0));
  }

  .lg\:focus\:tw-via-indigo-700:focus {
    --gradient-via-color: #4c51bf;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(76, 81, 191, 0));
  }

  .lg\:focus\:tw-via-indigo-800:focus {
    --gradient-via-color: #434190;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(67, 65, 144, 0));
  }

  .lg\:focus\:tw-via-indigo-900:focus {
    --gradient-via-color: #3c366b;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(60, 54, 107, 0));
  }

  .lg\:focus\:tw-via-purple-100:focus {
    --gradient-via-color: #faf5ff;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(250, 245, 255, 0));
  }

  .lg\:focus\:tw-via-purple-200:focus {
    --gradient-via-color: #e9d8fd;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(233, 216, 253, 0));
  }

  .lg\:focus\:tw-via-purple-300:focus {
    --gradient-via-color: #d6bcfa;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(214, 188, 250, 0));
  }

  .lg\:focus\:tw-via-purple-400:focus {
    --gradient-via-color: #b794f4;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(183, 148, 244, 0));
  }

  .lg\:focus\:tw-via-purple-500:focus {
    --gradient-via-color: #9f7aea;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(159, 122, 234, 0));
  }

  .lg\:focus\:tw-via-purple-600:focus {
    --gradient-via-color: #805ad5;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(128, 90, 213, 0));
  }

  .lg\:focus\:tw-via-purple-700:focus {
    --gradient-via-color: #6b46c1;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(107, 70, 193, 0));
  }

  .lg\:focus\:tw-via-purple-800:focus {
    --gradient-via-color: #553c9a;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(85, 60, 154, 0));
  }

  .lg\:focus\:tw-via-purple-900:focus {
    --gradient-via-color: #44337a;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(68, 51, 122, 0));
  }

  .lg\:focus\:tw-via-pink-100:focus {
    --gradient-via-color: #fff5f7;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 245, 247, 0));
  }

  .lg\:focus\:tw-via-pink-200:focus {
    --gradient-via-color: #fed7e2;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(254, 215, 226, 0));
  }

  .lg\:focus\:tw-via-pink-300:focus {
    --gradient-via-color: #fbb6ce;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(251, 182, 206, 0));
  }

  .lg\:focus\:tw-via-pink-400:focus {
    --gradient-via-color: #f687b3;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(246, 135, 179, 0));
  }

  .lg\:focus\:tw-via-pink-500:focus {
    --gradient-via-color: #ed64a6;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(237, 100, 166, 0));
  }

  .lg\:focus\:tw-via-pink-600:focus {
    --gradient-via-color: #d53f8c;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(213, 63, 140, 0));
  }

  .lg\:focus\:tw-via-pink-700:focus {
    --gradient-via-color: #b83280;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(184, 50, 128, 0));
  }

  .lg\:focus\:tw-via-pink-800:focus {
    --gradient-via-color: #97266d;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(151, 38, 109, 0));
  }

  .lg\:focus\:tw-via-pink-900:focus {
    --gradient-via-color: #702459;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(112, 36, 89, 0));
  }

  .lg\:focus\:tw-to-transparent:focus {
    --gradient-to-color: transparent;
  }

  .lg\:focus\:tw-to-current:focus {
    --gradient-to-color: currentColor;
  }

  .lg\:focus\:tw-to-black:focus {
    --gradient-to-color: #000;
  }

  .lg\:focus\:tw-to-white:focus {
    --gradient-to-color: #fff;
  }

  .lg\:focus\:tw-to-gray-100:focus {
    --gradient-to-color: #f7fafc;
  }

  .lg\:focus\:tw-to-gray-200:focus {
    --gradient-to-color: #edf2f7;
  }

  .lg\:focus\:tw-to-gray-300:focus {
    --gradient-to-color: #e2e8f0;
  }

  .lg\:focus\:tw-to-gray-400:focus {
    --gradient-to-color: #cbd5e0;
  }

  .lg\:focus\:tw-to-gray-500:focus {
    --gradient-to-color: #a0aec0;
  }

  .lg\:focus\:tw-to-gray-600:focus {
    --gradient-to-color: #718096;
  }

  .lg\:focus\:tw-to-gray-700:focus {
    --gradient-to-color: #4a5568;
  }

  .lg\:focus\:tw-to-gray-800:focus {
    --gradient-to-color: #2d3748;
  }

  .lg\:focus\:tw-to-gray-900:focus {
    --gradient-to-color: #1a202c;
  }

  .lg\:focus\:tw-to-red-100:focus {
    --gradient-to-color: #fff5f5;
  }

  .lg\:focus\:tw-to-red-200:focus {
    --gradient-to-color: #fed7d7;
  }

  .lg\:focus\:tw-to-red-300:focus {
    --gradient-to-color: #feb2b2;
  }

  .lg\:focus\:tw-to-red-400:focus {
    --gradient-to-color: #fc8181;
  }

  .lg\:focus\:tw-to-red-500:focus {
    --gradient-to-color: #f56565;
  }

  .lg\:focus\:tw-to-red-600:focus {
    --gradient-to-color: #e53e3e;
  }

  .lg\:focus\:tw-to-red-700:focus {
    --gradient-to-color: #c53030;
  }

  .lg\:focus\:tw-to-red-800:focus {
    --gradient-to-color: #9b2c2c;
  }

  .lg\:focus\:tw-to-red-900:focus {
    --gradient-to-color: #742a2a;
  }

  .lg\:focus\:tw-to-orange-100:focus {
    --gradient-to-color: #fffaf0;
  }

  .lg\:focus\:tw-to-orange-200:focus {
    --gradient-to-color: #feebc8;
  }

  .lg\:focus\:tw-to-orange-300:focus {
    --gradient-to-color: #fbd38d;
  }

  .lg\:focus\:tw-to-orange-400:focus {
    --gradient-to-color: #f6ad55;
  }

  .lg\:focus\:tw-to-orange-500:focus {
    --gradient-to-color: #ed8936;
  }

  .lg\:focus\:tw-to-orange-600:focus {
    --gradient-to-color: #dd6b20;
  }

  .lg\:focus\:tw-to-orange-700:focus {
    --gradient-to-color: #c05621;
  }

  .lg\:focus\:tw-to-orange-800:focus {
    --gradient-to-color: #9c4221;
  }

  .lg\:focus\:tw-to-orange-900:focus {
    --gradient-to-color: #7b341e;
  }

  .lg\:focus\:tw-to-yellow-100:focus {
    --gradient-to-color: #fffff0;
  }

  .lg\:focus\:tw-to-yellow-200:focus {
    --gradient-to-color: #fefcbf;
  }

  .lg\:focus\:tw-to-yellow-300:focus {
    --gradient-to-color: #faf089;
  }

  .lg\:focus\:tw-to-yellow-400:focus {
    --gradient-to-color: #f6e05e;
  }

  .lg\:focus\:tw-to-yellow-500:focus {
    --gradient-to-color: #ecc94b;
  }

  .lg\:focus\:tw-to-yellow-600:focus {
    --gradient-to-color: #d69e2e;
  }

  .lg\:focus\:tw-to-yellow-700:focus {
    --gradient-to-color: #b7791f;
  }

  .lg\:focus\:tw-to-yellow-800:focus {
    --gradient-to-color: #975a16;
  }

  .lg\:focus\:tw-to-yellow-900:focus {
    --gradient-to-color: #744210;
  }

  .lg\:focus\:tw-to-green-100:focus {
    --gradient-to-color: #f0fff4;
  }

  .lg\:focus\:tw-to-green-200:focus {
    --gradient-to-color: #c6f6d5;
  }

  .lg\:focus\:tw-to-green-300:focus {
    --gradient-to-color: #9ae6b4;
  }

  .lg\:focus\:tw-to-green-400:focus {
    --gradient-to-color: #68d391;
  }

  .lg\:focus\:tw-to-green-500:focus {
    --gradient-to-color: #48bb78;
  }

  .lg\:focus\:tw-to-green-600:focus {
    --gradient-to-color: #38a169;
  }

  .lg\:focus\:tw-to-green-700:focus {
    --gradient-to-color: #2f855a;
  }

  .lg\:focus\:tw-to-green-800:focus {
    --gradient-to-color: #276749;
  }

  .lg\:focus\:tw-to-green-900:focus {
    --gradient-to-color: #22543d;
  }

  .lg\:focus\:tw-to-teal-100:focus {
    --gradient-to-color: #e6fffa;
  }

  .lg\:focus\:tw-to-teal-200:focus {
    --gradient-to-color: #b2f5ea;
  }

  .lg\:focus\:tw-to-teal-300:focus {
    --gradient-to-color: #81e6d9;
  }

  .lg\:focus\:tw-to-teal-400:focus {
    --gradient-to-color: #4fd1c5;
  }

  .lg\:focus\:tw-to-teal-500:focus {
    --gradient-to-color: #38b2ac;
  }

  .lg\:focus\:tw-to-teal-600:focus {
    --gradient-to-color: #319795;
  }

  .lg\:focus\:tw-to-teal-700:focus {
    --gradient-to-color: #2c7a7b;
  }

  .lg\:focus\:tw-to-teal-800:focus {
    --gradient-to-color: #285e61;
  }

  .lg\:focus\:tw-to-teal-900:focus {
    --gradient-to-color: #234e52;
  }

  .lg\:focus\:tw-to-blue-100:focus {
    --gradient-to-color: #ebf8ff;
  }

  .lg\:focus\:tw-to-blue-200:focus {
    --gradient-to-color: #bee3f8;
  }

  .lg\:focus\:tw-to-blue-300:focus {
    --gradient-to-color: #90cdf4;
  }

  .lg\:focus\:tw-to-blue-400:focus {
    --gradient-to-color: #63b3ed;
  }

  .lg\:focus\:tw-to-blue-500:focus {
    --gradient-to-color: #4299e1;
  }

  .lg\:focus\:tw-to-blue-600:focus {
    --gradient-to-color: #3182ce;
  }

  .lg\:focus\:tw-to-blue-700:focus {
    --gradient-to-color: #2b6cb0;
  }

  .lg\:focus\:tw-to-blue-800:focus {
    --gradient-to-color: #2c5282;
  }

  .lg\:focus\:tw-to-blue-900:focus {
    --gradient-to-color: #2a4365;
  }

  .lg\:focus\:tw-to-indigo-100:focus {
    --gradient-to-color: #ebf4ff;
  }

  .lg\:focus\:tw-to-indigo-200:focus {
    --gradient-to-color: #c3dafe;
  }

  .lg\:focus\:tw-to-indigo-300:focus {
    --gradient-to-color: #a3bffa;
  }

  .lg\:focus\:tw-to-indigo-400:focus {
    --gradient-to-color: #7f9cf5;
  }

  .lg\:focus\:tw-to-indigo-500:focus {
    --gradient-to-color: #667eea;
  }

  .lg\:focus\:tw-to-indigo-600:focus {
    --gradient-to-color: #5a67d8;
  }

  .lg\:focus\:tw-to-indigo-700:focus {
    --gradient-to-color: #4c51bf;
  }

  .lg\:focus\:tw-to-indigo-800:focus {
    --gradient-to-color: #434190;
  }

  .lg\:focus\:tw-to-indigo-900:focus {
    --gradient-to-color: #3c366b;
  }

  .lg\:focus\:tw-to-purple-100:focus {
    --gradient-to-color: #faf5ff;
  }

  .lg\:focus\:tw-to-purple-200:focus {
    --gradient-to-color: #e9d8fd;
  }

  .lg\:focus\:tw-to-purple-300:focus {
    --gradient-to-color: #d6bcfa;
  }

  .lg\:focus\:tw-to-purple-400:focus {
    --gradient-to-color: #b794f4;
  }

  .lg\:focus\:tw-to-purple-500:focus {
    --gradient-to-color: #9f7aea;
  }

  .lg\:focus\:tw-to-purple-600:focus {
    --gradient-to-color: #805ad5;
  }

  .lg\:focus\:tw-to-purple-700:focus {
    --gradient-to-color: #6b46c1;
  }

  .lg\:focus\:tw-to-purple-800:focus {
    --gradient-to-color: #553c9a;
  }

  .lg\:focus\:tw-to-purple-900:focus {
    --gradient-to-color: #44337a;
  }

  .lg\:focus\:tw-to-pink-100:focus {
    --gradient-to-color: #fff5f7;
  }

  .lg\:focus\:tw-to-pink-200:focus {
    --gradient-to-color: #fed7e2;
  }

  .lg\:focus\:tw-to-pink-300:focus {
    --gradient-to-color: #fbb6ce;
  }

  .lg\:focus\:tw-to-pink-400:focus {
    --gradient-to-color: #f687b3;
  }

  .lg\:focus\:tw-to-pink-500:focus {
    --gradient-to-color: #ed64a6;
  }

  .lg\:focus\:tw-to-pink-600:focus {
    --gradient-to-color: #d53f8c;
  }

  .lg\:focus\:tw-to-pink-700:focus {
    --gradient-to-color: #b83280;
  }

  .lg\:focus\:tw-to-pink-800:focus {
    --gradient-to-color: #97266d;
  }

  .lg\:focus\:tw-to-pink-900:focus {
    --gradient-to-color: #702459;
  }

  .lg\:tw-bg-opacity-0 {
    --bg-opacity: 0;
  }

  .lg\:tw-bg-opacity-25 {
    --bg-opacity: 0.25;
  }

  .lg\:tw-bg-opacity-50 {
    --bg-opacity: 0.5;
  }

  .lg\:tw-bg-opacity-75 {
    --bg-opacity: 0.75;
  }

  .lg\:tw-bg-opacity-100 {
    --bg-opacity: 1;
  }

  .lg\:hover\:tw-bg-opacity-0:hover {
    --bg-opacity: 0;
  }

  .lg\:hover\:tw-bg-opacity-25:hover {
    --bg-opacity: 0.25;
  }

  .lg\:hover\:tw-bg-opacity-50:hover {
    --bg-opacity: 0.5;
  }

  .lg\:hover\:tw-bg-opacity-75:hover {
    --bg-opacity: 0.75;
  }

  .lg\:hover\:tw-bg-opacity-100:hover {
    --bg-opacity: 1;
  }

  .lg\:focus\:tw-bg-opacity-0:focus {
    --bg-opacity: 0;
  }

  .lg\:focus\:tw-bg-opacity-25:focus {
    --bg-opacity: 0.25;
  }

  .lg\:focus\:tw-bg-opacity-50:focus {
    --bg-opacity: 0.5;
  }

  .lg\:focus\:tw-bg-opacity-75:focus {
    --bg-opacity: 0.75;
  }

  .lg\:focus\:tw-bg-opacity-100:focus {
    --bg-opacity: 1;
  }

  .lg\:tw-bg-bottom {
    background-position: bottom;
  }

  .lg\:tw-bg-center {
    background-position: center;
  }

  .lg\:tw-bg-left {
    background-position: left;
  }

  .lg\:tw-bg-left-bottom {
    background-position: left bottom;
  }

  .lg\:tw-bg-left-top {
    background-position: left top;
  }

  .lg\:tw-bg-right {
    background-position: right;
  }

  .lg\:tw-bg-right-bottom {
    background-position: right bottom;
  }

  .lg\:tw-bg-right-top {
    background-position: right top;
  }

  .lg\:tw-bg-top {
    background-position: top;
  }

  .lg\:tw-bg-repeat {
    background-repeat: repeat;
  }

  .lg\:tw-bg-no-repeat {
    background-repeat: no-repeat;
  }

  .lg\:tw-bg-repeat-x {
    background-repeat: repeat-x;
  }

  .lg\:tw-bg-repeat-y {
    background-repeat: repeat-y;
  }

  .lg\:tw-bg-repeat-round {
    background-repeat: round;
  }

  .lg\:tw-bg-repeat-space {
    background-repeat: space;
  }

  .lg\:tw-bg-auto {
    background-size: auto;
  }

  .lg\:tw-bg-cover {
    background-size: cover;
  }

  .lg\:tw-bg-contain {
    background-size: contain;
  }

  .lg\:tw-border-collapse {
    border-collapse: collapse;
  }

  .lg\:tw-border-separate {
    border-collapse: separate;
  }

  .lg\:tw-border-transparent {
    border-color: transparent;
  }

  .lg\:tw-border-current {
    border-color: currentColor;
  }

  .lg\:tw-border-black {
    --border-opacity: 1;
    border-color: #000;
    border-color: rgba(0, 0, 0, var(--border-opacity));
  }

  .lg\:tw-border-white {
    --border-opacity: 1;
    border-color: #fff;
    border-color: rgba(255, 255, 255, var(--border-opacity));
  }

  .lg\:tw-border-gray-100 {
    --border-opacity: 1;
    border-color: #f7fafc;
    border-color: rgba(247, 250, 252, var(--border-opacity));
  }

  .lg\:tw-border-gray-200 {
    --border-opacity: 1;
    border-color: #edf2f7;
    border-color: rgba(237, 242, 247, var(--border-opacity));
  }

  .lg\:tw-border-gray-300 {
    --border-opacity: 1;
    border-color: #e2e8f0;
    border-color: rgba(226, 232, 240, var(--border-opacity));
  }

  .lg\:tw-border-gray-400 {
    --border-opacity: 1;
    border-color: #cbd5e0;
    border-color: rgba(203, 213, 224, var(--border-opacity));
  }

  .lg\:tw-border-gray-500 {
    --border-opacity: 1;
    border-color: #a0aec0;
    border-color: rgba(160, 174, 192, var(--border-opacity));
  }

  .lg\:tw-border-gray-600 {
    --border-opacity: 1;
    border-color: #718096;
    border-color: rgba(113, 128, 150, var(--border-opacity));
  }

  .lg\:tw-border-gray-700 {
    --border-opacity: 1;
    border-color: #4a5568;
    border-color: rgba(74, 85, 104, var(--border-opacity));
  }

  .lg\:tw-border-gray-800 {
    --border-opacity: 1;
    border-color: #2d3748;
    border-color: rgba(45, 55, 72, var(--border-opacity));
  }

  .lg\:tw-border-gray-900 {
    --border-opacity: 1;
    border-color: #1a202c;
    border-color: rgba(26, 32, 44, var(--border-opacity));
  }

  .lg\:tw-border-red-100 {
    --border-opacity: 1;
    border-color: #fff5f5;
    border-color: rgba(255, 245, 245, var(--border-opacity));
  }

  .lg\:tw-border-red-200 {
    --border-opacity: 1;
    border-color: #fed7d7;
    border-color: rgba(254, 215, 215, var(--border-opacity));
  }

  .lg\:tw-border-red-300 {
    --border-opacity: 1;
    border-color: #feb2b2;
    border-color: rgba(254, 178, 178, var(--border-opacity));
  }

  .lg\:tw-border-red-400 {
    --border-opacity: 1;
    border-color: #fc8181;
    border-color: rgba(252, 129, 129, var(--border-opacity));
  }

  .lg\:tw-border-red-500 {
    --border-opacity: 1;
    border-color: #f56565;
    border-color: rgba(245, 101, 101, var(--border-opacity));
  }

  .lg\:tw-border-red-600 {
    --border-opacity: 1;
    border-color: #e53e3e;
    border-color: rgba(229, 62, 62, var(--border-opacity));
  }

  .lg\:tw-border-red-700 {
    --border-opacity: 1;
    border-color: #c53030;
    border-color: rgba(197, 48, 48, var(--border-opacity));
  }

  .lg\:tw-border-red-800 {
    --border-opacity: 1;
    border-color: #9b2c2c;
    border-color: rgba(155, 44, 44, var(--border-opacity));
  }

  .lg\:tw-border-red-900 {
    --border-opacity: 1;
    border-color: #742a2a;
    border-color: rgba(116, 42, 42, var(--border-opacity));
  }

  .lg\:tw-border-orange-100 {
    --border-opacity: 1;
    border-color: #fffaf0;
    border-color: rgba(255, 250, 240, var(--border-opacity));
  }

  .lg\:tw-border-orange-200 {
    --border-opacity: 1;
    border-color: #feebc8;
    border-color: rgba(254, 235, 200, var(--border-opacity));
  }

  .lg\:tw-border-orange-300 {
    --border-opacity: 1;
    border-color: #fbd38d;
    border-color: rgba(251, 211, 141, var(--border-opacity));
  }

  .lg\:tw-border-orange-400 {
    --border-opacity: 1;
    border-color: #f6ad55;
    border-color: rgba(246, 173, 85, var(--border-opacity));
  }

  .lg\:tw-border-orange-500 {
    --border-opacity: 1;
    border-color: #ed8936;
    border-color: rgba(237, 137, 54, var(--border-opacity));
  }

  .lg\:tw-border-orange-600 {
    --border-opacity: 1;
    border-color: #dd6b20;
    border-color: rgba(221, 107, 32, var(--border-opacity));
  }

  .lg\:tw-border-orange-700 {
    --border-opacity: 1;
    border-color: #c05621;
    border-color: rgba(192, 86, 33, var(--border-opacity));
  }

  .lg\:tw-border-orange-800 {
    --border-opacity: 1;
    border-color: #9c4221;
    border-color: rgba(156, 66, 33, var(--border-opacity));
  }

  .lg\:tw-border-orange-900 {
    --border-opacity: 1;
    border-color: #7b341e;
    border-color: rgba(123, 52, 30, var(--border-opacity));
  }

  .lg\:tw-border-yellow-100 {
    --border-opacity: 1;
    border-color: #fffff0;
    border-color: rgba(255, 255, 240, var(--border-opacity));
  }

  .lg\:tw-border-yellow-200 {
    --border-opacity: 1;
    border-color: #fefcbf;
    border-color: rgba(254, 252, 191, var(--border-opacity));
  }

  .lg\:tw-border-yellow-300 {
    --border-opacity: 1;
    border-color: #faf089;
    border-color: rgba(250, 240, 137, var(--border-opacity));
  }

  .lg\:tw-border-yellow-400 {
    --border-opacity: 1;
    border-color: #f6e05e;
    border-color: rgba(246, 224, 94, var(--border-opacity));
  }

  .lg\:tw-border-yellow-500 {
    --border-opacity: 1;
    border-color: #ecc94b;
    border-color: rgba(236, 201, 75, var(--border-opacity));
  }

  .lg\:tw-border-yellow-600 {
    --border-opacity: 1;
    border-color: #d69e2e;
    border-color: rgba(214, 158, 46, var(--border-opacity));
  }

  .lg\:tw-border-yellow-700 {
    --border-opacity: 1;
    border-color: #b7791f;
    border-color: rgba(183, 121, 31, var(--border-opacity));
  }

  .lg\:tw-border-yellow-800 {
    --border-opacity: 1;
    border-color: #975a16;
    border-color: rgba(151, 90, 22, var(--border-opacity));
  }

  .lg\:tw-border-yellow-900 {
    --border-opacity: 1;
    border-color: #744210;
    border-color: rgba(116, 66, 16, var(--border-opacity));
  }

  .lg\:tw-border-green-100 {
    --border-opacity: 1;
    border-color: #f0fff4;
    border-color: rgba(240, 255, 244, var(--border-opacity));
  }

  .lg\:tw-border-green-200 {
    --border-opacity: 1;
    border-color: #c6f6d5;
    border-color: rgba(198, 246, 213, var(--border-opacity));
  }

  .lg\:tw-border-green-300 {
    --border-opacity: 1;
    border-color: #9ae6b4;
    border-color: rgba(154, 230, 180, var(--border-opacity));
  }

  .lg\:tw-border-green-400 {
    --border-opacity: 1;
    border-color: #68d391;
    border-color: rgba(104, 211, 145, var(--border-opacity));
  }

  .lg\:tw-border-green-500 {
    --border-opacity: 1;
    border-color: #48bb78;
    border-color: rgba(72, 187, 120, var(--border-opacity));
  }

  .lg\:tw-border-green-600 {
    --border-opacity: 1;
    border-color: #38a169;
    border-color: rgba(56, 161, 105, var(--border-opacity));
  }

  .lg\:tw-border-green-700 {
    --border-opacity: 1;
    border-color: #2f855a;
    border-color: rgba(47, 133, 90, var(--border-opacity));
  }

  .lg\:tw-border-green-800 {
    --border-opacity: 1;
    border-color: #276749;
    border-color: rgba(39, 103, 73, var(--border-opacity));
  }

  .lg\:tw-border-green-900 {
    --border-opacity: 1;
    border-color: #22543d;
    border-color: rgba(34, 84, 61, var(--border-opacity));
  }

  .lg\:tw-border-teal-100 {
    --border-opacity: 1;
    border-color: #e6fffa;
    border-color: rgba(230, 255, 250, var(--border-opacity));
  }

  .lg\:tw-border-teal-200 {
    --border-opacity: 1;
    border-color: #b2f5ea;
    border-color: rgba(178, 245, 234, var(--border-opacity));
  }

  .lg\:tw-border-teal-300 {
    --border-opacity: 1;
    border-color: #81e6d9;
    border-color: rgba(129, 230, 217, var(--border-opacity));
  }

  .lg\:tw-border-teal-400 {
    --border-opacity: 1;
    border-color: #4fd1c5;
    border-color: rgba(79, 209, 197, var(--border-opacity));
  }

  .lg\:tw-border-teal-500 {
    --border-opacity: 1;
    border-color: #38b2ac;
    border-color: rgba(56, 178, 172, var(--border-opacity));
  }

  .lg\:tw-border-teal-600 {
    --border-opacity: 1;
    border-color: #319795;
    border-color: rgba(49, 151, 149, var(--border-opacity));
  }

  .lg\:tw-border-teal-700 {
    --border-opacity: 1;
    border-color: #2c7a7b;
    border-color: rgba(44, 122, 123, var(--border-opacity));
  }

  .lg\:tw-border-teal-800 {
    --border-opacity: 1;
    border-color: #285e61;
    border-color: rgba(40, 94, 97, var(--border-opacity));
  }

  .lg\:tw-border-teal-900 {
    --border-opacity: 1;
    border-color: #234e52;
    border-color: rgba(35, 78, 82, var(--border-opacity));
  }

  .lg\:tw-border-blue-100 {
    --border-opacity: 1;
    border-color: #ebf8ff;
    border-color: rgba(235, 248, 255, var(--border-opacity));
  }

  .lg\:tw-border-blue-200 {
    --border-opacity: 1;
    border-color: #bee3f8;
    border-color: rgba(190, 227, 248, var(--border-opacity));
  }

  .lg\:tw-border-blue-300 {
    --border-opacity: 1;
    border-color: #90cdf4;
    border-color: rgba(144, 205, 244, var(--border-opacity));
  }

  .lg\:tw-border-blue-400 {
    --border-opacity: 1;
    border-color: #63b3ed;
    border-color: rgba(99, 179, 237, var(--border-opacity));
  }

  .lg\:tw-border-blue-500 {
    --border-opacity: 1;
    border-color: #4299e1;
    border-color: rgba(66, 153, 225, var(--border-opacity));
  }

  .lg\:tw-border-blue-600 {
    --border-opacity: 1;
    border-color: #3182ce;
    border-color: rgba(49, 130, 206, var(--border-opacity));
  }

  .lg\:tw-border-blue-700 {
    --border-opacity: 1;
    border-color: #2b6cb0;
    border-color: rgba(43, 108, 176, var(--border-opacity));
  }

  .lg\:tw-border-blue-800 {
    --border-opacity: 1;
    border-color: #2c5282;
    border-color: rgba(44, 82, 130, var(--border-opacity));
  }

  .lg\:tw-border-blue-900 {
    --border-opacity: 1;
    border-color: #2a4365;
    border-color: rgba(42, 67, 101, var(--border-opacity));
  }

  .lg\:tw-border-indigo-100 {
    --border-opacity: 1;
    border-color: #ebf4ff;
    border-color: rgba(235, 244, 255, var(--border-opacity));
  }

  .lg\:tw-border-indigo-200 {
    --border-opacity: 1;
    border-color: #c3dafe;
    border-color: rgba(195, 218, 254, var(--border-opacity));
  }

  .lg\:tw-border-indigo-300 {
    --border-opacity: 1;
    border-color: #a3bffa;
    border-color: rgba(163, 191, 250, var(--border-opacity));
  }

  .lg\:tw-border-indigo-400 {
    --border-opacity: 1;
    border-color: #7f9cf5;
    border-color: rgba(127, 156, 245, var(--border-opacity));
  }

  .lg\:tw-border-indigo-500 {
    --border-opacity: 1;
    border-color: #667eea;
    border-color: rgba(102, 126, 234, var(--border-opacity));
  }

  .lg\:tw-border-indigo-600 {
    --border-opacity: 1;
    border-color: #5a67d8;
    border-color: rgba(90, 103, 216, var(--border-opacity));
  }

  .lg\:tw-border-indigo-700 {
    --border-opacity: 1;
    border-color: #4c51bf;
    border-color: rgba(76, 81, 191, var(--border-opacity));
  }

  .lg\:tw-border-indigo-800 {
    --border-opacity: 1;
    border-color: #434190;
    border-color: rgba(67, 65, 144, var(--border-opacity));
  }

  .lg\:tw-border-indigo-900 {
    --border-opacity: 1;
    border-color: #3c366b;
    border-color: rgba(60, 54, 107, var(--border-opacity));
  }

  .lg\:tw-border-purple-100 {
    --border-opacity: 1;
    border-color: #faf5ff;
    border-color: rgba(250, 245, 255, var(--border-opacity));
  }

  .lg\:tw-border-purple-200 {
    --border-opacity: 1;
    border-color: #e9d8fd;
    border-color: rgba(233, 216, 253, var(--border-opacity));
  }

  .lg\:tw-border-purple-300 {
    --border-opacity: 1;
    border-color: #d6bcfa;
    border-color: rgba(214, 188, 250, var(--border-opacity));
  }

  .lg\:tw-border-purple-400 {
    --border-opacity: 1;
    border-color: #b794f4;
    border-color: rgba(183, 148, 244, var(--border-opacity));
  }

  .lg\:tw-border-purple-500 {
    --border-opacity: 1;
    border-color: #9f7aea;
    border-color: rgba(159, 122, 234, var(--border-opacity));
  }

  .lg\:tw-border-purple-600 {
    --border-opacity: 1;
    border-color: #805ad5;
    border-color: rgba(128, 90, 213, var(--border-opacity));
  }

  .lg\:tw-border-purple-700 {
    --border-opacity: 1;
    border-color: #6b46c1;
    border-color: rgba(107, 70, 193, var(--border-opacity));
  }

  .lg\:tw-border-purple-800 {
    --border-opacity: 1;
    border-color: #553c9a;
    border-color: rgba(85, 60, 154, var(--border-opacity));
  }

  .lg\:tw-border-purple-900 {
    --border-opacity: 1;
    border-color: #44337a;
    border-color: rgba(68, 51, 122, var(--border-opacity));
  }

  .lg\:tw-border-pink-100 {
    --border-opacity: 1;
    border-color: #fff5f7;
    border-color: rgba(255, 245, 247, var(--border-opacity));
  }

  .lg\:tw-border-pink-200 {
    --border-opacity: 1;
    border-color: #fed7e2;
    border-color: rgba(254, 215, 226, var(--border-opacity));
  }

  .lg\:tw-border-pink-300 {
    --border-opacity: 1;
    border-color: #fbb6ce;
    border-color: rgba(251, 182, 206, var(--border-opacity));
  }

  .lg\:tw-border-pink-400 {
    --border-opacity: 1;
    border-color: #f687b3;
    border-color: rgba(246, 135, 179, var(--border-opacity));
  }

  .lg\:tw-border-pink-500 {
    --border-opacity: 1;
    border-color: #ed64a6;
    border-color: rgba(237, 100, 166, var(--border-opacity));
  }

  .lg\:tw-border-pink-600 {
    --border-opacity: 1;
    border-color: #d53f8c;
    border-color: rgba(213, 63, 140, var(--border-opacity));
  }

  .lg\:tw-border-pink-700 {
    --border-opacity: 1;
    border-color: #b83280;
    border-color: rgba(184, 50, 128, var(--border-opacity));
  }

  .lg\:tw-border-pink-800 {
    --border-opacity: 1;
    border-color: #97266d;
    border-color: rgba(151, 38, 109, var(--border-opacity));
  }

  .lg\:tw-border-pink-900 {
    --border-opacity: 1;
    border-color: #702459;
    border-color: rgba(112, 36, 89, var(--border-opacity));
  }

  .lg\:hover\:tw-border-transparent:hover {
    border-color: transparent;
  }

  .lg\:hover\:tw-border-current:hover {
    border-color: currentColor;
  }

  .lg\:hover\:tw-border-black:hover {
    --border-opacity: 1;
    border-color: #000;
    border-color: rgba(0, 0, 0, var(--border-opacity));
  }

  .lg\:hover\:tw-border-white:hover {
    --border-opacity: 1;
    border-color: #fff;
    border-color: rgba(255, 255, 255, var(--border-opacity));
  }

  .lg\:hover\:tw-border-gray-100:hover {
    --border-opacity: 1;
    border-color: #f7fafc;
    border-color: rgba(247, 250, 252, var(--border-opacity));
  }

  .lg\:hover\:tw-border-gray-200:hover {
    --border-opacity: 1;
    border-color: #edf2f7;
    border-color: rgba(237, 242, 247, var(--border-opacity));
  }

  .lg\:hover\:tw-border-gray-300:hover {
    --border-opacity: 1;
    border-color: #e2e8f0;
    border-color: rgba(226, 232, 240, var(--border-opacity));
  }

  .lg\:hover\:tw-border-gray-400:hover {
    --border-opacity: 1;
    border-color: #cbd5e0;
    border-color: rgba(203, 213, 224, var(--border-opacity));
  }

  .lg\:hover\:tw-border-gray-500:hover {
    --border-opacity: 1;
    border-color: #a0aec0;
    border-color: rgba(160, 174, 192, var(--border-opacity));
  }

  .lg\:hover\:tw-border-gray-600:hover {
    --border-opacity: 1;
    border-color: #718096;
    border-color: rgba(113, 128, 150, var(--border-opacity));
  }

  .lg\:hover\:tw-border-gray-700:hover {
    --border-opacity: 1;
    border-color: #4a5568;
    border-color: rgba(74, 85, 104, var(--border-opacity));
  }

  .lg\:hover\:tw-border-gray-800:hover {
    --border-opacity: 1;
    border-color: #2d3748;
    border-color: rgba(45, 55, 72, var(--border-opacity));
  }

  .lg\:hover\:tw-border-gray-900:hover {
    --border-opacity: 1;
    border-color: #1a202c;
    border-color: rgba(26, 32, 44, var(--border-opacity));
  }

  .lg\:hover\:tw-border-red-100:hover {
    --border-opacity: 1;
    border-color: #fff5f5;
    border-color: rgba(255, 245, 245, var(--border-opacity));
  }

  .lg\:hover\:tw-border-red-200:hover {
    --border-opacity: 1;
    border-color: #fed7d7;
    border-color: rgba(254, 215, 215, var(--border-opacity));
  }

  .lg\:hover\:tw-border-red-300:hover {
    --border-opacity: 1;
    border-color: #feb2b2;
    border-color: rgba(254, 178, 178, var(--border-opacity));
  }

  .lg\:hover\:tw-border-red-400:hover {
    --border-opacity: 1;
    border-color: #fc8181;
    border-color: rgba(252, 129, 129, var(--border-opacity));
  }

  .lg\:hover\:tw-border-red-500:hover {
    --border-opacity: 1;
    border-color: #f56565;
    border-color: rgba(245, 101, 101, var(--border-opacity));
  }

  .lg\:hover\:tw-border-red-600:hover {
    --border-opacity: 1;
    border-color: #e53e3e;
    border-color: rgba(229, 62, 62, var(--border-opacity));
  }

  .lg\:hover\:tw-border-red-700:hover {
    --border-opacity: 1;
    border-color: #c53030;
    border-color: rgba(197, 48, 48, var(--border-opacity));
  }

  .lg\:hover\:tw-border-red-800:hover {
    --border-opacity: 1;
    border-color: #9b2c2c;
    border-color: rgba(155, 44, 44, var(--border-opacity));
  }

  .lg\:hover\:tw-border-red-900:hover {
    --border-opacity: 1;
    border-color: #742a2a;
    border-color: rgba(116, 42, 42, var(--border-opacity));
  }

  .lg\:hover\:tw-border-orange-100:hover {
    --border-opacity: 1;
    border-color: #fffaf0;
    border-color: rgba(255, 250, 240, var(--border-opacity));
  }

  .lg\:hover\:tw-border-orange-200:hover {
    --border-opacity: 1;
    border-color: #feebc8;
    border-color: rgba(254, 235, 200, var(--border-opacity));
  }

  .lg\:hover\:tw-border-orange-300:hover {
    --border-opacity: 1;
    border-color: #fbd38d;
    border-color: rgba(251, 211, 141, var(--border-opacity));
  }

  .lg\:hover\:tw-border-orange-400:hover {
    --border-opacity: 1;
    border-color: #f6ad55;
    border-color: rgba(246, 173, 85, var(--border-opacity));
  }

  .lg\:hover\:tw-border-orange-500:hover {
    --border-opacity: 1;
    border-color: #ed8936;
    border-color: rgba(237, 137, 54, var(--border-opacity));
  }

  .lg\:hover\:tw-border-orange-600:hover {
    --border-opacity: 1;
    border-color: #dd6b20;
    border-color: rgba(221, 107, 32, var(--border-opacity));
  }

  .lg\:hover\:tw-border-orange-700:hover {
    --border-opacity: 1;
    border-color: #c05621;
    border-color: rgba(192, 86, 33, var(--border-opacity));
  }

  .lg\:hover\:tw-border-orange-800:hover {
    --border-opacity: 1;
    border-color: #9c4221;
    border-color: rgba(156, 66, 33, var(--border-opacity));
  }

  .lg\:hover\:tw-border-orange-900:hover {
    --border-opacity: 1;
    border-color: #7b341e;
    border-color: rgba(123, 52, 30, var(--border-opacity));
  }

  .lg\:hover\:tw-border-yellow-100:hover {
    --border-opacity: 1;
    border-color: #fffff0;
    border-color: rgba(255, 255, 240, var(--border-opacity));
  }

  .lg\:hover\:tw-border-yellow-200:hover {
    --border-opacity: 1;
    border-color: #fefcbf;
    border-color: rgba(254, 252, 191, var(--border-opacity));
  }

  .lg\:hover\:tw-border-yellow-300:hover {
    --border-opacity: 1;
    border-color: #faf089;
    border-color: rgba(250, 240, 137, var(--border-opacity));
  }

  .lg\:hover\:tw-border-yellow-400:hover {
    --border-opacity: 1;
    border-color: #f6e05e;
    border-color: rgba(246, 224, 94, var(--border-opacity));
  }

  .lg\:hover\:tw-border-yellow-500:hover {
    --border-opacity: 1;
    border-color: #ecc94b;
    border-color: rgba(236, 201, 75, var(--border-opacity));
  }

  .lg\:hover\:tw-border-yellow-600:hover {
    --border-opacity: 1;
    border-color: #d69e2e;
    border-color: rgba(214, 158, 46, var(--border-opacity));
  }

  .lg\:hover\:tw-border-yellow-700:hover {
    --border-opacity: 1;
    border-color: #b7791f;
    border-color: rgba(183, 121, 31, var(--border-opacity));
  }

  .lg\:hover\:tw-border-yellow-800:hover {
    --border-opacity: 1;
    border-color: #975a16;
    border-color: rgba(151, 90, 22, var(--border-opacity));
  }

  .lg\:hover\:tw-border-yellow-900:hover {
    --border-opacity: 1;
    border-color: #744210;
    border-color: rgba(116, 66, 16, var(--border-opacity));
  }

  .lg\:hover\:tw-border-green-100:hover {
    --border-opacity: 1;
    border-color: #f0fff4;
    border-color: rgba(240, 255, 244, var(--border-opacity));
  }

  .lg\:hover\:tw-border-green-200:hover {
    --border-opacity: 1;
    border-color: #c6f6d5;
    border-color: rgba(198, 246, 213, var(--border-opacity));
  }

  .lg\:hover\:tw-border-green-300:hover {
    --border-opacity: 1;
    border-color: #9ae6b4;
    border-color: rgba(154, 230, 180, var(--border-opacity));
  }

  .lg\:hover\:tw-border-green-400:hover {
    --border-opacity: 1;
    border-color: #68d391;
    border-color: rgba(104, 211, 145, var(--border-opacity));
  }

  .lg\:hover\:tw-border-green-500:hover {
    --border-opacity: 1;
    border-color: #48bb78;
    border-color: rgba(72, 187, 120, var(--border-opacity));
  }

  .lg\:hover\:tw-border-green-600:hover {
    --border-opacity: 1;
    border-color: #38a169;
    border-color: rgba(56, 161, 105, var(--border-opacity));
  }

  .lg\:hover\:tw-border-green-700:hover {
    --border-opacity: 1;
    border-color: #2f855a;
    border-color: rgba(47, 133, 90, var(--border-opacity));
  }

  .lg\:hover\:tw-border-green-800:hover {
    --border-opacity: 1;
    border-color: #276749;
    border-color: rgba(39, 103, 73, var(--border-opacity));
  }

  .lg\:hover\:tw-border-green-900:hover {
    --border-opacity: 1;
    border-color: #22543d;
    border-color: rgba(34, 84, 61, var(--border-opacity));
  }

  .lg\:hover\:tw-border-teal-100:hover {
    --border-opacity: 1;
    border-color: #e6fffa;
    border-color: rgba(230, 255, 250, var(--border-opacity));
  }

  .lg\:hover\:tw-border-teal-200:hover {
    --border-opacity: 1;
    border-color: #b2f5ea;
    border-color: rgba(178, 245, 234, var(--border-opacity));
  }

  .lg\:hover\:tw-border-teal-300:hover {
    --border-opacity: 1;
    border-color: #81e6d9;
    border-color: rgba(129, 230, 217, var(--border-opacity));
  }

  .lg\:hover\:tw-border-teal-400:hover {
    --border-opacity: 1;
    border-color: #4fd1c5;
    border-color: rgba(79, 209, 197, var(--border-opacity));
  }

  .lg\:hover\:tw-border-teal-500:hover {
    --border-opacity: 1;
    border-color: #38b2ac;
    border-color: rgba(56, 178, 172, var(--border-opacity));
  }

  .lg\:hover\:tw-border-teal-600:hover {
    --border-opacity: 1;
    border-color: #319795;
    border-color: rgba(49, 151, 149, var(--border-opacity));
  }

  .lg\:hover\:tw-border-teal-700:hover {
    --border-opacity: 1;
    border-color: #2c7a7b;
    border-color: rgba(44, 122, 123, var(--border-opacity));
  }

  .lg\:hover\:tw-border-teal-800:hover {
    --border-opacity: 1;
    border-color: #285e61;
    border-color: rgba(40, 94, 97, var(--border-opacity));
  }

  .lg\:hover\:tw-border-teal-900:hover {
    --border-opacity: 1;
    border-color: #234e52;
    border-color: rgba(35, 78, 82, var(--border-opacity));
  }

  .lg\:hover\:tw-border-blue-100:hover {
    --border-opacity: 1;
    border-color: #ebf8ff;
    border-color: rgba(235, 248, 255, var(--border-opacity));
  }

  .lg\:hover\:tw-border-blue-200:hover {
    --border-opacity: 1;
    border-color: #bee3f8;
    border-color: rgba(190, 227, 248, var(--border-opacity));
  }

  .lg\:hover\:tw-border-blue-300:hover {
    --border-opacity: 1;
    border-color: #90cdf4;
    border-color: rgba(144, 205, 244, var(--border-opacity));
  }

  .lg\:hover\:tw-border-blue-400:hover {
    --border-opacity: 1;
    border-color: #63b3ed;
    border-color: rgba(99, 179, 237, var(--border-opacity));
  }

  .lg\:hover\:tw-border-blue-500:hover {
    --border-opacity: 1;
    border-color: #4299e1;
    border-color: rgba(66, 153, 225, var(--border-opacity));
  }

  .lg\:hover\:tw-border-blue-600:hover {
    --border-opacity: 1;
    border-color: #3182ce;
    border-color: rgba(49, 130, 206, var(--border-opacity));
  }

  .lg\:hover\:tw-border-blue-700:hover {
    --border-opacity: 1;
    border-color: #2b6cb0;
    border-color: rgba(43, 108, 176, var(--border-opacity));
  }

  .lg\:hover\:tw-border-blue-800:hover {
    --border-opacity: 1;
    border-color: #2c5282;
    border-color: rgba(44, 82, 130, var(--border-opacity));
  }

  .lg\:hover\:tw-border-blue-900:hover {
    --border-opacity: 1;
    border-color: #2a4365;
    border-color: rgba(42, 67, 101, var(--border-opacity));
  }

  .lg\:hover\:tw-border-indigo-100:hover {
    --border-opacity: 1;
    border-color: #ebf4ff;
    border-color: rgba(235, 244, 255, var(--border-opacity));
  }

  .lg\:hover\:tw-border-indigo-200:hover {
    --border-opacity: 1;
    border-color: #c3dafe;
    border-color: rgba(195, 218, 254, var(--border-opacity));
  }

  .lg\:hover\:tw-border-indigo-300:hover {
    --border-opacity: 1;
    border-color: #a3bffa;
    border-color: rgba(163, 191, 250, var(--border-opacity));
  }

  .lg\:hover\:tw-border-indigo-400:hover {
    --border-opacity: 1;
    border-color: #7f9cf5;
    border-color: rgba(127, 156, 245, var(--border-opacity));
  }

  .lg\:hover\:tw-border-indigo-500:hover {
    --border-opacity: 1;
    border-color: #667eea;
    border-color: rgba(102, 126, 234, var(--border-opacity));
  }

  .lg\:hover\:tw-border-indigo-600:hover {
    --border-opacity: 1;
    border-color: #5a67d8;
    border-color: rgba(90, 103, 216, var(--border-opacity));
  }

  .lg\:hover\:tw-border-indigo-700:hover {
    --border-opacity: 1;
    border-color: #4c51bf;
    border-color: rgba(76, 81, 191, var(--border-opacity));
  }

  .lg\:hover\:tw-border-indigo-800:hover {
    --border-opacity: 1;
    border-color: #434190;
    border-color: rgba(67, 65, 144, var(--border-opacity));
  }

  .lg\:hover\:tw-border-indigo-900:hover {
    --border-opacity: 1;
    border-color: #3c366b;
    border-color: rgba(60, 54, 107, var(--border-opacity));
  }

  .lg\:hover\:tw-border-purple-100:hover {
    --border-opacity: 1;
    border-color: #faf5ff;
    border-color: rgba(250, 245, 255, var(--border-opacity));
  }

  .lg\:hover\:tw-border-purple-200:hover {
    --border-opacity: 1;
    border-color: #e9d8fd;
    border-color: rgba(233, 216, 253, var(--border-opacity));
  }

  .lg\:hover\:tw-border-purple-300:hover {
    --border-opacity: 1;
    border-color: #d6bcfa;
    border-color: rgba(214, 188, 250, var(--border-opacity));
  }

  .lg\:hover\:tw-border-purple-400:hover {
    --border-opacity: 1;
    border-color: #b794f4;
    border-color: rgba(183, 148, 244, var(--border-opacity));
  }

  .lg\:hover\:tw-border-purple-500:hover {
    --border-opacity: 1;
    border-color: #9f7aea;
    border-color: rgba(159, 122, 234, var(--border-opacity));
  }

  .lg\:hover\:tw-border-purple-600:hover {
    --border-opacity: 1;
    border-color: #805ad5;
    border-color: rgba(128, 90, 213, var(--border-opacity));
  }

  .lg\:hover\:tw-border-purple-700:hover {
    --border-opacity: 1;
    border-color: #6b46c1;
    border-color: rgba(107, 70, 193, var(--border-opacity));
  }

  .lg\:hover\:tw-border-purple-800:hover {
    --border-opacity: 1;
    border-color: #553c9a;
    border-color: rgba(85, 60, 154, var(--border-opacity));
  }

  .lg\:hover\:tw-border-purple-900:hover {
    --border-opacity: 1;
    border-color: #44337a;
    border-color: rgba(68, 51, 122, var(--border-opacity));
  }

  .lg\:hover\:tw-border-pink-100:hover {
    --border-opacity: 1;
    border-color: #fff5f7;
    border-color: rgba(255, 245, 247, var(--border-opacity));
  }

  .lg\:hover\:tw-border-pink-200:hover {
    --border-opacity: 1;
    border-color: #fed7e2;
    border-color: rgba(254, 215, 226, var(--border-opacity));
  }

  .lg\:hover\:tw-border-pink-300:hover {
    --border-opacity: 1;
    border-color: #fbb6ce;
    border-color: rgba(251, 182, 206, var(--border-opacity));
  }

  .lg\:hover\:tw-border-pink-400:hover {
    --border-opacity: 1;
    border-color: #f687b3;
    border-color: rgba(246, 135, 179, var(--border-opacity));
  }

  .lg\:hover\:tw-border-pink-500:hover {
    --border-opacity: 1;
    border-color: #ed64a6;
    border-color: rgba(237, 100, 166, var(--border-opacity));
  }

  .lg\:hover\:tw-border-pink-600:hover {
    --border-opacity: 1;
    border-color: #d53f8c;
    border-color: rgba(213, 63, 140, var(--border-opacity));
  }

  .lg\:hover\:tw-border-pink-700:hover {
    --border-opacity: 1;
    border-color: #b83280;
    border-color: rgba(184, 50, 128, var(--border-opacity));
  }

  .lg\:hover\:tw-border-pink-800:hover {
    --border-opacity: 1;
    border-color: #97266d;
    border-color: rgba(151, 38, 109, var(--border-opacity));
  }

  .lg\:hover\:tw-border-pink-900:hover {
    --border-opacity: 1;
    border-color: #702459;
    border-color: rgba(112, 36, 89, var(--border-opacity));
  }

  .lg\:focus\:tw-border-transparent:focus {
    border-color: transparent;
  }

  .lg\:focus\:tw-border-current:focus {
    border-color: currentColor;
  }

  .lg\:focus\:tw-border-black:focus {
    --border-opacity: 1;
    border-color: #000;
    border-color: rgba(0, 0, 0, var(--border-opacity));
  }

  .lg\:focus\:tw-border-white:focus {
    --border-opacity: 1;
    border-color: #fff;
    border-color: rgba(255, 255, 255, var(--border-opacity));
  }

  .lg\:focus\:tw-border-gray-100:focus {
    --border-opacity: 1;
    border-color: #f7fafc;
    border-color: rgba(247, 250, 252, var(--border-opacity));
  }

  .lg\:focus\:tw-border-gray-200:focus {
    --border-opacity: 1;
    border-color: #edf2f7;
    border-color: rgba(237, 242, 247, var(--border-opacity));
  }

  .lg\:focus\:tw-border-gray-300:focus {
    --border-opacity: 1;
    border-color: #e2e8f0;
    border-color: rgba(226, 232, 240, var(--border-opacity));
  }

  .lg\:focus\:tw-border-gray-400:focus {
    --border-opacity: 1;
    border-color: #cbd5e0;
    border-color: rgba(203, 213, 224, var(--border-opacity));
  }

  .lg\:focus\:tw-border-gray-500:focus {
    --border-opacity: 1;
    border-color: #a0aec0;
    border-color: rgba(160, 174, 192, var(--border-opacity));
  }

  .lg\:focus\:tw-border-gray-600:focus {
    --border-opacity: 1;
    border-color: #718096;
    border-color: rgba(113, 128, 150, var(--border-opacity));
  }

  .lg\:focus\:tw-border-gray-700:focus {
    --border-opacity: 1;
    border-color: #4a5568;
    border-color: rgba(74, 85, 104, var(--border-opacity));
  }

  .lg\:focus\:tw-border-gray-800:focus {
    --border-opacity: 1;
    border-color: #2d3748;
    border-color: rgba(45, 55, 72, var(--border-opacity));
  }

  .lg\:focus\:tw-border-gray-900:focus {
    --border-opacity: 1;
    border-color: #1a202c;
    border-color: rgba(26, 32, 44, var(--border-opacity));
  }

  .lg\:focus\:tw-border-red-100:focus {
    --border-opacity: 1;
    border-color: #fff5f5;
    border-color: rgba(255, 245, 245, var(--border-opacity));
  }

  .lg\:focus\:tw-border-red-200:focus {
    --border-opacity: 1;
    border-color: #fed7d7;
    border-color: rgba(254, 215, 215, var(--border-opacity));
  }

  .lg\:focus\:tw-border-red-300:focus {
    --border-opacity: 1;
    border-color: #feb2b2;
    border-color: rgba(254, 178, 178, var(--border-opacity));
  }

  .lg\:focus\:tw-border-red-400:focus {
    --border-opacity: 1;
    border-color: #fc8181;
    border-color: rgba(252, 129, 129, var(--border-opacity));
  }

  .lg\:focus\:tw-border-red-500:focus {
    --border-opacity: 1;
    border-color: #f56565;
    border-color: rgba(245, 101, 101, var(--border-opacity));
  }

  .lg\:focus\:tw-border-red-600:focus {
    --border-opacity: 1;
    border-color: #e53e3e;
    border-color: rgba(229, 62, 62, var(--border-opacity));
  }

  .lg\:focus\:tw-border-red-700:focus {
    --border-opacity: 1;
    border-color: #c53030;
    border-color: rgba(197, 48, 48, var(--border-opacity));
  }

  .lg\:focus\:tw-border-red-800:focus {
    --border-opacity: 1;
    border-color: #9b2c2c;
    border-color: rgba(155, 44, 44, var(--border-opacity));
  }

  .lg\:focus\:tw-border-red-900:focus {
    --border-opacity: 1;
    border-color: #742a2a;
    border-color: rgba(116, 42, 42, var(--border-opacity));
  }

  .lg\:focus\:tw-border-orange-100:focus {
    --border-opacity: 1;
    border-color: #fffaf0;
    border-color: rgba(255, 250, 240, var(--border-opacity));
  }

  .lg\:focus\:tw-border-orange-200:focus {
    --border-opacity: 1;
    border-color: #feebc8;
    border-color: rgba(254, 235, 200, var(--border-opacity));
  }

  .lg\:focus\:tw-border-orange-300:focus {
    --border-opacity: 1;
    border-color: #fbd38d;
    border-color: rgba(251, 211, 141, var(--border-opacity));
  }

  .lg\:focus\:tw-border-orange-400:focus {
    --border-opacity: 1;
    border-color: #f6ad55;
    border-color: rgba(246, 173, 85, var(--border-opacity));
  }

  .lg\:focus\:tw-border-orange-500:focus {
    --border-opacity: 1;
    border-color: #ed8936;
    border-color: rgba(237, 137, 54, var(--border-opacity));
  }

  .lg\:focus\:tw-border-orange-600:focus {
    --border-opacity: 1;
    border-color: #dd6b20;
    border-color: rgba(221, 107, 32, var(--border-opacity));
  }

  .lg\:focus\:tw-border-orange-700:focus {
    --border-opacity: 1;
    border-color: #c05621;
    border-color: rgba(192, 86, 33, var(--border-opacity));
  }

  .lg\:focus\:tw-border-orange-800:focus {
    --border-opacity: 1;
    border-color: #9c4221;
    border-color: rgba(156, 66, 33, var(--border-opacity));
  }

  .lg\:focus\:tw-border-orange-900:focus {
    --border-opacity: 1;
    border-color: #7b341e;
    border-color: rgba(123, 52, 30, var(--border-opacity));
  }

  .lg\:focus\:tw-border-yellow-100:focus {
    --border-opacity: 1;
    border-color: #fffff0;
    border-color: rgba(255, 255, 240, var(--border-opacity));
  }

  .lg\:focus\:tw-border-yellow-200:focus {
    --border-opacity: 1;
    border-color: #fefcbf;
    border-color: rgba(254, 252, 191, var(--border-opacity));
  }

  .lg\:focus\:tw-border-yellow-300:focus {
    --border-opacity: 1;
    border-color: #faf089;
    border-color: rgba(250, 240, 137, var(--border-opacity));
  }

  .lg\:focus\:tw-border-yellow-400:focus {
    --border-opacity: 1;
    border-color: #f6e05e;
    border-color: rgba(246, 224, 94, var(--border-opacity));
  }

  .lg\:focus\:tw-border-yellow-500:focus {
    --border-opacity: 1;
    border-color: #ecc94b;
    border-color: rgba(236, 201, 75, var(--border-opacity));
  }

  .lg\:focus\:tw-border-yellow-600:focus {
    --border-opacity: 1;
    border-color: #d69e2e;
    border-color: rgba(214, 158, 46, var(--border-opacity));
  }

  .lg\:focus\:tw-border-yellow-700:focus {
    --border-opacity: 1;
    border-color: #b7791f;
    border-color: rgba(183, 121, 31, var(--border-opacity));
  }

  .lg\:focus\:tw-border-yellow-800:focus {
    --border-opacity: 1;
    border-color: #975a16;
    border-color: rgba(151, 90, 22, var(--border-opacity));
  }

  .lg\:focus\:tw-border-yellow-900:focus {
    --border-opacity: 1;
    border-color: #744210;
    border-color: rgba(116, 66, 16, var(--border-opacity));
  }

  .lg\:focus\:tw-border-green-100:focus {
    --border-opacity: 1;
    border-color: #f0fff4;
    border-color: rgba(240, 255, 244, var(--border-opacity));
  }

  .lg\:focus\:tw-border-green-200:focus {
    --border-opacity: 1;
    border-color: #c6f6d5;
    border-color: rgba(198, 246, 213, var(--border-opacity));
  }

  .lg\:focus\:tw-border-green-300:focus {
    --border-opacity: 1;
    border-color: #9ae6b4;
    border-color: rgba(154, 230, 180, var(--border-opacity));
  }

  .lg\:focus\:tw-border-green-400:focus {
    --border-opacity: 1;
    border-color: #68d391;
    border-color: rgba(104, 211, 145, var(--border-opacity));
  }

  .lg\:focus\:tw-border-green-500:focus {
    --border-opacity: 1;
    border-color: #48bb78;
    border-color: rgba(72, 187, 120, var(--border-opacity));
  }

  .lg\:focus\:tw-border-green-600:focus {
    --border-opacity: 1;
    border-color: #38a169;
    border-color: rgba(56, 161, 105, var(--border-opacity));
  }

  .lg\:focus\:tw-border-green-700:focus {
    --border-opacity: 1;
    border-color: #2f855a;
    border-color: rgba(47, 133, 90, var(--border-opacity));
  }

  .lg\:focus\:tw-border-green-800:focus {
    --border-opacity: 1;
    border-color: #276749;
    border-color: rgba(39, 103, 73, var(--border-opacity));
  }

  .lg\:focus\:tw-border-green-900:focus {
    --border-opacity: 1;
    border-color: #22543d;
    border-color: rgba(34, 84, 61, var(--border-opacity));
  }

  .lg\:focus\:tw-border-teal-100:focus {
    --border-opacity: 1;
    border-color: #e6fffa;
    border-color: rgba(230, 255, 250, var(--border-opacity));
  }

  .lg\:focus\:tw-border-teal-200:focus {
    --border-opacity: 1;
    border-color: #b2f5ea;
    border-color: rgba(178, 245, 234, var(--border-opacity));
  }

  .lg\:focus\:tw-border-teal-300:focus {
    --border-opacity: 1;
    border-color: #81e6d9;
    border-color: rgba(129, 230, 217, var(--border-opacity));
  }

  .lg\:focus\:tw-border-teal-400:focus {
    --border-opacity: 1;
    border-color: #4fd1c5;
    border-color: rgba(79, 209, 197, var(--border-opacity));
  }

  .lg\:focus\:tw-border-teal-500:focus {
    --border-opacity: 1;
    border-color: #38b2ac;
    border-color: rgba(56, 178, 172, var(--border-opacity));
  }

  .lg\:focus\:tw-border-teal-600:focus {
    --border-opacity: 1;
    border-color: #319795;
    border-color: rgba(49, 151, 149, var(--border-opacity));
  }

  .lg\:focus\:tw-border-teal-700:focus {
    --border-opacity: 1;
    border-color: #2c7a7b;
    border-color: rgba(44, 122, 123, var(--border-opacity));
  }

  .lg\:focus\:tw-border-teal-800:focus {
    --border-opacity: 1;
    border-color: #285e61;
    border-color: rgba(40, 94, 97, var(--border-opacity));
  }

  .lg\:focus\:tw-border-teal-900:focus {
    --border-opacity: 1;
    border-color: #234e52;
    border-color: rgba(35, 78, 82, var(--border-opacity));
  }

  .lg\:focus\:tw-border-blue-100:focus {
    --border-opacity: 1;
    border-color: #ebf8ff;
    border-color: rgba(235, 248, 255, var(--border-opacity));
  }

  .lg\:focus\:tw-border-blue-200:focus {
    --border-opacity: 1;
    border-color: #bee3f8;
    border-color: rgba(190, 227, 248, var(--border-opacity));
  }

  .lg\:focus\:tw-border-blue-300:focus {
    --border-opacity: 1;
    border-color: #90cdf4;
    border-color: rgba(144, 205, 244, var(--border-opacity));
  }

  .lg\:focus\:tw-border-blue-400:focus {
    --border-opacity: 1;
    border-color: #63b3ed;
    border-color: rgba(99, 179, 237, var(--border-opacity));
  }

  .lg\:focus\:tw-border-blue-500:focus {
    --border-opacity: 1;
    border-color: #4299e1;
    border-color: rgba(66, 153, 225, var(--border-opacity));
  }

  .lg\:focus\:tw-border-blue-600:focus {
    --border-opacity: 1;
    border-color: #3182ce;
    border-color: rgba(49, 130, 206, var(--border-opacity));
  }

  .lg\:focus\:tw-border-blue-700:focus {
    --border-opacity: 1;
    border-color: #2b6cb0;
    border-color: rgba(43, 108, 176, var(--border-opacity));
  }

  .lg\:focus\:tw-border-blue-800:focus {
    --border-opacity: 1;
    border-color: #2c5282;
    border-color: rgba(44, 82, 130, var(--border-opacity));
  }

  .lg\:focus\:tw-border-blue-900:focus {
    --border-opacity: 1;
    border-color: #2a4365;
    border-color: rgba(42, 67, 101, var(--border-opacity));
  }

  .lg\:focus\:tw-border-indigo-100:focus {
    --border-opacity: 1;
    border-color: #ebf4ff;
    border-color: rgba(235, 244, 255, var(--border-opacity));
  }

  .lg\:focus\:tw-border-indigo-200:focus {
    --border-opacity: 1;
    border-color: #c3dafe;
    border-color: rgba(195, 218, 254, var(--border-opacity));
  }

  .lg\:focus\:tw-border-indigo-300:focus {
    --border-opacity: 1;
    border-color: #a3bffa;
    border-color: rgba(163, 191, 250, var(--border-opacity));
  }

  .lg\:focus\:tw-border-indigo-400:focus {
    --border-opacity: 1;
    border-color: #7f9cf5;
    border-color: rgba(127, 156, 245, var(--border-opacity));
  }

  .lg\:focus\:tw-border-indigo-500:focus {
    --border-opacity: 1;
    border-color: #667eea;
    border-color: rgba(102, 126, 234, var(--border-opacity));
  }

  .lg\:focus\:tw-border-indigo-600:focus {
    --border-opacity: 1;
    border-color: #5a67d8;
    border-color: rgba(90, 103, 216, var(--border-opacity));
  }

  .lg\:focus\:tw-border-indigo-700:focus {
    --border-opacity: 1;
    border-color: #4c51bf;
    border-color: rgba(76, 81, 191, var(--border-opacity));
  }

  .lg\:focus\:tw-border-indigo-800:focus {
    --border-opacity: 1;
    border-color: #434190;
    border-color: rgba(67, 65, 144, var(--border-opacity));
  }

  .lg\:focus\:tw-border-indigo-900:focus {
    --border-opacity: 1;
    border-color: #3c366b;
    border-color: rgba(60, 54, 107, var(--border-opacity));
  }

  .lg\:focus\:tw-border-purple-100:focus {
    --border-opacity: 1;
    border-color: #faf5ff;
    border-color: rgba(250, 245, 255, var(--border-opacity));
  }

  .lg\:focus\:tw-border-purple-200:focus {
    --border-opacity: 1;
    border-color: #e9d8fd;
    border-color: rgba(233, 216, 253, var(--border-opacity));
  }

  .lg\:focus\:tw-border-purple-300:focus {
    --border-opacity: 1;
    border-color: #d6bcfa;
    border-color: rgba(214, 188, 250, var(--border-opacity));
  }

  .lg\:focus\:tw-border-purple-400:focus {
    --border-opacity: 1;
    border-color: #b794f4;
    border-color: rgba(183, 148, 244, var(--border-opacity));
  }

  .lg\:focus\:tw-border-purple-500:focus {
    --border-opacity: 1;
    border-color: #9f7aea;
    border-color: rgba(159, 122, 234, var(--border-opacity));
  }

  .lg\:focus\:tw-border-purple-600:focus {
    --border-opacity: 1;
    border-color: #805ad5;
    border-color: rgba(128, 90, 213, var(--border-opacity));
  }

  .lg\:focus\:tw-border-purple-700:focus {
    --border-opacity: 1;
    border-color: #6b46c1;
    border-color: rgba(107, 70, 193, var(--border-opacity));
  }

  .lg\:focus\:tw-border-purple-800:focus {
    --border-opacity: 1;
    border-color: #553c9a;
    border-color: rgba(85, 60, 154, var(--border-opacity));
  }

  .lg\:focus\:tw-border-purple-900:focus {
    --border-opacity: 1;
    border-color: #44337a;
    border-color: rgba(68, 51, 122, var(--border-opacity));
  }

  .lg\:focus\:tw-border-pink-100:focus {
    --border-opacity: 1;
    border-color: #fff5f7;
    border-color: rgba(255, 245, 247, var(--border-opacity));
  }

  .lg\:focus\:tw-border-pink-200:focus {
    --border-opacity: 1;
    border-color: #fed7e2;
    border-color: rgba(254, 215, 226, var(--border-opacity));
  }

  .lg\:focus\:tw-border-pink-300:focus {
    --border-opacity: 1;
    border-color: #fbb6ce;
    border-color: rgba(251, 182, 206, var(--border-opacity));
  }

  .lg\:focus\:tw-border-pink-400:focus {
    --border-opacity: 1;
    border-color: #f687b3;
    border-color: rgba(246, 135, 179, var(--border-opacity));
  }

  .lg\:focus\:tw-border-pink-500:focus {
    --border-opacity: 1;
    border-color: #ed64a6;
    border-color: rgba(237, 100, 166, var(--border-opacity));
  }

  .lg\:focus\:tw-border-pink-600:focus {
    --border-opacity: 1;
    border-color: #d53f8c;
    border-color: rgba(213, 63, 140, var(--border-opacity));
  }

  .lg\:focus\:tw-border-pink-700:focus {
    --border-opacity: 1;
    border-color: #b83280;
    border-color: rgba(184, 50, 128, var(--border-opacity));
  }

  .lg\:focus\:tw-border-pink-800:focus {
    --border-opacity: 1;
    border-color: #97266d;
    border-color: rgba(151, 38, 109, var(--border-opacity));
  }

  .lg\:focus\:tw-border-pink-900:focus {
    --border-opacity: 1;
    border-color: #702459;
    border-color: rgba(112, 36, 89, var(--border-opacity));
  }

  .lg\:tw-border-opacity-0 {
    --border-opacity: 0;
  }

  .lg\:tw-border-opacity-25 {
    --border-opacity: 0.25;
  }

  .lg\:tw-border-opacity-50 {
    --border-opacity: 0.5;
  }

  .lg\:tw-border-opacity-75 {
    --border-opacity: 0.75;
  }

  .lg\:tw-border-opacity-100 {
    --border-opacity: 1;
  }

  .lg\:hover\:tw-border-opacity-0:hover {
    --border-opacity: 0;
  }

  .lg\:hover\:tw-border-opacity-25:hover {
    --border-opacity: 0.25;
  }

  .lg\:hover\:tw-border-opacity-50:hover {
    --border-opacity: 0.5;
  }

  .lg\:hover\:tw-border-opacity-75:hover {
    --border-opacity: 0.75;
  }

  .lg\:hover\:tw-border-opacity-100:hover {
    --border-opacity: 1;
  }

  .lg\:focus\:tw-border-opacity-0:focus {
    --border-opacity: 0;
  }

  .lg\:focus\:tw-border-opacity-25:focus {
    --border-opacity: 0.25;
  }

  .lg\:focus\:tw-border-opacity-50:focus {
    --border-opacity: 0.5;
  }

  .lg\:focus\:tw-border-opacity-75:focus {
    --border-opacity: 0.75;
  }

  .lg\:focus\:tw-border-opacity-100:focus {
    --border-opacity: 1;
  }

  .lg\:tw-rounded-none {
    border-radius: 0;
  }

  .lg\:tw-rounded-sm {
    border-radius: 0.125rem;
  }

  .lg\:tw-rounded {
    border-radius: 0.25rem;
  }

  .lg\:tw-rounded-md {
    border-radius: 0.375rem;
  }

  .lg\:tw-rounded-lg {
    border-radius: 0.5rem;
  }

  .lg\:tw-rounded-xl {
    border-radius: 0.75rem;
  }

  .lg\:tw-rounded-2xl {
    border-radius: 1rem;
  }

  .lg\:tw-rounded-3xl {
    border-radius: 1.5rem;
  }

  .lg\:tw-rounded-full {
    border-radius: 9999px;
  }

  .lg\:tw-rounded-t-none {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }

  .lg\:tw-rounded-r-none {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  .lg\:tw-rounded-b-none {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
  }

  .lg\:tw-rounded-l-none {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }

  .lg\:tw-rounded-t-sm {
    border-top-left-radius: 0.125rem;
    border-top-right-radius: 0.125rem;
  }

  .lg\:tw-rounded-r-sm {
    border-top-right-radius: 0.125rem;
    border-bottom-right-radius: 0.125rem;
  }

  .lg\:tw-rounded-b-sm {
    border-bottom-right-radius: 0.125rem;
    border-bottom-left-radius: 0.125rem;
  }

  .lg\:tw-rounded-l-sm {
    border-top-left-radius: 0.125rem;
    border-bottom-left-radius: 0.125rem;
  }

  .lg\:tw-rounded-t {
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem;
  }

  .lg\:tw-rounded-r {
    border-top-right-radius: 0.25rem;
    border-bottom-right-radius: 0.25rem;
  }

  .lg\:tw-rounded-b {
    border-bottom-right-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem;
  }

  .lg\:tw-rounded-l {
    border-top-left-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem;
  }

  .lg\:tw-rounded-t-md {
    border-top-left-radius: 0.375rem;
    border-top-right-radius: 0.375rem;
  }

  .lg\:tw-rounded-r-md {
    border-top-right-radius: 0.375rem;
    border-bottom-right-radius: 0.375rem;
  }

  .lg\:tw-rounded-b-md {
    border-bottom-right-radius: 0.375rem;
    border-bottom-left-radius: 0.375rem;
  }

  .lg\:tw-rounded-l-md {
    border-top-left-radius: 0.375rem;
    border-bottom-left-radius: 0.375rem;
  }

  .lg\:tw-rounded-t-lg {
    border-top-left-radius: 0.5rem;
    border-top-right-radius: 0.5rem;
  }

  .lg\:tw-rounded-r-lg {
    border-top-right-radius: 0.5rem;
    border-bottom-right-radius: 0.5rem;
  }

  .lg\:tw-rounded-b-lg {
    border-bottom-right-radius: 0.5rem;
    border-bottom-left-radius: 0.5rem;
  }

  .lg\:tw-rounded-l-lg {
    border-top-left-radius: 0.5rem;
    border-bottom-left-radius: 0.5rem;
  }

  .lg\:tw-rounded-t-xl {
    border-top-left-radius: 0.75rem;
    border-top-right-radius: 0.75rem;
  }

  .lg\:tw-rounded-r-xl {
    border-top-right-radius: 0.75rem;
    border-bottom-right-radius: 0.75rem;
  }

  .lg\:tw-rounded-b-xl {
    border-bottom-right-radius: 0.75rem;
    border-bottom-left-radius: 0.75rem;
  }

  .lg\:tw-rounded-l-xl {
    border-top-left-radius: 0.75rem;
    border-bottom-left-radius: 0.75rem;
  }

  .lg\:tw-rounded-t-2xl {
    border-top-left-radius: 1rem;
    border-top-right-radius: 1rem;
  }

  .lg\:tw-rounded-r-2xl {
    border-top-right-radius: 1rem;
    border-bottom-right-radius: 1rem;
  }

  .lg\:tw-rounded-b-2xl {
    border-bottom-right-radius: 1rem;
    border-bottom-left-radius: 1rem;
  }

  .lg\:tw-rounded-l-2xl {
    border-top-left-radius: 1rem;
    border-bottom-left-radius: 1rem;
  }

  .lg\:tw-rounded-t-3xl {
    border-top-left-radius: 1.5rem;
    border-top-right-radius: 1.5rem;
  }

  .lg\:tw-rounded-r-3xl {
    border-top-right-radius: 1.5rem;
    border-bottom-right-radius: 1.5rem;
  }

  .lg\:tw-rounded-b-3xl {
    border-bottom-right-radius: 1.5rem;
    border-bottom-left-radius: 1.5rem;
  }

  .lg\:tw-rounded-l-3xl {
    border-top-left-radius: 1.5rem;
    border-bottom-left-radius: 1.5rem;
  }

  .lg\:tw-rounded-t-full {
    border-top-left-radius: 9999px;
    border-top-right-radius: 9999px;
  }

  .lg\:tw-rounded-r-full {
    border-top-right-radius: 9999px;
    border-bottom-right-radius: 9999px;
  }

  .lg\:tw-rounded-b-full {
    border-bottom-right-radius: 9999px;
    border-bottom-left-radius: 9999px;
  }

  .lg\:tw-rounded-l-full {
    border-top-left-radius: 9999px;
    border-bottom-left-radius: 9999px;
  }

  .lg\:tw-rounded-tl-none {
    border-top-left-radius: 0;
  }

  .lg\:tw-rounded-tr-none {
    border-top-right-radius: 0;
  }

  .lg\:tw-rounded-br-none {
    border-bottom-right-radius: 0;
  }

  .lg\:tw-rounded-bl-none {
    border-bottom-left-radius: 0;
  }

  .lg\:tw-rounded-tl-sm {
    border-top-left-radius: 0.125rem;
  }

  .lg\:tw-rounded-tr-sm {
    border-top-right-radius: 0.125rem;
  }

  .lg\:tw-rounded-br-sm {
    border-bottom-right-radius: 0.125rem;
  }

  .lg\:tw-rounded-bl-sm {
    border-bottom-left-radius: 0.125rem;
  }

  .lg\:tw-rounded-tl {
    border-top-left-radius: 0.25rem;
  }

  .lg\:tw-rounded-tr {
    border-top-right-radius: 0.25rem;
  }

  .lg\:tw-rounded-br {
    border-bottom-right-radius: 0.25rem;
  }

  .lg\:tw-rounded-bl {
    border-bottom-left-radius: 0.25rem;
  }

  .lg\:tw-rounded-tl-md {
    border-top-left-radius: 0.375rem;
  }

  .lg\:tw-rounded-tr-md {
    border-top-right-radius: 0.375rem;
  }

  .lg\:tw-rounded-br-md {
    border-bottom-right-radius: 0.375rem;
  }

  .lg\:tw-rounded-bl-md {
    border-bottom-left-radius: 0.375rem;
  }

  .lg\:tw-rounded-tl-lg {
    border-top-left-radius: 0.5rem;
  }

  .lg\:tw-rounded-tr-lg {
    border-top-right-radius: 0.5rem;
  }

  .lg\:tw-rounded-br-lg {
    border-bottom-right-radius: 0.5rem;
  }

  .lg\:tw-rounded-bl-lg {
    border-bottom-left-radius: 0.5rem;
  }

  .lg\:tw-rounded-tl-xl {
    border-top-left-radius: 0.75rem;
  }

  .lg\:tw-rounded-tr-xl {
    border-top-right-radius: 0.75rem;
  }

  .lg\:tw-rounded-br-xl {
    border-bottom-right-radius: 0.75rem;
  }

  .lg\:tw-rounded-bl-xl {
    border-bottom-left-radius: 0.75rem;
  }

  .lg\:tw-rounded-tl-2xl {
    border-top-left-radius: 1rem;
  }

  .lg\:tw-rounded-tr-2xl {
    border-top-right-radius: 1rem;
  }

  .lg\:tw-rounded-br-2xl {
    border-bottom-right-radius: 1rem;
  }

  .lg\:tw-rounded-bl-2xl {
    border-bottom-left-radius: 1rem;
  }

  .lg\:tw-rounded-tl-3xl {
    border-top-left-radius: 1.5rem;
  }

  .lg\:tw-rounded-tr-3xl {
    border-top-right-radius: 1.5rem;
  }

  .lg\:tw-rounded-br-3xl {
    border-bottom-right-radius: 1.5rem;
  }

  .lg\:tw-rounded-bl-3xl {
    border-bottom-left-radius: 1.5rem;
  }

  .lg\:tw-rounded-tl-full {
    border-top-left-radius: 9999px;
  }

  .lg\:tw-rounded-tr-full {
    border-top-right-radius: 9999px;
  }

  .lg\:tw-rounded-br-full {
    border-bottom-right-radius: 9999px;
  }

  .lg\:tw-rounded-bl-full {
    border-bottom-left-radius: 9999px;
  }

  .lg\:tw-border-solid {
    border-style: solid;
  }

  .lg\:tw-border-dashed {
    border-style: dashed;
  }

  .lg\:tw-border-dotted {
    border-style: dotted;
  }

  .lg\:tw-border-double {
    border-style: double;
  }

  .lg\:tw-border-none {
    border-style: none;
  }

  .lg\:tw-border-0 {
    border-width: 0;
  }

  .lg\:tw-border-2 {
    border-width: 2px;
  }

  .lg\:tw-border-4 {
    border-width: 4px;
  }

  .lg\:tw-border-8 {
    border-width: 8px;
  }

  .lg\:tw-border {
    border-width: 1px;
  }

  .lg\:tw-border-t-0 {
    border-top-width: 0;
  }

  .lg\:tw-border-r-0 {
    border-right-width: 0;
  }

  .lg\:tw-border-b-0 {
    border-bottom-width: 0;
  }

  .lg\:tw-border-l-0 {
    border-left-width: 0;
  }

  .lg\:tw-border-t-2 {
    border-top-width: 2px;
  }

  .lg\:tw-border-r-2 {
    border-right-width: 2px;
  }

  .lg\:tw-border-b-2 {
    border-bottom-width: 2px;
  }

  .lg\:tw-border-l-2 {
    border-left-width: 2px;
  }

  .lg\:tw-border-t-4 {
    border-top-width: 4px;
  }

  .lg\:tw-border-r-4 {
    border-right-width: 4px;
  }

  .lg\:tw-border-b-4 {
    border-bottom-width: 4px;
  }

  .lg\:tw-border-l-4 {
    border-left-width: 4px;
  }

  .lg\:tw-border-t-8 {
    border-top-width: 8px;
  }

  .lg\:tw-border-r-8 {
    border-right-width: 8px;
  }

  .lg\:tw-border-b-8 {
    border-bottom-width: 8px;
  }

  .lg\:tw-border-l-8 {
    border-left-width: 8px;
  }

  .lg\:tw-border-t {
    border-top-width: 1px;
  }

  .lg\:tw-border-r {
    border-right-width: 1px;
  }

  .lg\:tw-border-b {
    border-bottom-width: 1px;
  }

  .lg\:tw-border-l {
    border-left-width: 1px;
  }

  .lg\:tw-box-border {
    box-sizing: border-box;
  }

  .lg\:tw-box-content {
    box-sizing: content-box;
  }

  .lg\:tw-cursor-auto {
    cursor: auto;
  }

  .lg\:tw-cursor-default {
    cursor: default;
  }

  .lg\:tw-cursor-pointer {
    cursor: pointer;
  }

  .lg\:tw-cursor-wait {
    cursor: wait;
  }

  .lg\:tw-cursor-text {
    cursor: text;
  }

  .lg\:tw-cursor-move {
    cursor: move;
  }

  .lg\:tw-cursor-not-allowed {
    cursor: not-allowed;
  }

  .lg\:tw-block {
    display: block;
  }

  .lg\:tw-inline-block {
    display: inline-block;
  }

  .lg\:tw-inline {
    display: inline;
  }

  .lg\:tw-flex {
    display: flex;
  }

  .lg\:tw-inline-flex {
    display: inline-flex;
  }

  .lg\:tw-table {
    display: table;
  }

  .lg\:tw-table-caption {
    display: table-caption;
  }

  .lg\:tw-table-cell {
    display: table-cell;
  }

  .lg\:tw-table-column {
    display: table-column;
  }

  .lg\:tw-table-column-group {
    display: table-column-group;
  }

  .lg\:tw-table-footer-group {
    display: table-footer-group;
  }

  .lg\:tw-table-header-group {
    display: table-header-group;
  }

  .lg\:tw-table-row-group {
    display: table-row-group;
  }

  .lg\:tw-table-row {
    display: table-row;
  }

  .lg\:tw-flow-root {
    display: flow-root;
  }

  .lg\:tw-grid {
    display: grid;
  }

  .lg\:tw-inline-grid {
    display: inline-grid;
  }

  .lg\:tw-contents {
    display: contents;
  }

  .lg\:tw-hidden {
    display: none;
  }

  .lg\:tw-flex-row {
    flex-direction: row;
  }

  .lg\:tw-flex-row-reverse {
    flex-direction: row-reverse;
  }

  .lg\:tw-flex-col {
    flex-direction: column;
  }

  .lg\:tw-flex-col-reverse {
    flex-direction: column-reverse;
  }

  .lg\:tw-flex-wrap {
    flex-wrap: wrap;
  }

  .lg\:tw-flex-wrap-reverse {
    flex-wrap: wrap-reverse;
  }

  .lg\:tw-flex-no-wrap {
    flex-wrap: nowrap;
  }

  .lg\:tw-place-items-auto {
    place-items: auto;
  }

  .lg\:tw-place-items-start {
    place-items: start;
  }

  .lg\:tw-place-items-end {
    place-items: end;
  }

  .lg\:tw-place-items-center {
    place-items: center;
  }

  .lg\:tw-place-items-stretch {
    place-items: stretch;
  }

  .lg\:tw-place-content-center {
    place-content: center;
  }

  .lg\:tw-place-content-start {
    place-content: start;
  }

  .lg\:tw-place-content-end {
    place-content: end;
  }

  .lg\:tw-place-content-between {
    place-content: space-between;
  }

  .lg\:tw-place-content-around {
    place-content: space-around;
  }

  .lg\:tw-place-content-evenly {
    place-content: space-evenly;
  }

  .lg\:tw-place-content-stretch {
    place-content: stretch;
  }

  .lg\:tw-place-self-auto {
    place-self: auto;
  }

  .lg\:tw-place-self-start {
    place-self: start;
  }

  .lg\:tw-place-self-end {
    place-self: end;
  }

  .lg\:tw-place-self-center {
    place-self: center;
  }

  .lg\:tw-place-self-stretch {
    place-self: stretch;
  }

  .lg\:tw-items-start {
    align-items: flex-start;
  }

  .lg\:tw-items-end {
    align-items: flex-end;
  }

  .lg\:tw-items-center {
    align-items: center;
  }

  .lg\:tw-items-baseline {
    align-items: baseline;
  }

  .lg\:tw-items-stretch {
    align-items: stretch;
  }

  .lg\:tw-content-center {
    align-content: center;
  }

  .lg\:tw-content-start {
    align-content: flex-start;
  }

  .lg\:tw-content-end {
    align-content: flex-end;
  }

  .lg\:tw-content-between {
    align-content: space-between;
  }

  .lg\:tw-content-around {
    align-content: space-around;
  }

  .lg\:tw-content-evenly {
    align-content: space-evenly;
  }

  .lg\:tw-self-auto {
    align-self: auto;
  }

  .lg\:tw-self-start {
    align-self: flex-start;
  }

  .lg\:tw-self-end {
    align-self: flex-end;
  }

  .lg\:tw-self-center {
    align-self: center;
  }

  .lg\:tw-self-stretch {
    align-self: stretch;
  }

  .lg\:tw-justify-items-auto {
    justify-items: auto;
  }

  .lg\:tw-justify-items-start {
    justify-items: start;
  }

  .lg\:tw-justify-items-end {
    justify-items: end;
  }

  .lg\:tw-justify-items-center {
    justify-items: center;
  }

  .lg\:tw-justify-items-stretch {
    justify-items: stretch;
  }

  .lg\:tw-justify-start {
    justify-content: flex-start;
  }

  .lg\:tw-justify-end {
    justify-content: flex-end;
  }

  .lg\:tw-justify-center {
    justify-content: center;
  }

  .lg\:tw-justify-between {
    justify-content: space-between;
  }

  .lg\:tw-justify-around {
    justify-content: space-around;
  }

  .lg\:tw-justify-evenly {
    justify-content: space-evenly;
  }

  .lg\:tw-justify-self-auto {
    justify-self: auto;
  }

  .lg\:tw-justify-self-start {
    justify-self: start;
  }

  .lg\:tw-justify-self-end {
    justify-self: end;
  }

  .lg\:tw-justify-self-center {
    justify-self: center;
  }

  .lg\:tw-justify-self-stretch {
    justify-self: stretch;
  }

  .lg\:tw-flex-1 {
    flex: 1 1 0%;
  }

  .lg\:tw-flex-auto {
    flex: 1 1 auto;
  }

  .lg\:tw-flex-initial {
    flex: 0 1 auto;
  }

  .lg\:tw-flex-none {
    flex: none;
  }

  .lg\:tw-flex-grow-0 {
    flex-grow: 0;
  }

  .lg\:tw-flex-grow {
    flex-grow: 1;
  }

  .lg\:tw-flex-shrink-0 {
    flex-shrink: 0;
  }

  .lg\:tw-flex-shrink {
    flex-shrink: 1;
  }

  .lg\:tw-order-1 {
    order: 1;
  }

  .lg\:tw-order-2 {
    order: 2;
  }

  .lg\:tw-order-3 {
    order: 3;
  }

  .lg\:tw-order-4 {
    order: 4;
  }

  .lg\:tw-order-5 {
    order: 5;
  }

  .lg\:tw-order-6 {
    order: 6;
  }

  .lg\:tw-order-7 {
    order: 7;
  }

  .lg\:tw-order-8 {
    order: 8;
  }

  .lg\:tw-order-9 {
    order: 9;
  }

  .lg\:tw-order-10 {
    order: 10;
  }

  .lg\:tw-order-11 {
    order: 11;
  }

  .lg\:tw-order-12 {
    order: 12;
  }

  .lg\:tw-order-first {
    order: -9999;
  }

  .lg\:tw-order-last {
    order: 9999;
  }

  .lg\:tw-order-none {
    order: 0;
  }

  .lg\:tw-float-right {
    float: right;
  }

  .lg\:tw-float-left {
    float: left;
  }

  .lg\:tw-float-none {
    float: none;
  }

  .lg\:tw-clearfix:after {
    content: "";
    display: table;
    clear: both;
  }

  .lg\:tw-clear-left {
    clear: left;
  }

  .lg\:tw-clear-right {
    clear: right;
  }

  .lg\:tw-clear-both {
    clear: both;
  }

  .lg\:tw-clear-none {
    clear: none;
  }

  .lg\:tw-font-sans {
    font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  }

  .lg\:tw-font-serif {
    font-family: Georgia, Cambria, "Times New Roman", Times, serif;
  }

  .lg\:tw-font-mono {
    font-family: Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  }

  .lg\:tw-font-hairline {
    font-weight: 100;
  }

  .lg\:tw-font-thin {
    font-weight: 200;
  }

  .lg\:tw-font-light {
    font-weight: 300;
  }

  .lg\:tw-font-normal {
    font-weight: 400;
  }

  .lg\:tw-font-medium {
    font-weight: 500;
  }

  .lg\:tw-font-semibold {
    font-weight: 600;
  }

  .lg\:tw-font-bold {
    font-weight: 700;
  }

  .lg\:tw-font-extrabold {
    font-weight: 800;
  }

  .lg\:tw-font-black {
    font-weight: 900;
  }

  .lg\:hover\:tw-font-hairline:hover {
    font-weight: 100;
  }

  .lg\:hover\:tw-font-thin:hover {
    font-weight: 200;
  }

  .lg\:hover\:tw-font-light:hover {
    font-weight: 300;
  }

  .lg\:hover\:tw-font-normal:hover {
    font-weight: 400;
  }

  .lg\:hover\:tw-font-medium:hover {
    font-weight: 500;
  }

  .lg\:hover\:tw-font-semibold:hover {
    font-weight: 600;
  }

  .lg\:hover\:tw-font-bold:hover {
    font-weight: 700;
  }

  .lg\:hover\:tw-font-extrabold:hover {
    font-weight: 800;
  }

  .lg\:hover\:tw-font-black:hover {
    font-weight: 900;
  }

  .lg\:focus\:tw-font-hairline:focus {
    font-weight: 100;
  }

  .lg\:focus\:tw-font-thin:focus {
    font-weight: 200;
  }

  .lg\:focus\:tw-font-light:focus {
    font-weight: 300;
  }

  .lg\:focus\:tw-font-normal:focus {
    font-weight: 400;
  }

  .lg\:focus\:tw-font-medium:focus {
    font-weight: 500;
  }

  .lg\:focus\:tw-font-semibold:focus {
    font-weight: 600;
  }

  .lg\:focus\:tw-font-bold:focus {
    font-weight: 700;
  }

  .lg\:focus\:tw-font-extrabold:focus {
    font-weight: 800;
  }

  .lg\:focus\:tw-font-black:focus {
    font-weight: 900;
  }

  .lg\:tw-h-0 {
    height: 0;
  }

  .lg\:tw-h-1 {
    height: 0.25rem;
  }

  .lg\:tw-h-2 {
    height: 0.5rem;
  }

  .lg\:tw-h-3 {
    height: 0.75rem;
  }

  .lg\:tw-h-4 {
    height: 1rem;
  }

  .lg\:tw-h-5 {
    height: 1.25rem;
  }

  .lg\:tw-h-6 {
    height: 1.5rem;
  }

  .lg\:tw-h-8 {
    height: 2rem;
  }

  .lg\:tw-h-10 {
    height: 2.5rem;
  }

  .lg\:tw-h-12 {
    height: 3rem;
  }

  .lg\:tw-h-16 {
    height: 4rem;
  }

  .lg\:tw-h-20 {
    height: 5rem;
  }

  .lg\:tw-h-24 {
    height: 6rem;
  }

  .lg\:tw-h-32 {
    height: 8rem;
  }

  .lg\:tw-h-40 {
    height: 10rem;
  }

  .lg\:tw-h-48 {
    height: 12rem;
  }

  .lg\:tw-h-56 {
    height: 14rem;
  }

  .lg\:tw-h-64 {
    height: 16rem;
  }

  .lg\:tw-h-auto {
    height: auto;
  }

  .lg\:tw-h-px {
    height: 1px;
  }

  .lg\:tw-h-270px {
    height: 270px;
  }

  .lg\:tw-h-400px {
    height: 400px;
  }

  .lg\:tw-h-464px {
    height: 464px;
  }

  .lg\:tw-h-480px {
    height: 480px;
  }

  .lg\:tw-h-500px {
    height: 500px;
  }

  .lg\:tw-h-576px {
    height: 576px;
  }

  .lg\:tw-h-600px {
    height: 600px;
  }

  .lg\:tw-h-672px {
    height: 672px;
  }

  .lg\:tw-h-full {
    height: 100%;
  }

  .lg\:tw-h-screen {
    height: 100vh;
  }

  .lg\:tw-text-xs {
    font-size: 0.75rem;
  }

  .lg\:tw-text-sm {
    font-size: 0.875rem;
  }

  .lg\:tw-text-base {
    font-size: 1rem;
  }

  .lg\:tw-text-lg {
    font-size: 1.125rem;
  }

  .lg\:tw-text-xl {
    font-size: 1.25rem;
  }

  .lg\:tw-text-2xl {
    font-size: 1.5rem;
  }

  .lg\:tw-text-3xl {
    font-size: 1.875rem;
  }

  .lg\:tw-text-4xl {
    font-size: 2.25rem;
  }

  .lg\:tw-text-5xl {
    font-size: 3rem;
  }

  .lg\:tw-text-6xl {
    font-size: 4rem;
  }

  .lg\:tw-leading-3 {
    line-height: .75rem;
  }

  .lg\:tw-leading-4 {
    line-height: 1rem;
  }

  .lg\:tw-leading-5 {
    line-height: 1.25rem;
  }

  .lg\:tw-leading-6 {
    line-height: 1.5rem;
  }

  .lg\:tw-leading-7 {
    line-height: 1.75rem;
  }

  .lg\:tw-leading-8 {
    line-height: 2rem;
  }

  .lg\:tw-leading-9 {
    line-height: 2.25rem;
  }

  .lg\:tw-leading-10 {
    line-height: 2.5rem;
  }

  .lg\:tw-leading-none {
    line-height: 1;
  }

  .lg\:tw-leading-tight {
    line-height: 1.25;
  }

  .lg\:tw-leading-snug {
    line-height: 1.375;
  }

  .lg\:tw-leading-normal {
    line-height: 1.5;
  }

  .lg\:tw-leading-relaxed {
    line-height: 1.625;
  }

  .lg\:tw-leading-loose {
    line-height: 2;
  }

  .lg\:tw-list-inside {
    list-style-position: inside;
  }

  .lg\:tw-list-outside {
    list-style-position: outside;
  }

  .lg\:tw-list-none {
    list-style-type: none;
  }

  .lg\:tw-list-disc {
    list-style-type: disc;
  }

  .lg\:tw-list-decimal {
    list-style-type: decimal;
  }

  .lg\:tw-m-0 {
    margin: 0;
  }

  .lg\:tw-m-1 {
    margin: 0.25rem;
  }

  .lg\:tw-m-2 {
    margin: 0.5rem;
  }

  .lg\:tw-m-3 {
    margin: 0.75rem;
  }

  .lg\:tw-m-4 {
    margin: 1rem;
  }

  .lg\:tw-m-5 {
    margin: 1.25rem;
  }

  .lg\:tw-m-6 {
    margin: 1.5rem;
  }

  .lg\:tw-m-8 {
    margin: 2rem;
  }

  .lg\:tw-m-10 {
    margin: 2.5rem;
  }

  .lg\:tw-m-12 {
    margin: 3rem;
  }

  .lg\:tw-m-16 {
    margin: 4rem;
  }

  .lg\:tw-m-20 {
    margin: 5rem;
  }

  .lg\:tw-m-24 {
    margin: 6rem;
  }

  .lg\:tw-m-32 {
    margin: 8rem;
  }

  .lg\:tw-m-40 {
    margin: 10rem;
  }

  .lg\:tw-m-48 {
    margin: 12rem;
  }

  .lg\:tw-m-56 {
    margin: 14rem;
  }

  .lg\:tw-m-64 {
    margin: 16rem;
  }

  .lg\:tw-m-auto {
    margin: auto;
  }

  .lg\:tw-m-px {
    margin: 1px;
  }

  .lg\:tw-m-270px {
    margin: 270px;
  }

  .lg\:tw-m-400px {
    margin: 400px;
  }

  .lg\:tw-m-464px {
    margin: 464px;
  }

  .lg\:tw-m-480px {
    margin: 480px;
  }

  .lg\:tw-m-500px {
    margin: 500px;
  }

  .lg\:tw-m-576px {
    margin: 576px;
  }

  .lg\:tw-m-600px {
    margin: 600px;
  }

  .lg\:tw-m-672px {
    margin: 672px;
  }

  .lg\:tw--m-1 {
    margin: -0.25rem;
  }

  .lg\:tw--m-2 {
    margin: -0.5rem;
  }

  .lg\:tw--m-3 {
    margin: -0.75rem;
  }

  .lg\:tw--m-4 {
    margin: -1rem;
  }

  .lg\:tw--m-5 {
    margin: -1.25rem;
  }

  .lg\:tw--m-6 {
    margin: -1.5rem;
  }

  .lg\:tw--m-8 {
    margin: -2rem;
  }

  .lg\:tw--m-10 {
    margin: -2.5rem;
  }

  .lg\:tw--m-12 {
    margin: -3rem;
  }

  .lg\:tw--m-16 {
    margin: -4rem;
  }

  .lg\:tw--m-20 {
    margin: -5rem;
  }

  .lg\:tw--m-24 {
    margin: -6rem;
  }

  .lg\:tw--m-32 {
    margin: -8rem;
  }

  .lg\:tw--m-40 {
    margin: -10rem;
  }

  .lg\:tw--m-48 {
    margin: -12rem;
  }

  .lg\:tw--m-56 {
    margin: -14rem;
  }

  .lg\:tw--m-64 {
    margin: -16rem;
  }

  .lg\:tw--m-px {
    margin: -1px;
  }

  .lg\:tw--m-270px {
    margin: -270px;
  }

  .lg\:tw--m-400px {
    margin: -400px;
  }

  .lg\:tw--m-464px {
    margin: -464px;
  }

  .lg\:tw--m-480px {
    margin: -480px;
  }

  .lg\:tw--m-500px {
    margin: -500px;
  }

  .lg\:tw--m-576px {
    margin: -576px;
  }

  .lg\:tw--m-600px {
    margin: -600px;
  }

  .lg\:tw--m-672px {
    margin: -672px;
  }

  .lg\:tw-my-0 {
    margin-top: 0;
    margin-bottom: 0;
  }

  .lg\:tw-mx-0 {
    margin-left: 0;
    margin-right: 0;
  }

  .lg\:tw-my-1 {
    margin-top: 0.25rem;
    margin-bottom: 0.25rem;
  }

  .lg\:tw-mx-1 {
    margin-left: 0.25rem;
    margin-right: 0.25rem;
  }

  .lg\:tw-my-2 {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
  }

  .lg\:tw-mx-2 {
    margin-left: 0.5rem;
    margin-right: 0.5rem;
  }

  .lg\:tw-my-3 {
    margin-top: 0.75rem;
    margin-bottom: 0.75rem;
  }

  .lg\:tw-mx-3 {
    margin-left: 0.75rem;
    margin-right: 0.75rem;
  }

  .lg\:tw-my-4 {
    margin-top: 1rem;
    margin-bottom: 1rem;
  }

  .lg\:tw-mx-4 {
    margin-left: 1rem;
    margin-right: 1rem;
  }

  .lg\:tw-my-5 {
    margin-top: 1.25rem;
    margin-bottom: 1.25rem;
  }

  .lg\:tw-mx-5 {
    margin-left: 1.25rem;
    margin-right: 1.25rem;
  }

  .lg\:tw-my-6 {
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
  }

  .lg\:tw-mx-6 {
    margin-left: 1.5rem;
    margin-right: 1.5rem;
  }

  .lg\:tw-my-8 {
    margin-top: 2rem;
    margin-bottom: 2rem;
  }

  .lg\:tw-mx-8 {
    margin-left: 2rem;
    margin-right: 2rem;
  }

  .lg\:tw-my-10 {
    margin-top: 2.5rem;
    margin-bottom: 2.5rem;
  }

  .lg\:tw-mx-10 {
    margin-left: 2.5rem;
    margin-right: 2.5rem;
  }

  .lg\:tw-my-12 {
    margin-top: 3rem;
    margin-bottom: 3rem;
  }

  .lg\:tw-mx-12 {
    margin-left: 3rem;
    margin-right: 3rem;
  }

  .lg\:tw-my-16 {
    margin-top: 4rem;
    margin-bottom: 4rem;
  }

  .lg\:tw-mx-16 {
    margin-left: 4rem;
    margin-right: 4rem;
  }

  .lg\:tw-my-20 {
    margin-top: 5rem;
    margin-bottom: 5rem;
  }

  .lg\:tw-mx-20 {
    margin-left: 5rem;
    margin-right: 5rem;
  }

  .lg\:tw-my-24 {
    margin-top: 6rem;
    margin-bottom: 6rem;
  }

  .lg\:tw-mx-24 {
    margin-left: 6rem;
    margin-right: 6rem;
  }

  .lg\:tw-my-32 {
    margin-top: 8rem;
    margin-bottom: 8rem;
  }

  .lg\:tw-mx-32 {
    margin-left: 8rem;
    margin-right: 8rem;
  }

  .lg\:tw-my-40 {
    margin-top: 10rem;
    margin-bottom: 10rem;
  }

  .lg\:tw-mx-40 {
    margin-left: 10rem;
    margin-right: 10rem;
  }

  .lg\:tw-my-48 {
    margin-top: 12rem;
    margin-bottom: 12rem;
  }

  .lg\:tw-mx-48 {
    margin-left: 12rem;
    margin-right: 12rem;
  }

  .lg\:tw-my-56 {
    margin-top: 14rem;
    margin-bottom: 14rem;
  }

  .lg\:tw-mx-56 {
    margin-left: 14rem;
    margin-right: 14rem;
  }

  .lg\:tw-my-64 {
    margin-top: 16rem;
    margin-bottom: 16rem;
  }

  .lg\:tw-mx-64 {
    margin-left: 16rem;
    margin-right: 16rem;
  }

  .lg\:tw-my-auto {
    margin-top: auto;
    margin-bottom: auto;
  }

  .lg\:tw-mx-auto {
    margin-left: auto;
    margin-right: auto;
  }

  .lg\:tw-my-px {
    margin-top: 1px;
    margin-bottom: 1px;
  }

  .lg\:tw-mx-px {
    margin-left: 1px;
    margin-right: 1px;
  }

  .lg\:tw-my-270px {
    margin-top: 270px;
    margin-bottom: 270px;
  }

  .lg\:tw-mx-270px {
    margin-left: 270px;
    margin-right: 270px;
  }

  .lg\:tw-my-400px {
    margin-top: 400px;
    margin-bottom: 400px;
  }

  .lg\:tw-mx-400px {
    margin-left: 400px;
    margin-right: 400px;
  }

  .lg\:tw-my-464px {
    margin-top: 464px;
    margin-bottom: 464px;
  }

  .lg\:tw-mx-464px {
    margin-left: 464px;
    margin-right: 464px;
  }

  .lg\:tw-my-480px {
    margin-top: 480px;
    margin-bottom: 480px;
  }

  .lg\:tw-mx-480px {
    margin-left: 480px;
    margin-right: 480px;
  }

  .lg\:tw-my-500px {
    margin-top: 500px;
    margin-bottom: 500px;
  }

  .lg\:tw-mx-500px {
    margin-left: 500px;
    margin-right: 500px;
  }

  .lg\:tw-my-576px {
    margin-top: 576px;
    margin-bottom: 576px;
  }

  .lg\:tw-mx-576px {
    margin-left: 576px;
    margin-right: 576px;
  }

  .lg\:tw-my-600px {
    margin-top: 600px;
    margin-bottom: 600px;
  }

  .lg\:tw-mx-600px {
    margin-left: 600px;
    margin-right: 600px;
  }

  .lg\:tw-my-672px {
    margin-top: 672px;
    margin-bottom: 672px;
  }

  .lg\:tw-mx-672px {
    margin-left: 672px;
    margin-right: 672px;
  }

  .lg\:tw--my-1 {
    margin-top: -0.25rem;
    margin-bottom: -0.25rem;
  }

  .lg\:tw--mx-1 {
    margin-left: -0.25rem;
    margin-right: -0.25rem;
  }

  .lg\:tw--my-2 {
    margin-top: -0.5rem;
    margin-bottom: -0.5rem;
  }

  .lg\:tw--mx-2 {
    margin-left: -0.5rem;
    margin-right: -0.5rem;
  }

  .lg\:tw--my-3 {
    margin-top: -0.75rem;
    margin-bottom: -0.75rem;
  }

  .lg\:tw--mx-3 {
    margin-left: -0.75rem;
    margin-right: -0.75rem;
  }

  .lg\:tw--my-4 {
    margin-top: -1rem;
    margin-bottom: -1rem;
  }

  .lg\:tw--mx-4 {
    margin-left: -1rem;
    margin-right: -1rem;
  }

  .lg\:tw--my-5 {
    margin-top: -1.25rem;
    margin-bottom: -1.25rem;
  }

  .lg\:tw--mx-5 {
    margin-left: -1.25rem;
    margin-right: -1.25rem;
  }

  .lg\:tw--my-6 {
    margin-top: -1.5rem;
    margin-bottom: -1.5rem;
  }

  .lg\:tw--mx-6 {
    margin-left: -1.5rem;
    margin-right: -1.5rem;
  }

  .lg\:tw--my-8 {
    margin-top: -2rem;
    margin-bottom: -2rem;
  }

  .lg\:tw--mx-8 {
    margin-left: -2rem;
    margin-right: -2rem;
  }

  .lg\:tw--my-10 {
    margin-top: -2.5rem;
    margin-bottom: -2.5rem;
  }

  .lg\:tw--mx-10 {
    margin-left: -2.5rem;
    margin-right: -2.5rem;
  }

  .lg\:tw--my-12 {
    margin-top: -3rem;
    margin-bottom: -3rem;
  }

  .lg\:tw--mx-12 {
    margin-left: -3rem;
    margin-right: -3rem;
  }

  .lg\:tw--my-16 {
    margin-top: -4rem;
    margin-bottom: -4rem;
  }

  .lg\:tw--mx-16 {
    margin-left: -4rem;
    margin-right: -4rem;
  }

  .lg\:tw--my-20 {
    margin-top: -5rem;
    margin-bottom: -5rem;
  }

  .lg\:tw--mx-20 {
    margin-left: -5rem;
    margin-right: -5rem;
  }

  .lg\:tw--my-24 {
    margin-top: -6rem;
    margin-bottom: -6rem;
  }

  .lg\:tw--mx-24 {
    margin-left: -6rem;
    margin-right: -6rem;
  }

  .lg\:tw--my-32 {
    margin-top: -8rem;
    margin-bottom: -8rem;
  }

  .lg\:tw--mx-32 {
    margin-left: -8rem;
    margin-right: -8rem;
  }

  .lg\:tw--my-40 {
    margin-top: -10rem;
    margin-bottom: -10rem;
  }

  .lg\:tw--mx-40 {
    margin-left: -10rem;
    margin-right: -10rem;
  }

  .lg\:tw--my-48 {
    margin-top: -12rem;
    margin-bottom: -12rem;
  }

  .lg\:tw--mx-48 {
    margin-left: -12rem;
    margin-right: -12rem;
  }

  .lg\:tw--my-56 {
    margin-top: -14rem;
    margin-bottom: -14rem;
  }

  .lg\:tw--mx-56 {
    margin-left: -14rem;
    margin-right: -14rem;
  }

  .lg\:tw--my-64 {
    margin-top: -16rem;
    margin-bottom: -16rem;
  }

  .lg\:tw--mx-64 {
    margin-left: -16rem;
    margin-right: -16rem;
  }

  .lg\:tw--my-px {
    margin-top: -1px;
    margin-bottom: -1px;
  }

  .lg\:tw--mx-px {
    margin-left: -1px;
    margin-right: -1px;
  }

  .lg\:tw--my-270px {
    margin-top: -270px;
    margin-bottom: -270px;
  }

  .lg\:tw--mx-270px {
    margin-left: -270px;
    margin-right: -270px;
  }

  .lg\:tw--my-400px {
    margin-top: -400px;
    margin-bottom: -400px;
  }

  .lg\:tw--mx-400px {
    margin-left: -400px;
    margin-right: -400px;
  }

  .lg\:tw--my-464px {
    margin-top: -464px;
    margin-bottom: -464px;
  }

  .lg\:tw--mx-464px {
    margin-left: -464px;
    margin-right: -464px;
  }

  .lg\:tw--my-480px {
    margin-top: -480px;
    margin-bottom: -480px;
  }

  .lg\:tw--mx-480px {
    margin-left: -480px;
    margin-right: -480px;
  }

  .lg\:tw--my-500px {
    margin-top: -500px;
    margin-bottom: -500px;
  }

  .lg\:tw--mx-500px {
    margin-left: -500px;
    margin-right: -500px;
  }

  .lg\:tw--my-576px {
    margin-top: -576px;
    margin-bottom: -576px;
  }

  .lg\:tw--mx-576px {
    margin-left: -576px;
    margin-right: -576px;
  }

  .lg\:tw--my-600px {
    margin-top: -600px;
    margin-bottom: -600px;
  }

  .lg\:tw--mx-600px {
    margin-left: -600px;
    margin-right: -600px;
  }

  .lg\:tw--my-672px {
    margin-top: -672px;
    margin-bottom: -672px;
  }

  .lg\:tw--mx-672px {
    margin-left: -672px;
    margin-right: -672px;
  }

  .lg\:tw-mt-0 {
    margin-top: 0;
  }

  .lg\:tw-mr-0 {
    margin-right: 0;
  }

  .lg\:tw-mb-0 {
    margin-bottom: 0;
  }

  .lg\:tw-ml-0 {
    margin-left: 0;
  }

  .lg\:tw-mt-1 {
    margin-top: 0.25rem;
  }

  .lg\:tw-mr-1 {
    margin-right: 0.25rem;
  }

  .lg\:tw-mb-1 {
    margin-bottom: 0.25rem;
  }

  .lg\:tw-ml-1 {
    margin-left: 0.25rem;
  }

  .lg\:tw-mt-2 {
    margin-top: 0.5rem;
  }

  .lg\:tw-mr-2 {
    margin-right: 0.5rem;
  }

  .lg\:tw-mb-2 {
    margin-bottom: 0.5rem;
  }

  .lg\:tw-ml-2 {
    margin-left: 0.5rem;
  }

  .lg\:tw-mt-3 {
    margin-top: 0.75rem;
  }

  .lg\:tw-mr-3 {
    margin-right: 0.75rem;
  }

  .lg\:tw-mb-3 {
    margin-bottom: 0.75rem;
  }

  .lg\:tw-ml-3 {
    margin-left: 0.75rem;
  }

  .lg\:tw-mt-4 {
    margin-top: 1rem;
  }

  .lg\:tw-mr-4 {
    margin-right: 1rem;
  }

  .lg\:tw-mb-4 {
    margin-bottom: 1rem;
  }

  .lg\:tw-ml-4 {
    margin-left: 1rem;
  }

  .lg\:tw-mt-5 {
    margin-top: 1.25rem;
  }

  .lg\:tw-mr-5 {
    margin-right: 1.25rem;
  }

  .lg\:tw-mb-5 {
    margin-bottom: 1.25rem;
  }

  .lg\:tw-ml-5 {
    margin-left: 1.25rem;
  }

  .lg\:tw-mt-6 {
    margin-top: 1.5rem;
  }

  .lg\:tw-mr-6 {
    margin-right: 1.5rem;
  }

  .lg\:tw-mb-6 {
    margin-bottom: 1.5rem;
  }

  .lg\:tw-ml-6 {
    margin-left: 1.5rem;
  }

  .lg\:tw-mt-8 {
    margin-top: 2rem;
  }

  .lg\:tw-mr-8 {
    margin-right: 2rem;
  }

  .lg\:tw-mb-8 {
    margin-bottom: 2rem;
  }

  .lg\:tw-ml-8 {
    margin-left: 2rem;
  }

  .lg\:tw-mt-10 {
    margin-top: 2.5rem;
  }

  .lg\:tw-mr-10 {
    margin-right: 2.5rem;
  }

  .lg\:tw-mb-10 {
    margin-bottom: 2.5rem;
  }

  .lg\:tw-ml-10 {
    margin-left: 2.5rem;
  }

  .lg\:tw-mt-12 {
    margin-top: 3rem;
  }

  .lg\:tw-mr-12 {
    margin-right: 3rem;
  }

  .lg\:tw-mb-12 {
    margin-bottom: 3rem;
  }

  .lg\:tw-ml-12 {
    margin-left: 3rem;
  }

  .lg\:tw-mt-16 {
    margin-top: 4rem;
  }

  .lg\:tw-mr-16 {
    margin-right: 4rem;
  }

  .lg\:tw-mb-16 {
    margin-bottom: 4rem;
  }

  .lg\:tw-ml-16 {
    margin-left: 4rem;
  }

  .lg\:tw-mt-20 {
    margin-top: 5rem;
  }

  .lg\:tw-mr-20 {
    margin-right: 5rem;
  }

  .lg\:tw-mb-20 {
    margin-bottom: 5rem;
  }

  .lg\:tw-ml-20 {
    margin-left: 5rem;
  }

  .lg\:tw-mt-24 {
    margin-top: 6rem;
  }

  .lg\:tw-mr-24 {
    margin-right: 6rem;
  }

  .lg\:tw-mb-24 {
    margin-bottom: 6rem;
  }

  .lg\:tw-ml-24 {
    margin-left: 6rem;
  }

  .lg\:tw-mt-32 {
    margin-top: 8rem;
  }

  .lg\:tw-mr-32 {
    margin-right: 8rem;
  }

  .lg\:tw-mb-32 {
    margin-bottom: 8rem;
  }

  .lg\:tw-ml-32 {
    margin-left: 8rem;
  }

  .lg\:tw-mt-40 {
    margin-top: 10rem;
  }

  .lg\:tw-mr-40 {
    margin-right: 10rem;
  }

  .lg\:tw-mb-40 {
    margin-bottom: 10rem;
  }

  .lg\:tw-ml-40 {
    margin-left: 10rem;
  }

  .lg\:tw-mt-48 {
    margin-top: 12rem;
  }

  .lg\:tw-mr-48 {
    margin-right: 12rem;
  }

  .lg\:tw-mb-48 {
    margin-bottom: 12rem;
  }

  .lg\:tw-ml-48 {
    margin-left: 12rem;
  }

  .lg\:tw-mt-56 {
    margin-top: 14rem;
  }

  .lg\:tw-mr-56 {
    margin-right: 14rem;
  }

  .lg\:tw-mb-56 {
    margin-bottom: 14rem;
  }

  .lg\:tw-ml-56 {
    margin-left: 14rem;
  }

  .lg\:tw-mt-64 {
    margin-top: 16rem;
  }

  .lg\:tw-mr-64 {
    margin-right: 16rem;
  }

  .lg\:tw-mb-64 {
    margin-bottom: 16rem;
  }

  .lg\:tw-ml-64 {
    margin-left: 16rem;
  }

  .lg\:tw-mt-auto {
    margin-top: auto;
  }

  .lg\:tw-mr-auto {
    margin-right: auto;
  }

  .lg\:tw-mb-auto {
    margin-bottom: auto;
  }

  .lg\:tw-ml-auto {
    margin-left: auto;
  }

  .lg\:tw-mt-px {
    margin-top: 1px;
  }

  .lg\:tw-mr-px {
    margin-right: 1px;
  }

  .lg\:tw-mb-px {
    margin-bottom: 1px;
  }

  .lg\:tw-ml-px {
    margin-left: 1px;
  }

  .lg\:tw-mt-270px {
    margin-top: 270px;
  }

  .lg\:tw-mr-270px {
    margin-right: 270px;
  }

  .lg\:tw-mb-270px {
    margin-bottom: 270px;
  }

  .lg\:tw-ml-270px {
    margin-left: 270px;
  }

  .lg\:tw-mt-400px {
    margin-top: 400px;
  }

  .lg\:tw-mr-400px {
    margin-right: 400px;
  }

  .lg\:tw-mb-400px {
    margin-bottom: 400px;
  }

  .lg\:tw-ml-400px {
    margin-left: 400px;
  }

  .lg\:tw-mt-464px {
    margin-top: 464px;
  }

  .lg\:tw-mr-464px {
    margin-right: 464px;
  }

  .lg\:tw-mb-464px {
    margin-bottom: 464px;
  }

  .lg\:tw-ml-464px {
    margin-left: 464px;
  }

  .lg\:tw-mt-480px {
    margin-top: 480px;
  }

  .lg\:tw-mr-480px {
    margin-right: 480px;
  }

  .lg\:tw-mb-480px {
    margin-bottom: 480px;
  }

  .lg\:tw-ml-480px {
    margin-left: 480px;
  }

  .lg\:tw-mt-500px {
    margin-top: 500px;
  }

  .lg\:tw-mr-500px {
    margin-right: 500px;
  }

  .lg\:tw-mb-500px {
    margin-bottom: 500px;
  }

  .lg\:tw-ml-500px {
    margin-left: 500px;
  }

  .lg\:tw-mt-576px {
    margin-top: 576px;
  }

  .lg\:tw-mr-576px {
    margin-right: 576px;
  }

  .lg\:tw-mb-576px {
    margin-bottom: 576px;
  }

  .lg\:tw-ml-576px {
    margin-left: 576px;
  }

  .lg\:tw-mt-600px {
    margin-top: 600px;
  }

  .lg\:tw-mr-600px {
    margin-right: 600px;
  }

  .lg\:tw-mb-600px {
    margin-bottom: 600px;
  }

  .lg\:tw-ml-600px {
    margin-left: 600px;
  }

  .lg\:tw-mt-672px {
    margin-top: 672px;
  }

  .lg\:tw-mr-672px {
    margin-right: 672px;
  }

  .lg\:tw-mb-672px {
    margin-bottom: 672px;
  }

  .lg\:tw-ml-672px {
    margin-left: 672px;
  }

  .lg\:tw--mt-1 {
    margin-top: -0.25rem;
  }

  .lg\:tw--mr-1 {
    margin-right: -0.25rem;
  }

  .lg\:tw--mb-1 {
    margin-bottom: -0.25rem;
  }

  .lg\:tw--ml-1 {
    margin-left: -0.25rem;
  }

  .lg\:tw--mt-2 {
    margin-top: -0.5rem;
  }

  .lg\:tw--mr-2 {
    margin-right: -0.5rem;
  }

  .lg\:tw--mb-2 {
    margin-bottom: -0.5rem;
  }

  .lg\:tw--ml-2 {
    margin-left: -0.5rem;
  }

  .lg\:tw--mt-3 {
    margin-top: -0.75rem;
  }

  .lg\:tw--mr-3 {
    margin-right: -0.75rem;
  }

  .lg\:tw--mb-3 {
    margin-bottom: -0.75rem;
  }

  .lg\:tw--ml-3 {
    margin-left: -0.75rem;
  }

  .lg\:tw--mt-4 {
    margin-top: -1rem;
  }

  .lg\:tw--mr-4 {
    margin-right: -1rem;
  }

  .lg\:tw--mb-4 {
    margin-bottom: -1rem;
  }

  .lg\:tw--ml-4 {
    margin-left: -1rem;
  }

  .lg\:tw--mt-5 {
    margin-top: -1.25rem;
  }

  .lg\:tw--mr-5 {
    margin-right: -1.25rem;
  }

  .lg\:tw--mb-5 {
    margin-bottom: -1.25rem;
  }

  .lg\:tw--ml-5 {
    margin-left: -1.25rem;
  }

  .lg\:tw--mt-6 {
    margin-top: -1.5rem;
  }

  .lg\:tw--mr-6 {
    margin-right: -1.5rem;
  }

  .lg\:tw--mb-6 {
    margin-bottom: -1.5rem;
  }

  .lg\:tw--ml-6 {
    margin-left: -1.5rem;
  }

  .lg\:tw--mt-8 {
    margin-top: -2rem;
  }

  .lg\:tw--mr-8 {
    margin-right: -2rem;
  }

  .lg\:tw--mb-8 {
    margin-bottom: -2rem;
  }

  .lg\:tw--ml-8 {
    margin-left: -2rem;
  }

  .lg\:tw--mt-10 {
    margin-top: -2.5rem;
  }

  .lg\:tw--mr-10 {
    margin-right: -2.5rem;
  }

  .lg\:tw--mb-10 {
    margin-bottom: -2.5rem;
  }

  .lg\:tw--ml-10 {
    margin-left: -2.5rem;
  }

  .lg\:tw--mt-12 {
    margin-top: -3rem;
  }

  .lg\:tw--mr-12 {
    margin-right: -3rem;
  }

  .lg\:tw--mb-12 {
    margin-bottom: -3rem;
  }

  .lg\:tw--ml-12 {
    margin-left: -3rem;
  }

  .lg\:tw--mt-16 {
    margin-top: -4rem;
  }

  .lg\:tw--mr-16 {
    margin-right: -4rem;
  }

  .lg\:tw--mb-16 {
    margin-bottom: -4rem;
  }

  .lg\:tw--ml-16 {
    margin-left: -4rem;
  }

  .lg\:tw--mt-20 {
    margin-top: -5rem;
  }

  .lg\:tw--mr-20 {
    margin-right: -5rem;
  }

  .lg\:tw--mb-20 {
    margin-bottom: -5rem;
  }

  .lg\:tw--ml-20 {
    margin-left: -5rem;
  }

  .lg\:tw--mt-24 {
    margin-top: -6rem;
  }

  .lg\:tw--mr-24 {
    margin-right: -6rem;
  }

  .lg\:tw--mb-24 {
    margin-bottom: -6rem;
  }

  .lg\:tw--ml-24 {
    margin-left: -6rem;
  }

  .lg\:tw--mt-32 {
    margin-top: -8rem;
  }

  .lg\:tw--mr-32 {
    margin-right: -8rem;
  }

  .lg\:tw--mb-32 {
    margin-bottom: -8rem;
  }

  .lg\:tw--ml-32 {
    margin-left: -8rem;
  }

  .lg\:tw--mt-40 {
    margin-top: -10rem;
  }

  .lg\:tw--mr-40 {
    margin-right: -10rem;
  }

  .lg\:tw--mb-40 {
    margin-bottom: -10rem;
  }

  .lg\:tw--ml-40 {
    margin-left: -10rem;
  }

  .lg\:tw--mt-48 {
    margin-top: -12rem;
  }

  .lg\:tw--mr-48 {
    margin-right: -12rem;
  }

  .lg\:tw--mb-48 {
    margin-bottom: -12rem;
  }

  .lg\:tw--ml-48 {
    margin-left: -12rem;
  }

  .lg\:tw--mt-56 {
    margin-top: -14rem;
  }

  .lg\:tw--mr-56 {
    margin-right: -14rem;
  }

  .lg\:tw--mb-56 {
    margin-bottom: -14rem;
  }

  .lg\:tw--ml-56 {
    margin-left: -14rem;
  }

  .lg\:tw--mt-64 {
    margin-top: -16rem;
  }

  .lg\:tw--mr-64 {
    margin-right: -16rem;
  }

  .lg\:tw--mb-64 {
    margin-bottom: -16rem;
  }

  .lg\:tw--ml-64 {
    margin-left: -16rem;
  }

  .lg\:tw--mt-px {
    margin-top: -1px;
  }

  .lg\:tw--mr-px {
    margin-right: -1px;
  }

  .lg\:tw--mb-px {
    margin-bottom: -1px;
  }

  .lg\:tw--ml-px {
    margin-left: -1px;
  }

  .lg\:tw--mt-270px {
    margin-top: -270px;
  }

  .lg\:tw--mr-270px {
    margin-right: -270px;
  }

  .lg\:tw--mb-270px {
    margin-bottom: -270px;
  }

  .lg\:tw--ml-270px {
    margin-left: -270px;
  }

  .lg\:tw--mt-400px {
    margin-top: -400px;
  }

  .lg\:tw--mr-400px {
    margin-right: -400px;
  }

  .lg\:tw--mb-400px {
    margin-bottom: -400px;
  }

  .lg\:tw--ml-400px {
    margin-left: -400px;
  }

  .lg\:tw--mt-464px {
    margin-top: -464px;
  }

  .lg\:tw--mr-464px {
    margin-right: -464px;
  }

  .lg\:tw--mb-464px {
    margin-bottom: -464px;
  }

  .lg\:tw--ml-464px {
    margin-left: -464px;
  }

  .lg\:tw--mt-480px {
    margin-top: -480px;
  }

  .lg\:tw--mr-480px {
    margin-right: -480px;
  }

  .lg\:tw--mb-480px {
    margin-bottom: -480px;
  }

  .lg\:tw--ml-480px {
    margin-left: -480px;
  }

  .lg\:tw--mt-500px {
    margin-top: -500px;
  }

  .lg\:tw--mr-500px {
    margin-right: -500px;
  }

  .lg\:tw--mb-500px {
    margin-bottom: -500px;
  }

  .lg\:tw--ml-500px {
    margin-left: -500px;
  }

  .lg\:tw--mt-576px {
    margin-top: -576px;
  }

  .lg\:tw--mr-576px {
    margin-right: -576px;
  }

  .lg\:tw--mb-576px {
    margin-bottom: -576px;
  }

  .lg\:tw--ml-576px {
    margin-left: -576px;
  }

  .lg\:tw--mt-600px {
    margin-top: -600px;
  }

  .lg\:tw--mr-600px {
    margin-right: -600px;
  }

  .lg\:tw--mb-600px {
    margin-bottom: -600px;
  }

  .lg\:tw--ml-600px {
    margin-left: -600px;
  }

  .lg\:tw--mt-672px {
    margin-top: -672px;
  }

  .lg\:tw--mr-672px {
    margin-right: -672px;
  }

  .lg\:tw--mb-672px {
    margin-bottom: -672px;
  }

  .lg\:tw--ml-672px {
    margin-left: -672px;
  }

  .lg\:tw-max-h-full {
    max-height: 100%;
  }

  .lg\:tw-max-h-screen {
    max-height: 100vh;
  }

  .lg\:tw-max-w-none {
    max-width: none;
  }

  .lg\:tw-max-w-xs {
    max-width: 20rem;
  }

  .lg\:tw-max-w-sm {
    max-width: 24rem;
  }

  .lg\:tw-max-w-md {
    max-width: 28rem;
  }

  .lg\:tw-max-w-lg {
    max-width: 32rem;
  }

  .lg\:tw-max-w-xl {
    max-width: 36rem;
  }

  .lg\:tw-max-w-2xl {
    max-width: 42rem;
  }

  .lg\:tw-max-w-3xl {
    max-width: 48rem;
  }

  .lg\:tw-max-w-4xl {
    max-width: 56rem;
  }

  .lg\:tw-max-w-5xl {
    max-width: 64rem;
  }

  .lg\:tw-max-w-6xl {
    max-width: 72rem;
  }

  .lg\:tw-max-w-full {
    max-width: 100%;
  }

  .lg\:tw-max-w-screen-sm {
    max-width: 640px;
  }

  .lg\:tw-max-w-screen-md {
    max-width: 768px;
  }

  .lg\:tw-max-w-screen-lg {
    max-width: 1024px;
  }

  .lg\:tw-max-w-screen-xl {
    max-width: 1280px;
  }

  .lg\:tw-min-h-0 {
    min-height: 0;
  }

  .lg\:tw-min-h-full {
    min-height: 100%;
  }

  .lg\:tw-min-h-screen {
    min-height: 100vh;
  }

  .lg\:tw-min-h-576px {
    min-height: 576px;
  }

  .lg\:tw-min-h-600px {
    min-height: 600px;
  }

  .lg\:tw-min-h-672px {
    min-height: 672px;
  }

  .lg\:tw-min-w-0 {
    min-width: 0;
  }

  .lg\:tw-min-w-full {
    min-width: 100%;
  }

  .lg\:tw-min-w-360px {
    min-width: 360px;
  }

  .lg\:tw-min-w-540px {
    min-width: 540px;
  }

  .lg\:tw-object-contain {
    -o-object-fit: contain;
       object-fit: contain;
  }

  .lg\:tw-object-cover {
    -o-object-fit: cover;
       object-fit: cover;
  }

  .lg\:tw-object-fill {
    -o-object-fit: fill;
       object-fit: fill;
  }

  .lg\:tw-object-none {
    -o-object-fit: none;
       object-fit: none;
  }

  .lg\:tw-object-scale-down {
    -o-object-fit: scale-down;
       object-fit: scale-down;
  }

  .lg\:tw-object-bottom {
    -o-object-position: bottom;
       object-position: bottom;
  }

  .lg\:tw-object-center {
    -o-object-position: center;
       object-position: center;
  }

  .lg\:tw-object-left {
    -o-object-position: left;
       object-position: left;
  }

  .lg\:tw-object-left-bottom {
    -o-object-position: left bottom;
       object-position: left bottom;
  }

  .lg\:tw-object-left-top {
    -o-object-position: left top;
       object-position: left top;
  }

  .lg\:tw-object-right {
    -o-object-position: right;
       object-position: right;
  }

  .lg\:tw-object-right-bottom {
    -o-object-position: right bottom;
       object-position: right bottom;
  }

  .lg\:tw-object-right-top {
    -o-object-position: right top;
       object-position: right top;
  }

  .lg\:tw-object-top {
    -o-object-position: top;
       object-position: top;
  }

  .lg\:tw-opacity-0 {
    opacity: 0;
  }

  .lg\:tw-opacity-25 {
    opacity: 0.25;
  }

  .lg\:tw-opacity-50 {
    opacity: 0.5;
  }

  .lg\:tw-opacity-75 {
    opacity: 0.75;
  }

  .lg\:tw-opacity-100 {
    opacity: 1;
  }

  .lg\:hover\:tw-opacity-0:hover {
    opacity: 0;
  }

  .lg\:hover\:tw-opacity-25:hover {
    opacity: 0.25;
  }

  .lg\:hover\:tw-opacity-50:hover {
    opacity: 0.5;
  }

  .lg\:hover\:tw-opacity-75:hover {
    opacity: 0.75;
  }

  .lg\:hover\:tw-opacity-100:hover {
    opacity: 1;
  }

  .lg\:focus\:tw-opacity-0:focus {
    opacity: 0;
  }

  .lg\:focus\:tw-opacity-25:focus {
    opacity: 0.25;
  }

  .lg\:focus\:tw-opacity-50:focus {
    opacity: 0.5;
  }

  .lg\:focus\:tw-opacity-75:focus {
    opacity: 0.75;
  }

  .lg\:focus\:tw-opacity-100:focus {
    opacity: 1;
  }

  .lg\:tw-outline-none {
    outline: 2px solid transparent;
    outline-offset: 2px;
  }

  .lg\:tw-outline-white {
    outline: 2px dotted white;
    outline-offset: 2px;
  }

  .lg\:tw-outline-black {
    outline: 2px dotted black;
    outline-offset: 2px;
  }

  .lg\:focus\:tw-outline-none:focus {
    outline: 2px solid transparent;
    outline-offset: 2px;
  }

  .lg\:focus\:tw-outline-white:focus {
    outline: 2px dotted white;
    outline-offset: 2px;
  }

  .lg\:focus\:tw-outline-black:focus {
    outline: 2px dotted black;
    outline-offset: 2px;
  }

  .lg\:tw-overflow-auto {
    overflow: auto;
  }

  .lg\:tw-overflow-hidden {
    overflow: hidden;
  }

  .lg\:tw-overflow-visible {
    overflow: visible;
  }

  .lg\:tw-overflow-scroll {
    overflow: scroll;
  }

  .lg\:tw-overflow-x-auto {
    overflow-x: auto;
  }

  .lg\:tw-overflow-y-auto {
    overflow-y: auto;
  }

  .lg\:tw-overflow-x-hidden {
    overflow-x: hidden;
  }

  .lg\:tw-overflow-y-hidden {
    overflow-y: hidden;
  }

  .lg\:tw-overflow-x-visible {
    overflow-x: visible;
  }

  .lg\:tw-overflow-y-visible {
    overflow-y: visible;
  }

  .lg\:tw-overflow-x-scroll {
    overflow-x: scroll;
  }

  .lg\:tw-overflow-y-scroll {
    overflow-y: scroll;
  }

  .lg\:tw-scrolling-touch {
    -webkit-overflow-scrolling: touch;
  }

  .lg\:tw-scrolling-auto {
    -webkit-overflow-scrolling: auto;
  }

  .lg\:tw-overscroll-auto {
    overscroll-behavior: auto;
  }

  .lg\:tw-overscroll-contain {
    overscroll-behavior: contain;
  }

  .lg\:tw-overscroll-none {
    overscroll-behavior: none;
  }

  .lg\:tw-overscroll-y-auto {
    overscroll-behavior-y: auto;
  }

  .lg\:tw-overscroll-y-contain {
    overscroll-behavior-y: contain;
  }

  .lg\:tw-overscroll-y-none {
    overscroll-behavior-y: none;
  }

  .lg\:tw-overscroll-x-auto {
    overscroll-behavior-x: auto;
  }

  .lg\:tw-overscroll-x-contain {
    overscroll-behavior-x: contain;
  }

  .lg\:tw-overscroll-x-none {
    overscroll-behavior-x: none;
  }

  .lg\:tw-p-0 {
    padding: 0;
  }

  .lg\:tw-p-1 {
    padding: 0.25rem;
  }

  .lg\:tw-p-2 {
    padding: 0.5rem;
  }

  .lg\:tw-p-3 {
    padding: 0.75rem;
  }

  .lg\:tw-p-4 {
    padding: 1rem;
  }

  .lg\:tw-p-5 {
    padding: 1.25rem;
  }

  .lg\:tw-p-6 {
    padding: 1.5rem;
  }

  .lg\:tw-p-8 {
    padding: 2rem;
  }

  .lg\:tw-p-10 {
    padding: 2.5rem;
  }

  .lg\:tw-p-12 {
    padding: 3rem;
  }

  .lg\:tw-p-16 {
    padding: 4rem;
  }

  .lg\:tw-p-20 {
    padding: 5rem;
  }

  .lg\:tw-p-24 {
    padding: 6rem;
  }

  .lg\:tw-p-32 {
    padding: 8rem;
  }

  .lg\:tw-p-40 {
    padding: 10rem;
  }

  .lg\:tw-p-48 {
    padding: 12rem;
  }

  .lg\:tw-p-56 {
    padding: 14rem;
  }

  .lg\:tw-p-64 {
    padding: 16rem;
  }

  .lg\:tw-p-px {
    padding: 1px;
  }

  .lg\:tw-p-270px {
    padding: 270px;
  }

  .lg\:tw-p-400px {
    padding: 400px;
  }

  .lg\:tw-p-464px {
    padding: 464px;
  }

  .lg\:tw-p-480px {
    padding: 480px;
  }

  .lg\:tw-p-500px {
    padding: 500px;
  }

  .lg\:tw-p-576px {
    padding: 576px;
  }

  .lg\:tw-p-600px {
    padding: 600px;
  }

  .lg\:tw-p-672px {
    padding: 672px;
  }

  .lg\:tw-py-0 {
    padding-top: 0;
    padding-bottom: 0;
  }

  .lg\:tw-px-0 {
    padding-left: 0;
    padding-right: 0;
  }

  .lg\:tw-py-1 {
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;
  }

  .lg\:tw-px-1 {
    padding-left: 0.25rem;
    padding-right: 0.25rem;
  }

  .lg\:tw-py-2 {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
  }

  .lg\:tw-px-2 {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }

  .lg\:tw-py-3 {
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
  }

  .lg\:tw-px-3 {
    padding-left: 0.75rem;
    padding-right: 0.75rem;
  }

  .lg\:tw-py-4 {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }

  .lg\:tw-px-4 {
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .lg\:tw-py-5 {
    padding-top: 1.25rem;
    padding-bottom: 1.25rem;
  }

  .lg\:tw-px-5 {
    padding-left: 1.25rem;
    padding-right: 1.25rem;
  }

  .lg\:tw-py-6 {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
  }

  .lg\:tw-px-6 {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }

  .lg\:tw-py-8 {
    padding-top: 2rem;
    padding-bottom: 2rem;
  }

  .lg\:tw-px-8 {
    padding-left: 2rem;
    padding-right: 2rem;
  }

  .lg\:tw-py-10 {
    padding-top: 2.5rem;
    padding-bottom: 2.5rem;
  }

  .lg\:tw-px-10 {
    padding-left: 2.5rem;
    padding-right: 2.5rem;
  }

  .lg\:tw-py-12 {
    padding-top: 3rem;
    padding-bottom: 3rem;
  }

  .lg\:tw-px-12 {
    padding-left: 3rem;
    padding-right: 3rem;
  }

  .lg\:tw-py-16 {
    padding-top: 4rem;
    padding-bottom: 4rem;
  }

  .lg\:tw-px-16 {
    padding-left: 4rem;
    padding-right: 4rem;
  }

  .lg\:tw-py-20 {
    padding-top: 5rem;
    padding-bottom: 5rem;
  }

  .lg\:tw-px-20 {
    padding-left: 5rem;
    padding-right: 5rem;
  }

  .lg\:tw-py-24 {
    padding-top: 6rem;
    padding-bottom: 6rem;
  }

  .lg\:tw-px-24 {
    padding-left: 6rem;
    padding-right: 6rem;
  }

  .lg\:tw-py-32 {
    padding-top: 8rem;
    padding-bottom: 8rem;
  }

  .lg\:tw-px-32 {
    padding-left: 8rem;
    padding-right: 8rem;
  }

  .lg\:tw-py-40 {
    padding-top: 10rem;
    padding-bottom: 10rem;
  }

  .lg\:tw-px-40 {
    padding-left: 10rem;
    padding-right: 10rem;
  }

  .lg\:tw-py-48 {
    padding-top: 12rem;
    padding-bottom: 12rem;
  }

  .lg\:tw-px-48 {
    padding-left: 12rem;
    padding-right: 12rem;
  }

  .lg\:tw-py-56 {
    padding-top: 14rem;
    padding-bottom: 14rem;
  }

  .lg\:tw-px-56 {
    padding-left: 14rem;
    padding-right: 14rem;
  }

  .lg\:tw-py-64 {
    padding-top: 16rem;
    padding-bottom: 16rem;
  }

  .lg\:tw-px-64 {
    padding-left: 16rem;
    padding-right: 16rem;
  }

  .lg\:tw-py-px {
    padding-top: 1px;
    padding-bottom: 1px;
  }

  .lg\:tw-px-px {
    padding-left: 1px;
    padding-right: 1px;
  }

  .lg\:tw-py-270px {
    padding-top: 270px;
    padding-bottom: 270px;
  }

  .lg\:tw-px-270px {
    padding-left: 270px;
    padding-right: 270px;
  }

  .lg\:tw-py-400px {
    padding-top: 400px;
    padding-bottom: 400px;
  }

  .lg\:tw-px-400px {
    padding-left: 400px;
    padding-right: 400px;
  }

  .lg\:tw-py-464px {
    padding-top: 464px;
    padding-bottom: 464px;
  }

  .lg\:tw-px-464px {
    padding-left: 464px;
    padding-right: 464px;
  }

  .lg\:tw-py-480px {
    padding-top: 480px;
    padding-bottom: 480px;
  }

  .lg\:tw-px-480px {
    padding-left: 480px;
    padding-right: 480px;
  }

  .lg\:tw-py-500px {
    padding-top: 500px;
    padding-bottom: 500px;
  }

  .lg\:tw-px-500px {
    padding-left: 500px;
    padding-right: 500px;
  }

  .lg\:tw-py-576px {
    padding-top: 576px;
    padding-bottom: 576px;
  }

  .lg\:tw-px-576px {
    padding-left: 576px;
    padding-right: 576px;
  }

  .lg\:tw-py-600px {
    padding-top: 600px;
    padding-bottom: 600px;
  }

  .lg\:tw-px-600px {
    padding-left: 600px;
    padding-right: 600px;
  }

  .lg\:tw-py-672px {
    padding-top: 672px;
    padding-bottom: 672px;
  }

  .lg\:tw-px-672px {
    padding-left: 672px;
    padding-right: 672px;
  }

  .lg\:tw-pt-0 {
    padding-top: 0;
  }

  .lg\:tw-pr-0 {
    padding-right: 0;
  }

  .lg\:tw-pb-0 {
    padding-bottom: 0;
  }

  .lg\:tw-pl-0 {
    padding-left: 0;
  }

  .lg\:tw-pt-1 {
    padding-top: 0.25rem;
  }

  .lg\:tw-pr-1 {
    padding-right: 0.25rem;
  }

  .lg\:tw-pb-1 {
    padding-bottom: 0.25rem;
  }

  .lg\:tw-pl-1 {
    padding-left: 0.25rem;
  }

  .lg\:tw-pt-2 {
    padding-top: 0.5rem;
  }

  .lg\:tw-pr-2 {
    padding-right: 0.5rem;
  }

  .lg\:tw-pb-2 {
    padding-bottom: 0.5rem;
  }

  .lg\:tw-pl-2 {
    padding-left: 0.5rem;
  }

  .lg\:tw-pt-3 {
    padding-top: 0.75rem;
  }

  .lg\:tw-pr-3 {
    padding-right: 0.75rem;
  }

  .lg\:tw-pb-3 {
    padding-bottom: 0.75rem;
  }

  .lg\:tw-pl-3 {
    padding-left: 0.75rem;
  }

  .lg\:tw-pt-4 {
    padding-top: 1rem;
  }

  .lg\:tw-pr-4 {
    padding-right: 1rem;
  }

  .lg\:tw-pb-4 {
    padding-bottom: 1rem;
  }

  .lg\:tw-pl-4 {
    padding-left: 1rem;
  }

  .lg\:tw-pt-5 {
    padding-top: 1.25rem;
  }

  .lg\:tw-pr-5 {
    padding-right: 1.25rem;
  }

  .lg\:tw-pb-5 {
    padding-bottom: 1.25rem;
  }

  .lg\:tw-pl-5 {
    padding-left: 1.25rem;
  }

  .lg\:tw-pt-6 {
    padding-top: 1.5rem;
  }

  .lg\:tw-pr-6 {
    padding-right: 1.5rem;
  }

  .lg\:tw-pb-6 {
    padding-bottom: 1.5rem;
  }

  .lg\:tw-pl-6 {
    padding-left: 1.5rem;
  }

  .lg\:tw-pt-8 {
    padding-top: 2rem;
  }

  .lg\:tw-pr-8 {
    padding-right: 2rem;
  }

  .lg\:tw-pb-8 {
    padding-bottom: 2rem;
  }

  .lg\:tw-pl-8 {
    padding-left: 2rem;
  }

  .lg\:tw-pt-10 {
    padding-top: 2.5rem;
  }

  .lg\:tw-pr-10 {
    padding-right: 2.5rem;
  }

  .lg\:tw-pb-10 {
    padding-bottom: 2.5rem;
  }

  .lg\:tw-pl-10 {
    padding-left: 2.5rem;
  }

  .lg\:tw-pt-12 {
    padding-top: 3rem;
  }

  .lg\:tw-pr-12 {
    padding-right: 3rem;
  }

  .lg\:tw-pb-12 {
    padding-bottom: 3rem;
  }

  .lg\:tw-pl-12 {
    padding-left: 3rem;
  }

  .lg\:tw-pt-16 {
    padding-top: 4rem;
  }

  .lg\:tw-pr-16 {
    padding-right: 4rem;
  }

  .lg\:tw-pb-16 {
    padding-bottom: 4rem;
  }

  .lg\:tw-pl-16 {
    padding-left: 4rem;
  }

  .lg\:tw-pt-20 {
    padding-top: 5rem;
  }

  .lg\:tw-pr-20 {
    padding-right: 5rem;
  }

  .lg\:tw-pb-20 {
    padding-bottom: 5rem;
  }

  .lg\:tw-pl-20 {
    padding-left: 5rem;
  }

  .lg\:tw-pt-24 {
    padding-top: 6rem;
  }

  .lg\:tw-pr-24 {
    padding-right: 6rem;
  }

  .lg\:tw-pb-24 {
    padding-bottom: 6rem;
  }

  .lg\:tw-pl-24 {
    padding-left: 6rem;
  }

  .lg\:tw-pt-32 {
    padding-top: 8rem;
  }

  .lg\:tw-pr-32 {
    padding-right: 8rem;
  }

  .lg\:tw-pb-32 {
    padding-bottom: 8rem;
  }

  .lg\:tw-pl-32 {
    padding-left: 8rem;
  }

  .lg\:tw-pt-40 {
    padding-top: 10rem;
  }

  .lg\:tw-pr-40 {
    padding-right: 10rem;
  }

  .lg\:tw-pb-40 {
    padding-bottom: 10rem;
  }

  .lg\:tw-pl-40 {
    padding-left: 10rem;
  }

  .lg\:tw-pt-48 {
    padding-top: 12rem;
  }

  .lg\:tw-pr-48 {
    padding-right: 12rem;
  }

  .lg\:tw-pb-48 {
    padding-bottom: 12rem;
  }

  .lg\:tw-pl-48 {
    padding-left: 12rem;
  }

  .lg\:tw-pt-56 {
    padding-top: 14rem;
  }

  .lg\:tw-pr-56 {
    padding-right: 14rem;
  }

  .lg\:tw-pb-56 {
    padding-bottom: 14rem;
  }

  .lg\:tw-pl-56 {
    padding-left: 14rem;
  }

  .lg\:tw-pt-64 {
    padding-top: 16rem;
  }

  .lg\:tw-pr-64 {
    padding-right: 16rem;
  }

  .lg\:tw-pb-64 {
    padding-bottom: 16rem;
  }

  .lg\:tw-pl-64 {
    padding-left: 16rem;
  }

  .lg\:tw-pt-px {
    padding-top: 1px;
  }

  .lg\:tw-pr-px {
    padding-right: 1px;
  }

  .lg\:tw-pb-px {
    padding-bottom: 1px;
  }

  .lg\:tw-pl-px {
    padding-left: 1px;
  }

  .lg\:tw-pt-270px {
    padding-top: 270px;
  }

  .lg\:tw-pr-270px {
    padding-right: 270px;
  }

  .lg\:tw-pb-270px {
    padding-bottom: 270px;
  }

  .lg\:tw-pl-270px {
    padding-left: 270px;
  }

  .lg\:tw-pt-400px {
    padding-top: 400px;
  }

  .lg\:tw-pr-400px {
    padding-right: 400px;
  }

  .lg\:tw-pb-400px {
    padding-bottom: 400px;
  }

  .lg\:tw-pl-400px {
    padding-left: 400px;
  }

  .lg\:tw-pt-464px {
    padding-top: 464px;
  }

  .lg\:tw-pr-464px {
    padding-right: 464px;
  }

  .lg\:tw-pb-464px {
    padding-bottom: 464px;
  }

  .lg\:tw-pl-464px {
    padding-left: 464px;
  }

  .lg\:tw-pt-480px {
    padding-top: 480px;
  }

  .lg\:tw-pr-480px {
    padding-right: 480px;
  }

  .lg\:tw-pb-480px {
    padding-bottom: 480px;
  }

  .lg\:tw-pl-480px {
    padding-left: 480px;
  }

  .lg\:tw-pt-500px {
    padding-top: 500px;
  }

  .lg\:tw-pr-500px {
    padding-right: 500px;
  }

  .lg\:tw-pb-500px {
    padding-bottom: 500px;
  }

  .lg\:tw-pl-500px {
    padding-left: 500px;
  }

  .lg\:tw-pt-576px {
    padding-top: 576px;
  }

  .lg\:tw-pr-576px {
    padding-right: 576px;
  }

  .lg\:tw-pb-576px {
    padding-bottom: 576px;
  }

  .lg\:tw-pl-576px {
    padding-left: 576px;
  }

  .lg\:tw-pt-600px {
    padding-top: 600px;
  }

  .lg\:tw-pr-600px {
    padding-right: 600px;
  }

  .lg\:tw-pb-600px {
    padding-bottom: 600px;
  }

  .lg\:tw-pl-600px {
    padding-left: 600px;
  }

  .lg\:tw-pt-672px {
    padding-top: 672px;
  }

  .lg\:tw-pr-672px {
    padding-right: 672px;
  }

  .lg\:tw-pb-672px {
    padding-bottom: 672px;
  }

  .lg\:tw-pl-672px {
    padding-left: 672px;
  }

  .lg\:tw-placeholder-transparent::-moz-placeholder {
    color: transparent;
  }

  .lg\:tw-placeholder-transparent::placeholder {
    color: transparent;
  }

  .lg\:tw-placeholder-current::-moz-placeholder {
    color: currentColor;
  }

  .lg\:tw-placeholder-current::placeholder {
    color: currentColor;
  }

  .lg\:tw-placeholder-black::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #000;
    color: rgba(0, 0, 0, var(--placeholder-opacity));
  }

  .lg\:tw-placeholder-black::placeholder {
    --placeholder-opacity: 1;
    color: #000;
    color: rgba(0, 0, 0, var(--placeholder-opacity));
  }

  .lg\:tw-placeholder-white::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #fff;
    color: rgba(255, 255, 255, var(--placeholder-opacity));
  }

  .lg\:tw-placeholder-white::placeholder {
    --placeholder-opacity: 1;
    color: #fff;
    color: rgba(255, 255, 255, var(--placeholder-opacity));
  }

  .lg\:tw-placeholder-gray-100::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #f7fafc;
    color: rgba(247, 250, 252, var(--placeholder-opacity));
  }

  .lg\:tw-placeholder-gray-100::placeholder {
    --placeholder-opacity: 1;
    color: #f7fafc;
    color: rgba(247, 250, 252, var(--placeholder-opacity));
  }

  .lg\:tw-placeholder-gray-200::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #edf2f7;
    color: rgba(237, 242, 247, var(--placeholder-opacity));
  }

  .lg\:tw-placeholder-gray-200::placeholder {
    --placeholder-opacity: 1;
    color: #edf2f7;
    color: rgba(237, 242, 247, var(--placeholder-opacity));
  }

  .lg\:tw-placeholder-gray-300::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #e2e8f0;
    color: rgba(226, 232, 240, var(--placeholder-opacity));
  }

  .lg\:tw-placeholder-gray-300::placeholder {
    --placeholder-opacity: 1;
    color: #e2e8f0;
    color: rgba(226, 232, 240, var(--placeholder-opacity));
  }

  .lg\:tw-placeholder-gray-400::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #cbd5e0;
    color: rgba(203, 213, 224, var(--placeholder-opacity));
  }

  .lg\:tw-placeholder-gray-400::placeholder {
    --placeholder-opacity: 1;
    color: #cbd5e0;
    color: rgba(203, 213, 224, var(--placeholder-opacity));
  }

  .lg\:tw-placeholder-gray-500::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #a0aec0;
    color: rgba(160, 174, 192, var(--placeholder-opacity));
  }

  .lg\:tw-placeholder-gray-500::placeholder {
    --placeholder-opacity: 1;
    color: #a0aec0;
    color: rgba(160, 174, 192, var(--placeholder-opacity));
  }

  .lg\:tw-placeholder-gray-600::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #718096;
    color: rgba(113, 128, 150, var(--placeholder-opacity));
  }

  .lg\:tw-placeholder-gray-600::placeholder {
    --placeholder-opacity: 1;
    color: #718096;
    color: rgba(113, 128, 150, var(--placeholder-opacity));
  }

  .lg\:tw-placeholder-gray-700::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #4a5568;
    color: rgba(74, 85, 104, var(--placeholder-opacity));
  }

  .lg\:tw-placeholder-gray-700::placeholder {
    --placeholder-opacity: 1;
    color: #4a5568;
    color: rgba(74, 85, 104, var(--placeholder-opacity));
  }

  .lg\:tw-placeholder-gray-800::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #2d3748;
    color: rgba(45, 55, 72, var(--placeholder-opacity));
  }

  .lg\:tw-placeholder-gray-800::placeholder {
    --placeholder-opacity: 1;
    color: #2d3748;
    color: rgba(45, 55, 72, var(--placeholder-opacity));
  }

  .lg\:tw-placeholder-gray-900::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #1a202c;
    color: rgba(26, 32, 44, var(--placeholder-opacity));
  }

  .lg\:tw-placeholder-gray-900::placeholder {
    --placeholder-opacity: 1;
    color: #1a202c;
    color: rgba(26, 32, 44, var(--placeholder-opacity));
  }

  .lg\:tw-placeholder-red-100::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #fff5f5;
    color: rgba(255, 245, 245, var(--placeholder-opacity));
  }

  .lg\:tw-placeholder-red-100::placeholder {
    --placeholder-opacity: 1;
    color: #fff5f5;
    color: rgba(255, 245, 245, var(--placeholder-opacity));
  }

  .lg\:tw-placeholder-red-200::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #fed7d7;
    color: rgba(254, 215, 215, var(--placeholder-opacity));
  }

  .lg\:tw-placeholder-red-200::placeholder {
    --placeholder-opacity: 1;
    color: #fed7d7;
    color: rgba(254, 215, 215, var(--placeholder-opacity));
  }

  .lg\:tw-placeholder-red-300::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #feb2b2;
    color: rgba(254, 178, 178, var(--placeholder-opacity));
  }

  .lg\:tw-placeholder-red-300::placeholder {
    --placeholder-opacity: 1;
    color: #feb2b2;
    color: rgba(254, 178, 178, var(--placeholder-opacity));
  }

  .lg\:tw-placeholder-red-400::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #fc8181;
    color: rgba(252, 129, 129, var(--placeholder-opacity));
  }

  .lg\:tw-placeholder-red-400::placeholder {
    --placeholder-opacity: 1;
    color: #fc8181;
    color: rgba(252, 129, 129, var(--placeholder-opacity));
  }

  .lg\:tw-placeholder-red-500::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #f56565;
    color: rgba(245, 101, 101, var(--placeholder-opacity));
  }

  .lg\:tw-placeholder-red-500::placeholder {
    --placeholder-opacity: 1;
    color: #f56565;
    color: rgba(245, 101, 101, var(--placeholder-opacity));
  }

  .lg\:tw-placeholder-red-600::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #e53e3e;
    color: rgba(229, 62, 62, var(--placeholder-opacity));
  }

  .lg\:tw-placeholder-red-600::placeholder {
    --placeholder-opacity: 1;
    color: #e53e3e;
    color: rgba(229, 62, 62, var(--placeholder-opacity));
  }

  .lg\:tw-placeholder-red-700::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #c53030;
    color: rgba(197, 48, 48, var(--placeholder-opacity));
  }

  .lg\:tw-placeholder-red-700::placeholder {
    --placeholder-opacity: 1;
    color: #c53030;
    color: rgba(197, 48, 48, var(--placeholder-opacity));
  }

  .lg\:tw-placeholder-red-800::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #9b2c2c;
    color: rgba(155, 44, 44, var(--placeholder-opacity));
  }

  .lg\:tw-placeholder-red-800::placeholder {
    --placeholder-opacity: 1;
    color: #9b2c2c;
    color: rgba(155, 44, 44, var(--placeholder-opacity));
  }

  .lg\:tw-placeholder-red-900::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #742a2a;
    color: rgba(116, 42, 42, var(--placeholder-opacity));
  }

  .lg\:tw-placeholder-red-900::placeholder {
    --placeholder-opacity: 1;
    color: #742a2a;
    color: rgba(116, 42, 42, var(--placeholder-opacity));
  }

  .lg\:tw-placeholder-orange-100::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #fffaf0;
    color: rgba(255, 250, 240, var(--placeholder-opacity));
  }

  .lg\:tw-placeholder-orange-100::placeholder {
    --placeholder-opacity: 1;
    color: #fffaf0;
    color: rgba(255, 250, 240, var(--placeholder-opacity));
  }

  .lg\:tw-placeholder-orange-200::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #feebc8;
    color: rgba(254, 235, 200, var(--placeholder-opacity));
  }

  .lg\:tw-placeholder-orange-200::placeholder {
    --placeholder-opacity: 1;
    color: #feebc8;
    color: rgba(254, 235, 200, var(--placeholder-opacity));
  }

  .lg\:tw-placeholder-orange-300::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #fbd38d;
    color: rgba(251, 211, 141, var(--placeholder-opacity));
  }

  .lg\:tw-placeholder-orange-300::placeholder {
    --placeholder-opacity: 1;
    color: #fbd38d;
    color: rgba(251, 211, 141, var(--placeholder-opacity));
  }

  .lg\:tw-placeholder-orange-400::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #f6ad55;
    color: rgba(246, 173, 85, var(--placeholder-opacity));
  }

  .lg\:tw-placeholder-orange-400::placeholder {
    --placeholder-opacity: 1;
    color: #f6ad55;
    color: rgba(246, 173, 85, var(--placeholder-opacity));
  }

  .lg\:tw-placeholder-orange-500::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #ed8936;
    color: rgba(237, 137, 54, var(--placeholder-opacity));
  }

  .lg\:tw-placeholder-orange-500::placeholder {
    --placeholder-opacity: 1;
    color: #ed8936;
    color: rgba(237, 137, 54, var(--placeholder-opacity));
  }

  .lg\:tw-placeholder-orange-600::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #dd6b20;
    color: rgba(221, 107, 32, var(--placeholder-opacity));
  }

  .lg\:tw-placeholder-orange-600::placeholder {
    --placeholder-opacity: 1;
    color: #dd6b20;
    color: rgba(221, 107, 32, var(--placeholder-opacity));
  }

  .lg\:tw-placeholder-orange-700::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #c05621;
    color: rgba(192, 86, 33, var(--placeholder-opacity));
  }

  .lg\:tw-placeholder-orange-700::placeholder {
    --placeholder-opacity: 1;
    color: #c05621;
    color: rgba(192, 86, 33, var(--placeholder-opacity));
  }

  .lg\:tw-placeholder-orange-800::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #9c4221;
    color: rgba(156, 66, 33, var(--placeholder-opacity));
  }

  .lg\:tw-placeholder-orange-800::placeholder {
    --placeholder-opacity: 1;
    color: #9c4221;
    color: rgba(156, 66, 33, var(--placeholder-opacity));
  }

  .lg\:tw-placeholder-orange-900::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #7b341e;
    color: rgba(123, 52, 30, var(--placeholder-opacity));
  }

  .lg\:tw-placeholder-orange-900::placeholder {
    --placeholder-opacity: 1;
    color: #7b341e;
    color: rgba(123, 52, 30, var(--placeholder-opacity));
  }

  .lg\:tw-placeholder-yellow-100::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #fffff0;
    color: rgba(255, 255, 240, var(--placeholder-opacity));
  }

  .lg\:tw-placeholder-yellow-100::placeholder {
    --placeholder-opacity: 1;
    color: #fffff0;
    color: rgba(255, 255, 240, var(--placeholder-opacity));
  }

  .lg\:tw-placeholder-yellow-200::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #fefcbf;
    color: rgba(254, 252, 191, var(--placeholder-opacity));
  }

  .lg\:tw-placeholder-yellow-200::placeholder {
    --placeholder-opacity: 1;
    color: #fefcbf;
    color: rgba(254, 252, 191, var(--placeholder-opacity));
  }

  .lg\:tw-placeholder-yellow-300::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #faf089;
    color: rgba(250, 240, 137, var(--placeholder-opacity));
  }

  .lg\:tw-placeholder-yellow-300::placeholder {
    --placeholder-opacity: 1;
    color: #faf089;
    color: rgba(250, 240, 137, var(--placeholder-opacity));
  }

  .lg\:tw-placeholder-yellow-400::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #f6e05e;
    color: rgba(246, 224, 94, var(--placeholder-opacity));
  }

  .lg\:tw-placeholder-yellow-400::placeholder {
    --placeholder-opacity: 1;
    color: #f6e05e;
    color: rgba(246, 224, 94, var(--placeholder-opacity));
  }

  .lg\:tw-placeholder-yellow-500::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #ecc94b;
    color: rgba(236, 201, 75, var(--placeholder-opacity));
  }

  .lg\:tw-placeholder-yellow-500::placeholder {
    --placeholder-opacity: 1;
    color: #ecc94b;
    color: rgba(236, 201, 75, var(--placeholder-opacity));
  }

  .lg\:tw-placeholder-yellow-600::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #d69e2e;
    color: rgba(214, 158, 46, var(--placeholder-opacity));
  }

  .lg\:tw-placeholder-yellow-600::placeholder {
    --placeholder-opacity: 1;
    color: #d69e2e;
    color: rgba(214, 158, 46, var(--placeholder-opacity));
  }

  .lg\:tw-placeholder-yellow-700::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #b7791f;
    color: rgba(183, 121, 31, var(--placeholder-opacity));
  }

  .lg\:tw-placeholder-yellow-700::placeholder {
    --placeholder-opacity: 1;
    color: #b7791f;
    color: rgba(183, 121, 31, var(--placeholder-opacity));
  }

  .lg\:tw-placeholder-yellow-800::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #975a16;
    color: rgba(151, 90, 22, var(--placeholder-opacity));
  }

  .lg\:tw-placeholder-yellow-800::placeholder {
    --placeholder-opacity: 1;
    color: #975a16;
    color: rgba(151, 90, 22, var(--placeholder-opacity));
  }

  .lg\:tw-placeholder-yellow-900::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #744210;
    color: rgba(116, 66, 16, var(--placeholder-opacity));
  }

  .lg\:tw-placeholder-yellow-900::placeholder {
    --placeholder-opacity: 1;
    color: #744210;
    color: rgba(116, 66, 16, var(--placeholder-opacity));
  }

  .lg\:tw-placeholder-green-100::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #f0fff4;
    color: rgba(240, 255, 244, var(--placeholder-opacity));
  }

  .lg\:tw-placeholder-green-100::placeholder {
    --placeholder-opacity: 1;
    color: #f0fff4;
    color: rgba(240, 255, 244, var(--placeholder-opacity));
  }

  .lg\:tw-placeholder-green-200::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #c6f6d5;
    color: rgba(198, 246, 213, var(--placeholder-opacity));
  }

  .lg\:tw-placeholder-green-200::placeholder {
    --placeholder-opacity: 1;
    color: #c6f6d5;
    color: rgba(198, 246, 213, var(--placeholder-opacity));
  }

  .lg\:tw-placeholder-green-300::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #9ae6b4;
    color: rgba(154, 230, 180, var(--placeholder-opacity));
  }

  .lg\:tw-placeholder-green-300::placeholder {
    --placeholder-opacity: 1;
    color: #9ae6b4;
    color: rgba(154, 230, 180, var(--placeholder-opacity));
  }

  .lg\:tw-placeholder-green-400::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #68d391;
    color: rgba(104, 211, 145, var(--placeholder-opacity));
  }

  .lg\:tw-placeholder-green-400::placeholder {
    --placeholder-opacity: 1;
    color: #68d391;
    color: rgba(104, 211, 145, var(--placeholder-opacity));
  }

  .lg\:tw-placeholder-green-500::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #48bb78;
    color: rgba(72, 187, 120, var(--placeholder-opacity));
  }

  .lg\:tw-placeholder-green-500::placeholder {
    --placeholder-opacity: 1;
    color: #48bb78;
    color: rgba(72, 187, 120, var(--placeholder-opacity));
  }

  .lg\:tw-placeholder-green-600::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #38a169;
    color: rgba(56, 161, 105, var(--placeholder-opacity));
  }

  .lg\:tw-placeholder-green-600::placeholder {
    --placeholder-opacity: 1;
    color: #38a169;
    color: rgba(56, 161, 105, var(--placeholder-opacity));
  }

  .lg\:tw-placeholder-green-700::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #2f855a;
    color: rgba(47, 133, 90, var(--placeholder-opacity));
  }

  .lg\:tw-placeholder-green-700::placeholder {
    --placeholder-opacity: 1;
    color: #2f855a;
    color: rgba(47, 133, 90, var(--placeholder-opacity));
  }

  .lg\:tw-placeholder-green-800::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #276749;
    color: rgba(39, 103, 73, var(--placeholder-opacity));
  }

  .lg\:tw-placeholder-green-800::placeholder {
    --placeholder-opacity: 1;
    color: #276749;
    color: rgba(39, 103, 73, var(--placeholder-opacity));
  }

  .lg\:tw-placeholder-green-900::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #22543d;
    color: rgba(34, 84, 61, var(--placeholder-opacity));
  }

  .lg\:tw-placeholder-green-900::placeholder {
    --placeholder-opacity: 1;
    color: #22543d;
    color: rgba(34, 84, 61, var(--placeholder-opacity));
  }

  .lg\:tw-placeholder-teal-100::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #e6fffa;
    color: rgba(230, 255, 250, var(--placeholder-opacity));
  }

  .lg\:tw-placeholder-teal-100::placeholder {
    --placeholder-opacity: 1;
    color: #e6fffa;
    color: rgba(230, 255, 250, var(--placeholder-opacity));
  }

  .lg\:tw-placeholder-teal-200::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #b2f5ea;
    color: rgba(178, 245, 234, var(--placeholder-opacity));
  }

  .lg\:tw-placeholder-teal-200::placeholder {
    --placeholder-opacity: 1;
    color: #b2f5ea;
    color: rgba(178, 245, 234, var(--placeholder-opacity));
  }

  .lg\:tw-placeholder-teal-300::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #81e6d9;
    color: rgba(129, 230, 217, var(--placeholder-opacity));
  }

  .lg\:tw-placeholder-teal-300::placeholder {
    --placeholder-opacity: 1;
    color: #81e6d9;
    color: rgba(129, 230, 217, var(--placeholder-opacity));
  }

  .lg\:tw-placeholder-teal-400::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #4fd1c5;
    color: rgba(79, 209, 197, var(--placeholder-opacity));
  }

  .lg\:tw-placeholder-teal-400::placeholder {
    --placeholder-opacity: 1;
    color: #4fd1c5;
    color: rgba(79, 209, 197, var(--placeholder-opacity));
  }

  .lg\:tw-placeholder-teal-500::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #38b2ac;
    color: rgba(56, 178, 172, var(--placeholder-opacity));
  }

  .lg\:tw-placeholder-teal-500::placeholder {
    --placeholder-opacity: 1;
    color: #38b2ac;
    color: rgba(56, 178, 172, var(--placeholder-opacity));
  }

  .lg\:tw-placeholder-teal-600::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #319795;
    color: rgba(49, 151, 149, var(--placeholder-opacity));
  }

  .lg\:tw-placeholder-teal-600::placeholder {
    --placeholder-opacity: 1;
    color: #319795;
    color: rgba(49, 151, 149, var(--placeholder-opacity));
  }

  .lg\:tw-placeholder-teal-700::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #2c7a7b;
    color: rgba(44, 122, 123, var(--placeholder-opacity));
  }

  .lg\:tw-placeholder-teal-700::placeholder {
    --placeholder-opacity: 1;
    color: #2c7a7b;
    color: rgba(44, 122, 123, var(--placeholder-opacity));
  }

  .lg\:tw-placeholder-teal-800::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #285e61;
    color: rgba(40, 94, 97, var(--placeholder-opacity));
  }

  .lg\:tw-placeholder-teal-800::placeholder {
    --placeholder-opacity: 1;
    color: #285e61;
    color: rgba(40, 94, 97, var(--placeholder-opacity));
  }

  .lg\:tw-placeholder-teal-900::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #234e52;
    color: rgba(35, 78, 82, var(--placeholder-opacity));
  }

  .lg\:tw-placeholder-teal-900::placeholder {
    --placeholder-opacity: 1;
    color: #234e52;
    color: rgba(35, 78, 82, var(--placeholder-opacity));
  }

  .lg\:tw-placeholder-blue-100::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #ebf8ff;
    color: rgba(235, 248, 255, var(--placeholder-opacity));
  }

  .lg\:tw-placeholder-blue-100::placeholder {
    --placeholder-opacity: 1;
    color: #ebf8ff;
    color: rgba(235, 248, 255, var(--placeholder-opacity));
  }

  .lg\:tw-placeholder-blue-200::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #bee3f8;
    color: rgba(190, 227, 248, var(--placeholder-opacity));
  }

  .lg\:tw-placeholder-blue-200::placeholder {
    --placeholder-opacity: 1;
    color: #bee3f8;
    color: rgba(190, 227, 248, var(--placeholder-opacity));
  }

  .lg\:tw-placeholder-blue-300::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #90cdf4;
    color: rgba(144, 205, 244, var(--placeholder-opacity));
  }

  .lg\:tw-placeholder-blue-300::placeholder {
    --placeholder-opacity: 1;
    color: #90cdf4;
    color: rgba(144, 205, 244, var(--placeholder-opacity));
  }

  .lg\:tw-placeholder-blue-400::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #63b3ed;
    color: rgba(99, 179, 237, var(--placeholder-opacity));
  }

  .lg\:tw-placeholder-blue-400::placeholder {
    --placeholder-opacity: 1;
    color: #63b3ed;
    color: rgba(99, 179, 237, var(--placeholder-opacity));
  }

  .lg\:tw-placeholder-blue-500::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #4299e1;
    color: rgba(66, 153, 225, var(--placeholder-opacity));
  }

  .lg\:tw-placeholder-blue-500::placeholder {
    --placeholder-opacity: 1;
    color: #4299e1;
    color: rgba(66, 153, 225, var(--placeholder-opacity));
  }

  .lg\:tw-placeholder-blue-600::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #3182ce;
    color: rgba(49, 130, 206, var(--placeholder-opacity));
  }

  .lg\:tw-placeholder-blue-600::placeholder {
    --placeholder-opacity: 1;
    color: #3182ce;
    color: rgba(49, 130, 206, var(--placeholder-opacity));
  }

  .lg\:tw-placeholder-blue-700::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #2b6cb0;
    color: rgba(43, 108, 176, var(--placeholder-opacity));
  }

  .lg\:tw-placeholder-blue-700::placeholder {
    --placeholder-opacity: 1;
    color: #2b6cb0;
    color: rgba(43, 108, 176, var(--placeholder-opacity));
  }

  .lg\:tw-placeholder-blue-800::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #2c5282;
    color: rgba(44, 82, 130, var(--placeholder-opacity));
  }

  .lg\:tw-placeholder-blue-800::placeholder {
    --placeholder-opacity: 1;
    color: #2c5282;
    color: rgba(44, 82, 130, var(--placeholder-opacity));
  }

  .lg\:tw-placeholder-blue-900::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #2a4365;
    color: rgba(42, 67, 101, var(--placeholder-opacity));
  }

  .lg\:tw-placeholder-blue-900::placeholder {
    --placeholder-opacity: 1;
    color: #2a4365;
    color: rgba(42, 67, 101, var(--placeholder-opacity));
  }

  .lg\:tw-placeholder-indigo-100::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #ebf4ff;
    color: rgba(235, 244, 255, var(--placeholder-opacity));
  }

  .lg\:tw-placeholder-indigo-100::placeholder {
    --placeholder-opacity: 1;
    color: #ebf4ff;
    color: rgba(235, 244, 255, var(--placeholder-opacity));
  }

  .lg\:tw-placeholder-indigo-200::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #c3dafe;
    color: rgba(195, 218, 254, var(--placeholder-opacity));
  }

  .lg\:tw-placeholder-indigo-200::placeholder {
    --placeholder-opacity: 1;
    color: #c3dafe;
    color: rgba(195, 218, 254, var(--placeholder-opacity));
  }

  .lg\:tw-placeholder-indigo-300::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #a3bffa;
    color: rgba(163, 191, 250, var(--placeholder-opacity));
  }

  .lg\:tw-placeholder-indigo-300::placeholder {
    --placeholder-opacity: 1;
    color: #a3bffa;
    color: rgba(163, 191, 250, var(--placeholder-opacity));
  }

  .lg\:tw-placeholder-indigo-400::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #7f9cf5;
    color: rgba(127, 156, 245, var(--placeholder-opacity));
  }

  .lg\:tw-placeholder-indigo-400::placeholder {
    --placeholder-opacity: 1;
    color: #7f9cf5;
    color: rgba(127, 156, 245, var(--placeholder-opacity));
  }

  .lg\:tw-placeholder-indigo-500::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #667eea;
    color: rgba(102, 126, 234, var(--placeholder-opacity));
  }

  .lg\:tw-placeholder-indigo-500::placeholder {
    --placeholder-opacity: 1;
    color: #667eea;
    color: rgba(102, 126, 234, var(--placeholder-opacity));
  }

  .lg\:tw-placeholder-indigo-600::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #5a67d8;
    color: rgba(90, 103, 216, var(--placeholder-opacity));
  }

  .lg\:tw-placeholder-indigo-600::placeholder {
    --placeholder-opacity: 1;
    color: #5a67d8;
    color: rgba(90, 103, 216, var(--placeholder-opacity));
  }

  .lg\:tw-placeholder-indigo-700::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #4c51bf;
    color: rgba(76, 81, 191, var(--placeholder-opacity));
  }

  .lg\:tw-placeholder-indigo-700::placeholder {
    --placeholder-opacity: 1;
    color: #4c51bf;
    color: rgba(76, 81, 191, var(--placeholder-opacity));
  }

  .lg\:tw-placeholder-indigo-800::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #434190;
    color: rgba(67, 65, 144, var(--placeholder-opacity));
  }

  .lg\:tw-placeholder-indigo-800::placeholder {
    --placeholder-opacity: 1;
    color: #434190;
    color: rgba(67, 65, 144, var(--placeholder-opacity));
  }

  .lg\:tw-placeholder-indigo-900::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #3c366b;
    color: rgba(60, 54, 107, var(--placeholder-opacity));
  }

  .lg\:tw-placeholder-indigo-900::placeholder {
    --placeholder-opacity: 1;
    color: #3c366b;
    color: rgba(60, 54, 107, var(--placeholder-opacity));
  }

  .lg\:tw-placeholder-purple-100::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #faf5ff;
    color: rgba(250, 245, 255, var(--placeholder-opacity));
  }

  .lg\:tw-placeholder-purple-100::placeholder {
    --placeholder-opacity: 1;
    color: #faf5ff;
    color: rgba(250, 245, 255, var(--placeholder-opacity));
  }

  .lg\:tw-placeholder-purple-200::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #e9d8fd;
    color: rgba(233, 216, 253, var(--placeholder-opacity));
  }

  .lg\:tw-placeholder-purple-200::placeholder {
    --placeholder-opacity: 1;
    color: #e9d8fd;
    color: rgba(233, 216, 253, var(--placeholder-opacity));
  }

  .lg\:tw-placeholder-purple-300::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #d6bcfa;
    color: rgba(214, 188, 250, var(--placeholder-opacity));
  }

  .lg\:tw-placeholder-purple-300::placeholder {
    --placeholder-opacity: 1;
    color: #d6bcfa;
    color: rgba(214, 188, 250, var(--placeholder-opacity));
  }

  .lg\:tw-placeholder-purple-400::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #b794f4;
    color: rgba(183, 148, 244, var(--placeholder-opacity));
  }

  .lg\:tw-placeholder-purple-400::placeholder {
    --placeholder-opacity: 1;
    color: #b794f4;
    color: rgba(183, 148, 244, var(--placeholder-opacity));
  }

  .lg\:tw-placeholder-purple-500::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #9f7aea;
    color: rgba(159, 122, 234, var(--placeholder-opacity));
  }

  .lg\:tw-placeholder-purple-500::placeholder {
    --placeholder-opacity: 1;
    color: #9f7aea;
    color: rgba(159, 122, 234, var(--placeholder-opacity));
  }

  .lg\:tw-placeholder-purple-600::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #805ad5;
    color: rgba(128, 90, 213, var(--placeholder-opacity));
  }

  .lg\:tw-placeholder-purple-600::placeholder {
    --placeholder-opacity: 1;
    color: #805ad5;
    color: rgba(128, 90, 213, var(--placeholder-opacity));
  }

  .lg\:tw-placeholder-purple-700::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #6b46c1;
    color: rgba(107, 70, 193, var(--placeholder-opacity));
  }

  .lg\:tw-placeholder-purple-700::placeholder {
    --placeholder-opacity: 1;
    color: #6b46c1;
    color: rgba(107, 70, 193, var(--placeholder-opacity));
  }

  .lg\:tw-placeholder-purple-800::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #553c9a;
    color: rgba(85, 60, 154, var(--placeholder-opacity));
  }

  .lg\:tw-placeholder-purple-800::placeholder {
    --placeholder-opacity: 1;
    color: #553c9a;
    color: rgba(85, 60, 154, var(--placeholder-opacity));
  }

  .lg\:tw-placeholder-purple-900::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #44337a;
    color: rgba(68, 51, 122, var(--placeholder-opacity));
  }

  .lg\:tw-placeholder-purple-900::placeholder {
    --placeholder-opacity: 1;
    color: #44337a;
    color: rgba(68, 51, 122, var(--placeholder-opacity));
  }

  .lg\:tw-placeholder-pink-100::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #fff5f7;
    color: rgba(255, 245, 247, var(--placeholder-opacity));
  }

  .lg\:tw-placeholder-pink-100::placeholder {
    --placeholder-opacity: 1;
    color: #fff5f7;
    color: rgba(255, 245, 247, var(--placeholder-opacity));
  }

  .lg\:tw-placeholder-pink-200::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #fed7e2;
    color: rgba(254, 215, 226, var(--placeholder-opacity));
  }

  .lg\:tw-placeholder-pink-200::placeholder {
    --placeholder-opacity: 1;
    color: #fed7e2;
    color: rgba(254, 215, 226, var(--placeholder-opacity));
  }

  .lg\:tw-placeholder-pink-300::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #fbb6ce;
    color: rgba(251, 182, 206, var(--placeholder-opacity));
  }

  .lg\:tw-placeholder-pink-300::placeholder {
    --placeholder-opacity: 1;
    color: #fbb6ce;
    color: rgba(251, 182, 206, var(--placeholder-opacity));
  }

  .lg\:tw-placeholder-pink-400::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #f687b3;
    color: rgba(246, 135, 179, var(--placeholder-opacity));
  }

  .lg\:tw-placeholder-pink-400::placeholder {
    --placeholder-opacity: 1;
    color: #f687b3;
    color: rgba(246, 135, 179, var(--placeholder-opacity));
  }

  .lg\:tw-placeholder-pink-500::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #ed64a6;
    color: rgba(237, 100, 166, var(--placeholder-opacity));
  }

  .lg\:tw-placeholder-pink-500::placeholder {
    --placeholder-opacity: 1;
    color: #ed64a6;
    color: rgba(237, 100, 166, var(--placeholder-opacity));
  }

  .lg\:tw-placeholder-pink-600::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #d53f8c;
    color: rgba(213, 63, 140, var(--placeholder-opacity));
  }

  .lg\:tw-placeholder-pink-600::placeholder {
    --placeholder-opacity: 1;
    color: #d53f8c;
    color: rgba(213, 63, 140, var(--placeholder-opacity));
  }

  .lg\:tw-placeholder-pink-700::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #b83280;
    color: rgba(184, 50, 128, var(--placeholder-opacity));
  }

  .lg\:tw-placeholder-pink-700::placeholder {
    --placeholder-opacity: 1;
    color: #b83280;
    color: rgba(184, 50, 128, var(--placeholder-opacity));
  }

  .lg\:tw-placeholder-pink-800::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #97266d;
    color: rgba(151, 38, 109, var(--placeholder-opacity));
  }

  .lg\:tw-placeholder-pink-800::placeholder {
    --placeholder-opacity: 1;
    color: #97266d;
    color: rgba(151, 38, 109, var(--placeholder-opacity));
  }

  .lg\:tw-placeholder-pink-900::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #702459;
    color: rgba(112, 36, 89, var(--placeholder-opacity));
  }

  .lg\:tw-placeholder-pink-900::placeholder {
    --placeholder-opacity: 1;
    color: #702459;
    color: rgba(112, 36, 89, var(--placeholder-opacity));
  }

  .lg\:focus\:tw-placeholder-transparent:focus::-moz-placeholder {
    color: transparent;
  }

  .lg\:focus\:tw-placeholder-transparent:focus::placeholder {
    color: transparent;
  }

  .lg\:focus\:tw-placeholder-current:focus::-moz-placeholder {
    color: currentColor;
  }

  .lg\:focus\:tw-placeholder-current:focus::placeholder {
    color: currentColor;
  }

  .lg\:focus\:tw-placeholder-black:focus::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #000;
    color: rgba(0, 0, 0, var(--placeholder-opacity));
  }

  .lg\:focus\:tw-placeholder-black:focus::placeholder {
    --placeholder-opacity: 1;
    color: #000;
    color: rgba(0, 0, 0, var(--placeholder-opacity));
  }

  .lg\:focus\:tw-placeholder-white:focus::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #fff;
    color: rgba(255, 255, 255, var(--placeholder-opacity));
  }

  .lg\:focus\:tw-placeholder-white:focus::placeholder {
    --placeholder-opacity: 1;
    color: #fff;
    color: rgba(255, 255, 255, var(--placeholder-opacity));
  }

  .lg\:focus\:tw-placeholder-gray-100:focus::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #f7fafc;
    color: rgba(247, 250, 252, var(--placeholder-opacity));
  }

  .lg\:focus\:tw-placeholder-gray-100:focus::placeholder {
    --placeholder-opacity: 1;
    color: #f7fafc;
    color: rgba(247, 250, 252, var(--placeholder-opacity));
  }

  .lg\:focus\:tw-placeholder-gray-200:focus::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #edf2f7;
    color: rgba(237, 242, 247, var(--placeholder-opacity));
  }

  .lg\:focus\:tw-placeholder-gray-200:focus::placeholder {
    --placeholder-opacity: 1;
    color: #edf2f7;
    color: rgba(237, 242, 247, var(--placeholder-opacity));
  }

  .lg\:focus\:tw-placeholder-gray-300:focus::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #e2e8f0;
    color: rgba(226, 232, 240, var(--placeholder-opacity));
  }

  .lg\:focus\:tw-placeholder-gray-300:focus::placeholder {
    --placeholder-opacity: 1;
    color: #e2e8f0;
    color: rgba(226, 232, 240, var(--placeholder-opacity));
  }

  .lg\:focus\:tw-placeholder-gray-400:focus::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #cbd5e0;
    color: rgba(203, 213, 224, var(--placeholder-opacity));
  }

  .lg\:focus\:tw-placeholder-gray-400:focus::placeholder {
    --placeholder-opacity: 1;
    color: #cbd5e0;
    color: rgba(203, 213, 224, var(--placeholder-opacity));
  }

  .lg\:focus\:tw-placeholder-gray-500:focus::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #a0aec0;
    color: rgba(160, 174, 192, var(--placeholder-opacity));
  }

  .lg\:focus\:tw-placeholder-gray-500:focus::placeholder {
    --placeholder-opacity: 1;
    color: #a0aec0;
    color: rgba(160, 174, 192, var(--placeholder-opacity));
  }

  .lg\:focus\:tw-placeholder-gray-600:focus::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #718096;
    color: rgba(113, 128, 150, var(--placeholder-opacity));
  }

  .lg\:focus\:tw-placeholder-gray-600:focus::placeholder {
    --placeholder-opacity: 1;
    color: #718096;
    color: rgba(113, 128, 150, var(--placeholder-opacity));
  }

  .lg\:focus\:tw-placeholder-gray-700:focus::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #4a5568;
    color: rgba(74, 85, 104, var(--placeholder-opacity));
  }

  .lg\:focus\:tw-placeholder-gray-700:focus::placeholder {
    --placeholder-opacity: 1;
    color: #4a5568;
    color: rgba(74, 85, 104, var(--placeholder-opacity));
  }

  .lg\:focus\:tw-placeholder-gray-800:focus::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #2d3748;
    color: rgba(45, 55, 72, var(--placeholder-opacity));
  }

  .lg\:focus\:tw-placeholder-gray-800:focus::placeholder {
    --placeholder-opacity: 1;
    color: #2d3748;
    color: rgba(45, 55, 72, var(--placeholder-opacity));
  }

  .lg\:focus\:tw-placeholder-gray-900:focus::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #1a202c;
    color: rgba(26, 32, 44, var(--placeholder-opacity));
  }

  .lg\:focus\:tw-placeholder-gray-900:focus::placeholder {
    --placeholder-opacity: 1;
    color: #1a202c;
    color: rgba(26, 32, 44, var(--placeholder-opacity));
  }

  .lg\:focus\:tw-placeholder-red-100:focus::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #fff5f5;
    color: rgba(255, 245, 245, var(--placeholder-opacity));
  }

  .lg\:focus\:tw-placeholder-red-100:focus::placeholder {
    --placeholder-opacity: 1;
    color: #fff5f5;
    color: rgba(255, 245, 245, var(--placeholder-opacity));
  }

  .lg\:focus\:tw-placeholder-red-200:focus::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #fed7d7;
    color: rgba(254, 215, 215, var(--placeholder-opacity));
  }

  .lg\:focus\:tw-placeholder-red-200:focus::placeholder {
    --placeholder-opacity: 1;
    color: #fed7d7;
    color: rgba(254, 215, 215, var(--placeholder-opacity));
  }

  .lg\:focus\:tw-placeholder-red-300:focus::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #feb2b2;
    color: rgba(254, 178, 178, var(--placeholder-opacity));
  }

  .lg\:focus\:tw-placeholder-red-300:focus::placeholder {
    --placeholder-opacity: 1;
    color: #feb2b2;
    color: rgba(254, 178, 178, var(--placeholder-opacity));
  }

  .lg\:focus\:tw-placeholder-red-400:focus::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #fc8181;
    color: rgba(252, 129, 129, var(--placeholder-opacity));
  }

  .lg\:focus\:tw-placeholder-red-400:focus::placeholder {
    --placeholder-opacity: 1;
    color: #fc8181;
    color: rgba(252, 129, 129, var(--placeholder-opacity));
  }

  .lg\:focus\:tw-placeholder-red-500:focus::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #f56565;
    color: rgba(245, 101, 101, var(--placeholder-opacity));
  }

  .lg\:focus\:tw-placeholder-red-500:focus::placeholder {
    --placeholder-opacity: 1;
    color: #f56565;
    color: rgba(245, 101, 101, var(--placeholder-opacity));
  }

  .lg\:focus\:tw-placeholder-red-600:focus::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #e53e3e;
    color: rgba(229, 62, 62, var(--placeholder-opacity));
  }

  .lg\:focus\:tw-placeholder-red-600:focus::placeholder {
    --placeholder-opacity: 1;
    color: #e53e3e;
    color: rgba(229, 62, 62, var(--placeholder-opacity));
  }

  .lg\:focus\:tw-placeholder-red-700:focus::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #c53030;
    color: rgba(197, 48, 48, var(--placeholder-opacity));
  }

  .lg\:focus\:tw-placeholder-red-700:focus::placeholder {
    --placeholder-opacity: 1;
    color: #c53030;
    color: rgba(197, 48, 48, var(--placeholder-opacity));
  }

  .lg\:focus\:tw-placeholder-red-800:focus::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #9b2c2c;
    color: rgba(155, 44, 44, var(--placeholder-opacity));
  }

  .lg\:focus\:tw-placeholder-red-800:focus::placeholder {
    --placeholder-opacity: 1;
    color: #9b2c2c;
    color: rgba(155, 44, 44, var(--placeholder-opacity));
  }

  .lg\:focus\:tw-placeholder-red-900:focus::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #742a2a;
    color: rgba(116, 42, 42, var(--placeholder-opacity));
  }

  .lg\:focus\:tw-placeholder-red-900:focus::placeholder {
    --placeholder-opacity: 1;
    color: #742a2a;
    color: rgba(116, 42, 42, var(--placeholder-opacity));
  }

  .lg\:focus\:tw-placeholder-orange-100:focus::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #fffaf0;
    color: rgba(255, 250, 240, var(--placeholder-opacity));
  }

  .lg\:focus\:tw-placeholder-orange-100:focus::placeholder {
    --placeholder-opacity: 1;
    color: #fffaf0;
    color: rgba(255, 250, 240, var(--placeholder-opacity));
  }

  .lg\:focus\:tw-placeholder-orange-200:focus::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #feebc8;
    color: rgba(254, 235, 200, var(--placeholder-opacity));
  }

  .lg\:focus\:tw-placeholder-orange-200:focus::placeholder {
    --placeholder-opacity: 1;
    color: #feebc8;
    color: rgba(254, 235, 200, var(--placeholder-opacity));
  }

  .lg\:focus\:tw-placeholder-orange-300:focus::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #fbd38d;
    color: rgba(251, 211, 141, var(--placeholder-opacity));
  }

  .lg\:focus\:tw-placeholder-orange-300:focus::placeholder {
    --placeholder-opacity: 1;
    color: #fbd38d;
    color: rgba(251, 211, 141, var(--placeholder-opacity));
  }

  .lg\:focus\:tw-placeholder-orange-400:focus::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #f6ad55;
    color: rgba(246, 173, 85, var(--placeholder-opacity));
  }

  .lg\:focus\:tw-placeholder-orange-400:focus::placeholder {
    --placeholder-opacity: 1;
    color: #f6ad55;
    color: rgba(246, 173, 85, var(--placeholder-opacity));
  }

  .lg\:focus\:tw-placeholder-orange-500:focus::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #ed8936;
    color: rgba(237, 137, 54, var(--placeholder-opacity));
  }

  .lg\:focus\:tw-placeholder-orange-500:focus::placeholder {
    --placeholder-opacity: 1;
    color: #ed8936;
    color: rgba(237, 137, 54, var(--placeholder-opacity));
  }

  .lg\:focus\:tw-placeholder-orange-600:focus::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #dd6b20;
    color: rgba(221, 107, 32, var(--placeholder-opacity));
  }

  .lg\:focus\:tw-placeholder-orange-600:focus::placeholder {
    --placeholder-opacity: 1;
    color: #dd6b20;
    color: rgba(221, 107, 32, var(--placeholder-opacity));
  }

  .lg\:focus\:tw-placeholder-orange-700:focus::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #c05621;
    color: rgba(192, 86, 33, var(--placeholder-opacity));
  }

  .lg\:focus\:tw-placeholder-orange-700:focus::placeholder {
    --placeholder-opacity: 1;
    color: #c05621;
    color: rgba(192, 86, 33, var(--placeholder-opacity));
  }

  .lg\:focus\:tw-placeholder-orange-800:focus::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #9c4221;
    color: rgba(156, 66, 33, var(--placeholder-opacity));
  }

  .lg\:focus\:tw-placeholder-orange-800:focus::placeholder {
    --placeholder-opacity: 1;
    color: #9c4221;
    color: rgba(156, 66, 33, var(--placeholder-opacity));
  }

  .lg\:focus\:tw-placeholder-orange-900:focus::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #7b341e;
    color: rgba(123, 52, 30, var(--placeholder-opacity));
  }

  .lg\:focus\:tw-placeholder-orange-900:focus::placeholder {
    --placeholder-opacity: 1;
    color: #7b341e;
    color: rgba(123, 52, 30, var(--placeholder-opacity));
  }

  .lg\:focus\:tw-placeholder-yellow-100:focus::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #fffff0;
    color: rgba(255, 255, 240, var(--placeholder-opacity));
  }

  .lg\:focus\:tw-placeholder-yellow-100:focus::placeholder {
    --placeholder-opacity: 1;
    color: #fffff0;
    color: rgba(255, 255, 240, var(--placeholder-opacity));
  }

  .lg\:focus\:tw-placeholder-yellow-200:focus::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #fefcbf;
    color: rgba(254, 252, 191, var(--placeholder-opacity));
  }

  .lg\:focus\:tw-placeholder-yellow-200:focus::placeholder {
    --placeholder-opacity: 1;
    color: #fefcbf;
    color: rgba(254, 252, 191, var(--placeholder-opacity));
  }

  .lg\:focus\:tw-placeholder-yellow-300:focus::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #faf089;
    color: rgba(250, 240, 137, var(--placeholder-opacity));
  }

  .lg\:focus\:tw-placeholder-yellow-300:focus::placeholder {
    --placeholder-opacity: 1;
    color: #faf089;
    color: rgba(250, 240, 137, var(--placeholder-opacity));
  }

  .lg\:focus\:tw-placeholder-yellow-400:focus::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #f6e05e;
    color: rgba(246, 224, 94, var(--placeholder-opacity));
  }

  .lg\:focus\:tw-placeholder-yellow-400:focus::placeholder {
    --placeholder-opacity: 1;
    color: #f6e05e;
    color: rgba(246, 224, 94, var(--placeholder-opacity));
  }

  .lg\:focus\:tw-placeholder-yellow-500:focus::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #ecc94b;
    color: rgba(236, 201, 75, var(--placeholder-opacity));
  }

  .lg\:focus\:tw-placeholder-yellow-500:focus::placeholder {
    --placeholder-opacity: 1;
    color: #ecc94b;
    color: rgba(236, 201, 75, var(--placeholder-opacity));
  }

  .lg\:focus\:tw-placeholder-yellow-600:focus::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #d69e2e;
    color: rgba(214, 158, 46, var(--placeholder-opacity));
  }

  .lg\:focus\:tw-placeholder-yellow-600:focus::placeholder {
    --placeholder-opacity: 1;
    color: #d69e2e;
    color: rgba(214, 158, 46, var(--placeholder-opacity));
  }

  .lg\:focus\:tw-placeholder-yellow-700:focus::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #b7791f;
    color: rgba(183, 121, 31, var(--placeholder-opacity));
  }

  .lg\:focus\:tw-placeholder-yellow-700:focus::placeholder {
    --placeholder-opacity: 1;
    color: #b7791f;
    color: rgba(183, 121, 31, var(--placeholder-opacity));
  }

  .lg\:focus\:tw-placeholder-yellow-800:focus::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #975a16;
    color: rgba(151, 90, 22, var(--placeholder-opacity));
  }

  .lg\:focus\:tw-placeholder-yellow-800:focus::placeholder {
    --placeholder-opacity: 1;
    color: #975a16;
    color: rgba(151, 90, 22, var(--placeholder-opacity));
  }

  .lg\:focus\:tw-placeholder-yellow-900:focus::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #744210;
    color: rgba(116, 66, 16, var(--placeholder-opacity));
  }

  .lg\:focus\:tw-placeholder-yellow-900:focus::placeholder {
    --placeholder-opacity: 1;
    color: #744210;
    color: rgba(116, 66, 16, var(--placeholder-opacity));
  }

  .lg\:focus\:tw-placeholder-green-100:focus::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #f0fff4;
    color: rgba(240, 255, 244, var(--placeholder-opacity));
  }

  .lg\:focus\:tw-placeholder-green-100:focus::placeholder {
    --placeholder-opacity: 1;
    color: #f0fff4;
    color: rgba(240, 255, 244, var(--placeholder-opacity));
  }

  .lg\:focus\:tw-placeholder-green-200:focus::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #c6f6d5;
    color: rgba(198, 246, 213, var(--placeholder-opacity));
  }

  .lg\:focus\:tw-placeholder-green-200:focus::placeholder {
    --placeholder-opacity: 1;
    color: #c6f6d5;
    color: rgba(198, 246, 213, var(--placeholder-opacity));
  }

  .lg\:focus\:tw-placeholder-green-300:focus::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #9ae6b4;
    color: rgba(154, 230, 180, var(--placeholder-opacity));
  }

  .lg\:focus\:tw-placeholder-green-300:focus::placeholder {
    --placeholder-opacity: 1;
    color: #9ae6b4;
    color: rgba(154, 230, 180, var(--placeholder-opacity));
  }

  .lg\:focus\:tw-placeholder-green-400:focus::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #68d391;
    color: rgba(104, 211, 145, var(--placeholder-opacity));
  }

  .lg\:focus\:tw-placeholder-green-400:focus::placeholder {
    --placeholder-opacity: 1;
    color: #68d391;
    color: rgba(104, 211, 145, var(--placeholder-opacity));
  }

  .lg\:focus\:tw-placeholder-green-500:focus::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #48bb78;
    color: rgba(72, 187, 120, var(--placeholder-opacity));
  }

  .lg\:focus\:tw-placeholder-green-500:focus::placeholder {
    --placeholder-opacity: 1;
    color: #48bb78;
    color: rgba(72, 187, 120, var(--placeholder-opacity));
  }

  .lg\:focus\:tw-placeholder-green-600:focus::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #38a169;
    color: rgba(56, 161, 105, var(--placeholder-opacity));
  }

  .lg\:focus\:tw-placeholder-green-600:focus::placeholder {
    --placeholder-opacity: 1;
    color: #38a169;
    color: rgba(56, 161, 105, var(--placeholder-opacity));
  }

  .lg\:focus\:tw-placeholder-green-700:focus::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #2f855a;
    color: rgba(47, 133, 90, var(--placeholder-opacity));
  }

  .lg\:focus\:tw-placeholder-green-700:focus::placeholder {
    --placeholder-opacity: 1;
    color: #2f855a;
    color: rgba(47, 133, 90, var(--placeholder-opacity));
  }

  .lg\:focus\:tw-placeholder-green-800:focus::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #276749;
    color: rgba(39, 103, 73, var(--placeholder-opacity));
  }

  .lg\:focus\:tw-placeholder-green-800:focus::placeholder {
    --placeholder-opacity: 1;
    color: #276749;
    color: rgba(39, 103, 73, var(--placeholder-opacity));
  }

  .lg\:focus\:tw-placeholder-green-900:focus::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #22543d;
    color: rgba(34, 84, 61, var(--placeholder-opacity));
  }

  .lg\:focus\:tw-placeholder-green-900:focus::placeholder {
    --placeholder-opacity: 1;
    color: #22543d;
    color: rgba(34, 84, 61, var(--placeholder-opacity));
  }

  .lg\:focus\:tw-placeholder-teal-100:focus::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #e6fffa;
    color: rgba(230, 255, 250, var(--placeholder-opacity));
  }

  .lg\:focus\:tw-placeholder-teal-100:focus::placeholder {
    --placeholder-opacity: 1;
    color: #e6fffa;
    color: rgba(230, 255, 250, var(--placeholder-opacity));
  }

  .lg\:focus\:tw-placeholder-teal-200:focus::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #b2f5ea;
    color: rgba(178, 245, 234, var(--placeholder-opacity));
  }

  .lg\:focus\:tw-placeholder-teal-200:focus::placeholder {
    --placeholder-opacity: 1;
    color: #b2f5ea;
    color: rgba(178, 245, 234, var(--placeholder-opacity));
  }

  .lg\:focus\:tw-placeholder-teal-300:focus::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #81e6d9;
    color: rgba(129, 230, 217, var(--placeholder-opacity));
  }

  .lg\:focus\:tw-placeholder-teal-300:focus::placeholder {
    --placeholder-opacity: 1;
    color: #81e6d9;
    color: rgba(129, 230, 217, var(--placeholder-opacity));
  }

  .lg\:focus\:tw-placeholder-teal-400:focus::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #4fd1c5;
    color: rgba(79, 209, 197, var(--placeholder-opacity));
  }

  .lg\:focus\:tw-placeholder-teal-400:focus::placeholder {
    --placeholder-opacity: 1;
    color: #4fd1c5;
    color: rgba(79, 209, 197, var(--placeholder-opacity));
  }

  .lg\:focus\:tw-placeholder-teal-500:focus::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #38b2ac;
    color: rgba(56, 178, 172, var(--placeholder-opacity));
  }

  .lg\:focus\:tw-placeholder-teal-500:focus::placeholder {
    --placeholder-opacity: 1;
    color: #38b2ac;
    color: rgba(56, 178, 172, var(--placeholder-opacity));
  }

  .lg\:focus\:tw-placeholder-teal-600:focus::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #319795;
    color: rgba(49, 151, 149, var(--placeholder-opacity));
  }

  .lg\:focus\:tw-placeholder-teal-600:focus::placeholder {
    --placeholder-opacity: 1;
    color: #319795;
    color: rgba(49, 151, 149, var(--placeholder-opacity));
  }

  .lg\:focus\:tw-placeholder-teal-700:focus::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #2c7a7b;
    color: rgba(44, 122, 123, var(--placeholder-opacity));
  }

  .lg\:focus\:tw-placeholder-teal-700:focus::placeholder {
    --placeholder-opacity: 1;
    color: #2c7a7b;
    color: rgba(44, 122, 123, var(--placeholder-opacity));
  }

  .lg\:focus\:tw-placeholder-teal-800:focus::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #285e61;
    color: rgba(40, 94, 97, var(--placeholder-opacity));
  }

  .lg\:focus\:tw-placeholder-teal-800:focus::placeholder {
    --placeholder-opacity: 1;
    color: #285e61;
    color: rgba(40, 94, 97, var(--placeholder-opacity));
  }

  .lg\:focus\:tw-placeholder-teal-900:focus::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #234e52;
    color: rgba(35, 78, 82, var(--placeholder-opacity));
  }

  .lg\:focus\:tw-placeholder-teal-900:focus::placeholder {
    --placeholder-opacity: 1;
    color: #234e52;
    color: rgba(35, 78, 82, var(--placeholder-opacity));
  }

  .lg\:focus\:tw-placeholder-blue-100:focus::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #ebf8ff;
    color: rgba(235, 248, 255, var(--placeholder-opacity));
  }

  .lg\:focus\:tw-placeholder-blue-100:focus::placeholder {
    --placeholder-opacity: 1;
    color: #ebf8ff;
    color: rgba(235, 248, 255, var(--placeholder-opacity));
  }

  .lg\:focus\:tw-placeholder-blue-200:focus::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #bee3f8;
    color: rgba(190, 227, 248, var(--placeholder-opacity));
  }

  .lg\:focus\:tw-placeholder-blue-200:focus::placeholder {
    --placeholder-opacity: 1;
    color: #bee3f8;
    color: rgba(190, 227, 248, var(--placeholder-opacity));
  }

  .lg\:focus\:tw-placeholder-blue-300:focus::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #90cdf4;
    color: rgba(144, 205, 244, var(--placeholder-opacity));
  }

  .lg\:focus\:tw-placeholder-blue-300:focus::placeholder {
    --placeholder-opacity: 1;
    color: #90cdf4;
    color: rgba(144, 205, 244, var(--placeholder-opacity));
  }

  .lg\:focus\:tw-placeholder-blue-400:focus::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #63b3ed;
    color: rgba(99, 179, 237, var(--placeholder-opacity));
  }

  .lg\:focus\:tw-placeholder-blue-400:focus::placeholder {
    --placeholder-opacity: 1;
    color: #63b3ed;
    color: rgba(99, 179, 237, var(--placeholder-opacity));
  }

  .lg\:focus\:tw-placeholder-blue-500:focus::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #4299e1;
    color: rgba(66, 153, 225, var(--placeholder-opacity));
  }

  .lg\:focus\:tw-placeholder-blue-500:focus::placeholder {
    --placeholder-opacity: 1;
    color: #4299e1;
    color: rgba(66, 153, 225, var(--placeholder-opacity));
  }

  .lg\:focus\:tw-placeholder-blue-600:focus::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #3182ce;
    color: rgba(49, 130, 206, var(--placeholder-opacity));
  }

  .lg\:focus\:tw-placeholder-blue-600:focus::placeholder {
    --placeholder-opacity: 1;
    color: #3182ce;
    color: rgba(49, 130, 206, var(--placeholder-opacity));
  }

  .lg\:focus\:tw-placeholder-blue-700:focus::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #2b6cb0;
    color: rgba(43, 108, 176, var(--placeholder-opacity));
  }

  .lg\:focus\:tw-placeholder-blue-700:focus::placeholder {
    --placeholder-opacity: 1;
    color: #2b6cb0;
    color: rgba(43, 108, 176, var(--placeholder-opacity));
  }

  .lg\:focus\:tw-placeholder-blue-800:focus::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #2c5282;
    color: rgba(44, 82, 130, var(--placeholder-opacity));
  }

  .lg\:focus\:tw-placeholder-blue-800:focus::placeholder {
    --placeholder-opacity: 1;
    color: #2c5282;
    color: rgba(44, 82, 130, var(--placeholder-opacity));
  }

  .lg\:focus\:tw-placeholder-blue-900:focus::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #2a4365;
    color: rgba(42, 67, 101, var(--placeholder-opacity));
  }

  .lg\:focus\:tw-placeholder-blue-900:focus::placeholder {
    --placeholder-opacity: 1;
    color: #2a4365;
    color: rgba(42, 67, 101, var(--placeholder-opacity));
  }

  .lg\:focus\:tw-placeholder-indigo-100:focus::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #ebf4ff;
    color: rgba(235, 244, 255, var(--placeholder-opacity));
  }

  .lg\:focus\:tw-placeholder-indigo-100:focus::placeholder {
    --placeholder-opacity: 1;
    color: #ebf4ff;
    color: rgba(235, 244, 255, var(--placeholder-opacity));
  }

  .lg\:focus\:tw-placeholder-indigo-200:focus::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #c3dafe;
    color: rgba(195, 218, 254, var(--placeholder-opacity));
  }

  .lg\:focus\:tw-placeholder-indigo-200:focus::placeholder {
    --placeholder-opacity: 1;
    color: #c3dafe;
    color: rgba(195, 218, 254, var(--placeholder-opacity));
  }

  .lg\:focus\:tw-placeholder-indigo-300:focus::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #a3bffa;
    color: rgba(163, 191, 250, var(--placeholder-opacity));
  }

  .lg\:focus\:tw-placeholder-indigo-300:focus::placeholder {
    --placeholder-opacity: 1;
    color: #a3bffa;
    color: rgba(163, 191, 250, var(--placeholder-opacity));
  }

  .lg\:focus\:tw-placeholder-indigo-400:focus::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #7f9cf5;
    color: rgba(127, 156, 245, var(--placeholder-opacity));
  }

  .lg\:focus\:tw-placeholder-indigo-400:focus::placeholder {
    --placeholder-opacity: 1;
    color: #7f9cf5;
    color: rgba(127, 156, 245, var(--placeholder-opacity));
  }

  .lg\:focus\:tw-placeholder-indigo-500:focus::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #667eea;
    color: rgba(102, 126, 234, var(--placeholder-opacity));
  }

  .lg\:focus\:tw-placeholder-indigo-500:focus::placeholder {
    --placeholder-opacity: 1;
    color: #667eea;
    color: rgba(102, 126, 234, var(--placeholder-opacity));
  }

  .lg\:focus\:tw-placeholder-indigo-600:focus::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #5a67d8;
    color: rgba(90, 103, 216, var(--placeholder-opacity));
  }

  .lg\:focus\:tw-placeholder-indigo-600:focus::placeholder {
    --placeholder-opacity: 1;
    color: #5a67d8;
    color: rgba(90, 103, 216, var(--placeholder-opacity));
  }

  .lg\:focus\:tw-placeholder-indigo-700:focus::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #4c51bf;
    color: rgba(76, 81, 191, var(--placeholder-opacity));
  }

  .lg\:focus\:tw-placeholder-indigo-700:focus::placeholder {
    --placeholder-opacity: 1;
    color: #4c51bf;
    color: rgba(76, 81, 191, var(--placeholder-opacity));
  }

  .lg\:focus\:tw-placeholder-indigo-800:focus::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #434190;
    color: rgba(67, 65, 144, var(--placeholder-opacity));
  }

  .lg\:focus\:tw-placeholder-indigo-800:focus::placeholder {
    --placeholder-opacity: 1;
    color: #434190;
    color: rgba(67, 65, 144, var(--placeholder-opacity));
  }

  .lg\:focus\:tw-placeholder-indigo-900:focus::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #3c366b;
    color: rgba(60, 54, 107, var(--placeholder-opacity));
  }

  .lg\:focus\:tw-placeholder-indigo-900:focus::placeholder {
    --placeholder-opacity: 1;
    color: #3c366b;
    color: rgba(60, 54, 107, var(--placeholder-opacity));
  }

  .lg\:focus\:tw-placeholder-purple-100:focus::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #faf5ff;
    color: rgba(250, 245, 255, var(--placeholder-opacity));
  }

  .lg\:focus\:tw-placeholder-purple-100:focus::placeholder {
    --placeholder-opacity: 1;
    color: #faf5ff;
    color: rgba(250, 245, 255, var(--placeholder-opacity));
  }

  .lg\:focus\:tw-placeholder-purple-200:focus::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #e9d8fd;
    color: rgba(233, 216, 253, var(--placeholder-opacity));
  }

  .lg\:focus\:tw-placeholder-purple-200:focus::placeholder {
    --placeholder-opacity: 1;
    color: #e9d8fd;
    color: rgba(233, 216, 253, var(--placeholder-opacity));
  }

  .lg\:focus\:tw-placeholder-purple-300:focus::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #d6bcfa;
    color: rgba(214, 188, 250, var(--placeholder-opacity));
  }

  .lg\:focus\:tw-placeholder-purple-300:focus::placeholder {
    --placeholder-opacity: 1;
    color: #d6bcfa;
    color: rgba(214, 188, 250, var(--placeholder-opacity));
  }

  .lg\:focus\:tw-placeholder-purple-400:focus::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #b794f4;
    color: rgba(183, 148, 244, var(--placeholder-opacity));
  }

  .lg\:focus\:tw-placeholder-purple-400:focus::placeholder {
    --placeholder-opacity: 1;
    color: #b794f4;
    color: rgba(183, 148, 244, var(--placeholder-opacity));
  }

  .lg\:focus\:tw-placeholder-purple-500:focus::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #9f7aea;
    color: rgba(159, 122, 234, var(--placeholder-opacity));
  }

  .lg\:focus\:tw-placeholder-purple-500:focus::placeholder {
    --placeholder-opacity: 1;
    color: #9f7aea;
    color: rgba(159, 122, 234, var(--placeholder-opacity));
  }

  .lg\:focus\:tw-placeholder-purple-600:focus::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #805ad5;
    color: rgba(128, 90, 213, var(--placeholder-opacity));
  }

  .lg\:focus\:tw-placeholder-purple-600:focus::placeholder {
    --placeholder-opacity: 1;
    color: #805ad5;
    color: rgba(128, 90, 213, var(--placeholder-opacity));
  }

  .lg\:focus\:tw-placeholder-purple-700:focus::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #6b46c1;
    color: rgba(107, 70, 193, var(--placeholder-opacity));
  }

  .lg\:focus\:tw-placeholder-purple-700:focus::placeholder {
    --placeholder-opacity: 1;
    color: #6b46c1;
    color: rgba(107, 70, 193, var(--placeholder-opacity));
  }

  .lg\:focus\:tw-placeholder-purple-800:focus::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #553c9a;
    color: rgba(85, 60, 154, var(--placeholder-opacity));
  }

  .lg\:focus\:tw-placeholder-purple-800:focus::placeholder {
    --placeholder-opacity: 1;
    color: #553c9a;
    color: rgba(85, 60, 154, var(--placeholder-opacity));
  }

  .lg\:focus\:tw-placeholder-purple-900:focus::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #44337a;
    color: rgba(68, 51, 122, var(--placeholder-opacity));
  }

  .lg\:focus\:tw-placeholder-purple-900:focus::placeholder {
    --placeholder-opacity: 1;
    color: #44337a;
    color: rgba(68, 51, 122, var(--placeholder-opacity));
  }

  .lg\:focus\:tw-placeholder-pink-100:focus::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #fff5f7;
    color: rgba(255, 245, 247, var(--placeholder-opacity));
  }

  .lg\:focus\:tw-placeholder-pink-100:focus::placeholder {
    --placeholder-opacity: 1;
    color: #fff5f7;
    color: rgba(255, 245, 247, var(--placeholder-opacity));
  }

  .lg\:focus\:tw-placeholder-pink-200:focus::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #fed7e2;
    color: rgba(254, 215, 226, var(--placeholder-opacity));
  }

  .lg\:focus\:tw-placeholder-pink-200:focus::placeholder {
    --placeholder-opacity: 1;
    color: #fed7e2;
    color: rgba(254, 215, 226, var(--placeholder-opacity));
  }

  .lg\:focus\:tw-placeholder-pink-300:focus::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #fbb6ce;
    color: rgba(251, 182, 206, var(--placeholder-opacity));
  }

  .lg\:focus\:tw-placeholder-pink-300:focus::placeholder {
    --placeholder-opacity: 1;
    color: #fbb6ce;
    color: rgba(251, 182, 206, var(--placeholder-opacity));
  }

  .lg\:focus\:tw-placeholder-pink-400:focus::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #f687b3;
    color: rgba(246, 135, 179, var(--placeholder-opacity));
  }

  .lg\:focus\:tw-placeholder-pink-400:focus::placeholder {
    --placeholder-opacity: 1;
    color: #f687b3;
    color: rgba(246, 135, 179, var(--placeholder-opacity));
  }

  .lg\:focus\:tw-placeholder-pink-500:focus::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #ed64a6;
    color: rgba(237, 100, 166, var(--placeholder-opacity));
  }

  .lg\:focus\:tw-placeholder-pink-500:focus::placeholder {
    --placeholder-opacity: 1;
    color: #ed64a6;
    color: rgba(237, 100, 166, var(--placeholder-opacity));
  }

  .lg\:focus\:tw-placeholder-pink-600:focus::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #d53f8c;
    color: rgba(213, 63, 140, var(--placeholder-opacity));
  }

  .lg\:focus\:tw-placeholder-pink-600:focus::placeholder {
    --placeholder-opacity: 1;
    color: #d53f8c;
    color: rgba(213, 63, 140, var(--placeholder-opacity));
  }

  .lg\:focus\:tw-placeholder-pink-700:focus::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #b83280;
    color: rgba(184, 50, 128, var(--placeholder-opacity));
  }

  .lg\:focus\:tw-placeholder-pink-700:focus::placeholder {
    --placeholder-opacity: 1;
    color: #b83280;
    color: rgba(184, 50, 128, var(--placeholder-opacity));
  }

  .lg\:focus\:tw-placeholder-pink-800:focus::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #97266d;
    color: rgba(151, 38, 109, var(--placeholder-opacity));
  }

  .lg\:focus\:tw-placeholder-pink-800:focus::placeholder {
    --placeholder-opacity: 1;
    color: #97266d;
    color: rgba(151, 38, 109, var(--placeholder-opacity));
  }

  .lg\:focus\:tw-placeholder-pink-900:focus::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #702459;
    color: rgba(112, 36, 89, var(--placeholder-opacity));
  }

  .lg\:focus\:tw-placeholder-pink-900:focus::placeholder {
    --placeholder-opacity: 1;
    color: #702459;
    color: rgba(112, 36, 89, var(--placeholder-opacity));
  }

  .lg\:tw-placeholder-opacity-0::-moz-placeholder {
    --placeholder-opacity: 0;
  }

  .lg\:tw-placeholder-opacity-0::placeholder {
    --placeholder-opacity: 0;
  }

  .lg\:tw-placeholder-opacity-25::-moz-placeholder {
    --placeholder-opacity: 0.25;
  }

  .lg\:tw-placeholder-opacity-25::placeholder {
    --placeholder-opacity: 0.25;
  }

  .lg\:tw-placeholder-opacity-50::-moz-placeholder {
    --placeholder-opacity: 0.5;
  }

  .lg\:tw-placeholder-opacity-50::placeholder {
    --placeholder-opacity: 0.5;
  }

  .lg\:tw-placeholder-opacity-75::-moz-placeholder {
    --placeholder-opacity: 0.75;
  }

  .lg\:tw-placeholder-opacity-75::placeholder {
    --placeholder-opacity: 0.75;
  }

  .lg\:tw-placeholder-opacity-100::-moz-placeholder {
    --placeholder-opacity: 1;
  }

  .lg\:tw-placeholder-opacity-100::placeholder {
    --placeholder-opacity: 1;
  }

  .lg\:focus\:tw-placeholder-opacity-0:focus::-moz-placeholder {
    --placeholder-opacity: 0;
  }

  .lg\:focus\:tw-placeholder-opacity-0:focus::placeholder {
    --placeholder-opacity: 0;
  }

  .lg\:focus\:tw-placeholder-opacity-25:focus::-moz-placeholder {
    --placeholder-opacity: 0.25;
  }

  .lg\:focus\:tw-placeholder-opacity-25:focus::placeholder {
    --placeholder-opacity: 0.25;
  }

  .lg\:focus\:tw-placeholder-opacity-50:focus::-moz-placeholder {
    --placeholder-opacity: 0.5;
  }

  .lg\:focus\:tw-placeholder-opacity-50:focus::placeholder {
    --placeholder-opacity: 0.5;
  }

  .lg\:focus\:tw-placeholder-opacity-75:focus::-moz-placeholder {
    --placeholder-opacity: 0.75;
  }

  .lg\:focus\:tw-placeholder-opacity-75:focus::placeholder {
    --placeholder-opacity: 0.75;
  }

  .lg\:focus\:tw-placeholder-opacity-100:focus::-moz-placeholder {
    --placeholder-opacity: 1;
  }

  .lg\:focus\:tw-placeholder-opacity-100:focus::placeholder {
    --placeholder-opacity: 1;
  }

  .lg\:tw-pointer-events-none {
    pointer-events: none;
  }

  .lg\:tw-pointer-events-auto {
    pointer-events: auto;
  }

  .lg\:tw-static {
    position: static;
  }

  .lg\:tw-fixed {
    position: fixed;
  }

  .lg\:tw-absolute {
    position: absolute;
  }

  .lg\:tw-relative {
    position: relative;
  }

  .lg\:tw-sticky {
    position: sticky;
  }

  .lg\:tw-inset-0 {
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }

  .lg\:tw-inset-auto {
    top: auto;
    right: auto;
    bottom: auto;
    left: auto;
  }

  .lg\:tw-inset-y-0 {
    top: 0;
    bottom: 0;
  }

  .lg\:tw-inset-x-0 {
    right: 0;
    left: 0;
  }

  .lg\:tw-inset-y-auto {
    top: auto;
    bottom: auto;
  }

  .lg\:tw-inset-x-auto {
    right: auto;
    left: auto;
  }

  .lg\:tw-top-0 {
    top: 0;
  }

  .lg\:tw-right-0 {
    right: 0;
  }

  .lg\:tw-bottom-0 {
    bottom: 0;
  }

  .lg\:tw-left-0 {
    left: 0;
  }

  .lg\:tw-top-auto {
    top: auto;
  }

  .lg\:tw-right-auto {
    right: auto;
  }

  .lg\:tw-bottom-auto {
    bottom: auto;
  }

  .lg\:tw-left-auto {
    left: auto;
  }

  .lg\:tw-resize-none {
    resize: none;
  }

  .lg\:tw-resize-y {
    resize: vertical;
  }

  .lg\:tw-resize-x {
    resize: horizontal;
  }

  .lg\:tw-resize {
    resize: both;
  }

  .lg\:tw-shadow-xs {
    box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.05);
  }

  .lg\:tw-shadow-sm {
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
  }

  .lg\:tw-shadow {
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
  }

  .lg\:tw-shadow-md {
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
  }

  .lg\:tw-shadow-lg {
    box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
  }

  .lg\:tw-shadow-xl {
    box-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04);
  }

  .lg\:tw-shadow-2xl {
    box-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25);
  }

  .lg\:tw-shadow-inner {
    box-shadow: inset 0 2px 4px 0 rgba(0, 0, 0, 0.06);
  }

  .lg\:tw-shadow-outline {
    box-shadow: 0 0 0 3px rgba(66, 153, 225, 0.5);
  }

  .lg\:tw-shadow-none {
    box-shadow: none;
  }

  .lg\:hover\:tw-shadow-xs:hover {
    box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.05);
  }

  .lg\:hover\:tw-shadow-sm:hover {
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
  }

  .lg\:hover\:tw-shadow:hover {
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
  }

  .lg\:hover\:tw-shadow-md:hover {
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
  }

  .lg\:hover\:tw-shadow-lg:hover {
    box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
  }

  .lg\:hover\:tw-shadow-xl:hover {
    box-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04);
  }

  .lg\:hover\:tw-shadow-2xl:hover {
    box-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25);
  }

  .lg\:hover\:tw-shadow-inner:hover {
    box-shadow: inset 0 2px 4px 0 rgba(0, 0, 0, 0.06);
  }

  .lg\:hover\:tw-shadow-outline:hover {
    box-shadow: 0 0 0 3px rgba(66, 153, 225, 0.5);
  }

  .lg\:hover\:tw-shadow-none:hover {
    box-shadow: none;
  }

  .lg\:focus\:tw-shadow-xs:focus {
    box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.05);
  }

  .lg\:focus\:tw-shadow-sm:focus {
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
  }

  .lg\:focus\:tw-shadow:focus {
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
  }

  .lg\:focus\:tw-shadow-md:focus {
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
  }

  .lg\:focus\:tw-shadow-lg:focus {
    box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
  }

  .lg\:focus\:tw-shadow-xl:focus {
    box-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04);
  }

  .lg\:focus\:tw-shadow-2xl:focus {
    box-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25);
  }

  .lg\:focus\:tw-shadow-inner:focus {
    box-shadow: inset 0 2px 4px 0 rgba(0, 0, 0, 0.06);
  }

  .lg\:focus\:tw-shadow-outline:focus {
    box-shadow: 0 0 0 3px rgba(66, 153, 225, 0.5);
  }

  .lg\:focus\:tw-shadow-none:focus {
    box-shadow: none;
  }

  .lg\:tw-fill-current {
    fill: currentColor;
  }

  .lg\:tw-stroke-current {
    stroke: currentColor;
  }

  .lg\:tw-stroke-0 {
    stroke-width: 0;
  }

  .lg\:tw-stroke-1 {
    stroke-width: 1;
  }

  .lg\:tw-stroke-2 {
    stroke-width: 2;
  }

  .lg\:tw-table-auto {
    table-layout: auto;
  }

  .lg\:tw-table-fixed {
    table-layout: fixed;
  }

  .lg\:tw-text-left {
    text-align: left;
  }

  .lg\:tw-text-center {
    text-align: center;
  }

  .lg\:tw-text-right {
    text-align: right;
  }

  .lg\:tw-text-justify {
    text-align: justify;
  }

  .lg\:tw-text-transparent {
    color: transparent;
  }

  .lg\:tw-text-current {
    color: currentColor;
  }

  .lg\:tw-text-black {
    --text-opacity: 1;
    color: #000;
    color: rgba(0, 0, 0, var(--text-opacity));
  }

  .lg\:tw-text-white {
    --text-opacity: 1;
    color: #fff;
    color: rgba(255, 255, 255, var(--text-opacity));
  }

  .lg\:tw-text-gray-100 {
    --text-opacity: 1;
    color: #f7fafc;
    color: rgba(247, 250, 252, var(--text-opacity));
  }

  .lg\:tw-text-gray-200 {
    --text-opacity: 1;
    color: #edf2f7;
    color: rgba(237, 242, 247, var(--text-opacity));
  }

  .lg\:tw-text-gray-300 {
    --text-opacity: 1;
    color: #e2e8f0;
    color: rgba(226, 232, 240, var(--text-opacity));
  }

  .lg\:tw-text-gray-400 {
    --text-opacity: 1;
    color: #cbd5e0;
    color: rgba(203, 213, 224, var(--text-opacity));
  }

  .lg\:tw-text-gray-500 {
    --text-opacity: 1;
    color: #a0aec0;
    color: rgba(160, 174, 192, var(--text-opacity));
  }

  .lg\:tw-text-gray-600 {
    --text-opacity: 1;
    color: #718096;
    color: rgba(113, 128, 150, var(--text-opacity));
  }

  .lg\:tw-text-gray-700 {
    --text-opacity: 1;
    color: #4a5568;
    color: rgba(74, 85, 104, var(--text-opacity));
  }

  .lg\:tw-text-gray-800 {
    --text-opacity: 1;
    color: #2d3748;
    color: rgba(45, 55, 72, var(--text-opacity));
  }

  .lg\:tw-text-gray-900 {
    --text-opacity: 1;
    color: #1a202c;
    color: rgba(26, 32, 44, var(--text-opacity));
  }

  .lg\:tw-text-red-100 {
    --text-opacity: 1;
    color: #fff5f5;
    color: rgba(255, 245, 245, var(--text-opacity));
  }

  .lg\:tw-text-red-200 {
    --text-opacity: 1;
    color: #fed7d7;
    color: rgba(254, 215, 215, var(--text-opacity));
  }

  .lg\:tw-text-red-300 {
    --text-opacity: 1;
    color: #feb2b2;
    color: rgba(254, 178, 178, var(--text-opacity));
  }

  .lg\:tw-text-red-400 {
    --text-opacity: 1;
    color: #fc8181;
    color: rgba(252, 129, 129, var(--text-opacity));
  }

  .lg\:tw-text-red-500 {
    --text-opacity: 1;
    color: #f56565;
    color: rgba(245, 101, 101, var(--text-opacity));
  }

  .lg\:tw-text-red-600 {
    --text-opacity: 1;
    color: #e53e3e;
    color: rgba(229, 62, 62, var(--text-opacity));
  }

  .lg\:tw-text-red-700 {
    --text-opacity: 1;
    color: #c53030;
    color: rgba(197, 48, 48, var(--text-opacity));
  }

  .lg\:tw-text-red-800 {
    --text-opacity: 1;
    color: #9b2c2c;
    color: rgba(155, 44, 44, var(--text-opacity));
  }

  .lg\:tw-text-red-900 {
    --text-opacity: 1;
    color: #742a2a;
    color: rgba(116, 42, 42, var(--text-opacity));
  }

  .lg\:tw-text-orange-100 {
    --text-opacity: 1;
    color: #fffaf0;
    color: rgba(255, 250, 240, var(--text-opacity));
  }

  .lg\:tw-text-orange-200 {
    --text-opacity: 1;
    color: #feebc8;
    color: rgba(254, 235, 200, var(--text-opacity));
  }

  .lg\:tw-text-orange-300 {
    --text-opacity: 1;
    color: #fbd38d;
    color: rgba(251, 211, 141, var(--text-opacity));
  }

  .lg\:tw-text-orange-400 {
    --text-opacity: 1;
    color: #f6ad55;
    color: rgba(246, 173, 85, var(--text-opacity));
  }

  .lg\:tw-text-orange-500 {
    --text-opacity: 1;
    color: #ed8936;
    color: rgba(237, 137, 54, var(--text-opacity));
  }

  .lg\:tw-text-orange-600 {
    --text-opacity: 1;
    color: #dd6b20;
    color: rgba(221, 107, 32, var(--text-opacity));
  }

  .lg\:tw-text-orange-700 {
    --text-opacity: 1;
    color: #c05621;
    color: rgba(192, 86, 33, var(--text-opacity));
  }

  .lg\:tw-text-orange-800 {
    --text-opacity: 1;
    color: #9c4221;
    color: rgba(156, 66, 33, var(--text-opacity));
  }

  .lg\:tw-text-orange-900 {
    --text-opacity: 1;
    color: #7b341e;
    color: rgba(123, 52, 30, var(--text-opacity));
  }

  .lg\:tw-text-yellow-100 {
    --text-opacity: 1;
    color: #fffff0;
    color: rgba(255, 255, 240, var(--text-opacity));
  }

  .lg\:tw-text-yellow-200 {
    --text-opacity: 1;
    color: #fefcbf;
    color: rgba(254, 252, 191, var(--text-opacity));
  }

  .lg\:tw-text-yellow-300 {
    --text-opacity: 1;
    color: #faf089;
    color: rgba(250, 240, 137, var(--text-opacity));
  }

  .lg\:tw-text-yellow-400 {
    --text-opacity: 1;
    color: #f6e05e;
    color: rgba(246, 224, 94, var(--text-opacity));
  }

  .lg\:tw-text-yellow-500 {
    --text-opacity: 1;
    color: #ecc94b;
    color: rgba(236, 201, 75, var(--text-opacity));
  }

  .lg\:tw-text-yellow-600 {
    --text-opacity: 1;
    color: #d69e2e;
    color: rgba(214, 158, 46, var(--text-opacity));
  }

  .lg\:tw-text-yellow-700 {
    --text-opacity: 1;
    color: #b7791f;
    color: rgba(183, 121, 31, var(--text-opacity));
  }

  .lg\:tw-text-yellow-800 {
    --text-opacity: 1;
    color: #975a16;
    color: rgba(151, 90, 22, var(--text-opacity));
  }

  .lg\:tw-text-yellow-900 {
    --text-opacity: 1;
    color: #744210;
    color: rgba(116, 66, 16, var(--text-opacity));
  }

  .lg\:tw-text-green-100 {
    --text-opacity: 1;
    color: #f0fff4;
    color: rgba(240, 255, 244, var(--text-opacity));
  }

  .lg\:tw-text-green-200 {
    --text-opacity: 1;
    color: #c6f6d5;
    color: rgba(198, 246, 213, var(--text-opacity));
  }

  .lg\:tw-text-green-300 {
    --text-opacity: 1;
    color: #9ae6b4;
    color: rgba(154, 230, 180, var(--text-opacity));
  }

  .lg\:tw-text-green-400 {
    --text-opacity: 1;
    color: #68d391;
    color: rgba(104, 211, 145, var(--text-opacity));
  }

  .lg\:tw-text-green-500 {
    --text-opacity: 1;
    color: #48bb78;
    color: rgba(72, 187, 120, var(--text-opacity));
  }

  .lg\:tw-text-green-600 {
    --text-opacity: 1;
    color: #38a169;
    color: rgba(56, 161, 105, var(--text-opacity));
  }

  .lg\:tw-text-green-700 {
    --text-opacity: 1;
    color: #2f855a;
    color: rgba(47, 133, 90, var(--text-opacity));
  }

  .lg\:tw-text-green-800 {
    --text-opacity: 1;
    color: #276749;
    color: rgba(39, 103, 73, var(--text-opacity));
  }

  .lg\:tw-text-green-900 {
    --text-opacity: 1;
    color: #22543d;
    color: rgba(34, 84, 61, var(--text-opacity));
  }

  .lg\:tw-text-teal-100 {
    --text-opacity: 1;
    color: #e6fffa;
    color: rgba(230, 255, 250, var(--text-opacity));
  }

  .lg\:tw-text-teal-200 {
    --text-opacity: 1;
    color: #b2f5ea;
    color: rgba(178, 245, 234, var(--text-opacity));
  }

  .lg\:tw-text-teal-300 {
    --text-opacity: 1;
    color: #81e6d9;
    color: rgba(129, 230, 217, var(--text-opacity));
  }

  .lg\:tw-text-teal-400 {
    --text-opacity: 1;
    color: #4fd1c5;
    color: rgba(79, 209, 197, var(--text-opacity));
  }

  .lg\:tw-text-teal-500 {
    --text-opacity: 1;
    color: #38b2ac;
    color: rgba(56, 178, 172, var(--text-opacity));
  }

  .lg\:tw-text-teal-600 {
    --text-opacity: 1;
    color: #319795;
    color: rgba(49, 151, 149, var(--text-opacity));
  }

  .lg\:tw-text-teal-700 {
    --text-opacity: 1;
    color: #2c7a7b;
    color: rgba(44, 122, 123, var(--text-opacity));
  }

  .lg\:tw-text-teal-800 {
    --text-opacity: 1;
    color: #285e61;
    color: rgba(40, 94, 97, var(--text-opacity));
  }

  .lg\:tw-text-teal-900 {
    --text-opacity: 1;
    color: #234e52;
    color: rgba(35, 78, 82, var(--text-opacity));
  }

  .lg\:tw-text-blue-100 {
    --text-opacity: 1;
    color: #ebf8ff;
    color: rgba(235, 248, 255, var(--text-opacity));
  }

  .lg\:tw-text-blue-200 {
    --text-opacity: 1;
    color: #bee3f8;
    color: rgba(190, 227, 248, var(--text-opacity));
  }

  .lg\:tw-text-blue-300 {
    --text-opacity: 1;
    color: #90cdf4;
    color: rgba(144, 205, 244, var(--text-opacity));
  }

  .lg\:tw-text-blue-400 {
    --text-opacity: 1;
    color: #63b3ed;
    color: rgba(99, 179, 237, var(--text-opacity));
  }

  .lg\:tw-text-blue-500 {
    --text-opacity: 1;
    color: #4299e1;
    color: rgba(66, 153, 225, var(--text-opacity));
  }

  .lg\:tw-text-blue-600 {
    --text-opacity: 1;
    color: #3182ce;
    color: rgba(49, 130, 206, var(--text-opacity));
  }

  .lg\:tw-text-blue-700 {
    --text-opacity: 1;
    color: #2b6cb0;
    color: rgba(43, 108, 176, var(--text-opacity));
  }

  .lg\:tw-text-blue-800 {
    --text-opacity: 1;
    color: #2c5282;
    color: rgba(44, 82, 130, var(--text-opacity));
  }

  .lg\:tw-text-blue-900 {
    --text-opacity: 1;
    color: #2a4365;
    color: rgba(42, 67, 101, var(--text-opacity));
  }

  .lg\:tw-text-indigo-100 {
    --text-opacity: 1;
    color: #ebf4ff;
    color: rgba(235, 244, 255, var(--text-opacity));
  }

  .lg\:tw-text-indigo-200 {
    --text-opacity: 1;
    color: #c3dafe;
    color: rgba(195, 218, 254, var(--text-opacity));
  }

  .lg\:tw-text-indigo-300 {
    --text-opacity: 1;
    color: #a3bffa;
    color: rgba(163, 191, 250, var(--text-opacity));
  }

  .lg\:tw-text-indigo-400 {
    --text-opacity: 1;
    color: #7f9cf5;
    color: rgba(127, 156, 245, var(--text-opacity));
  }

  .lg\:tw-text-indigo-500 {
    --text-opacity: 1;
    color: #667eea;
    color: rgba(102, 126, 234, var(--text-opacity));
  }

  .lg\:tw-text-indigo-600 {
    --text-opacity: 1;
    color: #5a67d8;
    color: rgba(90, 103, 216, var(--text-opacity));
  }

  .lg\:tw-text-indigo-700 {
    --text-opacity: 1;
    color: #4c51bf;
    color: rgba(76, 81, 191, var(--text-opacity));
  }

  .lg\:tw-text-indigo-800 {
    --text-opacity: 1;
    color: #434190;
    color: rgba(67, 65, 144, var(--text-opacity));
  }

  .lg\:tw-text-indigo-900 {
    --text-opacity: 1;
    color: #3c366b;
    color: rgba(60, 54, 107, var(--text-opacity));
  }

  .lg\:tw-text-purple-100 {
    --text-opacity: 1;
    color: #faf5ff;
    color: rgba(250, 245, 255, var(--text-opacity));
  }

  .lg\:tw-text-purple-200 {
    --text-opacity: 1;
    color: #e9d8fd;
    color: rgba(233, 216, 253, var(--text-opacity));
  }

  .lg\:tw-text-purple-300 {
    --text-opacity: 1;
    color: #d6bcfa;
    color: rgba(214, 188, 250, var(--text-opacity));
  }

  .lg\:tw-text-purple-400 {
    --text-opacity: 1;
    color: #b794f4;
    color: rgba(183, 148, 244, var(--text-opacity));
  }

  .lg\:tw-text-purple-500 {
    --text-opacity: 1;
    color: #9f7aea;
    color: rgba(159, 122, 234, var(--text-opacity));
  }

  .lg\:tw-text-purple-600 {
    --text-opacity: 1;
    color: #805ad5;
    color: rgba(128, 90, 213, var(--text-opacity));
  }

  .lg\:tw-text-purple-700 {
    --text-opacity: 1;
    color: #6b46c1;
    color: rgba(107, 70, 193, var(--text-opacity));
  }

  .lg\:tw-text-purple-800 {
    --text-opacity: 1;
    color: #553c9a;
    color: rgba(85, 60, 154, var(--text-opacity));
  }

  .lg\:tw-text-purple-900 {
    --text-opacity: 1;
    color: #44337a;
    color: rgba(68, 51, 122, var(--text-opacity));
  }

  .lg\:tw-text-pink-100 {
    --text-opacity: 1;
    color: #fff5f7;
    color: rgba(255, 245, 247, var(--text-opacity));
  }

  .lg\:tw-text-pink-200 {
    --text-opacity: 1;
    color: #fed7e2;
    color: rgba(254, 215, 226, var(--text-opacity));
  }

  .lg\:tw-text-pink-300 {
    --text-opacity: 1;
    color: #fbb6ce;
    color: rgba(251, 182, 206, var(--text-opacity));
  }

  .lg\:tw-text-pink-400 {
    --text-opacity: 1;
    color: #f687b3;
    color: rgba(246, 135, 179, var(--text-opacity));
  }

  .lg\:tw-text-pink-500 {
    --text-opacity: 1;
    color: #ed64a6;
    color: rgba(237, 100, 166, var(--text-opacity));
  }

  .lg\:tw-text-pink-600 {
    --text-opacity: 1;
    color: #d53f8c;
    color: rgba(213, 63, 140, var(--text-opacity));
  }

  .lg\:tw-text-pink-700 {
    --text-opacity: 1;
    color: #b83280;
    color: rgba(184, 50, 128, var(--text-opacity));
  }

  .lg\:tw-text-pink-800 {
    --text-opacity: 1;
    color: #97266d;
    color: rgba(151, 38, 109, var(--text-opacity));
  }

  .lg\:tw-text-pink-900 {
    --text-opacity: 1;
    color: #702459;
    color: rgba(112, 36, 89, var(--text-opacity));
  }

  .lg\:hover\:tw-text-transparent:hover {
    color: transparent;
  }

  .lg\:hover\:tw-text-current:hover {
    color: currentColor;
  }

  .lg\:hover\:tw-text-black:hover {
    --text-opacity: 1;
    color: #000;
    color: rgba(0, 0, 0, var(--text-opacity));
  }

  .lg\:hover\:tw-text-white:hover {
    --text-opacity: 1;
    color: #fff;
    color: rgba(255, 255, 255, var(--text-opacity));
  }

  .lg\:hover\:tw-text-gray-100:hover {
    --text-opacity: 1;
    color: #f7fafc;
    color: rgba(247, 250, 252, var(--text-opacity));
  }

  .lg\:hover\:tw-text-gray-200:hover {
    --text-opacity: 1;
    color: #edf2f7;
    color: rgba(237, 242, 247, var(--text-opacity));
  }

  .lg\:hover\:tw-text-gray-300:hover {
    --text-opacity: 1;
    color: #e2e8f0;
    color: rgba(226, 232, 240, var(--text-opacity));
  }

  .lg\:hover\:tw-text-gray-400:hover {
    --text-opacity: 1;
    color: #cbd5e0;
    color: rgba(203, 213, 224, var(--text-opacity));
  }

  .lg\:hover\:tw-text-gray-500:hover {
    --text-opacity: 1;
    color: #a0aec0;
    color: rgba(160, 174, 192, var(--text-opacity));
  }

  .lg\:hover\:tw-text-gray-600:hover {
    --text-opacity: 1;
    color: #718096;
    color: rgba(113, 128, 150, var(--text-opacity));
  }

  .lg\:hover\:tw-text-gray-700:hover {
    --text-opacity: 1;
    color: #4a5568;
    color: rgba(74, 85, 104, var(--text-opacity));
  }

  .lg\:hover\:tw-text-gray-800:hover {
    --text-opacity: 1;
    color: #2d3748;
    color: rgba(45, 55, 72, var(--text-opacity));
  }

  .lg\:hover\:tw-text-gray-900:hover {
    --text-opacity: 1;
    color: #1a202c;
    color: rgba(26, 32, 44, var(--text-opacity));
  }

  .lg\:hover\:tw-text-red-100:hover {
    --text-opacity: 1;
    color: #fff5f5;
    color: rgba(255, 245, 245, var(--text-opacity));
  }

  .lg\:hover\:tw-text-red-200:hover {
    --text-opacity: 1;
    color: #fed7d7;
    color: rgba(254, 215, 215, var(--text-opacity));
  }

  .lg\:hover\:tw-text-red-300:hover {
    --text-opacity: 1;
    color: #feb2b2;
    color: rgba(254, 178, 178, var(--text-opacity));
  }

  .lg\:hover\:tw-text-red-400:hover {
    --text-opacity: 1;
    color: #fc8181;
    color: rgba(252, 129, 129, var(--text-opacity));
  }

  .lg\:hover\:tw-text-red-500:hover {
    --text-opacity: 1;
    color: #f56565;
    color: rgba(245, 101, 101, var(--text-opacity));
  }

  .lg\:hover\:tw-text-red-600:hover {
    --text-opacity: 1;
    color: #e53e3e;
    color: rgba(229, 62, 62, var(--text-opacity));
  }

  .lg\:hover\:tw-text-red-700:hover {
    --text-opacity: 1;
    color: #c53030;
    color: rgba(197, 48, 48, var(--text-opacity));
  }

  .lg\:hover\:tw-text-red-800:hover {
    --text-opacity: 1;
    color: #9b2c2c;
    color: rgba(155, 44, 44, var(--text-opacity));
  }

  .lg\:hover\:tw-text-red-900:hover {
    --text-opacity: 1;
    color: #742a2a;
    color: rgba(116, 42, 42, var(--text-opacity));
  }

  .lg\:hover\:tw-text-orange-100:hover {
    --text-opacity: 1;
    color: #fffaf0;
    color: rgba(255, 250, 240, var(--text-opacity));
  }

  .lg\:hover\:tw-text-orange-200:hover {
    --text-opacity: 1;
    color: #feebc8;
    color: rgba(254, 235, 200, var(--text-opacity));
  }

  .lg\:hover\:tw-text-orange-300:hover {
    --text-opacity: 1;
    color: #fbd38d;
    color: rgba(251, 211, 141, var(--text-opacity));
  }

  .lg\:hover\:tw-text-orange-400:hover {
    --text-opacity: 1;
    color: #f6ad55;
    color: rgba(246, 173, 85, var(--text-opacity));
  }

  .lg\:hover\:tw-text-orange-500:hover {
    --text-opacity: 1;
    color: #ed8936;
    color: rgba(237, 137, 54, var(--text-opacity));
  }

  .lg\:hover\:tw-text-orange-600:hover {
    --text-opacity: 1;
    color: #dd6b20;
    color: rgba(221, 107, 32, var(--text-opacity));
  }

  .lg\:hover\:tw-text-orange-700:hover {
    --text-opacity: 1;
    color: #c05621;
    color: rgba(192, 86, 33, var(--text-opacity));
  }

  .lg\:hover\:tw-text-orange-800:hover {
    --text-opacity: 1;
    color: #9c4221;
    color: rgba(156, 66, 33, var(--text-opacity));
  }

  .lg\:hover\:tw-text-orange-900:hover {
    --text-opacity: 1;
    color: #7b341e;
    color: rgba(123, 52, 30, var(--text-opacity));
  }

  .lg\:hover\:tw-text-yellow-100:hover {
    --text-opacity: 1;
    color: #fffff0;
    color: rgba(255, 255, 240, var(--text-opacity));
  }

  .lg\:hover\:tw-text-yellow-200:hover {
    --text-opacity: 1;
    color: #fefcbf;
    color: rgba(254, 252, 191, var(--text-opacity));
  }

  .lg\:hover\:tw-text-yellow-300:hover {
    --text-opacity: 1;
    color: #faf089;
    color: rgba(250, 240, 137, var(--text-opacity));
  }

  .lg\:hover\:tw-text-yellow-400:hover {
    --text-opacity: 1;
    color: #f6e05e;
    color: rgba(246, 224, 94, var(--text-opacity));
  }

  .lg\:hover\:tw-text-yellow-500:hover {
    --text-opacity: 1;
    color: #ecc94b;
    color: rgba(236, 201, 75, var(--text-opacity));
  }

  .lg\:hover\:tw-text-yellow-600:hover {
    --text-opacity: 1;
    color: #d69e2e;
    color: rgba(214, 158, 46, var(--text-opacity));
  }

  .lg\:hover\:tw-text-yellow-700:hover {
    --text-opacity: 1;
    color: #b7791f;
    color: rgba(183, 121, 31, var(--text-opacity));
  }

  .lg\:hover\:tw-text-yellow-800:hover {
    --text-opacity: 1;
    color: #975a16;
    color: rgba(151, 90, 22, var(--text-opacity));
  }

  .lg\:hover\:tw-text-yellow-900:hover {
    --text-opacity: 1;
    color: #744210;
    color: rgba(116, 66, 16, var(--text-opacity));
  }

  .lg\:hover\:tw-text-green-100:hover {
    --text-opacity: 1;
    color: #f0fff4;
    color: rgba(240, 255, 244, var(--text-opacity));
  }

  .lg\:hover\:tw-text-green-200:hover {
    --text-opacity: 1;
    color: #c6f6d5;
    color: rgba(198, 246, 213, var(--text-opacity));
  }

  .lg\:hover\:tw-text-green-300:hover {
    --text-opacity: 1;
    color: #9ae6b4;
    color: rgba(154, 230, 180, var(--text-opacity));
  }

  .lg\:hover\:tw-text-green-400:hover {
    --text-opacity: 1;
    color: #68d391;
    color: rgba(104, 211, 145, var(--text-opacity));
  }

  .lg\:hover\:tw-text-green-500:hover {
    --text-opacity: 1;
    color: #48bb78;
    color: rgba(72, 187, 120, var(--text-opacity));
  }

  .lg\:hover\:tw-text-green-600:hover {
    --text-opacity: 1;
    color: #38a169;
    color: rgba(56, 161, 105, var(--text-opacity));
  }

  .lg\:hover\:tw-text-green-700:hover {
    --text-opacity: 1;
    color: #2f855a;
    color: rgba(47, 133, 90, var(--text-opacity));
  }

  .lg\:hover\:tw-text-green-800:hover {
    --text-opacity: 1;
    color: #276749;
    color: rgba(39, 103, 73, var(--text-opacity));
  }

  .lg\:hover\:tw-text-green-900:hover {
    --text-opacity: 1;
    color: #22543d;
    color: rgba(34, 84, 61, var(--text-opacity));
  }

  .lg\:hover\:tw-text-teal-100:hover {
    --text-opacity: 1;
    color: #e6fffa;
    color: rgba(230, 255, 250, var(--text-opacity));
  }

  .lg\:hover\:tw-text-teal-200:hover {
    --text-opacity: 1;
    color: #b2f5ea;
    color: rgba(178, 245, 234, var(--text-opacity));
  }

  .lg\:hover\:tw-text-teal-300:hover {
    --text-opacity: 1;
    color: #81e6d9;
    color: rgba(129, 230, 217, var(--text-opacity));
  }

  .lg\:hover\:tw-text-teal-400:hover {
    --text-opacity: 1;
    color: #4fd1c5;
    color: rgba(79, 209, 197, var(--text-opacity));
  }

  .lg\:hover\:tw-text-teal-500:hover {
    --text-opacity: 1;
    color: #38b2ac;
    color: rgba(56, 178, 172, var(--text-opacity));
  }

  .lg\:hover\:tw-text-teal-600:hover {
    --text-opacity: 1;
    color: #319795;
    color: rgba(49, 151, 149, var(--text-opacity));
  }

  .lg\:hover\:tw-text-teal-700:hover {
    --text-opacity: 1;
    color: #2c7a7b;
    color: rgba(44, 122, 123, var(--text-opacity));
  }

  .lg\:hover\:tw-text-teal-800:hover {
    --text-opacity: 1;
    color: #285e61;
    color: rgba(40, 94, 97, var(--text-opacity));
  }

  .lg\:hover\:tw-text-teal-900:hover {
    --text-opacity: 1;
    color: #234e52;
    color: rgba(35, 78, 82, var(--text-opacity));
  }

  .lg\:hover\:tw-text-blue-100:hover {
    --text-opacity: 1;
    color: #ebf8ff;
    color: rgba(235, 248, 255, var(--text-opacity));
  }

  .lg\:hover\:tw-text-blue-200:hover {
    --text-opacity: 1;
    color: #bee3f8;
    color: rgba(190, 227, 248, var(--text-opacity));
  }

  .lg\:hover\:tw-text-blue-300:hover {
    --text-opacity: 1;
    color: #90cdf4;
    color: rgba(144, 205, 244, var(--text-opacity));
  }

  .lg\:hover\:tw-text-blue-400:hover {
    --text-opacity: 1;
    color: #63b3ed;
    color: rgba(99, 179, 237, var(--text-opacity));
  }

  .lg\:hover\:tw-text-blue-500:hover {
    --text-opacity: 1;
    color: #4299e1;
    color: rgba(66, 153, 225, var(--text-opacity));
  }

  .lg\:hover\:tw-text-blue-600:hover {
    --text-opacity: 1;
    color: #3182ce;
    color: rgba(49, 130, 206, var(--text-opacity));
  }

  .lg\:hover\:tw-text-blue-700:hover {
    --text-opacity: 1;
    color: #2b6cb0;
    color: rgba(43, 108, 176, var(--text-opacity));
  }

  .lg\:hover\:tw-text-blue-800:hover {
    --text-opacity: 1;
    color: #2c5282;
    color: rgba(44, 82, 130, var(--text-opacity));
  }

  .lg\:hover\:tw-text-blue-900:hover {
    --text-opacity: 1;
    color: #2a4365;
    color: rgba(42, 67, 101, var(--text-opacity));
  }

  .lg\:hover\:tw-text-indigo-100:hover {
    --text-opacity: 1;
    color: #ebf4ff;
    color: rgba(235, 244, 255, var(--text-opacity));
  }

  .lg\:hover\:tw-text-indigo-200:hover {
    --text-opacity: 1;
    color: #c3dafe;
    color: rgba(195, 218, 254, var(--text-opacity));
  }

  .lg\:hover\:tw-text-indigo-300:hover {
    --text-opacity: 1;
    color: #a3bffa;
    color: rgba(163, 191, 250, var(--text-opacity));
  }

  .lg\:hover\:tw-text-indigo-400:hover {
    --text-opacity: 1;
    color: #7f9cf5;
    color: rgba(127, 156, 245, var(--text-opacity));
  }

  .lg\:hover\:tw-text-indigo-500:hover {
    --text-opacity: 1;
    color: #667eea;
    color: rgba(102, 126, 234, var(--text-opacity));
  }

  .lg\:hover\:tw-text-indigo-600:hover {
    --text-opacity: 1;
    color: #5a67d8;
    color: rgba(90, 103, 216, var(--text-opacity));
  }

  .lg\:hover\:tw-text-indigo-700:hover {
    --text-opacity: 1;
    color: #4c51bf;
    color: rgba(76, 81, 191, var(--text-opacity));
  }

  .lg\:hover\:tw-text-indigo-800:hover {
    --text-opacity: 1;
    color: #434190;
    color: rgba(67, 65, 144, var(--text-opacity));
  }

  .lg\:hover\:tw-text-indigo-900:hover {
    --text-opacity: 1;
    color: #3c366b;
    color: rgba(60, 54, 107, var(--text-opacity));
  }

  .lg\:hover\:tw-text-purple-100:hover {
    --text-opacity: 1;
    color: #faf5ff;
    color: rgba(250, 245, 255, var(--text-opacity));
  }

  .lg\:hover\:tw-text-purple-200:hover {
    --text-opacity: 1;
    color: #e9d8fd;
    color: rgba(233, 216, 253, var(--text-opacity));
  }

  .lg\:hover\:tw-text-purple-300:hover {
    --text-opacity: 1;
    color: #d6bcfa;
    color: rgba(214, 188, 250, var(--text-opacity));
  }

  .lg\:hover\:tw-text-purple-400:hover {
    --text-opacity: 1;
    color: #b794f4;
    color: rgba(183, 148, 244, var(--text-opacity));
  }

  .lg\:hover\:tw-text-purple-500:hover {
    --text-opacity: 1;
    color: #9f7aea;
    color: rgba(159, 122, 234, var(--text-opacity));
  }

  .lg\:hover\:tw-text-purple-600:hover {
    --text-opacity: 1;
    color: #805ad5;
    color: rgba(128, 90, 213, var(--text-opacity));
  }

  .lg\:hover\:tw-text-purple-700:hover {
    --text-opacity: 1;
    color: #6b46c1;
    color: rgba(107, 70, 193, var(--text-opacity));
  }

  .lg\:hover\:tw-text-purple-800:hover {
    --text-opacity: 1;
    color: #553c9a;
    color: rgba(85, 60, 154, var(--text-opacity));
  }

  .lg\:hover\:tw-text-purple-900:hover {
    --text-opacity: 1;
    color: #44337a;
    color: rgba(68, 51, 122, var(--text-opacity));
  }

  .lg\:hover\:tw-text-pink-100:hover {
    --text-opacity: 1;
    color: #fff5f7;
    color: rgba(255, 245, 247, var(--text-opacity));
  }

  .lg\:hover\:tw-text-pink-200:hover {
    --text-opacity: 1;
    color: #fed7e2;
    color: rgba(254, 215, 226, var(--text-opacity));
  }

  .lg\:hover\:tw-text-pink-300:hover {
    --text-opacity: 1;
    color: #fbb6ce;
    color: rgba(251, 182, 206, var(--text-opacity));
  }

  .lg\:hover\:tw-text-pink-400:hover {
    --text-opacity: 1;
    color: #f687b3;
    color: rgba(246, 135, 179, var(--text-opacity));
  }

  .lg\:hover\:tw-text-pink-500:hover {
    --text-opacity: 1;
    color: #ed64a6;
    color: rgba(237, 100, 166, var(--text-opacity));
  }

  .lg\:hover\:tw-text-pink-600:hover {
    --text-opacity: 1;
    color: #d53f8c;
    color: rgba(213, 63, 140, var(--text-opacity));
  }

  .lg\:hover\:tw-text-pink-700:hover {
    --text-opacity: 1;
    color: #b83280;
    color: rgba(184, 50, 128, var(--text-opacity));
  }

  .lg\:hover\:tw-text-pink-800:hover {
    --text-opacity: 1;
    color: #97266d;
    color: rgba(151, 38, 109, var(--text-opacity));
  }

  .lg\:hover\:tw-text-pink-900:hover {
    --text-opacity: 1;
    color: #702459;
    color: rgba(112, 36, 89, var(--text-opacity));
  }

  .lg\:focus\:tw-text-transparent:focus {
    color: transparent;
  }

  .lg\:focus\:tw-text-current:focus {
    color: currentColor;
  }

  .lg\:focus\:tw-text-black:focus {
    --text-opacity: 1;
    color: #000;
    color: rgba(0, 0, 0, var(--text-opacity));
  }

  .lg\:focus\:tw-text-white:focus {
    --text-opacity: 1;
    color: #fff;
    color: rgba(255, 255, 255, var(--text-opacity));
  }

  .lg\:focus\:tw-text-gray-100:focus {
    --text-opacity: 1;
    color: #f7fafc;
    color: rgba(247, 250, 252, var(--text-opacity));
  }

  .lg\:focus\:tw-text-gray-200:focus {
    --text-opacity: 1;
    color: #edf2f7;
    color: rgba(237, 242, 247, var(--text-opacity));
  }

  .lg\:focus\:tw-text-gray-300:focus {
    --text-opacity: 1;
    color: #e2e8f0;
    color: rgba(226, 232, 240, var(--text-opacity));
  }

  .lg\:focus\:tw-text-gray-400:focus {
    --text-opacity: 1;
    color: #cbd5e0;
    color: rgba(203, 213, 224, var(--text-opacity));
  }

  .lg\:focus\:tw-text-gray-500:focus {
    --text-opacity: 1;
    color: #a0aec0;
    color: rgba(160, 174, 192, var(--text-opacity));
  }

  .lg\:focus\:tw-text-gray-600:focus {
    --text-opacity: 1;
    color: #718096;
    color: rgba(113, 128, 150, var(--text-opacity));
  }

  .lg\:focus\:tw-text-gray-700:focus {
    --text-opacity: 1;
    color: #4a5568;
    color: rgba(74, 85, 104, var(--text-opacity));
  }

  .lg\:focus\:tw-text-gray-800:focus {
    --text-opacity: 1;
    color: #2d3748;
    color: rgba(45, 55, 72, var(--text-opacity));
  }

  .lg\:focus\:tw-text-gray-900:focus {
    --text-opacity: 1;
    color: #1a202c;
    color: rgba(26, 32, 44, var(--text-opacity));
  }

  .lg\:focus\:tw-text-red-100:focus {
    --text-opacity: 1;
    color: #fff5f5;
    color: rgba(255, 245, 245, var(--text-opacity));
  }

  .lg\:focus\:tw-text-red-200:focus {
    --text-opacity: 1;
    color: #fed7d7;
    color: rgba(254, 215, 215, var(--text-opacity));
  }

  .lg\:focus\:tw-text-red-300:focus {
    --text-opacity: 1;
    color: #feb2b2;
    color: rgba(254, 178, 178, var(--text-opacity));
  }

  .lg\:focus\:tw-text-red-400:focus {
    --text-opacity: 1;
    color: #fc8181;
    color: rgba(252, 129, 129, var(--text-opacity));
  }

  .lg\:focus\:tw-text-red-500:focus {
    --text-opacity: 1;
    color: #f56565;
    color: rgba(245, 101, 101, var(--text-opacity));
  }

  .lg\:focus\:tw-text-red-600:focus {
    --text-opacity: 1;
    color: #e53e3e;
    color: rgba(229, 62, 62, var(--text-opacity));
  }

  .lg\:focus\:tw-text-red-700:focus {
    --text-opacity: 1;
    color: #c53030;
    color: rgba(197, 48, 48, var(--text-opacity));
  }

  .lg\:focus\:tw-text-red-800:focus {
    --text-opacity: 1;
    color: #9b2c2c;
    color: rgba(155, 44, 44, var(--text-opacity));
  }

  .lg\:focus\:tw-text-red-900:focus {
    --text-opacity: 1;
    color: #742a2a;
    color: rgba(116, 42, 42, var(--text-opacity));
  }

  .lg\:focus\:tw-text-orange-100:focus {
    --text-opacity: 1;
    color: #fffaf0;
    color: rgba(255, 250, 240, var(--text-opacity));
  }

  .lg\:focus\:tw-text-orange-200:focus {
    --text-opacity: 1;
    color: #feebc8;
    color: rgba(254, 235, 200, var(--text-opacity));
  }

  .lg\:focus\:tw-text-orange-300:focus {
    --text-opacity: 1;
    color: #fbd38d;
    color: rgba(251, 211, 141, var(--text-opacity));
  }

  .lg\:focus\:tw-text-orange-400:focus {
    --text-opacity: 1;
    color: #f6ad55;
    color: rgba(246, 173, 85, var(--text-opacity));
  }

  .lg\:focus\:tw-text-orange-500:focus {
    --text-opacity: 1;
    color: #ed8936;
    color: rgba(237, 137, 54, var(--text-opacity));
  }

  .lg\:focus\:tw-text-orange-600:focus {
    --text-opacity: 1;
    color: #dd6b20;
    color: rgba(221, 107, 32, var(--text-opacity));
  }

  .lg\:focus\:tw-text-orange-700:focus {
    --text-opacity: 1;
    color: #c05621;
    color: rgba(192, 86, 33, var(--text-opacity));
  }

  .lg\:focus\:tw-text-orange-800:focus {
    --text-opacity: 1;
    color: #9c4221;
    color: rgba(156, 66, 33, var(--text-opacity));
  }

  .lg\:focus\:tw-text-orange-900:focus {
    --text-opacity: 1;
    color: #7b341e;
    color: rgba(123, 52, 30, var(--text-opacity));
  }

  .lg\:focus\:tw-text-yellow-100:focus {
    --text-opacity: 1;
    color: #fffff0;
    color: rgba(255, 255, 240, var(--text-opacity));
  }

  .lg\:focus\:tw-text-yellow-200:focus {
    --text-opacity: 1;
    color: #fefcbf;
    color: rgba(254, 252, 191, var(--text-opacity));
  }

  .lg\:focus\:tw-text-yellow-300:focus {
    --text-opacity: 1;
    color: #faf089;
    color: rgba(250, 240, 137, var(--text-opacity));
  }

  .lg\:focus\:tw-text-yellow-400:focus {
    --text-opacity: 1;
    color: #f6e05e;
    color: rgba(246, 224, 94, var(--text-opacity));
  }

  .lg\:focus\:tw-text-yellow-500:focus {
    --text-opacity: 1;
    color: #ecc94b;
    color: rgba(236, 201, 75, var(--text-opacity));
  }

  .lg\:focus\:tw-text-yellow-600:focus {
    --text-opacity: 1;
    color: #d69e2e;
    color: rgba(214, 158, 46, var(--text-opacity));
  }

  .lg\:focus\:tw-text-yellow-700:focus {
    --text-opacity: 1;
    color: #b7791f;
    color: rgba(183, 121, 31, var(--text-opacity));
  }

  .lg\:focus\:tw-text-yellow-800:focus {
    --text-opacity: 1;
    color: #975a16;
    color: rgba(151, 90, 22, var(--text-opacity));
  }

  .lg\:focus\:tw-text-yellow-900:focus {
    --text-opacity: 1;
    color: #744210;
    color: rgba(116, 66, 16, var(--text-opacity));
  }

  .lg\:focus\:tw-text-green-100:focus {
    --text-opacity: 1;
    color: #f0fff4;
    color: rgba(240, 255, 244, var(--text-opacity));
  }

  .lg\:focus\:tw-text-green-200:focus {
    --text-opacity: 1;
    color: #c6f6d5;
    color: rgba(198, 246, 213, var(--text-opacity));
  }

  .lg\:focus\:tw-text-green-300:focus {
    --text-opacity: 1;
    color: #9ae6b4;
    color: rgba(154, 230, 180, var(--text-opacity));
  }

  .lg\:focus\:tw-text-green-400:focus {
    --text-opacity: 1;
    color: #68d391;
    color: rgba(104, 211, 145, var(--text-opacity));
  }

  .lg\:focus\:tw-text-green-500:focus {
    --text-opacity: 1;
    color: #48bb78;
    color: rgba(72, 187, 120, var(--text-opacity));
  }

  .lg\:focus\:tw-text-green-600:focus {
    --text-opacity: 1;
    color: #38a169;
    color: rgba(56, 161, 105, var(--text-opacity));
  }

  .lg\:focus\:tw-text-green-700:focus {
    --text-opacity: 1;
    color: #2f855a;
    color: rgba(47, 133, 90, var(--text-opacity));
  }

  .lg\:focus\:tw-text-green-800:focus {
    --text-opacity: 1;
    color: #276749;
    color: rgba(39, 103, 73, var(--text-opacity));
  }

  .lg\:focus\:tw-text-green-900:focus {
    --text-opacity: 1;
    color: #22543d;
    color: rgba(34, 84, 61, var(--text-opacity));
  }

  .lg\:focus\:tw-text-teal-100:focus {
    --text-opacity: 1;
    color: #e6fffa;
    color: rgba(230, 255, 250, var(--text-opacity));
  }

  .lg\:focus\:tw-text-teal-200:focus {
    --text-opacity: 1;
    color: #b2f5ea;
    color: rgba(178, 245, 234, var(--text-opacity));
  }

  .lg\:focus\:tw-text-teal-300:focus {
    --text-opacity: 1;
    color: #81e6d9;
    color: rgba(129, 230, 217, var(--text-opacity));
  }

  .lg\:focus\:tw-text-teal-400:focus {
    --text-opacity: 1;
    color: #4fd1c5;
    color: rgba(79, 209, 197, var(--text-opacity));
  }

  .lg\:focus\:tw-text-teal-500:focus {
    --text-opacity: 1;
    color: #38b2ac;
    color: rgba(56, 178, 172, var(--text-opacity));
  }

  .lg\:focus\:tw-text-teal-600:focus {
    --text-opacity: 1;
    color: #319795;
    color: rgba(49, 151, 149, var(--text-opacity));
  }

  .lg\:focus\:tw-text-teal-700:focus {
    --text-opacity: 1;
    color: #2c7a7b;
    color: rgba(44, 122, 123, var(--text-opacity));
  }

  .lg\:focus\:tw-text-teal-800:focus {
    --text-opacity: 1;
    color: #285e61;
    color: rgba(40, 94, 97, var(--text-opacity));
  }

  .lg\:focus\:tw-text-teal-900:focus {
    --text-opacity: 1;
    color: #234e52;
    color: rgba(35, 78, 82, var(--text-opacity));
  }

  .lg\:focus\:tw-text-blue-100:focus {
    --text-opacity: 1;
    color: #ebf8ff;
    color: rgba(235, 248, 255, var(--text-opacity));
  }

  .lg\:focus\:tw-text-blue-200:focus {
    --text-opacity: 1;
    color: #bee3f8;
    color: rgba(190, 227, 248, var(--text-opacity));
  }

  .lg\:focus\:tw-text-blue-300:focus {
    --text-opacity: 1;
    color: #90cdf4;
    color: rgba(144, 205, 244, var(--text-opacity));
  }

  .lg\:focus\:tw-text-blue-400:focus {
    --text-opacity: 1;
    color: #63b3ed;
    color: rgba(99, 179, 237, var(--text-opacity));
  }

  .lg\:focus\:tw-text-blue-500:focus {
    --text-opacity: 1;
    color: #4299e1;
    color: rgba(66, 153, 225, var(--text-opacity));
  }

  .lg\:focus\:tw-text-blue-600:focus {
    --text-opacity: 1;
    color: #3182ce;
    color: rgba(49, 130, 206, var(--text-opacity));
  }

  .lg\:focus\:tw-text-blue-700:focus {
    --text-opacity: 1;
    color: #2b6cb0;
    color: rgba(43, 108, 176, var(--text-opacity));
  }

  .lg\:focus\:tw-text-blue-800:focus {
    --text-opacity: 1;
    color: #2c5282;
    color: rgba(44, 82, 130, var(--text-opacity));
  }

  .lg\:focus\:tw-text-blue-900:focus {
    --text-opacity: 1;
    color: #2a4365;
    color: rgba(42, 67, 101, var(--text-opacity));
  }

  .lg\:focus\:tw-text-indigo-100:focus {
    --text-opacity: 1;
    color: #ebf4ff;
    color: rgba(235, 244, 255, var(--text-opacity));
  }

  .lg\:focus\:tw-text-indigo-200:focus {
    --text-opacity: 1;
    color: #c3dafe;
    color: rgba(195, 218, 254, var(--text-opacity));
  }

  .lg\:focus\:tw-text-indigo-300:focus {
    --text-opacity: 1;
    color: #a3bffa;
    color: rgba(163, 191, 250, var(--text-opacity));
  }

  .lg\:focus\:tw-text-indigo-400:focus {
    --text-opacity: 1;
    color: #7f9cf5;
    color: rgba(127, 156, 245, var(--text-opacity));
  }

  .lg\:focus\:tw-text-indigo-500:focus {
    --text-opacity: 1;
    color: #667eea;
    color: rgba(102, 126, 234, var(--text-opacity));
  }

  .lg\:focus\:tw-text-indigo-600:focus {
    --text-opacity: 1;
    color: #5a67d8;
    color: rgba(90, 103, 216, var(--text-opacity));
  }

  .lg\:focus\:tw-text-indigo-700:focus {
    --text-opacity: 1;
    color: #4c51bf;
    color: rgba(76, 81, 191, var(--text-opacity));
  }

  .lg\:focus\:tw-text-indigo-800:focus {
    --text-opacity: 1;
    color: #434190;
    color: rgba(67, 65, 144, var(--text-opacity));
  }

  .lg\:focus\:tw-text-indigo-900:focus {
    --text-opacity: 1;
    color: #3c366b;
    color: rgba(60, 54, 107, var(--text-opacity));
  }

  .lg\:focus\:tw-text-purple-100:focus {
    --text-opacity: 1;
    color: #faf5ff;
    color: rgba(250, 245, 255, var(--text-opacity));
  }

  .lg\:focus\:tw-text-purple-200:focus {
    --text-opacity: 1;
    color: #e9d8fd;
    color: rgba(233, 216, 253, var(--text-opacity));
  }

  .lg\:focus\:tw-text-purple-300:focus {
    --text-opacity: 1;
    color: #d6bcfa;
    color: rgba(214, 188, 250, var(--text-opacity));
  }

  .lg\:focus\:tw-text-purple-400:focus {
    --text-opacity: 1;
    color: #b794f4;
    color: rgba(183, 148, 244, var(--text-opacity));
  }

  .lg\:focus\:tw-text-purple-500:focus {
    --text-opacity: 1;
    color: #9f7aea;
    color: rgba(159, 122, 234, var(--text-opacity));
  }

  .lg\:focus\:tw-text-purple-600:focus {
    --text-opacity: 1;
    color: #805ad5;
    color: rgba(128, 90, 213, var(--text-opacity));
  }

  .lg\:focus\:tw-text-purple-700:focus {
    --text-opacity: 1;
    color: #6b46c1;
    color: rgba(107, 70, 193, var(--text-opacity));
  }

  .lg\:focus\:tw-text-purple-800:focus {
    --text-opacity: 1;
    color: #553c9a;
    color: rgba(85, 60, 154, var(--text-opacity));
  }

  .lg\:focus\:tw-text-purple-900:focus {
    --text-opacity: 1;
    color: #44337a;
    color: rgba(68, 51, 122, var(--text-opacity));
  }

  .lg\:focus\:tw-text-pink-100:focus {
    --text-opacity: 1;
    color: #fff5f7;
    color: rgba(255, 245, 247, var(--text-opacity));
  }

  .lg\:focus\:tw-text-pink-200:focus {
    --text-opacity: 1;
    color: #fed7e2;
    color: rgba(254, 215, 226, var(--text-opacity));
  }

  .lg\:focus\:tw-text-pink-300:focus {
    --text-opacity: 1;
    color: #fbb6ce;
    color: rgba(251, 182, 206, var(--text-opacity));
  }

  .lg\:focus\:tw-text-pink-400:focus {
    --text-opacity: 1;
    color: #f687b3;
    color: rgba(246, 135, 179, var(--text-opacity));
  }

  .lg\:focus\:tw-text-pink-500:focus {
    --text-opacity: 1;
    color: #ed64a6;
    color: rgba(237, 100, 166, var(--text-opacity));
  }

  .lg\:focus\:tw-text-pink-600:focus {
    --text-opacity: 1;
    color: #d53f8c;
    color: rgba(213, 63, 140, var(--text-opacity));
  }

  .lg\:focus\:tw-text-pink-700:focus {
    --text-opacity: 1;
    color: #b83280;
    color: rgba(184, 50, 128, var(--text-opacity));
  }

  .lg\:focus\:tw-text-pink-800:focus {
    --text-opacity: 1;
    color: #97266d;
    color: rgba(151, 38, 109, var(--text-opacity));
  }

  .lg\:focus\:tw-text-pink-900:focus {
    --text-opacity: 1;
    color: #702459;
    color: rgba(112, 36, 89, var(--text-opacity));
  }

  .lg\:tw-text-opacity-0 {
    --text-opacity: 0;
  }

  .lg\:tw-text-opacity-25 {
    --text-opacity: 0.25;
  }

  .lg\:tw-text-opacity-50 {
    --text-opacity: 0.5;
  }

  .lg\:tw-text-opacity-75 {
    --text-opacity: 0.75;
  }

  .lg\:tw-text-opacity-100 {
    --text-opacity: 1;
  }

  .lg\:hover\:tw-text-opacity-0:hover {
    --text-opacity: 0;
  }

  .lg\:hover\:tw-text-opacity-25:hover {
    --text-opacity: 0.25;
  }

  .lg\:hover\:tw-text-opacity-50:hover {
    --text-opacity: 0.5;
  }

  .lg\:hover\:tw-text-opacity-75:hover {
    --text-opacity: 0.75;
  }

  .lg\:hover\:tw-text-opacity-100:hover {
    --text-opacity: 1;
  }

  .lg\:focus\:tw-text-opacity-0:focus {
    --text-opacity: 0;
  }

  .lg\:focus\:tw-text-opacity-25:focus {
    --text-opacity: 0.25;
  }

  .lg\:focus\:tw-text-opacity-50:focus {
    --text-opacity: 0.5;
  }

  .lg\:focus\:tw-text-opacity-75:focus {
    --text-opacity: 0.75;
  }

  .lg\:focus\:tw-text-opacity-100:focus {
    --text-opacity: 1;
  }

  .lg\:tw-italic {
    font-style: italic;
  }

  .lg\:tw-not-italic {
    font-style: normal;
  }

  .lg\:tw-uppercase {
    text-transform: uppercase;
  }

  .lg\:tw-lowercase {
    text-transform: lowercase;
  }

  .lg\:tw-capitalize {
    text-transform: capitalize;
  }

  .lg\:tw-normal-case {
    text-transform: none;
  }

  .lg\:tw-underline {
    text-decoration: underline;
  }

  .lg\:tw-line-through {
    text-decoration: line-through;
  }

  .lg\:tw-no-underline {
    text-decoration: none;
  }

  .lg\:hover\:tw-underline:hover {
    text-decoration: underline;
  }

  .lg\:hover\:tw-line-through:hover {
    text-decoration: line-through;
  }

  .lg\:hover\:tw-no-underline:hover {
    text-decoration: none;
  }

  .lg\:focus\:tw-underline:focus {
    text-decoration: underline;
  }

  .lg\:focus\:tw-line-through:focus {
    text-decoration: line-through;
  }

  .lg\:focus\:tw-no-underline:focus {
    text-decoration: none;
  }

  .lg\:tw-antialiased {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  .lg\:tw-subpixel-antialiased {
    -webkit-font-smoothing: auto;
    -moz-osx-font-smoothing: auto;
  }

  .lg\:tw-ordinal, .lg\:tw-slashed-zero, .lg\:tw-lining-nums, .lg\:tw-oldstyle-nums, .lg\:tw-proportional-nums, .lg\:tw-tabular-nums, .lg\:tw-diagonal-fractions, .lg\:tw-stacked-fractions {
    --font-variant-numeric-ordinal: var(--tailwind-empty,/*!*/ /*!*/);
    --font-variant-numeric-slashed-zero: var(--tailwind-empty,/*!*/ /*!*/);
    --font-variant-numeric-figure: var(--tailwind-empty,/*!*/ /*!*/);
    --font-variant-numeric-spacing: var(--tailwind-empty,/*!*/ /*!*/);
    --font-variant-numeric-fraction: var(--tailwind-empty,/*!*/ /*!*/);
    font-variant-numeric: var(--font-variant-numeric-ordinal) var(--font-variant-numeric-slashed-zero) var(--font-variant-numeric-figure) var(--font-variant-numeric-spacing) var(--font-variant-numeric-fraction);
  }

  .lg\:tw-normal-nums {
    font-variant-numeric: normal;
  }

  .lg\:tw-ordinal {
    --font-variant-numeric-ordinal: ordinal;
  }

  .lg\:tw-slashed-zero {
    --font-variant-numeric-slashed-zero: slashed-zero;
  }

  .lg\:tw-lining-nums {
    --font-variant-numeric-figure: lining-nums;
  }

  .lg\:tw-oldstyle-nums {
    --font-variant-numeric-figure: oldstyle-nums;
  }

  .lg\:tw-proportional-nums {
    --font-variant-numeric-spacing: proportional-nums;
  }

  .lg\:tw-tabular-nums {
    --font-variant-numeric-spacing: tabular-nums;
  }

  .lg\:tw-diagonal-fractions {
    --font-variant-numeric-fraction: diagonal-fractions;
  }

  .lg\:tw-stacked-fractions {
    --font-variant-numeric-fraction: stacked-fractions;
  }

  .lg\:tw-tracking-tighter {
    letter-spacing: -0.05em;
  }

  .lg\:tw-tracking-tight {
    letter-spacing: -0.025em;
  }

  .lg\:tw-tracking-normal {
    letter-spacing: 0;
  }

  .lg\:tw-tracking-wide {
    letter-spacing: 0.025em;
  }

  .lg\:tw-tracking-wider {
    letter-spacing: 0.05em;
  }

  .lg\:tw-tracking-widest {
    letter-spacing: 0.1em;
  }

  .lg\:tw-select-none {
    -webkit-user-select: none;
       -moz-user-select: none;
            user-select: none;
  }

  .lg\:tw-select-text {
    -webkit-user-select: text;
       -moz-user-select: text;
            user-select: text;
  }

  .lg\:tw-select-all {
    -webkit-user-select: all;
       -moz-user-select: all;
            user-select: all;
  }

  .lg\:tw-select-auto {
    -webkit-user-select: auto;
       -moz-user-select: auto;
            user-select: auto;
  }

  .lg\:tw-align-baseline {
    vertical-align: baseline;
  }

  .lg\:tw-align-top {
    vertical-align: top;
  }

  .lg\:tw-align-middle {
    vertical-align: middle;
  }

  .lg\:tw-align-bottom {
    vertical-align: bottom;
  }

  .lg\:tw-align-text-top {
    vertical-align: text-top;
  }

  .lg\:tw-align-text-bottom {
    vertical-align: text-bottom;
  }

  .lg\:tw-visible {
    visibility: visible;
  }

  .lg\:tw-invisible {
    visibility: hidden;
  }

  .lg\:tw-whitespace-normal {
    white-space: normal;
  }

  .lg\:tw-whitespace-no-wrap {
    white-space: nowrap;
  }

  .lg\:tw-whitespace-pre {
    white-space: pre;
  }

  .lg\:tw-whitespace-pre-line {
    white-space: pre-line;
  }

  .lg\:tw-whitespace-pre-wrap {
    white-space: pre-wrap;
  }

  .lg\:tw-break-normal {
    word-wrap: normal;
    overflow-wrap: normal;
    word-break: normal;
  }

  .lg\:tw-break-words {
    word-wrap: break-word;
    overflow-wrap: break-word;
  }

  .lg\:tw-break-all {
    word-break: break-all;
  }

  .lg\:tw-truncate {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .lg\:tw-w-0 {
    width: 0;
  }

  .lg\:tw-w-1 {
    width: 0.25rem;
  }

  .lg\:tw-w-2 {
    width: 0.5rem;
  }

  .lg\:tw-w-3 {
    width: 0.75rem;
  }

  .lg\:tw-w-4 {
    width: 1rem;
  }

  .lg\:tw-w-5 {
    width: 1.25rem;
  }

  .lg\:tw-w-6 {
    width: 1.5rem;
  }

  .lg\:tw-w-8 {
    width: 2rem;
  }

  .lg\:tw-w-10 {
    width: 2.5rem;
  }

  .lg\:tw-w-12 {
    width: 3rem;
  }

  .lg\:tw-w-16 {
    width: 4rem;
  }

  .lg\:tw-w-20 {
    width: 5rem;
  }

  .lg\:tw-w-24 {
    width: 6rem;
  }

  .lg\:tw-w-32 {
    width: 8rem;
  }

  .lg\:tw-w-40 {
    width: 10rem;
  }

  .lg\:tw-w-48 {
    width: 12rem;
  }

  .lg\:tw-w-56 {
    width: 14rem;
  }

  .lg\:tw-w-64 {
    width: 16rem;
  }

  .lg\:tw-w-auto {
    width: auto;
  }

  .lg\:tw-w-px {
    width: 1px;
  }

  .lg\:tw-w-270px {
    width: 270px;
  }

  .lg\:tw-w-400px {
    width: 400px;
  }

  .lg\:tw-w-464px {
    width: 464px;
  }

  .lg\:tw-w-480px {
    width: 480px;
  }

  .lg\:tw-w-500px {
    width: 500px;
  }

  .lg\:tw-w-576px {
    width: 576px;
  }

  .lg\:tw-w-600px {
    width: 600px;
  }

  .lg\:tw-w-672px {
    width: 672px;
  }

  .lg\:tw-w-1\/2 {
    width: 50%;
  }

  .lg\:tw-w-1\/3 {
    width: 33.333333%;
  }

  .lg\:tw-w-2\/3 {
    width: 66.666667%;
  }

  .lg\:tw-w-1\/4 {
    width: 25%;
  }

  .lg\:tw-w-2\/4 {
    width: 50%;
  }

  .lg\:tw-w-3\/4 {
    width: 75%;
  }

  .lg\:tw-w-1\/5 {
    width: 20%;
  }

  .lg\:tw-w-2\/5 {
    width: 40%;
  }

  .lg\:tw-w-3\/5 {
    width: 60%;
  }

  .lg\:tw-w-4\/5 {
    width: 80%;
  }

  .lg\:tw-w-1\/6 {
    width: 16.666667%;
  }

  .lg\:tw-w-2\/6 {
    width: 33.333333%;
  }

  .lg\:tw-w-3\/6 {
    width: 50%;
  }

  .lg\:tw-w-4\/6 {
    width: 66.666667%;
  }

  .lg\:tw-w-5\/6 {
    width: 83.333333%;
  }

  .lg\:tw-w-1\/12 {
    width: 8.333333%;
  }

  .lg\:tw-w-2\/12 {
    width: 16.666667%;
  }

  .lg\:tw-w-3\/12 {
    width: 25%;
  }

  .lg\:tw-w-4\/12 {
    width: 33.333333%;
  }

  .lg\:tw-w-5\/12 {
    width: 41.666667%;
  }

  .lg\:tw-w-6\/12 {
    width: 50%;
  }

  .lg\:tw-w-7\/12 {
    width: 58.333333%;
  }

  .lg\:tw-w-8\/12 {
    width: 66.666667%;
  }

  .lg\:tw-w-9\/12 {
    width: 75%;
  }

  .lg\:tw-w-10\/12 {
    width: 83.333333%;
  }

  .lg\:tw-w-11\/12 {
    width: 91.666667%;
  }

  .lg\:tw-w-full {
    width: 100%;
  }

  .lg\:tw-w-screen {
    width: 100vw;
  }

  .lg\:tw-z-0 {
    z-index: 0;
  }

  .lg\:tw-z-10 {
    z-index: 10;
  }

  .lg\:tw-z-20 {
    z-index: 20;
  }

  .lg\:tw-z-30 {
    z-index: 30;
  }

  .lg\:tw-z-40 {
    z-index: 40;
  }

  .lg\:tw-z-50 {
    z-index: 50;
  }

  .lg\:tw-z-auto {
    z-index: auto;
  }

  .lg\:tw-gap-0 {
    grid-gap: 0;
    gap: 0;
  }

  .lg\:tw-gap-1 {
    grid-gap: 0.25rem;
    gap: 0.25rem;
  }

  .lg\:tw-gap-2 {
    grid-gap: 0.5rem;
    gap: 0.5rem;
  }

  .lg\:tw-gap-3 {
    grid-gap: 0.75rem;
    gap: 0.75rem;
  }

  .lg\:tw-gap-4 {
    grid-gap: 1rem;
    gap: 1rem;
  }

  .lg\:tw-gap-5 {
    grid-gap: 1.25rem;
    gap: 1.25rem;
  }

  .lg\:tw-gap-6 {
    grid-gap: 1.5rem;
    gap: 1.5rem;
  }

  .lg\:tw-gap-8 {
    grid-gap: 2rem;
    gap: 2rem;
  }

  .lg\:tw-gap-10 {
    grid-gap: 2.5rem;
    gap: 2.5rem;
  }

  .lg\:tw-gap-12 {
    grid-gap: 3rem;
    gap: 3rem;
  }

  .lg\:tw-gap-16 {
    grid-gap: 4rem;
    gap: 4rem;
  }

  .lg\:tw-gap-20 {
    grid-gap: 5rem;
    gap: 5rem;
  }

  .lg\:tw-gap-24 {
    grid-gap: 6rem;
    gap: 6rem;
  }

  .lg\:tw-gap-32 {
    grid-gap: 8rem;
    gap: 8rem;
  }

  .lg\:tw-gap-40 {
    grid-gap: 10rem;
    gap: 10rem;
  }

  .lg\:tw-gap-48 {
    grid-gap: 12rem;
    gap: 12rem;
  }

  .lg\:tw-gap-56 {
    grid-gap: 14rem;
    gap: 14rem;
  }

  .lg\:tw-gap-64 {
    grid-gap: 16rem;
    gap: 16rem;
  }

  .lg\:tw-gap-px {
    grid-gap: 1px;
    gap: 1px;
  }

  .lg\:tw-gap-270px {
    grid-gap: 270px;
    gap: 270px;
  }

  .lg\:tw-gap-400px {
    grid-gap: 400px;
    gap: 400px;
  }

  .lg\:tw-gap-464px {
    grid-gap: 464px;
    gap: 464px;
  }

  .lg\:tw-gap-480px {
    grid-gap: 480px;
    gap: 480px;
  }

  .lg\:tw-gap-500px {
    grid-gap: 500px;
    gap: 500px;
  }

  .lg\:tw-gap-576px {
    grid-gap: 576px;
    gap: 576px;
  }

  .lg\:tw-gap-600px {
    grid-gap: 600px;
    gap: 600px;
  }

  .lg\:tw-gap-672px {
    grid-gap: 672px;
    gap: 672px;
  }

  .lg\:tw-col-gap-0 {
    grid-column-gap: 0;
    -moz-column-gap: 0;
         column-gap: 0;
  }

  .lg\:tw-col-gap-1 {
    grid-column-gap: 0.25rem;
    -moz-column-gap: 0.25rem;
         column-gap: 0.25rem;
  }

  .lg\:tw-col-gap-2 {
    grid-column-gap: 0.5rem;
    -moz-column-gap: 0.5rem;
         column-gap: 0.5rem;
  }

  .lg\:tw-col-gap-3 {
    grid-column-gap: 0.75rem;
    -moz-column-gap: 0.75rem;
         column-gap: 0.75rem;
  }

  .lg\:tw-col-gap-4 {
    grid-column-gap: 1rem;
    -moz-column-gap: 1rem;
         column-gap: 1rem;
  }

  .lg\:tw-col-gap-5 {
    grid-column-gap: 1.25rem;
    -moz-column-gap: 1.25rem;
         column-gap: 1.25rem;
  }

  .lg\:tw-col-gap-6 {
    grid-column-gap: 1.5rem;
    -moz-column-gap: 1.5rem;
         column-gap: 1.5rem;
  }

  .lg\:tw-col-gap-8 {
    grid-column-gap: 2rem;
    -moz-column-gap: 2rem;
         column-gap: 2rem;
  }

  .lg\:tw-col-gap-10 {
    grid-column-gap: 2.5rem;
    -moz-column-gap: 2.5rem;
         column-gap: 2.5rem;
  }

  .lg\:tw-col-gap-12 {
    grid-column-gap: 3rem;
    -moz-column-gap: 3rem;
         column-gap: 3rem;
  }

  .lg\:tw-col-gap-16 {
    grid-column-gap: 4rem;
    -moz-column-gap: 4rem;
         column-gap: 4rem;
  }

  .lg\:tw-col-gap-20 {
    grid-column-gap: 5rem;
    -moz-column-gap: 5rem;
         column-gap: 5rem;
  }

  .lg\:tw-col-gap-24 {
    grid-column-gap: 6rem;
    -moz-column-gap: 6rem;
         column-gap: 6rem;
  }

  .lg\:tw-col-gap-32 {
    grid-column-gap: 8rem;
    -moz-column-gap: 8rem;
         column-gap: 8rem;
  }

  .lg\:tw-col-gap-40 {
    grid-column-gap: 10rem;
    -moz-column-gap: 10rem;
         column-gap: 10rem;
  }

  .lg\:tw-col-gap-48 {
    grid-column-gap: 12rem;
    -moz-column-gap: 12rem;
         column-gap: 12rem;
  }

  .lg\:tw-col-gap-56 {
    grid-column-gap: 14rem;
    -moz-column-gap: 14rem;
         column-gap: 14rem;
  }

  .lg\:tw-col-gap-64 {
    grid-column-gap: 16rem;
    -moz-column-gap: 16rem;
         column-gap: 16rem;
  }

  .lg\:tw-col-gap-px {
    grid-column-gap: 1px;
    -moz-column-gap: 1px;
         column-gap: 1px;
  }

  .lg\:tw-col-gap-270px {
    grid-column-gap: 270px;
    -moz-column-gap: 270px;
         column-gap: 270px;
  }

  .lg\:tw-col-gap-400px {
    grid-column-gap: 400px;
    -moz-column-gap: 400px;
         column-gap: 400px;
  }

  .lg\:tw-col-gap-464px {
    grid-column-gap: 464px;
    -moz-column-gap: 464px;
         column-gap: 464px;
  }

  .lg\:tw-col-gap-480px {
    grid-column-gap: 480px;
    -moz-column-gap: 480px;
         column-gap: 480px;
  }

  .lg\:tw-col-gap-500px {
    grid-column-gap: 500px;
    -moz-column-gap: 500px;
         column-gap: 500px;
  }

  .lg\:tw-col-gap-576px {
    grid-column-gap: 576px;
    -moz-column-gap: 576px;
         column-gap: 576px;
  }

  .lg\:tw-col-gap-600px {
    grid-column-gap: 600px;
    -moz-column-gap: 600px;
         column-gap: 600px;
  }

  .lg\:tw-col-gap-672px {
    grid-column-gap: 672px;
    -moz-column-gap: 672px;
         column-gap: 672px;
  }

  .lg\:tw-gap-x-0 {
    grid-column-gap: 0;
    -moz-column-gap: 0;
         column-gap: 0;
  }

  .lg\:tw-gap-x-1 {
    grid-column-gap: 0.25rem;
    -moz-column-gap: 0.25rem;
         column-gap: 0.25rem;
  }

  .lg\:tw-gap-x-2 {
    grid-column-gap: 0.5rem;
    -moz-column-gap: 0.5rem;
         column-gap: 0.5rem;
  }

  .lg\:tw-gap-x-3 {
    grid-column-gap: 0.75rem;
    -moz-column-gap: 0.75rem;
         column-gap: 0.75rem;
  }

  .lg\:tw-gap-x-4 {
    grid-column-gap: 1rem;
    -moz-column-gap: 1rem;
         column-gap: 1rem;
  }

  .lg\:tw-gap-x-5 {
    grid-column-gap: 1.25rem;
    -moz-column-gap: 1.25rem;
         column-gap: 1.25rem;
  }

  .lg\:tw-gap-x-6 {
    grid-column-gap: 1.5rem;
    -moz-column-gap: 1.5rem;
         column-gap: 1.5rem;
  }

  .lg\:tw-gap-x-8 {
    grid-column-gap: 2rem;
    -moz-column-gap: 2rem;
         column-gap: 2rem;
  }

  .lg\:tw-gap-x-10 {
    grid-column-gap: 2.5rem;
    -moz-column-gap: 2.5rem;
         column-gap: 2.5rem;
  }

  .lg\:tw-gap-x-12 {
    grid-column-gap: 3rem;
    -moz-column-gap: 3rem;
         column-gap: 3rem;
  }

  .lg\:tw-gap-x-16 {
    grid-column-gap: 4rem;
    -moz-column-gap: 4rem;
         column-gap: 4rem;
  }

  .lg\:tw-gap-x-20 {
    grid-column-gap: 5rem;
    -moz-column-gap: 5rem;
         column-gap: 5rem;
  }

  .lg\:tw-gap-x-24 {
    grid-column-gap: 6rem;
    -moz-column-gap: 6rem;
         column-gap: 6rem;
  }

  .lg\:tw-gap-x-32 {
    grid-column-gap: 8rem;
    -moz-column-gap: 8rem;
         column-gap: 8rem;
  }

  .lg\:tw-gap-x-40 {
    grid-column-gap: 10rem;
    -moz-column-gap: 10rem;
         column-gap: 10rem;
  }

  .lg\:tw-gap-x-48 {
    grid-column-gap: 12rem;
    -moz-column-gap: 12rem;
         column-gap: 12rem;
  }

  .lg\:tw-gap-x-56 {
    grid-column-gap: 14rem;
    -moz-column-gap: 14rem;
         column-gap: 14rem;
  }

  .lg\:tw-gap-x-64 {
    grid-column-gap: 16rem;
    -moz-column-gap: 16rem;
         column-gap: 16rem;
  }

  .lg\:tw-gap-x-px {
    grid-column-gap: 1px;
    -moz-column-gap: 1px;
         column-gap: 1px;
  }

  .lg\:tw-gap-x-270px {
    grid-column-gap: 270px;
    -moz-column-gap: 270px;
         column-gap: 270px;
  }

  .lg\:tw-gap-x-400px {
    grid-column-gap: 400px;
    -moz-column-gap: 400px;
         column-gap: 400px;
  }

  .lg\:tw-gap-x-464px {
    grid-column-gap: 464px;
    -moz-column-gap: 464px;
         column-gap: 464px;
  }

  .lg\:tw-gap-x-480px {
    grid-column-gap: 480px;
    -moz-column-gap: 480px;
         column-gap: 480px;
  }

  .lg\:tw-gap-x-500px {
    grid-column-gap: 500px;
    -moz-column-gap: 500px;
         column-gap: 500px;
  }

  .lg\:tw-gap-x-576px {
    grid-column-gap: 576px;
    -moz-column-gap: 576px;
         column-gap: 576px;
  }

  .lg\:tw-gap-x-600px {
    grid-column-gap: 600px;
    -moz-column-gap: 600px;
         column-gap: 600px;
  }

  .lg\:tw-gap-x-672px {
    grid-column-gap: 672px;
    -moz-column-gap: 672px;
         column-gap: 672px;
  }

  .lg\:tw-row-gap-0 {
    grid-row-gap: 0;
    row-gap: 0;
  }

  .lg\:tw-row-gap-1 {
    grid-row-gap: 0.25rem;
    row-gap: 0.25rem;
  }

  .lg\:tw-row-gap-2 {
    grid-row-gap: 0.5rem;
    row-gap: 0.5rem;
  }

  .lg\:tw-row-gap-3 {
    grid-row-gap: 0.75rem;
    row-gap: 0.75rem;
  }

  .lg\:tw-row-gap-4 {
    grid-row-gap: 1rem;
    row-gap: 1rem;
  }

  .lg\:tw-row-gap-5 {
    grid-row-gap: 1.25rem;
    row-gap: 1.25rem;
  }

  .lg\:tw-row-gap-6 {
    grid-row-gap: 1.5rem;
    row-gap: 1.5rem;
  }

  .lg\:tw-row-gap-8 {
    grid-row-gap: 2rem;
    row-gap: 2rem;
  }

  .lg\:tw-row-gap-10 {
    grid-row-gap: 2.5rem;
    row-gap: 2.5rem;
  }

  .lg\:tw-row-gap-12 {
    grid-row-gap: 3rem;
    row-gap: 3rem;
  }

  .lg\:tw-row-gap-16 {
    grid-row-gap: 4rem;
    row-gap: 4rem;
  }

  .lg\:tw-row-gap-20 {
    grid-row-gap: 5rem;
    row-gap: 5rem;
  }

  .lg\:tw-row-gap-24 {
    grid-row-gap: 6rem;
    row-gap: 6rem;
  }

  .lg\:tw-row-gap-32 {
    grid-row-gap: 8rem;
    row-gap: 8rem;
  }

  .lg\:tw-row-gap-40 {
    grid-row-gap: 10rem;
    row-gap: 10rem;
  }

  .lg\:tw-row-gap-48 {
    grid-row-gap: 12rem;
    row-gap: 12rem;
  }

  .lg\:tw-row-gap-56 {
    grid-row-gap: 14rem;
    row-gap: 14rem;
  }

  .lg\:tw-row-gap-64 {
    grid-row-gap: 16rem;
    row-gap: 16rem;
  }

  .lg\:tw-row-gap-px {
    grid-row-gap: 1px;
    row-gap: 1px;
  }

  .lg\:tw-row-gap-270px {
    grid-row-gap: 270px;
    row-gap: 270px;
  }

  .lg\:tw-row-gap-400px {
    grid-row-gap: 400px;
    row-gap: 400px;
  }

  .lg\:tw-row-gap-464px {
    grid-row-gap: 464px;
    row-gap: 464px;
  }

  .lg\:tw-row-gap-480px {
    grid-row-gap: 480px;
    row-gap: 480px;
  }

  .lg\:tw-row-gap-500px {
    grid-row-gap: 500px;
    row-gap: 500px;
  }

  .lg\:tw-row-gap-576px {
    grid-row-gap: 576px;
    row-gap: 576px;
  }

  .lg\:tw-row-gap-600px {
    grid-row-gap: 600px;
    row-gap: 600px;
  }

  .lg\:tw-row-gap-672px {
    grid-row-gap: 672px;
    row-gap: 672px;
  }

  .lg\:tw-gap-y-0 {
    grid-row-gap: 0;
    row-gap: 0;
  }

  .lg\:tw-gap-y-1 {
    grid-row-gap: 0.25rem;
    row-gap: 0.25rem;
  }

  .lg\:tw-gap-y-2 {
    grid-row-gap: 0.5rem;
    row-gap: 0.5rem;
  }

  .lg\:tw-gap-y-3 {
    grid-row-gap: 0.75rem;
    row-gap: 0.75rem;
  }

  .lg\:tw-gap-y-4 {
    grid-row-gap: 1rem;
    row-gap: 1rem;
  }

  .lg\:tw-gap-y-5 {
    grid-row-gap: 1.25rem;
    row-gap: 1.25rem;
  }

  .lg\:tw-gap-y-6 {
    grid-row-gap: 1.5rem;
    row-gap: 1.5rem;
  }

  .lg\:tw-gap-y-8 {
    grid-row-gap: 2rem;
    row-gap: 2rem;
  }

  .lg\:tw-gap-y-10 {
    grid-row-gap: 2.5rem;
    row-gap: 2.5rem;
  }

  .lg\:tw-gap-y-12 {
    grid-row-gap: 3rem;
    row-gap: 3rem;
  }

  .lg\:tw-gap-y-16 {
    grid-row-gap: 4rem;
    row-gap: 4rem;
  }

  .lg\:tw-gap-y-20 {
    grid-row-gap: 5rem;
    row-gap: 5rem;
  }

  .lg\:tw-gap-y-24 {
    grid-row-gap: 6rem;
    row-gap: 6rem;
  }

  .lg\:tw-gap-y-32 {
    grid-row-gap: 8rem;
    row-gap: 8rem;
  }

  .lg\:tw-gap-y-40 {
    grid-row-gap: 10rem;
    row-gap: 10rem;
  }

  .lg\:tw-gap-y-48 {
    grid-row-gap: 12rem;
    row-gap: 12rem;
  }

  .lg\:tw-gap-y-56 {
    grid-row-gap: 14rem;
    row-gap: 14rem;
  }

  .lg\:tw-gap-y-64 {
    grid-row-gap: 16rem;
    row-gap: 16rem;
  }

  .lg\:tw-gap-y-px {
    grid-row-gap: 1px;
    row-gap: 1px;
  }

  .lg\:tw-gap-y-270px {
    grid-row-gap: 270px;
    row-gap: 270px;
  }

  .lg\:tw-gap-y-400px {
    grid-row-gap: 400px;
    row-gap: 400px;
  }

  .lg\:tw-gap-y-464px {
    grid-row-gap: 464px;
    row-gap: 464px;
  }

  .lg\:tw-gap-y-480px {
    grid-row-gap: 480px;
    row-gap: 480px;
  }

  .lg\:tw-gap-y-500px {
    grid-row-gap: 500px;
    row-gap: 500px;
  }

  .lg\:tw-gap-y-576px {
    grid-row-gap: 576px;
    row-gap: 576px;
  }

  .lg\:tw-gap-y-600px {
    grid-row-gap: 600px;
    row-gap: 600px;
  }

  .lg\:tw-gap-y-672px {
    grid-row-gap: 672px;
    row-gap: 672px;
  }

  .lg\:tw-grid-flow-row {
    grid-auto-flow: row;
  }

  .lg\:tw-grid-flow-col {
    grid-auto-flow: column;
  }

  .lg\:tw-grid-flow-row-dense {
    grid-auto-flow: row dense;
  }

  .lg\:tw-grid-flow-col-dense {
    grid-auto-flow: column dense;
  }

  .lg\:tw-grid-cols-1 {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }

  .lg\:tw-grid-cols-2 {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  .lg\:tw-grid-cols-3 {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }

  .lg\:tw-grid-cols-4 {
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }

  .lg\:tw-grid-cols-5 {
    grid-template-columns: repeat(5, minmax(0, 1fr));
  }

  .lg\:tw-grid-cols-6 {
    grid-template-columns: repeat(6, minmax(0, 1fr));
  }

  .lg\:tw-grid-cols-7 {
    grid-template-columns: repeat(7, minmax(0, 1fr));
  }

  .lg\:tw-grid-cols-8 {
    grid-template-columns: repeat(8, minmax(0, 1fr));
  }

  .lg\:tw-grid-cols-9 {
    grid-template-columns: repeat(9, minmax(0, 1fr));
  }

  .lg\:tw-grid-cols-10 {
    grid-template-columns: repeat(10, minmax(0, 1fr));
  }

  .lg\:tw-grid-cols-11 {
    grid-template-columns: repeat(11, minmax(0, 1fr));
  }

  .lg\:tw-grid-cols-12 {
    grid-template-columns: repeat(12, minmax(0, 1fr));
  }

  .lg\:tw-grid-cols-none {
    grid-template-columns: none;
  }

  .lg\:tw-auto-cols-auto {
    grid-auto-columns: auto;
  }

  .lg\:tw-auto-cols-min {
    grid-auto-columns: min-content;
  }

  .lg\:tw-auto-cols-max {
    grid-auto-columns: max-content;
  }

  .lg\:tw-auto-cols-fr {
    grid-auto-columns: minmax(0, 1fr);
  }

  .lg\:tw-col-auto {
    grid-column: auto;
  }

  .lg\:tw-col-span-1 {
    grid-column: span 1 / span 1;
  }

  .lg\:tw-col-span-2 {
    grid-column: span 2 / span 2;
  }

  .lg\:tw-col-span-3 {
    grid-column: span 3 / span 3;
  }

  .lg\:tw-col-span-4 {
    grid-column: span 4 / span 4;
  }

  .lg\:tw-col-span-5 {
    grid-column: span 5 / span 5;
  }

  .lg\:tw-col-span-6 {
    grid-column: span 6 / span 6;
  }

  .lg\:tw-col-span-7 {
    grid-column: span 7 / span 7;
  }

  .lg\:tw-col-span-8 {
    grid-column: span 8 / span 8;
  }

  .lg\:tw-col-span-9 {
    grid-column: span 9 / span 9;
  }

  .lg\:tw-col-span-10 {
    grid-column: span 10 / span 10;
  }

  .lg\:tw-col-span-11 {
    grid-column: span 11 / span 11;
  }

  .lg\:tw-col-span-12 {
    grid-column: span 12 / span 12;
  }

  .lg\:tw-col-span-full {
    grid-column: 1 / -1;
  }

  .lg\:tw-col-start-1 {
    grid-column-start: 1;
  }

  .lg\:tw-col-start-2 {
    grid-column-start: 2;
  }

  .lg\:tw-col-start-3 {
    grid-column-start: 3;
  }

  .lg\:tw-col-start-4 {
    grid-column-start: 4;
  }

  .lg\:tw-col-start-5 {
    grid-column-start: 5;
  }

  .lg\:tw-col-start-6 {
    grid-column-start: 6;
  }

  .lg\:tw-col-start-7 {
    grid-column-start: 7;
  }

  .lg\:tw-col-start-8 {
    grid-column-start: 8;
  }

  .lg\:tw-col-start-9 {
    grid-column-start: 9;
  }

  .lg\:tw-col-start-10 {
    grid-column-start: 10;
  }

  .lg\:tw-col-start-11 {
    grid-column-start: 11;
  }

  .lg\:tw-col-start-12 {
    grid-column-start: 12;
  }

  .lg\:tw-col-start-13 {
    grid-column-start: 13;
  }

  .lg\:tw-col-start-auto {
    grid-column-start: auto;
  }

  .lg\:tw-col-end-1 {
    grid-column-end: 1;
  }

  .lg\:tw-col-end-2 {
    grid-column-end: 2;
  }

  .lg\:tw-col-end-3 {
    grid-column-end: 3;
  }

  .lg\:tw-col-end-4 {
    grid-column-end: 4;
  }

  .lg\:tw-col-end-5 {
    grid-column-end: 5;
  }

  .lg\:tw-col-end-6 {
    grid-column-end: 6;
  }

  .lg\:tw-col-end-7 {
    grid-column-end: 7;
  }

  .lg\:tw-col-end-8 {
    grid-column-end: 8;
  }

  .lg\:tw-col-end-9 {
    grid-column-end: 9;
  }

  .lg\:tw-col-end-10 {
    grid-column-end: 10;
  }

  .lg\:tw-col-end-11 {
    grid-column-end: 11;
  }

  .lg\:tw-col-end-12 {
    grid-column-end: 12;
  }

  .lg\:tw-col-end-13 {
    grid-column-end: 13;
  }

  .lg\:tw-col-end-auto {
    grid-column-end: auto;
  }

  .lg\:tw-grid-rows-1 {
    grid-template-rows: repeat(1, minmax(0, 1fr));
  }

  .lg\:tw-grid-rows-2 {
    grid-template-rows: repeat(2, minmax(0, 1fr));
  }

  .lg\:tw-grid-rows-3 {
    grid-template-rows: repeat(3, minmax(0, 1fr));
  }

  .lg\:tw-grid-rows-4 {
    grid-template-rows: repeat(4, minmax(0, 1fr));
  }

  .lg\:tw-grid-rows-5 {
    grid-template-rows: repeat(5, minmax(0, 1fr));
  }

  .lg\:tw-grid-rows-6 {
    grid-template-rows: repeat(6, minmax(0, 1fr));
  }

  .lg\:tw-grid-rows-none {
    grid-template-rows: none;
  }

  .lg\:tw-auto-rows-auto {
    grid-auto-rows: auto;
  }

  .lg\:tw-auto-rows-min {
    grid-auto-rows: min-content;
  }

  .lg\:tw-auto-rows-max {
    grid-auto-rows: max-content;
  }

  .lg\:tw-auto-rows-fr {
    grid-auto-rows: minmax(0, 1fr);
  }

  .lg\:tw-row-auto {
    grid-row: auto;
  }

  .lg\:tw-row-span-1 {
    grid-row: span 1 / span 1;
  }

  .lg\:tw-row-span-2 {
    grid-row: span 2 / span 2;
  }

  .lg\:tw-row-span-3 {
    grid-row: span 3 / span 3;
  }

  .lg\:tw-row-span-4 {
    grid-row: span 4 / span 4;
  }

  .lg\:tw-row-span-5 {
    grid-row: span 5 / span 5;
  }

  .lg\:tw-row-span-6 {
    grid-row: span 6 / span 6;
  }

  .lg\:tw-row-span-full {
    grid-row: 1 / -1;
  }

  .lg\:tw-row-start-1 {
    grid-row-start: 1;
  }

  .lg\:tw-row-start-2 {
    grid-row-start: 2;
  }

  .lg\:tw-row-start-3 {
    grid-row-start: 3;
  }

  .lg\:tw-row-start-4 {
    grid-row-start: 4;
  }

  .lg\:tw-row-start-5 {
    grid-row-start: 5;
  }

  .lg\:tw-row-start-6 {
    grid-row-start: 6;
  }

  .lg\:tw-row-start-7 {
    grid-row-start: 7;
  }

  .lg\:tw-row-start-auto {
    grid-row-start: auto;
  }

  .lg\:tw-row-end-1 {
    grid-row-end: 1;
  }

  .lg\:tw-row-end-2 {
    grid-row-end: 2;
  }

  .lg\:tw-row-end-3 {
    grid-row-end: 3;
  }

  .lg\:tw-row-end-4 {
    grid-row-end: 4;
  }

  .lg\:tw-row-end-5 {
    grid-row-end: 5;
  }

  .lg\:tw-row-end-6 {
    grid-row-end: 6;
  }

  .lg\:tw-row-end-7 {
    grid-row-end: 7;
  }

  .lg\:tw-row-end-auto {
    grid-row-end: auto;
  }

  .lg\:tw-transform {
    --transform-translate-x: 0;
    --transform-translate-y: 0;
    --transform-rotate: 0;
    --transform-skew-x: 0;
    --transform-skew-y: 0;
    --transform-scale-x: 1;
    --transform-scale-y: 1;
    transform: translateX(var(--transform-translate-x)) translateY(var(--transform-translate-y)) rotate(var(--transform-rotate)) skewX(var(--transform-skew-x)) skewY(var(--transform-skew-y)) scaleX(var(--transform-scale-x)) scaleY(var(--transform-scale-y));
  }

  .lg\:tw-transform-none {
    transform: none;
  }

  .lg\:tw-origin-center {
    transform-origin: center;
  }

  .lg\:tw-origin-top {
    transform-origin: top;
  }

  .lg\:tw-origin-top-right {
    transform-origin: top right;
  }

  .lg\:tw-origin-right {
    transform-origin: right;
  }

  .lg\:tw-origin-bottom-right {
    transform-origin: bottom right;
  }

  .lg\:tw-origin-bottom {
    transform-origin: bottom;
  }

  .lg\:tw-origin-bottom-left {
    transform-origin: bottom left;
  }

  .lg\:tw-origin-left {
    transform-origin: left;
  }

  .lg\:tw-origin-top-left {
    transform-origin: top left;
  }

  .lg\:tw-scale-0 {
    --transform-scale-x: 0;
    --transform-scale-y: 0;
  }

  .lg\:tw-scale-50 {
    --transform-scale-x: .5;
    --transform-scale-y: .5;
  }

  .lg\:tw-scale-75 {
    --transform-scale-x: .75;
    --transform-scale-y: .75;
  }

  .lg\:tw-scale-90 {
    --transform-scale-x: .9;
    --transform-scale-y: .9;
  }

  .lg\:tw-scale-95 {
    --transform-scale-x: .95;
    --transform-scale-y: .95;
  }

  .lg\:tw-scale-100 {
    --transform-scale-x: 1;
    --transform-scale-y: 1;
  }

  .lg\:tw-scale-105 {
    --transform-scale-x: 1.05;
    --transform-scale-y: 1.05;
  }

  .lg\:tw-scale-110 {
    --transform-scale-x: 1.1;
    --transform-scale-y: 1.1;
  }

  .lg\:tw-scale-125 {
    --transform-scale-x: 1.25;
    --transform-scale-y: 1.25;
  }

  .lg\:tw-scale-150 {
    --transform-scale-x: 1.5;
    --transform-scale-y: 1.5;
  }

  .lg\:tw-scale-x-0 {
    --transform-scale-x: 0;
  }

  .lg\:tw-scale-x-50 {
    --transform-scale-x: .5;
  }

  .lg\:tw-scale-x-75 {
    --transform-scale-x: .75;
  }

  .lg\:tw-scale-x-90 {
    --transform-scale-x: .9;
  }

  .lg\:tw-scale-x-95 {
    --transform-scale-x: .95;
  }

  .lg\:tw-scale-x-100 {
    --transform-scale-x: 1;
  }

  .lg\:tw-scale-x-105 {
    --transform-scale-x: 1.05;
  }

  .lg\:tw-scale-x-110 {
    --transform-scale-x: 1.1;
  }

  .lg\:tw-scale-x-125 {
    --transform-scale-x: 1.25;
  }

  .lg\:tw-scale-x-150 {
    --transform-scale-x: 1.5;
  }

  .lg\:tw-scale-y-0 {
    --transform-scale-y: 0;
  }

  .lg\:tw-scale-y-50 {
    --transform-scale-y: .5;
  }

  .lg\:tw-scale-y-75 {
    --transform-scale-y: .75;
  }

  .lg\:tw-scale-y-90 {
    --transform-scale-y: .9;
  }

  .lg\:tw-scale-y-95 {
    --transform-scale-y: .95;
  }

  .lg\:tw-scale-y-100 {
    --transform-scale-y: 1;
  }

  .lg\:tw-scale-y-105 {
    --transform-scale-y: 1.05;
  }

  .lg\:tw-scale-y-110 {
    --transform-scale-y: 1.1;
  }

  .lg\:tw-scale-y-125 {
    --transform-scale-y: 1.25;
  }

  .lg\:tw-scale-y-150 {
    --transform-scale-y: 1.5;
  }

  .lg\:hover\:tw-scale-0:hover {
    --transform-scale-x: 0;
    --transform-scale-y: 0;
  }

  .lg\:hover\:tw-scale-50:hover {
    --transform-scale-x: .5;
    --transform-scale-y: .5;
  }

  .lg\:hover\:tw-scale-75:hover {
    --transform-scale-x: .75;
    --transform-scale-y: .75;
  }

  .lg\:hover\:tw-scale-90:hover {
    --transform-scale-x: .9;
    --transform-scale-y: .9;
  }

  .lg\:hover\:tw-scale-95:hover {
    --transform-scale-x: .95;
    --transform-scale-y: .95;
  }

  .lg\:hover\:tw-scale-100:hover {
    --transform-scale-x: 1;
    --transform-scale-y: 1;
  }

  .lg\:hover\:tw-scale-105:hover {
    --transform-scale-x: 1.05;
    --transform-scale-y: 1.05;
  }

  .lg\:hover\:tw-scale-110:hover {
    --transform-scale-x: 1.1;
    --transform-scale-y: 1.1;
  }

  .lg\:hover\:tw-scale-125:hover {
    --transform-scale-x: 1.25;
    --transform-scale-y: 1.25;
  }

  .lg\:hover\:tw-scale-150:hover {
    --transform-scale-x: 1.5;
    --transform-scale-y: 1.5;
  }

  .lg\:hover\:tw-scale-x-0:hover {
    --transform-scale-x: 0;
  }

  .lg\:hover\:tw-scale-x-50:hover {
    --transform-scale-x: .5;
  }

  .lg\:hover\:tw-scale-x-75:hover {
    --transform-scale-x: .75;
  }

  .lg\:hover\:tw-scale-x-90:hover {
    --transform-scale-x: .9;
  }

  .lg\:hover\:tw-scale-x-95:hover {
    --transform-scale-x: .95;
  }

  .lg\:hover\:tw-scale-x-100:hover {
    --transform-scale-x: 1;
  }

  .lg\:hover\:tw-scale-x-105:hover {
    --transform-scale-x: 1.05;
  }

  .lg\:hover\:tw-scale-x-110:hover {
    --transform-scale-x: 1.1;
  }

  .lg\:hover\:tw-scale-x-125:hover {
    --transform-scale-x: 1.25;
  }

  .lg\:hover\:tw-scale-x-150:hover {
    --transform-scale-x: 1.5;
  }

  .lg\:hover\:tw-scale-y-0:hover {
    --transform-scale-y: 0;
  }

  .lg\:hover\:tw-scale-y-50:hover {
    --transform-scale-y: .5;
  }

  .lg\:hover\:tw-scale-y-75:hover {
    --transform-scale-y: .75;
  }

  .lg\:hover\:tw-scale-y-90:hover {
    --transform-scale-y: .9;
  }

  .lg\:hover\:tw-scale-y-95:hover {
    --transform-scale-y: .95;
  }

  .lg\:hover\:tw-scale-y-100:hover {
    --transform-scale-y: 1;
  }

  .lg\:hover\:tw-scale-y-105:hover {
    --transform-scale-y: 1.05;
  }

  .lg\:hover\:tw-scale-y-110:hover {
    --transform-scale-y: 1.1;
  }

  .lg\:hover\:tw-scale-y-125:hover {
    --transform-scale-y: 1.25;
  }

  .lg\:hover\:tw-scale-y-150:hover {
    --transform-scale-y: 1.5;
  }

  .lg\:focus\:tw-scale-0:focus {
    --transform-scale-x: 0;
    --transform-scale-y: 0;
  }

  .lg\:focus\:tw-scale-50:focus {
    --transform-scale-x: .5;
    --transform-scale-y: .5;
  }

  .lg\:focus\:tw-scale-75:focus {
    --transform-scale-x: .75;
    --transform-scale-y: .75;
  }

  .lg\:focus\:tw-scale-90:focus {
    --transform-scale-x: .9;
    --transform-scale-y: .9;
  }

  .lg\:focus\:tw-scale-95:focus {
    --transform-scale-x: .95;
    --transform-scale-y: .95;
  }

  .lg\:focus\:tw-scale-100:focus {
    --transform-scale-x: 1;
    --transform-scale-y: 1;
  }

  .lg\:focus\:tw-scale-105:focus {
    --transform-scale-x: 1.05;
    --transform-scale-y: 1.05;
  }

  .lg\:focus\:tw-scale-110:focus {
    --transform-scale-x: 1.1;
    --transform-scale-y: 1.1;
  }

  .lg\:focus\:tw-scale-125:focus {
    --transform-scale-x: 1.25;
    --transform-scale-y: 1.25;
  }

  .lg\:focus\:tw-scale-150:focus {
    --transform-scale-x: 1.5;
    --transform-scale-y: 1.5;
  }

  .lg\:focus\:tw-scale-x-0:focus {
    --transform-scale-x: 0;
  }

  .lg\:focus\:tw-scale-x-50:focus {
    --transform-scale-x: .5;
  }

  .lg\:focus\:tw-scale-x-75:focus {
    --transform-scale-x: .75;
  }

  .lg\:focus\:tw-scale-x-90:focus {
    --transform-scale-x: .9;
  }

  .lg\:focus\:tw-scale-x-95:focus {
    --transform-scale-x: .95;
  }

  .lg\:focus\:tw-scale-x-100:focus {
    --transform-scale-x: 1;
  }

  .lg\:focus\:tw-scale-x-105:focus {
    --transform-scale-x: 1.05;
  }

  .lg\:focus\:tw-scale-x-110:focus {
    --transform-scale-x: 1.1;
  }

  .lg\:focus\:tw-scale-x-125:focus {
    --transform-scale-x: 1.25;
  }

  .lg\:focus\:tw-scale-x-150:focus {
    --transform-scale-x: 1.5;
  }

  .lg\:focus\:tw-scale-y-0:focus {
    --transform-scale-y: 0;
  }

  .lg\:focus\:tw-scale-y-50:focus {
    --transform-scale-y: .5;
  }

  .lg\:focus\:tw-scale-y-75:focus {
    --transform-scale-y: .75;
  }

  .lg\:focus\:tw-scale-y-90:focus {
    --transform-scale-y: .9;
  }

  .lg\:focus\:tw-scale-y-95:focus {
    --transform-scale-y: .95;
  }

  .lg\:focus\:tw-scale-y-100:focus {
    --transform-scale-y: 1;
  }

  .lg\:focus\:tw-scale-y-105:focus {
    --transform-scale-y: 1.05;
  }

  .lg\:focus\:tw-scale-y-110:focus {
    --transform-scale-y: 1.1;
  }

  .lg\:focus\:tw-scale-y-125:focus {
    --transform-scale-y: 1.25;
  }

  .lg\:focus\:tw-scale-y-150:focus {
    --transform-scale-y: 1.5;
  }

  .lg\:tw-rotate-0 {
    --transform-rotate: 0;
  }

  .lg\:tw-rotate-1 {
    --transform-rotate: 1deg;
  }

  .lg\:tw-rotate-2 {
    --transform-rotate: 2deg;
  }

  .lg\:tw-rotate-3 {
    --transform-rotate: 3deg;
  }

  .lg\:tw-rotate-6 {
    --transform-rotate: 6deg;
  }

  .lg\:tw-rotate-12 {
    --transform-rotate: 12deg;
  }

  .lg\:tw-rotate-45 {
    --transform-rotate: 45deg;
  }

  .lg\:tw-rotate-90 {
    --transform-rotate: 90deg;
  }

  .lg\:tw-rotate-180 {
    --transform-rotate: 180deg;
  }

  .lg\:tw--rotate-180 {
    --transform-rotate: -180deg;
  }

  .lg\:tw--rotate-90 {
    --transform-rotate: -90deg;
  }

  .lg\:tw--rotate-45 {
    --transform-rotate: -45deg;
  }

  .lg\:tw--rotate-12 {
    --transform-rotate: -12deg;
  }

  .lg\:tw--rotate-6 {
    --transform-rotate: -6deg;
  }

  .lg\:tw--rotate-3 {
    --transform-rotate: -3deg;
  }

  .lg\:tw--rotate-2 {
    --transform-rotate: -2deg;
  }

  .lg\:tw--rotate-1 {
    --transform-rotate: -1deg;
  }

  .lg\:hover\:tw-rotate-0:hover {
    --transform-rotate: 0;
  }

  .lg\:hover\:tw-rotate-1:hover {
    --transform-rotate: 1deg;
  }

  .lg\:hover\:tw-rotate-2:hover {
    --transform-rotate: 2deg;
  }

  .lg\:hover\:tw-rotate-3:hover {
    --transform-rotate: 3deg;
  }

  .lg\:hover\:tw-rotate-6:hover {
    --transform-rotate: 6deg;
  }

  .lg\:hover\:tw-rotate-12:hover {
    --transform-rotate: 12deg;
  }

  .lg\:hover\:tw-rotate-45:hover {
    --transform-rotate: 45deg;
  }

  .lg\:hover\:tw-rotate-90:hover {
    --transform-rotate: 90deg;
  }

  .lg\:hover\:tw-rotate-180:hover {
    --transform-rotate: 180deg;
  }

  .lg\:hover\:tw--rotate-180:hover {
    --transform-rotate: -180deg;
  }

  .lg\:hover\:tw--rotate-90:hover {
    --transform-rotate: -90deg;
  }

  .lg\:hover\:tw--rotate-45:hover {
    --transform-rotate: -45deg;
  }

  .lg\:hover\:tw--rotate-12:hover {
    --transform-rotate: -12deg;
  }

  .lg\:hover\:tw--rotate-6:hover {
    --transform-rotate: -6deg;
  }

  .lg\:hover\:tw--rotate-3:hover {
    --transform-rotate: -3deg;
  }

  .lg\:hover\:tw--rotate-2:hover {
    --transform-rotate: -2deg;
  }

  .lg\:hover\:tw--rotate-1:hover {
    --transform-rotate: -1deg;
  }

  .lg\:focus\:tw-rotate-0:focus {
    --transform-rotate: 0;
  }

  .lg\:focus\:tw-rotate-1:focus {
    --transform-rotate: 1deg;
  }

  .lg\:focus\:tw-rotate-2:focus {
    --transform-rotate: 2deg;
  }

  .lg\:focus\:tw-rotate-3:focus {
    --transform-rotate: 3deg;
  }

  .lg\:focus\:tw-rotate-6:focus {
    --transform-rotate: 6deg;
  }

  .lg\:focus\:tw-rotate-12:focus {
    --transform-rotate: 12deg;
  }

  .lg\:focus\:tw-rotate-45:focus {
    --transform-rotate: 45deg;
  }

  .lg\:focus\:tw-rotate-90:focus {
    --transform-rotate: 90deg;
  }

  .lg\:focus\:tw-rotate-180:focus {
    --transform-rotate: 180deg;
  }

  .lg\:focus\:tw--rotate-180:focus {
    --transform-rotate: -180deg;
  }

  .lg\:focus\:tw--rotate-90:focus {
    --transform-rotate: -90deg;
  }

  .lg\:focus\:tw--rotate-45:focus {
    --transform-rotate: -45deg;
  }

  .lg\:focus\:tw--rotate-12:focus {
    --transform-rotate: -12deg;
  }

  .lg\:focus\:tw--rotate-6:focus {
    --transform-rotate: -6deg;
  }

  .lg\:focus\:tw--rotate-3:focus {
    --transform-rotate: -3deg;
  }

  .lg\:focus\:tw--rotate-2:focus {
    --transform-rotate: -2deg;
  }

  .lg\:focus\:tw--rotate-1:focus {
    --transform-rotate: -1deg;
  }

  .lg\:tw-translate-x-0 {
    --transform-translate-x: 0;
  }

  .lg\:tw-translate-x-1 {
    --transform-translate-x: 0.25rem;
  }

  .lg\:tw-translate-x-2 {
    --transform-translate-x: 0.5rem;
  }

  .lg\:tw-translate-x-3 {
    --transform-translate-x: 0.75rem;
  }

  .lg\:tw-translate-x-4 {
    --transform-translate-x: 1rem;
  }

  .lg\:tw-translate-x-5 {
    --transform-translate-x: 1.25rem;
  }

  .lg\:tw-translate-x-6 {
    --transform-translate-x: 1.5rem;
  }

  .lg\:tw-translate-x-8 {
    --transform-translate-x: 2rem;
  }

  .lg\:tw-translate-x-10 {
    --transform-translate-x: 2.5rem;
  }

  .lg\:tw-translate-x-12 {
    --transform-translate-x: 3rem;
  }

  .lg\:tw-translate-x-16 {
    --transform-translate-x: 4rem;
  }

  .lg\:tw-translate-x-20 {
    --transform-translate-x: 5rem;
  }

  .lg\:tw-translate-x-24 {
    --transform-translate-x: 6rem;
  }

  .lg\:tw-translate-x-32 {
    --transform-translate-x: 8rem;
  }

  .lg\:tw-translate-x-40 {
    --transform-translate-x: 10rem;
  }

  .lg\:tw-translate-x-48 {
    --transform-translate-x: 12rem;
  }

  .lg\:tw-translate-x-56 {
    --transform-translate-x: 14rem;
  }

  .lg\:tw-translate-x-64 {
    --transform-translate-x: 16rem;
  }

  .lg\:tw-translate-x-px {
    --transform-translate-x: 1px;
  }

  .lg\:tw-translate-x-270px {
    --transform-translate-x: 270px;
  }

  .lg\:tw-translate-x-400px {
    --transform-translate-x: 400px;
  }

  .lg\:tw-translate-x-464px {
    --transform-translate-x: 464px;
  }

  .lg\:tw-translate-x-480px {
    --transform-translate-x: 480px;
  }

  .lg\:tw-translate-x-500px {
    --transform-translate-x: 500px;
  }

  .lg\:tw-translate-x-576px {
    --transform-translate-x: 576px;
  }

  .lg\:tw-translate-x-600px {
    --transform-translate-x: 600px;
  }

  .lg\:tw-translate-x-672px {
    --transform-translate-x: 672px;
  }

  .lg\:tw--translate-x-1 {
    --transform-translate-x: -0.25rem;
  }

  .lg\:tw--translate-x-2 {
    --transform-translate-x: -0.5rem;
  }

  .lg\:tw--translate-x-3 {
    --transform-translate-x: -0.75rem;
  }

  .lg\:tw--translate-x-4 {
    --transform-translate-x: -1rem;
  }

  .lg\:tw--translate-x-5 {
    --transform-translate-x: -1.25rem;
  }

  .lg\:tw--translate-x-6 {
    --transform-translate-x: -1.5rem;
  }

  .lg\:tw--translate-x-8 {
    --transform-translate-x: -2rem;
  }

  .lg\:tw--translate-x-10 {
    --transform-translate-x: -2.5rem;
  }

  .lg\:tw--translate-x-12 {
    --transform-translate-x: -3rem;
  }

  .lg\:tw--translate-x-16 {
    --transform-translate-x: -4rem;
  }

  .lg\:tw--translate-x-20 {
    --transform-translate-x: -5rem;
  }

  .lg\:tw--translate-x-24 {
    --transform-translate-x: -6rem;
  }

  .lg\:tw--translate-x-32 {
    --transform-translate-x: -8rem;
  }

  .lg\:tw--translate-x-40 {
    --transform-translate-x: -10rem;
  }

  .lg\:tw--translate-x-48 {
    --transform-translate-x: -12rem;
  }

  .lg\:tw--translate-x-56 {
    --transform-translate-x: -14rem;
  }

  .lg\:tw--translate-x-64 {
    --transform-translate-x: -16rem;
  }

  .lg\:tw--translate-x-px {
    --transform-translate-x: -1px;
  }

  .lg\:tw--translate-x-270px {
    --transform-translate-x: -270px;
  }

  .lg\:tw--translate-x-400px {
    --transform-translate-x: -400px;
  }

  .lg\:tw--translate-x-464px {
    --transform-translate-x: -464px;
  }

  .lg\:tw--translate-x-480px {
    --transform-translate-x: -480px;
  }

  .lg\:tw--translate-x-500px {
    --transform-translate-x: -500px;
  }

  .lg\:tw--translate-x-576px {
    --transform-translate-x: -576px;
  }

  .lg\:tw--translate-x-600px {
    --transform-translate-x: -600px;
  }

  .lg\:tw--translate-x-672px {
    --transform-translate-x: -672px;
  }

  .lg\:tw--translate-x-full {
    --transform-translate-x: -100%;
  }

  .lg\:tw--translate-x-1\/2 {
    --transform-translate-x: -50%;
  }

  .lg\:tw-translate-x-1\/2 {
    --transform-translate-x: 50%;
  }

  .lg\:tw-translate-x-full {
    --transform-translate-x: 100%;
  }

  .lg\:tw-translate-y-0 {
    --transform-translate-y: 0;
  }

  .lg\:tw-translate-y-1 {
    --transform-translate-y: 0.25rem;
  }

  .lg\:tw-translate-y-2 {
    --transform-translate-y: 0.5rem;
  }

  .lg\:tw-translate-y-3 {
    --transform-translate-y: 0.75rem;
  }

  .lg\:tw-translate-y-4 {
    --transform-translate-y: 1rem;
  }

  .lg\:tw-translate-y-5 {
    --transform-translate-y: 1.25rem;
  }

  .lg\:tw-translate-y-6 {
    --transform-translate-y: 1.5rem;
  }

  .lg\:tw-translate-y-8 {
    --transform-translate-y: 2rem;
  }

  .lg\:tw-translate-y-10 {
    --transform-translate-y: 2.5rem;
  }

  .lg\:tw-translate-y-12 {
    --transform-translate-y: 3rem;
  }

  .lg\:tw-translate-y-16 {
    --transform-translate-y: 4rem;
  }

  .lg\:tw-translate-y-20 {
    --transform-translate-y: 5rem;
  }

  .lg\:tw-translate-y-24 {
    --transform-translate-y: 6rem;
  }

  .lg\:tw-translate-y-32 {
    --transform-translate-y: 8rem;
  }

  .lg\:tw-translate-y-40 {
    --transform-translate-y: 10rem;
  }

  .lg\:tw-translate-y-48 {
    --transform-translate-y: 12rem;
  }

  .lg\:tw-translate-y-56 {
    --transform-translate-y: 14rem;
  }

  .lg\:tw-translate-y-64 {
    --transform-translate-y: 16rem;
  }

  .lg\:tw-translate-y-px {
    --transform-translate-y: 1px;
  }

  .lg\:tw-translate-y-270px {
    --transform-translate-y: 270px;
  }

  .lg\:tw-translate-y-400px {
    --transform-translate-y: 400px;
  }

  .lg\:tw-translate-y-464px {
    --transform-translate-y: 464px;
  }

  .lg\:tw-translate-y-480px {
    --transform-translate-y: 480px;
  }

  .lg\:tw-translate-y-500px {
    --transform-translate-y: 500px;
  }

  .lg\:tw-translate-y-576px {
    --transform-translate-y: 576px;
  }

  .lg\:tw-translate-y-600px {
    --transform-translate-y: 600px;
  }

  .lg\:tw-translate-y-672px {
    --transform-translate-y: 672px;
  }

  .lg\:tw--translate-y-1 {
    --transform-translate-y: -0.25rem;
  }

  .lg\:tw--translate-y-2 {
    --transform-translate-y: -0.5rem;
  }

  .lg\:tw--translate-y-3 {
    --transform-translate-y: -0.75rem;
  }

  .lg\:tw--translate-y-4 {
    --transform-translate-y: -1rem;
  }

  .lg\:tw--translate-y-5 {
    --transform-translate-y: -1.25rem;
  }

  .lg\:tw--translate-y-6 {
    --transform-translate-y: -1.5rem;
  }

  .lg\:tw--translate-y-8 {
    --transform-translate-y: -2rem;
  }

  .lg\:tw--translate-y-10 {
    --transform-translate-y: -2.5rem;
  }

  .lg\:tw--translate-y-12 {
    --transform-translate-y: -3rem;
  }

  .lg\:tw--translate-y-16 {
    --transform-translate-y: -4rem;
  }

  .lg\:tw--translate-y-20 {
    --transform-translate-y: -5rem;
  }

  .lg\:tw--translate-y-24 {
    --transform-translate-y: -6rem;
  }

  .lg\:tw--translate-y-32 {
    --transform-translate-y: -8rem;
  }

  .lg\:tw--translate-y-40 {
    --transform-translate-y: -10rem;
  }

  .lg\:tw--translate-y-48 {
    --transform-translate-y: -12rem;
  }

  .lg\:tw--translate-y-56 {
    --transform-translate-y: -14rem;
  }

  .lg\:tw--translate-y-64 {
    --transform-translate-y: -16rem;
  }

  .lg\:tw--translate-y-px {
    --transform-translate-y: -1px;
  }

  .lg\:tw--translate-y-270px {
    --transform-translate-y: -270px;
  }

  .lg\:tw--translate-y-400px {
    --transform-translate-y: -400px;
  }

  .lg\:tw--translate-y-464px {
    --transform-translate-y: -464px;
  }

  .lg\:tw--translate-y-480px {
    --transform-translate-y: -480px;
  }

  .lg\:tw--translate-y-500px {
    --transform-translate-y: -500px;
  }

  .lg\:tw--translate-y-576px {
    --transform-translate-y: -576px;
  }

  .lg\:tw--translate-y-600px {
    --transform-translate-y: -600px;
  }

  .lg\:tw--translate-y-672px {
    --transform-translate-y: -672px;
  }

  .lg\:tw--translate-y-full {
    --transform-translate-y: -100%;
  }

  .lg\:tw--translate-y-1\/2 {
    --transform-translate-y: -50%;
  }

  .lg\:tw-translate-y-1\/2 {
    --transform-translate-y: 50%;
  }

  .lg\:tw-translate-y-full {
    --transform-translate-y: 100%;
  }

  .lg\:hover\:tw-translate-x-0:hover {
    --transform-translate-x: 0;
  }

  .lg\:hover\:tw-translate-x-1:hover {
    --transform-translate-x: 0.25rem;
  }

  .lg\:hover\:tw-translate-x-2:hover {
    --transform-translate-x: 0.5rem;
  }

  .lg\:hover\:tw-translate-x-3:hover {
    --transform-translate-x: 0.75rem;
  }

  .lg\:hover\:tw-translate-x-4:hover {
    --transform-translate-x: 1rem;
  }

  .lg\:hover\:tw-translate-x-5:hover {
    --transform-translate-x: 1.25rem;
  }

  .lg\:hover\:tw-translate-x-6:hover {
    --transform-translate-x: 1.5rem;
  }

  .lg\:hover\:tw-translate-x-8:hover {
    --transform-translate-x: 2rem;
  }

  .lg\:hover\:tw-translate-x-10:hover {
    --transform-translate-x: 2.5rem;
  }

  .lg\:hover\:tw-translate-x-12:hover {
    --transform-translate-x: 3rem;
  }

  .lg\:hover\:tw-translate-x-16:hover {
    --transform-translate-x: 4rem;
  }

  .lg\:hover\:tw-translate-x-20:hover {
    --transform-translate-x: 5rem;
  }

  .lg\:hover\:tw-translate-x-24:hover {
    --transform-translate-x: 6rem;
  }

  .lg\:hover\:tw-translate-x-32:hover {
    --transform-translate-x: 8rem;
  }

  .lg\:hover\:tw-translate-x-40:hover {
    --transform-translate-x: 10rem;
  }

  .lg\:hover\:tw-translate-x-48:hover {
    --transform-translate-x: 12rem;
  }

  .lg\:hover\:tw-translate-x-56:hover {
    --transform-translate-x: 14rem;
  }

  .lg\:hover\:tw-translate-x-64:hover {
    --transform-translate-x: 16rem;
  }

  .lg\:hover\:tw-translate-x-px:hover {
    --transform-translate-x: 1px;
  }

  .lg\:hover\:tw-translate-x-270px:hover {
    --transform-translate-x: 270px;
  }

  .lg\:hover\:tw-translate-x-400px:hover {
    --transform-translate-x: 400px;
  }

  .lg\:hover\:tw-translate-x-464px:hover {
    --transform-translate-x: 464px;
  }

  .lg\:hover\:tw-translate-x-480px:hover {
    --transform-translate-x: 480px;
  }

  .lg\:hover\:tw-translate-x-500px:hover {
    --transform-translate-x: 500px;
  }

  .lg\:hover\:tw-translate-x-576px:hover {
    --transform-translate-x: 576px;
  }

  .lg\:hover\:tw-translate-x-600px:hover {
    --transform-translate-x: 600px;
  }

  .lg\:hover\:tw-translate-x-672px:hover {
    --transform-translate-x: 672px;
  }

  .lg\:hover\:tw--translate-x-1:hover {
    --transform-translate-x: -0.25rem;
  }

  .lg\:hover\:tw--translate-x-2:hover {
    --transform-translate-x: -0.5rem;
  }

  .lg\:hover\:tw--translate-x-3:hover {
    --transform-translate-x: -0.75rem;
  }

  .lg\:hover\:tw--translate-x-4:hover {
    --transform-translate-x: -1rem;
  }

  .lg\:hover\:tw--translate-x-5:hover {
    --transform-translate-x: -1.25rem;
  }

  .lg\:hover\:tw--translate-x-6:hover {
    --transform-translate-x: -1.5rem;
  }

  .lg\:hover\:tw--translate-x-8:hover {
    --transform-translate-x: -2rem;
  }

  .lg\:hover\:tw--translate-x-10:hover {
    --transform-translate-x: -2.5rem;
  }

  .lg\:hover\:tw--translate-x-12:hover {
    --transform-translate-x: -3rem;
  }

  .lg\:hover\:tw--translate-x-16:hover {
    --transform-translate-x: -4rem;
  }

  .lg\:hover\:tw--translate-x-20:hover {
    --transform-translate-x: -5rem;
  }

  .lg\:hover\:tw--translate-x-24:hover {
    --transform-translate-x: -6rem;
  }

  .lg\:hover\:tw--translate-x-32:hover {
    --transform-translate-x: -8rem;
  }

  .lg\:hover\:tw--translate-x-40:hover {
    --transform-translate-x: -10rem;
  }

  .lg\:hover\:tw--translate-x-48:hover {
    --transform-translate-x: -12rem;
  }

  .lg\:hover\:tw--translate-x-56:hover {
    --transform-translate-x: -14rem;
  }

  .lg\:hover\:tw--translate-x-64:hover {
    --transform-translate-x: -16rem;
  }

  .lg\:hover\:tw--translate-x-px:hover {
    --transform-translate-x: -1px;
  }

  .lg\:hover\:tw--translate-x-270px:hover {
    --transform-translate-x: -270px;
  }

  .lg\:hover\:tw--translate-x-400px:hover {
    --transform-translate-x: -400px;
  }

  .lg\:hover\:tw--translate-x-464px:hover {
    --transform-translate-x: -464px;
  }

  .lg\:hover\:tw--translate-x-480px:hover {
    --transform-translate-x: -480px;
  }

  .lg\:hover\:tw--translate-x-500px:hover {
    --transform-translate-x: -500px;
  }

  .lg\:hover\:tw--translate-x-576px:hover {
    --transform-translate-x: -576px;
  }

  .lg\:hover\:tw--translate-x-600px:hover {
    --transform-translate-x: -600px;
  }

  .lg\:hover\:tw--translate-x-672px:hover {
    --transform-translate-x: -672px;
  }

  .lg\:hover\:tw--translate-x-full:hover {
    --transform-translate-x: -100%;
  }

  .lg\:hover\:tw--translate-x-1\/2:hover {
    --transform-translate-x: -50%;
  }

  .lg\:hover\:tw-translate-x-1\/2:hover {
    --transform-translate-x: 50%;
  }

  .lg\:hover\:tw-translate-x-full:hover {
    --transform-translate-x: 100%;
  }

  .lg\:hover\:tw-translate-y-0:hover {
    --transform-translate-y: 0;
  }

  .lg\:hover\:tw-translate-y-1:hover {
    --transform-translate-y: 0.25rem;
  }

  .lg\:hover\:tw-translate-y-2:hover {
    --transform-translate-y: 0.5rem;
  }

  .lg\:hover\:tw-translate-y-3:hover {
    --transform-translate-y: 0.75rem;
  }

  .lg\:hover\:tw-translate-y-4:hover {
    --transform-translate-y: 1rem;
  }

  .lg\:hover\:tw-translate-y-5:hover {
    --transform-translate-y: 1.25rem;
  }

  .lg\:hover\:tw-translate-y-6:hover {
    --transform-translate-y: 1.5rem;
  }

  .lg\:hover\:tw-translate-y-8:hover {
    --transform-translate-y: 2rem;
  }

  .lg\:hover\:tw-translate-y-10:hover {
    --transform-translate-y: 2.5rem;
  }

  .lg\:hover\:tw-translate-y-12:hover {
    --transform-translate-y: 3rem;
  }

  .lg\:hover\:tw-translate-y-16:hover {
    --transform-translate-y: 4rem;
  }

  .lg\:hover\:tw-translate-y-20:hover {
    --transform-translate-y: 5rem;
  }

  .lg\:hover\:tw-translate-y-24:hover {
    --transform-translate-y: 6rem;
  }

  .lg\:hover\:tw-translate-y-32:hover {
    --transform-translate-y: 8rem;
  }

  .lg\:hover\:tw-translate-y-40:hover {
    --transform-translate-y: 10rem;
  }

  .lg\:hover\:tw-translate-y-48:hover {
    --transform-translate-y: 12rem;
  }

  .lg\:hover\:tw-translate-y-56:hover {
    --transform-translate-y: 14rem;
  }

  .lg\:hover\:tw-translate-y-64:hover {
    --transform-translate-y: 16rem;
  }

  .lg\:hover\:tw-translate-y-px:hover {
    --transform-translate-y: 1px;
  }

  .lg\:hover\:tw-translate-y-270px:hover {
    --transform-translate-y: 270px;
  }

  .lg\:hover\:tw-translate-y-400px:hover {
    --transform-translate-y: 400px;
  }

  .lg\:hover\:tw-translate-y-464px:hover {
    --transform-translate-y: 464px;
  }

  .lg\:hover\:tw-translate-y-480px:hover {
    --transform-translate-y: 480px;
  }

  .lg\:hover\:tw-translate-y-500px:hover {
    --transform-translate-y: 500px;
  }

  .lg\:hover\:tw-translate-y-576px:hover {
    --transform-translate-y: 576px;
  }

  .lg\:hover\:tw-translate-y-600px:hover {
    --transform-translate-y: 600px;
  }

  .lg\:hover\:tw-translate-y-672px:hover {
    --transform-translate-y: 672px;
  }

  .lg\:hover\:tw--translate-y-1:hover {
    --transform-translate-y: -0.25rem;
  }

  .lg\:hover\:tw--translate-y-2:hover {
    --transform-translate-y: -0.5rem;
  }

  .lg\:hover\:tw--translate-y-3:hover {
    --transform-translate-y: -0.75rem;
  }

  .lg\:hover\:tw--translate-y-4:hover {
    --transform-translate-y: -1rem;
  }

  .lg\:hover\:tw--translate-y-5:hover {
    --transform-translate-y: -1.25rem;
  }

  .lg\:hover\:tw--translate-y-6:hover {
    --transform-translate-y: -1.5rem;
  }

  .lg\:hover\:tw--translate-y-8:hover {
    --transform-translate-y: -2rem;
  }

  .lg\:hover\:tw--translate-y-10:hover {
    --transform-translate-y: -2.5rem;
  }

  .lg\:hover\:tw--translate-y-12:hover {
    --transform-translate-y: -3rem;
  }

  .lg\:hover\:tw--translate-y-16:hover {
    --transform-translate-y: -4rem;
  }

  .lg\:hover\:tw--translate-y-20:hover {
    --transform-translate-y: -5rem;
  }

  .lg\:hover\:tw--translate-y-24:hover {
    --transform-translate-y: -6rem;
  }

  .lg\:hover\:tw--translate-y-32:hover {
    --transform-translate-y: -8rem;
  }

  .lg\:hover\:tw--translate-y-40:hover {
    --transform-translate-y: -10rem;
  }

  .lg\:hover\:tw--translate-y-48:hover {
    --transform-translate-y: -12rem;
  }

  .lg\:hover\:tw--translate-y-56:hover {
    --transform-translate-y: -14rem;
  }

  .lg\:hover\:tw--translate-y-64:hover {
    --transform-translate-y: -16rem;
  }

  .lg\:hover\:tw--translate-y-px:hover {
    --transform-translate-y: -1px;
  }

  .lg\:hover\:tw--translate-y-270px:hover {
    --transform-translate-y: -270px;
  }

  .lg\:hover\:tw--translate-y-400px:hover {
    --transform-translate-y: -400px;
  }

  .lg\:hover\:tw--translate-y-464px:hover {
    --transform-translate-y: -464px;
  }

  .lg\:hover\:tw--translate-y-480px:hover {
    --transform-translate-y: -480px;
  }

  .lg\:hover\:tw--translate-y-500px:hover {
    --transform-translate-y: -500px;
  }

  .lg\:hover\:tw--translate-y-576px:hover {
    --transform-translate-y: -576px;
  }

  .lg\:hover\:tw--translate-y-600px:hover {
    --transform-translate-y: -600px;
  }

  .lg\:hover\:tw--translate-y-672px:hover {
    --transform-translate-y: -672px;
  }

  .lg\:hover\:tw--translate-y-full:hover {
    --transform-translate-y: -100%;
  }

  .lg\:hover\:tw--translate-y-1\/2:hover {
    --transform-translate-y: -50%;
  }

  .lg\:hover\:tw-translate-y-1\/2:hover {
    --transform-translate-y: 50%;
  }

  .lg\:hover\:tw-translate-y-full:hover {
    --transform-translate-y: 100%;
  }

  .lg\:focus\:tw-translate-x-0:focus {
    --transform-translate-x: 0;
  }

  .lg\:focus\:tw-translate-x-1:focus {
    --transform-translate-x: 0.25rem;
  }

  .lg\:focus\:tw-translate-x-2:focus {
    --transform-translate-x: 0.5rem;
  }

  .lg\:focus\:tw-translate-x-3:focus {
    --transform-translate-x: 0.75rem;
  }

  .lg\:focus\:tw-translate-x-4:focus {
    --transform-translate-x: 1rem;
  }

  .lg\:focus\:tw-translate-x-5:focus {
    --transform-translate-x: 1.25rem;
  }

  .lg\:focus\:tw-translate-x-6:focus {
    --transform-translate-x: 1.5rem;
  }

  .lg\:focus\:tw-translate-x-8:focus {
    --transform-translate-x: 2rem;
  }

  .lg\:focus\:tw-translate-x-10:focus {
    --transform-translate-x: 2.5rem;
  }

  .lg\:focus\:tw-translate-x-12:focus {
    --transform-translate-x: 3rem;
  }

  .lg\:focus\:tw-translate-x-16:focus {
    --transform-translate-x: 4rem;
  }

  .lg\:focus\:tw-translate-x-20:focus {
    --transform-translate-x: 5rem;
  }

  .lg\:focus\:tw-translate-x-24:focus {
    --transform-translate-x: 6rem;
  }

  .lg\:focus\:tw-translate-x-32:focus {
    --transform-translate-x: 8rem;
  }

  .lg\:focus\:tw-translate-x-40:focus {
    --transform-translate-x: 10rem;
  }

  .lg\:focus\:tw-translate-x-48:focus {
    --transform-translate-x: 12rem;
  }

  .lg\:focus\:tw-translate-x-56:focus {
    --transform-translate-x: 14rem;
  }

  .lg\:focus\:tw-translate-x-64:focus {
    --transform-translate-x: 16rem;
  }

  .lg\:focus\:tw-translate-x-px:focus {
    --transform-translate-x: 1px;
  }

  .lg\:focus\:tw-translate-x-270px:focus {
    --transform-translate-x: 270px;
  }

  .lg\:focus\:tw-translate-x-400px:focus {
    --transform-translate-x: 400px;
  }

  .lg\:focus\:tw-translate-x-464px:focus {
    --transform-translate-x: 464px;
  }

  .lg\:focus\:tw-translate-x-480px:focus {
    --transform-translate-x: 480px;
  }

  .lg\:focus\:tw-translate-x-500px:focus {
    --transform-translate-x: 500px;
  }

  .lg\:focus\:tw-translate-x-576px:focus {
    --transform-translate-x: 576px;
  }

  .lg\:focus\:tw-translate-x-600px:focus {
    --transform-translate-x: 600px;
  }

  .lg\:focus\:tw-translate-x-672px:focus {
    --transform-translate-x: 672px;
  }

  .lg\:focus\:tw--translate-x-1:focus {
    --transform-translate-x: -0.25rem;
  }

  .lg\:focus\:tw--translate-x-2:focus {
    --transform-translate-x: -0.5rem;
  }

  .lg\:focus\:tw--translate-x-3:focus {
    --transform-translate-x: -0.75rem;
  }

  .lg\:focus\:tw--translate-x-4:focus {
    --transform-translate-x: -1rem;
  }

  .lg\:focus\:tw--translate-x-5:focus {
    --transform-translate-x: -1.25rem;
  }

  .lg\:focus\:tw--translate-x-6:focus {
    --transform-translate-x: -1.5rem;
  }

  .lg\:focus\:tw--translate-x-8:focus {
    --transform-translate-x: -2rem;
  }

  .lg\:focus\:tw--translate-x-10:focus {
    --transform-translate-x: -2.5rem;
  }

  .lg\:focus\:tw--translate-x-12:focus {
    --transform-translate-x: -3rem;
  }

  .lg\:focus\:tw--translate-x-16:focus {
    --transform-translate-x: -4rem;
  }

  .lg\:focus\:tw--translate-x-20:focus {
    --transform-translate-x: -5rem;
  }

  .lg\:focus\:tw--translate-x-24:focus {
    --transform-translate-x: -6rem;
  }

  .lg\:focus\:tw--translate-x-32:focus {
    --transform-translate-x: -8rem;
  }

  .lg\:focus\:tw--translate-x-40:focus {
    --transform-translate-x: -10rem;
  }

  .lg\:focus\:tw--translate-x-48:focus {
    --transform-translate-x: -12rem;
  }

  .lg\:focus\:tw--translate-x-56:focus {
    --transform-translate-x: -14rem;
  }

  .lg\:focus\:tw--translate-x-64:focus {
    --transform-translate-x: -16rem;
  }

  .lg\:focus\:tw--translate-x-px:focus {
    --transform-translate-x: -1px;
  }

  .lg\:focus\:tw--translate-x-270px:focus {
    --transform-translate-x: -270px;
  }

  .lg\:focus\:tw--translate-x-400px:focus {
    --transform-translate-x: -400px;
  }

  .lg\:focus\:tw--translate-x-464px:focus {
    --transform-translate-x: -464px;
  }

  .lg\:focus\:tw--translate-x-480px:focus {
    --transform-translate-x: -480px;
  }

  .lg\:focus\:tw--translate-x-500px:focus {
    --transform-translate-x: -500px;
  }

  .lg\:focus\:tw--translate-x-576px:focus {
    --transform-translate-x: -576px;
  }

  .lg\:focus\:tw--translate-x-600px:focus {
    --transform-translate-x: -600px;
  }

  .lg\:focus\:tw--translate-x-672px:focus {
    --transform-translate-x: -672px;
  }

  .lg\:focus\:tw--translate-x-full:focus {
    --transform-translate-x: -100%;
  }

  .lg\:focus\:tw--translate-x-1\/2:focus {
    --transform-translate-x: -50%;
  }

  .lg\:focus\:tw-translate-x-1\/2:focus {
    --transform-translate-x: 50%;
  }

  .lg\:focus\:tw-translate-x-full:focus {
    --transform-translate-x: 100%;
  }

  .lg\:focus\:tw-translate-y-0:focus {
    --transform-translate-y: 0;
  }

  .lg\:focus\:tw-translate-y-1:focus {
    --transform-translate-y: 0.25rem;
  }

  .lg\:focus\:tw-translate-y-2:focus {
    --transform-translate-y: 0.5rem;
  }

  .lg\:focus\:tw-translate-y-3:focus {
    --transform-translate-y: 0.75rem;
  }

  .lg\:focus\:tw-translate-y-4:focus {
    --transform-translate-y: 1rem;
  }

  .lg\:focus\:tw-translate-y-5:focus {
    --transform-translate-y: 1.25rem;
  }

  .lg\:focus\:tw-translate-y-6:focus {
    --transform-translate-y: 1.5rem;
  }

  .lg\:focus\:tw-translate-y-8:focus {
    --transform-translate-y: 2rem;
  }

  .lg\:focus\:tw-translate-y-10:focus {
    --transform-translate-y: 2.5rem;
  }

  .lg\:focus\:tw-translate-y-12:focus {
    --transform-translate-y: 3rem;
  }

  .lg\:focus\:tw-translate-y-16:focus {
    --transform-translate-y: 4rem;
  }

  .lg\:focus\:tw-translate-y-20:focus {
    --transform-translate-y: 5rem;
  }

  .lg\:focus\:tw-translate-y-24:focus {
    --transform-translate-y: 6rem;
  }

  .lg\:focus\:tw-translate-y-32:focus {
    --transform-translate-y: 8rem;
  }

  .lg\:focus\:tw-translate-y-40:focus {
    --transform-translate-y: 10rem;
  }

  .lg\:focus\:tw-translate-y-48:focus {
    --transform-translate-y: 12rem;
  }

  .lg\:focus\:tw-translate-y-56:focus {
    --transform-translate-y: 14rem;
  }

  .lg\:focus\:tw-translate-y-64:focus {
    --transform-translate-y: 16rem;
  }

  .lg\:focus\:tw-translate-y-px:focus {
    --transform-translate-y: 1px;
  }

  .lg\:focus\:tw-translate-y-270px:focus {
    --transform-translate-y: 270px;
  }

  .lg\:focus\:tw-translate-y-400px:focus {
    --transform-translate-y: 400px;
  }

  .lg\:focus\:tw-translate-y-464px:focus {
    --transform-translate-y: 464px;
  }

  .lg\:focus\:tw-translate-y-480px:focus {
    --transform-translate-y: 480px;
  }

  .lg\:focus\:tw-translate-y-500px:focus {
    --transform-translate-y: 500px;
  }

  .lg\:focus\:tw-translate-y-576px:focus {
    --transform-translate-y: 576px;
  }

  .lg\:focus\:tw-translate-y-600px:focus {
    --transform-translate-y: 600px;
  }

  .lg\:focus\:tw-translate-y-672px:focus {
    --transform-translate-y: 672px;
  }

  .lg\:focus\:tw--translate-y-1:focus {
    --transform-translate-y: -0.25rem;
  }

  .lg\:focus\:tw--translate-y-2:focus {
    --transform-translate-y: -0.5rem;
  }

  .lg\:focus\:tw--translate-y-3:focus {
    --transform-translate-y: -0.75rem;
  }

  .lg\:focus\:tw--translate-y-4:focus {
    --transform-translate-y: -1rem;
  }

  .lg\:focus\:tw--translate-y-5:focus {
    --transform-translate-y: -1.25rem;
  }

  .lg\:focus\:tw--translate-y-6:focus {
    --transform-translate-y: -1.5rem;
  }

  .lg\:focus\:tw--translate-y-8:focus {
    --transform-translate-y: -2rem;
  }

  .lg\:focus\:tw--translate-y-10:focus {
    --transform-translate-y: -2.5rem;
  }

  .lg\:focus\:tw--translate-y-12:focus {
    --transform-translate-y: -3rem;
  }

  .lg\:focus\:tw--translate-y-16:focus {
    --transform-translate-y: -4rem;
  }

  .lg\:focus\:tw--translate-y-20:focus {
    --transform-translate-y: -5rem;
  }

  .lg\:focus\:tw--translate-y-24:focus {
    --transform-translate-y: -6rem;
  }

  .lg\:focus\:tw--translate-y-32:focus {
    --transform-translate-y: -8rem;
  }

  .lg\:focus\:tw--translate-y-40:focus {
    --transform-translate-y: -10rem;
  }

  .lg\:focus\:tw--translate-y-48:focus {
    --transform-translate-y: -12rem;
  }

  .lg\:focus\:tw--translate-y-56:focus {
    --transform-translate-y: -14rem;
  }

  .lg\:focus\:tw--translate-y-64:focus {
    --transform-translate-y: -16rem;
  }

  .lg\:focus\:tw--translate-y-px:focus {
    --transform-translate-y: -1px;
  }

  .lg\:focus\:tw--translate-y-270px:focus {
    --transform-translate-y: -270px;
  }

  .lg\:focus\:tw--translate-y-400px:focus {
    --transform-translate-y: -400px;
  }

  .lg\:focus\:tw--translate-y-464px:focus {
    --transform-translate-y: -464px;
  }

  .lg\:focus\:tw--translate-y-480px:focus {
    --transform-translate-y: -480px;
  }

  .lg\:focus\:tw--translate-y-500px:focus {
    --transform-translate-y: -500px;
  }

  .lg\:focus\:tw--translate-y-576px:focus {
    --transform-translate-y: -576px;
  }

  .lg\:focus\:tw--translate-y-600px:focus {
    --transform-translate-y: -600px;
  }

  .lg\:focus\:tw--translate-y-672px:focus {
    --transform-translate-y: -672px;
  }

  .lg\:focus\:tw--translate-y-full:focus {
    --transform-translate-y: -100%;
  }

  .lg\:focus\:tw--translate-y-1\/2:focus {
    --transform-translate-y: -50%;
  }

  .lg\:focus\:tw-translate-y-1\/2:focus {
    --transform-translate-y: 50%;
  }

  .lg\:focus\:tw-translate-y-full:focus {
    --transform-translate-y: 100%;
  }

  .lg\:tw-skew-x-0 {
    --transform-skew-x: 0;
  }

  .lg\:tw-skew-x-1 {
    --transform-skew-x: 1deg;
  }

  .lg\:tw-skew-x-2 {
    --transform-skew-x: 2deg;
  }

  .lg\:tw-skew-x-3 {
    --transform-skew-x: 3deg;
  }

  .lg\:tw-skew-x-6 {
    --transform-skew-x: 6deg;
  }

  .lg\:tw-skew-x-12 {
    --transform-skew-x: 12deg;
  }

  .lg\:tw--skew-x-12 {
    --transform-skew-x: -12deg;
  }

  .lg\:tw--skew-x-6 {
    --transform-skew-x: -6deg;
  }

  .lg\:tw--skew-x-3 {
    --transform-skew-x: -3deg;
  }

  .lg\:tw--skew-x-2 {
    --transform-skew-x: -2deg;
  }

  .lg\:tw--skew-x-1 {
    --transform-skew-x: -1deg;
  }

  .lg\:tw-skew-y-0 {
    --transform-skew-y: 0;
  }

  .lg\:tw-skew-y-1 {
    --transform-skew-y: 1deg;
  }

  .lg\:tw-skew-y-2 {
    --transform-skew-y: 2deg;
  }

  .lg\:tw-skew-y-3 {
    --transform-skew-y: 3deg;
  }

  .lg\:tw-skew-y-6 {
    --transform-skew-y: 6deg;
  }

  .lg\:tw-skew-y-12 {
    --transform-skew-y: 12deg;
  }

  .lg\:tw--skew-y-12 {
    --transform-skew-y: -12deg;
  }

  .lg\:tw--skew-y-6 {
    --transform-skew-y: -6deg;
  }

  .lg\:tw--skew-y-3 {
    --transform-skew-y: -3deg;
  }

  .lg\:tw--skew-y-2 {
    --transform-skew-y: -2deg;
  }

  .lg\:tw--skew-y-1 {
    --transform-skew-y: -1deg;
  }

  .lg\:hover\:tw-skew-x-0:hover {
    --transform-skew-x: 0;
  }

  .lg\:hover\:tw-skew-x-1:hover {
    --transform-skew-x: 1deg;
  }

  .lg\:hover\:tw-skew-x-2:hover {
    --transform-skew-x: 2deg;
  }

  .lg\:hover\:tw-skew-x-3:hover {
    --transform-skew-x: 3deg;
  }

  .lg\:hover\:tw-skew-x-6:hover {
    --transform-skew-x: 6deg;
  }

  .lg\:hover\:tw-skew-x-12:hover {
    --transform-skew-x: 12deg;
  }

  .lg\:hover\:tw--skew-x-12:hover {
    --transform-skew-x: -12deg;
  }

  .lg\:hover\:tw--skew-x-6:hover {
    --transform-skew-x: -6deg;
  }

  .lg\:hover\:tw--skew-x-3:hover {
    --transform-skew-x: -3deg;
  }

  .lg\:hover\:tw--skew-x-2:hover {
    --transform-skew-x: -2deg;
  }

  .lg\:hover\:tw--skew-x-1:hover {
    --transform-skew-x: -1deg;
  }

  .lg\:hover\:tw-skew-y-0:hover {
    --transform-skew-y: 0;
  }

  .lg\:hover\:tw-skew-y-1:hover {
    --transform-skew-y: 1deg;
  }

  .lg\:hover\:tw-skew-y-2:hover {
    --transform-skew-y: 2deg;
  }

  .lg\:hover\:tw-skew-y-3:hover {
    --transform-skew-y: 3deg;
  }

  .lg\:hover\:tw-skew-y-6:hover {
    --transform-skew-y: 6deg;
  }

  .lg\:hover\:tw-skew-y-12:hover {
    --transform-skew-y: 12deg;
  }

  .lg\:hover\:tw--skew-y-12:hover {
    --transform-skew-y: -12deg;
  }

  .lg\:hover\:tw--skew-y-6:hover {
    --transform-skew-y: -6deg;
  }

  .lg\:hover\:tw--skew-y-3:hover {
    --transform-skew-y: -3deg;
  }

  .lg\:hover\:tw--skew-y-2:hover {
    --transform-skew-y: -2deg;
  }

  .lg\:hover\:tw--skew-y-1:hover {
    --transform-skew-y: -1deg;
  }

  .lg\:focus\:tw-skew-x-0:focus {
    --transform-skew-x: 0;
  }

  .lg\:focus\:tw-skew-x-1:focus {
    --transform-skew-x: 1deg;
  }

  .lg\:focus\:tw-skew-x-2:focus {
    --transform-skew-x: 2deg;
  }

  .lg\:focus\:tw-skew-x-3:focus {
    --transform-skew-x: 3deg;
  }

  .lg\:focus\:tw-skew-x-6:focus {
    --transform-skew-x: 6deg;
  }

  .lg\:focus\:tw-skew-x-12:focus {
    --transform-skew-x: 12deg;
  }

  .lg\:focus\:tw--skew-x-12:focus {
    --transform-skew-x: -12deg;
  }

  .lg\:focus\:tw--skew-x-6:focus {
    --transform-skew-x: -6deg;
  }

  .lg\:focus\:tw--skew-x-3:focus {
    --transform-skew-x: -3deg;
  }

  .lg\:focus\:tw--skew-x-2:focus {
    --transform-skew-x: -2deg;
  }

  .lg\:focus\:tw--skew-x-1:focus {
    --transform-skew-x: -1deg;
  }

  .lg\:focus\:tw-skew-y-0:focus {
    --transform-skew-y: 0;
  }

  .lg\:focus\:tw-skew-y-1:focus {
    --transform-skew-y: 1deg;
  }

  .lg\:focus\:tw-skew-y-2:focus {
    --transform-skew-y: 2deg;
  }

  .lg\:focus\:tw-skew-y-3:focus {
    --transform-skew-y: 3deg;
  }

  .lg\:focus\:tw-skew-y-6:focus {
    --transform-skew-y: 6deg;
  }

  .lg\:focus\:tw-skew-y-12:focus {
    --transform-skew-y: 12deg;
  }

  .lg\:focus\:tw--skew-y-12:focus {
    --transform-skew-y: -12deg;
  }

  .lg\:focus\:tw--skew-y-6:focus {
    --transform-skew-y: -6deg;
  }

  .lg\:focus\:tw--skew-y-3:focus {
    --transform-skew-y: -3deg;
  }

  .lg\:focus\:tw--skew-y-2:focus {
    --transform-skew-y: -2deg;
  }

  .lg\:focus\:tw--skew-y-1:focus {
    --transform-skew-y: -1deg;
  }

  .lg\:tw-transition-none {
    transition-property: none;
  }

  .lg\:tw-transition-all {
    transition-property: all;
  }

  .lg\:tw-transition {
    transition-property: background-color, border-color, color, fill, stroke, opacity, box-shadow, transform;
  }

  .lg\:tw-transition-colors {
    transition-property: background-color, border-color, color, fill, stroke;
  }

  .lg\:tw-transition-opacity {
    transition-property: opacity;
  }

  .lg\:tw-transition-shadow {
    transition-property: box-shadow;
  }

  .lg\:tw-transition-transform {
    transition-property: transform;
  }

  .lg\:tw-ease-linear {
    transition-timing-function: linear;
  }

  .lg\:tw-ease-in {
    transition-timing-function: cubic-bezier(0.4, 0, 1, 1);
  }

  .lg\:tw-ease-out {
    transition-timing-function: cubic-bezier(0, 0, 0.2, 1);
  }

  .lg\:tw-ease-in-out {
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  }

  .lg\:tw-duration-75 {
    transition-duration: 75ms;
  }

  .lg\:tw-duration-100 {
    transition-duration: 100ms;
  }

  .lg\:tw-duration-150 {
    transition-duration: 150ms;
  }

  .lg\:tw-duration-200 {
    transition-duration: 200ms;
  }

  .lg\:tw-duration-300 {
    transition-duration: 300ms;
  }

  .lg\:tw-duration-500 {
    transition-duration: 500ms;
  }

  .lg\:tw-duration-700 {
    transition-duration: 700ms;
  }

  .lg\:tw-duration-1000 {
    transition-duration: 1000ms;
  }

  .lg\:tw-delay-75 {
    transition-delay: 75ms;
  }

  .lg\:tw-delay-100 {
    transition-delay: 100ms;
  }

  .lg\:tw-delay-150 {
    transition-delay: 150ms;
  }

  .lg\:tw-delay-200 {
    transition-delay: 200ms;
  }

  .lg\:tw-delay-300 {
    transition-delay: 300ms;
  }

  .lg\:tw-delay-500 {
    transition-delay: 500ms;
  }

  .lg\:tw-delay-700 {
    transition-delay: 700ms;
  }

  .lg\:tw-delay-1000 {
    transition-delay: 1000ms;
  }

  .lg\:tw-animate-none {
    animation: none;
  }

  .lg\:tw-animate-spin {
    animation: spin 1s linear infinite;
  }

  .lg\:tw-animate-ping {
    animation: ping 1s cubic-bezier(0, 0, 0.2, 1) infinite;
  }

  .lg\:tw-animate-pulse {
    animation: pulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite;
  }

  .lg\:tw-animate-bounce {
    animation: bounce 1s infinite;
  }
}

@media (min-width: 1280px) {
  .xl\:tw-container {
    width: 100%;
  }

  @media (min-width: 640px) {
    .xl\:tw-container {
      max-width: 640px;
    }
  }

  @media (min-width: 768px) {
    .xl\:tw-container {
      max-width: 768px;
    }
  }

  @media (min-width: 1024px) {
    .xl\:tw-container {
      max-width: 1024px;
    }
  }

  @media (min-width: 1280px) {
    .xl\:tw-container {
      max-width: 1280px;
    }
  }

  .xl\:tw-space-y-0 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(0px * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(0px * var(--space-y-reverse));
  }

  .xl\:tw-space-x-0 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(0px * var(--space-x-reverse));
    margin-left: calc(0px * calc(1 - var(--space-x-reverse)));
  }

  .xl\:tw-space-y-1 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(0.25rem * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(0.25rem * var(--space-y-reverse));
  }

  .xl\:tw-space-x-1 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(0.25rem * var(--space-x-reverse));
    margin-left: calc(0.25rem * calc(1 - var(--space-x-reverse)));
  }

  .xl\:tw-space-y-2 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(0.5rem * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(0.5rem * var(--space-y-reverse));
  }

  .xl\:tw-space-x-2 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(0.5rem * var(--space-x-reverse));
    margin-left: calc(0.5rem * calc(1 - var(--space-x-reverse)));
  }

  .xl\:tw-space-y-3 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(0.75rem * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(0.75rem * var(--space-y-reverse));
  }

  .xl\:tw-space-x-3 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(0.75rem * var(--space-x-reverse));
    margin-left: calc(0.75rem * calc(1 - var(--space-x-reverse)));
  }

  .xl\:tw-space-y-4 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(1rem * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(1rem * var(--space-y-reverse));
  }

  .xl\:tw-space-x-4 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(1rem * var(--space-x-reverse));
    margin-left: calc(1rem * calc(1 - var(--space-x-reverse)));
  }

  .xl\:tw-space-y-5 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(1.25rem * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(1.25rem * var(--space-y-reverse));
  }

  .xl\:tw-space-x-5 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(1.25rem * var(--space-x-reverse));
    margin-left: calc(1.25rem * calc(1 - var(--space-x-reverse)));
  }

  .xl\:tw-space-y-6 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(1.5rem * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(1.5rem * var(--space-y-reverse));
  }

  .xl\:tw-space-x-6 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(1.5rem * var(--space-x-reverse));
    margin-left: calc(1.5rem * calc(1 - var(--space-x-reverse)));
  }

  .xl\:tw-space-y-8 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(2rem * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(2rem * var(--space-y-reverse));
  }

  .xl\:tw-space-x-8 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(2rem * var(--space-x-reverse));
    margin-left: calc(2rem * calc(1 - var(--space-x-reverse)));
  }

  .xl\:tw-space-y-10 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(2.5rem * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(2.5rem * var(--space-y-reverse));
  }

  .xl\:tw-space-x-10 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(2.5rem * var(--space-x-reverse));
    margin-left: calc(2.5rem * calc(1 - var(--space-x-reverse)));
  }

  .xl\:tw-space-y-12 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(3rem * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(3rem * var(--space-y-reverse));
  }

  .xl\:tw-space-x-12 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(3rem * var(--space-x-reverse));
    margin-left: calc(3rem * calc(1 - var(--space-x-reverse)));
  }

  .xl\:tw-space-y-16 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(4rem * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(4rem * var(--space-y-reverse));
  }

  .xl\:tw-space-x-16 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(4rem * var(--space-x-reverse));
    margin-left: calc(4rem * calc(1 - var(--space-x-reverse)));
  }

  .xl\:tw-space-y-20 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(5rem * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(5rem * var(--space-y-reverse));
  }

  .xl\:tw-space-x-20 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(5rem * var(--space-x-reverse));
    margin-left: calc(5rem * calc(1 - var(--space-x-reverse)));
  }

  .xl\:tw-space-y-24 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(6rem * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(6rem * var(--space-y-reverse));
  }

  .xl\:tw-space-x-24 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(6rem * var(--space-x-reverse));
    margin-left: calc(6rem * calc(1 - var(--space-x-reverse)));
  }

  .xl\:tw-space-y-32 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(8rem * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(8rem * var(--space-y-reverse));
  }

  .xl\:tw-space-x-32 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(8rem * var(--space-x-reverse));
    margin-left: calc(8rem * calc(1 - var(--space-x-reverse)));
  }

  .xl\:tw-space-y-40 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(10rem * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(10rem * var(--space-y-reverse));
  }

  .xl\:tw-space-x-40 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(10rem * var(--space-x-reverse));
    margin-left: calc(10rem * calc(1 - var(--space-x-reverse)));
  }

  .xl\:tw-space-y-48 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(12rem * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(12rem * var(--space-y-reverse));
  }

  .xl\:tw-space-x-48 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(12rem * var(--space-x-reverse));
    margin-left: calc(12rem * calc(1 - var(--space-x-reverse)));
  }

  .xl\:tw-space-y-56 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(14rem * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(14rem * var(--space-y-reverse));
  }

  .xl\:tw-space-x-56 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(14rem * var(--space-x-reverse));
    margin-left: calc(14rem * calc(1 - var(--space-x-reverse)));
  }

  .xl\:tw-space-y-64 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(16rem * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(16rem * var(--space-y-reverse));
  }

  .xl\:tw-space-x-64 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(16rem * var(--space-x-reverse));
    margin-left: calc(16rem * calc(1 - var(--space-x-reverse)));
  }

  .xl\:tw-space-y-px > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(1px * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(1px * var(--space-y-reverse));
  }

  .xl\:tw-space-x-px > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(1px * var(--space-x-reverse));
    margin-left: calc(1px * calc(1 - var(--space-x-reverse)));
  }

  .xl\:tw-space-y-270px > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(270px * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(270px * var(--space-y-reverse));
  }

  .xl\:tw-space-x-270px > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(270px * var(--space-x-reverse));
    margin-left: calc(270px * calc(1 - var(--space-x-reverse)));
  }

  .xl\:tw-space-y-400px > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(400px * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(400px * var(--space-y-reverse));
  }

  .xl\:tw-space-x-400px > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(400px * var(--space-x-reverse));
    margin-left: calc(400px * calc(1 - var(--space-x-reverse)));
  }

  .xl\:tw-space-y-464px > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(464px * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(464px * var(--space-y-reverse));
  }

  .xl\:tw-space-x-464px > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(464px * var(--space-x-reverse));
    margin-left: calc(464px * calc(1 - var(--space-x-reverse)));
  }

  .xl\:tw-space-y-480px > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(480px * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(480px * var(--space-y-reverse));
  }

  .xl\:tw-space-x-480px > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(480px * var(--space-x-reverse));
    margin-left: calc(480px * calc(1 - var(--space-x-reverse)));
  }

  .xl\:tw-space-y-500px > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(500px * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(500px * var(--space-y-reverse));
  }

  .xl\:tw-space-x-500px > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(500px * var(--space-x-reverse));
    margin-left: calc(500px * calc(1 - var(--space-x-reverse)));
  }

  .xl\:tw-space-y-576px > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(576px * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(576px * var(--space-y-reverse));
  }

  .xl\:tw-space-x-576px > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(576px * var(--space-x-reverse));
    margin-left: calc(576px * calc(1 - var(--space-x-reverse)));
  }

  .xl\:tw-space-y-600px > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(600px * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(600px * var(--space-y-reverse));
  }

  .xl\:tw-space-x-600px > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(600px * var(--space-x-reverse));
    margin-left: calc(600px * calc(1 - var(--space-x-reverse)));
  }

  .xl\:tw-space-y-672px > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(672px * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(672px * var(--space-y-reverse));
  }

  .xl\:tw-space-x-672px > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(672px * var(--space-x-reverse));
    margin-left: calc(672px * calc(1 - var(--space-x-reverse)));
  }

  .xl\:tw--space-y-1 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(-0.25rem * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(-0.25rem * var(--space-y-reverse));
  }

  .xl\:tw--space-x-1 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(-0.25rem * var(--space-x-reverse));
    margin-left: calc(-0.25rem * calc(1 - var(--space-x-reverse)));
  }

  .xl\:tw--space-y-2 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(-0.5rem * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(-0.5rem * var(--space-y-reverse));
  }

  .xl\:tw--space-x-2 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(-0.5rem * var(--space-x-reverse));
    margin-left: calc(-0.5rem * calc(1 - var(--space-x-reverse)));
  }

  .xl\:tw--space-y-3 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(-0.75rem * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(-0.75rem * var(--space-y-reverse));
  }

  .xl\:tw--space-x-3 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(-0.75rem * var(--space-x-reverse));
    margin-left: calc(-0.75rem * calc(1 - var(--space-x-reverse)));
  }

  .xl\:tw--space-y-4 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(-1rem * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(-1rem * var(--space-y-reverse));
  }

  .xl\:tw--space-x-4 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(-1rem * var(--space-x-reverse));
    margin-left: calc(-1rem * calc(1 - var(--space-x-reverse)));
  }

  .xl\:tw--space-y-5 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(-1.25rem * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(-1.25rem * var(--space-y-reverse));
  }

  .xl\:tw--space-x-5 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(-1.25rem * var(--space-x-reverse));
    margin-left: calc(-1.25rem * calc(1 - var(--space-x-reverse)));
  }

  .xl\:tw--space-y-6 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(-1.5rem * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(-1.5rem * var(--space-y-reverse));
  }

  .xl\:tw--space-x-6 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(-1.5rem * var(--space-x-reverse));
    margin-left: calc(-1.5rem * calc(1 - var(--space-x-reverse)));
  }

  .xl\:tw--space-y-8 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(-2rem * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(-2rem * var(--space-y-reverse));
  }

  .xl\:tw--space-x-8 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(-2rem * var(--space-x-reverse));
    margin-left: calc(-2rem * calc(1 - var(--space-x-reverse)));
  }

  .xl\:tw--space-y-10 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(-2.5rem * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(-2.5rem * var(--space-y-reverse));
  }

  .xl\:tw--space-x-10 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(-2.5rem * var(--space-x-reverse));
    margin-left: calc(-2.5rem * calc(1 - var(--space-x-reverse)));
  }

  .xl\:tw--space-y-12 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(-3rem * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(-3rem * var(--space-y-reverse));
  }

  .xl\:tw--space-x-12 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(-3rem * var(--space-x-reverse));
    margin-left: calc(-3rem * calc(1 - var(--space-x-reverse)));
  }

  .xl\:tw--space-y-16 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(-4rem * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(-4rem * var(--space-y-reverse));
  }

  .xl\:tw--space-x-16 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(-4rem * var(--space-x-reverse));
    margin-left: calc(-4rem * calc(1 - var(--space-x-reverse)));
  }

  .xl\:tw--space-y-20 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(-5rem * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(-5rem * var(--space-y-reverse));
  }

  .xl\:tw--space-x-20 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(-5rem * var(--space-x-reverse));
    margin-left: calc(-5rem * calc(1 - var(--space-x-reverse)));
  }

  .xl\:tw--space-y-24 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(-6rem * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(-6rem * var(--space-y-reverse));
  }

  .xl\:tw--space-x-24 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(-6rem * var(--space-x-reverse));
    margin-left: calc(-6rem * calc(1 - var(--space-x-reverse)));
  }

  .xl\:tw--space-y-32 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(-8rem * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(-8rem * var(--space-y-reverse));
  }

  .xl\:tw--space-x-32 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(-8rem * var(--space-x-reverse));
    margin-left: calc(-8rem * calc(1 - var(--space-x-reverse)));
  }

  .xl\:tw--space-y-40 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(-10rem * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(-10rem * var(--space-y-reverse));
  }

  .xl\:tw--space-x-40 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(-10rem * var(--space-x-reverse));
    margin-left: calc(-10rem * calc(1 - var(--space-x-reverse)));
  }

  .xl\:tw--space-y-48 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(-12rem * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(-12rem * var(--space-y-reverse));
  }

  .xl\:tw--space-x-48 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(-12rem * var(--space-x-reverse));
    margin-left: calc(-12rem * calc(1 - var(--space-x-reverse)));
  }

  .xl\:tw--space-y-56 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(-14rem * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(-14rem * var(--space-y-reverse));
  }

  .xl\:tw--space-x-56 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(-14rem * var(--space-x-reverse));
    margin-left: calc(-14rem * calc(1 - var(--space-x-reverse)));
  }

  .xl\:tw--space-y-64 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(-16rem * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(-16rem * var(--space-y-reverse));
  }

  .xl\:tw--space-x-64 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(-16rem * var(--space-x-reverse));
    margin-left: calc(-16rem * calc(1 - var(--space-x-reverse)));
  }

  .xl\:tw--space-y-px > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(-1px * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(-1px * var(--space-y-reverse));
  }

  .xl\:tw--space-x-px > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(-1px * var(--space-x-reverse));
    margin-left: calc(-1px * calc(1 - var(--space-x-reverse)));
  }

  .xl\:tw--space-y-270px > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(-270px * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(-270px * var(--space-y-reverse));
  }

  .xl\:tw--space-x-270px > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(-270px * var(--space-x-reverse));
    margin-left: calc(-270px * calc(1 - var(--space-x-reverse)));
  }

  .xl\:tw--space-y-400px > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(-400px * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(-400px * var(--space-y-reverse));
  }

  .xl\:tw--space-x-400px > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(-400px * var(--space-x-reverse));
    margin-left: calc(-400px * calc(1 - var(--space-x-reverse)));
  }

  .xl\:tw--space-y-464px > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(-464px * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(-464px * var(--space-y-reverse));
  }

  .xl\:tw--space-x-464px > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(-464px * var(--space-x-reverse));
    margin-left: calc(-464px * calc(1 - var(--space-x-reverse)));
  }

  .xl\:tw--space-y-480px > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(-480px * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(-480px * var(--space-y-reverse));
  }

  .xl\:tw--space-x-480px > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(-480px * var(--space-x-reverse));
    margin-left: calc(-480px * calc(1 - var(--space-x-reverse)));
  }

  .xl\:tw--space-y-500px > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(-500px * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(-500px * var(--space-y-reverse));
  }

  .xl\:tw--space-x-500px > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(-500px * var(--space-x-reverse));
    margin-left: calc(-500px * calc(1 - var(--space-x-reverse)));
  }

  .xl\:tw--space-y-576px > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(-576px * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(-576px * var(--space-y-reverse));
  }

  .xl\:tw--space-x-576px > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(-576px * var(--space-x-reverse));
    margin-left: calc(-576px * calc(1 - var(--space-x-reverse)));
  }

  .xl\:tw--space-y-600px > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(-600px * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(-600px * var(--space-y-reverse));
  }

  .xl\:tw--space-x-600px > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(-600px * var(--space-x-reverse));
    margin-left: calc(-600px * calc(1 - var(--space-x-reverse)));
  }

  .xl\:tw--space-y-672px > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(-672px * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(-672px * var(--space-y-reverse));
  }

  .xl\:tw--space-x-672px > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(-672px * var(--space-x-reverse));
    margin-left: calc(-672px * calc(1 - var(--space-x-reverse)));
  }

  .xl\:tw-space-y-reverse > :not(template) ~ :not(template) {
    --space-y-reverse: 1;
  }

  .xl\:tw-space-x-reverse > :not(template) ~ :not(template) {
    --space-x-reverse: 1;
  }

  .xl\:tw-divide-y-0 > :not(template) ~ :not(template) {
    --divide-y-reverse: 0;
    border-top-width: calc(0px * calc(1 - var(--divide-y-reverse)));
    border-bottom-width: calc(0px * var(--divide-y-reverse));
  }

  .xl\:tw-divide-x-0 > :not(template) ~ :not(template) {
    --divide-x-reverse: 0;
    border-right-width: calc(0px * var(--divide-x-reverse));
    border-left-width: calc(0px * calc(1 - var(--divide-x-reverse)));
  }

  .xl\:tw-divide-y-2 > :not(template) ~ :not(template) {
    --divide-y-reverse: 0;
    border-top-width: calc(2px * calc(1 - var(--divide-y-reverse)));
    border-bottom-width: calc(2px * var(--divide-y-reverse));
  }

  .xl\:tw-divide-x-2 > :not(template) ~ :not(template) {
    --divide-x-reverse: 0;
    border-right-width: calc(2px * var(--divide-x-reverse));
    border-left-width: calc(2px * calc(1 - var(--divide-x-reverse)));
  }

  .xl\:tw-divide-y-4 > :not(template) ~ :not(template) {
    --divide-y-reverse: 0;
    border-top-width: calc(4px * calc(1 - var(--divide-y-reverse)));
    border-bottom-width: calc(4px * var(--divide-y-reverse));
  }

  .xl\:tw-divide-x-4 > :not(template) ~ :not(template) {
    --divide-x-reverse: 0;
    border-right-width: calc(4px * var(--divide-x-reverse));
    border-left-width: calc(4px * calc(1 - var(--divide-x-reverse)));
  }

  .xl\:tw-divide-y-8 > :not(template) ~ :not(template) {
    --divide-y-reverse: 0;
    border-top-width: calc(8px * calc(1 - var(--divide-y-reverse)));
    border-bottom-width: calc(8px * var(--divide-y-reverse));
  }

  .xl\:tw-divide-x-8 > :not(template) ~ :not(template) {
    --divide-x-reverse: 0;
    border-right-width: calc(8px * var(--divide-x-reverse));
    border-left-width: calc(8px * calc(1 - var(--divide-x-reverse)));
  }

  .xl\:tw-divide-y > :not(template) ~ :not(template) {
    --divide-y-reverse: 0;
    border-top-width: calc(1px * calc(1 - var(--divide-y-reverse)));
    border-bottom-width: calc(1px * var(--divide-y-reverse));
  }

  .xl\:tw-divide-x > :not(template) ~ :not(template) {
    --divide-x-reverse: 0;
    border-right-width: calc(1px * var(--divide-x-reverse));
    border-left-width: calc(1px * calc(1 - var(--divide-x-reverse)));
  }

  .xl\:tw-divide-y-reverse > :not(template) ~ :not(template) {
    --divide-y-reverse: 1;
  }

  .xl\:tw-divide-x-reverse > :not(template) ~ :not(template) {
    --divide-x-reverse: 1;
  }

  .xl\:tw-divide-transparent > :not(template) ~ :not(template) {
    border-color: transparent;
  }

  .xl\:tw-divide-current > :not(template) ~ :not(template) {
    border-color: currentColor;
  }

  .xl\:tw-divide-black > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #000;
    border-color: rgba(0, 0, 0, var(--divide-opacity));
  }

  .xl\:tw-divide-white > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #fff;
    border-color: rgba(255, 255, 255, var(--divide-opacity));
  }

  .xl\:tw-divide-gray-100 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #f7fafc;
    border-color: rgba(247, 250, 252, var(--divide-opacity));
  }

  .xl\:tw-divide-gray-200 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #edf2f7;
    border-color: rgba(237, 242, 247, var(--divide-opacity));
  }

  .xl\:tw-divide-gray-300 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #e2e8f0;
    border-color: rgba(226, 232, 240, var(--divide-opacity));
  }

  .xl\:tw-divide-gray-400 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #cbd5e0;
    border-color: rgba(203, 213, 224, var(--divide-opacity));
  }

  .xl\:tw-divide-gray-500 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #a0aec0;
    border-color: rgba(160, 174, 192, var(--divide-opacity));
  }

  .xl\:tw-divide-gray-600 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #718096;
    border-color: rgba(113, 128, 150, var(--divide-opacity));
  }

  .xl\:tw-divide-gray-700 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #4a5568;
    border-color: rgba(74, 85, 104, var(--divide-opacity));
  }

  .xl\:tw-divide-gray-800 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #2d3748;
    border-color: rgba(45, 55, 72, var(--divide-opacity));
  }

  .xl\:tw-divide-gray-900 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #1a202c;
    border-color: rgba(26, 32, 44, var(--divide-opacity));
  }

  .xl\:tw-divide-red-100 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #fff5f5;
    border-color: rgba(255, 245, 245, var(--divide-opacity));
  }

  .xl\:tw-divide-red-200 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #fed7d7;
    border-color: rgba(254, 215, 215, var(--divide-opacity));
  }

  .xl\:tw-divide-red-300 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #feb2b2;
    border-color: rgba(254, 178, 178, var(--divide-opacity));
  }

  .xl\:tw-divide-red-400 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #fc8181;
    border-color: rgba(252, 129, 129, var(--divide-opacity));
  }

  .xl\:tw-divide-red-500 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #f56565;
    border-color: rgba(245, 101, 101, var(--divide-opacity));
  }

  .xl\:tw-divide-red-600 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #e53e3e;
    border-color: rgba(229, 62, 62, var(--divide-opacity));
  }

  .xl\:tw-divide-red-700 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #c53030;
    border-color: rgba(197, 48, 48, var(--divide-opacity));
  }

  .xl\:tw-divide-red-800 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #9b2c2c;
    border-color: rgba(155, 44, 44, var(--divide-opacity));
  }

  .xl\:tw-divide-red-900 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #742a2a;
    border-color: rgba(116, 42, 42, var(--divide-opacity));
  }

  .xl\:tw-divide-orange-100 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #fffaf0;
    border-color: rgba(255, 250, 240, var(--divide-opacity));
  }

  .xl\:tw-divide-orange-200 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #feebc8;
    border-color: rgba(254, 235, 200, var(--divide-opacity));
  }

  .xl\:tw-divide-orange-300 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #fbd38d;
    border-color: rgba(251, 211, 141, var(--divide-opacity));
  }

  .xl\:tw-divide-orange-400 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #f6ad55;
    border-color: rgba(246, 173, 85, var(--divide-opacity));
  }

  .xl\:tw-divide-orange-500 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #ed8936;
    border-color: rgba(237, 137, 54, var(--divide-opacity));
  }

  .xl\:tw-divide-orange-600 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #dd6b20;
    border-color: rgba(221, 107, 32, var(--divide-opacity));
  }

  .xl\:tw-divide-orange-700 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #c05621;
    border-color: rgba(192, 86, 33, var(--divide-opacity));
  }

  .xl\:tw-divide-orange-800 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #9c4221;
    border-color: rgba(156, 66, 33, var(--divide-opacity));
  }

  .xl\:tw-divide-orange-900 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #7b341e;
    border-color: rgba(123, 52, 30, var(--divide-opacity));
  }

  .xl\:tw-divide-yellow-100 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #fffff0;
    border-color: rgba(255, 255, 240, var(--divide-opacity));
  }

  .xl\:tw-divide-yellow-200 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #fefcbf;
    border-color: rgba(254, 252, 191, var(--divide-opacity));
  }

  .xl\:tw-divide-yellow-300 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #faf089;
    border-color: rgba(250, 240, 137, var(--divide-opacity));
  }

  .xl\:tw-divide-yellow-400 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #f6e05e;
    border-color: rgba(246, 224, 94, var(--divide-opacity));
  }

  .xl\:tw-divide-yellow-500 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #ecc94b;
    border-color: rgba(236, 201, 75, var(--divide-opacity));
  }

  .xl\:tw-divide-yellow-600 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #d69e2e;
    border-color: rgba(214, 158, 46, var(--divide-opacity));
  }

  .xl\:tw-divide-yellow-700 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #b7791f;
    border-color: rgba(183, 121, 31, var(--divide-opacity));
  }

  .xl\:tw-divide-yellow-800 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #975a16;
    border-color: rgba(151, 90, 22, var(--divide-opacity));
  }

  .xl\:tw-divide-yellow-900 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #744210;
    border-color: rgba(116, 66, 16, var(--divide-opacity));
  }

  .xl\:tw-divide-green-100 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #f0fff4;
    border-color: rgba(240, 255, 244, var(--divide-opacity));
  }

  .xl\:tw-divide-green-200 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #c6f6d5;
    border-color: rgba(198, 246, 213, var(--divide-opacity));
  }

  .xl\:tw-divide-green-300 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #9ae6b4;
    border-color: rgba(154, 230, 180, var(--divide-opacity));
  }

  .xl\:tw-divide-green-400 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #68d391;
    border-color: rgba(104, 211, 145, var(--divide-opacity));
  }

  .xl\:tw-divide-green-500 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #48bb78;
    border-color: rgba(72, 187, 120, var(--divide-opacity));
  }

  .xl\:tw-divide-green-600 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #38a169;
    border-color: rgba(56, 161, 105, var(--divide-opacity));
  }

  .xl\:tw-divide-green-700 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #2f855a;
    border-color: rgba(47, 133, 90, var(--divide-opacity));
  }

  .xl\:tw-divide-green-800 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #276749;
    border-color: rgba(39, 103, 73, var(--divide-opacity));
  }

  .xl\:tw-divide-green-900 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #22543d;
    border-color: rgba(34, 84, 61, var(--divide-opacity));
  }

  .xl\:tw-divide-teal-100 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #e6fffa;
    border-color: rgba(230, 255, 250, var(--divide-opacity));
  }

  .xl\:tw-divide-teal-200 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #b2f5ea;
    border-color: rgba(178, 245, 234, var(--divide-opacity));
  }

  .xl\:tw-divide-teal-300 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #81e6d9;
    border-color: rgba(129, 230, 217, var(--divide-opacity));
  }

  .xl\:tw-divide-teal-400 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #4fd1c5;
    border-color: rgba(79, 209, 197, var(--divide-opacity));
  }

  .xl\:tw-divide-teal-500 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #38b2ac;
    border-color: rgba(56, 178, 172, var(--divide-opacity));
  }

  .xl\:tw-divide-teal-600 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #319795;
    border-color: rgba(49, 151, 149, var(--divide-opacity));
  }

  .xl\:tw-divide-teal-700 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #2c7a7b;
    border-color: rgba(44, 122, 123, var(--divide-opacity));
  }

  .xl\:tw-divide-teal-800 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #285e61;
    border-color: rgba(40, 94, 97, var(--divide-opacity));
  }

  .xl\:tw-divide-teal-900 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #234e52;
    border-color: rgba(35, 78, 82, var(--divide-opacity));
  }

  .xl\:tw-divide-blue-100 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #ebf8ff;
    border-color: rgba(235, 248, 255, var(--divide-opacity));
  }

  .xl\:tw-divide-blue-200 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #bee3f8;
    border-color: rgba(190, 227, 248, var(--divide-opacity));
  }

  .xl\:tw-divide-blue-300 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #90cdf4;
    border-color: rgba(144, 205, 244, var(--divide-opacity));
  }

  .xl\:tw-divide-blue-400 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #63b3ed;
    border-color: rgba(99, 179, 237, var(--divide-opacity));
  }

  .xl\:tw-divide-blue-500 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #4299e1;
    border-color: rgba(66, 153, 225, var(--divide-opacity));
  }

  .xl\:tw-divide-blue-600 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #3182ce;
    border-color: rgba(49, 130, 206, var(--divide-opacity));
  }

  .xl\:tw-divide-blue-700 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #2b6cb0;
    border-color: rgba(43, 108, 176, var(--divide-opacity));
  }

  .xl\:tw-divide-blue-800 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #2c5282;
    border-color: rgba(44, 82, 130, var(--divide-opacity));
  }

  .xl\:tw-divide-blue-900 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #2a4365;
    border-color: rgba(42, 67, 101, var(--divide-opacity));
  }

  .xl\:tw-divide-indigo-100 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #ebf4ff;
    border-color: rgba(235, 244, 255, var(--divide-opacity));
  }

  .xl\:tw-divide-indigo-200 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #c3dafe;
    border-color: rgba(195, 218, 254, var(--divide-opacity));
  }

  .xl\:tw-divide-indigo-300 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #a3bffa;
    border-color: rgba(163, 191, 250, var(--divide-opacity));
  }

  .xl\:tw-divide-indigo-400 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #7f9cf5;
    border-color: rgba(127, 156, 245, var(--divide-opacity));
  }

  .xl\:tw-divide-indigo-500 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #667eea;
    border-color: rgba(102, 126, 234, var(--divide-opacity));
  }

  .xl\:tw-divide-indigo-600 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #5a67d8;
    border-color: rgba(90, 103, 216, var(--divide-opacity));
  }

  .xl\:tw-divide-indigo-700 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #4c51bf;
    border-color: rgba(76, 81, 191, var(--divide-opacity));
  }

  .xl\:tw-divide-indigo-800 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #434190;
    border-color: rgba(67, 65, 144, var(--divide-opacity));
  }

  .xl\:tw-divide-indigo-900 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #3c366b;
    border-color: rgba(60, 54, 107, var(--divide-opacity));
  }

  .xl\:tw-divide-purple-100 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #faf5ff;
    border-color: rgba(250, 245, 255, var(--divide-opacity));
  }

  .xl\:tw-divide-purple-200 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #e9d8fd;
    border-color: rgba(233, 216, 253, var(--divide-opacity));
  }

  .xl\:tw-divide-purple-300 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #d6bcfa;
    border-color: rgba(214, 188, 250, var(--divide-opacity));
  }

  .xl\:tw-divide-purple-400 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #b794f4;
    border-color: rgba(183, 148, 244, var(--divide-opacity));
  }

  .xl\:tw-divide-purple-500 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #9f7aea;
    border-color: rgba(159, 122, 234, var(--divide-opacity));
  }

  .xl\:tw-divide-purple-600 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #805ad5;
    border-color: rgba(128, 90, 213, var(--divide-opacity));
  }

  .xl\:tw-divide-purple-700 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #6b46c1;
    border-color: rgba(107, 70, 193, var(--divide-opacity));
  }

  .xl\:tw-divide-purple-800 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #553c9a;
    border-color: rgba(85, 60, 154, var(--divide-opacity));
  }

  .xl\:tw-divide-purple-900 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #44337a;
    border-color: rgba(68, 51, 122, var(--divide-opacity));
  }

  .xl\:tw-divide-pink-100 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #fff5f7;
    border-color: rgba(255, 245, 247, var(--divide-opacity));
  }

  .xl\:tw-divide-pink-200 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #fed7e2;
    border-color: rgba(254, 215, 226, var(--divide-opacity));
  }

  .xl\:tw-divide-pink-300 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #fbb6ce;
    border-color: rgba(251, 182, 206, var(--divide-opacity));
  }

  .xl\:tw-divide-pink-400 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #f687b3;
    border-color: rgba(246, 135, 179, var(--divide-opacity));
  }

  .xl\:tw-divide-pink-500 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #ed64a6;
    border-color: rgba(237, 100, 166, var(--divide-opacity));
  }

  .xl\:tw-divide-pink-600 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #d53f8c;
    border-color: rgba(213, 63, 140, var(--divide-opacity));
  }

  .xl\:tw-divide-pink-700 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #b83280;
    border-color: rgba(184, 50, 128, var(--divide-opacity));
  }

  .xl\:tw-divide-pink-800 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #97266d;
    border-color: rgba(151, 38, 109, var(--divide-opacity));
  }

  .xl\:tw-divide-pink-900 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #702459;
    border-color: rgba(112, 36, 89, var(--divide-opacity));
  }

  .xl\:tw-divide-solid > :not(template) ~ :not(template) {
    border-style: solid;
  }

  .xl\:tw-divide-dashed > :not(template) ~ :not(template) {
    border-style: dashed;
  }

  .xl\:tw-divide-dotted > :not(template) ~ :not(template) {
    border-style: dotted;
  }

  .xl\:tw-divide-double > :not(template) ~ :not(template) {
    border-style: double;
  }

  .xl\:tw-divide-none > :not(template) ~ :not(template) {
    border-style: none;
  }

  .xl\:tw-divide-opacity-0 > :not(template) ~ :not(template) {
    --divide-opacity: 0;
  }

  .xl\:tw-divide-opacity-25 > :not(template) ~ :not(template) {
    --divide-opacity: 0.25;
  }

  .xl\:tw-divide-opacity-50 > :not(template) ~ :not(template) {
    --divide-opacity: 0.5;
  }

  .xl\:tw-divide-opacity-75 > :not(template) ~ :not(template) {
    --divide-opacity: 0.75;
  }

  .xl\:tw-divide-opacity-100 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
  }

  .xl\:tw-sr-only {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    border-width: 0;
  }

  .xl\:tw-not-sr-only {
    position: static;
    width: auto;
    height: auto;
    padding: 0;
    margin: 0;
    overflow: visible;
    clip: auto;
    white-space: normal;
  }

  .xl\:focus\:tw-sr-only:focus {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    border-width: 0;
  }

  .xl\:focus\:tw-not-sr-only:focus {
    position: static;
    width: auto;
    height: auto;
    padding: 0;
    margin: 0;
    overflow: visible;
    clip: auto;
    white-space: normal;
  }

  .xl\:tw-appearance-none {
    -webkit-appearance: none;
       -moz-appearance: none;
            appearance: none;
  }

  .xl\:tw-bg-fixed {
    background-attachment: fixed;
  }

  .xl\:tw-bg-local {
    background-attachment: local;
  }

  .xl\:tw-bg-scroll {
    background-attachment: scroll;
  }

  .xl\:tw-bg-clip-border {
    background-clip: border-box;
  }

  .xl\:tw-bg-clip-padding {
    background-clip: padding-box;
  }

  .xl\:tw-bg-clip-content {
    background-clip: content-box;
  }

  .xl\:tw-bg-clip-text {
    -webkit-background-clip: text;
            background-clip: text;
  }

  .xl\:tw-bg-transparent {
    background-color: transparent;
  }

  .xl\:tw-bg-current {
    background-color: currentColor;
  }

  .xl\:tw-bg-black {
    --bg-opacity: 1;
    background-color: #000;
    background-color: rgba(0, 0, 0, var(--bg-opacity));
  }

  .xl\:tw-bg-white {
    --bg-opacity: 1;
    background-color: #fff;
    background-color: rgba(255, 255, 255, var(--bg-opacity));
  }

  .xl\:tw-bg-gray-100 {
    --bg-opacity: 1;
    background-color: #f7fafc;
    background-color: rgba(247, 250, 252, var(--bg-opacity));
  }

  .xl\:tw-bg-gray-200 {
    --bg-opacity: 1;
    background-color: #edf2f7;
    background-color: rgba(237, 242, 247, var(--bg-opacity));
  }

  .xl\:tw-bg-gray-300 {
    --bg-opacity: 1;
    background-color: #e2e8f0;
    background-color: rgba(226, 232, 240, var(--bg-opacity));
  }

  .xl\:tw-bg-gray-400 {
    --bg-opacity: 1;
    background-color: #cbd5e0;
    background-color: rgba(203, 213, 224, var(--bg-opacity));
  }

  .xl\:tw-bg-gray-500 {
    --bg-opacity: 1;
    background-color: #a0aec0;
    background-color: rgba(160, 174, 192, var(--bg-opacity));
  }

  .xl\:tw-bg-gray-600 {
    --bg-opacity: 1;
    background-color: #718096;
    background-color: rgba(113, 128, 150, var(--bg-opacity));
  }

  .xl\:tw-bg-gray-700 {
    --bg-opacity: 1;
    background-color: #4a5568;
    background-color: rgba(74, 85, 104, var(--bg-opacity));
  }

  .xl\:tw-bg-gray-800 {
    --bg-opacity: 1;
    background-color: #2d3748;
    background-color: rgba(45, 55, 72, var(--bg-opacity));
  }

  .xl\:tw-bg-gray-900 {
    --bg-opacity: 1;
    background-color: #1a202c;
    background-color: rgba(26, 32, 44, var(--bg-opacity));
  }

  .xl\:tw-bg-red-100 {
    --bg-opacity: 1;
    background-color: #fff5f5;
    background-color: rgba(255, 245, 245, var(--bg-opacity));
  }

  .xl\:tw-bg-red-200 {
    --bg-opacity: 1;
    background-color: #fed7d7;
    background-color: rgba(254, 215, 215, var(--bg-opacity));
  }

  .xl\:tw-bg-red-300 {
    --bg-opacity: 1;
    background-color: #feb2b2;
    background-color: rgba(254, 178, 178, var(--bg-opacity));
  }

  .xl\:tw-bg-red-400 {
    --bg-opacity: 1;
    background-color: #fc8181;
    background-color: rgba(252, 129, 129, var(--bg-opacity));
  }

  .xl\:tw-bg-red-500 {
    --bg-opacity: 1;
    background-color: #f56565;
    background-color: rgba(245, 101, 101, var(--bg-opacity));
  }

  .xl\:tw-bg-red-600 {
    --bg-opacity: 1;
    background-color: #e53e3e;
    background-color: rgba(229, 62, 62, var(--bg-opacity));
  }

  .xl\:tw-bg-red-700 {
    --bg-opacity: 1;
    background-color: #c53030;
    background-color: rgba(197, 48, 48, var(--bg-opacity));
  }

  .xl\:tw-bg-red-800 {
    --bg-opacity: 1;
    background-color: #9b2c2c;
    background-color: rgba(155, 44, 44, var(--bg-opacity));
  }

  .xl\:tw-bg-red-900 {
    --bg-opacity: 1;
    background-color: #742a2a;
    background-color: rgba(116, 42, 42, var(--bg-opacity));
  }

  .xl\:tw-bg-orange-100 {
    --bg-opacity: 1;
    background-color: #fffaf0;
    background-color: rgba(255, 250, 240, var(--bg-opacity));
  }

  .xl\:tw-bg-orange-200 {
    --bg-opacity: 1;
    background-color: #feebc8;
    background-color: rgba(254, 235, 200, var(--bg-opacity));
  }

  .xl\:tw-bg-orange-300 {
    --bg-opacity: 1;
    background-color: #fbd38d;
    background-color: rgba(251, 211, 141, var(--bg-opacity));
  }

  .xl\:tw-bg-orange-400 {
    --bg-opacity: 1;
    background-color: #f6ad55;
    background-color: rgba(246, 173, 85, var(--bg-opacity));
  }

  .xl\:tw-bg-orange-500 {
    --bg-opacity: 1;
    background-color: #ed8936;
    background-color: rgba(237, 137, 54, var(--bg-opacity));
  }

  .xl\:tw-bg-orange-600 {
    --bg-opacity: 1;
    background-color: #dd6b20;
    background-color: rgba(221, 107, 32, var(--bg-opacity));
  }

  .xl\:tw-bg-orange-700 {
    --bg-opacity: 1;
    background-color: #c05621;
    background-color: rgba(192, 86, 33, var(--bg-opacity));
  }

  .xl\:tw-bg-orange-800 {
    --bg-opacity: 1;
    background-color: #9c4221;
    background-color: rgba(156, 66, 33, var(--bg-opacity));
  }

  .xl\:tw-bg-orange-900 {
    --bg-opacity: 1;
    background-color: #7b341e;
    background-color: rgba(123, 52, 30, var(--bg-opacity));
  }

  .xl\:tw-bg-yellow-100 {
    --bg-opacity: 1;
    background-color: #fffff0;
    background-color: rgba(255, 255, 240, var(--bg-opacity));
  }

  .xl\:tw-bg-yellow-200 {
    --bg-opacity: 1;
    background-color: #fefcbf;
    background-color: rgba(254, 252, 191, var(--bg-opacity));
  }

  .xl\:tw-bg-yellow-300 {
    --bg-opacity: 1;
    background-color: #faf089;
    background-color: rgba(250, 240, 137, var(--bg-opacity));
  }

  .xl\:tw-bg-yellow-400 {
    --bg-opacity: 1;
    background-color: #f6e05e;
    background-color: rgba(246, 224, 94, var(--bg-opacity));
  }

  .xl\:tw-bg-yellow-500 {
    --bg-opacity: 1;
    background-color: #ecc94b;
    background-color: rgba(236, 201, 75, var(--bg-opacity));
  }

  .xl\:tw-bg-yellow-600 {
    --bg-opacity: 1;
    background-color: #d69e2e;
    background-color: rgba(214, 158, 46, var(--bg-opacity));
  }

  .xl\:tw-bg-yellow-700 {
    --bg-opacity: 1;
    background-color: #b7791f;
    background-color: rgba(183, 121, 31, var(--bg-opacity));
  }

  .xl\:tw-bg-yellow-800 {
    --bg-opacity: 1;
    background-color: #975a16;
    background-color: rgba(151, 90, 22, var(--bg-opacity));
  }

  .xl\:tw-bg-yellow-900 {
    --bg-opacity: 1;
    background-color: #744210;
    background-color: rgba(116, 66, 16, var(--bg-opacity));
  }

  .xl\:tw-bg-green-100 {
    --bg-opacity: 1;
    background-color: #f0fff4;
    background-color: rgba(240, 255, 244, var(--bg-opacity));
  }

  .xl\:tw-bg-green-200 {
    --bg-opacity: 1;
    background-color: #c6f6d5;
    background-color: rgba(198, 246, 213, var(--bg-opacity));
  }

  .xl\:tw-bg-green-300 {
    --bg-opacity: 1;
    background-color: #9ae6b4;
    background-color: rgba(154, 230, 180, var(--bg-opacity));
  }

  .xl\:tw-bg-green-400 {
    --bg-opacity: 1;
    background-color: #68d391;
    background-color: rgba(104, 211, 145, var(--bg-opacity));
  }

  .xl\:tw-bg-green-500 {
    --bg-opacity: 1;
    background-color: #48bb78;
    background-color: rgba(72, 187, 120, var(--bg-opacity));
  }

  .xl\:tw-bg-green-600 {
    --bg-opacity: 1;
    background-color: #38a169;
    background-color: rgba(56, 161, 105, var(--bg-opacity));
  }

  .xl\:tw-bg-green-700 {
    --bg-opacity: 1;
    background-color: #2f855a;
    background-color: rgba(47, 133, 90, var(--bg-opacity));
  }

  .xl\:tw-bg-green-800 {
    --bg-opacity: 1;
    background-color: #276749;
    background-color: rgba(39, 103, 73, var(--bg-opacity));
  }

  .xl\:tw-bg-green-900 {
    --bg-opacity: 1;
    background-color: #22543d;
    background-color: rgba(34, 84, 61, var(--bg-opacity));
  }

  .xl\:tw-bg-teal-100 {
    --bg-opacity: 1;
    background-color: #e6fffa;
    background-color: rgba(230, 255, 250, var(--bg-opacity));
  }

  .xl\:tw-bg-teal-200 {
    --bg-opacity: 1;
    background-color: #b2f5ea;
    background-color: rgba(178, 245, 234, var(--bg-opacity));
  }

  .xl\:tw-bg-teal-300 {
    --bg-opacity: 1;
    background-color: #81e6d9;
    background-color: rgba(129, 230, 217, var(--bg-opacity));
  }

  .xl\:tw-bg-teal-400 {
    --bg-opacity: 1;
    background-color: #4fd1c5;
    background-color: rgba(79, 209, 197, var(--bg-opacity));
  }

  .xl\:tw-bg-teal-500 {
    --bg-opacity: 1;
    background-color: #38b2ac;
    background-color: rgba(56, 178, 172, var(--bg-opacity));
  }

  .xl\:tw-bg-teal-600 {
    --bg-opacity: 1;
    background-color: #319795;
    background-color: rgba(49, 151, 149, var(--bg-opacity));
  }

  .xl\:tw-bg-teal-700 {
    --bg-opacity: 1;
    background-color: #2c7a7b;
    background-color: rgba(44, 122, 123, var(--bg-opacity));
  }

  .xl\:tw-bg-teal-800 {
    --bg-opacity: 1;
    background-color: #285e61;
    background-color: rgba(40, 94, 97, var(--bg-opacity));
  }

  .xl\:tw-bg-teal-900 {
    --bg-opacity: 1;
    background-color: #234e52;
    background-color: rgba(35, 78, 82, var(--bg-opacity));
  }

  .xl\:tw-bg-blue-100 {
    --bg-opacity: 1;
    background-color: #ebf8ff;
    background-color: rgba(235, 248, 255, var(--bg-opacity));
  }

  .xl\:tw-bg-blue-200 {
    --bg-opacity: 1;
    background-color: #bee3f8;
    background-color: rgba(190, 227, 248, var(--bg-opacity));
  }

  .xl\:tw-bg-blue-300 {
    --bg-opacity: 1;
    background-color: #90cdf4;
    background-color: rgba(144, 205, 244, var(--bg-opacity));
  }

  .xl\:tw-bg-blue-400 {
    --bg-opacity: 1;
    background-color: #63b3ed;
    background-color: rgba(99, 179, 237, var(--bg-opacity));
  }

  .xl\:tw-bg-blue-500 {
    --bg-opacity: 1;
    background-color: #4299e1;
    background-color: rgba(66, 153, 225, var(--bg-opacity));
  }

  .xl\:tw-bg-blue-600 {
    --bg-opacity: 1;
    background-color: #3182ce;
    background-color: rgba(49, 130, 206, var(--bg-opacity));
  }

  .xl\:tw-bg-blue-700 {
    --bg-opacity: 1;
    background-color: #2b6cb0;
    background-color: rgba(43, 108, 176, var(--bg-opacity));
  }

  .xl\:tw-bg-blue-800 {
    --bg-opacity: 1;
    background-color: #2c5282;
    background-color: rgba(44, 82, 130, var(--bg-opacity));
  }

  .xl\:tw-bg-blue-900 {
    --bg-opacity: 1;
    background-color: #2a4365;
    background-color: rgba(42, 67, 101, var(--bg-opacity));
  }

  .xl\:tw-bg-indigo-100 {
    --bg-opacity: 1;
    background-color: #ebf4ff;
    background-color: rgba(235, 244, 255, var(--bg-opacity));
  }

  .xl\:tw-bg-indigo-200 {
    --bg-opacity: 1;
    background-color: #c3dafe;
    background-color: rgba(195, 218, 254, var(--bg-opacity));
  }

  .xl\:tw-bg-indigo-300 {
    --bg-opacity: 1;
    background-color: #a3bffa;
    background-color: rgba(163, 191, 250, var(--bg-opacity));
  }

  .xl\:tw-bg-indigo-400 {
    --bg-opacity: 1;
    background-color: #7f9cf5;
    background-color: rgba(127, 156, 245, var(--bg-opacity));
  }

  .xl\:tw-bg-indigo-500 {
    --bg-opacity: 1;
    background-color: #667eea;
    background-color: rgba(102, 126, 234, var(--bg-opacity));
  }

  .xl\:tw-bg-indigo-600 {
    --bg-opacity: 1;
    background-color: #5a67d8;
    background-color: rgba(90, 103, 216, var(--bg-opacity));
  }

  .xl\:tw-bg-indigo-700 {
    --bg-opacity: 1;
    background-color: #4c51bf;
    background-color: rgba(76, 81, 191, var(--bg-opacity));
  }

  .xl\:tw-bg-indigo-800 {
    --bg-opacity: 1;
    background-color: #434190;
    background-color: rgba(67, 65, 144, var(--bg-opacity));
  }

  .xl\:tw-bg-indigo-900 {
    --bg-opacity: 1;
    background-color: #3c366b;
    background-color: rgba(60, 54, 107, var(--bg-opacity));
  }

  .xl\:tw-bg-purple-100 {
    --bg-opacity: 1;
    background-color: #faf5ff;
    background-color: rgba(250, 245, 255, var(--bg-opacity));
  }

  .xl\:tw-bg-purple-200 {
    --bg-opacity: 1;
    background-color: #e9d8fd;
    background-color: rgba(233, 216, 253, var(--bg-opacity));
  }

  .xl\:tw-bg-purple-300 {
    --bg-opacity: 1;
    background-color: #d6bcfa;
    background-color: rgba(214, 188, 250, var(--bg-opacity));
  }

  .xl\:tw-bg-purple-400 {
    --bg-opacity: 1;
    background-color: #b794f4;
    background-color: rgba(183, 148, 244, var(--bg-opacity));
  }

  .xl\:tw-bg-purple-500 {
    --bg-opacity: 1;
    background-color: #9f7aea;
    background-color: rgba(159, 122, 234, var(--bg-opacity));
  }

  .xl\:tw-bg-purple-600 {
    --bg-opacity: 1;
    background-color: #805ad5;
    background-color: rgba(128, 90, 213, var(--bg-opacity));
  }

  .xl\:tw-bg-purple-700 {
    --bg-opacity: 1;
    background-color: #6b46c1;
    background-color: rgba(107, 70, 193, var(--bg-opacity));
  }

  .xl\:tw-bg-purple-800 {
    --bg-opacity: 1;
    background-color: #553c9a;
    background-color: rgba(85, 60, 154, var(--bg-opacity));
  }

  .xl\:tw-bg-purple-900 {
    --bg-opacity: 1;
    background-color: #44337a;
    background-color: rgba(68, 51, 122, var(--bg-opacity));
  }

  .xl\:tw-bg-pink-100 {
    --bg-opacity: 1;
    background-color: #fff5f7;
    background-color: rgba(255, 245, 247, var(--bg-opacity));
  }

  .xl\:tw-bg-pink-200 {
    --bg-opacity: 1;
    background-color: #fed7e2;
    background-color: rgba(254, 215, 226, var(--bg-opacity));
  }

  .xl\:tw-bg-pink-300 {
    --bg-opacity: 1;
    background-color: #fbb6ce;
    background-color: rgba(251, 182, 206, var(--bg-opacity));
  }

  .xl\:tw-bg-pink-400 {
    --bg-opacity: 1;
    background-color: #f687b3;
    background-color: rgba(246, 135, 179, var(--bg-opacity));
  }

  .xl\:tw-bg-pink-500 {
    --bg-opacity: 1;
    background-color: #ed64a6;
    background-color: rgba(237, 100, 166, var(--bg-opacity));
  }

  .xl\:tw-bg-pink-600 {
    --bg-opacity: 1;
    background-color: #d53f8c;
    background-color: rgba(213, 63, 140, var(--bg-opacity));
  }

  .xl\:tw-bg-pink-700 {
    --bg-opacity: 1;
    background-color: #b83280;
    background-color: rgba(184, 50, 128, var(--bg-opacity));
  }

  .xl\:tw-bg-pink-800 {
    --bg-opacity: 1;
    background-color: #97266d;
    background-color: rgba(151, 38, 109, var(--bg-opacity));
  }

  .xl\:tw-bg-pink-900 {
    --bg-opacity: 1;
    background-color: #702459;
    background-color: rgba(112, 36, 89, var(--bg-opacity));
  }

  .xl\:hover\:tw-bg-transparent:hover {
    background-color: transparent;
  }

  .xl\:hover\:tw-bg-current:hover {
    background-color: currentColor;
  }

  .xl\:hover\:tw-bg-black:hover {
    --bg-opacity: 1;
    background-color: #000;
    background-color: rgba(0, 0, 0, var(--bg-opacity));
  }

  .xl\:hover\:tw-bg-white:hover {
    --bg-opacity: 1;
    background-color: #fff;
    background-color: rgba(255, 255, 255, var(--bg-opacity));
  }

  .xl\:hover\:tw-bg-gray-100:hover {
    --bg-opacity: 1;
    background-color: #f7fafc;
    background-color: rgba(247, 250, 252, var(--bg-opacity));
  }

  .xl\:hover\:tw-bg-gray-200:hover {
    --bg-opacity: 1;
    background-color: #edf2f7;
    background-color: rgba(237, 242, 247, var(--bg-opacity));
  }

  .xl\:hover\:tw-bg-gray-300:hover {
    --bg-opacity: 1;
    background-color: #e2e8f0;
    background-color: rgba(226, 232, 240, var(--bg-opacity));
  }

  .xl\:hover\:tw-bg-gray-400:hover {
    --bg-opacity: 1;
    background-color: #cbd5e0;
    background-color: rgba(203, 213, 224, var(--bg-opacity));
  }

  .xl\:hover\:tw-bg-gray-500:hover {
    --bg-opacity: 1;
    background-color: #a0aec0;
    background-color: rgba(160, 174, 192, var(--bg-opacity));
  }

  .xl\:hover\:tw-bg-gray-600:hover {
    --bg-opacity: 1;
    background-color: #718096;
    background-color: rgba(113, 128, 150, var(--bg-opacity));
  }

  .xl\:hover\:tw-bg-gray-700:hover {
    --bg-opacity: 1;
    background-color: #4a5568;
    background-color: rgba(74, 85, 104, var(--bg-opacity));
  }

  .xl\:hover\:tw-bg-gray-800:hover {
    --bg-opacity: 1;
    background-color: #2d3748;
    background-color: rgba(45, 55, 72, var(--bg-opacity));
  }

  .xl\:hover\:tw-bg-gray-900:hover {
    --bg-opacity: 1;
    background-color: #1a202c;
    background-color: rgba(26, 32, 44, var(--bg-opacity));
  }

  .xl\:hover\:tw-bg-red-100:hover {
    --bg-opacity: 1;
    background-color: #fff5f5;
    background-color: rgba(255, 245, 245, var(--bg-opacity));
  }

  .xl\:hover\:tw-bg-red-200:hover {
    --bg-opacity: 1;
    background-color: #fed7d7;
    background-color: rgba(254, 215, 215, var(--bg-opacity));
  }

  .xl\:hover\:tw-bg-red-300:hover {
    --bg-opacity: 1;
    background-color: #feb2b2;
    background-color: rgba(254, 178, 178, var(--bg-opacity));
  }

  .xl\:hover\:tw-bg-red-400:hover {
    --bg-opacity: 1;
    background-color: #fc8181;
    background-color: rgba(252, 129, 129, var(--bg-opacity));
  }

  .xl\:hover\:tw-bg-red-500:hover {
    --bg-opacity: 1;
    background-color: #f56565;
    background-color: rgba(245, 101, 101, var(--bg-opacity));
  }

  .xl\:hover\:tw-bg-red-600:hover {
    --bg-opacity: 1;
    background-color: #e53e3e;
    background-color: rgba(229, 62, 62, var(--bg-opacity));
  }

  .xl\:hover\:tw-bg-red-700:hover {
    --bg-opacity: 1;
    background-color: #c53030;
    background-color: rgba(197, 48, 48, var(--bg-opacity));
  }

  .xl\:hover\:tw-bg-red-800:hover {
    --bg-opacity: 1;
    background-color: #9b2c2c;
    background-color: rgba(155, 44, 44, var(--bg-opacity));
  }

  .xl\:hover\:tw-bg-red-900:hover {
    --bg-opacity: 1;
    background-color: #742a2a;
    background-color: rgba(116, 42, 42, var(--bg-opacity));
  }

  .xl\:hover\:tw-bg-orange-100:hover {
    --bg-opacity: 1;
    background-color: #fffaf0;
    background-color: rgba(255, 250, 240, var(--bg-opacity));
  }

  .xl\:hover\:tw-bg-orange-200:hover {
    --bg-opacity: 1;
    background-color: #feebc8;
    background-color: rgba(254, 235, 200, var(--bg-opacity));
  }

  .xl\:hover\:tw-bg-orange-300:hover {
    --bg-opacity: 1;
    background-color: #fbd38d;
    background-color: rgba(251, 211, 141, var(--bg-opacity));
  }

  .xl\:hover\:tw-bg-orange-400:hover {
    --bg-opacity: 1;
    background-color: #f6ad55;
    background-color: rgba(246, 173, 85, var(--bg-opacity));
  }

  .xl\:hover\:tw-bg-orange-500:hover {
    --bg-opacity: 1;
    background-color: #ed8936;
    background-color: rgba(237, 137, 54, var(--bg-opacity));
  }

  .xl\:hover\:tw-bg-orange-600:hover {
    --bg-opacity: 1;
    background-color: #dd6b20;
    background-color: rgba(221, 107, 32, var(--bg-opacity));
  }

  .xl\:hover\:tw-bg-orange-700:hover {
    --bg-opacity: 1;
    background-color: #c05621;
    background-color: rgba(192, 86, 33, var(--bg-opacity));
  }

  .xl\:hover\:tw-bg-orange-800:hover {
    --bg-opacity: 1;
    background-color: #9c4221;
    background-color: rgba(156, 66, 33, var(--bg-opacity));
  }

  .xl\:hover\:tw-bg-orange-900:hover {
    --bg-opacity: 1;
    background-color: #7b341e;
    background-color: rgba(123, 52, 30, var(--bg-opacity));
  }

  .xl\:hover\:tw-bg-yellow-100:hover {
    --bg-opacity: 1;
    background-color: #fffff0;
    background-color: rgba(255, 255, 240, var(--bg-opacity));
  }

  .xl\:hover\:tw-bg-yellow-200:hover {
    --bg-opacity: 1;
    background-color: #fefcbf;
    background-color: rgba(254, 252, 191, var(--bg-opacity));
  }

  .xl\:hover\:tw-bg-yellow-300:hover {
    --bg-opacity: 1;
    background-color: #faf089;
    background-color: rgba(250, 240, 137, var(--bg-opacity));
  }

  .xl\:hover\:tw-bg-yellow-400:hover {
    --bg-opacity: 1;
    background-color: #f6e05e;
    background-color: rgba(246, 224, 94, var(--bg-opacity));
  }

  .xl\:hover\:tw-bg-yellow-500:hover {
    --bg-opacity: 1;
    background-color: #ecc94b;
    background-color: rgba(236, 201, 75, var(--bg-opacity));
  }

  .xl\:hover\:tw-bg-yellow-600:hover {
    --bg-opacity: 1;
    background-color: #d69e2e;
    background-color: rgba(214, 158, 46, var(--bg-opacity));
  }

  .xl\:hover\:tw-bg-yellow-700:hover {
    --bg-opacity: 1;
    background-color: #b7791f;
    background-color: rgba(183, 121, 31, var(--bg-opacity));
  }

  .xl\:hover\:tw-bg-yellow-800:hover {
    --bg-opacity: 1;
    background-color: #975a16;
    background-color: rgba(151, 90, 22, var(--bg-opacity));
  }

  .xl\:hover\:tw-bg-yellow-900:hover {
    --bg-opacity: 1;
    background-color: #744210;
    background-color: rgba(116, 66, 16, var(--bg-opacity));
  }

  .xl\:hover\:tw-bg-green-100:hover {
    --bg-opacity: 1;
    background-color: #f0fff4;
    background-color: rgba(240, 255, 244, var(--bg-opacity));
  }

  .xl\:hover\:tw-bg-green-200:hover {
    --bg-opacity: 1;
    background-color: #c6f6d5;
    background-color: rgba(198, 246, 213, var(--bg-opacity));
  }

  .xl\:hover\:tw-bg-green-300:hover {
    --bg-opacity: 1;
    background-color: #9ae6b4;
    background-color: rgba(154, 230, 180, var(--bg-opacity));
  }

  .xl\:hover\:tw-bg-green-400:hover {
    --bg-opacity: 1;
    background-color: #68d391;
    background-color: rgba(104, 211, 145, var(--bg-opacity));
  }

  .xl\:hover\:tw-bg-green-500:hover {
    --bg-opacity: 1;
    background-color: #48bb78;
    background-color: rgba(72, 187, 120, var(--bg-opacity));
  }

  .xl\:hover\:tw-bg-green-600:hover {
    --bg-opacity: 1;
    background-color: #38a169;
    background-color: rgba(56, 161, 105, var(--bg-opacity));
  }

  .xl\:hover\:tw-bg-green-700:hover {
    --bg-opacity: 1;
    background-color: #2f855a;
    background-color: rgba(47, 133, 90, var(--bg-opacity));
  }

  .xl\:hover\:tw-bg-green-800:hover {
    --bg-opacity: 1;
    background-color: #276749;
    background-color: rgba(39, 103, 73, var(--bg-opacity));
  }

  .xl\:hover\:tw-bg-green-900:hover {
    --bg-opacity: 1;
    background-color: #22543d;
    background-color: rgba(34, 84, 61, var(--bg-opacity));
  }

  .xl\:hover\:tw-bg-teal-100:hover {
    --bg-opacity: 1;
    background-color: #e6fffa;
    background-color: rgba(230, 255, 250, var(--bg-opacity));
  }

  .xl\:hover\:tw-bg-teal-200:hover {
    --bg-opacity: 1;
    background-color: #b2f5ea;
    background-color: rgba(178, 245, 234, var(--bg-opacity));
  }

  .xl\:hover\:tw-bg-teal-300:hover {
    --bg-opacity: 1;
    background-color: #81e6d9;
    background-color: rgba(129, 230, 217, var(--bg-opacity));
  }

  .xl\:hover\:tw-bg-teal-400:hover {
    --bg-opacity: 1;
    background-color: #4fd1c5;
    background-color: rgba(79, 209, 197, var(--bg-opacity));
  }

  .xl\:hover\:tw-bg-teal-500:hover {
    --bg-opacity: 1;
    background-color: #38b2ac;
    background-color: rgba(56, 178, 172, var(--bg-opacity));
  }

  .xl\:hover\:tw-bg-teal-600:hover {
    --bg-opacity: 1;
    background-color: #319795;
    background-color: rgba(49, 151, 149, var(--bg-opacity));
  }

  .xl\:hover\:tw-bg-teal-700:hover {
    --bg-opacity: 1;
    background-color: #2c7a7b;
    background-color: rgba(44, 122, 123, var(--bg-opacity));
  }

  .xl\:hover\:tw-bg-teal-800:hover {
    --bg-opacity: 1;
    background-color: #285e61;
    background-color: rgba(40, 94, 97, var(--bg-opacity));
  }

  .xl\:hover\:tw-bg-teal-900:hover {
    --bg-opacity: 1;
    background-color: #234e52;
    background-color: rgba(35, 78, 82, var(--bg-opacity));
  }

  .xl\:hover\:tw-bg-blue-100:hover {
    --bg-opacity: 1;
    background-color: #ebf8ff;
    background-color: rgba(235, 248, 255, var(--bg-opacity));
  }

  .xl\:hover\:tw-bg-blue-200:hover {
    --bg-opacity: 1;
    background-color: #bee3f8;
    background-color: rgba(190, 227, 248, var(--bg-opacity));
  }

  .xl\:hover\:tw-bg-blue-300:hover {
    --bg-opacity: 1;
    background-color: #90cdf4;
    background-color: rgba(144, 205, 244, var(--bg-opacity));
  }

  .xl\:hover\:tw-bg-blue-400:hover {
    --bg-opacity: 1;
    background-color: #63b3ed;
    background-color: rgba(99, 179, 237, var(--bg-opacity));
  }

  .xl\:hover\:tw-bg-blue-500:hover {
    --bg-opacity: 1;
    background-color: #4299e1;
    background-color: rgba(66, 153, 225, var(--bg-opacity));
  }

  .xl\:hover\:tw-bg-blue-600:hover {
    --bg-opacity: 1;
    background-color: #3182ce;
    background-color: rgba(49, 130, 206, var(--bg-opacity));
  }

  .xl\:hover\:tw-bg-blue-700:hover {
    --bg-opacity: 1;
    background-color: #2b6cb0;
    background-color: rgba(43, 108, 176, var(--bg-opacity));
  }

  .xl\:hover\:tw-bg-blue-800:hover {
    --bg-opacity: 1;
    background-color: #2c5282;
    background-color: rgba(44, 82, 130, var(--bg-opacity));
  }

  .xl\:hover\:tw-bg-blue-900:hover {
    --bg-opacity: 1;
    background-color: #2a4365;
    background-color: rgba(42, 67, 101, var(--bg-opacity));
  }

  .xl\:hover\:tw-bg-indigo-100:hover {
    --bg-opacity: 1;
    background-color: #ebf4ff;
    background-color: rgba(235, 244, 255, var(--bg-opacity));
  }

  .xl\:hover\:tw-bg-indigo-200:hover {
    --bg-opacity: 1;
    background-color: #c3dafe;
    background-color: rgba(195, 218, 254, var(--bg-opacity));
  }

  .xl\:hover\:tw-bg-indigo-300:hover {
    --bg-opacity: 1;
    background-color: #a3bffa;
    background-color: rgba(163, 191, 250, var(--bg-opacity));
  }

  .xl\:hover\:tw-bg-indigo-400:hover {
    --bg-opacity: 1;
    background-color: #7f9cf5;
    background-color: rgba(127, 156, 245, var(--bg-opacity));
  }

  .xl\:hover\:tw-bg-indigo-500:hover {
    --bg-opacity: 1;
    background-color: #667eea;
    background-color: rgba(102, 126, 234, var(--bg-opacity));
  }

  .xl\:hover\:tw-bg-indigo-600:hover {
    --bg-opacity: 1;
    background-color: #5a67d8;
    background-color: rgba(90, 103, 216, var(--bg-opacity));
  }

  .xl\:hover\:tw-bg-indigo-700:hover {
    --bg-opacity: 1;
    background-color: #4c51bf;
    background-color: rgba(76, 81, 191, var(--bg-opacity));
  }

  .xl\:hover\:tw-bg-indigo-800:hover {
    --bg-opacity: 1;
    background-color: #434190;
    background-color: rgba(67, 65, 144, var(--bg-opacity));
  }

  .xl\:hover\:tw-bg-indigo-900:hover {
    --bg-opacity: 1;
    background-color: #3c366b;
    background-color: rgba(60, 54, 107, var(--bg-opacity));
  }

  .xl\:hover\:tw-bg-purple-100:hover {
    --bg-opacity: 1;
    background-color: #faf5ff;
    background-color: rgba(250, 245, 255, var(--bg-opacity));
  }

  .xl\:hover\:tw-bg-purple-200:hover {
    --bg-opacity: 1;
    background-color: #e9d8fd;
    background-color: rgba(233, 216, 253, var(--bg-opacity));
  }

  .xl\:hover\:tw-bg-purple-300:hover {
    --bg-opacity: 1;
    background-color: #d6bcfa;
    background-color: rgba(214, 188, 250, var(--bg-opacity));
  }

  .xl\:hover\:tw-bg-purple-400:hover {
    --bg-opacity: 1;
    background-color: #b794f4;
    background-color: rgba(183, 148, 244, var(--bg-opacity));
  }

  .xl\:hover\:tw-bg-purple-500:hover {
    --bg-opacity: 1;
    background-color: #9f7aea;
    background-color: rgba(159, 122, 234, var(--bg-opacity));
  }

  .xl\:hover\:tw-bg-purple-600:hover {
    --bg-opacity: 1;
    background-color: #805ad5;
    background-color: rgba(128, 90, 213, var(--bg-opacity));
  }

  .xl\:hover\:tw-bg-purple-700:hover {
    --bg-opacity: 1;
    background-color: #6b46c1;
    background-color: rgba(107, 70, 193, var(--bg-opacity));
  }

  .xl\:hover\:tw-bg-purple-800:hover {
    --bg-opacity: 1;
    background-color: #553c9a;
    background-color: rgba(85, 60, 154, var(--bg-opacity));
  }

  .xl\:hover\:tw-bg-purple-900:hover {
    --bg-opacity: 1;
    background-color: #44337a;
    background-color: rgba(68, 51, 122, var(--bg-opacity));
  }

  .xl\:hover\:tw-bg-pink-100:hover {
    --bg-opacity: 1;
    background-color: #fff5f7;
    background-color: rgba(255, 245, 247, var(--bg-opacity));
  }

  .xl\:hover\:tw-bg-pink-200:hover {
    --bg-opacity: 1;
    background-color: #fed7e2;
    background-color: rgba(254, 215, 226, var(--bg-opacity));
  }

  .xl\:hover\:tw-bg-pink-300:hover {
    --bg-opacity: 1;
    background-color: #fbb6ce;
    background-color: rgba(251, 182, 206, var(--bg-opacity));
  }

  .xl\:hover\:tw-bg-pink-400:hover {
    --bg-opacity: 1;
    background-color: #f687b3;
    background-color: rgba(246, 135, 179, var(--bg-opacity));
  }

  .xl\:hover\:tw-bg-pink-500:hover {
    --bg-opacity: 1;
    background-color: #ed64a6;
    background-color: rgba(237, 100, 166, var(--bg-opacity));
  }

  .xl\:hover\:tw-bg-pink-600:hover {
    --bg-opacity: 1;
    background-color: #d53f8c;
    background-color: rgba(213, 63, 140, var(--bg-opacity));
  }

  .xl\:hover\:tw-bg-pink-700:hover {
    --bg-opacity: 1;
    background-color: #b83280;
    background-color: rgba(184, 50, 128, var(--bg-opacity));
  }

  .xl\:hover\:tw-bg-pink-800:hover {
    --bg-opacity: 1;
    background-color: #97266d;
    background-color: rgba(151, 38, 109, var(--bg-opacity));
  }

  .xl\:hover\:tw-bg-pink-900:hover {
    --bg-opacity: 1;
    background-color: #702459;
    background-color: rgba(112, 36, 89, var(--bg-opacity));
  }

  .xl\:focus\:tw-bg-transparent:focus {
    background-color: transparent;
  }

  .xl\:focus\:tw-bg-current:focus {
    background-color: currentColor;
  }

  .xl\:focus\:tw-bg-black:focus {
    --bg-opacity: 1;
    background-color: #000;
    background-color: rgba(0, 0, 0, var(--bg-opacity));
  }

  .xl\:focus\:tw-bg-white:focus {
    --bg-opacity: 1;
    background-color: #fff;
    background-color: rgba(255, 255, 255, var(--bg-opacity));
  }

  .xl\:focus\:tw-bg-gray-100:focus {
    --bg-opacity: 1;
    background-color: #f7fafc;
    background-color: rgba(247, 250, 252, var(--bg-opacity));
  }

  .xl\:focus\:tw-bg-gray-200:focus {
    --bg-opacity: 1;
    background-color: #edf2f7;
    background-color: rgba(237, 242, 247, var(--bg-opacity));
  }

  .xl\:focus\:tw-bg-gray-300:focus {
    --bg-opacity: 1;
    background-color: #e2e8f0;
    background-color: rgba(226, 232, 240, var(--bg-opacity));
  }

  .xl\:focus\:tw-bg-gray-400:focus {
    --bg-opacity: 1;
    background-color: #cbd5e0;
    background-color: rgba(203, 213, 224, var(--bg-opacity));
  }

  .xl\:focus\:tw-bg-gray-500:focus {
    --bg-opacity: 1;
    background-color: #a0aec0;
    background-color: rgba(160, 174, 192, var(--bg-opacity));
  }

  .xl\:focus\:tw-bg-gray-600:focus {
    --bg-opacity: 1;
    background-color: #718096;
    background-color: rgba(113, 128, 150, var(--bg-opacity));
  }

  .xl\:focus\:tw-bg-gray-700:focus {
    --bg-opacity: 1;
    background-color: #4a5568;
    background-color: rgba(74, 85, 104, var(--bg-opacity));
  }

  .xl\:focus\:tw-bg-gray-800:focus {
    --bg-opacity: 1;
    background-color: #2d3748;
    background-color: rgba(45, 55, 72, var(--bg-opacity));
  }

  .xl\:focus\:tw-bg-gray-900:focus {
    --bg-opacity: 1;
    background-color: #1a202c;
    background-color: rgba(26, 32, 44, var(--bg-opacity));
  }

  .xl\:focus\:tw-bg-red-100:focus {
    --bg-opacity: 1;
    background-color: #fff5f5;
    background-color: rgba(255, 245, 245, var(--bg-opacity));
  }

  .xl\:focus\:tw-bg-red-200:focus {
    --bg-opacity: 1;
    background-color: #fed7d7;
    background-color: rgba(254, 215, 215, var(--bg-opacity));
  }

  .xl\:focus\:tw-bg-red-300:focus {
    --bg-opacity: 1;
    background-color: #feb2b2;
    background-color: rgba(254, 178, 178, var(--bg-opacity));
  }

  .xl\:focus\:tw-bg-red-400:focus {
    --bg-opacity: 1;
    background-color: #fc8181;
    background-color: rgba(252, 129, 129, var(--bg-opacity));
  }

  .xl\:focus\:tw-bg-red-500:focus {
    --bg-opacity: 1;
    background-color: #f56565;
    background-color: rgba(245, 101, 101, var(--bg-opacity));
  }

  .xl\:focus\:tw-bg-red-600:focus {
    --bg-opacity: 1;
    background-color: #e53e3e;
    background-color: rgba(229, 62, 62, var(--bg-opacity));
  }

  .xl\:focus\:tw-bg-red-700:focus {
    --bg-opacity: 1;
    background-color: #c53030;
    background-color: rgba(197, 48, 48, var(--bg-opacity));
  }

  .xl\:focus\:tw-bg-red-800:focus {
    --bg-opacity: 1;
    background-color: #9b2c2c;
    background-color: rgba(155, 44, 44, var(--bg-opacity));
  }

  .xl\:focus\:tw-bg-red-900:focus {
    --bg-opacity: 1;
    background-color: #742a2a;
    background-color: rgba(116, 42, 42, var(--bg-opacity));
  }

  .xl\:focus\:tw-bg-orange-100:focus {
    --bg-opacity: 1;
    background-color: #fffaf0;
    background-color: rgba(255, 250, 240, var(--bg-opacity));
  }

  .xl\:focus\:tw-bg-orange-200:focus {
    --bg-opacity: 1;
    background-color: #feebc8;
    background-color: rgba(254, 235, 200, var(--bg-opacity));
  }

  .xl\:focus\:tw-bg-orange-300:focus {
    --bg-opacity: 1;
    background-color: #fbd38d;
    background-color: rgba(251, 211, 141, var(--bg-opacity));
  }

  .xl\:focus\:tw-bg-orange-400:focus {
    --bg-opacity: 1;
    background-color: #f6ad55;
    background-color: rgba(246, 173, 85, var(--bg-opacity));
  }

  .xl\:focus\:tw-bg-orange-500:focus {
    --bg-opacity: 1;
    background-color: #ed8936;
    background-color: rgba(237, 137, 54, var(--bg-opacity));
  }

  .xl\:focus\:tw-bg-orange-600:focus {
    --bg-opacity: 1;
    background-color: #dd6b20;
    background-color: rgba(221, 107, 32, var(--bg-opacity));
  }

  .xl\:focus\:tw-bg-orange-700:focus {
    --bg-opacity: 1;
    background-color: #c05621;
    background-color: rgba(192, 86, 33, var(--bg-opacity));
  }

  .xl\:focus\:tw-bg-orange-800:focus {
    --bg-opacity: 1;
    background-color: #9c4221;
    background-color: rgba(156, 66, 33, var(--bg-opacity));
  }

  .xl\:focus\:tw-bg-orange-900:focus {
    --bg-opacity: 1;
    background-color: #7b341e;
    background-color: rgba(123, 52, 30, var(--bg-opacity));
  }

  .xl\:focus\:tw-bg-yellow-100:focus {
    --bg-opacity: 1;
    background-color: #fffff0;
    background-color: rgba(255, 255, 240, var(--bg-opacity));
  }

  .xl\:focus\:tw-bg-yellow-200:focus {
    --bg-opacity: 1;
    background-color: #fefcbf;
    background-color: rgba(254, 252, 191, var(--bg-opacity));
  }

  .xl\:focus\:tw-bg-yellow-300:focus {
    --bg-opacity: 1;
    background-color: #faf089;
    background-color: rgba(250, 240, 137, var(--bg-opacity));
  }

  .xl\:focus\:tw-bg-yellow-400:focus {
    --bg-opacity: 1;
    background-color: #f6e05e;
    background-color: rgba(246, 224, 94, var(--bg-opacity));
  }

  .xl\:focus\:tw-bg-yellow-500:focus {
    --bg-opacity: 1;
    background-color: #ecc94b;
    background-color: rgba(236, 201, 75, var(--bg-opacity));
  }

  .xl\:focus\:tw-bg-yellow-600:focus {
    --bg-opacity: 1;
    background-color: #d69e2e;
    background-color: rgba(214, 158, 46, var(--bg-opacity));
  }

  .xl\:focus\:tw-bg-yellow-700:focus {
    --bg-opacity: 1;
    background-color: #b7791f;
    background-color: rgba(183, 121, 31, var(--bg-opacity));
  }

  .xl\:focus\:tw-bg-yellow-800:focus {
    --bg-opacity: 1;
    background-color: #975a16;
    background-color: rgba(151, 90, 22, var(--bg-opacity));
  }

  .xl\:focus\:tw-bg-yellow-900:focus {
    --bg-opacity: 1;
    background-color: #744210;
    background-color: rgba(116, 66, 16, var(--bg-opacity));
  }

  .xl\:focus\:tw-bg-green-100:focus {
    --bg-opacity: 1;
    background-color: #f0fff4;
    background-color: rgba(240, 255, 244, var(--bg-opacity));
  }

  .xl\:focus\:tw-bg-green-200:focus {
    --bg-opacity: 1;
    background-color: #c6f6d5;
    background-color: rgba(198, 246, 213, var(--bg-opacity));
  }

  .xl\:focus\:tw-bg-green-300:focus {
    --bg-opacity: 1;
    background-color: #9ae6b4;
    background-color: rgba(154, 230, 180, var(--bg-opacity));
  }

  .xl\:focus\:tw-bg-green-400:focus {
    --bg-opacity: 1;
    background-color: #68d391;
    background-color: rgba(104, 211, 145, var(--bg-opacity));
  }

  .xl\:focus\:tw-bg-green-500:focus {
    --bg-opacity: 1;
    background-color: #48bb78;
    background-color: rgba(72, 187, 120, var(--bg-opacity));
  }

  .xl\:focus\:tw-bg-green-600:focus {
    --bg-opacity: 1;
    background-color: #38a169;
    background-color: rgba(56, 161, 105, var(--bg-opacity));
  }

  .xl\:focus\:tw-bg-green-700:focus {
    --bg-opacity: 1;
    background-color: #2f855a;
    background-color: rgba(47, 133, 90, var(--bg-opacity));
  }

  .xl\:focus\:tw-bg-green-800:focus {
    --bg-opacity: 1;
    background-color: #276749;
    background-color: rgba(39, 103, 73, var(--bg-opacity));
  }

  .xl\:focus\:tw-bg-green-900:focus {
    --bg-opacity: 1;
    background-color: #22543d;
    background-color: rgba(34, 84, 61, var(--bg-opacity));
  }

  .xl\:focus\:tw-bg-teal-100:focus {
    --bg-opacity: 1;
    background-color: #e6fffa;
    background-color: rgba(230, 255, 250, var(--bg-opacity));
  }

  .xl\:focus\:tw-bg-teal-200:focus {
    --bg-opacity: 1;
    background-color: #b2f5ea;
    background-color: rgba(178, 245, 234, var(--bg-opacity));
  }

  .xl\:focus\:tw-bg-teal-300:focus {
    --bg-opacity: 1;
    background-color: #81e6d9;
    background-color: rgba(129, 230, 217, var(--bg-opacity));
  }

  .xl\:focus\:tw-bg-teal-400:focus {
    --bg-opacity: 1;
    background-color: #4fd1c5;
    background-color: rgba(79, 209, 197, var(--bg-opacity));
  }

  .xl\:focus\:tw-bg-teal-500:focus {
    --bg-opacity: 1;
    background-color: #38b2ac;
    background-color: rgba(56, 178, 172, var(--bg-opacity));
  }

  .xl\:focus\:tw-bg-teal-600:focus {
    --bg-opacity: 1;
    background-color: #319795;
    background-color: rgba(49, 151, 149, var(--bg-opacity));
  }

  .xl\:focus\:tw-bg-teal-700:focus {
    --bg-opacity: 1;
    background-color: #2c7a7b;
    background-color: rgba(44, 122, 123, var(--bg-opacity));
  }

  .xl\:focus\:tw-bg-teal-800:focus {
    --bg-opacity: 1;
    background-color: #285e61;
    background-color: rgba(40, 94, 97, var(--bg-opacity));
  }

  .xl\:focus\:tw-bg-teal-900:focus {
    --bg-opacity: 1;
    background-color: #234e52;
    background-color: rgba(35, 78, 82, var(--bg-opacity));
  }

  .xl\:focus\:tw-bg-blue-100:focus {
    --bg-opacity: 1;
    background-color: #ebf8ff;
    background-color: rgba(235, 248, 255, var(--bg-opacity));
  }

  .xl\:focus\:tw-bg-blue-200:focus {
    --bg-opacity: 1;
    background-color: #bee3f8;
    background-color: rgba(190, 227, 248, var(--bg-opacity));
  }

  .xl\:focus\:tw-bg-blue-300:focus {
    --bg-opacity: 1;
    background-color: #90cdf4;
    background-color: rgba(144, 205, 244, var(--bg-opacity));
  }

  .xl\:focus\:tw-bg-blue-400:focus {
    --bg-opacity: 1;
    background-color: #63b3ed;
    background-color: rgba(99, 179, 237, var(--bg-opacity));
  }

  .xl\:focus\:tw-bg-blue-500:focus {
    --bg-opacity: 1;
    background-color: #4299e1;
    background-color: rgba(66, 153, 225, var(--bg-opacity));
  }

  .xl\:focus\:tw-bg-blue-600:focus {
    --bg-opacity: 1;
    background-color: #3182ce;
    background-color: rgba(49, 130, 206, var(--bg-opacity));
  }

  .xl\:focus\:tw-bg-blue-700:focus {
    --bg-opacity: 1;
    background-color: #2b6cb0;
    background-color: rgba(43, 108, 176, var(--bg-opacity));
  }

  .xl\:focus\:tw-bg-blue-800:focus {
    --bg-opacity: 1;
    background-color: #2c5282;
    background-color: rgba(44, 82, 130, var(--bg-opacity));
  }

  .xl\:focus\:tw-bg-blue-900:focus {
    --bg-opacity: 1;
    background-color: #2a4365;
    background-color: rgba(42, 67, 101, var(--bg-opacity));
  }

  .xl\:focus\:tw-bg-indigo-100:focus {
    --bg-opacity: 1;
    background-color: #ebf4ff;
    background-color: rgba(235, 244, 255, var(--bg-opacity));
  }

  .xl\:focus\:tw-bg-indigo-200:focus {
    --bg-opacity: 1;
    background-color: #c3dafe;
    background-color: rgba(195, 218, 254, var(--bg-opacity));
  }

  .xl\:focus\:tw-bg-indigo-300:focus {
    --bg-opacity: 1;
    background-color: #a3bffa;
    background-color: rgba(163, 191, 250, var(--bg-opacity));
  }

  .xl\:focus\:tw-bg-indigo-400:focus {
    --bg-opacity: 1;
    background-color: #7f9cf5;
    background-color: rgba(127, 156, 245, var(--bg-opacity));
  }

  .xl\:focus\:tw-bg-indigo-500:focus {
    --bg-opacity: 1;
    background-color: #667eea;
    background-color: rgba(102, 126, 234, var(--bg-opacity));
  }

  .xl\:focus\:tw-bg-indigo-600:focus {
    --bg-opacity: 1;
    background-color: #5a67d8;
    background-color: rgba(90, 103, 216, var(--bg-opacity));
  }

  .xl\:focus\:tw-bg-indigo-700:focus {
    --bg-opacity: 1;
    background-color: #4c51bf;
    background-color: rgba(76, 81, 191, var(--bg-opacity));
  }

  .xl\:focus\:tw-bg-indigo-800:focus {
    --bg-opacity: 1;
    background-color: #434190;
    background-color: rgba(67, 65, 144, var(--bg-opacity));
  }

  .xl\:focus\:tw-bg-indigo-900:focus {
    --bg-opacity: 1;
    background-color: #3c366b;
    background-color: rgba(60, 54, 107, var(--bg-opacity));
  }

  .xl\:focus\:tw-bg-purple-100:focus {
    --bg-opacity: 1;
    background-color: #faf5ff;
    background-color: rgba(250, 245, 255, var(--bg-opacity));
  }

  .xl\:focus\:tw-bg-purple-200:focus {
    --bg-opacity: 1;
    background-color: #e9d8fd;
    background-color: rgba(233, 216, 253, var(--bg-opacity));
  }

  .xl\:focus\:tw-bg-purple-300:focus {
    --bg-opacity: 1;
    background-color: #d6bcfa;
    background-color: rgba(214, 188, 250, var(--bg-opacity));
  }

  .xl\:focus\:tw-bg-purple-400:focus {
    --bg-opacity: 1;
    background-color: #b794f4;
    background-color: rgba(183, 148, 244, var(--bg-opacity));
  }

  .xl\:focus\:tw-bg-purple-500:focus {
    --bg-opacity: 1;
    background-color: #9f7aea;
    background-color: rgba(159, 122, 234, var(--bg-opacity));
  }

  .xl\:focus\:tw-bg-purple-600:focus {
    --bg-opacity: 1;
    background-color: #805ad5;
    background-color: rgba(128, 90, 213, var(--bg-opacity));
  }

  .xl\:focus\:tw-bg-purple-700:focus {
    --bg-opacity: 1;
    background-color: #6b46c1;
    background-color: rgba(107, 70, 193, var(--bg-opacity));
  }

  .xl\:focus\:tw-bg-purple-800:focus {
    --bg-opacity: 1;
    background-color: #553c9a;
    background-color: rgba(85, 60, 154, var(--bg-opacity));
  }

  .xl\:focus\:tw-bg-purple-900:focus {
    --bg-opacity: 1;
    background-color: #44337a;
    background-color: rgba(68, 51, 122, var(--bg-opacity));
  }

  .xl\:focus\:tw-bg-pink-100:focus {
    --bg-opacity: 1;
    background-color: #fff5f7;
    background-color: rgba(255, 245, 247, var(--bg-opacity));
  }

  .xl\:focus\:tw-bg-pink-200:focus {
    --bg-opacity: 1;
    background-color: #fed7e2;
    background-color: rgba(254, 215, 226, var(--bg-opacity));
  }

  .xl\:focus\:tw-bg-pink-300:focus {
    --bg-opacity: 1;
    background-color: #fbb6ce;
    background-color: rgba(251, 182, 206, var(--bg-opacity));
  }

  .xl\:focus\:tw-bg-pink-400:focus {
    --bg-opacity: 1;
    background-color: #f687b3;
    background-color: rgba(246, 135, 179, var(--bg-opacity));
  }

  .xl\:focus\:tw-bg-pink-500:focus {
    --bg-opacity: 1;
    background-color: #ed64a6;
    background-color: rgba(237, 100, 166, var(--bg-opacity));
  }

  .xl\:focus\:tw-bg-pink-600:focus {
    --bg-opacity: 1;
    background-color: #d53f8c;
    background-color: rgba(213, 63, 140, var(--bg-opacity));
  }

  .xl\:focus\:tw-bg-pink-700:focus {
    --bg-opacity: 1;
    background-color: #b83280;
    background-color: rgba(184, 50, 128, var(--bg-opacity));
  }

  .xl\:focus\:tw-bg-pink-800:focus {
    --bg-opacity: 1;
    background-color: #97266d;
    background-color: rgba(151, 38, 109, var(--bg-opacity));
  }

  .xl\:focus\:tw-bg-pink-900:focus {
    --bg-opacity: 1;
    background-color: #702459;
    background-color: rgba(112, 36, 89, var(--bg-opacity));
  }

  .xl\:tw-bg-none {
    background-image: none;
  }

  .xl\:tw-bg-gradient-to-t {
    background-image: linear-gradient(to top, var(--gradient-color-stops));
  }

  .xl\:tw-bg-gradient-to-tr {
    background-image: linear-gradient(to top right, var(--gradient-color-stops));
  }

  .xl\:tw-bg-gradient-to-r {
    background-image: linear-gradient(to right, var(--gradient-color-stops));
  }

  .xl\:tw-bg-gradient-to-br {
    background-image: linear-gradient(to bottom right, var(--gradient-color-stops));
  }

  .xl\:tw-bg-gradient-to-b {
    background-image: linear-gradient(to bottom, var(--gradient-color-stops));
  }

  .xl\:tw-bg-gradient-to-bl {
    background-image: linear-gradient(to bottom left, var(--gradient-color-stops));
  }

  .xl\:tw-bg-gradient-to-l {
    background-image: linear-gradient(to left, var(--gradient-color-stops));
  }

  .xl\:tw-bg-gradient-to-tl {
    background-image: linear-gradient(to top left, var(--gradient-color-stops));
  }

  .xl\:tw-bg-wave-pattern {
    background-image: url('../assets/images/nawbar_waves.svg');
  }

  .xl\:tw-from-transparent {
    --gradient-from-color: transparent;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(0, 0, 0, 0));
  }

  .xl\:tw-from-current {
    --gradient-from-color: currentColor;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0));
  }

  .xl\:tw-from-black {
    --gradient-from-color: #000;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(0, 0, 0, 0));
  }

  .xl\:tw-from-white {
    --gradient-from-color: #fff;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0));
  }

  .xl\:tw-from-gray-100 {
    --gradient-from-color: #f7fafc;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(247, 250, 252, 0));
  }

  .xl\:tw-from-gray-200 {
    --gradient-from-color: #edf2f7;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(237, 242, 247, 0));
  }

  .xl\:tw-from-gray-300 {
    --gradient-from-color: #e2e8f0;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(226, 232, 240, 0));
  }

  .xl\:tw-from-gray-400 {
    --gradient-from-color: #cbd5e0;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(203, 213, 224, 0));
  }

  .xl\:tw-from-gray-500 {
    --gradient-from-color: #a0aec0;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(160, 174, 192, 0));
  }

  .xl\:tw-from-gray-600 {
    --gradient-from-color: #718096;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(113, 128, 150, 0));
  }

  .xl\:tw-from-gray-700 {
    --gradient-from-color: #4a5568;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(74, 85, 104, 0));
  }

  .xl\:tw-from-gray-800 {
    --gradient-from-color: #2d3748;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(45, 55, 72, 0));
  }

  .xl\:tw-from-gray-900 {
    --gradient-from-color: #1a202c;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(26, 32, 44, 0));
  }

  .xl\:tw-from-red-100 {
    --gradient-from-color: #fff5f5;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 245, 245, 0));
  }

  .xl\:tw-from-red-200 {
    --gradient-from-color: #fed7d7;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(254, 215, 215, 0));
  }

  .xl\:tw-from-red-300 {
    --gradient-from-color: #feb2b2;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(254, 178, 178, 0));
  }

  .xl\:tw-from-red-400 {
    --gradient-from-color: #fc8181;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(252, 129, 129, 0));
  }

  .xl\:tw-from-red-500 {
    --gradient-from-color: #f56565;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(245, 101, 101, 0));
  }

  .xl\:tw-from-red-600 {
    --gradient-from-color: #e53e3e;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(229, 62, 62, 0));
  }

  .xl\:tw-from-red-700 {
    --gradient-from-color: #c53030;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(197, 48, 48, 0));
  }

  .xl\:tw-from-red-800 {
    --gradient-from-color: #9b2c2c;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(155, 44, 44, 0));
  }

  .xl\:tw-from-red-900 {
    --gradient-from-color: #742a2a;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(116, 42, 42, 0));
  }

  .xl\:tw-from-orange-100 {
    --gradient-from-color: #fffaf0;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 250, 240, 0));
  }

  .xl\:tw-from-orange-200 {
    --gradient-from-color: #feebc8;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(254, 235, 200, 0));
  }

  .xl\:tw-from-orange-300 {
    --gradient-from-color: #fbd38d;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(251, 211, 141, 0));
  }

  .xl\:tw-from-orange-400 {
    --gradient-from-color: #f6ad55;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(246, 173, 85, 0));
  }

  .xl\:tw-from-orange-500 {
    --gradient-from-color: #ed8936;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(237, 137, 54, 0));
  }

  .xl\:tw-from-orange-600 {
    --gradient-from-color: #dd6b20;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(221, 107, 32, 0));
  }

  .xl\:tw-from-orange-700 {
    --gradient-from-color: #c05621;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(192, 86, 33, 0));
  }

  .xl\:tw-from-orange-800 {
    --gradient-from-color: #9c4221;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(156, 66, 33, 0));
  }

  .xl\:tw-from-orange-900 {
    --gradient-from-color: #7b341e;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(123, 52, 30, 0));
  }

  .xl\:tw-from-yellow-100 {
    --gradient-from-color: #fffff0;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 240, 0));
  }

  .xl\:tw-from-yellow-200 {
    --gradient-from-color: #fefcbf;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(254, 252, 191, 0));
  }

  .xl\:tw-from-yellow-300 {
    --gradient-from-color: #faf089;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(250, 240, 137, 0));
  }

  .xl\:tw-from-yellow-400 {
    --gradient-from-color: #f6e05e;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(246, 224, 94, 0));
  }

  .xl\:tw-from-yellow-500 {
    --gradient-from-color: #ecc94b;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(236, 201, 75, 0));
  }

  .xl\:tw-from-yellow-600 {
    --gradient-from-color: #d69e2e;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(214, 158, 46, 0));
  }

  .xl\:tw-from-yellow-700 {
    --gradient-from-color: #b7791f;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(183, 121, 31, 0));
  }

  .xl\:tw-from-yellow-800 {
    --gradient-from-color: #975a16;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(151, 90, 22, 0));
  }

  .xl\:tw-from-yellow-900 {
    --gradient-from-color: #744210;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(116, 66, 16, 0));
  }

  .xl\:tw-from-green-100 {
    --gradient-from-color: #f0fff4;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(240, 255, 244, 0));
  }

  .xl\:tw-from-green-200 {
    --gradient-from-color: #c6f6d5;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(198, 246, 213, 0));
  }

  .xl\:tw-from-green-300 {
    --gradient-from-color: #9ae6b4;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(154, 230, 180, 0));
  }

  .xl\:tw-from-green-400 {
    --gradient-from-color: #68d391;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(104, 211, 145, 0));
  }

  .xl\:tw-from-green-500 {
    --gradient-from-color: #48bb78;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(72, 187, 120, 0));
  }

  .xl\:tw-from-green-600 {
    --gradient-from-color: #38a169;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(56, 161, 105, 0));
  }

  .xl\:tw-from-green-700 {
    --gradient-from-color: #2f855a;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(47, 133, 90, 0));
  }

  .xl\:tw-from-green-800 {
    --gradient-from-color: #276749;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(39, 103, 73, 0));
  }

  .xl\:tw-from-green-900 {
    --gradient-from-color: #22543d;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(34, 84, 61, 0));
  }

  .xl\:tw-from-teal-100 {
    --gradient-from-color: #e6fffa;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(230, 255, 250, 0));
  }

  .xl\:tw-from-teal-200 {
    --gradient-from-color: #b2f5ea;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(178, 245, 234, 0));
  }

  .xl\:tw-from-teal-300 {
    --gradient-from-color: #81e6d9;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(129, 230, 217, 0));
  }

  .xl\:tw-from-teal-400 {
    --gradient-from-color: #4fd1c5;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(79, 209, 197, 0));
  }

  .xl\:tw-from-teal-500 {
    --gradient-from-color: #38b2ac;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(56, 178, 172, 0));
  }

  .xl\:tw-from-teal-600 {
    --gradient-from-color: #319795;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(49, 151, 149, 0));
  }

  .xl\:tw-from-teal-700 {
    --gradient-from-color: #2c7a7b;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(44, 122, 123, 0));
  }

  .xl\:tw-from-teal-800 {
    --gradient-from-color: #285e61;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(40, 94, 97, 0));
  }

  .xl\:tw-from-teal-900 {
    --gradient-from-color: #234e52;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(35, 78, 82, 0));
  }

  .xl\:tw-from-blue-100 {
    --gradient-from-color: #ebf8ff;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(235, 248, 255, 0));
  }

  .xl\:tw-from-blue-200 {
    --gradient-from-color: #bee3f8;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(190, 227, 248, 0));
  }

  .xl\:tw-from-blue-300 {
    --gradient-from-color: #90cdf4;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(144, 205, 244, 0));
  }

  .xl\:tw-from-blue-400 {
    --gradient-from-color: #63b3ed;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(99, 179, 237, 0));
  }

  .xl\:tw-from-blue-500 {
    --gradient-from-color: #4299e1;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(66, 153, 225, 0));
  }

  .xl\:tw-from-blue-600 {
    --gradient-from-color: #3182ce;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(49, 130, 206, 0));
  }

  .xl\:tw-from-blue-700 {
    --gradient-from-color: #2b6cb0;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(43, 108, 176, 0));
  }

  .xl\:tw-from-blue-800 {
    --gradient-from-color: #2c5282;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(44, 82, 130, 0));
  }

  .xl\:tw-from-blue-900 {
    --gradient-from-color: #2a4365;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(42, 67, 101, 0));
  }

  .xl\:tw-from-indigo-100 {
    --gradient-from-color: #ebf4ff;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(235, 244, 255, 0));
  }

  .xl\:tw-from-indigo-200 {
    --gradient-from-color: #c3dafe;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(195, 218, 254, 0));
  }

  .xl\:tw-from-indigo-300 {
    --gradient-from-color: #a3bffa;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(163, 191, 250, 0));
  }

  .xl\:tw-from-indigo-400 {
    --gradient-from-color: #7f9cf5;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(127, 156, 245, 0));
  }

  .xl\:tw-from-indigo-500 {
    --gradient-from-color: #667eea;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(102, 126, 234, 0));
  }

  .xl\:tw-from-indigo-600 {
    --gradient-from-color: #5a67d8;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(90, 103, 216, 0));
  }

  .xl\:tw-from-indigo-700 {
    --gradient-from-color: #4c51bf;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(76, 81, 191, 0));
  }

  .xl\:tw-from-indigo-800 {
    --gradient-from-color: #434190;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(67, 65, 144, 0));
  }

  .xl\:tw-from-indigo-900 {
    --gradient-from-color: #3c366b;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(60, 54, 107, 0));
  }

  .xl\:tw-from-purple-100 {
    --gradient-from-color: #faf5ff;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(250, 245, 255, 0));
  }

  .xl\:tw-from-purple-200 {
    --gradient-from-color: #e9d8fd;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(233, 216, 253, 0));
  }

  .xl\:tw-from-purple-300 {
    --gradient-from-color: #d6bcfa;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(214, 188, 250, 0));
  }

  .xl\:tw-from-purple-400 {
    --gradient-from-color: #b794f4;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(183, 148, 244, 0));
  }

  .xl\:tw-from-purple-500 {
    --gradient-from-color: #9f7aea;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(159, 122, 234, 0));
  }

  .xl\:tw-from-purple-600 {
    --gradient-from-color: #805ad5;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(128, 90, 213, 0));
  }

  .xl\:tw-from-purple-700 {
    --gradient-from-color: #6b46c1;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(107, 70, 193, 0));
  }

  .xl\:tw-from-purple-800 {
    --gradient-from-color: #553c9a;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(85, 60, 154, 0));
  }

  .xl\:tw-from-purple-900 {
    --gradient-from-color: #44337a;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(68, 51, 122, 0));
  }

  .xl\:tw-from-pink-100 {
    --gradient-from-color: #fff5f7;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 245, 247, 0));
  }

  .xl\:tw-from-pink-200 {
    --gradient-from-color: #fed7e2;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(254, 215, 226, 0));
  }

  .xl\:tw-from-pink-300 {
    --gradient-from-color: #fbb6ce;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(251, 182, 206, 0));
  }

  .xl\:tw-from-pink-400 {
    --gradient-from-color: #f687b3;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(246, 135, 179, 0));
  }

  .xl\:tw-from-pink-500 {
    --gradient-from-color: #ed64a6;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(237, 100, 166, 0));
  }

  .xl\:tw-from-pink-600 {
    --gradient-from-color: #d53f8c;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(213, 63, 140, 0));
  }

  .xl\:tw-from-pink-700 {
    --gradient-from-color: #b83280;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(184, 50, 128, 0));
  }

  .xl\:tw-from-pink-800 {
    --gradient-from-color: #97266d;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(151, 38, 109, 0));
  }

  .xl\:tw-from-pink-900 {
    --gradient-from-color: #702459;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(112, 36, 89, 0));
  }

  .xl\:tw-via-transparent {
    --gradient-via-color: transparent;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(0, 0, 0, 0));
  }

  .xl\:tw-via-current {
    --gradient-via-color: currentColor;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0));
  }

  .xl\:tw-via-black {
    --gradient-via-color: #000;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(0, 0, 0, 0));
  }

  .xl\:tw-via-white {
    --gradient-via-color: #fff;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0));
  }

  .xl\:tw-via-gray-100 {
    --gradient-via-color: #f7fafc;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(247, 250, 252, 0));
  }

  .xl\:tw-via-gray-200 {
    --gradient-via-color: #edf2f7;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(237, 242, 247, 0));
  }

  .xl\:tw-via-gray-300 {
    --gradient-via-color: #e2e8f0;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(226, 232, 240, 0));
  }

  .xl\:tw-via-gray-400 {
    --gradient-via-color: #cbd5e0;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(203, 213, 224, 0));
  }

  .xl\:tw-via-gray-500 {
    --gradient-via-color: #a0aec0;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(160, 174, 192, 0));
  }

  .xl\:tw-via-gray-600 {
    --gradient-via-color: #718096;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(113, 128, 150, 0));
  }

  .xl\:tw-via-gray-700 {
    --gradient-via-color: #4a5568;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(74, 85, 104, 0));
  }

  .xl\:tw-via-gray-800 {
    --gradient-via-color: #2d3748;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(45, 55, 72, 0));
  }

  .xl\:tw-via-gray-900 {
    --gradient-via-color: #1a202c;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(26, 32, 44, 0));
  }

  .xl\:tw-via-red-100 {
    --gradient-via-color: #fff5f5;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 245, 245, 0));
  }

  .xl\:tw-via-red-200 {
    --gradient-via-color: #fed7d7;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(254, 215, 215, 0));
  }

  .xl\:tw-via-red-300 {
    --gradient-via-color: #feb2b2;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(254, 178, 178, 0));
  }

  .xl\:tw-via-red-400 {
    --gradient-via-color: #fc8181;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(252, 129, 129, 0));
  }

  .xl\:tw-via-red-500 {
    --gradient-via-color: #f56565;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(245, 101, 101, 0));
  }

  .xl\:tw-via-red-600 {
    --gradient-via-color: #e53e3e;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(229, 62, 62, 0));
  }

  .xl\:tw-via-red-700 {
    --gradient-via-color: #c53030;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(197, 48, 48, 0));
  }

  .xl\:tw-via-red-800 {
    --gradient-via-color: #9b2c2c;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(155, 44, 44, 0));
  }

  .xl\:tw-via-red-900 {
    --gradient-via-color: #742a2a;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(116, 42, 42, 0));
  }

  .xl\:tw-via-orange-100 {
    --gradient-via-color: #fffaf0;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 250, 240, 0));
  }

  .xl\:tw-via-orange-200 {
    --gradient-via-color: #feebc8;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(254, 235, 200, 0));
  }

  .xl\:tw-via-orange-300 {
    --gradient-via-color: #fbd38d;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(251, 211, 141, 0));
  }

  .xl\:tw-via-orange-400 {
    --gradient-via-color: #f6ad55;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(246, 173, 85, 0));
  }

  .xl\:tw-via-orange-500 {
    --gradient-via-color: #ed8936;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(237, 137, 54, 0));
  }

  .xl\:tw-via-orange-600 {
    --gradient-via-color: #dd6b20;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(221, 107, 32, 0));
  }

  .xl\:tw-via-orange-700 {
    --gradient-via-color: #c05621;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(192, 86, 33, 0));
  }

  .xl\:tw-via-orange-800 {
    --gradient-via-color: #9c4221;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(156, 66, 33, 0));
  }

  .xl\:tw-via-orange-900 {
    --gradient-via-color: #7b341e;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(123, 52, 30, 0));
  }

  .xl\:tw-via-yellow-100 {
    --gradient-via-color: #fffff0;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 240, 0));
  }

  .xl\:tw-via-yellow-200 {
    --gradient-via-color: #fefcbf;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(254, 252, 191, 0));
  }

  .xl\:tw-via-yellow-300 {
    --gradient-via-color: #faf089;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(250, 240, 137, 0));
  }

  .xl\:tw-via-yellow-400 {
    --gradient-via-color: #f6e05e;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(246, 224, 94, 0));
  }

  .xl\:tw-via-yellow-500 {
    --gradient-via-color: #ecc94b;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(236, 201, 75, 0));
  }

  .xl\:tw-via-yellow-600 {
    --gradient-via-color: #d69e2e;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(214, 158, 46, 0));
  }

  .xl\:tw-via-yellow-700 {
    --gradient-via-color: #b7791f;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(183, 121, 31, 0));
  }

  .xl\:tw-via-yellow-800 {
    --gradient-via-color: #975a16;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(151, 90, 22, 0));
  }

  .xl\:tw-via-yellow-900 {
    --gradient-via-color: #744210;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(116, 66, 16, 0));
  }

  .xl\:tw-via-green-100 {
    --gradient-via-color: #f0fff4;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(240, 255, 244, 0));
  }

  .xl\:tw-via-green-200 {
    --gradient-via-color: #c6f6d5;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(198, 246, 213, 0));
  }

  .xl\:tw-via-green-300 {
    --gradient-via-color: #9ae6b4;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(154, 230, 180, 0));
  }

  .xl\:tw-via-green-400 {
    --gradient-via-color: #68d391;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(104, 211, 145, 0));
  }

  .xl\:tw-via-green-500 {
    --gradient-via-color: #48bb78;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(72, 187, 120, 0));
  }

  .xl\:tw-via-green-600 {
    --gradient-via-color: #38a169;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(56, 161, 105, 0));
  }

  .xl\:tw-via-green-700 {
    --gradient-via-color: #2f855a;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(47, 133, 90, 0));
  }

  .xl\:tw-via-green-800 {
    --gradient-via-color: #276749;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(39, 103, 73, 0));
  }

  .xl\:tw-via-green-900 {
    --gradient-via-color: #22543d;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(34, 84, 61, 0));
  }

  .xl\:tw-via-teal-100 {
    --gradient-via-color: #e6fffa;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(230, 255, 250, 0));
  }

  .xl\:tw-via-teal-200 {
    --gradient-via-color: #b2f5ea;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(178, 245, 234, 0));
  }

  .xl\:tw-via-teal-300 {
    --gradient-via-color: #81e6d9;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(129, 230, 217, 0));
  }

  .xl\:tw-via-teal-400 {
    --gradient-via-color: #4fd1c5;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(79, 209, 197, 0));
  }

  .xl\:tw-via-teal-500 {
    --gradient-via-color: #38b2ac;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(56, 178, 172, 0));
  }

  .xl\:tw-via-teal-600 {
    --gradient-via-color: #319795;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(49, 151, 149, 0));
  }

  .xl\:tw-via-teal-700 {
    --gradient-via-color: #2c7a7b;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(44, 122, 123, 0));
  }

  .xl\:tw-via-teal-800 {
    --gradient-via-color: #285e61;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(40, 94, 97, 0));
  }

  .xl\:tw-via-teal-900 {
    --gradient-via-color: #234e52;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(35, 78, 82, 0));
  }

  .xl\:tw-via-blue-100 {
    --gradient-via-color: #ebf8ff;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(235, 248, 255, 0));
  }

  .xl\:tw-via-blue-200 {
    --gradient-via-color: #bee3f8;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(190, 227, 248, 0));
  }

  .xl\:tw-via-blue-300 {
    --gradient-via-color: #90cdf4;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(144, 205, 244, 0));
  }

  .xl\:tw-via-blue-400 {
    --gradient-via-color: #63b3ed;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(99, 179, 237, 0));
  }

  .xl\:tw-via-blue-500 {
    --gradient-via-color: #4299e1;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(66, 153, 225, 0));
  }

  .xl\:tw-via-blue-600 {
    --gradient-via-color: #3182ce;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(49, 130, 206, 0));
  }

  .xl\:tw-via-blue-700 {
    --gradient-via-color: #2b6cb0;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(43, 108, 176, 0));
  }

  .xl\:tw-via-blue-800 {
    --gradient-via-color: #2c5282;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(44, 82, 130, 0));
  }

  .xl\:tw-via-blue-900 {
    --gradient-via-color: #2a4365;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(42, 67, 101, 0));
  }

  .xl\:tw-via-indigo-100 {
    --gradient-via-color: #ebf4ff;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(235, 244, 255, 0));
  }

  .xl\:tw-via-indigo-200 {
    --gradient-via-color: #c3dafe;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(195, 218, 254, 0));
  }

  .xl\:tw-via-indigo-300 {
    --gradient-via-color: #a3bffa;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(163, 191, 250, 0));
  }

  .xl\:tw-via-indigo-400 {
    --gradient-via-color: #7f9cf5;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(127, 156, 245, 0));
  }

  .xl\:tw-via-indigo-500 {
    --gradient-via-color: #667eea;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(102, 126, 234, 0));
  }

  .xl\:tw-via-indigo-600 {
    --gradient-via-color: #5a67d8;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(90, 103, 216, 0));
  }

  .xl\:tw-via-indigo-700 {
    --gradient-via-color: #4c51bf;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(76, 81, 191, 0));
  }

  .xl\:tw-via-indigo-800 {
    --gradient-via-color: #434190;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(67, 65, 144, 0));
  }

  .xl\:tw-via-indigo-900 {
    --gradient-via-color: #3c366b;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(60, 54, 107, 0));
  }

  .xl\:tw-via-purple-100 {
    --gradient-via-color: #faf5ff;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(250, 245, 255, 0));
  }

  .xl\:tw-via-purple-200 {
    --gradient-via-color: #e9d8fd;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(233, 216, 253, 0));
  }

  .xl\:tw-via-purple-300 {
    --gradient-via-color: #d6bcfa;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(214, 188, 250, 0));
  }

  .xl\:tw-via-purple-400 {
    --gradient-via-color: #b794f4;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(183, 148, 244, 0));
  }

  .xl\:tw-via-purple-500 {
    --gradient-via-color: #9f7aea;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(159, 122, 234, 0));
  }

  .xl\:tw-via-purple-600 {
    --gradient-via-color: #805ad5;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(128, 90, 213, 0));
  }

  .xl\:tw-via-purple-700 {
    --gradient-via-color: #6b46c1;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(107, 70, 193, 0));
  }

  .xl\:tw-via-purple-800 {
    --gradient-via-color: #553c9a;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(85, 60, 154, 0));
  }

  .xl\:tw-via-purple-900 {
    --gradient-via-color: #44337a;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(68, 51, 122, 0));
  }

  .xl\:tw-via-pink-100 {
    --gradient-via-color: #fff5f7;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 245, 247, 0));
  }

  .xl\:tw-via-pink-200 {
    --gradient-via-color: #fed7e2;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(254, 215, 226, 0));
  }

  .xl\:tw-via-pink-300 {
    --gradient-via-color: #fbb6ce;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(251, 182, 206, 0));
  }

  .xl\:tw-via-pink-400 {
    --gradient-via-color: #f687b3;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(246, 135, 179, 0));
  }

  .xl\:tw-via-pink-500 {
    --gradient-via-color: #ed64a6;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(237, 100, 166, 0));
  }

  .xl\:tw-via-pink-600 {
    --gradient-via-color: #d53f8c;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(213, 63, 140, 0));
  }

  .xl\:tw-via-pink-700 {
    --gradient-via-color: #b83280;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(184, 50, 128, 0));
  }

  .xl\:tw-via-pink-800 {
    --gradient-via-color: #97266d;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(151, 38, 109, 0));
  }

  .xl\:tw-via-pink-900 {
    --gradient-via-color: #702459;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(112, 36, 89, 0));
  }

  .xl\:tw-to-transparent {
    --gradient-to-color: transparent;
  }

  .xl\:tw-to-current {
    --gradient-to-color: currentColor;
  }

  .xl\:tw-to-black {
    --gradient-to-color: #000;
  }

  .xl\:tw-to-white {
    --gradient-to-color: #fff;
  }

  .xl\:tw-to-gray-100 {
    --gradient-to-color: #f7fafc;
  }

  .xl\:tw-to-gray-200 {
    --gradient-to-color: #edf2f7;
  }

  .xl\:tw-to-gray-300 {
    --gradient-to-color: #e2e8f0;
  }

  .xl\:tw-to-gray-400 {
    --gradient-to-color: #cbd5e0;
  }

  .xl\:tw-to-gray-500 {
    --gradient-to-color: #a0aec0;
  }

  .xl\:tw-to-gray-600 {
    --gradient-to-color: #718096;
  }

  .xl\:tw-to-gray-700 {
    --gradient-to-color: #4a5568;
  }

  .xl\:tw-to-gray-800 {
    --gradient-to-color: #2d3748;
  }

  .xl\:tw-to-gray-900 {
    --gradient-to-color: #1a202c;
  }

  .xl\:tw-to-red-100 {
    --gradient-to-color: #fff5f5;
  }

  .xl\:tw-to-red-200 {
    --gradient-to-color: #fed7d7;
  }

  .xl\:tw-to-red-300 {
    --gradient-to-color: #feb2b2;
  }

  .xl\:tw-to-red-400 {
    --gradient-to-color: #fc8181;
  }

  .xl\:tw-to-red-500 {
    --gradient-to-color: #f56565;
  }

  .xl\:tw-to-red-600 {
    --gradient-to-color: #e53e3e;
  }

  .xl\:tw-to-red-700 {
    --gradient-to-color: #c53030;
  }

  .xl\:tw-to-red-800 {
    --gradient-to-color: #9b2c2c;
  }

  .xl\:tw-to-red-900 {
    --gradient-to-color: #742a2a;
  }

  .xl\:tw-to-orange-100 {
    --gradient-to-color: #fffaf0;
  }

  .xl\:tw-to-orange-200 {
    --gradient-to-color: #feebc8;
  }

  .xl\:tw-to-orange-300 {
    --gradient-to-color: #fbd38d;
  }

  .xl\:tw-to-orange-400 {
    --gradient-to-color: #f6ad55;
  }

  .xl\:tw-to-orange-500 {
    --gradient-to-color: #ed8936;
  }

  .xl\:tw-to-orange-600 {
    --gradient-to-color: #dd6b20;
  }

  .xl\:tw-to-orange-700 {
    --gradient-to-color: #c05621;
  }

  .xl\:tw-to-orange-800 {
    --gradient-to-color: #9c4221;
  }

  .xl\:tw-to-orange-900 {
    --gradient-to-color: #7b341e;
  }

  .xl\:tw-to-yellow-100 {
    --gradient-to-color: #fffff0;
  }

  .xl\:tw-to-yellow-200 {
    --gradient-to-color: #fefcbf;
  }

  .xl\:tw-to-yellow-300 {
    --gradient-to-color: #faf089;
  }

  .xl\:tw-to-yellow-400 {
    --gradient-to-color: #f6e05e;
  }

  .xl\:tw-to-yellow-500 {
    --gradient-to-color: #ecc94b;
  }

  .xl\:tw-to-yellow-600 {
    --gradient-to-color: #d69e2e;
  }

  .xl\:tw-to-yellow-700 {
    --gradient-to-color: #b7791f;
  }

  .xl\:tw-to-yellow-800 {
    --gradient-to-color: #975a16;
  }

  .xl\:tw-to-yellow-900 {
    --gradient-to-color: #744210;
  }

  .xl\:tw-to-green-100 {
    --gradient-to-color: #f0fff4;
  }

  .xl\:tw-to-green-200 {
    --gradient-to-color: #c6f6d5;
  }

  .xl\:tw-to-green-300 {
    --gradient-to-color: #9ae6b4;
  }

  .xl\:tw-to-green-400 {
    --gradient-to-color: #68d391;
  }

  .xl\:tw-to-green-500 {
    --gradient-to-color: #48bb78;
  }

  .xl\:tw-to-green-600 {
    --gradient-to-color: #38a169;
  }

  .xl\:tw-to-green-700 {
    --gradient-to-color: #2f855a;
  }

  .xl\:tw-to-green-800 {
    --gradient-to-color: #276749;
  }

  .xl\:tw-to-green-900 {
    --gradient-to-color: #22543d;
  }

  .xl\:tw-to-teal-100 {
    --gradient-to-color: #e6fffa;
  }

  .xl\:tw-to-teal-200 {
    --gradient-to-color: #b2f5ea;
  }

  .xl\:tw-to-teal-300 {
    --gradient-to-color: #81e6d9;
  }

  .xl\:tw-to-teal-400 {
    --gradient-to-color: #4fd1c5;
  }

  .xl\:tw-to-teal-500 {
    --gradient-to-color: #38b2ac;
  }

  .xl\:tw-to-teal-600 {
    --gradient-to-color: #319795;
  }

  .xl\:tw-to-teal-700 {
    --gradient-to-color: #2c7a7b;
  }

  .xl\:tw-to-teal-800 {
    --gradient-to-color: #285e61;
  }

  .xl\:tw-to-teal-900 {
    --gradient-to-color: #234e52;
  }

  .xl\:tw-to-blue-100 {
    --gradient-to-color: #ebf8ff;
  }

  .xl\:tw-to-blue-200 {
    --gradient-to-color: #bee3f8;
  }

  .xl\:tw-to-blue-300 {
    --gradient-to-color: #90cdf4;
  }

  .xl\:tw-to-blue-400 {
    --gradient-to-color: #63b3ed;
  }

  .xl\:tw-to-blue-500 {
    --gradient-to-color: #4299e1;
  }

  .xl\:tw-to-blue-600 {
    --gradient-to-color: #3182ce;
  }

  .xl\:tw-to-blue-700 {
    --gradient-to-color: #2b6cb0;
  }

  .xl\:tw-to-blue-800 {
    --gradient-to-color: #2c5282;
  }

  .xl\:tw-to-blue-900 {
    --gradient-to-color: #2a4365;
  }

  .xl\:tw-to-indigo-100 {
    --gradient-to-color: #ebf4ff;
  }

  .xl\:tw-to-indigo-200 {
    --gradient-to-color: #c3dafe;
  }

  .xl\:tw-to-indigo-300 {
    --gradient-to-color: #a3bffa;
  }

  .xl\:tw-to-indigo-400 {
    --gradient-to-color: #7f9cf5;
  }

  .xl\:tw-to-indigo-500 {
    --gradient-to-color: #667eea;
  }

  .xl\:tw-to-indigo-600 {
    --gradient-to-color: #5a67d8;
  }

  .xl\:tw-to-indigo-700 {
    --gradient-to-color: #4c51bf;
  }

  .xl\:tw-to-indigo-800 {
    --gradient-to-color: #434190;
  }

  .xl\:tw-to-indigo-900 {
    --gradient-to-color: #3c366b;
  }

  .xl\:tw-to-purple-100 {
    --gradient-to-color: #faf5ff;
  }

  .xl\:tw-to-purple-200 {
    --gradient-to-color: #e9d8fd;
  }

  .xl\:tw-to-purple-300 {
    --gradient-to-color: #d6bcfa;
  }

  .xl\:tw-to-purple-400 {
    --gradient-to-color: #b794f4;
  }

  .xl\:tw-to-purple-500 {
    --gradient-to-color: #9f7aea;
  }

  .xl\:tw-to-purple-600 {
    --gradient-to-color: #805ad5;
  }

  .xl\:tw-to-purple-700 {
    --gradient-to-color: #6b46c1;
  }

  .xl\:tw-to-purple-800 {
    --gradient-to-color: #553c9a;
  }

  .xl\:tw-to-purple-900 {
    --gradient-to-color: #44337a;
  }

  .xl\:tw-to-pink-100 {
    --gradient-to-color: #fff5f7;
  }

  .xl\:tw-to-pink-200 {
    --gradient-to-color: #fed7e2;
  }

  .xl\:tw-to-pink-300 {
    --gradient-to-color: #fbb6ce;
  }

  .xl\:tw-to-pink-400 {
    --gradient-to-color: #f687b3;
  }

  .xl\:tw-to-pink-500 {
    --gradient-to-color: #ed64a6;
  }

  .xl\:tw-to-pink-600 {
    --gradient-to-color: #d53f8c;
  }

  .xl\:tw-to-pink-700 {
    --gradient-to-color: #b83280;
  }

  .xl\:tw-to-pink-800 {
    --gradient-to-color: #97266d;
  }

  .xl\:tw-to-pink-900 {
    --gradient-to-color: #702459;
  }

  .xl\:hover\:tw-from-transparent:hover {
    --gradient-from-color: transparent;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(0, 0, 0, 0));
  }

  .xl\:hover\:tw-from-current:hover {
    --gradient-from-color: currentColor;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0));
  }

  .xl\:hover\:tw-from-black:hover {
    --gradient-from-color: #000;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(0, 0, 0, 0));
  }

  .xl\:hover\:tw-from-white:hover {
    --gradient-from-color: #fff;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0));
  }

  .xl\:hover\:tw-from-gray-100:hover {
    --gradient-from-color: #f7fafc;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(247, 250, 252, 0));
  }

  .xl\:hover\:tw-from-gray-200:hover {
    --gradient-from-color: #edf2f7;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(237, 242, 247, 0));
  }

  .xl\:hover\:tw-from-gray-300:hover {
    --gradient-from-color: #e2e8f0;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(226, 232, 240, 0));
  }

  .xl\:hover\:tw-from-gray-400:hover {
    --gradient-from-color: #cbd5e0;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(203, 213, 224, 0));
  }

  .xl\:hover\:tw-from-gray-500:hover {
    --gradient-from-color: #a0aec0;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(160, 174, 192, 0));
  }

  .xl\:hover\:tw-from-gray-600:hover {
    --gradient-from-color: #718096;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(113, 128, 150, 0));
  }

  .xl\:hover\:tw-from-gray-700:hover {
    --gradient-from-color: #4a5568;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(74, 85, 104, 0));
  }

  .xl\:hover\:tw-from-gray-800:hover {
    --gradient-from-color: #2d3748;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(45, 55, 72, 0));
  }

  .xl\:hover\:tw-from-gray-900:hover {
    --gradient-from-color: #1a202c;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(26, 32, 44, 0));
  }

  .xl\:hover\:tw-from-red-100:hover {
    --gradient-from-color: #fff5f5;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 245, 245, 0));
  }

  .xl\:hover\:tw-from-red-200:hover {
    --gradient-from-color: #fed7d7;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(254, 215, 215, 0));
  }

  .xl\:hover\:tw-from-red-300:hover {
    --gradient-from-color: #feb2b2;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(254, 178, 178, 0));
  }

  .xl\:hover\:tw-from-red-400:hover {
    --gradient-from-color: #fc8181;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(252, 129, 129, 0));
  }

  .xl\:hover\:tw-from-red-500:hover {
    --gradient-from-color: #f56565;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(245, 101, 101, 0));
  }

  .xl\:hover\:tw-from-red-600:hover {
    --gradient-from-color: #e53e3e;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(229, 62, 62, 0));
  }

  .xl\:hover\:tw-from-red-700:hover {
    --gradient-from-color: #c53030;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(197, 48, 48, 0));
  }

  .xl\:hover\:tw-from-red-800:hover {
    --gradient-from-color: #9b2c2c;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(155, 44, 44, 0));
  }

  .xl\:hover\:tw-from-red-900:hover {
    --gradient-from-color: #742a2a;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(116, 42, 42, 0));
  }

  .xl\:hover\:tw-from-orange-100:hover {
    --gradient-from-color: #fffaf0;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 250, 240, 0));
  }

  .xl\:hover\:tw-from-orange-200:hover {
    --gradient-from-color: #feebc8;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(254, 235, 200, 0));
  }

  .xl\:hover\:tw-from-orange-300:hover {
    --gradient-from-color: #fbd38d;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(251, 211, 141, 0));
  }

  .xl\:hover\:tw-from-orange-400:hover {
    --gradient-from-color: #f6ad55;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(246, 173, 85, 0));
  }

  .xl\:hover\:tw-from-orange-500:hover {
    --gradient-from-color: #ed8936;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(237, 137, 54, 0));
  }

  .xl\:hover\:tw-from-orange-600:hover {
    --gradient-from-color: #dd6b20;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(221, 107, 32, 0));
  }

  .xl\:hover\:tw-from-orange-700:hover {
    --gradient-from-color: #c05621;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(192, 86, 33, 0));
  }

  .xl\:hover\:tw-from-orange-800:hover {
    --gradient-from-color: #9c4221;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(156, 66, 33, 0));
  }

  .xl\:hover\:tw-from-orange-900:hover {
    --gradient-from-color: #7b341e;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(123, 52, 30, 0));
  }

  .xl\:hover\:tw-from-yellow-100:hover {
    --gradient-from-color: #fffff0;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 240, 0));
  }

  .xl\:hover\:tw-from-yellow-200:hover {
    --gradient-from-color: #fefcbf;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(254, 252, 191, 0));
  }

  .xl\:hover\:tw-from-yellow-300:hover {
    --gradient-from-color: #faf089;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(250, 240, 137, 0));
  }

  .xl\:hover\:tw-from-yellow-400:hover {
    --gradient-from-color: #f6e05e;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(246, 224, 94, 0));
  }

  .xl\:hover\:tw-from-yellow-500:hover {
    --gradient-from-color: #ecc94b;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(236, 201, 75, 0));
  }

  .xl\:hover\:tw-from-yellow-600:hover {
    --gradient-from-color: #d69e2e;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(214, 158, 46, 0));
  }

  .xl\:hover\:tw-from-yellow-700:hover {
    --gradient-from-color: #b7791f;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(183, 121, 31, 0));
  }

  .xl\:hover\:tw-from-yellow-800:hover {
    --gradient-from-color: #975a16;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(151, 90, 22, 0));
  }

  .xl\:hover\:tw-from-yellow-900:hover {
    --gradient-from-color: #744210;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(116, 66, 16, 0));
  }

  .xl\:hover\:tw-from-green-100:hover {
    --gradient-from-color: #f0fff4;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(240, 255, 244, 0));
  }

  .xl\:hover\:tw-from-green-200:hover {
    --gradient-from-color: #c6f6d5;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(198, 246, 213, 0));
  }

  .xl\:hover\:tw-from-green-300:hover {
    --gradient-from-color: #9ae6b4;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(154, 230, 180, 0));
  }

  .xl\:hover\:tw-from-green-400:hover {
    --gradient-from-color: #68d391;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(104, 211, 145, 0));
  }

  .xl\:hover\:tw-from-green-500:hover {
    --gradient-from-color: #48bb78;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(72, 187, 120, 0));
  }

  .xl\:hover\:tw-from-green-600:hover {
    --gradient-from-color: #38a169;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(56, 161, 105, 0));
  }

  .xl\:hover\:tw-from-green-700:hover {
    --gradient-from-color: #2f855a;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(47, 133, 90, 0));
  }

  .xl\:hover\:tw-from-green-800:hover {
    --gradient-from-color: #276749;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(39, 103, 73, 0));
  }

  .xl\:hover\:tw-from-green-900:hover {
    --gradient-from-color: #22543d;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(34, 84, 61, 0));
  }

  .xl\:hover\:tw-from-teal-100:hover {
    --gradient-from-color: #e6fffa;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(230, 255, 250, 0));
  }

  .xl\:hover\:tw-from-teal-200:hover {
    --gradient-from-color: #b2f5ea;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(178, 245, 234, 0));
  }

  .xl\:hover\:tw-from-teal-300:hover {
    --gradient-from-color: #81e6d9;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(129, 230, 217, 0));
  }

  .xl\:hover\:tw-from-teal-400:hover {
    --gradient-from-color: #4fd1c5;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(79, 209, 197, 0));
  }

  .xl\:hover\:tw-from-teal-500:hover {
    --gradient-from-color: #38b2ac;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(56, 178, 172, 0));
  }

  .xl\:hover\:tw-from-teal-600:hover {
    --gradient-from-color: #319795;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(49, 151, 149, 0));
  }

  .xl\:hover\:tw-from-teal-700:hover {
    --gradient-from-color: #2c7a7b;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(44, 122, 123, 0));
  }

  .xl\:hover\:tw-from-teal-800:hover {
    --gradient-from-color: #285e61;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(40, 94, 97, 0));
  }

  .xl\:hover\:tw-from-teal-900:hover {
    --gradient-from-color: #234e52;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(35, 78, 82, 0));
  }

  .xl\:hover\:tw-from-blue-100:hover {
    --gradient-from-color: #ebf8ff;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(235, 248, 255, 0));
  }

  .xl\:hover\:tw-from-blue-200:hover {
    --gradient-from-color: #bee3f8;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(190, 227, 248, 0));
  }

  .xl\:hover\:tw-from-blue-300:hover {
    --gradient-from-color: #90cdf4;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(144, 205, 244, 0));
  }

  .xl\:hover\:tw-from-blue-400:hover {
    --gradient-from-color: #63b3ed;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(99, 179, 237, 0));
  }

  .xl\:hover\:tw-from-blue-500:hover {
    --gradient-from-color: #4299e1;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(66, 153, 225, 0));
  }

  .xl\:hover\:tw-from-blue-600:hover {
    --gradient-from-color: #3182ce;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(49, 130, 206, 0));
  }

  .xl\:hover\:tw-from-blue-700:hover {
    --gradient-from-color: #2b6cb0;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(43, 108, 176, 0));
  }

  .xl\:hover\:tw-from-blue-800:hover {
    --gradient-from-color: #2c5282;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(44, 82, 130, 0));
  }

  .xl\:hover\:tw-from-blue-900:hover {
    --gradient-from-color: #2a4365;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(42, 67, 101, 0));
  }

  .xl\:hover\:tw-from-indigo-100:hover {
    --gradient-from-color: #ebf4ff;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(235, 244, 255, 0));
  }

  .xl\:hover\:tw-from-indigo-200:hover {
    --gradient-from-color: #c3dafe;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(195, 218, 254, 0));
  }

  .xl\:hover\:tw-from-indigo-300:hover {
    --gradient-from-color: #a3bffa;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(163, 191, 250, 0));
  }

  .xl\:hover\:tw-from-indigo-400:hover {
    --gradient-from-color: #7f9cf5;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(127, 156, 245, 0));
  }

  .xl\:hover\:tw-from-indigo-500:hover {
    --gradient-from-color: #667eea;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(102, 126, 234, 0));
  }

  .xl\:hover\:tw-from-indigo-600:hover {
    --gradient-from-color: #5a67d8;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(90, 103, 216, 0));
  }

  .xl\:hover\:tw-from-indigo-700:hover {
    --gradient-from-color: #4c51bf;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(76, 81, 191, 0));
  }

  .xl\:hover\:tw-from-indigo-800:hover {
    --gradient-from-color: #434190;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(67, 65, 144, 0));
  }

  .xl\:hover\:tw-from-indigo-900:hover {
    --gradient-from-color: #3c366b;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(60, 54, 107, 0));
  }

  .xl\:hover\:tw-from-purple-100:hover {
    --gradient-from-color: #faf5ff;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(250, 245, 255, 0));
  }

  .xl\:hover\:tw-from-purple-200:hover {
    --gradient-from-color: #e9d8fd;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(233, 216, 253, 0));
  }

  .xl\:hover\:tw-from-purple-300:hover {
    --gradient-from-color: #d6bcfa;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(214, 188, 250, 0));
  }

  .xl\:hover\:tw-from-purple-400:hover {
    --gradient-from-color: #b794f4;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(183, 148, 244, 0));
  }

  .xl\:hover\:tw-from-purple-500:hover {
    --gradient-from-color: #9f7aea;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(159, 122, 234, 0));
  }

  .xl\:hover\:tw-from-purple-600:hover {
    --gradient-from-color: #805ad5;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(128, 90, 213, 0));
  }

  .xl\:hover\:tw-from-purple-700:hover {
    --gradient-from-color: #6b46c1;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(107, 70, 193, 0));
  }

  .xl\:hover\:tw-from-purple-800:hover {
    --gradient-from-color: #553c9a;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(85, 60, 154, 0));
  }

  .xl\:hover\:tw-from-purple-900:hover {
    --gradient-from-color: #44337a;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(68, 51, 122, 0));
  }

  .xl\:hover\:tw-from-pink-100:hover {
    --gradient-from-color: #fff5f7;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 245, 247, 0));
  }

  .xl\:hover\:tw-from-pink-200:hover {
    --gradient-from-color: #fed7e2;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(254, 215, 226, 0));
  }

  .xl\:hover\:tw-from-pink-300:hover {
    --gradient-from-color: #fbb6ce;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(251, 182, 206, 0));
  }

  .xl\:hover\:tw-from-pink-400:hover {
    --gradient-from-color: #f687b3;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(246, 135, 179, 0));
  }

  .xl\:hover\:tw-from-pink-500:hover {
    --gradient-from-color: #ed64a6;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(237, 100, 166, 0));
  }

  .xl\:hover\:tw-from-pink-600:hover {
    --gradient-from-color: #d53f8c;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(213, 63, 140, 0));
  }

  .xl\:hover\:tw-from-pink-700:hover {
    --gradient-from-color: #b83280;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(184, 50, 128, 0));
  }

  .xl\:hover\:tw-from-pink-800:hover {
    --gradient-from-color: #97266d;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(151, 38, 109, 0));
  }

  .xl\:hover\:tw-from-pink-900:hover {
    --gradient-from-color: #702459;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(112, 36, 89, 0));
  }

  .xl\:hover\:tw-via-transparent:hover {
    --gradient-via-color: transparent;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(0, 0, 0, 0));
  }

  .xl\:hover\:tw-via-current:hover {
    --gradient-via-color: currentColor;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0));
  }

  .xl\:hover\:tw-via-black:hover {
    --gradient-via-color: #000;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(0, 0, 0, 0));
  }

  .xl\:hover\:tw-via-white:hover {
    --gradient-via-color: #fff;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0));
  }

  .xl\:hover\:tw-via-gray-100:hover {
    --gradient-via-color: #f7fafc;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(247, 250, 252, 0));
  }

  .xl\:hover\:tw-via-gray-200:hover {
    --gradient-via-color: #edf2f7;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(237, 242, 247, 0));
  }

  .xl\:hover\:tw-via-gray-300:hover {
    --gradient-via-color: #e2e8f0;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(226, 232, 240, 0));
  }

  .xl\:hover\:tw-via-gray-400:hover {
    --gradient-via-color: #cbd5e0;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(203, 213, 224, 0));
  }

  .xl\:hover\:tw-via-gray-500:hover {
    --gradient-via-color: #a0aec0;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(160, 174, 192, 0));
  }

  .xl\:hover\:tw-via-gray-600:hover {
    --gradient-via-color: #718096;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(113, 128, 150, 0));
  }

  .xl\:hover\:tw-via-gray-700:hover {
    --gradient-via-color: #4a5568;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(74, 85, 104, 0));
  }

  .xl\:hover\:tw-via-gray-800:hover {
    --gradient-via-color: #2d3748;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(45, 55, 72, 0));
  }

  .xl\:hover\:tw-via-gray-900:hover {
    --gradient-via-color: #1a202c;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(26, 32, 44, 0));
  }

  .xl\:hover\:tw-via-red-100:hover {
    --gradient-via-color: #fff5f5;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 245, 245, 0));
  }

  .xl\:hover\:tw-via-red-200:hover {
    --gradient-via-color: #fed7d7;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(254, 215, 215, 0));
  }

  .xl\:hover\:tw-via-red-300:hover {
    --gradient-via-color: #feb2b2;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(254, 178, 178, 0));
  }

  .xl\:hover\:tw-via-red-400:hover {
    --gradient-via-color: #fc8181;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(252, 129, 129, 0));
  }

  .xl\:hover\:tw-via-red-500:hover {
    --gradient-via-color: #f56565;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(245, 101, 101, 0));
  }

  .xl\:hover\:tw-via-red-600:hover {
    --gradient-via-color: #e53e3e;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(229, 62, 62, 0));
  }

  .xl\:hover\:tw-via-red-700:hover {
    --gradient-via-color: #c53030;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(197, 48, 48, 0));
  }

  .xl\:hover\:tw-via-red-800:hover {
    --gradient-via-color: #9b2c2c;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(155, 44, 44, 0));
  }

  .xl\:hover\:tw-via-red-900:hover {
    --gradient-via-color: #742a2a;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(116, 42, 42, 0));
  }

  .xl\:hover\:tw-via-orange-100:hover {
    --gradient-via-color: #fffaf0;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 250, 240, 0));
  }

  .xl\:hover\:tw-via-orange-200:hover {
    --gradient-via-color: #feebc8;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(254, 235, 200, 0));
  }

  .xl\:hover\:tw-via-orange-300:hover {
    --gradient-via-color: #fbd38d;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(251, 211, 141, 0));
  }

  .xl\:hover\:tw-via-orange-400:hover {
    --gradient-via-color: #f6ad55;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(246, 173, 85, 0));
  }

  .xl\:hover\:tw-via-orange-500:hover {
    --gradient-via-color: #ed8936;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(237, 137, 54, 0));
  }

  .xl\:hover\:tw-via-orange-600:hover {
    --gradient-via-color: #dd6b20;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(221, 107, 32, 0));
  }

  .xl\:hover\:tw-via-orange-700:hover {
    --gradient-via-color: #c05621;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(192, 86, 33, 0));
  }

  .xl\:hover\:tw-via-orange-800:hover {
    --gradient-via-color: #9c4221;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(156, 66, 33, 0));
  }

  .xl\:hover\:tw-via-orange-900:hover {
    --gradient-via-color: #7b341e;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(123, 52, 30, 0));
  }

  .xl\:hover\:tw-via-yellow-100:hover {
    --gradient-via-color: #fffff0;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 240, 0));
  }

  .xl\:hover\:tw-via-yellow-200:hover {
    --gradient-via-color: #fefcbf;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(254, 252, 191, 0));
  }

  .xl\:hover\:tw-via-yellow-300:hover {
    --gradient-via-color: #faf089;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(250, 240, 137, 0));
  }

  .xl\:hover\:tw-via-yellow-400:hover {
    --gradient-via-color: #f6e05e;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(246, 224, 94, 0));
  }

  .xl\:hover\:tw-via-yellow-500:hover {
    --gradient-via-color: #ecc94b;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(236, 201, 75, 0));
  }

  .xl\:hover\:tw-via-yellow-600:hover {
    --gradient-via-color: #d69e2e;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(214, 158, 46, 0));
  }

  .xl\:hover\:tw-via-yellow-700:hover {
    --gradient-via-color: #b7791f;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(183, 121, 31, 0));
  }

  .xl\:hover\:tw-via-yellow-800:hover {
    --gradient-via-color: #975a16;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(151, 90, 22, 0));
  }

  .xl\:hover\:tw-via-yellow-900:hover {
    --gradient-via-color: #744210;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(116, 66, 16, 0));
  }

  .xl\:hover\:tw-via-green-100:hover {
    --gradient-via-color: #f0fff4;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(240, 255, 244, 0));
  }

  .xl\:hover\:tw-via-green-200:hover {
    --gradient-via-color: #c6f6d5;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(198, 246, 213, 0));
  }

  .xl\:hover\:tw-via-green-300:hover {
    --gradient-via-color: #9ae6b4;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(154, 230, 180, 0));
  }

  .xl\:hover\:tw-via-green-400:hover {
    --gradient-via-color: #68d391;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(104, 211, 145, 0));
  }

  .xl\:hover\:tw-via-green-500:hover {
    --gradient-via-color: #48bb78;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(72, 187, 120, 0));
  }

  .xl\:hover\:tw-via-green-600:hover {
    --gradient-via-color: #38a169;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(56, 161, 105, 0));
  }

  .xl\:hover\:tw-via-green-700:hover {
    --gradient-via-color: #2f855a;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(47, 133, 90, 0));
  }

  .xl\:hover\:tw-via-green-800:hover {
    --gradient-via-color: #276749;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(39, 103, 73, 0));
  }

  .xl\:hover\:tw-via-green-900:hover {
    --gradient-via-color: #22543d;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(34, 84, 61, 0));
  }

  .xl\:hover\:tw-via-teal-100:hover {
    --gradient-via-color: #e6fffa;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(230, 255, 250, 0));
  }

  .xl\:hover\:tw-via-teal-200:hover {
    --gradient-via-color: #b2f5ea;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(178, 245, 234, 0));
  }

  .xl\:hover\:tw-via-teal-300:hover {
    --gradient-via-color: #81e6d9;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(129, 230, 217, 0));
  }

  .xl\:hover\:tw-via-teal-400:hover {
    --gradient-via-color: #4fd1c5;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(79, 209, 197, 0));
  }

  .xl\:hover\:tw-via-teal-500:hover {
    --gradient-via-color: #38b2ac;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(56, 178, 172, 0));
  }

  .xl\:hover\:tw-via-teal-600:hover {
    --gradient-via-color: #319795;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(49, 151, 149, 0));
  }

  .xl\:hover\:tw-via-teal-700:hover {
    --gradient-via-color: #2c7a7b;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(44, 122, 123, 0));
  }

  .xl\:hover\:tw-via-teal-800:hover {
    --gradient-via-color: #285e61;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(40, 94, 97, 0));
  }

  .xl\:hover\:tw-via-teal-900:hover {
    --gradient-via-color: #234e52;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(35, 78, 82, 0));
  }

  .xl\:hover\:tw-via-blue-100:hover {
    --gradient-via-color: #ebf8ff;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(235, 248, 255, 0));
  }

  .xl\:hover\:tw-via-blue-200:hover {
    --gradient-via-color: #bee3f8;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(190, 227, 248, 0));
  }

  .xl\:hover\:tw-via-blue-300:hover {
    --gradient-via-color: #90cdf4;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(144, 205, 244, 0));
  }

  .xl\:hover\:tw-via-blue-400:hover {
    --gradient-via-color: #63b3ed;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(99, 179, 237, 0));
  }

  .xl\:hover\:tw-via-blue-500:hover {
    --gradient-via-color: #4299e1;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(66, 153, 225, 0));
  }

  .xl\:hover\:tw-via-blue-600:hover {
    --gradient-via-color: #3182ce;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(49, 130, 206, 0));
  }

  .xl\:hover\:tw-via-blue-700:hover {
    --gradient-via-color: #2b6cb0;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(43, 108, 176, 0));
  }

  .xl\:hover\:tw-via-blue-800:hover {
    --gradient-via-color: #2c5282;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(44, 82, 130, 0));
  }

  .xl\:hover\:tw-via-blue-900:hover {
    --gradient-via-color: #2a4365;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(42, 67, 101, 0));
  }

  .xl\:hover\:tw-via-indigo-100:hover {
    --gradient-via-color: #ebf4ff;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(235, 244, 255, 0));
  }

  .xl\:hover\:tw-via-indigo-200:hover {
    --gradient-via-color: #c3dafe;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(195, 218, 254, 0));
  }

  .xl\:hover\:tw-via-indigo-300:hover {
    --gradient-via-color: #a3bffa;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(163, 191, 250, 0));
  }

  .xl\:hover\:tw-via-indigo-400:hover {
    --gradient-via-color: #7f9cf5;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(127, 156, 245, 0));
  }

  .xl\:hover\:tw-via-indigo-500:hover {
    --gradient-via-color: #667eea;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(102, 126, 234, 0));
  }

  .xl\:hover\:tw-via-indigo-600:hover {
    --gradient-via-color: #5a67d8;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(90, 103, 216, 0));
  }

  .xl\:hover\:tw-via-indigo-700:hover {
    --gradient-via-color: #4c51bf;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(76, 81, 191, 0));
  }

  .xl\:hover\:tw-via-indigo-800:hover {
    --gradient-via-color: #434190;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(67, 65, 144, 0));
  }

  .xl\:hover\:tw-via-indigo-900:hover {
    --gradient-via-color: #3c366b;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(60, 54, 107, 0));
  }

  .xl\:hover\:tw-via-purple-100:hover {
    --gradient-via-color: #faf5ff;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(250, 245, 255, 0));
  }

  .xl\:hover\:tw-via-purple-200:hover {
    --gradient-via-color: #e9d8fd;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(233, 216, 253, 0));
  }

  .xl\:hover\:tw-via-purple-300:hover {
    --gradient-via-color: #d6bcfa;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(214, 188, 250, 0));
  }

  .xl\:hover\:tw-via-purple-400:hover {
    --gradient-via-color: #b794f4;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(183, 148, 244, 0));
  }

  .xl\:hover\:tw-via-purple-500:hover {
    --gradient-via-color: #9f7aea;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(159, 122, 234, 0));
  }

  .xl\:hover\:tw-via-purple-600:hover {
    --gradient-via-color: #805ad5;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(128, 90, 213, 0));
  }

  .xl\:hover\:tw-via-purple-700:hover {
    --gradient-via-color: #6b46c1;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(107, 70, 193, 0));
  }

  .xl\:hover\:tw-via-purple-800:hover {
    --gradient-via-color: #553c9a;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(85, 60, 154, 0));
  }

  .xl\:hover\:tw-via-purple-900:hover {
    --gradient-via-color: #44337a;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(68, 51, 122, 0));
  }

  .xl\:hover\:tw-via-pink-100:hover {
    --gradient-via-color: #fff5f7;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 245, 247, 0));
  }

  .xl\:hover\:tw-via-pink-200:hover {
    --gradient-via-color: #fed7e2;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(254, 215, 226, 0));
  }

  .xl\:hover\:tw-via-pink-300:hover {
    --gradient-via-color: #fbb6ce;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(251, 182, 206, 0));
  }

  .xl\:hover\:tw-via-pink-400:hover {
    --gradient-via-color: #f687b3;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(246, 135, 179, 0));
  }

  .xl\:hover\:tw-via-pink-500:hover {
    --gradient-via-color: #ed64a6;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(237, 100, 166, 0));
  }

  .xl\:hover\:tw-via-pink-600:hover {
    --gradient-via-color: #d53f8c;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(213, 63, 140, 0));
  }

  .xl\:hover\:tw-via-pink-700:hover {
    --gradient-via-color: #b83280;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(184, 50, 128, 0));
  }

  .xl\:hover\:tw-via-pink-800:hover {
    --gradient-via-color: #97266d;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(151, 38, 109, 0));
  }

  .xl\:hover\:tw-via-pink-900:hover {
    --gradient-via-color: #702459;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(112, 36, 89, 0));
  }

  .xl\:hover\:tw-to-transparent:hover {
    --gradient-to-color: transparent;
  }

  .xl\:hover\:tw-to-current:hover {
    --gradient-to-color: currentColor;
  }

  .xl\:hover\:tw-to-black:hover {
    --gradient-to-color: #000;
  }

  .xl\:hover\:tw-to-white:hover {
    --gradient-to-color: #fff;
  }

  .xl\:hover\:tw-to-gray-100:hover {
    --gradient-to-color: #f7fafc;
  }

  .xl\:hover\:tw-to-gray-200:hover {
    --gradient-to-color: #edf2f7;
  }

  .xl\:hover\:tw-to-gray-300:hover {
    --gradient-to-color: #e2e8f0;
  }

  .xl\:hover\:tw-to-gray-400:hover {
    --gradient-to-color: #cbd5e0;
  }

  .xl\:hover\:tw-to-gray-500:hover {
    --gradient-to-color: #a0aec0;
  }

  .xl\:hover\:tw-to-gray-600:hover {
    --gradient-to-color: #718096;
  }

  .xl\:hover\:tw-to-gray-700:hover {
    --gradient-to-color: #4a5568;
  }

  .xl\:hover\:tw-to-gray-800:hover {
    --gradient-to-color: #2d3748;
  }

  .xl\:hover\:tw-to-gray-900:hover {
    --gradient-to-color: #1a202c;
  }

  .xl\:hover\:tw-to-red-100:hover {
    --gradient-to-color: #fff5f5;
  }

  .xl\:hover\:tw-to-red-200:hover {
    --gradient-to-color: #fed7d7;
  }

  .xl\:hover\:tw-to-red-300:hover {
    --gradient-to-color: #feb2b2;
  }

  .xl\:hover\:tw-to-red-400:hover {
    --gradient-to-color: #fc8181;
  }

  .xl\:hover\:tw-to-red-500:hover {
    --gradient-to-color: #f56565;
  }

  .xl\:hover\:tw-to-red-600:hover {
    --gradient-to-color: #e53e3e;
  }

  .xl\:hover\:tw-to-red-700:hover {
    --gradient-to-color: #c53030;
  }

  .xl\:hover\:tw-to-red-800:hover {
    --gradient-to-color: #9b2c2c;
  }

  .xl\:hover\:tw-to-red-900:hover {
    --gradient-to-color: #742a2a;
  }

  .xl\:hover\:tw-to-orange-100:hover {
    --gradient-to-color: #fffaf0;
  }

  .xl\:hover\:tw-to-orange-200:hover {
    --gradient-to-color: #feebc8;
  }

  .xl\:hover\:tw-to-orange-300:hover {
    --gradient-to-color: #fbd38d;
  }

  .xl\:hover\:tw-to-orange-400:hover {
    --gradient-to-color: #f6ad55;
  }

  .xl\:hover\:tw-to-orange-500:hover {
    --gradient-to-color: #ed8936;
  }

  .xl\:hover\:tw-to-orange-600:hover {
    --gradient-to-color: #dd6b20;
  }

  .xl\:hover\:tw-to-orange-700:hover {
    --gradient-to-color: #c05621;
  }

  .xl\:hover\:tw-to-orange-800:hover {
    --gradient-to-color: #9c4221;
  }

  .xl\:hover\:tw-to-orange-900:hover {
    --gradient-to-color: #7b341e;
  }

  .xl\:hover\:tw-to-yellow-100:hover {
    --gradient-to-color: #fffff0;
  }

  .xl\:hover\:tw-to-yellow-200:hover {
    --gradient-to-color: #fefcbf;
  }

  .xl\:hover\:tw-to-yellow-300:hover {
    --gradient-to-color: #faf089;
  }

  .xl\:hover\:tw-to-yellow-400:hover {
    --gradient-to-color: #f6e05e;
  }

  .xl\:hover\:tw-to-yellow-500:hover {
    --gradient-to-color: #ecc94b;
  }

  .xl\:hover\:tw-to-yellow-600:hover {
    --gradient-to-color: #d69e2e;
  }

  .xl\:hover\:tw-to-yellow-700:hover {
    --gradient-to-color: #b7791f;
  }

  .xl\:hover\:tw-to-yellow-800:hover {
    --gradient-to-color: #975a16;
  }

  .xl\:hover\:tw-to-yellow-900:hover {
    --gradient-to-color: #744210;
  }

  .xl\:hover\:tw-to-green-100:hover {
    --gradient-to-color: #f0fff4;
  }

  .xl\:hover\:tw-to-green-200:hover {
    --gradient-to-color: #c6f6d5;
  }

  .xl\:hover\:tw-to-green-300:hover {
    --gradient-to-color: #9ae6b4;
  }

  .xl\:hover\:tw-to-green-400:hover {
    --gradient-to-color: #68d391;
  }

  .xl\:hover\:tw-to-green-500:hover {
    --gradient-to-color: #48bb78;
  }

  .xl\:hover\:tw-to-green-600:hover {
    --gradient-to-color: #38a169;
  }

  .xl\:hover\:tw-to-green-700:hover {
    --gradient-to-color: #2f855a;
  }

  .xl\:hover\:tw-to-green-800:hover {
    --gradient-to-color: #276749;
  }

  .xl\:hover\:tw-to-green-900:hover {
    --gradient-to-color: #22543d;
  }

  .xl\:hover\:tw-to-teal-100:hover {
    --gradient-to-color: #e6fffa;
  }

  .xl\:hover\:tw-to-teal-200:hover {
    --gradient-to-color: #b2f5ea;
  }

  .xl\:hover\:tw-to-teal-300:hover {
    --gradient-to-color: #81e6d9;
  }

  .xl\:hover\:tw-to-teal-400:hover {
    --gradient-to-color: #4fd1c5;
  }

  .xl\:hover\:tw-to-teal-500:hover {
    --gradient-to-color: #38b2ac;
  }

  .xl\:hover\:tw-to-teal-600:hover {
    --gradient-to-color: #319795;
  }

  .xl\:hover\:tw-to-teal-700:hover {
    --gradient-to-color: #2c7a7b;
  }

  .xl\:hover\:tw-to-teal-800:hover {
    --gradient-to-color: #285e61;
  }

  .xl\:hover\:tw-to-teal-900:hover {
    --gradient-to-color: #234e52;
  }

  .xl\:hover\:tw-to-blue-100:hover {
    --gradient-to-color: #ebf8ff;
  }

  .xl\:hover\:tw-to-blue-200:hover {
    --gradient-to-color: #bee3f8;
  }

  .xl\:hover\:tw-to-blue-300:hover {
    --gradient-to-color: #90cdf4;
  }

  .xl\:hover\:tw-to-blue-400:hover {
    --gradient-to-color: #63b3ed;
  }

  .xl\:hover\:tw-to-blue-500:hover {
    --gradient-to-color: #4299e1;
  }

  .xl\:hover\:tw-to-blue-600:hover {
    --gradient-to-color: #3182ce;
  }

  .xl\:hover\:tw-to-blue-700:hover {
    --gradient-to-color: #2b6cb0;
  }

  .xl\:hover\:tw-to-blue-800:hover {
    --gradient-to-color: #2c5282;
  }

  .xl\:hover\:tw-to-blue-900:hover {
    --gradient-to-color: #2a4365;
  }

  .xl\:hover\:tw-to-indigo-100:hover {
    --gradient-to-color: #ebf4ff;
  }

  .xl\:hover\:tw-to-indigo-200:hover {
    --gradient-to-color: #c3dafe;
  }

  .xl\:hover\:tw-to-indigo-300:hover {
    --gradient-to-color: #a3bffa;
  }

  .xl\:hover\:tw-to-indigo-400:hover {
    --gradient-to-color: #7f9cf5;
  }

  .xl\:hover\:tw-to-indigo-500:hover {
    --gradient-to-color: #667eea;
  }

  .xl\:hover\:tw-to-indigo-600:hover {
    --gradient-to-color: #5a67d8;
  }

  .xl\:hover\:tw-to-indigo-700:hover {
    --gradient-to-color: #4c51bf;
  }

  .xl\:hover\:tw-to-indigo-800:hover {
    --gradient-to-color: #434190;
  }

  .xl\:hover\:tw-to-indigo-900:hover {
    --gradient-to-color: #3c366b;
  }

  .xl\:hover\:tw-to-purple-100:hover {
    --gradient-to-color: #faf5ff;
  }

  .xl\:hover\:tw-to-purple-200:hover {
    --gradient-to-color: #e9d8fd;
  }

  .xl\:hover\:tw-to-purple-300:hover {
    --gradient-to-color: #d6bcfa;
  }

  .xl\:hover\:tw-to-purple-400:hover {
    --gradient-to-color: #b794f4;
  }

  .xl\:hover\:tw-to-purple-500:hover {
    --gradient-to-color: #9f7aea;
  }

  .xl\:hover\:tw-to-purple-600:hover {
    --gradient-to-color: #805ad5;
  }

  .xl\:hover\:tw-to-purple-700:hover {
    --gradient-to-color: #6b46c1;
  }

  .xl\:hover\:tw-to-purple-800:hover {
    --gradient-to-color: #553c9a;
  }

  .xl\:hover\:tw-to-purple-900:hover {
    --gradient-to-color: #44337a;
  }

  .xl\:hover\:tw-to-pink-100:hover {
    --gradient-to-color: #fff5f7;
  }

  .xl\:hover\:tw-to-pink-200:hover {
    --gradient-to-color: #fed7e2;
  }

  .xl\:hover\:tw-to-pink-300:hover {
    --gradient-to-color: #fbb6ce;
  }

  .xl\:hover\:tw-to-pink-400:hover {
    --gradient-to-color: #f687b3;
  }

  .xl\:hover\:tw-to-pink-500:hover {
    --gradient-to-color: #ed64a6;
  }

  .xl\:hover\:tw-to-pink-600:hover {
    --gradient-to-color: #d53f8c;
  }

  .xl\:hover\:tw-to-pink-700:hover {
    --gradient-to-color: #b83280;
  }

  .xl\:hover\:tw-to-pink-800:hover {
    --gradient-to-color: #97266d;
  }

  .xl\:hover\:tw-to-pink-900:hover {
    --gradient-to-color: #702459;
  }

  .xl\:focus\:tw-from-transparent:focus {
    --gradient-from-color: transparent;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(0, 0, 0, 0));
  }

  .xl\:focus\:tw-from-current:focus {
    --gradient-from-color: currentColor;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0));
  }

  .xl\:focus\:tw-from-black:focus {
    --gradient-from-color: #000;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(0, 0, 0, 0));
  }

  .xl\:focus\:tw-from-white:focus {
    --gradient-from-color: #fff;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0));
  }

  .xl\:focus\:tw-from-gray-100:focus {
    --gradient-from-color: #f7fafc;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(247, 250, 252, 0));
  }

  .xl\:focus\:tw-from-gray-200:focus {
    --gradient-from-color: #edf2f7;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(237, 242, 247, 0));
  }

  .xl\:focus\:tw-from-gray-300:focus {
    --gradient-from-color: #e2e8f0;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(226, 232, 240, 0));
  }

  .xl\:focus\:tw-from-gray-400:focus {
    --gradient-from-color: #cbd5e0;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(203, 213, 224, 0));
  }

  .xl\:focus\:tw-from-gray-500:focus {
    --gradient-from-color: #a0aec0;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(160, 174, 192, 0));
  }

  .xl\:focus\:tw-from-gray-600:focus {
    --gradient-from-color: #718096;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(113, 128, 150, 0));
  }

  .xl\:focus\:tw-from-gray-700:focus {
    --gradient-from-color: #4a5568;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(74, 85, 104, 0));
  }

  .xl\:focus\:tw-from-gray-800:focus {
    --gradient-from-color: #2d3748;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(45, 55, 72, 0));
  }

  .xl\:focus\:tw-from-gray-900:focus {
    --gradient-from-color: #1a202c;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(26, 32, 44, 0));
  }

  .xl\:focus\:tw-from-red-100:focus {
    --gradient-from-color: #fff5f5;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 245, 245, 0));
  }

  .xl\:focus\:tw-from-red-200:focus {
    --gradient-from-color: #fed7d7;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(254, 215, 215, 0));
  }

  .xl\:focus\:tw-from-red-300:focus {
    --gradient-from-color: #feb2b2;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(254, 178, 178, 0));
  }

  .xl\:focus\:tw-from-red-400:focus {
    --gradient-from-color: #fc8181;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(252, 129, 129, 0));
  }

  .xl\:focus\:tw-from-red-500:focus {
    --gradient-from-color: #f56565;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(245, 101, 101, 0));
  }

  .xl\:focus\:tw-from-red-600:focus {
    --gradient-from-color: #e53e3e;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(229, 62, 62, 0));
  }

  .xl\:focus\:tw-from-red-700:focus {
    --gradient-from-color: #c53030;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(197, 48, 48, 0));
  }

  .xl\:focus\:tw-from-red-800:focus {
    --gradient-from-color: #9b2c2c;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(155, 44, 44, 0));
  }

  .xl\:focus\:tw-from-red-900:focus {
    --gradient-from-color: #742a2a;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(116, 42, 42, 0));
  }

  .xl\:focus\:tw-from-orange-100:focus {
    --gradient-from-color: #fffaf0;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 250, 240, 0));
  }

  .xl\:focus\:tw-from-orange-200:focus {
    --gradient-from-color: #feebc8;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(254, 235, 200, 0));
  }

  .xl\:focus\:tw-from-orange-300:focus {
    --gradient-from-color: #fbd38d;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(251, 211, 141, 0));
  }

  .xl\:focus\:tw-from-orange-400:focus {
    --gradient-from-color: #f6ad55;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(246, 173, 85, 0));
  }

  .xl\:focus\:tw-from-orange-500:focus {
    --gradient-from-color: #ed8936;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(237, 137, 54, 0));
  }

  .xl\:focus\:tw-from-orange-600:focus {
    --gradient-from-color: #dd6b20;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(221, 107, 32, 0));
  }

  .xl\:focus\:tw-from-orange-700:focus {
    --gradient-from-color: #c05621;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(192, 86, 33, 0));
  }

  .xl\:focus\:tw-from-orange-800:focus {
    --gradient-from-color: #9c4221;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(156, 66, 33, 0));
  }

  .xl\:focus\:tw-from-orange-900:focus {
    --gradient-from-color: #7b341e;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(123, 52, 30, 0));
  }

  .xl\:focus\:tw-from-yellow-100:focus {
    --gradient-from-color: #fffff0;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 240, 0));
  }

  .xl\:focus\:tw-from-yellow-200:focus {
    --gradient-from-color: #fefcbf;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(254, 252, 191, 0));
  }

  .xl\:focus\:tw-from-yellow-300:focus {
    --gradient-from-color: #faf089;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(250, 240, 137, 0));
  }

  .xl\:focus\:tw-from-yellow-400:focus {
    --gradient-from-color: #f6e05e;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(246, 224, 94, 0));
  }

  .xl\:focus\:tw-from-yellow-500:focus {
    --gradient-from-color: #ecc94b;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(236, 201, 75, 0));
  }

  .xl\:focus\:tw-from-yellow-600:focus {
    --gradient-from-color: #d69e2e;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(214, 158, 46, 0));
  }

  .xl\:focus\:tw-from-yellow-700:focus {
    --gradient-from-color: #b7791f;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(183, 121, 31, 0));
  }

  .xl\:focus\:tw-from-yellow-800:focus {
    --gradient-from-color: #975a16;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(151, 90, 22, 0));
  }

  .xl\:focus\:tw-from-yellow-900:focus {
    --gradient-from-color: #744210;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(116, 66, 16, 0));
  }

  .xl\:focus\:tw-from-green-100:focus {
    --gradient-from-color: #f0fff4;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(240, 255, 244, 0));
  }

  .xl\:focus\:tw-from-green-200:focus {
    --gradient-from-color: #c6f6d5;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(198, 246, 213, 0));
  }

  .xl\:focus\:tw-from-green-300:focus {
    --gradient-from-color: #9ae6b4;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(154, 230, 180, 0));
  }

  .xl\:focus\:tw-from-green-400:focus {
    --gradient-from-color: #68d391;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(104, 211, 145, 0));
  }

  .xl\:focus\:tw-from-green-500:focus {
    --gradient-from-color: #48bb78;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(72, 187, 120, 0));
  }

  .xl\:focus\:tw-from-green-600:focus {
    --gradient-from-color: #38a169;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(56, 161, 105, 0));
  }

  .xl\:focus\:tw-from-green-700:focus {
    --gradient-from-color: #2f855a;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(47, 133, 90, 0));
  }

  .xl\:focus\:tw-from-green-800:focus {
    --gradient-from-color: #276749;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(39, 103, 73, 0));
  }

  .xl\:focus\:tw-from-green-900:focus {
    --gradient-from-color: #22543d;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(34, 84, 61, 0));
  }

  .xl\:focus\:tw-from-teal-100:focus {
    --gradient-from-color: #e6fffa;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(230, 255, 250, 0));
  }

  .xl\:focus\:tw-from-teal-200:focus {
    --gradient-from-color: #b2f5ea;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(178, 245, 234, 0));
  }

  .xl\:focus\:tw-from-teal-300:focus {
    --gradient-from-color: #81e6d9;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(129, 230, 217, 0));
  }

  .xl\:focus\:tw-from-teal-400:focus {
    --gradient-from-color: #4fd1c5;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(79, 209, 197, 0));
  }

  .xl\:focus\:tw-from-teal-500:focus {
    --gradient-from-color: #38b2ac;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(56, 178, 172, 0));
  }

  .xl\:focus\:tw-from-teal-600:focus {
    --gradient-from-color: #319795;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(49, 151, 149, 0));
  }

  .xl\:focus\:tw-from-teal-700:focus {
    --gradient-from-color: #2c7a7b;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(44, 122, 123, 0));
  }

  .xl\:focus\:tw-from-teal-800:focus {
    --gradient-from-color: #285e61;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(40, 94, 97, 0));
  }

  .xl\:focus\:tw-from-teal-900:focus {
    --gradient-from-color: #234e52;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(35, 78, 82, 0));
  }

  .xl\:focus\:tw-from-blue-100:focus {
    --gradient-from-color: #ebf8ff;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(235, 248, 255, 0));
  }

  .xl\:focus\:tw-from-blue-200:focus {
    --gradient-from-color: #bee3f8;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(190, 227, 248, 0));
  }

  .xl\:focus\:tw-from-blue-300:focus {
    --gradient-from-color: #90cdf4;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(144, 205, 244, 0));
  }

  .xl\:focus\:tw-from-blue-400:focus {
    --gradient-from-color: #63b3ed;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(99, 179, 237, 0));
  }

  .xl\:focus\:tw-from-blue-500:focus {
    --gradient-from-color: #4299e1;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(66, 153, 225, 0));
  }

  .xl\:focus\:tw-from-blue-600:focus {
    --gradient-from-color: #3182ce;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(49, 130, 206, 0));
  }

  .xl\:focus\:tw-from-blue-700:focus {
    --gradient-from-color: #2b6cb0;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(43, 108, 176, 0));
  }

  .xl\:focus\:tw-from-blue-800:focus {
    --gradient-from-color: #2c5282;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(44, 82, 130, 0));
  }

  .xl\:focus\:tw-from-blue-900:focus {
    --gradient-from-color: #2a4365;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(42, 67, 101, 0));
  }

  .xl\:focus\:tw-from-indigo-100:focus {
    --gradient-from-color: #ebf4ff;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(235, 244, 255, 0));
  }

  .xl\:focus\:tw-from-indigo-200:focus {
    --gradient-from-color: #c3dafe;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(195, 218, 254, 0));
  }

  .xl\:focus\:tw-from-indigo-300:focus {
    --gradient-from-color: #a3bffa;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(163, 191, 250, 0));
  }

  .xl\:focus\:tw-from-indigo-400:focus {
    --gradient-from-color: #7f9cf5;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(127, 156, 245, 0));
  }

  .xl\:focus\:tw-from-indigo-500:focus {
    --gradient-from-color: #667eea;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(102, 126, 234, 0));
  }

  .xl\:focus\:tw-from-indigo-600:focus {
    --gradient-from-color: #5a67d8;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(90, 103, 216, 0));
  }

  .xl\:focus\:tw-from-indigo-700:focus {
    --gradient-from-color: #4c51bf;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(76, 81, 191, 0));
  }

  .xl\:focus\:tw-from-indigo-800:focus {
    --gradient-from-color: #434190;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(67, 65, 144, 0));
  }

  .xl\:focus\:tw-from-indigo-900:focus {
    --gradient-from-color: #3c366b;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(60, 54, 107, 0));
  }

  .xl\:focus\:tw-from-purple-100:focus {
    --gradient-from-color: #faf5ff;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(250, 245, 255, 0));
  }

  .xl\:focus\:tw-from-purple-200:focus {
    --gradient-from-color: #e9d8fd;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(233, 216, 253, 0));
  }

  .xl\:focus\:tw-from-purple-300:focus {
    --gradient-from-color: #d6bcfa;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(214, 188, 250, 0));
  }

  .xl\:focus\:tw-from-purple-400:focus {
    --gradient-from-color: #b794f4;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(183, 148, 244, 0));
  }

  .xl\:focus\:tw-from-purple-500:focus {
    --gradient-from-color: #9f7aea;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(159, 122, 234, 0));
  }

  .xl\:focus\:tw-from-purple-600:focus {
    --gradient-from-color: #805ad5;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(128, 90, 213, 0));
  }

  .xl\:focus\:tw-from-purple-700:focus {
    --gradient-from-color: #6b46c1;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(107, 70, 193, 0));
  }

  .xl\:focus\:tw-from-purple-800:focus {
    --gradient-from-color: #553c9a;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(85, 60, 154, 0));
  }

  .xl\:focus\:tw-from-purple-900:focus {
    --gradient-from-color: #44337a;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(68, 51, 122, 0));
  }

  .xl\:focus\:tw-from-pink-100:focus {
    --gradient-from-color: #fff5f7;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 245, 247, 0));
  }

  .xl\:focus\:tw-from-pink-200:focus {
    --gradient-from-color: #fed7e2;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(254, 215, 226, 0));
  }

  .xl\:focus\:tw-from-pink-300:focus {
    --gradient-from-color: #fbb6ce;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(251, 182, 206, 0));
  }

  .xl\:focus\:tw-from-pink-400:focus {
    --gradient-from-color: #f687b3;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(246, 135, 179, 0));
  }

  .xl\:focus\:tw-from-pink-500:focus {
    --gradient-from-color: #ed64a6;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(237, 100, 166, 0));
  }

  .xl\:focus\:tw-from-pink-600:focus {
    --gradient-from-color: #d53f8c;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(213, 63, 140, 0));
  }

  .xl\:focus\:tw-from-pink-700:focus {
    --gradient-from-color: #b83280;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(184, 50, 128, 0));
  }

  .xl\:focus\:tw-from-pink-800:focus {
    --gradient-from-color: #97266d;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(151, 38, 109, 0));
  }

  .xl\:focus\:tw-from-pink-900:focus {
    --gradient-from-color: #702459;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(112, 36, 89, 0));
  }

  .xl\:focus\:tw-via-transparent:focus {
    --gradient-via-color: transparent;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(0, 0, 0, 0));
  }

  .xl\:focus\:tw-via-current:focus {
    --gradient-via-color: currentColor;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0));
  }

  .xl\:focus\:tw-via-black:focus {
    --gradient-via-color: #000;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(0, 0, 0, 0));
  }

  .xl\:focus\:tw-via-white:focus {
    --gradient-via-color: #fff;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0));
  }

  .xl\:focus\:tw-via-gray-100:focus {
    --gradient-via-color: #f7fafc;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(247, 250, 252, 0));
  }

  .xl\:focus\:tw-via-gray-200:focus {
    --gradient-via-color: #edf2f7;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(237, 242, 247, 0));
  }

  .xl\:focus\:tw-via-gray-300:focus {
    --gradient-via-color: #e2e8f0;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(226, 232, 240, 0));
  }

  .xl\:focus\:tw-via-gray-400:focus {
    --gradient-via-color: #cbd5e0;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(203, 213, 224, 0));
  }

  .xl\:focus\:tw-via-gray-500:focus {
    --gradient-via-color: #a0aec0;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(160, 174, 192, 0));
  }

  .xl\:focus\:tw-via-gray-600:focus {
    --gradient-via-color: #718096;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(113, 128, 150, 0));
  }

  .xl\:focus\:tw-via-gray-700:focus {
    --gradient-via-color: #4a5568;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(74, 85, 104, 0));
  }

  .xl\:focus\:tw-via-gray-800:focus {
    --gradient-via-color: #2d3748;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(45, 55, 72, 0));
  }

  .xl\:focus\:tw-via-gray-900:focus {
    --gradient-via-color: #1a202c;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(26, 32, 44, 0));
  }

  .xl\:focus\:tw-via-red-100:focus {
    --gradient-via-color: #fff5f5;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 245, 245, 0));
  }

  .xl\:focus\:tw-via-red-200:focus {
    --gradient-via-color: #fed7d7;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(254, 215, 215, 0));
  }

  .xl\:focus\:tw-via-red-300:focus {
    --gradient-via-color: #feb2b2;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(254, 178, 178, 0));
  }

  .xl\:focus\:tw-via-red-400:focus {
    --gradient-via-color: #fc8181;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(252, 129, 129, 0));
  }

  .xl\:focus\:tw-via-red-500:focus {
    --gradient-via-color: #f56565;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(245, 101, 101, 0));
  }

  .xl\:focus\:tw-via-red-600:focus {
    --gradient-via-color: #e53e3e;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(229, 62, 62, 0));
  }

  .xl\:focus\:tw-via-red-700:focus {
    --gradient-via-color: #c53030;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(197, 48, 48, 0));
  }

  .xl\:focus\:tw-via-red-800:focus {
    --gradient-via-color: #9b2c2c;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(155, 44, 44, 0));
  }

  .xl\:focus\:tw-via-red-900:focus {
    --gradient-via-color: #742a2a;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(116, 42, 42, 0));
  }

  .xl\:focus\:tw-via-orange-100:focus {
    --gradient-via-color: #fffaf0;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 250, 240, 0));
  }

  .xl\:focus\:tw-via-orange-200:focus {
    --gradient-via-color: #feebc8;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(254, 235, 200, 0));
  }

  .xl\:focus\:tw-via-orange-300:focus {
    --gradient-via-color: #fbd38d;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(251, 211, 141, 0));
  }

  .xl\:focus\:tw-via-orange-400:focus {
    --gradient-via-color: #f6ad55;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(246, 173, 85, 0));
  }

  .xl\:focus\:tw-via-orange-500:focus {
    --gradient-via-color: #ed8936;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(237, 137, 54, 0));
  }

  .xl\:focus\:tw-via-orange-600:focus {
    --gradient-via-color: #dd6b20;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(221, 107, 32, 0));
  }

  .xl\:focus\:tw-via-orange-700:focus {
    --gradient-via-color: #c05621;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(192, 86, 33, 0));
  }

  .xl\:focus\:tw-via-orange-800:focus {
    --gradient-via-color: #9c4221;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(156, 66, 33, 0));
  }

  .xl\:focus\:tw-via-orange-900:focus {
    --gradient-via-color: #7b341e;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(123, 52, 30, 0));
  }

  .xl\:focus\:tw-via-yellow-100:focus {
    --gradient-via-color: #fffff0;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 240, 0));
  }

  .xl\:focus\:tw-via-yellow-200:focus {
    --gradient-via-color: #fefcbf;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(254, 252, 191, 0));
  }

  .xl\:focus\:tw-via-yellow-300:focus {
    --gradient-via-color: #faf089;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(250, 240, 137, 0));
  }

  .xl\:focus\:tw-via-yellow-400:focus {
    --gradient-via-color: #f6e05e;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(246, 224, 94, 0));
  }

  .xl\:focus\:tw-via-yellow-500:focus {
    --gradient-via-color: #ecc94b;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(236, 201, 75, 0));
  }

  .xl\:focus\:tw-via-yellow-600:focus {
    --gradient-via-color: #d69e2e;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(214, 158, 46, 0));
  }

  .xl\:focus\:tw-via-yellow-700:focus {
    --gradient-via-color: #b7791f;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(183, 121, 31, 0));
  }

  .xl\:focus\:tw-via-yellow-800:focus {
    --gradient-via-color: #975a16;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(151, 90, 22, 0));
  }

  .xl\:focus\:tw-via-yellow-900:focus {
    --gradient-via-color: #744210;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(116, 66, 16, 0));
  }

  .xl\:focus\:tw-via-green-100:focus {
    --gradient-via-color: #f0fff4;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(240, 255, 244, 0));
  }

  .xl\:focus\:tw-via-green-200:focus {
    --gradient-via-color: #c6f6d5;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(198, 246, 213, 0));
  }

  .xl\:focus\:tw-via-green-300:focus {
    --gradient-via-color: #9ae6b4;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(154, 230, 180, 0));
  }

  .xl\:focus\:tw-via-green-400:focus {
    --gradient-via-color: #68d391;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(104, 211, 145, 0));
  }

  .xl\:focus\:tw-via-green-500:focus {
    --gradient-via-color: #48bb78;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(72, 187, 120, 0));
  }

  .xl\:focus\:tw-via-green-600:focus {
    --gradient-via-color: #38a169;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(56, 161, 105, 0));
  }

  .xl\:focus\:tw-via-green-700:focus {
    --gradient-via-color: #2f855a;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(47, 133, 90, 0));
  }

  .xl\:focus\:tw-via-green-800:focus {
    --gradient-via-color: #276749;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(39, 103, 73, 0));
  }

  .xl\:focus\:tw-via-green-900:focus {
    --gradient-via-color: #22543d;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(34, 84, 61, 0));
  }

  .xl\:focus\:tw-via-teal-100:focus {
    --gradient-via-color: #e6fffa;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(230, 255, 250, 0));
  }

  .xl\:focus\:tw-via-teal-200:focus {
    --gradient-via-color: #b2f5ea;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(178, 245, 234, 0));
  }

  .xl\:focus\:tw-via-teal-300:focus {
    --gradient-via-color: #81e6d9;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(129, 230, 217, 0));
  }

  .xl\:focus\:tw-via-teal-400:focus {
    --gradient-via-color: #4fd1c5;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(79, 209, 197, 0));
  }

  .xl\:focus\:tw-via-teal-500:focus {
    --gradient-via-color: #38b2ac;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(56, 178, 172, 0));
  }

  .xl\:focus\:tw-via-teal-600:focus {
    --gradient-via-color: #319795;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(49, 151, 149, 0));
  }

  .xl\:focus\:tw-via-teal-700:focus {
    --gradient-via-color: #2c7a7b;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(44, 122, 123, 0));
  }

  .xl\:focus\:tw-via-teal-800:focus {
    --gradient-via-color: #285e61;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(40, 94, 97, 0));
  }

  .xl\:focus\:tw-via-teal-900:focus {
    --gradient-via-color: #234e52;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(35, 78, 82, 0));
  }

  .xl\:focus\:tw-via-blue-100:focus {
    --gradient-via-color: #ebf8ff;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(235, 248, 255, 0));
  }

  .xl\:focus\:tw-via-blue-200:focus {
    --gradient-via-color: #bee3f8;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(190, 227, 248, 0));
  }

  .xl\:focus\:tw-via-blue-300:focus {
    --gradient-via-color: #90cdf4;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(144, 205, 244, 0));
  }

  .xl\:focus\:tw-via-blue-400:focus {
    --gradient-via-color: #63b3ed;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(99, 179, 237, 0));
  }

  .xl\:focus\:tw-via-blue-500:focus {
    --gradient-via-color: #4299e1;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(66, 153, 225, 0));
  }

  .xl\:focus\:tw-via-blue-600:focus {
    --gradient-via-color: #3182ce;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(49, 130, 206, 0));
  }

  .xl\:focus\:tw-via-blue-700:focus {
    --gradient-via-color: #2b6cb0;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(43, 108, 176, 0));
  }

  .xl\:focus\:tw-via-blue-800:focus {
    --gradient-via-color: #2c5282;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(44, 82, 130, 0));
  }

  .xl\:focus\:tw-via-blue-900:focus {
    --gradient-via-color: #2a4365;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(42, 67, 101, 0));
  }

  .xl\:focus\:tw-via-indigo-100:focus {
    --gradient-via-color: #ebf4ff;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(235, 244, 255, 0));
  }

  .xl\:focus\:tw-via-indigo-200:focus {
    --gradient-via-color: #c3dafe;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(195, 218, 254, 0));
  }

  .xl\:focus\:tw-via-indigo-300:focus {
    --gradient-via-color: #a3bffa;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(163, 191, 250, 0));
  }

  .xl\:focus\:tw-via-indigo-400:focus {
    --gradient-via-color: #7f9cf5;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(127, 156, 245, 0));
  }

  .xl\:focus\:tw-via-indigo-500:focus {
    --gradient-via-color: #667eea;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(102, 126, 234, 0));
  }

  .xl\:focus\:tw-via-indigo-600:focus {
    --gradient-via-color: #5a67d8;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(90, 103, 216, 0));
  }

  .xl\:focus\:tw-via-indigo-700:focus {
    --gradient-via-color: #4c51bf;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(76, 81, 191, 0));
  }

  .xl\:focus\:tw-via-indigo-800:focus {
    --gradient-via-color: #434190;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(67, 65, 144, 0));
  }

  .xl\:focus\:tw-via-indigo-900:focus {
    --gradient-via-color: #3c366b;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(60, 54, 107, 0));
  }

  .xl\:focus\:tw-via-purple-100:focus {
    --gradient-via-color: #faf5ff;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(250, 245, 255, 0));
  }

  .xl\:focus\:tw-via-purple-200:focus {
    --gradient-via-color: #e9d8fd;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(233, 216, 253, 0));
  }

  .xl\:focus\:tw-via-purple-300:focus {
    --gradient-via-color: #d6bcfa;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(214, 188, 250, 0));
  }

  .xl\:focus\:tw-via-purple-400:focus {
    --gradient-via-color: #b794f4;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(183, 148, 244, 0));
  }

  .xl\:focus\:tw-via-purple-500:focus {
    --gradient-via-color: #9f7aea;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(159, 122, 234, 0));
  }

  .xl\:focus\:tw-via-purple-600:focus {
    --gradient-via-color: #805ad5;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(128, 90, 213, 0));
  }

  .xl\:focus\:tw-via-purple-700:focus {
    --gradient-via-color: #6b46c1;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(107, 70, 193, 0));
  }

  .xl\:focus\:tw-via-purple-800:focus {
    --gradient-via-color: #553c9a;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(85, 60, 154, 0));
  }

  .xl\:focus\:tw-via-purple-900:focus {
    --gradient-via-color: #44337a;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(68, 51, 122, 0));
  }

  .xl\:focus\:tw-via-pink-100:focus {
    --gradient-via-color: #fff5f7;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 245, 247, 0));
  }

  .xl\:focus\:tw-via-pink-200:focus {
    --gradient-via-color: #fed7e2;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(254, 215, 226, 0));
  }

  .xl\:focus\:tw-via-pink-300:focus {
    --gradient-via-color: #fbb6ce;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(251, 182, 206, 0));
  }

  .xl\:focus\:tw-via-pink-400:focus {
    --gradient-via-color: #f687b3;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(246, 135, 179, 0));
  }

  .xl\:focus\:tw-via-pink-500:focus {
    --gradient-via-color: #ed64a6;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(237, 100, 166, 0));
  }

  .xl\:focus\:tw-via-pink-600:focus {
    --gradient-via-color: #d53f8c;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(213, 63, 140, 0));
  }

  .xl\:focus\:tw-via-pink-700:focus {
    --gradient-via-color: #b83280;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(184, 50, 128, 0));
  }

  .xl\:focus\:tw-via-pink-800:focus {
    --gradient-via-color: #97266d;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(151, 38, 109, 0));
  }

  .xl\:focus\:tw-via-pink-900:focus {
    --gradient-via-color: #702459;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(112, 36, 89, 0));
  }

  .xl\:focus\:tw-to-transparent:focus {
    --gradient-to-color: transparent;
  }

  .xl\:focus\:tw-to-current:focus {
    --gradient-to-color: currentColor;
  }

  .xl\:focus\:tw-to-black:focus {
    --gradient-to-color: #000;
  }

  .xl\:focus\:tw-to-white:focus {
    --gradient-to-color: #fff;
  }

  .xl\:focus\:tw-to-gray-100:focus {
    --gradient-to-color: #f7fafc;
  }

  .xl\:focus\:tw-to-gray-200:focus {
    --gradient-to-color: #edf2f7;
  }

  .xl\:focus\:tw-to-gray-300:focus {
    --gradient-to-color: #e2e8f0;
  }

  .xl\:focus\:tw-to-gray-400:focus {
    --gradient-to-color: #cbd5e0;
  }

  .xl\:focus\:tw-to-gray-500:focus {
    --gradient-to-color: #a0aec0;
  }

  .xl\:focus\:tw-to-gray-600:focus {
    --gradient-to-color: #718096;
  }

  .xl\:focus\:tw-to-gray-700:focus {
    --gradient-to-color: #4a5568;
  }

  .xl\:focus\:tw-to-gray-800:focus {
    --gradient-to-color: #2d3748;
  }

  .xl\:focus\:tw-to-gray-900:focus {
    --gradient-to-color: #1a202c;
  }

  .xl\:focus\:tw-to-red-100:focus {
    --gradient-to-color: #fff5f5;
  }

  .xl\:focus\:tw-to-red-200:focus {
    --gradient-to-color: #fed7d7;
  }

  .xl\:focus\:tw-to-red-300:focus {
    --gradient-to-color: #feb2b2;
  }

  .xl\:focus\:tw-to-red-400:focus {
    --gradient-to-color: #fc8181;
  }

  .xl\:focus\:tw-to-red-500:focus {
    --gradient-to-color: #f56565;
  }

  .xl\:focus\:tw-to-red-600:focus {
    --gradient-to-color: #e53e3e;
  }

  .xl\:focus\:tw-to-red-700:focus {
    --gradient-to-color: #c53030;
  }

  .xl\:focus\:tw-to-red-800:focus {
    --gradient-to-color: #9b2c2c;
  }

  .xl\:focus\:tw-to-red-900:focus {
    --gradient-to-color: #742a2a;
  }

  .xl\:focus\:tw-to-orange-100:focus {
    --gradient-to-color: #fffaf0;
  }

  .xl\:focus\:tw-to-orange-200:focus {
    --gradient-to-color: #feebc8;
  }

  .xl\:focus\:tw-to-orange-300:focus {
    --gradient-to-color: #fbd38d;
  }

  .xl\:focus\:tw-to-orange-400:focus {
    --gradient-to-color: #f6ad55;
  }

  .xl\:focus\:tw-to-orange-500:focus {
    --gradient-to-color: #ed8936;
  }

  .xl\:focus\:tw-to-orange-600:focus {
    --gradient-to-color: #dd6b20;
  }

  .xl\:focus\:tw-to-orange-700:focus {
    --gradient-to-color: #c05621;
  }

  .xl\:focus\:tw-to-orange-800:focus {
    --gradient-to-color: #9c4221;
  }

  .xl\:focus\:tw-to-orange-900:focus {
    --gradient-to-color: #7b341e;
  }

  .xl\:focus\:tw-to-yellow-100:focus {
    --gradient-to-color: #fffff0;
  }

  .xl\:focus\:tw-to-yellow-200:focus {
    --gradient-to-color: #fefcbf;
  }

  .xl\:focus\:tw-to-yellow-300:focus {
    --gradient-to-color: #faf089;
  }

  .xl\:focus\:tw-to-yellow-400:focus {
    --gradient-to-color: #f6e05e;
  }

  .xl\:focus\:tw-to-yellow-500:focus {
    --gradient-to-color: #ecc94b;
  }

  .xl\:focus\:tw-to-yellow-600:focus {
    --gradient-to-color: #d69e2e;
  }

  .xl\:focus\:tw-to-yellow-700:focus {
    --gradient-to-color: #b7791f;
  }

  .xl\:focus\:tw-to-yellow-800:focus {
    --gradient-to-color: #975a16;
  }

  .xl\:focus\:tw-to-yellow-900:focus {
    --gradient-to-color: #744210;
  }

  .xl\:focus\:tw-to-green-100:focus {
    --gradient-to-color: #f0fff4;
  }

  .xl\:focus\:tw-to-green-200:focus {
    --gradient-to-color: #c6f6d5;
  }

  .xl\:focus\:tw-to-green-300:focus {
    --gradient-to-color: #9ae6b4;
  }

  .xl\:focus\:tw-to-green-400:focus {
    --gradient-to-color: #68d391;
  }

  .xl\:focus\:tw-to-green-500:focus {
    --gradient-to-color: #48bb78;
  }

  .xl\:focus\:tw-to-green-600:focus {
    --gradient-to-color: #38a169;
  }

  .xl\:focus\:tw-to-green-700:focus {
    --gradient-to-color: #2f855a;
  }

  .xl\:focus\:tw-to-green-800:focus {
    --gradient-to-color: #276749;
  }

  .xl\:focus\:tw-to-green-900:focus {
    --gradient-to-color: #22543d;
  }

  .xl\:focus\:tw-to-teal-100:focus {
    --gradient-to-color: #e6fffa;
  }

  .xl\:focus\:tw-to-teal-200:focus {
    --gradient-to-color: #b2f5ea;
  }

  .xl\:focus\:tw-to-teal-300:focus {
    --gradient-to-color: #81e6d9;
  }

  .xl\:focus\:tw-to-teal-400:focus {
    --gradient-to-color: #4fd1c5;
  }

  .xl\:focus\:tw-to-teal-500:focus {
    --gradient-to-color: #38b2ac;
  }

  .xl\:focus\:tw-to-teal-600:focus {
    --gradient-to-color: #319795;
  }

  .xl\:focus\:tw-to-teal-700:focus {
    --gradient-to-color: #2c7a7b;
  }

  .xl\:focus\:tw-to-teal-800:focus {
    --gradient-to-color: #285e61;
  }

  .xl\:focus\:tw-to-teal-900:focus {
    --gradient-to-color: #234e52;
  }

  .xl\:focus\:tw-to-blue-100:focus {
    --gradient-to-color: #ebf8ff;
  }

  .xl\:focus\:tw-to-blue-200:focus {
    --gradient-to-color: #bee3f8;
  }

  .xl\:focus\:tw-to-blue-300:focus {
    --gradient-to-color: #90cdf4;
  }

  .xl\:focus\:tw-to-blue-400:focus {
    --gradient-to-color: #63b3ed;
  }

  .xl\:focus\:tw-to-blue-500:focus {
    --gradient-to-color: #4299e1;
  }

  .xl\:focus\:tw-to-blue-600:focus {
    --gradient-to-color: #3182ce;
  }

  .xl\:focus\:tw-to-blue-700:focus {
    --gradient-to-color: #2b6cb0;
  }

  .xl\:focus\:tw-to-blue-800:focus {
    --gradient-to-color: #2c5282;
  }

  .xl\:focus\:tw-to-blue-900:focus {
    --gradient-to-color: #2a4365;
  }

  .xl\:focus\:tw-to-indigo-100:focus {
    --gradient-to-color: #ebf4ff;
  }

  .xl\:focus\:tw-to-indigo-200:focus {
    --gradient-to-color: #c3dafe;
  }

  .xl\:focus\:tw-to-indigo-300:focus {
    --gradient-to-color: #a3bffa;
  }

  .xl\:focus\:tw-to-indigo-400:focus {
    --gradient-to-color: #7f9cf5;
  }

  .xl\:focus\:tw-to-indigo-500:focus {
    --gradient-to-color: #667eea;
  }

  .xl\:focus\:tw-to-indigo-600:focus {
    --gradient-to-color: #5a67d8;
  }

  .xl\:focus\:tw-to-indigo-700:focus {
    --gradient-to-color: #4c51bf;
  }

  .xl\:focus\:tw-to-indigo-800:focus {
    --gradient-to-color: #434190;
  }

  .xl\:focus\:tw-to-indigo-900:focus {
    --gradient-to-color: #3c366b;
  }

  .xl\:focus\:tw-to-purple-100:focus {
    --gradient-to-color: #faf5ff;
  }

  .xl\:focus\:tw-to-purple-200:focus {
    --gradient-to-color: #e9d8fd;
  }

  .xl\:focus\:tw-to-purple-300:focus {
    --gradient-to-color: #d6bcfa;
  }

  .xl\:focus\:tw-to-purple-400:focus {
    --gradient-to-color: #b794f4;
  }

  .xl\:focus\:tw-to-purple-500:focus {
    --gradient-to-color: #9f7aea;
  }

  .xl\:focus\:tw-to-purple-600:focus {
    --gradient-to-color: #805ad5;
  }

  .xl\:focus\:tw-to-purple-700:focus {
    --gradient-to-color: #6b46c1;
  }

  .xl\:focus\:tw-to-purple-800:focus {
    --gradient-to-color: #553c9a;
  }

  .xl\:focus\:tw-to-purple-900:focus {
    --gradient-to-color: #44337a;
  }

  .xl\:focus\:tw-to-pink-100:focus {
    --gradient-to-color: #fff5f7;
  }

  .xl\:focus\:tw-to-pink-200:focus {
    --gradient-to-color: #fed7e2;
  }

  .xl\:focus\:tw-to-pink-300:focus {
    --gradient-to-color: #fbb6ce;
  }

  .xl\:focus\:tw-to-pink-400:focus {
    --gradient-to-color: #f687b3;
  }

  .xl\:focus\:tw-to-pink-500:focus {
    --gradient-to-color: #ed64a6;
  }

  .xl\:focus\:tw-to-pink-600:focus {
    --gradient-to-color: #d53f8c;
  }

  .xl\:focus\:tw-to-pink-700:focus {
    --gradient-to-color: #b83280;
  }

  .xl\:focus\:tw-to-pink-800:focus {
    --gradient-to-color: #97266d;
  }

  .xl\:focus\:tw-to-pink-900:focus {
    --gradient-to-color: #702459;
  }

  .xl\:tw-bg-opacity-0 {
    --bg-opacity: 0;
  }

  .xl\:tw-bg-opacity-25 {
    --bg-opacity: 0.25;
  }

  .xl\:tw-bg-opacity-50 {
    --bg-opacity: 0.5;
  }

  .xl\:tw-bg-opacity-75 {
    --bg-opacity: 0.75;
  }

  .xl\:tw-bg-opacity-100 {
    --bg-opacity: 1;
  }

  .xl\:hover\:tw-bg-opacity-0:hover {
    --bg-opacity: 0;
  }

  .xl\:hover\:tw-bg-opacity-25:hover {
    --bg-opacity: 0.25;
  }

  .xl\:hover\:tw-bg-opacity-50:hover {
    --bg-opacity: 0.5;
  }

  .xl\:hover\:tw-bg-opacity-75:hover {
    --bg-opacity: 0.75;
  }

  .xl\:hover\:tw-bg-opacity-100:hover {
    --bg-opacity: 1;
  }

  .xl\:focus\:tw-bg-opacity-0:focus {
    --bg-opacity: 0;
  }

  .xl\:focus\:tw-bg-opacity-25:focus {
    --bg-opacity: 0.25;
  }

  .xl\:focus\:tw-bg-opacity-50:focus {
    --bg-opacity: 0.5;
  }

  .xl\:focus\:tw-bg-opacity-75:focus {
    --bg-opacity: 0.75;
  }

  .xl\:focus\:tw-bg-opacity-100:focus {
    --bg-opacity: 1;
  }

  .xl\:tw-bg-bottom {
    background-position: bottom;
  }

  .xl\:tw-bg-center {
    background-position: center;
  }

  .xl\:tw-bg-left {
    background-position: left;
  }

  .xl\:tw-bg-left-bottom {
    background-position: left bottom;
  }

  .xl\:tw-bg-left-top {
    background-position: left top;
  }

  .xl\:tw-bg-right {
    background-position: right;
  }

  .xl\:tw-bg-right-bottom {
    background-position: right bottom;
  }

  .xl\:tw-bg-right-top {
    background-position: right top;
  }

  .xl\:tw-bg-top {
    background-position: top;
  }

  .xl\:tw-bg-repeat {
    background-repeat: repeat;
  }

  .xl\:tw-bg-no-repeat {
    background-repeat: no-repeat;
  }

  .xl\:tw-bg-repeat-x {
    background-repeat: repeat-x;
  }

  .xl\:tw-bg-repeat-y {
    background-repeat: repeat-y;
  }

  .xl\:tw-bg-repeat-round {
    background-repeat: round;
  }

  .xl\:tw-bg-repeat-space {
    background-repeat: space;
  }

  .xl\:tw-bg-auto {
    background-size: auto;
  }

  .xl\:tw-bg-cover {
    background-size: cover;
  }

  .xl\:tw-bg-contain {
    background-size: contain;
  }

  .xl\:tw-border-collapse {
    border-collapse: collapse;
  }

  .xl\:tw-border-separate {
    border-collapse: separate;
  }

  .xl\:tw-border-transparent {
    border-color: transparent;
  }

  .xl\:tw-border-current {
    border-color: currentColor;
  }

  .xl\:tw-border-black {
    --border-opacity: 1;
    border-color: #000;
    border-color: rgba(0, 0, 0, var(--border-opacity));
  }

  .xl\:tw-border-white {
    --border-opacity: 1;
    border-color: #fff;
    border-color: rgba(255, 255, 255, var(--border-opacity));
  }

  .xl\:tw-border-gray-100 {
    --border-opacity: 1;
    border-color: #f7fafc;
    border-color: rgba(247, 250, 252, var(--border-opacity));
  }

  .xl\:tw-border-gray-200 {
    --border-opacity: 1;
    border-color: #edf2f7;
    border-color: rgba(237, 242, 247, var(--border-opacity));
  }

  .xl\:tw-border-gray-300 {
    --border-opacity: 1;
    border-color: #e2e8f0;
    border-color: rgba(226, 232, 240, var(--border-opacity));
  }

  .xl\:tw-border-gray-400 {
    --border-opacity: 1;
    border-color: #cbd5e0;
    border-color: rgba(203, 213, 224, var(--border-opacity));
  }

  .xl\:tw-border-gray-500 {
    --border-opacity: 1;
    border-color: #a0aec0;
    border-color: rgba(160, 174, 192, var(--border-opacity));
  }

  .xl\:tw-border-gray-600 {
    --border-opacity: 1;
    border-color: #718096;
    border-color: rgba(113, 128, 150, var(--border-opacity));
  }

  .xl\:tw-border-gray-700 {
    --border-opacity: 1;
    border-color: #4a5568;
    border-color: rgba(74, 85, 104, var(--border-opacity));
  }

  .xl\:tw-border-gray-800 {
    --border-opacity: 1;
    border-color: #2d3748;
    border-color: rgba(45, 55, 72, var(--border-opacity));
  }

  .xl\:tw-border-gray-900 {
    --border-opacity: 1;
    border-color: #1a202c;
    border-color: rgba(26, 32, 44, var(--border-opacity));
  }

  .xl\:tw-border-red-100 {
    --border-opacity: 1;
    border-color: #fff5f5;
    border-color: rgba(255, 245, 245, var(--border-opacity));
  }

  .xl\:tw-border-red-200 {
    --border-opacity: 1;
    border-color: #fed7d7;
    border-color: rgba(254, 215, 215, var(--border-opacity));
  }

  .xl\:tw-border-red-300 {
    --border-opacity: 1;
    border-color: #feb2b2;
    border-color: rgba(254, 178, 178, var(--border-opacity));
  }

  .xl\:tw-border-red-400 {
    --border-opacity: 1;
    border-color: #fc8181;
    border-color: rgba(252, 129, 129, var(--border-opacity));
  }

  .xl\:tw-border-red-500 {
    --border-opacity: 1;
    border-color: #f56565;
    border-color: rgba(245, 101, 101, var(--border-opacity));
  }

  .xl\:tw-border-red-600 {
    --border-opacity: 1;
    border-color: #e53e3e;
    border-color: rgba(229, 62, 62, var(--border-opacity));
  }

  .xl\:tw-border-red-700 {
    --border-opacity: 1;
    border-color: #c53030;
    border-color: rgba(197, 48, 48, var(--border-opacity));
  }

  .xl\:tw-border-red-800 {
    --border-opacity: 1;
    border-color: #9b2c2c;
    border-color: rgba(155, 44, 44, var(--border-opacity));
  }

  .xl\:tw-border-red-900 {
    --border-opacity: 1;
    border-color: #742a2a;
    border-color: rgba(116, 42, 42, var(--border-opacity));
  }

  .xl\:tw-border-orange-100 {
    --border-opacity: 1;
    border-color: #fffaf0;
    border-color: rgba(255, 250, 240, var(--border-opacity));
  }

  .xl\:tw-border-orange-200 {
    --border-opacity: 1;
    border-color: #feebc8;
    border-color: rgba(254, 235, 200, var(--border-opacity));
  }

  .xl\:tw-border-orange-300 {
    --border-opacity: 1;
    border-color: #fbd38d;
    border-color: rgba(251, 211, 141, var(--border-opacity));
  }

  .xl\:tw-border-orange-400 {
    --border-opacity: 1;
    border-color: #f6ad55;
    border-color: rgba(246, 173, 85, var(--border-opacity));
  }

  .xl\:tw-border-orange-500 {
    --border-opacity: 1;
    border-color: #ed8936;
    border-color: rgba(237, 137, 54, var(--border-opacity));
  }

  .xl\:tw-border-orange-600 {
    --border-opacity: 1;
    border-color: #dd6b20;
    border-color: rgba(221, 107, 32, var(--border-opacity));
  }

  .xl\:tw-border-orange-700 {
    --border-opacity: 1;
    border-color: #c05621;
    border-color: rgba(192, 86, 33, var(--border-opacity));
  }

  .xl\:tw-border-orange-800 {
    --border-opacity: 1;
    border-color: #9c4221;
    border-color: rgba(156, 66, 33, var(--border-opacity));
  }

  .xl\:tw-border-orange-900 {
    --border-opacity: 1;
    border-color: #7b341e;
    border-color: rgba(123, 52, 30, var(--border-opacity));
  }

  .xl\:tw-border-yellow-100 {
    --border-opacity: 1;
    border-color: #fffff0;
    border-color: rgba(255, 255, 240, var(--border-opacity));
  }

  .xl\:tw-border-yellow-200 {
    --border-opacity: 1;
    border-color: #fefcbf;
    border-color: rgba(254, 252, 191, var(--border-opacity));
  }

  .xl\:tw-border-yellow-300 {
    --border-opacity: 1;
    border-color: #faf089;
    border-color: rgba(250, 240, 137, var(--border-opacity));
  }

  .xl\:tw-border-yellow-400 {
    --border-opacity: 1;
    border-color: #f6e05e;
    border-color: rgba(246, 224, 94, var(--border-opacity));
  }

  .xl\:tw-border-yellow-500 {
    --border-opacity: 1;
    border-color: #ecc94b;
    border-color: rgba(236, 201, 75, var(--border-opacity));
  }

  .xl\:tw-border-yellow-600 {
    --border-opacity: 1;
    border-color: #d69e2e;
    border-color: rgba(214, 158, 46, var(--border-opacity));
  }

  .xl\:tw-border-yellow-700 {
    --border-opacity: 1;
    border-color: #b7791f;
    border-color: rgba(183, 121, 31, var(--border-opacity));
  }

  .xl\:tw-border-yellow-800 {
    --border-opacity: 1;
    border-color: #975a16;
    border-color: rgba(151, 90, 22, var(--border-opacity));
  }

  .xl\:tw-border-yellow-900 {
    --border-opacity: 1;
    border-color: #744210;
    border-color: rgba(116, 66, 16, var(--border-opacity));
  }

  .xl\:tw-border-green-100 {
    --border-opacity: 1;
    border-color: #f0fff4;
    border-color: rgba(240, 255, 244, var(--border-opacity));
  }

  .xl\:tw-border-green-200 {
    --border-opacity: 1;
    border-color: #c6f6d5;
    border-color: rgba(198, 246, 213, var(--border-opacity));
  }

  .xl\:tw-border-green-300 {
    --border-opacity: 1;
    border-color: #9ae6b4;
    border-color: rgba(154, 230, 180, var(--border-opacity));
  }

  .xl\:tw-border-green-400 {
    --border-opacity: 1;
    border-color: #68d391;
    border-color: rgba(104, 211, 145, var(--border-opacity));
  }

  .xl\:tw-border-green-500 {
    --border-opacity: 1;
    border-color: #48bb78;
    border-color: rgba(72, 187, 120, var(--border-opacity));
  }

  .xl\:tw-border-green-600 {
    --border-opacity: 1;
    border-color: #38a169;
    border-color: rgba(56, 161, 105, var(--border-opacity));
  }

  .xl\:tw-border-green-700 {
    --border-opacity: 1;
    border-color: #2f855a;
    border-color: rgba(47, 133, 90, var(--border-opacity));
  }

  .xl\:tw-border-green-800 {
    --border-opacity: 1;
    border-color: #276749;
    border-color: rgba(39, 103, 73, var(--border-opacity));
  }

  .xl\:tw-border-green-900 {
    --border-opacity: 1;
    border-color: #22543d;
    border-color: rgba(34, 84, 61, var(--border-opacity));
  }

  .xl\:tw-border-teal-100 {
    --border-opacity: 1;
    border-color: #e6fffa;
    border-color: rgba(230, 255, 250, var(--border-opacity));
  }

  .xl\:tw-border-teal-200 {
    --border-opacity: 1;
    border-color: #b2f5ea;
    border-color: rgba(178, 245, 234, var(--border-opacity));
  }

  .xl\:tw-border-teal-300 {
    --border-opacity: 1;
    border-color: #81e6d9;
    border-color: rgba(129, 230, 217, var(--border-opacity));
  }

  .xl\:tw-border-teal-400 {
    --border-opacity: 1;
    border-color: #4fd1c5;
    border-color: rgba(79, 209, 197, var(--border-opacity));
  }

  .xl\:tw-border-teal-500 {
    --border-opacity: 1;
    border-color: #38b2ac;
    border-color: rgba(56, 178, 172, var(--border-opacity));
  }

  .xl\:tw-border-teal-600 {
    --border-opacity: 1;
    border-color: #319795;
    border-color: rgba(49, 151, 149, var(--border-opacity));
  }

  .xl\:tw-border-teal-700 {
    --border-opacity: 1;
    border-color: #2c7a7b;
    border-color: rgba(44, 122, 123, var(--border-opacity));
  }

  .xl\:tw-border-teal-800 {
    --border-opacity: 1;
    border-color: #285e61;
    border-color: rgba(40, 94, 97, var(--border-opacity));
  }

  .xl\:tw-border-teal-900 {
    --border-opacity: 1;
    border-color: #234e52;
    border-color: rgba(35, 78, 82, var(--border-opacity));
  }

  .xl\:tw-border-blue-100 {
    --border-opacity: 1;
    border-color: #ebf8ff;
    border-color: rgba(235, 248, 255, var(--border-opacity));
  }

  .xl\:tw-border-blue-200 {
    --border-opacity: 1;
    border-color: #bee3f8;
    border-color: rgba(190, 227, 248, var(--border-opacity));
  }

  .xl\:tw-border-blue-300 {
    --border-opacity: 1;
    border-color: #90cdf4;
    border-color: rgba(144, 205, 244, var(--border-opacity));
  }

  .xl\:tw-border-blue-400 {
    --border-opacity: 1;
    border-color: #63b3ed;
    border-color: rgba(99, 179, 237, var(--border-opacity));
  }

  .xl\:tw-border-blue-500 {
    --border-opacity: 1;
    border-color: #4299e1;
    border-color: rgba(66, 153, 225, var(--border-opacity));
  }

  .xl\:tw-border-blue-600 {
    --border-opacity: 1;
    border-color: #3182ce;
    border-color: rgba(49, 130, 206, var(--border-opacity));
  }

  .xl\:tw-border-blue-700 {
    --border-opacity: 1;
    border-color: #2b6cb0;
    border-color: rgba(43, 108, 176, var(--border-opacity));
  }

  .xl\:tw-border-blue-800 {
    --border-opacity: 1;
    border-color: #2c5282;
    border-color: rgba(44, 82, 130, var(--border-opacity));
  }

  .xl\:tw-border-blue-900 {
    --border-opacity: 1;
    border-color: #2a4365;
    border-color: rgba(42, 67, 101, var(--border-opacity));
  }

  .xl\:tw-border-indigo-100 {
    --border-opacity: 1;
    border-color: #ebf4ff;
    border-color: rgba(235, 244, 255, var(--border-opacity));
  }

  .xl\:tw-border-indigo-200 {
    --border-opacity: 1;
    border-color: #c3dafe;
    border-color: rgba(195, 218, 254, var(--border-opacity));
  }

  .xl\:tw-border-indigo-300 {
    --border-opacity: 1;
    border-color: #a3bffa;
    border-color: rgba(163, 191, 250, var(--border-opacity));
  }

  .xl\:tw-border-indigo-400 {
    --border-opacity: 1;
    border-color: #7f9cf5;
    border-color: rgba(127, 156, 245, var(--border-opacity));
  }

  .xl\:tw-border-indigo-500 {
    --border-opacity: 1;
    border-color: #667eea;
    border-color: rgba(102, 126, 234, var(--border-opacity));
  }

  .xl\:tw-border-indigo-600 {
    --border-opacity: 1;
    border-color: #5a67d8;
    border-color: rgba(90, 103, 216, var(--border-opacity));
  }

  .xl\:tw-border-indigo-700 {
    --border-opacity: 1;
    border-color: #4c51bf;
    border-color: rgba(76, 81, 191, var(--border-opacity));
  }

  .xl\:tw-border-indigo-800 {
    --border-opacity: 1;
    border-color: #434190;
    border-color: rgba(67, 65, 144, var(--border-opacity));
  }

  .xl\:tw-border-indigo-900 {
    --border-opacity: 1;
    border-color: #3c366b;
    border-color: rgba(60, 54, 107, var(--border-opacity));
  }

  .xl\:tw-border-purple-100 {
    --border-opacity: 1;
    border-color: #faf5ff;
    border-color: rgba(250, 245, 255, var(--border-opacity));
  }

  .xl\:tw-border-purple-200 {
    --border-opacity: 1;
    border-color: #e9d8fd;
    border-color: rgba(233, 216, 253, var(--border-opacity));
  }

  .xl\:tw-border-purple-300 {
    --border-opacity: 1;
    border-color: #d6bcfa;
    border-color: rgba(214, 188, 250, var(--border-opacity));
  }

  .xl\:tw-border-purple-400 {
    --border-opacity: 1;
    border-color: #b794f4;
    border-color: rgba(183, 148, 244, var(--border-opacity));
  }

  .xl\:tw-border-purple-500 {
    --border-opacity: 1;
    border-color: #9f7aea;
    border-color: rgba(159, 122, 234, var(--border-opacity));
  }

  .xl\:tw-border-purple-600 {
    --border-opacity: 1;
    border-color: #805ad5;
    border-color: rgba(128, 90, 213, var(--border-opacity));
  }

  .xl\:tw-border-purple-700 {
    --border-opacity: 1;
    border-color: #6b46c1;
    border-color: rgba(107, 70, 193, var(--border-opacity));
  }

  .xl\:tw-border-purple-800 {
    --border-opacity: 1;
    border-color: #553c9a;
    border-color: rgba(85, 60, 154, var(--border-opacity));
  }

  .xl\:tw-border-purple-900 {
    --border-opacity: 1;
    border-color: #44337a;
    border-color: rgba(68, 51, 122, var(--border-opacity));
  }

  .xl\:tw-border-pink-100 {
    --border-opacity: 1;
    border-color: #fff5f7;
    border-color: rgba(255, 245, 247, var(--border-opacity));
  }

  .xl\:tw-border-pink-200 {
    --border-opacity: 1;
    border-color: #fed7e2;
    border-color: rgba(254, 215, 226, var(--border-opacity));
  }

  .xl\:tw-border-pink-300 {
    --border-opacity: 1;
    border-color: #fbb6ce;
    border-color: rgba(251, 182, 206, var(--border-opacity));
  }

  .xl\:tw-border-pink-400 {
    --border-opacity: 1;
    border-color: #f687b3;
    border-color: rgba(246, 135, 179, var(--border-opacity));
  }

  .xl\:tw-border-pink-500 {
    --border-opacity: 1;
    border-color: #ed64a6;
    border-color: rgba(237, 100, 166, var(--border-opacity));
  }

  .xl\:tw-border-pink-600 {
    --border-opacity: 1;
    border-color: #d53f8c;
    border-color: rgba(213, 63, 140, var(--border-opacity));
  }

  .xl\:tw-border-pink-700 {
    --border-opacity: 1;
    border-color: #b83280;
    border-color: rgba(184, 50, 128, var(--border-opacity));
  }

  .xl\:tw-border-pink-800 {
    --border-opacity: 1;
    border-color: #97266d;
    border-color: rgba(151, 38, 109, var(--border-opacity));
  }

  .xl\:tw-border-pink-900 {
    --border-opacity: 1;
    border-color: #702459;
    border-color: rgba(112, 36, 89, var(--border-opacity));
  }

  .xl\:hover\:tw-border-transparent:hover {
    border-color: transparent;
  }

  .xl\:hover\:tw-border-current:hover {
    border-color: currentColor;
  }

  .xl\:hover\:tw-border-black:hover {
    --border-opacity: 1;
    border-color: #000;
    border-color: rgba(0, 0, 0, var(--border-opacity));
  }

  .xl\:hover\:tw-border-white:hover {
    --border-opacity: 1;
    border-color: #fff;
    border-color: rgba(255, 255, 255, var(--border-opacity));
  }

  .xl\:hover\:tw-border-gray-100:hover {
    --border-opacity: 1;
    border-color: #f7fafc;
    border-color: rgba(247, 250, 252, var(--border-opacity));
  }

  .xl\:hover\:tw-border-gray-200:hover {
    --border-opacity: 1;
    border-color: #edf2f7;
    border-color: rgba(237, 242, 247, var(--border-opacity));
  }

  .xl\:hover\:tw-border-gray-300:hover {
    --border-opacity: 1;
    border-color: #e2e8f0;
    border-color: rgba(226, 232, 240, var(--border-opacity));
  }

  .xl\:hover\:tw-border-gray-400:hover {
    --border-opacity: 1;
    border-color: #cbd5e0;
    border-color: rgba(203, 213, 224, var(--border-opacity));
  }

  .xl\:hover\:tw-border-gray-500:hover {
    --border-opacity: 1;
    border-color: #a0aec0;
    border-color: rgba(160, 174, 192, var(--border-opacity));
  }

  .xl\:hover\:tw-border-gray-600:hover {
    --border-opacity: 1;
    border-color: #718096;
    border-color: rgba(113, 128, 150, var(--border-opacity));
  }

  .xl\:hover\:tw-border-gray-700:hover {
    --border-opacity: 1;
    border-color: #4a5568;
    border-color: rgba(74, 85, 104, var(--border-opacity));
  }

  .xl\:hover\:tw-border-gray-800:hover {
    --border-opacity: 1;
    border-color: #2d3748;
    border-color: rgba(45, 55, 72, var(--border-opacity));
  }

  .xl\:hover\:tw-border-gray-900:hover {
    --border-opacity: 1;
    border-color: #1a202c;
    border-color: rgba(26, 32, 44, var(--border-opacity));
  }

  .xl\:hover\:tw-border-red-100:hover {
    --border-opacity: 1;
    border-color: #fff5f5;
    border-color: rgba(255, 245, 245, var(--border-opacity));
  }

  .xl\:hover\:tw-border-red-200:hover {
    --border-opacity: 1;
    border-color: #fed7d7;
    border-color: rgba(254, 215, 215, var(--border-opacity));
  }

  .xl\:hover\:tw-border-red-300:hover {
    --border-opacity: 1;
    border-color: #feb2b2;
    border-color: rgba(254, 178, 178, var(--border-opacity));
  }

  .xl\:hover\:tw-border-red-400:hover {
    --border-opacity: 1;
    border-color: #fc8181;
    border-color: rgba(252, 129, 129, var(--border-opacity));
  }

  .xl\:hover\:tw-border-red-500:hover {
    --border-opacity: 1;
    border-color: #f56565;
    border-color: rgba(245, 101, 101, var(--border-opacity));
  }

  .xl\:hover\:tw-border-red-600:hover {
    --border-opacity: 1;
    border-color: #e53e3e;
    border-color: rgba(229, 62, 62, var(--border-opacity));
  }

  .xl\:hover\:tw-border-red-700:hover {
    --border-opacity: 1;
    border-color: #c53030;
    border-color: rgba(197, 48, 48, var(--border-opacity));
  }

  .xl\:hover\:tw-border-red-800:hover {
    --border-opacity: 1;
    border-color: #9b2c2c;
    border-color: rgba(155, 44, 44, var(--border-opacity));
  }

  .xl\:hover\:tw-border-red-900:hover {
    --border-opacity: 1;
    border-color: #742a2a;
    border-color: rgba(116, 42, 42, var(--border-opacity));
  }

  .xl\:hover\:tw-border-orange-100:hover {
    --border-opacity: 1;
    border-color: #fffaf0;
    border-color: rgba(255, 250, 240, var(--border-opacity));
  }

  .xl\:hover\:tw-border-orange-200:hover {
    --border-opacity: 1;
    border-color: #feebc8;
    border-color: rgba(254, 235, 200, var(--border-opacity));
  }

  .xl\:hover\:tw-border-orange-300:hover {
    --border-opacity: 1;
    border-color: #fbd38d;
    border-color: rgba(251, 211, 141, var(--border-opacity));
  }

  .xl\:hover\:tw-border-orange-400:hover {
    --border-opacity: 1;
    border-color: #f6ad55;
    border-color: rgba(246, 173, 85, var(--border-opacity));
  }

  .xl\:hover\:tw-border-orange-500:hover {
    --border-opacity: 1;
    border-color: #ed8936;
    border-color: rgba(237, 137, 54, var(--border-opacity));
  }

  .xl\:hover\:tw-border-orange-600:hover {
    --border-opacity: 1;
    border-color: #dd6b20;
    border-color: rgba(221, 107, 32, var(--border-opacity));
  }

  .xl\:hover\:tw-border-orange-700:hover {
    --border-opacity: 1;
    border-color: #c05621;
    border-color: rgba(192, 86, 33, var(--border-opacity));
  }

  .xl\:hover\:tw-border-orange-800:hover {
    --border-opacity: 1;
    border-color: #9c4221;
    border-color: rgba(156, 66, 33, var(--border-opacity));
  }

  .xl\:hover\:tw-border-orange-900:hover {
    --border-opacity: 1;
    border-color: #7b341e;
    border-color: rgba(123, 52, 30, var(--border-opacity));
  }

  .xl\:hover\:tw-border-yellow-100:hover {
    --border-opacity: 1;
    border-color: #fffff0;
    border-color: rgba(255, 255, 240, var(--border-opacity));
  }

  .xl\:hover\:tw-border-yellow-200:hover {
    --border-opacity: 1;
    border-color: #fefcbf;
    border-color: rgba(254, 252, 191, var(--border-opacity));
  }

  .xl\:hover\:tw-border-yellow-300:hover {
    --border-opacity: 1;
    border-color: #faf089;
    border-color: rgba(250, 240, 137, var(--border-opacity));
  }

  .xl\:hover\:tw-border-yellow-400:hover {
    --border-opacity: 1;
    border-color: #f6e05e;
    border-color: rgba(246, 224, 94, var(--border-opacity));
  }

  .xl\:hover\:tw-border-yellow-500:hover {
    --border-opacity: 1;
    border-color: #ecc94b;
    border-color: rgba(236, 201, 75, var(--border-opacity));
  }

  .xl\:hover\:tw-border-yellow-600:hover {
    --border-opacity: 1;
    border-color: #d69e2e;
    border-color: rgba(214, 158, 46, var(--border-opacity));
  }

  .xl\:hover\:tw-border-yellow-700:hover {
    --border-opacity: 1;
    border-color: #b7791f;
    border-color: rgba(183, 121, 31, var(--border-opacity));
  }

  .xl\:hover\:tw-border-yellow-800:hover {
    --border-opacity: 1;
    border-color: #975a16;
    border-color: rgba(151, 90, 22, var(--border-opacity));
  }

  .xl\:hover\:tw-border-yellow-900:hover {
    --border-opacity: 1;
    border-color: #744210;
    border-color: rgba(116, 66, 16, var(--border-opacity));
  }

  .xl\:hover\:tw-border-green-100:hover {
    --border-opacity: 1;
    border-color: #f0fff4;
    border-color: rgba(240, 255, 244, var(--border-opacity));
  }

  .xl\:hover\:tw-border-green-200:hover {
    --border-opacity: 1;
    border-color: #c6f6d5;
    border-color: rgba(198, 246, 213, var(--border-opacity));
  }

  .xl\:hover\:tw-border-green-300:hover {
    --border-opacity: 1;
    border-color: #9ae6b4;
    border-color: rgba(154, 230, 180, var(--border-opacity));
  }

  .xl\:hover\:tw-border-green-400:hover {
    --border-opacity: 1;
    border-color: #68d391;
    border-color: rgba(104, 211, 145, var(--border-opacity));
  }

  .xl\:hover\:tw-border-green-500:hover {
    --border-opacity: 1;
    border-color: #48bb78;
    border-color: rgba(72, 187, 120, var(--border-opacity));
  }

  .xl\:hover\:tw-border-green-600:hover {
    --border-opacity: 1;
    border-color: #38a169;
    border-color: rgba(56, 161, 105, var(--border-opacity));
  }

  .xl\:hover\:tw-border-green-700:hover {
    --border-opacity: 1;
    border-color: #2f855a;
    border-color: rgba(47, 133, 90, var(--border-opacity));
  }

  .xl\:hover\:tw-border-green-800:hover {
    --border-opacity: 1;
    border-color: #276749;
    border-color: rgba(39, 103, 73, var(--border-opacity));
  }

  .xl\:hover\:tw-border-green-900:hover {
    --border-opacity: 1;
    border-color: #22543d;
    border-color: rgba(34, 84, 61, var(--border-opacity));
  }

  .xl\:hover\:tw-border-teal-100:hover {
    --border-opacity: 1;
    border-color: #e6fffa;
    border-color: rgba(230, 255, 250, var(--border-opacity));
  }

  .xl\:hover\:tw-border-teal-200:hover {
    --border-opacity: 1;
    border-color: #b2f5ea;
    border-color: rgba(178, 245, 234, var(--border-opacity));
  }

  .xl\:hover\:tw-border-teal-300:hover {
    --border-opacity: 1;
    border-color: #81e6d9;
    border-color: rgba(129, 230, 217, var(--border-opacity));
  }

  .xl\:hover\:tw-border-teal-400:hover {
    --border-opacity: 1;
    border-color: #4fd1c5;
    border-color: rgba(79, 209, 197, var(--border-opacity));
  }

  .xl\:hover\:tw-border-teal-500:hover {
    --border-opacity: 1;
    border-color: #38b2ac;
    border-color: rgba(56, 178, 172, var(--border-opacity));
  }

  .xl\:hover\:tw-border-teal-600:hover {
    --border-opacity: 1;
    border-color: #319795;
    border-color: rgba(49, 151, 149, var(--border-opacity));
  }

  .xl\:hover\:tw-border-teal-700:hover {
    --border-opacity: 1;
    border-color: #2c7a7b;
    border-color: rgba(44, 122, 123, var(--border-opacity));
  }

  .xl\:hover\:tw-border-teal-800:hover {
    --border-opacity: 1;
    border-color: #285e61;
    border-color: rgba(40, 94, 97, var(--border-opacity));
  }

  .xl\:hover\:tw-border-teal-900:hover {
    --border-opacity: 1;
    border-color: #234e52;
    border-color: rgba(35, 78, 82, var(--border-opacity));
  }

  .xl\:hover\:tw-border-blue-100:hover {
    --border-opacity: 1;
    border-color: #ebf8ff;
    border-color: rgba(235, 248, 255, var(--border-opacity));
  }

  .xl\:hover\:tw-border-blue-200:hover {
    --border-opacity: 1;
    border-color: #bee3f8;
    border-color: rgba(190, 227, 248, var(--border-opacity));
  }

  .xl\:hover\:tw-border-blue-300:hover {
    --border-opacity: 1;
    border-color: #90cdf4;
    border-color: rgba(144, 205, 244, var(--border-opacity));
  }

  .xl\:hover\:tw-border-blue-400:hover {
    --border-opacity: 1;
    border-color: #63b3ed;
    border-color: rgba(99, 179, 237, var(--border-opacity));
  }

  .xl\:hover\:tw-border-blue-500:hover {
    --border-opacity: 1;
    border-color: #4299e1;
    border-color: rgba(66, 153, 225, var(--border-opacity));
  }

  .xl\:hover\:tw-border-blue-600:hover {
    --border-opacity: 1;
    border-color: #3182ce;
    border-color: rgba(49, 130, 206, var(--border-opacity));
  }

  .xl\:hover\:tw-border-blue-700:hover {
    --border-opacity: 1;
    border-color: #2b6cb0;
    border-color: rgba(43, 108, 176, var(--border-opacity));
  }

  .xl\:hover\:tw-border-blue-800:hover {
    --border-opacity: 1;
    border-color: #2c5282;
    border-color: rgba(44, 82, 130, var(--border-opacity));
  }

  .xl\:hover\:tw-border-blue-900:hover {
    --border-opacity: 1;
    border-color: #2a4365;
    border-color: rgba(42, 67, 101, var(--border-opacity));
  }

  .xl\:hover\:tw-border-indigo-100:hover {
    --border-opacity: 1;
    border-color: #ebf4ff;
    border-color: rgba(235, 244, 255, var(--border-opacity));
  }

  .xl\:hover\:tw-border-indigo-200:hover {
    --border-opacity: 1;
    border-color: #c3dafe;
    border-color: rgba(195, 218, 254, var(--border-opacity));
  }

  .xl\:hover\:tw-border-indigo-300:hover {
    --border-opacity: 1;
    border-color: #a3bffa;
    border-color: rgba(163, 191, 250, var(--border-opacity));
  }

  .xl\:hover\:tw-border-indigo-400:hover {
    --border-opacity: 1;
    border-color: #7f9cf5;
    border-color: rgba(127, 156, 245, var(--border-opacity));
  }

  .xl\:hover\:tw-border-indigo-500:hover {
    --border-opacity: 1;
    border-color: #667eea;
    border-color: rgba(102, 126, 234, var(--border-opacity));
  }

  .xl\:hover\:tw-border-indigo-600:hover {
    --border-opacity: 1;
    border-color: #5a67d8;
    border-color: rgba(90, 103, 216, var(--border-opacity));
  }

  .xl\:hover\:tw-border-indigo-700:hover {
    --border-opacity: 1;
    border-color: #4c51bf;
    border-color: rgba(76, 81, 191, var(--border-opacity));
  }

  .xl\:hover\:tw-border-indigo-800:hover {
    --border-opacity: 1;
    border-color: #434190;
    border-color: rgba(67, 65, 144, var(--border-opacity));
  }

  .xl\:hover\:tw-border-indigo-900:hover {
    --border-opacity: 1;
    border-color: #3c366b;
    border-color: rgba(60, 54, 107, var(--border-opacity));
  }

  .xl\:hover\:tw-border-purple-100:hover {
    --border-opacity: 1;
    border-color: #faf5ff;
    border-color: rgba(250, 245, 255, var(--border-opacity));
  }

  .xl\:hover\:tw-border-purple-200:hover {
    --border-opacity: 1;
    border-color: #e9d8fd;
    border-color: rgba(233, 216, 253, var(--border-opacity));
  }

  .xl\:hover\:tw-border-purple-300:hover {
    --border-opacity: 1;
    border-color: #d6bcfa;
    border-color: rgba(214, 188, 250, var(--border-opacity));
  }

  .xl\:hover\:tw-border-purple-400:hover {
    --border-opacity: 1;
    border-color: #b794f4;
    border-color: rgba(183, 148, 244, var(--border-opacity));
  }

  .xl\:hover\:tw-border-purple-500:hover {
    --border-opacity: 1;
    border-color: #9f7aea;
    border-color: rgba(159, 122, 234, var(--border-opacity));
  }

  .xl\:hover\:tw-border-purple-600:hover {
    --border-opacity: 1;
    border-color: #805ad5;
    border-color: rgba(128, 90, 213, var(--border-opacity));
  }

  .xl\:hover\:tw-border-purple-700:hover {
    --border-opacity: 1;
    border-color: #6b46c1;
    border-color: rgba(107, 70, 193, var(--border-opacity));
  }

  .xl\:hover\:tw-border-purple-800:hover {
    --border-opacity: 1;
    border-color: #553c9a;
    border-color: rgba(85, 60, 154, var(--border-opacity));
  }

  .xl\:hover\:tw-border-purple-900:hover {
    --border-opacity: 1;
    border-color: #44337a;
    border-color: rgba(68, 51, 122, var(--border-opacity));
  }

  .xl\:hover\:tw-border-pink-100:hover {
    --border-opacity: 1;
    border-color: #fff5f7;
    border-color: rgba(255, 245, 247, var(--border-opacity));
  }

  .xl\:hover\:tw-border-pink-200:hover {
    --border-opacity: 1;
    border-color: #fed7e2;
    border-color: rgba(254, 215, 226, var(--border-opacity));
  }

  .xl\:hover\:tw-border-pink-300:hover {
    --border-opacity: 1;
    border-color: #fbb6ce;
    border-color: rgba(251, 182, 206, var(--border-opacity));
  }

  .xl\:hover\:tw-border-pink-400:hover {
    --border-opacity: 1;
    border-color: #f687b3;
    border-color: rgba(246, 135, 179, var(--border-opacity));
  }

  .xl\:hover\:tw-border-pink-500:hover {
    --border-opacity: 1;
    border-color: #ed64a6;
    border-color: rgba(237, 100, 166, var(--border-opacity));
  }

  .xl\:hover\:tw-border-pink-600:hover {
    --border-opacity: 1;
    border-color: #d53f8c;
    border-color: rgba(213, 63, 140, var(--border-opacity));
  }

  .xl\:hover\:tw-border-pink-700:hover {
    --border-opacity: 1;
    border-color: #b83280;
    border-color: rgba(184, 50, 128, var(--border-opacity));
  }

  .xl\:hover\:tw-border-pink-800:hover {
    --border-opacity: 1;
    border-color: #97266d;
    border-color: rgba(151, 38, 109, var(--border-opacity));
  }

  .xl\:hover\:tw-border-pink-900:hover {
    --border-opacity: 1;
    border-color: #702459;
    border-color: rgba(112, 36, 89, var(--border-opacity));
  }

  .xl\:focus\:tw-border-transparent:focus {
    border-color: transparent;
  }

  .xl\:focus\:tw-border-current:focus {
    border-color: currentColor;
  }

  .xl\:focus\:tw-border-black:focus {
    --border-opacity: 1;
    border-color: #000;
    border-color: rgba(0, 0, 0, var(--border-opacity));
  }

  .xl\:focus\:tw-border-white:focus {
    --border-opacity: 1;
    border-color: #fff;
    border-color: rgba(255, 255, 255, var(--border-opacity));
  }

  .xl\:focus\:tw-border-gray-100:focus {
    --border-opacity: 1;
    border-color: #f7fafc;
    border-color: rgba(247, 250, 252, var(--border-opacity));
  }

  .xl\:focus\:tw-border-gray-200:focus {
    --border-opacity: 1;
    border-color: #edf2f7;
    border-color: rgba(237, 242, 247, var(--border-opacity));
  }

  .xl\:focus\:tw-border-gray-300:focus {
    --border-opacity: 1;
    border-color: #e2e8f0;
    border-color: rgba(226, 232, 240, var(--border-opacity));
  }

  .xl\:focus\:tw-border-gray-400:focus {
    --border-opacity: 1;
    border-color: #cbd5e0;
    border-color: rgba(203, 213, 224, var(--border-opacity));
  }

  .xl\:focus\:tw-border-gray-500:focus {
    --border-opacity: 1;
    border-color: #a0aec0;
    border-color: rgba(160, 174, 192, var(--border-opacity));
  }

  .xl\:focus\:tw-border-gray-600:focus {
    --border-opacity: 1;
    border-color: #718096;
    border-color: rgba(113, 128, 150, var(--border-opacity));
  }

  .xl\:focus\:tw-border-gray-700:focus {
    --border-opacity: 1;
    border-color: #4a5568;
    border-color: rgba(74, 85, 104, var(--border-opacity));
  }

  .xl\:focus\:tw-border-gray-800:focus {
    --border-opacity: 1;
    border-color: #2d3748;
    border-color: rgba(45, 55, 72, var(--border-opacity));
  }

  .xl\:focus\:tw-border-gray-900:focus {
    --border-opacity: 1;
    border-color: #1a202c;
    border-color: rgba(26, 32, 44, var(--border-opacity));
  }

  .xl\:focus\:tw-border-red-100:focus {
    --border-opacity: 1;
    border-color: #fff5f5;
    border-color: rgba(255, 245, 245, var(--border-opacity));
  }

  .xl\:focus\:tw-border-red-200:focus {
    --border-opacity: 1;
    border-color: #fed7d7;
    border-color: rgba(254, 215, 215, var(--border-opacity));
  }

  .xl\:focus\:tw-border-red-300:focus {
    --border-opacity: 1;
    border-color: #feb2b2;
    border-color: rgba(254, 178, 178, var(--border-opacity));
  }

  .xl\:focus\:tw-border-red-400:focus {
    --border-opacity: 1;
    border-color: #fc8181;
    border-color: rgba(252, 129, 129, var(--border-opacity));
  }

  .xl\:focus\:tw-border-red-500:focus {
    --border-opacity: 1;
    border-color: #f56565;
    border-color: rgba(245, 101, 101, var(--border-opacity));
  }

  .xl\:focus\:tw-border-red-600:focus {
    --border-opacity: 1;
    border-color: #e53e3e;
    border-color: rgba(229, 62, 62, var(--border-opacity));
  }

  .xl\:focus\:tw-border-red-700:focus {
    --border-opacity: 1;
    border-color: #c53030;
    border-color: rgba(197, 48, 48, var(--border-opacity));
  }

  .xl\:focus\:tw-border-red-800:focus {
    --border-opacity: 1;
    border-color: #9b2c2c;
    border-color: rgba(155, 44, 44, var(--border-opacity));
  }

  .xl\:focus\:tw-border-red-900:focus {
    --border-opacity: 1;
    border-color: #742a2a;
    border-color: rgba(116, 42, 42, var(--border-opacity));
  }

  .xl\:focus\:tw-border-orange-100:focus {
    --border-opacity: 1;
    border-color: #fffaf0;
    border-color: rgba(255, 250, 240, var(--border-opacity));
  }

  .xl\:focus\:tw-border-orange-200:focus {
    --border-opacity: 1;
    border-color: #feebc8;
    border-color: rgba(254, 235, 200, var(--border-opacity));
  }

  .xl\:focus\:tw-border-orange-300:focus {
    --border-opacity: 1;
    border-color: #fbd38d;
    border-color: rgba(251, 211, 141, var(--border-opacity));
  }

  .xl\:focus\:tw-border-orange-400:focus {
    --border-opacity: 1;
    border-color: #f6ad55;
    border-color: rgba(246, 173, 85, var(--border-opacity));
  }

  .xl\:focus\:tw-border-orange-500:focus {
    --border-opacity: 1;
    border-color: #ed8936;
    border-color: rgba(237, 137, 54, var(--border-opacity));
  }

  .xl\:focus\:tw-border-orange-600:focus {
    --border-opacity: 1;
    border-color: #dd6b20;
    border-color: rgba(221, 107, 32, var(--border-opacity));
  }

  .xl\:focus\:tw-border-orange-700:focus {
    --border-opacity: 1;
    border-color: #c05621;
    border-color: rgba(192, 86, 33, var(--border-opacity));
  }

  .xl\:focus\:tw-border-orange-800:focus {
    --border-opacity: 1;
    border-color: #9c4221;
    border-color: rgba(156, 66, 33, var(--border-opacity));
  }

  .xl\:focus\:tw-border-orange-900:focus {
    --border-opacity: 1;
    border-color: #7b341e;
    border-color: rgba(123, 52, 30, var(--border-opacity));
  }

  .xl\:focus\:tw-border-yellow-100:focus {
    --border-opacity: 1;
    border-color: #fffff0;
    border-color: rgba(255, 255, 240, var(--border-opacity));
  }

  .xl\:focus\:tw-border-yellow-200:focus {
    --border-opacity: 1;
    border-color: #fefcbf;
    border-color: rgba(254, 252, 191, var(--border-opacity));
  }

  .xl\:focus\:tw-border-yellow-300:focus {
    --border-opacity: 1;
    border-color: #faf089;
    border-color: rgba(250, 240, 137, var(--border-opacity));
  }

  .xl\:focus\:tw-border-yellow-400:focus {
    --border-opacity: 1;
    border-color: #f6e05e;
    border-color: rgba(246, 224, 94, var(--border-opacity));
  }

  .xl\:focus\:tw-border-yellow-500:focus {
    --border-opacity: 1;
    border-color: #ecc94b;
    border-color: rgba(236, 201, 75, var(--border-opacity));
  }

  .xl\:focus\:tw-border-yellow-600:focus {
    --border-opacity: 1;
    border-color: #d69e2e;
    border-color: rgba(214, 158, 46, var(--border-opacity));
  }

  .xl\:focus\:tw-border-yellow-700:focus {
    --border-opacity: 1;
    border-color: #b7791f;
    border-color: rgba(183, 121, 31, var(--border-opacity));
  }

  .xl\:focus\:tw-border-yellow-800:focus {
    --border-opacity: 1;
    border-color: #975a16;
    border-color: rgba(151, 90, 22, var(--border-opacity));
  }

  .xl\:focus\:tw-border-yellow-900:focus {
    --border-opacity: 1;
    border-color: #744210;
    border-color: rgba(116, 66, 16, var(--border-opacity));
  }

  .xl\:focus\:tw-border-green-100:focus {
    --border-opacity: 1;
    border-color: #f0fff4;
    border-color: rgba(240, 255, 244, var(--border-opacity));
  }

  .xl\:focus\:tw-border-green-200:focus {
    --border-opacity: 1;
    border-color: #c6f6d5;
    border-color: rgba(198, 246, 213, var(--border-opacity));
  }

  .xl\:focus\:tw-border-green-300:focus {
    --border-opacity: 1;
    border-color: #9ae6b4;
    border-color: rgba(154, 230, 180, var(--border-opacity));
  }

  .xl\:focus\:tw-border-green-400:focus {
    --border-opacity: 1;
    border-color: #68d391;
    border-color: rgba(104, 211, 145, var(--border-opacity));
  }

  .xl\:focus\:tw-border-green-500:focus {
    --border-opacity: 1;
    border-color: #48bb78;
    border-color: rgba(72, 187, 120, var(--border-opacity));
  }

  .xl\:focus\:tw-border-green-600:focus {
    --border-opacity: 1;
    border-color: #38a169;
    border-color: rgba(56, 161, 105, var(--border-opacity));
  }

  .xl\:focus\:tw-border-green-700:focus {
    --border-opacity: 1;
    border-color: #2f855a;
    border-color: rgba(47, 133, 90, var(--border-opacity));
  }

  .xl\:focus\:tw-border-green-800:focus {
    --border-opacity: 1;
    border-color: #276749;
    border-color: rgba(39, 103, 73, var(--border-opacity));
  }

  .xl\:focus\:tw-border-green-900:focus {
    --border-opacity: 1;
    border-color: #22543d;
    border-color: rgba(34, 84, 61, var(--border-opacity));
  }

  .xl\:focus\:tw-border-teal-100:focus {
    --border-opacity: 1;
    border-color: #e6fffa;
    border-color: rgba(230, 255, 250, var(--border-opacity));
  }

  .xl\:focus\:tw-border-teal-200:focus {
    --border-opacity: 1;
    border-color: #b2f5ea;
    border-color: rgba(178, 245, 234, var(--border-opacity));
  }

  .xl\:focus\:tw-border-teal-300:focus {
    --border-opacity: 1;
    border-color: #81e6d9;
    border-color: rgba(129, 230, 217, var(--border-opacity));
  }

  .xl\:focus\:tw-border-teal-400:focus {
    --border-opacity: 1;
    border-color: #4fd1c5;
    border-color: rgba(79, 209, 197, var(--border-opacity));
  }

  .xl\:focus\:tw-border-teal-500:focus {
    --border-opacity: 1;
    border-color: #38b2ac;
    border-color: rgba(56, 178, 172, var(--border-opacity));
  }

  .xl\:focus\:tw-border-teal-600:focus {
    --border-opacity: 1;
    border-color: #319795;
    border-color: rgba(49, 151, 149, var(--border-opacity));
  }

  .xl\:focus\:tw-border-teal-700:focus {
    --border-opacity: 1;
    border-color: #2c7a7b;
    border-color: rgba(44, 122, 123, var(--border-opacity));
  }

  .xl\:focus\:tw-border-teal-800:focus {
    --border-opacity: 1;
    border-color: #285e61;
    border-color: rgba(40, 94, 97, var(--border-opacity));
  }

  .xl\:focus\:tw-border-teal-900:focus {
    --border-opacity: 1;
    border-color: #234e52;
    border-color: rgba(35, 78, 82, var(--border-opacity));
  }

  .xl\:focus\:tw-border-blue-100:focus {
    --border-opacity: 1;
    border-color: #ebf8ff;
    border-color: rgba(235, 248, 255, var(--border-opacity));
  }

  .xl\:focus\:tw-border-blue-200:focus {
    --border-opacity: 1;
    border-color: #bee3f8;
    border-color: rgba(190, 227, 248, var(--border-opacity));
  }

  .xl\:focus\:tw-border-blue-300:focus {
    --border-opacity: 1;
    border-color: #90cdf4;
    border-color: rgba(144, 205, 244, var(--border-opacity));
  }

  .xl\:focus\:tw-border-blue-400:focus {
    --border-opacity: 1;
    border-color: #63b3ed;
    border-color: rgba(99, 179, 237, var(--border-opacity));
  }

  .xl\:focus\:tw-border-blue-500:focus {
    --border-opacity: 1;
    border-color: #4299e1;
    border-color: rgba(66, 153, 225, var(--border-opacity));
  }

  .xl\:focus\:tw-border-blue-600:focus {
    --border-opacity: 1;
    border-color: #3182ce;
    border-color: rgba(49, 130, 206, var(--border-opacity));
  }

  .xl\:focus\:tw-border-blue-700:focus {
    --border-opacity: 1;
    border-color: #2b6cb0;
    border-color: rgba(43, 108, 176, var(--border-opacity));
  }

  .xl\:focus\:tw-border-blue-800:focus {
    --border-opacity: 1;
    border-color: #2c5282;
    border-color: rgba(44, 82, 130, var(--border-opacity));
  }

  .xl\:focus\:tw-border-blue-900:focus {
    --border-opacity: 1;
    border-color: #2a4365;
    border-color: rgba(42, 67, 101, var(--border-opacity));
  }

  .xl\:focus\:tw-border-indigo-100:focus {
    --border-opacity: 1;
    border-color: #ebf4ff;
    border-color: rgba(235, 244, 255, var(--border-opacity));
  }

  .xl\:focus\:tw-border-indigo-200:focus {
    --border-opacity: 1;
    border-color: #c3dafe;
    border-color: rgba(195, 218, 254, var(--border-opacity));
  }

  .xl\:focus\:tw-border-indigo-300:focus {
    --border-opacity: 1;
    border-color: #a3bffa;
    border-color: rgba(163, 191, 250, var(--border-opacity));
  }

  .xl\:focus\:tw-border-indigo-400:focus {
    --border-opacity: 1;
    border-color: #7f9cf5;
    border-color: rgba(127, 156, 245, var(--border-opacity));
  }

  .xl\:focus\:tw-border-indigo-500:focus {
    --border-opacity: 1;
    border-color: #667eea;
    border-color: rgba(102, 126, 234, var(--border-opacity));
  }

  .xl\:focus\:tw-border-indigo-600:focus {
    --border-opacity: 1;
    border-color: #5a67d8;
    border-color: rgba(90, 103, 216, var(--border-opacity));
  }

  .xl\:focus\:tw-border-indigo-700:focus {
    --border-opacity: 1;
    border-color: #4c51bf;
    border-color: rgba(76, 81, 191, var(--border-opacity));
  }

  .xl\:focus\:tw-border-indigo-800:focus {
    --border-opacity: 1;
    border-color: #434190;
    border-color: rgba(67, 65, 144, var(--border-opacity));
  }

  .xl\:focus\:tw-border-indigo-900:focus {
    --border-opacity: 1;
    border-color: #3c366b;
    border-color: rgba(60, 54, 107, var(--border-opacity));
  }

  .xl\:focus\:tw-border-purple-100:focus {
    --border-opacity: 1;
    border-color: #faf5ff;
    border-color: rgba(250, 245, 255, var(--border-opacity));
  }

  .xl\:focus\:tw-border-purple-200:focus {
    --border-opacity: 1;
    border-color: #e9d8fd;
    border-color: rgba(233, 216, 253, var(--border-opacity));
  }

  .xl\:focus\:tw-border-purple-300:focus {
    --border-opacity: 1;
    border-color: #d6bcfa;
    border-color: rgba(214, 188, 250, var(--border-opacity));
  }

  .xl\:focus\:tw-border-purple-400:focus {
    --border-opacity: 1;
    border-color: #b794f4;
    border-color: rgba(183, 148, 244, var(--border-opacity));
  }

  .xl\:focus\:tw-border-purple-500:focus {
    --border-opacity: 1;
    border-color: #9f7aea;
    border-color: rgba(159, 122, 234, var(--border-opacity));
  }

  .xl\:focus\:tw-border-purple-600:focus {
    --border-opacity: 1;
    border-color: #805ad5;
    border-color: rgba(128, 90, 213, var(--border-opacity));
  }

  .xl\:focus\:tw-border-purple-700:focus {
    --border-opacity: 1;
    border-color: #6b46c1;
    border-color: rgba(107, 70, 193, var(--border-opacity));
  }

  .xl\:focus\:tw-border-purple-800:focus {
    --border-opacity: 1;
    border-color: #553c9a;
    border-color: rgba(85, 60, 154, var(--border-opacity));
  }

  .xl\:focus\:tw-border-purple-900:focus {
    --border-opacity: 1;
    border-color: #44337a;
    border-color: rgba(68, 51, 122, var(--border-opacity));
  }

  .xl\:focus\:tw-border-pink-100:focus {
    --border-opacity: 1;
    border-color: #fff5f7;
    border-color: rgba(255, 245, 247, var(--border-opacity));
  }

  .xl\:focus\:tw-border-pink-200:focus {
    --border-opacity: 1;
    border-color: #fed7e2;
    border-color: rgba(254, 215, 226, var(--border-opacity));
  }

  .xl\:focus\:tw-border-pink-300:focus {
    --border-opacity: 1;
    border-color: #fbb6ce;
    border-color: rgba(251, 182, 206, var(--border-opacity));
  }

  .xl\:focus\:tw-border-pink-400:focus {
    --border-opacity: 1;
    border-color: #f687b3;
    border-color: rgba(246, 135, 179, var(--border-opacity));
  }

  .xl\:focus\:tw-border-pink-500:focus {
    --border-opacity: 1;
    border-color: #ed64a6;
    border-color: rgba(237, 100, 166, var(--border-opacity));
  }

  .xl\:focus\:tw-border-pink-600:focus {
    --border-opacity: 1;
    border-color: #d53f8c;
    border-color: rgba(213, 63, 140, var(--border-opacity));
  }

  .xl\:focus\:tw-border-pink-700:focus {
    --border-opacity: 1;
    border-color: #b83280;
    border-color: rgba(184, 50, 128, var(--border-opacity));
  }

  .xl\:focus\:tw-border-pink-800:focus {
    --border-opacity: 1;
    border-color: #97266d;
    border-color: rgba(151, 38, 109, var(--border-opacity));
  }

  .xl\:focus\:tw-border-pink-900:focus {
    --border-opacity: 1;
    border-color: #702459;
    border-color: rgba(112, 36, 89, var(--border-opacity));
  }

  .xl\:tw-border-opacity-0 {
    --border-opacity: 0;
  }

  .xl\:tw-border-opacity-25 {
    --border-opacity: 0.25;
  }

  .xl\:tw-border-opacity-50 {
    --border-opacity: 0.5;
  }

  .xl\:tw-border-opacity-75 {
    --border-opacity: 0.75;
  }

  .xl\:tw-border-opacity-100 {
    --border-opacity: 1;
  }

  .xl\:hover\:tw-border-opacity-0:hover {
    --border-opacity: 0;
  }

  .xl\:hover\:tw-border-opacity-25:hover {
    --border-opacity: 0.25;
  }

  .xl\:hover\:tw-border-opacity-50:hover {
    --border-opacity: 0.5;
  }

  .xl\:hover\:tw-border-opacity-75:hover {
    --border-opacity: 0.75;
  }

  .xl\:hover\:tw-border-opacity-100:hover {
    --border-opacity: 1;
  }

  .xl\:focus\:tw-border-opacity-0:focus {
    --border-opacity: 0;
  }

  .xl\:focus\:tw-border-opacity-25:focus {
    --border-opacity: 0.25;
  }

  .xl\:focus\:tw-border-opacity-50:focus {
    --border-opacity: 0.5;
  }

  .xl\:focus\:tw-border-opacity-75:focus {
    --border-opacity: 0.75;
  }

  .xl\:focus\:tw-border-opacity-100:focus {
    --border-opacity: 1;
  }

  .xl\:tw-rounded-none {
    border-radius: 0;
  }

  .xl\:tw-rounded-sm {
    border-radius: 0.125rem;
  }

  .xl\:tw-rounded {
    border-radius: 0.25rem;
  }

  .xl\:tw-rounded-md {
    border-radius: 0.375rem;
  }

  .xl\:tw-rounded-lg {
    border-radius: 0.5rem;
  }

  .xl\:tw-rounded-xl {
    border-radius: 0.75rem;
  }

  .xl\:tw-rounded-2xl {
    border-radius: 1rem;
  }

  .xl\:tw-rounded-3xl {
    border-radius: 1.5rem;
  }

  .xl\:tw-rounded-full {
    border-radius: 9999px;
  }

  .xl\:tw-rounded-t-none {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }

  .xl\:tw-rounded-r-none {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  .xl\:tw-rounded-b-none {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
  }

  .xl\:tw-rounded-l-none {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }

  .xl\:tw-rounded-t-sm {
    border-top-left-radius: 0.125rem;
    border-top-right-radius: 0.125rem;
  }

  .xl\:tw-rounded-r-sm {
    border-top-right-radius: 0.125rem;
    border-bottom-right-radius: 0.125rem;
  }

  .xl\:tw-rounded-b-sm {
    border-bottom-right-radius: 0.125rem;
    border-bottom-left-radius: 0.125rem;
  }

  .xl\:tw-rounded-l-sm {
    border-top-left-radius: 0.125rem;
    border-bottom-left-radius: 0.125rem;
  }

  .xl\:tw-rounded-t {
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem;
  }

  .xl\:tw-rounded-r {
    border-top-right-radius: 0.25rem;
    border-bottom-right-radius: 0.25rem;
  }

  .xl\:tw-rounded-b {
    border-bottom-right-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem;
  }

  .xl\:tw-rounded-l {
    border-top-left-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem;
  }

  .xl\:tw-rounded-t-md {
    border-top-left-radius: 0.375rem;
    border-top-right-radius: 0.375rem;
  }

  .xl\:tw-rounded-r-md {
    border-top-right-radius: 0.375rem;
    border-bottom-right-radius: 0.375rem;
  }

  .xl\:tw-rounded-b-md {
    border-bottom-right-radius: 0.375rem;
    border-bottom-left-radius: 0.375rem;
  }

  .xl\:tw-rounded-l-md {
    border-top-left-radius: 0.375rem;
    border-bottom-left-radius: 0.375rem;
  }

  .xl\:tw-rounded-t-lg {
    border-top-left-radius: 0.5rem;
    border-top-right-radius: 0.5rem;
  }

  .xl\:tw-rounded-r-lg {
    border-top-right-radius: 0.5rem;
    border-bottom-right-radius: 0.5rem;
  }

  .xl\:tw-rounded-b-lg {
    border-bottom-right-radius: 0.5rem;
    border-bottom-left-radius: 0.5rem;
  }

  .xl\:tw-rounded-l-lg {
    border-top-left-radius: 0.5rem;
    border-bottom-left-radius: 0.5rem;
  }

  .xl\:tw-rounded-t-xl {
    border-top-left-radius: 0.75rem;
    border-top-right-radius: 0.75rem;
  }

  .xl\:tw-rounded-r-xl {
    border-top-right-radius: 0.75rem;
    border-bottom-right-radius: 0.75rem;
  }

  .xl\:tw-rounded-b-xl {
    border-bottom-right-radius: 0.75rem;
    border-bottom-left-radius: 0.75rem;
  }

  .xl\:tw-rounded-l-xl {
    border-top-left-radius: 0.75rem;
    border-bottom-left-radius: 0.75rem;
  }

  .xl\:tw-rounded-t-2xl {
    border-top-left-radius: 1rem;
    border-top-right-radius: 1rem;
  }

  .xl\:tw-rounded-r-2xl {
    border-top-right-radius: 1rem;
    border-bottom-right-radius: 1rem;
  }

  .xl\:tw-rounded-b-2xl {
    border-bottom-right-radius: 1rem;
    border-bottom-left-radius: 1rem;
  }

  .xl\:tw-rounded-l-2xl {
    border-top-left-radius: 1rem;
    border-bottom-left-radius: 1rem;
  }

  .xl\:tw-rounded-t-3xl {
    border-top-left-radius: 1.5rem;
    border-top-right-radius: 1.5rem;
  }

  .xl\:tw-rounded-r-3xl {
    border-top-right-radius: 1.5rem;
    border-bottom-right-radius: 1.5rem;
  }

  .xl\:tw-rounded-b-3xl {
    border-bottom-right-radius: 1.5rem;
    border-bottom-left-radius: 1.5rem;
  }

  .xl\:tw-rounded-l-3xl {
    border-top-left-radius: 1.5rem;
    border-bottom-left-radius: 1.5rem;
  }

  .xl\:tw-rounded-t-full {
    border-top-left-radius: 9999px;
    border-top-right-radius: 9999px;
  }

  .xl\:tw-rounded-r-full {
    border-top-right-radius: 9999px;
    border-bottom-right-radius: 9999px;
  }

  .xl\:tw-rounded-b-full {
    border-bottom-right-radius: 9999px;
    border-bottom-left-radius: 9999px;
  }

  .xl\:tw-rounded-l-full {
    border-top-left-radius: 9999px;
    border-bottom-left-radius: 9999px;
  }

  .xl\:tw-rounded-tl-none {
    border-top-left-radius: 0;
  }

  .xl\:tw-rounded-tr-none {
    border-top-right-radius: 0;
  }

  .xl\:tw-rounded-br-none {
    border-bottom-right-radius: 0;
  }

  .xl\:tw-rounded-bl-none {
    border-bottom-left-radius: 0;
  }

  .xl\:tw-rounded-tl-sm {
    border-top-left-radius: 0.125rem;
  }

  .xl\:tw-rounded-tr-sm {
    border-top-right-radius: 0.125rem;
  }

  .xl\:tw-rounded-br-sm {
    border-bottom-right-radius: 0.125rem;
  }

  .xl\:tw-rounded-bl-sm {
    border-bottom-left-radius: 0.125rem;
  }

  .xl\:tw-rounded-tl {
    border-top-left-radius: 0.25rem;
  }

  .xl\:tw-rounded-tr {
    border-top-right-radius: 0.25rem;
  }

  .xl\:tw-rounded-br {
    border-bottom-right-radius: 0.25rem;
  }

  .xl\:tw-rounded-bl {
    border-bottom-left-radius: 0.25rem;
  }

  .xl\:tw-rounded-tl-md {
    border-top-left-radius: 0.375rem;
  }

  .xl\:tw-rounded-tr-md {
    border-top-right-radius: 0.375rem;
  }

  .xl\:tw-rounded-br-md {
    border-bottom-right-radius: 0.375rem;
  }

  .xl\:tw-rounded-bl-md {
    border-bottom-left-radius: 0.375rem;
  }

  .xl\:tw-rounded-tl-lg {
    border-top-left-radius: 0.5rem;
  }

  .xl\:tw-rounded-tr-lg {
    border-top-right-radius: 0.5rem;
  }

  .xl\:tw-rounded-br-lg {
    border-bottom-right-radius: 0.5rem;
  }

  .xl\:tw-rounded-bl-lg {
    border-bottom-left-radius: 0.5rem;
  }

  .xl\:tw-rounded-tl-xl {
    border-top-left-radius: 0.75rem;
  }

  .xl\:tw-rounded-tr-xl {
    border-top-right-radius: 0.75rem;
  }

  .xl\:tw-rounded-br-xl {
    border-bottom-right-radius: 0.75rem;
  }

  .xl\:tw-rounded-bl-xl {
    border-bottom-left-radius: 0.75rem;
  }

  .xl\:tw-rounded-tl-2xl {
    border-top-left-radius: 1rem;
  }

  .xl\:tw-rounded-tr-2xl {
    border-top-right-radius: 1rem;
  }

  .xl\:tw-rounded-br-2xl {
    border-bottom-right-radius: 1rem;
  }

  .xl\:tw-rounded-bl-2xl {
    border-bottom-left-radius: 1rem;
  }

  .xl\:tw-rounded-tl-3xl {
    border-top-left-radius: 1.5rem;
  }

  .xl\:tw-rounded-tr-3xl {
    border-top-right-radius: 1.5rem;
  }

  .xl\:tw-rounded-br-3xl {
    border-bottom-right-radius: 1.5rem;
  }

  .xl\:tw-rounded-bl-3xl {
    border-bottom-left-radius: 1.5rem;
  }

  .xl\:tw-rounded-tl-full {
    border-top-left-radius: 9999px;
  }

  .xl\:tw-rounded-tr-full {
    border-top-right-radius: 9999px;
  }

  .xl\:tw-rounded-br-full {
    border-bottom-right-radius: 9999px;
  }

  .xl\:tw-rounded-bl-full {
    border-bottom-left-radius: 9999px;
  }

  .xl\:tw-border-solid {
    border-style: solid;
  }

  .xl\:tw-border-dashed {
    border-style: dashed;
  }

  .xl\:tw-border-dotted {
    border-style: dotted;
  }

  .xl\:tw-border-double {
    border-style: double;
  }

  .xl\:tw-border-none {
    border-style: none;
  }

  .xl\:tw-border-0 {
    border-width: 0;
  }

  .xl\:tw-border-2 {
    border-width: 2px;
  }

  .xl\:tw-border-4 {
    border-width: 4px;
  }

  .xl\:tw-border-8 {
    border-width: 8px;
  }

  .xl\:tw-border {
    border-width: 1px;
  }

  .xl\:tw-border-t-0 {
    border-top-width: 0;
  }

  .xl\:tw-border-r-0 {
    border-right-width: 0;
  }

  .xl\:tw-border-b-0 {
    border-bottom-width: 0;
  }

  .xl\:tw-border-l-0 {
    border-left-width: 0;
  }

  .xl\:tw-border-t-2 {
    border-top-width: 2px;
  }

  .xl\:tw-border-r-2 {
    border-right-width: 2px;
  }

  .xl\:tw-border-b-2 {
    border-bottom-width: 2px;
  }

  .xl\:tw-border-l-2 {
    border-left-width: 2px;
  }

  .xl\:tw-border-t-4 {
    border-top-width: 4px;
  }

  .xl\:tw-border-r-4 {
    border-right-width: 4px;
  }

  .xl\:tw-border-b-4 {
    border-bottom-width: 4px;
  }

  .xl\:tw-border-l-4 {
    border-left-width: 4px;
  }

  .xl\:tw-border-t-8 {
    border-top-width: 8px;
  }

  .xl\:tw-border-r-8 {
    border-right-width: 8px;
  }

  .xl\:tw-border-b-8 {
    border-bottom-width: 8px;
  }

  .xl\:tw-border-l-8 {
    border-left-width: 8px;
  }

  .xl\:tw-border-t {
    border-top-width: 1px;
  }

  .xl\:tw-border-r {
    border-right-width: 1px;
  }

  .xl\:tw-border-b {
    border-bottom-width: 1px;
  }

  .xl\:tw-border-l {
    border-left-width: 1px;
  }

  .xl\:tw-box-border {
    box-sizing: border-box;
  }

  .xl\:tw-box-content {
    box-sizing: content-box;
  }

  .xl\:tw-cursor-auto {
    cursor: auto;
  }

  .xl\:tw-cursor-default {
    cursor: default;
  }

  .xl\:tw-cursor-pointer {
    cursor: pointer;
  }

  .xl\:tw-cursor-wait {
    cursor: wait;
  }

  .xl\:tw-cursor-text {
    cursor: text;
  }

  .xl\:tw-cursor-move {
    cursor: move;
  }

  .xl\:tw-cursor-not-allowed {
    cursor: not-allowed;
  }

  .xl\:tw-block {
    display: block;
  }

  .xl\:tw-inline-block {
    display: inline-block;
  }

  .xl\:tw-inline {
    display: inline;
  }

  .xl\:tw-flex {
    display: flex;
  }

  .xl\:tw-inline-flex {
    display: inline-flex;
  }

  .xl\:tw-table {
    display: table;
  }

  .xl\:tw-table-caption {
    display: table-caption;
  }

  .xl\:tw-table-cell {
    display: table-cell;
  }

  .xl\:tw-table-column {
    display: table-column;
  }

  .xl\:tw-table-column-group {
    display: table-column-group;
  }

  .xl\:tw-table-footer-group {
    display: table-footer-group;
  }

  .xl\:tw-table-header-group {
    display: table-header-group;
  }

  .xl\:tw-table-row-group {
    display: table-row-group;
  }

  .xl\:tw-table-row {
    display: table-row;
  }

  .xl\:tw-flow-root {
    display: flow-root;
  }

  .xl\:tw-grid {
    display: grid;
  }

  .xl\:tw-inline-grid {
    display: inline-grid;
  }

  .xl\:tw-contents {
    display: contents;
  }

  .xl\:tw-hidden {
    display: none;
  }

  .xl\:tw-flex-row {
    flex-direction: row;
  }

  .xl\:tw-flex-row-reverse {
    flex-direction: row-reverse;
  }

  .xl\:tw-flex-col {
    flex-direction: column;
  }

  .xl\:tw-flex-col-reverse {
    flex-direction: column-reverse;
  }

  .xl\:tw-flex-wrap {
    flex-wrap: wrap;
  }

  .xl\:tw-flex-wrap-reverse {
    flex-wrap: wrap-reverse;
  }

  .xl\:tw-flex-no-wrap {
    flex-wrap: nowrap;
  }

  .xl\:tw-place-items-auto {
    place-items: auto;
  }

  .xl\:tw-place-items-start {
    place-items: start;
  }

  .xl\:tw-place-items-end {
    place-items: end;
  }

  .xl\:tw-place-items-center {
    place-items: center;
  }

  .xl\:tw-place-items-stretch {
    place-items: stretch;
  }

  .xl\:tw-place-content-center {
    place-content: center;
  }

  .xl\:tw-place-content-start {
    place-content: start;
  }

  .xl\:tw-place-content-end {
    place-content: end;
  }

  .xl\:tw-place-content-between {
    place-content: space-between;
  }

  .xl\:tw-place-content-around {
    place-content: space-around;
  }

  .xl\:tw-place-content-evenly {
    place-content: space-evenly;
  }

  .xl\:tw-place-content-stretch {
    place-content: stretch;
  }

  .xl\:tw-place-self-auto {
    place-self: auto;
  }

  .xl\:tw-place-self-start {
    place-self: start;
  }

  .xl\:tw-place-self-end {
    place-self: end;
  }

  .xl\:tw-place-self-center {
    place-self: center;
  }

  .xl\:tw-place-self-stretch {
    place-self: stretch;
  }

  .xl\:tw-items-start {
    align-items: flex-start;
  }

  .xl\:tw-items-end {
    align-items: flex-end;
  }

  .xl\:tw-items-center {
    align-items: center;
  }

  .xl\:tw-items-baseline {
    align-items: baseline;
  }

  .xl\:tw-items-stretch {
    align-items: stretch;
  }

  .xl\:tw-content-center {
    align-content: center;
  }

  .xl\:tw-content-start {
    align-content: flex-start;
  }

  .xl\:tw-content-end {
    align-content: flex-end;
  }

  .xl\:tw-content-between {
    align-content: space-between;
  }

  .xl\:tw-content-around {
    align-content: space-around;
  }

  .xl\:tw-content-evenly {
    align-content: space-evenly;
  }

  .xl\:tw-self-auto {
    align-self: auto;
  }

  .xl\:tw-self-start {
    align-self: flex-start;
  }

  .xl\:tw-self-end {
    align-self: flex-end;
  }

  .xl\:tw-self-center {
    align-self: center;
  }

  .xl\:tw-self-stretch {
    align-self: stretch;
  }

  .xl\:tw-justify-items-auto {
    justify-items: auto;
  }

  .xl\:tw-justify-items-start {
    justify-items: start;
  }

  .xl\:tw-justify-items-end {
    justify-items: end;
  }

  .xl\:tw-justify-items-center {
    justify-items: center;
  }

  .xl\:tw-justify-items-stretch {
    justify-items: stretch;
  }

  .xl\:tw-justify-start {
    justify-content: flex-start;
  }

  .xl\:tw-justify-end {
    justify-content: flex-end;
  }

  .xl\:tw-justify-center {
    justify-content: center;
  }

  .xl\:tw-justify-between {
    justify-content: space-between;
  }

  .xl\:tw-justify-around {
    justify-content: space-around;
  }

  .xl\:tw-justify-evenly {
    justify-content: space-evenly;
  }

  .xl\:tw-justify-self-auto {
    justify-self: auto;
  }

  .xl\:tw-justify-self-start {
    justify-self: start;
  }

  .xl\:tw-justify-self-end {
    justify-self: end;
  }

  .xl\:tw-justify-self-center {
    justify-self: center;
  }

  .xl\:tw-justify-self-stretch {
    justify-self: stretch;
  }

  .xl\:tw-flex-1 {
    flex: 1 1 0%;
  }

  .xl\:tw-flex-auto {
    flex: 1 1 auto;
  }

  .xl\:tw-flex-initial {
    flex: 0 1 auto;
  }

  .xl\:tw-flex-none {
    flex: none;
  }

  .xl\:tw-flex-grow-0 {
    flex-grow: 0;
  }

  .xl\:tw-flex-grow {
    flex-grow: 1;
  }

  .xl\:tw-flex-shrink-0 {
    flex-shrink: 0;
  }

  .xl\:tw-flex-shrink {
    flex-shrink: 1;
  }

  .xl\:tw-order-1 {
    order: 1;
  }

  .xl\:tw-order-2 {
    order: 2;
  }

  .xl\:tw-order-3 {
    order: 3;
  }

  .xl\:tw-order-4 {
    order: 4;
  }

  .xl\:tw-order-5 {
    order: 5;
  }

  .xl\:tw-order-6 {
    order: 6;
  }

  .xl\:tw-order-7 {
    order: 7;
  }

  .xl\:tw-order-8 {
    order: 8;
  }

  .xl\:tw-order-9 {
    order: 9;
  }

  .xl\:tw-order-10 {
    order: 10;
  }

  .xl\:tw-order-11 {
    order: 11;
  }

  .xl\:tw-order-12 {
    order: 12;
  }

  .xl\:tw-order-first {
    order: -9999;
  }

  .xl\:tw-order-last {
    order: 9999;
  }

  .xl\:tw-order-none {
    order: 0;
  }

  .xl\:tw-float-right {
    float: right;
  }

  .xl\:tw-float-left {
    float: left;
  }

  .xl\:tw-float-none {
    float: none;
  }

  .xl\:tw-clearfix:after {
    content: "";
    display: table;
    clear: both;
  }

  .xl\:tw-clear-left {
    clear: left;
  }

  .xl\:tw-clear-right {
    clear: right;
  }

  .xl\:tw-clear-both {
    clear: both;
  }

  .xl\:tw-clear-none {
    clear: none;
  }

  .xl\:tw-font-sans {
    font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  }

  .xl\:tw-font-serif {
    font-family: Georgia, Cambria, "Times New Roman", Times, serif;
  }

  .xl\:tw-font-mono {
    font-family: Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  }

  .xl\:tw-font-hairline {
    font-weight: 100;
  }

  .xl\:tw-font-thin {
    font-weight: 200;
  }

  .xl\:tw-font-light {
    font-weight: 300;
  }

  .xl\:tw-font-normal {
    font-weight: 400;
  }

  .xl\:tw-font-medium {
    font-weight: 500;
  }

  .xl\:tw-font-semibold {
    font-weight: 600;
  }

  .xl\:tw-font-bold {
    font-weight: 700;
  }

  .xl\:tw-font-extrabold {
    font-weight: 800;
  }

  .xl\:tw-font-black {
    font-weight: 900;
  }

  .xl\:hover\:tw-font-hairline:hover {
    font-weight: 100;
  }

  .xl\:hover\:tw-font-thin:hover {
    font-weight: 200;
  }

  .xl\:hover\:tw-font-light:hover {
    font-weight: 300;
  }

  .xl\:hover\:tw-font-normal:hover {
    font-weight: 400;
  }

  .xl\:hover\:tw-font-medium:hover {
    font-weight: 500;
  }

  .xl\:hover\:tw-font-semibold:hover {
    font-weight: 600;
  }

  .xl\:hover\:tw-font-bold:hover {
    font-weight: 700;
  }

  .xl\:hover\:tw-font-extrabold:hover {
    font-weight: 800;
  }

  .xl\:hover\:tw-font-black:hover {
    font-weight: 900;
  }

  .xl\:focus\:tw-font-hairline:focus {
    font-weight: 100;
  }

  .xl\:focus\:tw-font-thin:focus {
    font-weight: 200;
  }

  .xl\:focus\:tw-font-light:focus {
    font-weight: 300;
  }

  .xl\:focus\:tw-font-normal:focus {
    font-weight: 400;
  }

  .xl\:focus\:tw-font-medium:focus {
    font-weight: 500;
  }

  .xl\:focus\:tw-font-semibold:focus {
    font-weight: 600;
  }

  .xl\:focus\:tw-font-bold:focus {
    font-weight: 700;
  }

  .xl\:focus\:tw-font-extrabold:focus {
    font-weight: 800;
  }

  .xl\:focus\:tw-font-black:focus {
    font-weight: 900;
  }

  .xl\:tw-h-0 {
    height: 0;
  }

  .xl\:tw-h-1 {
    height: 0.25rem;
  }

  .xl\:tw-h-2 {
    height: 0.5rem;
  }

  .xl\:tw-h-3 {
    height: 0.75rem;
  }

  .xl\:tw-h-4 {
    height: 1rem;
  }

  .xl\:tw-h-5 {
    height: 1.25rem;
  }

  .xl\:tw-h-6 {
    height: 1.5rem;
  }

  .xl\:tw-h-8 {
    height: 2rem;
  }

  .xl\:tw-h-10 {
    height: 2.5rem;
  }

  .xl\:tw-h-12 {
    height: 3rem;
  }

  .xl\:tw-h-16 {
    height: 4rem;
  }

  .xl\:tw-h-20 {
    height: 5rem;
  }

  .xl\:tw-h-24 {
    height: 6rem;
  }

  .xl\:tw-h-32 {
    height: 8rem;
  }

  .xl\:tw-h-40 {
    height: 10rem;
  }

  .xl\:tw-h-48 {
    height: 12rem;
  }

  .xl\:tw-h-56 {
    height: 14rem;
  }

  .xl\:tw-h-64 {
    height: 16rem;
  }

  .xl\:tw-h-auto {
    height: auto;
  }

  .xl\:tw-h-px {
    height: 1px;
  }

  .xl\:tw-h-270px {
    height: 270px;
  }

  .xl\:tw-h-400px {
    height: 400px;
  }

  .xl\:tw-h-464px {
    height: 464px;
  }

  .xl\:tw-h-480px {
    height: 480px;
  }

  .xl\:tw-h-500px {
    height: 500px;
  }

  .xl\:tw-h-576px {
    height: 576px;
  }

  .xl\:tw-h-600px {
    height: 600px;
  }

  .xl\:tw-h-672px {
    height: 672px;
  }

  .xl\:tw-h-full {
    height: 100%;
  }

  .xl\:tw-h-screen {
    height: 100vh;
  }

  .xl\:tw-text-xs {
    font-size: 0.75rem;
  }

  .xl\:tw-text-sm {
    font-size: 0.875rem;
  }

  .xl\:tw-text-base {
    font-size: 1rem;
  }

  .xl\:tw-text-lg {
    font-size: 1.125rem;
  }

  .xl\:tw-text-xl {
    font-size: 1.25rem;
  }

  .xl\:tw-text-2xl {
    font-size: 1.5rem;
  }

  .xl\:tw-text-3xl {
    font-size: 1.875rem;
  }

  .xl\:tw-text-4xl {
    font-size: 2.25rem;
  }

  .xl\:tw-text-5xl {
    font-size: 3rem;
  }

  .xl\:tw-text-6xl {
    font-size: 4rem;
  }

  .xl\:tw-leading-3 {
    line-height: .75rem;
  }

  .xl\:tw-leading-4 {
    line-height: 1rem;
  }

  .xl\:tw-leading-5 {
    line-height: 1.25rem;
  }

  .xl\:tw-leading-6 {
    line-height: 1.5rem;
  }

  .xl\:tw-leading-7 {
    line-height: 1.75rem;
  }

  .xl\:tw-leading-8 {
    line-height: 2rem;
  }

  .xl\:tw-leading-9 {
    line-height: 2.25rem;
  }

  .xl\:tw-leading-10 {
    line-height: 2.5rem;
  }

  .xl\:tw-leading-none {
    line-height: 1;
  }

  .xl\:tw-leading-tight {
    line-height: 1.25;
  }

  .xl\:tw-leading-snug {
    line-height: 1.375;
  }

  .xl\:tw-leading-normal {
    line-height: 1.5;
  }

  .xl\:tw-leading-relaxed {
    line-height: 1.625;
  }

  .xl\:tw-leading-loose {
    line-height: 2;
  }

  .xl\:tw-list-inside {
    list-style-position: inside;
  }

  .xl\:tw-list-outside {
    list-style-position: outside;
  }

  .xl\:tw-list-none {
    list-style-type: none;
  }

  .xl\:tw-list-disc {
    list-style-type: disc;
  }

  .xl\:tw-list-decimal {
    list-style-type: decimal;
  }

  .xl\:tw-m-0 {
    margin: 0;
  }

  .xl\:tw-m-1 {
    margin: 0.25rem;
  }

  .xl\:tw-m-2 {
    margin: 0.5rem;
  }

  .xl\:tw-m-3 {
    margin: 0.75rem;
  }

  .xl\:tw-m-4 {
    margin: 1rem;
  }

  .xl\:tw-m-5 {
    margin: 1.25rem;
  }

  .xl\:tw-m-6 {
    margin: 1.5rem;
  }

  .xl\:tw-m-8 {
    margin: 2rem;
  }

  .xl\:tw-m-10 {
    margin: 2.5rem;
  }

  .xl\:tw-m-12 {
    margin: 3rem;
  }

  .xl\:tw-m-16 {
    margin: 4rem;
  }

  .xl\:tw-m-20 {
    margin: 5rem;
  }

  .xl\:tw-m-24 {
    margin: 6rem;
  }

  .xl\:tw-m-32 {
    margin: 8rem;
  }

  .xl\:tw-m-40 {
    margin: 10rem;
  }

  .xl\:tw-m-48 {
    margin: 12rem;
  }

  .xl\:tw-m-56 {
    margin: 14rem;
  }

  .xl\:tw-m-64 {
    margin: 16rem;
  }

  .xl\:tw-m-auto {
    margin: auto;
  }

  .xl\:tw-m-px {
    margin: 1px;
  }

  .xl\:tw-m-270px {
    margin: 270px;
  }

  .xl\:tw-m-400px {
    margin: 400px;
  }

  .xl\:tw-m-464px {
    margin: 464px;
  }

  .xl\:tw-m-480px {
    margin: 480px;
  }

  .xl\:tw-m-500px {
    margin: 500px;
  }

  .xl\:tw-m-576px {
    margin: 576px;
  }

  .xl\:tw-m-600px {
    margin: 600px;
  }

  .xl\:tw-m-672px {
    margin: 672px;
  }

  .xl\:tw--m-1 {
    margin: -0.25rem;
  }

  .xl\:tw--m-2 {
    margin: -0.5rem;
  }

  .xl\:tw--m-3 {
    margin: -0.75rem;
  }

  .xl\:tw--m-4 {
    margin: -1rem;
  }

  .xl\:tw--m-5 {
    margin: -1.25rem;
  }

  .xl\:tw--m-6 {
    margin: -1.5rem;
  }

  .xl\:tw--m-8 {
    margin: -2rem;
  }

  .xl\:tw--m-10 {
    margin: -2.5rem;
  }

  .xl\:tw--m-12 {
    margin: -3rem;
  }

  .xl\:tw--m-16 {
    margin: -4rem;
  }

  .xl\:tw--m-20 {
    margin: -5rem;
  }

  .xl\:tw--m-24 {
    margin: -6rem;
  }

  .xl\:tw--m-32 {
    margin: -8rem;
  }

  .xl\:tw--m-40 {
    margin: -10rem;
  }

  .xl\:tw--m-48 {
    margin: -12rem;
  }

  .xl\:tw--m-56 {
    margin: -14rem;
  }

  .xl\:tw--m-64 {
    margin: -16rem;
  }

  .xl\:tw--m-px {
    margin: -1px;
  }

  .xl\:tw--m-270px {
    margin: -270px;
  }

  .xl\:tw--m-400px {
    margin: -400px;
  }

  .xl\:tw--m-464px {
    margin: -464px;
  }

  .xl\:tw--m-480px {
    margin: -480px;
  }

  .xl\:tw--m-500px {
    margin: -500px;
  }

  .xl\:tw--m-576px {
    margin: -576px;
  }

  .xl\:tw--m-600px {
    margin: -600px;
  }

  .xl\:tw--m-672px {
    margin: -672px;
  }

  .xl\:tw-my-0 {
    margin-top: 0;
    margin-bottom: 0;
  }

  .xl\:tw-mx-0 {
    margin-left: 0;
    margin-right: 0;
  }

  .xl\:tw-my-1 {
    margin-top: 0.25rem;
    margin-bottom: 0.25rem;
  }

  .xl\:tw-mx-1 {
    margin-left: 0.25rem;
    margin-right: 0.25rem;
  }

  .xl\:tw-my-2 {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
  }

  .xl\:tw-mx-2 {
    margin-left: 0.5rem;
    margin-right: 0.5rem;
  }

  .xl\:tw-my-3 {
    margin-top: 0.75rem;
    margin-bottom: 0.75rem;
  }

  .xl\:tw-mx-3 {
    margin-left: 0.75rem;
    margin-right: 0.75rem;
  }

  .xl\:tw-my-4 {
    margin-top: 1rem;
    margin-bottom: 1rem;
  }

  .xl\:tw-mx-4 {
    margin-left: 1rem;
    margin-right: 1rem;
  }

  .xl\:tw-my-5 {
    margin-top: 1.25rem;
    margin-bottom: 1.25rem;
  }

  .xl\:tw-mx-5 {
    margin-left: 1.25rem;
    margin-right: 1.25rem;
  }

  .xl\:tw-my-6 {
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
  }

  .xl\:tw-mx-6 {
    margin-left: 1.5rem;
    margin-right: 1.5rem;
  }

  .xl\:tw-my-8 {
    margin-top: 2rem;
    margin-bottom: 2rem;
  }

  .xl\:tw-mx-8 {
    margin-left: 2rem;
    margin-right: 2rem;
  }

  .xl\:tw-my-10 {
    margin-top: 2.5rem;
    margin-bottom: 2.5rem;
  }

  .xl\:tw-mx-10 {
    margin-left: 2.5rem;
    margin-right: 2.5rem;
  }

  .xl\:tw-my-12 {
    margin-top: 3rem;
    margin-bottom: 3rem;
  }

  .xl\:tw-mx-12 {
    margin-left: 3rem;
    margin-right: 3rem;
  }

  .xl\:tw-my-16 {
    margin-top: 4rem;
    margin-bottom: 4rem;
  }

  .xl\:tw-mx-16 {
    margin-left: 4rem;
    margin-right: 4rem;
  }

  .xl\:tw-my-20 {
    margin-top: 5rem;
    margin-bottom: 5rem;
  }

  .xl\:tw-mx-20 {
    margin-left: 5rem;
    margin-right: 5rem;
  }

  .xl\:tw-my-24 {
    margin-top: 6rem;
    margin-bottom: 6rem;
  }

  .xl\:tw-mx-24 {
    margin-left: 6rem;
    margin-right: 6rem;
  }

  .xl\:tw-my-32 {
    margin-top: 8rem;
    margin-bottom: 8rem;
  }

  .xl\:tw-mx-32 {
    margin-left: 8rem;
    margin-right: 8rem;
  }

  .xl\:tw-my-40 {
    margin-top: 10rem;
    margin-bottom: 10rem;
  }

  .xl\:tw-mx-40 {
    margin-left: 10rem;
    margin-right: 10rem;
  }

  .xl\:tw-my-48 {
    margin-top: 12rem;
    margin-bottom: 12rem;
  }

  .xl\:tw-mx-48 {
    margin-left: 12rem;
    margin-right: 12rem;
  }

  .xl\:tw-my-56 {
    margin-top: 14rem;
    margin-bottom: 14rem;
  }

  .xl\:tw-mx-56 {
    margin-left: 14rem;
    margin-right: 14rem;
  }

  .xl\:tw-my-64 {
    margin-top: 16rem;
    margin-bottom: 16rem;
  }

  .xl\:tw-mx-64 {
    margin-left: 16rem;
    margin-right: 16rem;
  }

  .xl\:tw-my-auto {
    margin-top: auto;
    margin-bottom: auto;
  }

  .xl\:tw-mx-auto {
    margin-left: auto;
    margin-right: auto;
  }

  .xl\:tw-my-px {
    margin-top: 1px;
    margin-bottom: 1px;
  }

  .xl\:tw-mx-px {
    margin-left: 1px;
    margin-right: 1px;
  }

  .xl\:tw-my-270px {
    margin-top: 270px;
    margin-bottom: 270px;
  }

  .xl\:tw-mx-270px {
    margin-left: 270px;
    margin-right: 270px;
  }

  .xl\:tw-my-400px {
    margin-top: 400px;
    margin-bottom: 400px;
  }

  .xl\:tw-mx-400px {
    margin-left: 400px;
    margin-right: 400px;
  }

  .xl\:tw-my-464px {
    margin-top: 464px;
    margin-bottom: 464px;
  }

  .xl\:tw-mx-464px {
    margin-left: 464px;
    margin-right: 464px;
  }

  .xl\:tw-my-480px {
    margin-top: 480px;
    margin-bottom: 480px;
  }

  .xl\:tw-mx-480px {
    margin-left: 480px;
    margin-right: 480px;
  }

  .xl\:tw-my-500px {
    margin-top: 500px;
    margin-bottom: 500px;
  }

  .xl\:tw-mx-500px {
    margin-left: 500px;
    margin-right: 500px;
  }

  .xl\:tw-my-576px {
    margin-top: 576px;
    margin-bottom: 576px;
  }

  .xl\:tw-mx-576px {
    margin-left: 576px;
    margin-right: 576px;
  }

  .xl\:tw-my-600px {
    margin-top: 600px;
    margin-bottom: 600px;
  }

  .xl\:tw-mx-600px {
    margin-left: 600px;
    margin-right: 600px;
  }

  .xl\:tw-my-672px {
    margin-top: 672px;
    margin-bottom: 672px;
  }

  .xl\:tw-mx-672px {
    margin-left: 672px;
    margin-right: 672px;
  }

  .xl\:tw--my-1 {
    margin-top: -0.25rem;
    margin-bottom: -0.25rem;
  }

  .xl\:tw--mx-1 {
    margin-left: -0.25rem;
    margin-right: -0.25rem;
  }

  .xl\:tw--my-2 {
    margin-top: -0.5rem;
    margin-bottom: -0.5rem;
  }

  .xl\:tw--mx-2 {
    margin-left: -0.5rem;
    margin-right: -0.5rem;
  }

  .xl\:tw--my-3 {
    margin-top: -0.75rem;
    margin-bottom: -0.75rem;
  }

  .xl\:tw--mx-3 {
    margin-left: -0.75rem;
    margin-right: -0.75rem;
  }

  .xl\:tw--my-4 {
    margin-top: -1rem;
    margin-bottom: -1rem;
  }

  .xl\:tw--mx-4 {
    margin-left: -1rem;
    margin-right: -1rem;
  }

  .xl\:tw--my-5 {
    margin-top: -1.25rem;
    margin-bottom: -1.25rem;
  }

  .xl\:tw--mx-5 {
    margin-left: -1.25rem;
    margin-right: -1.25rem;
  }

  .xl\:tw--my-6 {
    margin-top: -1.5rem;
    margin-bottom: -1.5rem;
  }

  .xl\:tw--mx-6 {
    margin-left: -1.5rem;
    margin-right: -1.5rem;
  }

  .xl\:tw--my-8 {
    margin-top: -2rem;
    margin-bottom: -2rem;
  }

  .xl\:tw--mx-8 {
    margin-left: -2rem;
    margin-right: -2rem;
  }

  .xl\:tw--my-10 {
    margin-top: -2.5rem;
    margin-bottom: -2.5rem;
  }

  .xl\:tw--mx-10 {
    margin-left: -2.5rem;
    margin-right: -2.5rem;
  }

  .xl\:tw--my-12 {
    margin-top: -3rem;
    margin-bottom: -3rem;
  }

  .xl\:tw--mx-12 {
    margin-left: -3rem;
    margin-right: -3rem;
  }

  .xl\:tw--my-16 {
    margin-top: -4rem;
    margin-bottom: -4rem;
  }

  .xl\:tw--mx-16 {
    margin-left: -4rem;
    margin-right: -4rem;
  }

  .xl\:tw--my-20 {
    margin-top: -5rem;
    margin-bottom: -5rem;
  }

  .xl\:tw--mx-20 {
    margin-left: -5rem;
    margin-right: -5rem;
  }

  .xl\:tw--my-24 {
    margin-top: -6rem;
    margin-bottom: -6rem;
  }

  .xl\:tw--mx-24 {
    margin-left: -6rem;
    margin-right: -6rem;
  }

  .xl\:tw--my-32 {
    margin-top: -8rem;
    margin-bottom: -8rem;
  }

  .xl\:tw--mx-32 {
    margin-left: -8rem;
    margin-right: -8rem;
  }

  .xl\:tw--my-40 {
    margin-top: -10rem;
    margin-bottom: -10rem;
  }

  .xl\:tw--mx-40 {
    margin-left: -10rem;
    margin-right: -10rem;
  }

  .xl\:tw--my-48 {
    margin-top: -12rem;
    margin-bottom: -12rem;
  }

  .xl\:tw--mx-48 {
    margin-left: -12rem;
    margin-right: -12rem;
  }

  .xl\:tw--my-56 {
    margin-top: -14rem;
    margin-bottom: -14rem;
  }

  .xl\:tw--mx-56 {
    margin-left: -14rem;
    margin-right: -14rem;
  }

  .xl\:tw--my-64 {
    margin-top: -16rem;
    margin-bottom: -16rem;
  }

  .xl\:tw--mx-64 {
    margin-left: -16rem;
    margin-right: -16rem;
  }

  .xl\:tw--my-px {
    margin-top: -1px;
    margin-bottom: -1px;
  }

  .xl\:tw--mx-px {
    margin-left: -1px;
    margin-right: -1px;
  }

  .xl\:tw--my-270px {
    margin-top: -270px;
    margin-bottom: -270px;
  }

  .xl\:tw--mx-270px {
    margin-left: -270px;
    margin-right: -270px;
  }

  .xl\:tw--my-400px {
    margin-top: -400px;
    margin-bottom: -400px;
  }

  .xl\:tw--mx-400px {
    margin-left: -400px;
    margin-right: -400px;
  }

  .xl\:tw--my-464px {
    margin-top: -464px;
    margin-bottom: -464px;
  }

  .xl\:tw--mx-464px {
    margin-left: -464px;
    margin-right: -464px;
  }

  .xl\:tw--my-480px {
    margin-top: -480px;
    margin-bottom: -480px;
  }

  .xl\:tw--mx-480px {
    margin-left: -480px;
    margin-right: -480px;
  }

  .xl\:tw--my-500px {
    margin-top: -500px;
    margin-bottom: -500px;
  }

  .xl\:tw--mx-500px {
    margin-left: -500px;
    margin-right: -500px;
  }

  .xl\:tw--my-576px {
    margin-top: -576px;
    margin-bottom: -576px;
  }

  .xl\:tw--mx-576px {
    margin-left: -576px;
    margin-right: -576px;
  }

  .xl\:tw--my-600px {
    margin-top: -600px;
    margin-bottom: -600px;
  }

  .xl\:tw--mx-600px {
    margin-left: -600px;
    margin-right: -600px;
  }

  .xl\:tw--my-672px {
    margin-top: -672px;
    margin-bottom: -672px;
  }

  .xl\:tw--mx-672px {
    margin-left: -672px;
    margin-right: -672px;
  }

  .xl\:tw-mt-0 {
    margin-top: 0;
  }

  .xl\:tw-mr-0 {
    margin-right: 0;
  }

  .xl\:tw-mb-0 {
    margin-bottom: 0;
  }

  .xl\:tw-ml-0 {
    margin-left: 0;
  }

  .xl\:tw-mt-1 {
    margin-top: 0.25rem;
  }

  .xl\:tw-mr-1 {
    margin-right: 0.25rem;
  }

  .xl\:tw-mb-1 {
    margin-bottom: 0.25rem;
  }

  .xl\:tw-ml-1 {
    margin-left: 0.25rem;
  }

  .xl\:tw-mt-2 {
    margin-top: 0.5rem;
  }

  .xl\:tw-mr-2 {
    margin-right: 0.5rem;
  }

  .xl\:tw-mb-2 {
    margin-bottom: 0.5rem;
  }

  .xl\:tw-ml-2 {
    margin-left: 0.5rem;
  }

  .xl\:tw-mt-3 {
    margin-top: 0.75rem;
  }

  .xl\:tw-mr-3 {
    margin-right: 0.75rem;
  }

  .xl\:tw-mb-3 {
    margin-bottom: 0.75rem;
  }

  .xl\:tw-ml-3 {
    margin-left: 0.75rem;
  }

  .xl\:tw-mt-4 {
    margin-top: 1rem;
  }

  .xl\:tw-mr-4 {
    margin-right: 1rem;
  }

  .xl\:tw-mb-4 {
    margin-bottom: 1rem;
  }

  .xl\:tw-ml-4 {
    margin-left: 1rem;
  }

  .xl\:tw-mt-5 {
    margin-top: 1.25rem;
  }

  .xl\:tw-mr-5 {
    margin-right: 1.25rem;
  }

  .xl\:tw-mb-5 {
    margin-bottom: 1.25rem;
  }

  .xl\:tw-ml-5 {
    margin-left: 1.25rem;
  }

  .xl\:tw-mt-6 {
    margin-top: 1.5rem;
  }

  .xl\:tw-mr-6 {
    margin-right: 1.5rem;
  }

  .xl\:tw-mb-6 {
    margin-bottom: 1.5rem;
  }

  .xl\:tw-ml-6 {
    margin-left: 1.5rem;
  }

  .xl\:tw-mt-8 {
    margin-top: 2rem;
  }

  .xl\:tw-mr-8 {
    margin-right: 2rem;
  }

  .xl\:tw-mb-8 {
    margin-bottom: 2rem;
  }

  .xl\:tw-ml-8 {
    margin-left: 2rem;
  }

  .xl\:tw-mt-10 {
    margin-top: 2.5rem;
  }

  .xl\:tw-mr-10 {
    margin-right: 2.5rem;
  }

  .xl\:tw-mb-10 {
    margin-bottom: 2.5rem;
  }

  .xl\:tw-ml-10 {
    margin-left: 2.5rem;
  }

  .xl\:tw-mt-12 {
    margin-top: 3rem;
  }

  .xl\:tw-mr-12 {
    margin-right: 3rem;
  }

  .xl\:tw-mb-12 {
    margin-bottom: 3rem;
  }

  .xl\:tw-ml-12 {
    margin-left: 3rem;
  }

  .xl\:tw-mt-16 {
    margin-top: 4rem;
  }

  .xl\:tw-mr-16 {
    margin-right: 4rem;
  }

  .xl\:tw-mb-16 {
    margin-bottom: 4rem;
  }

  .xl\:tw-ml-16 {
    margin-left: 4rem;
  }

  .xl\:tw-mt-20 {
    margin-top: 5rem;
  }

  .xl\:tw-mr-20 {
    margin-right: 5rem;
  }

  .xl\:tw-mb-20 {
    margin-bottom: 5rem;
  }

  .xl\:tw-ml-20 {
    margin-left: 5rem;
  }

  .xl\:tw-mt-24 {
    margin-top: 6rem;
  }

  .xl\:tw-mr-24 {
    margin-right: 6rem;
  }

  .xl\:tw-mb-24 {
    margin-bottom: 6rem;
  }

  .xl\:tw-ml-24 {
    margin-left: 6rem;
  }

  .xl\:tw-mt-32 {
    margin-top: 8rem;
  }

  .xl\:tw-mr-32 {
    margin-right: 8rem;
  }

  .xl\:tw-mb-32 {
    margin-bottom: 8rem;
  }

  .xl\:tw-ml-32 {
    margin-left: 8rem;
  }

  .xl\:tw-mt-40 {
    margin-top: 10rem;
  }

  .xl\:tw-mr-40 {
    margin-right: 10rem;
  }

  .xl\:tw-mb-40 {
    margin-bottom: 10rem;
  }

  .xl\:tw-ml-40 {
    margin-left: 10rem;
  }

  .xl\:tw-mt-48 {
    margin-top: 12rem;
  }

  .xl\:tw-mr-48 {
    margin-right: 12rem;
  }

  .xl\:tw-mb-48 {
    margin-bottom: 12rem;
  }

  .xl\:tw-ml-48 {
    margin-left: 12rem;
  }

  .xl\:tw-mt-56 {
    margin-top: 14rem;
  }

  .xl\:tw-mr-56 {
    margin-right: 14rem;
  }

  .xl\:tw-mb-56 {
    margin-bottom: 14rem;
  }

  .xl\:tw-ml-56 {
    margin-left: 14rem;
  }

  .xl\:tw-mt-64 {
    margin-top: 16rem;
  }

  .xl\:tw-mr-64 {
    margin-right: 16rem;
  }

  .xl\:tw-mb-64 {
    margin-bottom: 16rem;
  }

  .xl\:tw-ml-64 {
    margin-left: 16rem;
  }

  .xl\:tw-mt-auto {
    margin-top: auto;
  }

  .xl\:tw-mr-auto {
    margin-right: auto;
  }

  .xl\:tw-mb-auto {
    margin-bottom: auto;
  }

  .xl\:tw-ml-auto {
    margin-left: auto;
  }

  .xl\:tw-mt-px {
    margin-top: 1px;
  }

  .xl\:tw-mr-px {
    margin-right: 1px;
  }

  .xl\:tw-mb-px {
    margin-bottom: 1px;
  }

  .xl\:tw-ml-px {
    margin-left: 1px;
  }

  .xl\:tw-mt-270px {
    margin-top: 270px;
  }

  .xl\:tw-mr-270px {
    margin-right: 270px;
  }

  .xl\:tw-mb-270px {
    margin-bottom: 270px;
  }

  .xl\:tw-ml-270px {
    margin-left: 270px;
  }

  .xl\:tw-mt-400px {
    margin-top: 400px;
  }

  .xl\:tw-mr-400px {
    margin-right: 400px;
  }

  .xl\:tw-mb-400px {
    margin-bottom: 400px;
  }

  .xl\:tw-ml-400px {
    margin-left: 400px;
  }

  .xl\:tw-mt-464px {
    margin-top: 464px;
  }

  .xl\:tw-mr-464px {
    margin-right: 464px;
  }

  .xl\:tw-mb-464px {
    margin-bottom: 464px;
  }

  .xl\:tw-ml-464px {
    margin-left: 464px;
  }

  .xl\:tw-mt-480px {
    margin-top: 480px;
  }

  .xl\:tw-mr-480px {
    margin-right: 480px;
  }

  .xl\:tw-mb-480px {
    margin-bottom: 480px;
  }

  .xl\:tw-ml-480px {
    margin-left: 480px;
  }

  .xl\:tw-mt-500px {
    margin-top: 500px;
  }

  .xl\:tw-mr-500px {
    margin-right: 500px;
  }

  .xl\:tw-mb-500px {
    margin-bottom: 500px;
  }

  .xl\:tw-ml-500px {
    margin-left: 500px;
  }

  .xl\:tw-mt-576px {
    margin-top: 576px;
  }

  .xl\:tw-mr-576px {
    margin-right: 576px;
  }

  .xl\:tw-mb-576px {
    margin-bottom: 576px;
  }

  .xl\:tw-ml-576px {
    margin-left: 576px;
  }

  .xl\:tw-mt-600px {
    margin-top: 600px;
  }

  .xl\:tw-mr-600px {
    margin-right: 600px;
  }

  .xl\:tw-mb-600px {
    margin-bottom: 600px;
  }

  .xl\:tw-ml-600px {
    margin-left: 600px;
  }

  .xl\:tw-mt-672px {
    margin-top: 672px;
  }

  .xl\:tw-mr-672px {
    margin-right: 672px;
  }

  .xl\:tw-mb-672px {
    margin-bottom: 672px;
  }

  .xl\:tw-ml-672px {
    margin-left: 672px;
  }

  .xl\:tw--mt-1 {
    margin-top: -0.25rem;
  }

  .xl\:tw--mr-1 {
    margin-right: -0.25rem;
  }

  .xl\:tw--mb-1 {
    margin-bottom: -0.25rem;
  }

  .xl\:tw--ml-1 {
    margin-left: -0.25rem;
  }

  .xl\:tw--mt-2 {
    margin-top: -0.5rem;
  }

  .xl\:tw--mr-2 {
    margin-right: -0.5rem;
  }

  .xl\:tw--mb-2 {
    margin-bottom: -0.5rem;
  }

  .xl\:tw--ml-2 {
    margin-left: -0.5rem;
  }

  .xl\:tw--mt-3 {
    margin-top: -0.75rem;
  }

  .xl\:tw--mr-3 {
    margin-right: -0.75rem;
  }

  .xl\:tw--mb-3 {
    margin-bottom: -0.75rem;
  }

  .xl\:tw--ml-3 {
    margin-left: -0.75rem;
  }

  .xl\:tw--mt-4 {
    margin-top: -1rem;
  }

  .xl\:tw--mr-4 {
    margin-right: -1rem;
  }

  .xl\:tw--mb-4 {
    margin-bottom: -1rem;
  }

  .xl\:tw--ml-4 {
    margin-left: -1rem;
  }

  .xl\:tw--mt-5 {
    margin-top: -1.25rem;
  }

  .xl\:tw--mr-5 {
    margin-right: -1.25rem;
  }

  .xl\:tw--mb-5 {
    margin-bottom: -1.25rem;
  }

  .xl\:tw--ml-5 {
    margin-left: -1.25rem;
  }

  .xl\:tw--mt-6 {
    margin-top: -1.5rem;
  }

  .xl\:tw--mr-6 {
    margin-right: -1.5rem;
  }

  .xl\:tw--mb-6 {
    margin-bottom: -1.5rem;
  }

  .xl\:tw--ml-6 {
    margin-left: -1.5rem;
  }

  .xl\:tw--mt-8 {
    margin-top: -2rem;
  }

  .xl\:tw--mr-8 {
    margin-right: -2rem;
  }

  .xl\:tw--mb-8 {
    margin-bottom: -2rem;
  }

  .xl\:tw--ml-8 {
    margin-left: -2rem;
  }

  .xl\:tw--mt-10 {
    margin-top: -2.5rem;
  }

  .xl\:tw--mr-10 {
    margin-right: -2.5rem;
  }

  .xl\:tw--mb-10 {
    margin-bottom: -2.5rem;
  }

  .xl\:tw--ml-10 {
    margin-left: -2.5rem;
  }

  .xl\:tw--mt-12 {
    margin-top: -3rem;
  }

  .xl\:tw--mr-12 {
    margin-right: -3rem;
  }

  .xl\:tw--mb-12 {
    margin-bottom: -3rem;
  }

  .xl\:tw--ml-12 {
    margin-left: -3rem;
  }

  .xl\:tw--mt-16 {
    margin-top: -4rem;
  }

  .xl\:tw--mr-16 {
    margin-right: -4rem;
  }

  .xl\:tw--mb-16 {
    margin-bottom: -4rem;
  }

  .xl\:tw--ml-16 {
    margin-left: -4rem;
  }

  .xl\:tw--mt-20 {
    margin-top: -5rem;
  }

  .xl\:tw--mr-20 {
    margin-right: -5rem;
  }

  .xl\:tw--mb-20 {
    margin-bottom: -5rem;
  }

  .xl\:tw--ml-20 {
    margin-left: -5rem;
  }

  .xl\:tw--mt-24 {
    margin-top: -6rem;
  }

  .xl\:tw--mr-24 {
    margin-right: -6rem;
  }

  .xl\:tw--mb-24 {
    margin-bottom: -6rem;
  }

  .xl\:tw--ml-24 {
    margin-left: -6rem;
  }

  .xl\:tw--mt-32 {
    margin-top: -8rem;
  }

  .xl\:tw--mr-32 {
    margin-right: -8rem;
  }

  .xl\:tw--mb-32 {
    margin-bottom: -8rem;
  }

  .xl\:tw--ml-32 {
    margin-left: -8rem;
  }

  .xl\:tw--mt-40 {
    margin-top: -10rem;
  }

  .xl\:tw--mr-40 {
    margin-right: -10rem;
  }

  .xl\:tw--mb-40 {
    margin-bottom: -10rem;
  }

  .xl\:tw--ml-40 {
    margin-left: -10rem;
  }

  .xl\:tw--mt-48 {
    margin-top: -12rem;
  }

  .xl\:tw--mr-48 {
    margin-right: -12rem;
  }

  .xl\:tw--mb-48 {
    margin-bottom: -12rem;
  }

  .xl\:tw--ml-48 {
    margin-left: -12rem;
  }

  .xl\:tw--mt-56 {
    margin-top: -14rem;
  }

  .xl\:tw--mr-56 {
    margin-right: -14rem;
  }

  .xl\:tw--mb-56 {
    margin-bottom: -14rem;
  }

  .xl\:tw--ml-56 {
    margin-left: -14rem;
  }

  .xl\:tw--mt-64 {
    margin-top: -16rem;
  }

  .xl\:tw--mr-64 {
    margin-right: -16rem;
  }

  .xl\:tw--mb-64 {
    margin-bottom: -16rem;
  }

  .xl\:tw--ml-64 {
    margin-left: -16rem;
  }

  .xl\:tw--mt-px {
    margin-top: -1px;
  }

  .xl\:tw--mr-px {
    margin-right: -1px;
  }

  .xl\:tw--mb-px {
    margin-bottom: -1px;
  }

  .xl\:tw--ml-px {
    margin-left: -1px;
  }

  .xl\:tw--mt-270px {
    margin-top: -270px;
  }

  .xl\:tw--mr-270px {
    margin-right: -270px;
  }

  .xl\:tw--mb-270px {
    margin-bottom: -270px;
  }

  .xl\:tw--ml-270px {
    margin-left: -270px;
  }

  .xl\:tw--mt-400px {
    margin-top: -400px;
  }

  .xl\:tw--mr-400px {
    margin-right: -400px;
  }

  .xl\:tw--mb-400px {
    margin-bottom: -400px;
  }

  .xl\:tw--ml-400px {
    margin-left: -400px;
  }

  .xl\:tw--mt-464px {
    margin-top: -464px;
  }

  .xl\:tw--mr-464px {
    margin-right: -464px;
  }

  .xl\:tw--mb-464px {
    margin-bottom: -464px;
  }

  .xl\:tw--ml-464px {
    margin-left: -464px;
  }

  .xl\:tw--mt-480px {
    margin-top: -480px;
  }

  .xl\:tw--mr-480px {
    margin-right: -480px;
  }

  .xl\:tw--mb-480px {
    margin-bottom: -480px;
  }

  .xl\:tw--ml-480px {
    margin-left: -480px;
  }

  .xl\:tw--mt-500px {
    margin-top: -500px;
  }

  .xl\:tw--mr-500px {
    margin-right: -500px;
  }

  .xl\:tw--mb-500px {
    margin-bottom: -500px;
  }

  .xl\:tw--ml-500px {
    margin-left: -500px;
  }

  .xl\:tw--mt-576px {
    margin-top: -576px;
  }

  .xl\:tw--mr-576px {
    margin-right: -576px;
  }

  .xl\:tw--mb-576px {
    margin-bottom: -576px;
  }

  .xl\:tw--ml-576px {
    margin-left: -576px;
  }

  .xl\:tw--mt-600px {
    margin-top: -600px;
  }

  .xl\:tw--mr-600px {
    margin-right: -600px;
  }

  .xl\:tw--mb-600px {
    margin-bottom: -600px;
  }

  .xl\:tw--ml-600px {
    margin-left: -600px;
  }

  .xl\:tw--mt-672px {
    margin-top: -672px;
  }

  .xl\:tw--mr-672px {
    margin-right: -672px;
  }

  .xl\:tw--mb-672px {
    margin-bottom: -672px;
  }

  .xl\:tw--ml-672px {
    margin-left: -672px;
  }

  .xl\:tw-max-h-full {
    max-height: 100%;
  }

  .xl\:tw-max-h-screen {
    max-height: 100vh;
  }

  .xl\:tw-max-w-none {
    max-width: none;
  }

  .xl\:tw-max-w-xs {
    max-width: 20rem;
  }

  .xl\:tw-max-w-sm {
    max-width: 24rem;
  }

  .xl\:tw-max-w-md {
    max-width: 28rem;
  }

  .xl\:tw-max-w-lg {
    max-width: 32rem;
  }

  .xl\:tw-max-w-xl {
    max-width: 36rem;
  }

  .xl\:tw-max-w-2xl {
    max-width: 42rem;
  }

  .xl\:tw-max-w-3xl {
    max-width: 48rem;
  }

  .xl\:tw-max-w-4xl {
    max-width: 56rem;
  }

  .xl\:tw-max-w-5xl {
    max-width: 64rem;
  }

  .xl\:tw-max-w-6xl {
    max-width: 72rem;
  }

  .xl\:tw-max-w-full {
    max-width: 100%;
  }

  .xl\:tw-max-w-screen-sm {
    max-width: 640px;
  }

  .xl\:tw-max-w-screen-md {
    max-width: 768px;
  }

  .xl\:tw-max-w-screen-lg {
    max-width: 1024px;
  }

  .xl\:tw-max-w-screen-xl {
    max-width: 1280px;
  }

  .xl\:tw-min-h-0 {
    min-height: 0;
  }

  .xl\:tw-min-h-full {
    min-height: 100%;
  }

  .xl\:tw-min-h-screen {
    min-height: 100vh;
  }

  .xl\:tw-min-h-576px {
    min-height: 576px;
  }

  .xl\:tw-min-h-600px {
    min-height: 600px;
  }

  .xl\:tw-min-h-672px {
    min-height: 672px;
  }

  .xl\:tw-min-w-0 {
    min-width: 0;
  }

  .xl\:tw-min-w-full {
    min-width: 100%;
  }

  .xl\:tw-min-w-360px {
    min-width: 360px;
  }

  .xl\:tw-min-w-540px {
    min-width: 540px;
  }

  .xl\:tw-object-contain {
    -o-object-fit: contain;
       object-fit: contain;
  }

  .xl\:tw-object-cover {
    -o-object-fit: cover;
       object-fit: cover;
  }

  .xl\:tw-object-fill {
    -o-object-fit: fill;
       object-fit: fill;
  }

  .xl\:tw-object-none {
    -o-object-fit: none;
       object-fit: none;
  }

  .xl\:tw-object-scale-down {
    -o-object-fit: scale-down;
       object-fit: scale-down;
  }

  .xl\:tw-object-bottom {
    -o-object-position: bottom;
       object-position: bottom;
  }

  .xl\:tw-object-center {
    -o-object-position: center;
       object-position: center;
  }

  .xl\:tw-object-left {
    -o-object-position: left;
       object-position: left;
  }

  .xl\:tw-object-left-bottom {
    -o-object-position: left bottom;
       object-position: left bottom;
  }

  .xl\:tw-object-left-top {
    -o-object-position: left top;
       object-position: left top;
  }

  .xl\:tw-object-right {
    -o-object-position: right;
       object-position: right;
  }

  .xl\:tw-object-right-bottom {
    -o-object-position: right bottom;
       object-position: right bottom;
  }

  .xl\:tw-object-right-top {
    -o-object-position: right top;
       object-position: right top;
  }

  .xl\:tw-object-top {
    -o-object-position: top;
       object-position: top;
  }

  .xl\:tw-opacity-0 {
    opacity: 0;
  }

  .xl\:tw-opacity-25 {
    opacity: 0.25;
  }

  .xl\:tw-opacity-50 {
    opacity: 0.5;
  }

  .xl\:tw-opacity-75 {
    opacity: 0.75;
  }

  .xl\:tw-opacity-100 {
    opacity: 1;
  }

  .xl\:hover\:tw-opacity-0:hover {
    opacity: 0;
  }

  .xl\:hover\:tw-opacity-25:hover {
    opacity: 0.25;
  }

  .xl\:hover\:tw-opacity-50:hover {
    opacity: 0.5;
  }

  .xl\:hover\:tw-opacity-75:hover {
    opacity: 0.75;
  }

  .xl\:hover\:tw-opacity-100:hover {
    opacity: 1;
  }

  .xl\:focus\:tw-opacity-0:focus {
    opacity: 0;
  }

  .xl\:focus\:tw-opacity-25:focus {
    opacity: 0.25;
  }

  .xl\:focus\:tw-opacity-50:focus {
    opacity: 0.5;
  }

  .xl\:focus\:tw-opacity-75:focus {
    opacity: 0.75;
  }

  .xl\:focus\:tw-opacity-100:focus {
    opacity: 1;
  }

  .xl\:tw-outline-none {
    outline: 2px solid transparent;
    outline-offset: 2px;
  }

  .xl\:tw-outline-white {
    outline: 2px dotted white;
    outline-offset: 2px;
  }

  .xl\:tw-outline-black {
    outline: 2px dotted black;
    outline-offset: 2px;
  }

  .xl\:focus\:tw-outline-none:focus {
    outline: 2px solid transparent;
    outline-offset: 2px;
  }

  .xl\:focus\:tw-outline-white:focus {
    outline: 2px dotted white;
    outline-offset: 2px;
  }

  .xl\:focus\:tw-outline-black:focus {
    outline: 2px dotted black;
    outline-offset: 2px;
  }

  .xl\:tw-overflow-auto {
    overflow: auto;
  }

  .xl\:tw-overflow-hidden {
    overflow: hidden;
  }

  .xl\:tw-overflow-visible {
    overflow: visible;
  }

  .xl\:tw-overflow-scroll {
    overflow: scroll;
  }

  .xl\:tw-overflow-x-auto {
    overflow-x: auto;
  }

  .xl\:tw-overflow-y-auto {
    overflow-y: auto;
  }

  .xl\:tw-overflow-x-hidden {
    overflow-x: hidden;
  }

  .xl\:tw-overflow-y-hidden {
    overflow-y: hidden;
  }

  .xl\:tw-overflow-x-visible {
    overflow-x: visible;
  }

  .xl\:tw-overflow-y-visible {
    overflow-y: visible;
  }

  .xl\:tw-overflow-x-scroll {
    overflow-x: scroll;
  }

  .xl\:tw-overflow-y-scroll {
    overflow-y: scroll;
  }

  .xl\:tw-scrolling-touch {
    -webkit-overflow-scrolling: touch;
  }

  .xl\:tw-scrolling-auto {
    -webkit-overflow-scrolling: auto;
  }

  .xl\:tw-overscroll-auto {
    overscroll-behavior: auto;
  }

  .xl\:tw-overscroll-contain {
    overscroll-behavior: contain;
  }

  .xl\:tw-overscroll-none {
    overscroll-behavior: none;
  }

  .xl\:tw-overscroll-y-auto {
    overscroll-behavior-y: auto;
  }

  .xl\:tw-overscroll-y-contain {
    overscroll-behavior-y: contain;
  }

  .xl\:tw-overscroll-y-none {
    overscroll-behavior-y: none;
  }

  .xl\:tw-overscroll-x-auto {
    overscroll-behavior-x: auto;
  }

  .xl\:tw-overscroll-x-contain {
    overscroll-behavior-x: contain;
  }

  .xl\:tw-overscroll-x-none {
    overscroll-behavior-x: none;
  }

  .xl\:tw-p-0 {
    padding: 0;
  }

  .xl\:tw-p-1 {
    padding: 0.25rem;
  }

  .xl\:tw-p-2 {
    padding: 0.5rem;
  }

  .xl\:tw-p-3 {
    padding: 0.75rem;
  }

  .xl\:tw-p-4 {
    padding: 1rem;
  }

  .xl\:tw-p-5 {
    padding: 1.25rem;
  }

  .xl\:tw-p-6 {
    padding: 1.5rem;
  }

  .xl\:tw-p-8 {
    padding: 2rem;
  }

  .xl\:tw-p-10 {
    padding: 2.5rem;
  }

  .xl\:tw-p-12 {
    padding: 3rem;
  }

  .xl\:tw-p-16 {
    padding: 4rem;
  }

  .xl\:tw-p-20 {
    padding: 5rem;
  }

  .xl\:tw-p-24 {
    padding: 6rem;
  }

  .xl\:tw-p-32 {
    padding: 8rem;
  }

  .xl\:tw-p-40 {
    padding: 10rem;
  }

  .xl\:tw-p-48 {
    padding: 12rem;
  }

  .xl\:tw-p-56 {
    padding: 14rem;
  }

  .xl\:tw-p-64 {
    padding: 16rem;
  }

  .xl\:tw-p-px {
    padding: 1px;
  }

  .xl\:tw-p-270px {
    padding: 270px;
  }

  .xl\:tw-p-400px {
    padding: 400px;
  }

  .xl\:tw-p-464px {
    padding: 464px;
  }

  .xl\:tw-p-480px {
    padding: 480px;
  }

  .xl\:tw-p-500px {
    padding: 500px;
  }

  .xl\:tw-p-576px {
    padding: 576px;
  }

  .xl\:tw-p-600px {
    padding: 600px;
  }

  .xl\:tw-p-672px {
    padding: 672px;
  }

  .xl\:tw-py-0 {
    padding-top: 0;
    padding-bottom: 0;
  }

  .xl\:tw-px-0 {
    padding-left: 0;
    padding-right: 0;
  }

  .xl\:tw-py-1 {
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;
  }

  .xl\:tw-px-1 {
    padding-left: 0.25rem;
    padding-right: 0.25rem;
  }

  .xl\:tw-py-2 {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
  }

  .xl\:tw-px-2 {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }

  .xl\:tw-py-3 {
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
  }

  .xl\:tw-px-3 {
    padding-left: 0.75rem;
    padding-right: 0.75rem;
  }

  .xl\:tw-py-4 {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }

  .xl\:tw-px-4 {
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .xl\:tw-py-5 {
    padding-top: 1.25rem;
    padding-bottom: 1.25rem;
  }

  .xl\:tw-px-5 {
    padding-left: 1.25rem;
    padding-right: 1.25rem;
  }

  .xl\:tw-py-6 {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
  }

  .xl\:tw-px-6 {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }

  .xl\:tw-py-8 {
    padding-top: 2rem;
    padding-bottom: 2rem;
  }

  .xl\:tw-px-8 {
    padding-left: 2rem;
    padding-right: 2rem;
  }

  .xl\:tw-py-10 {
    padding-top: 2.5rem;
    padding-bottom: 2.5rem;
  }

  .xl\:tw-px-10 {
    padding-left: 2.5rem;
    padding-right: 2.5rem;
  }

  .xl\:tw-py-12 {
    padding-top: 3rem;
    padding-bottom: 3rem;
  }

  .xl\:tw-px-12 {
    padding-left: 3rem;
    padding-right: 3rem;
  }

  .xl\:tw-py-16 {
    padding-top: 4rem;
    padding-bottom: 4rem;
  }

  .xl\:tw-px-16 {
    padding-left: 4rem;
    padding-right: 4rem;
  }

  .xl\:tw-py-20 {
    padding-top: 5rem;
    padding-bottom: 5rem;
  }

  .xl\:tw-px-20 {
    padding-left: 5rem;
    padding-right: 5rem;
  }

  .xl\:tw-py-24 {
    padding-top: 6rem;
    padding-bottom: 6rem;
  }

  .xl\:tw-px-24 {
    padding-left: 6rem;
    padding-right: 6rem;
  }

  .xl\:tw-py-32 {
    padding-top: 8rem;
    padding-bottom: 8rem;
  }

  .xl\:tw-px-32 {
    padding-left: 8rem;
    padding-right: 8rem;
  }

  .xl\:tw-py-40 {
    padding-top: 10rem;
    padding-bottom: 10rem;
  }

  .xl\:tw-px-40 {
    padding-left: 10rem;
    padding-right: 10rem;
  }

  .xl\:tw-py-48 {
    padding-top: 12rem;
    padding-bottom: 12rem;
  }

  .xl\:tw-px-48 {
    padding-left: 12rem;
    padding-right: 12rem;
  }

  .xl\:tw-py-56 {
    padding-top: 14rem;
    padding-bottom: 14rem;
  }

  .xl\:tw-px-56 {
    padding-left: 14rem;
    padding-right: 14rem;
  }

  .xl\:tw-py-64 {
    padding-top: 16rem;
    padding-bottom: 16rem;
  }

  .xl\:tw-px-64 {
    padding-left: 16rem;
    padding-right: 16rem;
  }

  .xl\:tw-py-px {
    padding-top: 1px;
    padding-bottom: 1px;
  }

  .xl\:tw-px-px {
    padding-left: 1px;
    padding-right: 1px;
  }

  .xl\:tw-py-270px {
    padding-top: 270px;
    padding-bottom: 270px;
  }

  .xl\:tw-px-270px {
    padding-left: 270px;
    padding-right: 270px;
  }

  .xl\:tw-py-400px {
    padding-top: 400px;
    padding-bottom: 400px;
  }

  .xl\:tw-px-400px {
    padding-left: 400px;
    padding-right: 400px;
  }

  .xl\:tw-py-464px {
    padding-top: 464px;
    padding-bottom: 464px;
  }

  .xl\:tw-px-464px {
    padding-left: 464px;
    padding-right: 464px;
  }

  .xl\:tw-py-480px {
    padding-top: 480px;
    padding-bottom: 480px;
  }

  .xl\:tw-px-480px {
    padding-left: 480px;
    padding-right: 480px;
  }

  .xl\:tw-py-500px {
    padding-top: 500px;
    padding-bottom: 500px;
  }

  .xl\:tw-px-500px {
    padding-left: 500px;
    padding-right: 500px;
  }

  .xl\:tw-py-576px {
    padding-top: 576px;
    padding-bottom: 576px;
  }

  .xl\:tw-px-576px {
    padding-left: 576px;
    padding-right: 576px;
  }

  .xl\:tw-py-600px {
    padding-top: 600px;
    padding-bottom: 600px;
  }

  .xl\:tw-px-600px {
    padding-left: 600px;
    padding-right: 600px;
  }

  .xl\:tw-py-672px {
    padding-top: 672px;
    padding-bottom: 672px;
  }

  .xl\:tw-px-672px {
    padding-left: 672px;
    padding-right: 672px;
  }

  .xl\:tw-pt-0 {
    padding-top: 0;
  }

  .xl\:tw-pr-0 {
    padding-right: 0;
  }

  .xl\:tw-pb-0 {
    padding-bottom: 0;
  }

  .xl\:tw-pl-0 {
    padding-left: 0;
  }

  .xl\:tw-pt-1 {
    padding-top: 0.25rem;
  }

  .xl\:tw-pr-1 {
    padding-right: 0.25rem;
  }

  .xl\:tw-pb-1 {
    padding-bottom: 0.25rem;
  }

  .xl\:tw-pl-1 {
    padding-left: 0.25rem;
  }

  .xl\:tw-pt-2 {
    padding-top: 0.5rem;
  }

  .xl\:tw-pr-2 {
    padding-right: 0.5rem;
  }

  .xl\:tw-pb-2 {
    padding-bottom: 0.5rem;
  }

  .xl\:tw-pl-2 {
    padding-left: 0.5rem;
  }

  .xl\:tw-pt-3 {
    padding-top: 0.75rem;
  }

  .xl\:tw-pr-3 {
    padding-right: 0.75rem;
  }

  .xl\:tw-pb-3 {
    padding-bottom: 0.75rem;
  }

  .xl\:tw-pl-3 {
    padding-left: 0.75rem;
  }

  .xl\:tw-pt-4 {
    padding-top: 1rem;
  }

  .xl\:tw-pr-4 {
    padding-right: 1rem;
  }

  .xl\:tw-pb-4 {
    padding-bottom: 1rem;
  }

  .xl\:tw-pl-4 {
    padding-left: 1rem;
  }

  .xl\:tw-pt-5 {
    padding-top: 1.25rem;
  }

  .xl\:tw-pr-5 {
    padding-right: 1.25rem;
  }

  .xl\:tw-pb-5 {
    padding-bottom: 1.25rem;
  }

  .xl\:tw-pl-5 {
    padding-left: 1.25rem;
  }

  .xl\:tw-pt-6 {
    padding-top: 1.5rem;
  }

  .xl\:tw-pr-6 {
    padding-right: 1.5rem;
  }

  .xl\:tw-pb-6 {
    padding-bottom: 1.5rem;
  }

  .xl\:tw-pl-6 {
    padding-left: 1.5rem;
  }

  .xl\:tw-pt-8 {
    padding-top: 2rem;
  }

  .xl\:tw-pr-8 {
    padding-right: 2rem;
  }

  .xl\:tw-pb-8 {
    padding-bottom: 2rem;
  }

  .xl\:tw-pl-8 {
    padding-left: 2rem;
  }

  .xl\:tw-pt-10 {
    padding-top: 2.5rem;
  }

  .xl\:tw-pr-10 {
    padding-right: 2.5rem;
  }

  .xl\:tw-pb-10 {
    padding-bottom: 2.5rem;
  }

  .xl\:tw-pl-10 {
    padding-left: 2.5rem;
  }

  .xl\:tw-pt-12 {
    padding-top: 3rem;
  }

  .xl\:tw-pr-12 {
    padding-right: 3rem;
  }

  .xl\:tw-pb-12 {
    padding-bottom: 3rem;
  }

  .xl\:tw-pl-12 {
    padding-left: 3rem;
  }

  .xl\:tw-pt-16 {
    padding-top: 4rem;
  }

  .xl\:tw-pr-16 {
    padding-right: 4rem;
  }

  .xl\:tw-pb-16 {
    padding-bottom: 4rem;
  }

  .xl\:tw-pl-16 {
    padding-left: 4rem;
  }

  .xl\:tw-pt-20 {
    padding-top: 5rem;
  }

  .xl\:tw-pr-20 {
    padding-right: 5rem;
  }

  .xl\:tw-pb-20 {
    padding-bottom: 5rem;
  }

  .xl\:tw-pl-20 {
    padding-left: 5rem;
  }

  .xl\:tw-pt-24 {
    padding-top: 6rem;
  }

  .xl\:tw-pr-24 {
    padding-right: 6rem;
  }

  .xl\:tw-pb-24 {
    padding-bottom: 6rem;
  }

  .xl\:tw-pl-24 {
    padding-left: 6rem;
  }

  .xl\:tw-pt-32 {
    padding-top: 8rem;
  }

  .xl\:tw-pr-32 {
    padding-right: 8rem;
  }

  .xl\:tw-pb-32 {
    padding-bottom: 8rem;
  }

  .xl\:tw-pl-32 {
    padding-left: 8rem;
  }

  .xl\:tw-pt-40 {
    padding-top: 10rem;
  }

  .xl\:tw-pr-40 {
    padding-right: 10rem;
  }

  .xl\:tw-pb-40 {
    padding-bottom: 10rem;
  }

  .xl\:tw-pl-40 {
    padding-left: 10rem;
  }

  .xl\:tw-pt-48 {
    padding-top: 12rem;
  }

  .xl\:tw-pr-48 {
    padding-right: 12rem;
  }

  .xl\:tw-pb-48 {
    padding-bottom: 12rem;
  }

  .xl\:tw-pl-48 {
    padding-left: 12rem;
  }

  .xl\:tw-pt-56 {
    padding-top: 14rem;
  }

  .xl\:tw-pr-56 {
    padding-right: 14rem;
  }

  .xl\:tw-pb-56 {
    padding-bottom: 14rem;
  }

  .xl\:tw-pl-56 {
    padding-left: 14rem;
  }

  .xl\:tw-pt-64 {
    padding-top: 16rem;
  }

  .xl\:tw-pr-64 {
    padding-right: 16rem;
  }

  .xl\:tw-pb-64 {
    padding-bottom: 16rem;
  }

  .xl\:tw-pl-64 {
    padding-left: 16rem;
  }

  .xl\:tw-pt-px {
    padding-top: 1px;
  }

  .xl\:tw-pr-px {
    padding-right: 1px;
  }

  .xl\:tw-pb-px {
    padding-bottom: 1px;
  }

  .xl\:tw-pl-px {
    padding-left: 1px;
  }

  .xl\:tw-pt-270px {
    padding-top: 270px;
  }

  .xl\:tw-pr-270px {
    padding-right: 270px;
  }

  .xl\:tw-pb-270px {
    padding-bottom: 270px;
  }

  .xl\:tw-pl-270px {
    padding-left: 270px;
  }

  .xl\:tw-pt-400px {
    padding-top: 400px;
  }

  .xl\:tw-pr-400px {
    padding-right: 400px;
  }

  .xl\:tw-pb-400px {
    padding-bottom: 400px;
  }

  .xl\:tw-pl-400px {
    padding-left: 400px;
  }

  .xl\:tw-pt-464px {
    padding-top: 464px;
  }

  .xl\:tw-pr-464px {
    padding-right: 464px;
  }

  .xl\:tw-pb-464px {
    padding-bottom: 464px;
  }

  .xl\:tw-pl-464px {
    padding-left: 464px;
  }

  .xl\:tw-pt-480px {
    padding-top: 480px;
  }

  .xl\:tw-pr-480px {
    padding-right: 480px;
  }

  .xl\:tw-pb-480px {
    padding-bottom: 480px;
  }

  .xl\:tw-pl-480px {
    padding-left: 480px;
  }

  .xl\:tw-pt-500px {
    padding-top: 500px;
  }

  .xl\:tw-pr-500px {
    padding-right: 500px;
  }

  .xl\:tw-pb-500px {
    padding-bottom: 500px;
  }

  .xl\:tw-pl-500px {
    padding-left: 500px;
  }

  .xl\:tw-pt-576px {
    padding-top: 576px;
  }

  .xl\:tw-pr-576px {
    padding-right: 576px;
  }

  .xl\:tw-pb-576px {
    padding-bottom: 576px;
  }

  .xl\:tw-pl-576px {
    padding-left: 576px;
  }

  .xl\:tw-pt-600px {
    padding-top: 600px;
  }

  .xl\:tw-pr-600px {
    padding-right: 600px;
  }

  .xl\:tw-pb-600px {
    padding-bottom: 600px;
  }

  .xl\:tw-pl-600px {
    padding-left: 600px;
  }

  .xl\:tw-pt-672px {
    padding-top: 672px;
  }

  .xl\:tw-pr-672px {
    padding-right: 672px;
  }

  .xl\:tw-pb-672px {
    padding-bottom: 672px;
  }

  .xl\:tw-pl-672px {
    padding-left: 672px;
  }

  .xl\:tw-placeholder-transparent::-moz-placeholder {
    color: transparent;
  }

  .xl\:tw-placeholder-transparent::placeholder {
    color: transparent;
  }

  .xl\:tw-placeholder-current::-moz-placeholder {
    color: currentColor;
  }

  .xl\:tw-placeholder-current::placeholder {
    color: currentColor;
  }

  .xl\:tw-placeholder-black::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #000;
    color: rgba(0, 0, 0, var(--placeholder-opacity));
  }

  .xl\:tw-placeholder-black::placeholder {
    --placeholder-opacity: 1;
    color: #000;
    color: rgba(0, 0, 0, var(--placeholder-opacity));
  }

  .xl\:tw-placeholder-white::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #fff;
    color: rgba(255, 255, 255, var(--placeholder-opacity));
  }

  .xl\:tw-placeholder-white::placeholder {
    --placeholder-opacity: 1;
    color: #fff;
    color: rgba(255, 255, 255, var(--placeholder-opacity));
  }

  .xl\:tw-placeholder-gray-100::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #f7fafc;
    color: rgba(247, 250, 252, var(--placeholder-opacity));
  }

  .xl\:tw-placeholder-gray-100::placeholder {
    --placeholder-opacity: 1;
    color: #f7fafc;
    color: rgba(247, 250, 252, var(--placeholder-opacity));
  }

  .xl\:tw-placeholder-gray-200::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #edf2f7;
    color: rgba(237, 242, 247, var(--placeholder-opacity));
  }

  .xl\:tw-placeholder-gray-200::placeholder {
    --placeholder-opacity: 1;
    color: #edf2f7;
    color: rgba(237, 242, 247, var(--placeholder-opacity));
  }

  .xl\:tw-placeholder-gray-300::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #e2e8f0;
    color: rgba(226, 232, 240, var(--placeholder-opacity));
  }

  .xl\:tw-placeholder-gray-300::placeholder {
    --placeholder-opacity: 1;
    color: #e2e8f0;
    color: rgba(226, 232, 240, var(--placeholder-opacity));
  }

  .xl\:tw-placeholder-gray-400::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #cbd5e0;
    color: rgba(203, 213, 224, var(--placeholder-opacity));
  }

  .xl\:tw-placeholder-gray-400::placeholder {
    --placeholder-opacity: 1;
    color: #cbd5e0;
    color: rgba(203, 213, 224, var(--placeholder-opacity));
  }

  .xl\:tw-placeholder-gray-500::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #a0aec0;
    color: rgba(160, 174, 192, var(--placeholder-opacity));
  }

  .xl\:tw-placeholder-gray-500::placeholder {
    --placeholder-opacity: 1;
    color: #a0aec0;
    color: rgba(160, 174, 192, var(--placeholder-opacity));
  }

  .xl\:tw-placeholder-gray-600::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #718096;
    color: rgba(113, 128, 150, var(--placeholder-opacity));
  }

  .xl\:tw-placeholder-gray-600::placeholder {
    --placeholder-opacity: 1;
    color: #718096;
    color: rgba(113, 128, 150, var(--placeholder-opacity));
  }

  .xl\:tw-placeholder-gray-700::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #4a5568;
    color: rgba(74, 85, 104, var(--placeholder-opacity));
  }

  .xl\:tw-placeholder-gray-700::placeholder {
    --placeholder-opacity: 1;
    color: #4a5568;
    color: rgba(74, 85, 104, var(--placeholder-opacity));
  }

  .xl\:tw-placeholder-gray-800::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #2d3748;
    color: rgba(45, 55, 72, var(--placeholder-opacity));
  }

  .xl\:tw-placeholder-gray-800::placeholder {
    --placeholder-opacity: 1;
    color: #2d3748;
    color: rgba(45, 55, 72, var(--placeholder-opacity));
  }

  .xl\:tw-placeholder-gray-900::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #1a202c;
    color: rgba(26, 32, 44, var(--placeholder-opacity));
  }

  .xl\:tw-placeholder-gray-900::placeholder {
    --placeholder-opacity: 1;
    color: #1a202c;
    color: rgba(26, 32, 44, var(--placeholder-opacity));
  }

  .xl\:tw-placeholder-red-100::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #fff5f5;
    color: rgba(255, 245, 245, var(--placeholder-opacity));
  }

  .xl\:tw-placeholder-red-100::placeholder {
    --placeholder-opacity: 1;
    color: #fff5f5;
    color: rgba(255, 245, 245, var(--placeholder-opacity));
  }

  .xl\:tw-placeholder-red-200::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #fed7d7;
    color: rgba(254, 215, 215, var(--placeholder-opacity));
  }

  .xl\:tw-placeholder-red-200::placeholder {
    --placeholder-opacity: 1;
    color: #fed7d7;
    color: rgba(254, 215, 215, var(--placeholder-opacity));
  }

  .xl\:tw-placeholder-red-300::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #feb2b2;
    color: rgba(254, 178, 178, var(--placeholder-opacity));
  }

  .xl\:tw-placeholder-red-300::placeholder {
    --placeholder-opacity: 1;
    color: #feb2b2;
    color: rgba(254, 178, 178, var(--placeholder-opacity));
  }

  .xl\:tw-placeholder-red-400::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #fc8181;
    color: rgba(252, 129, 129, var(--placeholder-opacity));
  }

  .xl\:tw-placeholder-red-400::placeholder {
    --placeholder-opacity: 1;
    color: #fc8181;
    color: rgba(252, 129, 129, var(--placeholder-opacity));
  }

  .xl\:tw-placeholder-red-500::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #f56565;
    color: rgba(245, 101, 101, var(--placeholder-opacity));
  }

  .xl\:tw-placeholder-red-500::placeholder {
    --placeholder-opacity: 1;
    color: #f56565;
    color: rgba(245, 101, 101, var(--placeholder-opacity));
  }

  .xl\:tw-placeholder-red-600::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #e53e3e;
    color: rgba(229, 62, 62, var(--placeholder-opacity));
  }

  .xl\:tw-placeholder-red-600::placeholder {
    --placeholder-opacity: 1;
    color: #e53e3e;
    color: rgba(229, 62, 62, var(--placeholder-opacity));
  }

  .xl\:tw-placeholder-red-700::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #c53030;
    color: rgba(197, 48, 48, var(--placeholder-opacity));
  }

  .xl\:tw-placeholder-red-700::placeholder {
    --placeholder-opacity: 1;
    color: #c53030;
    color: rgba(197, 48, 48, var(--placeholder-opacity));
  }

  .xl\:tw-placeholder-red-800::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #9b2c2c;
    color: rgba(155, 44, 44, var(--placeholder-opacity));
  }

  .xl\:tw-placeholder-red-800::placeholder {
    --placeholder-opacity: 1;
    color: #9b2c2c;
    color: rgba(155, 44, 44, var(--placeholder-opacity));
  }

  .xl\:tw-placeholder-red-900::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #742a2a;
    color: rgba(116, 42, 42, var(--placeholder-opacity));
  }

  .xl\:tw-placeholder-red-900::placeholder {
    --placeholder-opacity: 1;
    color: #742a2a;
    color: rgba(116, 42, 42, var(--placeholder-opacity));
  }

  .xl\:tw-placeholder-orange-100::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #fffaf0;
    color: rgba(255, 250, 240, var(--placeholder-opacity));
  }

  .xl\:tw-placeholder-orange-100::placeholder {
    --placeholder-opacity: 1;
    color: #fffaf0;
    color: rgba(255, 250, 240, var(--placeholder-opacity));
  }

  .xl\:tw-placeholder-orange-200::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #feebc8;
    color: rgba(254, 235, 200, var(--placeholder-opacity));
  }

  .xl\:tw-placeholder-orange-200::placeholder {
    --placeholder-opacity: 1;
    color: #feebc8;
    color: rgba(254, 235, 200, var(--placeholder-opacity));
  }

  .xl\:tw-placeholder-orange-300::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #fbd38d;
    color: rgba(251, 211, 141, var(--placeholder-opacity));
  }

  .xl\:tw-placeholder-orange-300::placeholder {
    --placeholder-opacity: 1;
    color: #fbd38d;
    color: rgba(251, 211, 141, var(--placeholder-opacity));
  }

  .xl\:tw-placeholder-orange-400::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #f6ad55;
    color: rgba(246, 173, 85, var(--placeholder-opacity));
  }

  .xl\:tw-placeholder-orange-400::placeholder {
    --placeholder-opacity: 1;
    color: #f6ad55;
    color: rgba(246, 173, 85, var(--placeholder-opacity));
  }

  .xl\:tw-placeholder-orange-500::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #ed8936;
    color: rgba(237, 137, 54, var(--placeholder-opacity));
  }

  .xl\:tw-placeholder-orange-500::placeholder {
    --placeholder-opacity: 1;
    color: #ed8936;
    color: rgba(237, 137, 54, var(--placeholder-opacity));
  }

  .xl\:tw-placeholder-orange-600::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #dd6b20;
    color: rgba(221, 107, 32, var(--placeholder-opacity));
  }

  .xl\:tw-placeholder-orange-600::placeholder {
    --placeholder-opacity: 1;
    color: #dd6b20;
    color: rgba(221, 107, 32, var(--placeholder-opacity));
  }

  .xl\:tw-placeholder-orange-700::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #c05621;
    color: rgba(192, 86, 33, var(--placeholder-opacity));
  }

  .xl\:tw-placeholder-orange-700::placeholder {
    --placeholder-opacity: 1;
    color: #c05621;
    color: rgba(192, 86, 33, var(--placeholder-opacity));
  }

  .xl\:tw-placeholder-orange-800::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #9c4221;
    color: rgba(156, 66, 33, var(--placeholder-opacity));
  }

  .xl\:tw-placeholder-orange-800::placeholder {
    --placeholder-opacity: 1;
    color: #9c4221;
    color: rgba(156, 66, 33, var(--placeholder-opacity));
  }

  .xl\:tw-placeholder-orange-900::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #7b341e;
    color: rgba(123, 52, 30, var(--placeholder-opacity));
  }

  .xl\:tw-placeholder-orange-900::placeholder {
    --placeholder-opacity: 1;
    color: #7b341e;
    color: rgba(123, 52, 30, var(--placeholder-opacity));
  }

  .xl\:tw-placeholder-yellow-100::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #fffff0;
    color: rgba(255, 255, 240, var(--placeholder-opacity));
  }

  .xl\:tw-placeholder-yellow-100::placeholder {
    --placeholder-opacity: 1;
    color: #fffff0;
    color: rgba(255, 255, 240, var(--placeholder-opacity));
  }

  .xl\:tw-placeholder-yellow-200::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #fefcbf;
    color: rgba(254, 252, 191, var(--placeholder-opacity));
  }

  .xl\:tw-placeholder-yellow-200::placeholder {
    --placeholder-opacity: 1;
    color: #fefcbf;
    color: rgba(254, 252, 191, var(--placeholder-opacity));
  }

  .xl\:tw-placeholder-yellow-300::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #faf089;
    color: rgba(250, 240, 137, var(--placeholder-opacity));
  }

  .xl\:tw-placeholder-yellow-300::placeholder {
    --placeholder-opacity: 1;
    color: #faf089;
    color: rgba(250, 240, 137, var(--placeholder-opacity));
  }

  .xl\:tw-placeholder-yellow-400::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #f6e05e;
    color: rgba(246, 224, 94, var(--placeholder-opacity));
  }

  .xl\:tw-placeholder-yellow-400::placeholder {
    --placeholder-opacity: 1;
    color: #f6e05e;
    color: rgba(246, 224, 94, var(--placeholder-opacity));
  }

  .xl\:tw-placeholder-yellow-500::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #ecc94b;
    color: rgba(236, 201, 75, var(--placeholder-opacity));
  }

  .xl\:tw-placeholder-yellow-500::placeholder {
    --placeholder-opacity: 1;
    color: #ecc94b;
    color: rgba(236, 201, 75, var(--placeholder-opacity));
  }

  .xl\:tw-placeholder-yellow-600::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #d69e2e;
    color: rgba(214, 158, 46, var(--placeholder-opacity));
  }

  .xl\:tw-placeholder-yellow-600::placeholder {
    --placeholder-opacity: 1;
    color: #d69e2e;
    color: rgba(214, 158, 46, var(--placeholder-opacity));
  }

  .xl\:tw-placeholder-yellow-700::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #b7791f;
    color: rgba(183, 121, 31, var(--placeholder-opacity));
  }

  .xl\:tw-placeholder-yellow-700::placeholder {
    --placeholder-opacity: 1;
    color: #b7791f;
    color: rgba(183, 121, 31, var(--placeholder-opacity));
  }

  .xl\:tw-placeholder-yellow-800::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #975a16;
    color: rgba(151, 90, 22, var(--placeholder-opacity));
  }

  .xl\:tw-placeholder-yellow-800::placeholder {
    --placeholder-opacity: 1;
    color: #975a16;
    color: rgba(151, 90, 22, var(--placeholder-opacity));
  }

  .xl\:tw-placeholder-yellow-900::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #744210;
    color: rgba(116, 66, 16, var(--placeholder-opacity));
  }

  .xl\:tw-placeholder-yellow-900::placeholder {
    --placeholder-opacity: 1;
    color: #744210;
    color: rgba(116, 66, 16, var(--placeholder-opacity));
  }

  .xl\:tw-placeholder-green-100::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #f0fff4;
    color: rgba(240, 255, 244, var(--placeholder-opacity));
  }

  .xl\:tw-placeholder-green-100::placeholder {
    --placeholder-opacity: 1;
    color: #f0fff4;
    color: rgba(240, 255, 244, var(--placeholder-opacity));
  }

  .xl\:tw-placeholder-green-200::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #c6f6d5;
    color: rgba(198, 246, 213, var(--placeholder-opacity));
  }

  .xl\:tw-placeholder-green-200::placeholder {
    --placeholder-opacity: 1;
    color: #c6f6d5;
    color: rgba(198, 246, 213, var(--placeholder-opacity));
  }

  .xl\:tw-placeholder-green-300::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #9ae6b4;
    color: rgba(154, 230, 180, var(--placeholder-opacity));
  }

  .xl\:tw-placeholder-green-300::placeholder {
    --placeholder-opacity: 1;
    color: #9ae6b4;
    color: rgba(154, 230, 180, var(--placeholder-opacity));
  }

  .xl\:tw-placeholder-green-400::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #68d391;
    color: rgba(104, 211, 145, var(--placeholder-opacity));
  }

  .xl\:tw-placeholder-green-400::placeholder {
    --placeholder-opacity: 1;
    color: #68d391;
    color: rgba(104, 211, 145, var(--placeholder-opacity));
  }

  .xl\:tw-placeholder-green-500::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #48bb78;
    color: rgba(72, 187, 120, var(--placeholder-opacity));
  }

  .xl\:tw-placeholder-green-500::placeholder {
    --placeholder-opacity: 1;
    color: #48bb78;
    color: rgba(72, 187, 120, var(--placeholder-opacity));
  }

  .xl\:tw-placeholder-green-600::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #38a169;
    color: rgba(56, 161, 105, var(--placeholder-opacity));
  }

  .xl\:tw-placeholder-green-600::placeholder {
    --placeholder-opacity: 1;
    color: #38a169;
    color: rgba(56, 161, 105, var(--placeholder-opacity));
  }

  .xl\:tw-placeholder-green-700::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #2f855a;
    color: rgba(47, 133, 90, var(--placeholder-opacity));
  }

  .xl\:tw-placeholder-green-700::placeholder {
    --placeholder-opacity: 1;
    color: #2f855a;
    color: rgba(47, 133, 90, var(--placeholder-opacity));
  }

  .xl\:tw-placeholder-green-800::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #276749;
    color: rgba(39, 103, 73, var(--placeholder-opacity));
  }

  .xl\:tw-placeholder-green-800::placeholder {
    --placeholder-opacity: 1;
    color: #276749;
    color: rgba(39, 103, 73, var(--placeholder-opacity));
  }

  .xl\:tw-placeholder-green-900::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #22543d;
    color: rgba(34, 84, 61, var(--placeholder-opacity));
  }

  .xl\:tw-placeholder-green-900::placeholder {
    --placeholder-opacity: 1;
    color: #22543d;
    color: rgba(34, 84, 61, var(--placeholder-opacity));
  }

  .xl\:tw-placeholder-teal-100::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #e6fffa;
    color: rgba(230, 255, 250, var(--placeholder-opacity));
  }

  .xl\:tw-placeholder-teal-100::placeholder {
    --placeholder-opacity: 1;
    color: #e6fffa;
    color: rgba(230, 255, 250, var(--placeholder-opacity));
  }

  .xl\:tw-placeholder-teal-200::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #b2f5ea;
    color: rgba(178, 245, 234, var(--placeholder-opacity));
  }

  .xl\:tw-placeholder-teal-200::placeholder {
    --placeholder-opacity: 1;
    color: #b2f5ea;
    color: rgba(178, 245, 234, var(--placeholder-opacity));
  }

  .xl\:tw-placeholder-teal-300::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #81e6d9;
    color: rgba(129, 230, 217, var(--placeholder-opacity));
  }

  .xl\:tw-placeholder-teal-300::placeholder {
    --placeholder-opacity: 1;
    color: #81e6d9;
    color: rgba(129, 230, 217, var(--placeholder-opacity));
  }

  .xl\:tw-placeholder-teal-400::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #4fd1c5;
    color: rgba(79, 209, 197, var(--placeholder-opacity));
  }

  .xl\:tw-placeholder-teal-400::placeholder {
    --placeholder-opacity: 1;
    color: #4fd1c5;
    color: rgba(79, 209, 197, var(--placeholder-opacity));
  }

  .xl\:tw-placeholder-teal-500::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #38b2ac;
    color: rgba(56, 178, 172, var(--placeholder-opacity));
  }

  .xl\:tw-placeholder-teal-500::placeholder {
    --placeholder-opacity: 1;
    color: #38b2ac;
    color: rgba(56, 178, 172, var(--placeholder-opacity));
  }

  .xl\:tw-placeholder-teal-600::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #319795;
    color: rgba(49, 151, 149, var(--placeholder-opacity));
  }

  .xl\:tw-placeholder-teal-600::placeholder {
    --placeholder-opacity: 1;
    color: #319795;
    color: rgba(49, 151, 149, var(--placeholder-opacity));
  }

  .xl\:tw-placeholder-teal-700::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #2c7a7b;
    color: rgba(44, 122, 123, var(--placeholder-opacity));
  }

  .xl\:tw-placeholder-teal-700::placeholder {
    --placeholder-opacity: 1;
    color: #2c7a7b;
    color: rgba(44, 122, 123, var(--placeholder-opacity));
  }

  .xl\:tw-placeholder-teal-800::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #285e61;
    color: rgba(40, 94, 97, var(--placeholder-opacity));
  }

  .xl\:tw-placeholder-teal-800::placeholder {
    --placeholder-opacity: 1;
    color: #285e61;
    color: rgba(40, 94, 97, var(--placeholder-opacity));
  }

  .xl\:tw-placeholder-teal-900::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #234e52;
    color: rgba(35, 78, 82, var(--placeholder-opacity));
  }

  .xl\:tw-placeholder-teal-900::placeholder {
    --placeholder-opacity: 1;
    color: #234e52;
    color: rgba(35, 78, 82, var(--placeholder-opacity));
  }

  .xl\:tw-placeholder-blue-100::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #ebf8ff;
    color: rgba(235, 248, 255, var(--placeholder-opacity));
  }

  .xl\:tw-placeholder-blue-100::placeholder {
    --placeholder-opacity: 1;
    color: #ebf8ff;
    color: rgba(235, 248, 255, var(--placeholder-opacity));
  }

  .xl\:tw-placeholder-blue-200::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #bee3f8;
    color: rgba(190, 227, 248, var(--placeholder-opacity));
  }

  .xl\:tw-placeholder-blue-200::placeholder {
    --placeholder-opacity: 1;
    color: #bee3f8;
    color: rgba(190, 227, 248, var(--placeholder-opacity));
  }

  .xl\:tw-placeholder-blue-300::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #90cdf4;
    color: rgba(144, 205, 244, var(--placeholder-opacity));
  }

  .xl\:tw-placeholder-blue-300::placeholder {
    --placeholder-opacity: 1;
    color: #90cdf4;
    color: rgba(144, 205, 244, var(--placeholder-opacity));
  }

  .xl\:tw-placeholder-blue-400::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #63b3ed;
    color: rgba(99, 179, 237, var(--placeholder-opacity));
  }

  .xl\:tw-placeholder-blue-400::placeholder {
    --placeholder-opacity: 1;
    color: #63b3ed;
    color: rgba(99, 179, 237, var(--placeholder-opacity));
  }

  .xl\:tw-placeholder-blue-500::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #4299e1;
    color: rgba(66, 153, 225, var(--placeholder-opacity));
  }

  .xl\:tw-placeholder-blue-500::placeholder {
    --placeholder-opacity: 1;
    color: #4299e1;
    color: rgba(66, 153, 225, var(--placeholder-opacity));
  }

  .xl\:tw-placeholder-blue-600::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #3182ce;
    color: rgba(49, 130, 206, var(--placeholder-opacity));
  }

  .xl\:tw-placeholder-blue-600::placeholder {
    --placeholder-opacity: 1;
    color: #3182ce;
    color: rgba(49, 130, 206, var(--placeholder-opacity));
  }

  .xl\:tw-placeholder-blue-700::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #2b6cb0;
    color: rgba(43, 108, 176, var(--placeholder-opacity));
  }

  .xl\:tw-placeholder-blue-700::placeholder {
    --placeholder-opacity: 1;
    color: #2b6cb0;
    color: rgba(43, 108, 176, var(--placeholder-opacity));
  }

  .xl\:tw-placeholder-blue-800::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #2c5282;
    color: rgba(44, 82, 130, var(--placeholder-opacity));
  }

  .xl\:tw-placeholder-blue-800::placeholder {
    --placeholder-opacity: 1;
    color: #2c5282;
    color: rgba(44, 82, 130, var(--placeholder-opacity));
  }

  .xl\:tw-placeholder-blue-900::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #2a4365;
    color: rgba(42, 67, 101, var(--placeholder-opacity));
  }

  .xl\:tw-placeholder-blue-900::placeholder {
    --placeholder-opacity: 1;
    color: #2a4365;
    color: rgba(42, 67, 101, var(--placeholder-opacity));
  }

  .xl\:tw-placeholder-indigo-100::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #ebf4ff;
    color: rgba(235, 244, 255, var(--placeholder-opacity));
  }

  .xl\:tw-placeholder-indigo-100::placeholder {
    --placeholder-opacity: 1;
    color: #ebf4ff;
    color: rgba(235, 244, 255, var(--placeholder-opacity));
  }

  .xl\:tw-placeholder-indigo-200::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #c3dafe;
    color: rgba(195, 218, 254, var(--placeholder-opacity));
  }

  .xl\:tw-placeholder-indigo-200::placeholder {
    --placeholder-opacity: 1;
    color: #c3dafe;
    color: rgba(195, 218, 254, var(--placeholder-opacity));
  }

  .xl\:tw-placeholder-indigo-300::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #a3bffa;
    color: rgba(163, 191, 250, var(--placeholder-opacity));
  }

  .xl\:tw-placeholder-indigo-300::placeholder {
    --placeholder-opacity: 1;
    color: #a3bffa;
    color: rgba(163, 191, 250, var(--placeholder-opacity));
  }

  .xl\:tw-placeholder-indigo-400::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #7f9cf5;
    color: rgba(127, 156, 245, var(--placeholder-opacity));
  }

  .xl\:tw-placeholder-indigo-400::placeholder {
    --placeholder-opacity: 1;
    color: #7f9cf5;
    color: rgba(127, 156, 245, var(--placeholder-opacity));
  }

  .xl\:tw-placeholder-indigo-500::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #667eea;
    color: rgba(102, 126, 234, var(--placeholder-opacity));
  }

  .xl\:tw-placeholder-indigo-500::placeholder {
    --placeholder-opacity: 1;
    color: #667eea;
    color: rgba(102, 126, 234, var(--placeholder-opacity));
  }

  .xl\:tw-placeholder-indigo-600::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #5a67d8;
    color: rgba(90, 103, 216, var(--placeholder-opacity));
  }

  .xl\:tw-placeholder-indigo-600::placeholder {
    --placeholder-opacity: 1;
    color: #5a67d8;
    color: rgba(90, 103, 216, var(--placeholder-opacity));
  }

  .xl\:tw-placeholder-indigo-700::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #4c51bf;
    color: rgba(76, 81, 191, var(--placeholder-opacity));
  }

  .xl\:tw-placeholder-indigo-700::placeholder {
    --placeholder-opacity: 1;
    color: #4c51bf;
    color: rgba(76, 81, 191, var(--placeholder-opacity));
  }

  .xl\:tw-placeholder-indigo-800::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #434190;
    color: rgba(67, 65, 144, var(--placeholder-opacity));
  }

  .xl\:tw-placeholder-indigo-800::placeholder {
    --placeholder-opacity: 1;
    color: #434190;
    color: rgba(67, 65, 144, var(--placeholder-opacity));
  }

  .xl\:tw-placeholder-indigo-900::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #3c366b;
    color: rgba(60, 54, 107, var(--placeholder-opacity));
  }

  .xl\:tw-placeholder-indigo-900::placeholder {
    --placeholder-opacity: 1;
    color: #3c366b;
    color: rgba(60, 54, 107, var(--placeholder-opacity));
  }

  .xl\:tw-placeholder-purple-100::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #faf5ff;
    color: rgba(250, 245, 255, var(--placeholder-opacity));
  }

  .xl\:tw-placeholder-purple-100::placeholder {
    --placeholder-opacity: 1;
    color: #faf5ff;
    color: rgba(250, 245, 255, var(--placeholder-opacity));
  }

  .xl\:tw-placeholder-purple-200::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #e9d8fd;
    color: rgba(233, 216, 253, var(--placeholder-opacity));
  }

  .xl\:tw-placeholder-purple-200::placeholder {
    --placeholder-opacity: 1;
    color: #e9d8fd;
    color: rgba(233, 216, 253, var(--placeholder-opacity));
  }

  .xl\:tw-placeholder-purple-300::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #d6bcfa;
    color: rgba(214, 188, 250, var(--placeholder-opacity));
  }

  .xl\:tw-placeholder-purple-300::placeholder {
    --placeholder-opacity: 1;
    color: #d6bcfa;
    color: rgba(214, 188, 250, var(--placeholder-opacity));
  }

  .xl\:tw-placeholder-purple-400::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #b794f4;
    color: rgba(183, 148, 244, var(--placeholder-opacity));
  }

  .xl\:tw-placeholder-purple-400::placeholder {
    --placeholder-opacity: 1;
    color: #b794f4;
    color: rgba(183, 148, 244, var(--placeholder-opacity));
  }

  .xl\:tw-placeholder-purple-500::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #9f7aea;
    color: rgba(159, 122, 234, var(--placeholder-opacity));
  }

  .xl\:tw-placeholder-purple-500::placeholder {
    --placeholder-opacity: 1;
    color: #9f7aea;
    color: rgba(159, 122, 234, var(--placeholder-opacity));
  }

  .xl\:tw-placeholder-purple-600::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #805ad5;
    color: rgba(128, 90, 213, var(--placeholder-opacity));
  }

  .xl\:tw-placeholder-purple-600::placeholder {
    --placeholder-opacity: 1;
    color: #805ad5;
    color: rgba(128, 90, 213, var(--placeholder-opacity));
  }

  .xl\:tw-placeholder-purple-700::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #6b46c1;
    color: rgba(107, 70, 193, var(--placeholder-opacity));
  }

  .xl\:tw-placeholder-purple-700::placeholder {
    --placeholder-opacity: 1;
    color: #6b46c1;
    color: rgba(107, 70, 193, var(--placeholder-opacity));
  }

  .xl\:tw-placeholder-purple-800::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #553c9a;
    color: rgba(85, 60, 154, var(--placeholder-opacity));
  }

  .xl\:tw-placeholder-purple-800::placeholder {
    --placeholder-opacity: 1;
    color: #553c9a;
    color: rgba(85, 60, 154, var(--placeholder-opacity));
  }

  .xl\:tw-placeholder-purple-900::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #44337a;
    color: rgba(68, 51, 122, var(--placeholder-opacity));
  }

  .xl\:tw-placeholder-purple-900::placeholder {
    --placeholder-opacity: 1;
    color: #44337a;
    color: rgba(68, 51, 122, var(--placeholder-opacity));
  }

  .xl\:tw-placeholder-pink-100::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #fff5f7;
    color: rgba(255, 245, 247, var(--placeholder-opacity));
  }

  .xl\:tw-placeholder-pink-100::placeholder {
    --placeholder-opacity: 1;
    color: #fff5f7;
    color: rgba(255, 245, 247, var(--placeholder-opacity));
  }

  .xl\:tw-placeholder-pink-200::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #fed7e2;
    color: rgba(254, 215, 226, var(--placeholder-opacity));
  }

  .xl\:tw-placeholder-pink-200::placeholder {
    --placeholder-opacity: 1;
    color: #fed7e2;
    color: rgba(254, 215, 226, var(--placeholder-opacity));
  }

  .xl\:tw-placeholder-pink-300::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #fbb6ce;
    color: rgba(251, 182, 206, var(--placeholder-opacity));
  }

  .xl\:tw-placeholder-pink-300::placeholder {
    --placeholder-opacity: 1;
    color: #fbb6ce;
    color: rgba(251, 182, 206, var(--placeholder-opacity));
  }

  .xl\:tw-placeholder-pink-400::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #f687b3;
    color: rgba(246, 135, 179, var(--placeholder-opacity));
  }

  .xl\:tw-placeholder-pink-400::placeholder {
    --placeholder-opacity: 1;
    color: #f687b3;
    color: rgba(246, 135, 179, var(--placeholder-opacity));
  }

  .xl\:tw-placeholder-pink-500::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #ed64a6;
    color: rgba(237, 100, 166, var(--placeholder-opacity));
  }

  .xl\:tw-placeholder-pink-500::placeholder {
    --placeholder-opacity: 1;
    color: #ed64a6;
    color: rgba(237, 100, 166, var(--placeholder-opacity));
  }

  .xl\:tw-placeholder-pink-600::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #d53f8c;
    color: rgba(213, 63, 140, var(--placeholder-opacity));
  }

  .xl\:tw-placeholder-pink-600::placeholder {
    --placeholder-opacity: 1;
    color: #d53f8c;
    color: rgba(213, 63, 140, var(--placeholder-opacity));
  }

  .xl\:tw-placeholder-pink-700::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #b83280;
    color: rgba(184, 50, 128, var(--placeholder-opacity));
  }

  .xl\:tw-placeholder-pink-700::placeholder {
    --placeholder-opacity: 1;
    color: #b83280;
    color: rgba(184, 50, 128, var(--placeholder-opacity));
  }

  .xl\:tw-placeholder-pink-800::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #97266d;
    color: rgba(151, 38, 109, var(--placeholder-opacity));
  }

  .xl\:tw-placeholder-pink-800::placeholder {
    --placeholder-opacity: 1;
    color: #97266d;
    color: rgba(151, 38, 109, var(--placeholder-opacity));
  }

  .xl\:tw-placeholder-pink-900::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #702459;
    color: rgba(112, 36, 89, var(--placeholder-opacity));
  }

  .xl\:tw-placeholder-pink-900::placeholder {
    --placeholder-opacity: 1;
    color: #702459;
    color: rgba(112, 36, 89, var(--placeholder-opacity));
  }

  .xl\:focus\:tw-placeholder-transparent:focus::-moz-placeholder {
    color: transparent;
  }

  .xl\:focus\:tw-placeholder-transparent:focus::placeholder {
    color: transparent;
  }

  .xl\:focus\:tw-placeholder-current:focus::-moz-placeholder {
    color: currentColor;
  }

  .xl\:focus\:tw-placeholder-current:focus::placeholder {
    color: currentColor;
  }

  .xl\:focus\:tw-placeholder-black:focus::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #000;
    color: rgba(0, 0, 0, var(--placeholder-opacity));
  }

  .xl\:focus\:tw-placeholder-black:focus::placeholder {
    --placeholder-opacity: 1;
    color: #000;
    color: rgba(0, 0, 0, var(--placeholder-opacity));
  }

  .xl\:focus\:tw-placeholder-white:focus::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #fff;
    color: rgba(255, 255, 255, var(--placeholder-opacity));
  }

  .xl\:focus\:tw-placeholder-white:focus::placeholder {
    --placeholder-opacity: 1;
    color: #fff;
    color: rgba(255, 255, 255, var(--placeholder-opacity));
  }

  .xl\:focus\:tw-placeholder-gray-100:focus::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #f7fafc;
    color: rgba(247, 250, 252, var(--placeholder-opacity));
  }

  .xl\:focus\:tw-placeholder-gray-100:focus::placeholder {
    --placeholder-opacity: 1;
    color: #f7fafc;
    color: rgba(247, 250, 252, var(--placeholder-opacity));
  }

  .xl\:focus\:tw-placeholder-gray-200:focus::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #edf2f7;
    color: rgba(237, 242, 247, var(--placeholder-opacity));
  }

  .xl\:focus\:tw-placeholder-gray-200:focus::placeholder {
    --placeholder-opacity: 1;
    color: #edf2f7;
    color: rgba(237, 242, 247, var(--placeholder-opacity));
  }

  .xl\:focus\:tw-placeholder-gray-300:focus::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #e2e8f0;
    color: rgba(226, 232, 240, var(--placeholder-opacity));
  }

  .xl\:focus\:tw-placeholder-gray-300:focus::placeholder {
    --placeholder-opacity: 1;
    color: #e2e8f0;
    color: rgba(226, 232, 240, var(--placeholder-opacity));
  }

  .xl\:focus\:tw-placeholder-gray-400:focus::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #cbd5e0;
    color: rgba(203, 213, 224, var(--placeholder-opacity));
  }

  .xl\:focus\:tw-placeholder-gray-400:focus::placeholder {
    --placeholder-opacity: 1;
    color: #cbd5e0;
    color: rgba(203, 213, 224, var(--placeholder-opacity));
  }

  .xl\:focus\:tw-placeholder-gray-500:focus::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #a0aec0;
    color: rgba(160, 174, 192, var(--placeholder-opacity));
  }

  .xl\:focus\:tw-placeholder-gray-500:focus::placeholder {
    --placeholder-opacity: 1;
    color: #a0aec0;
    color: rgba(160, 174, 192, var(--placeholder-opacity));
  }

  .xl\:focus\:tw-placeholder-gray-600:focus::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #718096;
    color: rgba(113, 128, 150, var(--placeholder-opacity));
  }

  .xl\:focus\:tw-placeholder-gray-600:focus::placeholder {
    --placeholder-opacity: 1;
    color: #718096;
    color: rgba(113, 128, 150, var(--placeholder-opacity));
  }

  .xl\:focus\:tw-placeholder-gray-700:focus::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #4a5568;
    color: rgba(74, 85, 104, var(--placeholder-opacity));
  }

  .xl\:focus\:tw-placeholder-gray-700:focus::placeholder {
    --placeholder-opacity: 1;
    color: #4a5568;
    color: rgba(74, 85, 104, var(--placeholder-opacity));
  }

  .xl\:focus\:tw-placeholder-gray-800:focus::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #2d3748;
    color: rgba(45, 55, 72, var(--placeholder-opacity));
  }

  .xl\:focus\:tw-placeholder-gray-800:focus::placeholder {
    --placeholder-opacity: 1;
    color: #2d3748;
    color: rgba(45, 55, 72, var(--placeholder-opacity));
  }

  .xl\:focus\:tw-placeholder-gray-900:focus::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #1a202c;
    color: rgba(26, 32, 44, var(--placeholder-opacity));
  }

  .xl\:focus\:tw-placeholder-gray-900:focus::placeholder {
    --placeholder-opacity: 1;
    color: #1a202c;
    color: rgba(26, 32, 44, var(--placeholder-opacity));
  }

  .xl\:focus\:tw-placeholder-red-100:focus::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #fff5f5;
    color: rgba(255, 245, 245, var(--placeholder-opacity));
  }

  .xl\:focus\:tw-placeholder-red-100:focus::placeholder {
    --placeholder-opacity: 1;
    color: #fff5f5;
    color: rgba(255, 245, 245, var(--placeholder-opacity));
  }

  .xl\:focus\:tw-placeholder-red-200:focus::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #fed7d7;
    color: rgba(254, 215, 215, var(--placeholder-opacity));
  }

  .xl\:focus\:tw-placeholder-red-200:focus::placeholder {
    --placeholder-opacity: 1;
    color: #fed7d7;
    color: rgba(254, 215, 215, var(--placeholder-opacity));
  }

  .xl\:focus\:tw-placeholder-red-300:focus::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #feb2b2;
    color: rgba(254, 178, 178, var(--placeholder-opacity));
  }

  .xl\:focus\:tw-placeholder-red-300:focus::placeholder {
    --placeholder-opacity: 1;
    color: #feb2b2;
    color: rgba(254, 178, 178, var(--placeholder-opacity));
  }

  .xl\:focus\:tw-placeholder-red-400:focus::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #fc8181;
    color: rgba(252, 129, 129, var(--placeholder-opacity));
  }

  .xl\:focus\:tw-placeholder-red-400:focus::placeholder {
    --placeholder-opacity: 1;
    color: #fc8181;
    color: rgba(252, 129, 129, var(--placeholder-opacity));
  }

  .xl\:focus\:tw-placeholder-red-500:focus::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #f56565;
    color: rgba(245, 101, 101, var(--placeholder-opacity));
  }

  .xl\:focus\:tw-placeholder-red-500:focus::placeholder {
    --placeholder-opacity: 1;
    color: #f56565;
    color: rgba(245, 101, 101, var(--placeholder-opacity));
  }

  .xl\:focus\:tw-placeholder-red-600:focus::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #e53e3e;
    color: rgba(229, 62, 62, var(--placeholder-opacity));
  }

  .xl\:focus\:tw-placeholder-red-600:focus::placeholder {
    --placeholder-opacity: 1;
    color: #e53e3e;
    color: rgba(229, 62, 62, var(--placeholder-opacity));
  }

  .xl\:focus\:tw-placeholder-red-700:focus::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #c53030;
    color: rgba(197, 48, 48, var(--placeholder-opacity));
  }

  .xl\:focus\:tw-placeholder-red-700:focus::placeholder {
    --placeholder-opacity: 1;
    color: #c53030;
    color: rgba(197, 48, 48, var(--placeholder-opacity));
  }

  .xl\:focus\:tw-placeholder-red-800:focus::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #9b2c2c;
    color: rgba(155, 44, 44, var(--placeholder-opacity));
  }

  .xl\:focus\:tw-placeholder-red-800:focus::placeholder {
    --placeholder-opacity: 1;
    color: #9b2c2c;
    color: rgba(155, 44, 44, var(--placeholder-opacity));
  }

  .xl\:focus\:tw-placeholder-red-900:focus::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #742a2a;
    color: rgba(116, 42, 42, var(--placeholder-opacity));
  }

  .xl\:focus\:tw-placeholder-red-900:focus::placeholder {
    --placeholder-opacity: 1;
    color: #742a2a;
    color: rgba(116, 42, 42, var(--placeholder-opacity));
  }

  .xl\:focus\:tw-placeholder-orange-100:focus::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #fffaf0;
    color: rgba(255, 250, 240, var(--placeholder-opacity));
  }

  .xl\:focus\:tw-placeholder-orange-100:focus::placeholder {
    --placeholder-opacity: 1;
    color: #fffaf0;
    color: rgba(255, 250, 240, var(--placeholder-opacity));
  }

  .xl\:focus\:tw-placeholder-orange-200:focus::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #feebc8;
    color: rgba(254, 235, 200, var(--placeholder-opacity));
  }

  .xl\:focus\:tw-placeholder-orange-200:focus::placeholder {
    --placeholder-opacity: 1;
    color: #feebc8;
    color: rgba(254, 235, 200, var(--placeholder-opacity));
  }

  .xl\:focus\:tw-placeholder-orange-300:focus::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #fbd38d;
    color: rgba(251, 211, 141, var(--placeholder-opacity));
  }

  .xl\:focus\:tw-placeholder-orange-300:focus::placeholder {
    --placeholder-opacity: 1;
    color: #fbd38d;
    color: rgba(251, 211, 141, var(--placeholder-opacity));
  }

  .xl\:focus\:tw-placeholder-orange-400:focus::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #f6ad55;
    color: rgba(246, 173, 85, var(--placeholder-opacity));
  }

  .xl\:focus\:tw-placeholder-orange-400:focus::placeholder {
    --placeholder-opacity: 1;
    color: #f6ad55;
    color: rgba(246, 173, 85, var(--placeholder-opacity));
  }

  .xl\:focus\:tw-placeholder-orange-500:focus::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #ed8936;
    color: rgba(237, 137, 54, var(--placeholder-opacity));
  }

  .xl\:focus\:tw-placeholder-orange-500:focus::placeholder {
    --placeholder-opacity: 1;
    color: #ed8936;
    color: rgba(237, 137, 54, var(--placeholder-opacity));
  }

  .xl\:focus\:tw-placeholder-orange-600:focus::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #dd6b20;
    color: rgba(221, 107, 32, var(--placeholder-opacity));
  }

  .xl\:focus\:tw-placeholder-orange-600:focus::placeholder {
    --placeholder-opacity: 1;
    color: #dd6b20;
    color: rgba(221, 107, 32, var(--placeholder-opacity));
  }

  .xl\:focus\:tw-placeholder-orange-700:focus::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #c05621;
    color: rgba(192, 86, 33, var(--placeholder-opacity));
  }

  .xl\:focus\:tw-placeholder-orange-700:focus::placeholder {
    --placeholder-opacity: 1;
    color: #c05621;
    color: rgba(192, 86, 33, var(--placeholder-opacity));
  }

  .xl\:focus\:tw-placeholder-orange-800:focus::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #9c4221;
    color: rgba(156, 66, 33, var(--placeholder-opacity));
  }

  .xl\:focus\:tw-placeholder-orange-800:focus::placeholder {
    --placeholder-opacity: 1;
    color: #9c4221;
    color: rgba(156, 66, 33, var(--placeholder-opacity));
  }

  .xl\:focus\:tw-placeholder-orange-900:focus::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #7b341e;
    color: rgba(123, 52, 30, var(--placeholder-opacity));
  }

  .xl\:focus\:tw-placeholder-orange-900:focus::placeholder {
    --placeholder-opacity: 1;
    color: #7b341e;
    color: rgba(123, 52, 30, var(--placeholder-opacity));
  }

  .xl\:focus\:tw-placeholder-yellow-100:focus::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #fffff0;
    color: rgba(255, 255, 240, var(--placeholder-opacity));
  }

  .xl\:focus\:tw-placeholder-yellow-100:focus::placeholder {
    --placeholder-opacity: 1;
    color: #fffff0;
    color: rgba(255, 255, 240, var(--placeholder-opacity));
  }

  .xl\:focus\:tw-placeholder-yellow-200:focus::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #fefcbf;
    color: rgba(254, 252, 191, var(--placeholder-opacity));
  }

  .xl\:focus\:tw-placeholder-yellow-200:focus::placeholder {
    --placeholder-opacity: 1;
    color: #fefcbf;
    color: rgba(254, 252, 191, var(--placeholder-opacity));
  }

  .xl\:focus\:tw-placeholder-yellow-300:focus::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #faf089;
    color: rgba(250, 240, 137, var(--placeholder-opacity));
  }

  .xl\:focus\:tw-placeholder-yellow-300:focus::placeholder {
    --placeholder-opacity: 1;
    color: #faf089;
    color: rgba(250, 240, 137, var(--placeholder-opacity));
  }

  .xl\:focus\:tw-placeholder-yellow-400:focus::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #f6e05e;
    color: rgba(246, 224, 94, var(--placeholder-opacity));
  }

  .xl\:focus\:tw-placeholder-yellow-400:focus::placeholder {
    --placeholder-opacity: 1;
    color: #f6e05e;
    color: rgba(246, 224, 94, var(--placeholder-opacity));
  }

  .xl\:focus\:tw-placeholder-yellow-500:focus::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #ecc94b;
    color: rgba(236, 201, 75, var(--placeholder-opacity));
  }

  .xl\:focus\:tw-placeholder-yellow-500:focus::placeholder {
    --placeholder-opacity: 1;
    color: #ecc94b;
    color: rgba(236, 201, 75, var(--placeholder-opacity));
  }

  .xl\:focus\:tw-placeholder-yellow-600:focus::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #d69e2e;
    color: rgba(214, 158, 46, var(--placeholder-opacity));
  }

  .xl\:focus\:tw-placeholder-yellow-600:focus::placeholder {
    --placeholder-opacity: 1;
    color: #d69e2e;
    color: rgba(214, 158, 46, var(--placeholder-opacity));
  }

  .xl\:focus\:tw-placeholder-yellow-700:focus::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #b7791f;
    color: rgba(183, 121, 31, var(--placeholder-opacity));
  }

  .xl\:focus\:tw-placeholder-yellow-700:focus::placeholder {
    --placeholder-opacity: 1;
    color: #b7791f;
    color: rgba(183, 121, 31, var(--placeholder-opacity));
  }

  .xl\:focus\:tw-placeholder-yellow-800:focus::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #975a16;
    color: rgba(151, 90, 22, var(--placeholder-opacity));
  }

  .xl\:focus\:tw-placeholder-yellow-800:focus::placeholder {
    --placeholder-opacity: 1;
    color: #975a16;
    color: rgba(151, 90, 22, var(--placeholder-opacity));
  }

  .xl\:focus\:tw-placeholder-yellow-900:focus::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #744210;
    color: rgba(116, 66, 16, var(--placeholder-opacity));
  }

  .xl\:focus\:tw-placeholder-yellow-900:focus::placeholder {
    --placeholder-opacity: 1;
    color: #744210;
    color: rgba(116, 66, 16, var(--placeholder-opacity));
  }

  .xl\:focus\:tw-placeholder-green-100:focus::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #f0fff4;
    color: rgba(240, 255, 244, var(--placeholder-opacity));
  }

  .xl\:focus\:tw-placeholder-green-100:focus::placeholder {
    --placeholder-opacity: 1;
    color: #f0fff4;
    color: rgba(240, 255, 244, var(--placeholder-opacity));
  }

  .xl\:focus\:tw-placeholder-green-200:focus::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #c6f6d5;
    color: rgba(198, 246, 213, var(--placeholder-opacity));
  }

  .xl\:focus\:tw-placeholder-green-200:focus::placeholder {
    --placeholder-opacity: 1;
    color: #c6f6d5;
    color: rgba(198, 246, 213, var(--placeholder-opacity));
  }

  .xl\:focus\:tw-placeholder-green-300:focus::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #9ae6b4;
    color: rgba(154, 230, 180, var(--placeholder-opacity));
  }

  .xl\:focus\:tw-placeholder-green-300:focus::placeholder {
    --placeholder-opacity: 1;
    color: #9ae6b4;
    color: rgba(154, 230, 180, var(--placeholder-opacity));
  }

  .xl\:focus\:tw-placeholder-green-400:focus::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #68d391;
    color: rgba(104, 211, 145, var(--placeholder-opacity));
  }

  .xl\:focus\:tw-placeholder-green-400:focus::placeholder {
    --placeholder-opacity: 1;
    color: #68d391;
    color: rgba(104, 211, 145, var(--placeholder-opacity));
  }

  .xl\:focus\:tw-placeholder-green-500:focus::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #48bb78;
    color: rgba(72, 187, 120, var(--placeholder-opacity));
  }

  .xl\:focus\:tw-placeholder-green-500:focus::placeholder {
    --placeholder-opacity: 1;
    color: #48bb78;
    color: rgba(72, 187, 120, var(--placeholder-opacity));
  }

  .xl\:focus\:tw-placeholder-green-600:focus::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #38a169;
    color: rgba(56, 161, 105, var(--placeholder-opacity));
  }

  .xl\:focus\:tw-placeholder-green-600:focus::placeholder {
    --placeholder-opacity: 1;
    color: #38a169;
    color: rgba(56, 161, 105, var(--placeholder-opacity));
  }

  .xl\:focus\:tw-placeholder-green-700:focus::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #2f855a;
    color: rgba(47, 133, 90, var(--placeholder-opacity));
  }

  .xl\:focus\:tw-placeholder-green-700:focus::placeholder {
    --placeholder-opacity: 1;
    color: #2f855a;
    color: rgba(47, 133, 90, var(--placeholder-opacity));
  }

  .xl\:focus\:tw-placeholder-green-800:focus::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #276749;
    color: rgba(39, 103, 73, var(--placeholder-opacity));
  }

  .xl\:focus\:tw-placeholder-green-800:focus::placeholder {
    --placeholder-opacity: 1;
    color: #276749;
    color: rgba(39, 103, 73, var(--placeholder-opacity));
  }

  .xl\:focus\:tw-placeholder-green-900:focus::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #22543d;
    color: rgba(34, 84, 61, var(--placeholder-opacity));
  }

  .xl\:focus\:tw-placeholder-green-900:focus::placeholder {
    --placeholder-opacity: 1;
    color: #22543d;
    color: rgba(34, 84, 61, var(--placeholder-opacity));
  }

  .xl\:focus\:tw-placeholder-teal-100:focus::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #e6fffa;
    color: rgba(230, 255, 250, var(--placeholder-opacity));
  }

  .xl\:focus\:tw-placeholder-teal-100:focus::placeholder {
    --placeholder-opacity: 1;
    color: #e6fffa;
    color: rgba(230, 255, 250, var(--placeholder-opacity));
  }

  .xl\:focus\:tw-placeholder-teal-200:focus::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #b2f5ea;
    color: rgba(178, 245, 234, var(--placeholder-opacity));
  }

  .xl\:focus\:tw-placeholder-teal-200:focus::placeholder {
    --placeholder-opacity: 1;
    color: #b2f5ea;
    color: rgba(178, 245, 234, var(--placeholder-opacity));
  }

  .xl\:focus\:tw-placeholder-teal-300:focus::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #81e6d9;
    color: rgba(129, 230, 217, var(--placeholder-opacity));
  }

  .xl\:focus\:tw-placeholder-teal-300:focus::placeholder {
    --placeholder-opacity: 1;
    color: #81e6d9;
    color: rgba(129, 230, 217, var(--placeholder-opacity));
  }

  .xl\:focus\:tw-placeholder-teal-400:focus::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #4fd1c5;
    color: rgba(79, 209, 197, var(--placeholder-opacity));
  }

  .xl\:focus\:tw-placeholder-teal-400:focus::placeholder {
    --placeholder-opacity: 1;
    color: #4fd1c5;
    color: rgba(79, 209, 197, var(--placeholder-opacity));
  }

  .xl\:focus\:tw-placeholder-teal-500:focus::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #38b2ac;
    color: rgba(56, 178, 172, var(--placeholder-opacity));
  }

  .xl\:focus\:tw-placeholder-teal-500:focus::placeholder {
    --placeholder-opacity: 1;
    color: #38b2ac;
    color: rgba(56, 178, 172, var(--placeholder-opacity));
  }

  .xl\:focus\:tw-placeholder-teal-600:focus::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #319795;
    color: rgba(49, 151, 149, var(--placeholder-opacity));
  }

  .xl\:focus\:tw-placeholder-teal-600:focus::placeholder {
    --placeholder-opacity: 1;
    color: #319795;
    color: rgba(49, 151, 149, var(--placeholder-opacity));
  }

  .xl\:focus\:tw-placeholder-teal-700:focus::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #2c7a7b;
    color: rgba(44, 122, 123, var(--placeholder-opacity));
  }

  .xl\:focus\:tw-placeholder-teal-700:focus::placeholder {
    --placeholder-opacity: 1;
    color: #2c7a7b;
    color: rgba(44, 122, 123, var(--placeholder-opacity));
  }

  .xl\:focus\:tw-placeholder-teal-800:focus::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #285e61;
    color: rgba(40, 94, 97, var(--placeholder-opacity));
  }

  .xl\:focus\:tw-placeholder-teal-800:focus::placeholder {
    --placeholder-opacity: 1;
    color: #285e61;
    color: rgba(40, 94, 97, var(--placeholder-opacity));
  }

  .xl\:focus\:tw-placeholder-teal-900:focus::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #234e52;
    color: rgba(35, 78, 82, var(--placeholder-opacity));
  }

  .xl\:focus\:tw-placeholder-teal-900:focus::placeholder {
    --placeholder-opacity: 1;
    color: #234e52;
    color: rgba(35, 78, 82, var(--placeholder-opacity));
  }

  .xl\:focus\:tw-placeholder-blue-100:focus::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #ebf8ff;
    color: rgba(235, 248, 255, var(--placeholder-opacity));
  }

  .xl\:focus\:tw-placeholder-blue-100:focus::placeholder {
    --placeholder-opacity: 1;
    color: #ebf8ff;
    color: rgba(235, 248, 255, var(--placeholder-opacity));
  }

  .xl\:focus\:tw-placeholder-blue-200:focus::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #bee3f8;
    color: rgba(190, 227, 248, var(--placeholder-opacity));
  }

  .xl\:focus\:tw-placeholder-blue-200:focus::placeholder {
    --placeholder-opacity: 1;
    color: #bee3f8;
    color: rgba(190, 227, 248, var(--placeholder-opacity));
  }

  .xl\:focus\:tw-placeholder-blue-300:focus::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #90cdf4;
    color: rgba(144, 205, 244, var(--placeholder-opacity));
  }

  .xl\:focus\:tw-placeholder-blue-300:focus::placeholder {
    --placeholder-opacity: 1;
    color: #90cdf4;
    color: rgba(144, 205, 244, var(--placeholder-opacity));
  }

  .xl\:focus\:tw-placeholder-blue-400:focus::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #63b3ed;
    color: rgba(99, 179, 237, var(--placeholder-opacity));
  }

  .xl\:focus\:tw-placeholder-blue-400:focus::placeholder {
    --placeholder-opacity: 1;
    color: #63b3ed;
    color: rgba(99, 179, 237, var(--placeholder-opacity));
  }

  .xl\:focus\:tw-placeholder-blue-500:focus::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #4299e1;
    color: rgba(66, 153, 225, var(--placeholder-opacity));
  }

  .xl\:focus\:tw-placeholder-blue-500:focus::placeholder {
    --placeholder-opacity: 1;
    color: #4299e1;
    color: rgba(66, 153, 225, var(--placeholder-opacity));
  }

  .xl\:focus\:tw-placeholder-blue-600:focus::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #3182ce;
    color: rgba(49, 130, 206, var(--placeholder-opacity));
  }

  .xl\:focus\:tw-placeholder-blue-600:focus::placeholder {
    --placeholder-opacity: 1;
    color: #3182ce;
    color: rgba(49, 130, 206, var(--placeholder-opacity));
  }

  .xl\:focus\:tw-placeholder-blue-700:focus::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #2b6cb0;
    color: rgba(43, 108, 176, var(--placeholder-opacity));
  }

  .xl\:focus\:tw-placeholder-blue-700:focus::placeholder {
    --placeholder-opacity: 1;
    color: #2b6cb0;
    color: rgba(43, 108, 176, var(--placeholder-opacity));
  }

  .xl\:focus\:tw-placeholder-blue-800:focus::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #2c5282;
    color: rgba(44, 82, 130, var(--placeholder-opacity));
  }

  .xl\:focus\:tw-placeholder-blue-800:focus::placeholder {
    --placeholder-opacity: 1;
    color: #2c5282;
    color: rgba(44, 82, 130, var(--placeholder-opacity));
  }

  .xl\:focus\:tw-placeholder-blue-900:focus::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #2a4365;
    color: rgba(42, 67, 101, var(--placeholder-opacity));
  }

  .xl\:focus\:tw-placeholder-blue-900:focus::placeholder {
    --placeholder-opacity: 1;
    color: #2a4365;
    color: rgba(42, 67, 101, var(--placeholder-opacity));
  }

  .xl\:focus\:tw-placeholder-indigo-100:focus::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #ebf4ff;
    color: rgba(235, 244, 255, var(--placeholder-opacity));
  }

  .xl\:focus\:tw-placeholder-indigo-100:focus::placeholder {
    --placeholder-opacity: 1;
    color: #ebf4ff;
    color: rgba(235, 244, 255, var(--placeholder-opacity));
  }

  .xl\:focus\:tw-placeholder-indigo-200:focus::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #c3dafe;
    color: rgba(195, 218, 254, var(--placeholder-opacity));
  }

  .xl\:focus\:tw-placeholder-indigo-200:focus::placeholder {
    --placeholder-opacity: 1;
    color: #c3dafe;
    color: rgba(195, 218, 254, var(--placeholder-opacity));
  }

  .xl\:focus\:tw-placeholder-indigo-300:focus::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #a3bffa;
    color: rgba(163, 191, 250, var(--placeholder-opacity));
  }

  .xl\:focus\:tw-placeholder-indigo-300:focus::placeholder {
    --placeholder-opacity: 1;
    color: #a3bffa;
    color: rgba(163, 191, 250, var(--placeholder-opacity));
  }

  .xl\:focus\:tw-placeholder-indigo-400:focus::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #7f9cf5;
    color: rgba(127, 156, 245, var(--placeholder-opacity));
  }

  .xl\:focus\:tw-placeholder-indigo-400:focus::placeholder {
    --placeholder-opacity: 1;
    color: #7f9cf5;
    color: rgba(127, 156, 245, var(--placeholder-opacity));
  }

  .xl\:focus\:tw-placeholder-indigo-500:focus::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #667eea;
    color: rgba(102, 126, 234, var(--placeholder-opacity));
  }

  .xl\:focus\:tw-placeholder-indigo-500:focus::placeholder {
    --placeholder-opacity: 1;
    color: #667eea;
    color: rgba(102, 126, 234, var(--placeholder-opacity));
  }

  .xl\:focus\:tw-placeholder-indigo-600:focus::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #5a67d8;
    color: rgba(90, 103, 216, var(--placeholder-opacity));
  }

  .xl\:focus\:tw-placeholder-indigo-600:focus::placeholder {
    --placeholder-opacity: 1;
    color: #5a67d8;
    color: rgba(90, 103, 216, var(--placeholder-opacity));
  }

  .xl\:focus\:tw-placeholder-indigo-700:focus::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #4c51bf;
    color: rgba(76, 81, 191, var(--placeholder-opacity));
  }

  .xl\:focus\:tw-placeholder-indigo-700:focus::placeholder {
    --placeholder-opacity: 1;
    color: #4c51bf;
    color: rgba(76, 81, 191, var(--placeholder-opacity));
  }

  .xl\:focus\:tw-placeholder-indigo-800:focus::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #434190;
    color: rgba(67, 65, 144, var(--placeholder-opacity));
  }

  .xl\:focus\:tw-placeholder-indigo-800:focus::placeholder {
    --placeholder-opacity: 1;
    color: #434190;
    color: rgba(67, 65, 144, var(--placeholder-opacity));
  }

  .xl\:focus\:tw-placeholder-indigo-900:focus::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #3c366b;
    color: rgba(60, 54, 107, var(--placeholder-opacity));
  }

  .xl\:focus\:tw-placeholder-indigo-900:focus::placeholder {
    --placeholder-opacity: 1;
    color: #3c366b;
    color: rgba(60, 54, 107, var(--placeholder-opacity));
  }

  .xl\:focus\:tw-placeholder-purple-100:focus::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #faf5ff;
    color: rgba(250, 245, 255, var(--placeholder-opacity));
  }

  .xl\:focus\:tw-placeholder-purple-100:focus::placeholder {
    --placeholder-opacity: 1;
    color: #faf5ff;
    color: rgba(250, 245, 255, var(--placeholder-opacity));
  }

  .xl\:focus\:tw-placeholder-purple-200:focus::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #e9d8fd;
    color: rgba(233, 216, 253, var(--placeholder-opacity));
  }

  .xl\:focus\:tw-placeholder-purple-200:focus::placeholder {
    --placeholder-opacity: 1;
    color: #e9d8fd;
    color: rgba(233, 216, 253, var(--placeholder-opacity));
  }

  .xl\:focus\:tw-placeholder-purple-300:focus::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #d6bcfa;
    color: rgba(214, 188, 250, var(--placeholder-opacity));
  }

  .xl\:focus\:tw-placeholder-purple-300:focus::placeholder {
    --placeholder-opacity: 1;
    color: #d6bcfa;
    color: rgba(214, 188, 250, var(--placeholder-opacity));
  }

  .xl\:focus\:tw-placeholder-purple-400:focus::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #b794f4;
    color: rgba(183, 148, 244, var(--placeholder-opacity));
  }

  .xl\:focus\:tw-placeholder-purple-400:focus::placeholder {
    --placeholder-opacity: 1;
    color: #b794f4;
    color: rgba(183, 148, 244, var(--placeholder-opacity));
  }

  .xl\:focus\:tw-placeholder-purple-500:focus::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #9f7aea;
    color: rgba(159, 122, 234, var(--placeholder-opacity));
  }

  .xl\:focus\:tw-placeholder-purple-500:focus::placeholder {
    --placeholder-opacity: 1;
    color: #9f7aea;
    color: rgba(159, 122, 234, var(--placeholder-opacity));
  }

  .xl\:focus\:tw-placeholder-purple-600:focus::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #805ad5;
    color: rgba(128, 90, 213, var(--placeholder-opacity));
  }

  .xl\:focus\:tw-placeholder-purple-600:focus::placeholder {
    --placeholder-opacity: 1;
    color: #805ad5;
    color: rgba(128, 90, 213, var(--placeholder-opacity));
  }

  .xl\:focus\:tw-placeholder-purple-700:focus::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #6b46c1;
    color: rgba(107, 70, 193, var(--placeholder-opacity));
  }

  .xl\:focus\:tw-placeholder-purple-700:focus::placeholder {
    --placeholder-opacity: 1;
    color: #6b46c1;
    color: rgba(107, 70, 193, var(--placeholder-opacity));
  }

  .xl\:focus\:tw-placeholder-purple-800:focus::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #553c9a;
    color: rgba(85, 60, 154, var(--placeholder-opacity));
  }

  .xl\:focus\:tw-placeholder-purple-800:focus::placeholder {
    --placeholder-opacity: 1;
    color: #553c9a;
    color: rgba(85, 60, 154, var(--placeholder-opacity));
  }

  .xl\:focus\:tw-placeholder-purple-900:focus::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #44337a;
    color: rgba(68, 51, 122, var(--placeholder-opacity));
  }

  .xl\:focus\:tw-placeholder-purple-900:focus::placeholder {
    --placeholder-opacity: 1;
    color: #44337a;
    color: rgba(68, 51, 122, var(--placeholder-opacity));
  }

  .xl\:focus\:tw-placeholder-pink-100:focus::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #fff5f7;
    color: rgba(255, 245, 247, var(--placeholder-opacity));
  }

  .xl\:focus\:tw-placeholder-pink-100:focus::placeholder {
    --placeholder-opacity: 1;
    color: #fff5f7;
    color: rgba(255, 245, 247, var(--placeholder-opacity));
  }

  .xl\:focus\:tw-placeholder-pink-200:focus::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #fed7e2;
    color: rgba(254, 215, 226, var(--placeholder-opacity));
  }

  .xl\:focus\:tw-placeholder-pink-200:focus::placeholder {
    --placeholder-opacity: 1;
    color: #fed7e2;
    color: rgba(254, 215, 226, var(--placeholder-opacity));
  }

  .xl\:focus\:tw-placeholder-pink-300:focus::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #fbb6ce;
    color: rgba(251, 182, 206, var(--placeholder-opacity));
  }

  .xl\:focus\:tw-placeholder-pink-300:focus::placeholder {
    --placeholder-opacity: 1;
    color: #fbb6ce;
    color: rgba(251, 182, 206, var(--placeholder-opacity));
  }

  .xl\:focus\:tw-placeholder-pink-400:focus::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #f687b3;
    color: rgba(246, 135, 179, var(--placeholder-opacity));
  }

  .xl\:focus\:tw-placeholder-pink-400:focus::placeholder {
    --placeholder-opacity: 1;
    color: #f687b3;
    color: rgba(246, 135, 179, var(--placeholder-opacity));
  }

  .xl\:focus\:tw-placeholder-pink-500:focus::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #ed64a6;
    color: rgba(237, 100, 166, var(--placeholder-opacity));
  }

  .xl\:focus\:tw-placeholder-pink-500:focus::placeholder {
    --placeholder-opacity: 1;
    color: #ed64a6;
    color: rgba(237, 100, 166, var(--placeholder-opacity));
  }

  .xl\:focus\:tw-placeholder-pink-600:focus::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #d53f8c;
    color: rgba(213, 63, 140, var(--placeholder-opacity));
  }

  .xl\:focus\:tw-placeholder-pink-600:focus::placeholder {
    --placeholder-opacity: 1;
    color: #d53f8c;
    color: rgba(213, 63, 140, var(--placeholder-opacity));
  }

  .xl\:focus\:tw-placeholder-pink-700:focus::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #b83280;
    color: rgba(184, 50, 128, var(--placeholder-opacity));
  }

  .xl\:focus\:tw-placeholder-pink-700:focus::placeholder {
    --placeholder-opacity: 1;
    color: #b83280;
    color: rgba(184, 50, 128, var(--placeholder-opacity));
  }

  .xl\:focus\:tw-placeholder-pink-800:focus::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #97266d;
    color: rgba(151, 38, 109, var(--placeholder-opacity));
  }

  .xl\:focus\:tw-placeholder-pink-800:focus::placeholder {
    --placeholder-opacity: 1;
    color: #97266d;
    color: rgba(151, 38, 109, var(--placeholder-opacity));
  }

  .xl\:focus\:tw-placeholder-pink-900:focus::-moz-placeholder {
    --placeholder-opacity: 1;
    color: #702459;
    color: rgba(112, 36, 89, var(--placeholder-opacity));
  }

  .xl\:focus\:tw-placeholder-pink-900:focus::placeholder {
    --placeholder-opacity: 1;
    color: #702459;
    color: rgba(112, 36, 89, var(--placeholder-opacity));
  }

  .xl\:tw-placeholder-opacity-0::-moz-placeholder {
    --placeholder-opacity: 0;
  }

  .xl\:tw-placeholder-opacity-0::placeholder {
    --placeholder-opacity: 0;
  }

  .xl\:tw-placeholder-opacity-25::-moz-placeholder {
    --placeholder-opacity: 0.25;
  }

  .xl\:tw-placeholder-opacity-25::placeholder {
    --placeholder-opacity: 0.25;
  }

  .xl\:tw-placeholder-opacity-50::-moz-placeholder {
    --placeholder-opacity: 0.5;
  }

  .xl\:tw-placeholder-opacity-50::placeholder {
    --placeholder-opacity: 0.5;
  }

  .xl\:tw-placeholder-opacity-75::-moz-placeholder {
    --placeholder-opacity: 0.75;
  }

  .xl\:tw-placeholder-opacity-75::placeholder {
    --placeholder-opacity: 0.75;
  }

  .xl\:tw-placeholder-opacity-100::-moz-placeholder {
    --placeholder-opacity: 1;
  }

  .xl\:tw-placeholder-opacity-100::placeholder {
    --placeholder-opacity: 1;
  }

  .xl\:focus\:tw-placeholder-opacity-0:focus::-moz-placeholder {
    --placeholder-opacity: 0;
  }

  .xl\:focus\:tw-placeholder-opacity-0:focus::placeholder {
    --placeholder-opacity: 0;
  }

  .xl\:focus\:tw-placeholder-opacity-25:focus::-moz-placeholder {
    --placeholder-opacity: 0.25;
  }

  .xl\:focus\:tw-placeholder-opacity-25:focus::placeholder {
    --placeholder-opacity: 0.25;
  }

  .xl\:focus\:tw-placeholder-opacity-50:focus::-moz-placeholder {
    --placeholder-opacity: 0.5;
  }

  .xl\:focus\:tw-placeholder-opacity-50:focus::placeholder {
    --placeholder-opacity: 0.5;
  }

  .xl\:focus\:tw-placeholder-opacity-75:focus::-moz-placeholder {
    --placeholder-opacity: 0.75;
  }

  .xl\:focus\:tw-placeholder-opacity-75:focus::placeholder {
    --placeholder-opacity: 0.75;
  }

  .xl\:focus\:tw-placeholder-opacity-100:focus::-moz-placeholder {
    --placeholder-opacity: 1;
  }

  .xl\:focus\:tw-placeholder-opacity-100:focus::placeholder {
    --placeholder-opacity: 1;
  }

  .xl\:tw-pointer-events-none {
    pointer-events: none;
  }

  .xl\:tw-pointer-events-auto {
    pointer-events: auto;
  }

  .xl\:tw-static {
    position: static;
  }

  .xl\:tw-fixed {
    position: fixed;
  }

  .xl\:tw-absolute {
    position: absolute;
  }

  .xl\:tw-relative {
    position: relative;
  }

  .xl\:tw-sticky {
    position: sticky;
  }

  .xl\:tw-inset-0 {
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }

  .xl\:tw-inset-auto {
    top: auto;
    right: auto;
    bottom: auto;
    left: auto;
  }

  .xl\:tw-inset-y-0 {
    top: 0;
    bottom: 0;
  }

  .xl\:tw-inset-x-0 {
    right: 0;
    left: 0;
  }

  .xl\:tw-inset-y-auto {
    top: auto;
    bottom: auto;
  }

  .xl\:tw-inset-x-auto {
    right: auto;
    left: auto;
  }

  .xl\:tw-top-0 {
    top: 0;
  }

  .xl\:tw-right-0 {
    right: 0;
  }

  .xl\:tw-bottom-0 {
    bottom: 0;
  }

  .xl\:tw-left-0 {
    left: 0;
  }

  .xl\:tw-top-auto {
    top: auto;
  }

  .xl\:tw-right-auto {
    right: auto;
  }

  .xl\:tw-bottom-auto {
    bottom: auto;
  }

  .xl\:tw-left-auto {
    left: auto;
  }

  .xl\:tw-resize-none {
    resize: none;
  }

  .xl\:tw-resize-y {
    resize: vertical;
  }

  .xl\:tw-resize-x {
    resize: horizontal;
  }

  .xl\:tw-resize {
    resize: both;
  }

  .xl\:tw-shadow-xs {
    box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.05);
  }

  .xl\:tw-shadow-sm {
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
  }

  .xl\:tw-shadow {
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
  }

  .xl\:tw-shadow-md {
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
  }

  .xl\:tw-shadow-lg {
    box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
  }

  .xl\:tw-shadow-xl {
    box-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04);
  }

  .xl\:tw-shadow-2xl {
    box-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25);
  }

  .xl\:tw-shadow-inner {
    box-shadow: inset 0 2px 4px 0 rgba(0, 0, 0, 0.06);
  }

  .xl\:tw-shadow-outline {
    box-shadow: 0 0 0 3px rgba(66, 153, 225, 0.5);
  }

  .xl\:tw-shadow-none {
    box-shadow: none;
  }

  .xl\:hover\:tw-shadow-xs:hover {
    box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.05);
  }

  .xl\:hover\:tw-shadow-sm:hover {
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
  }

  .xl\:hover\:tw-shadow:hover {
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
  }

  .xl\:hover\:tw-shadow-md:hover {
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
  }

  .xl\:hover\:tw-shadow-lg:hover {
    box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
  }

  .xl\:hover\:tw-shadow-xl:hover {
    box-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04);
  }

  .xl\:hover\:tw-shadow-2xl:hover {
    box-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25);
  }

  .xl\:hover\:tw-shadow-inner:hover {
    box-shadow: inset 0 2px 4px 0 rgba(0, 0, 0, 0.06);
  }

  .xl\:hover\:tw-shadow-outline:hover {
    box-shadow: 0 0 0 3px rgba(66, 153, 225, 0.5);
  }

  .xl\:hover\:tw-shadow-none:hover {
    box-shadow: none;
  }

  .xl\:focus\:tw-shadow-xs:focus {
    box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.05);
  }

  .xl\:focus\:tw-shadow-sm:focus {
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
  }

  .xl\:focus\:tw-shadow:focus {
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
  }

  .xl\:focus\:tw-shadow-md:focus {
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
  }

  .xl\:focus\:tw-shadow-lg:focus {
    box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
  }

  .xl\:focus\:tw-shadow-xl:focus {
    box-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04);
  }

  .xl\:focus\:tw-shadow-2xl:focus {
    box-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25);
  }

  .xl\:focus\:tw-shadow-inner:focus {
    box-shadow: inset 0 2px 4px 0 rgba(0, 0, 0, 0.06);
  }

  .xl\:focus\:tw-shadow-outline:focus {
    box-shadow: 0 0 0 3px rgba(66, 153, 225, 0.5);
  }

  .xl\:focus\:tw-shadow-none:focus {
    box-shadow: none;
  }

  .xl\:tw-fill-current {
    fill: currentColor;
  }

  .xl\:tw-stroke-current {
    stroke: currentColor;
  }

  .xl\:tw-stroke-0 {
    stroke-width: 0;
  }

  .xl\:tw-stroke-1 {
    stroke-width: 1;
  }

  .xl\:tw-stroke-2 {
    stroke-width: 2;
  }

  .xl\:tw-table-auto {
    table-layout: auto;
  }

  .xl\:tw-table-fixed {
    table-layout: fixed;
  }

  .xl\:tw-text-left {
    text-align: left;
  }

  .xl\:tw-text-center {
    text-align: center;
  }

  .xl\:tw-text-right {
    text-align: right;
  }

  .xl\:tw-text-justify {
    text-align: justify;
  }

  .xl\:tw-text-transparent {
    color: transparent;
  }

  .xl\:tw-text-current {
    color: currentColor;
  }

  .xl\:tw-text-black {
    --text-opacity: 1;
    color: #000;
    color: rgba(0, 0, 0, var(--text-opacity));
  }

  .xl\:tw-text-white {
    --text-opacity: 1;
    color: #fff;
    color: rgba(255, 255, 255, var(--text-opacity));
  }

  .xl\:tw-text-gray-100 {
    --text-opacity: 1;
    color: #f7fafc;
    color: rgba(247, 250, 252, var(--text-opacity));
  }

  .xl\:tw-text-gray-200 {
    --text-opacity: 1;
    color: #edf2f7;
    color: rgba(237, 242, 247, var(--text-opacity));
  }

  .xl\:tw-text-gray-300 {
    --text-opacity: 1;
    color: #e2e8f0;
    color: rgba(226, 232, 240, var(--text-opacity));
  }

  .xl\:tw-text-gray-400 {
    --text-opacity: 1;
    color: #cbd5e0;
    color: rgba(203, 213, 224, var(--text-opacity));
  }

  .xl\:tw-text-gray-500 {
    --text-opacity: 1;
    color: #a0aec0;
    color: rgba(160, 174, 192, var(--text-opacity));
  }

  .xl\:tw-text-gray-600 {
    --text-opacity: 1;
    color: #718096;
    color: rgba(113, 128, 150, var(--text-opacity));
  }

  .xl\:tw-text-gray-700 {
    --text-opacity: 1;
    color: #4a5568;
    color: rgba(74, 85, 104, var(--text-opacity));
  }

  .xl\:tw-text-gray-800 {
    --text-opacity: 1;
    color: #2d3748;
    color: rgba(45, 55, 72, var(--text-opacity));
  }

  .xl\:tw-text-gray-900 {
    --text-opacity: 1;
    color: #1a202c;
    color: rgba(26, 32, 44, var(--text-opacity));
  }

  .xl\:tw-text-red-100 {
    --text-opacity: 1;
    color: #fff5f5;
    color: rgba(255, 245, 245, var(--text-opacity));
  }

  .xl\:tw-text-red-200 {
    --text-opacity: 1;
    color: #fed7d7;
    color: rgba(254, 215, 215, var(--text-opacity));
  }

  .xl\:tw-text-red-300 {
    --text-opacity: 1;
    color: #feb2b2;
    color: rgba(254, 178, 178, var(--text-opacity));
  }

  .xl\:tw-text-red-400 {
    --text-opacity: 1;
    color: #fc8181;
    color: rgba(252, 129, 129, var(--text-opacity));
  }

  .xl\:tw-text-red-500 {
    --text-opacity: 1;
    color: #f56565;
    color: rgba(245, 101, 101, var(--text-opacity));
  }

  .xl\:tw-text-red-600 {
    --text-opacity: 1;
    color: #e53e3e;
    color: rgba(229, 62, 62, var(--text-opacity));
  }

  .xl\:tw-text-red-700 {
    --text-opacity: 1;
    color: #c53030;
    color: rgba(197, 48, 48, var(--text-opacity));
  }

  .xl\:tw-text-red-800 {
    --text-opacity: 1;
    color: #9b2c2c;
    color: rgba(155, 44, 44, var(--text-opacity));
  }

  .xl\:tw-text-red-900 {
    --text-opacity: 1;
    color: #742a2a;
    color: rgba(116, 42, 42, var(--text-opacity));
  }

  .xl\:tw-text-orange-100 {
    --text-opacity: 1;
    color: #fffaf0;
    color: rgba(255, 250, 240, var(--text-opacity));
  }

  .xl\:tw-text-orange-200 {
    --text-opacity: 1;
    color: #feebc8;
    color: rgba(254, 235, 200, var(--text-opacity));
  }

  .xl\:tw-text-orange-300 {
    --text-opacity: 1;
    color: #fbd38d;
    color: rgba(251, 211, 141, var(--text-opacity));
  }

  .xl\:tw-text-orange-400 {
    --text-opacity: 1;
    color: #f6ad55;
    color: rgba(246, 173, 85, var(--text-opacity));
  }

  .xl\:tw-text-orange-500 {
    --text-opacity: 1;
    color: #ed8936;
    color: rgba(237, 137, 54, var(--text-opacity));
  }

  .xl\:tw-text-orange-600 {
    --text-opacity: 1;
    color: #dd6b20;
    color: rgba(221, 107, 32, var(--text-opacity));
  }

  .xl\:tw-text-orange-700 {
    --text-opacity: 1;
    color: #c05621;
    color: rgba(192, 86, 33, var(--text-opacity));
  }

  .xl\:tw-text-orange-800 {
    --text-opacity: 1;
    color: #9c4221;
    color: rgba(156, 66, 33, var(--text-opacity));
  }

  .xl\:tw-text-orange-900 {
    --text-opacity: 1;
    color: #7b341e;
    color: rgba(123, 52, 30, var(--text-opacity));
  }

  .xl\:tw-text-yellow-100 {
    --text-opacity: 1;
    color: #fffff0;
    color: rgba(255, 255, 240, var(--text-opacity));
  }

  .xl\:tw-text-yellow-200 {
    --text-opacity: 1;
    color: #fefcbf;
    color: rgba(254, 252, 191, var(--text-opacity));
  }

  .xl\:tw-text-yellow-300 {
    --text-opacity: 1;
    color: #faf089;
    color: rgba(250, 240, 137, var(--text-opacity));
  }

  .xl\:tw-text-yellow-400 {
    --text-opacity: 1;
    color: #f6e05e;
    color: rgba(246, 224, 94, var(--text-opacity));
  }

  .xl\:tw-text-yellow-500 {
    --text-opacity: 1;
    color: #ecc94b;
    color: rgba(236, 201, 75, var(--text-opacity));
  }

  .xl\:tw-text-yellow-600 {
    --text-opacity: 1;
    color: #d69e2e;
    color: rgba(214, 158, 46, var(--text-opacity));
  }

  .xl\:tw-text-yellow-700 {
    --text-opacity: 1;
    color: #b7791f;
    color: rgba(183, 121, 31, var(--text-opacity));
  }

  .xl\:tw-text-yellow-800 {
    --text-opacity: 1;
    color: #975a16;
    color: rgba(151, 90, 22, var(--text-opacity));
  }

  .xl\:tw-text-yellow-900 {
    --text-opacity: 1;
    color: #744210;
    color: rgba(116, 66, 16, var(--text-opacity));
  }

  .xl\:tw-text-green-100 {
    --text-opacity: 1;
    color: #f0fff4;
    color: rgba(240, 255, 244, var(--text-opacity));
  }

  .xl\:tw-text-green-200 {
    --text-opacity: 1;
    color: #c6f6d5;
    color: rgba(198, 246, 213, var(--text-opacity));
  }

  .xl\:tw-text-green-300 {
    --text-opacity: 1;
    color: #9ae6b4;
    color: rgba(154, 230, 180, var(--text-opacity));
  }

  .xl\:tw-text-green-400 {
    --text-opacity: 1;
    color: #68d391;
    color: rgba(104, 211, 145, var(--text-opacity));
  }

  .xl\:tw-text-green-500 {
    --text-opacity: 1;
    color: #48bb78;
    color: rgba(72, 187, 120, var(--text-opacity));
  }

  .xl\:tw-text-green-600 {
    --text-opacity: 1;
    color: #38a169;
    color: rgba(56, 161, 105, var(--text-opacity));
  }

  .xl\:tw-text-green-700 {
    --text-opacity: 1;
    color: #2f855a;
    color: rgba(47, 133, 90, var(--text-opacity));
  }

  .xl\:tw-text-green-800 {
    --text-opacity: 1;
    color: #276749;
    color: rgba(39, 103, 73, var(--text-opacity));
  }

  .xl\:tw-text-green-900 {
    --text-opacity: 1;
    color: #22543d;
    color: rgba(34, 84, 61, var(--text-opacity));
  }

  .xl\:tw-text-teal-100 {
    --text-opacity: 1;
    color: #e6fffa;
    color: rgba(230, 255, 250, var(--text-opacity));
  }

  .xl\:tw-text-teal-200 {
    --text-opacity: 1;
    color: #b2f5ea;
    color: rgba(178, 245, 234, var(--text-opacity));
  }

  .xl\:tw-text-teal-300 {
    --text-opacity: 1;
    color: #81e6d9;
    color: rgba(129, 230, 217, var(--text-opacity));
  }

  .xl\:tw-text-teal-400 {
    --text-opacity: 1;
    color: #4fd1c5;
    color: rgba(79, 209, 197, var(--text-opacity));
  }

  .xl\:tw-text-teal-500 {
    --text-opacity: 1;
    color: #38b2ac;
    color: rgba(56, 178, 172, var(--text-opacity));
  }

  .xl\:tw-text-teal-600 {
    --text-opacity: 1;
    color: #319795;
    color: rgba(49, 151, 149, var(--text-opacity));
  }

  .xl\:tw-text-teal-700 {
    --text-opacity: 1;
    color: #2c7a7b;
    color: rgba(44, 122, 123, var(--text-opacity));
  }

  .xl\:tw-text-teal-800 {
    --text-opacity: 1;
    color: #285e61;
    color: rgba(40, 94, 97, var(--text-opacity));
  }

  .xl\:tw-text-teal-900 {
    --text-opacity: 1;
    color: #234e52;
    color: rgba(35, 78, 82, var(--text-opacity));
  }

  .xl\:tw-text-blue-100 {
    --text-opacity: 1;
    color: #ebf8ff;
    color: rgba(235, 248, 255, var(--text-opacity));
  }

  .xl\:tw-text-blue-200 {
    --text-opacity: 1;
    color: #bee3f8;
    color: rgba(190, 227, 248, var(--text-opacity));
  }

  .xl\:tw-text-blue-300 {
    --text-opacity: 1;
    color: #90cdf4;
    color: rgba(144, 205, 244, var(--text-opacity));
  }

  .xl\:tw-text-blue-400 {
    --text-opacity: 1;
    color: #63b3ed;
    color: rgba(99, 179, 237, var(--text-opacity));
  }

  .xl\:tw-text-blue-500 {
    --text-opacity: 1;
    color: #4299e1;
    color: rgba(66, 153, 225, var(--text-opacity));
  }

  .xl\:tw-text-blue-600 {
    --text-opacity: 1;
    color: #3182ce;
    color: rgba(49, 130, 206, var(--text-opacity));
  }

  .xl\:tw-text-blue-700 {
    --text-opacity: 1;
    color: #2b6cb0;
    color: rgba(43, 108, 176, var(--text-opacity));
  }

  .xl\:tw-text-blue-800 {
    --text-opacity: 1;
    color: #2c5282;
    color: rgba(44, 82, 130, var(--text-opacity));
  }

  .xl\:tw-text-blue-900 {
    --text-opacity: 1;
    color: #2a4365;
    color: rgba(42, 67, 101, var(--text-opacity));
  }

  .xl\:tw-text-indigo-100 {
    --text-opacity: 1;
    color: #ebf4ff;
    color: rgba(235, 244, 255, var(--text-opacity));
  }

  .xl\:tw-text-indigo-200 {
    --text-opacity: 1;
    color: #c3dafe;
    color: rgba(195, 218, 254, var(--text-opacity));
  }

  .xl\:tw-text-indigo-300 {
    --text-opacity: 1;
    color: #a3bffa;
    color: rgba(163, 191, 250, var(--text-opacity));
  }

  .xl\:tw-text-indigo-400 {
    --text-opacity: 1;
    color: #7f9cf5;
    color: rgba(127, 156, 245, var(--text-opacity));
  }

  .xl\:tw-text-indigo-500 {
    --text-opacity: 1;
    color: #667eea;
    color: rgba(102, 126, 234, var(--text-opacity));
  }

  .xl\:tw-text-indigo-600 {
    --text-opacity: 1;
    color: #5a67d8;
    color: rgba(90, 103, 216, var(--text-opacity));
  }

  .xl\:tw-text-indigo-700 {
    --text-opacity: 1;
    color: #4c51bf;
    color: rgba(76, 81, 191, var(--text-opacity));
  }

  .xl\:tw-text-indigo-800 {
    --text-opacity: 1;
    color: #434190;
    color: rgba(67, 65, 144, var(--text-opacity));
  }

  .xl\:tw-text-indigo-900 {
    --text-opacity: 1;
    color: #3c366b;
    color: rgba(60, 54, 107, var(--text-opacity));
  }

  .xl\:tw-text-purple-100 {
    --text-opacity: 1;
    color: #faf5ff;
    color: rgba(250, 245, 255, var(--text-opacity));
  }

  .xl\:tw-text-purple-200 {
    --text-opacity: 1;
    color: #e9d8fd;
    color: rgba(233, 216, 253, var(--text-opacity));
  }

  .xl\:tw-text-purple-300 {
    --text-opacity: 1;
    color: #d6bcfa;
    color: rgba(214, 188, 250, var(--text-opacity));
  }

  .xl\:tw-text-purple-400 {
    --text-opacity: 1;
    color: #b794f4;
    color: rgba(183, 148, 244, var(--text-opacity));
  }

  .xl\:tw-text-purple-500 {
    --text-opacity: 1;
    color: #9f7aea;
    color: rgba(159, 122, 234, var(--text-opacity));
  }

  .xl\:tw-text-purple-600 {
    --text-opacity: 1;
    color: #805ad5;
    color: rgba(128, 90, 213, var(--text-opacity));
  }

  .xl\:tw-text-purple-700 {
    --text-opacity: 1;
    color: #6b46c1;
    color: rgba(107, 70, 193, var(--text-opacity));
  }

  .xl\:tw-text-purple-800 {
    --text-opacity: 1;
    color: #553c9a;
    color: rgba(85, 60, 154, var(--text-opacity));
  }

  .xl\:tw-text-purple-900 {
    --text-opacity: 1;
    color: #44337a;
    color: rgba(68, 51, 122, var(--text-opacity));
  }

  .xl\:tw-text-pink-100 {
    --text-opacity: 1;
    color: #fff5f7;
    color: rgba(255, 245, 247, var(--text-opacity));
  }

  .xl\:tw-text-pink-200 {
    --text-opacity: 1;
    color: #fed7e2;
    color: rgba(254, 215, 226, var(--text-opacity));
  }

  .xl\:tw-text-pink-300 {
    --text-opacity: 1;
    color: #fbb6ce;
    color: rgba(251, 182, 206, var(--text-opacity));
  }

  .xl\:tw-text-pink-400 {
    --text-opacity: 1;
    color: #f687b3;
    color: rgba(246, 135, 179, var(--text-opacity));
  }

  .xl\:tw-text-pink-500 {
    --text-opacity: 1;
    color: #ed64a6;
    color: rgba(237, 100, 166, var(--text-opacity));
  }

  .xl\:tw-text-pink-600 {
    --text-opacity: 1;
    color: #d53f8c;
    color: rgba(213, 63, 140, var(--text-opacity));
  }

  .xl\:tw-text-pink-700 {
    --text-opacity: 1;
    color: #b83280;
    color: rgba(184, 50, 128, var(--text-opacity));
  }

  .xl\:tw-text-pink-800 {
    --text-opacity: 1;
    color: #97266d;
    color: rgba(151, 38, 109, var(--text-opacity));
  }

  .xl\:tw-text-pink-900 {
    --text-opacity: 1;
    color: #702459;
    color: rgba(112, 36, 89, var(--text-opacity));
  }

  .xl\:hover\:tw-text-transparent:hover {
    color: transparent;
  }

  .xl\:hover\:tw-text-current:hover {
    color: currentColor;
  }

  .xl\:hover\:tw-text-black:hover {
    --text-opacity: 1;
    color: #000;
    color: rgba(0, 0, 0, var(--text-opacity));
  }

  .xl\:hover\:tw-text-white:hover {
    --text-opacity: 1;
    color: #fff;
    color: rgba(255, 255, 255, var(--text-opacity));
  }

  .xl\:hover\:tw-text-gray-100:hover {
    --text-opacity: 1;
    color: #f7fafc;
    color: rgba(247, 250, 252, var(--text-opacity));
  }

  .xl\:hover\:tw-text-gray-200:hover {
    --text-opacity: 1;
    color: #edf2f7;
    color: rgba(237, 242, 247, var(--text-opacity));
  }

  .xl\:hover\:tw-text-gray-300:hover {
    --text-opacity: 1;
    color: #e2e8f0;
    color: rgba(226, 232, 240, var(--text-opacity));
  }

  .xl\:hover\:tw-text-gray-400:hover {
    --text-opacity: 1;
    color: #cbd5e0;
    color: rgba(203, 213, 224, var(--text-opacity));
  }

  .xl\:hover\:tw-text-gray-500:hover {
    --text-opacity: 1;
    color: #a0aec0;
    color: rgba(160, 174, 192, var(--text-opacity));
  }

  .xl\:hover\:tw-text-gray-600:hover {
    --text-opacity: 1;
    color: #718096;
    color: rgba(113, 128, 150, var(--text-opacity));
  }

  .xl\:hover\:tw-text-gray-700:hover {
    --text-opacity: 1;
    color: #4a5568;
    color: rgba(74, 85, 104, var(--text-opacity));
  }

  .xl\:hover\:tw-text-gray-800:hover {
    --text-opacity: 1;
    color: #2d3748;
    color: rgba(45, 55, 72, var(--text-opacity));
  }

  .xl\:hover\:tw-text-gray-900:hover {
    --text-opacity: 1;
    color: #1a202c;
    color: rgba(26, 32, 44, var(--text-opacity));
  }

  .xl\:hover\:tw-text-red-100:hover {
    --text-opacity: 1;
    color: #fff5f5;
    color: rgba(255, 245, 245, var(--text-opacity));
  }

  .xl\:hover\:tw-text-red-200:hover {
    --text-opacity: 1;
    color: #fed7d7;
    color: rgba(254, 215, 215, var(--text-opacity));
  }

  .xl\:hover\:tw-text-red-300:hover {
    --text-opacity: 1;
    color: #feb2b2;
    color: rgba(254, 178, 178, var(--text-opacity));
  }

  .xl\:hover\:tw-text-red-400:hover {
    --text-opacity: 1;
    color: #fc8181;
    color: rgba(252, 129, 129, var(--text-opacity));
  }

  .xl\:hover\:tw-text-red-500:hover {
    --text-opacity: 1;
    color: #f56565;
    color: rgba(245, 101, 101, var(--text-opacity));
  }

  .xl\:hover\:tw-text-red-600:hover {
    --text-opacity: 1;
    color: #e53e3e;
    color: rgba(229, 62, 62, var(--text-opacity));
  }

  .xl\:hover\:tw-text-red-700:hover {
    --text-opacity: 1;
    color: #c53030;
    color: rgba(197, 48, 48, var(--text-opacity));
  }

  .xl\:hover\:tw-text-red-800:hover {
    --text-opacity: 1;
    color: #9b2c2c;
    color: rgba(155, 44, 44, var(--text-opacity));
  }

  .xl\:hover\:tw-text-red-900:hover {
    --text-opacity: 1;
    color: #742a2a;
    color: rgba(116, 42, 42, var(--text-opacity));
  }

  .xl\:hover\:tw-text-orange-100:hover {
    --text-opacity: 1;
    color: #fffaf0;
    color: rgba(255, 250, 240, var(--text-opacity));
  }

  .xl\:hover\:tw-text-orange-200:hover {
    --text-opacity: 1;
    color: #feebc8;
    color: rgba(254, 235, 200, var(--text-opacity));
  }

  .xl\:hover\:tw-text-orange-300:hover {
    --text-opacity: 1;
    color: #fbd38d;
    color: rgba(251, 211, 141, var(--text-opacity));
  }

  .xl\:hover\:tw-text-orange-400:hover {
    --text-opacity: 1;
    color: #f6ad55;
    color: rgba(246, 173, 85, var(--text-opacity));
  }

  .xl\:hover\:tw-text-orange-500:hover {
    --text-opacity: 1;
    color: #ed8936;
    color: rgba(237, 137, 54, var(--text-opacity));
  }

  .xl\:hover\:tw-text-orange-600:hover {
    --text-opacity: 1;
    color: #dd6b20;
    color: rgba(221, 107, 32, var(--text-opacity));
  }

  .xl\:hover\:tw-text-orange-700:hover {
    --text-opacity: 1;
    color: #c05621;
    color: rgba(192, 86, 33, var(--text-opacity));
  }

  .xl\:hover\:tw-text-orange-800:hover {
    --text-opacity: 1;
    color: #9c4221;
    color: rgba(156, 66, 33, var(--text-opacity));
  }

  .xl\:hover\:tw-text-orange-900:hover {
    --text-opacity: 1;
    color: #7b341e;
    color: rgba(123, 52, 30, var(--text-opacity));
  }

  .xl\:hover\:tw-text-yellow-100:hover {
    --text-opacity: 1;
    color: #fffff0;
    color: rgba(255, 255, 240, var(--text-opacity));
  }

  .xl\:hover\:tw-text-yellow-200:hover {
    --text-opacity: 1;
    color: #fefcbf;
    color: rgba(254, 252, 191, var(--text-opacity));
  }

  .xl\:hover\:tw-text-yellow-300:hover {
    --text-opacity: 1;
    color: #faf089;
    color: rgba(250, 240, 137, var(--text-opacity));
  }

  .xl\:hover\:tw-text-yellow-400:hover {
    --text-opacity: 1;
    color: #f6e05e;
    color: rgba(246, 224, 94, var(--text-opacity));
  }

  .xl\:hover\:tw-text-yellow-500:hover {
    --text-opacity: 1;
    color: #ecc94b;
    color: rgba(236, 201, 75, var(--text-opacity));
  }

  .xl\:hover\:tw-text-yellow-600:hover {
    --text-opacity: 1;
    color: #d69e2e;
    color: rgba(214, 158, 46, var(--text-opacity));
  }

  .xl\:hover\:tw-text-yellow-700:hover {
    --text-opacity: 1;
    color: #b7791f;
    color: rgba(183, 121, 31, var(--text-opacity));
  }

  .xl\:hover\:tw-text-yellow-800:hover {
    --text-opacity: 1;
    color: #975a16;
    color: rgba(151, 90, 22, var(--text-opacity));
  }

  .xl\:hover\:tw-text-yellow-900:hover {
    --text-opacity: 1;
    color: #744210;
    color: rgba(116, 66, 16, var(--text-opacity));
  }

  .xl\:hover\:tw-text-green-100:hover {
    --text-opacity: 1;
    color: #f0fff4;
    color: rgba(240, 255, 244, var(--text-opacity));
  }

  .xl\:hover\:tw-text-green-200:hover {
    --text-opacity: 1;
    color: #c6f6d5;
    color: rgba(198, 246, 213, var(--text-opacity));
  }

  .xl\:hover\:tw-text-green-300:hover {
    --text-opacity: 1;
    color: #9ae6b4;
    color: rgba(154, 230, 180, var(--text-opacity));
  }

  .xl\:hover\:tw-text-green-400:hover {
    --text-opacity: 1;
    color: #68d391;
    color: rgba(104, 211, 145, var(--text-opacity));
  }

  .xl\:hover\:tw-text-green-500:hover {
    --text-opacity: 1;
    color: #48bb78;
    color: rgba(72, 187, 120, var(--text-opacity));
  }

  .xl\:hover\:tw-text-green-600:hover {
    --text-opacity: 1;
    color: #38a169;
    color: rgba(56, 161, 105, var(--text-opacity));
  }

  .xl\:hover\:tw-text-green-700:hover {
    --text-opacity: 1;
    color: #2f855a;
    color: rgba(47, 133, 90, var(--text-opacity));
  }

  .xl\:hover\:tw-text-green-800:hover {
    --text-opacity: 1;
    color: #276749;
    color: rgba(39, 103, 73, var(--text-opacity));
  }

  .xl\:hover\:tw-text-green-900:hover {
    --text-opacity: 1;
    color: #22543d;
    color: rgba(34, 84, 61, var(--text-opacity));
  }

  .xl\:hover\:tw-text-teal-100:hover {
    --text-opacity: 1;
    color: #e6fffa;
    color: rgba(230, 255, 250, var(--text-opacity));
  }

  .xl\:hover\:tw-text-teal-200:hover {
    --text-opacity: 1;
    color: #b2f5ea;
    color: rgba(178, 245, 234, var(--text-opacity));
  }

  .xl\:hover\:tw-text-teal-300:hover {
    --text-opacity: 1;
    color: #81e6d9;
    color: rgba(129, 230, 217, var(--text-opacity));
  }

  .xl\:hover\:tw-text-teal-400:hover {
    --text-opacity: 1;
    color: #4fd1c5;
    color: rgba(79, 209, 197, var(--text-opacity));
  }

  .xl\:hover\:tw-text-teal-500:hover {
    --text-opacity: 1;
    color: #38b2ac;
    color: rgba(56, 178, 172, var(--text-opacity));
  }

  .xl\:hover\:tw-text-teal-600:hover {
    --text-opacity: 1;
    color: #319795;
    color: rgba(49, 151, 149, var(--text-opacity));
  }

  .xl\:hover\:tw-text-teal-700:hover {
    --text-opacity: 1;
    color: #2c7a7b;
    color: rgba(44, 122, 123, var(--text-opacity));
  }

  .xl\:hover\:tw-text-teal-800:hover {
    --text-opacity: 1;
    color: #285e61;
    color: rgba(40, 94, 97, var(--text-opacity));
  }

  .xl\:hover\:tw-text-teal-900:hover {
    --text-opacity: 1;
    color: #234e52;
    color: rgba(35, 78, 82, var(--text-opacity));
  }

  .xl\:hover\:tw-text-blue-100:hover {
    --text-opacity: 1;
    color: #ebf8ff;
    color: rgba(235, 248, 255, var(--text-opacity));
  }

  .xl\:hover\:tw-text-blue-200:hover {
    --text-opacity: 1;
    color: #bee3f8;
    color: rgba(190, 227, 248, var(--text-opacity));
  }

  .xl\:hover\:tw-text-blue-300:hover {
    --text-opacity: 1;
    color: #90cdf4;
    color: rgba(144, 205, 244, var(--text-opacity));
  }

  .xl\:hover\:tw-text-blue-400:hover {
    --text-opacity: 1;
    color: #63b3ed;
    color: rgba(99, 179, 237, var(--text-opacity));
  }

  .xl\:hover\:tw-text-blue-500:hover {
    --text-opacity: 1;
    color: #4299e1;
    color: rgba(66, 153, 225, var(--text-opacity));
  }

  .xl\:hover\:tw-text-blue-600:hover {
    --text-opacity: 1;
    color: #3182ce;
    color: rgba(49, 130, 206, var(--text-opacity));
  }

  .xl\:hover\:tw-text-blue-700:hover {
    --text-opacity: 1;
    color: #2b6cb0;
    color: rgba(43, 108, 176, var(--text-opacity));
  }

  .xl\:hover\:tw-text-blue-800:hover {
    --text-opacity: 1;
    color: #2c5282;
    color: rgba(44, 82, 130, var(--text-opacity));
  }

  .xl\:hover\:tw-text-blue-900:hover {
    --text-opacity: 1;
    color: #2a4365;
    color: rgba(42, 67, 101, var(--text-opacity));
  }

  .xl\:hover\:tw-text-indigo-100:hover {
    --text-opacity: 1;
    color: #ebf4ff;
    color: rgba(235, 244, 255, var(--text-opacity));
  }

  .xl\:hover\:tw-text-indigo-200:hover {
    --text-opacity: 1;
    color: #c3dafe;
    color: rgba(195, 218, 254, var(--text-opacity));
  }

  .xl\:hover\:tw-text-indigo-300:hover {
    --text-opacity: 1;
    color: #a3bffa;
    color: rgba(163, 191, 250, var(--text-opacity));
  }

  .xl\:hover\:tw-text-indigo-400:hover {
    --text-opacity: 1;
    color: #7f9cf5;
    color: rgba(127, 156, 245, var(--text-opacity));
  }

  .xl\:hover\:tw-text-indigo-500:hover {
    --text-opacity: 1;
    color: #667eea;
    color: rgba(102, 126, 234, var(--text-opacity));
  }

  .xl\:hover\:tw-text-indigo-600:hover {
    --text-opacity: 1;
    color: #5a67d8;
    color: rgba(90, 103, 216, var(--text-opacity));
  }

  .xl\:hover\:tw-text-indigo-700:hover {
    --text-opacity: 1;
    color: #4c51bf;
    color: rgba(76, 81, 191, var(--text-opacity));
  }

  .xl\:hover\:tw-text-indigo-800:hover {
    --text-opacity: 1;
    color: #434190;
    color: rgba(67, 65, 144, var(--text-opacity));
  }

  .xl\:hover\:tw-text-indigo-900:hover {
    --text-opacity: 1;
    color: #3c366b;
    color: rgba(60, 54, 107, var(--text-opacity));
  }

  .xl\:hover\:tw-text-purple-100:hover {
    --text-opacity: 1;
    color: #faf5ff;
    color: rgba(250, 245, 255, var(--text-opacity));
  }

  .xl\:hover\:tw-text-purple-200:hover {
    --text-opacity: 1;
    color: #e9d8fd;
    color: rgba(233, 216, 253, var(--text-opacity));
  }

  .xl\:hover\:tw-text-purple-300:hover {
    --text-opacity: 1;
    color: #d6bcfa;
    color: rgba(214, 188, 250, var(--text-opacity));
  }

  .xl\:hover\:tw-text-purple-400:hover {
    --text-opacity: 1;
    color: #b794f4;
    color: rgba(183, 148, 244, var(--text-opacity));
  }

  .xl\:hover\:tw-text-purple-500:hover {
    --text-opacity: 1;
    color: #9f7aea;
    color: rgba(159, 122, 234, var(--text-opacity));
  }

  .xl\:hover\:tw-text-purple-600:hover {
    --text-opacity: 1;
    color: #805ad5;
    color: rgba(128, 90, 213, var(--text-opacity));
  }

  .xl\:hover\:tw-text-purple-700:hover {
    --text-opacity: 1;
    color: #6b46c1;
    color: rgba(107, 70, 193, var(--text-opacity));
  }

  .xl\:hover\:tw-text-purple-800:hover {
    --text-opacity: 1;
    color: #553c9a;
    color: rgba(85, 60, 154, var(--text-opacity));
  }

  .xl\:hover\:tw-text-purple-900:hover {
    --text-opacity: 1;
    color: #44337a;
    color: rgba(68, 51, 122, var(--text-opacity));
  }

  .xl\:hover\:tw-text-pink-100:hover {
    --text-opacity: 1;
    color: #fff5f7;
    color: rgba(255, 245, 247, var(--text-opacity));
  }

  .xl\:hover\:tw-text-pink-200:hover {
    --text-opacity: 1;
    color: #fed7e2;
    color: rgba(254, 215, 226, var(--text-opacity));
  }

  .xl\:hover\:tw-text-pink-300:hover {
    --text-opacity: 1;
    color: #fbb6ce;
    color: rgba(251, 182, 206, var(--text-opacity));
  }

  .xl\:hover\:tw-text-pink-400:hover {
    --text-opacity: 1;
    color: #f687b3;
    color: rgba(246, 135, 179, var(--text-opacity));
  }

  .xl\:hover\:tw-text-pink-500:hover {
    --text-opacity: 1;
    color: #ed64a6;
    color: rgba(237, 100, 166, var(--text-opacity));
  }

  .xl\:hover\:tw-text-pink-600:hover {
    --text-opacity: 1;
    color: #d53f8c;
    color: rgba(213, 63, 140, var(--text-opacity));
  }

  .xl\:hover\:tw-text-pink-700:hover {
    --text-opacity: 1;
    color: #b83280;
    color: rgba(184, 50, 128, var(--text-opacity));
  }

  .xl\:hover\:tw-text-pink-800:hover {
    --text-opacity: 1;
    color: #97266d;
    color: rgba(151, 38, 109, var(--text-opacity));
  }

  .xl\:hover\:tw-text-pink-900:hover {
    --text-opacity: 1;
    color: #702459;
    color: rgba(112, 36, 89, var(--text-opacity));
  }

  .xl\:focus\:tw-text-transparent:focus {
    color: transparent;
  }

  .xl\:focus\:tw-text-current:focus {
    color: currentColor;
  }

  .xl\:focus\:tw-text-black:focus {
    --text-opacity: 1;
    color: #000;
    color: rgba(0, 0, 0, var(--text-opacity));
  }

  .xl\:focus\:tw-text-white:focus {
    --text-opacity: 1;
    color: #fff;
    color: rgba(255, 255, 255, var(--text-opacity));
  }

  .xl\:focus\:tw-text-gray-100:focus {
    --text-opacity: 1;
    color: #f7fafc;
    color: rgba(247, 250, 252, var(--text-opacity));
  }

  .xl\:focus\:tw-text-gray-200:focus {
    --text-opacity: 1;
    color: #edf2f7;
    color: rgba(237, 242, 247, var(--text-opacity));
  }

  .xl\:focus\:tw-text-gray-300:focus {
    --text-opacity: 1;
    color: #e2e8f0;
    color: rgba(226, 232, 240, var(--text-opacity));
  }

  .xl\:focus\:tw-text-gray-400:focus {
    --text-opacity: 1;
    color: #cbd5e0;
    color: rgba(203, 213, 224, var(--text-opacity));
  }

  .xl\:focus\:tw-text-gray-500:focus {
    --text-opacity: 1;
    color: #a0aec0;
    color: rgba(160, 174, 192, var(--text-opacity));
  }

  .xl\:focus\:tw-text-gray-600:focus {
    --text-opacity: 1;
    color: #718096;
    color: rgba(113, 128, 150, var(--text-opacity));
  }

  .xl\:focus\:tw-text-gray-700:focus {
    --text-opacity: 1;
    color: #4a5568;
    color: rgba(74, 85, 104, var(--text-opacity));
  }

  .xl\:focus\:tw-text-gray-800:focus {
    --text-opacity: 1;
    color: #2d3748;
    color: rgba(45, 55, 72, var(--text-opacity));
  }

  .xl\:focus\:tw-text-gray-900:focus {
    --text-opacity: 1;
    color: #1a202c;
    color: rgba(26, 32, 44, var(--text-opacity));
  }

  .xl\:focus\:tw-text-red-100:focus {
    --text-opacity: 1;
    color: #fff5f5;
    color: rgba(255, 245, 245, var(--text-opacity));
  }

  .xl\:focus\:tw-text-red-200:focus {
    --text-opacity: 1;
    color: #fed7d7;
    color: rgba(254, 215, 215, var(--text-opacity));
  }

  .xl\:focus\:tw-text-red-300:focus {
    --text-opacity: 1;
    color: #feb2b2;
    color: rgba(254, 178, 178, var(--text-opacity));
  }

  .xl\:focus\:tw-text-red-400:focus {
    --text-opacity: 1;
    color: #fc8181;
    color: rgba(252, 129, 129, var(--text-opacity));
  }

  .xl\:focus\:tw-text-red-500:focus {
    --text-opacity: 1;
    color: #f56565;
    color: rgba(245, 101, 101, var(--text-opacity));
  }

  .xl\:focus\:tw-text-red-600:focus {
    --text-opacity: 1;
    color: #e53e3e;
    color: rgba(229, 62, 62, var(--text-opacity));
  }

  .xl\:focus\:tw-text-red-700:focus {
    --text-opacity: 1;
    color: #c53030;
    color: rgba(197, 48, 48, var(--text-opacity));
  }

  .xl\:focus\:tw-text-red-800:focus {
    --text-opacity: 1;
    color: #9b2c2c;
    color: rgba(155, 44, 44, var(--text-opacity));
  }

  .xl\:focus\:tw-text-red-900:focus {
    --text-opacity: 1;
    color: #742a2a;
    color: rgba(116, 42, 42, var(--text-opacity));
  }

  .xl\:focus\:tw-text-orange-100:focus {
    --text-opacity: 1;
    color: #fffaf0;
    color: rgba(255, 250, 240, var(--text-opacity));
  }

  .xl\:focus\:tw-text-orange-200:focus {
    --text-opacity: 1;
    color: #feebc8;
    color: rgba(254, 235, 200, var(--text-opacity));
  }

  .xl\:focus\:tw-text-orange-300:focus {
    --text-opacity: 1;
    color: #fbd38d;
    color: rgba(251, 211, 141, var(--text-opacity));
  }

  .xl\:focus\:tw-text-orange-400:focus {
    --text-opacity: 1;
    color: #f6ad55;
    color: rgba(246, 173, 85, var(--text-opacity));
  }

  .xl\:focus\:tw-text-orange-500:focus {
    --text-opacity: 1;
    color: #ed8936;
    color: rgba(237, 137, 54, var(--text-opacity));
  }

  .xl\:focus\:tw-text-orange-600:focus {
    --text-opacity: 1;
    color: #dd6b20;
    color: rgba(221, 107, 32, var(--text-opacity));
  }

  .xl\:focus\:tw-text-orange-700:focus {
    --text-opacity: 1;
    color: #c05621;
    color: rgba(192, 86, 33, var(--text-opacity));
  }

  .xl\:focus\:tw-text-orange-800:focus {
    --text-opacity: 1;
    color: #9c4221;
    color: rgba(156, 66, 33, var(--text-opacity));
  }

  .xl\:focus\:tw-text-orange-900:focus {
    --text-opacity: 1;
    color: #7b341e;
    color: rgba(123, 52, 30, var(--text-opacity));
  }

  .xl\:focus\:tw-text-yellow-100:focus {
    --text-opacity: 1;
    color: #fffff0;
    color: rgba(255, 255, 240, var(--text-opacity));
  }

  .xl\:focus\:tw-text-yellow-200:focus {
    --text-opacity: 1;
    color: #fefcbf;
    color: rgba(254, 252, 191, var(--text-opacity));
  }

  .xl\:focus\:tw-text-yellow-300:focus {
    --text-opacity: 1;
    color: #faf089;
    color: rgba(250, 240, 137, var(--text-opacity));
  }

  .xl\:focus\:tw-text-yellow-400:focus {
    --text-opacity: 1;
    color: #f6e05e;
    color: rgba(246, 224, 94, var(--text-opacity));
  }

  .xl\:focus\:tw-text-yellow-500:focus {
    --text-opacity: 1;
    color: #ecc94b;
    color: rgba(236, 201, 75, var(--text-opacity));
  }

  .xl\:focus\:tw-text-yellow-600:focus {
    --text-opacity: 1;
    color: #d69e2e;
    color: rgba(214, 158, 46, var(--text-opacity));
  }

  .xl\:focus\:tw-text-yellow-700:focus {
    --text-opacity: 1;
    color: #b7791f;
    color: rgba(183, 121, 31, var(--text-opacity));
  }

  .xl\:focus\:tw-text-yellow-800:focus {
    --text-opacity: 1;
    color: #975a16;
    color: rgba(151, 90, 22, var(--text-opacity));
  }

  .xl\:focus\:tw-text-yellow-900:focus {
    --text-opacity: 1;
    color: #744210;
    color: rgba(116, 66, 16, var(--text-opacity));
  }

  .xl\:focus\:tw-text-green-100:focus {
    --text-opacity: 1;
    color: #f0fff4;
    color: rgba(240, 255, 244, var(--text-opacity));
  }

  .xl\:focus\:tw-text-green-200:focus {
    --text-opacity: 1;
    color: #c6f6d5;
    color: rgba(198, 246, 213, var(--text-opacity));
  }

  .xl\:focus\:tw-text-green-300:focus {
    --text-opacity: 1;
    color: #9ae6b4;
    color: rgba(154, 230, 180, var(--text-opacity));
  }

  .xl\:focus\:tw-text-green-400:focus {
    --text-opacity: 1;
    color: #68d391;
    color: rgba(104, 211, 145, var(--text-opacity));
  }

  .xl\:focus\:tw-text-green-500:focus {
    --text-opacity: 1;
    color: #48bb78;
    color: rgba(72, 187, 120, var(--text-opacity));
  }

  .xl\:focus\:tw-text-green-600:focus {
    --text-opacity: 1;
    color: #38a169;
    color: rgba(56, 161, 105, var(--text-opacity));
  }

  .xl\:focus\:tw-text-green-700:focus {
    --text-opacity: 1;
    color: #2f855a;
    color: rgba(47, 133, 90, var(--text-opacity));
  }

  .xl\:focus\:tw-text-green-800:focus {
    --text-opacity: 1;
    color: #276749;
    color: rgba(39, 103, 73, var(--text-opacity));
  }

  .xl\:focus\:tw-text-green-900:focus {
    --text-opacity: 1;
    color: #22543d;
    color: rgba(34, 84, 61, var(--text-opacity));
  }

  .xl\:focus\:tw-text-teal-100:focus {
    --text-opacity: 1;
    color: #e6fffa;
    color: rgba(230, 255, 250, var(--text-opacity));
  }

  .xl\:focus\:tw-text-teal-200:focus {
    --text-opacity: 1;
    color: #b2f5ea;
    color: rgba(178, 245, 234, var(--text-opacity));
  }

  .xl\:focus\:tw-text-teal-300:focus {
    --text-opacity: 1;
    color: #81e6d9;
    color: rgba(129, 230, 217, var(--text-opacity));
  }

  .xl\:focus\:tw-text-teal-400:focus {
    --text-opacity: 1;
    color: #4fd1c5;
    color: rgba(79, 209, 197, var(--text-opacity));
  }

  .xl\:focus\:tw-text-teal-500:focus {
    --text-opacity: 1;
    color: #38b2ac;
    color: rgba(56, 178, 172, var(--text-opacity));
  }

  .xl\:focus\:tw-text-teal-600:focus {
    --text-opacity: 1;
    color: #319795;
    color: rgba(49, 151, 149, var(--text-opacity));
  }

  .xl\:focus\:tw-text-teal-700:focus {
    --text-opacity: 1;
    color: #2c7a7b;
    color: rgba(44, 122, 123, var(--text-opacity));
  }

  .xl\:focus\:tw-text-teal-800:focus {
    --text-opacity: 1;
    color: #285e61;
    color: rgba(40, 94, 97, var(--text-opacity));
  }

  .xl\:focus\:tw-text-teal-900:focus {
    --text-opacity: 1;
    color: #234e52;
    color: rgba(35, 78, 82, var(--text-opacity));
  }

  .xl\:focus\:tw-text-blue-100:focus {
    --text-opacity: 1;
    color: #ebf8ff;
    color: rgba(235, 248, 255, var(--text-opacity));
  }

  .xl\:focus\:tw-text-blue-200:focus {
    --text-opacity: 1;
    color: #bee3f8;
    color: rgba(190, 227, 248, var(--text-opacity));
  }

  .xl\:focus\:tw-text-blue-300:focus {
    --text-opacity: 1;
    color: #90cdf4;
    color: rgba(144, 205, 244, var(--text-opacity));
  }

  .xl\:focus\:tw-text-blue-400:focus {
    --text-opacity: 1;
    color: #63b3ed;
    color: rgba(99, 179, 237, var(--text-opacity));
  }

  .xl\:focus\:tw-text-blue-500:focus {
    --text-opacity: 1;
    color: #4299e1;
    color: rgba(66, 153, 225, var(--text-opacity));
  }

  .xl\:focus\:tw-text-blue-600:focus {
    --text-opacity: 1;
    color: #3182ce;
    color: rgba(49, 130, 206, var(--text-opacity));
  }

  .xl\:focus\:tw-text-blue-700:focus {
    --text-opacity: 1;
    color: #2b6cb0;
    color: rgba(43, 108, 176, var(--text-opacity));
  }

  .xl\:focus\:tw-text-blue-800:focus {
    --text-opacity: 1;
    color: #2c5282;
    color: rgba(44, 82, 130, var(--text-opacity));
  }

  .xl\:focus\:tw-text-blue-900:focus {
    --text-opacity: 1;
    color: #2a4365;
    color: rgba(42, 67, 101, var(--text-opacity));
  }

  .xl\:focus\:tw-text-indigo-100:focus {
    --text-opacity: 1;
    color: #ebf4ff;
    color: rgba(235, 244, 255, var(--text-opacity));
  }

  .xl\:focus\:tw-text-indigo-200:focus {
    --text-opacity: 1;
    color: #c3dafe;
    color: rgba(195, 218, 254, var(--text-opacity));
  }

  .xl\:focus\:tw-text-indigo-300:focus {
    --text-opacity: 1;
    color: #a3bffa;
    color: rgba(163, 191, 250, var(--text-opacity));
  }

  .xl\:focus\:tw-text-indigo-400:focus {
    --text-opacity: 1;
    color: #7f9cf5;
    color: rgba(127, 156, 245, var(--text-opacity));
  }

  .xl\:focus\:tw-text-indigo-500:focus {
    --text-opacity: 1;
    color: #667eea;
    color: rgba(102, 126, 234, var(--text-opacity));
  }

  .xl\:focus\:tw-text-indigo-600:focus {
    --text-opacity: 1;
    color: #5a67d8;
    color: rgba(90, 103, 216, var(--text-opacity));
  }

  .xl\:focus\:tw-text-indigo-700:focus {
    --text-opacity: 1;
    color: #4c51bf;
    color: rgba(76, 81, 191, var(--text-opacity));
  }

  .xl\:focus\:tw-text-indigo-800:focus {
    --text-opacity: 1;
    color: #434190;
    color: rgba(67, 65, 144, var(--text-opacity));
  }

  .xl\:focus\:tw-text-indigo-900:focus {
    --text-opacity: 1;
    color: #3c366b;
    color: rgba(60, 54, 107, var(--text-opacity));
  }

  .xl\:focus\:tw-text-purple-100:focus {
    --text-opacity: 1;
    color: #faf5ff;
    color: rgba(250, 245, 255, var(--text-opacity));
  }

  .xl\:focus\:tw-text-purple-200:focus {
    --text-opacity: 1;
    color: #e9d8fd;
    color: rgba(233, 216, 253, var(--text-opacity));
  }

  .xl\:focus\:tw-text-purple-300:focus {
    --text-opacity: 1;
    color: #d6bcfa;
    color: rgba(214, 188, 250, var(--text-opacity));
  }

  .xl\:focus\:tw-text-purple-400:focus {
    --text-opacity: 1;
    color: #b794f4;
    color: rgba(183, 148, 244, var(--text-opacity));
  }

  .xl\:focus\:tw-text-purple-500:focus {
    --text-opacity: 1;
    color: #9f7aea;
    color: rgba(159, 122, 234, var(--text-opacity));
  }

  .xl\:focus\:tw-text-purple-600:focus {
    --text-opacity: 1;
    color: #805ad5;
    color: rgba(128, 90, 213, var(--text-opacity));
  }

  .xl\:focus\:tw-text-purple-700:focus {
    --text-opacity: 1;
    color: #6b46c1;
    color: rgba(107, 70, 193, var(--text-opacity));
  }

  .xl\:focus\:tw-text-purple-800:focus {
    --text-opacity: 1;
    color: #553c9a;
    color: rgba(85, 60, 154, var(--text-opacity));
  }

  .xl\:focus\:tw-text-purple-900:focus {
    --text-opacity: 1;
    color: #44337a;
    color: rgba(68, 51, 122, var(--text-opacity));
  }

  .xl\:focus\:tw-text-pink-100:focus {
    --text-opacity: 1;
    color: #fff5f7;
    color: rgba(255, 245, 247, var(--text-opacity));
  }

  .xl\:focus\:tw-text-pink-200:focus {
    --text-opacity: 1;
    color: #fed7e2;
    color: rgba(254, 215, 226, var(--text-opacity));
  }

  .xl\:focus\:tw-text-pink-300:focus {
    --text-opacity: 1;
    color: #fbb6ce;
    color: rgba(251, 182, 206, var(--text-opacity));
  }

  .xl\:focus\:tw-text-pink-400:focus {
    --text-opacity: 1;
    color: #f687b3;
    color: rgba(246, 135, 179, var(--text-opacity));
  }

  .xl\:focus\:tw-text-pink-500:focus {
    --text-opacity: 1;
    color: #ed64a6;
    color: rgba(237, 100, 166, var(--text-opacity));
  }

  .xl\:focus\:tw-text-pink-600:focus {
    --text-opacity: 1;
    color: #d53f8c;
    color: rgba(213, 63, 140, var(--text-opacity));
  }

  .xl\:focus\:tw-text-pink-700:focus {
    --text-opacity: 1;
    color: #b83280;
    color: rgba(184, 50, 128, var(--text-opacity));
  }

  .xl\:focus\:tw-text-pink-800:focus {
    --text-opacity: 1;
    color: #97266d;
    color: rgba(151, 38, 109, var(--text-opacity));
  }

  .xl\:focus\:tw-text-pink-900:focus {
    --text-opacity: 1;
    color: #702459;
    color: rgba(112, 36, 89, var(--text-opacity));
  }

  .xl\:tw-text-opacity-0 {
    --text-opacity: 0;
  }

  .xl\:tw-text-opacity-25 {
    --text-opacity: 0.25;
  }

  .xl\:tw-text-opacity-50 {
    --text-opacity: 0.5;
  }

  .xl\:tw-text-opacity-75 {
    --text-opacity: 0.75;
  }

  .xl\:tw-text-opacity-100 {
    --text-opacity: 1;
  }

  .xl\:hover\:tw-text-opacity-0:hover {
    --text-opacity: 0;
  }

  .xl\:hover\:tw-text-opacity-25:hover {
    --text-opacity: 0.25;
  }

  .xl\:hover\:tw-text-opacity-50:hover {
    --text-opacity: 0.5;
  }

  .xl\:hover\:tw-text-opacity-75:hover {
    --text-opacity: 0.75;
  }

  .xl\:hover\:tw-text-opacity-100:hover {
    --text-opacity: 1;
  }

  .xl\:focus\:tw-text-opacity-0:focus {
    --text-opacity: 0;
  }

  .xl\:focus\:tw-text-opacity-25:focus {
    --text-opacity: 0.25;
  }

  .xl\:focus\:tw-text-opacity-50:focus {
    --text-opacity: 0.5;
  }

  .xl\:focus\:tw-text-opacity-75:focus {
    --text-opacity: 0.75;
  }

  .xl\:focus\:tw-text-opacity-100:focus {
    --text-opacity: 1;
  }

  .xl\:tw-italic {
    font-style: italic;
  }

  .xl\:tw-not-italic {
    font-style: normal;
  }

  .xl\:tw-uppercase {
    text-transform: uppercase;
  }

  .xl\:tw-lowercase {
    text-transform: lowercase;
  }

  .xl\:tw-capitalize {
    text-transform: capitalize;
  }

  .xl\:tw-normal-case {
    text-transform: none;
  }

  .xl\:tw-underline {
    text-decoration: underline;
  }

  .xl\:tw-line-through {
    text-decoration: line-through;
  }

  .xl\:tw-no-underline {
    text-decoration: none;
  }

  .xl\:hover\:tw-underline:hover {
    text-decoration: underline;
  }

  .xl\:hover\:tw-line-through:hover {
    text-decoration: line-through;
  }

  .xl\:hover\:tw-no-underline:hover {
    text-decoration: none;
  }

  .xl\:focus\:tw-underline:focus {
    text-decoration: underline;
  }

  .xl\:focus\:tw-line-through:focus {
    text-decoration: line-through;
  }

  .xl\:focus\:tw-no-underline:focus {
    text-decoration: none;
  }

  .xl\:tw-antialiased {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  .xl\:tw-subpixel-antialiased {
    -webkit-font-smoothing: auto;
    -moz-osx-font-smoothing: auto;
  }

  .xl\:tw-ordinal, .xl\:tw-slashed-zero, .xl\:tw-lining-nums, .xl\:tw-oldstyle-nums, .xl\:tw-proportional-nums, .xl\:tw-tabular-nums, .xl\:tw-diagonal-fractions, .xl\:tw-stacked-fractions {
    --font-variant-numeric-ordinal: var(--tailwind-empty,/*!*/ /*!*/);
    --font-variant-numeric-slashed-zero: var(--tailwind-empty,/*!*/ /*!*/);
    --font-variant-numeric-figure: var(--tailwind-empty,/*!*/ /*!*/);
    --font-variant-numeric-spacing: var(--tailwind-empty,/*!*/ /*!*/);
    --font-variant-numeric-fraction: var(--tailwind-empty,/*!*/ /*!*/);
    font-variant-numeric: var(--font-variant-numeric-ordinal) var(--font-variant-numeric-slashed-zero) var(--font-variant-numeric-figure) var(--font-variant-numeric-spacing) var(--font-variant-numeric-fraction);
  }

  .xl\:tw-normal-nums {
    font-variant-numeric: normal;
  }

  .xl\:tw-ordinal {
    --font-variant-numeric-ordinal: ordinal;
  }

  .xl\:tw-slashed-zero {
    --font-variant-numeric-slashed-zero: slashed-zero;
  }

  .xl\:tw-lining-nums {
    --font-variant-numeric-figure: lining-nums;
  }

  .xl\:tw-oldstyle-nums {
    --font-variant-numeric-figure: oldstyle-nums;
  }

  .xl\:tw-proportional-nums {
    --font-variant-numeric-spacing: proportional-nums;
  }

  .xl\:tw-tabular-nums {
    --font-variant-numeric-spacing: tabular-nums;
  }

  .xl\:tw-diagonal-fractions {
    --font-variant-numeric-fraction: diagonal-fractions;
  }

  .xl\:tw-stacked-fractions {
    --font-variant-numeric-fraction: stacked-fractions;
  }

  .xl\:tw-tracking-tighter {
    letter-spacing: -0.05em;
  }

  .xl\:tw-tracking-tight {
    letter-spacing: -0.025em;
  }

  .xl\:tw-tracking-normal {
    letter-spacing: 0;
  }

  .xl\:tw-tracking-wide {
    letter-spacing: 0.025em;
  }

  .xl\:tw-tracking-wider {
    letter-spacing: 0.05em;
  }

  .xl\:tw-tracking-widest {
    letter-spacing: 0.1em;
  }

  .xl\:tw-select-none {
    -webkit-user-select: none;
       -moz-user-select: none;
            user-select: none;
  }

  .xl\:tw-select-text {
    -webkit-user-select: text;
       -moz-user-select: text;
            user-select: text;
  }

  .xl\:tw-select-all {
    -webkit-user-select: all;
       -moz-user-select: all;
            user-select: all;
  }

  .xl\:tw-select-auto {
    -webkit-user-select: auto;
       -moz-user-select: auto;
            user-select: auto;
  }

  .xl\:tw-align-baseline {
    vertical-align: baseline;
  }

  .xl\:tw-align-top {
    vertical-align: top;
  }

  .xl\:tw-align-middle {
    vertical-align: middle;
  }

  .xl\:tw-align-bottom {
    vertical-align: bottom;
  }

  .xl\:tw-align-text-top {
    vertical-align: text-top;
  }

  .xl\:tw-align-text-bottom {
    vertical-align: text-bottom;
  }

  .xl\:tw-visible {
    visibility: visible;
  }

  .xl\:tw-invisible {
    visibility: hidden;
  }

  .xl\:tw-whitespace-normal {
    white-space: normal;
  }

  .xl\:tw-whitespace-no-wrap {
    white-space: nowrap;
  }

  .xl\:tw-whitespace-pre {
    white-space: pre;
  }

  .xl\:tw-whitespace-pre-line {
    white-space: pre-line;
  }

  .xl\:tw-whitespace-pre-wrap {
    white-space: pre-wrap;
  }

  .xl\:tw-break-normal {
    word-wrap: normal;
    overflow-wrap: normal;
    word-break: normal;
  }

  .xl\:tw-break-words {
    word-wrap: break-word;
    overflow-wrap: break-word;
  }

  .xl\:tw-break-all {
    word-break: break-all;
  }

  .xl\:tw-truncate {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .xl\:tw-w-0 {
    width: 0;
  }

  .xl\:tw-w-1 {
    width: 0.25rem;
  }

  .xl\:tw-w-2 {
    width: 0.5rem;
  }

  .xl\:tw-w-3 {
    width: 0.75rem;
  }

  .xl\:tw-w-4 {
    width: 1rem;
  }

  .xl\:tw-w-5 {
    width: 1.25rem;
  }

  .xl\:tw-w-6 {
    width: 1.5rem;
  }

  .xl\:tw-w-8 {
    width: 2rem;
  }

  .xl\:tw-w-10 {
    width: 2.5rem;
  }

  .xl\:tw-w-12 {
    width: 3rem;
  }

  .xl\:tw-w-16 {
    width: 4rem;
  }

  .xl\:tw-w-20 {
    width: 5rem;
  }

  .xl\:tw-w-24 {
    width: 6rem;
  }

  .xl\:tw-w-32 {
    width: 8rem;
  }

  .xl\:tw-w-40 {
    width: 10rem;
  }

  .xl\:tw-w-48 {
    width: 12rem;
  }

  .xl\:tw-w-56 {
    width: 14rem;
  }

  .xl\:tw-w-64 {
    width: 16rem;
  }

  .xl\:tw-w-auto {
    width: auto;
  }

  .xl\:tw-w-px {
    width: 1px;
  }

  .xl\:tw-w-270px {
    width: 270px;
  }

  .xl\:tw-w-400px {
    width: 400px;
  }

  .xl\:tw-w-464px {
    width: 464px;
  }

  .xl\:tw-w-480px {
    width: 480px;
  }

  .xl\:tw-w-500px {
    width: 500px;
  }

  .xl\:tw-w-576px {
    width: 576px;
  }

  .xl\:tw-w-600px {
    width: 600px;
  }

  .xl\:tw-w-672px {
    width: 672px;
  }

  .xl\:tw-w-1\/2 {
    width: 50%;
  }

  .xl\:tw-w-1\/3 {
    width: 33.333333%;
  }

  .xl\:tw-w-2\/3 {
    width: 66.666667%;
  }

  .xl\:tw-w-1\/4 {
    width: 25%;
  }

  .xl\:tw-w-2\/4 {
    width: 50%;
  }

  .xl\:tw-w-3\/4 {
    width: 75%;
  }

  .xl\:tw-w-1\/5 {
    width: 20%;
  }

  .xl\:tw-w-2\/5 {
    width: 40%;
  }

  .xl\:tw-w-3\/5 {
    width: 60%;
  }

  .xl\:tw-w-4\/5 {
    width: 80%;
  }

  .xl\:tw-w-1\/6 {
    width: 16.666667%;
  }

  .xl\:tw-w-2\/6 {
    width: 33.333333%;
  }

  .xl\:tw-w-3\/6 {
    width: 50%;
  }

  .xl\:tw-w-4\/6 {
    width: 66.666667%;
  }

  .xl\:tw-w-5\/6 {
    width: 83.333333%;
  }

  .xl\:tw-w-1\/12 {
    width: 8.333333%;
  }

  .xl\:tw-w-2\/12 {
    width: 16.666667%;
  }

  .xl\:tw-w-3\/12 {
    width: 25%;
  }

  .xl\:tw-w-4\/12 {
    width: 33.333333%;
  }

  .xl\:tw-w-5\/12 {
    width: 41.666667%;
  }

  .xl\:tw-w-6\/12 {
    width: 50%;
  }

  .xl\:tw-w-7\/12 {
    width: 58.333333%;
  }

  .xl\:tw-w-8\/12 {
    width: 66.666667%;
  }

  .xl\:tw-w-9\/12 {
    width: 75%;
  }

  .xl\:tw-w-10\/12 {
    width: 83.333333%;
  }

  .xl\:tw-w-11\/12 {
    width: 91.666667%;
  }

  .xl\:tw-w-full {
    width: 100%;
  }

  .xl\:tw-w-screen {
    width: 100vw;
  }

  .xl\:tw-z-0 {
    z-index: 0;
  }

  .xl\:tw-z-10 {
    z-index: 10;
  }

  .xl\:tw-z-20 {
    z-index: 20;
  }

  .xl\:tw-z-30 {
    z-index: 30;
  }

  .xl\:tw-z-40 {
    z-index: 40;
  }

  .xl\:tw-z-50 {
    z-index: 50;
  }

  .xl\:tw-z-auto {
    z-index: auto;
  }

  .xl\:tw-gap-0 {
    grid-gap: 0;
    gap: 0;
  }

  .xl\:tw-gap-1 {
    grid-gap: 0.25rem;
    gap: 0.25rem;
  }

  .xl\:tw-gap-2 {
    grid-gap: 0.5rem;
    gap: 0.5rem;
  }

  .xl\:tw-gap-3 {
    grid-gap: 0.75rem;
    gap: 0.75rem;
  }

  .xl\:tw-gap-4 {
    grid-gap: 1rem;
    gap: 1rem;
  }

  .xl\:tw-gap-5 {
    grid-gap: 1.25rem;
    gap: 1.25rem;
  }

  .xl\:tw-gap-6 {
    grid-gap: 1.5rem;
    gap: 1.5rem;
  }

  .xl\:tw-gap-8 {
    grid-gap: 2rem;
    gap: 2rem;
  }

  .xl\:tw-gap-10 {
    grid-gap: 2.5rem;
    gap: 2.5rem;
  }

  .xl\:tw-gap-12 {
    grid-gap: 3rem;
    gap: 3rem;
  }

  .xl\:tw-gap-16 {
    grid-gap: 4rem;
    gap: 4rem;
  }

  .xl\:tw-gap-20 {
    grid-gap: 5rem;
    gap: 5rem;
  }

  .xl\:tw-gap-24 {
    grid-gap: 6rem;
    gap: 6rem;
  }

  .xl\:tw-gap-32 {
    grid-gap: 8rem;
    gap: 8rem;
  }

  .xl\:tw-gap-40 {
    grid-gap: 10rem;
    gap: 10rem;
  }

  .xl\:tw-gap-48 {
    grid-gap: 12rem;
    gap: 12rem;
  }

  .xl\:tw-gap-56 {
    grid-gap: 14rem;
    gap: 14rem;
  }

  .xl\:tw-gap-64 {
    grid-gap: 16rem;
    gap: 16rem;
  }

  .xl\:tw-gap-px {
    grid-gap: 1px;
    gap: 1px;
  }

  .xl\:tw-gap-270px {
    grid-gap: 270px;
    gap: 270px;
  }

  .xl\:tw-gap-400px {
    grid-gap: 400px;
    gap: 400px;
  }

  .xl\:tw-gap-464px {
    grid-gap: 464px;
    gap: 464px;
  }

  .xl\:tw-gap-480px {
    grid-gap: 480px;
    gap: 480px;
  }

  .xl\:tw-gap-500px {
    grid-gap: 500px;
    gap: 500px;
  }

  .xl\:tw-gap-576px {
    grid-gap: 576px;
    gap: 576px;
  }

  .xl\:tw-gap-600px {
    grid-gap: 600px;
    gap: 600px;
  }

  .xl\:tw-gap-672px {
    grid-gap: 672px;
    gap: 672px;
  }

  .xl\:tw-col-gap-0 {
    grid-column-gap: 0;
    -moz-column-gap: 0;
         column-gap: 0;
  }

  .xl\:tw-col-gap-1 {
    grid-column-gap: 0.25rem;
    -moz-column-gap: 0.25rem;
         column-gap: 0.25rem;
  }

  .xl\:tw-col-gap-2 {
    grid-column-gap: 0.5rem;
    -moz-column-gap: 0.5rem;
         column-gap: 0.5rem;
  }

  .xl\:tw-col-gap-3 {
    grid-column-gap: 0.75rem;
    -moz-column-gap: 0.75rem;
         column-gap: 0.75rem;
  }

  .xl\:tw-col-gap-4 {
    grid-column-gap: 1rem;
    -moz-column-gap: 1rem;
         column-gap: 1rem;
  }

  .xl\:tw-col-gap-5 {
    grid-column-gap: 1.25rem;
    -moz-column-gap: 1.25rem;
         column-gap: 1.25rem;
  }

  .xl\:tw-col-gap-6 {
    grid-column-gap: 1.5rem;
    -moz-column-gap: 1.5rem;
         column-gap: 1.5rem;
  }

  .xl\:tw-col-gap-8 {
    grid-column-gap: 2rem;
    -moz-column-gap: 2rem;
         column-gap: 2rem;
  }

  .xl\:tw-col-gap-10 {
    grid-column-gap: 2.5rem;
    -moz-column-gap: 2.5rem;
         column-gap: 2.5rem;
  }

  .xl\:tw-col-gap-12 {
    grid-column-gap: 3rem;
    -moz-column-gap: 3rem;
         column-gap: 3rem;
  }

  .xl\:tw-col-gap-16 {
    grid-column-gap: 4rem;
    -moz-column-gap: 4rem;
         column-gap: 4rem;
  }

  .xl\:tw-col-gap-20 {
    grid-column-gap: 5rem;
    -moz-column-gap: 5rem;
         column-gap: 5rem;
  }

  .xl\:tw-col-gap-24 {
    grid-column-gap: 6rem;
    -moz-column-gap: 6rem;
         column-gap: 6rem;
  }

  .xl\:tw-col-gap-32 {
    grid-column-gap: 8rem;
    -moz-column-gap: 8rem;
         column-gap: 8rem;
  }

  .xl\:tw-col-gap-40 {
    grid-column-gap: 10rem;
    -moz-column-gap: 10rem;
         column-gap: 10rem;
  }

  .xl\:tw-col-gap-48 {
    grid-column-gap: 12rem;
    -moz-column-gap: 12rem;
         column-gap: 12rem;
  }

  .xl\:tw-col-gap-56 {
    grid-column-gap: 14rem;
    -moz-column-gap: 14rem;
         column-gap: 14rem;
  }

  .xl\:tw-col-gap-64 {
    grid-column-gap: 16rem;
    -moz-column-gap: 16rem;
         column-gap: 16rem;
  }

  .xl\:tw-col-gap-px {
    grid-column-gap: 1px;
    -moz-column-gap: 1px;
         column-gap: 1px;
  }

  .xl\:tw-col-gap-270px {
    grid-column-gap: 270px;
    -moz-column-gap: 270px;
         column-gap: 270px;
  }

  .xl\:tw-col-gap-400px {
    grid-column-gap: 400px;
    -moz-column-gap: 400px;
         column-gap: 400px;
  }

  .xl\:tw-col-gap-464px {
    grid-column-gap: 464px;
    -moz-column-gap: 464px;
         column-gap: 464px;
  }

  .xl\:tw-col-gap-480px {
    grid-column-gap: 480px;
    -moz-column-gap: 480px;
         column-gap: 480px;
  }

  .xl\:tw-col-gap-500px {
    grid-column-gap: 500px;
    -moz-column-gap: 500px;
         column-gap: 500px;
  }

  .xl\:tw-col-gap-576px {
    grid-column-gap: 576px;
    -moz-column-gap: 576px;
         column-gap: 576px;
  }

  .xl\:tw-col-gap-600px {
    grid-column-gap: 600px;
    -moz-column-gap: 600px;
         column-gap: 600px;
  }

  .xl\:tw-col-gap-672px {
    grid-column-gap: 672px;
    -moz-column-gap: 672px;
         column-gap: 672px;
  }

  .xl\:tw-gap-x-0 {
    grid-column-gap: 0;
    -moz-column-gap: 0;
         column-gap: 0;
  }

  .xl\:tw-gap-x-1 {
    grid-column-gap: 0.25rem;
    -moz-column-gap: 0.25rem;
         column-gap: 0.25rem;
  }

  .xl\:tw-gap-x-2 {
    grid-column-gap: 0.5rem;
    -moz-column-gap: 0.5rem;
         column-gap: 0.5rem;
  }

  .xl\:tw-gap-x-3 {
    grid-column-gap: 0.75rem;
    -moz-column-gap: 0.75rem;
         column-gap: 0.75rem;
  }

  .xl\:tw-gap-x-4 {
    grid-column-gap: 1rem;
    -moz-column-gap: 1rem;
         column-gap: 1rem;
  }

  .xl\:tw-gap-x-5 {
    grid-column-gap: 1.25rem;
    -moz-column-gap: 1.25rem;
         column-gap: 1.25rem;
  }

  .xl\:tw-gap-x-6 {
    grid-column-gap: 1.5rem;
    -moz-column-gap: 1.5rem;
         column-gap: 1.5rem;
  }

  .xl\:tw-gap-x-8 {
    grid-column-gap: 2rem;
    -moz-column-gap: 2rem;
         column-gap: 2rem;
  }

  .xl\:tw-gap-x-10 {
    grid-column-gap: 2.5rem;
    -moz-column-gap: 2.5rem;
         column-gap: 2.5rem;
  }

  .xl\:tw-gap-x-12 {
    grid-column-gap: 3rem;
    -moz-column-gap: 3rem;
         column-gap: 3rem;
  }

  .xl\:tw-gap-x-16 {
    grid-column-gap: 4rem;
    -moz-column-gap: 4rem;
         column-gap: 4rem;
  }

  .xl\:tw-gap-x-20 {
    grid-column-gap: 5rem;
    -moz-column-gap: 5rem;
         column-gap: 5rem;
  }

  .xl\:tw-gap-x-24 {
    grid-column-gap: 6rem;
    -moz-column-gap: 6rem;
         column-gap: 6rem;
  }

  .xl\:tw-gap-x-32 {
    grid-column-gap: 8rem;
    -moz-column-gap: 8rem;
         column-gap: 8rem;
  }

  .xl\:tw-gap-x-40 {
    grid-column-gap: 10rem;
    -moz-column-gap: 10rem;
         column-gap: 10rem;
  }

  .xl\:tw-gap-x-48 {
    grid-column-gap: 12rem;
    -moz-column-gap: 12rem;
         column-gap: 12rem;
  }

  .xl\:tw-gap-x-56 {
    grid-column-gap: 14rem;
    -moz-column-gap: 14rem;
         column-gap: 14rem;
  }

  .xl\:tw-gap-x-64 {
    grid-column-gap: 16rem;
    -moz-column-gap: 16rem;
         column-gap: 16rem;
  }

  .xl\:tw-gap-x-px {
    grid-column-gap: 1px;
    -moz-column-gap: 1px;
         column-gap: 1px;
  }

  .xl\:tw-gap-x-270px {
    grid-column-gap: 270px;
    -moz-column-gap: 270px;
         column-gap: 270px;
  }

  .xl\:tw-gap-x-400px {
    grid-column-gap: 400px;
    -moz-column-gap: 400px;
         column-gap: 400px;
  }

  .xl\:tw-gap-x-464px {
    grid-column-gap: 464px;
    -moz-column-gap: 464px;
         column-gap: 464px;
  }

  .xl\:tw-gap-x-480px {
    grid-column-gap: 480px;
    -moz-column-gap: 480px;
         column-gap: 480px;
  }

  .xl\:tw-gap-x-500px {
    grid-column-gap: 500px;
    -moz-column-gap: 500px;
         column-gap: 500px;
  }

  .xl\:tw-gap-x-576px {
    grid-column-gap: 576px;
    -moz-column-gap: 576px;
         column-gap: 576px;
  }

  .xl\:tw-gap-x-600px {
    grid-column-gap: 600px;
    -moz-column-gap: 600px;
         column-gap: 600px;
  }

  .xl\:tw-gap-x-672px {
    grid-column-gap: 672px;
    -moz-column-gap: 672px;
         column-gap: 672px;
  }

  .xl\:tw-row-gap-0 {
    grid-row-gap: 0;
    row-gap: 0;
  }

  .xl\:tw-row-gap-1 {
    grid-row-gap: 0.25rem;
    row-gap: 0.25rem;
  }

  .xl\:tw-row-gap-2 {
    grid-row-gap: 0.5rem;
    row-gap: 0.5rem;
  }

  .xl\:tw-row-gap-3 {
    grid-row-gap: 0.75rem;
    row-gap: 0.75rem;
  }

  .xl\:tw-row-gap-4 {
    grid-row-gap: 1rem;
    row-gap: 1rem;
  }

  .xl\:tw-row-gap-5 {
    grid-row-gap: 1.25rem;
    row-gap: 1.25rem;
  }

  .xl\:tw-row-gap-6 {
    grid-row-gap: 1.5rem;
    row-gap: 1.5rem;
  }

  .xl\:tw-row-gap-8 {
    grid-row-gap: 2rem;
    row-gap: 2rem;
  }

  .xl\:tw-row-gap-10 {
    grid-row-gap: 2.5rem;
    row-gap: 2.5rem;
  }

  .xl\:tw-row-gap-12 {
    grid-row-gap: 3rem;
    row-gap: 3rem;
  }

  .xl\:tw-row-gap-16 {
    grid-row-gap: 4rem;
    row-gap: 4rem;
  }

  .xl\:tw-row-gap-20 {
    grid-row-gap: 5rem;
    row-gap: 5rem;
  }

  .xl\:tw-row-gap-24 {
    grid-row-gap: 6rem;
    row-gap: 6rem;
  }

  .xl\:tw-row-gap-32 {
    grid-row-gap: 8rem;
    row-gap: 8rem;
  }

  .xl\:tw-row-gap-40 {
    grid-row-gap: 10rem;
    row-gap: 10rem;
  }

  .xl\:tw-row-gap-48 {
    grid-row-gap: 12rem;
    row-gap: 12rem;
  }

  .xl\:tw-row-gap-56 {
    grid-row-gap: 14rem;
    row-gap: 14rem;
  }

  .xl\:tw-row-gap-64 {
    grid-row-gap: 16rem;
    row-gap: 16rem;
  }

  .xl\:tw-row-gap-px {
    grid-row-gap: 1px;
    row-gap: 1px;
  }

  .xl\:tw-row-gap-270px {
    grid-row-gap: 270px;
    row-gap: 270px;
  }

  .xl\:tw-row-gap-400px {
    grid-row-gap: 400px;
    row-gap: 400px;
  }

  .xl\:tw-row-gap-464px {
    grid-row-gap: 464px;
    row-gap: 464px;
  }

  .xl\:tw-row-gap-480px {
    grid-row-gap: 480px;
    row-gap: 480px;
  }

  .xl\:tw-row-gap-500px {
    grid-row-gap: 500px;
    row-gap: 500px;
  }

  .xl\:tw-row-gap-576px {
    grid-row-gap: 576px;
    row-gap: 576px;
  }

  .xl\:tw-row-gap-600px {
    grid-row-gap: 600px;
    row-gap: 600px;
  }

  .xl\:tw-row-gap-672px {
    grid-row-gap: 672px;
    row-gap: 672px;
  }

  .xl\:tw-gap-y-0 {
    grid-row-gap: 0;
    row-gap: 0;
  }

  .xl\:tw-gap-y-1 {
    grid-row-gap: 0.25rem;
    row-gap: 0.25rem;
  }

  .xl\:tw-gap-y-2 {
    grid-row-gap: 0.5rem;
    row-gap: 0.5rem;
  }

  .xl\:tw-gap-y-3 {
    grid-row-gap: 0.75rem;
    row-gap: 0.75rem;
  }

  .xl\:tw-gap-y-4 {
    grid-row-gap: 1rem;
    row-gap: 1rem;
  }

  .xl\:tw-gap-y-5 {
    grid-row-gap: 1.25rem;
    row-gap: 1.25rem;
  }

  .xl\:tw-gap-y-6 {
    grid-row-gap: 1.5rem;
    row-gap: 1.5rem;
  }

  .xl\:tw-gap-y-8 {
    grid-row-gap: 2rem;
    row-gap: 2rem;
  }

  .xl\:tw-gap-y-10 {
    grid-row-gap: 2.5rem;
    row-gap: 2.5rem;
  }

  .xl\:tw-gap-y-12 {
    grid-row-gap: 3rem;
    row-gap: 3rem;
  }

  .xl\:tw-gap-y-16 {
    grid-row-gap: 4rem;
    row-gap: 4rem;
  }

  .xl\:tw-gap-y-20 {
    grid-row-gap: 5rem;
    row-gap: 5rem;
  }

  .xl\:tw-gap-y-24 {
    grid-row-gap: 6rem;
    row-gap: 6rem;
  }

  .xl\:tw-gap-y-32 {
    grid-row-gap: 8rem;
    row-gap: 8rem;
  }

  .xl\:tw-gap-y-40 {
    grid-row-gap: 10rem;
    row-gap: 10rem;
  }

  .xl\:tw-gap-y-48 {
    grid-row-gap: 12rem;
    row-gap: 12rem;
  }

  .xl\:tw-gap-y-56 {
    grid-row-gap: 14rem;
    row-gap: 14rem;
  }

  .xl\:tw-gap-y-64 {
    grid-row-gap: 16rem;
    row-gap: 16rem;
  }

  .xl\:tw-gap-y-px {
    grid-row-gap: 1px;
    row-gap: 1px;
  }

  .xl\:tw-gap-y-270px {
    grid-row-gap: 270px;
    row-gap: 270px;
  }

  .xl\:tw-gap-y-400px {
    grid-row-gap: 400px;
    row-gap: 400px;
  }

  .xl\:tw-gap-y-464px {
    grid-row-gap: 464px;
    row-gap: 464px;
  }

  .xl\:tw-gap-y-480px {
    grid-row-gap: 480px;
    row-gap: 480px;
  }

  .xl\:tw-gap-y-500px {
    grid-row-gap: 500px;
    row-gap: 500px;
  }

  .xl\:tw-gap-y-576px {
    grid-row-gap: 576px;
    row-gap: 576px;
  }

  .xl\:tw-gap-y-600px {
    grid-row-gap: 600px;
    row-gap: 600px;
  }

  .xl\:tw-gap-y-672px {
    grid-row-gap: 672px;
    row-gap: 672px;
  }

  .xl\:tw-grid-flow-row {
    grid-auto-flow: row;
  }

  .xl\:tw-grid-flow-col {
    grid-auto-flow: column;
  }

  .xl\:tw-grid-flow-row-dense {
    grid-auto-flow: row dense;
  }

  .xl\:tw-grid-flow-col-dense {
    grid-auto-flow: column dense;
  }

  .xl\:tw-grid-cols-1 {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }

  .xl\:tw-grid-cols-2 {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  .xl\:tw-grid-cols-3 {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }

  .xl\:tw-grid-cols-4 {
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }

  .xl\:tw-grid-cols-5 {
    grid-template-columns: repeat(5, minmax(0, 1fr));
  }

  .xl\:tw-grid-cols-6 {
    grid-template-columns: repeat(6, minmax(0, 1fr));
  }

  .xl\:tw-grid-cols-7 {
    grid-template-columns: repeat(7, minmax(0, 1fr));
  }

  .xl\:tw-grid-cols-8 {
    grid-template-columns: repeat(8, minmax(0, 1fr));
  }

  .xl\:tw-grid-cols-9 {
    grid-template-columns: repeat(9, minmax(0, 1fr));
  }

  .xl\:tw-grid-cols-10 {
    grid-template-columns: repeat(10, minmax(0, 1fr));
  }

  .xl\:tw-grid-cols-11 {
    grid-template-columns: repeat(11, minmax(0, 1fr));
  }

  .xl\:tw-grid-cols-12 {
    grid-template-columns: repeat(12, minmax(0, 1fr));
  }

  .xl\:tw-grid-cols-none {
    grid-template-columns: none;
  }

  .xl\:tw-auto-cols-auto {
    grid-auto-columns: auto;
  }

  .xl\:tw-auto-cols-min {
    grid-auto-columns: min-content;
  }

  .xl\:tw-auto-cols-max {
    grid-auto-columns: max-content;
  }

  .xl\:tw-auto-cols-fr {
    grid-auto-columns: minmax(0, 1fr);
  }

  .xl\:tw-col-auto {
    grid-column: auto;
  }

  .xl\:tw-col-span-1 {
    grid-column: span 1 / span 1;
  }

  .xl\:tw-col-span-2 {
    grid-column: span 2 / span 2;
  }

  .xl\:tw-col-span-3 {
    grid-column: span 3 / span 3;
  }

  .xl\:tw-col-span-4 {
    grid-column: span 4 / span 4;
  }

  .xl\:tw-col-span-5 {
    grid-column: span 5 / span 5;
  }

  .xl\:tw-col-span-6 {
    grid-column: span 6 / span 6;
  }

  .xl\:tw-col-span-7 {
    grid-column: span 7 / span 7;
  }

  .xl\:tw-col-span-8 {
    grid-column: span 8 / span 8;
  }

  .xl\:tw-col-span-9 {
    grid-column: span 9 / span 9;
  }

  .xl\:tw-col-span-10 {
    grid-column: span 10 / span 10;
  }

  .xl\:tw-col-span-11 {
    grid-column: span 11 / span 11;
  }

  .xl\:tw-col-span-12 {
    grid-column: span 12 / span 12;
  }

  .xl\:tw-col-span-full {
    grid-column: 1 / -1;
  }

  .xl\:tw-col-start-1 {
    grid-column-start: 1;
  }

  .xl\:tw-col-start-2 {
    grid-column-start: 2;
  }

  .xl\:tw-col-start-3 {
    grid-column-start: 3;
  }

  .xl\:tw-col-start-4 {
    grid-column-start: 4;
  }

  .xl\:tw-col-start-5 {
    grid-column-start: 5;
  }

  .xl\:tw-col-start-6 {
    grid-column-start: 6;
  }

  .xl\:tw-col-start-7 {
    grid-column-start: 7;
  }

  .xl\:tw-col-start-8 {
    grid-column-start: 8;
  }

  .xl\:tw-col-start-9 {
    grid-column-start: 9;
  }

  .xl\:tw-col-start-10 {
    grid-column-start: 10;
  }

  .xl\:tw-col-start-11 {
    grid-column-start: 11;
  }

  .xl\:tw-col-start-12 {
    grid-column-start: 12;
  }

  .xl\:tw-col-start-13 {
    grid-column-start: 13;
  }

  .xl\:tw-col-start-auto {
    grid-column-start: auto;
  }

  .xl\:tw-col-end-1 {
    grid-column-end: 1;
  }

  .xl\:tw-col-end-2 {
    grid-column-end: 2;
  }

  .xl\:tw-col-end-3 {
    grid-column-end: 3;
  }

  .xl\:tw-col-end-4 {
    grid-column-end: 4;
  }

  .xl\:tw-col-end-5 {
    grid-column-end: 5;
  }

  .xl\:tw-col-end-6 {
    grid-column-end: 6;
  }

  .xl\:tw-col-end-7 {
    grid-column-end: 7;
  }

  .xl\:tw-col-end-8 {
    grid-column-end: 8;
  }

  .xl\:tw-col-end-9 {
    grid-column-end: 9;
  }

  .xl\:tw-col-end-10 {
    grid-column-end: 10;
  }

  .xl\:tw-col-end-11 {
    grid-column-end: 11;
  }

  .xl\:tw-col-end-12 {
    grid-column-end: 12;
  }

  .xl\:tw-col-end-13 {
    grid-column-end: 13;
  }

  .xl\:tw-col-end-auto {
    grid-column-end: auto;
  }

  .xl\:tw-grid-rows-1 {
    grid-template-rows: repeat(1, minmax(0, 1fr));
  }

  .xl\:tw-grid-rows-2 {
    grid-template-rows: repeat(2, minmax(0, 1fr));
  }

  .xl\:tw-grid-rows-3 {
    grid-template-rows: repeat(3, minmax(0, 1fr));
  }

  .xl\:tw-grid-rows-4 {
    grid-template-rows: repeat(4, minmax(0, 1fr));
  }

  .xl\:tw-grid-rows-5 {
    grid-template-rows: repeat(5, minmax(0, 1fr));
  }

  .xl\:tw-grid-rows-6 {
    grid-template-rows: repeat(6, minmax(0, 1fr));
  }

  .xl\:tw-grid-rows-none {
    grid-template-rows: none;
  }

  .xl\:tw-auto-rows-auto {
    grid-auto-rows: auto;
  }

  .xl\:tw-auto-rows-min {
    grid-auto-rows: min-content;
  }

  .xl\:tw-auto-rows-max {
    grid-auto-rows: max-content;
  }

  .xl\:tw-auto-rows-fr {
    grid-auto-rows: minmax(0, 1fr);
  }

  .xl\:tw-row-auto {
    grid-row: auto;
  }

  .xl\:tw-row-span-1 {
    grid-row: span 1 / span 1;
  }

  .xl\:tw-row-span-2 {
    grid-row: span 2 / span 2;
  }

  .xl\:tw-row-span-3 {
    grid-row: span 3 / span 3;
  }

  .xl\:tw-row-span-4 {
    grid-row: span 4 / span 4;
  }

  .xl\:tw-row-span-5 {
    grid-row: span 5 / span 5;
  }

  .xl\:tw-row-span-6 {
    grid-row: span 6 / span 6;
  }

  .xl\:tw-row-span-full {
    grid-row: 1 / -1;
  }

  .xl\:tw-row-start-1 {
    grid-row-start: 1;
  }

  .xl\:tw-row-start-2 {
    grid-row-start: 2;
  }

  .xl\:tw-row-start-3 {
    grid-row-start: 3;
  }

  .xl\:tw-row-start-4 {
    grid-row-start: 4;
  }

  .xl\:tw-row-start-5 {
    grid-row-start: 5;
  }

  .xl\:tw-row-start-6 {
    grid-row-start: 6;
  }

  .xl\:tw-row-start-7 {
    grid-row-start: 7;
  }

  .xl\:tw-row-start-auto {
    grid-row-start: auto;
  }

  .xl\:tw-row-end-1 {
    grid-row-end: 1;
  }

  .xl\:tw-row-end-2 {
    grid-row-end: 2;
  }

  .xl\:tw-row-end-3 {
    grid-row-end: 3;
  }

  .xl\:tw-row-end-4 {
    grid-row-end: 4;
  }

  .xl\:tw-row-end-5 {
    grid-row-end: 5;
  }

  .xl\:tw-row-end-6 {
    grid-row-end: 6;
  }

  .xl\:tw-row-end-7 {
    grid-row-end: 7;
  }

  .xl\:tw-row-end-auto {
    grid-row-end: auto;
  }

  .xl\:tw-transform {
    --transform-translate-x: 0;
    --transform-translate-y: 0;
    --transform-rotate: 0;
    --transform-skew-x: 0;
    --transform-skew-y: 0;
    --transform-scale-x: 1;
    --transform-scale-y: 1;
    transform: translateX(var(--transform-translate-x)) translateY(var(--transform-translate-y)) rotate(var(--transform-rotate)) skewX(var(--transform-skew-x)) skewY(var(--transform-skew-y)) scaleX(var(--transform-scale-x)) scaleY(var(--transform-scale-y));
  }

  .xl\:tw-transform-none {
    transform: none;
  }

  .xl\:tw-origin-center {
    transform-origin: center;
  }

  .xl\:tw-origin-top {
    transform-origin: top;
  }

  .xl\:tw-origin-top-right {
    transform-origin: top right;
  }

  .xl\:tw-origin-right {
    transform-origin: right;
  }

  .xl\:tw-origin-bottom-right {
    transform-origin: bottom right;
  }

  .xl\:tw-origin-bottom {
    transform-origin: bottom;
  }

  .xl\:tw-origin-bottom-left {
    transform-origin: bottom left;
  }

  .xl\:tw-origin-left {
    transform-origin: left;
  }

  .xl\:tw-origin-top-left {
    transform-origin: top left;
  }

  .xl\:tw-scale-0 {
    --transform-scale-x: 0;
    --transform-scale-y: 0;
  }

  .xl\:tw-scale-50 {
    --transform-scale-x: .5;
    --transform-scale-y: .5;
  }

  .xl\:tw-scale-75 {
    --transform-scale-x: .75;
    --transform-scale-y: .75;
  }

  .xl\:tw-scale-90 {
    --transform-scale-x: .9;
    --transform-scale-y: .9;
  }

  .xl\:tw-scale-95 {
    --transform-scale-x: .95;
    --transform-scale-y: .95;
  }

  .xl\:tw-scale-100 {
    --transform-scale-x: 1;
    --transform-scale-y: 1;
  }

  .xl\:tw-scale-105 {
    --transform-scale-x: 1.05;
    --transform-scale-y: 1.05;
  }

  .xl\:tw-scale-110 {
    --transform-scale-x: 1.1;
    --transform-scale-y: 1.1;
  }

  .xl\:tw-scale-125 {
    --transform-scale-x: 1.25;
    --transform-scale-y: 1.25;
  }

  .xl\:tw-scale-150 {
    --transform-scale-x: 1.5;
    --transform-scale-y: 1.5;
  }

  .xl\:tw-scale-x-0 {
    --transform-scale-x: 0;
  }

  .xl\:tw-scale-x-50 {
    --transform-scale-x: .5;
  }

  .xl\:tw-scale-x-75 {
    --transform-scale-x: .75;
  }

  .xl\:tw-scale-x-90 {
    --transform-scale-x: .9;
  }

  .xl\:tw-scale-x-95 {
    --transform-scale-x: .95;
  }

  .xl\:tw-scale-x-100 {
    --transform-scale-x: 1;
  }

  .xl\:tw-scale-x-105 {
    --transform-scale-x: 1.05;
  }

  .xl\:tw-scale-x-110 {
    --transform-scale-x: 1.1;
  }

  .xl\:tw-scale-x-125 {
    --transform-scale-x: 1.25;
  }

  .xl\:tw-scale-x-150 {
    --transform-scale-x: 1.5;
  }

  .xl\:tw-scale-y-0 {
    --transform-scale-y: 0;
  }

  .xl\:tw-scale-y-50 {
    --transform-scale-y: .5;
  }

  .xl\:tw-scale-y-75 {
    --transform-scale-y: .75;
  }

  .xl\:tw-scale-y-90 {
    --transform-scale-y: .9;
  }

  .xl\:tw-scale-y-95 {
    --transform-scale-y: .95;
  }

  .xl\:tw-scale-y-100 {
    --transform-scale-y: 1;
  }

  .xl\:tw-scale-y-105 {
    --transform-scale-y: 1.05;
  }

  .xl\:tw-scale-y-110 {
    --transform-scale-y: 1.1;
  }

  .xl\:tw-scale-y-125 {
    --transform-scale-y: 1.25;
  }

  .xl\:tw-scale-y-150 {
    --transform-scale-y: 1.5;
  }

  .xl\:hover\:tw-scale-0:hover {
    --transform-scale-x: 0;
    --transform-scale-y: 0;
  }

  .xl\:hover\:tw-scale-50:hover {
    --transform-scale-x: .5;
    --transform-scale-y: .5;
  }

  .xl\:hover\:tw-scale-75:hover {
    --transform-scale-x: .75;
    --transform-scale-y: .75;
  }

  .xl\:hover\:tw-scale-90:hover {
    --transform-scale-x: .9;
    --transform-scale-y: .9;
  }

  .xl\:hover\:tw-scale-95:hover {
    --transform-scale-x: .95;
    --transform-scale-y: .95;
  }

  .xl\:hover\:tw-scale-100:hover {
    --transform-scale-x: 1;
    --transform-scale-y: 1;
  }

  .xl\:hover\:tw-scale-105:hover {
    --transform-scale-x: 1.05;
    --transform-scale-y: 1.05;
  }

  .xl\:hover\:tw-scale-110:hover {
    --transform-scale-x: 1.1;
    --transform-scale-y: 1.1;
  }

  .xl\:hover\:tw-scale-125:hover {
    --transform-scale-x: 1.25;
    --transform-scale-y: 1.25;
  }

  .xl\:hover\:tw-scale-150:hover {
    --transform-scale-x: 1.5;
    --transform-scale-y: 1.5;
  }

  .xl\:hover\:tw-scale-x-0:hover {
    --transform-scale-x: 0;
  }

  .xl\:hover\:tw-scale-x-50:hover {
    --transform-scale-x: .5;
  }

  .xl\:hover\:tw-scale-x-75:hover {
    --transform-scale-x: .75;
  }

  .xl\:hover\:tw-scale-x-90:hover {
    --transform-scale-x: .9;
  }

  .xl\:hover\:tw-scale-x-95:hover {
    --transform-scale-x: .95;
  }

  .xl\:hover\:tw-scale-x-100:hover {
    --transform-scale-x: 1;
  }

  .xl\:hover\:tw-scale-x-105:hover {
    --transform-scale-x: 1.05;
  }

  .xl\:hover\:tw-scale-x-110:hover {
    --transform-scale-x: 1.1;
  }

  .xl\:hover\:tw-scale-x-125:hover {
    --transform-scale-x: 1.25;
  }

  .xl\:hover\:tw-scale-x-150:hover {
    --transform-scale-x: 1.5;
  }

  .xl\:hover\:tw-scale-y-0:hover {
    --transform-scale-y: 0;
  }

  .xl\:hover\:tw-scale-y-50:hover {
    --transform-scale-y: .5;
  }

  .xl\:hover\:tw-scale-y-75:hover {
    --transform-scale-y: .75;
  }

  .xl\:hover\:tw-scale-y-90:hover {
    --transform-scale-y: .9;
  }

  .xl\:hover\:tw-scale-y-95:hover {
    --transform-scale-y: .95;
  }

  .xl\:hover\:tw-scale-y-100:hover {
    --transform-scale-y: 1;
  }

  .xl\:hover\:tw-scale-y-105:hover {
    --transform-scale-y: 1.05;
  }

  .xl\:hover\:tw-scale-y-110:hover {
    --transform-scale-y: 1.1;
  }

  .xl\:hover\:tw-scale-y-125:hover {
    --transform-scale-y: 1.25;
  }

  .xl\:hover\:tw-scale-y-150:hover {
    --transform-scale-y: 1.5;
  }

  .xl\:focus\:tw-scale-0:focus {
    --transform-scale-x: 0;
    --transform-scale-y: 0;
  }

  .xl\:focus\:tw-scale-50:focus {
    --transform-scale-x: .5;
    --transform-scale-y: .5;
  }

  .xl\:focus\:tw-scale-75:focus {
    --transform-scale-x: .75;
    --transform-scale-y: .75;
  }

  .xl\:focus\:tw-scale-90:focus {
    --transform-scale-x: .9;
    --transform-scale-y: .9;
  }

  .xl\:focus\:tw-scale-95:focus {
    --transform-scale-x: .95;
    --transform-scale-y: .95;
  }

  .xl\:focus\:tw-scale-100:focus {
    --transform-scale-x: 1;
    --transform-scale-y: 1;
  }

  .xl\:focus\:tw-scale-105:focus {
    --transform-scale-x: 1.05;
    --transform-scale-y: 1.05;
  }

  .xl\:focus\:tw-scale-110:focus {
    --transform-scale-x: 1.1;
    --transform-scale-y: 1.1;
  }

  .xl\:focus\:tw-scale-125:focus {
    --transform-scale-x: 1.25;
    --transform-scale-y: 1.25;
  }

  .xl\:focus\:tw-scale-150:focus {
    --transform-scale-x: 1.5;
    --transform-scale-y: 1.5;
  }

  .xl\:focus\:tw-scale-x-0:focus {
    --transform-scale-x: 0;
  }

  .xl\:focus\:tw-scale-x-50:focus {
    --transform-scale-x: .5;
  }

  .xl\:focus\:tw-scale-x-75:focus {
    --transform-scale-x: .75;
  }

  .xl\:focus\:tw-scale-x-90:focus {
    --transform-scale-x: .9;
  }

  .xl\:focus\:tw-scale-x-95:focus {
    --transform-scale-x: .95;
  }

  .xl\:focus\:tw-scale-x-100:focus {
    --transform-scale-x: 1;
  }

  .xl\:focus\:tw-scale-x-105:focus {
    --transform-scale-x: 1.05;
  }

  .xl\:focus\:tw-scale-x-110:focus {
    --transform-scale-x: 1.1;
  }

  .xl\:focus\:tw-scale-x-125:focus {
    --transform-scale-x: 1.25;
  }

  .xl\:focus\:tw-scale-x-150:focus {
    --transform-scale-x: 1.5;
  }

  .xl\:focus\:tw-scale-y-0:focus {
    --transform-scale-y: 0;
  }

  .xl\:focus\:tw-scale-y-50:focus {
    --transform-scale-y: .5;
  }

  .xl\:focus\:tw-scale-y-75:focus {
    --transform-scale-y: .75;
  }

  .xl\:focus\:tw-scale-y-90:focus {
    --transform-scale-y: .9;
  }

  .xl\:focus\:tw-scale-y-95:focus {
    --transform-scale-y: .95;
  }

  .xl\:focus\:tw-scale-y-100:focus {
    --transform-scale-y: 1;
  }

  .xl\:focus\:tw-scale-y-105:focus {
    --transform-scale-y: 1.05;
  }

  .xl\:focus\:tw-scale-y-110:focus {
    --transform-scale-y: 1.1;
  }

  .xl\:focus\:tw-scale-y-125:focus {
    --transform-scale-y: 1.25;
  }

  .xl\:focus\:tw-scale-y-150:focus {
    --transform-scale-y: 1.5;
  }

  .xl\:tw-rotate-0 {
    --transform-rotate: 0;
  }

  .xl\:tw-rotate-1 {
    --transform-rotate: 1deg;
  }

  .xl\:tw-rotate-2 {
    --transform-rotate: 2deg;
  }

  .xl\:tw-rotate-3 {
    --transform-rotate: 3deg;
  }

  .xl\:tw-rotate-6 {
    --transform-rotate: 6deg;
  }

  .xl\:tw-rotate-12 {
    --transform-rotate: 12deg;
  }

  .xl\:tw-rotate-45 {
    --transform-rotate: 45deg;
  }

  .xl\:tw-rotate-90 {
    --transform-rotate: 90deg;
  }

  .xl\:tw-rotate-180 {
    --transform-rotate: 180deg;
  }

  .xl\:tw--rotate-180 {
    --transform-rotate: -180deg;
  }

  .xl\:tw--rotate-90 {
    --transform-rotate: -90deg;
  }

  .xl\:tw--rotate-45 {
    --transform-rotate: -45deg;
  }

  .xl\:tw--rotate-12 {
    --transform-rotate: -12deg;
  }

  .xl\:tw--rotate-6 {
    --transform-rotate: -6deg;
  }

  .xl\:tw--rotate-3 {
    --transform-rotate: -3deg;
  }

  .xl\:tw--rotate-2 {
    --transform-rotate: -2deg;
  }

  .xl\:tw--rotate-1 {
    --transform-rotate: -1deg;
  }

  .xl\:hover\:tw-rotate-0:hover {
    --transform-rotate: 0;
  }

  .xl\:hover\:tw-rotate-1:hover {
    --transform-rotate: 1deg;
  }

  .xl\:hover\:tw-rotate-2:hover {
    --transform-rotate: 2deg;
  }

  .xl\:hover\:tw-rotate-3:hover {
    --transform-rotate: 3deg;
  }

  .xl\:hover\:tw-rotate-6:hover {
    --transform-rotate: 6deg;
  }

  .xl\:hover\:tw-rotate-12:hover {
    --transform-rotate: 12deg;
  }

  .xl\:hover\:tw-rotate-45:hover {
    --transform-rotate: 45deg;
  }

  .xl\:hover\:tw-rotate-90:hover {
    --transform-rotate: 90deg;
  }

  .xl\:hover\:tw-rotate-180:hover {
    --transform-rotate: 180deg;
  }

  .xl\:hover\:tw--rotate-180:hover {
    --transform-rotate: -180deg;
  }

  .xl\:hover\:tw--rotate-90:hover {
    --transform-rotate: -90deg;
  }

  .xl\:hover\:tw--rotate-45:hover {
    --transform-rotate: -45deg;
  }

  .xl\:hover\:tw--rotate-12:hover {
    --transform-rotate: -12deg;
  }

  .xl\:hover\:tw--rotate-6:hover {
    --transform-rotate: -6deg;
  }

  .xl\:hover\:tw--rotate-3:hover {
    --transform-rotate: -3deg;
  }

  .xl\:hover\:tw--rotate-2:hover {
    --transform-rotate: -2deg;
  }

  .xl\:hover\:tw--rotate-1:hover {
    --transform-rotate: -1deg;
  }

  .xl\:focus\:tw-rotate-0:focus {
    --transform-rotate: 0;
  }

  .xl\:focus\:tw-rotate-1:focus {
    --transform-rotate: 1deg;
  }

  .xl\:focus\:tw-rotate-2:focus {
    --transform-rotate: 2deg;
  }

  .xl\:focus\:tw-rotate-3:focus {
    --transform-rotate: 3deg;
  }

  .xl\:focus\:tw-rotate-6:focus {
    --transform-rotate: 6deg;
  }

  .xl\:focus\:tw-rotate-12:focus {
    --transform-rotate: 12deg;
  }

  .xl\:focus\:tw-rotate-45:focus {
    --transform-rotate: 45deg;
  }

  .xl\:focus\:tw-rotate-90:focus {
    --transform-rotate: 90deg;
  }

  .xl\:focus\:tw-rotate-180:focus {
    --transform-rotate: 180deg;
  }

  .xl\:focus\:tw--rotate-180:focus {
    --transform-rotate: -180deg;
  }

  .xl\:focus\:tw--rotate-90:focus {
    --transform-rotate: -90deg;
  }

  .xl\:focus\:tw--rotate-45:focus {
    --transform-rotate: -45deg;
  }

  .xl\:focus\:tw--rotate-12:focus {
    --transform-rotate: -12deg;
  }

  .xl\:focus\:tw--rotate-6:focus {
    --transform-rotate: -6deg;
  }

  .xl\:focus\:tw--rotate-3:focus {
    --transform-rotate: -3deg;
  }

  .xl\:focus\:tw--rotate-2:focus {
    --transform-rotate: -2deg;
  }

  .xl\:focus\:tw--rotate-1:focus {
    --transform-rotate: -1deg;
  }

  .xl\:tw-translate-x-0 {
    --transform-translate-x: 0;
  }

  .xl\:tw-translate-x-1 {
    --transform-translate-x: 0.25rem;
  }

  .xl\:tw-translate-x-2 {
    --transform-translate-x: 0.5rem;
  }

  .xl\:tw-translate-x-3 {
    --transform-translate-x: 0.75rem;
  }

  .xl\:tw-translate-x-4 {
    --transform-translate-x: 1rem;
  }

  .xl\:tw-translate-x-5 {
    --transform-translate-x: 1.25rem;
  }

  .xl\:tw-translate-x-6 {
    --transform-translate-x: 1.5rem;
  }

  .xl\:tw-translate-x-8 {
    --transform-translate-x: 2rem;
  }

  .xl\:tw-translate-x-10 {
    --transform-translate-x: 2.5rem;
  }

  .xl\:tw-translate-x-12 {
    --transform-translate-x: 3rem;
  }

  .xl\:tw-translate-x-16 {
    --transform-translate-x: 4rem;
  }

  .xl\:tw-translate-x-20 {
    --transform-translate-x: 5rem;
  }

  .xl\:tw-translate-x-24 {
    --transform-translate-x: 6rem;
  }

  .xl\:tw-translate-x-32 {
    --transform-translate-x: 8rem;
  }

  .xl\:tw-translate-x-40 {
    --transform-translate-x: 10rem;
  }

  .xl\:tw-translate-x-48 {
    --transform-translate-x: 12rem;
  }

  .xl\:tw-translate-x-56 {
    --transform-translate-x: 14rem;
  }

  .xl\:tw-translate-x-64 {
    --transform-translate-x: 16rem;
  }

  .xl\:tw-translate-x-px {
    --transform-translate-x: 1px;
  }

  .xl\:tw-translate-x-270px {
    --transform-translate-x: 270px;
  }

  .xl\:tw-translate-x-400px {
    --transform-translate-x: 400px;
  }

  .xl\:tw-translate-x-464px {
    --transform-translate-x: 464px;
  }

  .xl\:tw-translate-x-480px {
    --transform-translate-x: 480px;
  }

  .xl\:tw-translate-x-500px {
    --transform-translate-x: 500px;
  }

  .xl\:tw-translate-x-576px {
    --transform-translate-x: 576px;
  }

  .xl\:tw-translate-x-600px {
    --transform-translate-x: 600px;
  }

  .xl\:tw-translate-x-672px {
    --transform-translate-x: 672px;
  }

  .xl\:tw--translate-x-1 {
    --transform-translate-x: -0.25rem;
  }

  .xl\:tw--translate-x-2 {
    --transform-translate-x: -0.5rem;
  }

  .xl\:tw--translate-x-3 {
    --transform-translate-x: -0.75rem;
  }

  .xl\:tw--translate-x-4 {
    --transform-translate-x: -1rem;
  }

  .xl\:tw--translate-x-5 {
    --transform-translate-x: -1.25rem;
  }

  .xl\:tw--translate-x-6 {
    --transform-translate-x: -1.5rem;
  }

  .xl\:tw--translate-x-8 {
    --transform-translate-x: -2rem;
  }

  .xl\:tw--translate-x-10 {
    --transform-translate-x: -2.5rem;
  }

  .xl\:tw--translate-x-12 {
    --transform-translate-x: -3rem;
  }

  .xl\:tw--translate-x-16 {
    --transform-translate-x: -4rem;
  }

  .xl\:tw--translate-x-20 {
    --transform-translate-x: -5rem;
  }

  .xl\:tw--translate-x-24 {
    --transform-translate-x: -6rem;
  }

  .xl\:tw--translate-x-32 {
    --transform-translate-x: -8rem;
  }

  .xl\:tw--translate-x-40 {
    --transform-translate-x: -10rem;
  }

  .xl\:tw--translate-x-48 {
    --transform-translate-x: -12rem;
  }

  .xl\:tw--translate-x-56 {
    --transform-translate-x: -14rem;
  }

  .xl\:tw--translate-x-64 {
    --transform-translate-x: -16rem;
  }

  .xl\:tw--translate-x-px {
    --transform-translate-x: -1px;
  }

  .xl\:tw--translate-x-270px {
    --transform-translate-x: -270px;
  }

  .xl\:tw--translate-x-400px {
    --transform-translate-x: -400px;
  }

  .xl\:tw--translate-x-464px {
    --transform-translate-x: -464px;
  }

  .xl\:tw--translate-x-480px {
    --transform-translate-x: -480px;
  }

  .xl\:tw--translate-x-500px {
    --transform-translate-x: -500px;
  }

  .xl\:tw--translate-x-576px {
    --transform-translate-x: -576px;
  }

  .xl\:tw--translate-x-600px {
    --transform-translate-x: -600px;
  }

  .xl\:tw--translate-x-672px {
    --transform-translate-x: -672px;
  }

  .xl\:tw--translate-x-full {
    --transform-translate-x: -100%;
  }

  .xl\:tw--translate-x-1\/2 {
    --transform-translate-x: -50%;
  }

  .xl\:tw-translate-x-1\/2 {
    --transform-translate-x: 50%;
  }

  .xl\:tw-translate-x-full {
    --transform-translate-x: 100%;
  }

  .xl\:tw-translate-y-0 {
    --transform-translate-y: 0;
  }

  .xl\:tw-translate-y-1 {
    --transform-translate-y: 0.25rem;
  }

  .xl\:tw-translate-y-2 {
    --transform-translate-y: 0.5rem;
  }

  .xl\:tw-translate-y-3 {
    --transform-translate-y: 0.75rem;
  }

  .xl\:tw-translate-y-4 {
    --transform-translate-y: 1rem;
  }

  .xl\:tw-translate-y-5 {
    --transform-translate-y: 1.25rem;
  }

  .xl\:tw-translate-y-6 {
    --transform-translate-y: 1.5rem;
  }

  .xl\:tw-translate-y-8 {
    --transform-translate-y: 2rem;
  }

  .xl\:tw-translate-y-10 {
    --transform-translate-y: 2.5rem;
  }

  .xl\:tw-translate-y-12 {
    --transform-translate-y: 3rem;
  }

  .xl\:tw-translate-y-16 {
    --transform-translate-y: 4rem;
  }

  .xl\:tw-translate-y-20 {
    --transform-translate-y: 5rem;
  }

  .xl\:tw-translate-y-24 {
    --transform-translate-y: 6rem;
  }

  .xl\:tw-translate-y-32 {
    --transform-translate-y: 8rem;
  }

  .xl\:tw-translate-y-40 {
    --transform-translate-y: 10rem;
  }

  .xl\:tw-translate-y-48 {
    --transform-translate-y: 12rem;
  }

  .xl\:tw-translate-y-56 {
    --transform-translate-y: 14rem;
  }

  .xl\:tw-translate-y-64 {
    --transform-translate-y: 16rem;
  }

  .xl\:tw-translate-y-px {
    --transform-translate-y: 1px;
  }

  .xl\:tw-translate-y-270px {
    --transform-translate-y: 270px;
  }

  .xl\:tw-translate-y-400px {
    --transform-translate-y: 400px;
  }

  .xl\:tw-translate-y-464px {
    --transform-translate-y: 464px;
  }

  .xl\:tw-translate-y-480px {
    --transform-translate-y: 480px;
  }

  .xl\:tw-translate-y-500px {
    --transform-translate-y: 500px;
  }

  .xl\:tw-translate-y-576px {
    --transform-translate-y: 576px;
  }

  .xl\:tw-translate-y-600px {
    --transform-translate-y: 600px;
  }

  .xl\:tw-translate-y-672px {
    --transform-translate-y: 672px;
  }

  .xl\:tw--translate-y-1 {
    --transform-translate-y: -0.25rem;
  }

  .xl\:tw--translate-y-2 {
    --transform-translate-y: -0.5rem;
  }

  .xl\:tw--translate-y-3 {
    --transform-translate-y: -0.75rem;
  }

  .xl\:tw--translate-y-4 {
    --transform-translate-y: -1rem;
  }

  .xl\:tw--translate-y-5 {
    --transform-translate-y: -1.25rem;
  }

  .xl\:tw--translate-y-6 {
    --transform-translate-y: -1.5rem;
  }

  .xl\:tw--translate-y-8 {
    --transform-translate-y: -2rem;
  }

  .xl\:tw--translate-y-10 {
    --transform-translate-y: -2.5rem;
  }

  .xl\:tw--translate-y-12 {
    --transform-translate-y: -3rem;
  }

  .xl\:tw--translate-y-16 {
    --transform-translate-y: -4rem;
  }

  .xl\:tw--translate-y-20 {
    --transform-translate-y: -5rem;
  }

  .xl\:tw--translate-y-24 {
    --transform-translate-y: -6rem;
  }

  .xl\:tw--translate-y-32 {
    --transform-translate-y: -8rem;
  }

  .xl\:tw--translate-y-40 {
    --transform-translate-y: -10rem;
  }

  .xl\:tw--translate-y-48 {
    --transform-translate-y: -12rem;
  }

  .xl\:tw--translate-y-56 {
    --transform-translate-y: -14rem;
  }

  .xl\:tw--translate-y-64 {
    --transform-translate-y: -16rem;
  }

  .xl\:tw--translate-y-px {
    --transform-translate-y: -1px;
  }

  .xl\:tw--translate-y-270px {
    --transform-translate-y: -270px;
  }

  .xl\:tw--translate-y-400px {
    --transform-translate-y: -400px;
  }

  .xl\:tw--translate-y-464px {
    --transform-translate-y: -464px;
  }

  .xl\:tw--translate-y-480px {
    --transform-translate-y: -480px;
  }

  .xl\:tw--translate-y-500px {
    --transform-translate-y: -500px;
  }

  .xl\:tw--translate-y-576px {
    --transform-translate-y: -576px;
  }

  .xl\:tw--translate-y-600px {
    --transform-translate-y: -600px;
  }

  .xl\:tw--translate-y-672px {
    --transform-translate-y: -672px;
  }

  .xl\:tw--translate-y-full {
    --transform-translate-y: -100%;
  }

  .xl\:tw--translate-y-1\/2 {
    --transform-translate-y: -50%;
  }

  .xl\:tw-translate-y-1\/2 {
    --transform-translate-y: 50%;
  }

  .xl\:tw-translate-y-full {
    --transform-translate-y: 100%;
  }

  .xl\:hover\:tw-translate-x-0:hover {
    --transform-translate-x: 0;
  }

  .xl\:hover\:tw-translate-x-1:hover {
    --transform-translate-x: 0.25rem;
  }

  .xl\:hover\:tw-translate-x-2:hover {
    --transform-translate-x: 0.5rem;
  }

  .xl\:hover\:tw-translate-x-3:hover {
    --transform-translate-x: 0.75rem;
  }

  .xl\:hover\:tw-translate-x-4:hover {
    --transform-translate-x: 1rem;
  }

  .xl\:hover\:tw-translate-x-5:hover {
    --transform-translate-x: 1.25rem;
  }

  .xl\:hover\:tw-translate-x-6:hover {
    --transform-translate-x: 1.5rem;
  }

  .xl\:hover\:tw-translate-x-8:hover {
    --transform-translate-x: 2rem;
  }

  .xl\:hover\:tw-translate-x-10:hover {
    --transform-translate-x: 2.5rem;
  }

  .xl\:hover\:tw-translate-x-12:hover {
    --transform-translate-x: 3rem;
  }

  .xl\:hover\:tw-translate-x-16:hover {
    --transform-translate-x: 4rem;
  }

  .xl\:hover\:tw-translate-x-20:hover {
    --transform-translate-x: 5rem;
  }

  .xl\:hover\:tw-translate-x-24:hover {
    --transform-translate-x: 6rem;
  }

  .xl\:hover\:tw-translate-x-32:hover {
    --transform-translate-x: 8rem;
  }

  .xl\:hover\:tw-translate-x-40:hover {
    --transform-translate-x: 10rem;
  }

  .xl\:hover\:tw-translate-x-48:hover {
    --transform-translate-x: 12rem;
  }

  .xl\:hover\:tw-translate-x-56:hover {
    --transform-translate-x: 14rem;
  }

  .xl\:hover\:tw-translate-x-64:hover {
    --transform-translate-x: 16rem;
  }

  .xl\:hover\:tw-translate-x-px:hover {
    --transform-translate-x: 1px;
  }

  .xl\:hover\:tw-translate-x-270px:hover {
    --transform-translate-x: 270px;
  }

  .xl\:hover\:tw-translate-x-400px:hover {
    --transform-translate-x: 400px;
  }

  .xl\:hover\:tw-translate-x-464px:hover {
    --transform-translate-x: 464px;
  }

  .xl\:hover\:tw-translate-x-480px:hover {
    --transform-translate-x: 480px;
  }

  .xl\:hover\:tw-translate-x-500px:hover {
    --transform-translate-x: 500px;
  }

  .xl\:hover\:tw-translate-x-576px:hover {
    --transform-translate-x: 576px;
  }

  .xl\:hover\:tw-translate-x-600px:hover {
    --transform-translate-x: 600px;
  }

  .xl\:hover\:tw-translate-x-672px:hover {
    --transform-translate-x: 672px;
  }

  .xl\:hover\:tw--translate-x-1:hover {
    --transform-translate-x: -0.25rem;
  }

  .xl\:hover\:tw--translate-x-2:hover {
    --transform-translate-x: -0.5rem;
  }

  .xl\:hover\:tw--translate-x-3:hover {
    --transform-translate-x: -0.75rem;
  }

  .xl\:hover\:tw--translate-x-4:hover {
    --transform-translate-x: -1rem;
  }

  .xl\:hover\:tw--translate-x-5:hover {
    --transform-translate-x: -1.25rem;
  }

  .xl\:hover\:tw--translate-x-6:hover {
    --transform-translate-x: -1.5rem;
  }

  .xl\:hover\:tw--translate-x-8:hover {
    --transform-translate-x: -2rem;
  }

  .xl\:hover\:tw--translate-x-10:hover {
    --transform-translate-x: -2.5rem;
  }

  .xl\:hover\:tw--translate-x-12:hover {
    --transform-translate-x: -3rem;
  }

  .xl\:hover\:tw--translate-x-16:hover {
    --transform-translate-x: -4rem;
  }

  .xl\:hover\:tw--translate-x-20:hover {
    --transform-translate-x: -5rem;
  }

  .xl\:hover\:tw--translate-x-24:hover {
    --transform-translate-x: -6rem;
  }

  .xl\:hover\:tw--translate-x-32:hover {
    --transform-translate-x: -8rem;
  }

  .xl\:hover\:tw--translate-x-40:hover {
    --transform-translate-x: -10rem;
  }

  .xl\:hover\:tw--translate-x-48:hover {
    --transform-translate-x: -12rem;
  }

  .xl\:hover\:tw--translate-x-56:hover {
    --transform-translate-x: -14rem;
  }

  .xl\:hover\:tw--translate-x-64:hover {
    --transform-translate-x: -16rem;
  }

  .xl\:hover\:tw--translate-x-px:hover {
    --transform-translate-x: -1px;
  }

  .xl\:hover\:tw--translate-x-270px:hover {
    --transform-translate-x: -270px;
  }

  .xl\:hover\:tw--translate-x-400px:hover {
    --transform-translate-x: -400px;
  }

  .xl\:hover\:tw--translate-x-464px:hover {
    --transform-translate-x: -464px;
  }

  .xl\:hover\:tw--translate-x-480px:hover {
    --transform-translate-x: -480px;
  }

  .xl\:hover\:tw--translate-x-500px:hover {
    --transform-translate-x: -500px;
  }

  .xl\:hover\:tw--translate-x-576px:hover {
    --transform-translate-x: -576px;
  }

  .xl\:hover\:tw--translate-x-600px:hover {
    --transform-translate-x: -600px;
  }

  .xl\:hover\:tw--translate-x-672px:hover {
    --transform-translate-x: -672px;
  }

  .xl\:hover\:tw--translate-x-full:hover {
    --transform-translate-x: -100%;
  }

  .xl\:hover\:tw--translate-x-1\/2:hover {
    --transform-translate-x: -50%;
  }

  .xl\:hover\:tw-translate-x-1\/2:hover {
    --transform-translate-x: 50%;
  }

  .xl\:hover\:tw-translate-x-full:hover {
    --transform-translate-x: 100%;
  }

  .xl\:hover\:tw-translate-y-0:hover {
    --transform-translate-y: 0;
  }

  .xl\:hover\:tw-translate-y-1:hover {
    --transform-translate-y: 0.25rem;
  }

  .xl\:hover\:tw-translate-y-2:hover {
    --transform-translate-y: 0.5rem;
  }

  .xl\:hover\:tw-translate-y-3:hover {
    --transform-translate-y: 0.75rem;
  }

  .xl\:hover\:tw-translate-y-4:hover {
    --transform-translate-y: 1rem;
  }

  .xl\:hover\:tw-translate-y-5:hover {
    --transform-translate-y: 1.25rem;
  }

  .xl\:hover\:tw-translate-y-6:hover {
    --transform-translate-y: 1.5rem;
  }

  .xl\:hover\:tw-translate-y-8:hover {
    --transform-translate-y: 2rem;
  }

  .xl\:hover\:tw-translate-y-10:hover {
    --transform-translate-y: 2.5rem;
  }

  .xl\:hover\:tw-translate-y-12:hover {
    --transform-translate-y: 3rem;
  }

  .xl\:hover\:tw-translate-y-16:hover {
    --transform-translate-y: 4rem;
  }

  .xl\:hover\:tw-translate-y-20:hover {
    --transform-translate-y: 5rem;
  }

  .xl\:hover\:tw-translate-y-24:hover {
    --transform-translate-y: 6rem;
  }

  .xl\:hover\:tw-translate-y-32:hover {
    --transform-translate-y: 8rem;
  }

  .xl\:hover\:tw-translate-y-40:hover {
    --transform-translate-y: 10rem;
  }

  .xl\:hover\:tw-translate-y-48:hover {
    --transform-translate-y: 12rem;
  }

  .xl\:hover\:tw-translate-y-56:hover {
    --transform-translate-y: 14rem;
  }

  .xl\:hover\:tw-translate-y-64:hover {
    --transform-translate-y: 16rem;
  }

  .xl\:hover\:tw-translate-y-px:hover {
    --transform-translate-y: 1px;
  }

  .xl\:hover\:tw-translate-y-270px:hover {
    --transform-translate-y: 270px;
  }

  .xl\:hover\:tw-translate-y-400px:hover {
    --transform-translate-y: 400px;
  }

  .xl\:hover\:tw-translate-y-464px:hover {
    --transform-translate-y: 464px;
  }

  .xl\:hover\:tw-translate-y-480px:hover {
    --transform-translate-y: 480px;
  }

  .xl\:hover\:tw-translate-y-500px:hover {
    --transform-translate-y: 500px;
  }

  .xl\:hover\:tw-translate-y-576px:hover {
    --transform-translate-y: 576px;
  }

  .xl\:hover\:tw-translate-y-600px:hover {
    --transform-translate-y: 600px;
  }

  .xl\:hover\:tw-translate-y-672px:hover {
    --transform-translate-y: 672px;
  }

  .xl\:hover\:tw--translate-y-1:hover {
    --transform-translate-y: -0.25rem;
  }

  .xl\:hover\:tw--translate-y-2:hover {
    --transform-translate-y: -0.5rem;
  }

  .xl\:hover\:tw--translate-y-3:hover {
    --transform-translate-y: -0.75rem;
  }

  .xl\:hover\:tw--translate-y-4:hover {
    --transform-translate-y: -1rem;
  }

  .xl\:hover\:tw--translate-y-5:hover {
    --transform-translate-y: -1.25rem;
  }

  .xl\:hover\:tw--translate-y-6:hover {
    --transform-translate-y: -1.5rem;
  }

  .xl\:hover\:tw--translate-y-8:hover {
    --transform-translate-y: -2rem;
  }

  .xl\:hover\:tw--translate-y-10:hover {
    --transform-translate-y: -2.5rem;
  }

  .xl\:hover\:tw--translate-y-12:hover {
    --transform-translate-y: -3rem;
  }

  .xl\:hover\:tw--translate-y-16:hover {
    --transform-translate-y: -4rem;
  }

  .xl\:hover\:tw--translate-y-20:hover {
    --transform-translate-y: -5rem;
  }

  .xl\:hover\:tw--translate-y-24:hover {
    --transform-translate-y: -6rem;
  }

  .xl\:hover\:tw--translate-y-32:hover {
    --transform-translate-y: -8rem;
  }

  .xl\:hover\:tw--translate-y-40:hover {
    --transform-translate-y: -10rem;
  }

  .xl\:hover\:tw--translate-y-48:hover {
    --transform-translate-y: -12rem;
  }

  .xl\:hover\:tw--translate-y-56:hover {
    --transform-translate-y: -14rem;
  }

  .xl\:hover\:tw--translate-y-64:hover {
    --transform-translate-y: -16rem;
  }

  .xl\:hover\:tw--translate-y-px:hover {
    --transform-translate-y: -1px;
  }

  .xl\:hover\:tw--translate-y-270px:hover {
    --transform-translate-y: -270px;
  }

  .xl\:hover\:tw--translate-y-400px:hover {
    --transform-translate-y: -400px;
  }

  .xl\:hover\:tw--translate-y-464px:hover {
    --transform-translate-y: -464px;
  }

  .xl\:hover\:tw--translate-y-480px:hover {
    --transform-translate-y: -480px;
  }

  .xl\:hover\:tw--translate-y-500px:hover {
    --transform-translate-y: -500px;
  }

  .xl\:hover\:tw--translate-y-576px:hover {
    --transform-translate-y: -576px;
  }

  .xl\:hover\:tw--translate-y-600px:hover {
    --transform-translate-y: -600px;
  }

  .xl\:hover\:tw--translate-y-672px:hover {
    --transform-translate-y: -672px;
  }

  .xl\:hover\:tw--translate-y-full:hover {
    --transform-translate-y: -100%;
  }

  .xl\:hover\:tw--translate-y-1\/2:hover {
    --transform-translate-y: -50%;
  }

  .xl\:hover\:tw-translate-y-1\/2:hover {
    --transform-translate-y: 50%;
  }

  .xl\:hover\:tw-translate-y-full:hover {
    --transform-translate-y: 100%;
  }

  .xl\:focus\:tw-translate-x-0:focus {
    --transform-translate-x: 0;
  }

  .xl\:focus\:tw-translate-x-1:focus {
    --transform-translate-x: 0.25rem;
  }

  .xl\:focus\:tw-translate-x-2:focus {
    --transform-translate-x: 0.5rem;
  }

  .xl\:focus\:tw-translate-x-3:focus {
    --transform-translate-x: 0.75rem;
  }

  .xl\:focus\:tw-translate-x-4:focus {
    --transform-translate-x: 1rem;
  }

  .xl\:focus\:tw-translate-x-5:focus {
    --transform-translate-x: 1.25rem;
  }

  .xl\:focus\:tw-translate-x-6:focus {
    --transform-translate-x: 1.5rem;
  }

  .xl\:focus\:tw-translate-x-8:focus {
    --transform-translate-x: 2rem;
  }

  .xl\:focus\:tw-translate-x-10:focus {
    --transform-translate-x: 2.5rem;
  }

  .xl\:focus\:tw-translate-x-12:focus {
    --transform-translate-x: 3rem;
  }

  .xl\:focus\:tw-translate-x-16:focus {
    --transform-translate-x: 4rem;
  }

  .xl\:focus\:tw-translate-x-20:focus {
    --transform-translate-x: 5rem;
  }

  .xl\:focus\:tw-translate-x-24:focus {
    --transform-translate-x: 6rem;
  }

  .xl\:focus\:tw-translate-x-32:focus {
    --transform-translate-x: 8rem;
  }

  .xl\:focus\:tw-translate-x-40:focus {
    --transform-translate-x: 10rem;
  }

  .xl\:focus\:tw-translate-x-48:focus {
    --transform-translate-x: 12rem;
  }

  .xl\:focus\:tw-translate-x-56:focus {
    --transform-translate-x: 14rem;
  }

  .xl\:focus\:tw-translate-x-64:focus {
    --transform-translate-x: 16rem;
  }

  .xl\:focus\:tw-translate-x-px:focus {
    --transform-translate-x: 1px;
  }

  .xl\:focus\:tw-translate-x-270px:focus {
    --transform-translate-x: 270px;
  }

  .xl\:focus\:tw-translate-x-400px:focus {
    --transform-translate-x: 400px;
  }

  .xl\:focus\:tw-translate-x-464px:focus {
    --transform-translate-x: 464px;
  }

  .xl\:focus\:tw-translate-x-480px:focus {
    --transform-translate-x: 480px;
  }

  .xl\:focus\:tw-translate-x-500px:focus {
    --transform-translate-x: 500px;
  }

  .xl\:focus\:tw-translate-x-576px:focus {
    --transform-translate-x: 576px;
  }

  .xl\:focus\:tw-translate-x-600px:focus {
    --transform-translate-x: 600px;
  }

  .xl\:focus\:tw-translate-x-672px:focus {
    --transform-translate-x: 672px;
  }

  .xl\:focus\:tw--translate-x-1:focus {
    --transform-translate-x: -0.25rem;
  }

  .xl\:focus\:tw--translate-x-2:focus {
    --transform-translate-x: -0.5rem;
  }

  .xl\:focus\:tw--translate-x-3:focus {
    --transform-translate-x: -0.75rem;
  }

  .xl\:focus\:tw--translate-x-4:focus {
    --transform-translate-x: -1rem;
  }

  .xl\:focus\:tw--translate-x-5:focus {
    --transform-translate-x: -1.25rem;
  }

  .xl\:focus\:tw--translate-x-6:focus {
    --transform-translate-x: -1.5rem;
  }

  .xl\:focus\:tw--translate-x-8:focus {
    --transform-translate-x: -2rem;
  }

  .xl\:focus\:tw--translate-x-10:focus {
    --transform-translate-x: -2.5rem;
  }

  .xl\:focus\:tw--translate-x-12:focus {
    --transform-translate-x: -3rem;
  }

  .xl\:focus\:tw--translate-x-16:focus {
    --transform-translate-x: -4rem;
  }

  .xl\:focus\:tw--translate-x-20:focus {
    --transform-translate-x: -5rem;
  }

  .xl\:focus\:tw--translate-x-24:focus {
    --transform-translate-x: -6rem;
  }

  .xl\:focus\:tw--translate-x-32:focus {
    --transform-translate-x: -8rem;
  }

  .xl\:focus\:tw--translate-x-40:focus {
    --transform-translate-x: -10rem;
  }

  .xl\:focus\:tw--translate-x-48:focus {
    --transform-translate-x: -12rem;
  }

  .xl\:focus\:tw--translate-x-56:focus {
    --transform-translate-x: -14rem;
  }

  .xl\:focus\:tw--translate-x-64:focus {
    --transform-translate-x: -16rem;
  }

  .xl\:focus\:tw--translate-x-px:focus {
    --transform-translate-x: -1px;
  }

  .xl\:focus\:tw--translate-x-270px:focus {
    --transform-translate-x: -270px;
  }

  .xl\:focus\:tw--translate-x-400px:focus {
    --transform-translate-x: -400px;
  }

  .xl\:focus\:tw--translate-x-464px:focus {
    --transform-translate-x: -464px;
  }

  .xl\:focus\:tw--translate-x-480px:focus {
    --transform-translate-x: -480px;
  }

  .xl\:focus\:tw--translate-x-500px:focus {
    --transform-translate-x: -500px;
  }

  .xl\:focus\:tw--translate-x-576px:focus {
    --transform-translate-x: -576px;
  }

  .xl\:focus\:tw--translate-x-600px:focus {
    --transform-translate-x: -600px;
  }

  .xl\:focus\:tw--translate-x-672px:focus {
    --transform-translate-x: -672px;
  }

  .xl\:focus\:tw--translate-x-full:focus {
    --transform-translate-x: -100%;
  }

  .xl\:focus\:tw--translate-x-1\/2:focus {
    --transform-translate-x: -50%;
  }

  .xl\:focus\:tw-translate-x-1\/2:focus {
    --transform-translate-x: 50%;
  }

  .xl\:focus\:tw-translate-x-full:focus {
    --transform-translate-x: 100%;
  }

  .xl\:focus\:tw-translate-y-0:focus {
    --transform-translate-y: 0;
  }

  .xl\:focus\:tw-translate-y-1:focus {
    --transform-translate-y: 0.25rem;
  }

  .xl\:focus\:tw-translate-y-2:focus {
    --transform-translate-y: 0.5rem;
  }

  .xl\:focus\:tw-translate-y-3:focus {
    --transform-translate-y: 0.75rem;
  }

  .xl\:focus\:tw-translate-y-4:focus {
    --transform-translate-y: 1rem;
  }

  .xl\:focus\:tw-translate-y-5:focus {
    --transform-translate-y: 1.25rem;
  }

  .xl\:focus\:tw-translate-y-6:focus {
    --transform-translate-y: 1.5rem;
  }

  .xl\:focus\:tw-translate-y-8:focus {
    --transform-translate-y: 2rem;
  }

  .xl\:focus\:tw-translate-y-10:focus {
    --transform-translate-y: 2.5rem;
  }

  .xl\:focus\:tw-translate-y-12:focus {
    --transform-translate-y: 3rem;
  }

  .xl\:focus\:tw-translate-y-16:focus {
    --transform-translate-y: 4rem;
  }

  .xl\:focus\:tw-translate-y-20:focus {
    --transform-translate-y: 5rem;
  }

  .xl\:focus\:tw-translate-y-24:focus {
    --transform-translate-y: 6rem;
  }

  .xl\:focus\:tw-translate-y-32:focus {
    --transform-translate-y: 8rem;
  }

  .xl\:focus\:tw-translate-y-40:focus {
    --transform-translate-y: 10rem;
  }

  .xl\:focus\:tw-translate-y-48:focus {
    --transform-translate-y: 12rem;
  }

  .xl\:focus\:tw-translate-y-56:focus {
    --transform-translate-y: 14rem;
  }

  .xl\:focus\:tw-translate-y-64:focus {
    --transform-translate-y: 16rem;
  }

  .xl\:focus\:tw-translate-y-px:focus {
    --transform-translate-y: 1px;
  }

  .xl\:focus\:tw-translate-y-270px:focus {
    --transform-translate-y: 270px;
  }

  .xl\:focus\:tw-translate-y-400px:focus {
    --transform-translate-y: 400px;
  }

  .xl\:focus\:tw-translate-y-464px:focus {
    --transform-translate-y: 464px;
  }

  .xl\:focus\:tw-translate-y-480px:focus {
    --transform-translate-y: 480px;
  }

  .xl\:focus\:tw-translate-y-500px:focus {
    --transform-translate-y: 500px;
  }

  .xl\:focus\:tw-translate-y-576px:focus {
    --transform-translate-y: 576px;
  }

  .xl\:focus\:tw-translate-y-600px:focus {
    --transform-translate-y: 600px;
  }

  .xl\:focus\:tw-translate-y-672px:focus {
    --transform-translate-y: 672px;
  }

  .xl\:focus\:tw--translate-y-1:focus {
    --transform-translate-y: -0.25rem;
  }

  .xl\:focus\:tw--translate-y-2:focus {
    --transform-translate-y: -0.5rem;
  }

  .xl\:focus\:tw--translate-y-3:focus {
    --transform-translate-y: -0.75rem;
  }

  .xl\:focus\:tw--translate-y-4:focus {
    --transform-translate-y: -1rem;
  }

  .xl\:focus\:tw--translate-y-5:focus {
    --transform-translate-y: -1.25rem;
  }

  .xl\:focus\:tw--translate-y-6:focus {
    --transform-translate-y: -1.5rem;
  }

  .xl\:focus\:tw--translate-y-8:focus {
    --transform-translate-y: -2rem;
  }

  .xl\:focus\:tw--translate-y-10:focus {
    --transform-translate-y: -2.5rem;
  }

  .xl\:focus\:tw--translate-y-12:focus {
    --transform-translate-y: -3rem;
  }

  .xl\:focus\:tw--translate-y-16:focus {
    --transform-translate-y: -4rem;
  }

  .xl\:focus\:tw--translate-y-20:focus {
    --transform-translate-y: -5rem;
  }

  .xl\:focus\:tw--translate-y-24:focus {
    --transform-translate-y: -6rem;
  }

  .xl\:focus\:tw--translate-y-32:focus {
    --transform-translate-y: -8rem;
  }

  .xl\:focus\:tw--translate-y-40:focus {
    --transform-translate-y: -10rem;
  }

  .xl\:focus\:tw--translate-y-48:focus {
    --transform-translate-y: -12rem;
  }

  .xl\:focus\:tw--translate-y-56:focus {
    --transform-translate-y: -14rem;
  }

  .xl\:focus\:tw--translate-y-64:focus {
    --transform-translate-y: -16rem;
  }

  .xl\:focus\:tw--translate-y-px:focus {
    --transform-translate-y: -1px;
  }

  .xl\:focus\:tw--translate-y-270px:focus {
    --transform-translate-y: -270px;
  }

  .xl\:focus\:tw--translate-y-400px:focus {
    --transform-translate-y: -400px;
  }

  .xl\:focus\:tw--translate-y-464px:focus {
    --transform-translate-y: -464px;
  }

  .xl\:focus\:tw--translate-y-480px:focus {
    --transform-translate-y: -480px;
  }

  .xl\:focus\:tw--translate-y-500px:focus {
    --transform-translate-y: -500px;
  }

  .xl\:focus\:tw--translate-y-576px:focus {
    --transform-translate-y: -576px;
  }

  .xl\:focus\:tw--translate-y-600px:focus {
    --transform-translate-y: -600px;
  }

  .xl\:focus\:tw--translate-y-672px:focus {
    --transform-translate-y: -672px;
  }

  .xl\:focus\:tw--translate-y-full:focus {
    --transform-translate-y: -100%;
  }

  .xl\:focus\:tw--translate-y-1\/2:focus {
    --transform-translate-y: -50%;
  }

  .xl\:focus\:tw-translate-y-1\/2:focus {
    --transform-translate-y: 50%;
  }

  .xl\:focus\:tw-translate-y-full:focus {
    --transform-translate-y: 100%;
  }

  .xl\:tw-skew-x-0 {
    --transform-skew-x: 0;
  }

  .xl\:tw-skew-x-1 {
    --transform-skew-x: 1deg;
  }

  .xl\:tw-skew-x-2 {
    --transform-skew-x: 2deg;
  }

  .xl\:tw-skew-x-3 {
    --transform-skew-x: 3deg;
  }

  .xl\:tw-skew-x-6 {
    --transform-skew-x: 6deg;
  }

  .xl\:tw-skew-x-12 {
    --transform-skew-x: 12deg;
  }

  .xl\:tw--skew-x-12 {
    --transform-skew-x: -12deg;
  }

  .xl\:tw--skew-x-6 {
    --transform-skew-x: -6deg;
  }

  .xl\:tw--skew-x-3 {
    --transform-skew-x: -3deg;
  }

  .xl\:tw--skew-x-2 {
    --transform-skew-x: -2deg;
  }

  .xl\:tw--skew-x-1 {
    --transform-skew-x: -1deg;
  }

  .xl\:tw-skew-y-0 {
    --transform-skew-y: 0;
  }

  .xl\:tw-skew-y-1 {
    --transform-skew-y: 1deg;
  }

  .xl\:tw-skew-y-2 {
    --transform-skew-y: 2deg;
  }

  .xl\:tw-skew-y-3 {
    --transform-skew-y: 3deg;
  }

  .xl\:tw-skew-y-6 {
    --transform-skew-y: 6deg;
  }

  .xl\:tw-skew-y-12 {
    --transform-skew-y: 12deg;
  }

  .xl\:tw--skew-y-12 {
    --transform-skew-y: -12deg;
  }

  .xl\:tw--skew-y-6 {
    --transform-skew-y: -6deg;
  }

  .xl\:tw--skew-y-3 {
    --transform-skew-y: -3deg;
  }

  .xl\:tw--skew-y-2 {
    --transform-skew-y: -2deg;
  }

  .xl\:tw--skew-y-1 {
    --transform-skew-y: -1deg;
  }

  .xl\:hover\:tw-skew-x-0:hover {
    --transform-skew-x: 0;
  }

  .xl\:hover\:tw-skew-x-1:hover {
    --transform-skew-x: 1deg;
  }

  .xl\:hover\:tw-skew-x-2:hover {
    --transform-skew-x: 2deg;
  }

  .xl\:hover\:tw-skew-x-3:hover {
    --transform-skew-x: 3deg;
  }

  .xl\:hover\:tw-skew-x-6:hover {
    --transform-skew-x: 6deg;
  }

  .xl\:hover\:tw-skew-x-12:hover {
    --transform-skew-x: 12deg;
  }

  .xl\:hover\:tw--skew-x-12:hover {
    --transform-skew-x: -12deg;
  }

  .xl\:hover\:tw--skew-x-6:hover {
    --transform-skew-x: -6deg;
  }

  .xl\:hover\:tw--skew-x-3:hover {
    --transform-skew-x: -3deg;
  }

  .xl\:hover\:tw--skew-x-2:hover {
    --transform-skew-x: -2deg;
  }

  .xl\:hover\:tw--skew-x-1:hover {
    --transform-skew-x: -1deg;
  }

  .xl\:hover\:tw-skew-y-0:hover {
    --transform-skew-y: 0;
  }

  .xl\:hover\:tw-skew-y-1:hover {
    --transform-skew-y: 1deg;
  }

  .xl\:hover\:tw-skew-y-2:hover {
    --transform-skew-y: 2deg;
  }

  .xl\:hover\:tw-skew-y-3:hover {
    --transform-skew-y: 3deg;
  }

  .xl\:hover\:tw-skew-y-6:hover {
    --transform-skew-y: 6deg;
  }

  .xl\:hover\:tw-skew-y-12:hover {
    --transform-skew-y: 12deg;
  }

  .xl\:hover\:tw--skew-y-12:hover {
    --transform-skew-y: -12deg;
  }

  .xl\:hover\:tw--skew-y-6:hover {
    --transform-skew-y: -6deg;
  }

  .xl\:hover\:tw--skew-y-3:hover {
    --transform-skew-y: -3deg;
  }

  .xl\:hover\:tw--skew-y-2:hover {
    --transform-skew-y: -2deg;
  }

  .xl\:hover\:tw--skew-y-1:hover {
    --transform-skew-y: -1deg;
  }

  .xl\:focus\:tw-skew-x-0:focus {
    --transform-skew-x: 0;
  }

  .xl\:focus\:tw-skew-x-1:focus {
    --transform-skew-x: 1deg;
  }

  .xl\:focus\:tw-skew-x-2:focus {
    --transform-skew-x: 2deg;
  }

  .xl\:focus\:tw-skew-x-3:focus {
    --transform-skew-x: 3deg;
  }

  .xl\:focus\:tw-skew-x-6:focus {
    --transform-skew-x: 6deg;
  }

  .xl\:focus\:tw-skew-x-12:focus {
    --transform-skew-x: 12deg;
  }

  .xl\:focus\:tw--skew-x-12:focus {
    --transform-skew-x: -12deg;
  }

  .xl\:focus\:tw--skew-x-6:focus {
    --transform-skew-x: -6deg;
  }

  .xl\:focus\:tw--skew-x-3:focus {
    --transform-skew-x: -3deg;
  }

  .xl\:focus\:tw--skew-x-2:focus {
    --transform-skew-x: -2deg;
  }

  .xl\:focus\:tw--skew-x-1:focus {
    --transform-skew-x: -1deg;
  }

  .xl\:focus\:tw-skew-y-0:focus {
    --transform-skew-y: 0;
  }

  .xl\:focus\:tw-skew-y-1:focus {
    --transform-skew-y: 1deg;
  }

  .xl\:focus\:tw-skew-y-2:focus {
    --transform-skew-y: 2deg;
  }

  .xl\:focus\:tw-skew-y-3:focus {
    --transform-skew-y: 3deg;
  }

  .xl\:focus\:tw-skew-y-6:focus {
    --transform-skew-y: 6deg;
  }

  .xl\:focus\:tw-skew-y-12:focus {
    --transform-skew-y: 12deg;
  }

  .xl\:focus\:tw--skew-y-12:focus {
    --transform-skew-y: -12deg;
  }

  .xl\:focus\:tw--skew-y-6:focus {
    --transform-skew-y: -6deg;
  }

  .xl\:focus\:tw--skew-y-3:focus {
    --transform-skew-y: -3deg;
  }

  .xl\:focus\:tw--skew-y-2:focus {
    --transform-skew-y: -2deg;
  }

  .xl\:focus\:tw--skew-y-1:focus {
    --transform-skew-y: -1deg;
  }

  .xl\:tw-transition-none {
    transition-property: none;
  }

  .xl\:tw-transition-all {
    transition-property: all;
  }

  .xl\:tw-transition {
    transition-property: background-color, border-color, color, fill, stroke, opacity, box-shadow, transform;
  }

  .xl\:tw-transition-colors {
    transition-property: background-color, border-color, color, fill, stroke;
  }

  .xl\:tw-transition-opacity {
    transition-property: opacity;
  }

  .xl\:tw-transition-shadow {
    transition-property: box-shadow;
  }

  .xl\:tw-transition-transform {
    transition-property: transform;
  }

  .xl\:tw-ease-linear {
    transition-timing-function: linear;
  }

  .xl\:tw-ease-in {
    transition-timing-function: cubic-bezier(0.4, 0, 1, 1);
  }

  .xl\:tw-ease-out {
    transition-timing-function: cubic-bezier(0, 0, 0.2, 1);
  }

  .xl\:tw-ease-in-out {
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  }

  .xl\:tw-duration-75 {
    transition-duration: 75ms;
  }

  .xl\:tw-duration-100 {
    transition-duration: 100ms;
  }

  .xl\:tw-duration-150 {
    transition-duration: 150ms;
  }

  .xl\:tw-duration-200 {
    transition-duration: 200ms;
  }

  .xl\:tw-duration-300 {
    transition-duration: 300ms;
  }

  .xl\:tw-duration-500 {
    transition-duration: 500ms;
  }

  .xl\:tw-duration-700 {
    transition-duration: 700ms;
  }

  .xl\:tw-duration-1000 {
    transition-duration: 1000ms;
  }

  .xl\:tw-delay-75 {
    transition-delay: 75ms;
  }

  .xl\:tw-delay-100 {
    transition-delay: 100ms;
  }

  .xl\:tw-delay-150 {
    transition-delay: 150ms;
  }

  .xl\:tw-delay-200 {
    transition-delay: 200ms;
  }

  .xl\:tw-delay-300 {
    transition-delay: 300ms;
  }

  .xl\:tw-delay-500 {
    transition-delay: 500ms;
  }

  .xl\:tw-delay-700 {
    transition-delay: 700ms;
  }

  .xl\:tw-delay-1000 {
    transition-delay: 1000ms;
  }

  .xl\:tw-animate-none {
    animation: none;
  }

  .xl\:tw-animate-spin {
    animation: spin 1s linear infinite;
  }

  .xl\:tw-animate-ping {
    animation: ping 1s cubic-bezier(0, 0, 0.2, 1) infinite;
  }

  .xl\:tw-animate-pulse {
    animation: pulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite;
  }

  .xl\:tw-animate-bounce {
    animation: bounce 1s infinite;
  }
}
